import React from "react"

const FacebookIcon = (props) => (
	<a href="https://www.facebook.com/eventric" target="_blank" rel="noopener noreferrer">
		<svg className="socialLink" width={props.size} height={props.size} fill={props.color} viewBox="0 0 32 32">
			<path d="M26.667 0h-21.333c-2.933 0-5.334 2.4-5.334 5.334v21.332c0 2.936 2.4 5.334 5.334 5.334l21.333-0c2.934 0 5.333-2.398 5.333-5.334v-21.332c0-2.934-2.399-5.334-5.333-5.334zM27.206 16h-5.206v14h-6v-14h-2.891v-4.58h2.891v-2.975c0-4.042 1.744-6.445 6.496-6.445h5.476v4.955h-4.473c-1.328-0.002-1.492 0.692-1.492 1.985l-0.007 2.48h6l-0.794 4.58z" />
		</svg>
	</a>
)

export default FacebookIcon
