import React from "react"
import moment from "moment"
import { Transition } from "react-spring/renderprops"

import Layout from "../components/Layout"
import TicketIcon from "../components/TicketIcon"

const Guests = (props) => {
	props.addSection("Guests")
	let loaded = false
	const sectionIndex = props.sections.findIndex(section => section === "Guests")
	if (props.destinationIndex === sectionIndex) {
		loaded = true
	}
	const totalGuests = props?.totalGuests || ""
	const guestDate = moment.utc(props.mostGuestDate, "YYYY-MM-DD HH:mm:ss").format("MMMM Do")
	const venueName = props?.mostGuestName || ""
	const venueCity = props?.mostGuestCity ? `, ${props.mostGuestCity}` : ""
	const guestAmount = props?.mostGuestAmount || ""

	const createTicket = index => {
		// pick a random number / percentage of vw
		let positionX = (Math.random() * 100) - 2
		const size = (Math.random() * 24) + 19
		const speed = (Math.random() * 2) + 1
		const ticketStyle = {
			animation: `rain ${speed}s ease-in forwards`,
			left: `${positionX}%`,
			position: "absolute",
			top: "-20%",
			width: size,
		}
		return <div key={index} style={ticketStyle}><TicketIcon size="100%" /></div>
	}

	const tickets = []
	for (let i = 0; i < 250; i++) {
		tickets.push(createTicket(i))
	}

	return (
		<>
		<Layout>
				{loaded &&
					<div style={styles.ticketStyle}>
						{tickets}
					</div>
				}

				{loaded &&
					<>
						<Transition
							from={{ opacity: 0, transform: 'translate3d(0,-40px,0)' }}
							enter={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}
						>
							{item => props =>
								<p style={{...props, ...styles.textOne}}>Your events had <b>{totalGuests}</b> guests</p>
							}
						</Transition>

						<Transition
							from={{ opacity: 0, transform: 'translate3d(0,-40px,0)' }}
							enter={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}
							delay={50}
						>
							{item => props =>
								<div style={{...props, ...styles.textTwo}}>
									<p>The most was <b>{guestAmount}</b> tickets on <b>{guestDate}</b> <br />at <b>{venueName}{venueCity}</b></p>
								</div>
							}
						</Transition>
					</>
				}

			</Layout>
		</>
	)
}

const styles = {
	iconContainer: {
		top: "10%",
		position: "absolute",
		right: "7%",
		width: "20%",
	},
	textOne: {
		fontSize: "1.5em",
		marginBottom: 20,
	},
	textTwo: {
		fontSize: "1.5em",
	},
	ticketStyle: {
		position: "absolute",
		height: "100vh",
		width: "100vw",
		left: 0,
		top:0,
	},
}

export default Guests
