import React, { useEffect } from "react"
import { Transition } from "react-spring/renderprops"
import "./LpsOne.css"

import Layout from "../components/Layout"

const LpsOne = (props) => {
	props.addSection("LpsOne")
	let loaded = false
	const sectionIndex = props.sections.findIndex((section) => section === "LpsOne")
	if (props.destinationIndex === sectionIndex) {
		loaded = true
	}
	const [isSmallWidth, setIsSmallWidth] = React.useState(false)
	// get window width on change to handle display of text content per design
	useEffect(() => {
		window.addEventListener("resize", () => {
			if (window.innerWidth > 1000) {
				setIsSmallWidth(false)
			} else {
				setIsSmallWidth(true)
			}
		})
		return () => {
			window.removeEventListener("resize")
		}
	}, [])
	const breakingText = () => {
		return (
			<>
				<p className="text maxWidth1200">
					Join us for the upcoming <span className="redColor bold">Master Tour Awards</span>,
				</p>
				<p className="text maxWidth1200">
					as we celebrate another year in outstanding achievements in live events!
				</p>
			</>
		)
	}
	const text = () => {
		return (
			<p className="text maxWidth1200">
				Join us for the upcoming <span className="redColor bold">Master Tour Awards</span>, as we
				celebrate another year in outstanding achievements in live events!
			</p>
		)
	}
	const content = isSmallWidth ? text : breakingText
	return (
		<>
			<Layout>
				{loaded && (
					<div style={styles.container}>
						<Transition
							from={{ opacity: 0, transform: "translate3d(-40px,0,0)" }}
							enter={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
						>
							{(item) => (props) =>
								(
									<div className="header" style={{ ...props }}>
										{content()}
										<p className="text spacing">
											<span className="redColor bold">
												January 18th - 20th 2024 - Palm Springs, CA
											</span>
										</p>
										<div className="spacing flex center">
											<div className="flex registerRow">
												<p className="text bold">@ Live Production Summit</p>
												<a
													target="_blank"
													rel="noopener noreferrer"
													href="https://tix.wrstbnd.com/e/event/liveproductionsummit24"
													className="text link bold"
												>
													Register Here
												</a>
											</div>
										</div>
										<p className="text spacing">
											Let's come together to honor the best in the industry and celebrate the
											success of Master Tour users.
										</p>
										<p className="text spacing">We look forward to seeing you there!</p>
									</div>
								)}
						</Transition>
					</div>
				)}
			</Layout>
		</>
	)
}

const styles = {
	container: {
		borderRadius: 10,
		margin: "0 auto",
		width: "100%",
	},
}

export default LpsOne
