import React from "react"
import PropTypes from "prop-types"

const propTypes = {
	color: PropTypes.string,
	size: PropTypes.string,
}

const ShareIcon = ({size, color, ...otherProps}) => (
	<svg width={size} height={size} {...otherProps} viewBox="0 0 22 16">
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g fillRule="nonzero" fill={color}>
				<path d="M5.33333333,10.6666667 C5.33333333,10.6666667 6.55883333,6.66666667 13.3333333,6.66666667 L13.3333333,10.6666667 L21.3333333,5.33333333 L13.3333333,0 L13.3333333,4 C8,4 5.33333333,7.3265 5.33333333,10.6666667 Z M14.6666667,13.3333333 L2.66666667,13.3333333 L2.66666667,5.33333333 L5.28908333,5.33333333 C5.499375,5.08504167 5.72475,4.84720833 5.96591667,4.62125 C6.88183333,3.763125 7.977625,3.10766667 9.21829167,2.66666667 L0,2.66666667 L0,16 L17.3333333,16 L17.3333333,10.4036667 L14.6666667,12.1814583 L14.6666667,13.3333333 Z" />
			</g>
		</g>
	</svg>
)

ShareIcon.propTypes = propTypes

export default ShareIcon
