import React from "react"
import { Transition } from "react-spring/renderprops"
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share"

import FacebookIcon from "../components/ShareLinkIcons/FacebookIcon"
import TwitterIcon from "../components/ShareLinkIcons/TwitterIcon"
import ShareIcon from "../components/ShareLinkIcons/ShareIcon"
import DownloadIcon from "../components/ShareLinkIcons/DownloadIcon"
import EventricSocialLinks from "../components/EventricSocialLinks"
import LinkedInIcon from "../components/ShareLinkIcons/LinkedInIcon"

const ThankYou = (props) => {
	props.addSection("ThankYou")
	let loaded = false
	const sectionIndex = props.sections.findIndex((section) => section === "ThankYou")
	if (props.destinationIndex === sectionIndex) {
		loaded = true
	}
	const titleContent = `Visit https://2023.eventric.com/${props.accountHash}`
	const urlContent = `https://2023.eventric.com/summ/${props.accountHash}.png`
	console.log({ titleContent, urlContent })
	// #mastertourontheroad #checkmastertour @eventric
	const mobileShare = () => {
		if (navigator.share) {
			navigator
				.share({
					url: urlContent,
					title: titleContent,
				})
				.catch(() => {
					console.log("Share cancelled.")
				})
		}
	}
	return (
		<div style={styles.container}>
			{loaded && (
				<>
					<Transition from={{ opacity: 0 }} enter={{ opacity: 1 }}>
						{(item) => (styleProps) =>
							(
								<div style={styleProps}>
									<p>From everyone at Eventric,</p>
									<div style={styles.textTwo}>
										<p>We wish you a safe and busy 2024.</p>
										<p>
											<b>Happy New Year!</b>
										</p>
										{titleContent && (
											<div style={styles.socialContainer}>
												<FacebookShareButton
													url={urlContent}
													size={50}
													hashtag="#mastertourontheroad"
												>
													<div className="shareFacebook" style={{ ...styles.shareContainer }}>
														<p style={styles.shareText}>Share</p>
														<FacebookIcon size={"1em"} color={"#fff"} />
													</div>
												</FacebookShareButton>
												<TwitterShareButton
													url={urlContent}
													size={50}
													title={titleContent}
													hashtags={["mastertourontheroad", "checkmastertour"]}
													via="eventric"
												>
													<div className="shareTwitter" style={styles.shareContainer}>
														<p style={styles.shareText}>Post</p>
														<TwitterIcon size={"1em"} color={"#fff"} />
													</div>
												</TwitterShareButton>
												<LinkedinShareButton url={urlContent} size={50}>
													<div className="shareLinkedIn" style={{ ...styles.shareContainer }}>
														<p style={styles.shareText}>Post</p>
														<LinkedInIcon size={"1em"} color={"#fff"} />
													</div>
												</LinkedinShareButton>
												{navigator?.share ? (
													<div
														onClick={mobileShare}
														className="shareNative"
														style={styles.shareContainer}
													>
														<p style={styles.shareText}>Share</p>
														<ShareIcon size={"1em"} color={"#fff"} />
													</div>
												) : null}
												<a
													href={`https://2023.eventric.com/summ/${
														props.accountHash || "default"
													}.png`}
													target="_blank"
													rel="noopener noreferrer"
													download={props.titleContent}
													className="shareDownload"
													style={{ ...styles.shareContainer }}
												>
													<p style={styles.shareText}>Save</p>
													<DownloadIcon size={"1em"} color={"#fff"} />
												</a>
											</div>
										)}
									</div>
									<div style={styles.evContainer}>
										<div style={styles.evLogo}>
											<a href="https://www.eventric.com" target="_blank" rel="noopener noreferrer">
												<img
													src="https://cdn-eoy.eventric.com/img/logo-ev.png"
													alt="Eventric logo"
													style={styles.evLogo}
												/>
											</a>
										</div>
										<div style={{ fontSize: "2.2em" }}>
											<EventricSocialLinks />
										</div>
										<p style={styles.muted}>
											Eventric, LLC. 1644 N. Honore Suite 301, Chicago, IL, 60622 USA
										</p>
										<p style={styles.muted}>© Eventric, LLC., All Rights Reserved.</p>
									</div>
								</div>
							)}
					</Transition>
				</>
			)}
		</div>
	)
}

const styles = {
	container: {
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		fontSize: "1.3em",
		justifyContent: "center",
	},
	evContainer: {
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		fontSize: "0.4em",
		marginTop: "1.5em",
	},
	evLogo: {
		marginBottom: ".4em",
		maxWidth: 500,
		width: "15em",
	},
	muted: {
		color: "#999999",
		marginBottom: 10,
	},
	shareContainer: {
		borderRadius: "10px",
		cursor: "pointer",
		display: "flex",
		justifyContent: "center",
		padding: "0.5em 1em",
		margin: "0.5em 0.3em",
		textTransform: "uppercase",
	},
	shareText: {
		marginRight: 10,
	},
	socialContainer: {
		alignItems: "center",
		display: "flex",
		flexWrap: "wrap",
		fontSize: "0.75em",
		justifyContent: "space-around",
		margin: "2em auto 4em",
		maxWidth: "80%",
	},
}

export default ThankYou
