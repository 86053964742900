import React from "react"
import TwitterIcon from "../components/TwitterIcon"
import FacebookIcon from "../components/FacebookIcon"
import InstagramIcon from "../components/InstagramIcon"
import LinkedinIcon from "../components/LinkedinIcon"

const EventricSocialLinks = props => (
	<div style={{...styles.evSocialContainer, ...props.style}}>
		<div style={styles.socialLink}>
			<TwitterIcon size={"1em"} color={"rgba(255,255,255,0.7)"} />
		</div>
		<div style={styles.socialLink}>
			<InstagramIcon size={"1em"} color={"rgba(255,255,255,0.7)"} />
		</div>
		<div style={styles.socialLink}>
			<FacebookIcon size={"1em"} color={"rgba(255,255,255,0.7)"} />
		</div>
		<div style={styles.socialLink}>
			<LinkedinIcon size={"1em"} color={"rgba(255,255,255,0.7)"} />
		</div>
	</div>
)

const styles = {
	evSocialContainer: {
		display: "flex",
		marginBottom: "20px",
		marginRight: "0.57em",
	},
	socialLink: {
		marginLeft: "0.57em",
	},
}

export default EventricSocialLinks
