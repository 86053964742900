import React from "react"
import { Transition } from "react-spring/renderprops"

import Layout from "../components/Layout"

const TaggedSpots = (props) => {
	props.addSection("TaggedSpots")
	let loaded = false
	const sectionIndex = props.sections.findIndex((section) => section === "TaggedSpots")
	if (props.destinationIndex === sectionIndex) {
		loaded = true
	}
	return (
		<>
			<Layout>
				<img
					src="https://cdn-eoy.eventric.com/img/Phone@2x.png"
					srcSet={`https://cdn-eoy.eventric.com/img/Phone@2x.png 2x, https://cdn-eoy.eventric.com/img/Phone@3x.png 3x`}
					alt="Master Tour tagged locations"
					style={styles.imageStyles}
				/>

				{loaded && (
					<Transition
						from={{ opacity: 0, transform: "translate3d(0,-40px,0)" }}
						enter={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
					>
						{(item) => (props) =>
							(
								<div style={{ ...props, ...styles.textContainer }}>
									<p className="taggedText">
										<span className="taggedTextSpan">Master Tour users</span>
									</p>
									<p className="taggedText">
										<span className="taggedTextSpan">
											tagged over <b>700</b>
										</span>
									</p>
									<p className="taggedText">
										<span className="taggedTextSpan">new locations</span>
									</p>
									<p className="taggedText">
										<span className="taggedTextSpan">worth checking out!</span>
									</p>
								</div>
							)}
					</Transition>
				)}
			</Layout>
		</>
	)
}

const styles = {
	imageStyles: {
		position: "absolute",
		left: "-2.5%",
		bottom: "-4%",
		width: "43vh",
		maxWidth: "60vw",
	},
	textContainer: {
		textAlign: "right",
	},
	text: {
		backgroundColor: "#000",
		boxDecorationBreak: "clone",
		display: "inline",
		padding: "0.7rem",
	},
}

export default TaggedSpots
