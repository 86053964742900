import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
const bugsnagClient = bugsnag('e3092b03162615c8b458728d728886cd')
bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = bugsnagClient.getPlugin('react')


ReactDOM.hydrate(
	<ErrorBoundary>
    <App />
  </ErrorBoundary>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
