import React, { useState } from "react"
import { Transition } from "react-spring/renderprops"

import Layout from "../components/Layout"
import TwoPeopleIcon from "../components/TwoPeopleIcon"

const Friends = (props) => {
	props.addSection("Friends")
	let loaded = false
	const sectionIndex = props.sections.findIndex((section) => section === "Friends")
	if (props.destinationIndex === sectionIndex) {
		loaded = true
	}
	const [peopleCount, setPeopleCount] = useState([])
	const addPeople = () => {
		const newCount = [...peopleCount, 1]
		setPeopleCount(newCount)
	}
	const setPeopleTimeout = () => {
		if (peopleCount.length >= 800 || !loaded) {
			return
		} else {
			setTimeout(addPeople, 0)
		}
	}
	if (loaded) {
		setPeopleTimeout()
	} else {
		if (peopleCount.length) {
			setPeopleCount([])
		}
	}
	return (
		<>
			<div style={styles.peopleContainer}>
				{peopleCount.map((item, index) => (
					<TwoPeopleIcon key={index} size={40} color={"rgba(255,255,255,0.15)"} />
				))}
			</div>
			<Layout>
				{loaded && (
					<>
						<Transition
							from={{ opacity: 0, transform: "translate3d(0,-40px,0)" }}
							enter={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
						>
							{(item) => (props) =>
								<p style={{ ...props, ...styles.textOne }}>Your events have lots of guests...</p>}
						</Transition>

						<Transition
							from={{ opacity: 0, transform: "translate3d(0,-40px,0)" }}
							enter={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
							delay={50}
						>
							{(item) => (props) =>
								(
									<p style={{ ...props, ...styles.textTwo }}>
										Master Tour users invited <b>800 thousand</b> guests!
									</p>
								)}
						</Transition>
					</>
				)}
			</Layout>
		</>
	)
}

const styles = {
	peopleContainer: {
		alignItems: "flex-start",
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		left: 0,
		position: "absolute",
		top: 0,
		width: "100%",
		zIndex: 0,
	},
	textOne: {
		fontSize: "1.3em",
		marginBottom: "0.8em",
	},
	textTwo: {
		fontSize: "1.7em",
	},
}

export default Friends
