import React from "react"

const ClockIcon = (props) => (
<svg width={props.size} height={props.size} viewBox="0 0 52 52">
    <g strokeWidth="1" stroke={props.color} fill="none" transform="translate(1.000000, 1.000000)" fillRule="evenodd">
        <rect x="0" y="0" width="50" height="50" rx="8" />
        <line className="clockHandHour" x1="23" y1="23" x2="34.4705882" y2="29.1176471" strokeLinecap="square" />
        <line className="clockHandMinute" x1="25.5" y1="27.5" x2="25.5" y2="6.5" strokeLinecap="square" />
    </g>
</svg>
)

export default ClockIcon
