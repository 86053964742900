import React from "react"
import { Transition } from "react-spring/renderprops"
import Layout from "../components/Layout"
import ThankYouDuo from "../components/ThankYouDuo"
import ThankYouTrio from "../components/ThankYouTrio"
import MobilePhone from "../components/MobilePhone"

const ComingSoon = (props) => {
	const { addSection, sections, destinationIndex } = props

	addSection("ComingSoon")

	const sectionIndex = sections.findIndex((section) => section === "ComingSoon")
	const loaded = destinationIndex === sectionIndex

	let size = window.matchMedia("(max-width: 767px)").matches ? "mobile" : "desktop"

	return (
		<>
			<Layout>
				{!loaded && (
					<div style={size === "mobile" ? styles.peopleContainerBottom : styles.peopleContainerTop}>
						<Transition
							from={{ opacity: 1, transform: "translate3d(0,-40px,0)" }}
							enter={{ opacity: 0, transform: "translate3d(0,0px,0)" }}
							config={{ duration: 10000, delay: 5000 }}
						>
							{(item) => () => <ThankYouDuo color="white" size={size} />}
						</Transition>
					</div>
				)}
				{loaded && (
					<div style={styles.fullText}>
						<Transition
							from={{ opacity: 0, transform: "translate3d(0,-40px,0)" }}
							enter={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
						>
							{(item) => (styleProps) =>
								<p style={{ ...styleProps, ...styles.titleText }}>2024 Coming Soon!</p>}
						</Transition>
						<div
							style={{
								...styles.blurb,
								marginLeft: size === "mobile" ? "-5%" : "0%",
								marginRight: size === "mobile" ? "5%" : "0%",
							}}
						>
							<Transition
								from={{ opacity: 0, transform: "translate3d(0,-40px,0)" }}
								enter={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
							>
								{(item) => (styleProps) =>
									(
										<div style={{ ...styleProps }}>
											<p>
												<span style={styles.highlightText}>Master Tour </span>
												<span style={styles.standardText}>and </span>
												<span style={styles.highlightText}>Master Tour Venue:</span>
											</p>
											<ul style={styles.unorderedList}>
												<li style={(styles.listItems, styles.standardText)}>
													Standardized Tech Pack
												</li>
												<li style={(styles.listItems, styles.standardText)}>Venue Integrations</li>
												<li style={(styles.listItems, styles.standardText)}>
													Advance Integrations
												</li>
											</ul>
										</div>
									)}
							</Transition>
							<Transition
								from={{ opacity: 0, transform: "translate3d(0,-40px,0)" }}
								enter={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
								delay={100}
							>
								{(item) => (styleProps) =>
									(
										<div style={{ ...styleProps, marginTop: "50px" }}>
											<p>
												<span style={styles.standardText}>All New </span>
												<span style={styles.highlightText}>Master Tour Mobile </span>
												<span style={styles.standardText}>Redesign:</span>
											</p>
											<ul style={styles.unorderedList}>
												<li style={(styles.listItems, styles.standardText)}>
													Desktop Functionality for Managers on the Road
												</li>
												<li style={(styles.listItems, styles.standardText)}>
													Enhanced Profile Management
												</li>
												<li style={(styles.listItems, styles.standardText)}>Enhanced Tagging</li>
											</ul>
										</div>
									)}
							</Transition>
						</div>
						<Transition
							from={{ opacity: 0, transform: "translate3d(0,-40px,0)" }}
							enter={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
						>
							{(item) => (styleProps) =>
								(
									<p style={{ ...styleProps, marginTop: "50px" }}>
										<span style={styles.highlightText}>Master Tour Crew </span>
										<span style={styles.standardText}>and More!</span>
									</p>
								)}
						</Transition>
						<div
							style={size === "mobile" ? styles.peopleContainerBottom : styles.peopleContainerTop}
						>
							<Transition from={{ opacity: 1 }} enter={{ opacity: 0 }} config={{ duration: 1000 }}>
								{(item) => (props) => <ThankYouDuo color="white" size={size} {...props} />}
							</Transition>
						</div>
						<div
							style={size === "mobile" ? styles.peopleContainerBottom : styles.peopleContainerTop}
						>
							<Transition from={{ opacity: 0 }} enter={{ opacity: 1 }} config={{ duration: 1000 }}>
								{(item) => (props) => <ThankYouTrio color="white" size={size} {...props} />}
							</Transition>
						</div>
					</div>
				)}
				<div style={styles.mobilePhoneContainer}>
					<MobilePhone color="white" size={size} left={size === "desktop" ? "5%" : "18%"} />
				</div>
			</Layout>
		</>
	)
}

const styles = {
	fullText: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		marginTop: "7.5%",
		width: "110%",
	},
	blurb: {
		textAlign: "left",
		display: "flex",
		flexDirection: "column",
		marginTop: "1em",
	},
	listItems: {
		marginLeft: "1em",
	},
	highlightText: {
		color: "#FF5151",
		fontWeight: "800",
	},
	standardText: {
		fontWeight: "400",
	},
	titleText: {
		fontWeight: "700",
		fontSize: "2em",
	},
	mobilePhoneContainer: {
		position: "absolute",
		bottom: "10%",
	},
	peopleContainerTop: {
		position: "absolute",
		top: "7.5%",
		right: "5%",
	},
	peopleContainerBottom: {
		position: "absolute",
		bottom: "10%",
		right: "18%",
	},
}

export default ComingSoon
