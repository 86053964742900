import React from "react"

const TicketIcon = (props) => (
	<svg width={props.size} height={props.size} strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 18 18">
		<path fill="#333" d="M4.41092194,2.24560765 L4.16640963,2.49011995 L4.30889703,2.80519118 C4.37464796,2.95058103 4.40909091,3.10875119 4.40909091,3.27272727 C4.40909091,3.90032358 3.90032358,4.40909091 3.27272727,4.40909091 C3.10875119,4.40909091 2.95058103,4.37464796 2.80519118,4.30889703 L2.49011995,4.16640963 L2.24560765,4.41092194 L0.464628428,6.19190115 L0.111075037,6.54545455 L0.464628428,6.89900794 L11.1009921,17.5353716 L11.4545455,17.888925 L11.8080988,17.5353716 L13.5890781,15.7543924 L13.8335904,15.50988 L13.691103,15.1948088 C13.625352,15.049419 13.5909091,14.8912488 13.5909091,14.7272727 C13.5909091,14.0996764 14.0996764,13.5909091 14.7272727,13.5909091 C14.8912488,13.5909091 15.049419,13.625352 15.1948088,13.691103 L15.50988,13.8335904 L15.7543924,13.5890781 L17.5353716,11.8080988 L17.888925,11.4545455 L17.5353716,11.1009921 L6.89900794,0.464628428 L6.54545455,0.111075037 L6.19190115,0.464628428 L4.41092194,2.24560765 Z" />
		<path fill="#ccc" d="M4.55938713,7.84372517 L10.2866599,13.4482706 L10.640172,13.7942075 L10.989917,13.4444625 L13.4444625,10.989917 L13.7942075,10.640172 L13.4482706,10.2866599 L7.84372517,4.55938713 L7.49021347,4.1981343 L7.13281025,4.55553752 L4.55553752,7.13281025 L4.1981343,7.49021347 L4.55938713,7.84372517 Z" />
	</svg>
)

export default TicketIcon
