import React from "react"

const ThankYouDuo = (props) => {
	let width
	let height
	if (props.size === "mobile") {
		width = 84
		height = 56
	} else {
		width = 193
		height = 129
	}
	return (
		<svg width={width} height={height} viewBox="0 0 193 129" fill={props.color} style={props}>
			<path d="M132.184 65.0801C115.709 65.0801 102.311 50.5879 102.311 32.7461C102.242 15.1777 115.777 0.958984 132.184 0.958984C148.727 0.958984 162.125 15.041 162.125 32.6094C162.125 50.5195 148.727 65.0801 132.184 65.0801ZM52.9551 65.832C38.668 65.832 26.9785 53.1172 26.9785 37.3945C26.9102 22.1504 38.7363 9.50391 52.9551 9.50391C67.3789 9.50391 79 21.9453 79 37.3262C79 53.0488 67.3789 65.832 52.9551 65.832ZM132.184 53.2539C141.754 53.2539 149.752 44.1621 149.752 32.6094C149.752 21.3984 141.822 12.7852 132.184 12.7852C122.613 12.7852 114.615 21.4668 114.684 32.7461C114.684 44.2988 122.75 53.2539 132.184 53.2539ZM52.9551 54.2109C60.8164 54.2109 67.4473 46.7598 67.4473 37.3262C67.4473 28.3027 60.9531 21.125 52.9551 21.125C45.1621 21.125 38.5996 28.4395 38.5996 37.3945C38.5996 46.7598 45.2305 54.2109 52.9551 54.2109ZM15.3574 128.654C5.58203 128.654 0.660156 124.484 0.660156 116.418C0.660156 93.7227 24.0391 73.0098 52.9551 73.0098C63.6191 73.0098 74.4199 75.8809 83.1016 81.2812C79.4102 83.7422 76.5391 86.5449 74.2832 89.8262C68.3359 86.4766 60.6113 84.5625 52.9551 84.5625C31.2168 84.5625 12.7598 99.6016 12.7598 115.324C12.7598 116.555 13.3066 117.102 14.6055 117.102H62.7305C62.252 121.613 64.7812 126.535 68.4727 128.654H15.3574ZM89.5273 128.654C77.7695 128.654 72.0957 124.963 72.0957 116.965C72.0957 98.2344 95.4746 73.0781 132.184 73.0781C168.893 73.0781 192.271 98.2344 192.271 116.965C192.271 124.963 186.598 128.654 174.771 128.654H89.5273ZM87.2715 116.828H177.096C178.668 116.828 179.283 116.35 179.283 115.051C179.283 104.592 162.398 84.9043 132.184 84.9043C101.969 84.9043 85.0156 104.592 85.0156 115.051C85.0156 116.35 85.6309 116.828 87.2715 116.828Z" />
		</svg>
	)
}

export default ThankYouDuo
