import React from "react"
import { Transition } from "react-spring/renderprops"

import Layout from "../components/Layout"
import BusIcon from "../components/BusIcon"

const Tours = (props) => {
	props.addSection("Tours")
	let loaded = false
	const sectionIndex = props.sections.findIndex(section => section === "Tours")
	if (props.destinationIndex === sectionIndex) {
		loaded = true
	}
	const tourCount = props.totalTours
	const orgCount = props.totalOrganizations
	const countryCount = props.totalCountries
	const regionCount = props.totalStates
	return (
		<>
			<Layout>

			{loaded &&
				<>
					<div style={styles.iconContainer}>
						<BusIcon size={"100%"} color="#111" />
					</div>
					<Transition
						from={{ opacity: 0, transform: 'translate3d(0,-40px,0)' }}
						enter={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}
					>
						{item => props =>
							<p style={{...props, ...styles.textOne}}>
								<span>You belonged to </span>
								<span><b>{orgCount}</b></span>
								<span>{orgCount > 1 ? " organizations" : " organization"} and </span>
								<span><b>{tourCount}</b></span>
								<span>{tourCount > 1 ? " tours" : " tour"}</span>
								<span>{(countryCount < 2 && regionCount < 2) ? "." : ","}</span>
							</p>
						}
					</Transition>

					{ (countryCount > 1 || regionCount > 1) &&
						<Transition
							from={{ opacity: 0, transform: 'translate3d(0,-40px,0)' }}
							enter={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}
							delay={50}
						>
							{item => props =>
								<p style={{...props, ...styles.textTwo}}>
								<span>covering </span>
								{countryCount > 1 &&
									<>
										<span><b>{countryCount}</b></span>
										<span>{countryCount > 1 ? " countries" : ""}</span>
									</>
								}
								<span>{(countryCount > 1 && regionCount > 1) ? " and " : ""}</span>
								{regionCount > 1 &&
									<>
										<span><b>{regionCount}</b></span>
										<span>{regionCount > 1 ? " states/regions" : ""}</span>
									</>
								}
								<span>...</span>
								</p>
							}
						</Transition>
					}
				</>
			}

			</Layout>
		</>
	)
}

const styles = {
	iconContainer: {
		bottom: "10%",
		position: "absolute",
		left: "10%",
		width: "20%",
	},
	textOne: {
		fontSize: "1.6em",
	},
	textTwo: {
		fontSize: "1.6em",
		marginTop: 20,
	},
}

export default Tours
