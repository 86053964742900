import React from "react"
import { Transition } from "react-spring/renderprops"
import "./LpsOne.css"
import "./LpsTwo.css"

import Layout from "../components/Layout"

const LpsTwo = (props) => {
	props.addSection("LpsTwo")
	let loaded = false
	const sectionIndex = props.sections.findIndex((section) => section === "LpsTwo")
	if (props.destinationIndex === sectionIndex) {
		loaded = true
	}
	return (
		<>
			<Layout className="layoutStyles">
				{loaded && (
					<div className="lpsContainer" style={styles.container}>
						<Transition
							from={{ opacity: 0, transform: "translate3d(-40px,0,0)" }}
							enter={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
						>
							{(item) => (props) =>
								(
									<div style={{ ...props, ...styles.header }}>
										<p className="textLarge bold">Celebrating Last Year's Excellence!</p>
										<p className="textLarge bold contentSpacing">
											Recap of
											<span className="yellowColor"> Winners from Master Tour Awards 2023:</span>
										</p>
										<div className="contentSpacing flex center">
											<div className="tableSpacing flex textSmall">
												<div className="leftLps">
													<div className="lpsSection">
														<div className="redColor">
															Top Rehearsal / Pre-Production Space of All Time
														</div>
														<div>
															<ul className="list">
																<li className="firstItem">• Third Encore in Burbank, CA</li>
																<li className="secondItem">• Alex Moore and John Hoik</li>
															</ul>
														</div>
													</div>
													<div className="lpsSection">
														<div className="redColor">Top Hotel</div>
														<div>
															<ul className="list">
																<li className="firstItem">
																	• Le Parc at Melrose - West Hollywood Hotel{" "}
																</li>
															</ul>
														</div>
													</div>
													<div className="lpsSection">
														<div className="redColor">Top Airline</div>
														<div>
															<ul className="list">
																<li className="firstItem">• Delta Airlines</li>
															</ul>
														</div>
													</div>
													<div className="lpsSection">
														<div className="redColor">All-Time Master Tour User</div>
														<div>
															<ul className="list">
																<li className="firstItem">• Eric Frankhouser</li>
															</ul>
														</div>
													</div>
												</div>
												<div className="rightLps">
													<div className="lpsSection">
														<div className="redColor">Top Venue of the Year - 2022</div>
														<div>
															<ul className="list">
																<li className="firstItem">• Red Rocks Amphitheatre</li>
																<li className="secondItem">• Tad Bowman</li>
															</ul>
														</div>
													</div>
													<div className="lpsSection">
														<div className="redColor">Top International Venue - 2022</div>
														<div>
															<ul className="list">
																<li className="firstItem">• Paradiso in Amsterdam</li>
																<li className="secondItem">• Marian Emmen</li>
															</ul>
														</div>
													</div>
													<div className="lpsSection">
														<div className="redColor">Top Venue - All Time</div>
														<div>
															<ul className="list">
																<li className="firstItem">• Ryman Auditorium, Nashville, TN</li>
																<li className="secondItem">• Brian Trager</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								)}
						</Transition>
					</div>
				)}
			</Layout>
		</>
	)
}

const styles = {
	container: {
		borderRadius: 10,
		width: "100%",
	},
	header: {
		fontSize: "1.25em",
		marginBottom: "0.7em",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
}

export default LpsTwo
