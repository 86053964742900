import React from "react"
import ReactFullpage from "@fullpage/react-fullpage"
import Helmet from "react-helmet"
import moment from "moment"

import Intro from "./sections/Intro"
import Tenure from "./sections/Tenure"
import LpsOne from "./sections/LpsOne"
import LpsTwo from "./sections/LpsTwo"
import Friends from "./sections/Friends"
import TaggedSpots from "./sections/TaggedSpots"
import Tours from "./sections/Tours"
import Events from "./sections/Events"
import ScheduleItems from "./sections/ScheduleItems"
import Guests from "./sections/Guests"
import ThankYou from "./sections/ThankYou"
import ComingSoon from "./sections/ComingSoon"
import endOfYearData from "./data/end-of-year-data"
import "./App.css"

const App = (props) => {
	let userData = props.userData || global.userData || {}
	const path = window.location.pathname

	if (Object.keys(userData).length === 0 && path.length > 1) {
		const accountId = path.substring(path.indexOf("/") + 1)
		const data = endOfYearData.RECORDS.find((record) => record.id === accountId)
		if (data) {
			userData = data
		}
	}
	// adjust name for possessiveness
	let namePossessive = userData.name?.split(" ")[0]
	if (namePossessive) {
		if (namePossessive.split("")[namePossessive.length - 1] === "s") {
			namePossessive += "'"
		} else {
			namePossessive += "'s"
		}
	} else {
		namePossessive = "Eventric's"
	}

	let titleContent = `${namePossessive} 2023 Master Tour Summary`
	const contentContent = `View ${namePossessive} year end touring statistics.`
	const imageUrl = `https://2023.eventric.com/summ/${userData.id || "default"}.png`
	const siteUrl = `https://2023.eventric.com/${userData.id ? userData.id : ""}`
	let sections = []

	const addSection = (sectionName) => {
		if (sections.indexOf(sectionName) === -1) {
			sections.push(sectionName)
		}
	}

	return (
		<ReactFullpage
			licenseKey="A9734E7E-26A04569-B0DE8BF0-0D1D4074"
			/* Options here */
			navigation={true}
			afterLoad={() => {}}
			render={({ state, fullpageApi }) => {
				const language = (typeof navigator === "object" && navigator?.language) || "en-US"
				const destinationIndex = state?.destination?.index
				const showEvents = userData?.eventPercent >= 0.16
				const showSchedule = userData?.schedulePercent >= 0.1
				const showGuests = userData?.guestPercent >= 0.35
				const showTenure =
					moment.utc(userData?.createdDate).isValid() &&
					!moment.utc(userData?.createdDate).isSame(moment.utc("2023-01-01"), "year")
				return (
					<ReactFullpage.Wrapper>
						<Helmet>
							<title>{titleContent}</title>
							<meta property="fb:app_id" content="707495109658396" />
							<meta property="og:site_name" content="Master Tour" />
							<meta property="og:type" content="website" />
							<meta property="og:title" content={titleContent} />
							<meta property="og:description" content={contentContent} />
							<meta property="og:image" content={imageUrl} />
							<meta property="og:image:width" content={2400} />
							<meta property="og:image:height" content={1260} />
							<meta property="og:url" content={siteUrl} />
							<meta property="twitter:title" content={titleContent} />
							<meta property="twitter:description" content={contentContent} />
							<meta property="twitter:image" content={imageUrl} />
							<meta name="twitter:image:alt" content={contentContent} />
							<meta property="twitter:card" content="summary_large_image" />
							<meta property="twitter:site" content="@eventric" />
							<meta property="twitter:url" content={siteUrl} />
						</Helmet>
						<div className="App">
							<div className="section one">
								<Intro name={namePossessive} fullpageApi={fullpageApi} addSection={addSection} />
							</div>

							{userData.id && showTenure && (
								<div className="section two">
									<Tenure
										createdDate={userData.createdDate}
										destinationIndex={destinationIndex}
										addSection={addSection}
										sections={sections}
									/>
								</div>
							)}

							{userData.id && (
								<>
									<div className="section eight">
										<Tours
											totalTours={userData?.totalTours?.toLocaleString(language)}
											totalOrganizations={userData?.totalOrganizations?.toLocaleString(language)}
											totalCountries={userData?.totalCountries?.toLocaleString(language)}
											totalStates={userData?.totalStates?.toLocaleString(language)}
											destinationIndex={destinationIndex}
											addSection={addSection}
											sections={sections}
										/>
									</div>

									{showEvents && (
										<div className="section nine">
											<Events
												totalEvents={userData?.totalEvents?.toLocaleString(language)}
												eventPercent={userData.eventPercent}
												destinationIndex={destinationIndex}
												addSection={addSection}
												sections={sections}
											/>
										</div>
									)}
									{showSchedule && (
										<div className="section ten">
											<ScheduleItems
												totalScheduleItems={userData.totalScheduleItems.toLocaleString(language)}
												destinationIndex={destinationIndex}
												addSection={addSection}
												sections={sections}
											/>
										</div>
									)}
									{showGuests && (
										<div className="section eleven">
											<Guests
												totalGuests={userData.totalGuests.toLocaleString(language)}
												mostGuestDate={userData.mostGuestDate}
												mostGuestName={userData.mostGuestName}
												mostGuestCity={userData.mostGuestCity}
												mostGuestAmount={userData.mostGuestAmount.toLocaleString(language)}
												destinationIndex={destinationIndex}
												addSection={addSection}
												sections={sections}
											/>
										</div>
									)}
								</>
							)}

							<div className="section six">
								<Friends
									destinationIndex={destinationIndex}
									addSection={addSection}
									sections={sections}
								/>
							</div>

							<div className="section seven">
								<TaggedSpots
									destinationIndex={destinationIndex}
									addSection={addSection}
									sections={sections}
								/>
							</div>

							<div className="section thirteen">
								<LpsOne
									destinationIndex={destinationIndex}
									addSection={addSection}
									sections={sections}
								/>
							</div>
							<div className="section thirteen">
								<LpsTwo
									destinationIndex={destinationIndex}
									addSection={addSection}
									sections={sections}
								/>
							</div>

							<div className="section twelve">
								<ComingSoon
									shareUrl={siteUrl}
									titleContent={titleContent}
									accountHash={userData.id}
									destinationIndex={destinationIndex}
									addSection={addSection}
									sections={sections}
								/>
							</div>

							<div className="section fourteen">
								<ThankYou
									shareUrl={siteUrl}
									titleContent={titleContent}
									accountHash={userData.id}
									destinationIndex={destinationIndex}
									addSection={addSection}
									sections={sections}
								/>
							</div>
						</div>
					</ReactFullpage.Wrapper>
				)
			}}
		/>
	)
}

export default App
