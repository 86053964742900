import React from "react"

const ThankYouTrio = (props) => {
	let width
	let height
	if (props.size === "mobile") {
		width = 112
		height = 54
	} else {
		width = 259
		height = 125
	}
	return (
		<svg width={width} height={height} viewBox="0 0 259 125" fill={props.color} style={props}>
			<path d="M129.568 60.8672C114.598 60.8672 102.02 47.5371 102.02 30.584C101.951 13.9043 114.734 0.847656 129.568 0.847656C144.402 0.847656 157.117 13.6309 157.117 30.4473C157.117 47.5371 144.471 60.8672 129.568 60.8672ZM50.4766 62.3711C37.4883 62.3711 26.4824 50.75 26.4824 36.0527C26.4141 21.4922 37.5566 10.1445 50.4766 10.1445C63.3965 10.1445 74.4707 21.2871 74.4707 35.916C74.4707 50.75 63.4648 62.3711 50.4766 62.3711ZM208.455 62.3711C195.535 62.3711 184.461 50.75 184.461 35.916C184.461 21.2871 195.604 10.1445 208.455 10.1445C221.375 10.1445 232.518 21.4922 232.518 36.0527C232.518 50.75 221.443 62.3711 208.455 62.3711ZM12.8105 124.441C3.58203 124.441 0.164062 120.613 0.164062 113.846C0.164062 95.3203 19.9199 72.7617 50.4766 72.7617C62.7812 72.7617 72.625 76.3848 80.1445 81.2383C64.7637 93.2695 56.7656 113.982 64.0117 124.441H12.8105ZM246.189 124.441H194.92C202.234 113.982 194.236 93.2695 178.855 81.2383C186.375 76.3848 196.15 72.7617 208.523 72.7617C239.08 72.7617 258.768 95.3203 258.768 113.846C258.768 120.613 255.35 124.441 246.189 124.441ZM86.9121 124.373C75.7012 124.373 71.8047 121.023 71.8047 114.734C71.8047 97.166 94.0898 72.9668 129.5 72.9668C164.979 72.9668 187.264 97.166 187.264 114.734C187.264 121.023 183.367 124.373 172.156 124.373H86.9121Z" />
		</svg>
	)
}

export default ThankYouTrio
