import React from "react"

const MobilePhone = (props) => {
	let width
	let height
	if (props.size === "mobile") {
		width = 41
		height = 65
	} else {
		width = 100
		height = 162
	}
	return (
		<svg width={width} height={height} viewBox="0 0 100 162" fill={props.color}>
			<path d="M21.0947 161.173C8.49707 161.173 0.000976562 153.043 0.000976562 140.958V21.1338C0.000976562 9.04883 8.49707 0.918945 21.0947 0.918945H78.6631C91.1143 0.918945 99.3906 9.04883 99.3906 21.1338V140.958C99.3906 153.043 91.1143 161.173 78.6631 161.173H21.0947ZM23.5117 146.451H75.9531C81.7393 146.451 85.1084 143.229 85.1084 137.662V24.4297C85.1084 18.8633 81.7393 15.7139 75.9531 15.7139H23.5117C17.7256 15.7139 14.3564 18.8633 14.3564 24.4297V137.662C14.3564 143.229 17.7256 146.451 23.5117 146.451ZM40.5771 32.9258C37.3545 32.9258 34.8643 30.4355 34.8643 27.2129C34.8643 24.0635 37.3545 21.6465 40.5771 21.6465H58.9609C62.1104 21.6465 64.6006 24.0635 64.6006 27.2129C64.6006 30.4355 62.1104 32.9258 58.9609 32.9258H40.5771ZM33.9854 140.665C31.8613 140.665 30.25 139.2 30.25 137.003C30.25 134.732 31.8613 133.268 33.9854 133.268H65.5527C67.6768 133.268 69.2148 134.732 69.2148 137.003C69.2148 139.2 67.6768 140.665 65.5527 140.665H33.9854Z" />
		</svg>
	)
}

export default MobilePhone
