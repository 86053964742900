import React, { useState, useEffect } from "react"
import moment from "moment"
import countdown from "countdown"
import { Transition } from "react-spring/renderprops"

import Layout from "../components/Layout"

const Tenure = (props) => {
	props.addSection("Tenure")
	let loaded = false
	const sectionIndex = props.sections.findIndex(section => section === "Tenure")
	if (props.destinationIndex === sectionIndex) {
		loaded = true
	}
	const startDate = moment.utc(props.createdDate, "YYYY-MM-DD HH:mm:ss")
	const updateCountdown = () => {
		setUserCountdown(countdown(startDate, null).toString())
	}
	const [userCountdown, setUserCountdown] = useState(typeof countdown === "function" && countdown(startDate, null).toString())
	useEffect(() => {
		loaded && setTimeout(updateCountdown, 1000)
	})

	return (
		<>
			<Layout>

			{loaded &&
				<>
					<Transition
						from={{ opacity: 0, transform: 'translate3d(0,-40px,0)' }}
						enter={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}
					>
						{item => styleProps =>
							<p style={{...styleProps, ...styles.text}}>You've been with us since <b>{startDate.format("MMMM Do, YYYY")}</b>...</p>
						}
					</Transition>

					<Transition
						from={{ opacity: 0, transform: 'translate3d(0,-40px,0)' }}
						enter={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}
						delay={50}
					>
						{item => styleProps =>
							<div style={{...styleProps, ...styles.countdown}}>
								<p>That's {userCountdown}!</p>
							</div>
						}
					</Transition>
					<Transition
						from={{ opacity: 0, transform: 'translate3d(0,-40px,0)' }}
						enter={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}
						delay={100}
					>
						{item => styleProps =>
							<div style={{...styleProps, ...styles.countdown}}>
								<p><b>Thank You!</b></p>
							</div>
						}
					</Transition>
				</>
			}

			</Layout>
		</>
	)
}

const styles ={
	countdown: {
		marginTop: "1.5em",
	},
	text: {
		fontSize: "1.3em",
	}
}

export default Tenure
