import React from "react"

const Intro = (props) => {
	props.addSection("Intro")
	return (
		<>
			<div style={styles.logo}>
				<img
					src="https://cdn-eoy.eventric.com/img/logo-mt.png"
					srcSet={`https://cdn-eoy.eventric.com/img/logo-mt@2x.png 2x, https://cdn-eoy.eventric.com/img/logo-mt@3x.png 3x`}
					alt="Master Tour logo"
				/>
			</div>

			<h1 style={styles.header}>Happy New Year!</h1>

			<p style={styles.text}>
				<b>{props.name}</b> 2023 Master Tour Recap
			</p>

			<div style={styles.arrow} onClick={() => props.fullpageApi.moveSectionDown()}>
				<svg className="arrow" strokeWidth="3" width="48px" height="20px" viewBox="0 0 48 20">
					<polyline fill="none" points="0 1 21.2864932 16.6020319 24.5581395 19 48 1" />
				</svg>
			</div>
		</>
	)
}

const styles = {
	arrow: {
		bottom: "0.7em",
		cursor: "pointer",
		margin: "0 auto",
		position: "absolute",
		stroke: "#ddd",
		width: "calc(100vw - 100px)",
	},
	header: {
		fontSize: "1.5em",
		margin: "0.5em 0 0.6em",
	},
	logo: {
		margin: "0 auto",
		maxWidth: "200px",
		width: "22vw",
	},
	text: {
		fontSize: "1.2em",
	},
}

export default Intro
