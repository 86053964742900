import React from "react"
import ClockIcon from "../components/ClockIcon"
import { Transition } from "react-spring/renderprops"

import Layout from "../components/Layout"

const ScheduleItems = (props) => {
	props.addSection("ScheduleItems")
	let loaded = false
	const sectionIndex = props.sections.findIndex(section => section === "ScheduleItems")
	if (props.destinationIndex === sectionIndex) {
		loaded = true
	}
	const totalItems = props.totalScheduleItems
	return (
		<>
			<div style={styles.clockContainer}>
				<ClockIcon size={"45vh"} color={"#333"} />
			</div>
			<Layout>
				{loaded &&
					<>
						<Transition
							from={{ opacity: 0, transform: 'translate3d(0,-40px,0)' }}
							enter={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}
						>
							{item => props =>
								<p style={{...props, ...styles.textOne}}>There were <b>{totalItems}</b> items scheduled...</p>
							}
						</Transition>

						<Transition
							from={{ opacity: 0, transform: 'translate3d(0,-40px,0)' }}
							enter={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}
							delay={50}
						>
							{item => props =>
								<p style={{...props, ...styles.textTwo}}>How many people were late to call time?</p>
							}
						</Transition>
					</>
				}

			</Layout>
		</>
	)
}

const styles = {
	clockContainer: {
		bottom: -50,
		position: "absolute",
		left: -50,
	},
	textOne: {
		fontSize: "1.5em",
		marginBottom: 20,
	},
	textTwo: {
		fontSize: "1.2em",
	},
}

export default ScheduleItems
