module.exports = {
	RECORDS: [
	{
		"id": "4198b2c",
		"email": "hmkraker@gmail.com",
		"name": "Hannah",
		"createdDate": "2018-07-16 19:04:54",
		"totalOrganizations": 7,
		"totalTours": 15,
		"totalDays": 801,
		"totalCountries": 7,
		"totalStates": 57,
		"totalEvents": 259,
		"eventPercent": 0.884783,
		"totalScheduleItems": 2826,
		"schedulePercent": 0.835386,
		"totalGuests": 1168,
		"guestPercent": 0.852602,
		"mostGuestDate": "2023-04-29 00:00:00",
		"mostGuestName": "Saenger Theatre",
		"mostGuestCity": "New Orleans",
		"mostGuestAmount": 130
	},
	{
		"id": "aed483d",
		"email": "adam.me.knowles@gmail.com",
		"name": "Adam",
		"createdDate": "2021-12-10 17:16:02",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 2051,
		"totalCountries": 10,
		"totalStates": 61,
		"totalEvents": 417,
		"eventPercent": 0.961727,
		"totalScheduleItems": 8242,
		"schedulePercent": 0.994438,
		"totalGuests": 2282,
		"guestPercent": 0.946762,
		"mostGuestDate": "2023-05-28 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 121
	},
	{
		"id": "fb9cae5",
		"email": "info@theloopteam.com",
		"name": "Sarah",
		"createdDate": "2019-02-07 11:41:12",
		"totalOrganizations": 13,
		"totalTours": 11,
		"totalDays": 183,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 1155,
		"schedulePercent": 0.528142,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ac2d452",
		"email": "Clozeetm@gmail.com",
		"name": "Austin",
		"createdDate": "2019-04-18 04:05:58",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 249,
		"totalCountries": 3,
		"totalStates": 32,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 1805,
		"schedulePercent": 0.686929,
		"totalGuests": 1098,
		"guestPercent": 0.839359,
		"mostGuestDate": "2023-10-12 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 90
	},
	{
		"id": "b08d271",
		"email": "tops@theteammusic.com",
		"name": "Tops",
		"createdDate": "2017-04-13 13:40:37",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 638,
		"totalCountries": 6,
		"totalStates": 19,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 179,
		"schedulePercent": 0.137598,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "03d4f1d",
		"email": "joseph@rawas.co.uk",
		"name": "Joe",
		"createdDate": "2018-09-14 13:51:12",
		"totalOrganizations": 12,
		"totalTours": 25,
		"totalDays": 852,
		"totalCountries": 19,
		"totalStates": 86,
		"totalEvents": 324,
		"eventPercent": 0.927692,
		"totalScheduleItems": 3845,
		"schedulePercent": 0.914978,
		"totalGuests": 1660,
		"guestPercent": 0.908356,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "Manchester Academy",
		"mostGuestCity": "Manchester",
		"mostGuestAmount": 55
	},
	{
		"id": "97179a9",
		"email": "tombegleytours@gmail.com",
		"name": "Tom",
		"createdDate": "2014-07-17 10:22:05",
		"totalOrganizations": 18,
		"totalTours": 19,
		"totalDays": 354,
		"totalCountries": 18,
		"totalStates": 49,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 2214,
		"schedulePercent": 0.752483,
		"totalGuests": 1000,
		"guestPercent": 0.823864,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Alexandra Palace Theatre",
		"mostGuestCity": "London",
		"mostGuestAmount": 123
	},
	{
		"id": "811cc25",
		"email": "maarten.cobbaut@lobbycall.com",
		"name": "Maarten",
		"createdDate": "2019-01-03 17:53:53",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 313,
		"totalCountries": 16,
		"totalStates": 54,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 3879,
		"schedulePercent": 0.917097,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bddad04",
		"email": "ericlafond4@gmail.com",
		"name": "Eric",
		"createdDate": "2022-10-17 18:02:39",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 217,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 198,
		"eventPercent": 0.802543,
		"totalScheduleItems": 1062,
		"schedulePercent": 0.501258,
		"totalGuests": 3211,
		"guestPercent": 0.973249,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "ThÃ©Ã¢tre St-Denis",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 204
	},
	{
		"id": "f1b8a36",
		"email": "katie@publicemily.net",
		"name": "Katie",
		"createdDate": "2011-01-25 02:02:42",
		"totalOrganizations": 21,
		"totalTours": 29,
		"totalDays": 851,
		"totalCountries": 3,
		"totalStates": 37,
		"totalEvents": 201,
		"eventPercent": 0.808237,
		"totalScheduleItems": 1999,
		"schedulePercent": 0.718315,
		"totalGuests": 1307,
		"guestPercent": 0.8726,
		"mostGuestDate": "2023-01-20 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 50
	},
	{
		"id": "fd72f0f",
		"email": "LBTtourasst@gmail.com",
		"name": "LBT",
		"createdDate": "2021-05-25 01:07:43",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 449,
		"totalCountries": 3,
		"totalStates": 22,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 1591,
		"schedulePercent": 0.63965,
		"totalGuests": 987,
		"guestPercent": 0.820554,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Nissan Stadium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 90
	},
	{
		"id": "a1f3723",
		"email": "soundladyrk@gmail.com",
		"name": "Rena ",
		"createdDate": "2016-12-28 20:05:48",
		"totalOrganizations": 17,
		"totalTours": 20,
		"totalDays": 262,
		"totalCountries": 7,
		"totalStates": 40,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 1088,
		"schedulePercent": 0.509469,
		"totalGuests": 130,
		"guestPercent": 0.498874,
		"mostGuestDate": "2023-05-21 00:00:00",
		"mostGuestName": "Merriweather Post Pavilion",
		"mostGuestCity": "Columbia",
		"mostGuestAmount": 24
	},
	{
		"id": "9fb8f32",
		"email": "zanhunt@me.com",
		"name": "Suzanne",
		"createdDate": "2012-06-13 16:51:20",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 231,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 601,
		"schedulePercent": 0.341544,
		"totalGuests": 412,
		"guestPercent": 0.658588,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 63
	},
	{
		"id": "88aa209",
		"email": "Avery@rischgrp.com",
		"name": "Avery",
		"createdDate": "2016-03-21 05:24:06",
		"totalOrganizations": 39,
		"totalTours": 28,
		"totalDays": 748,
		"totalCountries": 17,
		"totalStates": 62,
		"totalEvents": 256,
		"eventPercent": 0.882135,
		"totalScheduleItems": 2748,
		"schedulePercent": 0.825586,
		"totalGuests": 826,
		"guestPercent": 0.782678,
		"mostGuestDate": "2023-08-29 00:00:00",
		"mostGuestName": "The Bowery Ballroom",
		"mostGuestCity": "New York",
		"mostGuestAmount": 76
	},
	{
		"id": "4754b00",
		"email": "emiliemse@gmail.com",
		"name": "Emilie",
		"createdDate": "2021-07-18 02:15:35",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 122,
		"totalCountries": 5,
		"totalStates": 34,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 923,
		"schedulePercent": 0.457688,
		"totalGuests": 581,
		"guestPercent": 0.719243,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Marathon Music Works",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 57
	},
	{
		"id": "8d68460",
		"email": "gabriella.leigh.pulley@gmail.com",
		"name": "Gabriella",
		"createdDate": "2016-10-19 16:17:58",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 645,
		"totalCountries": 7,
		"totalStates": 37,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 3472,
		"schedulePercent": 0.8922,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0ccd8ca",
		"email": "bill.lowery@pm1pro.com",
		"name": "William",
		"createdDate": "2023-09-13 15:37:23",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 86,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 406,
		"schedulePercent": 0.259436,
		"totalGuests": 111,
		"guestPercent": 0.483115,
		"mostGuestDate": "2023-12-03 00:00:00",
		"mostGuestName": "Broward Center for the Performing Arts Stage Door",
		"mostGuestCity": "Fort Lauderdale",
		"mostGuestAmount": 32
	},
	{
		"id": "ef180df",
		"email": "smcfet1@hotmail.co.uk",
		"name": "Sean",
		"createdDate": "2018-02-26 10:15:00",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 306,
		"totalCountries": 6,
		"totalStates": 37,
		"totalEvents": 166,
		"eventPercent": 0.74308,
		"totalScheduleItems": 1698,
		"schedulePercent": 0.664415,
		"totalGuests": 1819,
		"guestPercent": 0.922262,
		"mostGuestDate": "2023-06-26 00:00:00",
		"mostGuestName": "Blackpool Opera House",
		"mostGuestCity": "Blackpool",
		"mostGuestAmount": 84
	},
	{
		"id": "c5b36ba",
		"email": "jordan.canales4@gmail.com",
		"name": "Jordan",
		"createdDate": "2021-09-04 16:48:20",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 501,
		"totalCountries": 3,
		"totalStates": 35,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 1175,
		"schedulePercent": 0.533174,
		"totalGuests": 181,
		"guestPercent": 0.537147,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 60
	},
	{
		"id": "f5ac78a",
		"email": "e.vassallo@tridentmusic.it",
		"name": "Elisabetta",
		"createdDate": "2023-02-06 11:06:50",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 74,
		"totalCountries": 1,
		"totalStates": 23,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 97,
		"schedulePercent": 0.0875381,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "004f5c7",
		"email": "thomas@lastationservice.org",
		"name": "Thomas",
		"createdDate": "2018-07-25 08:31:26",
		"totalOrganizations": 18,
		"totalTours": 14,
		"totalDays": 183,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 752,
		"schedulePercent": 0.402861,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "afb628b",
		"email": "ahaynes75@gmail.com",
		"name": "Adam",
		"createdDate": "2017-03-23 10:18:16",
		"totalOrganizations": 22,
		"totalTours": 46,
		"totalDays": 1207,
		"totalCountries": 15,
		"totalStates": 65,
		"totalEvents": 408,
		"eventPercent": 0.959078,
		"totalScheduleItems": 4139,
		"schedulePercent": 0.930208,
		"totalGuests": 248,
		"guestPercent": 0.577804,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "Elsewhere",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 21
	},
	{
		"id": "fa0e95e",
		"email": "firebirdindustries@gmail.com",
		"name": "David",
		"createdDate": "2023-01-13 17:37:59",
		"totalOrganizations": 5,
		"totalTours": 11,
		"totalDays": 233,
		"totalCountries": 24,
		"totalStates": 62,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 1933,
		"schedulePercent": 0.707721,
		"totalGuests": 596,
		"guestPercent": 0.725202,
		"mostGuestDate": "2023-04-02 00:00:00",
		"mostGuestName": "Slaktkyrkan",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 42
	},
	{
		"id": "f0b73f4",
		"email": "dtmason5@icloud.com",
		"name": "David",
		"createdDate": "2022-11-13 21:19:21",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 143,
		"totalCountries": 1,
		"totalStates": 29,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 529,
		"schedulePercent": 0.312409,
		"totalGuests": 67,
		"guestPercent": 0.443253,
		"mostGuestDate": "2023-06-28 00:00:00",
		"mostGuestName": "MGM Music Hall at Fenway",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 26
	},
	{
		"id": "745ba0e",
		"email": "joe@arcademgmt.com",
		"name": "Joe",
		"createdDate": "2023-10-06 14:00:44",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 81,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e21023c",
		"email": "jazz@eastsidebowl.com",
		"name": "Brandon",
		"createdDate": "2021-06-26 07:16:18",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 108,
		"totalCountries": 11,
		"totalStates": 22,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 452,
		"schedulePercent": 0.277447,
		"totalGuests": 143,
		"guestPercent": 0.508277,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Pepsi Center WTC",
		"mostGuestCity": "Benito Juarez",
		"mostGuestAmount": 31
	},
	{
		"id": "ff20009",
		"email": "christine.kane@redlightmanagement.com",
		"name": "Christine",
		"createdDate": "2012-07-01 22:35:29",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 257,
		"totalCountries": 6,
		"totalStates": 35,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 1189,
		"schedulePercent": 0.537015,
		"totalGuests": 38,
		"guestPercent": 0.402198,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "Big Night Live",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 11
	},
	{
		"id": "2513d1b",
		"email": "fionamarbooth@gmail.com",
		"name": "Fiona Marie",
		"createdDate": "2022-06-19 17:51:10",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 501,
		"totalCountries": 5,
		"totalStates": 35,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 1275,
		"schedulePercent": 0.558337,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a397b8b",
		"email": "info@marchfourthmarchingband.com",
		"name": "M4",
		"createdDate": "2012-11-27 00:43:38",
		"totalOrganizations": 1,
		"totalTours": 9,
		"totalDays": 55,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 214,
		"schedulePercent": 0.159184,
		"totalGuests": 148,
		"guestPercent": 0.51225,
		"mostGuestDate": "2023-03-04 00:00:00",
		"mostGuestName": "Crystal Ballroom",
		"mostGuestCity": "Portland",
		"mostGuestAmount": 133
	},
	{
		"id": "49e5329",
		"email": "xstaticstudios@msn.com",
		"name": "Robert",
		"createdDate": "2013-01-20 00:14:54",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 690,
		"totalCountries": 10,
		"totalStates": 48,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 2565,
		"schedulePercent": 0.801218,
		"totalGuests": 44,
		"guestPercent": 0.411469,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Friends Field North",
		"mostGuestCity": "Mill Valley",
		"mostGuestAmount": 18
	},
	{
		"id": "d3d4003",
		"email": "eric@lakesideentertainmentgroup.com",
		"name": "Eric",
		"createdDate": "2022-10-10 23:02:41",
		"totalOrganizations": 10,
		"totalTours": 20,
		"totalDays": 712,
		"totalCountries": 9,
		"totalStates": 57,
		"totalEvents": 308,
		"eventPercent": 0.919746,
		"totalScheduleItems": 3878,
		"schedulePercent": 0.916965,
		"totalGuests": 354,
		"guestPercent": 0.632102,
		"mostGuestDate": "2023-03-21 00:00:00",
		"mostGuestName": "Baby's All Right",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 37
	},
	{
		"id": "3a8675d",
		"email": "graemo@gmail.com",
		"name": "Graeme",
		"createdDate": "2011-05-09 06:24:05",
		"totalOrganizations": 7,
		"totalTours": 17,
		"totalDays": 540,
		"totalCountries": 17,
		"totalStates": 54,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 1990,
		"schedulePercent": 0.716991,
		"totalGuests": 770,
		"guestPercent": 0.769699,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "Iveagh Gardens",
		"mostGuestCity": "Dublin 2",
		"mostGuestAmount": 56
	},
	{
		"id": "6a19918",
		"email": "ashleigh@unifiedmusicgroup.com",
		"name": "Ash",
		"createdDate": "2017-11-07 18:41:49",
		"totalOrganizations": 6,
		"totalTours": 13,
		"totalDays": 301,
		"totalCountries": 5,
		"totalStates": 16,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 1240,
		"schedulePercent": 0.549861,
		"totalGuests": 169,
		"guestPercent": 0.529466,
		"mostGuestDate": "2023-08-30 00:00:00",
		"mostGuestName": "Central Park SummerStage",
		"mostGuestCity": "New York",
		"mostGuestAmount": 66
	},
	{
		"id": "e57214a",
		"email": "samacrum@gmail.com",
		"name": "Samantha",
		"createdDate": "2016-05-07 23:55:17",
		"totalOrganizations": 8,
		"totalTours": 7,
		"totalDays": 521,
		"totalCountries": 5,
		"totalStates": 49,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 1939,
		"schedulePercent": 0.70931,
		"totalGuests": 500,
		"guestPercent": 0.691829,
		"mostGuestDate": "2023-11-27 00:00:00",
		"mostGuestName": "Jannus Live",
		"mostGuestCity": "St. Petersburg",
		"mostGuestAmount": 36
	},
	{
		"id": "30274d9",
		"email": "jacobjine@gmail.com",
		"name": "Jacob",
		"createdDate": "2019-10-23 18:57:22",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 106,
		"totalCountries": 8,
		"totalStates": 36,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 641,
		"schedulePercent": 0.359025,
		"totalGuests": 237,
		"guestPercent": 0.571712,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Cornerstone Craft Beer & Live Music",
		"mostGuestCity": "Berkeley",
		"mostGuestAmount": 32
	},
	{
		"id": "0b4388e",
		"email": "francinehough@outlook.com",
		"name": "Francine",
		"createdDate": "2020-03-25 10:53:28",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 237,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 1436,
		"schedulePercent": 0.600848,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e2eead7",
		"email": "doug@portlandcelloproject.com",
		"name": "Douglas",
		"createdDate": "2018-10-15 17:20:29",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 59,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 146,
		"schedulePercent": 0.117071,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "Weinberg Center for the Arts",
		"mostGuestCity": "Frederick",
		"mostGuestAmount": 2
	},
	{
		"id": "1bd8d06",
		"email": "erik@globalEproductions.com",
		"name": "Erik",
		"createdDate": "2010-07-16 19:29:01",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 369,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 1149,
		"schedulePercent": 0.526818,
		"totalGuests": 465,
		"guestPercent": 0.678718,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "Zappos Theater",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 40
	},
	{
		"id": "d66c197",
		"email": "keith@mobilex.media",
		"name": "Keith",
		"createdDate": "2022-09-06 22:53:27",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 62,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 596,
		"schedulePercent": 0.338763,
		"totalGuests": 361,
		"guestPercent": 0.635413,
		"mostGuestDate": "2023-01-13 00:00:00",
		"mostGuestName": "Palladium Times Square",
		"mostGuestCity": "New York",
		"mostGuestAmount": 58
	},
	{
		"id": "9fbbd96",
		"email": "Lou.Ramirez@wmg.com",
		"name": "Lou",
		"createdDate": "2013-10-24 06:27:59",
		"totalOrganizations": 53,
		"totalTours": 49,
		"totalDays": 15785,
		"totalCountries": 17,
		"totalStates": 89,
		"totalEvents": 2284,
		"eventPercent": 0.998676,
		"totalScheduleItems": 3330,
		"schedulePercent": 0.880678,
		"totalGuests": 66,
		"guestPercent": 0.441001,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Houston Rodeo WIll Call Guest List",
		"mostGuestCity": "",
		"mostGuestAmount": 57
	},
	{
		"id": "453dc0a",
		"email": "mido@bluntmedia.co.uk",
		"name": "Francois",
		"createdDate": "2012-06-29 11:56:19",
		"totalOrganizations": 17,
		"totalTours": 10,
		"totalDays": 255,
		"totalCountries": 7,
		"totalStates": 42,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 1489,
		"schedulePercent": 0.615415,
		"totalGuests": 432,
		"guestPercent": 0.66574,
		"mostGuestDate": "2023-10-10 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 47
	},
	{
		"id": "9db7fe8",
		"email": "nathan@caminotouring.com",
		"name": "Nathan",
		"createdDate": "2017-07-06 19:55:22",
		"totalOrganizations": 14,
		"totalTours": 15,
		"totalDays": 975,
		"totalCountries": 10,
		"totalStates": 65,
		"totalEvents": 280,
		"eventPercent": 0.901867,
		"totalScheduleItems": 4096,
		"schedulePercent": 0.927294,
		"totalGuests": 3098,
		"guestPercent": 0.970732,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "T-Mobile Arena",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 118
	},
	{
		"id": "b524c76",
		"email": "recordssimone@gmail.com",
		"name": "Charlotte",
		"createdDate": "2019-09-12 13:21:01",
		"totalOrganizations": 26,
		"totalTours": 25,
		"totalDays": 471,
		"totalCountries": 4,
		"totalStates": 20,
		"totalEvents": 384,
		"eventPercent": 0.953119,
		"totalScheduleItems": 1973,
		"schedulePercent": 0.714607,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3a33244",
		"email": "admin@dnrproduction.com",
		"name": "Alexia",
		"createdDate": "2021-08-10 00:16:32",
		"totalOrganizations": 13,
		"totalTours": 20,
		"totalDays": 1001,
		"totalCountries": 11,
		"totalStates": 49,
		"totalEvents": 221,
		"eventPercent": 0.83777,
		"totalScheduleItems": 3073,
		"schedulePercent": 0.858562,
		"totalGuests": 1113,
		"guestPercent": 0.841478,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "El Rey Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 83
	},
	{
		"id": "4857f35",
		"email": "davidwrmartin@gmail.com",
		"name": "David",
		"createdDate": "2022-03-04 20:51:50",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 227,
		"totalCountries": 4,
		"totalStates": 38,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 676,
		"schedulePercent": 0.370944,
		"totalGuests": 534,
		"guestPercent": 0.704278,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 40
	},
	{
		"id": "a3749b8",
		"email": "itzel@rollonent.com",
		"name": "Itzel",
		"createdDate": "2023-08-15 14:17:50",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 71,
		"totalCountries": 7,
		"totalStates": 17,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 672,
		"schedulePercent": 0.36962,
		"totalGuests": 242,
		"guestPercent": 0.574626,
		"mostGuestDate": "2023-10-03 00:00:00",
		"mostGuestName": "The Brooklyn Mirage",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 79
	},
	{
		"id": "feb8258",
		"email": "kalandramusic@gmail.com",
		"name": "KALANDRA",
		"createdDate": "2023-09-25 10:12:02",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 33,
		"totalCountries": 9,
		"totalStates": 14,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 320,
		"schedulePercent": 0.218514,
		"totalGuests": 34,
		"guestPercent": 0.395842,
		"mostGuestDate": "2023-11-20 00:00:00",
		"mostGuestName": "O'Sullivans- Backstage By The Mill",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 6
	},
	{
		"id": "0c93f3d",
		"email": "KelleyMoore33@gmail.com",
		"name": "Kelley",
		"createdDate": "2023-09-06 18:42:12",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 53,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 319,
		"schedulePercent": 0.218117,
		"totalGuests": 46,
		"guestPercent": 0.415044,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "Carson Center Paducah Ky",
		"mostGuestCity": "Paducah",
		"mostGuestAmount": 10
	},
	{
		"id": "c7a964d",
		"email": "luke.jrtm@gmail.com",
		"name": "Luke",
		"createdDate": "2022-12-06 15:23:00",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 434,
		"totalCountries": 2,
		"totalStates": 35,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 2789,
		"schedulePercent": 0.830618,
		"totalGuests": 1822,
		"guestPercent": 0.922527,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 78
	},
	{
		"id": "b932659",
		"email": "andre@urbanjungle.com.br",
		"name": "Andre",
		"createdDate": "2012-03-29 21:15:42",
		"totalOrganizations": 18,
		"totalTours": 6,
		"totalDays": 118,
		"totalCountries": 10,
		"totalStates": 22,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 315,
		"schedulePercent": 0.215733,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "Paleo Festival Nyon",
		"mostGuestCity": "Nyon",
		"mostGuestAmount": 7
	},
	{
		"id": "5c2f3b4",
		"email": "bartcrowtourmanager@gmail.com",
		"name": "Bart",
		"createdDate": "2013-04-28 19:31:13",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 367,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 226,
		"schedulePercent": 0.167925,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e01a0a6",
		"email": "andykent@iinet.net.au",
		"name": "Andy",
		"createdDate": "2010-02-12 10:16:12",
		"totalOrganizations": 9,
		"totalTours": 14,
		"totalDays": 277,
		"totalCountries": 3,
		"totalStates": 8,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 865,
		"schedulePercent": 0.441531,
		"totalGuests": 713,
		"guestPercent": 0.755794,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Sydney Opera House",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 83
	},
	{
		"id": "4bf20b8",
		"email": "mghenkel@gmail.com",
		"name": "Mark",
		"createdDate": "2013-09-12 19:50:05",
		"totalOrganizations": 11,
		"totalTours": 11,
		"totalDays": 247,
		"totalCountries": 10,
		"totalStates": 43,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 766,
		"schedulePercent": 0.407496,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-05-04 00:00:00",
		"mostGuestName": "Village Underground",
		"mostGuestCity": "London",
		"mostGuestAmount": 14
	},
	{
		"id": "0ba662f",
		"email": "Dougshale@gmail.com",
		"name": "Doug",
		"createdDate": "2018-12-10 21:51:12",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 436,
		"totalCountries": 9,
		"totalStates": 51,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 2584,
		"schedulePercent": 0.803602,
		"totalGuests": 629,
		"guestPercent": 0.733545,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Whitewater Amphitheater",
		"mostGuestCity": "New Braunfels",
		"mostGuestAmount": 38
	},
	{
		"id": "8a1c06e",
		"email": "lisatmlarson@gmail.com",
		"name": "Lisa",
		"createdDate": "2017-12-26 04:34:10",
		"totalOrganizations": 21,
		"totalTours": 34,
		"totalDays": 762,
		"totalCountries": 22,
		"totalStates": 84,
		"totalEvents": 344,
		"eventPercent": 0.939213,
		"totalScheduleItems": 3949,
		"schedulePercent": 0.92107,
		"totalGuests": 926,
		"guestPercent": 0.805589,
		"mostGuestDate": "2023-02-14 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 67
	},
	{
		"id": "1933ce2",
		"email": "bozzcp@gmail.com",
		"name": "CRAIG",
		"createdDate": "2013-05-29 23:44:14",
		"totalOrganizations": 10,
		"totalTours": 6,
		"totalDays": 348,
		"totalCountries": 12,
		"totalStates": 53,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 1773,
		"schedulePercent": 0.67938,
		"totalGuests": 743,
		"guestPercent": 0.76268,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "The Majestic Ventura Theater",
		"mostGuestCity": "Ventura",
		"mostGuestAmount": 43
	},
	{
		"id": "730e660",
		"email": "production@tidelinesband.com",
		"name": "Tide",
		"createdDate": "2019-08-29 18:18:01",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 284,
		"totalCountries": 9,
		"totalStates": 27,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 1212,
		"schedulePercent": 0.543239,
		"totalGuests": 381,
		"guestPercent": 0.645875,
		"mostGuestDate": "2023-05-08 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 29
	},
	{
		"id": "ad39e6b",
		"email": "kjbraams@gmail.com",
		"name": "K-J ",
		"createdDate": "2023-08-28 10:19:33",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 37,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 35,
		"schedulePercent": 0.043438,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "273d67e",
		"email": "sunshinerae4@yahoo.com",
		"name": "Sunshine",
		"createdDate": "2021-07-18 20:41:46",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 415,
		"totalCountries": 3,
		"totalStates": 31,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 1502,
		"schedulePercent": 0.618329,
		"totalGuests": 781,
		"guestPercent": 0.771951,
		"mostGuestDate": "2023-04-20 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 93
	},
	{
		"id": "adcd383",
		"email": "tim@thecoreent.com",
		"name": "Tim",
		"createdDate": "2022-01-10 23:45:15",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 318,
		"totalCountries": 6,
		"totalStates": 41,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1319,
		"schedulePercent": 0.570785,
		"totalGuests": 1144,
		"guestPercent": 0.848232,
		"mostGuestDate": "2023-04-16 00:00:00",
		"mostGuestName": "The Danforth Music Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 101
	},
	{
		"id": "5ae1f3f",
		"email": "info@blackinkpresents.com",
		"name": "Black",
		"createdDate": "2022-04-20 23:52:26",
		"totalOrganizations": 14,
		"totalTours": 25,
		"totalDays": 693,
		"totalCountries": 10,
		"totalStates": 55,
		"totalEvents": 285,
		"eventPercent": 0.905973,
		"totalScheduleItems": 2907,
		"schedulePercent": 0.842802,
		"totalGuests": 1120,
		"guestPercent": 0.8432,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "The Town Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 49
	},
	{
		"id": "baa64e9",
		"email": "joestaiber@gmail.com",
		"name": "Joseph",
		"createdDate": "2022-05-26 18:01:55",
		"totalOrganizations": 1,
		"totalTours": 8,
		"totalDays": 1561,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 254,
		"schedulePercent": 0.182625,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6e10366",
		"email": "fly@yachtrock.no",
		"name": "Adrian",
		"createdDate": "2020-01-02 21:27:56",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 28,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 13,
		"eventPercent": 0.0892597,
		"totalScheduleItems": 169,
		"schedulePercent": 0.131903,
		"totalGuests": 148,
		"guestPercent": 0.51225,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Sentrum Scene",
		"mostGuestCity": "Oslo",
		"mostGuestAmount": 69
	},
	{
		"id": "7355126",
		"email": "sam@wearefree.com",
		"name": "Samantha",
		"createdDate": "2019-07-01 23:01:18",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 264,
		"totalCountries": 20,
		"totalStates": 66,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 1510,
		"schedulePercent": 0.620315,
		"totalGuests": 346,
		"guestPercent": 0.626672,
		"mostGuestDate": "2023-05-29 00:00:00",
		"mostGuestName": "Powerstation",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 57
	},
	{
		"id": "c33ec01",
		"email": "staticstation@yahoo.com",
		"name": "Patrick",
		"createdDate": "2012-02-01 19:41:48",
		"totalOrganizations": 14,
		"totalTours": 19,
		"totalDays": 1067,
		"totalCountries": 18,
		"totalStates": 55,
		"totalEvents": 195,
		"eventPercent": 0.796318,
		"totalScheduleItems": 2683,
		"schedulePercent": 0.817508,
		"totalGuests": 243,
		"guestPercent": 0.574758,
		"mostGuestDate": "2023-09-26 00:00:00",
		"mostGuestName": "The Town Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 43
	},
	{
		"id": "1e72950",
		"email": "bookings@kalimbaband.com",
		"name": "Chris",
		"createdDate": "2022-04-06 00:50:09",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 216,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 351,
		"schedulePercent": 0.233611,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3251904",
		"email": "cmateer12@gmail.com",
		"name": "Chris",
		"createdDate": "2021-09-15 21:52:24",
		"totalOrganizations": 6,
		"totalTours": 13,
		"totalDays": 483,
		"totalCountries": 3,
		"totalStates": 42,
		"totalEvents": 249,
		"eventPercent": 0.870746,
		"totalScheduleItems": 2481,
		"schedulePercent": 0.790624,
		"totalGuests": 343,
		"guestPercent": 0.624421,
		"mostGuestDate": "2023-07-10 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 49
	},
	{
		"id": "13277be",
		"email": "shelby.kirshner@gmail.com",
		"name": "Shelby",
		"createdDate": "2017-07-12 21:58:37",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 462,
		"totalCountries": 6,
		"totalStates": 15,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 1048,
		"schedulePercent": 0.496358,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "88347b2",
		"email": "cianlawmoore@gmail.com",
		"name": "Cian",
		"createdDate": "2023-10-18 14:05:04",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 76,
		"totalCountries": 4,
		"totalStates": 12,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 331,
		"schedulePercent": 0.224871,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 15
	},
	{
		"id": "5e4be52",
		"email": "JSTNREECE@GMAIL.COM",
		"name": "Justin",
		"createdDate": "2018-04-09 23:23:01",
		"totalOrganizations": 6,
		"totalTours": 13,
		"totalDays": 146,
		"totalCountries": 5,
		"totalStates": 39,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 1533,
		"schedulePercent": 0.625612,
		"totalGuests": 947,
		"guestPercent": 0.811813,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 524
	},
	{
		"id": "aebed9e",
		"email": "westonmays@comcast.net",
		"name": "Weston",
		"createdDate": "2013-08-26 21:48:53",
		"totalOrganizations": 11,
		"totalTours": 12,
		"totalDays": 1401,
		"totalCountries": 12,
		"totalStates": 58,
		"totalEvents": 331,
		"eventPercent": 0.932327,
		"totalScheduleItems": 4904,
		"schedulePercent": 0.958549,
		"totalGuests": 4976,
		"guestPercent": 0.991657,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Fenway Park",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 322
	},
	{
		"id": "788da62",
		"email": "mmurphy850@mac.com",
		"name": "Matthew ",
		"createdDate": "2019-02-25 18:05:46",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 61,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 593,
		"schedulePercent": 0.337306,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8ad80ea",
		"email": "Jaska.myllarin@gmail.com",
		"name": "Jaska",
		"createdDate": "2019-07-23 02:53:32",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 338,
		"totalCountries": 7,
		"totalStates": 62,
		"totalEvents": 217,
		"eventPercent": 0.833267,
		"totalScheduleItems": 2752,
		"schedulePercent": 0.825983,
		"totalGuests": 2373,
		"guestPercent": 0.949676,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "1720",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 134
	},
	{
		"id": "b00ce1b",
		"email": "Simon.Cadiz@wwecorp.com",
		"name": "Simon",
		"createdDate": "2018-11-21 19:10:52",
		"totalOrganizations": 2,
		"totalTours": 77,
		"totalDays": 293,
		"totalCountries": 8,
		"totalStates": 55,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 2591,
		"schedulePercent": 0.804794,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "77de2d4",
		"email": "chasericemgmt@gmail.com",
		"name": "Nandor",
		"createdDate": "2013-02-05 18:02:47",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 632,
		"totalCountries": 5,
		"totalStates": 55,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 1992,
		"schedulePercent": 0.717256,
		"totalGuests": 308,
		"guestPercent": 0.608131,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "CFG Bank Arena",
		"mostGuestCity": "Baltimore",
		"mostGuestAmount": 42
	},
	{
		"id": "92c4ab1",
		"email": "brad@blueraincoatmusic.com",
		"name": "Brad ",
		"createdDate": "2023-02-05 20:26:51",
		"totalOrganizations": 5,
		"totalTours": 16,
		"totalDays": 269,
		"totalCountries": 13,
		"totalStates": 43,
		"totalEvents": 134,
		"eventPercent": 0.658721,
		"totalScheduleItems": 1619,
		"schedulePercent": 0.646934,
		"totalGuests": 1792,
		"guestPercent": 0.919746,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "Don Haskins Center",
		"mostGuestCity": "El Paso",
		"mostGuestAmount": 213
	},
	{
		"id": "e1101a9",
		"email": "julie@kartelmusik.com",
		"name": "Laurie",
		"createdDate": "2022-06-22 14:43:35",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 326,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 379,
		"schedulePercent": 0.248311,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "Maison des arts Desjardins Drummondville",
		"mostGuestCity": "Drummondville",
		"mostGuestAmount": 2
	},
	{
		"id": "6de1759",
		"email": "Ann@krall.se",
		"name": "Ann",
		"createdDate": "2015-02-13 13:38:08",
		"totalOrganizations": 10,
		"totalTours": 2,
		"totalDays": 203,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 1018,
		"schedulePercent": 0.490531,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "11f5843",
		"email": "verena.wilmes@apassionata.com",
		"name": "Verena",
		"createdDate": "2023-10-02 20:00:31",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 91,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 572,
		"schedulePercent": 0.328963,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7b0a7fe",
		"email": "onestepahead1@me.com",
		"name": "George",
		"createdDate": "2012-02-20 16:14:30",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 216,
		"totalCountries": 15,
		"totalStates": 40,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 1943,
		"schedulePercent": 0.710237,
		"totalGuests": 280,
		"guestPercent": 0.594623,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The Aztec Theatre",
		"mostGuestCity": "San Antonio",
		"mostGuestAmount": 70
	},
	{
		"id": "0f58e61",
		"email": "allison@foreigneronline.com",
		"name": "Allison",
		"createdDate": "2018-07-27 23:27:33",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 270,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 296,
		"schedulePercent": 0.205933,
		"totalGuests": 222,
		"guestPercent": 0.564561,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "The Venetian Theatre",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 40
	},
	{
		"id": "6a75c82",
		"email": "Mr.Chico@rocketmail.com",
		"name": "Craig",
		"createdDate": "2013-02-25 20:20:19",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 971,
		"totalCountries": 26,
		"totalStates": 74,
		"totalEvents": 194,
		"eventPercent": 0.794597,
		"totalScheduleItems": 1878,
		"schedulePercent": 0.700834,
		"totalGuests": 750,
		"guestPercent": 0.764402,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "The Majestic Ventura Theater",
		"mostGuestCity": "Ventura",
		"mostGuestAmount": 43
	},
	{
		"id": "4832921",
		"email": "bmarquis81@gmail.com",
		"name": "Brian",
		"createdDate": "2014-01-20 06:57:25",
		"totalOrganizations": 10,
		"totalTours": 17,
		"totalDays": 689,
		"totalCountries": 17,
		"totalStates": 58,
		"totalEvents": 228,
		"eventPercent": 0.845848,
		"totalScheduleItems": 2873,
		"schedulePercent": 0.839889,
		"totalGuests": 729,
		"guestPercent": 0.759502,
		"mostGuestDate": "2023-01-26 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 80
	},
	{
		"id": "4d042fc",
		"email": "booking@fromthetop.org",
		"name": "Amanda",
		"createdDate": "2022-07-29 18:06:54",
		"totalOrganizations": 1,
		"totalTours": 11,
		"totalDays": 56,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 473,
		"schedulePercent": 0.286187,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "86a2ea8",
		"email": "dvd.hale@gmail.com",
		"name": "Dave",
		"createdDate": "2019-03-11 16:54:19",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 324,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 221,
		"schedulePercent": 0.163952,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6736aec",
		"email": "admin@jrhentertainment.com",
		"name": "jennifer",
		"createdDate": "2019-01-28 20:18:37",
		"totalOrganizations": 8,
		"totalTours": 7,
		"totalDays": 322,
		"totalCountries": 8,
		"totalStates": 35,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 775,
		"schedulePercent": 0.411071,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b0a231e",
		"email": "Chris@pmmla.com",
		"name": "Chris",
		"createdDate": "2014-03-12 18:27:18",
		"totalOrganizations": 6,
		"totalTours": 12,
		"totalDays": 179,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1216,
		"schedulePercent": 0.544034,
		"totalGuests": 567,
		"guestPercent": 0.715402,
		"mostGuestDate": "2023-11-05 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 82
	},
	{
		"id": "97c19d8",
		"email": "caisa@commonfolkrecords.com",
		"name": "Caisa",
		"createdDate": "2018-09-20 08:08:32",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 215,
		"totalCountries": 17,
		"totalStates": 45,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 1758,
		"schedulePercent": 0.675805,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "eb56c35",
		"email": "connor@just-another.co",
		"name": "Email",
		"createdDate": "2022-06-08 09:17:13",
		"totalOrganizations": 10,
		"totalTours": 10,
		"totalDays": 181,
		"totalCountries": 11,
		"totalStates": 51,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 1222,
		"schedulePercent": 0.54602,
		"totalGuests": 101,
		"guestPercent": 0.474904,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Regent Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 27
	},
	{
		"id": "760f521",
		"email": "Amanda@littlerobotproductions.com",
		"name": "Amanda",
		"createdDate": "2023-01-13 21:25:52",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 277,
		"totalCountries": 1,
		"totalStates": 30,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 1167,
		"schedulePercent": 0.531453,
		"totalGuests": 1691,
		"guestPercent": 0.912065,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Nashville Municipal Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 111
	},
	{
		"id": "b98740e",
		"email": "scott@annerin.com",
		"name": "Scott",
		"createdDate": "2009-07-15 23:35:16",
		"totalOrganizations": 22,
		"totalTours": 4,
		"totalDays": 89,
		"totalCountries": 3,
		"totalStates": 9,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 589,
		"schedulePercent": 0.335055,
		"totalGuests": 185,
		"guestPercent": 0.539664,
		"mostGuestDate": "2023-03-16 00:00:00",
		"mostGuestName": "Cowichan PAC",
		"mostGuestCity": "Duncan",
		"mostGuestAmount": 24
	},
	{
		"id": "436a2a7",
		"email": "michael.cisterna@gmail.com",
		"name": "Michael",
		"createdDate": "2022-09-20 02:45:27",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 20,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 172,
		"schedulePercent": 0.133757,
		"totalGuests": 109,
		"guestPercent": 0.481393,
		"mostGuestDate": "2023-06-02 00:00:00",
		"mostGuestName": "The Peppermint Club",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 51
	},
	{
		"id": "8f9eeff",
		"email": "grace@mgplive.com",
		"name": "Grace",
		"createdDate": "2023-02-13 17:02:07",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 264,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 313,
		"schedulePercent": 0.214674,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "715aa0c",
		"email": "mik@dropdeaftouring.co.uk",
		"name": "MIk",
		"createdDate": "2023-04-19 14:00:54",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 290,
		"totalCountries": 14,
		"totalStates": 58,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 1795,
		"schedulePercent": 0.684545,
		"totalGuests": 407,
		"guestPercent": 0.656337,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "The Stone Pony Summer Stage",
		"mostGuestCity": "Asbury Park",
		"mostGuestAmount": 41
	},
	{
		"id": "eb9bb20",
		"email": "setlife.rachaelrobertson@gmail.com",
		"name": "Rachael",
		"createdDate": "2019-07-27 19:04:07",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 140,
		"totalCountries": 2,
		"totalStates": 35,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 1406,
		"schedulePercent": 0.592902,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-10-09 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 0
	},
	{
		"id": "90b406c",
		"email": "info@charleycrockett.com",
		"name": "Mandy",
		"createdDate": "2022-10-27 13:28:51",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 373,
		"totalCountries": 8,
		"totalStates": 46,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 1855,
		"schedulePercent": 0.695934,
		"totalGuests": 649,
		"guestPercent": 0.73924,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Whitewater Amphitheater",
		"mostGuestCity": "New Braunfels",
		"mostGuestAmount": 38
	},
	{
		"id": "06bfc55",
		"email": "nate@clbr.co",
		"name": "NATHAN",
		"createdDate": "2018-08-01 04:07:53",
		"totalOrganizations": 6,
		"totalTours": 11,
		"totalDays": 367,
		"totalCountries": 14,
		"totalStates": 41,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1197,
		"schedulePercent": 0.539531,
		"totalGuests": 98,
		"guestPercent": 0.472917,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "Elsewhere",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 21
	},
	{
		"id": "b69740e",
		"email": "elena@flysouthmusic.com",
		"name": "Elena",
		"createdDate": "2018-01-23 19:17:52",
		"totalOrganizations": 16,
		"totalTours": 32,
		"totalDays": 1363,
		"totalCountries": 23,
		"totalStates": 89,
		"totalEvents": 455,
		"eventPercent": 0.968481,
		"totalScheduleItems": 7886,
		"schedulePercent": 0.993378,
		"totalGuests": 2036,
		"guestPercent": 0.935505,
		"mostGuestDate": "2023-02-06 00:00:00",
		"mostGuestName": "Grand Ole Opry",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 131
	},
	{
		"id": "55eeaec",
		"email": "frank.wright0712@gmail.com",
		"name": "Frank",
		"createdDate": "2019-08-08 15:42:47",
		"totalOrganizations": 14,
		"totalTours": 18,
		"totalDays": 465,
		"totalCountries": 16,
		"totalStates": 43,
		"totalEvents": 174,
		"eventPercent": 0.759502,
		"totalScheduleItems": 1869,
		"schedulePercent": 0.699245,
		"totalGuests": 344,
		"guestPercent": 0.624685,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Oxford Art Factory",
		"mostGuestCity": "Darlinghurst",
		"mostGuestAmount": 46
	},
	{
		"id": "7a8ab15",
		"email": "statechampsny@gmail.com",
		"name": "State",
		"createdDate": "2023-04-17 20:54:51",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 65,
		"totalCountries": 2,
		"totalStates": 31,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 492,
		"schedulePercent": 0.293604,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-09-24 00:00:00",
		"mostGuestName": "House of Blues Orlando",
		"mostGuestCity": "Lake Buena Vista",
		"mostGuestAmount": 3
	},
	{
		"id": "18a912c",
		"email": "katie@avantpop.com",
		"name": "Kathryn",
		"createdDate": "2009-11-02 16:10:43",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 114,
		"totalCountries": 1,
		"totalStates": 22,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 555,
		"schedulePercent": 0.323798,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "81aac96",
		"email": "tracy@thecoreent.com",
		"name": "Tracy",
		"createdDate": "2022-06-23 14:20:16",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 1266,
		"totalCountries": 7,
		"totalStates": 59,
		"totalEvents": 418,
		"eventPercent": 0.962257,
		"totalScheduleItems": 6359,
		"schedulePercent": 0.984108,
		"totalGuests": 1825,
		"guestPercent": 0.922792,
		"mostGuestDate": "2023-04-16 00:00:00",
		"mostGuestName": "The Danforth Music Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 101
	},
	{
		"id": "1747290",
		"email": "amber.dagdagan@gmail.com",
		"name": "Amber",
		"createdDate": "2019-04-04 02:48:58",
		"totalOrganizations": 6,
		"totalTours": 22,
		"totalDays": 1306,
		"totalCountries": 19,
		"totalStates": 87,
		"totalEvents": 396,
		"eventPercent": 0.95643,
		"totalScheduleItems": 5150,
		"schedulePercent": 0.96623,
		"totalGuests": 2871,
		"guestPercent": 0.967024,
		"mostGuestDate": "2023-11-27 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 86
	},
	{
		"id": "5f9aea2",
		"email": "tpproad@me.com",
		"name": "Bobby",
		"createdDate": "2009-12-11 20:33:11",
		"totalOrganizations": 17,
		"totalTours": 6,
		"totalDays": 243,
		"totalCountries": 6,
		"totalStates": 24,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 1636,
		"schedulePercent": 0.649848,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-09-03 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 3
	},
	{
		"id": "89e5a3f",
		"email": "mike@bkentertainmentgroup.com",
		"name": "Mike",
		"createdDate": "2022-04-12 12:52:41",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 53,
		"totalCountries": 5,
		"totalStates": 17,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 291,
		"schedulePercent": 0.203682,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-03-14 00:00:00",
		"mostGuestName": "O2 Shepherd's Bush Empire",
		"mostGuestCity": "London",
		"mostGuestAmount": 2
	},
	{
		"id": "856f08b",
		"email": "andym@24-7productions.co.uk",
		"name": "Andy ",
		"createdDate": "2016-07-14 15:04:22",
		"totalOrganizations": 9,
		"totalTours": 21,
		"totalDays": 1823,
		"totalCountries": 15,
		"totalStates": 35,
		"totalEvents": 186,
		"eventPercent": 0.782016,
		"totalScheduleItems": 2146,
		"schedulePercent": 0.741624,
		"totalGuests": 206,
		"guestPercent": 0.555291,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "3968553",
		"email": "brandonpescrille@gmail.com",
		"name": "Brandon",
		"createdDate": "2013-10-03 02:45:43",
		"totalOrganizations": 7,
		"totalTours": 14,
		"totalDays": 685,
		"totalCountries": 10,
		"totalStates": 47,
		"totalEvents": 274,
		"eventPercent": 0.897232,
		"totalScheduleItems": 4790,
		"schedulePercent": 0.95484,
		"totalGuests": 1363,
		"guestPercent": 0.878956,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 137
	},
	{
		"id": "fc461a2",
		"email": "jw@labelandfifth.com",
		"name": "Jonas",
		"createdDate": "2021-10-03 20:13:33",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 346,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 343,
		"eventPercent": 0.938551,
		"totalScheduleItems": 1113,
		"schedulePercent": 0.514634,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-12-15 00:00:00",
		"mostGuestName": "Hotel Cecil",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 10
	},
	{
		"id": "bb1c808",
		"email": "trogdorandsons@gmail.com",
		"name": "TROGDOR",
		"createdDate": "2021-09-01 17:21:34",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 462,
		"totalCountries": 6,
		"totalStates": 15,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 1048,
		"schedulePercent": 0.496358,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5ff0ef4",
		"email": "April.kulcsar@gmail.com",
		"name": "April",
		"createdDate": "2016-06-13 20:25:15",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 488,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 297,
		"schedulePercent": 0.206595,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "662765e",
		"email": "gentrytouring@gmail.com",
		"name": "Eboni",
		"createdDate": "2018-08-11 17:57:43",
		"totalOrganizations": 30,
		"totalTours": 18,
		"totalDays": 3424,
		"totalCountries": 23,
		"totalStates": 76,
		"totalEvents": 460,
		"eventPercent": 0.969276,
		"totalScheduleItems": 7633,
		"schedulePercent": 0.992054,
		"totalGuests": 1726,
		"guestPercent": 0.914846,
		"mostGuestDate": "2023-05-03 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 125
	},
	{
		"id": "4ccdf97",
		"email": "nl@lippmanent.com",
		"name": "Nick",
		"createdDate": "2013-06-17 14:52:40",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 386,
		"totalCountries": 8,
		"totalStates": 49,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 2219,
		"schedulePercent": 0.75341,
		"totalGuests": 532,
		"guestPercent": 0.703218,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "iTHINK Financial Amphitheatre",
		"mostGuestCity": "West Palm Beach",
		"mostGuestAmount": 84
	},
	{
		"id": "07ff941",
		"email": "kristina.jaman@gmail.com",
		"name": "Krissy",
		"createdDate": "2016-10-04 01:59:41",
		"totalOrganizations": 14,
		"totalTours": 82,
		"totalDays": 290,
		"totalCountries": 6,
		"totalStates": 16,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 1734,
		"schedulePercent": 0.671699,
		"totalGuests": 211,
		"guestPercent": 0.558204,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The Metro Theatre",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 65
	},
	{
		"id": "9ca7b5a",
		"email": "sydneyclarkbiz@gmail.com",
		"name": "Sydney",
		"createdDate": "2022-01-02 17:51:10",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 589,
		"totalCountries": 3,
		"totalStates": 46,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 3235,
		"schedulePercent": 0.8726,
		"totalGuests": 1356,
		"guestPercent": 0.878162,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "Vina Robles Amphitheatre",
		"mostGuestCity": "Paso Robles",
		"mostGuestAmount": 52
	},
	{
		"id": "9851d01",
		"email": "marymoe314@gmail.com",
		"name": "Mary",
		"createdDate": "2016-06-20 21:11:03",
		"totalOrganizations": 11,
		"totalTours": 11,
		"totalDays": 2312,
		"totalCountries": 14,
		"totalStates": 52,
		"totalEvents": 287,
		"eventPercent": 0.9069,
		"totalScheduleItems": 3288,
		"schedulePercent": 0.876837,
		"totalGuests": 66,
		"guestPercent": 0.441001,
		"mostGuestDate": "2023-02-09 00:00:00",
		"mostGuestName": "The Mission Ballroom",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 21
	},
	{
		"id": "0cdc517",
		"email": "Jesswekselblatt@gmail.com",
		"name": "Jessica",
		"createdDate": "2023-06-29 11:10:34",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 36,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 399,
		"schedulePercent": 0.25639,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b1e07a1",
		"email": "bookingkwk@gmail.com",
		"name": "Dre",
		"createdDate": "2017-03-07 20:07:17",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 176,
		"totalCountries": 8,
		"totalStates": 41,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 890,
		"schedulePercent": 0.449609,
		"totalGuests": 50,
		"guestPercent": 0.421798,
		"mostGuestDate": "2023-07-19 00:00:00",
		"mostGuestName": "Huntington Center",
		"mostGuestCity": "Toledo",
		"mostGuestAmount": 41
	},
	{
		"id": "452ac3d",
		"email": "matt@eighteentwentysix.com",
		"name": "James",
		"createdDate": "2023-04-10 19:27:41",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 236,
		"totalCountries": 5,
		"totalStates": 24,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 1138,
		"schedulePercent": 0.522845,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7a47db7",
		"email": "morgan.l.isaac@gmail.com",
		"name": "Morgan",
		"createdDate": "2013-04-26 23:56:18",
		"totalOrganizations": 10,
		"totalTours": 31,
		"totalDays": 834,
		"totalCountries": 17,
		"totalStates": 61,
		"totalEvents": 273,
		"eventPercent": 0.896305,
		"totalScheduleItems": 4176,
		"schedulePercent": 0.932724,
		"totalGuests": 182,
		"guestPercent": 0.538074,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "Kirstenbosch National Botanical Garden",
		"mostGuestCity": "Cape Town",
		"mostGuestAmount": 48
	},
	{
		"id": "ba82b05",
		"email": "lb@th-inside.de",
		"name": "Lina",
		"createdDate": "2023-04-23 17:22:02",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 26,
		"totalCountries": 5,
		"totalStates": 11,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 44,
		"schedulePercent": 0.0493974,
		"totalGuests": 28,
		"guestPercent": 0.386306,
		"mostGuestDate": "2023-04-30 00:00:00",
		"mostGuestName": "Indigo at O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 28
	},
	{
		"id": "4afd085",
		"email": "pamayalatour@gmail.com",
		"name": "Pamela",
		"createdDate": "2023-05-21 21:46:18",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 255,
		"totalCountries": 2,
		"totalStates": 33,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 1216,
		"schedulePercent": 0.544034,
		"totalGuests": 179,
		"guestPercent": 0.535426,
		"mostGuestDate": "2023-10-24 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 33
	},
	{
		"id": "cf8f794",
		"email": "christate19@outlook.com",
		"name": "Chris",
		"createdDate": "2018-05-09 21:42:31",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 367,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 167,
		"schedulePercent": 0.130711,
		"totalGuests": 44,
		"guestPercent": 0.411469,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Temecula Stampede",
		"mostGuestCity": "Temecula",
		"mostGuestAmount": 34
	},
	{
		"id": "a01b79f",
		"email": "weescotty71@gmail.com",
		"name": "Frank",
		"createdDate": "2013-05-10 04:23:14",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 528,
		"totalCountries": 4,
		"totalStates": 31,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 1586,
		"schedulePercent": 0.638326,
		"totalGuests": 898,
		"guestPercent": 0.799497,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "London Palladium",
		"mostGuestCity": "London",
		"mostGuestAmount": 104
	},
	{
		"id": "6940738",
		"email": "aubrey@eighteentwentysix.com",
		"name": "Aubrey",
		"createdDate": "2022-02-26 21:25:19",
		"totalOrganizations": 7,
		"totalTours": 17,
		"totalDays": 970,
		"totalCountries": 17,
		"totalStates": 61,
		"totalEvents": 241,
		"eventPercent": 0.862667,
		"totalScheduleItems": 3688,
		"schedulePercent": 0.906767,
		"totalGuests": 67,
		"guestPercent": 0.443253,
		"mostGuestDate": "2023-08-23 00:00:00",
		"mostGuestName": "House of Blues Anaheim",
		"mostGuestCity": "Anaheim",
		"mostGuestAmount": 47
	},
	{
		"id": "be07f06",
		"email": "produktion@revolverheld.de",
		"name": "Katharina",
		"createdDate": "2019-02-04 12:26:13",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 102,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 235,
		"schedulePercent": 0.171898,
		"totalGuests": 11,
		"guestPercent": 0.345782,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "Schloss Eyrichshof",
		"mostGuestCity": "Ebern",
		"mostGuestAmount": 9
	},
	{
		"id": "184d1f1",
		"email": "jonathan@awpservices.co",
		"name": "Jonathan",
		"createdDate": "2023-01-16 22:53:24",
		"totalOrganizations": 5,
		"totalTours": 13,
		"totalDays": 413,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 2539,
		"schedulePercent": 0.797245,
		"totalGuests": 183,
		"guestPercent": 0.538737,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "Orpheum Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 34
	},
	{
		"id": "dcb7d0f",
		"email": "lindseybathke@gmail.com",
		"name": "Lindsey",
		"createdDate": "2021-12-09 23:36:24",
		"totalOrganizations": 3,
		"totalTours": 14,
		"totalDays": 294,
		"totalCountries": 8,
		"totalStates": 44,
		"totalEvents": 148,
		"eventPercent": 0.696332,
		"totalScheduleItems": 1782,
		"schedulePercent": 0.681632,
		"totalGuests": 827,
		"guestPercent": 0.782943,
		"mostGuestDate": "2023-06-19 00:00:00",
		"mostGuestName": "Moore Theatre",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 133
	},
	{
		"id": "b62aff7",
		"email": "nikiller99@gmail.com",
		"name": "Nicola",
		"createdDate": "2012-10-22 18:08:40",
		"totalOrganizations": 18,
		"totalTours": 32,
		"totalDays": 678,
		"totalCountries": 7,
		"totalStates": 57,
		"totalEvents": 249,
		"eventPercent": 0.870746,
		"totalScheduleItems": 2533,
		"schedulePercent": 0.796716,
		"totalGuests": 1813,
		"guestPercent": 0.921467,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "Christchurch Town Hall of the Performing Arts",
		"mostGuestCity": "Christchurch",
		"mostGuestAmount": 93
	},
	{
		"id": "e48086e",
		"email": "wardenbailey@gmail.com",
		"name": "Chris",
		"createdDate": "2012-10-18 13:14:06",
		"totalOrganizations": 14,
		"totalTours": 13,
		"totalDays": 376,
		"totalCountries": 13,
		"totalStates": 53,
		"totalEvents": 195,
		"eventPercent": 0.796318,
		"totalScheduleItems": 1671,
		"schedulePercent": 0.657794,
		"totalGuests": 670,
		"guestPercent": 0.744272,
		"mostGuestDate": "2023-07-16 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 75
	},
	{
		"id": "72a949d",
		"email": "vaderpanzer@me.com",
		"name": "Piotr",
		"createdDate": "2011-02-19 14:08:48",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 31,
		"totalCountries": 4,
		"totalStates": 5,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 126,
		"schedulePercent": 0.106741,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-12-17 00:00:00",
		"mostGuestName": "Makulatura",
		"mostGuestCity": "Malbork",
		"mostGuestAmount": 0
	},
	{
		"id": "8c45ceb",
		"email": "eryn@redlettermanagementgroup.com",
		"name": "Eryn",
		"createdDate": "2017-08-10 00:43:03",
		"totalOrganizations": 13,
		"totalTours": 11,
		"totalDays": 587,
		"totalCountries": 4,
		"totalStates": 44,
		"totalEvents": 355,
		"eventPercent": 0.944246,
		"totalScheduleItems": 6061,
		"schedulePercent": 0.98093,
		"totalGuests": 2084,
		"guestPercent": 0.938949,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "Macon Centreplex - City Auditorium",
		"mostGuestCity": "Macon",
		"mostGuestAmount": 60
	},
	{
		"id": "c3d4d57",
		"email": "ipo@ineffablemusic.com",
		"name": "Ipo",
		"createdDate": "2020-01-14 18:47:00",
		"totalOrganizations": 4,
		"totalTours": 14,
		"totalDays": 335,
		"totalCountries": 5,
		"totalStates": 31,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 1694,
		"schedulePercent": 0.663621,
		"totalGuests": 1027,
		"guestPercent": 0.8285,
		"mostGuestDate": "2023-02-25 00:00:00",
		"mostGuestName": "Showbox SoDo",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 51
	},
	{
		"id": "0f01b57",
		"email": "bmontald@gmail.com",
		"name": "Bryant",
		"createdDate": "2021-07-14 13:51:20",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 649,
		"totalCountries": 5,
		"totalStates": 53,
		"totalEvents": 382,
		"eventPercent": 0.952457,
		"totalScheduleItems": 4460,
		"schedulePercent": 0.944113,
		"totalGuests": 1128,
		"guestPercent": 0.844259,
		"mostGuestDate": "2023-12-06 00:00:00",
		"mostGuestName": "Terrace Theatre",
		"mostGuestCity": "Long Beach",
		"mostGuestAmount": 73
	},
	{
		"id": "aef424e",
		"email": "chris.kiely@ignition.co.uk",
		"name": "Chris",
		"createdDate": "2020-10-03 04:31:24",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 815,
		"totalCountries": 19,
		"totalStates": 34,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 1451,
		"schedulePercent": 0.603894,
		"totalGuests": 93,
		"guestPercent": 0.468017,
		"mostGuestDate": "2023-06-26 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 33
	},
	{
		"id": "73ff430",
		"email": "NoBraggingRightsMusic@gmail.com",
		"name": "Anthony",
		"createdDate": "2023-08-15 21:49:48",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 30,
		"totalCountries": 1,
		"totalStates": 18,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 175,
		"schedulePercent": 0.135744,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "16f3bb6",
		"email": "julianstaniewski@mgmtrust.ca",
		"name": "Ryan",
		"createdDate": "2016-12-02 15:27:56",
		"totalOrganizations": 14,
		"totalTours": 5,
		"totalDays": 37,
		"totalCountries": 3,
		"totalStates": 5,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 15,
		"schedulePercent": 0.0276785,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f5e5ff8",
		"email": "pekka.kolehmainen@hoglive.fi",
		"name": "Pekka",
		"createdDate": "2022-08-01 13:16:14",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 151,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 523,
		"schedulePercent": 0.308701,
		"totalGuests": 89,
		"guestPercent": 0.462323,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "Tavastia",
		"mostGuestCity": "Helsingfors",
		"mostGuestAmount": 40
	},
	{
		"id": "f6078f1",
		"email": "KeithRMcPhee@gmail.com",
		"name": "Keith",
		"createdDate": "2023-03-17 00:30:39",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 153,
		"totalCountries": 3,
		"totalStates": 21,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 978,
		"schedulePercent": 0.474772,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-09-03 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 3
	},
	{
		"id": "52783c4",
		"email": "joepeat@gmail.com",
		"name": "Joe",
		"createdDate": "2009-10-07 13:43:43",
		"totalOrganizations": 5,
		"totalTours": 22,
		"totalDays": 260,
		"totalCountries": 6,
		"totalStates": 34,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 720,
		"schedulePercent": 0.388028,
		"totalGuests": 86,
		"guestPercent": 0.460204,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "First Avenue",
		"mostGuestCity": "Minneapolis",
		"mostGuestAmount": 31
	},
	{
		"id": "c2d6d9b",
		"email": "andyrobbinsprods@gmail.com",
		"name": "Andy",
		"createdDate": "2023-02-17 22:51:28",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 185,
		"totalCountries": 2,
		"totalStates": 47,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 2073,
		"schedulePercent": 0.730499,
		"totalGuests": 1510,
		"guestPercent": 0.895246,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Mizner Park Amphitheatre",
		"mostGuestCity": "Boca Raton",
		"mostGuestAmount": 80
	},
	{
		"id": "4f9ec53",
		"email": "mwalker@mikewalkercreative.com",
		"name": "Mike ",
		"createdDate": "2011-09-15 18:01:02",
		"totalOrganizations": 27,
		"totalTours": 7,
		"totalDays": 451,
		"totalCountries": 12,
		"totalStates": 51,
		"totalEvents": 148,
		"eventPercent": 0.696332,
		"totalScheduleItems": 2813,
		"schedulePercent": 0.833929,
		"totalGuests": 283,
		"guestPercent": 0.595153,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "Brilla Torrevieja",
		"mostGuestCity": "Torrevieja",
		"mostGuestAmount": 37
	},
	{
		"id": "f1994c1",
		"email": "fragglestickcar@me.com",
		"name": "Amos",
		"createdDate": "2011-03-21 17:48:23",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 325,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 455,
		"schedulePercent": 0.278639,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-06-04 00:00:00",
		"mostGuestName": "Railbird Festival (Limestone Stage)",
		"mostGuestCity": "Lexington,",
		"mostGuestAmount": 4
	},
	{
		"id": "ae4d497",
		"email": "info@track21.co.uk",
		"name": "Aaron",
		"createdDate": "2015-05-12 23:11:13",
		"totalOrganizations": 18,
		"totalTours": 17,
		"totalDays": 948,
		"totalCountries": 15,
		"totalStates": 63,
		"totalEvents": 292,
		"eventPercent": 0.910211,
		"totalScheduleItems": 4163,
		"schedulePercent": 0.93193,
		"totalGuests": 1054,
		"guestPercent": 0.833664,
		"mostGuestDate": "2023-04-17 00:00:00",
		"mostGuestName": "London Palladium",
		"mostGuestCity": "London",
		"mostGuestAmount": 73
	},
	{
		"id": "f96ba9c",
		"email": "jake.mazzuca@onlyhelix.com",
		"name": "Jake",
		"createdDate": "2023-05-23 13:45:13",
		"totalOrganizations": 16,
		"totalTours": 44,
		"totalDays": 625,
		"totalCountries": 19,
		"totalStates": 60,
		"totalEvents": 248,
		"eventPercent": 0.869554,
		"totalScheduleItems": 2788,
		"schedulePercent": 0.830354,
		"totalGuests": 223,
		"guestPercent": 0.564958,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 58
	},
	{
		"id": "5607cb5",
		"email": "izzy.ritchie@gmail.com",
		"name": "Isabel",
		"createdDate": "2016-09-08 20:35:42",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 361,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 256,
		"schedulePercent": 0.183684,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "Burton Cummings Theatre",
		"mostGuestCity": "Winnipeg",
		"mostGuestAmount": 15
	},
	{
		"id": "966f94b",
		"email": "piers@stltouring.com",
		"name": "Piers",
		"createdDate": "2017-09-29 03:37:32",
		"totalOrganizations": 22,
		"totalTours": 13,
		"totalDays": 386,
		"totalCountries": 23,
		"totalStates": 72,
		"totalEvents": 176,
		"eventPercent": 0.763475,
		"totalScheduleItems": 2834,
		"schedulePercent": 0.836048,
		"totalGuests": 344,
		"guestPercent": 0.624685,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "Kings Theatre",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 34
	},
	{
		"id": "b6a67eb",
		"email": "12grafj@gmail.com",
		"name": "Josh",
		"createdDate": "2021-06-17 15:34:52",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 277,
		"totalCountries": 8,
		"totalStates": 48,
		"totalEvents": 173,
		"eventPercent": 0.757516,
		"totalScheduleItems": 3133,
		"schedulePercent": 0.864654,
		"totalGuests": 2865,
		"guestPercent": 0.966627,
		"mostGuestDate": "2023-04-21 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 156
	},
	{
		"id": "baf735f",
		"email": "theamyhoffman@gmail.com",
		"name": "Amy ",
		"createdDate": "2022-09-13 18:48:02",
		"totalOrganizations": 4,
		"totalTours": 12,
		"totalDays": 169,
		"totalCountries": 5,
		"totalStates": 41,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 777,
		"schedulePercent": 0.411998,
		"totalGuests": 229,
		"guestPercent": 0.568402,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "Concord Music Hall",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 25
	},
	{
		"id": "75621c1",
		"email": "Anthony.mathews@live.com",
		"name": "Anthony",
		"createdDate": "2013-02-16 03:11:45",
		"totalOrganizations": 40,
		"totalTours": 33,
		"totalDays": 1364,
		"totalCountries": 25,
		"totalStates": 100,
		"totalEvents": 450,
		"eventPercent": 0.967554,
		"totalScheduleItems": 6639,
		"schedulePercent": 0.986227,
		"totalGuests": 2583,
		"guestPercent": 0.9559,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "The Forum ",
		"mostGuestCity": "Melbourne ",
		"mostGuestAmount": 131
	},
	{
		"id": "2aa82f0",
		"email": "info@chapmanmanagement.com",
		"name": "Office",
		"createdDate": "2018-10-31 19:28:17",
		"totalOrganizations": 20,
		"totalTours": 15,
		"totalDays": 690,
		"totalCountries": 6,
		"totalStates": 39,
		"totalEvents": 254,
		"eventPercent": 0.879619,
		"totalScheduleItems": 2122,
		"schedulePercent": 0.738445,
		"totalGuests": 116,
		"guestPercent": 0.488147,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Thornton Winery",
		"mostGuestCity": "Temecula",
		"mostGuestAmount": 21
	},
	{
		"id": "f7a397c",
		"email": "dennis@okaysoundsgreat.com",
		"name": "Dennis",
		"createdDate": "2022-05-12 18:05:38",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 248,
		"totalCountries": 9,
		"totalStates": 26,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 629,
		"schedulePercent": 0.354125,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Teatro Romano di Fiesole",
		"mostGuestCity": "Fiesole",
		"mostGuestAmount": 2
	},
	{
		"id": "129c33f",
		"email": "clara.lindberg@riksteatern.se",
		"name": "Clara",
		"createdDate": "2023-01-23 13:33:20",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 43,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 138,
		"schedulePercent": 0.112171,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6501ac0",
		"email": "mc.saunders@redlightmanagement.com",
		"name": "MC",
		"createdDate": "2018-01-16 18:20:03",
		"totalOrganizations": 6,
		"totalTours": 13,
		"totalDays": 368,
		"totalCountries": 10,
		"totalStates": 38,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 1200,
		"schedulePercent": 0.540326,
		"totalGuests": 38,
		"guestPercent": 0.402198,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "Big Night Live",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 11
	},
	{
		"id": "d8e0422",
		"email": "kasper@act.as",
		"name": "Kasper",
		"createdDate": "2018-05-22 07:38:13",
		"totalOrganizations": 36,
		"totalTours": 20,
		"totalDays": 393,
		"totalCountries": 5,
		"totalStates": 20,
		"totalEvents": 279,
		"eventPercent": 0.901205,
		"totalScheduleItems": 1952,
		"schedulePercent": 0.712356,
		"totalGuests": 174,
		"guestPercent": 0.532115,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Sentrum Scene",
		"mostGuestCity": "Oslo",
		"mostGuestAmount": 69
	},
	{
		"id": "f137494",
		"email": "kseegmueller@gmail.com",
		"name": "Kris ",
		"createdDate": "2013-05-01 10:17:03",
		"totalOrganizations": 18,
		"totalTours": 13,
		"totalDays": 748,
		"totalCountries": 8,
		"totalStates": 47,
		"totalEvents": 199,
		"eventPercent": 0.804264,
		"totalScheduleItems": 2315,
		"schedulePercent": 0.76758,
		"totalGuests": 850,
		"guestPercent": 0.78771,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "El Rey Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 83
	},
	{
		"id": "3aa334e",
		"email": "joy@newsongonline.com",
		"name": "Joy",
		"createdDate": "2016-07-07 16:40:31",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 217,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 133,
		"eventPercent": 0.656602,
		"totalScheduleItems": 1276,
		"schedulePercent": 0.558734,
		"totalGuests": 2824,
		"guestPercent": 0.964905,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 298
	},
	{
		"id": "5221171",
		"email": "loehr@event-production-concept.com",
		"name": "Martin",
		"createdDate": "2013-05-25 22:10:20",
		"totalOrganizations": 4,
		"totalTours": 13,
		"totalDays": 139,
		"totalCountries": 21,
		"totalStates": 39,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 989,
		"schedulePercent": 0.479804,
		"totalGuests": 27,
		"guestPercent": 0.38379,
		"mostGuestDate": "2023-04-25 00:00:00",
		"mostGuestName": "Vorterix",
		"mostGuestCity": "CABA",
		"mostGuestAmount": 8
	},
	{
		"id": "1876a32",
		"email": "billbuntain@mac.com",
		"name": "Bill",
		"createdDate": "2017-01-09 16:49:20",
		"totalOrganizations": 8,
		"totalTours": 3,
		"totalDays": 463,
		"totalCountries": 8,
		"totalStates": 41,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 3085,
		"schedulePercent": 0.859489,
		"totalGuests": 385,
		"guestPercent": 0.646934,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "SoFi Stadium",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 40
	},
	{
		"id": "aefa75d",
		"email": "RStanford@sjcfl.us",
		"name": "Richard",
		"createdDate": "2022-08-03 15:48:11",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 228,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 226,
		"eventPercent": 0.843464,
		"totalScheduleItems": 2311,
		"schedulePercent": 0.766521,
		"totalGuests": 47,
		"guestPercent": 0.417031,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "St. Augustine Amphitheatre",
		"mostGuestCity": "St. Augustine",
		"mostGuestAmount": 18
	},
	{
		"id": "9918aff",
		"email": "alison@wednesdaymgmt.com",
		"name": "Alison",
		"createdDate": "2023-08-28 19:07:27",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 68,
		"totalCountries": 6,
		"totalStates": 15,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 141,
		"schedulePercent": 0.114157,
		"totalGuests": 37,
		"guestPercent": 0.400212,
		"mostGuestDate": "2023-11-20 00:00:00",
		"mostGuestName": "Privatclub",
		"mostGuestCity": "",
		"mostGuestAmount": 37
	},
	{
		"id": "d3643e3",
		"email": "Gilliamkia@gmail.com",
		"name": "Kia",
		"createdDate": "2020-01-24 06:04:31",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 153,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 294,
		"schedulePercent": 0.205006,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "93a762e",
		"email": "hellobeauhere@gmail.com",
		"name": "Beau",
		"createdDate": "2023-03-17 01:44:20",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 62,
		"totalCountries": 6,
		"totalStates": 9,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 379,
		"schedulePercent": 0.248311,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f2c2ace",
		"email": "sara.huss@lifeline.se",
		"name": "Sara",
		"createdDate": "2015-11-02 12:51:33",
		"totalOrganizations": 33,
		"totalTours": 5,
		"totalDays": 300,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 141,
		"eventPercent": 0.678321,
		"totalScheduleItems": 1050,
		"schedulePercent": 0.497153,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1f8d1df",
		"email": "gothicprod@me.com",
		"name": "Bob",
		"createdDate": "2011-10-04 01:17:27",
		"totalOrganizations": 19,
		"totalTours": 8,
		"totalDays": 241,
		"totalCountries": 11,
		"totalStates": 19,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 688,
		"schedulePercent": 0.375579,
		"totalGuests": 235,
		"guestPercent": 0.57052,
		"mostGuestDate": "2023-01-15 00:00:00",
		"mostGuestName": "Electric Brixton",
		"mostGuestCity": "London",
		"mostGuestAmount": 45
	},
	{
		"id": "f895324",
		"email": "walkerterrell2@gmail.com",
		"name": "Walker",
		"createdDate": "2014-08-20 13:05:39",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 433,
		"totalCountries": 8,
		"totalStates": 48,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 2715,
		"schedulePercent": 0.821878,
		"totalGuests": 2214,
		"guestPercent": 0.944643,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Raymond James Stadium",
		"mostGuestCity": "Tampa ",
		"mostGuestAmount": 248
	},
	{
		"id": "87deeff",
		"email": "jenn@eccles.co.nz",
		"name": "Jenn",
		"createdDate": "2020-11-26 01:31:41",
		"totalOrganizations": 17,
		"totalTours": 35,
		"totalDays": 1131,
		"totalCountries": 11,
		"totalStates": 49,
		"totalEvents": 250,
		"eventPercent": 0.872335,
		"totalScheduleItems": 2756,
		"schedulePercent": 0.826381,
		"totalGuests": 1032,
		"guestPercent": 0.829691,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "bf0140c",
		"email": "nathan@nathanrogers.io",
		"name": "Nathan",
		"createdDate": "2023-06-02 20:20:53",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 208,
		"totalCountries": 1,
		"totalStates": 24,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 292,
		"schedulePercent": 0.204476,
		"totalGuests": 215,
		"guestPercent": 0.559529,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "Exit/In",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 43
	},
	{
		"id": "495bfa6",
		"email": "shanesenix@gmail.com",
		"name": "Shanese",
		"createdDate": "2019-08-29 19:26:21",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 51,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 410,
		"schedulePercent": 0.261422,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "Outside Lands Festival",
		"mostGuestCity": "San Francisco",
		"mostGuestAmount": 2
	},
	{
		"id": "c053587",
		"email": "calebgrayaudio@gmail.com",
		"name": "Caleb",
		"createdDate": "2021-09-24 12:22:10",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 736,
		"totalCountries": 3,
		"totalStates": 23,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 297,
		"schedulePercent": 0.206595,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ed45de4",
		"email": "TheCanTourEmail@gmail.com",
		"name": "Marc",
		"createdDate": "2023-08-10 21:14:54",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 142,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 169,
		"schedulePercent": 0.131903,
		"totalGuests": 80,
		"guestPercent": 0.455304,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Oak Canyon Park",
		"mostGuestCity": "Silverado",
		"mostGuestAmount": 33
	},
	{
		"id": "46d9383",
		"email": "sarah@spinspin.be",
		"name": "Sarah",
		"createdDate": "2018-09-04 10:01:39",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 194,
		"totalCountries": 3,
		"totalStates": 3,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 115,
		"schedulePercent": 0.0993246,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "90c3ca7",
		"email": "anthony.touring@gmail.com",
		"name": "Anthony",
		"createdDate": "2023-05-16 21:20:16",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 111,
		"totalCountries": 2,
		"totalStates": 33,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 930,
		"schedulePercent": 0.459939,
		"totalGuests": 111,
		"guestPercent": 0.483115,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 30
	},
	{
		"id": "0d503de",
		"email": "macewan.kate@gmail.com",
		"name": "Kate",
		"createdDate": "2017-02-15 18:36:48",
		"totalOrganizations": 8,
		"totalTours": 7,
		"totalDays": 438,
		"totalCountries": 6,
		"totalStates": 15,
		"totalEvents": 148,
		"eventPercent": 0.696332,
		"totalScheduleItems": 1493,
		"schedulePercent": 0.616342,
		"totalGuests": 1283,
		"guestPercent": 0.869289,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Don Valley Bowl",
		"mostGuestCity": "Sheffield",
		"mostGuestAmount": 108
	},
	{
		"id": "883fc48",
		"email": "mark@vectortone.com",
		"name": "Mark",
		"createdDate": "2016-12-27 21:46:16",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 710,
		"totalCountries": 8,
		"totalStates": 44,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 1421,
		"schedulePercent": 0.597139,
		"totalGuests": 121,
		"guestPercent": 0.490928,
		"mostGuestDate": "2023-04-07 00:00:00",
		"mostGuestName": "The Windjammer",
		"mostGuestCity": "Isle of Palms",
		"mostGuestAmount": 36
	},
	{
		"id": "41c8557",
		"email": "josh@joshcohen.info",
		"name": "Josh",
		"createdDate": "2010-12-01 22:36:02",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 383,
		"totalCountries": 15,
		"totalStates": 38,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 1253,
		"schedulePercent": 0.553569,
		"totalGuests": 351,
		"guestPercent": 0.630513,
		"mostGuestDate": "2023-01-20 00:00:00",
		"mostGuestName": "Walt Disney Concert Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 23
	},
	{
		"id": "cdde93e",
		"email": "mariklepro@gmail.com",
		"name": "Marion",
		"createdDate": "2018-02-01 16:31:54",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 68,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 532,
		"schedulePercent": 0.313733,
		"totalGuests": 53,
		"guestPercent": 0.426434,
		"mostGuestDate": "2023-01-11 00:00:00",
		"mostGuestName": "L'Olympia Bruno Coquatrix",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 9
	},
	{
		"id": "2682858",
		"email": "keza@earthlink.net",
		"name": "Kerry",
		"createdDate": "2012-03-08 16:35:00",
		"totalOrganizations": 31,
		"totalTours": 11,
		"totalDays": 310,
		"totalCountries": 6,
		"totalStates": 19,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 2100,
		"schedulePercent": 0.735134,
		"totalGuests": 620,
		"guestPercent": 0.731161,
		"mostGuestDate": "2023-04-27 00:00:00",
		"mostGuestName": "PICA (Port Melbourne Industrial Centre for the Arts)",
		"mostGuestCity": "Port Melbourne",
		"mostGuestAmount": 51
	},
	{
		"id": "d52176d",
		"email": "chip.dorsch@redlightmanagement.com",
		"name": "Chip",
		"createdDate": "2017-01-24 16:22:16",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 590,
		"totalCountries": 13,
		"totalStates": 63,
		"totalEvents": 208,
		"eventPercent": 0.819891,
		"totalScheduleItems": 2513,
		"schedulePercent": 0.795259,
		"totalGuests": 925,
		"guestPercent": 0.805191,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 43
	},
	{
		"id": "c64f393",
		"email": "garrybrokenshire@me.com",
		"name": "Garry",
		"createdDate": "2023-10-10 16:47:44",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 23,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 9,
		"eventPercent": 0.0607866,
		"totalScheduleItems": 143,
		"schedulePercent": 0.115217,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-12-15 00:00:00",
		"mostGuestName": "Canberra Theatre Centre",
		"mostGuestCity": "Canberra",
		"mostGuestAmount": 4
	},
	{
		"id": "58e25a4",
		"email": "crawmamadc@gmail.com",
		"name": "Kathleen",
		"createdDate": "2017-02-20 15:29:21",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 89,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 624,
		"schedulePercent": 0.351741,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8ead1b4",
		"email": "Anthony@wastedpotentialpresents.com",
		"name": "Anthony",
		"createdDate": "2019-06-06 20:09:37",
		"totalOrganizations": 12,
		"totalTours": 11,
		"totalDays": 701,
		"totalCountries": 7,
		"totalStates": 42,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 1505,
		"schedulePercent": 0.619388,
		"totalGuests": 1091,
		"guestPercent": 0.837637,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Microsoft Theather",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 315
	},
	{
		"id": "68866a2",
		"email": "cellocaza@gmail.com",
		"name": "Cello",
		"createdDate": "2017-10-09 23:53:43",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 348,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 346,
		"schedulePercent": 0.23136,
		"totalGuests": 195,
		"guestPercent": 0.546418,
		"mostGuestDate": "2023-01-25 00:00:00",
		"mostGuestName": "L`Ã‰toile Banque Nationale",
		"mostGuestCity": "Brossard",
		"mostGuestAmount": 23
	},
	{
		"id": "9e0b91f",
		"email": "Adnamay@icloud.com",
		"name": "Amanda",
		"createdDate": "2014-12-11 02:07:56",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 205,
		"totalCountries": 9,
		"totalStates": 54,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1618,
		"schedulePercent": 0.646272,
		"totalGuests": 1641,
		"guestPercent": 0.906635,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "T-Mobile Arena",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 118
	},
	{
		"id": "1f661aa",
		"email": "gluedllc@outlook.com",
		"name": "Royce",
		"createdDate": "2023-05-15 14:51:01",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 26,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 161,
		"schedulePercent": 0.127135,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0f941a2",
		"email": "dwill060@aol.com",
		"name": "Andrew ",
		"createdDate": "2016-08-10 17:56:27",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 70,
		"totalCountries": 7,
		"totalStates": 21,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 373,
		"schedulePercent": 0.245266,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Ruth Eckerd Hall",
		"mostGuestCity": "Clearwater",
		"mostGuestAmount": 7
	},
	{
		"id": "685d4d0",
		"email": "beraprod@gmail.com",
		"name": "Bera",
		"createdDate": "2022-05-25 09:52:05",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 207,
		"totalCountries": 8,
		"totalStates": 14,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 775,
		"schedulePercent": 0.411071,
		"totalGuests": 203,
		"guestPercent": 0.553172,
		"mostGuestDate": "2023-10-22 00:00:00",
		"mostGuestName": "Astra Kulturhaus",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 37
	},
	{
		"id": "66251ae",
		"email": "krzysztof.paciorek@gmail.com",
		"name": "Krzysztof",
		"createdDate": "2012-02-23 02:38:32",
		"totalOrganizations": 11,
		"totalTours": 3,
		"totalDays": 69,
		"totalCountries": 9,
		"totalStates": 17,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 367,
		"schedulePercent": 0.241557,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "846c48a",
		"email": "samueljohnjoseph@gmail.com",
		"name": "Sam",
		"createdDate": "2014-11-30 21:54:54",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 176,
		"totalCountries": 12,
		"totalStates": 31,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 1232,
		"schedulePercent": 0.547874,
		"totalGuests": 840,
		"guestPercent": 0.785724,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 50
	},
	{
		"id": "3842149",
		"email": "b.farquhar@haloent.ca",
		"name": "Brianne",
		"createdDate": "2017-11-07 23:15:21",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 113,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 203,
		"schedulePercent": 0.152165,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-12-06 00:00:00",
		"mostGuestName": "Fallsview Casino Resort",
		"mostGuestCity": "Niagara Falls",
		"mostGuestAmount": 5
	},
	{
		"id": "1722d85",
		"email": "Dkhan@stageaudioproduction.com",
		"name": "Darcy",
		"createdDate": "2015-08-18 20:16:06",
		"totalOrganizations": 21,
		"totalTours": 14,
		"totalDays": 485,
		"totalCountries": 14,
		"totalStates": 56,
		"totalEvents": 288,
		"eventPercent": 0.907694,
		"totalScheduleItems": 2433,
		"schedulePercent": 0.784135,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4ebb79e",
		"email": "englishbridgemedia@gmail.com",
		"name": "Pamela",
		"createdDate": "2021-04-24 22:00:19",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 293,
		"totalCountries": 4,
		"totalStates": 29,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 1445,
		"schedulePercent": 0.602702,
		"totalGuests": 28,
		"guestPercent": 0.386306,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Neptune Theatre",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 8
	},
	{
		"id": "5c5ebc4",
		"email": "aoifeodonovantm@gmail.com",
		"name": "Daniel",
		"createdDate": "2021-06-23 19:33:36",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 448,
		"totalCountries": 6,
		"totalStates": 47,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 1531,
		"schedulePercent": 0.625348,
		"totalGuests": 292,
		"guestPercent": 0.598596,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Cheekwood",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 106
	},
	{
		"id": "410117e",
		"email": "rick@fastcasual.co",
		"name": "Rick",
		"createdDate": "2021-08-23 16:35:36",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 106,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 802,
		"schedulePercent": 0.421269,
		"totalGuests": 568,
		"guestPercent": 0.715534,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 80
	},
	{
		"id": "ab34afe",
		"email": "mason.domke@wmg.com",
		"name": "Mason",
		"createdDate": "2022-01-25 16:21:20",
		"totalOrganizations": 46,
		"totalTours": 44,
		"totalDays": 15011,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 1913,
		"eventPercent": 0.993908,
		"totalScheduleItems": 742,
		"schedulePercent": 0.397431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "06b1473",
		"email": "james@travtours.com",
		"name": "James",
		"createdDate": "2022-11-18 23:19:12",
		"totalOrganizations": 14,
		"totalTours": 11,
		"totalDays": 197,
		"totalCountries": 9,
		"totalStates": 23,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1775,
		"schedulePercent": 0.679778,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a335bd2",
		"email": "chrisjmcnally@hotmail.com",
		"name": "Chris",
		"createdDate": "2015-09-21 14:17:11",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 231,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 601,
		"schedulePercent": 0.341544,
		"totalGuests": 412,
		"guestPercent": 0.658588,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 63
	},
	{
		"id": "b3bc402",
		"email": "charles@zouave-spectacles.com",
		"name": "Charles",
		"createdDate": "2017-12-27 11:36:49",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 100,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1075,
		"schedulePercent": 0.505761,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c2d6f25",
		"email": "mcharrelldesign@gmail.com",
		"name": "Megan",
		"createdDate": "2020-02-04 19:57:42",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 124,
		"totalCountries": 5,
		"totalStates": 29,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 591,
		"schedulePercent": 0.336512,
		"totalGuests": 197,
		"guestPercent": 0.548669,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "The Forum Auditorium",
		"mostGuestCity": "Harrisburg",
		"mostGuestAmount": 19
	},
	{
		"id": "7c4b34a",
		"email": "dirtyhoneyreceipts@gmail.com",
		"name": "Marc ",
		"createdDate": "2021-05-12 19:49:04",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 424,
		"totalCountries": 12,
		"totalStates": 62,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 1266,
		"schedulePercent": 0.55635,
		"totalGuests": 113,
		"guestPercent": 0.485366,
		"mostGuestDate": "2023-01-24 00:00:00",
		"mostGuestName": "The Garage",
		"mostGuestCity": "London",
		"mostGuestAmount": 24
	},
	{
		"id": "bd565ec",
		"email": "benjamineverest@icloud.com",
		"name": "Ben",
		"createdDate": "2017-04-15 19:47:57",
		"totalOrganizations": 9,
		"totalTours": 15,
		"totalDays": 642,
		"totalCountries": 18,
		"totalStates": 39,
		"totalEvents": 216,
		"eventPercent": 0.831943,
		"totalScheduleItems": 3001,
		"schedulePercent": 0.850881,
		"totalGuests": 459,
		"guestPercent": 0.677394,
		"mostGuestDate": "2023-04-20 00:00:00",
		"mostGuestName": "O2 Academy Bristol",
		"mostGuestCity": "Bristol",
		"mostGuestAmount": 54
	},
	{
		"id": "d207547",
		"email": "cmchicago@prathertouring.com",
		"name": "Taneal",
		"createdDate": "2023-09-11 19:23:54",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 183,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 606,
		"schedulePercent": 0.343796,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "32715e5",
		"email": "vancleveproductions@gmail.com",
		"name": "Jim",
		"createdDate": "2022-07-12 00:45:18",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 170,
		"schedulePercent": 0.132698,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "812b6a0",
		"email": "olebang@newmail.dk",
		"name": "Ole",
		"createdDate": "2011-08-02 21:40:00",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 28,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 229,
		"schedulePercent": 0.169117,
		"totalGuests": 121,
		"guestPercent": 0.490928,
		"mostGuestDate": "2023-04-21 00:00:00",
		"mostGuestName": "Det Kongelige Teater, Gamle Scene",
		"mostGuestCity": "K&#248;benhavn K",
		"mostGuestAmount": 61
	},
	{
		"id": "4e39215",
		"email": "erica.leite@mac.com",
		"name": "Erica",
		"createdDate": "2014-09-02 13:54:27",
		"totalOrganizations": 13,
		"totalTours": 15,
		"totalDays": 1026,
		"totalCountries": 24,
		"totalStates": 66,
		"totalEvents": 288,
		"eventPercent": 0.907694,
		"totalScheduleItems": 5172,
		"schedulePercent": 0.966892,
		"totalGuests": 960,
		"guestPercent": 0.815786,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 187
	},
	{
		"id": "461b43c",
		"email": "alex.linares@me.com",
		"name": "Alex",
		"createdDate": "2017-10-18 16:50:31",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 447,
		"totalCountries": 15,
		"totalStates": 32,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 958,
		"schedulePercent": 0.468282,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "249e2c2",
		"email": "wallymaloney@gmail.com",
		"name": "Walter",
		"createdDate": "2014-05-20 02:29:31",
		"totalOrganizations": 7,
		"totalTours": 18,
		"totalDays": 173,
		"totalCountries": 8,
		"totalStates": 16,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 737,
		"schedulePercent": 0.394385,
		"totalGuests": 457,
		"guestPercent": 0.676732,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Northcote Theatre",
		"mostGuestCity": "Northcote",
		"mostGuestAmount": 78
	},
	{
		"id": "89f3fea",
		"email": "katievan.tm@gmail.com",
		"name": "Katie",
		"createdDate": "2022-05-26 14:21:42",
		"totalOrganizations": 7,
		"totalTours": 19,
		"totalDays": 735,
		"totalCountries": 18,
		"totalStates": 73,
		"totalEvents": 333,
		"eventPercent": 0.934181,
		"totalScheduleItems": 4356,
		"schedulePercent": 0.940008,
		"totalGuests": 1337,
		"guestPercent": 0.876043,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "Concord Pavilion",
		"mostGuestCity": "Concord",
		"mostGuestAmount": 76
	},
	{
		"id": "f54474c",
		"email": "livetuotanto@warnermusic.com",
		"name": "Asta",
		"createdDate": "2019-07-08 13:50:58",
		"totalOrganizations": 52,
		"totalTours": 79,
		"totalDays": 1354,
		"totalCountries": 4,
		"totalStates": 186,
		"totalEvents": 1204,
		"eventPercent": 0.992584,
		"totalScheduleItems": 4908,
		"schedulePercent": 0.958813,
		"totalGuests": 526,
		"guestPercent": 0.700702,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "Tavastia",
		"mostGuestCity": "Helsingfors",
		"mostGuestAmount": 40
	},
	{
		"id": "75e23ba",
		"email": "Trippweir@gmail.com",
		"name": "Tripp",
		"createdDate": "2014-03-19 23:25:05",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 83,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 348,
		"schedulePercent": 0.232155,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "The Bourbon Theatre",
		"mostGuestCity": "Lincoln",
		"mostGuestAmount": 10
	},
	{
		"id": "6747d0b",
		"email": "clnaughton@gmail.com",
		"name": "Colleen",
		"createdDate": "2018-09-18 01:35:34",
		"totalOrganizations": 7,
		"totalTours": 15,
		"totalDays": 770,
		"totalCountries": 11,
		"totalStates": 45,
		"totalEvents": 261,
		"eventPercent": 0.886902,
		"totalScheduleItems": 4901,
		"schedulePercent": 0.958284,
		"totalGuests": 5990,
		"guestPercent": 0.994968,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Glen Helen Amphitheater",
		"mostGuestCity": "San Bernardino",
		"mostGuestAmount": 250
	},
	{
		"id": "945ae54",
		"email": "simon.johnson@frontiertouring.com",
		"name": "Simon",
		"createdDate": "2019-01-14 08:20:16",
		"totalOrganizations": 18,
		"totalTours": 14,
		"totalDays": 117,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 1088,
		"schedulePercent": 0.509469,
		"totalGuests": 737,
		"guestPercent": 0.761224,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "Forum Melbourne",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 79
	},
	{
		"id": "141d951",
		"email": "stevenreedsamuels@gmail.com",
		"name": "Steven",
		"createdDate": "2010-11-09 21:02:00",
		"totalOrganizations": 21,
		"totalTours": 17,
		"totalDays": 1995,
		"totalCountries": 8,
		"totalStates": 59,
		"totalEvents": 636,
		"eventPercent": 0.984108,
		"totalScheduleItems": 8819,
		"schedulePercent": 0.995497,
		"totalGuests": 3905,
		"guestPercent": 0.982651,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 293
	},
	{
		"id": "5999968",
		"email": "wearefrenship@gmail.com",
		"name": "James",
		"createdDate": "2016-08-19 21:44:56",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 49,
		"totalCountries": 1,
		"totalStates": 18,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "20bb09d",
		"email": "mattyrigg@gmail.com",
		"name": "Matty",
		"createdDate": "2016-05-25 18:59:51",
		"totalOrganizations": 13,
		"totalTours": 14,
		"totalDays": 635,
		"totalCountries": 12,
		"totalStates": 47,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 2334,
		"schedulePercent": 0.771553,
		"totalGuests": 129,
		"guestPercent": 0.497815,
		"mostGuestDate": "2023-01-31 00:00:00",
		"mostGuestName": "O2 Shepherd's Bush Empire",
		"mostGuestCity": "London",
		"mostGuestAmount": 58
	},
	{
		"id": "aee8f10",
		"email": "batin@theplug.se",
		"name": "Batin",
		"createdDate": "2021-10-12 10:34:20",
		"totalOrganizations": 9,
		"totalTours": 4,
		"totalDays": 64,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 142,
		"schedulePercent": 0.114422,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "45ea457",
		"email": "peachpitmusic@gmail.com",
		"name": "",
		"createdDate": "2018-05-02 16:51:03",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 71,
		"totalCountries": 7,
		"totalStates": 23,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 388,
		"schedulePercent": 0.252152,
		"totalGuests": 274,
		"guestPercent": 0.590783,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "Vic Theatre",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 48
	},
	{
		"id": "d645a50",
		"email": "drew.pinson@yahoo.com",
		"name": "Drew",
		"createdDate": "2021-09-14 14:38:21",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 916,
		"totalCountries": 3,
		"totalStates": 35,
		"totalEvents": 191,
		"eventPercent": 0.790491,
		"totalScheduleItems": 3259,
		"schedulePercent": 0.874454,
		"totalGuests": 162,
		"guestPercent": 0.524169,
		"mostGuestDate": "2023-11-26 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 41
	},
	{
		"id": "559eadb",
		"email": "info@lamaisonfauve.com",
		"name": "Etienne",
		"createdDate": "2023-04-10 17:05:05",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 170,
		"totalCountries": 9,
		"totalStates": 16,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 587,
		"schedulePercent": 0.334525,
		"totalGuests": 33,
		"guestPercent": 0.394252,
		"mostGuestDate": "2023-04-25 00:00:00",
		"mostGuestName": "La Cigale",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 30
	},
	{
		"id": "7bdc2ce",
		"email": "production@swedtunes.com",
		"name": "Jonny",
		"createdDate": "2023-06-16 10:30:29",
		"totalOrganizations": 1,
		"totalTours": 6,
		"totalDays": 57,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 391,
		"schedulePercent": 0.253344,
		"totalGuests": 47,
		"guestPercent": 0.417031,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "The Carolina Opry Theater",
		"mostGuestCity": "Myrtle Beach",
		"mostGuestAmount": 12
	},
	{
		"id": "f94c026",
		"email": "Ajthetm@gmail.com",
		"name": "Albert ",
		"createdDate": "2018-07-23 22:14:02",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 157,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 391,
		"schedulePercent": 0.253344,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "43beb91",
		"email": "tourwright@gmail.com",
		"name": "Cole",
		"createdDate": "2023-07-01 16:52:08",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 31,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 253,
		"schedulePercent": 0.18236,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "The Intersection",
		"mostGuestCity": "Grand Rapids",
		"mostGuestAmount": 10
	},
	{
		"id": "a3aff0a",
		"email": "rachel.baldwin@passioncitychurch.com",
		"name": "Rachel",
		"createdDate": "2014-07-31 14:59:53",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 515,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 2525,
		"schedulePercent": 0.795921,
		"totalGuests": 320,
		"guestPercent": 0.614091,
		"mostGuestDate": "2023-03-26 00:00:00",
		"mostGuestName": "United Supermarkets Arena",
		"mostGuestCity": "Lubbock",
		"mostGuestAmount": 26
	},
	{
		"id": "36aef44",
		"email": "samhocking@gmail.com",
		"name": "Sam",
		"createdDate": "2016-05-13 18:02:55",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 213,
		"totalCountries": 3,
		"totalStates": 12,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 1576,
		"schedulePercent": 0.635942,
		"totalGuests": 859,
		"guestPercent": 0.789829,
		"mostGuestDate": "2023-04-17 00:00:00",
		"mostGuestName": "London Palladium",
		"mostGuestCity": "London",
		"mostGuestAmount": 73
	},
	{
		"id": "c44c3c5",
		"email": "staccatomgmt@gmail.com",
		"name": "Dahlia",
		"createdDate": "2022-09-19 19:14:08",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 279,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 366,
		"schedulePercent": 0.24116,
		"totalGuests": 13,
		"guestPercent": 0.352536,
		"mostGuestDate": "2023-04-22 00:00:00",
		"mostGuestName": "Seabreeze Jazz Festival",
		"mostGuestCity": "Panama City",
		"mostGuestAmount": 10
	},
	{
		"id": "cef6055",
		"email": "brandon@zone6mgmt.com",
		"name": "Brandon",
		"createdDate": "2017-08-31 02:14:21",
		"totalOrganizations": 20,
		"totalTours": 6,
		"totalDays": 76,
		"totalCountries": 3,
		"totalStates": 15,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 349,
		"schedulePercent": 0.232684,
		"totalGuests": 137,
		"guestPercent": 0.504039,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Hollywood Forever",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 41
	},
	{
		"id": "275e02d",
		"email": "mark.enslin@pop.belmont.edu",
		"name": "Mark",
		"createdDate": "2021-01-27 01:42:33",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 31,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 108,
		"schedulePercent": 0.0956165,
		"totalGuests": 20,
		"guestPercent": 0.36909,
		"mostGuestDate": "2023-01-15 00:00:00",
		"mostGuestName": "Charleston Music Hall",
		"mostGuestCity": "Charleston",
		"mostGuestAmount": 8
	},
	{
		"id": "951e1bc",
		"email": "kbrazell@maximumartist.com",
		"name": "Kristi",
		"createdDate": "2012-01-25 21:50:16",
		"totalOrganizations": 11,
		"totalTours": 12,
		"totalDays": 842,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 142,
		"eventPercent": 0.680705,
		"totalScheduleItems": 2461,
		"schedulePercent": 0.78771,
		"totalGuests": 1039,
		"guestPercent": 0.830751,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "State Farm Arena",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 133
	},
	{
		"id": "c834fa9",
		"email": "Stian@soniccity.no",
		"name": "Stian",
		"createdDate": "2018-07-20 14:29:56",
		"totalOrganizations": 12,
		"totalTours": 8,
		"totalDays": 249,
		"totalCountries": 3,
		"totalStates": 11,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 663,
		"schedulePercent": 0.366177,
		"totalGuests": 361,
		"guestPercent": 0.635413,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "Oslo Opera House Kveld",
		"mostGuestCity": "Oslo",
		"mostGuestAmount": 63
	},
	{
		"id": "81f04de",
		"email": "tfsoundworks@gmail.com",
		"name": "Kelvin",
		"createdDate": "2023-01-10 17:56:13",
		"totalOrganizations": 2,
		"totalTours": 10,
		"totalDays": 494,
		"totalCountries": 15,
		"totalStates": 52,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 1799,
		"schedulePercent": 0.685869,
		"totalGuests": 51,
		"guestPercent": 0.423785,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Jazz At Lincoln Center",
		"mostGuestCity": "New York",
		"mostGuestAmount": 18
	},
	{
		"id": "ff3f86e",
		"email": "anni.annala@hoglive.fi",
		"name": "Anni-Sofia",
		"createdDate": "2023-02-10 09:14:54",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 430,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 417,
		"eventPercent": 0.961727,
		"totalScheduleItems": 2013,
		"schedulePercent": 0.721626,
		"totalGuests": 206,
		"guestPercent": 0.555291,
		"mostGuestDate": "2023-10-08 00:00:00",
		"mostGuestName": "Tavastia",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 58
	},
	{
		"id": "70fc698",
		"email": "morganbrittanyglenn@gmail.com",
		"name": "Morgan",
		"createdDate": "2022-01-07 14:18:55",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 246,
		"totalCountries": 1,
		"totalStates": 18,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 1356,
		"schedulePercent": 0.582175,
		"totalGuests": 559,
		"guestPercent": 0.713415,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Cadence Bank Amphitheatre at Chastain Park",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 67
	},
	{
		"id": "660a4bf",
		"email": "puestospalperreo@agudelo888.com",
		"name": "AGUDELO",
		"createdDate": "2022-06-16 15:28:23",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 420,
		"totalCountries": 3,
		"totalStates": 20,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 183,
		"schedulePercent": 0.140908,
		"totalGuests": 37,
		"guestPercent": 0.400212,
		"mostGuestDate": "2023-07-26 00:00:00",
		"mostGuestName": "EXITO CEDI VEGAS",
		"mostGuestCity": "ENVIGADO",
		"mostGuestAmount": 37
	},
	{
		"id": "baa652b",
		"email": "Ginoscarim@gmail.com",
		"name": "Gino",
		"createdDate": "2011-08-14 23:07:23",
		"totalOrganizations": 10,
		"totalTours": 7,
		"totalDays": 539,
		"totalCountries": 5,
		"totalStates": 51,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1497,
		"schedulePercent": 0.617402,
		"totalGuests": 666,
		"guestPercent": 0.742948,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Brooklyn Bowl Las Vegas",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 67
	},
	{
		"id": "844a561",
		"email": "bprenger95@gmail.com",
		"name": "Robert",
		"createdDate": "2018-03-08 18:08:29",
		"totalOrganizations": 25,
		"totalTours": 40,
		"totalDays": 2607,
		"totalCountries": 11,
		"totalStates": 71,
		"totalEvents": 585,
		"eventPercent": 0.981989,
		"totalScheduleItems": 7765,
		"schedulePercent": 0.992849,
		"totalGuests": 2211,
		"guestPercent": 0.944378,
		"mostGuestDate": "2023-04-08 00:00:00",
		"mostGuestName": "MGM Music Hall at Fenway",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 86
	},
	{
		"id": "7f5622e",
		"email": "kaigriffin@me.com",
		"name": "Kai",
		"createdDate": "2011-06-15 09:32:11",
		"totalOrganizations": 13,
		"totalTours": 26,
		"totalDays": 582,
		"totalCountries": 4,
		"totalStates": 51,
		"totalEvents": 282,
		"eventPercent": 0.904119,
		"totalScheduleItems": 2886,
		"schedulePercent": 0.840286,
		"totalGuests": 1253,
		"guestPercent": 0.865316,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "Avant Gardner EARLY SHOW",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 101
	},
	{
		"id": "3d78fb4",
		"email": "bellejunetours@gmail.com",
		"name": "Belle",
		"createdDate": "2023-05-24 09:11:04",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 65,
		"totalCountries": 7,
		"totalStates": 11,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 314,
		"schedulePercent": 0.215203,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d673f7c",
		"email": "thejelliboy@hotmail.com",
		"name": "Joel",
		"createdDate": "2010-11-17 01:37:52",
		"totalOrganizations": 32,
		"totalTours": 20,
		"totalDays": 695,
		"totalCountries": 15,
		"totalStates": 56,
		"totalEvents": 223,
		"eventPercent": 0.840154,
		"totalScheduleItems": 3934,
		"schedulePercent": 0.920275,
		"totalGuests": 270,
		"guestPercent": 0.589591,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "Orpheum Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 34
	},
	{
		"id": "9fa13a4",
		"email": "thomas.crevecoeur@gmail.com",
		"name": "Thomas",
		"createdDate": "2017-06-08 10:54:54",
		"totalOrganizations": 9,
		"totalTours": 4,
		"totalDays": 196,
		"totalCountries": 3,
		"totalStates": 12,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 1484,
		"schedulePercent": 0.610383,
		"totalGuests": 529,
		"guestPercent": 0.701894,
		"mostGuestDate": "2023-06-25 00:00:00",
		"mostGuestName": "ZÃ©nith du Grand Nancy",
		"mostGuestCity": "MaxÃ©ville",
		"mostGuestAmount": 34
	},
	{
		"id": "cc47d46",
		"email": "tomwoodsound@hotmail.co.uk",
		"name": "Tom",
		"createdDate": "2013-03-14 19:42:38",
		"totalOrganizations": 11,
		"totalTours": 7,
		"totalDays": 318,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1804,
		"schedulePercent": 0.686664,
		"totalGuests": 315,
		"guestPercent": 0.611839,
		"mostGuestDate": "2023-11-06 00:00:00",
		"mostGuestName": "The Observatory North Park",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 37
	},
	{
		"id": "158e61b",
		"email": "bobby@sandboxmgmt.com",
		"name": "Bobby",
		"createdDate": "2014-02-27 22:22:19",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 493,
		"totalCountries": 4,
		"totalStates": 24,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1815,
		"schedulePercent": 0.687856,
		"totalGuests": 987,
		"guestPercent": 0.820554,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Nissan Stadium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 90
	},
	{
		"id": "4103afc",
		"email": "anna@cardiffgiantmgmt.com",
		"name": "Anna",
		"createdDate": "2022-02-02 20:52:32",
		"totalOrganizations": 5,
		"totalTours": 18,
		"totalDays": 501,
		"totalCountries": 9,
		"totalStates": 34,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1062,
		"schedulePercent": 0.501258,
		"totalGuests": 286,
		"guestPercent": 0.596477,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "020db52",
		"email": "leonjune1@gmail.com",
		"name": "Leon",
		"createdDate": "2016-10-20 08:32:52",
		"totalOrganizations": 6,
		"totalTours": 17,
		"totalDays": 387,
		"totalCountries": 8,
		"totalStates": 19,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1042,
		"schedulePercent": 0.495166,
		"totalGuests": 327,
		"guestPercent": 0.617534,
		"mostGuestDate": "2023-08-14 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 61
	},
	{
		"id": "9693acd",
		"email": "tour.till.death@gmail.com",
		"name": "Joe",
		"createdDate": "2020-03-06 03:01:55",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 68,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 526,
		"schedulePercent": 0.310025,
		"totalGuests": 565,
		"guestPercent": 0.715137,
		"mostGuestDate": "2023-10-04 00:00:00",
		"mostGuestName": "The Bossanova Ballroom",
		"mostGuestCity": "Portland",
		"mostGuestAmount": 49
	},
	{
		"id": "bfd683c",
		"email": "ash.hillpeople@gmail.com",
		"name": "Ashley",
		"createdDate": "2017-05-18 11:55:40",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 127,
		"totalCountries": 5,
		"totalStates": 37,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 881,
		"schedulePercent": 0.447226,
		"totalGuests": 490,
		"guestPercent": 0.688518,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Orpheum Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 55
	},
	{
		"id": "5d416fc",
		"email": "lana@gallerie.us",
		"name": "Lana",
		"createdDate": "2023-06-03 16:57:51",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 37,
		"totalCountries": 4,
		"totalStates": 4,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 181,
		"schedulePercent": 0.139054,
		"totalGuests": 43,
		"guestPercent": 0.410674,
		"mostGuestDate": "2023-11-29 00:00:00",
		"mostGuestName": "The Sinclair",
		"mostGuestCity": "Cambridge",
		"mostGuestAmount": 10
	},
	{
		"id": "7ba1795",
		"email": "mattkingross@gmail.com",
		"name": "Matthew ",
		"createdDate": "2014-11-17 02:28:59",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 173,
		"totalCountries": 7,
		"totalStates": 26,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 1351,
		"schedulePercent": 0.58085,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-01-25 00:00:00",
		"mostGuestName": "Sharon L. Morse Performing Arts Center",
		"mostGuestCity": "The Villages",
		"mostGuestAmount": 4
	},
	{
		"id": "e51863f",
		"email": "melanieknott@gmail.com",
		"name": "Melanie",
		"createdDate": "2012-10-17 09:16:11",
		"totalOrganizations": 21,
		"totalTours": 22,
		"totalDays": 698,
		"totalCountries": 13,
		"totalStates": 31,
		"totalEvents": 154,
		"eventPercent": 0.71368,
		"totalScheduleItems": 2155,
		"schedulePercent": 0.743213,
		"totalGuests": 1241,
		"guestPercent": 0.863329,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Accor Arena",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 138
	},
	{
		"id": "c940dd9",
		"email": "sam_auty@hotmail.com",
		"name": "Sam",
		"createdDate": "2016-06-01 19:32:57",
		"totalOrganizations": 24,
		"totalTours": 25,
		"totalDays": 653,
		"totalCountries": 18,
		"totalStates": 85,
		"totalEvents": 330,
		"eventPercent": 0.931665,
		"totalScheduleItems": 4178,
		"schedulePercent": 0.932857,
		"totalGuests": 1600,
		"guestPercent": 0.902927,
		"mostGuestDate": "2023-02-15 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 38
	},
	{
		"id": "38e63c3",
		"email": "myerst.tj@gmail.com",
		"name": "T.J.",
		"createdDate": "2019-10-15 13:15:02",
		"totalOrganizations": 5,
		"totalTours": 15,
		"totalDays": 339,
		"totalCountries": 6,
		"totalStates": 36,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1518,
		"schedulePercent": 0.622831,
		"totalGuests": 648,
		"guestPercent": 0.738843,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 57
	},
	{
		"id": "b301ee5",
		"email": "benkogon@gmail.com",
		"name": "Bennett",
		"createdDate": "2022-05-09 18:26:46",
		"totalOrganizations": 2,
		"totalTours": 7,
		"totalDays": 51,
		"totalCountries": 2,
		"totalStates": 17,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 442,
		"schedulePercent": 0.273606,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2d74a23",
		"email": "evandro@laboratoriofantasma.com",
		"name": "Evandro",
		"createdDate": "2015-04-09 18:10:04",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 24,
		"totalCountries": 3,
		"totalStates": 9,
		"totalEvents": 9,
		"eventPercent": 0.0607866,
		"totalScheduleItems": 133,
		"schedulePercent": 0.109389,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2415190",
		"email": "tyler@refugemediagroup.com",
		"name": "Tyler",
		"createdDate": "2023-08-15 18:56:51",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 30,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 378,
		"schedulePercent": 0.247252,
		"totalGuests": 114,
		"guestPercent": 0.486028,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "Music Hall Center for the Performing Arts",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 63
	},
	{
		"id": "76231c3",
		"email": "sarah@blackviolin.net",
		"name": "Sarah",
		"createdDate": "2022-01-24 07:27:09",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 122,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 635,
		"schedulePercent": 0.356112,
		"totalGuests": 419,
		"guestPercent": 0.660707,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "Warner Theatre",
		"mostGuestCity": "Washington",
		"mostGuestAmount": 40
	},
	{
		"id": "53ac455",
		"email": "sarahjaynegreenhalgh@gmail.com",
		"name": "Sarah ",
		"createdDate": "2023-05-31 17:58:07",
		"totalOrganizations": 3,
		"totalTours": 12,
		"totalDays": 219,
		"totalCountries": 20,
		"totalStates": 59,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 1001,
		"schedulePercent": 0.485499,
		"totalGuests": 865,
		"guestPercent": 0.791286,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "London Palladium",
		"mostGuestCity": "London",
		"mostGuestAmount": 142
	},
	{
		"id": "3029261",
		"email": "makayla.purdy97@gmail.com",
		"name": "Makayla",
		"createdDate": "2023-04-28 09:04:34",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 65,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 371,
		"schedulePercent": 0.244074,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5649c4e",
		"email": "styxonstage@gmail.com",
		"name": "Brian",
		"createdDate": "2018-01-11 17:33:12",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 1333,
		"schedulePercent": 0.57542,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8416168",
		"email": "chuckpursel@gmail.com",
		"name": "Chuck ",
		"createdDate": "2019-01-09 19:26:27",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 512,
		"totalCountries": 2,
		"totalStates": 46,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 2041,
		"schedulePercent": 0.725599,
		"totalGuests": 1482,
		"guestPercent": 0.891802,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 89
	},
	{
		"id": "3612a45",
		"email": "ls@intuiti.co",
		"name": "Laurel",
		"createdDate": "2019-05-02 21:50:06",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 89,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 5,
		"eventPercent": 0.0348298,
		"totalScheduleItems": 133,
		"schedulePercent": 0.109389,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7236967",
		"email": "admin@breitgroup.com",
		"name": "Admin",
		"createdDate": "2021-08-04 16:21:12",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 75,
		"totalCountries": 3,
		"totalStates": 23,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 526,
		"schedulePercent": 0.310025,
		"totalGuests": 376,
		"guestPercent": 0.643888,
		"mostGuestDate": "2023-05-21 00:00:00",
		"mostGuestName": "Calvary Community Church",
		"mostGuestCity": "Westlake Village",
		"mostGuestAmount": 47
	},
	{
		"id": "7d8e369",
		"email": "Bmoore8815@gmail.com",
		"name": "Blaine",
		"createdDate": "2017-03-10 18:57:54",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 255,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 145,
		"eventPercent": 0.688518,
		"totalScheduleItems": 1161,
		"schedulePercent": 0.529466,
		"totalGuests": 506,
		"guestPercent": 0.694213,
		"mostGuestDate": "2023-01-07 00:00:00",
		"mostGuestName": "Billy Bob's",
		"mostGuestCity": "Ft. Worth",
		"mostGuestAmount": 108
	},
	{
		"id": "e90d783",
		"email": "hulkamaniac@me.com",
		"name": "Bill ",
		"createdDate": "2015-02-27 20:10:36",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 32,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 93,
		"schedulePercent": 0.0843597,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-08-01 00:00:00",
		"mostGuestName": "Madison Square Garden",
		"mostGuestCity": "New York",
		"mostGuestAmount": 1
	},
	{
		"id": "8c508de",
		"email": "warren.monnich.tm@gmail.com",
		"name": "Warren",
		"createdDate": "2022-06-15 20:38:30",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 77,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 402,
		"schedulePercent": 0.257582,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4fccb0a",
		"email": "j_lewis@liveshowjapan.com",
		"name": "John",
		"createdDate": "2022-07-21 22:33:21",
		"totalOrganizations": 5,
		"totalTours": 14,
		"totalDays": 324,
		"totalCountries": 14,
		"totalStates": 45,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 2102,
		"schedulePercent": 0.735797,
		"totalGuests": 387,
		"guestPercent": 0.647596,
		"mostGuestDate": "2023-07-16 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 75
	},
	{
		"id": "6e33f52",
		"email": "clara.peyerl@livenation.se",
		"name": "Clara",
		"createdDate": "2023-07-06 12:21:52",
		"totalOrganizations": 9,
		"totalTours": 12,
		"totalDays": 112,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 448,
		"schedulePercent": 0.275593,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Nordichallen",
		"mostGuestCity": "Sundsvall",
		"mostGuestAmount": 2
	},
	{
		"id": "fe8aea7",
		"email": "mgmt@baddreems.com",
		"name": "Ben",
		"createdDate": "2023-06-19 01:27:44",
		"totalOrganizations": 1,
		"totalTours": 8,
		"totalDays": 49,
		"totalCountries": 4,
		"totalStates": 11,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 238,
		"schedulePercent": 0.174679,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "10d0527",
		"email": "tshtouring@gmail.com",
		"name": "Tyler ",
		"createdDate": "2019-03-30 20:38:09",
		"totalOrganizations": 3,
		"totalTours": 12,
		"totalDays": 321,
		"totalCountries": 1,
		"totalStates": 41,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 1263,
		"schedulePercent": 0.555556,
		"totalGuests": 180,
		"guestPercent": 0.536485,
		"mostGuestDate": "2023-01-21 00:00:00",
		"mostGuestName": "Cervantes' Masterpiece Ballroom & Cervantes' Other Side",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 50
	},
	{
		"id": "e0d27d8",
		"email": "louisa.rochdi@lobbycall.com",
		"name": "Lou",
		"createdDate": "2022-03-18 23:53:18",
		"totalOrganizations": 22,
		"totalTours": 37,
		"totalDays": 1022,
		"totalCountries": 22,
		"totalStates": 67,
		"totalEvents": 264,
		"eventPercent": 0.889419,
		"totalScheduleItems": 4553,
		"schedulePercent": 0.947292,
		"totalGuests": 51,
		"guestPercent": 0.423785,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Tivoli Gardens",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 49
	},
	{
		"id": "a86311a",
		"email": "pawfuzz@gmail.com",
		"name": "Paw",
		"createdDate": "2015-04-15 18:21:31",
		"totalOrganizations": 33,
		"totalTours": 27,
		"totalDays": 613,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 552,
		"eventPercent": 0.979738,
		"totalScheduleItems": 3305,
		"schedulePercent": 0.878162,
		"totalGuests": 595,
		"guestPercent": 0.724805,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Royal Arena",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 140
	},
	{
		"id": "e0bf9c0",
		"email": "stu@houseoftours.co.uk",
		"name": "Stu",
		"createdDate": "2021-10-16 16:12:14",
		"totalOrganizations": 5,
		"totalTours": 13,
		"totalDays": 382,
		"totalCountries": 14,
		"totalStates": 68,
		"totalEvents": 221,
		"eventPercent": 0.83777,
		"totalScheduleItems": 3349,
		"schedulePercent": 0.883062,
		"totalGuests": 915,
		"guestPercent": 0.802543,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "AB Banan-Kompaniet",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 42
	},
	{
		"id": "529e6c7",
		"email": "john@breadwinnersalumni.com",
		"name": "John",
		"createdDate": "2022-02-19 21:37:01",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 84,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 456,
		"schedulePercent": 0.279168,
		"totalGuests": 824,
		"guestPercent": 0.782148,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Lakefront Arena",
		"mostGuestCity": "New Orleans",
		"mostGuestAmount": 184
	},
	{
		"id": "9ebe326",
		"email": "zacharymkaplan@gmail.com",
		"name": "Zachary",
		"createdDate": "2021-11-09 19:22:16",
		"totalOrganizations": 14,
		"totalTours": 13,
		"totalDays": 1087,
		"totalCountries": 22,
		"totalStates": 66,
		"totalEvents": 264,
		"eventPercent": 0.889419,
		"totalScheduleItems": 3699,
		"schedulePercent": 0.907032,
		"totalGuests": 1155,
		"guestPercent": 0.850086,
		"mostGuestDate": "2023-09-05 00:00:00",
		"mostGuestName": "Singapore Indoor Stadium",
		"mostGuestCity": "Singapore",
		"mostGuestAmount": 78
	},
	{
		"id": "5c502ee",
		"email": "jenny@allday.fi",
		"name": "Jenny",
		"createdDate": "2022-04-26 09:11:56",
		"totalOrganizations": 21,
		"totalTours": 36,
		"totalDays": 710,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 412,
		"eventPercent": 0.960535,
		"totalScheduleItems": 1475,
		"schedulePercent": 0.607999,
		"totalGuests": 34,
		"guestPercent": 0.395842,
		"mostGuestDate": "2023-03-10 00:00:00",
		"mostGuestName": "Ravintola Kunkku",
		"mostGuestCity": "Tahkovuori",
		"mostGuestAmount": 16
	},
	{
		"id": "ccd6c6c",
		"email": "stevenhackman@gmail.com",
		"name": "Steven",
		"createdDate": "2019-05-25 16:35:06",
		"totalOrganizations": 2,
		"totalTours": 8,
		"totalDays": 71,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 206,
		"schedulePercent": 0.153754,
		"totalGuests": 24,
		"guestPercent": 0.375712,
		"mostGuestDate": "2023-03-04 00:00:00",
		"mostGuestName": "Meyerson Symphony Center",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 22
	},
	{
		"id": "a0772e6",
		"email": "gemma@jokoy.com",
		"name": "Gemma",
		"createdDate": "2018-01-04 18:44:47",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 8,
		"totalStates": 44,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 1823,
		"schedulePercent": 0.689842,
		"totalGuests": 149,
		"guestPercent": 0.513972,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Footprint Center",
		"mostGuestCity": "Phoenix",
		"mostGuestAmount": 38
	},
	{
		"id": "d21f892",
		"email": "josh@opendoormgmt.com.au",
		"name": "joshua",
		"createdDate": "2019-10-01 08:39:44",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 248,
		"totalCountries": 6,
		"totalStates": 50,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1470,
		"schedulePercent": 0.607469,
		"totalGuests": 97,
		"guestPercent": 0.471461,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "FivePoint Amphitheatre",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 18
	},
	{
		"id": "18dc680",
		"email": "cj@minttalentgroup.com",
		"name": "CJ",
		"createdDate": "2020-09-25 05:03:43",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 505,
		"totalCountries": 2,
		"totalStates": 33,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 673,
		"schedulePercent": 0.369885,
		"totalGuests": 124,
		"guestPercent": 0.493445,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "Brooklyn Bowl",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 29
	},
	{
		"id": "1271180",
		"email": "shawn@ugivworld.com",
		"name": "Shawn",
		"createdDate": "2022-11-24 12:27:43",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 25,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 102,
		"schedulePercent": 0.0920408,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-08-06 00:00:00",
		"mostGuestName": "Festivent",
		"mostGuestCity": "Levis",
		"mostGuestAmount": 15
	},
	{
		"id": "7d6e482",
		"email": "fredande025@gmail.com",
		"name": "Fredrik",
		"createdDate": "2023-05-03 12:51:29",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 227,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ea78b2a",
		"email": "juli@punchbowlent.com",
		"name": "Juli",
		"createdDate": "2022-11-11 21:20:59",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 263,
		"totalCountries": 3,
		"totalStates": 41,
		"totalEvents": 176,
		"eventPercent": 0.763475,
		"totalScheduleItems": 1205,
		"schedulePercent": 0.540988,
		"totalGuests": 437,
		"guestPercent": 0.667196,
		"mostGuestDate": "2023-12-07 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 33
	},
	{
		"id": "429e93b",
		"email": "DanielEngholtCarlsen@Outlook.dk",
		"name": "Daniel Engholt",
		"createdDate": "2017-06-21 13:28:35",
		"totalOrganizations": 13,
		"totalTours": 9,
		"totalDays": 177,
		"totalCountries": 7,
		"totalStates": 11,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 934,
		"schedulePercent": 0.461131,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e3b475a",
		"email": "info@hephzibahproductions.com",
		"name": "Temidayo",
		"createdDate": "2022-06-20 19:59:27",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 147,
		"totalCountries": 5,
		"totalStates": 15,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 190,
		"schedulePercent": 0.144617,
		"totalGuests": 599,
		"guestPercent": 0.725864,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Toyota Center",
		"mostGuestCity": "Houston",
		"mostGuestAmount": 123
	},
	{
		"id": "690184a",
		"email": "mattwnuk@live.com",
		"name": "Matt",
		"createdDate": "2022-03-08 22:38:48",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 210,
		"totalCountries": 5,
		"totalStates": 33,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 735,
		"schedulePercent": 0.39412,
		"totalGuests": 36,
		"guestPercent": 0.397961,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "North to Shore Festival",
		"mostGuestCity": "Asbury Park",
		"mostGuestAmount": 36
	},
	{
		"id": "a261015",
		"email": "zrscott02@gmail.com",
		"name": "Zander",
		"createdDate": "2022-03-23 00:43:02",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 978,
		"totalCountries": 4,
		"totalStates": 53,
		"totalEvents": 270,
		"eventPercent": 0.894451,
		"totalScheduleItems": 3985,
		"schedulePercent": 0.922394,
		"totalGuests": 3113,
		"guestPercent": 0.971262,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 298
	},
	{
		"id": "5974528",
		"email": "swinders@gmail.com",
		"name": "Steven",
		"createdDate": "2013-04-03 02:07:44",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 96,
		"totalCountries": 4,
		"totalStates": 27,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 1220,
		"schedulePercent": 0.545358,
		"totalGuests": 79,
		"guestPercent": 0.454907,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 41
	},
	{
		"id": "84ca82f",
		"email": "anthonymehlhaff@gmail.com",
		"name": "Anthony",
		"createdDate": "2023-04-15 17:26:06",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 29,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 103,
		"schedulePercent": 0.0929678,
		"totalGuests": 17,
		"guestPercent": 0.362469,
		"mostGuestDate": "2023-06-18 00:00:00",
		"mostGuestName": "Zebulon",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 8
	},
	{
		"id": "7a0aba1",
		"email": "kevinstevens5@gmail.com",
		"name": "Kevin",
		"createdDate": "2017-10-30 21:55:36",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 1288,
		"schedulePercent": 0.563237,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e9d33a6",
		"email": "carlphilip@curlymanproduction.se",
		"name": "Carl-Philip",
		"createdDate": "2015-07-27 16:26:05",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 190,
		"totalCountries": 6,
		"totalStates": 11,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 1405,
		"schedulePercent": 0.592769,
		"totalGuests": 72,
		"guestPercent": 0.446828,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "StÃ¥ngebrofÃ¤ltet",
		"mostGuestCity": "LinkÃ¶ping",
		"mostGuestAmount": 28
	},
	{
		"id": "614798c",
		"email": "jungvirtjungvirt@gmail.com",
		"name": "Tomas",
		"createdDate": "2016-10-17 06:14:46",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 237,
		"totalCountries": 16,
		"totalStates": 57,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 1748,
		"schedulePercent": 0.67448,
		"totalGuests": 181,
		"guestPercent": 0.537147,
		"mostGuestDate": "2023-01-24 00:00:00",
		"mostGuestName": "Huxley's",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 42
	},
	{
		"id": "633a7ae",
		"email": "michaeltmacdonald@gmail.com",
		"name": "Michael",
		"createdDate": "2012-08-20 21:08:34",
		"totalOrganizations": 10,
		"totalTours": 16,
		"totalDays": 425,
		"totalCountries": 10,
		"totalStates": 56,
		"totalEvents": 205,
		"eventPercent": 0.814859,
		"totalScheduleItems": 2569,
		"schedulePercent": 0.801881,
		"totalGuests": 415,
		"guestPercent": 0.660045,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Saban Theatre",
		"mostGuestCity": "Beverly Hills",
		"mostGuestAmount": 43
	},
	{
		"id": "85238c7",
		"email": "taylorcone23@gmail.com",
		"name": "Taylor",
		"createdDate": "2023-01-31 21:12:49",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 516,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 541,
		"schedulePercent": 0.318236,
		"totalGuests": 57,
		"guestPercent": 0.430804,
		"mostGuestDate": "2023-02-09 00:00:00",
		"mostGuestName": "The Mission Ballroom",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 21
	},
	{
		"id": "5265699",
		"email": "joemanager@aol.com",
		"name": "Joey",
		"createdDate": "2010-06-10 16:58:56",
		"totalOrganizations": 18,
		"totalTours": 24,
		"totalDays": 631,
		"totalCountries": 16,
		"totalStates": 68,
		"totalEvents": 319,
		"eventPercent": 0.925175,
		"totalScheduleItems": 4778,
		"schedulePercent": 0.954576,
		"totalGuests": 2619,
		"guestPercent": 0.956694,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "Hard Rock Live",
		"mostGuestCity": "Orlando",
		"mostGuestAmount": 87
	},
	{
		"id": "c5e6783",
		"email": "msrluneau@aol.com",
		"name": "Matt",
		"createdDate": "2020-03-03 22:02:12",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 464,
		"totalCountries": 3,
		"totalStates": 37,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 1716,
		"schedulePercent": 0.668918,
		"totalGuests": 1885,
		"guestPercent": 0.926897,
		"mostGuestDate": "2023-07-06 00:00:00",
		"mostGuestName": "Saban Theatre",
		"mostGuestCity": "Beverly Hills",
		"mostGuestAmount": 105
	},
	{
		"id": "cb6e523",
		"email": "coble1212@gmail.com",
		"name": "R",
		"createdDate": "2021-12-06 00:16:45",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 358,
		"totalCountries": 16,
		"totalStates": 54,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 2482,
		"schedulePercent": 0.791021,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-07-09 00:00:00",
		"mostGuestName": "Finsbury Park",
		"mostGuestCity": "London",
		"mostGuestAmount": 2
	},
	{
		"id": "dd81356",
		"email": "gperren@aol.com",
		"name": "Geoff",
		"createdDate": "2017-05-17 22:48:37",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 162,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 117,
		"schedulePercent": 0.100914,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bd42061",
		"email": "sofie.hedqvist@gmail.com",
		"name": "Sofie",
		"createdDate": "2015-05-27 12:09:40",
		"totalOrganizations": 11,
		"totalTours": 5,
		"totalDays": 223,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 775,
		"schedulePercent": 0.411071,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e3d130f",
		"email": "alliz@songololomusic.com",
		"name": "Alliz",
		"createdDate": "2018-03-04 03:38:23",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 73,
		"totalCountries": 14,
		"totalStates": 18,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 343,
		"schedulePercent": 0.229638,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "69c0197",
		"email": "thom.perrault@gmail.com",
		"name": "Thomas",
		"createdDate": "2021-07-06 13:47:33",
		"totalOrganizations": 11,
		"totalTours": 10,
		"totalDays": 479,
		"totalCountries": 4,
		"totalStates": 13,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 1069,
		"schedulePercent": 0.504701,
		"totalGuests": 98,
		"guestPercent": 0.472917,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "EMB Sannois",
		"mostGuestCity": "Sannois",
		"mostGuestAmount": 19
	},
	{
		"id": "26780be",
		"email": "dorothy@atcmanagement.com",
		"name": "Dorothy ",
		"createdDate": "2019-10-12 00:51:29",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 60,
		"totalCountries": 7,
		"totalStates": 27,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 237,
		"schedulePercent": 0.173355,
		"totalGuests": 101,
		"guestPercent": 0.474904,
		"mostGuestDate": "2023-10-25 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 44
	},
	{
		"id": "3f88cf2",
		"email": "kara@wiedemer.us",
		"name": "Kara",
		"createdDate": "2019-06-21 23:24:52",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 512,
		"totalCountries": 20,
		"totalStates": 60,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 2497,
		"schedulePercent": 0.793008,
		"totalGuests": 677,
		"guestPercent": 0.747053,
		"mostGuestDate": "2023-11-02 00:00:00",
		"mostGuestName": "O2 Arena",
		"mostGuestCity": "London",
		"mostGuestAmount": 99
	},
	{
		"id": "afdc334",
		"email": "damonatkinson@mac.com",
		"name": "Damon",
		"createdDate": "2014-09-30 19:28:50",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 35,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 237,
		"schedulePercent": 0.173355,
		"totalGuests": 37,
		"guestPercent": 0.400212,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Metro",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 22
	},
	{
		"id": "1715b91",
		"email": "adam@altitudemgmtco.com",
		"name": "Adam",
		"createdDate": "2019-01-08 21:56:16",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 285,
		"totalCountries": 1,
		"totalStates": 38,
		"totalEvents": 204,
		"eventPercent": 0.813005,
		"totalScheduleItems": 1456,
		"schedulePercent": 0.604556,
		"totalGuests": 154,
		"guestPercent": 0.51662,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Cottonwood Creek Church",
		"mostGuestCity": "Allen",
		"mostGuestAmount": 21
	},
	{
		"id": "6e7e8ea",
		"email": "gilles.bouttens@lobbycall.com",
		"name": "Gilles",
		"createdDate": "2019-10-01 04:44:41",
		"totalOrganizations": 26,
		"totalTours": 21,
		"totalDays": 393,
		"totalCountries": 19,
		"totalStates": 75,
		"totalEvents": 228,
		"eventPercent": 0.845848,
		"totalScheduleItems": 3004,
		"schedulePercent": 0.851278,
		"totalGuests": 362,
		"guestPercent": 0.636472,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 51
	},
	{
		"id": "c44ce1b",
		"email": "stitchedupheartrock@gmail.com",
		"name": "Stitched",
		"createdDate": "2016-07-15 23:30:12",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 77,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 27,
		"schedulePercent": 0.0380082,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-09-01 00:00:00",
		"mostGuestName": "Summit Music Hall",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 2
	},
	{
		"id": "cada6c5",
		"email": "stevenpagemgmt@outlook.com",
		"name": "Cynthia",
		"createdDate": "2020-02-21 18:43:04",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 162,
		"totalCountries": 3,
		"totalStates": 17,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 469,
		"schedulePercent": 0.284201,
		"totalGuests": 44,
		"guestPercent": 0.411469,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "Koerner Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 29
	},
	{
		"id": "6355e96",
		"email": "Rick@x-smanagement.com",
		"name": "Rick",
		"createdDate": "2022-04-18 12:57:00",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 209,
		"totalCountries": 10,
		"totalStates": 39,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 1045,
		"schedulePercent": 0.495828,
		"totalGuests": 864,
		"guestPercent": 0.790756,
		"mostGuestDate": "2023-06-09 00:00:00",
		"mostGuestName": "Dreamland Margate - Scenic Stage",
		"mostGuestCity": "Margate",
		"mostGuestAmount": 77
	},
	{
		"id": "298c427",
		"email": "butuc@chillipepperfields.com",
		"name": "Victor",
		"createdDate": "2022-10-07 10:10:22",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 33,
		"totalCountries": 3,
		"totalStates": 4,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 274,
		"schedulePercent": 0.195074,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4e7a813",
		"email": "arnault.burgues@gmail.com",
		"name": "Arnault",
		"createdDate": "2018-05-07 16:20:51",
		"totalOrganizations": 9,
		"totalTours": 4,
		"totalDays": 149,
		"totalCountries": 7,
		"totalStates": 35,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 1075,
		"schedulePercent": 0.505761,
		"totalGuests": 381,
		"guestPercent": 0.645875,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "FNAC Live Paris",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 100
	},
	{
		"id": "e8aaf84",
		"email": "pscott@profusionproductions.com",
		"name": "Pierce",
		"createdDate": "2021-07-20 10:18:24",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 426,
		"totalCountries": 5,
		"totalStates": 11,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 467,
		"schedulePercent": 0.283406,
		"totalGuests": 142,
		"guestPercent": 0.506688,
		"mostGuestDate": "2023-02-08 00:00:00",
		"mostGuestName": "Margaret Court Arena",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 49
	},
	{
		"id": "a5ddeb3",
		"email": "malcolm@darkroastmgmt.com",
		"name": "Malcolm",
		"createdDate": "2022-10-12 18:08:40",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 764,
		"totalCountries": 10,
		"totalStates": 55,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 981,
		"schedulePercent": 0.475831,
		"totalGuests": 412,
		"guestPercent": 0.658588,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 72
	},
	{
		"id": "b1d059c",
		"email": "danieljabbate@icloud.com",
		"name": "Daniel",
		"createdDate": "2023-01-12 16:44:57",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 298,
		"totalCountries": 2,
		"totalStates": 44,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 1146,
		"schedulePercent": 0.525493,
		"totalGuests": 531,
		"guestPercent": 0.702821,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 47
	},
	{
		"id": "5abdcb2",
		"email": "Brandoncrobot@gmail.com",
		"name": "Brandon ",
		"createdDate": "2019-05-01 21:35:59",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 198,
		"totalCountries": 7,
		"totalStates": 51,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 1363,
		"schedulePercent": 0.583631,
		"totalGuests": 900,
		"guestPercent": 0.799629,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Palladium Times Square",
		"mostGuestCity": "New York",
		"mostGuestAmount": 97
	},
	{
		"id": "06c45cf",
		"email": "evanscaa@gmail.com",
		"name": "Carol",
		"createdDate": "2017-02-16 22:00:47",
		"totalOrganizations": 12,
		"totalTours": 22,
		"totalDays": 803,
		"totalCountries": 25,
		"totalStates": 90,
		"totalEvents": 416,
		"eventPercent": 0.96133,
		"totalScheduleItems": 7134,
		"schedulePercent": 0.989935,
		"totalGuests": 2172,
		"guestPercent": 0.942259,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 178
	},
	{
		"id": "0a4a897",
		"email": "Jensen@majorbob.com",
		"name": "Jensen",
		"createdDate": "2023-05-15 14:24:41",
		"totalOrganizations": 2,
		"totalTours": 10,
		"totalDays": 221,
		"totalCountries": 2,
		"totalStates": 33,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 933,
		"schedulePercent": 0.460734,
		"totalGuests": 108,
		"guestPercent": 0.481128,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 55
	},
	{
		"id": "ba1e506",
		"email": "skottybell@gmail.com",
		"name": "Scott",
		"createdDate": "2015-02-18 20:44:37",
		"totalOrganizations": 6,
		"totalTours": 11,
		"totalDays": 256,
		"totalCountries": 12,
		"totalStates": 58,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 1571,
		"schedulePercent": 0.63475,
		"totalGuests": 675,
		"guestPercent": 0.745597,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "Franklin Music Hall",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 56
	},
	{
		"id": "1f78b6f",
		"email": "iamchrisfreeman@gmail.com",
		"name": "Chris",
		"createdDate": "2013-04-17 00:15:54",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 1506,
		"schedulePercent": 0.619521,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Credit One Stadium",
		"mostGuestCity": "Charleston",
		"mostGuestAmount": 4
	},
	{
		"id": "6646ca4",
		"email": "annaezzell@yahoo.com",
		"name": "Anna",
		"createdDate": "2022-02-01 20:28:23",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 639,
		"totalCountries": 9,
		"totalStates": 56,
		"totalEvents": 168,
		"eventPercent": 0.747451,
		"totalScheduleItems": 2811,
		"schedulePercent": 0.833532,
		"totalGuests": 2497,
		"guestPercent": 0.953913,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Dickies Arena",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 433
	},
	{
		"id": "3a2ab6d",
		"email": "roryjljones@gmail.com",
		"name": "Rory",
		"createdDate": "2016-07-13 02:27:57",
		"totalOrganizations": 17,
		"totalTours": 38,
		"totalDays": 1985,
		"totalCountries": 18,
		"totalStates": 87,
		"totalEvents": 725,
		"eventPercent": 0.986889,
		"totalScheduleItems": 7813,
		"schedulePercent": 0.992981,
		"totalGuests": 1510,
		"guestPercent": 0.895246,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "The Marlin Room at Webster Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 58
	},
	{
		"id": "450e567",
		"email": "procopio76@me.com",
		"name": "Richard",
		"createdDate": "2010-06-18 01:19:00",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 569,
		"totalCountries": 8,
		"totalStates": 34,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1065,
		"schedulePercent": 0.50298,
		"totalGuests": 407,
		"guestPercent": 0.656337,
		"mostGuestDate": "2023-07-26 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 40
	},
	{
		"id": "91268f5",
		"email": "robert@robtourmanager.com",
		"name": "Robert",
		"createdDate": "2012-10-08 21:30:50",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 136,
		"totalCountries": 10,
		"totalStates": 35,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 920,
		"schedulePercent": 0.457026,
		"totalGuests": 843,
		"guestPercent": 0.786253,
		"mostGuestDate": "2023-06-09 00:00:00",
		"mostGuestName": "Dreamland Margate - Scenic Stage",
		"mostGuestCity": "Margate",
		"mostGuestAmount": 77
	},
	{
		"id": "458c502",
		"email": "jay@jswpro.co.uk",
		"name": "Jamie",
		"createdDate": "2013-06-04 17:29:34",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 86,
		"totalCountries": 7,
		"totalStates": 16,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 357,
		"schedulePercent": 0.236393,
		"totalGuests": 232,
		"guestPercent": 0.569196,
		"mostGuestDate": "2023-11-30 00:00:00",
		"mostGuestName": "Exmouth Pavilion",
		"mostGuestCity": "Exmouth",
		"mostGuestAmount": 26
	},
	{
		"id": "4d614be",
		"email": "elusiveyon@gmail.com",
		"name": "Yonnie",
		"createdDate": "2023-03-15 15:49:12",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 586,
		"totalCountries": 1,
		"totalStates": 44,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 2151,
		"schedulePercent": 0.742551,
		"totalGuests": 3491,
		"guestPercent": 0.976824,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 185
	},
	{
		"id": "59b0545",
		"email": "trod410@gmail.com",
		"name": "Todd",
		"createdDate": "2013-05-16 04:16:03",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 83,
		"totalCountries": 3,
		"totalStates": 23,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 493,
		"schedulePercent": 0.294663,
		"totalGuests": 748,
		"guestPercent": 0.764005,
		"mostGuestDate": "2023-07-18 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 170
	},
	{
		"id": "c453f31",
		"email": "mark@surfacemusicmgmt.com",
		"name": "Mark",
		"createdDate": "2022-08-09 18:56:14",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 60,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 458,
		"schedulePercent": 0.27983,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0053c77",
		"email": "sara.friedman@millsentertainment.com",
		"name": "Sara",
		"createdDate": "2016-10-11 17:02:50",
		"totalOrganizations": 52,
		"totalTours": 7,
		"totalDays": 819,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 1060,
		"schedulePercent": 0.500861,
		"totalGuests": 275,
		"guestPercent": 0.59171,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Proctors",
		"mostGuestCity": "Schenectady",
		"mostGuestAmount": 80
	},
	{
		"id": "52b3714",
		"email": "rams@pacificmusiccompany.com",
		"name": "Rams ",
		"createdDate": "2018-06-24 05:56:07",
		"totalOrganizations": 5,
		"totalTours": 11,
		"totalDays": 612,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 884,
		"schedulePercent": 0.447888,
		"totalGuests": 174,
		"guestPercent": 0.532115,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "MISSION BAYFEST",
		"mostGuestCity": "",
		"mostGuestAmount": 114
	},
	{
		"id": "36a1b6a",
		"email": "Bayledges@gmail.com",
		"name": "Zachary",
		"createdDate": "2017-10-10 21:26:11",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 286,
		"totalCountries": 2,
		"totalStates": 36,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1377,
		"schedulePercent": 0.586545,
		"totalGuests": 689,
		"guestPercent": 0.750497,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 121
	},
	{
		"id": "6fda036",
		"email": "simon@kickingthecurb.nu",
		"name": "Simon",
		"createdDate": "2023-08-07 14:12:19",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 80,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 159,
		"schedulePercent": 0.126208,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "08a7d45",
		"email": "Klas@year0001.com",
		"name": "Klas",
		"createdDate": "2014-09-09 09:20:50",
		"totalOrganizations": 18,
		"totalTours": 15,
		"totalDays": 219,
		"totalCountries": 19,
		"totalStates": 47,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 1297,
		"schedulePercent": 0.56562,
		"totalGuests": 752,
		"guestPercent": 0.765064,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Annexet",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 155
	},
	{
		"id": "d58d2a2",
		"email": "josh@roadlx.com",
		"name": "Josh ",
		"createdDate": "2015-11-07 17:04:13",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 411,
		"totalCountries": 17,
		"totalStates": 56,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 4139,
		"schedulePercent": 0.930208,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fceb13e",
		"email": "Andrew@soundbasher.com",
		"name": "Andrew",
		"createdDate": "2011-06-17 18:34:50",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 303,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1056,
		"schedulePercent": 0.499669,
		"totalGuests": 202,
		"guestPercent": 0.552774,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Bert's Barracuda Harley-Davidson",
		"mostGuestCity": "Clearwater",
		"mostGuestAmount": 46
	},
	{
		"id": "27004c3",
		"email": "smithtallyson@gmail.com",
		"name": "Allyson",
		"createdDate": "2019-06-11 20:29:19",
		"totalOrganizations": 28,
		"totalTours": 22,
		"totalDays": 388,
		"totalCountries": 12,
		"totalStates": 46,
		"totalEvents": 163,
		"eventPercent": 0.735134,
		"totalScheduleItems": 2539,
		"schedulePercent": 0.797245,
		"totalGuests": 1154,
		"guestPercent": 0.849954,
		"mostGuestDate": "2023-10-10 00:00:00",
		"mostGuestName": "Regent Theater DTLA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 71
	},
	{
		"id": "164b644",
		"email": "chrisroachmusic@hotmail.com",
		"name": "Chris",
		"createdDate": "2017-05-02 18:34:57",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 236,
		"totalCountries": 8,
		"totalStates": 27,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 1087,
		"schedulePercent": 0.509072,
		"totalGuests": 319,
		"guestPercent": 0.613826,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Royal Albert Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 42
	},
	{
		"id": "d9c00c3",
		"email": "campbell@premierartistproductions.com",
		"name": "Adam",
		"createdDate": "2014-03-14 15:32:31",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 425,
		"totalCountries": 19,
		"totalStates": 77,
		"totalEvents": 231,
		"eventPercent": 0.850616,
		"totalScheduleItems": 2977,
		"schedulePercent": 0.849159,
		"totalGuests": 818,
		"guestPercent": 0.779764,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "Royal Oak Theatre",
		"mostGuestCity": "Royal Oak",
		"mostGuestAmount": 49
	},
	{
		"id": "7d843cc",
		"email": "drewmolleur@gmail.com",
		"name": "Drew",
		"createdDate": "2012-05-10 20:17:37",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 105,
		"totalCountries": 6,
		"totalStates": 29,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 813,
		"schedulePercent": 0.424182,
		"totalGuests": 635,
		"guestPercent": 0.735267,
		"mostGuestDate": "2023-08-21 00:00:00",
		"mostGuestName": "BB&T Pavilion",
		"mostGuestCity": "Camden",
		"mostGuestAmount": 75
	},
	{
		"id": "193032f",
		"email": "topteninc@gmail.com",
		"name": "Don",
		"createdDate": "2022-04-11 16:29:34",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 690,
		"totalCountries": 4,
		"totalStates": 24,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 946,
		"schedulePercent": 0.464177,
		"totalGuests": 85,
		"guestPercent": 0.459144,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "Westgate Las Vegas Resort & Casino",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 32
	},
	{
		"id": "6a61485",
		"email": "karinaarcuri@showboxinc.com",
		"name": "Karina",
		"createdDate": "2023-03-08 17:47:30",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 74,
		"totalCountries": 4,
		"totalStates": 15,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 465,
		"schedulePercent": 0.282876,
		"totalGuests": 367,
		"guestPercent": 0.64018,
		"mostGuestDate": "2023-05-07 00:00:00",
		"mostGuestName": "Palacio de los Deportes",
		"mostGuestCity": "Ciudad de Mexico",
		"mostGuestAmount": 70
	},
	{
		"id": "b341d8f",
		"email": "jmblaispiano@gmail.com",
		"name": "Jean-Michel",
		"createdDate": "2021-12-10 14:09:39",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 31,
		"totalCountries": 5,
		"totalStates": 7,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 137,
		"schedulePercent": 0.111244,
		"totalGuests": 135,
		"guestPercent": 0.50245,
		"mostGuestDate": "2023-01-20 00:00:00",
		"mostGuestName": "Koerner Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 29
	},
	{
		"id": "11e734d",
		"email": "marco@highwayholidays.eu",
		"name": "Marco",
		"createdDate": "2023-02-22 16:37:04",
		"totalOrganizations": 3,
		"totalTours": 12,
		"totalDays": 311,
		"totalCountries": 21,
		"totalStates": 58,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 1502,
		"schedulePercent": 0.618329,
		"totalGuests": 540,
		"guestPercent": 0.705867,
		"mostGuestDate": "2023-09-13 00:00:00",
		"mostGuestName": "Scala",
		"mostGuestCity": "London",
		"mostGuestAmount": 51
	},
	{
		"id": "4be7d79",
		"email": "stigcorptouring@gmail.com",
		"name": "Stig",
		"createdDate": "2023-03-31 23:00:48",
		"totalOrganizations": 8,
		"totalTours": 12,
		"totalDays": 857,
		"totalCountries": 17,
		"totalStates": 64,
		"totalEvents": 211,
		"eventPercent": 0.825586,
		"totalScheduleItems": 2365,
		"schedulePercent": 0.775526,
		"totalGuests": 1435,
		"guestPercent": 0.885578,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 102
	},
	{
		"id": "9666ece",
		"email": "jarenpangelinan@gmail.com",
		"name": "Jaren",
		"createdDate": "2021-06-15 21:45:11",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 1922,
		"totalCountries": 4,
		"totalStates": 55,
		"totalEvents": 155,
		"eventPercent": 0.716197,
		"totalScheduleItems": 1760,
		"schedulePercent": 0.676599,
		"totalGuests": 20,
		"guestPercent": 0.36909,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Mohegan Sun Arena - Wolf Den",
		"mostGuestCity": "Uncasville",
		"mostGuestAmount": 4
	},
	{
		"id": "a116405",
		"email": "kevin.jonas@livenation-production.de",
		"name": "Kevin",
		"createdDate": "2023-04-03 11:59:26",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 81,
		"totalCountries": 3,
		"totalStates": 4,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 486,
		"schedulePercent": 0.291352,
		"totalGuests": 18,
		"guestPercent": 0.364455,
		"mostGuestDate": "2023-04-13 00:00:00",
		"mostGuestName": "QUARTERBACK Immobilien ARENA",
		"mostGuestCity": "Leipzig",
		"mostGuestAmount": 10
	},
	{
		"id": "b81e6a5",
		"email": "anthony@onehundredpercent.nz",
		"name": "Anthony",
		"createdDate": "2018-10-29 01:15:24",
		"totalOrganizations": 10,
		"totalTours": 11,
		"totalDays": 287,
		"totalCountries": 5,
		"totalStates": 19,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 971,
		"schedulePercent": 0.47252,
		"totalGuests": 160,
		"guestPercent": 0.522845,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "Hamer Hall",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 18
	},
	{
		"id": "834e748",
		"email": "collinrucker11@gmail.com",
		"name": "Collin",
		"createdDate": "2019-07-02 15:53:09",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 858,
		"totalCountries": 4,
		"totalStates": 34,
		"totalEvents": 190,
		"eventPercent": 0.788505,
		"totalScheduleItems": 3008,
		"schedulePercent": 0.851543,
		"totalGuests": 342,
		"guestPercent": 0.624156,
		"mostGuestDate": "2023-03-26 00:00:00",
		"mostGuestName": "United Supermarkets Arena",
		"mostGuestCity": "Lubbock",
		"mostGuestAmount": 26
	},
	{
		"id": "99d8b5d",
		"email": "marius@victorias.no",
		"name": "Marius",
		"createdDate": "2017-01-29 23:44:30",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 178,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 430,
		"schedulePercent": 0.268176,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d617675",
		"email": "tiffany@mutato.com",
		"name": "Tiffany",
		"createdDate": "2022-04-27 22:46:07",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 141,
		"totalCountries": 6,
		"totalStates": 12,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 609,
		"schedulePercent": 0.345517,
		"totalGuests": 582,
		"guestPercent": 0.719772,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 108
	},
	{
		"id": "87dfb08",
		"email": "andyleftwich@bellsouth.net",
		"name": "ANDY",
		"createdDate": "2012-11-07 17:01:44",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 285,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 260,
		"eventPercent": 0.885975,
		"totalScheduleItems": 1822,
		"schedulePercent": 0.689313,
		"totalGuests": 313,
		"guestPercent": 0.61078,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 38
	},
	{
		"id": "cd06b1e",
		"email": "loudstickjw@yahoo.co.uk",
		"name": "John",
		"createdDate": "2015-10-24 17:14:35",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 159,
		"totalCountries": 20,
		"totalStates": 36,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 934,
		"schedulePercent": 0.461131,
		"totalGuests": 132,
		"guestPercent": 0.499801,
		"mostGuestDate": "2023-11-02 00:00:00",
		"mostGuestName": "Batschkapp",
		"mostGuestCity": "Frankfurt am Main",
		"mostGuestAmount": 16
	},
	{
		"id": "34dee24",
		"email": "layne@crowdsurf.net",
		"name": "Layne",
		"createdDate": "2023-05-10 18:43:10",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 102,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 871,
		"schedulePercent": 0.443517,
		"totalGuests": 122,
		"guestPercent": 0.492253,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 48
	},
	{
		"id": "51661d3",
		"email": "Blair.Poirier@wmg.com",
		"name": "Blair",
		"createdDate": "2022-08-23 16:15:38",
		"totalOrganizations": 43,
		"totalTours": 41,
		"totalDays": 13921,
		"totalCountries": 15,
		"totalStates": 87,
		"totalEvents": 1652,
		"eventPercent": 0.993378,
		"totalScheduleItems": 741,
		"schedulePercent": 0.396636,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c4b844b",
		"email": "abailey@storyartistmgmt.com",
		"name": "Amelia",
		"createdDate": "2023-08-25 19:11:22",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 66,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 289,
		"schedulePercent": 0.20249,
		"totalGuests": 278,
		"guestPercent": 0.593564,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 42
	},
	{
		"id": "e33a003",
		"email": "ehddnr817@gmail.com",
		"name": "David",
		"createdDate": "2019-06-15 12:16:45",
		"totalOrganizations": 14,
		"totalTours": 14,
		"totalDays": 500,
		"totalCountries": 12,
		"totalStates": 43,
		"totalEvents": 176,
		"eventPercent": 0.763475,
		"totalScheduleItems": 4158,
		"schedulePercent": 0.931665,
		"totalGuests": 465,
		"guestPercent": 0.678718,
		"mostGuestDate": "2023-01-25 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 67
	},
	{
		"id": "24fe04f",
		"email": "deadman@deadmanagement.com",
		"name": "Brendan",
		"createdDate": "2009-07-22 23:13:49",
		"totalOrganizations": 32,
		"totalTours": 38,
		"totalDays": 2392,
		"totalCountries": 17,
		"totalStates": 83,
		"totalEvents": 689,
		"eventPercent": 0.98583,
		"totalScheduleItems": 8220,
		"schedulePercent": 0.994305,
		"totalGuests": 3937,
		"guestPercent": 0.982916,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Gorge Amphitheatre",
		"mostGuestCity": "George",
		"mostGuestAmount": 205
	},
	{
		"id": "79a3bcf",
		"email": "caitlin.engel@redlightmanagement.com",
		"name": "Caitlin",
		"createdDate": "2019-09-10 23:05:59",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 646,
		"totalCountries": 8,
		"totalStates": 31,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 780,
		"schedulePercent": 0.412925,
		"totalGuests": 85,
		"guestPercent": 0.459144,
		"mostGuestDate": "2023-05-28 00:00:00",
		"mostGuestName": "Cross The Tracks Festival",
		"mostGuestCity": "London",
		"mostGuestAmount": 24
	},
	{
		"id": "0a3284f",
		"email": "ladycouchmusic@gmail.com",
		"name": "Allen",
		"createdDate": "2022-07-18 13:38:47",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 368,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 172,
		"schedulePercent": 0.133757,
		"totalGuests": 17,
		"guestPercent": 0.362469,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "The Underdog",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 15
	},
	{
		"id": "f252f70",
		"email": "spippett@mac.com",
		"name": "Stephen",
		"createdDate": "2010-03-25 07:30:34",
		"totalOrganizations": 18,
		"totalTours": 25,
		"totalDays": 425,
		"totalCountries": 4,
		"totalStates": 39,
		"totalEvents": 210,
		"eventPercent": 0.823732,
		"totalScheduleItems": 4704,
		"schedulePercent": 0.951662,
		"totalGuests": 1362,
		"guestPercent": 0.878824,
		"mostGuestDate": "2023-05-02 00:00:00",
		"mostGuestName": "The Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 99
	},
	{
		"id": "394ed19",
		"email": "anastasiasmteam@networkstours.com",
		"name": "Anastasia",
		"createdDate": "2022-09-15 16:25:59",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 142,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 1627,
		"schedulePercent": 0.648259,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8c34ef1",
		"email": "staffanhamrin@gmail.com",
		"name": "Staffan",
		"createdDate": "2015-01-26 16:01:41",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 176,
		"totalCountries": 5,
		"totalStates": 34,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 1161,
		"schedulePercent": 0.529466,
		"totalGuests": 538,
		"guestPercent": 0.705205,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Gustavsvik Resorts AB",
		"mostGuestCity": "Ã–rebro",
		"mostGuestAmount": 91
	},
	{
		"id": "bcd43d4",
		"email": "timatlasbooking@gmail.com",
		"name": "Timothy",
		"createdDate": "2023-07-26 17:35:40",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 23,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 101,
		"schedulePercent": 0.0908489,
		"totalGuests": 27,
		"guestPercent": 0.38379,
		"mostGuestDate": "2023-09-14 00:00:00",
		"mostGuestName": "The Red Room at Cafe 939",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 11
	},
	{
		"id": "572ef4a",
		"email": "will@21stca.com",
		"name": "Will",
		"createdDate": "2020-01-10 22:08:53",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 430,
		"totalCountries": 3,
		"totalStates": 20,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 1067,
		"schedulePercent": 0.503642,
		"totalGuests": 193,
		"guestPercent": 0.545093,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "Rainbow Lagoon Park",
		"mostGuestCity": "Long Beach",
		"mostGuestAmount": 24
	},
	{
		"id": "cdf3564",
		"email": "Robertgbooker@gmail.com",
		"name": "Robert ",
		"createdDate": "2016-05-26 17:03:14",
		"totalOrganizations": 20,
		"totalTours": 27,
		"totalDays": 1220,
		"totalCountries": 20,
		"totalStates": 90,
		"totalEvents": 456,
		"eventPercent": 0.968613,
		"totalScheduleItems": 5395,
		"schedulePercent": 0.971262,
		"totalGuests": 1578,
		"guestPercent": 0.90094,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "Royal Festival Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 35
	},
	{
		"id": "9a0cc7c",
		"email": "amandapurcell88@gmail.com",
		"name": "Amanda",
		"createdDate": "2021-11-16 16:34:42",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 691,
		"totalCountries": 3,
		"totalStates": 42,
		"totalEvents": 222,
		"eventPercent": 0.839227,
		"totalScheduleItems": 2922,
		"schedulePercent": 0.844656,
		"totalGuests": 3700,
		"guestPercent": 0.979738,
		"mostGuestDate": "2023-09-24 00:00:00",
		"mostGuestName": "PNC Music Pavilion",
		"mostGuestCity": "Charlotte",
		"mostGuestAmount": 141
	},
	{
		"id": "c0718ee",
		"email": "markn@wmg.com",
		"name": "Mark",
		"createdDate": "2013-04-24 16:58:15",
		"totalOrganizations": 52,
		"totalTours": 48,
		"totalDays": 15567,
		"totalCountries": 16,
		"totalStates": 89,
		"totalEvents": 2207,
		"eventPercent": 0.998411,
		"totalScheduleItems": 2539,
		"schedulePercent": 0.797245,
		"totalGuests": 66,
		"guestPercent": 0.441001,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Houston Rodeo WIll Call Guest List",
		"mostGuestCity": "",
		"mostGuestAmount": 57
	},
	{
		"id": "4add1a1",
		"email": "maxtour2023@gmail.com",
		"name": "Tour",
		"createdDate": "2023-03-28 16:53:21",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 100,
		"totalCountries": 3,
		"totalStates": 34,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 477,
		"schedulePercent": 0.288041,
		"totalGuests": 32,
		"guestPercent": 0.392531,
		"mostGuestDate": "2023-04-21 00:00:00",
		"mostGuestName": "University Of Hawaii",
		"mostGuestCity": "Honolulu",
		"mostGuestAmount": 7
	},
	{
		"id": "8eebcc0",
		"email": "kgill@cityparksfoundation.org",
		"name": "Keith",
		"createdDate": "2022-05-30 22:35:42",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6936611",
		"email": "shariweber@mac.com",
		"name": "Shari",
		"createdDate": "2017-04-07 22:23:23",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 235,
		"totalCountries": 6,
		"totalStates": 28,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 826,
		"schedulePercent": 0.42842,
		"totalGuests": 36,
		"guestPercent": 0.397961,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "Kia Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 20
	},
	{
		"id": "4cae951",
		"email": "mikejlukas@gmail.com",
		"name": "Mike",
		"createdDate": "2010-09-01 14:27:40",
		"totalOrganizations": 15,
		"totalTours": 8,
		"totalDays": 375,
		"totalCountries": 5,
		"totalStates": 42,
		"totalEvents": 162,
		"eventPercent": 0.732883,
		"totalScheduleItems": 1644,
		"schedulePercent": 0.651967,
		"totalGuests": 586,
		"guestPercent": 0.720832,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Wolf Trap National Park for the Performing Arts",
		"mostGuestCity": "Vienna",
		"mostGuestAmount": 45
	},
	{
		"id": "8ff03ef",
		"email": "Gabezmangold@gmail.com",
		"name": "Gabe ",
		"createdDate": "2017-07-27 14:11:47",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 119,
		"totalCountries": 12,
		"totalStates": 45,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 1055,
		"schedulePercent": 0.499139,
		"totalGuests": 298,
		"guestPercent": 0.60098,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "Palladium",
		"mostGuestCity": "Worcester",
		"mostGuestAmount": 48
	},
	{
		"id": "cdc89a6",
		"email": "jen@theinvisiblellc.com",
		"name": "Jennifer",
		"createdDate": "2017-03-30 23:46:23",
		"totalOrganizations": 20,
		"totalTours": 22,
		"totalDays": 2206,
		"totalCountries": 19,
		"totalStates": 67,
		"totalEvents": 549,
		"eventPercent": 0.97934,
		"totalScheduleItems": 5969,
		"schedulePercent": 0.980003,
		"totalGuests": 1189,
		"guestPercent": 0.855781,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 185
	},
	{
		"id": "ab2c55b",
		"email": "Loganbeaver1@gmail.com",
		"name": "Logan",
		"createdDate": "2016-10-02 15:22:19",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 371,
		"totalCountries": 11,
		"totalStates": 66,
		"totalEvents": 192,
		"eventPercent": 0.791683,
		"totalScheduleItems": 2978,
		"schedulePercent": 0.849292,
		"totalGuests": 323,
		"guestPercent": 0.61568,
		"mostGuestDate": "2023-06-01 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 37
	},
	{
		"id": "844b3f2",
		"email": "mxcarlosgm@hotmail.com",
		"name": "Carlos",
		"createdDate": "2010-10-20 19:49:54",
		"totalOrganizations": 12,
		"totalTours": 11,
		"totalDays": 335,
		"totalCountries": 7,
		"totalStates": 50,
		"totalEvents": 178,
		"eventPercent": 0.767051,
		"totalScheduleItems": 2145,
		"schedulePercent": 0.741359,
		"totalGuests": 479,
		"guestPercent": 0.683221,
		"mostGuestDate": "2023-03-26 00:00:00",
		"mostGuestName": "The Palladium",
		"mostGuestCity": "New York",
		"mostGuestAmount": 58
	},
	{
		"id": "72cf859",
		"email": "kmoran10251@gmail.com",
		"name": "Katie",
		"createdDate": "2022-04-18 18:28:28",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 118,
		"totalCountries": 3,
		"totalStates": 18,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 868,
		"schedulePercent": 0.442061,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8898853",
		"email": "brian@e1fx.com",
		"name": "Brian",
		"createdDate": "2020-12-15 22:22:10",
		"totalOrganizations": 32,
		"totalTours": 18,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 156,
		"eventPercent": 0.718051,
		"totalScheduleItems": 212,
		"schedulePercent": 0.157595,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-01-08 00:00:00",
		"mostGuestName": "TIAA Bank Field",
		"mostGuestCity": "Jacksonville",
		"mostGuestAmount": 0
	},
	{
		"id": "1b38008",
		"email": "iand@10thst.com",
		"name": "Ian",
		"createdDate": "2014-09-11 16:48:03",
		"totalOrganizations": 10,
		"totalTours": 8,
		"totalDays": 507,
		"totalCountries": 17,
		"totalStates": 68,
		"totalEvents": 209,
		"eventPercent": 0.82201,
		"totalScheduleItems": 3869,
		"schedulePercent": 0.91617,
		"totalGuests": 2385,
		"guestPercent": 0.950073,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "Angel Of The Winds Arena",
		"mostGuestCity": "Everett",
		"mostGuestAmount": 59
	},
	{
		"id": "47e2eb5",
		"email": "katelyn@dogoodmanagement.com",
		"name": "Katelyn",
		"createdDate": "2018-08-23 03:51:39",
		"totalOrganizations": 13,
		"totalTours": 14,
		"totalDays": 968,
		"totalCountries": 8,
		"totalStates": 54,
		"totalEvents": 178,
		"eventPercent": 0.767051,
		"totalScheduleItems": 2761,
		"schedulePercent": 0.827175,
		"totalGuests": 783,
		"guestPercent": 0.772613,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Berkeley",
		"mostGuestAmount": 63
	},
	{
		"id": "328fb23",
		"email": "madisonkbaker@gmail.com",
		"name": "Katie",
		"createdDate": "2018-07-21 14:30:23",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 1582,
		"totalCountries": 13,
		"totalStates": 51,
		"totalEvents": 405,
		"eventPercent": 0.958284,
		"totalScheduleItems": 5081,
		"schedulePercent": 0.964243,
		"totalGuests": 1837,
		"guestPercent": 0.924381,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 281
	},
	{
		"id": "eb45a1c",
		"email": "nick@mistrnick.com",
		"name": "Nick",
		"createdDate": "2011-10-03 05:47:28",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 40,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 6,
		"eventPercent": 0.0397298,
		"totalScheduleItems": 93,
		"schedulePercent": 0.0843597,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "ReelWorks Denver",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 7
	},
	{
		"id": "45c43d5",
		"email": "jack@jack-mcleod.com",
		"name": "Jack",
		"createdDate": "2022-04-21 23:16:57",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 184,
		"totalCountries": 1,
		"totalStates": 15,
		"totalEvents": 184,
		"eventPercent": 0.77791,
		"totalScheduleItems": 1803,
		"schedulePercent": 0.686399,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8beb901",
		"email": "steinle.sam@gmail.com",
		"name": "Samantha",
		"createdDate": "2019-02-25 08:41:21",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 513,
		"totalCountries": 5,
		"totalStates": 26,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 2176,
		"schedulePercent": 0.746391,
		"totalGuests": 761,
		"guestPercent": 0.767183,
		"mostGuestDate": "2023-04-20 00:00:00",
		"mostGuestName": "KFC Yum! Center",
		"mostGuestCity": "Louisville",
		"mostGuestAmount": 154
	},
	{
		"id": "801bdb5",
		"email": "lisalebahar@gmail.com",
		"name": "Lisa",
		"createdDate": "2017-12-01 09:09:48",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 106,
		"totalCountries": 3,
		"totalStates": 9,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 931,
		"schedulePercent": 0.460336,
		"totalGuests": 276,
		"guestPercent": 0.592902,
		"mostGuestDate": "2023-06-25 00:00:00",
		"mostGuestName": "ZÃ©nith du Grand Nancy",
		"mostGuestCity": "MaxÃ©ville",
		"mostGuestAmount": 34
	},
	{
		"id": "bae6f65",
		"email": "zachsnyder@me.com",
		"name": "Zachary",
		"createdDate": "2013-05-12 23:12:39",
		"totalOrganizations": 8,
		"totalTours": 12,
		"totalDays": 745,
		"totalCountries": 21,
		"totalStates": 56,
		"totalEvents": 244,
		"eventPercent": 0.865713,
		"totalScheduleItems": 4710,
		"schedulePercent": 0.951794,
		"totalGuests": 1130,
		"guestPercent": 0.845451,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 104
	},
	{
		"id": "815f823",
		"email": "keikkaillu@gmail.com",
		"name": "Ilari",
		"createdDate": "2022-05-04 13:32:55",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 646,
		"totalCountries": 6,
		"totalStates": 13,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 679,
		"schedulePercent": 0.371871,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-03-07 00:00:00",
		"mostGuestName": "Das Bett",
		"mostGuestCity": "Frankfurt am Main",
		"mostGuestAmount": 3
	},
	{
		"id": "2135c24",
		"email": "nic.rodriguez@lobbycall.com",
		"name": "Nicholas",
		"createdDate": "2019-05-07 19:06:38",
		"totalOrganizations": 31,
		"totalTours": 23,
		"totalDays": 315,
		"totalCountries": 12,
		"totalStates": 36,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 1578,
		"schedulePercent": 0.63634,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7b1b844",
		"email": "alexandra.winton@icloud.com",
		"name": "Alexandra",
		"createdDate": "2023-10-02 22:57:59",
		"totalOrganizations": 1,
		"totalTours": 11,
		"totalDays": 129,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 927,
		"schedulePercent": 0.45835,
		"totalGuests": 57,
		"guestPercent": 0.430804,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Rod Laver Arena",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 23
	},
	{
		"id": "d4af4c6",
		"email": "manuela.iwansson@gmail.com",
		"name": "Manuela",
		"createdDate": "2019-10-02 09:36:51",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 34,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 151,
		"schedulePercent": 0.121308,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4379395",
		"email": "jonahlevine1@gmail.com",
		"name": "Jonah",
		"createdDate": "2023-07-26 07:42:14",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 118,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 242,
		"schedulePercent": 0.177195,
		"totalGuests": 547,
		"guestPercent": 0.708648,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "Palace Theatre",
		"mostGuestCity": "St Paul",
		"mostGuestAmount": 100
	},
	{
		"id": "a70e26a",
		"email": "andremorales@mac.com",
		"name": "Andre",
		"createdDate": "2009-11-11 00:17:54",
		"totalOrganizations": 11,
		"totalTours": 10,
		"totalDays": 979,
		"totalCountries": 14,
		"totalStates": 58,
		"totalEvents": 234,
		"eventPercent": 0.854324,
		"totalScheduleItems": 3711,
		"schedulePercent": 0.907827,
		"totalGuests": 41,
		"guestPercent": 0.407231,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "North to Shore Festival",
		"mostGuestCity": "Asbury Park",
		"mostGuestAmount": 36
	},
	{
		"id": "8a2cc28",
		"email": "mattshane366@gmail.com",
		"name": "Matthew",
		"createdDate": "2022-04-28 17:53:30",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 94,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 375,
		"schedulePercent": 0.245928,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e55f1e0",
		"email": "stevecoull@hotmail.com",
		"name": "Steve",
		"createdDate": "2013-06-12 13:53:52",
		"totalOrganizations": 36,
		"totalTours": 55,
		"totalDays": 1225,
		"totalCountries": 24,
		"totalStates": 70,
		"totalEvents": 363,
		"eventPercent": 0.947424,
		"totalScheduleItems": 4868,
		"schedulePercent": 0.957224,
		"totalGuests": 1752,
		"guestPercent": 0.917097,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 130
	},
	{
		"id": "54cddd2",
		"email": "shaunpkoplow@gmail.com",
		"name": "Shaun",
		"createdDate": "2019-11-12 03:34:00",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 26,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 9,
		"eventPercent": 0.0607866,
		"totalScheduleItems": 59,
		"schedulePercent": 0.0611839,
		"totalGuests": 92,
		"guestPercent": 0.466163,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 44
	},
	{
		"id": "46e2751",
		"email": "ukintern@qprime.com",
		"name": "Chris",
		"createdDate": "2012-07-30 21:02:04",
		"totalOrganizations": 6,
		"totalTours": 21,
		"totalDays": 370,
		"totalCountries": 10,
		"totalStates": 59,
		"totalEvents": 168,
		"eventPercent": 0.747451,
		"totalScheduleItems": 1827,
		"schedulePercent": 0.690769,
		"totalGuests": 102,
		"guestPercent": 0.477155,
		"mostGuestDate": "2023-02-13 00:00:00",
		"mostGuestName": "Trabendo",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 33
	},
	{
		"id": "9bad7dd",
		"email": "chadney@17ten.co",
		"name": "Chadney",
		"createdDate": "2022-07-07 17:23:12",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 262,
		"totalCountries": 7,
		"totalStates": 17,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 751,
		"schedulePercent": 0.402331,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b0ca52c",
		"email": "mccafferty.chris@gmail.com",
		"name": "Christopher",
		"createdDate": "2023-07-28 20:34:58",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 35,
		"totalCountries": 2,
		"totalStates": 17,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 530,
		"schedulePercent": 0.312939,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d003f24",
		"email": "tctourmgmt@me.com",
		"name": "Terence ",
		"createdDate": "2013-08-05 01:22:17",
		"totalOrganizations": 9,
		"totalTours": 5,
		"totalDays": 859,
		"totalCountries": 11,
		"totalStates": 49,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 929,
		"schedulePercent": 0.459409,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "634d0bd",
		"email": "heatherrmarie27@gmail.com",
		"name": "Heather",
		"createdDate": "2022-02-11 19:52:57",
		"totalOrganizations": 6,
		"totalTours": 19,
		"totalDays": 418,
		"totalCountries": 16,
		"totalStates": 67,
		"totalEvents": 242,
		"eventPercent": 0.864389,
		"totalScheduleItems": 2778,
		"schedulePercent": 0.829162,
		"totalGuests": 631,
		"guestPercent": 0.734207,
		"mostGuestDate": "2023-06-19 00:00:00",
		"mostGuestName": "Moore Theatre",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 133
	},
	{
		"id": "cb9761e",
		"email": "Dan@1851house.com",
		"name": "Daniel",
		"createdDate": "2016-08-19 19:10:52",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 534,
		"totalCountries": 13,
		"totalStates": 48,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 1768,
		"schedulePercent": 0.678188,
		"totalGuests": 275,
		"guestPercent": 0.59171,
		"mostGuestDate": "2023-01-31 00:00:00",
		"mostGuestName": "O2 Shepherd's Bush Empire",
		"mostGuestCity": "London",
		"mostGuestAmount": 58
	},
	{
		"id": "2dc78b8",
		"email": "michelleshaiyen@gmail.com",
		"name": "Michelle",
		"createdDate": "2022-03-30 12:50:47",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 192,
		"totalCountries": 5,
		"totalStates": 9,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 443,
		"schedulePercent": 0.274136,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7083cfa",
		"email": "vince@rightangleent.com",
		"name": "Vince",
		"createdDate": "2023-02-23 20:54:17",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 128,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 1197,
		"schedulePercent": 0.539531,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-03-21 00:00:00",
		"mostGuestName": "Peoria Civic Center",
		"mostGuestCity": "Peoria",
		"mostGuestAmount": 3
	},
	{
		"id": "f3f19d3",
		"email": "gagefreeman@yahoo.com",
		"name": "Gage",
		"createdDate": "2010-06-16 05:21:29",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 141,
		"totalCountries": 15,
		"totalStates": 28,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 3149,
		"guestPercent": 0.971792,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "Tottenham Hotspur Stadium",
		"mostGuestCity": "London",
		"mostGuestAmount": 338
	},
	{
		"id": "ed025e8",
		"email": "alex@perfected.audio",
		"name": "Alexander",
		"createdDate": "2022-04-12 20:48:15",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 216,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 351,
		"schedulePercent": 0.233611,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3cf4885",
		"email": "angel_maker@hotmail.com",
		"name": "Mike",
		"createdDate": "2022-10-07 17:10:38",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 96,
		"totalCountries": 12,
		"totalStates": 45,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1117,
		"schedulePercent": 0.515296,
		"totalGuests": 166,
		"guestPercent": 0.52748,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "O2 Academy Islington",
		"mostGuestCity": "London",
		"mostGuestAmount": 24
	},
	{
		"id": "db057ff",
		"email": "sam@theogunlesigroup.com",
		"name": "Sam",
		"createdDate": "2023-02-07 02:04:43",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 234,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 1477,
		"schedulePercent": 0.608661,
		"totalGuests": 889,
		"guestPercent": 0.796981,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 75
	},
	{
		"id": "fab37f7",
		"email": "kate.beasy@icloud.com",
		"name": "Kate",
		"createdDate": "2022-03-11 11:04:23",
		"totalOrganizations": 25,
		"totalTours": 31,
		"totalDays": 549,
		"totalCountries": 11,
		"totalStates": 39,
		"totalEvents": 196,
		"eventPercent": 0.798835,
		"totalScheduleItems": 3678,
		"schedulePercent": 0.906105,
		"totalGuests": 1464,
		"guestPercent": 0.889419,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "FivePoint Amphitheatre",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 104
	},
	{
		"id": "1474d3f",
		"email": "nina@nsproduction.dk",
		"name": "Nina",
		"createdDate": "2019-10-21 14:31:27",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 25,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 254,
		"schedulePercent": 0.182625,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4e2de87",
		"email": "amogayzel@gmail.com",
		"name": "Ashley",
		"createdDate": "2013-08-21 01:08:29",
		"totalOrganizations": 14,
		"totalTours": 34,
		"totalDays": 894,
		"totalCountries": 16,
		"totalStates": 62,
		"totalEvents": 359,
		"eventPercent": 0.946232,
		"totalScheduleItems": 5122,
		"schedulePercent": 0.965303,
		"totalGuests": 3789,
		"guestPercent": 0.981327,
		"mostGuestDate": "2023-08-23 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 310
	},
	{
		"id": "295336d",
		"email": "oona.kulmanen@livenation.fi",
		"name": "Oona",
		"createdDate": "2022-01-03 10:54:18",
		"totalOrganizations": 52,
		"totalTours": 34,
		"totalDays": 602,
		"totalCountries": 4,
		"totalStates": 6,
		"totalEvents": 493,
		"eventPercent": 0.973513,
		"totalScheduleItems": 2446,
		"schedulePercent": 0.785459,
		"totalGuests": 671,
		"guestPercent": 0.745067,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Kulttuuritalo",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 107
	},
	{
		"id": "8ef8b1f",
		"email": "davelagrande@gmail.com",
		"name": "Dave",
		"createdDate": "2011-01-12 19:10:27",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 169,
		"totalCountries": 3,
		"totalStates": 27,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 645,
		"schedulePercent": 0.360482,
		"totalGuests": 140,
		"guestPercent": 0.505628,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Heartwood Soundstage",
		"mostGuestCity": "Gainesville",
		"mostGuestAmount": 39
	},
	{
		"id": "30d16ac",
		"email": "skjarbak@mail.dk",
		"name": "Troels ",
		"createdDate": "2015-08-18 09:00:00",
		"totalOrganizations": 13,
		"totalTours": 5,
		"totalDays": 166,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 1188,
		"schedulePercent": 0.53675,
		"totalGuests": 196,
		"guestPercent": 0.546947,
		"mostGuestDate": "2023-04-21 00:00:00",
		"mostGuestName": "Det Kongelige Teater, Gamle Scene",
		"mostGuestCity": "K&#248;benhavn K",
		"mostGuestAmount": 61
	},
	{
		"id": "4f981a1",
		"email": "jim@teamtrick.com",
		"name": "Jim",
		"createdDate": "2012-01-25 05:31:18",
		"totalOrganizations": 18,
		"totalTours": 13,
		"totalDays": 156,
		"totalCountries": 6,
		"totalStates": 11,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 465,
		"schedulePercent": 0.282876,
		"totalGuests": 71,
		"guestPercent": 0.446431,
		"mostGuestDate": "2023-08-05 00:00:00",
		"mostGuestName": "The Corner Hotel",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 22
	},
	{
		"id": "8956301",
		"email": "mbrogan5823@gmail.com",
		"name": "Melissa",
		"createdDate": "2022-08-27 18:23:33",
		"totalOrganizations": 5,
		"totalTours": 16,
		"totalDays": 627,
		"totalCountries": 21,
		"totalStates": 64,
		"totalEvents": 160,
		"eventPercent": 0.72838,
		"totalScheduleItems": 2039,
		"schedulePercent": 0.725202,
		"totalGuests": 302,
		"guestPercent": 0.603496,
		"mostGuestDate": "2023-10-10 00:00:00",
		"mostGuestName": "Huxleys Neue Welt",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 34
	},
	{
		"id": "095f8a3",
		"email": "ezratarlowe@gmail.com",
		"name": "Ezra",
		"createdDate": "2018-08-10 17:32:13",
		"totalOrganizations": 6,
		"totalTours": 9,
		"totalDays": 528,
		"totalCountries": 3,
		"totalStates": 40,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 1626,
		"schedulePercent": 0.647861,
		"totalGuests": 1517,
		"guestPercent": 0.89657,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Nashville Municipal Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 111
	},
	{
		"id": "14abc24",
		"email": "Audio1Pro@aol.com",
		"name": "Phillip",
		"createdDate": "2016-05-18 17:30:33",
		"totalOrganizations": 11,
		"totalTours": 14,
		"totalDays": 343,
		"totalCountries": 6,
		"totalStates": 34,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 1562,
		"schedulePercent": 0.633029,
		"totalGuests": 449,
		"guestPercent": 0.673553,
		"mostGuestDate": "2023-04-06 00:00:00",
		"mostGuestName": "The Apollo Theater",
		"mostGuestCity": "New York",
		"mostGuestAmount": 35
	},
	{
		"id": "9a88332",
		"email": "mai.x.huynh@gmail.com",
		"name": "Mai",
		"createdDate": "2013-03-04 14:09:30",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 69,
		"totalCountries": 8,
		"totalStates": 12,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 389,
		"schedulePercent": 0.252682,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-05-28 00:00:00",
		"mostGuestName": "Cross The Tracks Festival",
		"mostGuestCity": "London",
		"mostGuestAmount": 9
	},
	{
		"id": "4346aac",
		"email": "jack@starseedentertainment.com",
		"name": "Jack",
		"createdDate": "2023-01-20 22:18:55",
		"totalOrganizations": 6,
		"totalTours": 17,
		"totalDays": 1326,
		"totalCountries": 5,
		"totalStates": 54,
		"totalEvents": 276,
		"eventPercent": 0.898292,
		"totalScheduleItems": 3777,
		"schedulePercent": 0.911005,
		"totalGuests": 1527,
		"guestPercent": 0.897365,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "HISTORY",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 117
	},
	{
		"id": "2029921",
		"email": "cjharveyart@gmail.com",
		"name": "CJ",
		"createdDate": "2017-04-09 17:34:22",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 46,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 384,
		"schedulePercent": 0.250695,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5abb74f",
		"email": "ejshannon4@gmail.com",
		"name": "E.J.",
		"createdDate": "2014-10-01 22:18:53",
		"totalOrganizations": 9,
		"totalTours": 9,
		"totalDays": 243,
		"totalCountries": 13,
		"totalStates": 54,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 1631,
		"schedulePercent": 0.649318,
		"totalGuests": 168,
		"guestPercent": 0.528804,
		"mostGuestDate": "2023-05-23 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 14
	},
	{
		"id": "b33b7ba",
		"email": "samuelgidley@gmail.com",
		"name": "Sam",
		"createdDate": "2014-06-24 19:17:50",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 104,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 727,
		"schedulePercent": 0.391604,
		"totalGuests": 30,
		"guestPercent": 0.389485,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Blue Gate Theatre",
		"mostGuestCity": "Shipshewana",
		"mostGuestAmount": 12
	},
	{
		"id": "1a15787",
		"email": "gary@tourprologic.com",
		"name": "Gary",
		"createdDate": "2013-06-14 19:29:30",
		"totalOrganizations": 26,
		"totalTours": 22,
		"totalDays": 815,
		"totalCountries": 15,
		"totalStates": 68,
		"totalEvents": 398,
		"eventPercent": 0.956959,
		"totalScheduleItems": 4733,
		"schedulePercent": 0.952986,
		"totalGuests": 1116,
		"guestPercent": 0.842008,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 65
	},
	{
		"id": "14c0479",
		"email": "baileyunterwagner@gmail.com",
		"name": "Bailey",
		"createdDate": "2023-07-06 19:14:08",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 121,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 556,
		"schedulePercent": 0.32446,
		"totalGuests": 373,
		"guestPercent": 0.642564,
		"mostGuestDate": "2023-09-21 00:00:00",
		"mostGuestName": "The Loft at Center Stage",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 57
	},
	{
		"id": "2ac0eed",
		"email": "milkychance.hq@gmail.com",
		"name": "Rob",
		"createdDate": "2019-11-05 23:06:17",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 279,
		"totalCountries": 10,
		"totalStates": 55,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 2912,
		"schedulePercent": 0.843597,
		"totalGuests": 1711,
		"guestPercent": 0.913919,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 142
	},
	{
		"id": "bde6be2",
		"email": "mark@marksydow.com",
		"name": "Mark",
		"createdDate": "2016-06-15 02:48:56",
		"totalOrganizations": 13,
		"totalTours": 7,
		"totalDays": 308,
		"totalCountries": 8,
		"totalStates": 20,
		"totalEvents": 225,
		"eventPercent": 0.842008,
		"totalScheduleItems": 1133,
		"schedulePercent": 0.520726,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "Missing Gorilla",
		"mostGuestCity": "Eltham",
		"mostGuestAmount": 2
	},
	{
		"id": "6454e4d",
		"email": "mmiley@me.com",
		"name": "Matt",
		"createdDate": "2013-05-11 16:01:32",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 271,
		"totalCountries": 17,
		"totalStates": 32,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 460,
		"schedulePercent": 0.280625,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "93c65f5",
		"email": "maxbrownmusic@gmail.com",
		"name": "Max",
		"createdDate": "2018-04-17 16:34:32",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 363,
		"totalCountries": 5,
		"totalStates": 40,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 1401,
		"schedulePercent": 0.591445,
		"totalGuests": 47,
		"guestPercent": 0.417031,
		"mostGuestDate": "2023-09-24 00:00:00",
		"mostGuestName": "Neptune Theatre",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 8
	},
	{
		"id": "645580e",
		"email": "levellerstour@hotmail.com",
		"name": "Jason",
		"createdDate": "2021-11-05 17:11:59",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 29,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 331,
		"schedulePercent": 0.224871,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-02-27 00:00:00",
		"mostGuestName": "Cheltenham Town Hall",
		"mostGuestCity": "Cheltenham",
		"mostGuestAmount": 8
	},
	{
		"id": "610844a",
		"email": "davidglennontour@gmail.com",
		"name": "David",
		"createdDate": "2014-03-03 00:29:08",
		"totalOrganizations": 15,
		"totalTours": 5,
		"totalDays": 174,
		"totalCountries": 1,
		"totalStates": 29,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 737,
		"schedulePercent": 0.394385,
		"totalGuests": 123,
		"guestPercent": 0.492782,
		"mostGuestDate": "2023-10-22 00:00:00",
		"mostGuestName": "The Carlyle Room",
		"mostGuestCity": "Washington DC",
		"mostGuestAmount": 19
	},
	{
		"id": "44fc552",
		"email": "lovelythetm@gmail.com",
		"name": "David",
		"createdDate": "2022-09-30 01:40:27",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 320,
		"totalCountries": 5,
		"totalStates": 27,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 478,
		"schedulePercent": 0.288439,
		"totalGuests": 480,
		"guestPercent": 0.683353,
		"mostGuestDate": "2023-06-28 00:00:00",
		"mostGuestName": "The Studio at Webster Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 187
	},
	{
		"id": "35b768d",
		"email": "michael@michaelhausman.com",
		"name": "Michael",
		"createdDate": "2017-05-10 21:00:41",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 124,
		"totalCountries": 7,
		"totalStates": 19,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 453,
		"schedulePercent": 0.277844,
		"totalGuests": 85,
		"guestPercent": 0.459144,
		"mostGuestDate": "2023-09-25 00:00:00",
		"mostGuestName": "City Winery",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 12
	},
	{
		"id": "2e4202f",
		"email": "matt@mattclery.com",
		"name": "Matthew",
		"createdDate": "2011-05-05 12:19:39",
		"totalOrganizations": 17,
		"totalTours": 13,
		"totalDays": 665,
		"totalCountries": 3,
		"totalStates": 41,
		"totalEvents": 134,
		"eventPercent": 0.658721,
		"totalScheduleItems": 2613,
		"schedulePercent": 0.810489,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d38ca44",
		"email": "jessflomas@gmail.com",
		"name": "Jess",
		"createdDate": "2019-05-08 22:32:04",
		"totalOrganizations": 19,
		"totalTours": 40,
		"totalDays": 1136,
		"totalCountries": 24,
		"totalStates": 101,
		"totalEvents": 430,
		"eventPercent": 0.964111,
		"totalScheduleItems": 6273,
		"schedulePercent": 0.983181,
		"totalGuests": 1296,
		"guestPercent": 0.871408,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 125
	},
	{
		"id": "a6877e5",
		"email": "amanda@thatsciencegang.com",
		"name": "Amanda",
		"createdDate": "2021-12-17 00:42:24",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 584,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 498,
		"schedulePercent": 0.297576,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "39c8eae",
		"email": "jeffmatz@gmail.com",
		"name": "Jeff",
		"createdDate": "2014-07-24 10:36:13",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 22,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 120,
		"schedulePercent": 0.102635,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "86d9b0d",
		"email": "MaryCatherine@wmg.com",
		"name": "Mary Catherine",
		"createdDate": "2017-06-01 20:13:42",
		"totalOrganizations": 50,
		"totalTours": 46,
		"totalDays": 15376,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 2006,
		"eventPercent": 0.996557,
		"totalScheduleItems": 1872,
		"schedulePercent": 0.699775,
		"totalGuests": 26,
		"guestPercent": 0.381274,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Music Farm",
		"mostGuestCity": "Charleston",
		"mostGuestAmount": 7
	},
	{
		"id": "0fb0415",
		"email": "Austinlbriggs@gmail.com",
		"name": "Austin",
		"createdDate": "2023-03-25 17:30:06",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 136,
		"totalCountries": 10,
		"totalStates": 34,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 853,
		"schedulePercent": 0.438485,
		"totalGuests": 58,
		"guestPercent": 0.431863,
		"mostGuestDate": "2023-06-26 00:00:00",
		"mostGuestName": "Vancouver Playhouse",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 20
	},
	{
		"id": "2d2939f",
		"email": "adrian.odle@gmail.com",
		"name": "Adrian",
		"createdDate": "2016-05-04 02:07:36",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 38,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 133,
		"schedulePercent": 0.109389,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f72f592",
		"email": "produccion.raigoza@gmail.com",
		"name": "Julian",
		"createdDate": "2019-01-26 15:09:14",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 592,
		"totalCountries": 7,
		"totalStates": 47,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 2643,
		"schedulePercent": 0.813932,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "333623a",
		"email": "james@blackcaselive.com",
		"name": "james ",
		"createdDate": "2022-01-13 13:51:03",
		"totalOrganizations": 13,
		"totalTours": 32,
		"totalDays": 277,
		"totalCountries": 20,
		"totalStates": 34,
		"totalEvents": 145,
		"eventPercent": 0.688518,
		"totalScheduleItems": 1503,
		"schedulePercent": 0.618858,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a9bf391",
		"email": "Jackson@indegoot.com",
		"name": "Jackson",
		"createdDate": "2018-03-16 15:06:33",
		"totalOrganizations": 9,
		"totalTours": 15,
		"totalDays": 437,
		"totalCountries": 14,
		"totalStates": 68,
		"totalEvents": 230,
		"eventPercent": 0.849159,
		"totalScheduleItems": 3738,
		"schedulePercent": 0.909019,
		"totalGuests": 933,
		"guestPercent": 0.80784,
		"mostGuestDate": "2023-03-26 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 40
	},
	{
		"id": "316e22e",
		"email": "reuben@gigatone.com.au",
		"name": "Reuben",
		"createdDate": "2022-01-12 06:21:38",
		"totalOrganizations": 10,
		"totalTours": 22,
		"totalDays": 966,
		"totalCountries": 8,
		"totalStates": 25,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 1189,
		"schedulePercent": 0.537015,
		"totalGuests": 63,
		"guestPercent": 0.437558,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "Lansdowne Hotel",
		"mostGuestCity": "Chippendale",
		"mostGuestAmount": 18
	},
	{
		"id": "ea18df1",
		"email": "hayleyc@punchbowlent.com",
		"name": "Hayley",
		"createdDate": "2023-04-07 15:02:43",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 204,
		"totalCountries": 3,
		"totalStates": 41,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 1087,
		"schedulePercent": 0.509072,
		"totalGuests": 437,
		"guestPercent": 0.667196,
		"mostGuestDate": "2023-12-07 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 33
	},
	{
		"id": "6e4cf54",
		"email": "teo@fullsteam.fi",
		"name": "Teo",
		"createdDate": "2022-05-03 09:11:03",
		"totalOrganizations": 92,
		"totalTours": 66,
		"totalDays": 1048,
		"totalCountries": 3,
		"totalStates": 4,
		"totalEvents": 761,
		"eventPercent": 0.987419,
		"totalScheduleItems": 2416,
		"schedulePercent": 0.782016,
		"totalGuests": 31,
		"guestPercent": 0.390942,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "KesÃ¤rauha",
		"mostGuestCity": "Turku",
		"mostGuestAmount": 5
	},
	{
		"id": "2b09ae3",
		"email": "natehndrsn@gmail.com",
		"name": "Nathaniel ",
		"createdDate": "2018-05-24 20:18:37",
		"totalOrganizations": 12,
		"totalTours": 5,
		"totalDays": 393,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 1592,
		"schedulePercent": 0.640313,
		"totalGuests": 546,
		"guestPercent": 0.707853,
		"mostGuestDate": "2023-04-27 00:00:00",
		"mostGuestName": "Schoepf's Old Time Bar-B-Que",
		"mostGuestCity": "Belton",
		"mostGuestAmount": 85
	},
	{
		"id": "28b3601",
		"email": "evelynebrouillard@gmail.com",
		"name": "Evelyne",
		"createdDate": "2022-04-05 20:52:02",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 98,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 633,
		"schedulePercent": 0.35545,
		"totalGuests": 1751,
		"guestPercent": 0.916965,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "ThÃ©Ã¢tre St-Denis",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 204
	},
	{
		"id": "336b060",
		"email": "tonybones86@gmail.com",
		"name": "Anthony",
		"createdDate": "2012-11-12 18:51:38",
		"totalOrganizations": 5,
		"totalTours": 12,
		"totalDays": 276,
		"totalCountries": 16,
		"totalStates": 52,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 1888,
		"schedulePercent": 0.702953,
		"totalGuests": 1096,
		"guestPercent": 0.839094,
		"mostGuestDate": "2023-07-10 00:00:00",
		"mostGuestName": "Somerset House",
		"mostGuestCity": "London",
		"mostGuestAmount": 121
	},
	{
		"id": "fcb2603",
		"email": "Scottlawsontouring@gmail.com",
		"name": "Scott",
		"createdDate": "2019-12-09 19:44:49",
		"totalOrganizations": 10,
		"totalTours": 6,
		"totalDays": 307,
		"totalCountries": 10,
		"totalStates": 24,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 1948,
		"schedulePercent": 0.711561,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-06-09 00:00:00",
		"mostGuestName": "Academy of Arts",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 8
	},
	{
		"id": "ad22593",
		"email": "Victoria.Nugent@wmg.com",
		"name": "Victoria",
		"createdDate": "2017-08-10 03:14:02",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 1667,
		"totalCountries": 7,
		"totalStates": 48,
		"totalEvents": 294,
		"eventPercent": 0.9118,
		"totalScheduleItems": 6,
		"schedulePercent": 0.0164217,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4860c4a",
		"email": "ashleigh.volz@me.com",
		"name": "Ashleigh",
		"createdDate": "2023-01-04 17:53:15",
		"totalOrganizations": 5,
		"totalTours": 12,
		"totalDays": 724,
		"totalCountries": 19,
		"totalStates": 55,
		"totalEvents": 213,
		"eventPercent": 0.827837,
		"totalScheduleItems": 2141,
		"schedulePercent": 0.740564,
		"totalGuests": 373,
		"guestPercent": 0.642564,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 75
	},
	{
		"id": "8f3d9cd",
		"email": "alexpineiroprod@gmail.com",
		"name": "Alexandra",
		"createdDate": "2022-06-27 16:54:43",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 122,
		"totalCountries": 18,
		"totalStates": 41,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 1119,
		"schedulePercent": 0.515958,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "58663f7",
		"email": "ulrich.kastner@salutclassique.de",
		"name": "Ulrich",
		"createdDate": "2023-01-20 16:23:00",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 339,
		"totalCountries": 3,
		"totalStates": 8,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 475,
		"schedulePercent": 0.287247,
		"totalGuests": 265,
		"guestPercent": 0.586942,
		"mostGuestDate": "2023-10-03 00:00:00",
		"mostGuestName": "Berliner Philharmonie",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 25
	},
	{
		"id": "53f5e49",
		"email": "lance@avlx.com",
		"name": "Lance",
		"createdDate": "2019-03-11 19:36:44",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 30,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 81,
		"schedulePercent": 0.075884,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-08-01 00:00:00",
		"mostGuestName": "Madison Square Garden",
		"mostGuestCity": "New York",
		"mostGuestAmount": 1
	},
	{
		"id": "14247ec",
		"email": "macmcaree@gmail.com",
		"name": "Michael",
		"createdDate": "2019-04-17 17:37:18",
		"totalOrganizations": 18,
		"totalTours": 16,
		"totalDays": 768,
		"totalCountries": 14,
		"totalStates": 56,
		"totalEvents": 229,
		"eventPercent": 0.847305,
		"totalScheduleItems": 4833,
		"schedulePercent": 0.956562,
		"totalGuests": 444,
		"guestPercent": 0.669845,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "LiveNation Pavilion At Toyota Music Factory",
		"mostGuestCity": "Irving",
		"mostGuestAmount": 51
	},
	{
		"id": "d668f59",
		"email": "tom@zweite-heimat-tourservice.de",
		"name": "Tom",
		"createdDate": "2023-11-06 10:11:42",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 52,
		"totalCountries": 8,
		"totalStates": 14,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 558,
		"schedulePercent": 0.32499,
		"totalGuests": 768,
		"guestPercent": 0.769037,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "Arena Leipzig",
		"mostGuestCity": "Leipzig",
		"mostGuestAmount": 143
	},
	{
		"id": "2202005",
		"email": "dontfeedaftermidnightllc@gmail.com",
		"name": "Matthew",
		"createdDate": "2021-07-21 22:27:03",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 188,
		"totalCountries": 9,
		"totalStates": 33,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 536,
		"schedulePercent": 0.315455,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-07-05 00:00:00",
		"mostGuestName": "The State Theatre",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 6
	},
	{
		"id": "d9b88ed",
		"email": "ninatomayko@gmail.com",
		"name": "Nina",
		"createdDate": "2017-02-15 03:53:19",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 227,
		"totalCountries": 5,
		"totalStates": 16,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 1254,
		"schedulePercent": 0.553966,
		"totalGuests": 1246,
		"guestPercent": 0.864389,
		"mostGuestDate": "2023-04-20 00:00:00",
		"mostGuestName": "KFC Yum! Center",
		"mostGuestCity": "Louisville",
		"mostGuestAmount": 154
	},
	{
		"id": "46c1373",
		"email": "cara@folketeatret.dk",
		"name": "Caspar",
		"createdDate": "2022-12-28 18:21:04",
		"totalOrganizations": 23,
		"totalTours": 5,
		"totalDays": 353,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 227,
		"eventPercent": 0.844391,
		"totalScheduleItems": 1279,
		"schedulePercent": 0.559926,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6279b06",
		"email": "vbertry@gmail.com",
		"name": "VERONIQUE",
		"createdDate": "2023-03-09 15:09:23",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 233,
		"totalCountries": 13,
		"totalStates": 27,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 897,
		"schedulePercent": 0.452523,
		"totalGuests": 569,
		"guestPercent": 0.715799,
		"mostGuestDate": "2023-08-09 00:00:00",
		"mostGuestName": "Plaza de Toros Alicante",
		"mostGuestCity": "Alacant",
		"mostGuestAmount": 28
	},
	{
		"id": "f45428c",
		"email": "jwong@sfjazz.org",
		"name": "Jeanette ",
		"createdDate": "2022-02-18 00:41:45",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 52,
		"totalCountries": 3,
		"totalStates": 6,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 222,
		"schedulePercent": 0.164879,
		"totalGuests": 31,
		"guestPercent": 0.390942,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "Birdland",
		"mostGuestCity": "New York",
		"mostGuestAmount": 9
	},
	{
		"id": "fad2ae7",
		"email": "benrobinson@hdpproductions.co.uk",
		"name": "Ben",
		"createdDate": "2014-05-28 21:27:40",
		"totalOrganizations": 11,
		"totalTours": 21,
		"totalDays": 866,
		"totalCountries": 18,
		"totalStates": 75,
		"totalEvents": 280,
		"eventPercent": 0.901867,
		"totalScheduleItems": 4655,
		"schedulePercent": 0.949808,
		"totalGuests": 1029,
		"guestPercent": 0.828764,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 112
	},
	{
		"id": "62ce9e4",
		"email": "anthony@smoothmanmusic.com",
		"name": "Anthony",
		"createdDate": "2023-03-15 16:25:43",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 46,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 163,
		"schedulePercent": 0.128857,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a601d09",
		"email": "hellspawnsound@icloud.com",
		"name": "Anthony",
		"createdDate": "2018-02-16 22:07:20",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 108,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 392,
		"schedulePercent": 0.254139,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Central Park of Maple Grove",
		"mostGuestCity": "Maple Grove",
		"mostGuestAmount": 2
	},
	{
		"id": "d716004",
		"email": "clairerandazzo@gmail.com",
		"name": "Claire",
		"createdDate": "2019-10-14 17:42:34",
		"totalOrganizations": 9,
		"totalTours": 33,
		"totalDays": 174,
		"totalCountries": 4,
		"totalStates": 13,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 756,
		"schedulePercent": 0.405244,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-11-02 00:00:00",
		"mostGuestName": "Fox Theatre",
		"mostGuestCity": "Boulder",
		"mostGuestAmount": 2
	},
	{
		"id": "286099f",
		"email": "info@sandboxmgmt.com",
		"name": "Sandbox",
		"createdDate": "2015-10-13 21:42:31",
		"totalOrganizations": 13,
		"totalTours": 6,
		"totalDays": 1588,
		"totalCountries": 7,
		"totalStates": 49,
		"totalEvents": 243,
		"eventPercent": 0.864786,
		"totalScheduleItems": 4189,
		"schedulePercent": 0.933519,
		"totalGuests": 1349,
		"guestPercent": 0.877367,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Nissan Stadium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 90
	},
	{
		"id": "72e36d0",
		"email": "fhaincourt@gmail.com",
		"name": "Fran",
		"createdDate": "2016-05-19 00:02:02",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 108,
		"totalCountries": 13,
		"totalStates": 27,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 730,
		"schedulePercent": 0.392266,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "046a7b4",
		"email": "youngbiz@theboostfirm.com",
		"name": "Lawrence",
		"createdDate": "2022-11-29 23:13:48",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 646,
		"totalCountries": 6,
		"totalStates": 33,
		"totalEvents": 137,
		"eventPercent": 0.666402,
		"totalScheduleItems": 2153,
		"schedulePercent": 0.742948,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "356bf3f",
		"email": "justinhell@mac.com",
		"name": "Justin",
		"createdDate": "2022-08-07 06:59:40",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 80,
		"totalCountries": 13,
		"totalStates": 21,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 330,
		"schedulePercent": 0.224209,
		"totalGuests": 207,
		"guestPercent": 0.555953,
		"mostGuestDate": "2023-09-13 00:00:00",
		"mostGuestName": "Scala",
		"mostGuestCity": "London",
		"mostGuestAmount": 51
	},
	{
		"id": "f703c8f",
		"email": "Kris.Heinold@me.com",
		"name": "Kris",
		"createdDate": "2014-01-12 22:18:31",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 626,
		"totalCountries": 5,
		"totalStates": 47,
		"totalEvents": 206,
		"eventPercent": 0.816183,
		"totalScheduleItems": 3021,
		"schedulePercent": 0.852867,
		"totalGuests": 924,
		"guestPercent": 0.804794,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Giant Center",
		"mostGuestCity": "Hershey",
		"mostGuestAmount": 72
	},
	{
		"id": "e405d80",
		"email": "ponderosamusic@gmail.com",
		"name": "Stefano",
		"createdDate": "2019-02-04 14:50:25",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 143,
		"totalCountries": 13,
		"totalStates": 22,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 1271,
		"schedulePercent": 0.557277,
		"totalGuests": 559,
		"guestPercent": 0.713415,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Vivo Rio",
		"mostGuestCity": "Rio de Janeiro",
		"mostGuestAmount": 26
	},
	{
		"id": "be96d01",
		"email": "josh@endeavour.live",
		"name": "Joshua",
		"createdDate": "2021-07-22 23:48:22",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 243,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 13,
		"eventPercent": 0.0892597,
		"totalScheduleItems": 323,
		"schedulePercent": 0.220501,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2b97e88",
		"email": "williampepple@gmail.com",
		"name": "William",
		"createdDate": "2013-07-03 13:28:55",
		"totalOrganizations": 8,
		"totalTours": 23,
		"totalDays": 444,
		"totalCountries": 11,
		"totalStates": 44,
		"totalEvents": 178,
		"eventPercent": 0.767051,
		"totalScheduleItems": 2809,
		"schedulePercent": 0.83287,
		"totalGuests": 2164,
		"guestPercent": 0.94173,
		"mostGuestDate": "2023-08-23 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 310
	},
	{
		"id": "9b0b90c",
		"email": "diane@3dfamily.org",
		"name": "3D",
		"createdDate": "2023-09-22 09:32:45",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 88,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 13,
		"eventPercent": 0.0892597,
		"totalScheduleItems": 139,
		"schedulePercent": 0.113362,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "eb128c8",
		"email": "lewisleft@gmail.com",
		"name": "Lewis",
		"createdDate": "2016-02-24 19:06:18",
		"totalOrganizations": 17,
		"totalTours": 5,
		"totalDays": 114,
		"totalCountries": 5,
		"totalStates": 13,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 716,
		"schedulePercent": 0.386836,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-02-19 00:00:00",
		"mostGuestName": "Melkweg",
		"mostGuestCity": "Amsterdam",
		"mostGuestAmount": 3
	},
	{
		"id": "5503f0e",
		"email": "timbrehawk@gmail.com",
		"name": "Connor",
		"createdDate": "2017-05-24 10:07:12",
		"totalOrganizations": 8,
		"totalTours": 11,
		"totalDays": 446,
		"totalCountries": 12,
		"totalStates": 54,
		"totalEvents": 225,
		"eventPercent": 0.842008,
		"totalScheduleItems": 3234,
		"schedulePercent": 0.872467,
		"totalGuests": 825,
		"guestPercent": 0.782281,
		"mostGuestDate": "2023-01-26 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 80
	},
	{
		"id": "a7c8654",
		"email": "rox@troycassardaley.com.au",
		"name": "Roxanne",
		"createdDate": "2014-07-21 02:25:15",
		"totalOrganizations": 7,
		"totalTours": 43,
		"totalDays": 1063,
		"totalCountries": 4,
		"totalStates": 19,
		"totalEvents": 183,
		"eventPercent": 0.775659,
		"totalScheduleItems": 2377,
		"schedulePercent": 0.776983,
		"totalGuests": 706,
		"guestPercent": 0.754734,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Rockhampton Riverfront Precinct",
		"mostGuestCity": "Rockhampton",
		"mostGuestAmount": 388
	},
	{
		"id": "648260c",
		"email": "ashduncan13@gmail.com",
		"name": "Ash",
		"createdDate": "2018-01-31 20:38:54",
		"totalOrganizations": 3,
		"totalTours": 10,
		"totalDays": 434,
		"totalCountries": 9,
		"totalStates": 42,
		"totalEvents": 187,
		"eventPercent": 0.784135,
		"totalScheduleItems": 2507,
		"schedulePercent": 0.794199,
		"totalGuests": 1365,
		"guestPercent": 0.879354,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 137
	},
	{
		"id": "d054a6d",
		"email": "Scatteredsound@gmail.com",
		"name": "Will",
		"createdDate": "2014-07-25 19:04:50",
		"totalOrganizations": 11,
		"totalTours": 16,
		"totalDays": 115,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 628,
		"schedulePercent": 0.353331,
		"totalGuests": 26,
		"guestPercent": 0.381274,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "Albion Fairgrounds",
		"mostGuestCity": "Maple Ridge",
		"mostGuestAmount": 11
	},
	{
		"id": "c9eab62",
		"email": "rob@robhaberman.com",
		"name": "Rob",
		"createdDate": "2022-02-10 16:59:53",
		"totalOrganizations": 7,
		"totalTours": 4,
		"totalDays": 145,
		"totalCountries": 2,
		"totalStates": 41,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 1983,
		"schedulePercent": 0.716461,
		"totalGuests": 670,
		"guestPercent": 0.744272,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "The Studio at Webster Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 54
	},
	{
		"id": "9bd24c7",
		"email": "kevin.cantillon@step.com",
		"name": "Kevin",
		"createdDate": "2022-08-15 16:20:34",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 204,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 336,
		"schedulePercent": 0.226593,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ffc219d",
		"email": "ryanmessickpdx@gmail.com",
		"name": "Ryan",
		"createdDate": "2014-09-03 00:24:37",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 1164,
		"totalCountries": 3,
		"totalStates": 44,
		"totalEvents": 137,
		"eventPercent": 0.666402,
		"totalScheduleItems": 2229,
		"schedulePercent": 0.755264,
		"totalGuests": 165,
		"guestPercent": 0.526818,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Centerstage- The Loft",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 24
	},
	{
		"id": "33cacc2",
		"email": "yevhen.starentertainment@gmail.com",
		"name": "Yevhen",
		"createdDate": "2022-12-25 22:40:00",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 294,
		"totalCountries": 15,
		"totalStates": 41,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 1638,
		"schedulePercent": 0.650642,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b200c22",
		"email": "mike.young@europe.com",
		"name": "Michael",
		"createdDate": "2017-04-27 10:24:05",
		"totalOrganizations": 12,
		"totalTours": 11,
		"totalDays": 1335,
		"totalCountries": 24,
		"totalStates": 56,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 1636,
		"schedulePercent": 0.649848,
		"totalGuests": 190,
		"guestPercent": 0.542445,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "Kirstenbosch National Botanical Garden",
		"mostGuestCity": "Cape Town",
		"mostGuestAmount": 48
	},
	{
		"id": "e6a149a",
		"email": "mikesabino1969@outlook.com",
		"name": "Michael",
		"createdDate": "2022-04-26 21:17:25",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 148,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 180,
		"schedulePercent": 0.138392,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1ef1e8e",
		"email": "jameshua.recording@gmail.com",
		"name": "Jimmy",
		"createdDate": "2021-10-15 01:56:52",
		"totalOrganizations": 8,
		"totalTours": 20,
		"totalDays": 396,
		"totalCountries": 17,
		"totalStates": 53,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 1982,
		"schedulePercent": 0.716064,
		"totalGuests": 1479,
		"guestPercent": 0.891538,
		"mostGuestDate": "2023-11-06 00:00:00",
		"mostGuestName": "Avant Gardner",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 124
	},
	{
		"id": "6f0969e",
		"email": "tour@rubenandclay.com",
		"name": "Cheryl",
		"createdDate": "2023-01-20 00:32:25",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 341,
		"totalCountries": 2,
		"totalStates": 33,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 969,
		"schedulePercent": 0.471063,
		"totalGuests": 405,
		"guestPercent": 0.655145,
		"mostGuestDate": "2023-04-29 00:00:00",
		"mostGuestName": "Koka Booth Amphitheatre",
		"mostGuestCity": "Cary",
		"mostGuestAmount": 93
	},
	{
		"id": "5bed17b",
		"email": "bandafabricio@gmail.com",
		"name": "Hector",
		"createdDate": "2023-07-12 05:23:29",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 139,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 335,
		"schedulePercent": 0.225798,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cc935ae",
		"email": "liss@bmusicagency.com",
		"name": "Melissa",
		"createdDate": "2023-09-26 04:55:48",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 51,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 116,
		"schedulePercent": 0.100384,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d64d203",
		"email": "Declanmuldoondrums@gmail.com",
		"name": "Declan",
		"createdDate": "2018-04-13 23:52:35",
		"totalOrganizations": 10,
		"totalTours": 1,
		"totalDays": 61,
		"totalCountries": 3,
		"totalStates": 17,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 216,
		"schedulePercent": 0.160509,
		"totalGuests": 103,
		"guestPercent": 0.478082,
		"mostGuestDate": "2023-03-16 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 25
	},
	{
		"id": "5243f8a",
		"email": "artist.coordinator@gentrytouring.com",
		"name": "Artist ",
		"createdDate": "2022-01-24 19:57:38",
		"totalOrganizations": 10,
		"totalTours": 9,
		"totalDays": 1445,
		"totalCountries": 4,
		"totalStates": 42,
		"totalEvents": 217,
		"eventPercent": 0.833267,
		"totalScheduleItems": 4304,
		"schedulePercent": 0.938021,
		"totalGuests": 1438,
		"guestPercent": 0.885843,
		"mostGuestDate": "2023-05-03 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 125
	},
	{
		"id": "12ac777",
		"email": "stefan@gigcontrol.com.au",
		"name": "Stefan",
		"createdDate": "2022-06-28 04:37:31",
		"totalOrganizations": 9,
		"totalTours": 40,
		"totalDays": 745,
		"totalCountries": 11,
		"totalStates": 33,
		"totalEvents": 207,
		"eventPercent": 0.817905,
		"totalScheduleItems": 1507,
		"schedulePercent": 0.619918,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-08-05 00:00:00",
		"mostGuestName": "Margaret Court Arena",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 12
	},
	{
		"id": "9b3eb92",
		"email": "emma@gryffinofficial.com",
		"name": "Emma",
		"createdDate": "2022-03-05 01:31:16",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 395,
		"totalCountries": 16,
		"totalStates": 48,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 1686,
		"schedulePercent": 0.661237,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "52e80c6",
		"email": "aymeric@teckoff.com",
		"name": "Aymeric",
		"createdDate": "2018-05-09 18:04:00",
		"totalOrganizations": 19,
		"totalTours": 17,
		"totalDays": 1644,
		"totalCountries": 6,
		"totalStates": 36,
		"totalEvents": 360,
		"eventPercent": 0.946497,
		"totalScheduleItems": 5540,
		"schedulePercent": 0.973911,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8ee3b33",
		"email": "jkevinpurvis@gmail.com",
		"name": "Kevin",
		"createdDate": "2021-04-21 02:35:07",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 664,
		"totalCountries": 8,
		"totalStates": 47,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1114,
		"schedulePercent": 0.514899,
		"totalGuests": 1220,
		"guestPercent": 0.860681,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 102
	},
	{
		"id": "3e68075",
		"email": "scott@onlytours.co.uk",
		"name": "Scott",
		"createdDate": "2016-06-24 19:56:38",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 360,
		"totalCountries": 23,
		"totalStates": 59,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 2312,
		"schedulePercent": 0.767051,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7ac6714",
		"email": "Rob@nubsound.co.uk",
		"name": "Rob",
		"createdDate": "2016-05-11 21:50:09",
		"totalOrganizations": 6,
		"totalTours": 26,
		"totalDays": 189,
		"totalCountries": 8,
		"totalStates": 19,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 969,
		"schedulePercent": 0.471063,
		"totalGuests": 559,
		"guestPercent": 0.713415,
		"mostGuestDate": "2023-11-29 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 222
	},
	{
		"id": "a8d5cc2",
		"email": "benni@benjaminsill.de",
		"name": "Benjamin",
		"createdDate": "2019-04-11 19:46:36",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 147,
		"totalCountries": 4,
		"totalStates": 12,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 1575,
		"schedulePercent": 0.635677,
		"totalGuests": 1614,
		"guestPercent": 0.904648,
		"mostGuestDate": "2023-07-19 00:00:00",
		"mostGuestName": "Schlossplatz Stuttgart",
		"mostGuestCity": "Stuttgart",
		"mostGuestAmount": 214
	},
	{
		"id": "2b6476f",
		"email": "gaelicstormtourm@gmail.com",
		"name": "Patrick",
		"createdDate": "2021-06-17 13:43:31",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 134,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 675,
		"schedulePercent": 0.370547,
		"totalGuests": 68,
		"guestPercent": 0.444312,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "The Bijou Theater",
		"mostGuestCity": "Knoxville",
		"mostGuestAmount": 4
	},
	{
		"id": "2c44ad6",
		"email": "letmiirokoutllc@icloud.com",
		"name": "Vic",
		"createdDate": "2023-08-23 20:03:06",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 172,
		"totalCountries": 3,
		"totalStates": 21,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 88,
		"schedulePercent": 0.0805191,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9636186",
		"email": "ManagementPgh@gmail.com",
		"name": "Chris ",
		"createdDate": "2018-03-27 21:44:16",
		"totalOrganizations": 8,
		"totalTours": 12,
		"totalDays": 281,
		"totalCountries": 4,
		"totalStates": 54,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 1872,
		"schedulePercent": 0.699775,
		"totalGuests": 962,
		"guestPercent": 0.816183,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 116
	},
	{
		"id": "bbc1ca0",
		"email": "toddy@kj.de",
		"name": "Thorsten",
		"createdDate": "2016-10-10 11:11:33",
		"totalOrganizations": 89,
		"totalTours": 41,
		"totalDays": 658,
		"totalCountries": 12,
		"totalStates": 44,
		"totalEvents": 267,
		"eventPercent": 0.892729,
		"totalScheduleItems": 1856,
		"schedulePercent": 0.696332,
		"totalGuests": 917,
		"guestPercent": 0.803337,
		"mostGuestDate": "2023-03-04 00:00:00",
		"mostGuestName": "Laeiszhalle",
		"mostGuestCity": "Hamburg",
		"mostGuestAmount": 49
	},
	{
		"id": "508da42",
		"email": "izzy@capotactic.com",
		"name": "Lauren",
		"createdDate": "2020-02-20 21:28:24",
		"totalOrganizations": 5,
		"totalTours": 11,
		"totalDays": 172,
		"totalCountries": 5,
		"totalStates": 37,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 1547,
		"schedulePercent": 0.629188,
		"totalGuests": 1211,
		"guestPercent": 0.859092,
		"mostGuestDate": "2023-05-07 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 75
	},
	{
		"id": "59fdca2",
		"email": "Mike@badmood.co",
		"name": "Mike",
		"createdDate": "2016-08-02 19:28:21",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 5,
		"totalStates": 22,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 654,
		"schedulePercent": 0.363528,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bdf7f83",
		"email": "janderson@mixedbymoe.com",
		"name": "Jorton",
		"createdDate": "2023-06-27 18:00:04",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 533,
		"totalCountries": 6,
		"totalStates": 23,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 416,
		"schedulePercent": 0.263144,
		"totalGuests": 16,
		"guestPercent": 0.361012,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Au-Rene Theater at Broward Center For The Performing Arts",
		"mostGuestCity": "Fort Lauderdale",
		"mostGuestAmount": 16
	},
	{
		"id": "6004d30",
		"email": "salonithakkar07@gmail.com",
		"name": "Saloni",
		"createdDate": "2021-07-28 07:36:43",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 28,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 170,
		"schedulePercent": 0.132698,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c632cc4",
		"email": "info@avpro.fi",
		"name": "AV Pro Oy",
		"createdDate": "2015-10-28 08:22:10",
		"totalOrganizations": 29,
		"totalTours": 24,
		"totalDays": 907,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 807,
		"eventPercent": 0.987949,
		"totalScheduleItems": 3239,
		"schedulePercent": 0.873129,
		"totalGuests": 26,
		"guestPercent": 0.381274,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Ruisrock",
		"mostGuestCity": "Turku",
		"mostGuestAmount": 6
	},
	{
		"id": "19f22a7",
		"email": "hellomarklynch@gmail.com",
		"name": "Mark",
		"createdDate": "2012-01-05 23:44:35",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 267,
		"totalCountries": 4,
		"totalStates": 22,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 473,
		"schedulePercent": 0.286187,
		"totalGuests": 68,
		"guestPercent": 0.444312,
		"mostGuestDate": "2023-08-16 00:00:00",
		"mostGuestName": "The Venice West",
		"mostGuestCity": "Venice",
		"mostGuestAmount": 16
	},
	{
		"id": "a7e52c0",
		"email": "SCOTTF@10THST.COM",
		"name": "BAILEY",
		"createdDate": "2022-09-26 21:18:43",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 269,
		"totalCountries": 3,
		"totalStates": 32,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 2018,
		"schedulePercent": 0.722951,
		"totalGuests": 555,
		"guestPercent": 0.711296,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "South Fork Dirt Riders",
		"mostGuestCity": "Taylorville",
		"mostGuestAmount": 29
	},
	{
		"id": "a1c22e0",
		"email": "bxaiello@gmail.com",
		"name": "Bryan",
		"createdDate": "2009-09-17 20:39:50",
		"totalOrganizations": 28,
		"totalTours": 26,
		"totalDays": 2678,
		"totalCountries": 22,
		"totalStates": 75,
		"totalEvents": 557,
		"eventPercent": 0.980135,
		"totalScheduleItems": 10475,
		"schedulePercent": 0.997749,
		"totalGuests": 1290,
		"guestPercent": 0.870746,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "The Brooklyn Mirage",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 179
	},
	{
		"id": "b2dd934",
		"email": "Mattagreig@gmail.com",
		"name": "Matt",
		"createdDate": "2014-02-20 22:10:23",
		"totalOrganizations": 20,
		"totalTours": 20,
		"totalDays": 690,
		"totalCountries": 19,
		"totalStates": 47,
		"totalEvents": 233,
		"eventPercent": 0.853662,
		"totalScheduleItems": 3368,
		"schedulePercent": 0.884783,
		"totalGuests": 892,
		"guestPercent": 0.797643,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 178
	},
	{
		"id": "e1cc6b1",
		"email": "nathalie.candel@gmail.com",
		"name": "Nathalie",
		"createdDate": "2017-09-07 15:01:25",
		"totalOrganizations": 15,
		"totalTours": 17,
		"totalDays": 303,
		"totalCountries": 18,
		"totalStates": 48,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 2251,
		"schedulePercent": 0.758575,
		"totalGuests": 132,
		"guestPercent": 0.499801,
		"mostGuestDate": "2023-10-03 00:00:00",
		"mostGuestName": "EartH (Evolutionary Arts Hackney)",
		"mostGuestCity": "London",
		"mostGuestAmount": 12
	},
	{
		"id": "907f26c",
		"email": "sean.stemaly@gmail.com",
		"name": "Sean",
		"createdDate": "2022-03-25 18:23:16",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 364,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 213,
		"schedulePercent": 0.158125,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Barnato",
		"mostGuestCity": "Omaha",
		"mostGuestAmount": 2
	},
	{
		"id": "ec51c11",
		"email": "tia@koolandthegang.com",
		"name": "Tia",
		"createdDate": "2022-12-28 04:52:51",
		"totalOrganizations": 2,
		"totalTours": 30,
		"totalDays": 933,
		"totalCountries": 8,
		"totalStates": 29,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 2591,
		"schedulePercent": 0.804794,
		"totalGuests": 444,
		"guestPercent": 0.669845,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "Hard Rock Live at Etess Arena",
		"mostGuestCity": "Atlantic City",
		"mostGuestAmount": 57
	},
	{
		"id": "976384a",
		"email": "tristankirkbride@comcast.net",
		"name": "Tristan",
		"createdDate": "2021-05-16 16:32:14",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 108,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 125,
		"schedulePercent": 0.106079,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ee48b1f",
		"email": "rowan@lostmotel.com",
		"name": "Rowan",
		"createdDate": "2023-03-19 20:39:53",
		"totalOrganizations": 7,
		"totalTours": 18,
		"totalDays": 739,
		"totalCountries": 17,
		"totalStates": 70,
		"totalEvents": 324,
		"eventPercent": 0.927692,
		"totalScheduleItems": 3947,
		"schedulePercent": 0.920938,
		"totalGuests": 1157,
		"guestPercent": 0.850616,
		"mostGuestDate": "2023-06-01 00:00:00",
		"mostGuestName": "The Enmore Theatre",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 107
	},
	{
		"id": "0fd2bdf",
		"email": "amanda@amandalouevents.co.uk",
		"name": "Amanda",
		"createdDate": "2018-03-05 18:20:34",
		"totalOrganizations": 13,
		"totalTours": 13,
		"totalDays": 844,
		"totalCountries": 21,
		"totalStates": 59,
		"totalEvents": 175,
		"eventPercent": 0.761753,
		"totalScheduleItems": 3035,
		"schedulePercent": 0.854456,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f1e6ee9",
		"email": "mfreeland2003@aol.com",
		"name": "Mike",
		"createdDate": "2023-04-19 02:26:52",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 232,
		"totalCountries": 1,
		"totalStates": 28,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 774,
		"schedulePercent": 0.410674,
		"totalGuests": 323,
		"guestPercent": 0.61568,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Schermerhorn Symphony Hall",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 35
	},
	{
		"id": "e291402",
		"email": "jhart792@gmail.com",
		"name": "Jon",
		"createdDate": "2020-02-06 01:17:14",
		"totalOrganizations": 6,
		"totalTours": 11,
		"totalDays": 573,
		"totalCountries": 3,
		"totalStates": 17,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 1101,
		"schedulePercent": 0.51172,
		"totalGuests": 304,
		"guestPercent": 0.605218,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "The Riviera Theatre",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 29
	},
	{
		"id": "6a89a9c",
		"email": "Marcus@bymanproduction.se",
		"name": "Marcus",
		"createdDate": "2023-05-05 09:42:29",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 59,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 512,
		"schedulePercent": 0.305125,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f19050f",
		"email": "mikelaff720@gmail.com",
		"name": "Mike ",
		"createdDate": "2017-08-14 15:41:05",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 108,
		"totalCountries": 12,
		"totalStates": 33,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 623,
		"schedulePercent": 0.350682,
		"totalGuests": 947,
		"guestPercent": 0.811813,
		"mostGuestDate": "2023-04-03 00:00:00",
		"mostGuestName": "Royal Albert Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 102
	},
	{
		"id": "ded26b8",
		"email": "tylerblakeschmid@gmail.com",
		"name": "Tyler",
		"createdDate": "2023-08-10 07:53:05",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 123,
		"totalCountries": 1,
		"totalStates": 24,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 362,
		"schedulePercent": 0.238776,
		"totalGuests": 458,
		"guestPercent": 0.677129,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Showbox SoDo",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 55
	},
	{
		"id": "9696c8f",
		"email": "josh@phaseshiftproductions.com",
		"name": "Josh",
		"createdDate": "2017-12-04 04:20:55",
		"totalOrganizations": 20,
		"totalTours": 51,
		"totalDays": 1110,
		"totalCountries": 23,
		"totalStates": 91,
		"totalEvents": 403,
		"eventPercent": 0.957886,
		"totalScheduleItems": 4753,
		"schedulePercent": 0.953913,
		"totalGuests": 485,
		"guestPercent": 0.685207,
		"mostGuestDate": "2023-01-15 00:00:00",
		"mostGuestName": "Electric Brixton",
		"mostGuestCity": "London",
		"mostGuestAmount": 45
	},
	{
		"id": "a2ecb69",
		"email": "kylegaines@icloud.com",
		"name": "Kyle",
		"createdDate": "2019-09-02 15:23:52",
		"totalOrganizations": 9,
		"totalTours": 12,
		"totalDays": 975,
		"totalCountries": 16,
		"totalStates": 50,
		"totalEvents": 341,
		"eventPercent": 0.937757,
		"totalScheduleItems": 3190,
		"schedulePercent": 0.869024,
		"totalGuests": 196,
		"guestPercent": 0.546947,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "Madison Square Garden",
		"mostGuestCity": "New York",
		"mostGuestAmount": 22
	},
	{
		"id": "275da38",
		"email": "rficara10@gmail.com",
		"name": "Rob",
		"createdDate": "2013-10-04 20:13:20",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 255,
		"totalCountries": 4,
		"totalStates": 22,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 705,
		"schedulePercent": 0.383128,
		"totalGuests": 1114,
		"guestPercent": 0.841743,
		"mostGuestDate": "2023-09-03 00:00:00",
		"mostGuestName": "Waikiki Shell",
		"mostGuestCity": "Honolulu",
		"mostGuestAmount": 112
	},
	{
		"id": "a73402c",
		"email": "jtmtouring@gmail.com",
		"name": "Josh",
		"createdDate": "2015-02-22 14:25:11",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 162,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 894,
		"schedulePercent": 0.451331,
		"totalGuests": 246,
		"guestPercent": 0.576612,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 88
	},
	{
		"id": "6ea6e2c",
		"email": "Jazz@recklesskelly.com",
		"name": "Jay",
		"createdDate": "2019-06-16 14:52:55",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 98,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 473,
		"schedulePercent": 0.286187,
		"totalGuests": 218,
		"guestPercent": 0.561515,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Stubb's BBQ - Waller Creek Amphitheater",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 46
	},
	{
		"id": "fec6c00",
		"email": "spike.anderson1@gmail.com",
		"name": "Spike",
		"createdDate": "2017-05-30 23:49:00",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 47,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 379,
		"schedulePercent": 0.248311,
		"totalGuests": 52,
		"guestPercent": 0.425374,
		"mostGuestDate": "2023-10-25 00:00:00",
		"mostGuestName": "Marathon Music Works",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 48
	},
	{
		"id": "7244068",
		"email": "claydavies94@gmail.com",
		"name": "Clayton",
		"createdDate": "2022-02-07 23:54:08",
		"totalOrganizations": 5,
		"totalTours": 14,
		"totalDays": 408,
		"totalCountries": 6,
		"totalStates": 14,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 987,
		"schedulePercent": 0.479142,
		"totalGuests": 783,
		"guestPercent": 0.772613,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "Factory Theatre",
		"mostGuestCity": "Marrickville",
		"mostGuestAmount": 63
	},
	{
		"id": "adf898e",
		"email": "johann@ayeayemusic.com",
		"name": "Email",
		"createdDate": "2023-02-22 17:33:07",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 83,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 471,
		"schedulePercent": 0.28473,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Gravenhurst Opera House",
		"mostGuestCity": "Gravenhurst",
		"mostGuestAmount": 5
	},
	{
		"id": "b3fd988",
		"email": "callycustard@hotmail.co.uk",
		"name": "Cally",
		"createdDate": "2015-08-11 17:41:55",
		"totalOrganizations": 12,
		"totalTours": 6,
		"totalDays": 400,
		"totalCountries": 13,
		"totalStates": 29,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 1560,
		"schedulePercent": 0.632367,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c0a7822",
		"email": "bcameron.b@gmail.com",
		"name": "Brennan",
		"createdDate": "2017-05-10 22:24:57",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 85,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 154,
		"schedulePercent": 0.123692,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "617e56f",
		"email": "johnnycuttpromotions@gmail.com",
		"name": "Jonathan",
		"createdDate": "2019-04-14 19:56:44",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 90,
		"totalCountries": 3,
		"totalStates": 25,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 1080,
		"schedulePercent": 0.507747,
		"totalGuests": 684,
		"guestPercent": 0.749172,
		"mostGuestDate": "2023-02-21 00:00:00",
		"mostGuestName": "Eagles Ballroom",
		"mostGuestCity": "Milwaukee",
		"mostGuestAmount": 63
	},
	{
		"id": "861bbb5",
		"email": "Vinnie@famemgmt.com",
		"name": "Vincent",
		"createdDate": "2014-06-08 19:40:47",
		"totalOrganizations": 34,
		"totalTours": 13,
		"totalDays": 787,
		"totalCountries": 14,
		"totalStates": 45,
		"totalEvents": 162,
		"eventPercent": 0.732883,
		"totalScheduleItems": 2047,
		"schedulePercent": 0.727718,
		"totalGuests": 1014,
		"guestPercent": 0.827175,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 86
	},
	{
		"id": "aeec2f3",
		"email": "Crillyashes@live.co.uk",
		"name": "Adam",
		"createdDate": "2017-04-08 09:51:14",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 242,
		"totalCountries": 10,
		"totalStates": 39,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1382,
		"schedulePercent": 0.587339,
		"totalGuests": 1462,
		"guestPercent": 0.889021,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 291
	},
	{
		"id": "8bb1b57",
		"email": "tour@sofiaellar.com",
		"name": "Maria",
		"createdDate": "2021-03-20 18:24:14",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 313,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 128,
		"schedulePercent": 0.107933,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bd1c029",
		"email": "joel.e.eriksson@gmail.com",
		"name": "Joel",
		"createdDate": "2013-01-02 21:59:59",
		"totalOrganizations": 12,
		"totalTours": 9,
		"totalDays": 548,
		"totalCountries": 11,
		"totalStates": 50,
		"totalEvents": 296,
		"eventPercent": 0.912992,
		"totalScheduleItems": 4412,
		"schedulePercent": 0.941597,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a74722f",
		"email": "graigclinton@gmail.com",
		"name": "Graig",
		"createdDate": "2014-04-30 15:16:27",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 298,
		"totalCountries": 1,
		"totalStates": 35,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 1528,
		"schedulePercent": 0.624421,
		"totalGuests": 132,
		"guestPercent": 0.499801,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 64
	},
	{
		"id": "758f235",
		"email": "isaiahcaudillo0530@gmail.com",
		"name": "Isaiah",
		"createdDate": "2022-04-05 21:58:58",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 58,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 754,
		"schedulePercent": 0.404185,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-05-31 00:00:00",
		"mostGuestName": "Akron Civic Theatre",
		"mostGuestCity": "Akron",
		"mostGuestAmount": 1
	},
	{
		"id": "c88aed6",
		"email": "brandon@ilovemonstertruck.com",
		"name": "BRANDON",
		"createdDate": "2016-02-25 23:04:53",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 82,
		"totalCountries": 10,
		"totalStates": 17,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 535,
		"schedulePercent": 0.31519,
		"totalGuests": 347,
		"guestPercent": 0.627467,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Alexandra Palace Theatre",
		"mostGuestCity": "London",
		"mostGuestAmount": 123
	},
	{
		"id": "77a440e",
		"email": "Noahbbou96@gmail.com",
		"name": "Noah",
		"createdDate": "2021-09-20 23:16:43",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 108,
		"totalCountries": 6,
		"totalStates": 35,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 627,
		"schedulePercent": 0.352669,
		"totalGuests": 59,
		"guestPercent": 0.433982,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Nile Theater",
		"mostGuestCity": "Mesa",
		"mostGuestAmount": 22
	},
	{
		"id": "8125ddb",
		"email": "robinscott@mac.com",
		"name": "Robin",
		"createdDate": "2012-02-05 23:10:48",
		"totalOrganizations": 6,
		"totalTours": 24,
		"totalDays": 1343,
		"totalCountries": 17,
		"totalStates": 48,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 1921,
		"schedulePercent": 0.706529,
		"totalGuests": 190,
		"guestPercent": 0.542445,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "287c780",
		"email": "holly@offthekerb.co.uk",
		"name": "Holly",
		"createdDate": "2022-11-09 10:28:22",
		"totalOrganizations": 32,
		"totalTours": 49,
		"totalDays": 3198,
		"totalCountries": 14,
		"totalStates": 43,
		"totalEvents": 864,
		"eventPercent": 0.988478,
		"totalScheduleItems": 4853,
		"schedulePercent": 0.956959,
		"totalGuests": 179,
		"guestPercent": 0.535426,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 57
	},
	{
		"id": "f7ec249",
		"email": "mikemonterulo@tkoco.com",
		"name": "Mike",
		"createdDate": "2010-07-05 18:26:42",
		"totalOrganizations": 17,
		"totalTours": 17,
		"totalDays": 341,
		"totalCountries": 10,
		"totalStates": 61,
		"totalEvents": 173,
		"eventPercent": 0.757516,
		"totalScheduleItems": 1956,
		"schedulePercent": 0.713018,
		"totalGuests": 1169,
		"guestPercent": 0.852735,
		"mostGuestDate": "2023-02-19 00:00:00",
		"mostGuestName": "City National Grove of Anaheim",
		"mostGuestCity": "Anaheim",
		"mostGuestAmount": 57
	},
	{
		"id": "7153a89",
		"email": "jjackson@garyallan.com",
		"name": "Jeff",
		"createdDate": "2014-08-19 16:30:45",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 477,
		"totalCountries": 9,
		"totalStates": 44,
		"totalEvents": 141,
		"eventPercent": 0.678321,
		"totalScheduleItems": 2213,
		"schedulePercent": 0.751953,
		"totalGuests": 3218,
		"guestPercent": 0.973381,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Raymond James Stadium",
		"mostGuestCity": "Tampa ",
		"mostGuestAmount": 248
	},
	{
		"id": "65ba8bd",
		"email": "chris.patin@gmail.com",
		"name": "Christopher",
		"createdDate": "2014-09-28 00:09:30",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 137,
		"totalCountries": 9,
		"totalStates": 25,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 875,
		"schedulePercent": 0.445504,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-03-01 00:00:00",
		"mostGuestName": "The Fillmore",
		"mostGuestCity": "San Francisco",
		"mostGuestAmount": 6
	},
	{
		"id": "6e89aab",
		"email": "dan@fitzhart.com",
		"name": "Dan",
		"createdDate": "2017-04-18 22:06:38",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 584,
		"totalCountries": 2,
		"totalStates": 40,
		"totalEvents": 168,
		"eventPercent": 0.747451,
		"totalScheduleItems": 2039,
		"schedulePercent": 0.725202,
		"totalGuests": 487,
		"guestPercent": 0.686929,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "La Mirada Theatre for the Performing Arts",
		"mostGuestCity": "La Mirada",
		"mostGuestAmount": 20
	},
	{
		"id": "a8dfbe8",
		"email": "terry@te-artistmanagement.com",
		"name": "Terry",
		"createdDate": "2021-09-10 10:39:32",
		"totalOrganizations": 10,
		"totalTours": 15,
		"totalDays": 536,
		"totalCountries": 12,
		"totalStates": 35,
		"totalEvents": 245,
		"eventPercent": 0.867435,
		"totalScheduleItems": 964,
		"schedulePercent": 0.469342,
		"totalGuests": 187,
		"guestPercent": 0.540988,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "O2 Academy Glasgow",
		"mostGuestCity": "Glasgow",
		"mostGuestAmount": 75
	},
	{
		"id": "c026caa",
		"email": "sarahpmpls@gmail.com",
		"name": "Sarah",
		"createdDate": "2010-03-12 17:05:42",
		"totalOrganizations": 8,
		"totalTours": 3,
		"totalDays": 118,
		"totalCountries": 1,
		"totalStates": 23,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 1323,
		"schedulePercent": 0.57211,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f8d6cd8",
		"email": "simon_robinson00@hotmail.co.uk",
		"name": "Simon",
		"createdDate": "2015-04-13 15:19:43",
		"totalOrganizations": 9,
		"totalTours": 20,
		"totalDays": 842,
		"totalCountries": 21,
		"totalStates": 70,
		"totalEvents": 289,
		"eventPercent": 0.908621,
		"totalScheduleItems": 3042,
		"schedulePercent": 0.855119,
		"totalGuests": 398,
		"guestPercent": 0.653026,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Abbotsford Centre",
		"mostGuestCity": "Abbotsford",
		"mostGuestAmount": 66
	},
	{
		"id": "0e5a681",
		"email": "sam@articulateentertainment.com",
		"name": "Samuel",
		"createdDate": "2019-03-26 18:58:29",
		"totalOrganizations": 34,
		"totalTours": 28,
		"totalDays": 829,
		"totalCountries": 19,
		"totalStates": 72,
		"totalEvents": 395,
		"eventPercent": 0.956032,
		"totalScheduleItems": 4868,
		"schedulePercent": 0.957224,
		"totalGuests": 11,
		"guestPercent": 0.345782,
		"mostGuestDate": "2023-04-19 00:00:00",
		"mostGuestName": "Music Hall of Williamsburg",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 10
	},
	{
		"id": "3055fb8",
		"email": "info@palehorsesound.com",
		"name": "Greg",
		"createdDate": "2021-03-17 18:43:48",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 185,
		"totalCountries": 10,
		"totalStates": 49,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 952,
		"schedulePercent": 0.466163,
		"totalGuests": 1123,
		"guestPercent": 0.843464,
		"mostGuestDate": "2023-04-26 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 81
	},
	{
		"id": "8a8c1cb",
		"email": "terryt5@mac.com",
		"name": "Terry",
		"createdDate": "2017-03-28 19:58:26",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 208,
		"totalCountries": 3,
		"totalStates": 27,
		"totalEvents": 202,
		"eventPercent": 0.810091,
		"totalScheduleItems": 1818,
		"schedulePercent": 0.68865,
		"totalGuests": 47,
		"guestPercent": 0.417031,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "Unity Baptist Church",
		"mostGuestCity": "Newnan",
		"mostGuestAmount": 14
	},
	{
		"id": "163b1da",
		"email": "j60213@hotmail.com",
		"name": "Joy",
		"createdDate": "2022-08-05 12:06:03",
		"totalOrganizations": 3,
		"totalTours": 25,
		"totalDays": 157,
		"totalCountries": 8,
		"totalStates": 14,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 624,
		"schedulePercent": 0.351741,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "563f57f",
		"email": "mark@ncb.no",
		"name": "Mark",
		"createdDate": "2021-11-04 09:00:46",
		"totalOrganizations": 2,
		"totalTours": 15,
		"totalDays": 91,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 184,
		"schedulePercent": 0.141306,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c422755",
		"email": "ebaker@primarywave.com",
		"name": "Eric",
		"createdDate": "2018-05-31 01:22:25",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 512,
		"totalCountries": 11,
		"totalStates": 52,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 2250,
		"schedulePercent": 0.75831,
		"totalGuests": 1080,
		"guestPercent": 0.836578,
		"mostGuestDate": "2023-09-09 00:00:00",
		"mostGuestName": "The Wellmont Theater",
		"mostGuestCity": "Montclair",
		"mostGuestAmount": 43
	},
	{
		"id": "65bc53d",
		"email": "Endling215@gmail.com",
		"name": "Gabriel",
		"createdDate": "2022-04-21 18:38:43",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 93,
		"totalCountries": 2,
		"totalStates": 37,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 732,
		"schedulePercent": 0.393193,
		"totalGuests": 686,
		"guestPercent": 0.749702,
		"mostGuestDate": "2023-06-27 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 43
	},
	{
		"id": "1b7fcde",
		"email": "bobby@hausofgaga.com",
		"name": "Bobby",
		"createdDate": "2017-06-24 00:28:36",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 5,
		"eventPercent": 0.0348298,
		"totalScheduleItems": 1975,
		"schedulePercent": 0.715402,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4348b17",
		"email": "info@jeffcanada.com",
		"name": "Jeff",
		"createdDate": "2021-05-17 20:02:37",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 84,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 2,
		"schedulePercent": 0.00966759,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5c12130",
		"email": "nesto.hernandez07@gmail.com",
		"name": "Nesto",
		"createdDate": "2021-11-09 18:31:23",
		"totalOrganizations": 10,
		"totalTours": 14,
		"totalDays": 399,
		"totalCountries": 4,
		"totalStates": 50,
		"totalEvents": 231,
		"eventPercent": 0.850616,
		"totalScheduleItems": 3126,
		"schedulePercent": 0.863859,
		"totalGuests": 288,
		"guestPercent": 0.597404,
		"mostGuestDate": "2023-01-21 00:00:00",
		"mostGuestName": "Cervantes' Masterpiece Ballroom & Cervantes' Other Side",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 50
	},
	{
		"id": "bb51a7b",
		"email": "davidtroyer23@gmail.com",
		"name": "David",
		"createdDate": "2015-02-09 04:27:41",
		"totalOrganizations": 9,
		"totalTours": 9,
		"totalDays": 376,
		"totalCountries": 5,
		"totalStates": 44,
		"totalEvents": 184,
		"eventPercent": 0.77791,
		"totalScheduleItems": 1939,
		"schedulePercent": 0.70931,
		"totalGuests": 58,
		"guestPercent": 0.431863,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "The Bottleneck",
		"mostGuestCity": "Lawrence",
		"mostGuestAmount": 18
	},
	{
		"id": "44385e9",
		"email": "caleb@bodyjar.com",
		"name": "Caleb",
		"createdDate": "2017-01-10 22:08:51",
		"totalOrganizations": 26,
		"totalTours": 36,
		"totalDays": 434,
		"totalCountries": 15,
		"totalStates": 66,
		"totalEvents": 254,
		"eventPercent": 0.879619,
		"totalScheduleItems": 2431,
		"schedulePercent": 0.784002,
		"totalGuests": 429,
		"guestPercent": 0.664283,
		"mostGuestDate": "2023-01-15 00:00:00",
		"mostGuestName": "Electric Brixton",
		"mostGuestCity": "London",
		"mostGuestAmount": 45
	},
	{
		"id": "7327b43",
		"email": "ilana@tourniquet.co",
		"name": "Ilana",
		"createdDate": "2022-05-26 23:20:58",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 237,
		"totalCountries": 8,
		"totalStates": 30,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 828,
		"schedulePercent": 0.429744,
		"totalGuests": 225,
		"guestPercent": 0.566018,
		"mostGuestDate": "2023-08-29 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 39
	},
	{
		"id": "2c8fbc7",
		"email": "max@centerstageindustries.com",
		"name": "Max",
		"createdDate": "2016-10-08 18:34:17",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 1007,
		"totalCountries": 17,
		"totalStates": 64,
		"totalEvents": 250,
		"eventPercent": 0.872335,
		"totalScheduleItems": 2664,
		"schedulePercent": 0.815918,
		"totalGuests": 703,
		"guestPercent": 0.753807,
		"mostGuestDate": "2023-09-01 00:00:00",
		"mostGuestName": "Grand Park",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 185
	},
	{
		"id": "0559eb9",
		"email": "tsminc@live.com",
		"name": "Dwayne",
		"createdDate": "2011-07-08 19:57:30",
		"totalOrganizations": 14,
		"totalTours": 12,
		"totalDays": 947,
		"totalCountries": 14,
		"totalStates": 49,
		"totalEvents": 212,
		"eventPercent": 0.826778,
		"totalScheduleItems": 1851,
		"schedulePercent": 0.695272,
		"totalGuests": 13,
		"guestPercent": 0.352536,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Dos Equis Pavilion",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 9
	},
	{
		"id": "1d448bf",
		"email": "tmpmnate@gmail.com",
		"name": "Nathan",
		"createdDate": "2017-08-22 18:16:54",
		"totalOrganizations": 18,
		"totalTours": 14,
		"totalDays": 826,
		"totalCountries": 19,
		"totalStates": 57,
		"totalEvents": 337,
		"eventPercent": 0.936035,
		"totalScheduleItems": 3832,
		"schedulePercent": 0.914316,
		"totalGuests": 199,
		"guestPercent": 0.549729,
		"mostGuestDate": "2023-06-12 00:00:00",
		"mostGuestName": "The Garage",
		"mostGuestCity": "London",
		"mostGuestAmount": 38
	},
	{
		"id": "7fca22a",
		"email": "gallaghershelby@gmail.com",
		"name": "Shelby",
		"createdDate": "2017-09-18 14:25:48",
		"totalOrganizations": 5,
		"totalTours": 13,
		"totalDays": 371,
		"totalCountries": 18,
		"totalStates": 76,
		"totalEvents": 263,
		"eventPercent": 0.888889,
		"totalScheduleItems": 3610,
		"schedulePercent": 0.901602,
		"totalGuests": 1388,
		"guestPercent": 0.881473,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Royal Festival Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 35
	},
	{
		"id": "a83c536",
		"email": "lrothstein@cityparksfoundation.org",
		"name": "Lauren",
		"createdDate": "2023-06-09 14:48:40",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 187,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 1419,
		"schedulePercent": 0.595948,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "19c9c33",
		"email": "mary@qprime.com",
		"name": "Mary",
		"createdDate": "2016-10-19 22:13:19",
		"totalOrganizations": 16,
		"totalTours": 38,
		"totalDays": 1168,
		"totalCountries": 16,
		"totalStates": 83,
		"totalEvents": 504,
		"eventPercent": 0.974705,
		"totalScheduleItems": 6691,
		"schedulePercent": 0.986889,
		"totalGuests": 2878,
		"guestPercent": 0.967686,
		"mostGuestDate": "2023-10-02 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 405
	},
	{
		"id": "b197322",
		"email": "production@TEGLiveEurope.com",
		"name": "TEG Production",
		"createdDate": "2022-08-05 09:47:25",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 65,
		"totalCountries": 4,
		"totalStates": 6,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 236,
		"schedulePercent": 0.172295,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ffaf9cd",
		"email": "anna-chiara.fuenfgeld@apassionata.com",
		"name": "Anna-Chiara",
		"createdDate": "2023-09-15 15:39:16",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 113,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 600,
		"schedulePercent": 0.340882,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "54b94a4",
		"email": "lukejamesbtn@gmail.com",
		"name": "Luke",
		"createdDate": "2022-08-30 16:20:28",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 1602,
		"totalCountries": 17,
		"totalStates": 47,
		"totalEvents": 215,
		"eventPercent": 0.831016,
		"totalScheduleItems": 1103,
		"schedulePercent": 0.512382,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5350aef",
		"email": "Justin.ewert.22@gmail.com",
		"name": "Justin",
		"createdDate": "2016-04-06 20:22:23",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 32,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 163,
		"schedulePercent": 0.128857,
		"totalGuests": 81,
		"guestPercent": 0.455966,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Harrah's Cherokee Casino Resort",
		"mostGuestCity": "Cherokee",
		"mostGuestAmount": 30
	},
	{
		"id": "1d8d1ba",
		"email": "mulharer@gmail.com",
		"name": "Ross",
		"createdDate": "2023-06-04 07:44:23",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 51,
		"totalCountries": 4,
		"totalStates": 9,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 207,
		"schedulePercent": 0.154417,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fb7e765",
		"email": "maddy.seach@hotmail.com",
		"name": "Maddy",
		"createdDate": "2015-12-15 00:20:49",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 393,
		"totalCountries": 3,
		"totalStates": 51,
		"totalEvents": 304,
		"eventPercent": 0.917892,
		"totalScheduleItems": 3015,
		"schedulePercent": 0.85247,
		"totalGuests": 58,
		"guestPercent": 0.431863,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "Kauffman Center for the Performing Arts",
		"mostGuestCity": "Kansas City",
		"mostGuestAmount": 18
	},
	{
		"id": "3edea70",
		"email": "olivia@unitedstage.se",
		"name": "Olivia",
		"createdDate": "2023-04-20 17:09:33",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 191,
		"totalCountries": 4,
		"totalStates": 12,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 502,
		"schedulePercent": 0.299563,
		"totalGuests": 216,
		"guestPercent": 0.560058,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Betongparken",
		"mostGuestCity": "Johanneshov",
		"mostGuestAmount": 61
	},
	{
		"id": "9fb37b8",
		"email": "hrontour@gmail.com",
		"name": "Heather",
		"createdDate": "2010-12-21 03:00:42",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 380,
		"totalCountries": 12,
		"totalStates": 25,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 1333,
		"schedulePercent": 0.57542,
		"totalGuests": 49,
		"guestPercent": 0.419944,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Tivoli Gardens",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 49
	},
	{
		"id": "5d93ca1",
		"email": "jalena.m.noakes@gmail.com",
		"name": "Jalena",
		"createdDate": "2021-07-20 15:47:57",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 505,
		"totalCountries": 2,
		"totalStates": 37,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 2931,
		"schedulePercent": 0.845716,
		"totalGuests": 1448,
		"guestPercent": 0.886902,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Raising Cane's River Center",
		"mostGuestCity": "Baton Rouge",
		"mostGuestAmount": 122
	},
	{
		"id": "22a17dd",
		"email": "seansatfoh@gmail.com",
		"name": "Sean",
		"createdDate": "2015-05-08 13:44:28",
		"totalOrganizations": 16,
		"totalTours": 21,
		"totalDays": 1119,
		"totalCountries": 5,
		"totalStates": 50,
		"totalEvents": 227,
		"eventPercent": 0.844391,
		"totalScheduleItems": 2923,
		"schedulePercent": 0.844789,
		"totalGuests": 2134,
		"guestPercent": 0.940803,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "The Mission Ballroom",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 86
	},
	{
		"id": "48fa4c2",
		"email": "vanessasmaverick@gmail.com",
		"name": "Vanessa",
		"createdDate": "2023-06-06 02:56:33",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 290,
		"totalCountries": 9,
		"totalStates": 12,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 180,
		"schedulePercent": 0.138392,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "eca563a",
		"email": "melissa.s.cleal@gmail.com",
		"name": "M&#233;lissa",
		"createdDate": "2014-06-28 20:30:19",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 167,
		"totalCountries": 10,
		"totalStates": 31,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 1216,
		"schedulePercent": 0.544034,
		"totalGuests": 569,
		"guestPercent": 0.715799,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 91
	},
	{
		"id": "ed410ab",
		"email": "parker.anderson.audio@gmail.com",
		"name": "Parker",
		"createdDate": "2019-01-30 18:47:49",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 883,
		"totalCountries": 3,
		"totalStates": 50,
		"totalEvents": 230,
		"eventPercent": 0.849159,
		"totalScheduleItems": 3088,
		"schedulePercent": 0.860151,
		"totalGuests": 2756,
		"guestPercent": 0.963051,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 104
	},
	{
		"id": "2033e48",
		"email": "heidi@allday.fi",
		"name": "Heidi",
		"createdDate": "2022-09-07 07:08:06",
		"totalOrganizations": 22,
		"totalTours": 33,
		"totalDays": 282,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 282,
		"eventPercent": 0.904119,
		"totalScheduleItems": 984,
		"schedulePercent": 0.477553,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "05a31fc",
		"email": "Gregory.garrison@ucdenver.edu",
		"name": "Greg ",
		"createdDate": "2021-01-05 19:08:09",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 544,
		"totalCountries": 3,
		"totalStates": 32,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 1285,
		"schedulePercent": 0.56125,
		"totalGuests": 292,
		"guestPercent": 0.598596,
		"mostGuestDate": "2023-05-25 00:00:00",
		"mostGuestName": "Red Hat Amphitheater",
		"mostGuestCity": "Raleigh",
		"mostGuestAmount": 25
	},
	{
		"id": "1b1a647",
		"email": "Macochren@gmail.com",
		"name": "Michael",
		"createdDate": "2018-10-23 16:43:57",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 684,
		"totalCountries": 5,
		"totalStates": 46,
		"totalEvents": 279,
		"eventPercent": 0.901205,
		"totalScheduleItems": 4342,
		"schedulePercent": 0.939346,
		"totalGuests": 2428,
		"guestPercent": 0.951794,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Gas South Arena",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 157
	},
	{
		"id": "f84ad3f",
		"email": "prod@nouevaprod.com",
		"name": "AYA",
		"createdDate": "2023-04-04 13:26:55",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 67,
		"totalCountries": 4,
		"totalStates": 8,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 600,
		"schedulePercent": 0.340882,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "269830e",
		"email": "mastertour.agdl.1@gmail.com",
		"name": "Maxime2",
		"createdDate": "2023-10-02 15:51:42",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 35,
		"totalCountries": 7,
		"totalStates": 16,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 221,
		"schedulePercent": 0.163952,
		"totalGuests": 17,
		"guestPercent": 0.362469,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "Santeria Social Club",
		"mostGuestCity": "Milano",
		"mostGuestAmount": 7
	},
	{
		"id": "7eebe46",
		"email": "Victoria.Mason@wmg.com",
		"name": "Victoria",
		"createdDate": "2021-06-09 22:22:50",
		"totalOrganizations": 52,
		"totalTours": 45,
		"totalDays": 15128,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 1933,
		"eventPercent": 0.995497,
		"totalScheduleItems": 742,
		"schedulePercent": 0.397431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d24536b",
		"email": "bighead1213@gmail.com",
		"name": "Chris",
		"createdDate": "2015-09-16 21:08:53",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 443,
		"totalCountries": 6,
		"totalStates": 49,
		"totalEvents": 255,
		"eventPercent": 0.880943,
		"totalScheduleItems": 4993,
		"schedulePercent": 0.960932,
		"totalGuests": 7024,
		"guestPercent": 0.998411,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Fenway Park",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 322
	},
	{
		"id": "696867b",
		"email": "sam@asleepatthewheel.com",
		"name": "Sam ",
		"createdDate": "2015-08-11 16:53:42",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 458,
		"totalCountries": 2,
		"totalStates": 36,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 1592,
		"schedulePercent": 0.640313,
		"totalGuests": 246,
		"guestPercent": 0.576612,
		"mostGuestDate": "2023-07-09 00:00:00",
		"mostGuestName": "Gruene Hall",
		"mostGuestCity": "New Braunfels",
		"mostGuestAmount": 36
	},
	{
		"id": "0191556",
		"email": "muckle@mfmgt.com",
		"name": "Jane",
		"createdDate": "2010-06-24 16:26:18",
		"totalOrganizations": 3,
		"totalTours": 10,
		"totalDays": 135,
		"totalCountries": 4,
		"totalStates": 22,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 704,
		"schedulePercent": 0.382201,
		"totalGuests": 1289,
		"guestPercent": 0.870613,
		"mostGuestDate": "2023-05-31 00:00:00",
		"mostGuestName": "Santa Barbara Bowl",
		"mostGuestCity": "Santa Barbara",
		"mostGuestAmount": 103
	},
	{
		"id": "a9d80a4",
		"email": "HenryBordeaux@gmail.com",
		"name": "Henry",
		"createdDate": "2009-08-15 23:50:47",
		"totalOrganizations": 9,
		"totalTours": 2,
		"totalDays": 66,
		"totalCountries": 1,
		"totalStates": 24,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 840,
		"schedulePercent": 0.433982,
		"totalGuests": 820,
		"guestPercent": 0.780691,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 91
	},
	{
		"id": "688a56d",
		"email": "Mrmikecolucci@aol.com",
		"name": "Mike",
		"createdDate": "2019-09-06 22:16:14",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 338,
		"totalCountries": 8,
		"totalStates": 31,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 609,
		"schedulePercent": 0.345517,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-06-06 00:00:00",
		"mostGuestName": "Bellco Theatre",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 4
	},
	{
		"id": "6f56363",
		"email": "adamgrowley@gmail.com",
		"name": "Adam",
		"createdDate": "2022-03-12 19:02:51",
		"totalOrganizations": 14,
		"totalTours": 18,
		"totalDays": 585,
		"totalCountries": 15,
		"totalStates": 52,
		"totalEvents": 243,
		"eventPercent": 0.864786,
		"totalScheduleItems": 2219,
		"schedulePercent": 0.75341,
		"totalGuests": 715,
		"guestPercent": 0.756191,
		"mostGuestDate": "2023-11-02 00:00:00",
		"mostGuestName": "O2 Arena",
		"mostGuestCity": "London",
		"mostGuestAmount": 99
	},
	{
		"id": "bf39423",
		"email": "tjctouring@gmail.com",
		"name": "Tom",
		"createdDate": "2019-05-10 18:27:03",
		"totalOrganizations": 18,
		"totalTours": 23,
		"totalDays": 773,
		"totalCountries": 17,
		"totalStates": 85,
		"totalEvents": 354,
		"eventPercent": 0.943584,
		"totalScheduleItems": 4774,
		"schedulePercent": 0.954443,
		"totalGuests": 909,
		"guestPercent": 0.801483,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 50
	},
	{
		"id": "2ce49bc",
		"email": "Morganestapleton@me.com",
		"name": "Morgane",
		"createdDate": "2013-06-13 18:56:22",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 4,
		"totalStates": 34,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 1687,
		"schedulePercent": 0.661899,
		"totalGuests": 2645,
		"guestPercent": 0.957224,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "Nissan Stadium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 118
	},
	{
		"id": "12b54d5",
		"email": "steve@topshelf.com.au",
		"name": "Steve",
		"createdDate": "2017-10-17 17:22:06",
		"totalOrganizations": 21,
		"totalTours": 22,
		"totalDays": 392,
		"totalCountries": 10,
		"totalStates": 18,
		"totalEvents": 188,
		"eventPercent": 0.785856,
		"totalScheduleItems": 1775,
		"schedulePercent": 0.679778,
		"totalGuests": 964,
		"guestPercent": 0.816448,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Palais Theatre",
		"mostGuestCity": "St Kilda",
		"mostGuestAmount": 62
	},
	{
		"id": "3f164fa",
		"email": "meghanpatrickmusic@gmail.com",
		"name": "Meghan",
		"createdDate": "2017-08-18 00:06:28",
		"totalOrganizations": 6,
		"totalTours": 1,
		"totalDays": 246,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 395,
		"schedulePercent": 0.255728,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Algonquin Commons Theatre",
		"mostGuestCity": "Ottawa",
		"mostGuestAmount": 4
	},
	{
		"id": "948a000",
		"email": "abraham.road@gmail.com",
		"name": "Abraham",
		"createdDate": "2015-10-05 03:45:04",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 515,
		"totalCountries": 4,
		"totalStates": 16,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 378,
		"schedulePercent": 0.247252,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "17b7809",
		"email": "clelwica@gmail.com",
		"name": "Carrie",
		"createdDate": "2018-02-14 00:20:06",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 1106,
		"totalCountries": 3,
		"totalStates": 44,
		"totalEvents": 379,
		"eventPercent": 0.951,
		"totalScheduleItems": 3075,
		"schedulePercent": 0.858827,
		"totalGuests": 1742,
		"guestPercent": 0.915773,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Paycom Center",
		"mostGuestCity": "Oklahoma City",
		"mostGuestAmount": 167
	},
	{
		"id": "3f6ce42",
		"email": "stephanie@allthingslive.se",
		"name": "Stephanie",
		"createdDate": "2019-04-18 09:04:18",
		"totalOrganizations": 12,
		"totalTours": 7,
		"totalDays": 233,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 190,
		"eventPercent": 0.788505,
		"totalScheduleItems": 1516,
		"schedulePercent": 0.622169,
		"totalGuests": 453,
		"guestPercent": 0.67501,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Cirkus Arena",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 50
	},
	{
		"id": "e5552c8",
		"email": "jimcarmichael@mac.com",
		"name": "Jim",
		"createdDate": "2012-03-03 22:11:18",
		"totalOrganizations": 23,
		"totalTours": 6,
		"totalDays": 190,
		"totalCountries": 14,
		"totalStates": 39,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1551,
		"schedulePercent": 0.63038,
		"totalGuests": 387,
		"guestPercent": 0.647596,
		"mostGuestDate": "2023-07-16 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 75
	},
	{
		"id": "a399f6f",
		"email": "aki@udo.co.jp",
		"name": "Aki",
		"createdDate": "2012-10-31 03:23:11",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 637,
		"totalCountries": 23,
		"totalStates": 55,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 1404,
		"schedulePercent": 0.592372,
		"totalGuests": 329,
		"guestPercent": 0.618064,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "The Majestic Ventura Theater",
		"mostGuestCity": "Ventura",
		"mostGuestAmount": 43
	},
	{
		"id": "ee0b41f",
		"email": "rricotta11090@gmail.com",
		"name": "Robert",
		"createdDate": "2022-05-17 16:02:28",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 84,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 30,
		"schedulePercent": 0.0401271,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Country Fan Fest",
		"mostGuestCity": "Grantsville",
		"mostGuestAmount": 6
	},
	{
		"id": "3c6c7f4",
		"email": "chrisroli@comcast.net",
		"name": "chris",
		"createdDate": "2012-05-04 03:41:36",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 87,
		"totalCountries": 11,
		"totalStates": 17,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 752,
		"schedulePercent": 0.402861,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0b6192b",
		"email": "pete@1066touring.com",
		"name": "Pete",
		"createdDate": "2023-06-20 22:25:05",
		"totalOrganizations": 5,
		"totalTours": 19,
		"totalDays": 315,
		"totalCountries": 14,
		"totalStates": 19,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 1418,
		"schedulePercent": 0.595418,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4419de9",
		"email": "mike@hrd8wrk.com",
		"name": "Mike",
		"createdDate": "2022-05-05 19:41:10",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 337,
		"totalCountries": 2,
		"totalStates": 36,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 1302,
		"schedulePercent": 0.566812,
		"totalGuests": 232,
		"guestPercent": 0.569196,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 35
	},
	{
		"id": "1a8cfe6",
		"email": "v.zanghi408@gmail.com",
		"name": "Victoria ",
		"createdDate": "2013-03-07 19:24:30",
		"totalOrganizations": 19,
		"totalTours": 27,
		"totalDays": 641,
		"totalCountries": 17,
		"totalStates": 61,
		"totalEvents": 260,
		"eventPercent": 0.885975,
		"totalScheduleItems": 4128,
		"schedulePercent": 0.929281,
		"totalGuests": 2357,
		"guestPercent": 0.949013,
		"mostGuestDate": "2023-08-09 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 135
	},
	{
		"id": "8312821",
		"email": "nealfrancistm@gmail.com",
		"name": "Jack",
		"createdDate": "2021-08-10 23:32:26",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 166,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 536,
		"schedulePercent": 0.315455,
		"totalGuests": 19,
		"guestPercent": 0.366971,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Ameris Bank Amphitheatre",
		"mostGuestCity": "Alpharetta",
		"mostGuestAmount": 13
	},
	{
		"id": "a52af3e",
		"email": "chrisworleyprod@gmail.com",
		"name": "Chris",
		"createdDate": "2019-06-18 16:38:43",
		"totalOrganizations": 4,
		"totalTours": 10,
		"totalDays": 937,
		"totalCountries": 9,
		"totalStates": 56,
		"totalEvents": 197,
		"eventPercent": 0.800821,
		"totalScheduleItems": 3230,
		"schedulePercent": 0.872202,
		"totalGuests": 559,
		"guestPercent": 0.713415,
		"mostGuestDate": "2023-11-15 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 63
	},
	{
		"id": "1a27867",
		"email": "mondraspace@gmail.com",
		"name": "Jose",
		"createdDate": "2013-01-29 01:20:34",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 232,
		"totalCountries": 10,
		"totalStates": 59,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 1051,
		"schedulePercent": 0.497682,
		"totalGuests": 448,
		"guestPercent": 0.673156,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Teatro MetropÃ³litan",
		"mostGuestCity": "Ciudad de MÃ©xico",
		"mostGuestAmount": 41
	},
	{
		"id": "d0b510f",
		"email": "Wokeproductions@gmail.com",
		"name": "Miguel",
		"createdDate": "2015-06-30 20:54:34",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 123,
		"totalCountries": 1,
		"totalStates": 27,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 351,
		"schedulePercent": 0.233611,
		"totalGuests": 19,
		"guestPercent": 0.366971,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "The Regency Ballroom",
		"mostGuestCity": "SF",
		"mostGuestAmount": 17
	},
	{
		"id": "4df33d4",
		"email": "helena@krall.se",
		"name": "Helena",
		"createdDate": "2015-03-06 07:56:27",
		"totalOrganizations": 13,
		"totalTours": 6,
		"totalDays": 452,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 1803,
		"schedulePercent": 0.686399,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bdd969f",
		"email": "tourmanager@losvasquez.cl",
		"name": "Benjamin",
		"createdDate": "2023-01-09 11:05:37",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 130,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 942,
		"schedulePercent": 0.46325,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "86014e8",
		"email": "bkcarico@gmail.com",
		"name": "Brian",
		"createdDate": "2012-09-04 03:34:51",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 561,
		"totalCountries": 10,
		"totalStates": 43,
		"totalEvents": 155,
		"eventPercent": 0.716197,
		"totalScheduleItems": 3437,
		"schedulePercent": 0.889286,
		"totalGuests": 1239,
		"guestPercent": 0.863064,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "The Brooklyn Mirage",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 96
	},
	{
		"id": "52a06fa",
		"email": "RuegseggerJohn@gmail.com",
		"name": "John",
		"createdDate": "2023-06-07 22:35:35",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 88,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 317,
		"schedulePercent": 0.216395,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8406a7f",
		"email": "bradleykind@icloud.com",
		"name": "Bradley",
		"createdDate": "2012-04-03 00:20:41",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 348,
		"totalCountries": 2,
		"totalStates": 40,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 3014,
		"schedulePercent": 0.852205,
		"totalGuests": 1043,
		"guestPercent": 0.831545,
		"mostGuestDate": "2023-06-28 00:00:00",
		"mostGuestName": "Rogers Arena",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 178
	},
	{
		"id": "12c4bfd",
		"email": "oscar@themusicbusinesscompany.com",
		"name": "Oscar",
		"createdDate": "2022-10-25 02:22:09",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 357,
		"totalCountries": 4,
		"totalStates": 17,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 223,
		"schedulePercent": 0.165673,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cd9b450",
		"email": "info@adamrhodes.com.au",
		"name": "Adam",
		"createdDate": "2010-12-26 08:34:02",
		"totalOrganizations": 16,
		"totalTours": 38,
		"totalDays": 651,
		"totalCountries": 13,
		"totalStates": 61,
		"totalEvents": 267,
		"eventPercent": 0.892729,
		"totalScheduleItems": 3667,
		"schedulePercent": 0.905046,
		"totalGuests": 1477,
		"guestPercent": 0.891405,
		"mostGuestDate": "2023-06-01 00:00:00",
		"mostGuestName": "The Enmore Theatre",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 107
	},
	{
		"id": "ae268ce",
		"email": "los.garpa90@gmail.com",
		"name": "Carlos",
		"createdDate": "2017-09-07 03:46:37",
		"totalOrganizations": 8,
		"totalTours": 7,
		"totalDays": 547,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 875,
		"schedulePercent": 0.445504,
		"totalGuests": 117,
		"guestPercent": 0.488412,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "The Paramount",
		"mostGuestCity": "Huntington",
		"mostGuestAmount": 27
	},
	{
		"id": "abef10d",
		"email": "andrewgumas@gmail.com",
		"name": "Andrew",
		"createdDate": "2018-04-07 20:04:30",
		"totalOrganizations": 12,
		"totalTours": 19,
		"totalDays": 775,
		"totalCountries": 6,
		"totalStates": 44,
		"totalEvents": 217,
		"eventPercent": 0.833267,
		"totalScheduleItems": 3586,
		"schedulePercent": 0.898821,
		"totalGuests": 2559,
		"guestPercent": 0.955635,
		"mostGuestDate": "2023-08-04 00:00:00",
		"mostGuestName": "Sunset Cove Amphitheater",
		"mostGuestCity": "Boca Raton",
		"mostGuestAmount": 132
	},
	{
		"id": "65cb42f",
		"email": "thomas@thomasdulin.com",
		"name": "Thomas",
		"createdDate": "2014-05-21 17:31:53",
		"totalOrganizations": 21,
		"totalTours": 5,
		"totalDays": 307,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 1731,
		"schedulePercent": 0.671302,
		"totalGuests": 802,
		"guestPercent": 0.776718,
		"mostGuestDate": "2023-03-29 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 41
	},
	{
		"id": "9307069",
		"email": "palombella.laura@hotmail.com",
		"name": "Laura",
		"createdDate": "2021-12-06 01:50:06",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 311,
		"totalCountries": 5,
		"totalStates": 38,
		"totalEvents": 235,
		"eventPercent": 0.855516,
		"totalScheduleItems": 1656,
		"schedulePercent": 0.654483,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "86599ce",
		"email": "reidmartin11@gmail.com",
		"name": "Reid",
		"createdDate": "2018-02-20 16:58:02",
		"totalOrganizations": 14,
		"totalTours": 51,
		"totalDays": 1446,
		"totalCountries": 5,
		"totalStates": 55,
		"totalEvents": 219,
		"eventPercent": 0.836048,
		"totalScheduleItems": 2009,
		"schedulePercent": 0.720832,
		"totalGuests": 400,
		"guestPercent": 0.653291,
		"mostGuestDate": "2023-03-15 00:00:00",
		"mostGuestName": "The Teragram Ballroom",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 40
	},
	{
		"id": "a75331a",
		"email": "shaelamariephoto@gmail.com",
		"name": "Shaela",
		"createdDate": "2023-06-14 18:15:55",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 136,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 516,
		"schedulePercent": 0.306185,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5e41d01",
		"email": "cj@eighteentwentysix.com",
		"name": "CJ",
		"createdDate": "2022-02-21 02:39:50",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 935,
		"totalCountries": 3,
		"totalStates": 31,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 2110,
		"schedulePercent": 0.736724,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d0a64b7",
		"email": "aleksey.weyman@lobbycall.com",
		"name": "Aleksey",
		"createdDate": "2022-04-04 22:16:49",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 324,
		"totalCountries": 3,
		"totalStates": 25,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 508,
		"schedulePercent": 0.303139,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2e65069",
		"email": "intern@redcreativegroup.com",
		"name": "RED",
		"createdDate": "2023-02-06 22:03:38",
		"totalOrganizations": 2,
		"totalTours": 8,
		"totalDays": 105,
		"totalCountries": 1,
		"totalStates": 30,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 151,
		"schedulePercent": 0.121308,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Starlight Theatre",
		"mostGuestCity": "Kansas City",
		"mostGuestAmount": 6
	},
	{
		"id": "70fa00a",
		"email": "sofmediacompany@gmail.com",
		"name": "Sofie",
		"createdDate": "2023-03-24 12:40:42",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 209,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 291,
		"schedulePercent": 0.203682,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d63a65c",
		"email": "steve_day_22@hotmail.com",
		"name": "Steve",
		"createdDate": "2016-08-07 19:33:21",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 12,
		"totalStates": 24,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 587,
		"schedulePercent": 0.334525,
		"totalGuests": 372,
		"guestPercent": 0.641902,
		"mostGuestDate": "2023-08-01 00:00:00",
		"mostGuestName": "The Bellweather",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 82
	},
	{
		"id": "58b0c06",
		"email": "melinda@almostcountryentertainment.com",
		"name": "Casey ",
		"createdDate": "2014-07-07 19:10:08",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 376,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 555,
		"schedulePercent": 0.323798,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "42b9152",
		"email": "Andrew@naturallyseven.com",
		"name": "Andrew",
		"createdDate": "2014-09-23 18:06:32",
		"totalOrganizations": 4,
		"totalTours": 24,
		"totalDays": 407,
		"totalCountries": 8,
		"totalStates": 54,
		"totalEvents": 248,
		"eventPercent": 0.869554,
		"totalScheduleItems": 2109,
		"schedulePercent": 0.736459,
		"totalGuests": 67,
		"guestPercent": 0.443253,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Knight Theater",
		"mostGuestCity": "Charlotte",
		"mostGuestAmount": 18
	},
	{
		"id": "8d66062",
		"email": "cara@triple8mgmt.com",
		"name": "Cara",
		"createdDate": "2018-09-27 21:49:17",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 726,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 1723,
		"schedulePercent": 0.669977,
		"totalGuests": 427,
		"guestPercent": 0.663621,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "Billy Bob's Texas",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 44
	},
	{
		"id": "4259c9d",
		"email": "jack@victorwooten.com",
		"name": "Jack",
		"createdDate": "2012-04-02 17:55:42",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 157,
		"totalCountries": 3,
		"totalStates": 31,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 856,
		"schedulePercent": 0.439015,
		"totalGuests": 347,
		"guestPercent": 0.627467,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 49
	},
	{
		"id": "06411f5",
		"email": "slyfoxprod@me.com",
		"name": "Christopher ",
		"createdDate": "2014-01-16 13:18:15",
		"totalOrganizations": 17,
		"totalTours": 105,
		"totalDays": 1452,
		"totalCountries": 10,
		"totalStates": 71,
		"totalEvents": 454,
		"eventPercent": 0.968084,
		"totalScheduleItems": 6397,
		"schedulePercent": 0.984505,
		"totalGuests": 823,
		"guestPercent": 0.781883,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 98
	},
	{
		"id": "63ccd09",
		"email": "kubicek@mfmgt.com",
		"name": "Kyle",
		"createdDate": "2018-05-07 20:54:33",
		"totalOrganizations": 6,
		"totalTours": 31,
		"totalDays": 538,
		"totalCountries": 11,
		"totalStates": 46,
		"totalEvents": 212,
		"eventPercent": 0.826778,
		"totalScheduleItems": 2206,
		"schedulePercent": 0.751026,
		"totalGuests": 1984,
		"guestPercent": 0.932857,
		"mostGuestDate": "2023-11-20 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 62
	},
	{
		"id": "e76cfa9",
		"email": "frankiteevan@gmail.com",
		"name": "Franki",
		"createdDate": "2022-02-18 19:07:25",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 178,
		"totalCountries": 10,
		"totalStates": 34,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 896,
		"schedulePercent": 0.452126,
		"totalGuests": 441,
		"guestPercent": 0.66905,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "The Salt Shed",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 51
	},
	{
		"id": "3226640",
		"email": "ryanjankenbauer@gmail.com",
		"name": "Ryan",
		"createdDate": "2018-12-04 23:22:43",
		"totalOrganizations": 10,
		"totalTours": 17,
		"totalDays": 1009,
		"totalCountries": 6,
		"totalStates": 51,
		"totalEvents": 206,
		"eventPercent": 0.816183,
		"totalScheduleItems": 2286,
		"schedulePercent": 0.76321,
		"totalGuests": 911,
		"guestPercent": 0.801881,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 75
	},
	{
		"id": "4ef68c8",
		"email": "danpaproductions@gmail.com",
		"name": "Danny",
		"createdDate": "2022-08-23 23:28:10",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 190,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 187,
		"schedulePercent": 0.143557,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cfb7335",
		"email": "sarahcotterell1@gmail.com",
		"name": "Sarah",
		"createdDate": "2018-09-29 08:46:52",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 574,
		"totalCountries": 4,
		"totalStates": 21,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 671,
		"schedulePercent": 0.36909,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8f7b608",
		"email": "chumout@gmail.com",
		"name": "Tommy",
		"createdDate": "2022-05-08 06:28:03",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 127,
		"totalCountries": 3,
		"totalStates": 32,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 672,
		"schedulePercent": 0.36962,
		"totalGuests": 455,
		"guestPercent": 0.675805,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Palladium Times Square",
		"mostGuestCity": "New York",
		"mostGuestAmount": 97
	},
	{
		"id": "cf599ca",
		"email": "Rgovoruhk@gmail.com",
		"name": "Ronnie",
		"createdDate": "2017-03-02 01:58:39",
		"totalOrganizations": 4,
		"totalTours": 16,
		"totalDays": 445,
		"totalCountries": 3,
		"totalStates": 33,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 1282,
		"schedulePercent": 0.560985,
		"totalGuests": 88,
		"guestPercent": 0.461661,
		"mostGuestDate": "2023-11-05 00:00:00",
		"mostGuestName": "Meow Wolf",
		"mostGuestCity": "Santa Fe",
		"mostGuestAmount": 11
	},
	{
		"id": "71a6ef7",
		"email": "isaac@cosmica-artists.com",
		"name": "Isaac",
		"createdDate": "2022-05-11 22:09:34",
		"totalOrganizations": 5,
		"totalTours": 15,
		"totalDays": 388,
		"totalCountries": 10,
		"totalStates": 47,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 3042,
		"schedulePercent": 0.855119,
		"totalGuests": 1403,
		"guestPercent": 0.883194,
		"mostGuestDate": "2023-09-06 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 66
	},
	{
		"id": "bbd60d1",
		"email": "alexandra@backstagegroup.se",
		"name": "Alexandra ",
		"createdDate": "2021-07-01 13:31:34",
		"totalOrganizations": 13,
		"totalTours": 12,
		"totalDays": 704,
		"totalCountries": 10,
		"totalStates": 20,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 1312,
		"schedulePercent": 0.569064,
		"totalGuests": 668,
		"guestPercent": 0.74361,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Slottsskogsvallen",
		"mostGuestCity": "GÃ¶teborg",
		"mostGuestAmount": 72
	},
	{
		"id": "3ffde6c",
		"email": "dylan@deeprootsmanagement.com",
		"name": "Dylan",
		"createdDate": "2022-04-29 02:19:23",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 780,
		"totalCountries": 2,
		"totalStates": 45,
		"totalEvents": 243,
		"eventPercent": 0.864786,
		"totalScheduleItems": 2581,
		"schedulePercent": 0.80294,
		"totalGuests": 1252,
		"guestPercent": 0.865183,
		"mostGuestDate": "2023-09-22 00:00:00",
		"mostGuestName": "The Factory in Deep Ellum",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 52
	},
	{
		"id": "aebeb51",
		"email": "Kjohalloran@gmail.com",
		"name": "Kevin",
		"createdDate": "2016-09-20 14:03:33",
		"totalOrganizations": 19,
		"totalTours": 30,
		"totalDays": 1372,
		"totalCountries": 14,
		"totalStates": 70,
		"totalEvents": 473,
		"eventPercent": 0.971395,
		"totalScheduleItems": 4927,
		"schedulePercent": 0.959873,
		"totalGuests": 1247,
		"guestPercent": 0.864654,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "The Marlin Room at Webster Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 58
	},
	{
		"id": "7bc5136",
		"email": "stefany@goodforshowproductions.com",
		"name": "stefany",
		"createdDate": "2022-07-18 20:11:47",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 976,
		"totalCountries": 5,
		"totalStates": 35,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 986,
		"schedulePercent": 0.47848,
		"totalGuests": 145,
		"guestPercent": 0.509734,
		"mostGuestDate": "2023-03-06 00:00:00",
		"mostGuestName": "The Electric Jane",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 34
	},
	{
		"id": "2f1f335",
		"email": "bourbonrocksbkny@aol.com",
		"name": "David",
		"createdDate": "2010-02-17 16:42:05",
		"totalOrganizations": 27,
		"totalTours": 20,
		"totalDays": 203,
		"totalCountries": 4,
		"totalStates": 34,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 928,
		"schedulePercent": 0.459012,
		"totalGuests": 809,
		"guestPercent": 0.778175,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Minnesota State Fair",
		"mostGuestCity": "St Paul",
		"mostGuestAmount": 65
	},
	{
		"id": "f71d822",
		"email": "thomas@stahl.fi",
		"name": "Thomas",
		"createdDate": "2017-03-31 23:07:18",
		"totalOrganizations": 24,
		"totalTours": 16,
		"totalDays": 376,
		"totalCountries": 18,
		"totalStates": 57,
		"totalEvents": 178,
		"eventPercent": 0.767051,
		"totalScheduleItems": 1830,
		"schedulePercent": 0.691961,
		"totalGuests": 1157,
		"guestPercent": 0.850616,
		"mostGuestDate": "2023-01-05 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 45
	},
	{
		"id": "f2901e7",
		"email": "caroline@tajmer.dk",
		"name": "Caroline",
		"createdDate": "2023-05-01 09:42:55",
		"totalOrganizations": 10,
		"totalTours": 5,
		"totalDays": 92,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 452,
		"schedulePercent": 0.277447,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1c62f54",
		"email": "wf@rockandsoulmgmt.com",
		"name": "Wayne",
		"createdDate": "2016-01-21 20:29:59",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 128,
		"totalCountries": 1,
		"totalStates": 23,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1354,
		"schedulePercent": 0.581777,
		"totalGuests": 143,
		"guestPercent": 0.508277,
		"mostGuestDate": "2023-03-04 00:00:00",
		"mostGuestName": "Billy Bob's Texas",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 54
	},
	{
		"id": "997798d",
		"email": "chris@119collective.com",
		"name": "Chris",
		"createdDate": "2023-08-11 14:19:08",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 104,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 1688,
		"schedulePercent": 0.662429,
		"totalGuests": 307,
		"guestPercent": 0.607072,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Altria Theater",
		"mostGuestCity": "Richmond",
		"mostGuestAmount": 36
	},
	{
		"id": "b02a9cf",
		"email": "Rick@favortheartist.com",
		"name": "Rick",
		"createdDate": "2019-02-24 04:17:55",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 203,
		"totalCountries": 16,
		"totalStates": 37,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 528,
		"schedulePercent": 0.312012,
		"totalGuests": 942,
		"guestPercent": 0.810224,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 58
	},
	{
		"id": "dff0ebb",
		"email": "jvogel@terrains.co",
		"name": "Johnny",
		"createdDate": "2022-05-17 18:41:51",
		"totalOrganizations": 6,
		"totalTours": 15,
		"totalDays": 169,
		"totalCountries": 10,
		"totalStates": 27,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 434,
		"schedulePercent": 0.27003,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "41d96f4",
		"email": "brandon.aviles@lobbycall.com",
		"name": "brandon",
		"createdDate": "2022-04-13 19:38:28",
		"totalOrganizations": 10,
		"totalTours": 28,
		"totalDays": 541,
		"totalCountries": 17,
		"totalStates": 58,
		"totalEvents": 271,
		"eventPercent": 0.895113,
		"totalScheduleItems": 3976,
		"schedulePercent": 0.921865,
		"totalGuests": 129,
		"guestPercent": 0.497815,
		"mostGuestDate": "2023-05-18 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 124
	},
	{
		"id": "3135f71",
		"email": "marvinski@me.com",
		"name": "Mike",
		"createdDate": "2018-03-07 00:02:34",
		"totalOrganizations": 2,
		"totalTours": 13,
		"totalDays": 124,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 213,
		"schedulePercent": 0.158125,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "36e57bf",
		"email": "Jason.lupeituu@gmail.com",
		"name": "Jason \"Loopy\" ",
		"createdDate": "2011-09-18 00:42:04",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 270,
		"totalCountries": 7,
		"totalStates": 46,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1607,
		"schedulePercent": 0.644021,
		"totalGuests": 470,
		"guestPercent": 0.680969,
		"mostGuestDate": "2023-09-27 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 80
	},
	{
		"id": "156e6d0",
		"email": "production.dungeon@bigpond.com",
		"name": "Derek ",
		"createdDate": "2018-01-21 23:15:49",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 346,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 840,
		"schedulePercent": 0.433982,
		"totalGuests": 263,
		"guestPercent": 0.585618,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Freo.Social",
		"mostGuestCity": "Fremantle",
		"mostGuestAmount": 22
	},
	{
		"id": "3e7854a",
		"email": "mathis@bleucitron.net",
		"name": "RIVIERE",
		"createdDate": "2022-02-25 12:12:59",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 735,
		"totalCountries": 9,
		"totalStates": 25,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 2448,
		"schedulePercent": 0.785591,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "Stade de France",
		"mostGuestCity": "Saint-Denis",
		"mostGuestAmount": 4
	},
	{
		"id": "963787e",
		"email": "chris@iluminate.com",
		"name": "Nicole",
		"createdDate": "2023-09-12 16:04:48",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 52,
		"totalCountries": 1,
		"totalStates": 15,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 363,
		"schedulePercent": 0.239703,
		"totalGuests": 24,
		"guestPercent": 0.375712,
		"mostGuestDate": "2023-11-02 00:00:00",
		"mostGuestName": "The Palace Theatre",
		"mostGuestCity": "Stamford",
		"mostGuestAmount": 7
	},
	{
		"id": "c04c4f4",
		"email": "Daveaustinmusic@gmail.com",
		"name": "David",
		"createdDate": "2019-03-28 16:01:09",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 46,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 279,
		"schedulePercent": 0.19759,
		"totalGuests": 111,
		"guestPercent": 0.483115,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 38
	},
	{
		"id": "143ba61",
		"email": "michelle@paquinentertainment.com",
		"name": "Michelle",
		"createdDate": "2013-01-12 08:07:06",
		"totalOrganizations": 25,
		"totalTours": 22,
		"totalDays": 917,
		"totalCountries": 9,
		"totalStates": 65,
		"totalEvents": 328,
		"eventPercent": 0.929943,
		"totalScheduleItems": 3512,
		"schedulePercent": 0.894848,
		"totalGuests": 642,
		"guestPercent": 0.736591,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "White Eagle Hall",
		"mostGuestCity": "Jersey City",
		"mostGuestAmount": 41
	},
	{
		"id": "bf2f0ed",
		"email": "tessa@team.fi",
		"name": "Tessa",
		"createdDate": "2023-09-05 12:25:17",
		"totalOrganizations": 52,
		"totalTours": 41,
		"totalDays": 344,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 343,
		"eventPercent": 0.938551,
		"totalScheduleItems": 1226,
		"schedulePercent": 0.546815,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fc1bd07",
		"email": "erikaduffee@gmail.com",
		"name": "Erika",
		"createdDate": "2012-08-20 18:37:27",
		"totalOrganizations": 17,
		"totalTours": 22,
		"totalDays": 649,
		"totalCountries": 19,
		"totalStates": 69,
		"totalEvents": 308,
		"eventPercent": 0.919746,
		"totalScheduleItems": 4218,
		"schedulePercent": 0.934446,
		"totalGuests": 1262,
		"guestPercent": 0.866905,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 58
	},
	{
		"id": "615637d",
		"email": "stephenpoole@gmail.com",
		"name": "Stephen",
		"createdDate": "2016-08-30 17:29:21",
		"totalOrganizations": 9,
		"totalTours": 13,
		"totalDays": 376,
		"totalCountries": 10,
		"totalStates": 51,
		"totalEvents": 159,
		"eventPercent": 0.726394,
		"totalScheduleItems": 2713,
		"schedulePercent": 0.821613,
		"totalGuests": 2525,
		"guestPercent": 0.95484,
		"mostGuestDate": "2023-06-18 00:00:00",
		"mostGuestName": "Red Rocks Park and Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 183
	},
	{
		"id": "3dec1f2",
		"email": "filarmonica@fundacionbd.org",
		"name": "Filarm&#243;nica Joven de Colombia",
		"createdDate": "2022-05-17 20:10:00",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 24,
		"totalCountries": 3,
		"totalStates": 4,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 240,
		"schedulePercent": 0.175738,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c0fbf2b",
		"email": "info@fidelitycorp.com.au",
		"name": "Email",
		"createdDate": "2013-05-17 02:51:52",
		"totalOrganizations": 9,
		"totalTours": 37,
		"totalDays": 259,
		"totalCountries": 10,
		"totalStates": 25,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 1105,
		"schedulePercent": 0.512912,
		"totalGuests": 393,
		"guestPercent": 0.650775,
		"mostGuestDate": "2023-02-09 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 76
	},
	{
		"id": "5643890",
		"email": "tourdadtm@gmail.com",
		"name": "Benjamin",
		"createdDate": "2018-06-19 01:17:42",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 445,
		"totalCountries": 4,
		"totalStates": 33,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 1586,
		"schedulePercent": 0.638326,
		"totalGuests": 1076,
		"guestPercent": 0.835783,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Forest Hills Stadium",
		"mostGuestCity": "Forest Hills",
		"mostGuestAmount": 191
	},
	{
		"id": "98ff56b",
		"email": "shawtour@gmail.com",
		"name": "Stephen",
		"createdDate": "2015-03-11 17:43:30",
		"totalOrganizations": 11,
		"totalTours": 19,
		"totalDays": 1040,
		"totalCountries": 23,
		"totalStates": 90,
		"totalEvents": 324,
		"eventPercent": 0.927692,
		"totalScheduleItems": 4219,
		"schedulePercent": 0.934578,
		"totalGuests": 1408,
		"guestPercent": 0.883592,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 74
	},
	{
		"id": "100a55f",
		"email": "joe@unifiedmusicgroup.com",
		"name": "Joe",
		"createdDate": "2022-02-24 23:12:46",
		"totalOrganizations": 3,
		"totalTours": 13,
		"totalDays": 533,
		"totalCountries": 12,
		"totalStates": 48,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 1259,
		"schedulePercent": 0.554231,
		"totalGuests": 1220,
		"guestPercent": 0.860681,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 243
	},
	{
		"id": "46a384b",
		"email": "hannahmillerla@gmail.com",
		"name": "Hannah",
		"createdDate": "2019-09-17 21:07:23",
		"totalOrganizations": 4,
		"totalTours": 15,
		"totalDays": 531,
		"totalCountries": 14,
		"totalStates": 56,
		"totalEvents": 201,
		"eventPercent": 0.808237,
		"totalScheduleItems": 2540,
		"schedulePercent": 0.79804,
		"totalGuests": 2009,
		"guestPercent": 0.933916,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 61
	},
	{
		"id": "4eab94b",
		"email": "ericstecki@gmail.com",
		"name": "Eric",
		"createdDate": "2015-09-04 19:42:42",
		"totalOrganizations": 9,
		"totalTours": 24,
		"totalDays": 411,
		"totalCountries": 11,
		"totalStates": 40,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 1379,
		"schedulePercent": 0.58681,
		"totalGuests": 669,
		"guestPercent": 0.743875,
		"mostGuestDate": "2023-02-24 00:00:00",
		"mostGuestName": "The Danforth Music Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 70
	},
	{
		"id": "df6f0a1",
		"email": "hollerboyrecords@gmail.com",
		"name": "Loyd",
		"createdDate": "2021-11-04 02:19:51",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 37,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "52f9e0a",
		"email": "Seth.talley@gmail.com",
		"name": "Seth",
		"createdDate": "2014-02-05 02:25:30",
		"totalOrganizations": 8,
		"totalTours": 14,
		"totalDays": 587,
		"totalCountries": 2,
		"totalStates": 41,
		"totalEvents": 174,
		"eventPercent": 0.759502,
		"totalScheduleItems": 2730,
		"schedulePercent": 0.823335,
		"totalGuests": 888,
		"guestPercent": 0.796451,
		"mostGuestDate": "2023-04-22 00:00:00",
		"mostGuestName": "Great Southern Bank Arena",
		"mostGuestCity": "Springfield",
		"mostGuestAmount": 68
	},
	{
		"id": "08e3f45",
		"email": "kai@protain.de",
		"name": "Kai",
		"createdDate": "2019-12-15 15:14:36",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 150,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 392,
		"schedulePercent": 0.254139,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Turbinenhalle 2",
		"mostGuestCity": "Oberhausen",
		"mostGuestAmount": 4
	},
	{
		"id": "1606bff",
		"email": "curtwalsh@claywalker.com",
		"name": "Curt",
		"createdDate": "2017-07-22 14:13:26",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 179,
		"totalCountries": 1,
		"totalStates": 29,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 983,
		"schedulePercent": 0.476758,
		"totalGuests": 355,
		"guestPercent": 0.632631,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Billy Bob's",
		"mostGuestCity": "Ft. Worth",
		"mostGuestAmount": 48
	},
	{
		"id": "97c7fef",
		"email": "mcbridesride@me.com",
		"name": "Christian",
		"createdDate": "2011-01-11 05:52:35",
		"totalOrganizations": 26,
		"totalTours": 30,
		"totalDays": 590,
		"totalCountries": 10,
		"totalStates": 32,
		"totalEvents": 170,
		"eventPercent": 0.752086,
		"totalScheduleItems": 2362,
		"schedulePercent": 0.775262,
		"totalGuests": 101,
		"guestPercent": 0.474904,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "The Vanguard",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 49
	},
	{
		"id": "66dae1f",
		"email": "kiyalily11@gmail.com",
		"name": "Kiya",
		"createdDate": "2023-02-10 17:12:17",
		"totalOrganizations": 9,
		"totalTours": 14,
		"totalDays": 910,
		"totalCountries": 7,
		"totalStates": 36,
		"totalEvents": 198,
		"eventPercent": 0.802543,
		"totalScheduleItems": 2448,
		"schedulePercent": 0.785591,
		"totalGuests": 411,
		"guestPercent": 0.658191,
		"mostGuestDate": "2023-04-17 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 39
	},
	{
		"id": "5d3a94b",
		"email": "eelliott@cityparksfoundation.org",
		"name": "Erika",
		"createdDate": "2010-06-03 16:49:48",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d9ca0e0",
		"email": "detourmgmt@gmail.com",
		"name": "David",
		"createdDate": "2009-08-15 20:38:31",
		"totalOrganizations": 14,
		"totalTours": 11,
		"totalDays": 693,
		"totalCountries": 13,
		"totalStates": 54,
		"totalEvents": 206,
		"eventPercent": 0.816183,
		"totalScheduleItems": 2458,
		"schedulePercent": 0.78718,
		"totalGuests": 117,
		"guestPercent": 0.488412,
		"mostGuestDate": "2023-07-26 00:00:00",
		"mostGuestName": "Mizner Park Amphitheatre",
		"mostGuestCity": "Boca Raton",
		"mostGuestAmount": 42
	},
	{
		"id": "a3a0f37",
		"email": "norman@themavericksmusic.com",
		"name": "Norman",
		"createdDate": "2019-03-07 23:18:42",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 1587,
		"totalCountries": 13,
		"totalStates": 63,
		"totalEvents": 331,
		"eventPercent": 0.932327,
		"totalScheduleItems": 2988,
		"schedulePercent": 0.849821,
		"totalGuests": 1604,
		"guestPercent": 0.903324,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 96
	},
	{
		"id": "81b4273",
		"email": "kevin@legacyartists.us",
		"name": "KEVIN",
		"createdDate": "2022-08-17 16:16:55",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 323,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 94,
		"schedulePercent": 0.0852867,
		"totalGuests": 32,
		"guestPercent": 0.392531,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Kingdom",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 32
	},
	{
		"id": "1904838",
		"email": "lisa@plazatravel.com",
		"name": "Lisa",
		"createdDate": "2019-09-07 00:02:43",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 349,
		"totalCountries": 6,
		"totalStates": 38,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 716,
		"schedulePercent": 0.386836,
		"totalGuests": 867,
		"guestPercent": 0.791551,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 139
	},
	{
		"id": "8c782f0",
		"email": "sofie@theartist.eu",
		"name": "Sylvester Erbs",
		"createdDate": "2017-02-27 10:27:38",
		"totalOrganizations": 56,
		"totalTours": 38,
		"totalDays": 990,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 873,
		"eventPercent": 0.988743,
		"totalScheduleItems": 5884,
		"schedulePercent": 0.978678,
		"totalGuests": 1656,
		"guestPercent": 0.907694,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Royal Arena",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 140
	},
	{
		"id": "8c7af24",
		"email": "mayacummingsbusiness@gmail.com",
		"name": "Maya ",
		"createdDate": "2021-11-04 00:20:26",
		"totalOrganizations": 5,
		"totalTours": 11,
		"totalDays": 388,
		"totalCountries": 15,
		"totalStates": 50,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 1260,
		"schedulePercent": 0.554893,
		"totalGuests": 450,
		"guestPercent": 0.67395,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 207
	},
	{
		"id": "c841f09",
		"email": "touring@tunedin.co.nz",
		"name": "Taylor",
		"createdDate": "2022-07-21 04:49:22",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 398,
		"totalCountries": 9,
		"totalStates": 20,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 403,
		"schedulePercent": 0.258376,
		"totalGuests": 46,
		"guestPercent": 0.415044,
		"mostGuestDate": "2023-10-04 00:00:00",
		"mostGuestName": "Powerstation",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 11
	},
	{
		"id": "32c0a18",
		"email": "akmonger@gmail.com",
		"name": "Amanda",
		"createdDate": "2013-07-26 19:05:57",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 184,
		"totalCountries": 3,
		"totalStates": 25,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1600,
		"schedulePercent": 0.642829,
		"totalGuests": 1800,
		"guestPercent": 0.92054,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Paycom Center",
		"mostGuestCity": "Oklahoma City",
		"mostGuestAmount": 167
	},
	{
		"id": "17a3075",
		"email": "amillionwatts@gmail.com",
		"name": "Reuben",
		"createdDate": "2010-01-27 20:25:33",
		"totalOrganizations": 9,
		"totalTours": 3,
		"totalDays": 385,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 438,
		"schedulePercent": 0.272017,
		"totalGuests": 158,
		"guestPercent": 0.520858,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "Town Ballroom",
		"mostGuestCity": "Buffalo",
		"mostGuestAmount": 32
	},
	{
		"id": "1c3a04d",
		"email": "Musikoord@gmail.com",
		"name": "Kallyne",
		"createdDate": "2015-09-16 20:56:13",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 536,
		"totalCountries": 5,
		"totalStates": 35,
		"totalEvents": 259,
		"eventPercent": 0.884783,
		"totalScheduleItems": 4147,
		"schedulePercent": 0.93087,
		"totalGuests": 20,
		"guestPercent": 0.36909,
		"mostGuestDate": "2023-08-10 00:00:00",
		"mostGuestName": "Allegiant Stadium",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 20
	},
	{
		"id": "c5f80d0",
		"email": "sam@samiamentertainment.com",
		"name": "Samantha",
		"createdDate": "2021-01-19 18:21:54",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 547,
		"totalCountries": 4,
		"totalStates": 38,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 864,
		"schedulePercent": 0.440869,
		"totalGuests": 88,
		"guestPercent": 0.461661,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "The Heights Theater",
		"mostGuestCity": "Houston",
		"mostGuestAmount": 14
	},
	{
		"id": "2021d26",
		"email": "jskarz11@gmail.com",
		"name": "Joe",
		"createdDate": "2013-10-17 03:23:46",
		"totalOrganizations": 14,
		"totalTours": 9,
		"totalDays": 653,
		"totalCountries": 13,
		"totalStates": 59,
		"totalEvents": 214,
		"eventPercent": 0.829559,
		"totalScheduleItems": 2232,
		"schedulePercent": 0.755397,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ab4c683",
		"email": "saveyourshows@gmail.com",
		"name": "Brian",
		"createdDate": "2011-09-27 23:57:41",
		"totalOrganizations": 21,
		"totalTours": 30,
		"totalDays": 1176,
		"totalCountries": 14,
		"totalStates": 67,
		"totalEvents": 404,
		"eventPercent": 0.958019,
		"totalScheduleItems": 5468,
		"schedulePercent": 0.972586,
		"totalGuests": 4067,
		"guestPercent": 0.984373,
		"mostGuestDate": "2023-06-19 00:00:00",
		"mostGuestName": "Moore Theatre",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 133
	},
	{
		"id": "1fffce0",
		"email": "carl@thechoirofman.co.uk",
		"name": "Carl",
		"createdDate": "2019-01-31 13:55:29",
		"totalOrganizations": 12,
		"totalTours": 6,
		"totalDays": 555,
		"totalCountries": 3,
		"totalStates": 52,
		"totalEvents": 430,
		"eventPercent": 0.964111,
		"totalScheduleItems": 4125,
		"schedulePercent": 0.929016,
		"totalGuests": 58,
		"guestPercent": 0.431863,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "Kauffman Center for the Performing Arts",
		"mostGuestCity": "Kansas City",
		"mostGuestAmount": 18
	},
	{
		"id": "c80d35d",
		"email": "mikekeidel@gmail.com",
		"name": "Mike",
		"createdDate": "2012-03-29 01:09:28",
		"totalOrganizations": 11,
		"totalTours": 7,
		"totalDays": 318,
		"totalCountries": 10,
		"totalStates": 46,
		"totalEvents": 156,
		"eventPercent": 0.718051,
		"totalScheduleItems": 2337,
		"schedulePercent": 0.772348,
		"totalGuests": 2267,
		"guestPercent": 0.946365,
		"mostGuestDate": "2023-03-01 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 135
	},
	{
		"id": "01bb631",
		"email": "c.damm@damm.productions",
		"name": "Christian",
		"createdDate": "2022-05-25 20:09:03",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 198,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 1064,
		"schedulePercent": 0.502318,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "02d60c8",
		"email": "dylanbodleymusic@gmail.com",
		"name": "Dylan",
		"createdDate": "2021-11-02 02:57:23",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 348,
		"totalCountries": 3,
		"totalStates": 34,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 2199,
		"schedulePercent": 0.749305,
		"totalGuests": 887,
		"guestPercent": 0.796054,
		"mostGuestDate": "2023-07-26 00:00:00",
		"mostGuestName": "Santander Arena",
		"mostGuestCity": "Reading",
		"mostGuestAmount": 52
	},
	{
		"id": "526ed5a",
		"email": "avisdreese@gmail.com",
		"name": "Avis",
		"createdDate": "2019-05-15 17:39:40",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 431,
		"totalCountries": 6,
		"totalStates": 41,
		"totalEvents": 141,
		"eventPercent": 0.678321,
		"totalScheduleItems": 1221,
		"schedulePercent": 0.545491,
		"totalGuests": 447,
		"guestPercent": 0.672096,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 50
	},
	{
		"id": "a71236d",
		"email": "chucklesja@gmail.com",
		"name": "Robert",
		"createdDate": "2022-09-02 23:07:04",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 40,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 283,
		"schedulePercent": 0.199841,
		"totalGuests": 59,
		"guestPercent": 0.433982,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "Premier Theater at Foxwoods Resorts Casino",
		"mostGuestCity": "Ledyard",
		"mostGuestAmount": 10
	},
	{
		"id": "e46478a",
		"email": "meganfrestedt@gmail.com",
		"name": "Frestedt",
		"createdDate": "2014-01-11 04:41:49",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 119,
		"totalCountries": 3,
		"totalStates": 9,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 664,
		"schedulePercent": 0.366574,
		"totalGuests": 323,
		"guestPercent": 0.61568,
		"mostGuestDate": "2023-08-14 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 61
	},
	{
		"id": "f20a504",
		"email": "rdrummer@earthlink.net",
		"name": "Ronnie",
		"createdDate": "2014-05-27 19:01:42",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 165,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 428,
		"schedulePercent": 0.267117,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Ironstone Amphitheatre",
		"mostGuestCity": "Murphys",
		"mostGuestAmount": 4
	},
	{
		"id": "478bfb9",
		"email": "bmatt.tm@gmail.com",
		"name": "Brandon",
		"createdDate": "2019-09-13 18:58:47",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 1353,
		"totalCountries": 6,
		"totalStates": 54,
		"totalEvents": 243,
		"eventPercent": 0.864786,
		"totalScheduleItems": 4043,
		"schedulePercent": 0.925043,
		"totalGuests": 1438,
		"guestPercent": 0.885843,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 102
	},
	{
		"id": "45c4f87",
		"email": "peter@dereynamanagement.com",
		"name": "Peter ",
		"createdDate": "2022-11-14 03:30:21",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 229,
		"totalCountries": 13,
		"totalStates": 40,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 1287,
		"schedulePercent": 0.562839,
		"totalGuests": 432,
		"guestPercent": 0.66574,
		"mostGuestDate": "2023-03-22 00:00:00",
		"mostGuestName": "The Regent",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 27
	},
	{
		"id": "e948352",
		"email": "dbldavid73@gmail.com",
		"name": "David",
		"createdDate": "2015-03-05 00:50:32",
		"totalOrganizations": 16,
		"totalTours": 16,
		"totalDays": 1159,
		"totalCountries": 4,
		"totalStates": 55,
		"totalEvents": 418,
		"eventPercent": 0.962257,
		"totalScheduleItems": 5257,
		"schedulePercent": 0.969143,
		"totalGuests": 910,
		"guestPercent": 0.801616,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Pine Knob Music Theatre",
		"mostGuestCity": "City of the Village of Clarkston",
		"mostGuestAmount": 62
	},
	{
		"id": "bd16f33",
		"email": "osku.jalkanen@warnermusic.com",
		"name": "Osku",
		"createdDate": "2020-02-03 09:17:50",
		"totalOrganizations": 27,
		"totalTours": 25,
		"totalDays": 531,
		"totalCountries": 2,
		"totalStates": 75,
		"totalEvents": 365,
		"eventPercent": 0.948086,
		"totalScheduleItems": 1792,
		"schedulePercent": 0.683618,
		"totalGuests": 238,
		"guestPercent": 0.572639,
		"mostGuestDate": "2023-08-10 00:00:00",
		"mostGuestName": "Allas Sea Pool",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 28
	},
	{
		"id": "cec1595",
		"email": "jon@numbskullshows.com",
		"name": "Jon",
		"createdDate": "2018-02-27 22:15:58",
		"totalOrganizations": 11,
		"totalTours": 27,
		"totalDays": 3632,
		"totalCountries": 14,
		"totalStates": 50,
		"totalEvents": 218,
		"eventPercent": 0.834724,
		"totalScheduleItems": 1592,
		"schedulePercent": 0.640313,
		"totalGuests": 1052,
		"guestPercent": 0.833135,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Annexet",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 155
	},
	{
		"id": "3f764d6",
		"email": "jillcrowner@gmail.com",
		"name": "Jill",
		"createdDate": "2023-08-26 18:35:14",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 219,
		"totalCountries": 3,
		"totalStates": 20,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 1034,
		"schedulePercent": 0.49318,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "946b343",
		"email": "helen@1325.co.uk",
		"name": "Helen",
		"createdDate": "2018-07-16 13:08:36",
		"totalOrganizations": 8,
		"totalTours": 14,
		"totalDays": 1047,
		"totalCountries": 7,
		"totalStates": 33,
		"totalEvents": 491,
		"eventPercent": 0.972851,
		"totalScheduleItems": 6969,
		"schedulePercent": 0.989008,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Lincoln Financial Field",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 4
	},
	{
		"id": "facab60",
		"email": "EXHIBITAUDIO@gmail.com",
		"name": "Gregory",
		"createdDate": "2017-04-28 19:28:50",
		"totalOrganizations": 12,
		"totalTours": 9,
		"totalDays": 1289,
		"totalCountries": 4,
		"totalStates": 35,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 2365,
		"schedulePercent": 0.775526,
		"totalGuests": 669,
		"guestPercent": 0.743875,
		"mostGuestDate": "2023-05-03 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 125
	},
	{
		"id": "761291d",
		"email": "bboyninjatx@gmail.com",
		"name": "Anthony",
		"createdDate": "2021-07-16 11:36:07",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 341,
		"totalCountries": 8,
		"totalStates": 68,
		"totalEvents": 251,
		"eventPercent": 0.873792,
		"totalScheduleItems": 2606,
		"schedulePercent": 0.808502,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "aa82b9d",
		"email": "jamey@hiriemusic.com",
		"name": "Jamey",
		"createdDate": "2016-10-26 22:30:00",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 321,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 982,
		"schedulePercent": 0.476361,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e2eed3e",
		"email": "davina@ezracollective.com",
		"name": "Davina",
		"createdDate": "2023-07-12 10:59:11",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 199,
		"totalCountries": 11,
		"totalStates": 18,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 425,
		"schedulePercent": 0.265793,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "The Croxton Bandroom",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 2
	},
	{
		"id": "f385f51",
		"email": "samdicarlo27@gmail.com",
		"name": "Sam",
		"createdDate": "2017-04-12 19:48:36",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 877,
		"totalCountries": 6,
		"totalStates": 55,
		"totalEvents": 307,
		"eventPercent": 0.919216,
		"totalScheduleItems": 5967,
		"schedulePercent": 0.97987,
		"totalGuests": 4400,
		"guestPercent": 0.987286,
		"mostGuestDate": "2023-09-07 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 118
	},
	{
		"id": "5c7f9ce",
		"email": "advance@josh-smith.co",
		"name": "Maddie",
		"createdDate": "2022-09-27 08:02:25",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 112,
		"totalCountries": 3,
		"totalStates": 12,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 915,
		"schedulePercent": 0.455966,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6275b9c",
		"email": "njsoundguy@gmail.com",
		"name": "Dean",
		"createdDate": "2016-04-13 05:15:00",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 71,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 449,
		"schedulePercent": 0.27599,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Saenger Theatre",
		"mostGuestCity": "Mobile",
		"mostGuestAmount": 2
	},
	{
		"id": "a85e3dc",
		"email": "eliisa@team.fi",
		"name": "Eliisa",
		"createdDate": "2023-09-05 12:25:36",
		"totalOrganizations": 51,
		"totalTours": 41,
		"totalDays": 339,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 338,
		"eventPercent": 0.936432,
		"totalScheduleItems": 1206,
		"schedulePercent": 0.541518,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "df113fc",
		"email": "anna@unitedvision.se",
		"name": "Anna",
		"createdDate": "2022-03-16 14:07:52",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 242,
		"totalCountries": 6,
		"totalStates": 23,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 969,
		"schedulePercent": 0.471063,
		"totalGuests": 510,
		"guestPercent": 0.695272,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Goteborgs Konserthus Big Hall",
		"mostGuestCity": "Goteborg",
		"mostGuestAmount": 48
	},
	{
		"id": "988f60a",
		"email": "meganruddmusic@gmail.com",
		"name": "Megan",
		"createdDate": "2022-02-22 22:56:39",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 562,
		"totalCountries": 18,
		"totalStates": 43,
		"totalEvents": 327,
		"eventPercent": 0.929413,
		"totalScheduleItems": 3694,
		"schedulePercent": 0.9069,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b8e38c5",
		"email": "prodmgr314@gmail.com",
		"name": "Anthony ",
		"createdDate": "2015-09-22 04:30:59",
		"totalOrganizations": 22,
		"totalTours": 22,
		"totalDays": 551,
		"totalCountries": 22,
		"totalStates": 70,
		"totalEvents": 310,
		"eventPercent": 0.920938,
		"totalScheduleItems": 3507,
		"schedulePercent": 0.894584,
		"totalGuests": 597,
		"guestPercent": 0.725467,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Capital One Arena",
		"mostGuestCity": "Washington",
		"mostGuestAmount": 64
	},
	{
		"id": "dd2fe13",
		"email": "mp@es-g.si",
		"name": "Email",
		"createdDate": "2022-10-13 09:10:18",
		"totalOrganizations": 1,
		"totalTours": 6,
		"totalDays": 86,
		"totalCountries": 6,
		"totalStates": 9,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 534,
		"schedulePercent": 0.314793,
		"totalGuests": 673,
		"guestPercent": 0.745332,
		"mostGuestDate": "2023-10-22 00:00:00",
		"mostGuestName": "Zagreb",
		"mostGuestCity": "Zagreb",
		"mostGuestAmount": 95
	},
	{
		"id": "de02010",
		"email": "mike.rowland.tech@gmail.com",
		"name": "Michael",
		"createdDate": "2012-10-07 13:53:23",
		"totalOrganizations": 10,
		"totalTours": 16,
		"totalDays": 464,
		"totalCountries": 11,
		"totalStates": 65,
		"totalEvents": 194,
		"eventPercent": 0.794597,
		"totalScheduleItems": 3943,
		"schedulePercent": 0.920805,
		"totalGuests": 1017,
		"guestPercent": 0.82744,
		"mostGuestDate": "2023-03-10 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 46
	},
	{
		"id": "65ef1be",
		"email": "slocircus@icloud.com",
		"name": "Steven",
		"createdDate": "2023-03-16 01:00:40",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 290,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 167,
		"schedulePercent": 0.130711,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9e8c026",
		"email": "cserino78@gmail.com",
		"name": "Chris ",
		"createdDate": "2014-07-02 16:29:58",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 434,
		"totalCountries": 4,
		"totalStates": 48,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 2009,
		"schedulePercent": 0.720832,
		"totalGuests": 942,
		"guestPercent": 0.810224,
		"mostGuestDate": "2023-02-16 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 60
	},
	{
		"id": "ac778de",
		"email": "joshuapannepacker@gmail.com",
		"name": "Josh",
		"createdDate": "2022-01-18 17:26:54",
		"totalOrganizations": 8,
		"totalTours": 18,
		"totalDays": 905,
		"totalCountries": 9,
		"totalStates": 42,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 1911,
		"schedulePercent": 0.705734,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "Prudential Center",
		"mostGuestCity": "Newark",
		"mostGuestAmount": 1
	},
	{
		"id": "3aade43",
		"email": "pete@whiteleafevents.com",
		"name": "Pete",
		"createdDate": "2022-03-11 22:05:49",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 112,
		"totalCountries": 5,
		"totalStates": 52,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 645,
		"schedulePercent": 0.360482,
		"totalGuests": 348,
		"guestPercent": 0.628658,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "Greenfield Lake Amphitheatre",
		"mostGuestCity": "Wilmington",
		"mostGuestAmount": 20
	},
	{
		"id": "a8022c8",
		"email": "harvey.katie@gmail.com",
		"name": "Katie",
		"createdDate": "2015-03-04 20:48:12",
		"totalOrganizations": 15,
		"totalTours": 38,
		"totalDays": 586,
		"totalCountries": 9,
		"totalStates": 55,
		"totalEvents": 162,
		"eventPercent": 0.732883,
		"totalScheduleItems": 2004,
		"schedulePercent": 0.719772,
		"totalGuests": 266,
		"guestPercent": 0.587869,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 41
	},
	{
		"id": "9bc1087",
		"email": "tru_thoughts@hotmail.com",
		"name": "Richard",
		"createdDate": "2011-07-14 16:28:09",
		"totalOrganizations": 46,
		"totalTours": 21,
		"totalDays": 847,
		"totalCountries": 16,
		"totalStates": 60,
		"totalEvents": 301,
		"eventPercent": 0.91617,
		"totalScheduleItems": 2896,
		"schedulePercent": 0.841213,
		"totalGuests": 348,
		"guestPercent": 0.628658,
		"mostGuestDate": "2023-11-15 00:00:00",
		"mostGuestName": "Music Hall of Williamsburg",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 43
	},
	{
		"id": "6a25813",
		"email": "Ale.Ripatti@warnermusic.com",
		"name": "Ale",
		"createdDate": "2018-05-30 21:12:59",
		"totalOrganizations": 26,
		"totalTours": 25,
		"totalDays": 737,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 432,
		"eventPercent": 0.96464,
		"totalScheduleItems": 2242,
		"schedulePercent": 0.756721,
		"totalGuests": 655,
		"guestPercent": 0.740697,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Tavara-asema",
		"mostGuestCity": "Tampere",
		"mostGuestAmount": 36
	},
	{
		"id": "55d3a4c",
		"email": "colin@colinmillerworks.com",
		"name": "Colin",
		"createdDate": "2016-08-12 18:48:22",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 563,
		"totalCountries": 11,
		"totalStates": 44,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 2500,
		"schedulePercent": 0.793537,
		"totalGuests": 283,
		"guestPercent": 0.595153,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "Brilla Torrevieja",
		"mostGuestCity": "Torrevieja",
		"mostGuestAmount": 37
	},
	{
		"id": "475fd38",
		"email": "samantha.chilli@gmail.com",
		"name": "Samantha",
		"createdDate": "2022-04-29 03:43:09",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 324,
		"totalCountries": 17,
		"totalStates": 58,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 1459,
		"schedulePercent": 0.605218,
		"totalGuests": 579,
		"guestPercent": 0.718713,
		"mostGuestDate": "2023-03-12 00:00:00",
		"mostGuestName": "Barba Negra Red Stage",
		"mostGuestCity": "Budapest",
		"mostGuestAmount": 55
	},
	{
		"id": "01cef8f",
		"email": "joleemail@me.com",
		"name": "Jo",
		"createdDate": "2019-01-14 17:43:24",
		"totalOrganizations": 5,
		"totalTours": 12,
		"totalDays": 298,
		"totalCountries": 16,
		"totalStates": 61,
		"totalEvents": 156,
		"eventPercent": 0.718051,
		"totalScheduleItems": 2036,
		"schedulePercent": 0.72507,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e49d188",
		"email": "tourjess@gmail.com",
		"name": "Jessica",
		"createdDate": "2014-03-21 16:28:30",
		"totalOrganizations": 12,
		"totalTours": 16,
		"totalDays": 605,
		"totalCountries": 19,
		"totalStates": 77,
		"totalEvents": 285,
		"eventPercent": 0.905973,
		"totalScheduleItems": 4411,
		"schedulePercent": 0.941465,
		"totalGuests": 1348,
		"guestPercent": 0.877102,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 50
	},
	{
		"id": "5b6d6dc",
		"email": "assistant@gentrytouring.com",
		"name": "Gentry",
		"createdDate": "2022-04-07 01:15:43",
		"totalOrganizations": 13,
		"totalTours": 11,
		"totalDays": 2028,
		"totalCountries": 16,
		"totalStates": 63,
		"totalEvents": 265,
		"eventPercent": 0.890743,
		"totalScheduleItems": 4664,
		"schedulePercent": 0.950603,
		"totalGuests": 1137,
		"guestPercent": 0.846908,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 81
	},
	{
		"id": "bd1f387",
		"email": "Thewilkiewilkinson@gmail.com",
		"name": "Wilkie",
		"createdDate": "2013-08-12 12:32:44",
		"totalOrganizations": 10,
		"totalTours": 18,
		"totalDays": 1125,
		"totalCountries": 26,
		"totalStates": 72,
		"totalEvents": 309,
		"eventPercent": 0.920408,
		"totalScheduleItems": 5602,
		"schedulePercent": 0.974705,
		"totalGuests": 53,
		"guestPercent": 0.426434,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Tivoli Gardens",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 49
	},
	{
		"id": "39775db",
		"email": "hjprod@aol.com",
		"name": "Harold",
		"createdDate": "2013-03-12 04:23:34",
		"totalOrganizations": 7,
		"totalTours": 2,
		"totalDays": 604,
		"totalCountries": 5,
		"totalStates": 13,
		"totalEvents": 215,
		"eventPercent": 0.831016,
		"totalScheduleItems": 2305,
		"schedulePercent": 0.765859,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "dc4c5b6",
		"email": "amandadbryant@gmail.com",
		"name": "Amanda",
		"createdDate": "2016-02-22 06:20:06",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 302,
		"totalCountries": 3,
		"totalStates": 8,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 299,
		"schedulePercent": 0.207787,
		"totalGuests": 32,
		"guestPercent": 0.392531,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 22
	},
	{
		"id": "893c2d1",
		"email": "hello@thelivesource.co.uk",
		"name": "LiveSource",
		"createdDate": "2022-05-12 13:15:14",
		"totalOrganizations": 12,
		"totalTours": 35,
		"totalDays": 771,
		"totalCountries": 16,
		"totalStates": 42,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 1132,
		"schedulePercent": 0.520461,
		"totalGuests": 169,
		"guestPercent": 0.529466,
		"mostGuestDate": "2023-02-08 00:00:00",
		"mostGuestName": "HERE at Outernet",
		"mostGuestCity": "London",
		"mostGuestAmount": 70
	},
	{
		"id": "6beed9d",
		"email": "x@dolessmanagement.com",
		"name": "Christiana",
		"createdDate": "2023-02-22 19:15:24",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 472,
		"totalCountries": 1,
		"totalStates": 23,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 154,
		"schedulePercent": 0.123692,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f9b6cfd",
		"email": "craig@diamondcitystudio.com",
		"name": "Craig",
		"createdDate": "2022-11-01 02:45:46",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 225,
		"totalCountries": 2,
		"totalStates": 43,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 1365,
		"schedulePercent": 0.584029,
		"totalGuests": 387,
		"guestPercent": 0.647596,
		"mostGuestDate": "2023-05-11 00:00:00",
		"mostGuestName": "The Bowery Ballroom",
		"mostGuestCity": "New York",
		"mostGuestAmount": 55
	},
	{
		"id": "9feaf12",
		"email": "jjbdavis@comcast.net",
		"name": "Jeff",
		"createdDate": "2011-11-30 21:05:33",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 652,
		"schedulePercent": 0.362998,
		"totalGuests": 852,
		"guestPercent": 0.787975,
		"mostGuestDate": "2023-12-01 00:00:00",
		"mostGuestName": "Von Braun Center (Probst Arena)",
		"mostGuestCity": "Huntsville",
		"mostGuestAmount": 97
	},
	{
		"id": "acef06e",
		"email": "ks@csb.dk",
		"name": "Kenneth",
		"createdDate": "2019-01-11 10:51:51",
		"totalOrganizations": 5,
		"totalTours": 14,
		"totalDays": 173,
		"totalCountries": 6,
		"totalStates": 17,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1243,
		"schedulePercent": 0.550523,
		"totalGuests": 533,
		"guestPercent": 0.703748,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "BirkerÃ¸d IdrÃ¦tscenter",
		"mostGuestCity": "BirkerÃ¸d",
		"mostGuestAmount": 35
	},
	{
		"id": "1481ff1",
		"email": "blair@blairam.com",
		"name": "Blair",
		"createdDate": "2012-07-10 18:49:41",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 206,
		"totalCountries": 1,
		"totalStates": 15,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 255,
		"schedulePercent": 0.183287,
		"totalGuests": 137,
		"guestPercent": 0.504039,
		"mostGuestDate": "2023-06-21 00:00:00",
		"mostGuestName": "Wrigley Field",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 34
	},
	{
		"id": "6b0ba6f",
		"email": "amanda.martinez1174@gmail.com",
		"name": "Amanda",
		"createdDate": "2022-12-08 18:57:00",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 153,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 1004,
		"schedulePercent": 0.486161,
		"totalGuests": 939,
		"guestPercent": 0.809297,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Giant Center",
		"mostGuestCity": "Hershey",
		"mostGuestAmount": 72
	},
	{
		"id": "5e56292",
		"email": "pursesnatchers@gmail.com",
		"name": "Doug",
		"createdDate": "2012-09-14 17:34:58",
		"totalOrganizations": 38,
		"totalTours": 20,
		"totalDays": 159,
		"totalCountries": 7,
		"totalStates": 32,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 724,
		"schedulePercent": 0.390147,
		"totalGuests": 366,
		"guestPercent": 0.639386,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Minnesota State Fair",
		"mostGuestCity": "St Paul",
		"mostGuestAmount": 65
	},
	{
		"id": "85b1b8e",
		"email": "czhu@aegpresents.com",
		"name": "Carol",
		"createdDate": "2023-02-02 01:58:26",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 345,
		"totalCountries": 14,
		"totalStates": 42,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 1542,
		"schedulePercent": 0.628261,
		"totalGuests": 432,
		"guestPercent": 0.66574,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 58
	},
	{
		"id": "352c5a0",
		"email": "evancormiercsct@gmail.com",
		"name": "Evan",
		"createdDate": "2022-08-10 12:58:05",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 237,
		"totalCountries": 5,
		"totalStates": 33,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 1038,
		"schedulePercent": 0.494372,
		"totalGuests": 519,
		"guestPercent": 0.698451,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 54
	},
	{
		"id": "395ab40",
		"email": "claudia.2@hotmail.co.uk",
		"name": "claudia ",
		"createdDate": "2023-06-26 16:06:16",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 71,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 6,
		"eventPercent": 0.0397298,
		"totalScheduleItems": 231,
		"schedulePercent": 0.169779,
		"totalGuests": 37,
		"guestPercent": 0.400212,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "Montreux Jazz Festival",
		"mostGuestCity": "Montreux",
		"mostGuestAmount": 7
	},
	{
		"id": "2168368",
		"email": "ayleen@radarmanagement.com",
		"name": "Danny",
		"createdDate": "2023-01-19 21:39:42",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 33,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 9,
		"eventPercent": 0.0607866,
		"totalScheduleItems": 67,
		"schedulePercent": 0.0667461,
		"totalGuests": 50,
		"guestPercent": 0.421798,
		"mostGuestDate": "2023-02-28 00:00:00",
		"mostGuestName": "Rockwood Music Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 50
	},
	{
		"id": "594ea69",
		"email": "Info@dxdtours.com",
		"name": "Duncan",
		"createdDate": "2022-02-28 12:43:47",
		"totalOrganizations": 26,
		"totalTours": 20,
		"totalDays": 655,
		"totalCountries": 8,
		"totalStates": 42,
		"totalEvents": 225,
		"eventPercent": 0.842008,
		"totalScheduleItems": 2907,
		"schedulePercent": 0.842802,
		"totalGuests": 928,
		"guestPercent": 0.806251,
		"mostGuestDate": "2023-05-23 00:00:00",
		"mostGuestName": "The Warfield",
		"mostGuestCity": "San Francisco",
		"mostGuestAmount": 63
	},
	{
		"id": "628201d",
		"email": "execbranchtouring@gmail.com",
		"name": "Terrance",
		"createdDate": "2012-10-23 22:01:06",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 30,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 88,
		"schedulePercent": 0.0805191,
		"totalGuests": 32,
		"guestPercent": 0.392531,
		"mostGuestDate": "2023-04-02 00:00:00",
		"mostGuestName": "Lola's Room",
		"mostGuestCity": "Portland",
		"mostGuestAmount": 20
	},
	{
		"id": "8da9a51",
		"email": "kraxtrax@mac.com",
		"name": "William",
		"createdDate": "2009-08-05 01:38:45",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 364,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1345,
		"schedulePercent": 0.579129,
		"totalGuests": 439,
		"guestPercent": 0.668256,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Coachman Park Amphitheater",
		"mostGuestCity": "Clearwater",
		"mostGuestAmount": 31
	},
	{
		"id": "e9b3b3d",
		"email": "info@noisegate.eu",
		"name": "Dirk",
		"createdDate": "2017-05-06 10:03:39",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 184,
		"totalCountries": 21,
		"totalStates": 56,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 1726,
		"schedulePercent": 0.67064,
		"totalGuests": 747,
		"guestPercent": 0.76374,
		"mostGuestDate": "2023-05-19 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 81
	},
	{
		"id": "1bd50db",
		"email": "stuart@iamgrumpy.com",
		"name": "Stuart",
		"createdDate": "2019-01-16 08:11:37",
		"totalOrganizations": 6,
		"totalTours": 12,
		"totalDays": 974,
		"totalCountries": 11,
		"totalStates": 45,
		"totalEvents": 385,
		"eventPercent": 0.953649,
		"totalScheduleItems": 7217,
		"schedulePercent": 0.990332,
		"totalGuests": 1444,
		"guestPercent": 0.88624,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "The SSE Arena, Wembley",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 226
	},
	{
		"id": "4b16cee",
		"email": "jessi.tichenor@redlightmanagement.com",
		"name": "Jessi",
		"createdDate": "2016-09-21 13:31:06",
		"totalOrganizations": 18,
		"totalTours": 10,
		"totalDays": 301,
		"totalCountries": 2,
		"totalStates": 39,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 1033,
		"schedulePercent": 0.493047,
		"totalGuests": 446,
		"guestPercent": 0.671434,
		"mostGuestDate": "2023-09-27 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 80
	},
	{
		"id": "cf7b5d0",
		"email": "Drewiverson15@gmail.com",
		"name": "Drew",
		"createdDate": "2020-01-13 22:13:34",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 247,
		"totalCountries": 11,
		"totalStates": 30,
		"totalEvents": 155,
		"eventPercent": 0.716197,
		"totalScheduleItems": 1328,
		"schedulePercent": 0.574626,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "11b5a4f",
		"email": "henry.ashleym@gmail.com",
		"name": "Ashley",
		"createdDate": "2020-01-29 20:25:41",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 510,
		"totalCountries": 5,
		"totalStates": 46,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 2499,
		"schedulePercent": 0.793272,
		"totalGuests": 2767,
		"guestPercent": 0.963581,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 137
	},
	{
		"id": "8a0499c",
		"email": "pete.bowman@gmail.com",
		"name": "Peter",
		"createdDate": "2011-02-22 03:31:26",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 1190,
		"totalCountries": 3,
		"totalStates": 22,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1627,
		"schedulePercent": 0.648259,
		"totalGuests": 744,
		"guestPercent": 0.762945,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Nissan Stadium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 90
	},
	{
		"id": "f3ceb5b",
		"email": "info@cdp.com.au",
		"name": "Susan",
		"createdDate": "2018-01-31 06:02:27",
		"totalOrganizations": 5,
		"totalTours": 17,
		"totalDays": 989,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 550,
		"eventPercent": 0.979605,
		"totalScheduleItems": 3980,
		"schedulePercent": 0.921997,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8257414",
		"email": "alison@straightxnarrow.com",
		"name": "Alison",
		"createdDate": "2017-07-27 18:33:32",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 191,
		"totalCountries": 7,
		"totalStates": 30,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 1844,
		"schedulePercent": 0.694213,
		"totalGuests": 2662,
		"guestPercent": 0.958416,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The ICON Festival Stage",
		"mostGuestCity": "Cincinnati",
		"mostGuestAmount": 238
	},
	{
		"id": "dbe0ced",
		"email": "lloydwilliams@me.com",
		"name": "Lloyd",
		"createdDate": "2017-09-30 03:41:53",
		"totalOrganizations": 12,
		"totalTours": 16,
		"totalDays": 849,
		"totalCountries": 19,
		"totalStates": 68,
		"totalEvents": 383,
		"eventPercent": 0.952986,
		"totalScheduleItems": 5807,
		"schedulePercent": 0.977884,
		"totalGuests": 4920,
		"guestPercent": 0.991392,
		"mostGuestDate": "2023-07-03 00:00:00",
		"mostGuestName": "Banc of California Stadium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 219
	},
	{
		"id": "0aa220b",
		"email": "info@estmgmt.co.uk",
		"name": "Mark",
		"createdDate": "2019-07-16 12:35:05",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 145,
		"totalCountries": 10,
		"totalStates": 18,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 982,
		"schedulePercent": 0.476361,
		"totalGuests": 284,
		"guestPercent": 0.59608,
		"mostGuestDate": "2023-03-28 00:00:00",
		"mostGuestName": "Electric Brixton",
		"mostGuestCity": "London",
		"mostGuestAmount": 79
	},
	{
		"id": "f32afbe",
		"email": "rbuzzelli@mac.com",
		"name": "Randy",
		"createdDate": "2011-05-20 08:57:53",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 124,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 377,
		"schedulePercent": 0.246855,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-09-03 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 3
	},
	{
		"id": "3ebb18d",
		"email": "marthaearls@icloud.com",
		"name": "Martha",
		"createdDate": "2016-03-06 03:13:37",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 1117,
		"totalCountries": 8,
		"totalStates": 52,
		"totalEvents": 213,
		"eventPercent": 0.827837,
		"totalScheduleItems": 3609,
		"schedulePercent": 0.901338,
		"totalGuests": 531,
		"guestPercent": 0.702821,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "Thompson Boling Arena",
		"mostGuestCity": "Knoxville",
		"mostGuestAmount": 47
	},
	{
		"id": "8ef0ea3",
		"email": "Tristan_assistant@thecoreent.com",
		"name": "Email",
		"createdDate": "2022-11-02 20:07:33",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 750,
		"totalCountries": 4,
		"totalStates": 53,
		"totalEvents": 256,
		"eventPercent": 0.882135,
		"totalScheduleItems": 3814,
		"schedulePercent": 0.912462,
		"totalGuests": 510,
		"guestPercent": 0.695272,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "South Fork Dirt Riders",
		"mostGuestCity": "Taylorville",
		"mostGuestAmount": 29
	},
	{
		"id": "fe0d417",
		"email": "araymond@fullcircleartists.com",
		"name": "Amanda",
		"createdDate": "2022-04-13 20:08:25",
		"totalOrganizations": 7,
		"totalTours": 13,
		"totalDays": 41,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 166,
		"schedulePercent": 0.130314,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-01-18 00:00:00",
		"mostGuestName": "The Boca Raton",
		"mostGuestCity": "Boca Raton",
		"mostGuestAmount": 1
	},
	{
		"id": "3be0a2e",
		"email": "rocketsummermgmt@gmail.com",
		"name": "Tara",
		"createdDate": "2013-03-04 18:39:34",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 47,
		"totalCountries": 3,
		"totalStates": 22,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 279,
		"schedulePercent": 0.19759,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e1c2533",
		"email": "mollygtt@gmail.com",
		"name": "Molly",
		"createdDate": "2018-10-05 17:34:42",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 506,
		"totalCountries": 13,
		"totalStates": 52,
		"totalEvents": 171,
		"eventPercent": 0.75394,
		"totalScheduleItems": 1935,
		"schedulePercent": 0.708118,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2dab2ac",
		"email": "Rachelle@triple8mgmt.com",
		"name": "Rachelle",
		"createdDate": "2016-10-28 14:50:30",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 879,
		"totalCountries": 8,
		"totalStates": 48,
		"totalEvents": 230,
		"eventPercent": 0.849159,
		"totalScheduleItems": 2498,
		"schedulePercent": 0.79314,
		"totalGuests": 599,
		"guestPercent": 0.725864,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Billy Bob's",
		"mostGuestCity": "Ft. Worth",
		"mostGuestAmount": 65
	},
	{
		"id": "08a5756",
		"email": "alec@e1fx.com",
		"name": "Alec",
		"createdDate": "2020-12-09 16:33:16",
		"totalOrganizations": 46,
		"totalTours": 29,
		"totalDays": 787,
		"totalCountries": 3,
		"totalStates": 43,
		"totalEvents": 538,
		"eventPercent": 0.978281,
		"totalScheduleItems": 772,
		"schedulePercent": 0.409482,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-01-08 00:00:00",
		"mostGuestName": "TIAA Bank Field",
		"mostGuestCity": "Jacksonville",
		"mostGuestAmount": 0
	},
	{
		"id": "8625f05",
		"email": "charles@audio-engineer.co.uk",
		"name": "Charles",
		"createdDate": "2020-02-25 17:44:49",
		"totalOrganizations": 9,
		"totalTours": 13,
		"totalDays": 383,
		"totalCountries": 9,
		"totalStates": 16,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 1348,
		"schedulePercent": 0.580188,
		"totalGuests": 198,
		"guestPercent": 0.549331,
		"mostGuestDate": "2023-02-08 00:00:00",
		"mostGuestName": "The Garage",
		"mostGuestCity": "London",
		"mostGuestAmount": 39
	},
	{
		"id": "105761b",
		"email": "alyndalee@gmail.com",
		"name": "Alynda",
		"createdDate": "2014-06-13 20:27:27",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 102,
		"totalCountries": 7,
		"totalStates": 33,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 522,
		"schedulePercent": 0.308304,
		"totalGuests": 78,
		"guestPercent": 0.45398,
		"mostGuestDate": "2023-07-18 00:00:00",
		"mostGuestName": "Levon Helm Studios",
		"mostGuestCity": "Woodstock",
		"mostGuestAmount": 21
	},
	{
		"id": "a530c42",
		"email": "ilightfoot@conveyormanagement.com",
		"name": "Ivan",
		"createdDate": "2015-11-09 23:41:23",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 106,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 617,
		"schedulePercent": 0.348431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ca23b51",
		"email": "steve@blackwave.live",
		"name": "Steve",
		"createdDate": "2023-01-09 20:00:12",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 133,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 506,
		"schedulePercent": 0.301814,
		"totalGuests": 352,
		"guestPercent": 0.631042,
		"mostGuestDate": "2023-03-29 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 41
	},
	{
		"id": "78e5eae",
		"email": "dmspaeth@gmail.com",
		"name": "DEREK",
		"createdDate": "2019-07-14 00:03:22",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 341,
		"totalCountries": 4,
		"totalStates": 26,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 703,
		"schedulePercent": 0.381671,
		"totalGuests": 548,
		"guestPercent": 0.709178,
		"mostGuestDate": "2023-01-13 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 146
	},
	{
		"id": "5db9de8",
		"email": "daveungersound@gmail.com",
		"name": "David",
		"createdDate": "2018-09-26 18:31:08",
		"totalOrganizations": 2,
		"totalTours": 10,
		"totalDays": 239,
		"totalCountries": 11,
		"totalStates": 34,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 1078,
		"schedulePercent": 0.506953,
		"totalGuests": 48,
		"guestPercent": 0.419415,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Jazz At Lincoln Center",
		"mostGuestCity": "New York",
		"mostGuestAmount": 18
	},
	{
		"id": "5fa9ced",
		"email": "Hamois@me.com",
		"name": "Paul",
		"createdDate": "2013-03-01 13:32:23",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 39,
		"totalCountries": 5,
		"totalStates": 9,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 200,
		"schedulePercent": 0.150444,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d4446a4",
		"email": "mm@threesixzero.com",
		"name": "Milo",
		"createdDate": "2022-06-04 07:04:48",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 276,
		"totalCountries": 8,
		"totalStates": 34,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 694,
		"schedulePercent": 0.37836,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-11-26 00:00:00",
		"mostGuestName": "Vogue Theatre",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 8
	},
	{
		"id": "e8e5bdc",
		"email": "ohgnomegirl@gmail.com",
		"name": "Jennifer",
		"createdDate": "2017-09-19 14:14:04",
		"totalOrganizations": 15,
		"totalTours": 18,
		"totalDays": 1360,
		"totalCountries": 24,
		"totalStates": 71,
		"totalEvents": 292,
		"eventPercent": 0.910211,
		"totalScheduleItems": 5805,
		"schedulePercent": 0.977751,
		"totalGuests": 190,
		"guestPercent": 0.542445,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "Allegiant Stadium",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 66
	},
	{
		"id": "09639f8",
		"email": "jamesellswilson@gmail.com",
		"name": "James",
		"createdDate": "2022-03-10 03:01:50",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 35,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 492,
		"schedulePercent": 0.293604,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f8836bb",
		"email": "bcolettaj@gmail.com",
		"name": "Brad",
		"createdDate": "2021-05-29 04:01:03",
		"totalOrganizations": 9,
		"totalTours": 12,
		"totalDays": 589,
		"totalCountries": 11,
		"totalStates": 58,
		"totalEvents": 236,
		"eventPercent": 0.857237,
		"totalScheduleItems": 4362,
		"schedulePercent": 0.94014,
		"totalGuests": 3822,
		"guestPercent": 0.981989,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 185
	},
	{
		"id": "0d313d5",
		"email": "PennywiseTM1@gmail.com",
		"name": "Aaron",
		"createdDate": "2014-10-26 17:52:24",
		"totalOrganizations": 9,
		"totalTours": 8,
		"totalDays": 204,
		"totalCountries": 11,
		"totalStates": 39,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1753,
		"schedulePercent": 0.675142,
		"totalGuests": 621,
		"guestPercent": 0.731426,
		"mostGuestDate": "2023-05-08 00:00:00",
		"mostGuestName": "Crystal Ballroom",
		"mostGuestCity": "Portland",
		"mostGuestAmount": 48
	},
	{
		"id": "026055c",
		"email": "jacquelinetorgas@yahoo.com",
		"name": "Jacqueline ",
		"createdDate": "2022-10-10 19:10:34",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 103,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 399,
		"schedulePercent": 0.25639,
		"totalGuests": 131,
		"guestPercent": 0.499272,
		"mostGuestDate": "2023-10-29 00:00:00",
		"mostGuestName": "Mayo Performing Arts Center",
		"mostGuestCity": "Morristown",
		"mostGuestAmount": 24
	},
	{
		"id": "9043349",
		"email": "martin-gustavsson@hotmail.com",
		"name": "Martin",
		"createdDate": "2016-06-08 20:21:38",
		"totalOrganizations": 7,
		"totalTours": 4,
		"totalDays": 83,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 351,
		"schedulePercent": 0.233611,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8355aa5",
		"email": "sammyboller@gmail.com",
		"name": "Sammy",
		"createdDate": "2021-07-05 03:24:02",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 248,
		"totalCountries": 12,
		"totalStates": 46,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 814,
		"schedulePercent": 0.424447,
		"totalGuests": 64,
		"guestPercent": 0.439015,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "Carson Center Paducah Ky",
		"mostGuestCity": "Paducah",
		"mostGuestAmount": 10
	},
	{
		"id": "7d6caa6",
		"email": "tristan.griffin@gmail.com",
		"name": "Tristan",
		"createdDate": "2017-10-18 18:45:27",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 99,
		"totalCountries": 21,
		"totalStates": 44,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 627,
		"schedulePercent": 0.352669,
		"totalGuests": 213,
		"guestPercent": 0.558866,
		"mostGuestDate": "2023-04-06 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 45
	},
	{
		"id": "b3d23bf",
		"email": "johnnygameshow@gmail.com",
		"name": "Johnny",
		"createdDate": "2022-05-04 18:26:38",
		"totalOrganizations": 2,
		"totalTours": 10,
		"totalDays": 130,
		"totalCountries": 9,
		"totalStates": 29,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 497,
		"schedulePercent": 0.296914,
		"totalGuests": 42,
		"guestPercent": 0.40882,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Talking Stick Resort Amphitheater ",
		"mostGuestCity": "Phoenix",
		"mostGuestAmount": 23
	},
	{
		"id": "040d053",
		"email": "joeltpryor@gmail.com",
		"name": "Joel",
		"createdDate": "2018-06-19 03:41:40",
		"totalOrganizations": 13,
		"totalTours": 14,
		"totalDays": 1170,
		"totalCountries": 18,
		"totalStates": 75,
		"totalEvents": 368,
		"eventPercent": 0.948749,
		"totalScheduleItems": 5197,
		"schedulePercent": 0.967554,
		"totalGuests": 126,
		"guestPercent": 0.495564,
		"mostGuestDate": "2023-12-03 00:00:00",
		"mostGuestName": "ACL Live",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 31
	},
	{
		"id": "e9a70df",
		"email": "zachnugentmusic@gmail.com",
		"name": "Zach",
		"createdDate": "2016-12-15 05:40:38",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 87,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 286,
		"schedulePercent": 0.201033,
		"totalGuests": 11,
		"guestPercent": 0.345782,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Higher Ground",
		"mostGuestCity": "South Burlington",
		"mostGuestAmount": 11
	},
	{
		"id": "13ff7bf",
		"email": "Ba@concretecoordination.com",
		"name": "B",
		"createdDate": "2023-05-23 05:47:36",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 229,
		"totalCountries": 10,
		"totalStates": 36,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1495,
		"schedulePercent": 0.616872,
		"totalGuests": 112,
		"guestPercent": 0.484836,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 112
	},
	{
		"id": "3a32726",
		"email": "info@circa41.com",
		"name": "Circa",
		"createdDate": "2015-12-08 17:00:07",
		"totalOrganizations": 43,
		"totalTours": 29,
		"totalDays": 1550,
		"totalCountries": 14,
		"totalStates": 42,
		"totalEvents": 229,
		"eventPercent": 0.847305,
		"totalScheduleItems": 3459,
		"schedulePercent": 0.891008,
		"totalGuests": 696,
		"guestPercent": 0.752086,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Sydney Opera House",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 83
	},
	{
		"id": "7e8f606",
		"email": "vb@valtteribottas.com",
		"name": "Paul",
		"createdDate": "2023-01-25 07:23:27",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 340,
		"totalCountries": 9,
		"totalStates": 16,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 2844,
		"schedulePercent": 0.837637,
		"totalGuests": 47,
		"guestPercent": 0.417031,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "Bahrain GP",
		"mostGuestCity": "Sakhir",
		"mostGuestAmount": 6
	},
	{
		"id": "88edf54",
		"email": "tmgarris@me.com",
		"name": "Tommy",
		"createdDate": "2014-10-08 15:42:59",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 2264,
		"schedulePercent": 0.761224,
		"totalGuests": 1774,
		"guestPercent": 0.918554,
		"mostGuestDate": "2023-05-28 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 121
	},
	{
		"id": "6a8eac1",
		"email": "sweetpillband@gmail.com",
		"name": "Sweet",
		"createdDate": "2022-09-11 11:52:43",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 65,
		"totalCountries": 1,
		"totalStates": 25,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 324,
		"schedulePercent": 0.221428,
		"totalGuests": 77,
		"guestPercent": 0.452523,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Baltimore Soundstage",
		"mostGuestCity": "Baltimore",
		"mostGuestAmount": 11
	},
	{
		"id": "d8f28b8",
		"email": "lukegovermusic@gmail.com",
		"name": "Luke",
		"createdDate": "2019-02-08 14:19:41",
		"totalOrganizations": 12,
		"totalTours": 11,
		"totalDays": 195,
		"totalCountries": 3,
		"totalStates": 39,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 1541,
		"schedulePercent": 0.628129,
		"totalGuests": 225,
		"guestPercent": 0.566018,
		"mostGuestDate": "2023-07-26 00:00:00",
		"mostGuestName": "Mizner Park Amphitheatre",
		"mostGuestCity": "Boca Raton",
		"mostGuestAmount": 42
	},
	{
		"id": "8bcd87c",
		"email": "lukemilanese@gmail.com",
		"name": "Luke",
		"createdDate": "2013-03-04 22:07:26",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 298,
		"totalCountries": 5,
		"totalStates": 40,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 2298,
		"schedulePercent": 0.765594,
		"totalGuests": 1264,
		"guestPercent": 0.86717,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 59
	},
	{
		"id": "31ff326",
		"email": "nataliia@magiccirclemusic.com",
		"name": "Nataliia",
		"createdDate": "2021-10-06 12:49:32",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 217,
		"totalCountries": 12,
		"totalStates": 42,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 1791,
		"schedulePercent": 0.683088,
		"totalGuests": 852,
		"guestPercent": 0.787975,
		"mostGuestDate": "2023-10-31 00:00:00",
		"mostGuestName": "Mitsubishi Electric Halle - DÃ¼sseldorf Congress Sport & Event GmbH",
		"mostGuestCity": "DÃ¼sseldorf",
		"mostGuestAmount": 84
	},
	{
		"id": "81e3366",
		"email": "trina@qprime.com",
		"name": "Trina",
		"createdDate": "2011-03-22 17:41:05",
		"totalOrganizations": 15,
		"totalTours": 32,
		"totalDays": 714,
		"totalCountries": 14,
		"totalStates": 71,
		"totalEvents": 324,
		"eventPercent": 0.927692,
		"totalScheduleItems": 3707,
		"schedulePercent": 0.907562,
		"totalGuests": 3720,
		"guestPercent": 0.980268,
		"mostGuestDate": "2023-10-02 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 405
	},
	{
		"id": "bbe181a",
		"email": "curtistours@gmail.com",
		"name": "Curtis",
		"createdDate": "2013-07-15 20:55:34",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 363,
		"totalCountries": 1,
		"totalStates": 37,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 970,
		"schedulePercent": 0.472255,
		"totalGuests": 951,
		"guestPercent": 0.813005,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 89
	},
	{
		"id": "d2ecee5",
		"email": "bryan@hairstontouringproductions.com",
		"name": "Bryan",
		"createdDate": "2021-09-08 16:52:51",
		"totalOrganizations": 14,
		"totalTours": 17,
		"totalDays": 1630,
		"totalCountries": 18,
		"totalStates": 69,
		"totalEvents": 237,
		"eventPercent": 0.858429,
		"totalScheduleItems": 3809,
		"schedulePercent": 0.911932,
		"totalGuests": 613,
		"guestPercent": 0.729837,
		"mostGuestDate": "2023-09-05 00:00:00",
		"mostGuestName": "Singapore Indoor Stadium",
		"mostGuestCity": "Singapore",
		"mostGuestAmount": 78
	},
	{
		"id": "0cc4669",
		"email": "Scott@alphawolfcvlt.com",
		"name": "Scott",
		"createdDate": "2019-04-11 16:51:12",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 307,
		"totalCountries": 10,
		"totalStates": 49,
		"totalEvents": 168,
		"eventPercent": 0.747451,
		"totalScheduleItems": 2381,
		"schedulePercent": 0.777513,
		"totalGuests": 72,
		"guestPercent": 0.446828,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "San Jose Civic Auditorium",
		"mostGuestCity": "San Jose",
		"mostGuestAmount": 18
	},
	{
		"id": "a1caca7",
		"email": "peter.sehlin@luger.se",
		"name": "Peter",
		"createdDate": "2020-01-09 10:06:23",
		"totalOrganizations": 106,
		"totalTours": 91,
		"totalDays": 1355,
		"totalCountries": 22,
		"totalStates": 94,
		"totalEvents": 939,
		"eventPercent": 0.989405,
		"totalScheduleItems": 6939,
		"schedulePercent": 0.988743,
		"totalGuests": 1443,
		"guestPercent": 0.886108,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Annexet",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 155
	},
	{
		"id": "43b12ed",
		"email": "carey@railroadearth.com",
		"name": "Carey",
		"createdDate": "2015-12-03 19:55:49",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 382,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 1023,
		"schedulePercent": 0.491723,
		"totalGuests": 621,
		"guestPercent": 0.731426,
		"mostGuestDate": "2023-01-20 00:00:00",
		"mostGuestName": "The Fillmore",
		"mostGuestCity": "San Francisco",
		"mostGuestAmount": 33
	},
	{
		"id": "2be6248",
		"email": "si@wondercoreisland.com",
		"name": "Si",
		"createdDate": "2014-04-25 06:45:32",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 459,
		"totalCountries": 5,
		"totalStates": 24,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 693,
		"schedulePercent": 0.377831,
		"totalGuests": 421,
		"guestPercent": 0.662032,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 121
	},
	{
		"id": "50dcab8",
		"email": "nathanlee41@icloud.com",
		"name": "Nathan",
		"createdDate": "2021-07-29 15:01:52",
		"totalOrganizations": 2,
		"totalTours": 10,
		"totalDays": 349,
		"totalCountries": 5,
		"totalStates": 42,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 2078,
		"schedulePercent": 0.731426,
		"totalGuests": 945,
		"guestPercent": 0.810886,
		"mostGuestDate": "2023-04-13 00:00:00",
		"mostGuestName": "The Paramount",
		"mostGuestCity": "Huntington",
		"mostGuestAmount": 60
	},
	{
		"id": "3041e79",
		"email": "biggigantictm@gmail.com",
		"name": "Tour",
		"createdDate": "2016-03-01 18:04:07",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 104,
		"totalCountries": 3,
		"totalStates": 23,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 1050,
		"schedulePercent": 0.497153,
		"totalGuests": 356,
		"guestPercent": 0.633029,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "The Salt Shed",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 45
	},
	{
		"id": "33480cd",
		"email": "jerryhines11@gmail.com",
		"name": "jerry",
		"createdDate": "2023-06-27 19:49:08",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 154,
		"totalCountries": 1,
		"totalStates": 26,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 594,
		"schedulePercent": 0.337571,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7060e49",
		"email": "merkulesmgmt@gmail.com",
		"name": "Eli",
		"createdDate": "2019-10-07 18:06:33",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 53,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 210,
		"schedulePercent": 0.156006,
		"totalGuests": 223,
		"guestPercent": 0.564958,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "Capital Ballroom",
		"mostGuestCity": "Victoria",
		"mostGuestAmount": 54
	},
	{
		"id": "6ee83c1",
		"email": "sean_quish@me.com",
		"name": "Sean",
		"createdDate": "2011-11-16 19:05:09",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 125,
		"totalCountries": 11,
		"totalStates": 27,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 905,
		"schedulePercent": 0.453715,
		"totalGuests": 409,
		"guestPercent": 0.657264,
		"mostGuestDate": "2023-11-02 00:00:00",
		"mostGuestName": "O2 Arena",
		"mostGuestCity": "London",
		"mostGuestAmount": 99
	},
	{
		"id": "9d3f2bb",
		"email": "deathpacttourmanager@gmail.com",
		"name": "Davon",
		"createdDate": "2023-03-20 21:44:52",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 377,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 891,
		"schedulePercent": 0.450007,
		"totalGuests": 136,
		"guestPercent": 0.503377,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "Axis Club",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 26
	},
	{
		"id": "27673e4",
		"email": "bellaunionmgmt@gmail.com",
		"name": "Abbey",
		"createdDate": "2016-02-02 14:27:34",
		"totalOrganizations": 10,
		"totalTours": 22,
		"totalDays": 245,
		"totalCountries": 11,
		"totalStates": 37,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 929,
		"schedulePercent": 0.459409,
		"totalGuests": 150,
		"guestPercent": 0.514766,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "The Echo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 30
	},
	{
		"id": "46cedf4",
		"email": "dimitri.hondroudakis@gmail.com",
		"name": "Dimitri",
		"createdDate": "2015-06-10 12:47:49",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 55,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 269,
		"schedulePercent": 0.191498,
		"totalGuests": 13,
		"guestPercent": 0.352536,
		"mostGuestDate": "2023-04-15 00:00:00",
		"mostGuestName": "MAC 02",
		"mostGuestCity": "Hobart",
		"mostGuestAmount": 11
	},
	{
		"id": "70afc05",
		"email": "Jmoffat@truegritmanagement.com",
		"name": "John",
		"createdDate": "2021-06-08 01:20:33",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 210,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 513,
		"schedulePercent": 0.30539,
		"totalGuests": 100,
		"guestPercent": 0.473977,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Deadwood Mountain Grand Hotel and Casino",
		"mostGuestCity": "Deadwood",
		"mostGuestAmount": 50
	},
	{
		"id": "f555b1b",
		"email": "Darien@rhymesayers.com",
		"name": "Darien",
		"createdDate": "2015-09-16 14:45:43",
		"totalOrganizations": 7,
		"totalTours": 13,
		"totalDays": 225,
		"totalCountries": 6,
		"totalStates": 45,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 1135,
		"schedulePercent": 0.52205,
		"totalGuests": 998,
		"guestPercent": 0.823202,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 133
	},
	{
		"id": "e20e343",
		"email": "erfodge@gmail.com",
		"name": "Eric",
		"createdDate": "2022-10-02 22:19:37",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 47,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 274,
		"schedulePercent": 0.195074,
		"totalGuests": 41,
		"guestPercent": 0.407231,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 34
	},
	{
		"id": "c64144d",
		"email": "laur.troutman@gmail.com",
		"name": "Lauren",
		"createdDate": "2017-05-07 08:34:43",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 352,
		"totalCountries": 8,
		"totalStates": 41,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 1865,
		"schedulePercent": 0.698186,
		"totalGuests": 720,
		"guestPercent": 0.75778,
		"mostGuestDate": "2023-06-27 00:00:00",
		"mostGuestName": "Trinity College Dublin",
		"mostGuestCity": "Dublin",
		"mostGuestAmount": 52
	},
	{
		"id": "13e84b5",
		"email": "twiggysize@gmail.com",
		"name": "Twiggy",
		"createdDate": "2022-07-09 20:01:03",
		"totalOrganizations": 7,
		"totalTours": 29,
		"totalDays": 774,
		"totalCountries": 22,
		"totalStates": 68,
		"totalEvents": 245,
		"eventPercent": 0.867435,
		"totalScheduleItems": 3302,
		"schedulePercent": 0.877765,
		"totalGuests": 1721,
		"guestPercent": 0.914581,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "ea86dda",
		"email": "gc@musictourservices.co.uk",
		"name": "Graham",
		"createdDate": "2020-02-10 23:49:27",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 47,
		"totalCountries": 6,
		"totalStates": 9,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 341,
		"schedulePercent": 0.228314,
		"totalGuests": 260,
		"guestPercent": 0.584293,
		"mostGuestDate": "2023-09-13 00:00:00",
		"mostGuestName": "Cadogan Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 48
	},
	{
		"id": "743f655",
		"email": "logistics@hitproductions.com.au",
		"name": "HIT",
		"createdDate": "2023-04-04 22:27:02",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 195,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 875,
		"schedulePercent": 0.445504,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "48e7a30",
		"email": "adamhansonmusic@gmail.com",
		"name": "Adam",
		"createdDate": "2019-09-25 16:08:03",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 176,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 645,
		"schedulePercent": 0.360482,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "Prudential Center",
		"mostGuestCity": "Newark",
		"mostGuestAmount": 1
	},
	{
		"id": "5199bca",
		"email": "sam@kowboy.co",
		"name": "Samuel",
		"createdDate": "2023-03-23 14:13:43",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 101,
		"totalCountries": 3,
		"totalStates": 22,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 1038,
		"schedulePercent": 0.494372,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-09-29 00:00:00",
		"mostGuestName": "Toyota Center",
		"mostGuestCity": "Houston",
		"mostGuestAmount": 2
	},
	{
		"id": "7654446",
		"email": "hayo@denboeft.nl",
		"name": "Hayo",
		"createdDate": "2020-01-27 21:01:52",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 73,
		"totalCountries": 13,
		"totalStates": 21,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 708,
		"schedulePercent": 0.383923,
		"totalGuests": 739,
		"guestPercent": 0.761886,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "AFAS Live",
		"mostGuestCity": "Amsterdam",
		"mostGuestAmount": 74
	},
	{
		"id": "302adda",
		"email": "brade@me.com",
		"name": "Brad",
		"createdDate": "2014-12-29 20:07:45",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 142,
		"totalCountries": 3,
		"totalStates": 16,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 812,
		"schedulePercent": 0.423917,
		"totalGuests": 592,
		"guestPercent": 0.723613,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Billy Bob's",
		"mostGuestCity": "Ft. Worth",
		"mostGuestAmount": 65
	},
	{
		"id": "c39ac3a",
		"email": "Marybel@fluffyshop.com",
		"name": "Steve",
		"createdDate": "2018-05-04 01:03:38",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 290,
		"totalCountries": 5,
		"totalStates": 32,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 1265,
		"schedulePercent": 0.555953,
		"totalGuests": 268,
		"guestPercent": 0.588664,
		"mostGuestDate": "2023-08-27 00:00:00",
		"mostGuestName": "The Pacific Amphitheatre",
		"mostGuestCity": "Costa Mesa",
		"mostGuestAmount": 40
	},
	{
		"id": "3a7d038",
		"email": "dvillegas.audio@gmail.com",
		"name": "Daniel ",
		"createdDate": "2021-10-08 18:05:36",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 272,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 268,
		"schedulePercent": 0.190836,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "753554d",
		"email": "evanpierri@gmail.com",
		"name": "Evan",
		"createdDate": "2013-11-11 17:27:27",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 690,
		"totalCountries": 12,
		"totalStates": 62,
		"totalEvents": 249,
		"eventPercent": 0.870746,
		"totalScheduleItems": 4660,
		"schedulePercent": 0.950338,
		"totalGuests": 1487,
		"guestPercent": 0.892862,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "Vina Robles Amphitheatre",
		"mostGuestCity": "Paso Robles",
		"mostGuestAmount": 52
	},
	{
		"id": "eff6438",
		"email": "iwanttoemailmike@gmail.com",
		"name": "Michael",
		"createdDate": "2014-04-02 16:29:12",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 385,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1628,
		"schedulePercent": 0.649053,
		"totalGuests": 134,
		"guestPercent": 0.501258,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "WinStar World Casino- Paladium Sports Lounge",
		"mostGuestCity": "Thackerville",
		"mostGuestAmount": 58
	},
	{
		"id": "668b014",
		"email": "info@chmusic.dk",
		"name": "Claus",
		"createdDate": "2019-02-27 08:46:44",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 46,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 222,
		"schedulePercent": 0.164879,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "913cb85",
		"email": "mark@properproductions.org",
		"name": "Mark",
		"createdDate": "2012-06-24 16:38:00",
		"totalOrganizations": 7,
		"totalTours": 14,
		"totalDays": 89,
		"totalCountries": 7,
		"totalStates": 10,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 571,
		"schedulePercent": 0.328036,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d6eaef8",
		"email": "nemomusic74@gmail.com",
		"name": "Nemo",
		"createdDate": "2023-09-13 20:08:09",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 25,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 13,
		"eventPercent": 0.0892597,
		"totalScheduleItems": 172,
		"schedulePercent": 0.133757,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "02002be",
		"email": "clydestrain@hotmail.com",
		"name": "Clyde",
		"createdDate": "2018-05-22 05:48:54",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 438,
		"totalCountries": 14,
		"totalStates": 37,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 998,
		"schedulePercent": 0.484439,
		"totalGuests": 698,
		"guestPercent": 0.752218,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 103
	},
	{
		"id": "d3b14e6",
		"email": "mahssa@climbmountanalog.com",
		"name": "Mahssa",
		"createdDate": "2018-09-19 02:23:45",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 168,
		"totalCountries": 13,
		"totalStates": 38,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 1217,
		"schedulePercent": 0.544696,
		"totalGuests": 630,
		"guestPercent": 0.733943,
		"mostGuestDate": "2023-05-23 00:00:00",
		"mostGuestName": "The Warfield",
		"mostGuestCity": "San Francisco",
		"mostGuestAmount": 63
	},
	{
		"id": "bfa0d57",
		"email": "m.perrin@rockoon.live",
		"name": "MAX",
		"createdDate": "2021-09-06 13:56:42",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 51,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 643,
		"schedulePercent": 0.360085,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a0faf7b",
		"email": "miles.rivera@redlightmanagement.com",
		"name": "Miles",
		"createdDate": "2020-02-05 22:09:20",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 903,
		"totalCountries": 12,
		"totalStates": 63,
		"totalEvents": 186,
		"eventPercent": 0.782016,
		"totalScheduleItems": 3056,
		"schedulePercent": 0.857105,
		"totalGuests": 816,
		"guestPercent": 0.779632,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Royal Albert Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 42
	},
	{
		"id": "07c0ce5",
		"email": "jcolduv@g.clemson.edu",
		"name": "Joseph",
		"createdDate": "2023-06-13 19:19:03",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 223,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 893,
		"schedulePercent": 0.450934,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ec7bfea",
		"email": "lauren@hanstolo.com",
		"name": "Lauren",
		"createdDate": "2023-02-24 15:09:45",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 48,
		"totalCountries": 5,
		"totalStates": 6,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 212,
		"schedulePercent": 0.157595,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "461da62",
		"email": "mitchjamestours1@icloud.com",
		"name": "Mitch",
		"createdDate": "2019-09-04 19:13:08",
		"totalOrganizations": 10,
		"totalTours": 13,
		"totalDays": 1055,
		"totalCountries": 17,
		"totalStates": 64,
		"totalEvents": 260,
		"eventPercent": 0.885975,
		"totalScheduleItems": 4090,
		"schedulePercent": 0.92703,
		"totalGuests": 791,
		"guestPercent": 0.774599,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 65
	},
	{
		"id": "965ddfb",
		"email": "mattcroteau518@gmail.com",
		"name": "MATT",
		"createdDate": "2016-03-26 21:03:58",
		"totalOrganizations": 11,
		"totalTours": 9,
		"totalDays": 297,
		"totalCountries": 15,
		"totalStates": 57,
		"totalEvents": 148,
		"eventPercent": 0.696332,
		"totalScheduleItems": 3124,
		"schedulePercent": 0.863594,
		"totalGuests": 3875,
		"guestPercent": 0.982519,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Petco Park",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 186
	},
	{
		"id": "e961fb2",
		"email": "contact@harryduffin.uk",
		"name": "Harry",
		"createdDate": "2019-04-25 18:25:16",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 356,
		"totalCountries": 17,
		"totalStates": 32,
		"totalEvents": 162,
		"eventPercent": 0.732883,
		"totalScheduleItems": 1205,
		"schedulePercent": 0.540988,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c4057cc",
		"email": "alana@badomensofficial.com",
		"name": "Alana",
		"createdDate": "2023-06-08 17:11:47",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 68,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 497,
		"schedulePercent": 0.296914,
		"totalGuests": 926,
		"guestPercent": 0.805589,
		"mostGuestDate": "2023-10-08 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 116
	},
	{
		"id": "764b360",
		"email": "productiontm@gmail.com",
		"name": "Marc",
		"createdDate": "2012-12-09 15:10:47",
		"totalOrganizations": 8,
		"totalTours": 7,
		"totalDays": 1295,
		"totalCountries": 7,
		"totalStates": 34,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 259,
		"schedulePercent": 0.185273,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6c51ecd",
		"email": "ajsolomon126@gmail.com",
		"name": "Alyssa",
		"createdDate": "2018-05-14 17:27:20",
		"totalOrganizations": 12,
		"totalTours": 7,
		"totalDays": 388,
		"totalCountries": 14,
		"totalStates": 52,
		"totalEvents": 162,
		"eventPercent": 0.732883,
		"totalScheduleItems": 2270,
		"schedulePercent": 0.762018,
		"totalGuests": 1531,
		"guestPercent": 0.897894,
		"mostGuestDate": "2023-08-09 00:00:00",
		"mostGuestName": "Hollywood Forever",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 88
	},
	{
		"id": "951b69d",
		"email": "newsongrm@gmail.com",
		"name": "Mark",
		"createdDate": "2014-12-12 18:23:07",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 254,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 166,
		"eventPercent": 0.74308,
		"totalScheduleItems": 1583,
		"schedulePercent": 0.637664,
		"totalGuests": 2824,
		"guestPercent": 0.964905,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 298
	},
	{
		"id": "1892490",
		"email": "deanna@jamtour.com",
		"name": "Deanna",
		"createdDate": "2014-12-29 18:24:23",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 83,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 819,
		"schedulePercent": 0.426169,
		"totalGuests": 2824,
		"guestPercent": 0.964905,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 298
	},
	{
		"id": "502b65f",
		"email": "scott@sclarkeaudio.co.uk",
		"name": "Scott",
		"createdDate": "2017-07-03 23:03:54",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 164,
		"totalCountries": 10,
		"totalStates": 27,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 730,
		"schedulePercent": 0.392266,
		"totalGuests": 103,
		"guestPercent": 0.478082,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "Electric Brixton",
		"mostGuestCity": "London",
		"mostGuestAmount": 28
	},
	{
		"id": "c92043e",
		"email": "andy@jtwtouring.com",
		"name": "Andy",
		"createdDate": "2019-06-20 11:48:58",
		"totalOrganizations": 18,
		"totalTours": 18,
		"totalDays": 756,
		"totalCountries": 23,
		"totalStates": 77,
		"totalEvents": 239,
		"eventPercent": 0.860681,
		"totalScheduleItems": 2398,
		"schedulePercent": 0.780426,
		"totalGuests": 66,
		"guestPercent": 0.441001,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Hatfield Park",
		"mostGuestCity": "",
		"mostGuestAmount": 13
	},
	{
		"id": "dc617e0",
		"email": "ksweatlive@gmail.com",
		"name": "Michael",
		"createdDate": "2021-05-27 04:07:58",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 128,
		"totalCountries": 3,
		"totalStates": 33,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 185,
		"schedulePercent": 0.142233,
		"totalGuests": 140,
		"guestPercent": 0.505628,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 39
	},
	{
		"id": "c00f5cc",
		"email": "michaelairey@hotmail.com",
		"name": "Michael",
		"createdDate": "2017-02-06 18:25:39",
		"totalOrganizations": 2,
		"totalTours": 15,
		"totalDays": 616,
		"totalCountries": 20,
		"totalStates": 66,
		"totalEvents": 198,
		"eventPercent": 0.802543,
		"totalScheduleItems": 2505,
		"schedulePercent": 0.794067,
		"totalGuests": 1401,
		"guestPercent": 0.882929,
		"mostGuestDate": "2023-07-19 00:00:00",
		"mostGuestName": "Stadtpark",
		"mostGuestCity": "Hamburg",
		"mostGuestAmount": 46
	},
	{
		"id": "6ba8697",
		"email": "chris.fox@baysideonline.com",
		"name": "Chris",
		"createdDate": "2018-02-06 22:41:17",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 85,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 821,
		"schedulePercent": 0.426831,
		"totalGuests": 2824,
		"guestPercent": 0.964905,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 298
	},
	{
		"id": "e1b905a",
		"email": "jessamine.inspired@gmail.com",
		"name": "Jessamine",
		"createdDate": "2023-02-18 19:23:38",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 52,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 245,
		"schedulePercent": 0.179049,
		"totalGuests": 156,
		"guestPercent": 0.518607,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 28
	},
	{
		"id": "a35a858",
		"email": "emmapenymay@gmail.com",
		"name": "Emma",
		"createdDate": "2023-05-30 21:47:08",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 319,
		"totalCountries": 7,
		"totalStates": 26,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 656,
		"schedulePercent": 0.363925,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "aae6bfd",
		"email": "kelseyrhenry@gmail.com",
		"name": "Kelsey",
		"createdDate": "2015-03-23 19:49:22",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 370,
		"totalCountries": 3,
		"totalStates": 37,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 2130,
		"schedulePercent": 0.738843,
		"totalGuests": 2136,
		"guestPercent": 0.940935,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Mattress Firm Amphitheatre",
		"mostGuestCity": "Chula Vista",
		"mostGuestAmount": 136
	},
	{
		"id": "7b16f9d",
		"email": "neilmorristours@gmail.com",
		"name": "Neil ",
		"createdDate": "2017-07-19 20:04:46",
		"totalOrganizations": 28,
		"totalTours": 22,
		"totalDays": 437,
		"totalCountries": 31,
		"totalStates": 79,
		"totalEvents": 222,
		"eventPercent": 0.839227,
		"totalScheduleItems": 2810,
		"schedulePercent": 0.8334,
		"totalGuests": 394,
		"guestPercent": 0.651437,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 67
	},
	{
		"id": "8bc8afc",
		"email": "sheri@jonesandcompany.ca",
		"name": "Sheri",
		"createdDate": "2017-11-13 13:35:48",
		"totalOrganizations": 34,
		"totalTours": 20,
		"totalDays": 1991,
		"totalCountries": 10,
		"totalStates": 68,
		"totalEvents": 1229,
		"eventPercent": 0.992716,
		"totalScheduleItems": 2949,
		"schedulePercent": 0.847173,
		"totalGuests": 752,
		"guestPercent": 0.765064,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 54
	},
	{
		"id": "7470022",
		"email": "arman@slanderofficial.com",
		"name": "Arman",
		"createdDate": "2020-01-08 01:34:55",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 470,
		"totalCountries": 7,
		"totalStates": 31,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 977,
		"schedulePercent": 0.474507,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e78f39f",
		"email": "wunderhorsemgmt@gmail.com",
		"name": "John",
		"createdDate": "2023-03-20 18:55:23",
		"totalOrganizations": 2,
		"totalTours": 20,
		"totalDays": 157,
		"totalCountries": 9,
		"totalStates": 25,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 767,
		"schedulePercent": 0.408025,
		"totalGuests": 261,
		"guestPercent": 0.584823,
		"mostGuestDate": "2023-04-06 00:00:00",
		"mostGuestName": "Electric Ballroom",
		"mostGuestCity": "London",
		"mostGuestAmount": 71
	},
	{
		"id": "4980c53",
		"email": "Jltouring@earthlink.net",
		"name": "Charles",
		"createdDate": "2016-06-23 01:53:39",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 55,
		"totalCountries": 3,
		"totalStates": 25,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 244,
		"schedulePercent": 0.178784,
		"totalGuests": 457,
		"guestPercent": 0.676732,
		"mostGuestDate": "2023-08-05 00:00:00",
		"mostGuestName": "Roadrunner",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 71
	},
	{
		"id": "8aa428a",
		"email": "styxbackstage@gmail.com",
		"name": "Jeff",
		"createdDate": "2018-01-11 23:10:39",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 1333,
		"schedulePercent": 0.57542,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "99cbad4",
		"email": "kim@grandviewmusic.com",
		"name": "Kimberly",
		"createdDate": "2021-09-01 21:00:43",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 318,
		"totalCountries": 10,
		"totalStates": 59,
		"totalEvents": 160,
		"eventPercent": 0.72838,
		"totalScheduleItems": 2323,
		"schedulePercent": 0.769435,
		"totalGuests": 580,
		"guestPercent": 0.718845,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 79
	},
	{
		"id": "06d7a4d",
		"email": "smayfield@gmail.com",
		"name": "Sarah",
		"createdDate": "2012-09-09 09:24:41",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 311,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 583,
		"schedulePercent": 0.333068,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d74b245",
		"email": "info@yaeji.nyc",
		"name": "Jordan",
		"createdDate": "2018-06-20 20:17:35",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 4,
		"totalStates": 17,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 787,
		"schedulePercent": 0.415442,
		"totalGuests": 10,
		"guestPercent": 0.343531,
		"mostGuestDate": "2023-04-06 00:00:00",
		"mostGuestName": "Commodore Ballroom",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 6
	},
	{
		"id": "7959721",
		"email": "rshelton@outletgroupllc.com",
		"name": "Ravi",
		"createdDate": "2016-07-05 10:19:01",
		"totalOrganizations": 10,
		"totalTours": 6,
		"totalDays": 196,
		"totalCountries": 3,
		"totalStates": 22,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 209,
		"schedulePercent": 0.155609,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5999ae5",
		"email": "jackie@hausofgaga.com",
		"name": "Jackie",
		"createdDate": "2020-11-13 01:57:23",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 5,
		"eventPercent": 0.0348298,
		"totalScheduleItems": 1975,
		"schedulePercent": 0.715402,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a6277f7",
		"email": "benratliffmusic@gmail.com",
		"name": "Ben",
		"createdDate": "2017-05-02 20:50:51",
		"totalOrganizations": 11,
		"totalTours": 6,
		"totalDays": 969,
		"totalCountries": 3,
		"totalStates": 50,
		"totalEvents": 250,
		"eventPercent": 0.872335,
		"totalScheduleItems": 5437,
		"schedulePercent": 0.972322,
		"totalGuests": 2657,
		"guestPercent": 0.958151,
		"mostGuestDate": "2023-06-28 00:00:00",
		"mostGuestName": "Rogers Arena",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 178
	},
	{
		"id": "f551a56",
		"email": "steve@steveswift.com",
		"name": "Steve",
		"createdDate": "2015-09-09 04:29:21",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 288,
		"totalCountries": 5,
		"totalStates": 9,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 274,
		"schedulePercent": 0.195074,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f192ce2",
		"email": "plyler@zacbrownband.com",
		"name": "David",
		"createdDate": "2017-11-07 22:56:51",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 135,
		"totalCountries": 6,
		"totalStates": 30,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 1780,
		"schedulePercent": 0.680969,
		"totalGuests": 4325,
		"guestPercent": 0.985962,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Fenway Park",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 322
	},
	{
		"id": "3b97dba",
		"email": "nikkikilroy@icloud.com",
		"name": "Nicola",
		"createdDate": "2020-09-14 11:15:04",
		"totalOrganizations": 30,
		"totalTours": 71,
		"totalDays": 1553,
		"totalCountries": 22,
		"totalStates": 107,
		"totalEvents": 707,
		"eventPercent": 0.986227,
		"totalScheduleItems": 8929,
		"schedulePercent": 0.995895,
		"totalGuests": 3492,
		"guestPercent": 0.977222,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 524
	},
	{
		"id": "35ef986",
		"email": "pkd@colourblind.com.au",
		"name": "Claire",
		"createdDate": "2021-11-17 00:40:44",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 653,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 517,
		"schedulePercent": 0.306847,
		"totalGuests": 450,
		"guestPercent": 0.67395,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "The Enmore Theatre",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 111
	},
	{
		"id": "ee85d14",
		"email": "dougjacksonproduction@gmail.com",
		"name": "Doug",
		"createdDate": "2019-10-02 15:27:02",
		"totalOrganizations": 10,
		"totalTours": 22,
		"totalDays": 1010,
		"totalCountries": 8,
		"totalStates": 59,
		"totalEvents": 433,
		"eventPercent": 0.965038,
		"totalScheduleItems": 4620,
		"schedulePercent": 0.948881,
		"totalGuests": 1878,
		"guestPercent": 0.926765,
		"mostGuestDate": "2023-05-14 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 77
	},
	{
		"id": "65de21b",
		"email": "kriss.jakob@bottomrow.com",
		"name": "Kriss",
		"createdDate": "2023-05-25 21:25:25",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 107,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 425,
		"schedulePercent": 0.265793,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1501c92",
		"email": "monicalfranco47@gmail.com",
		"name": "Monica",
		"createdDate": "2022-02-11 17:43:51",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 477,
		"totalCountries": 9,
		"totalStates": 46,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 3278,
		"schedulePercent": 0.876043,
		"totalGuests": 990,
		"guestPercent": 0.821481,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 187
	},
	{
		"id": "0225c7c",
		"email": "chrisrobertsprod@me.com",
		"name": "Christopher",
		"createdDate": "2021-09-10 06:56:09",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 158,
		"totalCountries": 3,
		"totalStates": 4,
		"totalEvents": 5,
		"eventPercent": 0.0348298,
		"totalScheduleItems": 122,
		"schedulePercent": 0.104092,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "066ef65",
		"email": "robingenetier@gmail.com",
		"name": "Robin",
		"createdDate": "2016-09-14 21:31:22",
		"totalOrganizations": 25,
		"totalTours": 42,
		"totalDays": 1084,
		"totalCountries": 24,
		"totalStates": 99,
		"totalEvents": 390,
		"eventPercent": 0.954576,
		"totalScheduleItems": 4926,
		"schedulePercent": 0.95974,
		"totalGuests": 1186,
		"guestPercent": 0.854721,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Finsbury Park",
		"mostGuestCity": "London",
		"mostGuestAmount": 60
	},
	{
		"id": "c950743",
		"email": "milner92@mac.com",
		"name": "Jason",
		"createdDate": "2009-12-23 16:55:55",
		"totalOrganizations": 18,
		"totalTours": 17,
		"totalDays": 993,
		"totalCountries": 17,
		"totalStates": 65,
		"totalEvents": 376,
		"eventPercent": 0.949676,
		"totalScheduleItems": 3854,
		"schedulePercent": 0.91564,
		"totalGuests": 86,
		"guestPercent": 0.460204,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "CenturyLink Arena Boise",
		"mostGuestCity": "Boise",
		"mostGuestAmount": 20
	},
	{
		"id": "382b1bd",
		"email": "mjpengelly@gmail.com",
		"name": "Matt",
		"createdDate": "2011-06-15 10:24:36",
		"totalOrganizations": 3,
		"totalTours": 12,
		"totalDays": 328,
		"totalCountries": 21,
		"totalStates": 75,
		"totalEvents": 155,
		"eventPercent": 0.716197,
		"totalScheduleItems": 2180,
		"schedulePercent": 0.747583,
		"totalGuests": 218,
		"guestPercent": 0.561515,
		"mostGuestDate": "2023-03-28 00:00:00",
		"mostGuestName": "Music Hall of Williamsburg",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 43
	},
	{
		"id": "6e1e1d1",
		"email": "amalie@standingovation.no",
		"name": "Amalie",
		"createdDate": "2023-01-26 10:35:37",
		"totalOrganizations": 29,
		"totalTours": 23,
		"totalDays": 900,
		"totalCountries": 16,
		"totalStates": 45,
		"totalEvents": 369,
		"eventPercent": 0.949013,
		"totalScheduleItems": 3409,
		"schedulePercent": 0.888094,
		"totalGuests": 275,
		"guestPercent": 0.59171,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "Pepsi center",
		"mostGuestCity": "Ciudad de MÃ©xico",
		"mostGuestAmount": 57
	},
	{
		"id": "4bd07c6",
		"email": "pkimmel31@gmail.com",
		"name": "Patrick",
		"createdDate": "2022-06-21 16:07:13",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 57,
		"totalCountries": 1,
		"totalStates": 15,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 2,
		"schedulePercent": 0.00966759,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a0530e8",
		"email": "21Savage.TM@gmail.com",
		"name": "Chelsea",
		"createdDate": "2022-12-27 19:55:09",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 504,
		"totalCountries": 5,
		"totalStates": 26,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 731,
		"schedulePercent": 0.392928,
		"totalGuests": 2445,
		"guestPercent": 0.952192,
		"mostGuestDate": "2023-09-25 00:00:00",
		"mostGuestName": "State Farm Arena",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 130
	},
	{
		"id": "7a994d2",
		"email": "pettus13@mac.com",
		"name": "Gary",
		"createdDate": "2022-01-04 15:09:31",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 691,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1666,
		"schedulePercent": 0.656867,
		"totalGuests": 1209,
		"guestPercent": 0.858429,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Giant Center",
		"mostGuestCity": "Hershey",
		"mostGuestAmount": 72
	},
	{
		"id": "c72d8cd",
		"email": "tomfromstray@gmail.com",
		"name": "Thomas",
		"createdDate": "2023-10-20 16:06:23",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 65,
		"totalCountries": 10,
		"totalStates": 32,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 661,
		"schedulePercent": 0.365779,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1bdb2b3",
		"email": "bridgetcraigphoto@gmail.com",
		"name": "Bridget",
		"createdDate": "2019-07-17 00:58:15",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 851,
		"totalCountries": 17,
		"totalStates": 68,
		"totalEvents": 210,
		"eventPercent": 0.823732,
		"totalScheduleItems": 2152,
		"schedulePercent": 0.742815,
		"totalGuests": 547,
		"guestPercent": 0.708648,
		"mostGuestDate": "2023-07-23 00:00:00",
		"mostGuestName": "Yuengling Center",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 24
	},
	{
		"id": "fef107a",
		"email": "tourmanager.shanesmith@gmail.com",
		"name": "Shane ",
		"createdDate": "2018-04-06 18:34:17",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 3,
		"totalStates": 32,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 1108,
		"schedulePercent": 0.513707,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "Manchester Music Hall",
		"mostGuestCity": "Lexington",
		"mostGuestAmount": 2
	},
	{
		"id": "850b89e",
		"email": "brianpreese@gmail.com",
		"name": "Brian",
		"createdDate": "2012-09-20 17:57:17",
		"totalOrganizations": 17,
		"totalTours": 2,
		"totalDays": 132,
		"totalCountries": 3,
		"totalStates": 31,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 1438,
		"schedulePercent": 0.601377,
		"totalGuests": 4444,
		"guestPercent": 0.987684,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Dickies Arena",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 433
	},
	{
		"id": "a04fccf",
		"email": "pensacobrallc@gmail.com",
		"name": "Bill",
		"createdDate": "2018-03-31 18:23:26",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 208,
		"totalCountries": 4,
		"totalStates": 34,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 693,
		"schedulePercent": 0.377831,
		"totalGuests": 16,
		"guestPercent": 0.361012,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "Santa Barbara Bowl",
		"mostGuestCity": "Santa Barbara",
		"mostGuestAmount": 16
	},
	{
		"id": "ef835dc",
		"email": "sage@ragecrew.org",
		"name": "Sage Anthony",
		"createdDate": "2016-12-15 04:22:45",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 381,
		"totalCountries": 9,
		"totalStates": 52,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 1306,
		"schedulePercent": 0.567872,
		"totalGuests": 1198,
		"guestPercent": 0.857105,
		"mostGuestDate": "2023-08-23 00:00:00",
		"mostGuestName": "Ravinia",
		"mostGuestCity": "Highland Park",
		"mostGuestAmount": 50
	},
	{
		"id": "5191418",
		"email": "jay@mcintyre-ents.com",
		"name": "Jay",
		"createdDate": "2022-02-14 11:23:21",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 295,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 203,
		"eventPercent": 0.811681,
		"totalScheduleItems": 1115,
		"schedulePercent": 0.515031,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "42935d4",
		"email": "jackson.erich@gmail.com",
		"name": "Erich",
		"createdDate": "2013-01-17 14:03:06",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 176,
		"totalCountries": 9,
		"totalStates": 43,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 1526,
		"schedulePercent": 0.623891,
		"totalGuests": 607,
		"guestPercent": 0.728248,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "The Smith Center- Reynolds Hall",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 49
	},
	{
		"id": "a9f418e",
		"email": "Bernard@harlemglobetrotters.com",
		"name": "Arnold",
		"createdDate": "2016-01-28 22:06:30",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 341,
		"totalCountries": 8,
		"totalStates": 68,
		"totalEvents": 251,
		"eventPercent": 0.873792,
		"totalScheduleItems": 2606,
		"schedulePercent": 0.808502,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f62171f",
		"email": "rebellioustribes@gmail.com",
		"name": "Ronald",
		"createdDate": "2023-08-08 11:08:58",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 91,
		"totalCountries": 11,
		"totalStates": 35,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 327,
		"schedulePercent": 0.222752,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c35589c",
		"email": "Treytours@mac.com",
		"name": "Trey",
		"createdDate": "2009-08-06 18:06:47",
		"totalOrganizations": 23,
		"totalTours": 19,
		"totalDays": 557,
		"totalCountries": 6,
		"totalStates": 37,
		"totalEvents": 252,
		"eventPercent": 0.876308,
		"totalScheduleItems": 1924,
		"schedulePercent": 0.706794,
		"totalGuests": 1203,
		"guestPercent": 0.857767,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Kings Theatre",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 88
	},
	{
		"id": "e6d824e",
		"email": "brctouring@gmail.com",
		"name": "Bryan",
		"createdDate": "2014-02-09 21:29:00",
		"totalOrganizations": 11,
		"totalTours": 11,
		"totalDays": 532,
		"totalCountries": 14,
		"totalStates": 60,
		"totalEvents": 168,
		"eventPercent": 0.747451,
		"totalScheduleItems": 3457,
		"schedulePercent": 0.890346,
		"totalGuests": 1691,
		"guestPercent": 0.912065,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Petco Park",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 186
	},
	{
		"id": "68273b5",
		"email": "annalorrolin@gmail.com",
		"name": "Anna",
		"createdDate": "2021-10-15 12:10:26",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 260,
		"totalCountries": 4,
		"totalStates": 38,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 1534,
		"schedulePercent": 0.626407,
		"totalGuests": 760,
		"guestPercent": 0.767051,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "Vina Robles Amphitheatre",
		"mostGuestCity": "Paso Robles",
		"mostGuestAmount": 52
	},
	{
		"id": "6881937",
		"email": "Gnteknik@gmail.com",
		"name": "Gabriel",
		"createdDate": "2014-12-04 10:45:46",
		"totalOrganizations": 14,
		"totalTours": 5,
		"totalDays": 127,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 957,
		"schedulePercent": 0.468017,
		"totalGuests": 221,
		"guestPercent": 0.563634,
		"mostGuestDate": "2023-05-12 00:00:00",
		"mostGuestName": "Tivoli",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 36
	},
	{
		"id": "7ac942d",
		"email": "melissa@3phaseproduction.com",
		"name": "Melissa",
		"createdDate": "2011-04-29 16:51:01",
		"totalOrganizations": 16,
		"totalTours": 9,
		"totalDays": 683,
		"totalCountries": 11,
		"totalStates": 54,
		"totalEvents": 168,
		"eventPercent": 0.747451,
		"totalScheduleItems": 3652,
		"schedulePercent": 0.903986,
		"totalGuests": 2871,
		"guestPercent": 0.967024,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "Northwell Health at Jones Beach Theater",
		"mostGuestCity": "Wantagh",
		"mostGuestAmount": 109
	},
	{
		"id": "7b8e30b",
		"email": "connor.brock@wmg.com",
		"name": "Connor",
		"createdDate": "2022-06-21 18:28:52",
		"totalOrganizations": 45,
		"totalTours": 43,
		"totalDays": 14651,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 1796,
		"eventPercent": 0.993643,
		"totalScheduleItems": 741,
		"schedulePercent": 0.396636,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "acc3b4f",
		"email": "ollylong@me.com",
		"name": "OLIVER",
		"createdDate": "2012-02-26 22:38:06",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 495,
		"totalCountries": 5,
		"totalStates": 11,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 741,
		"schedulePercent": 0.396636,
		"totalGuests": 361,
		"guestPercent": 0.635413,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Royal Festival Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 59
	},
	{
		"id": "2b7c81c",
		"email": "kayleebell@hotmail.com",
		"name": "Kaylee",
		"createdDate": "2017-09-20 09:46:46",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 339,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 346,
		"schedulePercent": 0.23136,
		"totalGuests": 30,
		"guestPercent": 0.389485,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "Eden Park",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 10
	},
	{
		"id": "e5a8095",
		"email": "kadeemlemuel@gmail.com",
		"name": "Kadeem",
		"createdDate": "2019-11-06 12:02:51",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 24,
		"totalCountries": 5,
		"totalStates": 6,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 205,
		"schedulePercent": 0.153357,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "Nancy Jazz Pulstation",
		"mostGuestCity": "Nancy",
		"mostGuestAmount": 1
	},
	{
		"id": "12bb8e0",
		"email": "steve@rendcollectiveexperiment.com",
		"name": "Stephen",
		"createdDate": "2014-06-19 15:56:38",
		"totalOrganizations": 8,
		"totalTours": 13,
		"totalDays": 796,
		"totalCountries": 4,
		"totalStates": 40,
		"totalEvents": 193,
		"eventPercent": 0.792743,
		"totalScheduleItems": 3280,
		"schedulePercent": 0.876308,
		"totalGuests": 454,
		"guestPercent": 0.675672,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "Rocketown",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 123
	},
	{
		"id": "39c5491",
		"email": "tina@tftours.com",
		"name": "Tina",
		"createdDate": "2019-02-07 08:55:49",
		"totalOrganizations": 16,
		"totalTours": 29,
		"totalDays": 752,
		"totalCountries": 12,
		"totalStates": 44,
		"totalEvents": 249,
		"eventPercent": 0.870746,
		"totalScheduleItems": 3524,
		"schedulePercent": 0.895908,
		"totalGuests": 26,
		"guestPercent": 0.381274,
		"mostGuestDate": "2023-07-06 00:00:00",
		"mostGuestName": "Piazza del Duomo",
		"mostGuestCity": "Spoleto",
		"mostGuestAmount": 6
	},
	{
		"id": "4aac97c",
		"email": "Carpinom@yahoo.com",
		"name": "Mike",
		"createdDate": "2021-09-22 14:07:26",
		"totalOrganizations": 2,
		"totalTours": 16,
		"totalDays": 79,
		"totalCountries": 6,
		"totalStates": 21,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 485,
		"schedulePercent": 0.29122,
		"totalGuests": 51,
		"guestPercent": 0.423785,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Brown County Music Center",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 22
	},
	{
		"id": "1a55a62",
		"email": "Evan.owen1@gmail.com",
		"name": "Evan",
		"createdDate": "2015-10-10 11:54:55",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 366,
		"totalCountries": 3,
		"totalStates": 37,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 2293,
		"schedulePercent": 0.764667,
		"totalGuests": 1818,
		"guestPercent": 0.921865,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Nashville Municipal Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 92
	},
	{
		"id": "cc02c01",
		"email": "info@smartart.com",
		"name": "donna",
		"createdDate": "2009-07-27 22:56:39",
		"totalOrganizations": 17,
		"totalTours": 9,
		"totalDays": 591,
		"totalCountries": 10,
		"totalStates": 53,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 2319,
		"schedulePercent": 0.768243,
		"totalGuests": 800,
		"guestPercent": 0.776056,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "FirstBank Amphitheater",
		"mostGuestCity": "Franklin",
		"mostGuestAmount": 104
	},
	{
		"id": "612160d",
		"email": "brettsimmonds@gmail.com",
		"name": "Brett",
		"createdDate": "2019-02-18 21:14:39",
		"totalOrganizations": 10,
		"totalTours": 4,
		"totalDays": 64,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 268,
		"schedulePercent": 0.190836,
		"totalGuests": 40,
		"guestPercent": 0.405642,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Renfrew Roots Festival",
		"mostGuestCity": "Renfrew",
		"mostGuestAmount": 23
	},
	{
		"id": "2bdc3e2",
		"email": "macklamcd@gmail.com",
		"name": "Connor",
		"createdDate": "2023-03-23 19:34:18",
		"totalOrganizations": 3,
		"totalTours": 10,
		"totalDays": 206,
		"totalCountries": 11,
		"totalStates": 36,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 790,
		"schedulePercent": 0.416501,
		"totalGuests": 489,
		"guestPercent": 0.688253,
		"mostGuestDate": "2023-11-22 00:00:00",
		"mostGuestName": "XOYO",
		"mostGuestCity": "London",
		"mostGuestAmount": 45
	},
	{
		"id": "5d9f66a",
		"email": "info@gngmgmt.com",
		"name": "G&G",
		"createdDate": "2018-03-18 13:39:46",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 445,
		"totalCountries": 19,
		"totalStates": 80,
		"totalEvents": 245,
		"eventPercent": 0.867435,
		"totalScheduleItems": 3008,
		"schedulePercent": 0.851543,
		"totalGuests": 818,
		"guestPercent": 0.779764,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "Royal Oak Theatre",
		"mostGuestCity": "Royal Oak",
		"mostGuestAmount": 49
	},
	{
		"id": "1cbc174",
		"email": "coble1212@me.com",
		"name": "Richard",
		"createdDate": "2014-05-23 20:20:41",
		"totalOrganizations": 9,
		"totalTours": 5,
		"totalDays": 767,
		"totalCountries": 19,
		"totalStates": 58,
		"totalEvents": 196,
		"eventPercent": 0.798835,
		"totalScheduleItems": 3128,
		"schedulePercent": 0.864124,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-07-09 00:00:00",
		"mostGuestName": "Finsbury Park",
		"mostGuestCity": "London",
		"mostGuestAmount": 2
	},
	{
		"id": "513573b",
		"email": "paddymcpoland@gmail.com",
		"name": "Paddy",
		"createdDate": "2009-11-18 00:11:53",
		"totalOrganizations": 12,
		"totalTours": 7,
		"totalDays": 385,
		"totalCountries": 10,
		"totalStates": 43,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 1267,
		"schedulePercent": 0.556483,
		"totalGuests": 1031,
		"guestPercent": 0.829294,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "3Arena",
		"mostGuestCity": "Dublin 1",
		"mostGuestAmount": 103
	},
	{
		"id": "c6a56bd",
		"email": "jmmarmor@gmail.com",
		"name": "Jacob",
		"createdDate": "2023-08-30 13:13:38",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 64,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 704,
		"schedulePercent": 0.382201,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "MVP Arena",
		"mostGuestCity": "Albany",
		"mostGuestAmount": 6
	},
	{
		"id": "7b70b38",
		"email": "skanetour@gmail.com",
		"name": "Sean",
		"createdDate": "2022-02-08 16:16:59",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 15,
		"totalStates": 26,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 525,
		"schedulePercent": 0.309628,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "833c034",
		"email": "g.dncn11@gmail.com",
		"name": "Gehrig",
		"createdDate": "2019-09-06 12:39:42",
		"totalOrganizations": 4,
		"totalTours": 15,
		"totalDays": 694,
		"totalCountries": 19,
		"totalStates": 69,
		"totalEvents": 269,
		"eventPercent": 0.893789,
		"totalScheduleItems": 4814,
		"schedulePercent": 0.955503,
		"totalGuests": 2074,
		"guestPercent": 0.938286,
		"mostGuestDate": "2023-07-03 00:00:00",
		"mostGuestName": "Banc of California Stadium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 219
	},
	{
		"id": "b739948",
		"email": "fohaudiodude@gmail.com",
		"name": "Neil",
		"createdDate": "2018-02-04 12:14:24",
		"totalOrganizations": 8,
		"totalTours": 15,
		"totalDays": 429,
		"totalCountries": 14,
		"totalStates": 37,
		"totalEvents": 171,
		"eventPercent": 0.75394,
		"totalScheduleItems": 2475,
		"schedulePercent": 0.789829,
		"totalGuests": 926,
		"guestPercent": 0.805589,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 178
	},
	{
		"id": "76d7851",
		"email": "nina.chevillard@gmail.com",
		"name": "Nina",
		"createdDate": "2020-01-30 16:29:04",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 428,
		"totalCountries": 6,
		"totalStates": 16,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 1947,
		"schedulePercent": 0.711032,
		"totalGuests": 10,
		"guestPercent": 0.343531,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "MusÃ©e WÃ¼rth",
		"mostGuestCity": "Erstein",
		"mostGuestAmount": 8
	},
	{
		"id": "acd0405",
		"email": "kneebone@gmail.com",
		"name": "Leon",
		"createdDate": "2017-12-04 04:30:40",
		"totalOrganizations": 10,
		"totalTours": 14,
		"totalDays": 307,
		"totalCountries": 13,
		"totalStates": 31,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 2271,
		"schedulePercent": 0.762151,
		"totalGuests": 256,
		"guestPercent": 0.582307,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 50
	},
	{
		"id": "a76ec0e",
		"email": "enzopallazio@gmail.com",
		"name": "Enzo",
		"createdDate": "2021-05-10 20:35:39",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 767,
		"totalCountries": 4,
		"totalStates": 18,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 1897,
		"schedulePercent": 0.703748,
		"totalGuests": 1336,
		"guestPercent": 0.87591,
		"mostGuestDate": "2023-07-06 00:00:00",
		"mostGuestName": "Cabaret Sauvage",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 47
	},
	{
		"id": "63418ac",
		"email": "xavier@dannock.com.au",
		"name": "Xavier",
		"createdDate": "2016-05-01 12:38:24",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 173,
		"totalCountries": 3,
		"totalStates": 16,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 1321,
		"schedulePercent": 0.571845,
		"totalGuests": 89,
		"guestPercent": 0.462323,
		"mostGuestDate": "2023-04-29 00:00:00",
		"mostGuestName": "Casino Miami",
		"mostGuestCity": "Miami",
		"mostGuestAmount": 17
	},
	{
		"id": "87c19ae",
		"email": "theexpendablesfans@gmail.com",
		"name": "Andrew",
		"createdDate": "2015-06-03 01:06:28",
		"totalOrganizations": 3,
		"totalTours": 21,
		"totalDays": 459,
		"totalCountries": 2,
		"totalStates": 40,
		"totalEvents": 250,
		"eventPercent": 0.872335,
		"totalScheduleItems": 3145,
		"schedulePercent": 0.865183,
		"totalGuests": 4588,
		"guestPercent": 0.989141,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "The Pacific Amphitheatre",
		"mostGuestCity": "Costa Mesa",
		"mostGuestAmount": 238
	},
	{
		"id": "75ca4d2",
		"email": "drewstagecrew@gmail.com",
		"name": "Drew",
		"createdDate": "2012-09-22 21:13:41",
		"totalOrganizations": 21,
		"totalTours": 16,
		"totalDays": 892,
		"totalCountries": 8,
		"totalStates": 63,
		"totalEvents": 272,
		"eventPercent": 0.895643,
		"totalScheduleItems": 4895,
		"schedulePercent": 0.958019,
		"totalGuests": 496,
		"guestPercent": 0.690637,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "O2 Forum",
		"mostGuestCity": "London",
		"mostGuestAmount": 49
	},
	{
		"id": "0fb7e77",
		"email": "kaitlin.madewell@redlightmanagement.com",
		"name": "Kaitlin",
		"createdDate": "2022-07-11 20:19:58",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 1090,
		"totalCountries": 4,
		"totalStates": 54,
		"totalEvents": 215,
		"eventPercent": 0.831016,
		"totalScheduleItems": 3825,
		"schedulePercent": 0.913389,
		"totalGuests": 1451,
		"guestPercent": 0.887432,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Moody Center",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 140
	},
	{
		"id": "8ce442a",
		"email": "thana@thanalexa.com",
		"name": "Thana",
		"createdDate": "2022-05-27 15:56:06",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 22,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 9,
		"eventPercent": 0.0607866,
		"totalScheduleItems": 156,
		"schedulePercent": 0.124752,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-10-04 00:00:00",
		"mostGuestName": "Guangzhou Book Center Reader Club ",
		"mostGuestCity": "Guangzhou",
		"mostGuestAmount": 1
	},
	{
		"id": "4ecf13c",
		"email": "Victoria.Chaitoff@wmg.com",
		"name": "Victoria",
		"createdDate": "2017-06-01 20:13:00",
		"totalOrganizations": 49,
		"totalTours": 46,
		"totalDays": 15376,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 2006,
		"eventPercent": 0.996557,
		"totalScheduleItems": 1872,
		"schedulePercent": 0.699775,
		"totalGuests": 26,
		"guestPercent": 0.381274,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Music Farm",
		"mostGuestCity": "Charleston",
		"mostGuestAmount": 7
	},
	{
		"id": "0b60604",
		"email": "touringsam@gmail.com",
		"name": "Sam ",
		"createdDate": "2012-10-31 16:52:09",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 1506,
		"schedulePercent": 0.619521,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Credit One Stadium",
		"mostGuestCity": "Charleston",
		"mostGuestAmount": 4
	},
	{
		"id": "0ec25b1",
		"email": "alex@concordtouring.com",
		"name": "Alex",
		"createdDate": "2022-05-20 21:53:01",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 628,
		"totalCountries": 10,
		"totalStates": 50,
		"totalEvents": 167,
		"eventPercent": 0.745332,
		"totalScheduleItems": 3165,
		"schedulePercent": 0.867302,
		"totalGuests": 1395,
		"guestPercent": 0.882135,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 104
	},
	{
		"id": "28d8d22",
		"email": "jimmylodi5@gmail.com",
		"name": "Jimmy ",
		"createdDate": "2022-07-07 22:17:31",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 1293,
		"totalCountries": 3,
		"totalStates": 54,
		"totalEvents": 354,
		"eventPercent": 0.943584,
		"totalScheduleItems": 3808,
		"schedulePercent": 0.9118,
		"totalGuests": 40,
		"guestPercent": 0.405642,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "Waterfront Park",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 20
	},
	{
		"id": "e52971c",
		"email": "aakker@hotmail.co.uk",
		"name": "Chris",
		"createdDate": "2012-09-28 15:49:11",
		"totalOrganizations": 11,
		"totalTours": 10,
		"totalDays": 395,
		"totalCountries": 13,
		"totalStates": 47,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 1784,
		"schedulePercent": 0.681896,
		"totalGuests": 364,
		"guestPercent": 0.638194,
		"mostGuestDate": "2023-10-25 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 122
	},
	{
		"id": "61d96d6",
		"email": "gooden.jamie@gmail.com",
		"name": "Jamie",
		"createdDate": "2023-01-10 19:00:40",
		"totalOrganizations": 3,
		"totalTours": 11,
		"totalDays": 285,
		"totalCountries": 12,
		"totalStates": 54,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 2460,
		"schedulePercent": 0.787445,
		"totalGuests": 2089,
		"guestPercent": 0.939346,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "Hard Rock Live",
		"mostGuestCity": "Orlando",
		"mostGuestAmount": 87
	},
	{
		"id": "492e908",
		"email": "jannie@jayla.dk",
		"name": "Jannie",
		"createdDate": "2023-01-05 10:53:52",
		"totalOrganizations": 14,
		"totalTours": 20,
		"totalDays": 391,
		"totalCountries": 12,
		"totalStates": 31,
		"totalEvents": 327,
		"eventPercent": 0.929413,
		"totalScheduleItems": 2118,
		"schedulePercent": 0.737783,
		"totalGuests": 538,
		"guestPercent": 0.705205,
		"mostGuestDate": "2023-12-06 00:00:00",
		"mostGuestName": "Store Vega",
		"mostGuestCity": "Copenhagen",
		"mostGuestAmount": 44
	},
	{
		"id": "59ba9d5",
		"email": "gauthier.dennielou@gmail.com",
		"name": "Gauthier",
		"createdDate": "2019-11-11 09:42:14",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 23,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 144,
		"schedulePercent": 0.115614,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-01-21 00:00:00",
		"mostGuestName": "Le Train ThÃ©Ã¢tre",
		"mostGuestCity": "Portes-lÃ¨s-Valence",
		"mostGuestAmount": 8
	},
	{
		"id": "8e72e24",
		"email": "elenadesotophoto@gmail.com",
		"name": "Elena",
		"createdDate": "2022-10-03 17:47:17",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 175,
		"totalCountries": 1,
		"totalStates": 33,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 1393,
		"schedulePercent": 0.589856,
		"totalGuests": 2267,
		"guestPercent": 0.946365,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Nashville Municipal Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 111
	},
	{
		"id": "3df12fd",
		"email": "katy.baker1@gmail.com",
		"name": "Katy",
		"createdDate": "2016-09-14 21:29:39",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 160,
		"totalCountries": 7,
		"totalStates": 28,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 766,
		"schedulePercent": 0.407496,
		"totalGuests": 391,
		"guestPercent": 0.649715,
		"mostGuestDate": "2023-10-24 00:00:00",
		"mostGuestName": "Northcote Theatre",
		"mostGuestCity": "Northcote",
		"mostGuestAmount": 47
	},
	{
		"id": "33ac934",
		"email": "naylaazzouzlee@gmail.com",
		"name": "Nayla",
		"createdDate": "2023-07-05 12:00:05",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 52,
		"totalCountries": 3,
		"totalStates": 21,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 290,
		"schedulePercent": 0.203152,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Drkmttr",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 1
	},
	{
		"id": "ce52cd5",
		"email": "info@righthandmanagement.com.au",
		"name": "Anthony",
		"createdDate": "2016-03-03 01:07:32",
		"totalOrganizations": 16,
		"totalTours": 21,
		"totalDays": 117,
		"totalCountries": 4,
		"totalStates": 14,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 378,
		"schedulePercent": 0.247252,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "SummerSalt Hervey Bay Seafront Oval",
		"mostGuestCity": "Pialba",
		"mostGuestAmount": 19
	},
	{
		"id": "cbc68c1",
		"email": "darrenhawthorne@mac.com",
		"name": "Darren ",
		"createdDate": "2011-12-15 03:47:11",
		"totalOrganizations": 53,
		"totalTours": 46,
		"totalDays": 946,
		"totalCountries": 18,
		"totalStates": 47,
		"totalEvents": 313,
		"eventPercent": 0.922394,
		"totalScheduleItems": 4303,
		"schedulePercent": 0.937889,
		"totalGuests": 495,
		"guestPercent": 0.689975,
		"mostGuestDate": "2023-07-24 00:00:00",
		"mostGuestName": "Hordern Pavilion",
		"mostGuestCity": "Moore Park",
		"mostGuestAmount": 70
	},
	{
		"id": "fde3fd4",
		"email": "lachlandoley@mac.com",
		"name": "Lachlan",
		"createdDate": "2013-08-07 05:20:59",
		"totalOrganizations": 3,
		"totalTours": 15,
		"totalDays": 252,
		"totalCountries": 11,
		"totalStates": 20,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 596,
		"schedulePercent": 0.338763,
		"totalGuests": 162,
		"guestPercent": 0.524169,
		"mostGuestDate": "2023-06-18 00:00:00",
		"mostGuestName": "Joan Sutherland Performing Arts Centre",
		"mostGuestCity": "Penrith",
		"mostGuestAmount": 58
	},
	{
		"id": "922d4a1",
		"email": "ericswan78@gmail.com",
		"name": "Eric",
		"createdDate": "2019-12-18 23:00:58",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 83,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 1134,
		"schedulePercent": 0.521255,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b520785",
		"email": "sbaptiste@questionmarkent.com",
		"name": "Simon",
		"createdDate": "2023-01-14 14:52:58",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 956,
		"totalCountries": 4,
		"totalStates": 19,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 505,
		"schedulePercent": 0.301152,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "22d235d",
		"email": "ivaughn@rocknconcepts.com",
		"name": "Ian",
		"createdDate": "2021-08-01 21:29:09",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 597,
		"totalCountries": 17,
		"totalStates": 65,
		"totalEvents": 212,
		"eventPercent": 0.826778,
		"totalScheduleItems": 4099,
		"schedulePercent": 0.927559,
		"totalGuests": 1119,
		"guestPercent": 0.842802,
		"mostGuestDate": "2023-08-27 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 151
	},
	{
		"id": "5b9adbb",
		"email": "bibesmusic@gmail.com",
		"name": "Andy",
		"createdDate": "2017-11-10 11:43:23",
		"totalOrganizations": 10,
		"totalTours": 9,
		"totalDays": 85,
		"totalCountries": 7,
		"totalStates": 14,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 362,
		"schedulePercent": 0.238776,
		"totalGuests": 73,
		"guestPercent": 0.447755,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "Heaven",
		"mostGuestCity": "London",
		"mostGuestAmount": 51
	},
	{
		"id": "130e407",
		"email": "hramsey@me.com",
		"name": "Henry",
		"createdDate": "2022-04-03 03:08:15",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 96,
		"totalCountries": 3,
		"totalStates": 18,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 628,
		"schedulePercent": 0.353331,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4b8fd7f",
		"email": "rick@ruleoutloud.com",
		"name": "Rick",
		"createdDate": "2010-07-25 09:15:25",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 96,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 472,
		"schedulePercent": 0.285658,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "27884bc",
		"email": "Production@TheAretha.com",
		"name": "Johnny",
		"createdDate": "2022-01-11 15:21:16",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 621,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 607,
		"schedulePercent": 0.34459,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bef59ae",
		"email": "braunsound@me.com",
		"name": "Chris",
		"createdDate": "2013-10-28 18:22:54",
		"totalOrganizations": 20,
		"totalTours": 22,
		"totalDays": 899,
		"totalCountries": 16,
		"totalStates": 55,
		"totalEvents": 266,
		"eventPercent": 0.891935,
		"totalScheduleItems": 2912,
		"schedulePercent": 0.843597,
		"totalGuests": 654,
		"guestPercent": 0.740299,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Marathon Music Works",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 57
	},
	{
		"id": "fe701ff",
		"email": "thomas@ontoumgmt.com",
		"name": "Thomas",
		"createdDate": "2009-07-21 18:48:10",
		"totalOrganizations": 21,
		"totalTours": 11,
		"totalDays": 479,
		"totalCountries": 19,
		"totalStates": 45,
		"totalEvents": 137,
		"eventPercent": 0.666402,
		"totalScheduleItems": 711,
		"schedulePercent": 0.384982,
		"totalGuests": 942,
		"guestPercent": 0.810224,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 58
	},
	{
		"id": "4acd3e2",
		"email": "shelbytouring@gmail.com",
		"name": "Shelby",
		"createdDate": "2019-07-02 11:36:15",
		"totalOrganizations": 11,
		"totalTours": 7,
		"totalDays": 587,
		"totalCountries": 8,
		"totalStates": 46,
		"totalEvents": 198,
		"eventPercent": 0.802543,
		"totalScheduleItems": 2860,
		"schedulePercent": 0.838829,
		"totalGuests": 1798,
		"guestPercent": 0.920408,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "St. Augustine Amphitheatre",
		"mostGuestCity": "St. Augustine",
		"mostGuestAmount": 104
	},
	{
		"id": "aa780b4",
		"email": "katewilson100@gmail.com",
		"name": "Kate",
		"createdDate": "2021-07-20 01:00:43",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 511,
		"totalCountries": 10,
		"totalStates": 54,
		"totalEvents": 162,
		"eventPercent": 0.732883,
		"totalScheduleItems": 3165,
		"schedulePercent": 0.867302,
		"totalGuests": 4332,
		"guestPercent": 0.986624,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Fenway Park",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 322
	},
	{
		"id": "afd4bc8",
		"email": "ronnielcampbell@gmail.com",
		"name": "Ronnie",
		"createdDate": "2013-05-20 04:26:58",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 176,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 624,
		"schedulePercent": 0.351741,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "25bac52",
		"email": "maxonbrent@gmail.com",
		"name": "Brent",
		"createdDate": "2013-01-09 04:53:38",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 1481,
		"totalCountries": 5,
		"totalStates": 42,
		"totalEvents": 225,
		"eventPercent": 0.842008,
		"totalScheduleItems": 4534,
		"schedulePercent": 0.94663,
		"totalGuests": 3360,
		"guestPercent": 0.975235,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 293
	},
	{
		"id": "15aa2c4",
		"email": "nick@advancedtourmgmt.com",
		"name": "Nick",
		"createdDate": "2010-04-30 16:37:06",
		"totalOrganizations": 19,
		"totalTours": 10,
		"totalDays": 1256,
		"totalCountries": 14,
		"totalStates": 45,
		"totalEvents": 293,
		"eventPercent": 0.911005,
		"totalScheduleItems": 3849,
		"schedulePercent": 0.915243,
		"totalGuests": 939,
		"guestPercent": 0.809297,
		"mostGuestDate": "2023-05-23 00:00:00",
		"mostGuestName": "Spectrum Center",
		"mostGuestCity": "Charlotte",
		"mostGuestAmount": 55
	},
	{
		"id": "c07d4da",
		"email": "kptour@me.com",
		"name": "Rob",
		"createdDate": "2012-03-08 02:19:55",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 674,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 1545,
		"schedulePercent": 0.628658,
		"totalGuests": 524,
		"guestPercent": 0.699775,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Julian B Lane Riverfront Park",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 92
	},
	{
		"id": "38c9c60",
		"email": "sbwinkler72@gmail.com",
		"name": "Seth",
		"createdDate": "2021-10-13 13:12:56",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 888,
		"totalCountries": 9,
		"totalStates": 37,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 1088,
		"schedulePercent": 0.509469,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "Vanquish Nightclub",
		"mostGuestCity": "Minneapolis",
		"mostGuestAmount": 4
	},
	{
		"id": "2c4204f",
		"email": "goodtalk@mac.com",
		"name": "Scott",
		"createdDate": "2010-12-15 01:21:12",
		"totalOrganizations": 31,
		"totalTours": 15,
		"totalDays": 700,
		"totalCountries": 16,
		"totalStates": 65,
		"totalEvents": 218,
		"eventPercent": 0.834724,
		"totalScheduleItems": 2095,
		"schedulePercent": 0.734472,
		"totalGuests": 963,
		"guestPercent": 0.816316,
		"mostGuestDate": "2023-10-09 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 59
	},
	{
		"id": "50bcb21",
		"email": "hollie.doherty@outlook.com",
		"name": "Hollie",
		"createdDate": "2020-03-03 12:27:28",
		"totalOrganizations": 10,
		"totalTours": 11,
		"totalDays": 210,
		"totalCountries": 11,
		"totalStates": 22,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 684,
		"schedulePercent": 0.374388,
		"totalGuests": 329,
		"guestPercent": 0.618064,
		"mostGuestDate": "2023-01-26 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 80
	},
	{
		"id": "870688a",
		"email": "melodymariedepree@gmail.com",
		"name": "Melody",
		"createdDate": "2022-01-31 19:25:35",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 639,
		"totalCountries": 16,
		"totalStates": 52,
		"totalEvents": 362,
		"eventPercent": 0.947159,
		"totalScheduleItems": 5015,
		"schedulePercent": 0.962389,
		"totalGuests": 508,
		"guestPercent": 0.69461,
		"mostGuestDate": "2023-05-09 00:00:00",
		"mostGuestName": "Ziggo Dome",
		"mostGuestCity": "Amsterdam",
		"mostGuestAmount": 37
	},
	{
		"id": "d8f0c75",
		"email": "marissabellon@gmail.com",
		"name": "Marissa",
		"createdDate": "2022-10-04 13:23:47",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 116,
		"totalCountries": 7,
		"totalStates": 24,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 381,
		"schedulePercent": 0.249371,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ab53496",
		"email": "erinfornow@gmail.com",
		"name": "Erin",
		"createdDate": "2010-08-04 18:02:57",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 192,
		"totalCountries": 7,
		"totalStates": 20,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 1535,
		"schedulePercent": 0.626937,
		"totalGuests": 370,
		"guestPercent": 0.64124,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "MegaCorp Pavilion",
		"mostGuestCity": "Newport",
		"mostGuestAmount": 61
	},
	{
		"id": "ec48c4e",
		"email": "b.desage31@gmail.com",
		"name": "BERNARD",
		"createdDate": "2021-12-15 08:04:20",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 340,
		"totalCountries": 3,
		"totalStates": 9,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 833,
		"schedulePercent": 0.432393,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1542253",
		"email": "darakartz@gmail.com",
		"name": "Dara",
		"createdDate": "2011-04-07 07:24:05",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 127,
		"totalCountries": 7,
		"totalStates": 31,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 665,
		"schedulePercent": 0.367236,
		"totalGuests": 254,
		"guestPercent": 0.581248,
		"mostGuestDate": "2023-05-27 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 38
	},
	{
		"id": "1b09aff",
		"email": "prod@lacentralemobile.com",
		"name": "Maxime",
		"createdDate": "2022-11-14 16:10:38",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 230,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 407,
		"schedulePercent": 0.260098,
		"totalGuests": 32,
		"guestPercent": 0.392531,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "La Petite Ã‰glise",
		"mostGuestCity": "Saint-Eustache",
		"mostGuestAmount": 24
	},
	{
		"id": "caf21dd",
		"email": "tonyluby1@gmail.com",
		"name": "Tony",
		"createdDate": "2017-02-22 19:55:18",
		"totalOrganizations": 16,
		"totalTours": 26,
		"totalDays": 946,
		"totalCountries": 8,
		"totalStates": 31,
		"totalEvents": 232,
		"eventPercent": 0.852073,
		"totalScheduleItems": 2691,
		"schedulePercent": 0.818302,
		"totalGuests": 842,
		"guestPercent": 0.785989,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 101
	},
	{
		"id": "8b40303",
		"email": "roadm.victormanuelle@gmail.com",
		"name": "Marco",
		"createdDate": "2017-06-27 22:23:59",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 702,
		"totalCountries": 7,
		"totalStates": 14,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 1057,
		"schedulePercent": 0.500066,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2ccd231",
		"email": "ashleyrcmartin@gmail.com",
		"name": "Ashley",
		"createdDate": "2022-01-18 03:17:04",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 33,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 13,
		"eventPercent": 0.0892597,
		"totalScheduleItems": 198,
		"schedulePercent": 0.148987,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b998097",
		"email": "kidzboprm@gmail.com",
		"name": "Donna ",
		"createdDate": "2023-06-17 12:36:20",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 394,
		"totalCountries": 2,
		"totalStates": 31,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 3354,
		"schedulePercent": 0.883724,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-06-25 00:00:00",
		"mostGuestName": "Stamford Center for the Arts",
		"mostGuestCity": "Stamford",
		"mostGuestAmount": 6
	},
	{
		"id": "e35509f",
		"email": "emor009@gmail.com",
		"name": "Rome",
		"createdDate": "2016-06-20 22:17:34",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 364,
		"totalCountries": 5,
		"totalStates": 25,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 804,
		"schedulePercent": 0.421931,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "601766a",
		"email": "harlee@modestmanagement.com",
		"name": "Harlee",
		"createdDate": "2016-05-25 16:07:20",
		"totalOrganizations": 30,
		"totalTours": 16,
		"totalDays": 1442,
		"totalCountries": 15,
		"totalStates": 34,
		"totalEvents": 237,
		"eventPercent": 0.858429,
		"totalScheduleItems": 3478,
		"schedulePercent": 0.892597,
		"totalGuests": 142,
		"guestPercent": 0.506688,
		"mostGuestDate": "2023-02-02 00:00:00",
		"mostGuestName": "The Academy",
		"mostGuestCity": "Dublin 1",
		"mostGuestAmount": 39
	},
	{
		"id": "62415b8",
		"email": "fromwordofmouth@gmail.com",
		"name": "Espinoza",
		"createdDate": "2021-09-12 22:21:27",
		"totalOrganizations": 14,
		"totalTours": 11,
		"totalDays": 536,
		"totalCountries": 10,
		"totalStates": 48,
		"totalEvents": 177,
		"eventPercent": 0.765594,
		"totalScheduleItems": 1681,
		"schedulePercent": 0.660177,
		"totalGuests": 1075,
		"guestPercent": 0.835254,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "MetLife Stadium",
		"mostGuestCity": "East Rutherford",
		"mostGuestAmount": 66
	},
	{
		"id": "aaf84f7",
		"email": "zsp@havasi.eu",
		"name": "Zsofia",
		"createdDate": "2022-07-26 10:09:40",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 106,
		"totalCountries": 5,
		"totalStates": 6,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 774,
		"schedulePercent": 0.410674,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1d7a306",
		"email": "julie@juliecotton.co.uk",
		"name": "Julie",
		"createdDate": "2022-03-17 14:01:49",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 795,
		"totalCountries": 7,
		"totalStates": 32,
		"totalEvents": 356,
		"eventPercent": 0.944643,
		"totalScheduleItems": 5966,
		"schedulePercent": 0.979738,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Lincoln Financial Field",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 4
	},
	{
		"id": "5b31967",
		"email": "jocelyn@thesteadfastco.com",
		"name": "Jocelyn",
		"createdDate": "2023-01-05 19:41:44",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 428,
		"totalCountries": 6,
		"totalStates": 50,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 2197,
		"schedulePercent": 0.748907,
		"totalGuests": 633,
		"guestPercent": 0.73487,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "Dixie National Livestock Show / State Coliseum",
		"mostGuestCity": "Jackson",
		"mostGuestAmount": 31
	},
	{
		"id": "540fac5",
		"email": "julie@thenoiseacademy.com",
		"name": "Julie",
		"createdDate": "2019-08-09 01:42:26",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 191,
		"totalCountries": 10,
		"totalStates": 37,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1352,
		"schedulePercent": 0.580983,
		"totalGuests": 443,
		"guestPercent": 0.669315,
		"mostGuestDate": "2023-10-08 00:00:00",
		"mostGuestName": "Power Trip Music Festival",
		"mostGuestCity": "Indio",
		"mostGuestAmount": 87
	},
	{
		"id": "511061e",
		"email": "Bernie@tbz-music.de",
		"name": "Bernie",
		"createdDate": "2013-11-28 12:44:15",
		"totalOrganizations": 4,
		"totalTours": 21,
		"totalDays": 372,
		"totalCountries": 13,
		"totalStates": 41,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 972,
		"schedulePercent": 0.473447,
		"totalGuests": 207,
		"guestPercent": 0.555953,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 23
	},
	{
		"id": "c137f6f",
		"email": "kristinakarvonen@hotmail.com",
		"name": "Kristina",
		"createdDate": "2016-11-10 09:46:56",
		"totalOrganizations": 45,
		"totalTours": 34,
		"totalDays": 1225,
		"totalCountries": 6,
		"totalStates": 23,
		"totalEvents": 1164,
		"eventPercent": 0.991922,
		"totalScheduleItems": 4811,
		"schedulePercent": 0.95537,
		"totalGuests": 149,
		"guestPercent": 0.513972,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Tivoli Friheden",
		"mostGuestCity": "Aarhus",
		"mostGuestAmount": 36
	},
	{
		"id": "3c618e4",
		"email": "toneill@ctsavl.com",
		"name": "Tim",
		"createdDate": "2022-09-16 20:59:42",
		"totalOrganizations": 3,
		"totalTours": 12,
		"totalDays": 512,
		"totalCountries": 4,
		"totalStates": 40,
		"totalEvents": 420,
		"eventPercent": 0.962654,
		"totalScheduleItems": 3167,
		"schedulePercent": 0.867567,
		"totalGuests": 318,
		"guestPercent": 0.613031,
		"mostGuestDate": "2023-11-19 00:00:00",
		"mostGuestName": "The Maryland Theatre",
		"mostGuestCity": "Hagerstown",
		"mostGuestAmount": 23
	},
	{
		"id": "290e42d",
		"email": "hap@pardoproductionsinc.com",
		"name": "Hap",
		"createdDate": "2017-06-27 06:03:39",
		"totalOrganizations": 11,
		"totalTours": 47,
		"totalDays": 586,
		"totalCountries": 13,
		"totalStates": 50,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 1362,
		"schedulePercent": 0.583499,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ac81605",
		"email": "liammonroe1991@gmail.com",
		"name": "Liam",
		"createdDate": "2018-03-02 23:27:13",
		"totalOrganizations": 17,
		"totalTours": 34,
		"totalDays": 382,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 3391,
		"schedulePercent": 0.886902,
		"totalGuests": 4242,
		"guestPercent": 0.985565,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "Moody Center",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 314
	},
	{
		"id": "02c5fb8",
		"email": "info@skenecomedy.se",
		"name": "koshar",
		"createdDate": "2023-05-03 11:55:30",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 254,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "10f6c15",
		"email": "office@theprincipals.de",
		"name": "Mandy",
		"createdDate": "2019-05-10 10:50:13",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 112,
		"totalCountries": 11,
		"totalStates": 16,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 1064,
		"schedulePercent": 0.502318,
		"totalGuests": 469,
		"guestPercent": 0.680837,
		"mostGuestDate": "2023-07-23 00:00:00",
		"mostGuestName": "ParkbÃ¼hne Wuhlheide",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 305
	},
	{
		"id": "65ffe2f",
		"email": "magdalena@hellotillman.se",
		"name": "Magdalena",
		"createdDate": "2020-02-19 09:37:11",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 59,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 237,
		"schedulePercent": 0.173355,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "203dd3c",
		"email": "Keithwailes@gmail.com",
		"name": "Keith",
		"createdDate": "2016-10-25 02:38:44",
		"totalOrganizations": 9,
		"totalTours": 8,
		"totalDays": 330,
		"totalCountries": 7,
		"totalStates": 48,
		"totalEvents": 168,
		"eventPercent": 0.747451,
		"totalScheduleItems": 2659,
		"schedulePercent": 0.814727,
		"totalGuests": 1486,
		"guestPercent": 0.892465,
		"mostGuestDate": "2023-01-29 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 229
	},
	{
		"id": "2b23e97",
		"email": "primetouring@gmail.com",
		"name": "Kent",
		"createdDate": "2013-08-29 01:18:16",
		"totalOrganizations": 11,
		"totalTours": 22,
		"totalDays": 212,
		"totalCountries": 10,
		"totalStates": 46,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 1320,
		"schedulePercent": 0.57158,
		"totalGuests": 482,
		"guestPercent": 0.68428,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Cow Palace",
		"mostGuestCity": "Daly City",
		"mostGuestAmount": 226
	},
	{
		"id": "fe835bf",
		"email": "paul.collis@ststouring.co.uk",
		"name": "Paul",
		"createdDate": "2012-08-29 18:23:13",
		"totalOrganizations": 12,
		"totalTours": 12,
		"totalDays": 326,
		"totalCountries": 16,
		"totalStates": 74,
		"totalEvents": 216,
		"eventPercent": 0.831943,
		"totalScheduleItems": 2991,
		"schedulePercent": 0.850086,
		"totalGuests": 2037,
		"guestPercent": 0.936035,
		"mostGuestDate": "2023-05-11 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 87
	},
	{
		"id": "2bfeb33",
		"email": "shauntours@gmail.com",
		"name": "Shaun",
		"createdDate": "2011-04-05 01:11:11",
		"totalOrganizations": 14,
		"totalTours": 6,
		"totalDays": 173,
		"totalCountries": 9,
		"totalStates": 18,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 625,
		"schedulePercent": 0.352271,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b10edcf",
		"email": "mike@mdtourmanagement.com",
		"name": "Mike",
		"createdDate": "2022-08-22 15:22:07",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 144,
		"totalCountries": 10,
		"totalStates": 19,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 440,
		"schedulePercent": 0.272812,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1f56623",
		"email": "josejamesworldwide@gmail.com",
		"name": "Jose",
		"createdDate": "2013-07-07 21:29:13",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 669,
		"totalCountries": 10,
		"totalStates": 35,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 601,
		"schedulePercent": 0.341544,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bb82ea4",
		"email": "tommy@boyh.co.uk",
		"name": "Tommy",
		"createdDate": "2016-06-06 14:22:40",
		"totalOrganizations": 14,
		"totalTours": 56,
		"totalDays": 339,
		"totalCountries": 19,
		"totalStates": 43,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 1660,
		"schedulePercent": 0.655675,
		"totalGuests": 339,
		"guestPercent": 0.622964,
		"mostGuestDate": "2023-02-21 00:00:00",
		"mostGuestName": "Millennium Forum Theatre & Conference Centre",
		"mostGuestCity": "Londonderry",
		"mostGuestAmount": 40
	},
	{
		"id": "54368c4",
		"email": "campiglios@gmail.com",
		"name": "Silvia",
		"createdDate": "2023-05-06 14:02:31",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 183,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 125,
		"schedulePercent": 0.106079,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0364077",
		"email": "abbygilman@mac.com",
		"name": "Abigail",
		"createdDate": "2017-12-29 18:10:24",
		"totalOrganizations": 8,
		"totalTours": 15,
		"totalDays": 1206,
		"totalCountries": 21,
		"totalStates": 84,
		"totalEvents": 379,
		"eventPercent": 0.951,
		"totalScheduleItems": 6585,
		"schedulePercent": 0.985962,
		"totalGuests": 4708,
		"guestPercent": 0.98967,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 178
	},
	{
		"id": "3580a4a",
		"email": "dgee@cityparksfoundation.org",
		"name": "Danni ",
		"createdDate": "2014-05-28 18:19:05",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ad91b7d",
		"email": "scottyrock2000@gmail.com",
		"name": "Scott",
		"createdDate": "2010-10-01 22:37:18",
		"totalOrganizations": 13,
		"totalTours": 2,
		"totalDays": 134,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 1319,
		"schedulePercent": 0.570785,
		"totalGuests": 765,
		"guestPercent": 0.767845,
		"mostGuestDate": "2023-02-21 00:00:00",
		"mostGuestName": "Eagles Ballroom",
		"mostGuestCity": "Milwaukee",
		"mostGuestAmount": 63
	},
	{
		"id": "5e16ebb",
		"email": "danny.collins@harlemglobetrotters.com",
		"name": "Danny ",
		"createdDate": "2022-12-07 15:48:41",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 341,
		"totalCountries": 8,
		"totalStates": 68,
		"totalEvents": 251,
		"eventPercent": 0.873792,
		"totalScheduleItems": 2606,
		"schedulePercent": 0.808502,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8fa3ee9",
		"email": "jacob@jjmfp.com",
		"name": "Jacob",
		"createdDate": "2009-06-03 22:01:35",
		"totalOrganizations": 10,
		"totalTours": 24,
		"totalDays": 448,
		"totalCountries": 10,
		"totalStates": 50,
		"totalEvents": 182,
		"eventPercent": 0.773408,
		"totalScheduleItems": 2286,
		"schedulePercent": 0.76321,
		"totalGuests": 94,
		"guestPercent": 0.469474,
		"mostGuestDate": "2023-09-09 00:00:00",
		"mostGuestName": "Marquis Theater",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 16
	},
	{
		"id": "ead95a8",
		"email": "casper.beese.nielsen@gmail.com",
		"name": "Casper",
		"createdDate": "2019-05-23 10:03:50",
		"totalOrganizations": 13,
		"totalTours": 11,
		"totalDays": 196,
		"totalCountries": 5,
		"totalStates": 10,
		"totalEvents": 175,
		"eventPercent": 0.761753,
		"totalScheduleItems": 1042,
		"schedulePercent": 0.495166,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Ã˜havet Festival",
		"mostGuestCity": "Marstal",
		"mostGuestAmount": 4
	},
	{
		"id": "9d6fd78",
		"email": "jodi@thestorytour.org",
		"name": "Jodi",
		"createdDate": "2021-01-28 15:35:38",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 205,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 363,
		"schedulePercent": 0.239703,
		"totalGuests": 19,
		"guestPercent": 0.366971,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "Blair County Convention Center",
		"mostGuestCity": "Altoona",
		"mostGuestAmount": 14
	},
	{
		"id": "ebc0291",
		"email": "roncmoss@gmail.com",
		"name": "Ron",
		"createdDate": "2016-01-25 21:58:09",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 325,
		"totalCountries": 8,
		"totalStates": 11,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 777,
		"schedulePercent": 0.411998,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Grand Auditorium Louis LumiÃ¨re",
		"mostGuestCity": "Cannes",
		"mostGuestAmount": 6
	},
	{
		"id": "fc466d1",
		"email": "derek@lecrae.com",
		"name": "Derek",
		"createdDate": "2016-04-21 21:11:48",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 170,
		"totalCountries": 3,
		"totalStates": 34,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 1296,
		"schedulePercent": 0.565488,
		"totalGuests": 413,
		"guestPercent": 0.659383,
		"mostGuestDate": "2023-04-22 00:00:00",
		"mostGuestName": "Grace Church Houston",
		"mostGuestCity": "Houston",
		"mostGuestAmount": 70
	},
	{
		"id": "89f4a88",
		"email": "s.telgmann@msk-live.de",
		"name": "Samantha",
		"createdDate": "2023-05-26 06:58:34",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 352,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 1768,
		"schedulePercent": 0.678188,
		"totalGuests": 410,
		"guestPercent": 0.658059,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "BonnLive Open Air",
		"mostGuestCity": "Bonn",
		"mostGuestAmount": 61
	},
	{
		"id": "ec62dbf",
		"email": "kgoodwyn@cityparksfoundation.org",
		"name": "Kahlil ",
		"createdDate": "2011-05-06 19:25:49",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c64e283",
		"email": "amc@kelco.io",
		"name": "Anna Marie",
		"createdDate": "2023-03-09 01:52:18",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 298,
		"totalCountries": 6,
		"totalStates": 18,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 1312,
		"schedulePercent": 0.569064,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3c6c53f",
		"email": "c.a.andrews@live.com",
		"name": "Chris",
		"createdDate": "2022-02-08 23:55:26",
		"totalOrganizations": 6,
		"totalTours": 9,
		"totalDays": 198,
		"totalCountries": 2,
		"totalStates": 40,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 1232,
		"schedulePercent": 0.547874,
		"totalGuests": 166,
		"guestPercent": 0.52748,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "Avenir Centre",
		"mostGuestCity": "Moncton",
		"mostGuestAmount": 20
	},
	{
		"id": "d4e5b3b",
		"email": "ricardo@piosenka-plus.com",
		"name": "Ricardo ",
		"createdDate": "2023-09-05 17:55:04",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 22,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 158,
		"schedulePercent": 0.125811,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2d07fe3",
		"email": "reid@hrd8wrk.com",
		"name": "Reid",
		"createdDate": "2022-06-13 15:36:44",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 155,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 444,
		"schedulePercent": 0.274666,
		"totalGuests": 163,
		"guestPercent": 0.525493,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 30
	},
	{
		"id": "44e5428",
		"email": "ingridandressmusic@gmail.com",
		"name": "Ingrid",
		"createdDate": "2016-09-19 18:21:24",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 185,
		"totalCountries": 8,
		"totalStates": 38,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1686,
		"schedulePercent": 0.661237,
		"totalGuests": 519,
		"guestPercent": 0.698451,
		"mostGuestDate": "2023-03-02 00:00:00",
		"mostGuestName": "El Rey Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 42
	},
	{
		"id": "4d5463f",
		"email": "marie.chailosky@wmg.com",
		"name": "Marie",
		"createdDate": "2021-09-29 20:16:14",
		"totalOrganizations": 45,
		"totalTours": 44,
		"totalDays": 15011,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 1913,
		"eventPercent": 0.993908,
		"totalScheduleItems": 742,
		"schedulePercent": 0.397431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "17910bc",
		"email": "gabriel@houseoflove.net",
		"name": "Gabriel",
		"createdDate": "2021-08-10 13:43:48",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 363,
		"totalCountries": 11,
		"totalStates": 20,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 967,
		"schedulePercent": 0.470269,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a16f246",
		"email": "danwhitetm@me.com",
		"name": "DANIEL",
		"createdDate": "2022-06-15 10:32:07",
		"totalOrganizations": 9,
		"totalTours": 27,
		"totalDays": 229,
		"totalCountries": 7,
		"totalStates": 20,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 335,
		"schedulePercent": 0.225798,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6d7d9cd",
		"email": "jessica.b.summerour@gmail.com",
		"name": "Jessica",
		"createdDate": "2023-02-17 23:29:52",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 48,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 529,
		"schedulePercent": 0.312409,
		"totalGuests": 432,
		"guestPercent": 0.66574,
		"mostGuestDate": "2023-11-30 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 57
	},
	{
		"id": "8943aae",
		"email": "sepi.sadeghi@gmail.com",
		"name": "Sepi",
		"createdDate": "2023-02-13 02:57:17",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 155,
		"totalCountries": 3,
		"totalStates": 16,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 666,
		"schedulePercent": 0.367633,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "84c89f6",
		"email": "justin@qprime.com",
		"name": "Justin",
		"createdDate": "2014-01-17 15:20:40",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 191,
		"totalCountries": 6,
		"totalStates": 40,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 1422,
		"schedulePercent": 0.597272,
		"totalGuests": 47,
		"guestPercent": 0.417031,
		"mostGuestDate": "2023-11-29 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 33
	},
	{
		"id": "cfd7fa7",
		"email": "jean-louis@foreigneronline.com",
		"name": "Jean-Louis",
		"createdDate": "2018-10-27 06:32:10",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 468,
		"totalCountries": 3,
		"totalStates": 40,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 2890,
		"schedulePercent": 0.840418,
		"totalGuests": 2685,
		"guestPercent": 0.959873,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "Northwell Health at Jones Beach Theater",
		"mostGuestCity": "Wantagh",
		"mostGuestAmount": 109
	},
	{
		"id": "25c4418",
		"email": "travisholcomb1@gmail.com",
		"name": "travis",
		"createdDate": "2022-06-13 20:21:00",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 133,
		"totalCountries": 5,
		"totalStates": 36,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 682,
		"schedulePercent": 0.373725,
		"totalGuests": 136,
		"guestPercent": 0.503377,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "The Garage",
		"mostGuestCity": "London",
		"mostGuestAmount": 34
	},
	{
		"id": "c8b73a7",
		"email": "joemusicotouring@gmail.com",
		"name": "Joseph",
		"createdDate": "2019-05-31 22:32:25",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 980,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 693,
		"schedulePercent": 0.377831,
		"totalGuests": 66,
		"guestPercent": 0.441001,
		"mostGuestDate": "2023-02-09 00:00:00",
		"mostGuestName": "The Mission Ballroom",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 21
	},
	{
		"id": "88f08a2",
		"email": "cordell@transparentproductions.com",
		"name": "Cordell",
		"createdDate": "2021-12-10 17:33:12",
		"totalOrganizations": 9,
		"totalTours": 14,
		"totalDays": 690,
		"totalCountries": 4,
		"totalStates": 34,
		"totalEvents": 183,
		"eventPercent": 0.775659,
		"totalScheduleItems": 3069,
		"schedulePercent": 0.858164,
		"totalGuests": 1113,
		"guestPercent": 0.841478,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "The Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 130
	},
	{
		"id": "5f2296b",
		"email": "kylecrownover@gmail.com",
		"name": "Kyle",
		"createdDate": "2016-11-10 18:22:46",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 533,
		"totalCountries": 6,
		"totalStates": 53,
		"totalEvents": 211,
		"eventPercent": 0.825586,
		"totalScheduleItems": 2591,
		"schedulePercent": 0.804794,
		"totalGuests": 607,
		"guestPercent": 0.728248,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 50
	},
	{
		"id": "cfceed4",
		"email": "akeem@sixshooterrecords.com",
		"name": "Akeem",
		"createdDate": "2021-10-26 17:00:52",
		"totalOrganizations": 10,
		"totalTours": 7,
		"totalDays": 1364,
		"totalCountries": 13,
		"totalStates": 60,
		"totalEvents": 269,
		"eventPercent": 0.893789,
		"totalScheduleItems": 2510,
		"schedulePercent": 0.794597,
		"totalGuests": 906,
		"guestPercent": 0.800954,
		"mostGuestDate": "2023-06-14 00:00:00",
		"mostGuestName": "TD Music Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 65
	},
	{
		"id": "8324d59",
		"email": "hiroshi696@gmail.com",
		"name": "Hiroshi",
		"createdDate": "2023-02-23 17:43:29",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 84,
		"totalCountries": 1,
		"totalStates": 26,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 696,
		"schedulePercent": 0.379023,
		"totalGuests": 143,
		"guestPercent": 0.508277,
		"mostGuestDate": "2023-03-19 00:00:00",
		"mostGuestName": "House of Blues Anaheim",
		"mostGuestCity": "Anaheim",
		"mostGuestAmount": 41
	},
	{
		"id": "83e8d29",
		"email": "jasonrichardson4415@gmail.com",
		"name": "Jason",
		"createdDate": "2023-05-23 17:35:00",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 221,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 610,
		"schedulePercent": 0.345914,
		"totalGuests": 42,
		"guestPercent": 0.40882,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "The Windjammer",
		"mostGuestCity": "Isle of Palms",
		"mostGuestAmount": 21
	},
	{
		"id": "6072ab1",
		"email": "ppbouard@gmail.com",
		"name": "Pierre-Philippe",
		"createdDate": "2014-07-30 18:05:12",
		"totalOrganizations": 13,
		"totalTours": 9,
		"totalDays": 557,
		"totalCountries": 13,
		"totalStates": 33,
		"totalEvents": 182,
		"eventPercent": 0.773408,
		"totalScheduleItems": 2141,
		"schedulePercent": 0.740564,
		"totalGuests": 428,
		"guestPercent": 0.664018,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "Stereolux",
		"mostGuestCity": "Nantes",
		"mostGuestAmount": 35
	},
	{
		"id": "3d2cf30",
		"email": "sarah.lack@elevationchurch.org",
		"name": "Sarah",
		"createdDate": "2021-11-16 19:11:56",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 52,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 789,
		"schedulePercent": 0.415971,
		"totalGuests": 3514,
		"guestPercent": 0.977751,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "Moody Center",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 314
	},
	{
		"id": "5ba50aa",
		"email": "amoody@edge522.com",
		"name": "Andrerica",
		"createdDate": "2022-07-07 23:19:39",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 398,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 166,
		"eventPercent": 0.74308,
		"totalScheduleItems": 1715,
		"schedulePercent": 0.668653,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c2ddc37",
		"email": "brandoneggleston@gmail.com",
		"name": "Brandon",
		"createdDate": "2011-07-28 05:10:10",
		"totalOrganizations": 14,
		"totalTours": 22,
		"totalDays": 846,
		"totalCountries": 12,
		"totalStates": 69,
		"totalEvents": 334,
		"eventPercent": 0.934843,
		"totalScheduleItems": 4157,
		"schedulePercent": 0.931532,
		"totalGuests": 2048,
		"guestPercent": 0.936962,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 50
	},
	{
		"id": "e861c8e",
		"email": "jessicamlanza@gmail.com",
		"name": "Jessy",
		"createdDate": "2014-09-30 09:06:31",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 189,
		"totalCountries": 9,
		"totalStates": 27,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 322,
		"schedulePercent": 0.219706,
		"totalGuests": 17,
		"guestPercent": 0.362469,
		"mostGuestDate": "2023-04-07 00:00:00",
		"mostGuestName": "Roseland Theater",
		"mostGuestCity": "Portland",
		"mostGuestAmount": 10
	},
	{
		"id": "e3a843a",
		"email": "blakelittell.tm@gmail.com",
		"name": "Blake",
		"createdDate": "2018-09-05 19:31:44",
		"totalOrganizations": 8,
		"totalTours": 20,
		"totalDays": 651,
		"totalCountries": 6,
		"totalStates": 54,
		"totalEvents": 225,
		"eventPercent": 0.842008,
		"totalScheduleItems": 3336,
		"schedulePercent": 0.88187,
		"totalGuests": 1348,
		"guestPercent": 0.877102,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "The Brooklyn Mirage",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 179
	},
	{
		"id": "fc609f4",
		"email": "bretchinquan@me.com",
		"name": "Bret ",
		"createdDate": "2016-09-16 15:52:37",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 312,
		"totalCountries": 9,
		"totalStates": 16,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 719,
		"schedulePercent": 0.387763,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "275172c",
		"email": "dcabreramusic@gmail.com",
		"name": "Damb",
		"createdDate": "2021-11-08 16:21:42",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 272,
		"totalCountries": 3,
		"totalStates": 36,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 1828,
		"schedulePercent": 0.691034,
		"totalGuests": 1353,
		"guestPercent": 0.877765,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 70
	},
	{
		"id": "c620f5d",
		"email": "militariegun@gmail.com",
		"name": "Militarie",
		"createdDate": "2022-09-28 20:10:52",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 123,
		"totalCountries": 8,
		"totalStates": 44,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 730,
		"schedulePercent": 0.392266,
		"totalGuests": 81,
		"guestPercent": 0.455966,
		"mostGuestDate": "2023-02-02 00:00:00",
		"mostGuestName": "Mano Oculta",
		"mostGuestCity": "Portland",
		"mostGuestAmount": 12
	},
	{
		"id": "77ef6bc",
		"email": "tmpmbrad@gmail.com",
		"name": "Bradley",
		"createdDate": "2022-02-22 19:04:55",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 194,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 504,
		"schedulePercent": 0.30049,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-03-09 00:00:00",
		"mostGuestName": "EPIC Event Center",
		"mostGuestCity": "Green Bay",
		"mostGuestAmount": 6
	},
	{
		"id": "515bbe7",
		"email": "info@koch-music.dk",
		"name": "Jonathan",
		"createdDate": "2016-01-25 13:50:28",
		"totalOrganizations": 15,
		"totalTours": 10,
		"totalDays": 284,
		"totalCountries": 5,
		"totalStates": 22,
		"totalEvents": 296,
		"eventPercent": 0.912992,
		"totalScheduleItems": 2222,
		"schedulePercent": 0.754072,
		"totalGuests": 690,
		"guestPercent": 0.751026,
		"mostGuestDate": "2023-04-21 00:00:00",
		"mostGuestName": "Det Kongelige Teater, Gamle Scene",
		"mostGuestCity": "K&#248;benhavn K",
		"mostGuestAmount": 61
	},
	{
		"id": "74b8c9e",
		"email": "dw@danwoolfie.com",
		"name": "Dan",
		"createdDate": "2019-04-30 02:53:02",
		"totalOrganizations": 8,
		"totalTours": 35,
		"totalDays": 348,
		"totalCountries": 17,
		"totalStates": 33,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 3393,
		"schedulePercent": 0.8873,
		"totalGuests": 205,
		"guestPercent": 0.554231,
		"mostGuestDate": "2023-09-29 00:00:00",
		"mostGuestName": "Brighton Dome",
		"mostGuestCity": "Brighton",
		"mostGuestAmount": 48
	},
	{
		"id": "a695ab4",
		"email": "xasltd@hotmail.co.uk",
		"name": "Matthew",
		"createdDate": "2015-05-29 06:56:47",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 139,
		"totalCountries": 10,
		"totalStates": 26,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 704,
		"schedulePercent": 0.382201,
		"totalGuests": 584,
		"guestPercent": 0.720302,
		"mostGuestDate": "2023-05-19 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 81
	},
	{
		"id": "9916759",
		"email": "musik.dk@allthingslive.com",
		"name": "Production",
		"createdDate": "2021-10-28 14:18:25",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 65,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 276,
		"schedulePercent": 0.196001,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "247d484",
		"email": "kellyrussomgmt@gmail.com",
		"name": "Kelly",
		"createdDate": "2018-09-02 20:18:03",
		"totalOrganizations": 12,
		"totalTours": 18,
		"totalDays": 556,
		"totalCountries": 17,
		"totalStates": 64,
		"totalEvents": 198,
		"eventPercent": 0.802543,
		"totalScheduleItems": 3151,
		"schedulePercent": 0.86611,
		"totalGuests": 1728,
		"guestPercent": 0.915243,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 147
	},
	{
		"id": "fc94714",
		"email": "mstevens@fiery-angel.com",
		"name": "Fran",
		"createdDate": "2020-02-03 09:55:21",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 94,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 557,
		"schedulePercent": 0.324725,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f3bacaa",
		"email": "Jhuffaudio@gmail.com",
		"name": "JP",
		"createdDate": "2018-07-14 02:28:42",
		"totalOrganizations": 16,
		"totalTours": 25,
		"totalDays": 1145,
		"totalCountries": 8,
		"totalStates": 63,
		"totalEvents": 434,
		"eventPercent": 0.965303,
		"totalScheduleItems": 7029,
		"schedulePercent": 0.989538,
		"totalGuests": 3007,
		"guestPercent": 0.969408,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "House of Blues Orlando",
		"mostGuestCity": "Lake Buena Vista",
		"mostGuestAmount": 81
	},
	{
		"id": "3ab6779",
		"email": "tmt.green@yahoo.com",
		"name": "Laurie",
		"createdDate": "2012-04-07 17:59:28",
		"totalOrganizations": 4,
		"totalTours": 17,
		"totalDays": 187,
		"totalCountries": 16,
		"totalStates": 48,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 940,
		"schedulePercent": 0.462588,
		"totalGuests": 58,
		"guestPercent": 0.431863,
		"mostGuestDate": "2023-10-31 00:00:00",
		"mostGuestName": "Union Chapel",
		"mostGuestCity": "London",
		"mostGuestAmount": 28
	},
	{
		"id": "15329e8",
		"email": "eric.mayers@redlightmanagement.com",
		"name": "Eric",
		"createdDate": "2016-02-02 21:06:07",
		"totalOrganizations": 31,
		"totalTours": 33,
		"totalDays": 659,
		"totalCountries": 7,
		"totalStates": 48,
		"totalEvents": 351,
		"eventPercent": 0.941862,
		"totalScheduleItems": 3760,
		"schedulePercent": 0.909813,
		"totalGuests": 2081,
		"guestPercent": 0.938816,
		"mostGuestDate": "2023-09-06 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 66
	},
	{
		"id": "603de18",
		"email": "oaklandbass@gmail.com",
		"name": "Joshua",
		"createdDate": "2016-05-09 09:46:00",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 726,
		"totalCountries": 11,
		"totalStates": 43,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 900,
		"schedulePercent": 0.45292,
		"totalGuests": 295,
		"guestPercent": 0.599788,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 128
	},
	{
		"id": "a126be2",
		"email": "steve@thehalfchapter.com",
		"name": "Steven",
		"createdDate": "2017-06-01 11:05:01",
		"totalOrganizations": 8,
		"totalTours": 16,
		"totalDays": 221,
		"totalCountries": 12,
		"totalStates": 37,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 937,
		"schedulePercent": 0.461528,
		"totalGuests": 276,
		"guestPercent": 0.592902,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Forum Melbourne",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 55
	},
	{
		"id": "a832599",
		"email": "anastasiacm@networkstours.com",
		"name": "Company",
		"createdDate": "2021-09-01 20:27:58",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 142,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 1627,
		"schedulePercent": 0.648259,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "26a8d4a",
		"email": "alexpprince@gmail.com",
		"name": "Alexander",
		"createdDate": "2017-04-25 10:34:04",
		"totalOrganizations": 10,
		"totalTours": 7,
		"totalDays": 1042,
		"totalCountries": 6,
		"totalStates": 22,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 1701,
		"schedulePercent": 0.664945,
		"totalGuests": 16,
		"guestPercent": 0.361012,
		"mostGuestDate": "2023-05-26 00:00:00",
		"mostGuestName": "713 Music Hall",
		"mostGuestCity": "Houston",
		"mostGuestAmount": 7
	},
	{
		"id": "5069d5c",
		"email": "chaubard.ch@gmail.com",
		"name": "charlotte",
		"createdDate": "2023-04-17 11:58:58",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 233,
		"totalCountries": 6,
		"totalStates": 22,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 1718,
		"schedulePercent": 0.66905,
		"totalGuests": 1286,
		"guestPercent": 0.870216,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Bell Centre",
		"mostGuestCity": "Montreal",
		"mostGuestAmount": 113
	},
	{
		"id": "d48d8b4",
		"email": "shaggyontheboardz@icloud.com",
		"name": "Chris",
		"createdDate": "2022-06-10 23:36:18",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 75,
		"totalCountries": 3,
		"totalStates": 12,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 381,
		"schedulePercent": 0.249371,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "The Masonic",
		"mostGuestCity": "San Francisco",
		"mostGuestAmount": 1
	},
	{
		"id": "941f119",
		"email": "samantha.simm@greyline.live",
		"name": "Samantha",
		"createdDate": "2023-05-02 17:12:26",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 254,
		"totalCountries": 3,
		"totalStates": 11,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 359,
		"schedulePercent": 0.23732,
		"totalGuests": 136,
		"guestPercent": 0.503377,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Irving Plaza",
		"mostGuestCity": "New York",
		"mostGuestAmount": 27
	},
	{
		"id": "839dc84",
		"email": "nickfbp@gmail.com",
		"name": "Nick",
		"createdDate": "2017-10-10 17:36:19",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 129,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 834,
		"schedulePercent": 0.432658,
		"totalGuests": 200,
		"guestPercent": 0.550258,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 38
	},
	{
		"id": "754f704",
		"email": "mckenzie.meggan@googlemail.com",
		"name": "Meggan",
		"createdDate": "2013-12-03 14:15:00",
		"totalOrganizations": 11,
		"totalTours": 5,
		"totalDays": 61,
		"totalCountries": 7,
		"totalStates": 13,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 496,
		"schedulePercent": 0.296385,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e104aed",
		"email": "Drain831tm@gmail.com",
		"name": "Mark",
		"createdDate": "2023-05-18 14:29:24",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 56,
		"totalCountries": 8,
		"totalStates": 35,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 394,
		"schedulePercent": 0.255066,
		"totalGuests": 766,
		"guestPercent": 0.768243,
		"mostGuestDate": "2023-06-08 00:00:00",
		"mostGuestName": "Brooklyn Monarch",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 80
	},
	{
		"id": "4db67b8",
		"email": "steve@qprime.com",
		"name": "Steve",
		"createdDate": "2010-05-06 17:06:48",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 255,
		"totalCountries": 2,
		"totalStates": 41,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 2281,
		"schedulePercent": 0.762945,
		"totalGuests": 1003,
		"guestPercent": 0.824527,
		"mostGuestDate": "2023-09-27 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 80
	},
	{
		"id": "ab00b52",
		"email": "mark.goodell@gmail.com",
		"name": "Mark",
		"createdDate": "2019-04-02 18:35:11",
		"totalOrganizations": 7,
		"totalTours": 21,
		"totalDays": 367,
		"totalCountries": 12,
		"totalStates": 42,
		"totalEvents": 163,
		"eventPercent": 0.735134,
		"totalScheduleItems": 1428,
		"schedulePercent": 0.598861,
		"totalGuests": 359,
		"guestPercent": 0.634618,
		"mostGuestDate": "2023-03-16 00:00:00",
		"mostGuestName": "The Bowery Ballroom",
		"mostGuestCity": "New York",
		"mostGuestAmount": 33
	},
	{
		"id": "8820dff",
		"email": "mrjosejoaquin@gmail.com",
		"name": "Jose",
		"createdDate": "2015-07-04 15:27:54",
		"totalOrganizations": 13,
		"totalTours": 31,
		"totalDays": 918,
		"totalCountries": 21,
		"totalStates": 82,
		"totalEvents": 432,
		"eventPercent": 0.96464,
		"totalScheduleItems": 5098,
		"schedulePercent": 0.964773,
		"totalGuests": 2275,
		"guestPercent": 0.94663,
		"mostGuestDate": "2023-06-18 00:00:00",
		"mostGuestName": "Red Rocks Park and Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 183
	},
	{
		"id": "6c14b90",
		"email": "joelryanwise@gmail.com",
		"name": "Joel",
		"createdDate": "2022-08-19 19:13:05",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 112,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 344,
		"schedulePercent": 0.230168,
		"totalGuests": 138,
		"guestPercent": 0.504966,
		"mostGuestDate": "2023-01-21 00:00:00",
		"mostGuestName": "Cervantes' Masterpiece Ballroom & Cervantes' Other Side",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 50
	},
	{
		"id": "9f98687",
		"email": "vidxxx@aol.com",
		"name": "David",
		"createdDate": "2011-03-11 23:24:28",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 72,
		"totalCountries": 1,
		"totalStates": 28,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 482,
		"schedulePercent": 0.289631,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "56da780",
		"email": "chrisstinebrink@icloud.com",
		"name": "Chris",
		"createdDate": "2023-05-12 06:05:51",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 168,
		"totalCountries": 5,
		"totalStates": 20,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 1014,
		"schedulePercent": 0.489339,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "600b657",
		"email": "bendavidmusic@gmail.com",
		"name": "Ben",
		"createdDate": "2017-06-03 03:26:23",
		"totalOrganizations": 14,
		"totalTours": 23,
		"totalDays": 656,
		"totalCountries": 10,
		"totalStates": 50,
		"totalEvents": 241,
		"eventPercent": 0.862667,
		"totalScheduleItems": 2594,
		"schedulePercent": 0.806781,
		"totalGuests": 606,
		"guestPercent": 0.728115,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Manning Bar",
		"mostGuestCity": "Camperdown",
		"mostGuestAmount": 63
	},
	{
		"id": "2ab970a",
		"email": "Lowenthalmedia@gmail.com",
		"name": "Michael",
		"createdDate": "2022-08-24 19:39:52",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 720,
		"totalCountries": 6,
		"totalStates": 24,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 705,
		"schedulePercent": 0.383128,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "Vanquish Nightclub",
		"mostGuestCity": "Minneapolis",
		"mostGuestAmount": 4
	},
	{
		"id": "eb4ebf6",
		"email": "mastertour1@mediaresourcegroup.de",
		"name": "MRG",
		"createdDate": "2023-05-26 11:46:55",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 37,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 155,
		"schedulePercent": 0.124354,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "37300dc",
		"email": "pete@illuminationcreative.co.uk",
		"name": "Pete",
		"createdDate": "2021-07-27 10:18:41",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 85,
		"totalCountries": 3,
		"totalStates": 4,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 339,
		"schedulePercent": 0.227122,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c34dc93",
		"email": "rjdclark@gmail.com",
		"name": "Richie ",
		"createdDate": "2015-02-05 17:05:16",
		"totalOrganizations": 15,
		"totalTours": 19,
		"totalDays": 716,
		"totalCountries": 17,
		"totalStates": 55,
		"totalEvents": 255,
		"eventPercent": 0.880943,
		"totalScheduleItems": 3314,
		"schedulePercent": 0.879221,
		"totalGuests": 1515,
		"guestPercent": 0.896173,
		"mostGuestDate": "2023-05-12 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 183
	},
	{
		"id": "734198e",
		"email": "stanleymj@gmail.com",
		"name": "Mike ",
		"createdDate": "2012-06-16 14:52:14",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 364,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1345,
		"schedulePercent": 0.579129,
		"totalGuests": 439,
		"guestPercent": 0.668256,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Coachman Park Amphitheater",
		"mostGuestCity": "Clearwater",
		"mostGuestAmount": 31
	},
	{
		"id": "eff3efa",
		"email": "anthony@keylight.com.au",
		"name": "Anthony",
		"createdDate": "2012-08-06 05:31:54",
		"totalOrganizations": 12,
		"totalTours": 15,
		"totalDays": 306,
		"totalCountries": 14,
		"totalStates": 27,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 1612,
		"schedulePercent": 0.644948,
		"totalGuests": 168,
		"guestPercent": 0.528804,
		"mostGuestDate": "2023-06-18 00:00:00",
		"mostGuestName": "Joan Sutherland Performing Arts Centre",
		"mostGuestCity": "Penrith",
		"mostGuestAmount": 58
	},
	{
		"id": "62d97f2",
		"email": "sam@tommyboytouring.com",
		"name": "Sam",
		"createdDate": "2023-08-13 16:18:33",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 48,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 461,
		"schedulePercent": 0.28142,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ff6a031",
		"email": "mathew.fruitman@coalitionmusic.com",
		"name": "Matthew",
		"createdDate": "2018-06-19 15:12:13",
		"totalOrganizations": 19,
		"totalTours": 15,
		"totalDays": 579,
		"totalCountries": 11,
		"totalStates": 49,
		"totalEvents": 175,
		"eventPercent": 0.761753,
		"totalScheduleItems": 1738,
		"schedulePercent": 0.672361,
		"totalGuests": 545,
		"guestPercent": 0.707324,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 66
	},
	{
		"id": "135f8e8",
		"email": "JEFF@FONOGENIC.COM",
		"name": "JEFF",
		"createdDate": "2022-10-13 23:51:59",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 351,
		"totalCountries": 7,
		"totalStates": 34,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 2095,
		"schedulePercent": 0.734472,
		"totalGuests": 1093,
		"guestPercent": 0.838167,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 104
	},
	{
		"id": "948280c",
		"email": "cory@strangemusicinc.com",
		"name": "Cory",
		"createdDate": "2011-04-26 19:06:13",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 295,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 755,
		"schedulePercent": 0.404715,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-11-15 00:00:00",
		"mostGuestName": "Seminole Hard Rock Hotel & Casino Tampa",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 2
	},
	{
		"id": "032017d",
		"email": "ray.mariner@wmg.com",
		"name": "Ray",
		"createdDate": "2016-07-08 16:03:08",
		"totalOrganizations": 57,
		"totalTours": 51,
		"totalDays": 16692,
		"totalCountries": 16,
		"totalStates": 89,
		"totalEvents": 2347,
		"eventPercent": 0.999073,
		"totalScheduleItems": 6167,
		"schedulePercent": 0.981592,
		"totalGuests": 716,
		"guestPercent": 0.756853,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "South Fork Dirt Riders",
		"mostGuestCity": "Taylorville",
		"mostGuestAmount": 29
	},
	{
		"id": "312f2a9",
		"email": "turnoverva@gmail.com",
		"name": "Daniel",
		"createdDate": "2015-03-14 09:36:18",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 21,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 78,
		"schedulePercent": 0.0736326,
		"totalGuests": 82,
		"guestPercent": 0.457688,
		"mostGuestDate": "2023-06-11 00:00:00",
		"mostGuestName": "The Independent",
		"mostGuestCity": "San Francisco",
		"mostGuestAmount": 34
	},
	{
		"id": "6c3eeef",
		"email": "jonnyjtclark@gmail.com",
		"name": "Jonny",
		"createdDate": "2019-06-12 19:46:16",
		"totalOrganizations": 22,
		"totalTours": 4,
		"totalDays": 109,
		"totalCountries": 4,
		"totalStates": 5,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 278,
		"schedulePercent": 0.196795,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "355b2f8",
		"email": "rriley@cityparksfoundation.org",
		"name": "Rebecca",
		"createdDate": "2022-04-18 15:31:11",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "83dd6c4",
		"email": "a.bully@globalconcerts.de",
		"name": "Bully",
		"createdDate": "2018-01-12 18:16:07",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 20,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 253,
		"schedulePercent": 0.18236,
		"totalGuests": 219,
		"guestPercent": 0.562575,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Mercedes-Benz Arena Berlin",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 57
	},
	{
		"id": "c347fde",
		"email": "descendentstourmanager@gmail.com",
		"name": "Jeff",
		"createdDate": "2016-08-24 05:18:18",
		"totalOrganizations": 10,
		"totalTours": 21,
		"totalDays": 373,
		"totalCountries": 15,
		"totalStates": 43,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 1627,
		"schedulePercent": 0.648259,
		"totalGuests": 884,
		"guestPercent": 0.795259,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "Saint Rocke",
		"mostGuestCity": "Hermosa Beach",
		"mostGuestAmount": 52
	},
	{
		"id": "eeaf86c",
		"email": "spaz.htx@me.com",
		"name": "Dustin",
		"createdDate": "2014-10-17 06:13:11",
		"totalOrganizations": 13,
		"totalTours": 22,
		"totalDays": 526,
		"totalCountries": 19,
		"totalStates": 82,
		"totalEvents": 274,
		"eventPercent": 0.897232,
		"totalScheduleItems": 3464,
		"schedulePercent": 0.891538,
		"totalGuests": 878,
		"guestPercent": 0.794067,
		"mostGuestDate": "2023-09-13 00:00:00",
		"mostGuestName": "Hard Rock Cafe",
		"mostGuestCity": "Orlando",
		"mostGuestAmount": 64
	},
	{
		"id": "76140a5",
		"email": "rick@themavericksmusic.com",
		"name": "Rick ",
		"createdDate": "2018-05-18 23:14:43",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 4,
		"totalStates": 37,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 1608,
		"schedulePercent": 0.644153,
		"totalGuests": 683,
		"guestPercent": 0.74904,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "FirstBank Amphitheater",
		"mostGuestCity": "Franklin",
		"mostGuestAmount": 92
	},
	{
		"id": "23de6f0",
		"email": "steve.tusman@gmail.com",
		"name": "Steve ",
		"createdDate": "2011-05-04 05:19:16",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 3,
		"totalStates": 45,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1593,
		"schedulePercent": 0.641372,
		"totalGuests": 1540,
		"guestPercent": 0.898556,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 57
	},
	{
		"id": "7ff56f7",
		"email": "adam@preamppublicity.com",
		"name": "Adam",
		"createdDate": "2021-06-11 17:17:02",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 398,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 227,
		"schedulePercent": 0.168057,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Soundwell",
		"mostGuestCity": "Salt Lake City",
		"mostGuestAmount": 3
	},
	{
		"id": "6ae9ffb",
		"email": "thelariosmusicfirm@gmail.com",
		"name": "Sergio",
		"createdDate": "2022-11-29 15:09:01",
		"totalOrganizations": 2,
		"totalTours": 26,
		"totalDays": 191,
		"totalCountries": 9,
		"totalStates": 42,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 971,
		"schedulePercent": 0.47252,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b9ddb05",
		"email": "meangirlscm@networkstours.com",
		"name": "Liam",
		"createdDate": "2023-08-01 17:18:52",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 114,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 811,
		"schedulePercent": 0.42352,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "90f5d99",
		"email": "lander.audio@gmail.com",
		"name": "Ross",
		"createdDate": "2017-04-07 02:09:10",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 586,
		"totalCountries": 12,
		"totalStates": 51,
		"totalEvents": 271,
		"eventPercent": 0.895113,
		"totalScheduleItems": 3911,
		"schedulePercent": 0.919348,
		"totalGuests": 215,
		"guestPercent": 0.559529,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The Aztec Theatre",
		"mostGuestCity": "San Antonio",
		"mostGuestAmount": 70
	},
	{
		"id": "e1912c0",
		"email": "companymanager@balletbc.com",
		"name": "Kate",
		"createdDate": "2019-09-04 23:13:56",
		"totalOrganizations": 1,
		"totalTours": 6,
		"totalDays": 105,
		"totalCountries": 4,
		"totalStates": 11,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 983,
		"schedulePercent": 0.476758,
		"totalGuests": 106,
		"guestPercent": 0.480466,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "New York City Center",
		"mostGuestCity": "New York",
		"mostGuestAmount": 16
	},
	{
		"id": "8dddd22",
		"email": "TourManagerBG@gmail.com",
		"name": "Braden",
		"createdDate": "2022-03-31 18:05:28",
		"totalOrganizations": 11,
		"totalTours": 14,
		"totalDays": 1725,
		"totalCountries": 6,
		"totalStates": 58,
		"totalEvents": 379,
		"eventPercent": 0.951,
		"totalScheduleItems": 4102,
		"schedulePercent": 0.927692,
		"totalGuests": 848,
		"guestPercent": 0.787578,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 60
	},
	{
		"id": "8ab7199",
		"email": "plaid.wayne@icloud.com",
		"name": "Gerald ",
		"createdDate": "2021-06-09 17:50:20",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 270,
		"totalCountries": 2,
		"totalStates": 31,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 2261,
		"schedulePercent": 0.760826,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6baf8f8",
		"email": "feizlmayr.rene@gmail.com",
		"name": "Rene",
		"createdDate": "2022-08-25 12:42:50",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 50,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 298,
		"schedulePercent": 0.207257,
		"totalGuests": 369,
		"guestPercent": 0.641107,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "Stadthalle Graz",
		"mostGuestCity": "Graz",
		"mostGuestAmount": 203
	},
	{
		"id": "81f8be0",
		"email": "sofiafolkesson90@gmail.com",
		"name": "Sofia",
		"createdDate": "2022-03-22 10:09:42",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 367,
		"totalCountries": 2,
		"totalStates": 17,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 828,
		"schedulePercent": 0.429744,
		"totalGuests": 240,
		"guestPercent": 0.573434,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "Stortorget",
		"mostGuestCity": "Falkenberg",
		"mostGuestAmount": 39
	},
	{
		"id": "801a502",
		"email": "rachelleahwoliansky@gmail.com",
		"name": "Rachel",
		"createdDate": "2017-03-06 00:14:19",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 243,
		"totalCountries": 11,
		"totalStates": 43,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 1658,
		"schedulePercent": 0.655145,
		"totalGuests": 393,
		"guestPercent": 0.650775,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 57
	},
	{
		"id": "e4688e8",
		"email": "thomaswdrm@gmail.com",
		"name": "Thomas",
		"createdDate": "2022-11-08 19:44:05",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 313,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 1389,
		"schedulePercent": 0.588399,
		"totalGuests": 18,
		"guestPercent": 0.364455,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Tingley Coliseum",
		"mostGuestCity": "Albuquerque",
		"mostGuestAmount": 18
	},
	{
		"id": "f096cc8",
		"email": "jamie@newnavy.com.au",
		"name": "Jamie",
		"createdDate": "2013-10-16 00:11:37",
		"totalOrganizations": 86,
		"totalTours": 77,
		"totalDays": 1820,
		"totalCountries": 17,
		"totalStates": 59,
		"totalEvents": 496,
		"eventPercent": 0.973911,
		"totalScheduleItems": 6252,
		"schedulePercent": 0.982784,
		"totalGuests": 1837,
		"guestPercent": 0.924381,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 121
	},
	{
		"id": "edb5bad",
		"email": "dereklevibrown@gmail.com",
		"name": "Derek",
		"createdDate": "2017-07-20 16:05:36",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 90,
		"totalCountries": 4,
		"totalStates": 31,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 679,
		"schedulePercent": 0.371871,
		"totalGuests": 1284,
		"guestPercent": 0.869421,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "The Tulsa Theater",
		"mostGuestCity": "Tulsa",
		"mostGuestAmount": 147
	},
	{
		"id": "5a0815e",
		"email": "jose.aristizabal@4jts.com",
		"name": "Jose Luis",
		"createdDate": "2012-08-27 14:17:17",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 429,
		"totalCountries": 12,
		"totalStates": 40,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 1146,
		"schedulePercent": 0.525493,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8e6ba13",
		"email": "fulkerson@ttomproductions.com",
		"name": "Abigail",
		"createdDate": "2023-07-19 22:14:26",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 120,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 508,
		"schedulePercent": 0.303139,
		"totalGuests": 405,
		"guestPercent": 0.655145,
		"mostGuestDate": "2023-09-09 00:00:00",
		"mostGuestName": "Radio City Music Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 81
	},
	{
		"id": "f55a13c",
		"email": "Gb79@me.com",
		"name": "Gary ",
		"createdDate": "2013-07-16 14:02:27",
		"totalOrganizations": 4,
		"totalTours": 17,
		"totalDays": 289,
		"totalCountries": 16,
		"totalStates": 59,
		"totalEvents": 137,
		"eventPercent": 0.666402,
		"totalScheduleItems": 2079,
		"schedulePercent": 0.731559,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-03-21 00:00:00",
		"mostGuestName": "The Danforth Music Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 0
	},
	{
		"id": "5085eef",
		"email": "giulia.svartskog@gmail.com",
		"name": "Giulia",
		"createdDate": "2022-08-28 08:42:32",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 46,
		"totalCountries": 9,
		"totalStates": 15,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 380,
		"schedulePercent": 0.249106,
		"totalGuests": 37,
		"guestPercent": 0.400212,
		"mostGuestDate": "2023-11-20 00:00:00",
		"mostGuestName": "O'Sullivans- Backstage By The Mill",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 6
	},
	{
		"id": "cc0adbe",
		"email": "charlesdavisadams@gmail.com",
		"name": "Charlie",
		"createdDate": "2014-01-10 22:26:17",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 34,
		"totalCountries": 1,
		"totalStates": 15,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 322,
		"schedulePercent": 0.219706,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "434090e",
		"email": "melvin.godfrey@gmail.com",
		"name": "Melvin",
		"createdDate": "2011-07-22 02:25:46",
		"totalOrganizations": 16,
		"totalTours": 22,
		"totalDays": 919,
		"totalCountries": 10,
		"totalStates": 44,
		"totalEvents": 333,
		"eventPercent": 0.934181,
		"totalScheduleItems": 4153,
		"schedulePercent": 0.9314,
		"totalGuests": 1361,
		"guestPercent": 0.878692,
		"mostGuestDate": "2023-03-14 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 115
	},
	{
		"id": "b687b42",
		"email": "jennifer.pepke@icloud.com",
		"name": "Jennifer",
		"createdDate": "2016-04-16 22:21:18",
		"totalOrganizations": 11,
		"totalTours": 10,
		"totalDays": 551,
		"totalCountries": 12,
		"totalStates": 43,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 3512,
		"schedulePercent": 0.894848,
		"totalGuests": 1567,
		"guestPercent": 0.900146,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 98
	},
	{
		"id": "3e12204",
		"email": "Ryanmcaleenan.mp@gmail.com",
		"name": "Ryan",
		"createdDate": "2022-07-05 16:31:04",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 51,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 179,
		"schedulePercent": 0.137598,
		"totalGuests": 60,
		"guestPercent": 0.434644,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Fallsview Casino Resort",
		"mostGuestCity": "Niagara Falls",
		"mostGuestAmount": 36
	},
	{
		"id": "9152d67",
		"email": "averdelle@cityparksfoundation.org",
		"name": "Ailey",
		"createdDate": "2019-05-23 20:25:02",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e9ff403",
		"email": "j.blaesi@allstage.de",
		"name": "Jorge",
		"createdDate": "2022-10-18 17:37:35",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 35,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 50,
		"schedulePercent": 0.0540326,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bea1c3d",
		"email": "carlbellmusic123@gmail.com",
		"name": "Carl",
		"createdDate": "2021-07-13 21:13:35",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 47,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 241,
		"schedulePercent": 0.1764,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Stage AE",
		"mostGuestCity": "Pittsburgh",
		"mostGuestAmount": 5
	},
	{
		"id": "25f5b88",
		"email": "amanda@starfishentertainment.com",
		"name": "Amanda",
		"createdDate": "2014-01-10 16:31:57",
		"totalOrganizations": 11,
		"totalTours": 8,
		"totalDays": 382,
		"totalCountries": 9,
		"totalStates": 51,
		"totalEvents": 283,
		"eventPercent": 0.904516,
		"totalScheduleItems": 1274,
		"schedulePercent": 0.557674,
		"totalGuests": 50,
		"guestPercent": 0.421798,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "The Grove Theatre",
		"mostGuestCity": "Fenelon Falls",
		"mostGuestAmount": 15
	},
	{
		"id": "a258e3d",
		"email": "jeremychilds604@gmail.com",
		"name": "Jeremy",
		"createdDate": "2022-07-28 20:03:58",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 282,
		"totalCountries": 9,
		"totalStates": 12,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 123,
		"schedulePercent": 0.105019,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ec7f11e",
		"email": "maxrstewart@gmail.com",
		"name": "Max",
		"createdDate": "2020-12-02 01:36:28",
		"totalOrganizations": 6,
		"totalTours": 14,
		"totalDays": 386,
		"totalCountries": 6,
		"totalStates": 42,
		"totalEvents": 156,
		"eventPercent": 0.718051,
		"totalScheduleItems": 1372,
		"schedulePercent": 0.585883,
		"totalGuests": 536,
		"guestPercent": 0.70494,
		"mostGuestDate": "2023-06-27 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 43
	},
	{
		"id": "1db2ac1",
		"email": "nathaliabeard@gmail.com",
		"name": "Nathalia",
		"createdDate": "2021-07-12 19:00:19",
		"totalOrganizations": 13,
		"totalTours": 15,
		"totalDays": 530,
		"totalCountries": 12,
		"totalStates": 65,
		"totalEvents": 265,
		"eventPercent": 0.890743,
		"totalScheduleItems": 3110,
		"schedulePercent": 0.8628,
		"totalGuests": 855,
		"guestPercent": 0.788637,
		"mostGuestDate": "2023-09-25 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 122
	},
	{
		"id": "b526d3a",
		"email": "theonlyhomeiknow@gmail.com",
		"name": "Greg ",
		"createdDate": "2015-09-11 18:38:42",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 119,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 824,
		"schedulePercent": 0.427758,
		"totalGuests": 594,
		"guestPercent": 0.724672,
		"mostGuestDate": "2023-06-13 00:00:00",
		"mostGuestName": "(Le) Poisson Rouge",
		"mostGuestCity": "New York",
		"mostGuestAmount": 29
	},
	{
		"id": "7ea853a",
		"email": "g.tomlinson105@gmail.com",
		"name": "Garrett",
		"createdDate": "2023-02-16 21:02:17",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 42,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 264,
		"schedulePercent": 0.189114,
		"totalGuests": 102,
		"guestPercent": 0.477155,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "The Echo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 40
	},
	{
		"id": "ebd5c2f",
		"email": "tourmike@comcast.net",
		"name": "Mike",
		"createdDate": "2012-06-12 19:25:43",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 345,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 2343,
		"schedulePercent": 0.773275,
		"totalGuests": 2070,
		"guestPercent": 0.938021,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Paycom Center",
		"mostGuestCity": "Oklahoma City",
		"mostGuestAmount": 167
	},
	{
		"id": "a8ee94f",
		"email": "simfos@me.com",
		"name": "Simon",
		"createdDate": "2017-02-12 10:58:07",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 194,
		"totalCountries": 9,
		"totalStates": 41,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1950,
		"schedulePercent": 0.711826,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e70ce1c",
		"email": "MATT@FINDERSPICTURES.COM",
		"name": "MATT",
		"createdDate": "2019-12-18 10:52:17",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 281,
		"totalCountries": 3,
		"totalStates": 18,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 750,
		"schedulePercent": 0.402198,
		"totalGuests": 33,
		"guestPercent": 0.394252,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Agua Caliente Casino",
		"mostGuestCity": "Rancho Mirage",
		"mostGuestAmount": 14
	},
	{
		"id": "cbbd194",
		"email": "violaine.phi@gmail.com",
		"name": "Violaine",
		"createdDate": "2022-12-16 18:14:28",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 79,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 779,
		"schedulePercent": 0.412396,
		"totalGuests": 335,
		"guestPercent": 0.620712,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "Ancienne Belgique",
		"mostGuestCity": "Brussel",
		"mostGuestAmount": 27
	},
	{
		"id": "a6d88df",
		"email": "thewildelectric@gmail.com",
		"name": "Devin",
		"createdDate": "2022-10-31 16:33:15",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 295,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 174,
		"schedulePercent": 0.135346,
		"totalGuests": 21,
		"guestPercent": 0.371342,
		"mostGuestDate": "2023-08-05 00:00:00",
		"mostGuestName": "KEMBA Live!",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 10
	},
	{
		"id": "5afda0b",
		"email": "info@heartstopmusic.com",
		"name": "Heartstop",
		"createdDate": "2016-06-27 05:27:42",
		"totalOrganizations": 12,
		"totalTours": 11,
		"totalDays": 198,
		"totalCountries": 7,
		"totalStates": 35,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 872,
		"schedulePercent": 0.443915,
		"totalGuests": 704,
		"guestPercent": 0.754072,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "Christchurch Town Hall of the Performing Arts",
		"mostGuestCity": "Christchurch",
		"mostGuestAmount": 93
	},
	{
		"id": "0ebbf0c",
		"email": "dakotaw744@gmail.com",
		"name": "Dakota",
		"createdDate": "2021-10-19 15:00:08",
		"totalOrganizations": 9,
		"totalTours": 3,
		"totalDays": 157,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1032,
		"schedulePercent": 0.492782,
		"totalGuests": 588,
		"guestPercent": 0.722024,
		"mostGuestDate": "2023-04-27 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 189
	},
	{
		"id": "33e3b6e",
		"email": "ryandanielmccauley@gmail.com",
		"name": "Ryan",
		"createdDate": "2013-01-22 22:00:21",
		"totalOrganizations": 18,
		"totalTours": 21,
		"totalDays": 662,
		"totalCountries": 10,
		"totalStates": 53,
		"totalEvents": 244,
		"eventPercent": 0.865713,
		"totalScheduleItems": 3807,
		"schedulePercent": 0.911667,
		"totalGuests": 1421,
		"guestPercent": 0.884783,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "The Town Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 49
	},
	{
		"id": "dde4d5e",
		"email": "rehabtouring@gmail.com",
		"name": "Elizabeth ",
		"createdDate": "2022-02-02 21:26:43",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 69,
		"totalCountries": 1,
		"totalStates": 33,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 25,
		"schedulePercent": 0.036419,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0875693",
		"email": "emmasedgar@gmail.com",
		"name": "Emma",
		"createdDate": "2014-02-26 10:24:46",
		"totalOrganizations": 12,
		"totalTours": 25,
		"totalDays": 693,
		"totalCountries": 17,
		"totalStates": 44,
		"totalEvents": 186,
		"eventPercent": 0.782016,
		"totalScheduleItems": 2773,
		"schedulePercent": 0.828632,
		"totalGuests": 215,
		"guestPercent": 0.559529,
		"mostGuestDate": "2023-03-14 00:00:00",
		"mostGuestName": "Brisbane Entertainment Centre",
		"mostGuestCity": "Boondall",
		"mostGuestAmount": 32
	},
	{
		"id": "be7cf50",
		"email": "saramcdonaldbooking@gmail.com",
		"name": "Sara",
		"createdDate": "2023-06-17 18:45:20",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 21,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 161,
		"schedulePercent": 0.127135,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "Joshua Tree Music Festival",
		"mostGuestCity": "Joshua Tree",
		"mostGuestAmount": 2
	},
	{
		"id": "732601b",
		"email": "609brandon@gmail.com",
		"name": "Brandon",
		"createdDate": "2011-06-15 16:20:21",
		"totalOrganizations": 12,
		"totalTours": 13,
		"totalDays": 1116,
		"totalCountries": 19,
		"totalStates": 64,
		"totalEvents": 284,
		"eventPercent": 0.905708,
		"totalScheduleItems": 4928,
		"schedulePercent": 0.960138,
		"totalGuests": 3084,
		"guestPercent": 0.970335,
		"mostGuestDate": "2023-08-27 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 151
	},
	{
		"id": "c35cea5",
		"email": "jeff.pitt@me.com",
		"name": "Jeff",
		"createdDate": "2012-01-05 19:29:23",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 154,
		"totalCountries": 4,
		"totalStates": 42,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1307,
		"schedulePercent": 0.568534,
		"totalGuests": 571,
		"guestPercent": 0.716991,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "The Pacific Amphitheatre",
		"mostGuestCity": "Costa Mesa",
		"mostGuestAmount": 42
	},
	{
		"id": "5520a4a",
		"email": "ian_robert_laidlaw@yahoo.com.au",
		"name": "Ian",
		"createdDate": "2018-09-06 05:56:27",
		"totalOrganizations": 8,
		"totalTours": 33,
		"totalDays": 553,
		"totalCountries": 11,
		"totalStates": 43,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 1822,
		"schedulePercent": 0.689313,
		"totalGuests": 934,
		"guestPercent": 0.808105,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 243
	},
	{
		"id": "fee640d",
		"email": "adzforeverz@gmail.com",
		"name": "Adam",
		"createdDate": "2017-08-16 18:20:18",
		"totalOrganizations": 17,
		"totalTours": 11,
		"totalDays": 770,
		"totalCountries": 19,
		"totalStates": 62,
		"totalEvents": 240,
		"eventPercent": 0.86174,
		"totalScheduleItems": 4267,
		"schedulePercent": 0.936565,
		"totalGuests": 960,
		"guestPercent": 0.815786,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 187
	},
	{
		"id": "409d972",
		"email": "joeydiehlsound@gmail.com",
		"name": "Joseph",
		"createdDate": "2015-09-02 22:07:08",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 3,
		"totalStates": 42,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 2781,
		"schedulePercent": 0.829691,
		"totalGuests": 2379,
		"guestPercent": 0.949808,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 86
	},
	{
		"id": "e87ade6",
		"email": "rebecca@e1fx.com",
		"name": "Rebecca",
		"createdDate": "2022-09-14 18:15:37",
		"totalOrganizations": 29,
		"totalTours": 28,
		"totalDays": 430,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 256,
		"eventPercent": 0.882135,
		"totalScheduleItems": 318,
		"schedulePercent": 0.217322,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-01-08 00:00:00",
		"mostGuestName": "TIAA Bank Field",
		"mostGuestCity": "Jacksonville",
		"mostGuestAmount": 0
	},
	{
		"id": "bdd6922",
		"email": "daniellebeck11@yahoo.com",
		"name": "Danielle",
		"createdDate": "2022-09-03 19:26:14",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 189,
		"totalCountries": 2,
		"totalStates": 39,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1425,
		"schedulePercent": 0.597802,
		"totalGuests": 1486,
		"guestPercent": 0.892465,
		"mostGuestDate": "2023-01-29 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 229
	},
	{
		"id": "cdd9016",
		"email": "isaac@ripple-effect.co",
		"name": "Isaac",
		"createdDate": "2022-03-11 00:01:11",
		"totalOrganizations": 13,
		"totalTours": 26,
		"totalDays": 1778,
		"totalCountries": 7,
		"totalStates": 18,
		"totalEvents": 184,
		"eventPercent": 0.77791,
		"totalScheduleItems": 1354,
		"schedulePercent": 0.581777,
		"totalGuests": 102,
		"guestPercent": 0.477155,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "Lansdowne Hotel",
		"mostGuestCity": "Chippendale",
		"mostGuestAmount": 18
	},
	{
		"id": "a032899",
		"email": "matthew.oberst@gmail.com",
		"name": "Matthew",
		"createdDate": "2011-03-02 02:21:08",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 55,
		"totalCountries": 4,
		"totalStates": 10,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 159,
		"schedulePercent": 0.126208,
		"totalGuests": 155,
		"guestPercent": 0.51768,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Northcote Theatre",
		"mostGuestCity": "Northcote",
		"mostGuestAmount": 47
	},
	{
		"id": "5368409",
		"email": "mikezld@icloud.com",
		"name": "Michael",
		"createdDate": "2022-08-22 18:41:37",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 361,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 293,
		"schedulePercent": 0.204609,
		"totalGuests": 134,
		"guestPercent": 0.501258,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "House of Blues Chicago",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 43
	},
	{
		"id": "0318439",
		"email": "suzinmoon@me.com",
		"name": "Suzin",
		"createdDate": "2018-11-19 20:55:35",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 248,
		"totalCountries": 2,
		"totalStates": 36,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 1860,
		"schedulePercent": 0.697391,
		"totalGuests": 409,
		"guestPercent": 0.657264,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "iTHINK Financial Amphitheatre",
		"mostGuestCity": "West Palm Beach",
		"mostGuestAmount": 84
	},
	{
		"id": "fd3b8f5",
		"email": "antforbes12@gmail.com",
		"name": "Ant",
		"createdDate": "2013-03-22 16:18:32",
		"totalOrganizations": 13,
		"totalTours": 6,
		"totalDays": 238,
		"totalCountries": 5,
		"totalStates": 7,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 441,
		"schedulePercent": 0.272944,
		"totalGuests": 413,
		"guestPercent": 0.659383,
		"mostGuestDate": "2023-03-04 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 166
	},
	{
		"id": "27f3eb6",
		"email": "frederik@cphmusic.dk",
		"name": "Frederik",
		"createdDate": "2017-03-01 13:27:02",
		"totalOrganizations": 133,
		"totalTours": 61,
		"totalDays": 1860,
		"totalCountries": 10,
		"totalStates": 31,
		"totalEvents": 1655,
		"eventPercent": 0.993511,
		"totalScheduleItems": 8918,
		"schedulePercent": 0.995762,
		"totalGuests": 774,
		"guestPercent": 0.770494,
		"mostGuestDate": "2023-03-12 00:00:00",
		"mostGuestName": "Verti Music Hall",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 63
	},
	{
		"id": "4607f6d",
		"email": "Clintrorie@gmail.com",
		"name": "Clint",
		"createdDate": "2015-09-14 16:31:55",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 755,
		"totalCountries": 9,
		"totalStates": 49,
		"totalEvents": 330,
		"eventPercent": 0.931665,
		"totalScheduleItems": 2594,
		"schedulePercent": 0.806781,
		"totalGuests": 1068,
		"guestPercent": 0.834591,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Lincoln Financial Field",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 163
	},
	{
		"id": "d660840",
		"email": "cathy@rcam.com",
		"name": "Cathy",
		"createdDate": "2012-06-05 18:05:09",
		"totalOrganizations": 16,
		"totalTours": 9,
		"totalDays": 154,
		"totalCountries": 4,
		"totalStates": 28,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 1286,
		"schedulePercent": 0.562177,
		"totalGuests": 933,
		"guestPercent": 0.80784,
		"mostGuestDate": "2023-06-04 00:00:00",
		"mostGuestName": "Central Park SummerStage",
		"mostGuestCity": "New York",
		"mostGuestAmount": 74
	},
	{
		"id": "99dfb6d",
		"email": "garrettwburke@gmail.com",
		"name": "Garrett",
		"createdDate": "2019-11-04 21:22:31",
		"totalOrganizations": 5,
		"totalTours": 11,
		"totalDays": 238,
		"totalCountries": 11,
		"totalStates": 35,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 655,
		"schedulePercent": 0.363793,
		"totalGuests": 73,
		"guestPercent": 0.447755,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Troxy",
		"mostGuestCity": "London",
		"mostGuestAmount": 48
	},
	{
		"id": "7e9fcfa",
		"email": "daniel@mercyme.org",
		"name": "Daniel",
		"createdDate": "2014-04-17 14:04:35",
		"totalOrganizations": 4,
		"totalTours": 10,
		"totalDays": 161,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1746,
		"schedulePercent": 0.67395,
		"totalGuests": 1213,
		"guestPercent": 0.859621,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "State Farm Arena",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 133
	},
	{
		"id": "ee1ef01",
		"email": "cartervaughan@icloud.com",
		"name": "Carter",
		"createdDate": "2020-01-07 11:18:20",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 273,
		"totalCountries": 4,
		"totalStates": 34,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 1937,
		"schedulePercent": 0.708648,
		"totalGuests": 443,
		"guestPercent": 0.669315,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 75
	},
	{
		"id": "647ebc2",
		"email": "josh@ooshaudio.com",
		"name": "Joshua",
		"createdDate": "2023-01-19 03:38:20",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 432,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 651,
		"schedulePercent": 0.362601,
		"totalGuests": 97,
		"guestPercent": 0.471461,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Powerstation",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 16
	},
	{
		"id": "9cd4fe6",
		"email": "paul@reverse-thread.com",
		"name": "Paul",
		"createdDate": "2011-10-07 21:00:06",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 205,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 896,
		"schedulePercent": 0.452126,
		"totalGuests": 581,
		"guestPercent": 0.719243,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Bill Graham Civic Auditorium",
		"mostGuestCity": "San Francisco",
		"mostGuestAmount": 102
	},
	{
		"id": "3c58ef3",
		"email": "krichman@comcast.net",
		"name": "Kenneth",
		"createdDate": "2013-11-19 02:22:31",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 68,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 408,
		"schedulePercent": 0.260363,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3826c8a",
		"email": "chiara@whiteowltribe.com",
		"name": "Chiara",
		"createdDate": "2022-06-02 00:19:33",
		"totalOrganizations": 1,
		"totalTours": 8,
		"totalDays": 441,
		"totalCountries": 6,
		"totalStates": 22,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 1088,
		"schedulePercent": 0.509469,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ab0efbc",
		"email": "martin@associatedlondonmanagement.com",
		"name": "Martin",
		"createdDate": "2018-05-16 21:16:19",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 186,
		"totalCountries": 21,
		"totalStates": 53,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 1545,
		"schedulePercent": 0.628658,
		"totalGuests": 747,
		"guestPercent": 0.76374,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 61
	},
	{
		"id": "18bbe51",
		"email": "jacina@goldleaftouring.co",
		"name": "Jacina",
		"createdDate": "2023-01-16 23:41:48",
		"totalOrganizations": 1,
		"totalTours": 8,
		"totalDays": 247,
		"totalCountries": 5,
		"totalStates": 12,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 820,
		"schedulePercent": 0.426566,
		"totalGuests": 106,
		"guestPercent": 0.480466,
		"mostGuestDate": "2023-02-23 00:00:00",
		"mostGuestName": "Irving Plaza",
		"mostGuestCity": "New York",
		"mostGuestAmount": 59
	},
	{
		"id": "9fd44e6",
		"email": "nessingerleo@gmail.com",
		"name": "Leo",
		"createdDate": "2018-08-25 21:25:20",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 247,
		"totalCountries": 4,
		"totalStates": 29,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 1325,
		"schedulePercent": 0.573037,
		"totalGuests": 555,
		"guestPercent": 0.711296,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "del Lago Resort & Casino",
		"mostGuestCity": "Waterloo",
		"mostGuestAmount": 39
	},
	{
		"id": "6c2c95b",
		"email": "admin@skillettouring.com",
		"name": "Skillet",
		"createdDate": "2019-04-30 14:52:37",
		"totalOrganizations": 7,
		"totalTours": 2,
		"totalDays": 491,
		"totalCountries": 13,
		"totalStates": 54,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 2519,
		"schedulePercent": 0.795656,
		"totalGuests": 1137,
		"guestPercent": 0.846908,
		"mostGuestDate": "2023-02-21 00:00:00",
		"mostGuestName": "Eagles Ballroom",
		"mostGuestCity": "Milwaukee",
		"mostGuestAmount": 63
	},
	{
		"id": "73eae9d",
		"email": "eviluncletimmy@yahoo.com",
		"name": "Tim",
		"createdDate": "2014-10-23 19:27:15",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 600,
		"totalCountries": 11,
		"totalStates": 49,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 1416,
		"schedulePercent": 0.594888,
		"totalGuests": 1202,
		"guestPercent": 0.857502,
		"mostGuestDate": "2023-01-04 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 94
	},
	{
		"id": "15d882c",
		"email": "tourmgr1@me.com",
		"name": "Guy",
		"createdDate": "2014-04-26 16:19:53",
		"totalOrganizations": 14,
		"totalTours": 10,
		"totalDays": 336,
		"totalCountries": 14,
		"totalStates": 52,
		"totalEvents": 168,
		"eventPercent": 0.747451,
		"totalScheduleItems": 3246,
		"schedulePercent": 0.873792,
		"totalGuests": 1463,
		"guestPercent": 0.889286,
		"mostGuestDate": "2023-08-30 00:00:00",
		"mostGuestName": "Hollywood Casino Amphitheatre",
		"mostGuestCity": "Tinley Park",
		"mostGuestAmount": 122
	},
	{
		"id": "a240f19",
		"email": "saraparsonspa@gmail.com",
		"name": "Sara",
		"createdDate": "2017-05-30 22:34:52",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 108,
		"totalCountries": 12,
		"totalStates": 33,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 623,
		"schedulePercent": 0.350682,
		"totalGuests": 947,
		"guestPercent": 0.811813,
		"mostGuestDate": "2023-04-03 00:00:00",
		"mostGuestName": "Royal Albert Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 102
	},
	{
		"id": "d9c881f",
		"email": "constance@worksentertainment.com",
		"name": "Constance",
		"createdDate": "2016-10-03 20:22:07",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 636,
		"totalCountries": 4,
		"totalStates": 43,
		"totalEvents": 205,
		"eventPercent": 0.814859,
		"totalScheduleItems": 2601,
		"schedulePercent": 0.80784,
		"totalGuests": 549,
		"guestPercent": 0.709707,
		"mostGuestDate": "2023-01-05 00:00:00",
		"mostGuestName": "The Chicago Theatre",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 63
	},
	{
		"id": "1bb6258",
		"email": "crimsonmgt@gmail.com",
		"name": "Leah",
		"createdDate": "2010-07-08 23:02:34",
		"totalOrganizations": 10,
		"totalTours": 21,
		"totalDays": 914,
		"totalCountries": 3,
		"totalStates": 36,
		"totalEvents": 185,
		"eventPercent": 0.780029,
		"totalScheduleItems": 3699,
		"schedulePercent": 0.907032,
		"totalGuests": 379,
		"guestPercent": 0.64455,
		"mostGuestDate": "2023-03-20 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 21
	},
	{
		"id": "743d06f",
		"email": "Nick@nickfogel.com",
		"name": "Nick",
		"createdDate": "2022-05-27 14:56:34",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 520,
		"totalCountries": 1,
		"totalStates": 44,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1953,
		"schedulePercent": 0.712621,
		"totalGuests": 3346,
		"guestPercent": 0.974838,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 185
	},
	{
		"id": "92b30f1",
		"email": "anton.tm2022@gmail.com",
		"name": "Anton",
		"createdDate": "2023-01-12 10:47:47",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 270,
		"totalCountries": 14,
		"totalStates": 38,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 1428,
		"schedulePercent": 0.598861,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0860b3d",
		"email": "marie.boisteau@icloud.com",
		"name": "Marie",
		"createdDate": "2022-02-17 17:48:40",
		"totalOrganizations": 3,
		"totalTours": 13,
		"totalDays": 476,
		"totalCountries": 16,
		"totalStates": 41,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 1904,
		"schedulePercent": 0.704807,
		"totalGuests": 185,
		"guestPercent": 0.539664,
		"mostGuestDate": "2023-07-06 00:00:00",
		"mostGuestName": "Cabaret Sauvage",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 47
	},
	{
		"id": "7b2e4ef",
		"email": "CHUBZMIXEDIT@GMAIL.COM",
		"name": "Chubz",
		"createdDate": "2023-03-27 23:36:41",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 123,
		"totalCountries": 1,
		"totalStates": 27,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 351,
		"schedulePercent": 0.233611,
		"totalGuests": 19,
		"guestPercent": 0.366971,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "The Regency Ballroom",
		"mostGuestCity": "SF",
		"mostGuestAmount": 17
	},
	{
		"id": "c26c58d",
		"email": "angela@LTAgroup.org",
		"name": "Angela",
		"createdDate": "2016-05-02 18:32:35",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 26,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 263,
		"schedulePercent": 0.187922,
		"totalGuests": 45,
		"guestPercent": 0.412925,
		"mostGuestDate": "2023-02-19 00:00:00",
		"mostGuestName": "The Pullo Center",
		"mostGuestCity": "York",
		"mostGuestAmount": 17
	},
	{
		"id": "dd00782",
		"email": "rivieremanon@gmail.com",
		"name": "Manon",
		"createdDate": "2018-05-23 10:33:22",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 186,
		"totalCountries": 6,
		"totalStates": 16,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 1158,
		"schedulePercent": 0.529069,
		"totalGuests": 939,
		"guestPercent": 0.809297,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "ArÃ¨nes de NÃ®mes",
		"mostGuestCity": "NÃ®mes",
		"mostGuestAmount": 92
	},
	{
		"id": "6dd2887",
		"email": "les@lmaudio.co.uk",
		"name": "Leslie",
		"createdDate": "2023-03-22 10:42:03",
		"totalOrganizations": 2,
		"totalTours": 8,
		"totalDays": 103,
		"totalCountries": 8,
		"totalStates": 29,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 790,
		"schedulePercent": 0.416501,
		"totalGuests": 50,
		"guestPercent": 0.421798,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "The Masonic Lodge at Hollywood Forever",
		"mostGuestCity": "",
		"mostGuestAmount": 14
	},
	{
		"id": "4fff6ac",
		"email": "cturcios@dmani.com",
		"name": "Carlos ",
		"createdDate": "2017-06-09 00:15:49",
		"totalOrganizations": 10,
		"totalTours": 9,
		"totalDays": 508,
		"totalCountries": 13,
		"totalStates": 45,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 1342,
		"schedulePercent": 0.578334,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "dba9416",
		"email": "Kronik@imgstrongarm.com",
		"name": "Michael",
		"createdDate": "2015-05-18 18:37:17",
		"totalOrganizations": 1,
		"totalTours": 11,
		"totalDays": 129,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 927,
		"schedulePercent": 0.45835,
		"totalGuests": 57,
		"guestPercent": 0.430804,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Rod Laver Arena",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 23
	},
	{
		"id": "eb5cb6f",
		"email": "Tobin.k.b@gmail.com",
		"name": "Luka",
		"createdDate": "2017-10-15 19:12:36",
		"totalOrganizations": 11,
		"totalTours": 12,
		"totalDays": 441,
		"totalCountries": 11,
		"totalStates": 44,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 2174,
		"schedulePercent": 0.745861,
		"totalGuests": 1082,
		"guestPercent": 0.83671,
		"mostGuestDate": "2023-07-09 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 76
	},
	{
		"id": "289a36b",
		"email": "lauren.mschorr@gmail.com",
		"name": "Lauren",
		"createdDate": "2022-06-07 01:37:43",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 1105,
		"totalCountries": 5,
		"totalStates": 43,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 2275,
		"schedulePercent": 0.76268,
		"totalGuests": 1632,
		"guestPercent": 0.906105,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Waterfront Park",
		"mostGuestCity": "Burlington",
		"mostGuestAmount": 78
	},
	{
		"id": "22396be",
		"email": "pastache@85southshow.com",
		"name": "Pastache",
		"createdDate": "2021-07-07 20:50:13",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 1376,
		"totalCountries": 1,
		"totalStates": 24,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 2291,
		"schedulePercent": 0.764005,
		"totalGuests": 485,
		"guestPercent": 0.685207,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Macon Centreplex- Coliseum",
		"mostGuestCity": "Macon",
		"mostGuestAmount": 51
	},
	{
		"id": "3773e7c",
		"email": "briancrouch22@gmail.com",
		"name": "Brian",
		"createdDate": "2019-06-05 03:12:58",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 229,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 1111,
		"schedulePercent": 0.513972,
		"totalGuests": 750,
		"guestPercent": 0.764402,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 87
	},
	{
		"id": "4684cfc",
		"email": "info@bookyourshow.fr",
		"name": "SEVERINE",
		"createdDate": "2023-10-19 10:18:03",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 50,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 115,
		"schedulePercent": 0.0993246,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c8b72fb",
		"email": "kathaparolo@gmail.com",
		"name": "Katharina",
		"createdDate": "2018-08-10 20:03:51",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 501,
		"totalCountries": 5,
		"totalStates": 16,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 965,
		"schedulePercent": 0.469607,
		"totalGuests": 21,
		"guestPercent": 0.371342,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "Palais 12",
		"mostGuestCity": "Bruxelles",
		"mostGuestAmount": 19
	},
	{
		"id": "e9452fe",
		"email": "dan@anothermgmtco.com",
		"name": "Dan",
		"createdDate": "2021-08-17 15:47:46",
		"totalOrganizations": 8,
		"totalTours": 11,
		"totalDays": 185,
		"totalCountries": 8,
		"totalStates": 42,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1213,
		"schedulePercent": 0.543372,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-05-02 00:00:00",
		"mostGuestName": "The Woodward Theater",
		"mostGuestCity": "Cincinnati",
		"mostGuestAmount": 14
	},
	{
		"id": "e1e7c95",
		"email": "clancy@auxx.co",
		"name": "Clancy",
		"createdDate": "2013-10-08 19:31:03",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 370,
		"totalCountries": 6,
		"totalStates": 19,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 1433,
		"schedulePercent": 0.600053,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b34cd47",
		"email": "zoe.duplech@gmail.com",
		"name": "Zo&#233;",
		"createdDate": "2022-06-01 21:57:07",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 56,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 180,
		"schedulePercent": 0.138392,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0d3cb51",
		"email": "aegfrenchteam@gmail.com",
		"name": "AEG",
		"createdDate": "2023-06-01 13:16:33",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 204,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 379,
		"schedulePercent": 0.248311,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-06-28 00:00:00",
		"mostGuestName": "Major Bay Festival",
		"mostGuestCity": "",
		"mostGuestAmount": 15
	},
	{
		"id": "6caffd1",
		"email": "christian.korber@touringpeople.de",
		"name": "Christian",
		"createdDate": "2019-02-06 14:00:38",
		"totalOrganizations": 7,
		"totalTours": 18,
		"totalDays": 233,
		"totalCountries": 12,
		"totalStates": 42,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 2211,
		"schedulePercent": 0.751556,
		"totalGuests": 1220,
		"guestPercent": 0.860681,
		"mostGuestDate": "2023-07-19 00:00:00",
		"mostGuestName": "Schlossplatz Stuttgart",
		"mostGuestCity": "Stuttgart",
		"mostGuestAmount": 214
	},
	{
		"id": "211ff3a",
		"email": "poweronpete@me.com",
		"name": "Pete",
		"createdDate": "2015-01-05 13:05:16",
		"totalOrganizations": 11,
		"totalTours": 27,
		"totalDays": 583,
		"totalCountries": 12,
		"totalStates": 65,
		"totalEvents": 197,
		"eventPercent": 0.800821,
		"totalScheduleItems": 2382,
		"schedulePercent": 0.778043,
		"totalGuests": 264,
		"guestPercent": 0.58628,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "O2 Shepherd's Bush Empire",
		"mostGuestCity": "London",
		"mostGuestAmount": 49
	},
	{
		"id": "a2fc39d",
		"email": "jgiffordtouring@gmail.com",
		"name": "Jeremy",
		"createdDate": "2020-01-18 21:37:35",
		"totalOrganizations": 13,
		"totalTours": 9,
		"totalDays": 1115,
		"totalCountries": 4,
		"totalStates": 39,
		"totalEvents": 245,
		"eventPercent": 0.867435,
		"totalScheduleItems": 4130,
		"schedulePercent": 0.929413,
		"totalGuests": 3280,
		"guestPercent": 0.974308,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 298
	},
	{
		"id": "100cd7b",
		"email": "hello@lisagreve.de",
		"name": "Lisa",
		"createdDate": "2023-08-16 15:05:39",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 30,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 241,
		"schedulePercent": 0.1764,
		"totalGuests": 307,
		"guestPercent": 0.607072,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "E-Werk",
		"mostGuestCity": "Koln",
		"mostGuestAmount": 82
	},
	{
		"id": "ac3bcc5",
		"email": "Hwtourmanager@gmail.com",
		"name": "Patrick",
		"createdDate": "2022-04-15 19:54:42",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 214,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 600,
		"schedulePercent": 0.340882,
		"totalGuests": 18,
		"guestPercent": 0.364455,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "Wildwood Smokehouse & Saloon",
		"mostGuestCity": "Iowa City",
		"mostGuestAmount": 18
	},
	{
		"id": "75b8c16",
		"email": "clangord@gmail.com",
		"name": "Chelsea",
		"createdDate": "2015-09-24 21:09:39",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 180,
		"totalCountries": 2,
		"totalStates": 43,
		"totalEvents": 154,
		"eventPercent": 0.71368,
		"totalScheduleItems": 2202,
		"schedulePercent": 0.750497,
		"totalGuests": 897,
		"guestPercent": 0.799232,
		"mostGuestDate": "2023-12-23 00:00:00",
		"mostGuestName": "Orpheum Theater",
		"mostGuestCity": "Omaha",
		"mostGuestAmount": 74
	},
	{
		"id": "32e7776",
		"email": "hotwaxbandd@gmail.com",
		"name": "Steve",
		"createdDate": "2023-09-08 10:42:50",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 209,
		"totalCountries": 6,
		"totalStates": 15,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 1106,
		"schedulePercent": 0.513045,
		"totalGuests": 334,
		"guestPercent": 0.620183,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "Zebulon",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 56
	},
	{
		"id": "4c06342",
		"email": "tm@xavierrudd.com",
		"name": "Dan",
		"createdDate": "2021-03-15 23:43:56",
		"totalOrganizations": 6,
		"totalTours": 19,
		"totalDays": 401,
		"totalCountries": 4,
		"totalStates": 42,
		"totalEvents": 187,
		"eventPercent": 0.784135,
		"totalScheduleItems": 1686,
		"schedulePercent": 0.661237,
		"totalGuests": 984,
		"guestPercent": 0.820421,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 70
	},
	{
		"id": "cb3aa83",
		"email": "david@ganjawhitenight.com",
		"name": "David",
		"createdDate": "2018-06-27 20:09:00",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 65,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 265,
		"schedulePercent": 0.189511,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a19620d",
		"email": "tuotanto.happoradio@gmail.com",
		"name": "Kiertue",
		"createdDate": "2019-05-15 13:00:21",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 69,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 369,
		"schedulePercent": 0.242749,
		"totalGuests": 246,
		"guestPercent": 0.576612,
		"mostGuestDate": "2023-09-01 00:00:00",
		"mostGuestName": "Tavara-asema",
		"mostGuestCity": "Tampere",
		"mostGuestAmount": 36
	},
	{
		"id": "79401a2",
		"email": "eric440@gmail.com",
		"name": "Eric",
		"createdDate": "2017-12-06 16:41:33",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 172,
		"totalCountries": 3,
		"totalStates": 11,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 816,
		"schedulePercent": 0.425109,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2c6d2bc",
		"email": "mollycliona@gmail.com",
		"name": "Molly",
		"createdDate": "2014-03-21 12:01:48",
		"totalOrganizations": 11,
		"totalTours": 8,
		"totalDays": 485,
		"totalCountries": 14,
		"totalStates": 45,
		"totalEvents": 148,
		"eventPercent": 0.696332,
		"totalScheduleItems": 3506,
		"schedulePercent": 0.894451,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d9499ce",
		"email": "ptptourpsm@networkstours.com",
		"name": "Ashlynn",
		"createdDate": "2023-10-10 20:50:46",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 64,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 345,
		"schedulePercent": 0.23083,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d6fcd91",
		"email": "lowriderbooking@yahoo.com",
		"name": "Simon",
		"createdDate": "2014-09-08 17:15:43",
		"totalOrganizations": 23,
		"totalTours": 13,
		"totalDays": 455,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 1080,
		"schedulePercent": 0.507747,
		"totalGuests": 763,
		"guestPercent": 0.767316,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Minnesota State Fair",
		"mostGuestCity": "St Paul",
		"mostGuestAmount": 65
	},
	{
		"id": "2aa4490",
		"email": "marc@musicsoup.com",
		"name": "Marc",
		"createdDate": "2023-04-11 20:15:35",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 327,
		"totalCountries": 9,
		"totalStates": 58,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1165,
		"schedulePercent": 0.530526,
		"totalGuests": 145,
		"guestPercent": 0.509734,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "Lincoln Hall",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 23
	},
	{
		"id": "9940a20",
		"email": "tracyjoymetz@gmail.com",
		"name": "Tracy",
		"createdDate": "2012-04-25 14:52:54",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 341,
		"totalCountries": 13,
		"totalStates": 39,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 795,
		"schedulePercent": 0.41862,
		"totalGuests": 126,
		"guestPercent": 0.495564,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "Toyosu PIT",
		"mostGuestCity": "",
		"mostGuestAmount": 20
	},
	{
		"id": "7b3d1f6",
		"email": "Malmcewen@hotmail.com",
		"name": "Mark",
		"createdDate": "2018-10-01 07:42:04",
		"totalOrganizations": 15,
		"totalTours": 51,
		"totalDays": 504,
		"totalCountries": 11,
		"totalStates": 27,
		"totalEvents": 155,
		"eventPercent": 0.716197,
		"totalScheduleItems": 2094,
		"schedulePercent": 0.73434,
		"totalGuests": 614,
		"guestPercent": 0.72997,
		"mostGuestDate": "2023-02-09 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 76
	},
	{
		"id": "9ea4f8a",
		"email": "r23.ryang@gmail.com",
		"name": "Ryan",
		"createdDate": "2016-08-03 18:12:06",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 773,
		"totalCountries": 3,
		"totalStates": 47,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 2041,
		"schedulePercent": 0.725599,
		"totalGuests": 612,
		"guestPercent": 0.72944,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "Pompano Beach Amphitheatre",
		"mostGuestCity": "Pompano Beach",
		"mostGuestAmount": 39
	},
	{
		"id": "2ffde1e",
		"email": "business@therockcity.org",
		"name": "Lesley ",
		"createdDate": "2022-04-07 02:15:54",
		"totalOrganizations": 1,
		"totalTours": 35,
		"totalDays": 71,
		"totalCountries": 1,
		"totalStates": 18,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 403,
		"schedulePercent": 0.258376,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "339671a",
		"email": "robagaetano@gmail.com",
		"name": "Rob",
		"createdDate": "2019-11-09 10:49:08",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 487,
		"totalCountries": 3,
		"totalStates": 51,
		"totalEvents": 381,
		"eventPercent": 0.952059,
		"totalScheduleItems": 3811,
		"schedulePercent": 0.912197,
		"totalGuests": 58,
		"guestPercent": 0.431863,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "Kauffman Center for the Performing Arts",
		"mostGuestCity": "Kansas City",
		"mostGuestAmount": 18
	},
	{
		"id": "67a326a",
		"email": "nicholemjensen@gmail.com",
		"name": "Nichole",
		"createdDate": "2018-01-23 23:20:46",
		"totalOrganizations": 7,
		"totalTours": 15,
		"totalDays": 456,
		"totalCountries": 22,
		"totalStates": 67,
		"totalEvents": 156,
		"eventPercent": 0.718051,
		"totalScheduleItems": 1935,
		"schedulePercent": 0.708118,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "82c1872",
		"email": "bokassaprod@gmail.com",
		"name": "Eirik",
		"createdDate": "2022-10-16 23:06:58",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 243,
		"totalCountries": 6,
		"totalStates": 11,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 315,
		"schedulePercent": 0.215733,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "Vervenfestivalen",
		"mostGuestCity": "Horten",
		"mostGuestAmount": 4
	},
	{
		"id": "c6979dc",
		"email": "teigengayse@gmail.com",
		"name": "Teigen ",
		"createdDate": "2023-05-21 19:48:42",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 65,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 430,
		"schedulePercent": 0.268176,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "NewRoads Performing Arts Centre",
		"mostGuestCity": "Newmarket",
		"mostGuestAmount": 12
	},
	{
		"id": "11e3513",
		"email": "McKinleyTM2019@gmail.com",
		"name": "Raymond",
		"createdDate": "2019-05-20 19:29:42",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 729,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 1631,
		"schedulePercent": 0.649318,
		"totalGuests": 204,
		"guestPercent": 0.553834,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "FivePoint Amphitheatre",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 33
	},
	{
		"id": "fbbe9d2",
		"email": "Izawinul@gmail.com",
		"name": "Ivan",
		"createdDate": "2021-09-17 10:57:23",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 212,
		"totalCountries": 4,
		"totalStates": 6,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 273,
		"schedulePercent": 0.194676,
		"totalGuests": 13,
		"guestPercent": 0.352536,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "Porgy & Bess",
		"mostGuestCity": "Wien",
		"mostGuestAmount": 10
	},
	{
		"id": "e3f15cf",
		"email": "kyleigh@thesteadfastco.com",
		"name": "Kyleigh",
		"createdDate": "2021-09-14 14:18:14",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 604,
		"totalCountries": 5,
		"totalStates": 38,
		"totalEvents": 219,
		"eventPercent": 0.836048,
		"totalScheduleItems": 2046,
		"schedulePercent": 0.727321,
		"totalGuests": 982,
		"guestPercent": 0.820024,
		"mostGuestDate": "2023-11-21 00:00:00",
		"mostGuestName": "Wildhorse Saloon",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 283
	},
	{
		"id": "da7d1de",
		"email": "kaylor4@msn.com",
		"name": "John",
		"createdDate": "2015-04-03 15:52:36",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 182,
		"totalCountries": 11,
		"totalStates": 41,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 1838,
		"schedulePercent": 0.693286,
		"totalGuests": 2123,
		"guestPercent": 0.940405,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Hard Rock Live",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 221
	},
	{
		"id": "9d8729d",
		"email": "davewagner@me.com",
		"name": "Dave",
		"createdDate": "2011-07-12 20:14:55",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 568,
		"totalCountries": 3,
		"totalStates": 39,
		"totalEvents": 232,
		"eventPercent": 0.852073,
		"totalScheduleItems": 3238,
		"schedulePercent": 0.872997,
		"totalGuests": 2716,
		"guestPercent": 0.961594,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Gas South Arena",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 157
	},
	{
		"id": "c6bd203",
		"email": "jonas@badtasteempire.com",
		"name": "Jonas",
		"createdDate": "2018-05-31 12:37:55",
		"totalOrganizations": 6,
		"totalTours": 14,
		"totalDays": 583,
		"totalCountries": 11,
		"totalStates": 23,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 1091,
		"schedulePercent": 0.510264,
		"totalGuests": 119,
		"guestPercent": 0.489604,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "Debaser Strand",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 31
	},
	{
		"id": "1c37fae",
		"email": "chrisgreentm@gmail.com",
		"name": "Chris",
		"createdDate": "2012-04-19 11:29:44",
		"totalOrganizations": 20,
		"totalTours": 6,
		"totalDays": 247,
		"totalCountries": 15,
		"totalStates": 59,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 1829,
		"schedulePercent": 0.691299,
		"totalGuests": 803,
		"guestPercent": 0.77738,
		"mostGuestDate": "2023-10-31 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 85
	},
	{
		"id": "0e0405b",
		"email": "jschwartz@cityparksfoundation.org",
		"name": "Justin",
		"createdDate": "2022-06-02 16:32:14",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "dcd14e0",
		"email": "embragg@gmail.com",
		"name": "Emily",
		"createdDate": "2011-02-06 04:11:22",
		"totalOrganizations": 15,
		"totalTours": 9,
		"totalDays": 990,
		"totalCountries": 9,
		"totalStates": 51,
		"totalEvents": 203,
		"eventPercent": 0.811681,
		"totalScheduleItems": 4455,
		"schedulePercent": 0.943848,
		"totalGuests": 3464,
		"guestPercent": 0.976295,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "Bayfront Festival Park",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 162
	},
	{
		"id": "9c6f22b",
		"email": "Joshuarhead@gmail.com",
		"name": "Josh",
		"createdDate": "2023-03-17 20:45:48",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 79,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 261,
		"schedulePercent": 0.185936,
		"totalGuests": 233,
		"guestPercent": 0.569726,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 52
	},
	{
		"id": "295ba12",
		"email": "jbeste91@gmail.com",
		"name": "Jeremy",
		"createdDate": "2018-02-28 04:49:24",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 384,
		"totalCountries": 10,
		"totalStates": 42,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1581,
		"schedulePercent": 0.636869,
		"totalGuests": 216,
		"guestPercent": 0.560058,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 50
	},
	{
		"id": "d98bcc4",
		"email": "veerdhaniram@gmail.com",
		"name": "Veer",
		"createdDate": "2016-11-21 02:22:28",
		"totalOrganizations": 3,
		"totalTours": 30,
		"totalDays": 335,
		"totalCountries": 10,
		"totalStates": 52,
		"totalEvents": 154,
		"eventPercent": 0.71368,
		"totalScheduleItems": 1845,
		"schedulePercent": 0.694345,
		"totalGuests": 977,
		"guestPercent": 0.818567,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 70
	},
	{
		"id": "81bc30f",
		"email": "hairstontouringproductionsllc@gmail.com",
		"name": "Bryan",
		"createdDate": "2018-10-03 22:24:19",
		"totalOrganizations": 9,
		"totalTours": 9,
		"totalDays": 1234,
		"totalCountries": 6,
		"totalStates": 41,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 1734,
		"schedulePercent": 0.671699,
		"totalGuests": 107,
		"guestPercent": 0.480996,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "The Echo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 35
	},
	{
		"id": "647048d",
		"email": "josh@invisiblemgmt.com",
		"name": "Joshua",
		"createdDate": "2019-04-17 04:25:32",
		"totalOrganizations": 12,
		"totalTours": 14,
		"totalDays": 1098,
		"totalCountries": 19,
		"totalStates": 56,
		"totalEvents": 463,
		"eventPercent": 0.96954,
		"totalScheduleItems": 5148,
		"schedulePercent": 0.966097,
		"totalGuests": 6934,
		"guestPercent": 0.997616,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Wembley Stadium",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 625
	},
	{
		"id": "156ee28",
		"email": "julia.hansson@demogroup.se",
		"name": "Julia",
		"createdDate": "2022-01-27 09:24:04",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 141,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 461,
		"schedulePercent": 0.28142,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9f00eb2",
		"email": "DavidZoglioDT@gmail.com",
		"name": "David",
		"createdDate": "2014-09-17 21:58:58",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 503,
		"totalCountries": 21,
		"totalStates": 62,
		"totalEvents": 160,
		"eventPercent": 0.72838,
		"totalScheduleItems": 2479,
		"schedulePercent": 0.790359,
		"totalGuests": 1360,
		"guestPercent": 0.878559,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Broncos Stadium at Mile High Station",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 255
	},
	{
		"id": "55d423f",
		"email": "vkhaft99@gmail.com",
		"name": "Victoria",
		"createdDate": "2021-09-04 18:42:49",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 488,
		"totalCountries": 2,
		"totalStates": 33,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 2112,
		"schedulePercent": 0.737121,
		"totalGuests": 736,
		"guestPercent": 0.760959,
		"mostGuestDate": "2023-04-08 00:00:00",
		"mostGuestName": "Crescent Ballroom",
		"mostGuestCity": "Phoenix",
		"mostGuestAmount": 44
	},
	{
		"id": "9c0791c",
		"email": "clay@jr-adventures.com",
		"name": "Clay",
		"createdDate": "2012-05-30 15:01:03",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 129,
		"totalCountries": 2,
		"totalStates": 36,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 610,
		"schedulePercent": 0.345914,
		"totalGuests": 148,
		"guestPercent": 0.51225,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Bomhard Theater",
		"mostGuestCity": "Louisville",
		"mostGuestAmount": 17
	},
	{
		"id": "5d25427",
		"email": "pjfouts@yahoo.com",
		"name": "PJ",
		"createdDate": "2023-05-24 02:23:07",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 194,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 83,
		"schedulePercent": 0.0778705,
		"totalGuests": 65,
		"guestPercent": 0.440207,
		"mostGuestDate": "2023-07-03 00:00:00",
		"mostGuestName": "Island Grove Event Center",
		"mostGuestCity": "Greeley",
		"mostGuestAmount": 12
	},
	{
		"id": "037c2da",
		"email": "thomasjrowles@gmail.com",
		"name": "Tom",
		"createdDate": "2019-11-09 15:39:25",
		"totalOrganizations": 12,
		"totalTours": 9,
		"totalDays": 1270,
		"totalCountries": 6,
		"totalStates": 58,
		"totalEvents": 268,
		"eventPercent": 0.893127,
		"totalScheduleItems": 3607,
		"schedulePercent": 0.901073,
		"totalGuests": 954,
		"guestPercent": 0.813667,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 86
	},
	{
		"id": "57e1a85",
		"email": "andrewscott@geeklifemedia.com",
		"name": "Andrew",
		"createdDate": "2021-10-19 21:57:04",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 300,
		"totalCountries": 10,
		"totalStates": 55,
		"totalEvents": 157,
		"eventPercent": 0.720567,
		"totalScheduleItems": 2198,
		"schedulePercent": 0.749172,
		"totalGuests": 1331,
		"guestPercent": 0.875513,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 74
	},
	{
		"id": "89b092f",
		"email": "thomas@killbeatrepeat.com",
		"name": "Thomas",
		"createdDate": "2020-01-02 11:40:49",
		"totalOrganizations": 16,
		"totalTours": 12,
		"totalDays": 94,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 456,
		"schedulePercent": 0.279168,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7e50330",
		"email": "davy.gordon1984@gmail.com",
		"name": "Davy",
		"createdDate": "2015-02-26 22:01:32",
		"totalOrganizations": 15,
		"totalTours": 11,
		"totalDays": 1169,
		"totalCountries": 10,
		"totalStates": 64,
		"totalEvents": 331,
		"eventPercent": 0.932327,
		"totalScheduleItems": 4313,
		"schedulePercent": 0.938419,
		"totalGuests": 3167,
		"guestPercent": 0.972586,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "Forest Hills Stadium",
		"mostGuestCity": "Forest Hills",
		"mostGuestAmount": 184
	},
	{
		"id": "93f5a24",
		"email": "jallison@concertswest.com",
		"name": "Jim",
		"createdDate": "2021-11-15 06:08:09",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 158,
		"totalCountries": 6,
		"totalStates": 32,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1673,
		"schedulePercent": 0.658588,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "445bff4",
		"email": "missemh@hotmail.com",
		"name": "Emma",
		"createdDate": "2016-09-19 05:10:32",
		"totalOrganizations": 14,
		"totalTours": 29,
		"totalDays": 186,
		"totalCountries": 11,
		"totalStates": 38,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 782,
		"schedulePercent": 0.413588,
		"totalGuests": 137,
		"guestPercent": 0.504039,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Hollywood Forever",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 41
	},
	{
		"id": "6e83915",
		"email": "helena.y.jeen@gmail.com",
		"name": "Helena",
		"createdDate": "2023-06-27 14:17:20",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 276,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 1363,
		"schedulePercent": 0.583631,
		"totalGuests": 1186,
		"guestPercent": 0.854721,
		"mostGuestDate": "2023-09-07 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 118
	},
	{
		"id": "32cf217",
		"email": "forevarwavy27@gmail.com",
		"name": "Diego",
		"createdDate": "2021-09-08 18:42:22",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 38,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 210,
		"schedulePercent": 0.156006,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "93615f8",
		"email": "kristy@kadencegroup.com.au",
		"name": "Kristy",
		"createdDate": "2018-02-15 13:30:57",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 192,
		"totalCountries": 5,
		"totalStates": 17,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 668,
		"schedulePercent": 0.368296,
		"totalGuests": 1043,
		"guestPercent": 0.831545,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Rockhampton Riverfront Precinct",
		"mostGuestCity": "Rockhampton",
		"mostGuestAmount": 388
	},
	{
		"id": "f33a1f1",
		"email": "gtlo.tourbus@gmail.com",
		"name": "GTLO",
		"createdDate": "2022-03-10 22:01:09",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 179,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 1335,
		"schedulePercent": 0.576215,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ca79a9f",
		"email": "cfeimstertm@gmail.com",
		"name": "Connor",
		"createdDate": "2022-07-13 20:20:56",
		"totalOrganizations": 6,
		"totalTours": 9,
		"totalDays": 165,
		"totalCountries": 5,
		"totalStates": 32,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 1077,
		"schedulePercent": 0.506423,
		"totalGuests": 430,
		"guestPercent": 0.664548,
		"mostGuestDate": "2023-03-16 00:00:00",
		"mostGuestName": "El Rey Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 56
	},
	{
		"id": "17e209f",
		"email": "bm@cmp.live",
		"name": "Bert",
		"createdDate": "2022-03-10 18:30:04",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 176,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 1951,
		"schedulePercent": 0.712091,
		"totalGuests": 1102,
		"guestPercent": 0.839756,
		"mostGuestDate": "2023-07-19 00:00:00",
		"mostGuestName": "Schlossplatz Stuttgart",
		"mostGuestCity": "Stuttgart",
		"mostGuestAmount": 214
	},
	{
		"id": "65b8042",
		"email": "info.oskarwithak@gmail.com",
		"name": "Oskar",
		"createdDate": "2022-08-25 21:53:09",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 208,
		"totalCountries": 9,
		"totalStates": 39,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 933,
		"schedulePercent": 0.460734,
		"totalGuests": 92,
		"guestPercent": 0.466163,
		"mostGuestDate": "2023-08-08 00:00:00",
		"mostGuestName": "State Theatre",
		"mostGuestCity": "Portland",
		"mostGuestAmount": 23
	},
	{
		"id": "74fff36",
		"email": "candicerukes@me.com",
		"name": "Candice",
		"createdDate": "2015-09-30 15:44:16",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 72,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 82,
		"schedulePercent": 0.0770759,
		"totalGuests": 86,
		"guestPercent": 0.460204,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Knuckleheads Saloon - The Garage",
		"mostGuestCity": "Kansas City",
		"mostGuestAmount": 73
	},
	{
		"id": "be6ac8b",
		"email": "danelle@starfishentertainment.com",
		"name": "Danelle",
		"createdDate": "2022-11-01 14:55:31",
		"totalOrganizations": 13,
		"totalTours": 10,
		"totalDays": 495,
		"totalCountries": 10,
		"totalStates": 53,
		"totalEvents": 343,
		"eventPercent": 0.938551,
		"totalScheduleItems": 1793,
		"schedulePercent": 0.683751,
		"totalGuests": 844,
		"guestPercent": 0.786651,
		"mostGuestDate": "2023-09-04 00:00:00",
		"mostGuestName": "PNE Forum",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 71
	},
	{
		"id": "3df1f1d",
		"email": "angelwarren@gmail.com",
		"name": "Angel",
		"createdDate": "2022-11-27 02:29:50",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 47,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 410,
		"schedulePercent": 0.261422,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "46bd17b",
		"email": "teenagebottlerockettm@gmail.com",
		"name": "Des",
		"createdDate": "2021-08-12 07:20:24",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 96,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 596,
		"schedulePercent": 0.338763,
		"totalGuests": 274,
		"guestPercent": 0.590783,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "The Oriental Theater",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 39
	},
	{
		"id": "1027017",
		"email": "stephen@eastcitymanagement.com",
		"name": "Stephen",
		"createdDate": "2010-10-13 18:38:47",
		"totalOrganizations": 13,
		"totalTours": 4,
		"totalDays": 434,
		"totalCountries": 12,
		"totalStates": 38,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 1619,
		"schedulePercent": 0.646934,
		"totalGuests": 73,
		"guestPercent": 0.447755,
		"mostGuestDate": "2023-11-15 00:00:00",
		"mostGuestName": "Music Hall of Williamsburg",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 43
	},
	{
		"id": "36e67f0",
		"email": "jaimee.thein@gmail.com",
		"name": "Jaimee",
		"createdDate": "2015-11-08 03:30:40",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 602,
		"schedulePercent": 0.342471,
		"totalGuests": 495,
		"guestPercent": 0.689975,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Julian B Lane Riverfront Park",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 92
	},
	{
		"id": "cd6f45d",
		"email": "riotindustriesllc@gmail.com",
		"name": "Donovan",
		"createdDate": "2023-07-27 00:45:25",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 54,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 165,
		"schedulePercent": 0.129917,
		"totalGuests": 126,
		"guestPercent": 0.495564,
		"mostGuestDate": "2023-11-22 00:00:00",
		"mostGuestName": "The Palace Theatre",
		"mostGuestCity": "Calgary",
		"mostGuestAmount": 32
	},
	{
		"id": "92f48ae",
		"email": "info@vnvnation.com",
		"name": "Ronan",
		"createdDate": "2016-10-01 20:05:34",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 318,
		"totalCountries": 14,
		"totalStates": 34,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 537,
		"schedulePercent": 0.316514,
		"totalGuests": 127,
		"guestPercent": 0.497153,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 29
	},
	{
		"id": "1f619db",
		"email": "Leland.wilson21@gmail.com",
		"name": "Leland ",
		"createdDate": "2019-05-14 16:56:46",
		"totalOrganizations": 19,
		"totalTours": 11,
		"totalDays": 1590,
		"totalCountries": 9,
		"totalStates": 52,
		"totalEvents": 238,
		"eventPercent": 0.860019,
		"totalScheduleItems": 3888,
		"schedulePercent": 0.918024,
		"totalGuests": 244,
		"guestPercent": 0.575288,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "Yankee Stadium",
		"mostGuestCity": "Bronx",
		"mostGuestAmount": 46
	},
	{
		"id": "d3c0b2d",
		"email": "jen.ochej@gmail.com",
		"name": "Jen",
		"createdDate": "2017-03-14 17:40:14",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 381,
		"totalCountries": 6,
		"totalStates": 23,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 486,
		"schedulePercent": 0.291352,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-10-31 00:00:00",
		"mostGuestName": "(Le) Poisson Rouge",
		"mostGuestCity": "New York",
		"mostGuestAmount": 4
	},
	{
		"id": "c99ae7c",
		"email": "tiitta@showtekniikka.fi",
		"name": "Tommi",
		"createdDate": "2020-11-11 10:06:00",
		"totalOrganizations": 2,
		"totalTours": 8,
		"totalDays": 144,
		"totalCountries": 13,
		"totalStates": 27,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 1072,
		"schedulePercent": 0.505364,
		"totalGuests": 154,
		"guestPercent": 0.51662,
		"mostGuestDate": "2023-04-24 00:00:00",
		"mostGuestName": "Tapiola Festival",
		"mostGuestCity": "Espoo",
		"mostGuestAmount": 18
	},
	{
		"id": "58760a9",
		"email": "savageaudio@me.com",
		"name": "Michael",
		"createdDate": "2011-08-20 15:38:52",
		"totalOrganizations": 9,
		"totalTours": 3,
		"totalDays": 169,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 1037,
		"schedulePercent": 0.494107,
		"totalGuests": 504,
		"guestPercent": 0.693021,
		"mostGuestDate": "2023-04-15 00:00:00",
		"mostGuestName": "St. Augustine Amphitheatre",
		"mostGuestCity": "St. Augustine",
		"mostGuestAmount": 104
	},
	{
		"id": "49aabaa",
		"email": "anomalie@anomaliebeats.com",
		"name": "Nicolas",
		"createdDate": "2017-09-10 15:16:57",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 65,
		"totalCountries": 6,
		"totalStates": 10,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 210,
		"schedulePercent": 0.156006,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-05-23 00:00:00",
		"mostGuestName": " Empathy Center Empathy Hall",
		"mostGuestCity": "Seoul",
		"mostGuestAmount": 5
	},
	{
		"id": "312ce14",
		"email": "allychapmanmusic@gmail.com",
		"name": "Allison",
		"createdDate": "2019-05-15 06:20:18",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 190,
		"totalCountries": 10,
		"totalStates": 37,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 971,
		"schedulePercent": 0.47252,
		"totalGuests": 503,
		"guestPercent": 0.692756,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 50
	},
	{
		"id": "da45ecd",
		"email": "lynette@djjazzyjeff.com",
		"name": "Lynette",
		"createdDate": "2023-05-31 17:38:58",
		"totalOrganizations": 3,
		"totalTours": 15,
		"totalDays": 175,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 637,
		"schedulePercent": 0.357171,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-09-03 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 6
	},
	{
		"id": "4315d8e",
		"email": "nich.obb@gmail.com",
		"name": "Nich",
		"createdDate": "2014-08-11 20:19:54",
		"totalOrganizations": 11,
		"totalTours": 19,
		"totalDays": 685,
		"totalCountries": 2,
		"totalStates": 45,
		"totalEvents": 240,
		"eventPercent": 0.86174,
		"totalScheduleItems": 4221,
		"schedulePercent": 0.934711,
		"totalGuests": 4432,
		"guestPercent": 0.987551,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 298
	},
	{
		"id": "f6b2d14",
		"email": "todd@cvamgt.com",
		"name": "Email",
		"createdDate": "2018-05-15 17:08:46",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 99,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 271,
		"schedulePercent": 0.192822,
		"totalGuests": 104,
		"guestPercent": 0.478877,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "Southern Alberta Jubilee Auditorium",
		"mostGuestCity": "Calgary",
		"mostGuestAmount": 18
	},
	{
		"id": "65a51a5",
		"email": "Logistic@aol.com",
		"name": "John",
		"createdDate": "2009-07-22 15:49:21",
		"totalOrganizations": 14,
		"totalTours": 11,
		"totalDays": 326,
		"totalCountries": 8,
		"totalStates": 34,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1597,
		"schedulePercent": 0.642431,
		"totalGuests": 392,
		"guestPercent": 0.650113,
		"mostGuestDate": "2023-04-05 00:00:00",
		"mostGuestName": "Forsyth Barr Stadium",
		"mostGuestCity": "Dunedin",
		"mostGuestAmount": 32
	},
	{
		"id": "a73db9d",
		"email": "jimmykaz1@gmail.com",
		"name": "James",
		"createdDate": "2012-01-16 19:44:16",
		"totalOrganizations": 15,
		"totalTours": 10,
		"totalDays": 1127,
		"totalCountries": 17,
		"totalStates": 49,
		"totalEvents": 195,
		"eventPercent": 0.796318,
		"totalScheduleItems": 2907,
		"schedulePercent": 0.842802,
		"totalGuests": 968,
		"guestPercent": 0.816978,
		"mostGuestDate": "2023-02-09 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 76
	},
	{
		"id": "63b908b",
		"email": "info@smallbonemanagement.com",
		"name": "Ashley",
		"createdDate": "2012-09-06 16:14:49",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 730,
		"totalCountries": 7,
		"totalStates": 43,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 1287,
		"schedulePercent": 0.562839,
		"totalGuests": 1913,
		"guestPercent": 0.929678,
		"mostGuestDate": "2023-12-09 00:00:00",
		"mostGuestName": "The Orion Amphitheater",
		"mostGuestCity": "Huntsville",
		"mostGuestAmount": 109
	},
	{
		"id": "d22ebfb",
		"email": "kenny@tourprologic.com",
		"name": "Kenny",
		"createdDate": "2023-05-24 14:35:42",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 191,
		"totalCountries": 1,
		"totalStates": 35,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 1238,
		"schedulePercent": 0.549331,
		"totalGuests": 69,
		"guestPercent": 0.445107,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "Goodyear Theater",
		"mostGuestCity": "Akron",
		"mostGuestAmount": 17
	},
	{
		"id": "daeff36",
		"email": "emcwaller@gmail.com",
		"name": "Emily",
		"createdDate": "2016-11-04 03:59:15",
		"totalOrganizations": 16,
		"totalTours": 17,
		"totalDays": 1056,
		"totalCountries": 14,
		"totalStates": 54,
		"totalEvents": 179,
		"eventPercent": 0.769037,
		"totalScheduleItems": 2355,
		"schedulePercent": 0.774732,
		"totalGuests": 152,
		"guestPercent": 0.515693,
		"mostGuestDate": "2023-09-12 00:00:00",
		"mostGuestName": "9:30 Club",
		"mostGuestCity": "Washington",
		"mostGuestAmount": 25
	},
	{
		"id": "d7e82fc",
		"email": "beer.alicia@gmail.com",
		"name": "Alicia",
		"createdDate": "2014-04-28 19:39:40",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 267,
		"totalCountries": 14,
		"totalStates": 25,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1120,
		"schedulePercent": 0.516223,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "58b951f",
		"email": "rob@showchimp.com",
		"name": "Rob",
		"createdDate": "2015-06-13 05:46:50",
		"totalOrganizations": 21,
		"totalTours": 10,
		"totalDays": 753,
		"totalCountries": 5,
		"totalStates": 54,
		"totalEvents": 412,
		"eventPercent": 0.960535,
		"totalScheduleItems": 5151,
		"schedulePercent": 0.966362,
		"totalGuests": 1128,
		"guestPercent": 0.844259,
		"mostGuestDate": "2023-12-06 00:00:00",
		"mostGuestName": "Terrace Theatre",
		"mostGuestCity": "Long Beach",
		"mostGuestAmount": 73
	},
	{
		"id": "69708dd",
		"email": "stephanie.johnson@redlightmanagement.com",
		"name": "Stephanie",
		"createdDate": "2017-02-16 17:44:46",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 370,
		"totalCountries": 3,
		"totalStates": 37,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 2130,
		"schedulePercent": 0.738843,
		"totalGuests": 2136,
		"guestPercent": 0.940935,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Mattress Firm Amphitheatre",
		"mostGuestCity": "Chula Vista",
		"mostGuestAmount": 136
	},
	{
		"id": "86376ae",
		"email": "iharo@me.com",
		"name": "Ivan",
		"createdDate": "2018-07-30 19:21:28",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 808,
		"totalCountries": 3,
		"totalStates": 46,
		"totalEvents": 227,
		"eventPercent": 0.844391,
		"totalScheduleItems": 1408,
		"schedulePercent": 0.593299,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 7
	},
	{
		"id": "3f24486",
		"email": "lara@banishedmusic.com",
		"name": "Lara",
		"createdDate": "2023-02-14 20:38:15",
		"totalOrganizations": 60,
		"totalTours": 64,
		"totalDays": 475,
		"totalCountries": 14,
		"totalStates": 38,
		"totalEvents": 239,
		"eventPercent": 0.860681,
		"totalScheduleItems": 2651,
		"schedulePercent": 0.814462,
		"totalGuests": 2327,
		"guestPercent": 0.948484,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Powerstation",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 88
	},
	{
		"id": "f1951e0",
		"email": "katie@daislive.com",
		"name": "Katie",
		"createdDate": "2019-10-04 00:47:50",
		"totalOrganizations": 8,
		"totalTours": 14,
		"totalDays": 1202,
		"totalCountries": 7,
		"totalStates": 53,
		"totalEvents": 373,
		"eventPercent": 0.949411,
		"totalScheduleItems": 6952,
		"schedulePercent": 0.988876,
		"totalGuests": 3782,
		"guestPercent": 0.981062,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "Moody Center",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 314
	},
	{
		"id": "d73ed9b",
		"email": "umsteadjose@gmail.com",
		"name": "Jose",
		"createdDate": "2019-01-05 01:53:13",
		"totalOrganizations": 13,
		"totalTours": 7,
		"totalDays": 375,
		"totalCountries": 1,
		"totalStates": 24,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 998,
		"schedulePercent": 0.484439,
		"totalGuests": 27,
		"guestPercent": 0.38379,
		"mostGuestDate": "2023-04-22 00:00:00",
		"mostGuestName": "Seabreeze Jazz Festival",
		"mostGuestCity": "Panama City",
		"mostGuestAmount": 10
	},
	{
		"id": "e7ad7d6",
		"email": "Kevinmajorino@gmail.com",
		"name": "Kevin",
		"createdDate": "2013-06-07 00:17:03",
		"totalOrganizations": 14,
		"totalTours": 12,
		"totalDays": 1320,
		"totalCountries": 4,
		"totalStates": 53,
		"totalEvents": 214,
		"eventPercent": 0.829559,
		"totalScheduleItems": 3230,
		"schedulePercent": 0.872202,
		"totalGuests": 629,
		"guestPercent": 0.733545,
		"mostGuestDate": "2023-07-18 00:00:00",
		"mostGuestName": "El Rey Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 44
	},
	{
		"id": "71a64b2",
		"email": "linda@stalnacke.me",
		"name": "Linda-marie",
		"createdDate": "2016-01-23 12:46:52",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 123,
		"totalCountries": 3,
		"totalStates": 8,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 912,
		"schedulePercent": 0.455304,
		"totalGuests": 400,
		"guestPercent": 0.653291,
		"mostGuestDate": "2023-08-05 00:00:00",
		"mostGuestName": "Ullevi",
		"mostGuestCity": "GÃ¶teborg",
		"mostGuestAmount": 41
	},
	{
		"id": "468e63c",
		"email": "katarina.fredriksson@jubel.se",
		"name": "Katarina",
		"createdDate": "2022-04-01 12:39:31",
		"totalOrganizations": 18,
		"totalTours": 29,
		"totalDays": 389,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 283,
		"eventPercent": 0.904516,
		"totalScheduleItems": 2176,
		"schedulePercent": 0.746391,
		"totalGuests": 413,
		"guestPercent": 0.659383,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Uppsala Konsert & Kongress",
		"mostGuestCity": "Uppsala",
		"mostGuestAmount": 40
	},
	{
		"id": "09f0919",
		"email": "carl@carlburnettdesign.co.uk",
		"name": "Carl",
		"createdDate": "2016-05-23 16:59:44",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 471,
		"totalCountries": 8,
		"totalStates": 47,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 2602,
		"schedulePercent": 0.808105,
		"totalGuests": 205,
		"guestPercent": 0.554231,
		"mostGuestDate": "2023-07-25 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 32
	},
	{
		"id": "83a5c70",
		"email": "octopandapus@gmail.com",
		"name": "Elijah",
		"createdDate": "2021-08-27 17:22:36",
		"totalOrganizations": 2,
		"totalTours": 10,
		"totalDays": 226,
		"totalCountries": 8,
		"totalStates": 41,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 1210,
		"schedulePercent": 0.54271,
		"totalGuests": 40,
		"guestPercent": 0.405642,
		"mostGuestDate": "2023-02-15 00:00:00",
		"mostGuestName": "Wolf Barcelona",
		"mostGuestCity": "Barcelona",
		"mostGuestAmount": 9
	},
	{
		"id": "8380ff5",
		"email": "kmontgomery210@gmail.com",
		"name": "Kip ",
		"createdDate": "2016-08-27 10:32:23",
		"totalOrganizations": 21,
		"totalTours": 29,
		"totalDays": 1737,
		"totalCountries": 14,
		"totalStates": 79,
		"totalEvents": 371,
		"eventPercent": 0.949146,
		"totalScheduleItems": 5893,
		"schedulePercent": 0.978943,
		"totalGuests": 2130,
		"guestPercent": 0.940538,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Petco Park",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 186
	},
	{
		"id": "52abee3",
		"email": "erin@aflyingfox.com",
		"name": "Erin",
		"createdDate": "2020-01-22 20:53:48",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 49,
		"totalCountries": 7,
		"totalStates": 11,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 282,
		"schedulePercent": 0.199311,
		"totalGuests": 77,
		"guestPercent": 0.452523,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Kulttuuritalo",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 18
	},
	{
		"id": "347473b",
		"email": "tuotanto@livenation.fi",
		"name": "Tuotanto",
		"createdDate": "2017-10-11 09:44:21",
		"totalOrganizations": 66,
		"totalTours": 39,
		"totalDays": 858,
		"totalCountries": 4,
		"totalStates": 8,
		"totalEvents": 727,
		"eventPercent": 0.987022,
		"totalScheduleItems": 3658,
		"schedulePercent": 0.904781,
		"totalGuests": 682,
		"guestPercent": 0.748907,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Kulttuuritalo",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 107
	},
	{
		"id": "8e7fab1",
		"email": "harrisondrums@gmail.com",
		"name": "Terrance",
		"createdDate": "2019-10-17 18:39:46",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 315,
		"totalCountries": 3,
		"totalStates": 45,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 1814,
		"schedulePercent": 0.687591,
		"totalGuests": 485,
		"guestPercent": 0.685207,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "Microsoft Theather",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 50
	},
	{
		"id": "d13cbe2",
		"email": "colesilbermanmgmt@gmail.com",
		"name": "Cole",
		"createdDate": "2019-04-16 20:32:12",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 97,
		"totalCountries": 4,
		"totalStates": 23,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 572,
		"schedulePercent": 0.328963,
		"totalGuests": 256,
		"guestPercent": 0.582307,
		"mostGuestDate": "2023-03-15 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 47
	},
	{
		"id": "9808c0a",
		"email": "tim.harding@live.com",
		"name": "Timothy",
		"createdDate": "2013-12-15 23:48:41",
		"totalOrganizations": 13,
		"totalTours": 6,
		"totalDays": 419,
		"totalCountries": 2,
		"totalStates": 36,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 1323,
		"schedulePercent": 0.57211,
		"totalGuests": 566,
		"guestPercent": 0.71527,
		"mostGuestDate": "2023-02-19 00:00:00",
		"mostGuestName": "City National Grove of Anaheim",
		"mostGuestCity": "Anaheim",
		"mostGuestAmount": 57
	},
	{
		"id": "4ae1484",
		"email": "jonathan.loxentertainment@aol.com",
		"name": "ATN",
		"createdDate": "2023-03-02 21:15:43",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 201,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1902,
		"schedulePercent": 0.70441,
		"totalGuests": 307,
		"guestPercent": 0.607072,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Altria Theater",
		"mostGuestCity": "Richmond",
		"mostGuestAmount": 36
	},
	{
		"id": "466df3a",
		"email": "pmartinez@pantheraconsults.com",
		"name": "Peter",
		"createdDate": "2018-11-08 17:16:42",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 1030,
		"totalCountries": 4,
		"totalStates": 45,
		"totalEvents": 234,
		"eventPercent": 0.854324,
		"totalScheduleItems": 4258,
		"schedulePercent": 0.93577,
		"totalGuests": 5773,
		"guestPercent": 0.994835,
		"mostGuestDate": "2023-09-24 00:00:00",
		"mostGuestName": "PNC Music Pavilion",
		"mostGuestCity": "Charlotte",
		"mostGuestAmount": 141
	},
	{
		"id": "fa5d5b0",
		"email": "rbazuin@gmail.com",
		"name": "Rick ",
		"createdDate": "2015-08-11 16:02:39",
		"totalOrganizations": 7,
		"totalTours": 18,
		"totalDays": 1377,
		"totalCountries": 3,
		"totalStates": 20,
		"totalEvents": 137,
		"eventPercent": 0.666402,
		"totalScheduleItems": 1589,
		"schedulePercent": 0.638988,
		"totalGuests": 304,
		"guestPercent": 0.605218,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Commodore Ballroom",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 58
	},
	{
		"id": "50f3004",
		"email": "dvhdez@gmail.com",
		"name": "Drea",
		"createdDate": "2019-12-02 22:50:08",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 159,
		"totalCountries": 10,
		"totalStates": 31,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 972,
		"schedulePercent": 0.473447,
		"totalGuests": 55,
		"guestPercent": 0.42842,
		"mostGuestDate": "2023-03-28 00:00:00",
		"mostGuestName": "The Bowery Ballroom",
		"mostGuestCity": "New York",
		"mostGuestAmount": 27
	},
	{
		"id": "62c0cdb",
		"email": "ademunnik@me.com",
		"name": "Adam",
		"createdDate": "2013-07-17 12:58:25",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 186,
		"totalCountries": 4,
		"totalStates": 11,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 599,
		"schedulePercent": 0.340352,
		"totalGuests": 207,
		"guestPercent": 0.555953,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "Chatham Capitol Theatre",
		"mostGuestCity": "Chatham",
		"mostGuestAmount": 23
	},
	{
		"id": "2236dc2",
		"email": "paulvroom@live.ca",
		"name": "Paul",
		"createdDate": "2015-01-20 15:19:44",
		"totalOrganizations": 10,
		"totalTours": 14,
		"totalDays": 281,
		"totalCountries": 6,
		"totalStates": 28,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 675,
		"schedulePercent": 0.370547,
		"totalGuests": 30,
		"guestPercent": 0.389485,
		"mostGuestDate": "2023-08-27 00:00:00",
		"mostGuestName": "Zebulon",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 12
	},
	{
		"id": "6673021",
		"email": "richardpwheeler@me.com",
		"name": "Richard",
		"createdDate": "2018-09-20 12:53:13",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 59,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 375,
		"schedulePercent": 0.245928,
		"totalGuests": 133,
		"guestPercent": 0.500596,
		"mostGuestDate": "2023-08-06 00:00:00",
		"mostGuestName": "Longwood Gardens",
		"mostGuestCity": "Kennett Square",
		"mostGuestAmount": 17
	},
	{
		"id": "ea5e37e",
		"email": "avazegers@gmail.com",
		"name": "Ava",
		"createdDate": "2023-02-16 18:48:09",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 693,
		"totalCountries": 4,
		"totalStates": 43,
		"totalEvents": 283,
		"eventPercent": 0.904516,
		"totalScheduleItems": 5522,
		"schedulePercent": 0.973513,
		"totalGuests": 908,
		"guestPercent": 0.801218,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 278
	},
	{
		"id": "df55110",
		"email": "emma@unitedstage.dk",
		"name": "Emma ",
		"createdDate": "2022-10-05 07:54:47",
		"totalOrganizations": 49,
		"totalTours": 36,
		"totalDays": 1206,
		"totalCountries": 5,
		"totalStates": 19,
		"totalEvents": 1118,
		"eventPercent": 0.991392,
		"totalScheduleItems": 4772,
		"schedulePercent": 0.954311,
		"totalGuests": 23,
		"guestPercent": 0.37452,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Eksercerhuset",
		"mostGuestCity": "Fredericia",
		"mostGuestAmount": 5
	},
	{
		"id": "54a4650",
		"email": "Chelsey.Block@redlightmanagement.com",
		"name": "Chelsey",
		"createdDate": "2019-07-22 17:13:31",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 1101,
		"totalCountries": 3,
		"totalStates": 50,
		"totalEvents": 296,
		"eventPercent": 0.912992,
		"totalScheduleItems": 4998,
		"schedulePercent": 0.961727,
		"totalGuests": 2235,
		"guestPercent": 0.945703,
		"mostGuestDate": "2023-09-07 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 118
	},
	{
		"id": "5b91633",
		"email": "iaspply@gmail.com",
		"name": "Invent",
		"createdDate": "2022-10-31 17:32:09",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 97,
		"totalCountries": 10,
		"totalStates": 45,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 835,
		"schedulePercent": 0.432923,
		"totalGuests": 135,
		"guestPercent": 0.50245,
		"mostGuestDate": "2023-05-28 00:00:00",
		"mostGuestName": "Clyde Theatre",
		"mostGuestCity": "Fort Wayne",
		"mostGuestAmount": 34
	},
	{
		"id": "6631a7b",
		"email": "dgudinog@me.com",
		"name": "Dante",
		"createdDate": "2017-09-15 12:50:53",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 96,
		"totalCountries": 8,
		"totalStates": 36,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 766,
		"schedulePercent": 0.407496,
		"totalGuests": 335,
		"guestPercent": 0.620712,
		"mostGuestDate": "2023-07-24 00:00:00",
		"mostGuestName": "Central Park SummerStage",
		"mostGuestCity": "New York",
		"mostGuestAmount": 66
	},
	{
		"id": "47892c6",
		"email": "haily.branham@gmail.com",
		"name": "Haily",
		"createdDate": "2020-01-04 04:20:25",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 363,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 683,
		"schedulePercent": 0.37399,
		"totalGuests": 1214,
		"guestPercent": 0.860019,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 102
	},
	{
		"id": "b174d7c",
		"email": "vickie@girlieaction.com",
		"name": "Vickie",
		"createdDate": "2019-10-09 17:43:04",
		"totalOrganizations": 2,
		"totalTours": 7,
		"totalDays": 369,
		"totalCountries": 16,
		"totalStates": 36,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 626,
		"schedulePercent": 0.352404,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e657d22",
		"email": "reno421@gmail.com",
		"name": "Renaud",
		"createdDate": "2015-05-22 16:44:23",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 93,
		"totalCountries": 3,
		"totalStates": 26,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 357,
		"schedulePercent": 0.236393,
		"totalGuests": 412,
		"guestPercent": 0.658588,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "MTELUS",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 211
	},
	{
		"id": "6ef53e0",
		"email": "wesley@laescuelita.com",
		"name": "Wesley",
		"createdDate": "2022-08-02 19:23:37",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 840,
		"totalCountries": 11,
		"totalStates": 31,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 1533,
		"schedulePercent": 0.625612,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5502016",
		"email": "info@giandomenicoparente.it",
		"name": "Giandomenico",
		"createdDate": "2022-11-12 11:07:38",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 124,
		"totalCountries": 1,
		"totalStates": 46,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 571,
		"schedulePercent": 0.328036,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d4da299",
		"email": "jantore@ncb.no",
		"name": "Jan Tore",
		"createdDate": "2022-04-08 09:42:19",
		"totalOrganizations": 2,
		"totalTours": 15,
		"totalDays": 91,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 184,
		"schedulePercent": 0.141306,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0d927c8",
		"email": "ben@317productions.com",
		"name": "Ben",
		"createdDate": "2016-03-26 15:52:14",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 554,
		"totalCountries": 8,
		"totalStates": 39,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 2311,
		"schedulePercent": 0.766521,
		"totalGuests": 2831,
		"guestPercent": 0.966097,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Raymond James Stadium",
		"mostGuestCity": "Tampa ",
		"mostGuestAmount": 248
	},
	{
		"id": "e3b3392",
		"email": "AMarinoTAI@gmail.com",
		"name": "Anthony",
		"createdDate": "2010-07-15 14:42:50",
		"totalOrganizations": 11,
		"totalTours": 10,
		"totalDays": 843,
		"totalCountries": 22,
		"totalStates": 58,
		"totalEvents": 447,
		"eventPercent": 0.967289,
		"totalScheduleItems": 5729,
		"schedulePercent": 0.976824,
		"totalGuests": 318,
		"guestPercent": 0.613031,
		"mostGuestDate": "2023-08-13 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 43
	},
	{
		"id": "0b6f445",
		"email": "lucyjmatusik@gmail.com",
		"name": "Lucia ",
		"createdDate": "2021-10-29 09:24:36",
		"totalOrganizations": 8,
		"totalTours": 14,
		"totalDays": 300,
		"totalCountries": 11,
		"totalStates": 48,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1259,
		"schedulePercent": 0.554231,
		"totalGuests": 166,
		"guestPercent": 0.52748,
		"mostGuestDate": "2023-05-28 00:00:00",
		"mostGuestName": "O2 Academy Islington",
		"mostGuestCity": "London",
		"mostGuestAmount": 20
	},
	{
		"id": "bcdf1e2",
		"email": "okanmusic18@gmail.com",
		"name": "Luisa ",
		"createdDate": "2023-04-21 19:22:40",
		"totalOrganizations": 1,
		"totalTours": 13,
		"totalDays": 48,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 136,
		"schedulePercent": 0.110581,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "720a305",
		"email": "timzuchowski@gmail.com",
		"name": "Tim",
		"createdDate": "2011-02-07 05:33:47",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 179,
		"totalCountries": 8,
		"totalStates": 33,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 1312,
		"schedulePercent": 0.569064,
		"totalGuests": 1118,
		"guestPercent": 0.842273,
		"mostGuestDate": "2023-03-21 00:00:00",
		"mostGuestName": "The SSE Arena, Wembley",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 73
	},
	{
		"id": "2e462bd",
		"email": "aaronhedden@mac.com",
		"name": "Aaron",
		"createdDate": "2014-01-07 23:02:12",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 1077,
		"totalCountries": 7,
		"totalStates": 51,
		"totalEvents": 246,
		"eventPercent": 0.868759,
		"totalScheduleItems": 3980,
		"schedulePercent": 0.921997,
		"totalGuests": 835,
		"guestPercent": 0.784399,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "Thompson Boling Arena",
		"mostGuestCity": "Knoxville",
		"mostGuestAmount": 47
	},
	{
		"id": "366ac93",
		"email": "therumblenola@gmail.com",
		"name": "Ari ",
		"createdDate": "2023-06-05 23:45:22",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 208,
		"totalCountries": 1,
		"totalStates": 24,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 221,
		"schedulePercent": 0.163952,
		"totalGuests": 40,
		"guestPercent": 0.405642,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Aladdin Theater",
		"mostGuestCity": "Portland",
		"mostGuestAmount": 8
	},
	{
		"id": "623ca37",
		"email": "alexmfeld@gmail.com",
		"name": "Alex",
		"createdDate": "2012-10-26 23:28:50",
		"totalOrganizations": 12,
		"totalTours": 11,
		"totalDays": 1451,
		"totalCountries": 19,
		"totalStates": 81,
		"totalEvents": 348,
		"eventPercent": 0.94067,
		"totalScheduleItems": 6346,
		"schedulePercent": 0.983843,
		"totalGuests": 3062,
		"guestPercent": 0.97007,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 104
	},
	{
		"id": "419c6ca",
		"email": "loganaudio@gmail.com",
		"name": "Logan",
		"createdDate": "2015-11-08 04:20:42",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 367,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 1007,
		"schedulePercent": 0.48722,
		"totalGuests": 100,
		"guestPercent": 0.473977,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Deadwood Mountain Grand Hotel and Casino",
		"mostGuestCity": "Deadwood",
		"mostGuestAmount": 50
	},
	{
		"id": "06e0600",
		"email": "sa-ma-ha@gmx.de",
		"name": "Sandra",
		"createdDate": "2019-05-11 09:26:40",
		"totalOrganizations": 8,
		"totalTours": 11,
		"totalDays": 264,
		"totalCountries": 16,
		"totalStates": 49,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1725,
		"schedulePercent": 0.670242,
		"totalGuests": 616,
		"guestPercent": 0.730499,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Cow Palace",
		"mostGuestCity": "Daly City",
		"mostGuestAmount": 226
	},
	{
		"id": "e65ea12",
		"email": "sunburnedhand@gmail.com",
		"name": "John",
		"createdDate": "2013-11-20 12:25:52",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 113,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 481,
		"schedulePercent": 0.289101,
		"totalGuests": 180,
		"guestPercent": 0.536485,
		"mostGuestDate": "2023-10-10 00:00:00",
		"mostGuestName": "The Ardmore Music Hall",
		"mostGuestCity": "Ardmore",
		"mostGuestAmount": 30
	},
	{
		"id": "3674ba1",
		"email": "miwtourmanager@gmail.com",
		"name": "Ryan",
		"createdDate": "2023-02-10 18:56:01",
		"totalOrganizations": 6,
		"totalTours": 11,
		"totalDays": 455,
		"totalCountries": 10,
		"totalStates": 58,
		"totalEvents": 250,
		"eventPercent": 0.872335,
		"totalScheduleItems": 4108,
		"schedulePercent": 0.927957,
		"totalGuests": 345,
		"guestPercent": 0.62601,
		"mostGuestDate": "2023-08-06 00:00:00",
		"mostGuestName": "Pine Knob Music Theatre",
		"mostGuestCity": "City of the Village of Clarkston",
		"mostGuestAmount": 43
	},
	{
		"id": "3bafdf2",
		"email": "dave@3sixtytouring.co.uk",
		"name": "Dave",
		"createdDate": "2014-12-18 12:28:29",
		"totalOrganizations": 14,
		"totalTours": 18,
		"totalDays": 1495,
		"totalCountries": 22,
		"totalStates": 76,
		"totalEvents": 342,
		"eventPercent": 0.938154,
		"totalScheduleItems": 5303,
		"schedulePercent": 0.969938,
		"totalGuests": 1834,
		"guestPercent": 0.924248,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "The SSE Arena, Wembley",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 226
	},
	{
		"id": "ea1498a",
		"email": "kayla.aye@azoffcompany.com",
		"name": "MWG",
		"createdDate": "2022-07-03 16:43:07",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 406,
		"totalCountries": 14,
		"totalStates": 21,
		"totalEvents": 210,
		"eventPercent": 0.823732,
		"totalScheduleItems": 2320,
		"schedulePercent": 0.768772,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ee5a58d",
		"email": "mattburhite@gmail.com",
		"name": "Matt",
		"createdDate": "2023-03-31 22:37:17",
		"totalOrganizations": 1,
		"totalTours": 9,
		"totalDays": 119,
		"totalCountries": 3,
		"totalStates": 30,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 567,
		"schedulePercent": 0.327109,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "The Arts Campus at Willits",
		"mostGuestCity": "Basalt",
		"mostGuestAmount": 6
	},
	{
		"id": "5fea45f",
		"email": "contact@realmofobscura.com",
		"name": "Steffen",
		"createdDate": "2021-12-12 21:56:41",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 131,
		"totalCountries": 8,
		"totalStates": 31,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 785,
		"schedulePercent": 0.415044,
		"totalGuests": 45,
		"guestPercent": 0.412925,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Z7 Konzertfabrik",
		"mostGuestCity": "Pratteln",
		"mostGuestAmount": 20
	},
	{
		"id": "78f1747",
		"email": "ppraphanchith@cityparksfoundation.org",
		"name": "Paulina",
		"createdDate": "2022-05-25 19:54:46",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1af27e5",
		"email": "sooze@nilerodgers.com",
		"name": "Sooze",
		"createdDate": "2017-06-29 17:14:56",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 219,
		"totalCountries": 15,
		"totalStates": 45,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1454,
		"schedulePercent": 0.604291,
		"totalGuests": 485,
		"guestPercent": 0.685207,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "Warwick Castle",
		"mostGuestCity": "Warwick",
		"mostGuestAmount": 23
	},
	{
		"id": "d46ec3f",
		"email": "mercergold1@gmail.com",
		"name": "Mercer",
		"createdDate": "2022-05-04 22:55:18",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 64,
		"totalCountries": 6,
		"totalStates": 22,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 168,
		"schedulePercent": 0.131373,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ad2052d",
		"email": "thejoelentini@gmail.com",
		"name": "Joe",
		"createdDate": "2022-11-09 18:12:22",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 167,
		"totalCountries": 2,
		"totalStates": 37,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 944,
		"schedulePercent": 0.463515,
		"totalGuests": 551,
		"guestPercent": 0.710105,
		"mostGuestDate": "2023-01-12 00:00:00",
		"mostGuestName": "Riverside Revival Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 149
	},
	{
		"id": "9ff7ef8",
		"email": "info@theflamingarts.eu",
		"name": "Vadim ",
		"createdDate": "2023-10-18 13:31:01",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 53,
		"totalCountries": 14,
		"totalStates": 25,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 549,
		"schedulePercent": 0.320355,
		"totalGuests": 20,
		"guestPercent": 0.36909,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Razzmatazz 2",
		"mostGuestCity": "Barcelona",
		"mostGuestAmount": 12
	},
	{
		"id": "0b53e59",
		"email": "vanderzon.touring@gmail.com",
		"name": "Katrina",
		"createdDate": "2022-06-07 10:25:15",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 612,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 800,
		"schedulePercent": 0.420739,
		"totalGuests": 50,
		"guestPercent": 0.421798,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Iron City Bham",
		"mostGuestCity": "Birmingham",
		"mostGuestAmount": 17
	},
	{
		"id": "12340fb",
		"email": "ryan@ryan-alexander.com",
		"name": "Ryan",
		"createdDate": "2022-09-26 15:55:02",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 225,
		"totalCountries": 1,
		"totalStates": 18,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 1054,
		"schedulePercent": 0.498609,
		"totalGuests": 400,
		"guestPercent": 0.653291,
		"mostGuestDate": "2023-09-07 00:00:00",
		"mostGuestName": "Van Andel Arena",
		"mostGuestCity": "Grand Rapids",
		"mostGuestAmount": 42
	},
	{
		"id": "5f6b328",
		"email": "ianmarty@stmusicgroup.com",
		"name": "Ian",
		"createdDate": "2011-04-04 18:53:01",
		"totalOrganizations": 48,
		"totalTours": 25,
		"totalDays": 741,
		"totalCountries": 15,
		"totalStates": 103,
		"totalEvents": 281,
		"eventPercent": 0.903059,
		"totalScheduleItems": 3996,
		"schedulePercent": 0.923321,
		"totalGuests": 1193,
		"guestPercent": 0.856443,
		"mostGuestDate": "2023-02-19 00:00:00",
		"mostGuestName": "James L. Knight Center",
		"mostGuestCity": "Miami",
		"mostGuestAmount": 60
	},
	{
		"id": "3509583",
		"email": "ryan@ryanclarkmusic.com",
		"name": "Ryan",
		"createdDate": "2022-03-21 18:49:19",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 970,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 317,
		"schedulePercent": 0.216395,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2a4d438",
		"email": "tara@qprime.com",
		"name": "Tara",
		"createdDate": "2010-05-06 17:05:47",
		"totalOrganizations": 9,
		"totalTours": 15,
		"totalDays": 557,
		"totalCountries": 12,
		"totalStates": 59,
		"totalEvents": 197,
		"eventPercent": 0.800821,
		"totalScheduleItems": 2254,
		"schedulePercent": 0.759237,
		"totalGuests": 538,
		"guestPercent": 0.705205,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "EartH (Evolutionary Arts Hackney)",
		"mostGuestCity": "London",
		"mostGuestAmount": 48
	},
	{
		"id": "c377601",
		"email": "mahalia@thirdrodeo.com.au",
		"name": "Mahalia",
		"createdDate": "2019-04-30 23:29:27",
		"totalOrganizations": 16,
		"totalTours": 15,
		"totalDays": 532,
		"totalCountries": 5,
		"totalStates": 63,
		"totalEvents": 236,
		"eventPercent": 0.857237,
		"totalScheduleItems": 2610,
		"schedulePercent": 0.810224,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Eccles Theater",
		"mostGuestCity": "Salt Lake City",
		"mostGuestAmount": 6
	},
	{
		"id": "6fee451",
		"email": "Danielellisfoh@gmail.com",
		"name": "Daniel",
		"createdDate": "2013-09-14 10:17:22",
		"totalOrganizations": 15,
		"totalTours": 13,
		"totalDays": 862,
		"totalCountries": 2,
		"totalStates": 42,
		"totalEvents": 362,
		"eventPercent": 0.947159,
		"totalScheduleItems": 5008,
		"schedulePercent": 0.962124,
		"totalGuests": 3870,
		"guestPercent": 0.982254,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 298
	},
	{
		"id": "6145949",
		"email": "igor@ineffablemusic.com",
		"name": "Abel",
		"createdDate": "2011-07-13 20:37:45",
		"totalOrganizations": 17,
		"totalTours": 57,
		"totalDays": 1382,
		"totalCountries": 7,
		"totalStates": 51,
		"totalEvents": 488,
		"eventPercent": 0.972586,
		"totalScheduleItems": 5418,
		"schedulePercent": 0.971792,
		"totalGuests": 4557,
		"guestPercent": 0.988743,
		"mostGuestDate": "2023-06-18 00:00:00",
		"mostGuestName": "Red Rocks Park and Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 183
	},
	{
		"id": "bb10873",
		"email": "jamlarecords@gmail.com",
		"name": "Tia",
		"createdDate": "2018-03-19 03:59:33",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 153,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 294,
		"schedulePercent": 0.205006,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9f837ee",
		"email": "michaelschoenbeck@me.com",
		"name": "Michael",
		"createdDate": "2012-11-16 17:31:11",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 121,
		"totalCountries": 7,
		"totalStates": 29,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 1550,
		"schedulePercent": 0.630248,
		"totalGuests": 2662,
		"guestPercent": 0.958416,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The ICON Festival Stage",
		"mostGuestCity": "Cincinnati",
		"mostGuestAmount": 238
	},
	{
		"id": "833e7cf",
		"email": "paul@wells.ms",
		"name": "Paul",
		"createdDate": "2012-12-26 01:36:46",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 113,
		"totalCountries": 4,
		"totalStates": 24,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 647,
		"schedulePercent": 0.361806,
		"totalGuests": 182,
		"guestPercent": 0.538074,
		"mostGuestDate": "2023-05-17 00:00:00",
		"mostGuestName": "The Crocodile",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 44
	},
	{
		"id": "305062a",
		"email": "Ellusivegroupmail@icloud.com",
		"name": "Kevan",
		"createdDate": "2014-09-01 19:41:51",
		"totalOrganizations": 5,
		"totalTours": 15,
		"totalDays": 158,
		"totalCountries": 12,
		"totalStates": 17,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 1055,
		"schedulePercent": 0.499139,
		"totalGuests": 92,
		"guestPercent": 0.466163,
		"mostGuestDate": "2023-04-26 00:00:00",
		"mostGuestName": "Royal Albert Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 50
	},
	{
		"id": "a18fbce",
		"email": "worship@redrockschurch.com",
		"name": "Jennifer",
		"createdDate": "2023-03-07 17:22:55",
		"totalOrganizations": 1,
		"totalTours": 8,
		"totalDays": 54,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 363,
		"schedulePercent": 0.239703,
		"totalGuests": 29,
		"guestPercent": 0.387896,
		"mostGuestDate": "2023-09-22 00:00:00",
		"mostGuestName": "The Summit Church",
		"mostGuestCity": "Lee's Summit",
		"mostGuestAmount": 17
	},
	{
		"id": "3ee3e86",
		"email": "anjel.events@gmail.com",
		"name": "Anjel",
		"createdDate": "2016-01-19 22:38:53",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 373,
		"totalCountries": 9,
		"totalStates": 29,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 2171,
		"schedulePercent": 0.745597,
		"totalGuests": 30,
		"guestPercent": 0.389485,
		"mostGuestDate": "2023-07-09 00:00:00",
		"mostGuestName": "Hyde Park",
		"mostGuestCity": "London",
		"mostGuestAmount": 22
	},
	{
		"id": "0a4df68",
		"email": "thearcadianwild@gmail.com",
		"name": "Lincoln",
		"createdDate": "2023-02-17 19:40:57",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 134,
		"totalCountries": 1,
		"totalStates": 33,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 200,
		"schedulePercent": 0.150444,
		"totalGuests": 36,
		"guestPercent": 0.397961,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Exit/In",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 21
	},
	{
		"id": "5a9c5e0",
		"email": "panizfarokh@gmail.com",
		"name": "Paniz",
		"createdDate": "2022-12-16 20:34:35",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 52,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 281,
		"schedulePercent": 0.198384,
		"totalGuests": 290,
		"guestPercent": 0.597934,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 40
	},
	{
		"id": "f06920e",
		"email": "timmillikan@me.com",
		"name": "Tim",
		"createdDate": "2011-05-03 15:41:14",
		"totalOrganizations": 11,
		"totalTours": 21,
		"totalDays": 415,
		"totalCountries": 9,
		"totalStates": 38,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 1747,
		"schedulePercent": 0.674348,
		"totalGuests": 387,
		"guestPercent": 0.647596,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "Palais Theatre",
		"mostGuestCity": "St Kilda",
		"mostGuestAmount": 94
	},
	{
		"id": "aae49b3",
		"email": "claw@jamflowmgmt.com",
		"name": "Ryan",
		"createdDate": "2019-09-12 17:53:40",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 517,
		"totalCountries": 1,
		"totalStates": 24,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 490,
		"schedulePercent": 0.292676,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "The Broadberry",
		"mostGuestCity": "Richmond",
		"mostGuestAmount": 9
	},
	{
		"id": "dcb6474",
		"email": "tanneradams@live.com",
		"name": "Tanner",
		"createdDate": "2014-02-20 17:09:14",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 108,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 1071,
		"schedulePercent": 0.505231,
		"totalGuests": 726,
		"guestPercent": 0.75884,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Crypto.com Arena",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 114
	},
	{
		"id": "c02ae6a",
		"email": "sundriedsteph@gmail.com",
		"name": "Stephanie",
		"createdDate": "2022-08-23 23:32:08",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 45,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 146,
		"schedulePercent": 0.117071,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d50ae4b",
		"email": "Josephamills@gmail.com",
		"name": "Joseph",
		"createdDate": "2018-01-10 01:53:21",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 91,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 755,
		"schedulePercent": 0.404715,
		"totalGuests": 190,
		"guestPercent": 0.542445,
		"mostGuestDate": "2023-12-23 00:00:00",
		"mostGuestName": "Fisher Center for the Performing Arts",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 59
	},
	{
		"id": "be31340",
		"email": "lily@renegade.com.au",
		"name": "Lily",
		"createdDate": "2016-07-04 04:10:25",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 84,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 23,
		"schedulePercent": 0.0357569,
		"totalGuests": 303,
		"guestPercent": 0.604158,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "Palais Theatre",
		"mostGuestCity": "St Kilda",
		"mostGuestAmount": 94
	},
	{
		"id": "1f6c108",
		"email": "marcusdamal@web.de",
		"name": "Marcus",
		"createdDate": "2018-05-14 16:21:52",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 87,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 639,
		"schedulePercent": 0.357966,
		"totalGuests": 524,
		"guestPercent": 0.699775,
		"mostGuestDate": "2023-07-01 00:00:00",
		"mostGuestName": "Stadtpark",
		"mostGuestCity": "Hamburg",
		"mostGuestAmount": 123
	},
	{
		"id": "5f95143",
		"email": "kate@doubledlive.com",
		"name": "kate",
		"createdDate": "2021-09-29 19:58:35",
		"totalOrganizations": 11,
		"totalTours": 6,
		"totalDays": 1078,
		"totalCountries": 4,
		"totalStates": 6,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 467,
		"schedulePercent": 0.283406,
		"totalGuests": 401,
		"guestPercent": 0.654218,
		"mostGuestDate": "2023-03-07 00:00:00",
		"mostGuestName": "NX Newcastle",
		"mostGuestCity": "Newcastle upon Tyne",
		"mostGuestAmount": 166
	},
	{
		"id": "72ecfbf",
		"email": "emma@nohalfmeasures.com",
		"name": "Emma",
		"createdDate": "2021-10-17 08:05:03",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 1132,
		"totalCountries": 3,
		"totalStates": 8,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 985,
		"schedulePercent": 0.47795,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "Let's Rock Scotland",
		"mostGuestCity": "Dalkeith ",
		"mostGuestAmount": 3
	},
	{
		"id": "b9d9ece",
		"email": "daniel.kirkpatrick@gmail.com",
		"name": "Daniel",
		"createdDate": "2015-02-12 14:03:28",
		"totalOrganizations": 9,
		"totalTours": 24,
		"totalDays": 1869,
		"totalCountries": 20,
		"totalStates": 78,
		"totalEvents": 288,
		"eventPercent": 0.907694,
		"totalScheduleItems": 5932,
		"schedulePercent": 0.979473,
		"totalGuests": 1689,
		"guestPercent": 0.911932,
		"mostGuestDate": "2023-03-09 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 142
	},
	{
		"id": "799990d",
		"email": "kmtwist@me.com",
		"name": "Kevin ",
		"createdDate": "2011-06-21 03:53:05",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 4,
		"totalStates": 39,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 2707,
		"schedulePercent": 0.819494,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d7ef1b5",
		"email": "sabrina@cultureco.co",
		"name": "Sabrina",
		"createdDate": "2021-08-12 03:21:02",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 72,
		"totalCountries": 8,
		"totalStates": 18,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 483,
		"schedulePercent": 0.290028,
		"totalGuests": 26,
		"guestPercent": 0.381274,
		"mostGuestDate": "2023-05-27 00:00:00",
		"mostGuestName": "The Queen Mary",
		"mostGuestCity": "Long Beach",
		"mostGuestAmount": 16
	},
	{
		"id": "9c21100",
		"email": "mattshrimptonis@gmail.com",
		"name": "Matt",
		"createdDate": "2012-06-14 01:16:45",
		"totalOrganizations": 13,
		"totalTours": 7,
		"totalDays": 53,
		"totalCountries": 4,
		"totalStates": 10,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 314,
		"schedulePercent": 0.215203,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "44a97f6",
		"email": "leon@globalproductionpartners.com",
		"name": "Leon ",
		"createdDate": "2017-09-18 06:56:15",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 223,
		"totalCountries": 11,
		"totalStates": 29,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 1361,
		"schedulePercent": 0.583234,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "317505c",
		"email": "dw@scooterbraun.com",
		"name": "David",
		"createdDate": "2018-06-08 16:54:20",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 69,
		"totalCountries": 7,
		"totalStates": 20,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 242,
		"schedulePercent": 0.177195,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "dc740bd",
		"email": "Info@storefrontrecords.com",
		"name": "Storefront",
		"createdDate": "2022-04-27 21:28:04",
		"totalOrganizations": 1,
		"totalTours": 12,
		"totalDays": 337,
		"totalCountries": 9,
		"totalStates": 33,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 591,
		"schedulePercent": 0.336512,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6508747",
		"email": "hello@sammyrae.com",
		"name": "Sammy",
		"createdDate": "2021-07-11 14:28:24",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 277,
		"totalCountries": 7,
		"totalStates": 31,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 755,
		"schedulePercent": 0.404715,
		"totalGuests": 657,
		"guestPercent": 0.741226,
		"mostGuestDate": "2023-06-15 00:00:00",
		"mostGuestName": "Central Park SummerStage",
		"mostGuestCity": "New York",
		"mostGuestAmount": 81
	},
	{
		"id": "3f683c1",
		"email": "tourassist@samhunttouring.com",
		"name": "Kallie",
		"createdDate": "2017-06-12 16:05:31",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 267,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1412,
		"schedulePercent": 0.593829,
		"totalGuests": 2013,
		"guestPercent": 0.934313,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Gas South Arena",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 157
	},
	{
		"id": "b0ff9ba",
		"email": "extinguishboston@gmail.com",
		"name": "Ben",
		"createdDate": "2013-01-11 23:27:38",
		"totalOrganizations": 18,
		"totalTours": 15,
		"totalDays": 927,
		"totalCountries": 24,
		"totalStates": 65,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 2789,
		"schedulePercent": 0.830618,
		"totalGuests": 1675,
		"guestPercent": 0.910211,
		"mostGuestDate": "2023-08-20 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 99
	},
	{
		"id": "f184a84",
		"email": "alan.a.arana@gmail.com",
		"name": "Alan",
		"createdDate": "2010-01-29 20:15:43",
		"totalOrganizations": 11,
		"totalTours": 13,
		"totalDays": 498,
		"totalCountries": 11,
		"totalStates": 67,
		"totalEvents": 230,
		"eventPercent": 0.849159,
		"totalScheduleItems": 4075,
		"schedulePercent": 0.9265,
		"totalGuests": 2400,
		"guestPercent": 0.950867,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 98
	},
	{
		"id": "596e014",
		"email": "johnroberttravis@gmail.com",
		"name": "John",
		"createdDate": "2021-06-01 15:28:35",
		"totalOrganizations": 10,
		"totalTours": 11,
		"totalDays": 1229,
		"totalCountries": 5,
		"totalStates": 51,
		"totalEvents": 395,
		"eventPercent": 0.956032,
		"totalScheduleItems": 6546,
		"schedulePercent": 0.985168,
		"totalGuests": 4156,
		"guestPercent": 0.985168,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 298
	},
	{
		"id": "ef532ff",
		"email": "nherbertaz@gmail.com",
		"name": "Nick",
		"createdDate": "2018-02-18 19:29:44",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 40,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 171,
		"schedulePercent": 0.13336,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b3e0da1",
		"email": "hotelfoh@gmail.com",
		"name": "Jeff",
		"createdDate": "2023-03-11 01:26:03",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 362,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 435,
		"schedulePercent": 0.270295,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d055384",
		"email": "sheisaphroditecontact@gmail.com",
		"name": "Michelle",
		"createdDate": "2017-11-14 22:02:46",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 108,
		"totalCountries": 9,
		"totalStates": 12,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 352,
		"schedulePercent": 0.234538,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fef8c9f",
		"email": "ashnoah82@gmail.com",
		"name": "Noah",
		"createdDate": "2023-02-09 18:59:43",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 355,
		"totalCountries": 6,
		"totalStates": 42,
		"totalEvents": 288,
		"eventPercent": 0.907694,
		"totalScheduleItems": 2339,
		"schedulePercent": 0.772878,
		"totalGuests": 1112,
		"guestPercent": 0.841345,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 109
	},
	{
		"id": "c082e8f",
		"email": "dori.colly@gmail.com",
		"name": "Dori",
		"createdDate": "2017-05-21 16:27:31",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 142,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 493,
		"schedulePercent": 0.294663,
		"totalGuests": 110,
		"guestPercent": 0.482453,
		"mostGuestDate": "2023-11-05 00:00:00",
		"mostGuestName": "The Theater @ MGM National Harbor",
		"mostGuestCity": "Oxon Hill",
		"mostGuestAmount": 40
	},
	{
		"id": "ea86d4f",
		"email": "robbycox@rocketmail.com",
		"name": "Robby",
		"createdDate": "2015-07-16 21:40:47",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 622,
		"schedulePercent": 0.350417,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6f272b0",
		"email": "katie@katiesnyder.com",
		"name": "Katie",
		"createdDate": "2022-08-12 20:19:12",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 641,
		"totalCountries": 10,
		"totalStates": 58,
		"totalEvents": 173,
		"eventPercent": 0.757516,
		"totalScheduleItems": 2561,
		"schedulePercent": 0.800689,
		"totalGuests": 2041,
		"guestPercent": 0.936167,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Lincoln Financial Field",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 163
	},
	{
		"id": "e2e1c8e",
		"email": "charissaratz@gmail.com",
		"name": "Charissa",
		"createdDate": "2021-05-05 18:20:05",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 235,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 2252,
		"schedulePercent": 0.758972,
		"totalGuests": 1902,
		"guestPercent": 0.929016,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Dickies Arena",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 433
	},
	{
		"id": "9bedb4b",
		"email": "veikko@wearelive.us",
		"name": "Veikko",
		"createdDate": "2018-02-08 13:49:00",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 529,
		"totalCountries": 13,
		"totalStates": 57,
		"totalEvents": 217,
		"eventPercent": 0.833267,
		"totalScheduleItems": 3676,
		"schedulePercent": 0.905973,
		"totalGuests": 2295,
		"guestPercent": 0.947557,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Glen Helen Amphitheater",
		"mostGuestCity": "San Bernardino",
		"mostGuestAmount": 201
	},
	{
		"id": "a57e7a5",
		"email": "amajor.guy@gmail.com",
		"name": "George",
		"createdDate": "2009-09-03 01:15:55",
		"totalOrganizations": 11,
		"totalTours": 12,
		"totalDays": 499,
		"totalCountries": 12,
		"totalStates": 49,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 1902,
		"schedulePercent": 0.70441,
		"totalGuests": 193,
		"guestPercent": 0.545093,
		"mostGuestDate": "2023-08-10 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 23
	},
	{
		"id": "5e8be1f",
		"email": "margaux@thisfiction.com",
		"name": "Margaux",
		"createdDate": "2022-01-13 02:37:15",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 251,
		"totalCountries": 3,
		"totalStates": 25,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 1096,
		"schedulePercent": 0.511058,
		"totalGuests": 330,
		"guestPercent": 0.618858,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "Royale",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 53
	},
	{
		"id": "6d3eb2f",
		"email": "ukhcsomerville@gmail.com",
		"name": "Stanley",
		"createdDate": "2014-04-22 22:46:38",
		"totalOrganizations": 12,
		"totalTours": 10,
		"totalDays": 295,
		"totalCountries": 13,
		"totalStates": 41,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 1412,
		"schedulePercent": 0.593829,
		"totalGuests": 188,
		"guestPercent": 0.541518,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "Budweiser Stage",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 53
	},
	{
		"id": "fab1336",
		"email": "kat@streetrootspromo.com",
		"name": "Katina",
		"createdDate": "2022-06-20 16:52:01",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 228,
		"totalCountries": 4,
		"totalStates": 46,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 1602,
		"schedulePercent": 0.643623,
		"totalGuests": 1194,
		"guestPercent": 0.85684,
		"mostGuestDate": "2023-11-05 00:00:00",
		"mostGuestName": "The Concert Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 112
	},
	{
		"id": "cea59fd",
		"email": "mjwillf@icloud.com",
		"name": "Matthew",
		"createdDate": "2016-11-24 18:42:52",
		"totalOrganizations": 3,
		"totalTours": 11,
		"totalDays": 325,
		"totalCountries": 14,
		"totalStates": 40,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 1142,
		"schedulePercent": 0.524169,
		"totalGuests": 1141,
		"guestPercent": 0.847702,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 524
	},
	{
		"id": "ad1d59b",
		"email": "amy@goldiemgmt.com",
		"name": "Amy",
		"createdDate": "2020-11-05 06:28:47",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 40,
		"totalCountries": 3,
		"totalStates": 11,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 73,
		"schedulePercent": 0.0701894,
		"totalGuests": 171,
		"guestPercent": 0.530658,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Powerstation",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 88
	},
	{
		"id": "007db49",
		"email": "ccodella@concertswest.com",
		"name": "Charleene",
		"createdDate": "2021-09-06 18:27:36",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 249,
		"totalCountries": 6,
		"totalStates": 33,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 2708,
		"schedulePercent": 0.820156,
		"totalGuests": 274,
		"guestPercent": 0.590783,
		"mostGuestDate": "2023-11-05 00:00:00",
		"mostGuestName": "The Mission Ballroom",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 76
	},
	{
		"id": "4a42450",
		"email": "tonygittins@me.com",
		"name": "Tony",
		"createdDate": "2019-04-03 09:24:29",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 364,
		"totalCountries": 12,
		"totalStates": 38,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 1638,
		"schedulePercent": 0.650642,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f327c28",
		"email": "jeremyduboisproductions@gmail.com",
		"name": "Jeremy",
		"createdDate": "2017-04-05 16:44:03",
		"totalOrganizations": 15,
		"totalTours": 10,
		"totalDays": 1288,
		"totalCountries": 6,
		"totalStates": 38,
		"totalEvents": 177,
		"eventPercent": 0.765594,
		"totalScheduleItems": 4433,
		"schedulePercent": 0.942921,
		"totalGuests": 873,
		"guestPercent": 0.79261,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "Palacio de los Deportes",
		"mostGuestCity": "Ciudad de MÃ©xico",
		"mostGuestAmount": 79
	},
	{
		"id": "9b18c0a",
		"email": "youngmyungsong@icloud.com",
		"name": "Youngmyung",
		"createdDate": "2018-10-03 15:56:10",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 71,
		"totalCountries": 5,
		"totalStates": 8,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 127,
		"schedulePercent": 0.107535,
		"totalGuests": 87,
		"guestPercent": 0.460734,
		"mostGuestDate": "2023-09-27 00:00:00",
		"mostGuestName": "Lafayette",
		"mostGuestCity": "London",
		"mostGuestAmount": 50
	},
	{
		"id": "c3e2354",
		"email": "brooklyn.larry@me.com",
		"name": "Laurens",
		"createdDate": "2016-11-25 07:46:07",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 115,
		"totalCountries": 14,
		"totalStates": 40,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 639,
		"schedulePercent": 0.357966,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ac5a027",
		"email": "erika.danielson@riksteatern.se",
		"name": "Erika",
		"createdDate": "2022-03-07 22:25:05",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 146,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 362,
		"schedulePercent": 0.238776,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "80f8ab6",
		"email": "chris@achilleamusic.net",
		"name": "Chris",
		"createdDate": "2017-09-14 01:23:18",
		"totalOrganizations": 12,
		"totalTours": 6,
		"totalDays": 134,
		"totalCountries": 5,
		"totalStates": 30,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 427,
		"schedulePercent": 0.266587,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "106beb2",
		"email": "fanclubwallet@gmail.com",
		"name": "Hannah",
		"createdDate": "2022-06-13 18:38:42",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 40,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 172,
		"schedulePercent": 0.133757,
		"totalGuests": 196,
		"guestPercent": 0.546947,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "The Danforth Music Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 54
	},
	{
		"id": "4dd54d2",
		"email": "katiemuldoon@gmail.com",
		"name": "Katie",
		"createdDate": "2011-06-14 22:02:44",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 207,
		"totalCountries": 6,
		"totalStates": 35,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 1710,
		"schedulePercent": 0.667461,
		"totalGuests": 1432,
		"guestPercent": 0.885446,
		"mostGuestDate": "2023-09-06 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 66
	},
	{
		"id": "28289e2",
		"email": "ambermrhodes@gmail.com",
		"name": "Amber",
		"createdDate": "2022-03-14 19:38:50",
		"totalOrganizations": 2,
		"totalTours": 16,
		"totalDays": 344,
		"totalCountries": 17,
		"totalStates": 74,
		"totalEvents": 237,
		"eventPercent": 0.858429,
		"totalScheduleItems": 3188,
		"schedulePercent": 0.868759,
		"totalGuests": 1260,
		"guestPercent": 0.866508,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Royal Festival Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 35
	},
	{
		"id": "711e48a",
		"email": "simeon@mega-events.co.uk",
		"name": "simeon",
		"createdDate": "2021-05-04 15:57:56",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 43,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 255,
		"schedulePercent": 0.183287,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cf13dfa",
		"email": "simonrgarner@gmail.com",
		"name": "Simon",
		"createdDate": "2011-01-17 22:41:39",
		"totalOrganizations": 8,
		"totalTours": 25,
		"totalDays": 360,
		"totalCountries": 12,
		"totalStates": 46,
		"totalEvents": 156,
		"eventPercent": 0.718051,
		"totalScheduleItems": 2452,
		"schedulePercent": 0.785989,
		"totalGuests": 266,
		"guestPercent": 0.587869,
		"mostGuestDate": "2023-04-17 00:00:00",
		"mostGuestName": "Salle Wilfrid-Pelletier, EntrÃ©e des artistes",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 29
	},
	{
		"id": "1b1256b",
		"email": "marknoel@triple8mgmt.com",
		"name": "Mark",
		"createdDate": "2016-10-24 21:48:04",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 200,
		"totalCountries": 2,
		"totalStates": 41,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1144,
		"schedulePercent": 0.525361,
		"totalGuests": 361,
		"guestPercent": 0.635413,
		"mostGuestDate": "2023-01-07 00:00:00",
		"mostGuestName": "Billy Bob's",
		"mostGuestCity": "Ft. Worth",
		"mostGuestAmount": 108
	},
	{
		"id": "37a249e",
		"email": "tc3tourmanager@gmail.com",
		"name": "TC3",
		"createdDate": "2014-03-12 03:52:32",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 62,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 201,
		"schedulePercent": 0.151106,
		"totalGuests": 75,
		"guestPercent": 0.450007,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "Little Rock Music Hall",
		"mostGuestCity": "",
		"mostGuestAmount": 20
	},
	{
		"id": "cc4424c",
		"email": "daniel.romanoff@redlightmanagement.com",
		"name": "Daniel",
		"createdDate": "2013-01-11 19:09:27",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 202,
		"totalCountries": 6,
		"totalStates": 53,
		"totalEvents": 133,
		"eventPercent": 0.656602,
		"totalScheduleItems": 952,
		"schedulePercent": 0.466163,
		"totalGuests": 348,
		"guestPercent": 0.628658,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "Greenfield Lake Amphitheatre",
		"mostGuestCity": "Wilmington",
		"mostGuestAmount": 20
	},
	{
		"id": "e69ba9e",
		"email": "avanthigovender@gmail.com",
		"name": "Avanthi",
		"createdDate": "2016-10-31 14:21:24",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 567,
		"totalCountries": 5,
		"totalStates": 39,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 1746,
		"schedulePercent": 0.67395,
		"totalGuests": 1408,
		"guestPercent": 0.883592,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 70
	},
	{
		"id": "273a4bb",
		"email": "james@berlintoursupport.com",
		"name": "James",
		"createdDate": "2016-06-22 11:28:17",
		"totalOrganizations": 12,
		"totalTours": 2,
		"totalDays": 45,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 303,
		"schedulePercent": 0.209509,
		"totalGuests": 147,
		"guestPercent": 0.511323,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "The Metro Theatre",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 82
	},
	{
		"id": "fd643cd",
		"email": "rocco.caruso@wwecorp.com",
		"name": "Rocco",
		"createdDate": "2018-12-06 13:28:26",
		"totalOrganizations": 6,
		"totalTours": 78,
		"totalDays": 349,
		"totalCountries": 8,
		"totalStates": 55,
		"totalEvents": 204,
		"eventPercent": 0.813005,
		"totalScheduleItems": 3055,
		"schedulePercent": 0.856708,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "927e1be",
		"email": "sarah.donelly@mushroomgroup.com",
		"name": "Sarah",
		"createdDate": "2013-05-06 01:15:34",
		"totalOrganizations": 68,
		"totalTours": 25,
		"totalDays": 308,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 2000,
		"schedulePercent": 0.71911,
		"totalGuests": 363,
		"guestPercent": 0.637134,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "The Metro Theatre",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 47
	},
	{
		"id": "dacb851",
		"email": "info@laurastevenson.net",
		"name": "Mike",
		"createdDate": "2019-11-25 01:25:48",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 58,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 109,
		"schedulePercent": 0.0961462,
		"totalGuests": 73,
		"guestPercent": 0.447755,
		"mostGuestDate": "2023-04-25 00:00:00",
		"mostGuestName": "Highland Park Ebell Club",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 18
	},
	{
		"id": "61d76e1",
		"email": "rosannafreedman@gmail.com",
		"name": "Rosanna",
		"createdDate": "2014-11-30 14:37:19",
		"totalOrganizations": 18,
		"totalTours": 15,
		"totalDays": 288,
		"totalCountries": 18,
		"totalStates": 59,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 1693,
		"schedulePercent": 0.663356,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9a040fc",
		"email": "adrian@7commedia.com",
		"name": "Adrian",
		"createdDate": "2020-03-11 13:59:57",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 232,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 1020,
		"schedulePercent": 0.490928,
		"totalGuests": 18,
		"guestPercent": 0.364455,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Tingley Coliseum",
		"mostGuestCity": "Albuquerque",
		"mostGuestAmount": 18
	},
	{
		"id": "6dad165",
		"email": "bendavistouring@gmail.com",
		"name": "Ben",
		"createdDate": "2014-07-23 08:40:38",
		"totalOrganizations": 11,
		"totalTours": 16,
		"totalDays": 530,
		"totalCountries": 15,
		"totalStates": 81,
		"totalEvents": 276,
		"eventPercent": 0.898292,
		"totalScheduleItems": 4063,
		"schedulePercent": 0.92597,
		"totalGuests": 1665,
		"guestPercent": 0.908886,
		"mostGuestDate": "2023-08-29 00:00:00",
		"mostGuestName": "Riverside Municipal Auditorium",
		"mostGuestCity": "Riverside",
		"mostGuestAmount": 64
	},
	{
		"id": "f733183",
		"email": "gregoryadean@me.com",
		"name": "Greg",
		"createdDate": "2019-12-12 21:00:50",
		"totalOrganizations": 13,
		"totalTours": 6,
		"totalDays": 275,
		"totalCountries": 6,
		"totalStates": 36,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 1997,
		"schedulePercent": 0.717786,
		"totalGuests": 108,
		"guestPercent": 0.481128,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Allianz Parque",
		"mostGuestCity": "SÃ£o Paulo",
		"mostGuestAmount": 29
	},
	{
		"id": "046e877",
		"email": "s.fergusontm@gmail.com",
		"name": "Shane",
		"createdDate": "2023-01-31 00:16:32",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 228,
		"totalCountries": 5,
		"totalStates": 37,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 1111,
		"schedulePercent": 0.513972,
		"totalGuests": 19,
		"guestPercent": 0.366971,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Bank of New Hampshire Pavilion",
		"mostGuestCity": "Gilford",
		"mostGuestAmount": 7
	},
	{
		"id": "d81a4ad",
		"email": "martin.svensson@kulturmejeriet.se",
		"name": "Martin",
		"createdDate": "2017-11-27 12:34:30",
		"totalOrganizations": 11,
		"totalTours": 9,
		"totalDays": 675,
		"totalCountries": 6,
		"totalStates": 9,
		"totalEvents": 210,
		"eventPercent": 0.823732,
		"totalScheduleItems": 1559,
		"schedulePercent": 0.631837,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "Mejeriet",
		"mostGuestCity": "Lund",
		"mostGuestAmount": 1
	},
	{
		"id": "c2b33b1",
		"email": "andrewbrasswell@gmail.com",
		"name": "Andrew",
		"createdDate": "2022-03-01 21:38:36",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 306,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 401,
		"schedulePercent": 0.25692,
		"totalGuests": 307,
		"guestPercent": 0.607072,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 95
	},
	{
		"id": "c7eee68",
		"email": "elvie@travelstars.net",
		"name": "Elvie",
		"createdDate": "2013-07-10 14:39:27",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 1105,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 170,
		"eventPercent": 0.752086,
		"totalScheduleItems": 1809,
		"schedulePercent": 0.687326,
		"totalGuests": 299,
		"guestPercent": 0.602039,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Chester Frost Park",
		"mostGuestCity": "Hixson",
		"mostGuestAmount": 44
	},
	{
		"id": "a3dbf5f",
		"email": "jbfaulkner@gmail.com",
		"name": "Jacob",
		"createdDate": "2019-03-07 02:22:39",
		"totalOrganizations": 4,
		"totalTours": 15,
		"totalDays": 234,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 350,
		"schedulePercent": 0.232949,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "84b83eb",
		"email": "brian@olddominionband.com",
		"name": "Brian",
		"createdDate": "2023-02-05 14:56:56",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 104,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 390,
		"schedulePercent": 0.252947,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9dfac7e",
		"email": "tm@bigasstouring.com",
		"name": "Kara",
		"createdDate": "2021-11-28 20:12:21",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 298,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 1003,
		"schedulePercent": 0.485896,
		"totalGuests": 121,
		"guestPercent": 0.490928,
		"mostGuestDate": "2023-10-12 00:00:00",
		"mostGuestName": "Buckhead Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 25
	},
	{
		"id": "2bb13c3",
		"email": "jeroen.de.groot@ncb.no",
		"name": "Jeroen",
		"createdDate": "2021-11-04 10:42:58",
		"totalOrganizations": 1,
		"totalTours": 15,
		"totalDays": 91,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 184,
		"schedulePercent": 0.141306,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "00ea402",
		"email": "paul@froula.net",
		"name": "Paul",
		"createdDate": "2011-06-28 02:08:30",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 92,
		"totalCountries": 4,
		"totalStates": 20,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 689,
		"schedulePercent": 0.376242,
		"totalGuests": 179,
		"guestPercent": 0.535426,
		"mostGuestDate": "2023-11-06 00:00:00",
		"mostGuestName": "Pantages Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 56
	},
	{
		"id": "483ac3c",
		"email": "jenna@messinatouring.com",
		"name": "Jenna",
		"createdDate": "2017-03-24 21:13:00",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 772,
		"totalCountries": 3,
		"totalStates": 35,
		"totalEvents": 227,
		"eventPercent": 0.844391,
		"totalScheduleItems": 2948,
		"schedulePercent": 0.846908,
		"totalGuests": 4550,
		"guestPercent": 0.988611,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Paycom Center",
		"mostGuestCity": "Oklahoma City",
		"mostGuestAmount": 167
	},
	{
		"id": "76a45d0",
		"email": "alisonhocking@me.com",
		"name": "Alison",
		"createdDate": "2012-03-19 23:38:31",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 389,
		"totalCountries": 7,
		"totalStates": 39,
		"totalEvents": 220,
		"eventPercent": 0.83671,
		"totalScheduleItems": 2251,
		"schedulePercent": 0.758575,
		"totalGuests": 2036,
		"guestPercent": 0.935505,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Don Valley Bowl",
		"mostGuestCity": "Sheffield",
		"mostGuestAmount": 108
	},
	{
		"id": "9dacf4b",
		"email": "goff@theproduction.co.nz",
		"name": "Paul",
		"createdDate": "2015-01-22 01:36:26",
		"totalOrganizations": 21,
		"totalTours": 42,
		"totalDays": 531,
		"totalCountries": 7,
		"totalStates": 29,
		"totalEvents": 203,
		"eventPercent": 0.811681,
		"totalScheduleItems": 2812,
		"schedulePercent": 0.833664,
		"totalGuests": 178,
		"guestPercent": 0.534896,
		"mostGuestDate": "2023-09-21 00:00:00",
		"mostGuestName": "Michael Fowler Centre",
		"mostGuestCity": "Wellington",
		"mostGuestAmount": 34
	},
	{
		"id": "0e67aa7",
		"email": "gunnar@bringeland.se",
		"name": "Gunnar",
		"createdDate": "2018-07-02 21:06:08",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 324,
		"totalCountries": 3,
		"totalStates": 4,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 429,
		"schedulePercent": 0.267779,
		"totalGuests": 137,
		"guestPercent": 0.504039,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "Slaktkyrkan",
		"mostGuestCity": "Johanneshov",
		"mostGuestAmount": 23
	},
	{
		"id": "21cb8f7",
		"email": "soren@sierra79.com",
		"name": "Soren",
		"createdDate": "2012-12-26 22:08:29",
		"totalOrganizations": 41,
		"totalTours": 22,
		"totalDays": 267,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 229,
		"eventPercent": 0.847305,
		"totalScheduleItems": 1361,
		"schedulePercent": 0.583234,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e864edb",
		"email": "dcs.hughes@gmail.com",
		"name": "Dale",
		"createdDate": "2017-05-03 03:54:29",
		"totalOrganizations": 16,
		"totalTours": 23,
		"totalDays": 1064,
		"totalCountries": 11,
		"totalStates": 47,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 2178,
		"schedulePercent": 0.747053,
		"totalGuests": 875,
		"guestPercent": 0.793008,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 121
	},
	{
		"id": "e43d076",
		"email": "esteban@nextofkin.dk",
		"name": "Esteban",
		"createdDate": "2023-11-08 12:35:49",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 55,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 437,
		"schedulePercent": 0.271355,
		"totalGuests": 255,
		"guestPercent": 0.58191,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "Riverside Municipal Auditorium",
		"mostGuestCity": "Riverside",
		"mostGuestAmount": 46
	},
	{
		"id": "e0a0ee7",
		"email": "sydney@cjbs.uk",
		"name": "Sydney",
		"createdDate": "2022-05-27 10:26:01",
		"totalOrganizations": 11,
		"totalTours": 9,
		"totalDays": 669,
		"totalCountries": 18,
		"totalStates": 66,
		"totalEvents": 179,
		"eventPercent": 0.769037,
		"totalScheduleItems": 2381,
		"schedulePercent": 0.777513,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bbc64a5",
		"email": "lucasmtouring@gmail.com",
		"name": "Lucas",
		"createdDate": "2017-07-19 18:03:15",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 152,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 671,
		"schedulePercent": 0.36909,
		"totalGuests": 136,
		"guestPercent": 0.503377,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "El Club",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 30
	},
	{
		"id": "cd22474",
		"email": "daveo@theconcertbiz.com",
		"name": "Dave",
		"createdDate": "2018-11-14 00:28:20",
		"totalOrganizations": 15,
		"totalTours": 9,
		"totalDays": 623,
		"totalCountries": 10,
		"totalStates": 41,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 1374,
		"schedulePercent": 0.58628,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7a0ef01",
		"email": "meryl.luzzi@gmail.com",
		"name": "Meryl ",
		"createdDate": "2022-05-23 19:46:15",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 100,
		"totalCountries": 10,
		"totalStates": 32,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 610,
		"schedulePercent": 0.345914,
		"totalGuests": 33,
		"guestPercent": 0.394252,
		"mostGuestDate": "2023-09-22 00:00:00",
		"mostGuestName": "The Ritz",
		"mostGuestCity": "Raleigh",
		"mostGuestAmount": 15
	},
	{
		"id": "d13e875",
		"email": "jared@chapmanmanagement.com",
		"name": "Jared",
		"createdDate": "2022-08-31 18:34:26",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 192,
		"totalCountries": 3,
		"totalStates": 30,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 713,
		"schedulePercent": 0.385909,
		"totalGuests": 49,
		"guestPercent": 0.419944,
		"mostGuestDate": "2023-10-29 00:00:00",
		"mostGuestName": "Hyatt Regency Newport Beach",
		"mostGuestCity": "Newport Beach",
		"mostGuestAmount": 19
	},
	{
		"id": "52c4ccf",
		"email": "bbaleskarlin@gmail.com",
		"name": "Bales",
		"createdDate": "2019-01-19 15:52:29",
		"totalOrganizations": 7,
		"totalTours": 12,
		"totalDays": 858,
		"totalCountries": 18,
		"totalStates": 77,
		"totalEvents": 363,
		"eventPercent": 0.947424,
		"totalScheduleItems": 7744,
		"schedulePercent": 0.992584,
		"totalGuests": 1886,
		"guestPercent": 0.927162,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 98
	},
	{
		"id": "0c61dec",
		"email": "tyler@sauce-studio.com",
		"name": "Tyler",
		"createdDate": "2019-03-23 02:12:15",
		"totalOrganizations": 2,
		"totalTours": 10,
		"totalDays": 216,
		"totalCountries": 9,
		"totalStates": 33,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 1263,
		"schedulePercent": 0.555556,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "John Cain Arena",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 1
	},
	{
		"id": "7c70127",
		"email": "booking@conorgray.com",
		"name": "Conor",
		"createdDate": "2021-07-12 18:23:24",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 706,
		"totalCountries": 15,
		"totalStates": 55,
		"totalEvents": 160,
		"eventPercent": 0.72838,
		"totalScheduleItems": 1759,
		"schedulePercent": 0.676202,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-02-23 00:00:00",
		"mostGuestName": "Big Night Live",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 6
	},
	{
		"id": "d9c037f",
		"email": "vrogers@cityparksfoundation.org",
		"name": "Vernil",
		"createdDate": "2019-05-01 15:20:38",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cc08055",
		"email": "thehumanproblem@gmail.com",
		"name": "Ryan",
		"createdDate": "2011-08-31 18:11:23",
		"totalOrganizations": 2,
		"totalTours": 18,
		"totalDays": 145,
		"totalCountries": 6,
		"totalStates": 33,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 554,
		"schedulePercent": 0.323136,
		"totalGuests": 56,
		"guestPercent": 0.429744,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "Brighton Music Hall",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 21
	},
	{
		"id": "406dc93",
		"email": "sam@mundarzi.com",
		"name": "Sam",
		"createdDate": "2019-02-13 00:22:19",
		"totalOrganizations": 14,
		"totalTours": 16,
		"totalDays": 532,
		"totalCountries": 19,
		"totalStates": 45,
		"totalEvents": 280,
		"eventPercent": 0.901867,
		"totalScheduleItems": 2863,
		"schedulePercent": 0.838962,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Echo Arena",
		"mostGuestCity": "Liverpool",
		"mostGuestAmount": 2
	},
	{
		"id": "ceaa10b",
		"email": "Stephanie.Davenport@wmg.com",
		"name": "Stephanie",
		"createdDate": "2017-08-10 03:09:19",
		"totalOrganizations": 50,
		"totalTours": 45,
		"totalDays": 15128,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 1933,
		"eventPercent": 0.995497,
		"totalScheduleItems": 742,
		"schedulePercent": 0.397431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4aedf5e",
		"email": "seb@elephantriders.co.uk",
		"name": "Seb",
		"createdDate": "2012-10-14 14:37:12",
		"totalOrganizations": 15,
		"totalTours": 17,
		"totalDays": 467,
		"totalCountries": 15,
		"totalStates": 68,
		"totalEvents": 208,
		"eventPercent": 0.819891,
		"totalScheduleItems": 2420,
		"schedulePercent": 0.782545,
		"totalGuests": 763,
		"guestPercent": 0.767316,
		"mostGuestDate": "2023-05-02 00:00:00",
		"mostGuestName": "Avant Gardner",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 60
	},
	{
		"id": "43fc6a9",
		"email": "tomgreenevents@gmail.com",
		"name": "Tom",
		"createdDate": "2019-08-21 13:35:10",
		"totalOrganizations": 10,
		"totalTours": 28,
		"totalDays": 823,
		"totalCountries": 11,
		"totalStates": 40,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 1938,
		"schedulePercent": 0.709045,
		"totalGuests": 295,
		"guestPercent": 0.599788,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "CMA Theater at the Country Music Hall of Fame and Museum",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 41
	},
	{
		"id": "793716d",
		"email": "Shokes84@gmail.com",
		"name": "Ashoka",
		"createdDate": "2013-04-17 14:48:42",
		"totalOrganizations": 11,
		"totalTours": 12,
		"totalDays": 463,
		"totalCountries": 11,
		"totalStates": 54,
		"totalEvents": 204,
		"eventPercent": 0.813005,
		"totalScheduleItems": 2477,
		"schedulePercent": 0.790094,
		"totalGuests": 1464,
		"guestPercent": 0.889419,
		"mostGuestDate": "2023-02-01 00:00:00",
		"mostGuestName": "Commodore Ballroom",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 88
	},
	{
		"id": "7bad923",
		"email": "rubblebucket@rollcallrecords.com",
		"name": "Email",
		"createdDate": "2018-07-12 16:18:13",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 76,
		"totalCountries": 1,
		"totalStates": 23,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 243,
		"schedulePercent": 0.17799,
		"totalGuests": 403,
		"guestPercent": 0.654748,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 61
	},
	{
		"id": "81fa626",
		"email": "oosmanamir@gmail.com",
		"name": "Amir",
		"createdDate": "2014-04-17 20:12:25",
		"totalOrganizations": 14,
		"totalTours": 14,
		"totalDays": 1761,
		"totalCountries": 13,
		"totalStates": 63,
		"totalEvents": 257,
		"eventPercent": 0.882929,
		"totalScheduleItems": 4147,
		"schedulePercent": 0.93087,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "Vanquish Nightclub",
		"mostGuestCity": "Minneapolis",
		"mostGuestAmount": 4
	},
	{
		"id": "f95aaa6",
		"email": "sburke95@icloud.com",
		"name": "Steven",
		"createdDate": "2018-09-05 18:33:08",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 246,
		"totalCountries": 8,
		"totalStates": 45,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 1788,
		"schedulePercent": 0.682559,
		"totalGuests": 1222,
		"guestPercent": 0.862005,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 100
	},
	{
		"id": "f2ff67d",
		"email": "adt@cmpent.com",
		"name": "Andrew",
		"createdDate": "2013-01-19 19:46:01",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 1037,
		"totalCountries": 1,
		"totalStates": 28,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 998,
		"schedulePercent": 0.484439,
		"totalGuests": 19,
		"guestPercent": 0.366971,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "East Somerset Baptist Church",
		"mostGuestCity": "Somerset",
		"mostGuestAmount": 8
	},
	{
		"id": "fd14fcc",
		"email": "pdubs72@gmail.com",
		"name": "Peter",
		"createdDate": "2019-06-28 20:13:19",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 368,
		"totalCountries": 14,
		"totalStates": 45,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 1286,
		"schedulePercent": 0.562177,
		"totalGuests": 1245,
		"guestPercent": 0.863991,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Broncos Stadium at Mile High Station",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 255
	},
	{
		"id": "0dcf5ca",
		"email": "selie@rangemp.com",
		"name": "Sasha",
		"createdDate": "2023-04-08 04:09:55",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 82,
		"totalCountries": 4,
		"totalStates": 11,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 524,
		"schedulePercent": 0.309098,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-11-15 00:00:00",
		"mostGuestName": "Maida Vale",
		"mostGuestCity": "London",
		"mostGuestAmount": 4
	},
	{
		"id": "63940ce",
		"email": "luke.webb@hillsong.com",
		"name": "Luke",
		"createdDate": "2010-04-20 01:21:14",
		"totalOrganizations": 16,
		"totalTours": 9,
		"totalDays": 396,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 2387,
		"schedulePercent": 0.77897,
		"totalGuests": 678,
		"guestPercent": 0.747451,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Texas Trust CU Theatre",
		"mostGuestCity": "Grand Prairie",
		"mostGuestAmount": 69
	},
	{
		"id": "cad7f10",
		"email": "ffmastertour@gmail.com",
		"name": "Elizabeth",
		"createdDate": "2022-01-17 15:05:01",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 77,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 353,
		"schedulePercent": 0.234936,
		"totalGuests": 329,
		"guestPercent": 0.618064,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "The Minack Theatre",
		"mostGuestCity": "Penzance",
		"mostGuestAmount": 30
	},
	{
		"id": "ca14bbc",
		"email": "brittonbillik@mac.com",
		"name": "Britton",
		"createdDate": "2010-12-15 18:34:56",
		"totalOrganizations": 21,
		"totalTours": 20,
		"totalDays": 1188,
		"totalCountries": 19,
		"totalStates": 76,
		"totalEvents": 442,
		"eventPercent": 0.966759,
		"totalScheduleItems": 9992,
		"schedulePercent": 0.997219,
		"totalGuests": 6708,
		"guestPercent": 0.996557,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 240
	},
	{
		"id": "2d404f9",
		"email": "dclynch@mac.com",
		"name": "Dermot",
		"createdDate": "2011-08-22 19:37:20",
		"totalOrganizations": 11,
		"totalTours": 16,
		"totalDays": 637,
		"totalCountries": 19,
		"totalStates": 63,
		"totalEvents": 195,
		"eventPercent": 0.796318,
		"totalScheduleItems": 3992,
		"schedulePercent": 0.922792,
		"totalGuests": 271,
		"guestPercent": 0.589988,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Orpheum Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 55
	},
	{
		"id": "f453c5a",
		"email": "Youngwalttours@gmail.com",
		"name": "Walter",
		"createdDate": "2017-05-15 20:54:42",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 37,
		"totalCountries": 3,
		"totalStates": 8,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 362,
		"schedulePercent": 0.238776,
		"totalGuests": 631,
		"guestPercent": 0.734207,
		"mostGuestDate": "2023-02-04 00:00:00",
		"mostGuestName": "Accor Stadium",
		"mostGuestCity": "Sydney Olympic Park",
		"mostGuestAmount": 164
	},
	{
		"id": "878e539",
		"email": "jdandrew@mac.com",
		"name": "J.D.",
		"createdDate": "2019-05-23 04:30:21",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 102,
		"totalCountries": 4,
		"totalStates": 27,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 271,
		"schedulePercent": 0.192822,
		"totalGuests": 182,
		"guestPercent": 0.538074,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "The Garage",
		"mostGuestCity": "London",
		"mostGuestAmount": 37
	},
	{
		"id": "dab5e9d",
		"email": "ArbelaezAudio@gmail.com",
		"name": "Daniel",
		"createdDate": "2019-05-29 19:40:47",
		"totalOrganizations": 10,
		"totalTours": 17,
		"totalDays": 896,
		"totalCountries": 11,
		"totalStates": 52,
		"totalEvents": 184,
		"eventPercent": 0.77791,
		"totalScheduleItems": 2817,
		"schedulePercent": 0.834459,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-03-09 00:00:00",
		"mostGuestName": "Smoothie King Center",
		"mostGuestCity": "New Orleans",
		"mostGuestAmount": 2
	},
	{
		"id": "c694849",
		"email": "thewashboardunion@gmail.com",
		"name": "Hotel ",
		"createdDate": "2018-01-09 06:20:26",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 94,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 435,
		"schedulePercent": 0.270295,
		"totalGuests": 33,
		"guestPercent": 0.394252,
		"mostGuestDate": "2023-06-29 00:00:00",
		"mostGuestName": "Ponoka Stampede Grounds",
		"mostGuestCity": "Ponoka ",
		"mostGuestAmount": 14
	},
	{
		"id": "688e903",
		"email": "lotjehorvers@gmail.com",
		"name": "Lotte",
		"createdDate": "2010-05-05 18:57:29",
		"totalOrganizations": 18,
		"totalTours": 15,
		"totalDays": 538,
		"totalCountries": 14,
		"totalStates": 34,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 2077,
		"schedulePercent": 0.731294,
		"totalGuests": 56,
		"guestPercent": 0.429744,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Tivoli Gardens",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 49
	},
	{
		"id": "f5957df",
		"email": "nathan.burke@foreverlivingoriginals.com",
		"name": "Nathan",
		"createdDate": "2022-05-23 08:50:19",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 151,
		"totalCountries": 7,
		"totalStates": 25,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 1318,
		"schedulePercent": 0.57052,
		"totalGuests": 496,
		"guestPercent": 0.690637,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 53
	},
	{
		"id": "6ffd54e",
		"email": "brent@pacelive.com.au",
		"name": "Brent",
		"createdDate": "2018-09-13 03:53:52",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 159,
		"totalCountries": 4,
		"totalStates": 12,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1029,
		"schedulePercent": 0.492385,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1ad1b6b",
		"email": "evilpandastudios@gmail.com",
		"name": "Claudine",
		"createdDate": "2018-03-19 05:11:08",
		"totalOrganizations": 14,
		"totalTours": 9,
		"totalDays": 859,
		"totalCountries": 15,
		"totalStates": 54,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 3715,
		"schedulePercent": 0.907959,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "The SSE Arena, Wembley",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 3
	},
	{
		"id": "0300288",
		"email": "Benjamin.Kline@wmg.com",
		"name": "Ben",
		"createdDate": "2017-06-01 20:10:16",
		"totalOrganizations": 50,
		"totalTours": 45,
		"totalDays": 15128,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 1933,
		"eventPercent": 0.995497,
		"totalScheduleItems": 742,
		"schedulePercent": 0.397431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3e90b9d",
		"email": "Julian@harlekijnholland.com",
		"name": "Julian",
		"createdDate": "2019-09-18 13:50:53",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 288,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 792,
		"schedulePercent": 0.417825,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6384d6c",
		"email": "jonsortland@gmail.com",
		"name": "Jon",
		"createdDate": "2014-02-14 14:34:09",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 47,
		"totalCountries": 5,
		"totalStates": 14,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 206,
		"schedulePercent": 0.153754,
		"totalGuests": 10,
		"guestPercent": 0.343531,
		"mostGuestDate": "2023-11-29 00:00:00",
		"mostGuestName": "Wonder Ballroom",
		"mostGuestCity": "Portland",
		"mostGuestAmount": 4
	},
	{
		"id": "069b65d",
		"email": "Chase502@comcast.net",
		"name": "Jeff",
		"createdDate": "2014-09-03 04:51:27",
		"totalOrganizations": 7,
		"totalTours": 15,
		"totalDays": 627,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 1708,
		"schedulePercent": 0.666932,
		"totalGuests": 39,
		"guestPercent": 0.404185,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Tingley Coliseum",
		"mostGuestCity": "Albuquerque",
		"mostGuestAmount": 18
	},
	{
		"id": "6cb28b0",
		"email": "david@punkdafunk.com",
		"name": "David",
		"createdDate": "2011-09-21 16:19:45",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 193,
		"totalCountries": 11,
		"totalStates": 38,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 1581,
		"schedulePercent": 0.636869,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f51adb5",
		"email": "touring@moretalent.com.au",
		"name": "Tobie",
		"createdDate": "2020-07-10 01:30:18",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 381,
		"totalCountries": 4,
		"totalStates": 12,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 305,
		"schedulePercent": 0.210171,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "33fd597",
		"email": "lukasmwolf@gmail.com",
		"name": "Lukas",
		"createdDate": "2022-07-19 22:24:53",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 133,
		"totalCountries": 3,
		"totalStates": 17,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 694,
		"schedulePercent": 0.37836,
		"totalGuests": 101,
		"guestPercent": 0.474904,
		"mostGuestDate": "2023-04-13 00:00:00",
		"mostGuestName": "The Pomona Fox Theater",
		"mostGuestCity": "Pomona",
		"mostGuestAmount": 33
	},
	{
		"id": "06d3b42",
		"email": "tim.parsiegla@googlemail.com",
		"name": "Tim",
		"createdDate": "2022-04-23 12:46:57",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 98,
		"totalCountries": 9,
		"totalStates": 13,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 1056,
		"schedulePercent": 0.499669,
		"totalGuests": 4046,
		"guestPercent": 0.983976,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Olympiastadion Berlin",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 794
	},
	{
		"id": "2a70455",
		"email": "rudy@thewarningband.com",
		"name": "Rudy",
		"createdDate": "2019-06-27 00:11:22",
		"totalOrganizations": 4,
		"totalTours": 10,
		"totalDays": 544,
		"totalCountries": 16,
		"totalStates": 69,
		"totalEvents": 172,
		"eventPercent": 0.755661,
		"totalScheduleItems": 2726,
		"schedulePercent": 0.822805,
		"totalGuests": 240,
		"guestPercent": 0.573434,
		"mostGuestDate": "2023-05-02 00:00:00",
		"mostGuestName": "Regent Theater DTLA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 47
	},
	{
		"id": "58092ea",
		"email": "stiljah@gmail.com",
		"name": "Tiago",
		"createdDate": "2017-02-17 00:11:26",
		"totalOrganizations": 12,
		"totalTours": 9,
		"totalDays": 337,
		"totalCountries": 10,
		"totalStates": 14,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 426,
		"schedulePercent": 0.26619,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-08-05 00:00:00",
		"mostGuestName": "Reggae Geel",
		"mostGuestCity": "",
		"mostGuestAmount": 2
	},
	{
		"id": "fdf8d63",
		"email": "damo@blackfx.co.uk",
		"name": "Damo",
		"createdDate": "2022-02-15 22:49:01",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 100,
		"totalCountries": 7,
		"totalStates": 13,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 457,
		"schedulePercent": 0.279566,
		"totalGuests": 37,
		"guestPercent": 0.400212,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "Fuga",
		"mostGuestCity": "Bratislava-StarÃ© Mesto",
		"mostGuestAmount": 22
	},
	{
		"id": "386b061",
		"email": "Monomanq@gmail.com",
		"name": "Ryan",
		"createdDate": "2014-09-21 21:13:05",
		"totalOrganizations": 17,
		"totalTours": 15,
		"totalDays": 636,
		"totalCountries": 21,
		"totalStates": 74,
		"totalEvents": 250,
		"eventPercent": 0.872335,
		"totalScheduleItems": 3353,
		"schedulePercent": 0.883459,
		"totalGuests": 826,
		"guestPercent": 0.782678,
		"mostGuestDate": "2023-09-27 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 80
	},
	{
		"id": "d74d046",
		"email": "Marjorie.Boivin@musicor.quebecor.com",
		"name": "Marjorie",
		"createdDate": "2019-06-07 21:51:58",
		"totalOrganizations": 35,
		"totalTours": 20,
		"totalDays": 587,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 540,
		"eventPercent": 0.978811,
		"totalScheduleItems": 2726,
		"schedulePercent": 0.822805,
		"totalGuests": 5678,
		"guestPercent": 0.994305,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "ThÃ©Ã¢tre St-Denis",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 204
	},
	{
		"id": "6799bdb",
		"email": "fount.lynch@wmg.com",
		"name": "Fount",
		"createdDate": "2022-06-21 18:23:28",
		"totalOrganizations": 45,
		"totalTours": 43,
		"totalDays": 22899,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 2710,
		"eventPercent": 0.99947,
		"totalScheduleItems": 1462,
		"schedulePercent": 0.606277,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "44f4698",
		"email": "todd@sandboxmgmt.com",
		"name": "Todd",
		"createdDate": "2011-06-02 15:31:35",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 5,
		"totalStates": 33,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 1232,
		"schedulePercent": 0.547874,
		"totalGuests": 70,
		"guestPercent": 0.445636,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "San Antonio Stock Show & Rodeo",
		"mostGuestCity": "San Antonio",
		"mostGuestAmount": 25
	},
	{
		"id": "5e68ca6",
		"email": "orthodoxtn@gmail.com",
		"name": "Adam ",
		"createdDate": "2023-05-31 15:32:58",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 71,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 529,
		"schedulePercent": 0.312409,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Bottom Lounge",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 9
	},
	{
		"id": "2e73bf8",
		"email": "Jameslow1254@gmail.com",
		"name": "James",
		"createdDate": "2022-09-13 17:58:00",
		"totalOrganizations": 4,
		"totalTours": 13,
		"totalDays": 222,
		"totalCountries": 4,
		"totalStates": 38,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 1393,
		"schedulePercent": 0.589856,
		"totalGuests": 641,
		"guestPercent": 0.736326,
		"mostGuestDate": "2023-05-12 00:00:00",
		"mostGuestName": "Billy Bob's Texas",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 43
	},
	{
		"id": "14a4978",
		"email": "karen.soskin@gmail.com",
		"name": "Karen",
		"createdDate": "2014-04-10 19:39:35",
		"totalOrganizations": 9,
		"totalTours": 5,
		"totalDays": 760,
		"totalCountries": 5,
		"totalStates": 26,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 1323,
		"schedulePercent": 0.57211,
		"totalGuests": 431,
		"guestPercent": 0.66521,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 121
	},
	{
		"id": "af2fbfb",
		"email": "will@championsofmagic.co.uk",
		"name": "Will",
		"createdDate": "2019-08-16 19:38:55",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 118,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 278,
		"schedulePercent": 0.196795,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "41a2194",
		"email": "aux.tourmanager@sebastianyatra.com",
		"name": "Juliana",
		"createdDate": "2020-04-12 19:57:29",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 345,
		"totalCountries": 6,
		"totalStates": 28,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 1424,
		"schedulePercent": 0.597669,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e5800c9",
		"email": "garrenvolper@gmail.com",
		"name": "Garren",
		"createdDate": "2019-10-30 21:31:35",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 111,
		"totalCountries": 1,
		"totalStates": 29,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 701,
		"schedulePercent": 0.381142,
		"totalGuests": 692,
		"guestPercent": 0.751424,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "The Mission Ballroom",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 59
	},
	{
		"id": "f841df0",
		"email": "sawa@yumebanchi.jp",
		"name": "Hiromichi",
		"createdDate": "2023-04-06 22:12:31",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 108,
		"totalCountries": 14,
		"totalStates": 26,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 387,
		"schedulePercent": 0.251622,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "735419a",
		"email": "produktionsleitung@pur.de",
		"name": "Patrick",
		"createdDate": "2022-07-12 20:55:43",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 39,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 321,
		"schedulePercent": 0.218779,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "696587e",
		"email": "hannahmboren@gmail.com",
		"name": "Hannah",
		"createdDate": "2017-09-19 14:29:40",
		"totalOrganizations": 15,
		"totalTours": 35,
		"totalDays": 1224,
		"totalCountries": 12,
		"totalStates": 57,
		"totalEvents": 423,
		"eventPercent": 0.963184,
		"totalScheduleItems": 4851,
		"schedulePercent": 0.956827,
		"totalGuests": 1700,
		"guestPercent": 0.913257,
		"mostGuestDate": "2023-04-27 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 66
	},
	{
		"id": "2e1a386",
		"email": "stevie@steviedreads.co.uk",
		"name": "Stephen",
		"createdDate": "2014-01-21 23:40:46",
		"totalOrganizations": 6,
		"totalTours": 11,
		"totalDays": 510,
		"totalCountries": 8,
		"totalStates": 46,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 1512,
		"schedulePercent": 0.621242,
		"totalGuests": 308,
		"guestPercent": 0.608131,
		"mostGuestDate": "2023-10-02 00:00:00",
		"mostGuestName": "Regent Theater DTLA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 59
	},
	{
		"id": "92ae0df",
		"email": "paulnicholasslater@gmail.com",
		"name": "Paul",
		"createdDate": "2022-04-15 20:37:50",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 279,
		"totalCountries": 17,
		"totalStates": 51,
		"totalEvents": 174,
		"eventPercent": 0.759502,
		"totalScheduleItems": 1794,
		"schedulePercent": 0.68428,
		"totalGuests": 477,
		"guestPercent": 0.682823,
		"mostGuestDate": "2023-02-04 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 43
	},
	{
		"id": "6bb4c1b",
		"email": "tim@159manning.com",
		"name": "Tim",
		"createdDate": "2022-07-30 03:38:33",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 194,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 12,
		"schedulePercent": 0.0252947,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8da7f6a",
		"email": "ndieri.ba@zouave.net",
		"name": "Ndieri",
		"createdDate": "2022-04-29 13:57:30",
		"totalOrganizations": 10,
		"totalTours": 5,
		"totalDays": 297,
		"totalCountries": 6,
		"totalStates": 31,
		"totalEvents": 202,
		"eventPercent": 0.810091,
		"totalScheduleItems": 2292,
		"schedulePercent": 0.764402,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "Paloma",
		"mostGuestCity": "Nimes",
		"mostGuestAmount": 8
	},
	{
		"id": "dd43305",
		"email": "mehnerman@gmail.com",
		"name": "Alex",
		"createdDate": "2017-03-14 23:36:25",
		"totalOrganizations": 8,
		"totalTours": 11,
		"totalDays": 1228,
		"totalCountries": 4,
		"totalStates": 54,
		"totalEvents": 314,
		"eventPercent": 0.922527,
		"totalScheduleItems": 4167,
		"schedulePercent": 0.932194,
		"totalGuests": 954,
		"guestPercent": 0.813667,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 48
	},
	{
		"id": "99faad0",
		"email": "Chris.Fabiani@wmg.com",
		"name": "Chris",
		"createdDate": "2020-07-15 22:30:12",
		"totalOrganizations": 54,
		"totalTours": 48,
		"totalDays": 15567,
		"totalCountries": 16,
		"totalStates": 89,
		"totalEvents": 2207,
		"eventPercent": 0.998411,
		"totalScheduleItems": 2539,
		"schedulePercent": 0.797245,
		"totalGuests": 66,
		"guestPercent": 0.441001,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Houston Rodeo WIll Call Guest List",
		"mostGuestCity": "",
		"mostGuestAmount": 57
	},
	{
		"id": "b541283",
		"email": "Anthony@akariproductionco.com",
		"name": "Anthony",
		"createdDate": "2016-10-30 20:21:55",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 151,
		"totalCountries": 6,
		"totalStates": 36,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 1246,
		"schedulePercent": 0.551185,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0697bf6",
		"email": "marte@showlab.no",
		"name": "Marte Celina",
		"createdDate": "2017-10-03 09:17:06",
		"totalOrganizations": 15,
		"totalTours": 7,
		"totalDays": 91,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 677,
		"schedulePercent": 0.371342,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1830aab",
		"email": "evan.duran@me.com",
		"name": "Evan",
		"createdDate": "2012-07-19 18:38:09",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 237,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 775,
		"schedulePercent": 0.411071,
		"totalGuests": 406,
		"guestPercent": 0.65594,
		"mostGuestDate": "2023-04-13 00:00:00",
		"mostGuestName": "The Fisher Center for the Performing Arts",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 104
	},
	{
		"id": "d88968e",
		"email": "george@chambersmgt.com",
		"name": "George",
		"createdDate": "2015-06-18 09:58:34",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 465,
		"totalCountries": 6,
		"totalStates": 46,
		"totalEvents": 249,
		"eventPercent": 0.870746,
		"totalScheduleItems": 2608,
		"schedulePercent": 0.809826,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "df26a5b",
		"email": "pantea.moafi@fkpscorpio.se",
		"name": "Pantea",
		"createdDate": "2016-12-13 12:20:50",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 138,
		"totalCountries": 4,
		"totalStates": 19,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 505,
		"schedulePercent": 0.301152,
		"totalGuests": 552,
		"guestPercent": 0.710369,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Gullmarsvallen",
		"mostGuestCity": "Lysekil",
		"mostGuestAmount": 159
	},
	{
		"id": "a893eed",
		"email": "john@terrains.co",
		"name": "John",
		"createdDate": "2020-03-04 20:58:53",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 85,
		"totalCountries": 5,
		"totalStates": 16,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 246,
		"schedulePercent": 0.179976,
		"totalGuests": 11,
		"guestPercent": 0.345782,
		"mostGuestDate": "2023-05-28 00:00:00",
		"mostGuestName": "Brockwell Park",
		"mostGuestCity": "London",
		"mostGuestAmount": 11
	},
	{
		"id": "0aac9a9",
		"email": "mike@klikevents.net",
		"name": "Mike",
		"createdDate": "2015-11-10 16:30:33",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 70,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 513,
		"schedulePercent": 0.30539,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Bottom Lounge",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 1
	},
	{
		"id": "4520f60",
		"email": "michaelgeorgeoneil@gmail.com",
		"name": "Michael",
		"createdDate": "2013-10-25 20:06:31",
		"totalOrganizations": 11,
		"totalTours": 3,
		"totalDays": 223,
		"totalCountries": 7,
		"totalStates": 27,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 474,
		"schedulePercent": 0.286982,
		"totalGuests": 32,
		"guestPercent": 0.392531,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "She Rises Fest",
		"mostGuestCity": "Taos",
		"mostGuestAmount": 7
	},
	{
		"id": "b759de5",
		"email": "jarrodrbright@gmail.com",
		"name": "Jarrod",
		"createdDate": "2020-01-06 00:59:16",
		"totalOrganizations": 12,
		"totalTours": 16,
		"totalDays": 887,
		"totalCountries": 14,
		"totalStates": 63,
		"totalEvents": 289,
		"eventPercent": 0.908621,
		"totalScheduleItems": 3617,
		"schedulePercent": 0.902132,
		"totalGuests": 1444,
		"guestPercent": 0.88624,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 92
	},
	{
		"id": "e05c608",
		"email": "nafletcher93@gmail.com",
		"name": "Nathan",
		"createdDate": "2021-10-12 19:42:46",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 1089,
		"totalCountries": 4,
		"totalStates": 44,
		"totalEvents": 253,
		"eventPercent": 0.877765,
		"totalScheduleItems": 5134,
		"schedulePercent": 0.965567,
		"totalGuests": 1012,
		"guestPercent": 0.826778,
		"mostGuestDate": "2023-03-27 00:00:00",
		"mostGuestName": "City National Grove of Anaheim",
		"mostGuestCity": "Anaheim",
		"mostGuestAmount": 35
	},
	{
		"id": "859d765",
		"email": "Joshpetersen@mac.com",
		"name": "Josh",
		"createdDate": "2015-11-11 05:32:19",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 364,
		"totalCountries": 11,
		"totalStates": 42,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 524,
		"schedulePercent": 0.309098,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "71f19c4",
		"email": "Ryanmcmullanmusic@gmail.com",
		"name": "Ryan",
		"createdDate": "2015-05-25 17:58:23",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 39,
		"totalCountries": 5,
		"totalStates": 9,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 200,
		"schedulePercent": 0.150444,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "01f6e54",
		"email": "jamiehudson707@gmail.com",
		"name": "Jamie",
		"createdDate": "2021-11-30 23:45:15",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 1123,
		"totalCountries": 4,
		"totalStates": 53,
		"totalEvents": 235,
		"eventPercent": 0.855516,
		"totalScheduleItems": 4710,
		"schedulePercent": 0.951794,
		"totalGuests": 4584,
		"guestPercent": 0.989008,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Dickies Arena",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 433
	},
	{
		"id": "50fdb1f",
		"email": "realmalachioconnor@gmail.com",
		"name": "Malachi",
		"createdDate": "2023-08-21 22:14:58",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 107,
		"totalCountries": 5,
		"totalStates": 33,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 865,
		"schedulePercent": 0.441531,
		"totalGuests": 571,
		"guestPercent": 0.716991,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Marathon Music Works",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 57
	},
	{
		"id": "12195d4",
		"email": "sagen.sound@gmail.com",
		"name": "Carl",
		"createdDate": "2015-01-21 23:56:29",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 120,
		"totalCountries": 5,
		"totalStates": 23,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 1014,
		"schedulePercent": 0.489339,
		"totalGuests": 362,
		"guestPercent": 0.636472,
		"mostGuestDate": "2023-06-29 00:00:00",
		"mostGuestName": "Radio City Music Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 47
	},
	{
		"id": "a75949c",
		"email": "dakotaholden11@gmail.com",
		"name": "Dakota",
		"createdDate": "2022-05-13 18:22:18",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 102,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 266,
		"schedulePercent": 0.190041,
		"totalGuests": 41,
		"guestPercent": 0.407231,
		"mostGuestDate": "2023-11-05 00:00:00",
		"mostGuestName": "Asheville Music Hall",
		"mostGuestCity": "Asheville",
		"mostGuestAmount": 11
	},
	{
		"id": "b31fae2",
		"email": "JonathanJarrell@live.com",
		"name": "Jonathan",
		"createdDate": "2014-04-21 23:43:01",
		"totalOrganizations": 6,
		"totalTours": 15,
		"totalDays": 354,
		"totalCountries": 15,
		"totalStates": 71,
		"totalEvents": 236,
		"eventPercent": 0.857237,
		"totalScheduleItems": 3366,
		"schedulePercent": 0.884386,
		"totalGuests": 3498,
		"guestPercent": 0.977619,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Forum Melbourne",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 70
	},
	{
		"id": "5f27e26",
		"email": "ivonecgtz.tour@gmail.com",
		"name": "Ivone",
		"createdDate": "2023-03-11 05:23:50",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 560,
		"totalCountries": 5,
		"totalStates": 35,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 873,
		"schedulePercent": 0.444709,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-04-15 00:00:00",
		"mostGuestName": "El Rey Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 11
	},
	{
		"id": "abfe373",
		"email": "kirsty.heather.reid@outlook.com",
		"name": "Kirsty",
		"createdDate": "2023-08-18 00:29:42",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 31,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 297,
		"schedulePercent": 0.206595,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "825968a",
		"email": "jack@watchtowerentertainment.com",
		"name": "Jack",
		"createdDate": "2022-12-09 12:12:50",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 419,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 225,
		"schedulePercent": 0.1666,
		"totalGuests": 49,
		"guestPercent": 0.419944,
		"mostGuestDate": "2023-07-23 00:00:00",
		"mostGuestName": "Stages Music Arts",
		"mostGuestCity": "Cockeysville",
		"mostGuestAmount": 12
	},
	{
		"id": "344ea8f",
		"email": "jaymi@unifiedmusicgroup.com",
		"name": "Jaymi",
		"createdDate": "2022-07-07 01:48:38",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 149,
		"totalCountries": 4,
		"totalStates": 14,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 582,
		"schedulePercent": 0.332804,
		"totalGuests": 169,
		"guestPercent": 0.529466,
		"mostGuestDate": "2023-08-30 00:00:00",
		"mostGuestName": "Central Park SummerStage",
		"mostGuestCity": "New York",
		"mostGuestAmount": 66
	},
	{
		"id": "97bcc4f",
		"email": "agathe@bleucitron.net",
		"name": "Agathe ",
		"createdDate": "2021-11-01 17:34:07",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 1375,
		"totalCountries": 5,
		"totalStates": 26,
		"totalEvents": 183,
		"eventPercent": 0.775659,
		"totalScheduleItems": 3081,
		"schedulePercent": 0.859092,
		"totalGuests": 337,
		"guestPercent": 0.621375,
		"mostGuestDate": "2023-06-13 00:00:00",
		"mostGuestName": "Les Francofolies De Montreal",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 43
	},
	{
		"id": "976e689",
		"email": "melissa@thetowerproductions.com",
		"name": "melissa",
		"createdDate": "2018-02-18 06:33:29",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 83,
		"totalCountries": 6,
		"totalStates": 10,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 260,
		"schedulePercent": 0.185671,
		"totalGuests": 142,
		"guestPercent": 0.506688,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Tivoli Gardens",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 49
	},
	{
		"id": "a851ed5",
		"email": "fielding@qprime.com",
		"name": "Fielding",
		"createdDate": "2009-12-22 20:42:56",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 1749,
		"totalCountries": 5,
		"totalStates": 53,
		"totalEvents": 351,
		"eventPercent": 0.941862,
		"totalScheduleItems": 4720,
		"schedulePercent": 0.952721,
		"totalGuests": 4349,
		"guestPercent": 0.986757,
		"mostGuestDate": "2023-09-24 00:00:00",
		"mostGuestName": "PNC Music Pavilion",
		"mostGuestCity": "Charlotte",
		"mostGuestAmount": 141
	},
	{
		"id": "ef2d1d0",
		"email": "rvorobyev@mail.com",
		"name": "Roman",
		"createdDate": "2021-07-25 16:55:49",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 191,
		"totalCountries": 2,
		"totalStates": 33,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 779,
		"schedulePercent": 0.412396,
		"totalGuests": 657,
		"guestPercent": 0.741226,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Saban Theatre",
		"mostGuestCity": "Beverly Hills",
		"mostGuestAmount": 65
	},
	{
		"id": "b09721e",
		"email": "claireojmercer@gmail.com",
		"name": "Claire",
		"createdDate": "2022-04-13 22:06:16",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 621,
		"totalCountries": 16,
		"totalStates": 44,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 1912,
		"schedulePercent": 0.705999,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-06-25 00:00:00",
		"mostGuestName": "Brita-Arena",
		"mostGuestCity": "Wiesbaden",
		"mostGuestAmount": 5
	},
	{
		"id": "9134c57",
		"email": "gig2gigtravel@gmail.com",
		"name": "Tara",
		"createdDate": "2018-01-03 23:42:34",
		"totalOrganizations": 10,
		"totalTours": 8,
		"totalDays": 1241,
		"totalCountries": 15,
		"totalStates": 60,
		"totalEvents": 502,
		"eventPercent": 0.97444,
		"totalScheduleItems": 5095,
		"schedulePercent": 0.96464,
		"totalGuests": 533,
		"guestPercent": 0.703748,
		"mostGuestDate": "2023-09-22 00:00:00",
		"mostGuestName": "Billy Bob's Texas",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 41
	},
	{
		"id": "f02620b",
		"email": "april.hinchliffe@gmail.com",
		"name": "April ",
		"createdDate": "2022-06-25 21:58:50",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 191,
		"totalCountries": 2,
		"totalStates": 44,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 1242,
		"schedulePercent": 0.550391,
		"totalGuests": 13,
		"guestPercent": 0.352536,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "She Rises Fest",
		"mostGuestCity": "Taos",
		"mostGuestAmount": 7
	},
	{
		"id": "a395a21",
		"email": "wilkisonw@gmail.com",
		"name": "Will",
		"createdDate": "2011-08-09 21:52:13",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 1047,
		"totalCountries": 9,
		"totalStates": 41,
		"totalEvents": 185,
		"eventPercent": 0.780029,
		"totalScheduleItems": 2474,
		"schedulePercent": 0.789432,
		"totalGuests": 1452,
		"guestPercent": 0.887697,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Paycom Center",
		"mostGuestCity": "Oklahoma City",
		"mostGuestAmount": 167
	},
	{
		"id": "7bd9b4a",
		"email": "command@rodneycarrington.com",
		"name": "Mike",
		"createdDate": "2021-08-05 14:28:17",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 363,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 1,
		"schedulePercent": 0.00741624,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "181b63a",
		"email": "jogtexas@gmail.com",
		"name": "Johnny ",
		"createdDate": "2012-12-17 18:59:27",
		"totalOrganizations": 11,
		"totalTours": 10,
		"totalDays": 1053,
		"totalCountries": 11,
		"totalStates": 50,
		"totalEvents": 287,
		"eventPercent": 0.9069,
		"totalScheduleItems": 3024,
		"schedulePercent": 0.853397,
		"totalGuests": 548,
		"guestPercent": 0.709178,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Chester Frost Park",
		"mostGuestCity": "Hixson",
		"mostGuestAmount": 44
	},
	{
		"id": "1827835",
		"email": "laurajeanhyde@me.com",
		"name": "Laura Jean",
		"createdDate": "2017-02-23 17:44:28",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 285,
		"totalCountries": 9,
		"totalStates": 39,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1591,
		"schedulePercent": 0.63965,
		"totalGuests": 1358,
		"guestPercent": 0.878427,
		"mostGuestDate": "2023-08-09 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 135
	},
	{
		"id": "a6dd3cd",
		"email": "sam@live-mgmt.com",
		"name": "Sam",
		"createdDate": "2018-02-08 12:14:32",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 628,
		"totalCountries": 18,
		"totalStates": 47,
		"totalEvents": 311,
		"eventPercent": 0.921335,
		"totalScheduleItems": 4505,
		"schedulePercent": 0.945305,
		"totalGuests": 1136,
		"guestPercent": 0.846246,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Qudos Bank Arena",
		"mostGuestCity": "Sydney Olympic Park",
		"mostGuestAmount": 67
	},
	{
		"id": "2f72abe",
		"email": "flyleaftm@gmail.com",
		"name": "Katy",
		"createdDate": "2013-01-14 17:00:00",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 1259,
		"totalCountries": 6,
		"totalStates": 59,
		"totalEvents": 231,
		"eventPercent": 0.850616,
		"totalScheduleItems": 4631,
		"schedulePercent": 0.949013,
		"totalGuests": 2841,
		"guestPercent": 0.966362,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Dos Equis Pavilion",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 155
	},
	{
		"id": "67e783b",
		"email": "ian@thisistoonice.com",
		"name": "Ian",
		"createdDate": "2017-11-07 05:36:23",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 188,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 352,
		"schedulePercent": 0.234538,
		"totalGuests": 98,
		"guestPercent": 0.472917,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "The Basement",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 35
	},
	{
		"id": "bd72493",
		"email": "justindschell@gmail.com",
		"name": "Justin",
		"createdDate": "2016-09-29 16:32:09",
		"totalOrganizations": 7,
		"totalTours": 19,
		"totalDays": 425,
		"totalCountries": 16,
		"totalStates": 67,
		"totalEvents": 176,
		"eventPercent": 0.763475,
		"totalScheduleItems": 2329,
		"schedulePercent": 0.770626,
		"totalGuests": 192,
		"guestPercent": 0.544564,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Casino Rama Resort",
		"mostGuestCity": "Orillia",
		"mostGuestAmount": 34
	},
	{
		"id": "1b0f3eb",
		"email": "KyleMoshier15@gmail.com",
		"name": "Kyle",
		"createdDate": "2012-04-21 10:37:01",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 542,
		"totalCountries": 5,
		"totalStates": 50,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 3351,
		"schedulePercent": 0.883194,
		"totalGuests": 427,
		"guestPercent": 0.663621,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "Billy Bob's Texas",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 44
	},
	{
		"id": "cc8e3bf",
		"email": "ryan@atggroup.io",
		"name": "Ryan",
		"createdDate": "2023-07-20 06:40:15",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 161,
		"totalCountries": 8,
		"totalStates": 34,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 596,
		"schedulePercent": 0.338763,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7e16d50",
		"email": "npetok@me.com",
		"name": "Nick",
		"createdDate": "2021-07-24 04:57:34",
		"totalOrganizations": 20,
		"totalTours": 49,
		"totalDays": 1790,
		"totalCountries": 25,
		"totalStates": 86,
		"totalEvents": 581,
		"eventPercent": 0.981724,
		"totalScheduleItems": 6548,
		"schedulePercent": 0.9853,
		"totalGuests": 3731,
		"guestPercent": 0.980532,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 300
	},
	{
		"id": "f2874b5",
		"email": "ghazalhashemi.hmu@gmail.com",
		"name": "Ghazal",
		"createdDate": "2023-08-30 21:03:29",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 38,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 217,
		"schedulePercent": 0.160906,
		"totalGuests": 143,
		"guestPercent": 0.508277,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "The Teragram Ballroom",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 37
	},
	{
		"id": "a09cbae",
		"email": "fernjr@mac.com",
		"name": "Fernando",
		"createdDate": "2012-06-14 22:01:46",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 504,
		"totalCountries": 6,
		"totalStates": 52,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 1477,
		"schedulePercent": 0.608661,
		"totalGuests": 1474,
		"guestPercent": 0.890743,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 53
	},
	{
		"id": "c0372ae",
		"email": "Amigathedevil@gmail.com",
		"name": "Alicia",
		"createdDate": "2017-10-26 01:24:53",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 136,
		"totalCountries": 11,
		"totalStates": 48,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 11,
		"schedulePercent": 0.0237055,
		"totalGuests": 311,
		"guestPercent": 0.610118,
		"mostGuestDate": "2023-10-24 00:00:00",
		"mostGuestName": "Emo's",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 51
	},
	{
		"id": "b0c765b",
		"email": "contact@treizeseize.com",
		"name": "Elise",
		"createdDate": "2022-11-25 21:40:42",
		"totalOrganizations": 17,
		"totalTours": 36,
		"totalDays": 934,
		"totalCountries": 23,
		"totalStates": 93,
		"totalEvents": 342,
		"eventPercent": 0.938154,
		"totalScheduleItems": 4353,
		"schedulePercent": 0.939611,
		"totalGuests": 1131,
		"guestPercent": 0.845583,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Finsbury Park",
		"mostGuestCity": "London",
		"mostGuestAmount": 60
	},
	{
		"id": "b701738",
		"email": "dk@hellboundmanagement.com",
		"name": "Dylan",
		"createdDate": "2015-11-10 21:04:44",
		"totalOrganizations": 10,
		"totalTours": 16,
		"totalDays": 470,
		"totalCountries": 9,
		"totalStates": 37,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 1945,
		"schedulePercent": 0.710634,
		"totalGuests": 739,
		"guestPercent": 0.761886,
		"mostGuestDate": "2023-10-24 00:00:00",
		"mostGuestName": "Northcote Theatre",
		"mostGuestCity": "Northcote",
		"mostGuestAmount": 47
	},
	{
		"id": "fd1a754",
		"email": "rebeka@24-7productions.com",
		"name": "Rebeka",
		"createdDate": "2022-05-16 13:55:27",
		"totalOrganizations": 12,
		"totalTours": 21,
		"totalDays": 1065,
		"totalCountries": 19,
		"totalStates": 56,
		"totalEvents": 329,
		"eventPercent": 0.930738,
		"totalScheduleItems": 3982,
		"schedulePercent": 0.922262,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Echo Arena",
		"mostGuestCity": "Liverpool",
		"mostGuestAmount": 2
	},
	{
		"id": "7dcf9ea",
		"email": "ZackFultonTM@gmail.com",
		"name": "Zack",
		"createdDate": "2012-10-16 19:21:35",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 1400,
		"totalCountries": 18,
		"totalStates": 74,
		"totalEvents": 385,
		"eventPercent": 0.953649,
		"totalScheduleItems": 5780,
		"schedulePercent": 0.977619,
		"totalGuests": 3160,
		"guestPercent": 0.972322,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 104
	},
	{
		"id": "5d6b567",
		"email": "lexbooking@mac.com",
		"name": "Matt",
		"createdDate": "2012-03-27 00:23:16",
		"totalOrganizations": 24,
		"totalTours": 24,
		"totalDays": 1325,
		"totalCountries": 9,
		"totalStates": 62,
		"totalEvents": 463,
		"eventPercent": 0.96954,
		"totalScheduleItems": 5320,
		"schedulePercent": 0.970203,
		"totalGuests": 4785,
		"guestPercent": 0.99073,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Nashville Municipal Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 111
	},
	{
		"id": "aaf9bc4",
		"email": "mail@kaika.dk",
		"name": "Kaika",
		"createdDate": "2022-09-26 08:12:22",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 85,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 607,
		"schedulePercent": 0.34459,
		"totalGuests": 81,
		"guestPercent": 0.455966,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Tivoli Friheden",
		"mostGuestCity": "Aarhus",
		"mostGuestAmount": 15
	},
	{
		"id": "2dc0aee",
		"email": "bscheckel@gmail.com",
		"name": "Bryan",
		"createdDate": "2013-09-12 02:59:47",
		"totalOrganizations": 18,
		"totalTours": 10,
		"totalDays": 2418,
		"totalCountries": 9,
		"totalStates": 46,
		"totalEvents": 193,
		"eventPercent": 0.792743,
		"totalScheduleItems": 4590,
		"schedulePercent": 0.948351,
		"totalGuests": 348,
		"guestPercent": 0.628658,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Rose Bowl Stadium",
		"mostGuestCity": "Pasadena",
		"mostGuestAmount": 45
	},
	{
		"id": "18d4a26",
		"email": "ashleigh@7commedia.com",
		"name": "Ashleigh",
		"createdDate": "2023-09-05 19:39:22",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 117,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 186,
		"schedulePercent": 0.143027,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "60836c2",
		"email": "beattysound@gmail.com",
		"name": "Steven ",
		"createdDate": "2011-07-12 11:37:38",
		"totalOrganizations": 24,
		"totalTours": 8,
		"totalDays": 249,
		"totalCountries": 13,
		"totalStates": 40,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 1170,
		"schedulePercent": 0.531983,
		"totalGuests": 159,
		"guestPercent": 0.521918,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "American Gardens Theatre @ EPCOT",
		"mostGuestCity": "Orlando",
		"mostGuestAmount": 23
	},
	{
		"id": "660ab04",
		"email": "jarrettproaudio@gmail.com",
		"name": "Jarrett",
		"createdDate": "2017-11-11 17:53:30",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 342,
		"totalCountries": 7,
		"totalStates": 35,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 894,
		"schedulePercent": 0.451331,
		"totalGuests": 55,
		"guestPercent": 0.42842,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Irving Plaza",
		"mostGuestCity": "New York",
		"mostGuestAmount": 27
	},
	{
		"id": "ddef073",
		"email": "sevvassalos@hotmail.com",
		"name": "Sophia",
		"createdDate": "2022-10-26 17:44:00",
		"totalOrganizations": 1,
		"totalTours": 9,
		"totalDays": 295,
		"totalCountries": 17,
		"totalStates": 73,
		"totalEvents": 209,
		"eventPercent": 0.82201,
		"totalScheduleItems": 2823,
		"schedulePercent": 0.834989,
		"totalGuests": 1187,
		"guestPercent": 0.855251,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Royal Festival Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 35
	},
	{
		"id": "ea73255",
		"email": "tcbtucson@gmail.com",
		"name": "Jesse",
		"createdDate": "2023-05-19 02:37:49",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 55,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 65,
		"schedulePercent": 0.0654218,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "719abe2",
		"email": "wardmcd@gmail.com",
		"name": "Ward",
		"createdDate": "2015-06-04 21:58:19",
		"totalOrganizations": 9,
		"totalTours": 16,
		"totalDays": 404,
		"totalCountries": 12,
		"totalStates": 58,
		"totalEvents": 244,
		"eventPercent": 0.865713,
		"totalScheduleItems": 2181,
		"schedulePercent": 0.747716,
		"totalGuests": 514,
		"guestPercent": 0.696597,
		"mostGuestDate": "2023-07-01 00:00:00",
		"mostGuestName": "Crystal Palace Park",
		"mostGuestCity": "London",
		"mostGuestAmount": 40
	},
	{
		"id": "8cb5d6e",
		"email": "grunenwald.e@gmail.com",
		"name": "Elizabeth ",
		"createdDate": "2023-10-24 18:20:48",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 60,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 247,
		"schedulePercent": 0.180373,
		"totalGuests": 76,
		"guestPercent": 0.451596,
		"mostGuestDate": "2023-12-15 00:00:00",
		"mostGuestName": "Dr. Phillips Center for the Performing Arts",
		"mostGuestCity": "Orlando",
		"mostGuestAmount": 14
	},
	{
		"id": "cf255d6",
		"email": "evadrennan@gmail.com",
		"name": "Eva ",
		"createdDate": "2017-07-23 04:50:07",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 438,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 1704,
		"schedulePercent": 0.665607,
		"totalGuests": 1449,
		"guestPercent": 0.887035,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Dickies Arena",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 433
	},
	{
		"id": "228ff74",
		"email": "rh@creativeteamstudios.com",
		"name": "Robert",
		"createdDate": "2023-01-19 15:44:07",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 273,
		"totalCountries": 10,
		"totalStates": 15,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 561,
		"schedulePercent": 0.32605,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5b6e95c",
		"email": "paulhinojos@gmail.com",
		"name": "Paul",
		"createdDate": "2011-07-05 16:20:28",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 141,
		"totalCountries": 15,
		"totalStates": 28,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 3149,
		"guestPercent": 0.971792,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "Tottenham Hotspur Stadium",
		"mostGuestCity": "London",
		"mostGuestAmount": 338
	},
	{
		"id": "0a5eece",
		"email": "ethan@rosegardenartists.com",
		"name": "Ethan",
		"createdDate": "2023-09-21 21:01:04",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 166,
		"totalCountries": 6,
		"totalStates": 34,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 708,
		"schedulePercent": 0.383923,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a5f8f22",
		"email": "calebcarter@c2designs.co",
		"name": "Joshua",
		"createdDate": "2018-07-25 21:13:02",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 613,
		"totalCountries": 5,
		"totalStates": 51,
		"totalEvents": 187,
		"eventPercent": 0.784135,
		"totalScheduleItems": 3087,
		"schedulePercent": 0.859886,
		"totalGuests": 670,
		"guestPercent": 0.744272,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "South Fork Dirt Riders",
		"mostGuestCity": "Taylorville",
		"mostGuestAmount": 29
	},
	{
		"id": "f668426",
		"email": "jeromecrooks@gmail.com",
		"name": "Jerome",
		"createdDate": "2010-08-06 20:23:31",
		"totalOrganizations": 20,
		"totalTours": 25,
		"totalDays": 1058,
		"totalCountries": 22,
		"totalStates": 77,
		"totalEvents": 365,
		"eventPercent": 0.948086,
		"totalScheduleItems": 4907,
		"schedulePercent": 0.958681,
		"totalGuests": 457,
		"guestPercent": 0.676732,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Arena Birmingham",
		"mostGuestCity": "Birmingham",
		"mostGuestAmount": 209
	},
	{
		"id": "da1c241",
		"email": "rebeccatravis@mac.com",
		"name": "Rebecca",
		"createdDate": "2009-09-28 21:22:52",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 276,
		"totalCountries": 14,
		"totalStates": 47,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 1982,
		"schedulePercent": 0.716064,
		"totalGuests": 1396,
		"guestPercent": 0.8824,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 171
	},
	{
		"id": "0b7ee78",
		"email": "davey@daveymurphy.com",
		"name": "David ",
		"createdDate": "2021-08-09 16:31:24",
		"totalOrganizations": 11,
		"totalTours": 17,
		"totalDays": 1397,
		"totalCountries": 14,
		"totalStates": 43,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 2767,
		"schedulePercent": 0.828102,
		"totalGuests": 543,
		"guestPercent": 0.706926,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "GrÃ¶na Lund",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 59
	},
	{
		"id": "c3949c2",
		"email": "max.kastner@salutclassique.de",
		"name": "Max",
		"createdDate": "2022-08-01 09:31:55",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 362,
		"totalCountries": 3,
		"totalStates": 8,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 475,
		"schedulePercent": 0.287247,
		"totalGuests": 265,
		"guestPercent": 0.586942,
		"mostGuestDate": "2023-10-03 00:00:00",
		"mostGuestName": "Berliner Philharmonie",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 25
	},
	{
		"id": "4b016e6",
		"email": "kamron.kimbro@gmail.com",
		"name": "Kamron",
		"createdDate": "2019-02-26 18:33:07",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 4,
		"totalStates": 39,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 2707,
		"schedulePercent": 0.819494,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "73d956e",
		"email": "jeff@pinshows.com",
		"name": "Jeff",
		"createdDate": "2019-03-11 20:49:29",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 440,
		"totalCountries": 6,
		"totalStates": 35,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 848,
		"schedulePercent": 0.437558,
		"totalGuests": 353,
		"guestPercent": 0.631307,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 71
	},
	{
		"id": "0113cf5",
		"email": "molly@terrapincrossroads.net",
		"name": "Molly",
		"createdDate": "2019-08-14 23:54:24",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 37,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 272,
		"schedulePercent": 0.193749,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "The Capitol Theatre",
		"mostGuestCity": "Port Chester",
		"mostGuestAmount": 9
	},
	{
		"id": "3325364",
		"email": "zenojones@me.com",
		"name": "Zeno",
		"createdDate": "2023-01-04 05:55:29",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 177,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 814,
		"schedulePercent": 0.424447,
		"totalGuests": 527,
		"guestPercent": 0.701232,
		"mostGuestDate": "2023-05-03 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 125
	},
	{
		"id": "f73ef5b",
		"email": "luzjimenezcambil@gmail.com",
		"name": "Luz",
		"createdDate": "2019-01-16 12:20:39",
		"totalOrganizations": 32,
		"totalTours": 20,
		"totalDays": 401,
		"totalCountries": 9,
		"totalStates": 42,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 1282,
		"schedulePercent": 0.560985,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-04-22 00:00:00",
		"mostGuestName": "PARIS 15",
		"mostGuestCity": "MÃ¡laga",
		"mostGuestAmount": 4
	},
	{
		"id": "a0bcbd2",
		"email": "tom@mproductions.com.au",
		"name": "Tom",
		"createdDate": "2018-12-23 23:37:12",
		"totalOrganizations": 14,
		"totalTours": 30,
		"totalDays": 783,
		"totalCountries": 15,
		"totalStates": 66,
		"totalEvents": 345,
		"eventPercent": 0.939478,
		"totalScheduleItems": 3634,
		"schedulePercent": 0.903324,
		"totalGuests": 512,
		"guestPercent": 0.695934,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "Irving Plaza",
		"mostGuestCity": "New York",
		"mostGuestAmount": 65
	},
	{
		"id": "747e406",
		"email": "doug@sixshooterrecords.com",
		"name": "Doug",
		"createdDate": "2021-06-29 17:10:58",
		"totalOrganizations": 13,
		"totalTours": 15,
		"totalDays": 811,
		"totalCountries": 12,
		"totalStates": 57,
		"totalEvents": 232,
		"eventPercent": 0.852073,
		"totalScheduleItems": 2882,
		"schedulePercent": 0.840154,
		"totalGuests": 822,
		"guestPercent": 0.781618,
		"mostGuestDate": "2023-01-23 00:00:00",
		"mostGuestName": "The Enmore Theatre",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 47
	},
	{
		"id": "24c98e7",
		"email": "dextermillest@gmail.com",
		"name": "Dexter",
		"createdDate": "2019-12-05 09:37:06",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 164,
		"totalCountries": 5,
		"totalStates": 11,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 635,
		"schedulePercent": 0.356112,
		"totalGuests": 61,
		"guestPercent": 0.435571,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Bannau Brycheiniog National Park",
		"mostGuestCity": "Brecon",
		"mostGuestAmount": 12
	},
	{
		"id": "24b69a8",
		"email": "peterjolliffethomas@gmail.com",
		"name": "Peter",
		"createdDate": "2023-01-26 13:37:22",
		"totalOrganizations": 2,
		"totalTours": 29,
		"totalDays": 180,
		"totalCountries": 9,
		"totalStates": 15,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 306,
		"schedulePercent": 0.211098,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "39408de",
		"email": "Dean@deanhammer.com",
		"name": "Dean ",
		"createdDate": "2012-10-29 06:35:28",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 60,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 356,
		"schedulePercent": 0.235863,
		"totalGuests": 235,
		"guestPercent": 0.57052,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "The Metro Theatre",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 47
	},
	{
		"id": "1b98a4d",
		"email": "Justin@1985touring.com",
		"name": "Justin",
		"createdDate": "2016-07-12 02:49:07",
		"totalOrganizations": 52,
		"totalTours": 60,
		"totalDays": 1409,
		"totalCountries": 10,
		"totalStates": 50,
		"totalEvents": 492,
		"eventPercent": 0.973249,
		"totalScheduleItems": 5899,
		"schedulePercent": 0.979076,
		"totalGuests": 1586,
		"guestPercent": 0.902,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Microsoft Theather",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 315
	},
	{
		"id": "baef342",
		"email": "drcaudio@gmail.com",
		"name": "Dean",
		"createdDate": "2022-04-28 22:10:56",
		"totalOrganizations": 12,
		"totalTours": 21,
		"totalDays": 566,
		"totalCountries": 14,
		"totalStates": 36,
		"totalEvents": 252,
		"eventPercent": 0.876308,
		"totalScheduleItems": 3103,
		"schedulePercent": 0.861873,
		"totalGuests": 1614,
		"guestPercent": 0.904648,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Don Valley Bowl",
		"mostGuestCity": "Sheffield",
		"mostGuestAmount": 108
	},
	{
		"id": "d440ead",
		"email": "mattblakely@aol.com",
		"name": "Matt",
		"createdDate": "2012-05-14 19:42:12",
		"totalOrganizations": 14,
		"totalTours": 29,
		"totalDays": 1029,
		"totalCountries": 23,
		"totalStates": 86,
		"totalEvents": 325,
		"eventPercent": 0.928751,
		"totalScheduleItems": 5137,
		"schedulePercent": 0.965832,
		"totalGuests": 1562,
		"guestPercent": 0.899881,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Qudos Bank Arena",
		"mostGuestCity": "Sydney Olympic Park",
		"mostGuestAmount": 64
	},
	{
		"id": "c339d20",
		"email": "travis.hogan@frontiertouring.com",
		"name": "Travis",
		"createdDate": "2015-03-01 06:47:18",
		"totalOrganizations": 32,
		"totalTours": 15,
		"totalDays": 268,
		"totalCountries": 4,
		"totalStates": 12,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 1569,
		"schedulePercent": 0.634485,
		"totalGuests": 409,
		"guestPercent": 0.657264,
		"mostGuestDate": "2023-06-18 00:00:00",
		"mostGuestName": "Sidney Myer Music Bowl",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 58
	},
	{
		"id": "acfd7dd",
		"email": "scottdunn64@gmail.com",
		"name": "Scott",
		"createdDate": "2018-02-21 17:23:05",
		"totalOrganizations": 11,
		"totalTours": 14,
		"totalDays": 2900,
		"totalCountries": 15,
		"totalStates": 71,
		"totalEvents": 349,
		"eventPercent": 0.940803,
		"totalScheduleItems": 5019,
		"schedulePercent": 0.962521,
		"totalGuests": 1849,
		"guestPercent": 0.925175,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "O2 Shepherd's Bush Empire",
		"mostGuestCity": "London",
		"mostGuestAmount": 49
	},
	{
		"id": "800dee1",
		"email": "aaquilatotouring@gmail.com",
		"name": "Anthony",
		"createdDate": "2017-03-16 22:16:11",
		"totalOrganizations": 10,
		"totalTours": 5,
		"totalDays": 568,
		"totalCountries": 13,
		"totalStates": 53,
		"totalEvents": 186,
		"eventPercent": 0.782016,
		"totalScheduleItems": 2634,
		"schedulePercent": 0.812343,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "Mayo Performing Arts Center",
		"mostGuestCity": "Morristown",
		"mostGuestAmount": 6
	},
	{
		"id": "7509f42",
		"email": "Kleosthetm@gmail.com",
		"name": "Kevin",
		"createdDate": "2015-10-13 05:00:35",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 6,
		"totalStates": 34,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 595,
		"schedulePercent": 0.337968,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ee4c668",
		"email": "bpogue@maximumartist.com",
		"name": "Ben",
		"createdDate": "2016-08-24 22:34:15",
		"totalOrganizations": 13,
		"totalTours": 16,
		"totalDays": 1724,
		"totalCountries": 8,
		"totalStates": 54,
		"totalEvents": 365,
		"eventPercent": 0.948086,
		"totalScheduleItems": 5407,
		"schedulePercent": 0.971527,
		"totalGuests": 2737,
		"guestPercent": 0.961992,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Gas South Arena",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 157
	},
	{
		"id": "41f4999",
		"email": "thomas.melby@mac.com",
		"name": "Thomas",
		"createdDate": "2011-11-23 09:32:58",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 1240,
		"totalCountries": 5,
		"totalStates": 16,
		"totalEvents": 202,
		"eventPercent": 0.810091,
		"totalScheduleItems": 2063,
		"schedulePercent": 0.729043,
		"totalGuests": 581,
		"guestPercent": 0.719243,
		"mostGuestDate": "2023-12-01 00:00:00",
		"mostGuestName": "Sentrum Scene",
		"mostGuestCity": "Oslo",
		"mostGuestAmount": 25
	},
	{
		"id": "b9fd17c",
		"email": "mark@illuminartists.com",
		"name": "Mark",
		"createdDate": "2011-09-06 01:09:32",
		"totalOrganizations": 43,
		"totalTours": 45,
		"totalDays": 1125,
		"totalCountries": 22,
		"totalStates": 78,
		"totalEvents": 328,
		"eventPercent": 0.929943,
		"totalScheduleItems": 4999,
		"schedulePercent": 0.961859,
		"totalGuests": 2647,
		"guestPercent": 0.957886,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 243
	},
	{
		"id": "4d4129a",
		"email": "yogi@volumetouring.com",
		"name": "Matthew",
		"createdDate": "2012-08-06 12:14:03",
		"totalOrganizations": 37,
		"totalTours": 43,
		"totalDays": 859,
		"totalCountries": 24,
		"totalStates": 79,
		"totalEvents": 353,
		"eventPercent": 0.943319,
		"totalScheduleItems": 5424,
		"schedulePercent": 0.972057,
		"totalGuests": 2543,
		"guestPercent": 0.955503,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "The Forum ",
		"mostGuestCity": "Melbourne ",
		"mostGuestAmount": 131
	},
	{
		"id": "a32929d",
		"email": "matthias.nuytten@lobbycall.com",
		"name": "Matthias",
		"createdDate": "2021-10-27 14:42:52",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 60,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 375,
		"schedulePercent": 0.245928,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "36d174e",
		"email": "jasonparmar1@gmail.com",
		"name": "jason",
		"createdDate": "2019-08-31 03:58:04",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 156,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 218,
		"schedulePercent": 0.1617,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "06d4de9",
		"email": "castleartsmusic@gmail.com",
		"name": "Josh",
		"createdDate": "2017-07-11 16:15:06",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 370,
		"totalCountries": 3,
		"totalStates": 37,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 2130,
		"schedulePercent": 0.738843,
		"totalGuests": 2136,
		"guestPercent": 0.940935,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Mattress Firm Amphitheatre",
		"mostGuestCity": "Chula Vista",
		"mostGuestAmount": 136
	},
	{
		"id": "281d2da",
		"email": "antti@backbonemanagement.fi",
		"name": "Antti",
		"createdDate": "2010-01-28 18:01:32",
		"totalOrganizations": 20,
		"totalTours": 10,
		"totalDays": 735,
		"totalCountries": 23,
		"totalStates": 76,
		"totalEvents": 216,
		"eventPercent": 0.831943,
		"totalScheduleItems": 2690,
		"schedulePercent": 0.81817,
		"totalGuests": 1228,
		"guestPercent": 0.86227,
		"mostGuestDate": "2023-05-12 00:00:00",
		"mostGuestName": "Pepsi Center WTC",
		"mostGuestCity": "Mexico City",
		"mostGuestAmount": 60
	},
	{
		"id": "3887dba",
		"email": "henrik@acomicsoul.com",
		"name": "Henrik",
		"createdDate": "2017-01-06 14:58:34",
		"totalOrganizations": 37,
		"totalTours": 16,
		"totalDays": 251,
		"totalCountries": 10,
		"totalStates": 18,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 617,
		"schedulePercent": 0.348431,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-05-11 00:00:00",
		"mostGuestName": "Eleftheria Stadium",
		"mostGuestCity": "Egkomi",
		"mostGuestAmount": 5
	},
	{
		"id": "860f2f0",
		"email": "nick@sternmgmt.com",
		"name": "Nick",
		"createdDate": "2023-10-14 18:02:34",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 27,
		"totalCountries": 7,
		"totalStates": 8,
		"totalEvents": 9,
		"eventPercent": 0.0607866,
		"totalScheduleItems": 198,
		"schedulePercent": 0.148987,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "40d166b",
		"email": "Arikadomanick@gmail.com",
		"name": "Arika",
		"createdDate": "2015-04-16 23:50:36",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 666,
		"totalCountries": 13,
		"totalStates": 47,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 1622,
		"schedulePercent": 0.647464,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ceb46e6",
		"email": "dawnmoses@me.com",
		"name": "Dawn",
		"createdDate": "2016-09-18 11:09:54",
		"totalOrganizations": 12,
		"totalTours": 26,
		"totalDays": 798,
		"totalCountries": 23,
		"totalStates": 88,
		"totalEvents": 400,
		"eventPercent": 0.957357,
		"totalScheduleItems": 6688,
		"schedulePercent": 0.986757,
		"totalGuests": 2336,
		"guestPercent": 0.948749,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 112
	},
	{
		"id": "dc85bd8",
		"email": "gustavoarsuaga9@gmail.com",
		"name": "Gustavo",
		"createdDate": "2022-08-16 20:37:28",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 326,
		"totalCountries": 11,
		"totalStates": 52,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 2634,
		"schedulePercent": 0.812343,
		"totalGuests": 1508,
		"guestPercent": 0.895113,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "Angel Of The Winds Arena",
		"mostGuestCity": "Everett",
		"mostGuestAmount": 59
	},
	{
		"id": "d77026a",
		"email": "trumanm31@gmail.com",
		"name": "Truman",
		"createdDate": "2023-08-29 14:49:11",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 85,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 9,
		"eventPercent": 0.0607866,
		"totalScheduleItems": 274,
		"schedulePercent": 0.195074,
		"totalGuests": 306,
		"guestPercent": 0.606807,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "The Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 130
	},
	{
		"id": "856adc4",
		"email": "faith_assistant@thecoreent.com",
		"name": "Faith",
		"createdDate": "2023-07-19 00:36:04",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 696,
		"totalCountries": 4,
		"totalStates": 43,
		"totalEvents": 183,
		"eventPercent": 0.775659,
		"totalScheduleItems": 3549,
		"schedulePercent": 0.897365,
		"totalGuests": 527,
		"guestPercent": 0.701232,
		"mostGuestDate": "2023-09-12 00:00:00",
		"mostGuestName": "PNC Music Pavilion",
		"mostGuestCity": "Charlotte",
		"mostGuestAmount": 33
	},
	{
		"id": "d68d99c",
		"email": "sarahmhewes@gmail.com",
		"name": "Sarah Margaret",
		"createdDate": "2016-06-17 22:25:33",
		"totalOrganizations": 9,
		"totalTours": 4,
		"totalDays": 557,
		"totalCountries": 3,
		"totalStates": 34,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 1051,
		"schedulePercent": 0.497682,
		"totalGuests": 33,
		"guestPercent": 0.394252,
		"mostGuestDate": "2023-03-08 00:00:00",
		"mostGuestName": "Barbican Centre",
		"mostGuestCity": "London",
		"mostGuestAmount": 16
	},
	{
		"id": "6ebd6bb",
		"email": "CFATourPSM@networkstours.com",
		"name": "Abby",
		"createdDate": "2023-09-08 20:06:04",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 114,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 510,
		"schedulePercent": 0.304198,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "eb159bf",
		"email": "lissie@redlightmanagement.com",
		"name": "Anna",
		"createdDate": "2016-03-15 23:22:54",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 61,
		"totalCountries": 3,
		"totalStates": 8,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 219,
		"schedulePercent": 0.16276,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "3rd & Lindsley",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 9
	},
	{
		"id": "3609460",
		"email": "jacob@beaconlightmgmt.com",
		"name": "Jacob",
		"createdDate": "2023-08-04 21:35:53",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 29,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 161,
		"schedulePercent": 0.127135,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "51625af",
		"email": "jafreed430@icloud.com",
		"name": "Jennifer",
		"createdDate": "2018-12-29 07:13:24",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 924,
		"totalCountries": 7,
		"totalStates": 39,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 2295,
		"schedulePercent": 0.765064,
		"totalGuests": 407,
		"guestPercent": 0.656337,
		"mostGuestDate": "2023-01-10 00:00:00",
		"mostGuestName": "The Classic Center",
		"mostGuestCity": "Athens",
		"mostGuestAmount": 68
	},
	{
		"id": "4da2764",
		"email": "mgmt@macsaturn.com",
		"name": "Austin",
		"createdDate": "2022-05-17 15:56:53",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 937,
		"totalCountries": 12,
		"totalStates": 74,
		"totalEvents": 276,
		"eventPercent": 0.898292,
		"totalScheduleItems": 3122,
		"schedulePercent": 0.863197,
		"totalGuests": 596,
		"guestPercent": 0.725202,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 66
	},
	{
		"id": "0f37323",
		"email": "kamilmeklemberg@icloud.com",
		"name": "Kamil",
		"createdDate": "2022-05-20 08:06:14",
		"totalOrganizations": 8,
		"totalTours": 20,
		"totalDays": 424,
		"totalCountries": 12,
		"totalStates": 61,
		"totalEvents": 265,
		"eventPercent": 0.890743,
		"totalScheduleItems": 1856,
		"schedulePercent": 0.696332,
		"totalGuests": 346,
		"guestPercent": 0.626672,
		"mostGuestDate": "2023-03-02 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 97
	},
	{
		"id": "8056630",
		"email": "kelsey@schroductions.com",
		"name": "Kelsey",
		"createdDate": "2022-05-04 19:53:48",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 462,
		"totalCountries": 11,
		"totalStates": 29,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1390,
		"schedulePercent": 0.588531,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "The Metropolitan Nightclub",
		"mostGuestCity": "New Orleans",
		"mostGuestAmount": 5
	},
	{
		"id": "0aafbdc",
		"email": "mail@matthiasschueller.de",
		"name": "Matthias",
		"createdDate": "2019-01-20 19:44:54",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 52,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 305,
		"schedulePercent": 0.210171,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9888bd9",
		"email": "lindamaile0928@gmail.com",
		"name": "Linda",
		"createdDate": "2018-03-10 18:46:08",
		"totalOrganizations": 8,
		"totalTours": 11,
		"totalDays": 100,
		"totalCountries": 7,
		"totalStates": 32,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 685,
		"schedulePercent": 0.37505,
		"totalGuests": 292,
		"guestPercent": 0.598596,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 50
	},
	{
		"id": "814a8e0",
		"email": "carlijne@riverjuke.com",
		"name": "Carlijne",
		"createdDate": "2017-08-09 14:29:06",
		"totalOrganizations": 5,
		"totalTours": 24,
		"totalDays": 1023,
		"totalCountries": 27,
		"totalStates": 87,
		"totalEvents": 314,
		"eventPercent": 0.922527,
		"totalScheduleItems": 4710,
		"schedulePercent": 0.951794,
		"totalGuests": 2920,
		"guestPercent": 0.968613,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Lincoln Financial Field",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 163
	},
	{
		"id": "fbfeaa9",
		"email": "hello@poppyvision.com",
		"name": "Briana",
		"createdDate": "2023-07-31 13:12:58",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 58,
		"totalCountries": 3,
		"totalStates": 12,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 56,
		"schedulePercent": 0.0589326,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6c7d06e",
		"email": "al@mindriotagency.com",
		"name": "Al",
		"createdDate": "2012-12-10 05:50:43",
		"totalOrganizations": 42,
		"totalTours": 25,
		"totalDays": 479,
		"totalCountries": 17,
		"totalStates": 62,
		"totalEvents": 230,
		"eventPercent": 0.849159,
		"totalScheduleItems": 1609,
		"schedulePercent": 0.644418,
		"totalGuests": 1368,
		"guestPercent": 0.880016,
		"mostGuestDate": "2023-05-27 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 50
	},
	{
		"id": "cae809d",
		"email": "ally@donedealmgmt.com",
		"name": "Ally",
		"createdDate": "2023-03-07 02:08:39",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 56,
		"totalCountries": 8,
		"totalStates": 23,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 600,
		"schedulePercent": 0.340882,
		"totalGuests": 444,
		"guestPercent": 0.669845,
		"mostGuestDate": "2023-05-31 00:00:00",
		"mostGuestName": "Nashville Municipal Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 71
	},
	{
		"id": "cd5937c",
		"email": "sam@precisionmgt.com",
		"name": "Sam",
		"createdDate": "2013-12-26 20:00:24",
		"totalOrganizations": 2,
		"totalTours": 11,
		"totalDays": 106,
		"totalCountries": 7,
		"totalStates": 25,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 587,
		"schedulePercent": 0.334525,
		"totalGuests": 322,
		"guestPercent": 0.614753,
		"mostGuestDate": "2023-01-13 00:00:00",
		"mostGuestName": "Santa Clarita Performing Arts Center at College of the Canyons",
		"mostGuestCity": "Santa Clarita",
		"mostGuestAmount": 34
	},
	{
		"id": "fef610c",
		"email": "Marqueze@croel.com",
		"name": "Marqueze",
		"createdDate": "2023-10-05 02:06:39",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 87,
		"totalCountries": 6,
		"totalStates": 10,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 148,
		"schedulePercent": 0.118395,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "21fed42",
		"email": "y.schmellenkamp@highlight-concerts.com",
		"name": "Ivy",
		"createdDate": "2021-09-29 09:36:08",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 149,
		"totalCountries": 6,
		"totalStates": 31,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 2160,
		"schedulePercent": 0.744007,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "70bb5ec",
		"email": "jason.serrano@yahoo.com",
		"name": "Jason",
		"createdDate": "2016-10-04 15:40:30",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 263,
		"totalCountries": 5,
		"totalStates": 11,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 201,
		"schedulePercent": 0.151106,
		"totalGuests": 43,
		"guestPercent": 0.410674,
		"mostGuestDate": "2023-11-29 00:00:00",
		"mostGuestName": "The Sinclair",
		"mostGuestCity": "Cambridge",
		"mostGuestAmount": 10
	},
	{
		"id": "b1ddca2",
		"email": "johanna.irving@lifeline.se",
		"name": "Johanna",
		"createdDate": "2016-03-24 10:01:34",
		"totalOrganizations": 23,
		"totalTours": 1,
		"totalDays": 71,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 321,
		"schedulePercent": 0.218779,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5489980",
		"email": "thecoolbeans@me.com",
		"name": "Samantha",
		"createdDate": "2016-01-26 17:16:19",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 361,
		"totalCountries": 12,
		"totalStates": 25,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 1268,
		"schedulePercent": 0.556747,
		"totalGuests": 30,
		"guestPercent": 0.389485,
		"mostGuestDate": "2023-03-04 00:00:00",
		"mostGuestName": "Bondi Beach",
		"mostGuestCity": "",
		"mostGuestAmount": 30
	},
	{
		"id": "41d586d",
		"email": "michaeljwarren@mac.com",
		"name": "Michael",
		"createdDate": "2021-10-05 19:42:56",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 86,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 244,
		"schedulePercent": 0.178784,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "The Arden Theatre",
		"mostGuestCity": "St Albert",
		"mostGuestAmount": 2
	},
	{
		"id": "d9719ac",
		"email": "lukasmbrasmussen@icloud.com",
		"name": "Lukas",
		"createdDate": "2019-04-24 11:13:28",
		"totalOrganizations": 29,
		"totalTours": 15,
		"totalDays": 393,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 358,
		"eventPercent": 0.945305,
		"totalScheduleItems": 2297,
		"schedulePercent": 0.765197,
		"totalGuests": 676,
		"guestPercent": 0.746259,
		"mostGuestDate": "2023-05-27 00:00:00",
		"mostGuestName": "Store Vega",
		"mostGuestCity": "Copenhagen",
		"mostGuestAmount": 69
	},
	{
		"id": "922dc7f",
		"email": "jennyeslade@gmail.com",
		"name": "Jenny",
		"createdDate": "2021-08-09 17:00:23",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 854,
		"totalCountries": 7,
		"totalStates": 55,
		"totalEvents": 311,
		"eventPercent": 0.921335,
		"totalScheduleItems": 5135,
		"schedulePercent": 0.9657,
		"totalGuests": 3917,
		"guestPercent": 0.982784,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Mattress Firm Amphitheatre",
		"mostGuestCity": "Chula Vista",
		"mostGuestAmount": 272
	},
	{
		"id": "fd9f7ac",
		"email": "alice.murphy@redlightmanagement.com",
		"name": "Alice",
		"createdDate": "2023-03-17 14:39:56",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 50,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 264,
		"schedulePercent": 0.189114,
		"totalGuests": 59,
		"guestPercent": 0.433982,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Brighton Beach",
		"mostGuestCity": "Brighton ",
		"mostGuestAmount": 35
	},
	{
		"id": "7080e00",
		"email": "icruzorrico@yahoo.com",
		"name": "Ivonne",
		"createdDate": "2022-08-02 21:26:52",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 144,
		"totalCountries": 5,
		"totalStates": 25,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 1253,
		"schedulePercent": 0.553569,
		"totalGuests": 47,
		"guestPercent": 0.417031,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "Audiorama El Trompo",
		"mostGuestCity": "Tijuana",
		"mostGuestAmount": 30
	},
	{
		"id": "d3bfaef",
		"email": "jasoncrabbministries@gmail.com",
		"name": "Kellye",
		"createdDate": "2014-09-26 18:51:28",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 204,
		"totalCountries": 3,
		"totalStates": 27,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 1796,
		"schedulePercent": 0.685207,
		"totalGuests": 47,
		"guestPercent": 0.417031,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "Unity Baptist Church",
		"mostGuestCity": "Newnan",
		"mostGuestAmount": 14
	},
	{
		"id": "344bc80",
		"email": "Christopherwcla@gmail.com",
		"name": "Chris",
		"createdDate": "2011-09-01 19:15:08",
		"totalOrganizations": 14,
		"totalTours": 31,
		"totalDays": 1274,
		"totalCountries": 17,
		"totalStates": 64,
		"totalEvents": 339,
		"eventPercent": 0.937094,
		"totalScheduleItems": 4900,
		"schedulePercent": 0.958151,
		"totalGuests": 2603,
		"guestPercent": 0.95643,
		"mostGuestDate": "2023-03-01 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 135
	},
	{
		"id": "36c2b35",
		"email": "jg@a12industries.com",
		"name": "Jason",
		"createdDate": "2018-06-15 21:23:51",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 119,
		"totalCountries": 2,
		"totalStates": 40,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 1477,
		"schedulePercent": 0.608661,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "The Music Center @ Strathmore",
		"mostGuestCity": "North Bethesda",
		"mostGuestAmount": 9
	},
	{
		"id": "d09b7aa",
		"email": "KingstonTrioCreative@gmail.com",
		"name": "Routing manager",
		"createdDate": "2022-06-15 22:10:22",
		"totalOrganizations": 1,
		"totalTours": 13,
		"totalDays": 124,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 213,
		"schedulePercent": 0.158125,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e948768",
		"email": "mfbarrick@gmail.com",
		"name": "Matthew",
		"createdDate": "2016-08-11 20:46:56",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 169,
		"totalCountries": 7,
		"totalStates": 30,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 543,
		"schedulePercent": 0.318633,
		"totalGuests": 1021,
		"guestPercent": 0.827837,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Kings Theatre",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 88
	},
	{
		"id": "380a60a",
		"email": "escapethefateband@gmail.com",
		"name": "Craig",
		"createdDate": "2023-08-17 23:58:32",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 43,
		"totalCountries": 1,
		"totalStates": 22,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 510,
		"schedulePercent": 0.304198,
		"totalGuests": 224,
		"guestPercent": 0.565885,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "The Studio at Webster Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 54
	},
	{
		"id": "ce3ecb0",
		"email": "karina.lisset@yahoo.com",
		"name": "Karina",
		"createdDate": "2019-05-03 09:11:32",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 320,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 1067,
		"schedulePercent": 0.503642,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "df6b318",
		"email": "amcctour@gmail.com",
		"name": "Charles",
		"createdDate": "2023-11-02 20:58:32",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 50,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 149,
		"schedulePercent": 0.118925,
		"totalGuests": 63,
		"guestPercent": 0.437558,
		"mostGuestDate": "2023-12-23 00:00:00",
		"mostGuestName": "KeyBank State Theatre",
		"mostGuestCity": "Cleveland",
		"mostGuestAmount": 11
	},
	{
		"id": "71c8f8f",
		"email": "henry@te-artistmanagement.com",
		"name": "Henry",
		"createdDate": "2021-09-28 17:55:27",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 463,
		"totalCountries": 15,
		"totalStates": 40,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 1450,
		"schedulePercent": 0.603629,
		"totalGuests": 52,
		"guestPercent": 0.425374,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "Northcote Social Club",
		"mostGuestCity": "Northcote",
		"mostGuestAmount": 20
	},
	{
		"id": "58c2c4c",
		"email": "rachelle@victoriamonet.co",
		"name": "Jamie",
		"createdDate": "2021-10-21 16:32:22",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 371,
		"totalCountries": 4,
		"totalStates": 22,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 893,
		"schedulePercent": 0.450934,
		"totalGuests": 147,
		"guestPercent": 0.511323,
		"mostGuestDate": "2023-10-25 00:00:00",
		"mostGuestName": "Royal Oak Music Theatre",
		"mostGuestCity": "Royal Oak",
		"mostGuestAmount": 24
	},
	{
		"id": "fd7bd06",
		"email": "waspmonitor@gmail.com",
		"name": "Patrick",
		"createdDate": "2016-06-22 08:52:18",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 244,
		"totalCountries": 28,
		"totalStates": 52,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 1294,
		"schedulePercent": 0.564826,
		"totalGuests": 698,
		"guestPercent": 0.752218,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 33
	},
	{
		"id": "000a96d",
		"email": "Dustynblack@gmail.com",
		"name": "Dustin",
		"createdDate": "2014-09-04 20:23:38",
		"totalOrganizations": 11,
		"totalTours": 3,
		"totalDays": 414,
		"totalCountries": 3,
		"totalStates": 16,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 501,
		"schedulePercent": 0.298768,
		"totalGuests": 218,
		"guestPercent": 0.561515,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "El Rey Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 43
	},
	{
		"id": "15d71d4",
		"email": "chris.ableco@gmail.com",
		"name": "Chris",
		"createdDate": "2017-09-26 19:30:27",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 165,
		"totalCountries": 18,
		"totalStates": 48,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 964,
		"schedulePercent": 0.469342,
		"totalGuests": 571,
		"guestPercent": 0.716991,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 57
	},
	{
		"id": "fa63b86",
		"email": "hannah@dust-booking.com",
		"name": "Hannah",
		"createdDate": "2022-09-26 16:17:50",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 47,
		"totalCountries": 4,
		"totalStates": 11,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 191,
		"schedulePercent": 0.145146,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "Schokoladen",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 12
	},
	{
		"id": "db57fea",
		"email": "nathanthomasmusic@gmail.com",
		"name": "Nathan",
		"createdDate": "2012-05-13 17:28:17",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 152,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1549,
		"schedulePercent": 0.629586,
		"totalGuests": 1800,
		"guestPercent": 0.92054,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Paycom Center",
		"mostGuestCity": "Oklahoma City",
		"mostGuestAmount": 167
	},
	{
		"id": "47b74be",
		"email": "shantel@watchdogmgt.com",
		"name": "Shantel",
		"createdDate": "2021-06-22 20:42:47",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 276,
		"totalCountries": 8,
		"totalStates": 47,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 1406,
		"schedulePercent": 0.592902,
		"totalGuests": 295,
		"guestPercent": 0.599788,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "Vic Theatre",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 48
	},
	{
		"id": "816f037",
		"email": "foggy@psycho-acoustic.co.uk",
		"name": "Grace",
		"createdDate": "2021-11-27 18:02:39",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 104,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 182,
		"schedulePercent": 0.139849,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4deafde",
		"email": "russ@toursecure.com",
		"name": "Russell",
		"createdDate": "2019-06-11 23:10:47",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 390,
		"totalCountries": 9,
		"totalStates": 34,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 702,
		"schedulePercent": 0.381274,
		"totalGuests": 170,
		"guestPercent": 0.530261,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "North Island Credit Union Amphitheatre",
		"mostGuestCity": "Chula Vista",
		"mostGuestAmount": 24
	},
	{
		"id": "010b455",
		"email": "ethanwillisbooking@gmail.com",
		"name": "Ethan",
		"createdDate": "2022-06-29 17:06:43",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 170,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 392,
		"schedulePercent": 0.254139,
		"totalGuests": 21,
		"guestPercent": 0.371342,
		"mostGuestDate": "2023-09-22 00:00:00",
		"mostGuestName": "The Factory in Deep Ellum",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 9
	},
	{
		"id": "128b7af",
		"email": "scott.armstrong1@gmail.com",
		"name": "Scott",
		"createdDate": "2023-01-26 04:37:52",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 27,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 145,
		"schedulePercent": 0.116144,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5007d00",
		"email": "johngrassdavid@gmail.com",
		"name": "David",
		"createdDate": "2022-05-22 22:38:50",
		"totalOrganizations": 3,
		"totalTours": 11,
		"totalDays": 381,
		"totalCountries": 13,
		"totalStates": 63,
		"totalEvents": 204,
		"eventPercent": 0.813005,
		"totalScheduleItems": 3453,
		"schedulePercent": 0.889948,
		"totalGuests": 1301,
		"guestPercent": 0.87207,
		"mostGuestDate": "2023-09-13 00:00:00",
		"mostGuestName": "Byline Bank Aragon Ballroom",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 65
	},
	{
		"id": "df1b276",
		"email": "CreativeArtistNetworkLIVE@gmail.com",
		"name": "Gerard ",
		"createdDate": "2022-02-02 11:39:04",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 355,
		"totalCountries": 11,
		"totalStates": 41,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 2742,
		"schedulePercent": 0.824791,
		"totalGuests": 733,
		"guestPercent": 0.760164,
		"mostGuestDate": "2023-10-31 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 91
	},
	{
		"id": "83266c8",
		"email": "wendy@jimbrickman.com",
		"name": "Wendy",
		"createdDate": "2017-10-24 14:23:45",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 341,
		"totalCountries": 1,
		"totalStates": 23,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 445,
		"schedulePercent": 0.274798,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2351f65",
		"email": "pat-bonner@live.com",
		"name": "Pat",
		"createdDate": "2013-10-20 21:29:03",
		"totalOrganizations": 14,
		"totalTours": 14,
		"totalDays": 542,
		"totalCountries": 8,
		"totalStates": 36,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 2091,
		"schedulePercent": 0.73381,
		"totalGuests": 975,
		"guestPercent": 0.818302,
		"mostGuestDate": "2023-09-11 00:00:00",
		"mostGuestName": "The National Jazz Museum in Harlem",
		"mostGuestCity": "New York",
		"mostGuestAmount": 75
	},
	{
		"id": "d14f1ba",
		"email": "Lara@knittingfactorymgmt.com",
		"name": "Lara ",
		"createdDate": "2021-09-15 14:16:53",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 51,
		"totalCountries": 3,
		"totalStates": 6,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 223,
		"schedulePercent": 0.165673,
		"totalGuests": 137,
		"guestPercent": 0.504039,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Hollywood Forever",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 41
	},
	{
		"id": "b524dd5",
		"email": "blakebrandell@gmail.com",
		"name": "Blake",
		"createdDate": "2015-01-05 20:54:26",
		"totalOrganizations": 6,
		"totalTours": 13,
		"totalDays": 375,
		"totalCountries": 5,
		"totalStates": 46,
		"totalEvents": 235,
		"eventPercent": 0.855516,
		"totalScheduleItems": 2327,
		"schedulePercent": 0.770097,
		"totalGuests": 148,
		"guestPercent": 0.51225,
		"mostGuestDate": "2023-02-24 00:00:00",
		"mostGuestName": "Amalie Arena",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 25
	},
	{
		"id": "2504c67",
		"email": "thomasgutches@me.com",
		"name": "Thomas",
		"createdDate": "2017-05-24 09:43:23",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 143,
		"totalCountries": 12,
		"totalStates": 52,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 990,
		"schedulePercent": 0.480201,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "Den Atelier",
		"mostGuestCity": "Luxemburg",
		"mostGuestAmount": 2
	},
	{
		"id": "745fcc2",
		"email": "admin@cosmica-artists.com",
		"name": "Gil",
		"createdDate": "2023-01-25 23:50:41",
		"totalOrganizations": 11,
		"totalTours": 18,
		"totalDays": 456,
		"totalCountries": 5,
		"totalStates": 34,
		"totalEvents": 156,
		"eventPercent": 0.718051,
		"totalScheduleItems": 2011,
		"schedulePercent": 0.721494,
		"totalGuests": 47,
		"guestPercent": 0.417031,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "Audiorama El Trompo",
		"mostGuestCity": "Tijuana",
		"mostGuestAmount": 30
	},
	{
		"id": "c09dfc7",
		"email": "standbygold@gmail.com",
		"name": "Keeks",
		"createdDate": "2012-04-18 23:23:18",
		"totalOrganizations": 31,
		"totalTours": 15,
		"totalDays": 2139,
		"totalCountries": 11,
		"totalStates": 59,
		"totalEvents": 241,
		"eventPercent": 0.862667,
		"totalScheduleItems": 3451,
		"schedulePercent": 0.889816,
		"totalGuests": 717,
		"guestPercent": 0.757251,
		"mostGuestDate": "2023-04-21 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 85
	},
	{
		"id": "55a62ba",
		"email": "manager@wearephoenix.com",
		"name": "Chag",
		"createdDate": "2013-05-15 19:46:03",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 259,
		"totalCountries": 17,
		"totalStates": 37,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 1671,
		"schedulePercent": 0.657794,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "808e660",
		"email": "lalucebaron@gmail.com",
		"name": "Lucie",
		"createdDate": "2022-05-20 17:30:45",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 263,
		"totalCountries": 6,
		"totalStates": 10,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 427,
		"schedulePercent": 0.266587,
		"totalGuests": 36,
		"guestPercent": 0.397961,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "Parc AndrÃ©-CitroÃ«n",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 21
	},
	{
		"id": "cc83c81",
		"email": "koki@udo.co.jp",
		"name": "KOKI",
		"createdDate": "2022-05-26 03:23:54",
		"totalOrganizations": 18,
		"totalTours": 12,
		"totalDays": 281,
		"totalCountries": 8,
		"totalStates": 32,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 1325,
		"schedulePercent": 0.573037,
		"totalGuests": 379,
		"guestPercent": 0.64455,
		"mostGuestDate": "2023-05-02 00:00:00",
		"mostGuestName": "Maui Arts And Cultural Center",
		"mostGuestCity": "Kahalui, Maui",
		"mostGuestAmount": 69
	},
	{
		"id": "f95a87d",
		"email": "tyler@indietouring.com",
		"name": "Tyler",
		"createdDate": "2022-05-04 03:49:12",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 82,
		"totalCountries": 6,
		"totalStates": 24,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 486,
		"schedulePercent": 0.291352,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-09-11 00:00:00",
		"mostGuestName": "Roadrunner",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 8
	},
	{
		"id": "8a13f36",
		"email": "honza.os@gmail.com",
		"name": "Jan",
		"createdDate": "2019-01-28 15:38:14",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 171,
		"totalCountries": 17,
		"totalStates": 60,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1065,
		"schedulePercent": 0.50298,
		"totalGuests": 642,
		"guestPercent": 0.736591,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "Somerset House",
		"mostGuestCity": "London",
		"mostGuestAmount": 72
	},
	{
		"id": "127886b",
		"email": "jhoff.tmm@gmail.com",
		"name": "JANN",
		"createdDate": "2010-12-09 19:49:50",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 35,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 9,
		"eventPercent": 0.0607866,
		"totalScheduleItems": 123,
		"schedulePercent": 0.105019,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "16e1e46",
		"email": "milesmakesmusic@gmail.com",
		"name": "Miles",
		"createdDate": "2018-09-30 21:58:35",
		"totalOrganizations": 8,
		"totalTours": 12,
		"totalDays": 213,
		"totalCountries": 13,
		"totalStates": 45,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 1589,
		"schedulePercent": 0.638988,
		"totalGuests": 49,
		"guestPercent": 0.419944,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 49
	},
	{
		"id": "1177965",
		"email": "brian@zbuggroup.com",
		"name": "Brian ",
		"createdDate": "2014-09-09 22:27:38",
		"totalOrganizations": 2,
		"totalTours": 25,
		"totalDays": 146,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 856,
		"schedulePercent": 0.439015,
		"totalGuests": 262,
		"guestPercent": 0.585221,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Libbey Bowl",
		"mostGuestCity": "Ojai",
		"mostGuestAmount": 30
	},
	{
		"id": "8bb4a34",
		"email": "npmastertour@gmail.com",
		"name": "Brian ",
		"createdDate": "2014-06-17 01:07:55",
		"totalOrganizations": 15,
		"totalTours": 3,
		"totalDays": 392,
		"totalCountries": 3,
		"totalStates": 21,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 1279,
		"schedulePercent": 0.559926,
		"totalGuests": 430,
		"guestPercent": 0.664548,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 75
	},
	{
		"id": "7c83028",
		"email": "agendamontesanto@gmail.com",
		"name": "Geovanny",
		"createdDate": "2022-12-06 14:37:29",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 246,
		"totalCountries": 9,
		"totalStates": 56,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 1120,
		"schedulePercent": 0.516223,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0d5e853",
		"email": "benkitterman@gmail.com",
		"name": "Ben ",
		"createdDate": "2011-02-10 22:27:57",
		"totalOrganizations": 18,
		"totalTours": 25,
		"totalDays": 3139,
		"totalCountries": 30,
		"totalStates": 101,
		"totalEvents": 999,
		"eventPercent": 0.9902,
		"totalScheduleItems": 13417,
		"schedulePercent": 0.998941,
		"totalGuests": 1372,
		"guestPercent": 0.880281,
		"mostGuestDate": "2023-11-19 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 88
	},
	{
		"id": "e26852d",
		"email": "Admin@koolandthegang.com",
		"name": "Lindsey",
		"createdDate": "2023-01-05 17:49:28",
		"totalOrganizations": 1,
		"totalTours": 28,
		"totalDays": 559,
		"totalCountries": 8,
		"totalStates": 29,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 2591,
		"schedulePercent": 0.804794,
		"totalGuests": 444,
		"guestPercent": 0.669845,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "Hard Rock Live at Etess Arena",
		"mostGuestCity": "Atlantic City",
		"mostGuestAmount": 57
	},
	{
		"id": "776aa44",
		"email": "skanetouring@gmail.com",
		"name": "Sean",
		"createdDate": "2023-05-05 18:28:08",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 51,
		"totalCountries": 1,
		"totalStates": 22,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 498,
		"schedulePercent": 0.297576,
		"totalGuests": 43,
		"guestPercent": 0.410674,
		"mostGuestDate": "2023-08-06 00:00:00",
		"mostGuestName": "Pine Knob Music Theatre",
		"mostGuestCity": "City of the Village of Clarkston",
		"mostGuestAmount": 43
	},
	{
		"id": "630d44a",
		"email": "contact@spookcreative.co.uk",
		"name": "Phil",
		"createdDate": "2022-04-11 10:48:43",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 321,
		"totalCountries": 4,
		"totalStates": 6,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 382,
		"schedulePercent": 0.250166,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f6f8df9",
		"email": "ericpjohnson78@gmail.com",
		"name": "eric",
		"createdDate": "2020-02-20 19:32:11",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 348,
		"totalCountries": 13,
		"totalStates": 41,
		"totalEvents": 197,
		"eventPercent": 0.800821,
		"totalScheduleItems": 1706,
		"schedulePercent": 0.666004,
		"totalGuests": 786,
		"guestPercent": 0.773672,
		"mostGuestDate": "2023-11-05 00:00:00",
		"mostGuestName": "SoFi Stadium",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 652
	},
	{
		"id": "4db009f",
		"email": "ben@emo-orchestra.com",
		"name": "Benjamin",
		"createdDate": "2023-10-02 22:55:12",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 41,
		"totalCountries": 1,
		"totalStates": 22,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 322,
		"schedulePercent": 0.219706,
		"totalGuests": 278,
		"guestPercent": 0.593564,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Orpheum Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 41
	},
	{
		"id": "2b2087a",
		"email": "judit.matyasy@gmail.com",
		"name": "Judit",
		"createdDate": "2017-02-27 17:10:53",
		"totalOrganizations": 9,
		"totalTours": 5,
		"totalDays": 205,
		"totalCountries": 13,
		"totalStates": 24,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 1822,
		"schedulePercent": 0.689313,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c71db7c",
		"email": "easyplateau@gmail.com",
		"name": "Kris",
		"createdDate": "2023-03-20 16:48:16",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 43,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 245,
		"schedulePercent": 0.179049,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ef28b2a",
		"email": "tori@gagreflex.co.uk",
		"name": "Tori",
		"createdDate": "2023-01-19 13:44:51",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 212,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 799,
		"schedulePercent": 0.420209,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b342aab",
		"email": "contact@dylanschneidermusic.com",
		"name": "Dylan",
		"createdDate": "2021-05-06 17:51:37",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 31,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 350,
		"schedulePercent": 0.232949,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "998b8e9",
		"email": "alec_mills@me.com",
		"name": "Alec",
		"createdDate": "2013-02-28 17:56:06",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 1095,
		"totalCountries": 24,
		"totalStates": 48,
		"totalEvents": 251,
		"eventPercent": 0.873792,
		"totalScheduleItems": 3671,
		"schedulePercent": 0.905575,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c28ef2d",
		"email": "james@concertlogic.com",
		"name": "James",
		"createdDate": "2022-12-18 00:37:06",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 356,
		"totalCountries": 2,
		"totalStates": 37,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 1609,
		"schedulePercent": 0.644418,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b0bd6e8",
		"email": "chibbins@gmail.com",
		"name": "Chris",
		"createdDate": "2010-07-31 21:49:01",
		"totalOrganizations": 15,
		"totalTours": 27,
		"totalDays": 961,
		"totalCountries": 6,
		"totalStates": 59,
		"totalEvents": 349,
		"eventPercent": 0.940803,
		"totalScheduleItems": 4872,
		"schedulePercent": 0.957489,
		"totalGuests": 820,
		"guestPercent": 0.780691,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Commodore Ballroom",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 58
	},
	{
		"id": "efc151c",
		"email": "leon@frndsmgmt.com",
		"name": "Leon",
		"createdDate": "2015-03-10 03:23:44",
		"totalOrganizations": 12,
		"totalTours": 13,
		"totalDays": 194,
		"totalCountries": 7,
		"totalStates": 20,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 1161,
		"schedulePercent": 0.529466,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "015b1b5",
		"email": "dustinderosier@me.com",
		"name": "Dustin",
		"createdDate": "2011-05-08 02:31:52",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 562,
		"totalCountries": 4,
		"totalStates": 46,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 2339,
		"schedulePercent": 0.772878,
		"totalGuests": 185,
		"guestPercent": 0.539664,
		"mostGuestDate": "2023-02-24 00:00:00",
		"mostGuestName": "Amalie Arena",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 25
	},
	{
		"id": "1c538c4",
		"email": "theo@westridge.la",
		"name": "Theo",
		"createdDate": "2023-03-15 00:59:33",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 270,
		"totalCountries": 6,
		"totalStates": 27,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 748,
		"schedulePercent": 0.401006,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4ec01d2",
		"email": "victor@bdventertainment.com",
		"name": "Victor",
		"createdDate": "2017-10-31 01:25:04",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 356,
		"totalCountries": 3,
		"totalStates": 42,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 1636,
		"schedulePercent": 0.649848,
		"totalGuests": 53,
		"guestPercent": 0.426434,
		"mostGuestDate": "2023-05-04 00:00:00",
		"mostGuestName": "Fiddler's Green Amphitheatre",
		"mostGuestCity": "Englewood",
		"mostGuestAmount": 30
	},
	{
		"id": "9ae9d36",
		"email": "john.damico@wwecorp.com",
		"name": "John",
		"createdDate": "2018-09-12 16:31:36",
		"totalOrganizations": 1,
		"totalTours": 77,
		"totalDays": 293,
		"totalCountries": 8,
		"totalStates": 55,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 2591,
		"schedulePercent": 0.804794,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d2de91d",
		"email": "doug@qprime.com",
		"name": "Doug",
		"createdDate": "2010-01-08 18:43:39",
		"totalOrganizations": 18,
		"totalTours": 10,
		"totalDays": 259,
		"totalCountries": 9,
		"totalStates": 50,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 1514,
		"schedulePercent": 0.621639,
		"totalGuests": 500,
		"guestPercent": 0.691829,
		"mostGuestDate": "2023-09-27 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 80
	},
	{
		"id": "2ef4494",
		"email": "xrickyorozco@gmail.com",
		"name": "Richard",
		"createdDate": "2022-03-29 20:47:10",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 108,
		"totalCountries": 3,
		"totalStates": 35,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 829,
		"schedulePercent": 0.430009,
		"totalGuests": 1214,
		"guestPercent": 0.860019,
		"mostGuestDate": "2023-10-08 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 116
	},
	{
		"id": "91e04fb",
		"email": "maryjospillane@icloud.com",
		"name": "Maryjo",
		"createdDate": "2017-02-16 21:14:12",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 278,
		"totalCountries": 1,
		"totalStates": 37,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1436,
		"schedulePercent": 0.600848,
		"totalGuests": 487,
		"guestPercent": 0.686929,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "The Theater at Virgin Hotels",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 40
	},
	{
		"id": "9b58fc6",
		"email": "kim@whitecanvascollective.com",
		"name": "Kim",
		"createdDate": "2022-07-18 04:38:50",
		"totalOrganizations": 7,
		"totalTours": 4,
		"totalDays": 147,
		"totalCountries": 4,
		"totalStates": 9,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 357,
		"schedulePercent": 0.236393,
		"totalGuests": 79,
		"guestPercent": 0.454907,
		"mostGuestDate": "2023-02-25 00:00:00",
		"mostGuestName": "The Mothership",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 29
	},
	{
		"id": "af3ec10",
		"email": "jude@starfishentertainment.com",
		"name": "Judith",
		"createdDate": "2013-01-10 16:49:19",
		"totalOrganizations": 17,
		"totalTours": 10,
		"totalDays": 495,
		"totalCountries": 10,
		"totalStates": 53,
		"totalEvents": 343,
		"eventPercent": 0.938551,
		"totalScheduleItems": 1793,
		"schedulePercent": 0.683751,
		"totalGuests": 844,
		"guestPercent": 0.786651,
		"mostGuestDate": "2023-09-04 00:00:00",
		"mostGuestName": "PNE Forum",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 71
	},
	{
		"id": "98c8a56",
		"email": "leahmarlenemusic@gmail.com",
		"name": "Leah ",
		"createdDate": "2022-07-28 18:52:17",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 287,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 143,
		"schedulePercent": 0.115217,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5f7b3da",
		"email": "ksrichards2@gmail.com",
		"name": "Krystal",
		"createdDate": "2021-09-12 22:14:33",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 60,
		"totalCountries": 4,
		"totalStates": 5,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 181,
		"schedulePercent": 0.139054,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0bd8340",
		"email": "Summerstouringservices@gmail.com",
		"name": "Andrew",
		"createdDate": "2022-02-21 12:31:34",
		"totalOrganizations": 6,
		"totalTours": 19,
		"totalDays": 349,
		"totalCountries": 10,
		"totalStates": 57,
		"totalEvents": 157,
		"eventPercent": 0.720567,
		"totalScheduleItems": 1677,
		"schedulePercent": 0.65925,
		"totalGuests": 870,
		"guestPercent": 0.792345,
		"mostGuestDate": "2023-11-21 00:00:00",
		"mostGuestName": "Regent Theater DTLA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 44
	},
	{
		"id": "e645b60",
		"email": "tandyburnam@gmail.com",
		"name": "Tandy",
		"createdDate": "2018-04-10 23:21:15",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 308,
		"totalCountries": 3,
		"totalStates": 37,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 3285,
		"schedulePercent": 0.87644,
		"totalGuests": 310,
		"guestPercent": 0.609853,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Altria Theater",
		"mostGuestCity": "Richmond",
		"mostGuestAmount": 36
	},
	{
		"id": "8a4a42b",
		"email": "Imanimusic93@gmail.com",
		"name": "Pauly",
		"createdDate": "2023-04-30 19:08:24",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 1218,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 170,
		"schedulePercent": 0.132698,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "debc87a",
		"email": "ryan@stagedrivetouring.co.uk",
		"name": "Ryan",
		"createdDate": "2014-10-18 23:48:26",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 223,
		"totalCountries": 10,
		"totalStates": 27,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 739,
		"schedulePercent": 0.396106,
		"totalGuests": 196,
		"guestPercent": 0.546947,
		"mostGuestDate": "2023-11-30 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 60
	},
	{
		"id": "32c3962",
		"email": "robert@primarycolours.se",
		"name": "Robert",
		"createdDate": "2017-01-12 20:15:36",
		"totalOrganizations": 8,
		"totalTours": 11,
		"totalDays": 474,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 734,
		"schedulePercent": 0.39359,
		"totalGuests": 223,
		"guestPercent": 0.564958,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Uppsala Konsert & Kongress",
		"mostGuestCity": "Uppsala",
		"mostGuestAmount": 40
	},
	{
		"id": "9ff738c",
		"email": "tim.seconi@gmail.com",
		"name": "Tim",
		"createdDate": "2022-07-20 01:26:38",
		"totalOrganizations": 7,
		"totalTours": 17,
		"totalDays": 206,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1824,
		"schedulePercent": 0.689975,
		"totalGuests": 67,
		"guestPercent": 0.443253,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Rod Laver Arena",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 23
	},
	{
		"id": "ae5c42c",
		"email": "ericwagnertm@aol.com",
		"name": "Eric",
		"createdDate": "2019-05-15 20:13:01",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 275,
		"totalCountries": 4,
		"totalStates": 49,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 1826,
		"schedulePercent": 0.690505,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ecabdc6",
		"email": "imane@mer.as",
		"name": "Imane",
		"createdDate": "2020-01-31 08:43:03",
		"totalOrganizations": 1,
		"totalTours": 11,
		"totalDays": 202,
		"totalCountries": 8,
		"totalStates": 21,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 1077,
		"schedulePercent": 0.506423,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1550697",
		"email": "ant@stageguy.com",
		"name": "Ant",
		"createdDate": "2010-12-26 08:16:47",
		"totalOrganizations": 18,
		"totalTours": 34,
		"totalDays": 723,
		"totalCountries": 20,
		"totalStates": 47,
		"totalEvents": 191,
		"eventPercent": 0.790491,
		"totalScheduleItems": 3115,
		"schedulePercent": 0.863064,
		"totalGuests": 731,
		"guestPercent": 0.759634,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 50
	},
	{
		"id": "1178d34",
		"email": "jovanaontheroad@gmail.com",
		"name": "Jovana",
		"createdDate": "2018-10-07 23:58:19",
		"totalOrganizations": 8,
		"totalTours": 14,
		"totalDays": 387,
		"totalCountries": 9,
		"totalStates": 57,
		"totalEvents": 163,
		"eventPercent": 0.735134,
		"totalScheduleItems": 1982,
		"schedulePercent": 0.716064,
		"totalGuests": 705,
		"guestPercent": 0.75447,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Wolf Trap National Park for the Performing Arts",
		"mostGuestCity": "Vienna",
		"mostGuestAmount": 45
	},
	{
		"id": "60029f6",
		"email": "production@lifeliketouring.com",
		"name": "LLT",
		"createdDate": "2023-05-22 01:05:45",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 176,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 231,
		"schedulePercent": 0.169779,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2610d52",
		"email": "vickie@metclub.com",
		"name": "Vickie",
		"createdDate": "2012-05-01 17:46:21",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 46,
		"totalCountries": 4,
		"totalStates": 9,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 54,
		"schedulePercent": 0.0573434,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Ford Field",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 2
	},
	{
		"id": "3f5714e",
		"email": "ingela@biwa.se",
		"name": "Ingela",
		"createdDate": "2023-01-30 14:15:31",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 37,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 100,
		"schedulePercent": 0.0900543,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e4b4f1e",
		"email": "jussi@musiikkituotanto.fi",
		"name": "Jussi",
		"createdDate": "2021-08-27 00:57:16",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 422,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 347,
		"schedulePercent": 0.231757,
		"totalGuests": 41,
		"guestPercent": 0.407231,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Bar IHKU Helsinki",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 15
	},
	{
		"id": "12d441d",
		"email": "touring@talkocen.com",
		"name": "Tal",
		"createdDate": "2016-05-23 18:25:24",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 118,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 187,
		"schedulePercent": 0.143557,
		"totalGuests": 121,
		"guestPercent": 0.490928,
		"mostGuestDate": "2023-02-19 00:00:00",
		"mostGuestName": "Fred Kavli Theater",
		"mostGuestCity": "Thousand Oaks",
		"mostGuestAmount": 25
	},
	{
		"id": "9694e49",
		"email": "trn4audio@gmail.com",
		"name": "Todd",
		"createdDate": "2013-09-17 07:25:27",
		"totalOrganizations": 9,
		"totalTours": 14,
		"totalDays": 866,
		"totalCountries": 4,
		"totalStates": 34,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 2218,
		"schedulePercent": 0.753145,
		"totalGuests": 751,
		"guestPercent": 0.764667,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 91
	},
	{
		"id": "d9c7070",
		"email": "jakebartlett2005@gmail.com",
		"name": "Jake",
		"createdDate": "2022-02-04 03:35:57",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 726,
		"totalCountries": 11,
		"totalStates": 51,
		"totalEvents": 258,
		"eventPercent": 0.883989,
		"totalScheduleItems": 2469,
		"schedulePercent": 0.788902,
		"totalGuests": 699,
		"guestPercent": 0.752748,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Whitewater Amphitheater",
		"mostGuestCity": "New Braunfels",
		"mostGuestAmount": 38
	},
	{
		"id": "8d3eea2",
		"email": "Dominic@jbwkz.com",
		"name": "Dominic",
		"createdDate": "2022-12-30 21:46:08",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 307,
		"totalCountries": 3,
		"totalStates": 28,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1128,
		"schedulePercent": 0.519401,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "75c2dd8",
		"email": "Wez3@mac.com",
		"name": "Richard",
		"createdDate": "2013-10-14 21:13:08",
		"totalOrganizations": 6,
		"totalTours": 13,
		"totalDays": 230,
		"totalCountries": 14,
		"totalStates": 48,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 1927,
		"schedulePercent": 0.707324,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1dfd7d0",
		"email": "tedktouring@gmail.com",
		"name": "Ted",
		"createdDate": "2023-05-18 15:00:41",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 79,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 7,
		"eventPercent": 0.0458217,
		"totalScheduleItems": 126,
		"schedulePercent": 0.106741,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e288e28",
		"email": "JWAISHES@YAHOO.COM",
		"name": "Joshua",
		"createdDate": "2015-06-17 16:06:27",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 216,
		"totalCountries": 1,
		"totalStates": 31,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 599,
		"schedulePercent": 0.340352,
		"totalGuests": 36,
		"guestPercent": 0.397961,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 11
	},
	{
		"id": "1261bcf",
		"email": "sunsquabi.booking@gmail.com",
		"name": "Chris ",
		"createdDate": "2016-02-09 20:38:11",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 137,
		"totalCountries": 1,
		"totalStates": 26,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 503,
		"schedulePercent": 0.29996,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-10-12 00:00:00",
		"mostGuestName": "The Atlantis",
		"mostGuestCity": "Washington",
		"mostGuestAmount": 7
	},
	{
		"id": "2bc30ad",
		"email": "BMGTourACM@networkstours.com",
		"name": "Michael",
		"createdDate": "2022-08-27 18:35:38",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 51,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 340,
		"schedulePercent": 0.228049,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "06579fd",
		"email": "andy.mac777@gmail.com",
		"name": "Andy",
		"createdDate": "2014-02-21 21:45:45",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 161,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1746,
		"schedulePercent": 0.67395,
		"totalGuests": 1213,
		"guestPercent": 0.859621,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "State Farm Arena",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 133
	},
	{
		"id": "082e805",
		"email": "asiegler313@gmail.com",
		"name": "Aaron",
		"createdDate": "2016-02-05 21:27:04",
		"totalOrganizations": 26,
		"totalTours": 14,
		"totalDays": 425,
		"totalCountries": 13,
		"totalStates": 33,
		"totalEvents": 159,
		"eventPercent": 0.726394,
		"totalScheduleItems": 3393,
		"schedulePercent": 0.8873,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c9f4645",
		"email": "tesstours@gmail.com",
		"name": "Tessa",
		"createdDate": "2015-03-06 16:17:28",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 543,
		"totalCountries": 9,
		"totalStates": 23,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 1086,
		"schedulePercent": 0.508939,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d450525",
		"email": "Eleanor@lookoutkid.com",
		"name": "Eleanor",
		"createdDate": "2021-07-06 03:43:11",
		"totalOrganizations": 12,
		"totalTours": 30,
		"totalDays": 592,
		"totalCountries": 13,
		"totalStates": 65,
		"totalEvents": 279,
		"eventPercent": 0.901205,
		"totalScheduleItems": 3291,
		"schedulePercent": 0.877102,
		"totalGuests": 1098,
		"guestPercent": 0.839359,
		"mostGuestDate": "2023-10-25 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 56
	},
	{
		"id": "1771fe5",
		"email": "roxane@misterproductions.com",
		"name": "Mika",
		"createdDate": "2022-05-18 16:32:29",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 84,
		"totalCountries": 7,
		"totalStates": 16,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 1008,
		"schedulePercent": 0.487618,
		"totalGuests": 218,
		"guestPercent": 0.561515,
		"mostGuestDate": "2023-07-19 00:00:00",
		"mostGuestName": "ArÃ¨nes de Bayonne",
		"mostGuestCity": "Bayonne",
		"mostGuestAmount": 38
	},
	{
		"id": "082e252",
		"email": "leila.maraun@gmail.com",
		"name": "Leila",
		"createdDate": "2019-01-30 03:42:42",
		"totalOrganizations": 13,
		"totalTours": 38,
		"totalDays": 415,
		"totalCountries": 3,
		"totalStates": 15,
		"totalEvents": 166,
		"eventPercent": 0.74308,
		"totalScheduleItems": 1804,
		"schedulePercent": 0.686664,
		"totalGuests": 159,
		"guestPercent": 0.521918,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "Hamer Hall",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 18
	},
	{
		"id": "a88d3f1",
		"email": "n28music@gmail.com",
		"name": "Christine",
		"createdDate": "2023-11-03 16:42:33",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 34,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 133,
		"schedulePercent": 0.109389,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "Esplanade Arts & Heritage Centre",
		"mostGuestCity": "Medicine Hat",
		"mostGuestAmount": 2
	},
	{
		"id": "f8a6348",
		"email": "gerry@goldtheoryartists.com",
		"name": "Gerry",
		"createdDate": "2023-10-27 17:13:09",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 32,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 4,
		"eventPercent": 0.0288703,
		"totalScheduleItems": 77,
		"schedulePercent": 0.0731029,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "Hammerstein Ballroom",
		"mostGuestCity": "New York",
		"mostGuestAmount": 14
	},
	{
		"id": "59fea44",
		"email": "tm@joyamarleen.com",
		"name": "Eliane",
		"createdDate": "2023-03-09 11:44:38",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 97,
		"totalCountries": 3,
		"totalStates": 9,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 384,
		"schedulePercent": 0.250695,
		"totalGuests": 68,
		"guestPercent": 0.444312,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Zauberpark",
		"mostGuestCity": "ZÃ¼rich",
		"mostGuestAmount": 9
	},
	{
		"id": "03e02e9",
		"email": "Haydvass@gmail.com",
		"name": "Hayden",
		"createdDate": "2012-09-26 06:31:23",
		"totalOrganizations": 32,
		"totalTours": 34,
		"totalDays": 646,
		"totalCountries": 11,
		"totalStates": 30,
		"totalEvents": 230,
		"eventPercent": 0.849159,
		"totalScheduleItems": 4374,
		"schedulePercent": 0.94067,
		"totalGuests": 647,
		"guestPercent": 0.738445,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 98
	},
	{
		"id": "f76fb73",
		"email": "clay@watchtowerentertainment.com",
		"name": "Clay",
		"createdDate": "2022-05-23 23:26:29",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 419,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 225,
		"schedulePercent": 0.1666,
		"totalGuests": 49,
		"guestPercent": 0.419944,
		"mostGuestDate": "2023-07-23 00:00:00",
		"mostGuestName": "Stages Music Arts",
		"mostGuestCity": "Cockeysville",
		"mostGuestAmount": 12
	},
	{
		"id": "8043ae5",
		"email": "dtdb13@gmail.com",
		"name": "Dave",
		"createdDate": "2017-11-29 18:47:53",
		"totalOrganizations": 19,
		"totalTours": 9,
		"totalDays": 406,
		"totalCountries": 7,
		"totalStates": 42,
		"totalEvents": 175,
		"eventPercent": 0.761753,
		"totalScheduleItems": 1503,
		"schedulePercent": 0.618858,
		"totalGuests": 1467,
		"guestPercent": 0.890081,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 243
	},
	{
		"id": "aec2903",
		"email": "jb@motionmusic.us",
		"name": "JB",
		"createdDate": "2013-09-15 21:13:14",
		"totalOrganizations": 28,
		"totalTours": 15,
		"totalDays": 1724,
		"totalCountries": 9,
		"totalStates": 47,
		"totalEvents": 287,
		"eventPercent": 0.9069,
		"totalScheduleItems": 6528,
		"schedulePercent": 0.984903,
		"totalGuests": 2706,
		"guestPercent": 0.96133,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 281
	},
	{
		"id": "d566958",
		"email": "sebastian.pichel@livenation-production.de",
		"name": "Sebastian",
		"createdDate": "2018-06-04 12:04:50",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 252,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 4,
		"schedulePercent": 0.0139054,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d87ac52",
		"email": "nfallon@cityparksfoundation.org",
		"name": "Nathan",
		"createdDate": "2023-07-25 13:21:11",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 113,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 725,
		"schedulePercent": 0.390677,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "521d7c9",
		"email": "touring@artentgroup.com",
		"name": "Adrian",
		"createdDate": "2023-03-27 18:12:30",
		"totalOrganizations": 4,
		"totalTours": 21,
		"totalDays": 165,
		"totalCountries": 9,
		"totalStates": 32,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 664,
		"schedulePercent": 0.366574,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2085465",
		"email": "samfloodtouring@gmail.com",
		"name": "sam",
		"createdDate": "2022-02-19 01:08:58",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 528,
		"totalCountries": 2,
		"totalStates": 49,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 2240,
		"schedulePercent": 0.756191,
		"totalGuests": 1503,
		"guestPercent": 0.894584,
		"mostGuestDate": "2023-08-09 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 135
	},
	{
		"id": "666795a",
		"email": "sean@distancemgmt.com",
		"name": "Sean Patrick ",
		"createdDate": "2019-07-03 17:47:30",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 67,
		"totalCountries": 8,
		"totalStates": 24,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 307,
		"schedulePercent": 0.21176,
		"totalGuests": 256,
		"guestPercent": 0.582307,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "CMA Theater at the Country Music Hall of Fame and Museum",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 41
	},
	{
		"id": "6c8bdaf",
		"email": "t.wilder@haloent.ca",
		"name": "Tracey",
		"createdDate": "2017-11-08 00:58:40",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 113,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 203,
		"schedulePercent": 0.152165,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-12-06 00:00:00",
		"mostGuestName": "Fallsview Casino Resort",
		"mostGuestCity": "Niagara Falls",
		"mostGuestAmount": 5
	},
	{
		"id": "96475f3",
		"email": "mail@fast-entertainment.dk",
		"name": "Michael",
		"createdDate": "2016-12-30 07:11:30",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 58,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 268,
		"schedulePercent": 0.190836,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e8e44e3",
		"email": "denholmchris@gmail.com",
		"name": "Chris",
		"createdDate": "2015-02-20 05:29:36",
		"totalOrganizations": 9,
		"totalTours": 19,
		"totalDays": 938,
		"totalCountries": 17,
		"totalStates": 77,
		"totalEvents": 244,
		"eventPercent": 0.865713,
		"totalScheduleItems": 3897,
		"schedulePercent": 0.918157,
		"totalGuests": 505,
		"guestPercent": 0.693815,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "Pompano Beach Amphitheatre",
		"mostGuestCity": "Pompano Beach",
		"mostGuestAmount": 39
	},
	{
		"id": "913d43e",
		"email": "aaron2020davis@gmail.com",
		"name": "Aaron",
		"createdDate": "2019-09-26 19:53:58",
		"totalOrganizations": 7,
		"totalTours": 13,
		"totalDays": 185,
		"totalCountries": 10,
		"totalStates": 41,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1670,
		"schedulePercent": 0.657661,
		"totalGuests": 184,
		"guestPercent": 0.539266,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "Kings Theatre",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 34
	},
	{
		"id": "4fb7dad",
		"email": "chip_johnson@me.com",
		"name": "Chip",
		"createdDate": "2017-01-05 00:59:20",
		"totalOrganizations": 10,
		"totalTours": 10,
		"totalDays": 236,
		"totalCountries": 5,
		"totalStates": 42,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 1801,
		"schedulePercent": 0.686134,
		"totalGuests": 1779,
		"guestPercent": 0.918951,
		"mostGuestDate": "2023-08-09 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 135
	},
	{
		"id": "4bc79e0",
		"email": "Tom@keepsaketm.co.uk",
		"name": "Thomas",
		"createdDate": "2016-03-12 10:09:58",
		"totalOrganizations": 11,
		"totalTours": 18,
		"totalDays": 615,
		"totalCountries": 19,
		"totalStates": 80,
		"totalEvents": 377,
		"eventPercent": 0.950073,
		"totalScheduleItems": 5422,
		"schedulePercent": 0.971924,
		"totalGuests": 1743,
		"guestPercent": 0.91617,
		"mostGuestDate": "2023-04-20 00:00:00",
		"mostGuestName": "O2 Academy Bristol",
		"mostGuestCity": "Bristol",
		"mostGuestAmount": 54
	},
	{
		"id": "a863a3f",
		"email": "kevinlillyjr@me.com",
		"name": "Kevin",
		"createdDate": "2022-08-17 02:25:32",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 546,
		"totalCountries": 1,
		"totalStates": 41,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 1589,
		"schedulePercent": 0.638988,
		"totalGuests": 339,
		"guestPercent": 0.622964,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 48
	},
	{
		"id": "09f4b02",
		"email": "tmblearn@usc.edu",
		"name": "Admin",
		"createdDate": "2022-03-18 19:13:30",
		"totalOrganizations": 1,
		"totalTours": 18,
		"totalDays": 110,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 187,
		"eventPercent": 0.784135,
		"totalScheduleItems": 488,
		"schedulePercent": 0.292014,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2574190",
		"email": "sambrewster@outlook.com",
		"name": "Sam",
		"createdDate": "2022-07-30 01:28:44",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 244,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 403,
		"schedulePercent": 0.258376,
		"totalGuests": 150,
		"guestPercent": 0.514766,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Adelaide Festival Centre- Her Majesty's Theatre",
		"mostGuestCity": "Adelaide",
		"mostGuestAmount": 21
	},
	{
		"id": "bbbe4cc",
		"email": "Cirquemusicamastertour@gmail.com",
		"name": "James",
		"createdDate": "2023-09-07 16:18:53",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 55,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 500,
		"schedulePercent": 0.298504,
		"totalGuests": 83,
		"guestPercent": 0.45835,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "Avenir Centre",
		"mostGuestCity": "Moncton",
		"mostGuestAmount": 20
	},
	{
		"id": "40b6de0",
		"email": "arie@chuckwalla.co.uk",
		"name": "Arie",
		"createdDate": "2019-02-26 00:51:33",
		"totalOrganizations": 30,
		"totalTours": 52,
		"totalDays": 840,
		"totalCountries": 27,
		"totalStates": 85,
		"totalEvents": 285,
		"eventPercent": 0.905973,
		"totalScheduleItems": 3443,
		"schedulePercent": 0.889683,
		"totalGuests": 695,
		"guestPercent": 0.751689,
		"mostGuestDate": "2023-10-04 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 71
	},
	{
		"id": "dfc27ed",
		"email": "david@dustinlynchmusic.com",
		"name": "David",
		"createdDate": "2015-12-03 05:44:40",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 400,
		"totalCountries": 3,
		"totalStates": 40,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 1817,
		"schedulePercent": 0.688386,
		"totalGuests": 995,
		"guestPercent": 0.822408,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Billy Bob's Texas",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 58
	},
	{
		"id": "cc6108b",
		"email": "laurie.st-jean@audiogram.com",
		"name": "Laurie",
		"createdDate": "2023-09-06 15:55:04",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 108,
		"totalCountries": 3,
		"totalStates": 9,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 271,
		"schedulePercent": 0.192822,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Imperial Bell",
		"mostGuestCity": "Quebec",
		"mostGuestAmount": 7
	},
	{
		"id": "a8b2487",
		"email": "lasse.karlsson@lifeline.se",
		"name": "Lasse",
		"createdDate": "2014-02-21 10:45:35",
		"totalOrganizations": 35,
		"totalTours": 8,
		"totalDays": 569,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 235,
		"eventPercent": 0.855516,
		"totalScheduleItems": 1791,
		"schedulePercent": 0.683088,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cb9e225",
		"email": "jtownley5@gmail.com",
		"name": "Jonathan",
		"createdDate": "2019-01-15 22:15:31",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 4,
		"totalStates": 39,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 2707,
		"schedulePercent": 0.819494,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9c68bf1",
		"email": "iman@rockforfoodprods.com",
		"name": "Imanol",
		"createdDate": "2022-08-29 14:29:28",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 248,
		"totalCountries": 14,
		"totalStates": 37,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 986,
		"schedulePercent": 0.47848,
		"totalGuests": 421,
		"guestPercent": 0.662032,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Ãrea 12 Alicante",
		"mostGuestCity": "Alacant",
		"mostGuestAmount": 35
	},
	{
		"id": "42dfa0a",
		"email": "stationego@hotmail.com",
		"name": "Jake",
		"createdDate": "2012-04-25 16:24:09",
		"totalOrganizations": 10,
		"totalTours": 16,
		"totalDays": 301,
		"totalCountries": 13,
		"totalStates": 60,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 1426,
		"schedulePercent": 0.598199,
		"totalGuests": 677,
		"guestPercent": 0.747053,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 50
	},
	{
		"id": "4a98e29",
		"email": "arons@cmhlabelgroup.com",
		"name": "Aron",
		"createdDate": "2023-09-05 23:52:13",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 151,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 126,
		"schedulePercent": 0.106741,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-12-10 00:00:00",
		"mostGuestName": "City Winery Philadelphia",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 1
	},
	{
		"id": "fa49b2c",
		"email": "marilyn@marilynsaid.com",
		"name": "Marilyn",
		"createdDate": "2018-01-04 23:33:27",
		"totalOrganizations": 12,
		"totalTours": 6,
		"totalDays": 850,
		"totalCountries": 4,
		"totalStates": 25,
		"totalEvents": 317,
		"eventPercent": 0.924381,
		"totalScheduleItems": 3126,
		"schedulePercent": 0.863859,
		"totalGuests": 161,
		"guestPercent": 0.523374,
		"mostGuestDate": "2023-09-12 00:00:00",
		"mostGuestName": "Globe Life Park in Arlington",
		"mostGuestCity": "Arlington",
		"mostGuestAmount": 18
	},
	{
		"id": "256252a",
		"email": "chrisnathanld@hotmail.com",
		"name": "Chris",
		"createdDate": "2011-09-26 20:33:10",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 366,
		"totalCountries": 3,
		"totalStates": 26,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 1010,
		"schedulePercent": 0.488147,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8a2ab38",
		"email": "nonlineardigest@gmail.com",
		"name": "Christopher",
		"createdDate": "2022-01-07 00:36:35",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 279,
		"totalCountries": 9,
		"totalStates": 40,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 944,
		"schedulePercent": 0.463515,
		"totalGuests": 686,
		"guestPercent": 0.749702,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 64
	},
	{
		"id": "2bf4eae",
		"email": "dexterandthemoonrocks@gmail.com",
		"name": "Ryan",
		"createdDate": "2023-03-02 20:05:09",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 304,
		"totalCountries": 1,
		"totalStates": 30,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 742,
		"schedulePercent": 0.397431,
		"totalGuests": 89,
		"guestPercent": 0.462323,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "The Garden",
		"mostGuestCity": "Lubbock",
		"mostGuestAmount": 29
	},
	{
		"id": "4de74f9",
		"email": "Jakej757@gmail.com",
		"name": "Jake",
		"createdDate": "2017-04-10 20:14:03",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 339,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 1937,
		"schedulePercent": 0.708648,
		"totalGuests": 583,
		"guestPercent": 0.719905,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "The Theater at Virgin Hotels",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 40
	},
	{
		"id": "efef211",
		"email": "dctsi1@gmail.com",
		"name": "Douglas",
		"createdDate": "2011-02-18 17:42:26",
		"totalOrganizations": 10,
		"totalTours": 5,
		"totalDays": 495,
		"totalCountries": 9,
		"totalStates": 38,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1650,
		"schedulePercent": 0.653423,
		"totalGuests": 109,
		"guestPercent": 0.481393,
		"mostGuestDate": "2023-10-02 00:00:00",
		"mostGuestName": "Carnegie Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 31
	},
	{
		"id": "c830a1e",
		"email": "katharina828@gmail.com",
		"name": "Katharina",
		"createdDate": "2009-11-02 15:37:29",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 1037,
		"totalCountries": 17,
		"totalStates": 40,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 2214,
		"schedulePercent": 0.752483,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-06-25 00:00:00",
		"mostGuestName": "Brita-Arena",
		"mostGuestCity": "Wiesbaden",
		"mostGuestAmount": 5
	},
	{
		"id": "fd4e66d",
		"email": "pdcopes@gmail.com",
		"name": "Peyton",
		"createdDate": "2016-06-02 00:34:41",
		"totalOrganizations": 14,
		"totalTours": 17,
		"totalDays": 341,
		"totalCountries": 6,
		"totalStates": 37,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 1444,
		"schedulePercent": 0.602304,
		"totalGuests": 210,
		"guestPercent": 0.557542,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 43
	},
	{
		"id": "4381a9a",
		"email": "tbloom1125@gmail.com",
		"name": "Tyler",
		"createdDate": "2022-02-12 05:00:43",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 484,
		"totalCountries": 4,
		"totalStates": 43,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 2318,
		"schedulePercent": 0.767978,
		"totalGuests": 1143,
		"guestPercent": 0.8481,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 86
	},
	{
		"id": "cde8fb0",
		"email": "melaniefiorille@gmail.com",
		"name": "Melanie",
		"createdDate": "2013-11-06 00:36:12",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 51,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 592,
		"schedulePercent": 0.336909,
		"totalGuests": 159,
		"guestPercent": 0.521918,
		"mostGuestDate": "2023-12-10 00:00:00",
		"mostGuestName": "State Theatre New Jersey",
		"mostGuestCity": "New Brunswick",
		"mostGuestAmount": 17
	},
	{
		"id": "c95f1d9",
		"email": "teisha@teishajames.com",
		"name": "Teisha",
		"createdDate": "2016-04-05 15:31:57",
		"totalOrganizations": 11,
		"totalTours": 10,
		"totalDays": 671,
		"totalCountries": 12,
		"totalStates": 56,
		"totalEvents": 137,
		"eventPercent": 0.666402,
		"totalScheduleItems": 1572,
		"schedulePercent": 0.635015,
		"totalGuests": 1323,
		"guestPercent": 0.874586,
		"mostGuestDate": "2023-10-08 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 116
	},
	{
		"id": "86f81cf",
		"email": "dpajunas@gmail.com",
		"name": "Dillon",
		"createdDate": "2016-04-12 21:51:36",
		"totalOrganizations": 22,
		"totalTours": 12,
		"totalDays": 316,
		"totalCountries": 15,
		"totalStates": 62,
		"totalEvents": 180,
		"eventPercent": 0.770229,
		"totalScheduleItems": 3196,
		"schedulePercent": 0.869421,
		"totalGuests": 2233,
		"guestPercent": 0.945305,
		"mostGuestDate": "2023-06-28 00:00:00",
		"mostGuestName": "The Complex - Rockwell",
		"mostGuestCity": "Salt Lake City",
		"mostGuestAmount": 91
	},
	{
		"id": "1a34fe6",
		"email": "dukefoster1@gmail.com",
		"name": "Duke",
		"createdDate": "2013-01-10 17:48:25",
		"totalOrganizations": 10,
		"totalTours": 6,
		"totalDays": 106,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 258,
		"schedulePercent": 0.184744,
		"totalGuests": 55,
		"guestPercent": 0.42842,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "The Grove Theatre",
		"mostGuestCity": "Fenelon Falls",
		"mostGuestAmount": 15
	},
	{
		"id": "7f9001f",
		"email": "jenna0wad@gmail.com",
		"name": "Jenna",
		"createdDate": "2019-12-21 00:35:00",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 211,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 185,
		"eventPercent": 0.780029,
		"totalScheduleItems": 1459,
		"schedulePercent": 0.605218,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-11-22 00:00:00",
		"mostGuestName": "H. Ric Luhrs Performing Arts Center",
		"mostGuestCity": "Shippensburg",
		"mostGuestAmount": 2
	},
	{
		"id": "e5f55c4",
		"email": "stevenpagetours@gmail.com",
		"name": "Steven",
		"createdDate": "2021-11-08 17:03:04",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 269,
		"totalCountries": 15,
		"totalStates": 54,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 2674,
		"schedulePercent": 0.816845,
		"totalGuests": 2009,
		"guestPercent": 0.933916,
		"mostGuestDate": "2023-09-12 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 76
	},
	{
		"id": "6d224e3",
		"email": "sbay646@gmail.com",
		"name": "Stephanie",
		"createdDate": "2011-10-12 21:30:01",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 134,
		"totalCountries": 11,
		"totalStates": 33,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 747,
		"schedulePercent": 0.400609,
		"totalGuests": 295,
		"guestPercent": 0.599788,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "The Fillmore",
		"mostGuestCity": "San Francisco",
		"mostGuestAmount": 29
	},
	{
		"id": "152ad47",
		"email": "luke@ldlx.com.au",
		"name": "Luke",
		"createdDate": "2022-07-05 22:37:30",
		"totalOrganizations": 11,
		"totalTours": 14,
		"totalDays": 151,
		"totalCountries": 11,
		"totalStates": 24,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 981,
		"schedulePercent": 0.475831,
		"totalGuests": 834,
		"guestPercent": 0.784267,
		"mostGuestDate": "2023-09-09 00:00:00",
		"mostGuestName": "Forum Melbourne",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 89
	},
	{
		"id": "7cf0cdb",
		"email": "juanitajoaquin@gmail.com",
		"name": "Juanita ",
		"createdDate": "2023-09-18 19:24:12",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 69,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 590,
		"schedulePercent": 0.33585,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c128997",
		"email": "gabe@hellboundmanagement.com",
		"name": "Gabriel",
		"createdDate": "2012-05-10 15:06:16",
		"totalOrganizations": 8,
		"totalTours": 19,
		"totalDays": 300,
		"totalCountries": 18,
		"totalStates": 45,
		"totalEvents": 159,
		"eventPercent": 0.726394,
		"totalScheduleItems": 2100,
		"schedulePercent": 0.735134,
		"totalGuests": 810,
		"guestPercent": 0.778837,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 57
	},
	{
		"id": "1ae8368",
		"email": "logcabinwireless@hotmail.com",
		"name": "Zachary ",
		"createdDate": "2023-04-05 15:16:38",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 46,
		"totalCountries": 8,
		"totalStates": 18,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 324,
		"schedulePercent": 0.221428,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "Jazzhaus Freiburg",
		"mostGuestCity": "Freiburg im Breisgau",
		"mostGuestAmount": 5
	},
	{
		"id": "4bfc790",
		"email": "mr.jamesdean@me.com",
		"name": "James",
		"createdDate": "2012-06-11 22:14:02",
		"totalOrganizations": 7,
		"totalTours": 1,
		"totalDays": 248,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 817,
		"schedulePercent": 0.425374,
		"totalGuests": 935,
		"guestPercent": 0.80837,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "Bayfront Festival Park",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 162
	},
	{
		"id": "2a93976",
		"email": "karin.gunnarsson@riksteatern.se",
		"name": "Karin ",
		"createdDate": "2017-12-06 10:50:22",
		"totalOrganizations": 12,
		"totalTours": 3,
		"totalDays": 276,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 210,
		"schedulePercent": 0.156006,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f385127",
		"email": "kleon@pilobolus.org",
		"name": "Kirsten",
		"createdDate": "2021-07-30 15:38:22",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 388,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1177,
		"schedulePercent": 0.533704,
		"totalGuests": 233,
		"guestPercent": 0.569726,
		"mostGuestDate": "2023-03-12 00:00:00",
		"mostGuestName": "Shubert Theatre",
		"mostGuestCity": "New Haven",
		"mostGuestAmount": 18
	},
	{
		"id": "973c984",
		"email": "stew.is.ray@gmail.com",
		"name": "Stew",
		"createdDate": "2023-03-29 06:59:14",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 269,
		"totalCountries": 1,
		"totalStates": 34,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1117,
		"schedulePercent": 0.515296,
		"totalGuests": 522,
		"guestPercent": 0.69898,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Ogden Theatre",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 42
	},
	{
		"id": "a17ed04",
		"email": "mefecteau@gestev.com",
		"name": "Marie-Eve",
		"createdDate": "2023-08-02 17:30:31",
		"totalOrganizations": 35,
		"totalTours": 15,
		"totalDays": 209,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 195,
		"eventPercent": 0.796318,
		"totalScheduleItems": 953,
		"schedulePercent": 0.466826,
		"totalGuests": 1742,
		"guestPercent": 0.915773,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "ThÃ©Ã¢tre St-Denis",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 204
	},
	{
		"id": "9f8aeff",
		"email": "management@amplifiremusic.com",
		"name": "Stu",
		"createdDate": "2016-01-15 17:51:04",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 174,
		"totalCountries": 6,
		"totalStates": 18,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 663,
		"schedulePercent": 0.366177,
		"totalGuests": 731,
		"guestPercent": 0.759634,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "The Forum ",
		"mostGuestCity": "Melbourne ",
		"mostGuestAmount": 131
	},
	{
		"id": "93946f3",
		"email": "Tasha_wright@icloud.com",
		"name": "Tasha",
		"createdDate": "2015-07-06 14:28:35",
		"totalOrganizations": 10,
		"totalTours": 7,
		"totalDays": 195,
		"totalCountries": 7,
		"totalStates": 36,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 1068,
		"schedulePercent": 0.504436,
		"totalGuests": 1501,
		"guestPercent": 0.894451,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 183
	},
	{
		"id": "7c9f098",
		"email": "casvalwolfe@gmail.com",
		"name": "Casval",
		"createdDate": "2022-08-22 21:45:22",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 296,
		"totalCountries": 3,
		"totalStates": 50,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 697,
		"schedulePercent": 0.379552,
		"totalGuests": 348,
		"guestPercent": 0.628658,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 47
	},
	{
		"id": "ae21cba",
		"email": "Meganmkraemer@gmail.com",
		"name": "Megan",
		"createdDate": "2017-11-20 03:11:55",
		"totalOrganizations": 8,
		"totalTours": 55,
		"totalDays": 800,
		"totalCountries": 6,
		"totalStates": 55,
		"totalEvents": 246,
		"eventPercent": 0.868759,
		"totalScheduleItems": 3851,
		"schedulePercent": 0.915375,
		"totalGuests": 1885,
		"guestPercent": 0.926897,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Nashville Municipal Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 111
	},
	{
		"id": "a124190",
		"email": "christian@abvgrnd.com",
		"name": "Christian",
		"createdDate": "2021-09-20 17:49:04",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 992,
		"totalCountries": 9,
		"totalStates": 24,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 933,
		"schedulePercent": 0.460734,
		"totalGuests": 101,
		"guestPercent": 0.474904,
		"mostGuestDate": "2023-08-09 00:00:00",
		"mostGuestName": "NYCB Theatre at Westbury",
		"mostGuestCity": "Westbury",
		"mostGuestAmount": 15
	},
	{
		"id": "001111f",
		"email": "phil@shihad.com",
		"name": "Phil",
		"createdDate": "2015-09-23 10:13:24",
		"totalOrganizations": 40,
		"totalTours": 23,
		"totalDays": 382,
		"totalCountries": 4,
		"totalStates": 16,
		"totalEvents": 176,
		"eventPercent": 0.763475,
		"totalScheduleItems": 2483,
		"schedulePercent": 0.791153,
		"totalGuests": 467,
		"guestPercent": 0.680307,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "Palais Theatre",
		"mostGuestCity": "St Kilda",
		"mostGuestAmount": 117
	},
	{
		"id": "fb275ba",
		"email": "connory@hotmail.ca",
		"name": "Connor",
		"createdDate": "2019-05-27 01:01:12",
		"totalOrganizations": 12,
		"totalTours": 15,
		"totalDays": 635,
		"totalCountries": 14,
		"totalStates": 61,
		"totalEvents": 235,
		"eventPercent": 0.855516,
		"totalScheduleItems": 2813,
		"schedulePercent": 0.833929,
		"totalGuests": 2051,
		"guestPercent": 0.937227,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "HISTORY",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 117
	},
	{
		"id": "9604c80",
		"email": "info@rot90s.com",
		"name": "Toby ",
		"createdDate": "2023-08-09 11:58:50",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 487,
		"totalCountries": 9,
		"totalStates": 43,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 3089,
		"schedulePercent": 0.860283,
		"totalGuests": 888,
		"guestPercent": 0.796451,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 125
	},
	{
		"id": "95bf263",
		"email": "ambertogo@gmail.com",
		"name": "Amber",
		"createdDate": "2014-02-25 00:23:28",
		"totalOrganizations": 7,
		"totalTours": 14,
		"totalDays": 902,
		"totalCountries": 22,
		"totalStates": 69,
		"totalEvents": 277,
		"eventPercent": 0.899616,
		"totalScheduleItems": 4665,
		"schedulePercent": 0.950735,
		"totalGuests": 66,
		"guestPercent": 0.441001,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "Allegiant Stadium",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 66
	},
	{
		"id": "4cc0e52",
		"email": "terralightfoot@gmail.com",
		"name": "Terra",
		"createdDate": "2015-05-06 03:58:03",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 603,
		"totalCountries": 4,
		"totalStates": 15,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 233,
		"schedulePercent": 0.171368,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "RBC Ottawa BluesFest",
		"mostGuestCity": "Ottawa",
		"mostGuestAmount": 7
	},
	{
		"id": "70fb6c8",
		"email": "chelsea@upstairsmusic.com.au",
		"name": "Chelsea",
		"createdDate": "2013-06-03 01:40:29",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 259,
		"totalCountries": 18,
		"totalStates": 47,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 1583,
		"schedulePercent": 0.637664,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "dc1f54c",
		"email": "marykirk.pollard@gmail.com",
		"name": "MaryKirk",
		"createdDate": "2019-08-22 14:53:55",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 105,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 887,
		"schedulePercent": 0.448947,
		"totalGuests": 19,
		"guestPercent": 0.366971,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Grand Garden Arena",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 19
	},
	{
		"id": "a05afad",
		"email": "divinejr@wumusicgroup.com",
		"name": "Divine",
		"createdDate": "2019-05-08 12:32:31",
		"totalOrganizations": 7,
		"totalTours": 4,
		"totalDays": 482,
		"totalCountries": 11,
		"totalStates": 33,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1274,
		"schedulePercent": 0.557674,
		"totalGuests": 1720,
		"guestPercent": 0.914448,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Grand Garden Arena",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 161
	},
	{
		"id": "51968a6",
		"email": "h.kopton@msk-live.de",
		"name": "Hannah",
		"createdDate": "2022-11-07 16:04:39",
		"totalOrganizations": 13,
		"totalTours": 13,
		"totalDays": 386,
		"totalCountries": 3,
		"totalStates": 20,
		"totalEvents": 205,
		"eventPercent": 0.814859,
		"totalScheduleItems": 2272,
		"schedulePercent": 0.762416,
		"totalGuests": 678,
		"guestPercent": 0.747451,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "E-Werk",
		"mostGuestCity": "Koln",
		"mostGuestAmount": 82
	},
	{
		"id": "1997d9a",
		"email": "robertbastin@me.com",
		"name": "Robert",
		"createdDate": "2017-06-27 16:35:31",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 643,
		"totalCountries": 3,
		"totalStates": 28,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 952,
		"schedulePercent": 0.466163,
		"totalGuests": 314,
		"guestPercent": 0.61131,
		"mostGuestDate": "2023-05-22 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 42
	},
	{
		"id": "b30b4eb",
		"email": "weekdaysareweakdays@gmail.com",
		"name": "Rhyan",
		"createdDate": "2017-09-26 02:45:19",
		"totalOrganizations": 18,
		"totalTours": 25,
		"totalDays": 239,
		"totalCountries": 11,
		"totalStates": 44,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 1595,
		"schedulePercent": 0.641637,
		"totalGuests": 912,
		"guestPercent": 0.802145,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 188
	},
	{
		"id": "d1e2211",
		"email": "kcmixes@me.com",
		"name": "Kory",
		"createdDate": "2023-02-28 23:07:22",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 111,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 773,
		"schedulePercent": 0.410012,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6e4c8b3",
		"email": "jake.napoli@gmail.com",
		"name": "Jake",
		"createdDate": "2019-09-10 21:47:43",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 429,
		"totalCountries": 4,
		"totalStates": 44,
		"totalEvents": 175,
		"eventPercent": 0.761753,
		"totalScheduleItems": 3355,
		"schedulePercent": 0.883856,
		"totalGuests": 499,
		"guestPercent": 0.691432,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 139
	},
	{
		"id": "7560dfe",
		"email": "SullivanKingMusic@gmail.com",
		"name": "Keaton",
		"createdDate": "2021-12-06 05:52:20",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 298,
		"totalCountries": 6,
		"totalStates": 42,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 850,
		"schedulePercent": 0.437955,
		"totalGuests": 36,
		"guestPercent": 0.397961,
		"mostGuestDate": "2023-04-15 00:00:00",
		"mostGuestName": "The Bomb Factory",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 11
	},
	{
		"id": "a35763b",
		"email": "wkolacek76@gmail.com",
		"name": "William",
		"createdDate": "2017-08-14 03:31:05",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 104,
		"totalCountries": 13,
		"totalStates": 34,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 643,
		"schedulePercent": 0.360085,
		"totalGuests": 304,
		"guestPercent": 0.605218,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "House of Blues Orlando",
		"mostGuestCity": "Lake Buena Vista",
		"mostGuestAmount": 49
	},
	{
		"id": "af74f3b",
		"email": "erinricig@icloud.com",
		"name": "Erin",
		"createdDate": "2017-05-19 01:09:58",
		"totalOrganizations": 1,
		"totalTours": 9,
		"totalDays": 141,
		"totalCountries": 4,
		"totalStates": 29,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 1012,
		"schedulePercent": 0.488412,
		"totalGuests": 945,
		"guestPercent": 0.810886,
		"mostGuestDate": "2023-04-13 00:00:00",
		"mostGuestName": "The Paramount",
		"mostGuestCity": "Huntington",
		"mostGuestAmount": 60
	},
	{
		"id": "292c027",
		"email": "Iza@izaboethius.com",
		"name": "Iza",
		"createdDate": "2019-06-11 15:12:47",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 132,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 636,
		"schedulePercent": 0.356774,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fa4d8a9",
		"email": "troy@tdbproductions.com.au",
		"name": "Troy",
		"createdDate": "2018-11-25 00:59:54",
		"totalOrganizations": 24,
		"totalTours": 12,
		"totalDays": 886,
		"totalCountries": 14,
		"totalStates": 56,
		"totalEvents": 180,
		"eventPercent": 0.770229,
		"totalScheduleItems": 3018,
		"schedulePercent": 0.852735,
		"totalGuests": 2077,
		"guestPercent": 0.938551,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Raymond James Stadium",
		"mostGuestCity": "Tampa ",
		"mostGuestAmount": 248
	},
	{
		"id": "0a18d23",
		"email": "derekgramke@ymail.com",
		"name": "Derek",
		"createdDate": "2014-11-10 11:13:53",
		"totalOrganizations": 17,
		"totalTours": 13,
		"totalDays": 337,
		"totalCountries": 4,
		"totalStates": 40,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 1973,
		"schedulePercent": 0.714607,
		"totalGuests": 1522,
		"guestPercent": 0.896835,
		"mostGuestDate": "2023-10-03 00:00:00",
		"mostGuestName": "YouTube Theatre",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 110
	},
	{
		"id": "58e778d",
		"email": "Kleas@clairglobal.com",
		"name": "Kevin",
		"createdDate": "2016-08-06 21:48:11",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 51,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 851,
		"schedulePercent": 0.43822,
		"totalGuests": 618,
		"guestPercent": 0.730897,
		"mostGuestDate": "2023-08-30 00:00:00",
		"mostGuestName": "Hollywood Casino Amphitheatre",
		"mostGuestCity": "Tinley Park",
		"mostGuestAmount": 122
	},
	{
		"id": "410c250",
		"email": "maudelexx@mac.com",
		"name": "Alexis",
		"createdDate": "2016-09-01 20:14:14",
		"totalOrganizations": 17,
		"totalTours": 26,
		"totalDays": 887,
		"totalCountries": 21,
		"totalStates": 69,
		"totalEvents": 304,
		"eventPercent": 0.917892,
		"totalScheduleItems": 6302,
		"schedulePercent": 0.983578,
		"totalGuests": 2584,
		"guestPercent": 0.956165,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "Pine Knob Music Theatre",
		"mostGuestCity": "Clarkston",
		"mostGuestAmount": 104
	},
	{
		"id": "398bde1",
		"email": "ollie@traption.co.uk",
		"name": "Ollie",
		"createdDate": "2016-10-15 03:02:38",
		"totalOrganizations": 19,
		"totalTours": 24,
		"totalDays": 689,
		"totalCountries": 16,
		"totalStates": 57,
		"totalEvents": 194,
		"eventPercent": 0.794597,
		"totalScheduleItems": 4130,
		"schedulePercent": 0.929413,
		"totalGuests": 254,
		"guestPercent": 0.581248,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "Orpheum Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 34
	},
	{
		"id": "f5bc454",
		"email": "misscameron1@gmail.com",
		"name": "Heather ",
		"createdDate": "2017-03-11 18:20:19",
		"totalOrganizations": 4,
		"totalTours": 30,
		"totalDays": 248,
		"totalCountries": 12,
		"totalStates": 39,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 1123,
		"schedulePercent": 0.51715,
		"totalGuests": 412,
		"guestPercent": 0.658588,
		"mostGuestDate": "2023-11-02 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 41
	},
	{
		"id": "e9cb53f",
		"email": "nicktianodso@gmail.com",
		"name": "Nick",
		"createdDate": "2010-03-05 19:32:45",
		"totalOrganizations": 5,
		"totalTours": 17,
		"totalDays": 571,
		"totalCountries": 4,
		"totalStates": 35,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 1742,
		"schedulePercent": 0.672891,
		"totalGuests": 1235,
		"guestPercent": 0.8628,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 41
	},
	{
		"id": "eb67192",
		"email": "bonkasthepa@gmail.com",
		"name": "Bianca",
		"createdDate": "2019-07-23 02:06:08",
		"totalOrganizations": 7,
		"totalTours": 13,
		"totalDays": 1133,
		"totalCountries": 20,
		"totalStates": 72,
		"totalEvents": 459,
		"eventPercent": 0.969011,
		"totalScheduleItems": 7724,
		"schedulePercent": 0.992451,
		"totalGuests": 3748,
		"guestPercent": 0.980665,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 185
	},
	{
		"id": "f85d891",
		"email": "selina@letztewoelfe.com",
		"name": "Selina",
		"createdDate": "2022-10-18 08:24:25",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 152,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 305,
		"schedulePercent": 0.210171,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b85d8d7",
		"email": "sandra.kalnins@livenation.se",
		"name": "Sandra",
		"createdDate": "2023-01-13 11:37:10",
		"totalOrganizations": 14,
		"totalTours": 14,
		"totalDays": 271,
		"totalCountries": 3,
		"totalStates": 16,
		"totalEvents": 185,
		"eventPercent": 0.780029,
		"totalScheduleItems": 1234,
		"schedulePercent": 0.548537,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2c1df8a",
		"email": "JakevernumLX@gmail.com",
		"name": "Jake",
		"createdDate": "2018-03-23 11:56:45",
		"totalOrganizations": 5,
		"totalTours": 19,
		"totalDays": 234,
		"totalCountries": 11,
		"totalStates": 23,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 2053,
		"schedulePercent": 0.728248,
		"totalGuests": 900,
		"guestPercent": 0.799629,
		"mostGuestDate": "2023-04-06 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 106
	},
	{
		"id": "7b91953",
		"email": "dave@davejac.net",
		"name": "Dave",
		"createdDate": "2010-05-04 15:37:02",
		"totalOrganizations": 10,
		"totalTours": 6,
		"totalDays": 802,
		"totalCountries": 2,
		"totalStates": 43,
		"totalEvents": 277,
		"eventPercent": 0.899616,
		"totalScheduleItems": 1581,
		"schedulePercent": 0.636869,
		"totalGuests": 156,
		"guestPercent": 0.518607,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "Arrow Rock Lyceum Theatre",
		"mostGuestCity": "Arrow Rock",
		"mostGuestAmount": 22
	},
	{
		"id": "543ce52",
		"email": "steg5150@mac.com",
		"name": "Tom",
		"createdDate": "2012-10-06 02:02:17",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 352,
		"totalCountries": 4,
		"totalStates": 41,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 1402,
		"schedulePercent": 0.59171,
		"totalGuests": 367,
		"guestPercent": 0.64018,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "The Midland Theatre",
		"mostGuestCity": "Kansas City",
		"mostGuestAmount": 106
	},
	{
		"id": "8bb5b75",
		"email": "imwithdonlee@gmail.com",
		"name": "Don",
		"createdDate": "2012-03-29 19:02:49",
		"totalOrganizations": 4,
		"totalTours": 16,
		"totalDays": 211,
		"totalCountries": 9,
		"totalStates": 28,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1727,
		"schedulePercent": 0.670905,
		"totalGuests": 1451,
		"guestPercent": 0.887432,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Brookside Golf Course",
		"mostGuestCity": "Pasadena",
		"mostGuestAmount": 125
	},
	{
		"id": "baf0d21",
		"email": "manish@blaide.live",
		"name": "Manish",
		"createdDate": "2022-10-19 14:02:37",
		"totalOrganizations": 2,
		"totalTours": 8,
		"totalDays": 130,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 7,
		"schedulePercent": 0.0181433,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "dce4b7a",
		"email": "roadpresser@gmail.com",
		"name": "Jesus",
		"createdDate": "2016-06-29 10:01:23",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 136,
		"totalCountries": 11,
		"totalStates": 24,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 967,
		"schedulePercent": 0.470269,
		"totalGuests": 783,
		"guestPercent": 0.772613,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Movistar Arena",
		"mostGuestCity": "CABA",
		"mostGuestAmount": 69
	},
	{
		"id": "05763c1",
		"email": "Potenza.drew@gmail.com",
		"name": "Andrew",
		"createdDate": "2012-06-14 19:48:08",
		"totalOrganizations": 10,
		"totalTours": 12,
		"totalDays": 289,
		"totalCountries": 7,
		"totalStates": 43,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 1558,
		"schedulePercent": 0.63144,
		"totalGuests": 951,
		"guestPercent": 0.813005,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "Christchurch Town Hall of the Performing Arts",
		"mostGuestCity": "Christchurch",
		"mostGuestAmount": 93
	},
	{
		"id": "f857bf2",
		"email": "chris@bigfishbookingco.com",
		"name": "Christopher",
		"createdDate": "2022-02-11 18:27:52",
		"totalOrganizations": 10,
		"totalTours": 16,
		"totalDays": 849,
		"totalCountries": 11,
		"totalStates": 41,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 1138,
		"schedulePercent": 0.522845,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Teatro Romano di Fiesole",
		"mostGuestCity": "Fiesole",
		"mostGuestAmount": 2
	},
	{
		"id": "24e6590",
		"email": "caroll@starseedentertainment.com",
		"name": "Mike",
		"createdDate": "2023-09-05 20:36:50",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 227,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 726,
		"schedulePercent": 0.391074,
		"totalGuests": 334,
		"guestPercent": 0.620183,
		"mostGuestDate": "2023-10-24 00:00:00",
		"mostGuestName": "Conexus Arts Centre",
		"mostGuestCity": "Regina",
		"mostGuestAmount": 30
	},
	{
		"id": "968e1ad",
		"email": "garynotley1@gmail.com",
		"name": "GARY",
		"createdDate": "2013-07-21 11:21:57",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 186,
		"totalCountries": 8,
		"totalStates": 34,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 2095,
		"schedulePercent": 0.734472,
		"totalGuests": 1552,
		"guestPercent": 0.899086,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Little Caesars Arena",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 205
	},
	{
		"id": "fc34b38",
		"email": "info@maximelandry.com",
		"name": "Maxime",
		"createdDate": "2022-09-21 16:01:25",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 57,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 265,
		"schedulePercent": 0.189511,
		"totalGuests": 63,
		"guestPercent": 0.437558,
		"mostGuestDate": "2023-04-13 00:00:00",
		"mostGuestName": "Lionel-Groulx Theatre",
		"mostGuestCity": "Sainte-ThÃ©rÃ¨se",
		"mostGuestAmount": 12
	},
	{
		"id": "112aa47",
		"email": "ashleigh@thebeehive.la",
		"name": "Ashleigh",
		"createdDate": "2021-10-19 19:33:46",
		"totalOrganizations": 41,
		"totalTours": 20,
		"totalDays": 826,
		"totalCountries": 15,
		"totalStates": 49,
		"totalEvents": 160,
		"eventPercent": 0.72838,
		"totalScheduleItems": 1852,
		"schedulePercent": 0.695405,
		"totalGuests": 704,
		"guestPercent": 0.754072,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "The Salt Shed",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 62
	},
	{
		"id": "9081c95",
		"email": "norpmagnus@gmail.com",
		"name": "Norpan",
		"createdDate": "2017-09-25 13:11:06",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 45,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 215,
		"schedulePercent": 0.159846,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "abbbc50",
		"email": "n.dagostino@hotmail.com",
		"name": "Nathan",
		"createdDate": "2017-06-14 04:00:18",
		"totalOrganizations": 15,
		"totalTours": 20,
		"totalDays": 485,
		"totalCountries": 11,
		"totalStates": 44,
		"totalEvents": 203,
		"eventPercent": 0.811681,
		"totalScheduleItems": 3310,
		"schedulePercent": 0.878294,
		"totalGuests": 1083,
		"guestPercent": 0.837108,
		"mostGuestDate": "2023-06-01 00:00:00",
		"mostGuestName": "The Enmore Theatre",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 107
	},
	{
		"id": "aff9877",
		"email": "gidono@me.com",
		"name": "Gioia",
		"createdDate": "2017-03-01 16:14:49",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 56,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 251,
		"schedulePercent": 0.18183,
		"totalGuests": 24,
		"guestPercent": 0.375712,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "PARCO DI  SAN GIULIANO MESTRE",
		"mostGuestCity": "VENEZIA",
		"mostGuestAmount": 17
	},
	{
		"id": "2323715",
		"email": "beebebedell0@gmail.com",
		"name": "Beebe",
		"createdDate": "2023-03-07 22:25:57",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 188,
		"totalCountries": 7,
		"totalStates": 38,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 1519,
		"schedulePercent": 0.622964,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1de06b0",
		"email": "info@eastnashgrass.com",
		"name": "Maddie",
		"createdDate": "2023-07-23 14:31:41",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 46,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 157,
		"schedulePercent": 0.125281,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c2fa9f9",
		"email": "harryt@fastmail.com",
		"name": "Harry",
		"createdDate": "2016-04-19 04:27:43",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 511,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 2780,
		"schedulePercent": 0.829294,
		"totalGuests": 783,
		"guestPercent": 0.772613,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "The Orion Amphitheater",
		"mostGuestCity": "Huntsville",
		"mostGuestAmount": 80
	},
	{
		"id": "aecc7ce",
		"email": "yingyau@me.com",
		"name": "Ying",
		"createdDate": "2017-08-16 12:06:57",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 411,
		"totalCountries": 10,
		"totalStates": 15,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 1276,
		"schedulePercent": 0.558734,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "040ab99",
		"email": "a.krohn@liveeyemusic.com",
		"name": "Andre",
		"createdDate": "2018-01-23 18:55:44",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 140,
		"totalCountries": 3,
		"totalStates": 6,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 741,
		"schedulePercent": 0.396636,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "83457d5",
		"email": "fraser@monikeraudio.com",
		"name": "Fraser",
		"createdDate": "2016-02-19 02:54:25",
		"totalOrganizations": 14,
		"totalTours": 28,
		"totalDays": 667,
		"totalCountries": 18,
		"totalStates": 62,
		"totalEvents": 182,
		"eventPercent": 0.773408,
		"totalScheduleItems": 2788,
		"schedulePercent": 0.830354,
		"totalGuests": 718,
		"guestPercent": 0.757516,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 207
	},
	{
		"id": "e889bbf",
		"email": "gazwestman@gmail.com",
		"name": "Gary",
		"createdDate": "2010-04-05 22:19:00",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 242,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 491,
		"schedulePercent": 0.293206,
		"totalGuests": 408,
		"guestPercent": 0.656999,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 63
	},
	{
		"id": "c03cb18",
		"email": "Zachstern1@gmail.com",
		"name": "Zachary",
		"createdDate": "2017-11-23 23:22:36",
		"totalOrganizations": 23,
		"totalTours": 30,
		"totalDays": 1120,
		"totalCountries": 21,
		"totalStates": 67,
		"totalEvents": 283,
		"eventPercent": 0.904516,
		"totalScheduleItems": 5035,
		"schedulePercent": 0.962786,
		"totalGuests": 1662,
		"guestPercent": 0.908621,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "The Forum ",
		"mostGuestCity": "Melbourne ",
		"mostGuestAmount": 131
	},
	{
		"id": "5a414c7",
		"email": "tourmanager@rhythmofthearts.com",
		"name": "John",
		"createdDate": "2022-09-11 15:48:50",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 157,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 658,
		"schedulePercent": 0.364455,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d6fa5a2",
		"email": "robban@rarproduktion.com",
		"name": "Robert",
		"createdDate": "2015-06-03 19:43:52",
		"totalOrganizations": 14,
		"totalTours": 5,
		"totalDays": 315,
		"totalCountries": 9,
		"totalStates": 23,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 1094,
		"schedulePercent": 0.510528,
		"totalGuests": 511,
		"guestPercent": 0.695669,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Gullmarsvallen",
		"mostGuestCity": "Lysekil",
		"mostGuestAmount": 159
	},
	{
		"id": "dca3f4c",
		"email": "Ben.dexter@gmail.com",
		"name": "Ben",
		"createdDate": "2013-01-28 16:49:40",
		"totalOrganizations": 16,
		"totalTours": 9,
		"totalDays": 606,
		"totalCountries": 10,
		"totalStates": 32,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 1447,
		"schedulePercent": 0.602834,
		"totalGuests": 121,
		"guestPercent": 0.490928,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 55
	},
	{
		"id": "a0472fb",
		"email": "sarkintm@gmail.com",
		"name": "Brian",
		"createdDate": "2019-06-13 21:57:46",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 191,
		"totalCountries": 3,
		"totalStates": 31,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 1063,
		"schedulePercent": 0.501788,
		"totalGuests": 504,
		"guestPercent": 0.693021,
		"mostGuestDate": "2023-03-22 00:00:00",
		"mostGuestName": "The Factory in Deep Ellum",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 39
	},
	{
		"id": "8b3562d",
		"email": "peter@4ears.be",
		"name": "Peter",
		"createdDate": "2017-08-02 20:18:15",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 97,
		"totalCountries": 4,
		"totalStates": 10,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 468,
		"schedulePercent": 0.283936,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "143c820",
		"email": "jayinman22@gmail.com",
		"name": "James",
		"createdDate": "2023-06-23 20:32:44",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 38,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 249,
		"schedulePercent": 0.181168,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f7ba5e7",
		"email": "hoffmannwolf@mac.com",
		"name": "Wolf",
		"createdDate": "2018-11-07 15:10:17",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 91,
		"totalCountries": 17,
		"totalStates": 32,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 613,
		"schedulePercent": 0.346974,
		"totalGuests": 27,
		"guestPercent": 0.38379,
		"mostGuestDate": "2023-04-25 00:00:00",
		"mostGuestName": "Vorterix",
		"mostGuestCity": "CABA",
		"mostGuestAmount": 8
	},
	{
		"id": "443072e",
		"email": "dougmwilson@gmail.com",
		"name": "Doug",
		"createdDate": "2017-08-23 17:50:27",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 243,
		"totalCountries": 6,
		"totalStates": 37,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1553,
		"schedulePercent": 0.630645,
		"totalGuests": 489,
		"guestPercent": 0.688253,
		"mostGuestDate": "2023-03-10 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 46
	},
	{
		"id": "c44d0a9",
		"email": "robin.hedberg@gmail.com",
		"name": "Robin",
		"createdDate": "2015-02-12 14:57:34",
		"totalOrganizations": 18,
		"totalTours": 6,
		"totalDays": 174,
		"totalCountries": 14,
		"totalStates": 38,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1060,
		"schedulePercent": 0.500861,
		"totalGuests": 875,
		"guestPercent": 0.793008,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Gustavsvik Resorts AB",
		"mostGuestCity": "Ã–rebro",
		"mostGuestAmount": 91
	},
	{
		"id": "c94ba80",
		"email": "scottxcarey@gmail.com",
		"name": "Scott",
		"createdDate": "2019-03-15 23:24:23",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 315,
		"totalCountries": 18,
		"totalStates": 69,
		"totalEvents": 183,
		"eventPercent": 0.775659,
		"totalScheduleItems": 2667,
		"schedulePercent": 0.816316,
		"totalGuests": 1649,
		"guestPercent": 0.9069,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "Olympiahalle",
		"mostGuestCity": "MÃ¼nchen",
		"mostGuestAmount": 135
	},
	{
		"id": "fd1b07b",
		"email": "gt.mastertour@gmail.com",
		"name": "Gentry Touring",
		"createdDate": "2023-09-10 03:18:01",
		"totalOrganizations": 9,
		"totalTours": 9,
		"totalDays": 923,
		"totalCountries": 5,
		"totalStates": 42,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 2305,
		"schedulePercent": 0.765859,
		"totalGuests": 924,
		"guestPercent": 0.804794,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 81
	},
	{
		"id": "750cbf9",
		"email": "larchehug@gmail.com",
		"name": "Hugues",
		"createdDate": "2018-10-15 19:46:16",
		"totalOrganizations": 35,
		"totalTours": 21,
		"totalDays": 571,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 528,
		"eventPercent": 0.977222,
		"totalScheduleItems": 2566,
		"schedulePercent": 0.801483,
		"totalGuests": 5598,
		"guestPercent": 0.994173,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "ThÃ©Ã¢tre St-Denis",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 204
	},
	{
		"id": "9e2059e",
		"email": "brianthunders@gmail.com",
		"name": "Brian",
		"createdDate": "2012-08-20 14:42:47",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 71,
		"totalCountries": 3,
		"totalStates": 17,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 537,
		"schedulePercent": 0.316514,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Pepsi Center WTC",
		"mostGuestCity": "Benito Juarez",
		"mostGuestAmount": 4
	},
	{
		"id": "5a875ae",
		"email": "colinjamesmoore@me.com",
		"name": "Colin",
		"createdDate": "2013-07-04 18:14:40",
		"totalOrganizations": 22,
		"totalTours": 26,
		"totalDays": 1471,
		"totalCountries": 17,
		"totalStates": 75,
		"totalEvents": 439,
		"eventPercent": 0.966627,
		"totalScheduleItems": 7193,
		"schedulePercent": 0.990068,
		"totalGuests": 2064,
		"guestPercent": 0.937757,
		"mostGuestDate": "2023-05-02 00:00:00",
		"mostGuestName": "The Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 99
	},
	{
		"id": "354f4fc",
		"email": "sk@lichtscheu.com",
		"name": "Silvio",
		"createdDate": "2015-10-29 13:17:30",
		"totalOrganizations": 11,
		"totalTours": 6,
		"totalDays": 458,
		"totalCountries": 17,
		"totalStates": 37,
		"totalEvents": 245,
		"eventPercent": 0.867435,
		"totalScheduleItems": 3516,
		"schedulePercent": 0.895378,
		"totalGuests": 5400,
		"guestPercent": 0.993643,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Olympiastadion Berlin",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 794
	},
	{
		"id": "30cbe80",
		"email": "julianne@disquesfarwest.com",
		"name": "Julianne",
		"createdDate": "2023-08-07 17:36:19",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 999,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 296,
		"schedulePercent": 0.205933,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "468e64e",
		"email": "joshuagaynorcote@gmail.com",
		"name": "Joshua ",
		"createdDate": "2022-05-26 18:59:10",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 589,
		"schedulePercent": 0.335055,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "106fe1f",
		"email": "catherine@disquesfarwest.com",
		"name": "Catherine",
		"createdDate": "2021-11-11 21:24:07",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 1079,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 468,
		"schedulePercent": 0.283936,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5beeb30",
		"email": "Lizrose.ws@gmail.com",
		"name": "Elizabeth",
		"createdDate": "2018-01-22 19:08:37",
		"totalOrganizations": 7,
		"totalTours": 14,
		"totalDays": 320,
		"totalCountries": 13,
		"totalStates": 49,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1215,
		"schedulePercent": 0.543769,
		"totalGuests": 47,
		"guestPercent": 0.417031,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "Kia Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 20
	},
	{
		"id": "09f5ec4",
		"email": "shirleyminne@aol.com",
		"name": "Shirley",
		"createdDate": "2010-06-30 16:21:57",
		"totalOrganizations": 11,
		"totalTours": 9,
		"totalDays": 697,
		"totalCountries": 3,
		"totalStates": 46,
		"totalEvents": 221,
		"eventPercent": 0.83777,
		"totalScheduleItems": 3862,
		"schedulePercent": 0.915905,
		"totalGuests": 3800,
		"guestPercent": 0.981724,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 185
	},
	{
		"id": "1509c33",
		"email": "db@davebelis.com",
		"name": "David",
		"createdDate": "2017-02-23 02:22:27",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 138,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 779,
		"schedulePercent": 0.412396,
		"totalGuests": 18,
		"guestPercent": 0.364455,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Tingley Coliseum",
		"mostGuestCity": "Albuquerque",
		"mostGuestAmount": 18
	},
	{
		"id": "4b54c3b",
		"email": "contabilidad@manuelmedrano.com",
		"name": "CAMILA",
		"createdDate": "2022-08-16 02:01:07",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 26,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 218,
		"schedulePercent": 0.1617,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "46e1e96",
		"email": "sarah@mfmgt.com",
		"name": "Sarah",
		"createdDate": "2012-05-04 23:24:42",
		"totalOrganizations": 5,
		"totalTours": 28,
		"totalDays": 129,
		"totalCountries": 4,
		"totalStates": 19,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 878,
		"schedulePercent": 0.446696,
		"totalGuests": 225,
		"guestPercent": 0.566018,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "Music Hall Center for the Performing Arts",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 63
	},
	{
		"id": "d6972fc",
		"email": "its.boy.golden@gmail.com",
		"name": "Liam",
		"createdDate": "2022-04-05 01:49:04",
		"totalOrganizations": 2,
		"totalTours": 9,
		"totalDays": 342,
		"totalCountries": 14,
		"totalStates": 52,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1078,
		"schedulePercent": 0.506953,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "National Arts Centre",
		"mostGuestCity": "Ottawa",
		"mostGuestAmount": 5
	},
	{
		"id": "b0116e6",
		"email": "utilityontour@gmail.com",
		"name": "Jane",
		"createdDate": "2022-02-04 10:54:01",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 125,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 869,
		"schedulePercent": 0.44259,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d2cf479",
		"email": "leelee_edwards@hotmail.com",
		"name": "Lee",
		"createdDate": "2018-05-30 03:25:41",
		"totalOrganizations": 11,
		"totalTours": 14,
		"totalDays": 282,
		"totalCountries": 13,
		"totalStates": 44,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 1511,
		"schedulePercent": 0.62058,
		"totalGuests": 648,
		"guestPercent": 0.738843,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 100
	},
	{
		"id": "7856376",
		"email": "jennifersalemrftm@gmail.com",
		"name": "Jennifer",
		"createdDate": "2018-09-21 20:39:12",
		"totalOrganizations": 4,
		"totalTours": 24,
		"totalDays": 630,
		"totalCountries": 19,
		"totalStates": 80,
		"totalEvents": 322,
		"eventPercent": 0.927162,
		"totalScheduleItems": 3995,
		"schedulePercent": 0.923057,
		"totalGuests": 1516,
		"guestPercent": 0.896438,
		"mostGuestDate": "2023-02-04 00:00:00",
		"mostGuestName": "The Music Center @ Strathmore",
		"mostGuestCity": "North Bethesda",
		"mostGuestAmount": 68
	},
	{
		"id": "b9cc7ec",
		"email": "ericswanson@sojamusic.com",
		"name": "Eric",
		"createdDate": "2018-01-16 13:25:03",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 125,
		"totalCountries": 8,
		"totalStates": 26,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 784,
		"schedulePercent": 0.41425,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "429606d",
		"email": "dan@ineffablemusic.com",
		"name": "Dan",
		"createdDate": "2021-07-08 03:47:36",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 403,
		"totalCountries": 1,
		"totalStates": 37,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 1112,
		"schedulePercent": 0.514369,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Baltimore Soundstage",
		"mostGuestCity": "Baltimore",
		"mostGuestAmount": 10
	},
	{
		"id": "3d0e26c",
		"email": "tom@lastriderecords.com",
		"name": "Tom",
		"createdDate": "2019-09-18 00:59:16",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 30,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 170,
		"schedulePercent": 0.132698,
		"totalGuests": 42,
		"guestPercent": 0.40882,
		"mostGuestDate": "2023-10-10 00:00:00",
		"mostGuestName": "Bendigo Hotel",
		"mostGuestCity": "Collingwood",
		"mostGuestAmount": 14
	},
	{
		"id": "b7f1270",
		"email": "rudy@mnjr.co",
		"name": "Rudy",
		"createdDate": "2022-02-28 18:59:01",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 1095,
		"totalCountries": 9,
		"totalStates": 53,
		"totalEvents": 173,
		"eventPercent": 0.757516,
		"totalScheduleItems": 1021,
		"schedulePercent": 0.491193,
		"totalGuests": 505,
		"guestPercent": 0.693815,
		"mostGuestDate": "2023-12-09 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 36
	},
	{
		"id": "a45f5ab",
		"email": "tom@limebearprojects.com",
		"name": "Tom",
		"createdDate": "2022-05-31 23:41:20",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 29,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 227,
		"schedulePercent": 0.168057,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "00b779c",
		"email": "tcoletaylor@gmail.com",
		"name": "Cole",
		"createdDate": "2014-04-02 20:42:50",
		"totalOrganizations": 7,
		"totalTours": 16,
		"totalDays": 860,
		"totalCountries": 9,
		"totalStates": 58,
		"totalEvents": 192,
		"eventPercent": 0.791683,
		"totalScheduleItems": 2662,
		"schedulePercent": 0.815256,
		"totalGuests": 122,
		"guestPercent": 0.492253,
		"mostGuestDate": "2023-04-07 00:00:00",
		"mostGuestName": "The Windjammer",
		"mostGuestCity": "Isle of Palms",
		"mostGuestAmount": 36
	},
	{
		"id": "e8a983f",
		"email": "duvall.patrick99@gmail.com",
		"name": "Patrick",
		"createdDate": "2019-07-16 13:45:18",
		"totalOrganizations": 7,
		"totalTours": 19,
		"totalDays": 449,
		"totalCountries": 11,
		"totalStates": 51,
		"totalEvents": 179,
		"eventPercent": 0.769037,
		"totalScheduleItems": 2397,
		"schedulePercent": 0.780294,
		"totalGuests": 623,
		"guestPercent": 0.732353,
		"mostGuestDate": "2023-06-19 00:00:00",
		"mostGuestName": "Moore Theatre",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 133
	},
	{
		"id": "ff3c06c",
		"email": "mturnbullmusic@gmail.com",
		"name": "Michael",
		"createdDate": "2022-10-25 13:31:08",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 655,
		"totalCountries": 7,
		"totalStates": 14,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 1206,
		"schedulePercent": 0.541518,
		"totalGuests": 886,
		"guestPercent": 0.795789,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "London Palladium",
		"mostGuestCity": "London",
		"mostGuestAmount": 132
	},
	{
		"id": "214acfd",
		"email": "kevinpuig@gmail.com",
		"name": "Kevin",
		"createdDate": "2014-06-03 19:39:21",
		"totalOrganizations": 13,
		"totalTours": 9,
		"totalDays": 376,
		"totalCountries": 11,
		"totalStates": 50,
		"totalEvents": 177,
		"eventPercent": 0.765594,
		"totalScheduleItems": 2583,
		"schedulePercent": 0.803337,
		"totalGuests": 1070,
		"guestPercent": 0.834724,
		"mostGuestDate": "2023-11-22 00:00:00",
		"mostGuestName": "Toyota Arena",
		"mostGuestCity": "Ontario",
		"mostGuestAmount": 102
	},
	{
		"id": "bbc13be",
		"email": "julia.carlsson@livenation.se",
		"name": "Julia",
		"createdDate": "2020-01-30 14:58:12",
		"totalOrganizations": 49,
		"totalTours": 55,
		"totalDays": 1035,
		"totalCountries": 8,
		"totalStates": 41,
		"totalEvents": 750,
		"eventPercent": 0.987154,
		"totalScheduleItems": 5065,
		"schedulePercent": 0.963713,
		"totalGuests": 349,
		"guestPercent": 0.629453,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "Sofiero GÃ¥rd",
		"mostGuestCity": "Helsingborg",
		"mostGuestAmount": 59
	},
	{
		"id": "65cb440",
		"email": "alex@i2ientertainment.com",
		"name": "Alex",
		"createdDate": "2012-02-24 05:32:30",
		"totalOrganizations": 11,
		"totalTours": 9,
		"totalDays": 1094,
		"totalCountries": 13,
		"totalStates": 56,
		"totalEvents": 252,
		"eventPercent": 0.876308,
		"totalScheduleItems": 3195,
		"schedulePercent": 0.869289,
		"totalGuests": 2179,
		"guestPercent": 0.942657,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Forest Hills Stadium",
		"mostGuestCity": "Forest Hills",
		"mostGuestAmount": 191
	},
	{
		"id": "6c70f3e",
		"email": "mreillyproduction@gmail.com",
		"name": "Morgan",
		"createdDate": "2023-05-18 22:54:13",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 125,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 219,
		"schedulePercent": 0.16276,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-06-02 00:00:00",
		"mostGuestName": "Brickie Bowl",
		"mostGuestCity": "Hobart",
		"mostGuestAmount": 4
	},
	{
		"id": "1b03375",
		"email": "mrintza@gmail.com",
		"name": "Martin",
		"createdDate": "2014-12-09 14:32:02",
		"totalOrganizations": 71,
		"totalTours": 38,
		"totalDays": 656,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 585,
		"eventPercent": 0.981989,
		"totalScheduleItems": 2945,
		"schedulePercent": 0.846643,
		"totalGuests": 337,
		"guestPercent": 0.621375,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "Fredagsrock i Tivoli",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 50
	},
	{
		"id": "5194f17",
		"email": "doe@doejonesmusic.com",
		"name": "Dominique",
		"createdDate": "2021-09-24 15:19:45",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 67,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 601,
		"schedulePercent": 0.341544,
		"totalGuests": 357,
		"guestPercent": 0.633558,
		"mostGuestDate": "2023-04-22 00:00:00",
		"mostGuestName": "Grace Church Houston",
		"mostGuestCity": "Houston",
		"mostGuestAmount": 70
	},
	{
		"id": "eaa731a",
		"email": "meji@rimasmusic.com",
		"name": "Angel",
		"createdDate": "2021-12-29 00:44:07",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 385,
		"totalCountries": 3,
		"totalStates": 15,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 626,
		"schedulePercent": 0.352404,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9daed39",
		"email": "TFinley266@aol.com",
		"name": "Terry",
		"createdDate": "2012-03-19 20:50:32",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 153,
		"totalCountries": 4,
		"totalStates": 30,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 997,
		"schedulePercent": 0.484042,
		"totalGuests": 227,
		"guestPercent": 0.567474,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Saban Theatre",
		"mostGuestCity": "Beverly Hills",
		"mostGuestAmount": 43
	},
	{
		"id": "5f7f252",
		"email": "jason@bandlsound.com",
		"name": "Jason",
		"createdDate": "2016-08-16 22:16:57",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 254,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1945,
		"schedulePercent": 0.710634,
		"totalGuests": 470,
		"guestPercent": 0.680969,
		"mostGuestDate": "2023-04-22 00:00:00",
		"mostGuestName": "Grace Church Houston",
		"mostGuestCity": "Houston",
		"mostGuestAmount": 70
	},
	{
		"id": "e5332ba",
		"email": "Jennifer.Koss@gmail.com",
		"name": "Jennifer",
		"createdDate": "2015-07-10 18:25:37",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 1013,
		"totalCountries": 11,
		"totalStates": 33,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 1653,
		"schedulePercent": 0.654086,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6110f60",
		"email": "adam@centralonline.tv",
		"name": "Adam ",
		"createdDate": "2018-04-25 06:07:39",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 76,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 819,
		"schedulePercent": 0.426169,
		"totalGuests": 562,
		"guestPercent": 0.714607,
		"mostGuestDate": "2023-03-19 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 84
	},
	{
		"id": "89026bd",
		"email": "lexi@vossevents.com",
		"name": "Lexi",
		"createdDate": "2022-06-20 22:44:40",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 266,
		"totalCountries": 15,
		"totalStates": 68,
		"totalEvents": 148,
		"eventPercent": 0.696332,
		"totalScheduleItems": 1668,
		"schedulePercent": 0.657131,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cb01d9a",
		"email": "marksydow@gmail.com",
		"name": "Mark",
		"createdDate": "2020-11-19 04:22:36",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 121,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 645,
		"schedulePercent": 0.360482,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "Missing Gorilla",
		"mostGuestCity": "Eltham",
		"mostGuestAmount": 2
	},
	{
		"id": "5d7aa7c",
		"email": "fabian@chasmgmt.com",
		"name": "Fabian",
		"createdDate": "2015-07-24 00:42:27",
		"totalOrganizations": 15,
		"totalTours": 18,
		"totalDays": 790,
		"totalCountries": 9,
		"totalStates": 48,
		"totalEvents": 228,
		"eventPercent": 0.845848,
		"totalScheduleItems": 1660,
		"schedulePercent": 0.655675,
		"totalGuests": 481,
		"guestPercent": 0.683751,
		"mostGuestDate": "2023-10-24 00:00:00",
		"mostGuestName": "Emo's",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 51
	},
	{
		"id": "f37ba66",
		"email": "mush@mushlx.com",
		"name": "Michael",
		"createdDate": "2018-12-16 13:35:39",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 86,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 367,
		"schedulePercent": 0.241557,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9b3e3a3",
		"email": "Tm@nikkilane.com",
		"name": "NL",
		"createdDate": "2017-05-13 20:03:11",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 303,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 388,
		"schedulePercent": 0.252152,
		"totalGuests": 67,
		"guestPercent": 0.443253,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Dos Equis Pavilion",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 13
	},
	{
		"id": "684b6cf",
		"email": "ingrid@standingovation.no",
		"name": "Ingrid",
		"createdDate": "2015-10-27 11:31:15",
		"totalOrganizations": 40,
		"totalTours": 24,
		"totalDays": 966,
		"totalCountries": 16,
		"totalStates": 45,
		"totalEvents": 408,
		"eventPercent": 0.959078,
		"totalScheduleItems": 3595,
		"schedulePercent": 0.899616,
		"totalGuests": 275,
		"guestPercent": 0.59171,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "Pepsi center",
		"mostGuestCity": "Ciudad de MÃ©xico",
		"mostGuestAmount": 57
	},
	{
		"id": "cbf9385",
		"email": "jenkinsc@mac.com",
		"name": "Curtis",
		"createdDate": "2010-04-10 03:13:16",
		"totalOrganizations": 6,
		"totalTours": 1,
		"totalDays": 369,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 1149,
		"schedulePercent": 0.526818,
		"totalGuests": 465,
		"guestPercent": 0.678718,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "Zappos Theater",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 40
	},
	{
		"id": "0cbee9d",
		"email": "drptouring@gmail.com",
		"name": "David",
		"createdDate": "2023-05-20 16:22:17",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 72,
		"totalCountries": 11,
		"totalStates": 25,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 593,
		"schedulePercent": 0.337306,
		"totalGuests": 547,
		"guestPercent": 0.708648,
		"mostGuestDate": "2023-11-26 00:00:00",
		"mostGuestName": "EartH (Evolutionary Arts Hackney)",
		"mostGuestCity": "London",
		"mostGuestAmount": 97
	},
	{
		"id": "925dabd",
		"email": "rmesh@mac.com",
		"name": "Ron",
		"createdDate": "2009-07-24 15:30:19",
		"totalOrganizations": 13,
		"totalTours": 4,
		"totalDays": 686,
		"totalCountries": 14,
		"totalStates": 57,
		"totalEvents": 179,
		"eventPercent": 0.769037,
		"totalScheduleItems": 2164,
		"schedulePercent": 0.744802,
		"totalGuests": 1111,
		"guestPercent": 0.841081,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "Mountain Winery",
		"mostGuestCity": "Saratoga",
		"mostGuestAmount": 31
	},
	{
		"id": "1452029",
		"email": "staddondrums@gmail.com",
		"name": "Daniel",
		"createdDate": "2023-09-30 13:31:23",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 46,
		"totalCountries": 1,
		"totalStates": 24,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 198,
		"schedulePercent": 0.148987,
		"totalGuests": 53,
		"guestPercent": 0.426434,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 37
	},
	{
		"id": "c3fb4ac",
		"email": "eddie@triple8mgmt.com",
		"name": "Eric",
		"createdDate": "2013-08-01 23:41:02",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 465,
		"totalCountries": 16,
		"totalStates": 52,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 1873,
		"schedulePercent": 0.700437,
		"totalGuests": 510,
		"guestPercent": 0.695272,
		"mostGuestDate": "2023-05-18 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 60
	},
	{
		"id": "1630218",
		"email": "jcherry@foundationsmgmt.com",
		"name": "Jeff",
		"createdDate": "2023-09-27 15:22:19",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 55,
		"totalCountries": 1,
		"totalStates": 23,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 680,
		"schedulePercent": 0.372533,
		"totalGuests": 1150,
		"guestPercent": 0.849292,
		"mostGuestDate": "2023-11-15 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 142
	},
	{
		"id": "6720c0d",
		"email": "simon@shiftmanagement.co.uk",
		"name": "Simon",
		"createdDate": "2010-06-01 11:21:02",
		"totalOrganizations": 11,
		"totalTours": 1,
		"totalDays": 153,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 9,
		"eventPercent": 0.0607866,
		"totalScheduleItems": 192,
		"schedulePercent": 0.145676,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "258c919",
		"email": "lindsaylovesemail@gmail.com",
		"name": "Lindsay",
		"createdDate": "2016-07-14 03:14:15",
		"totalOrganizations": 6,
		"totalTours": 1,
		"totalDays": 283,
		"totalCountries": 5,
		"totalStates": 28,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 3021,
		"schedulePercent": 0.852867,
		"totalGuests": 4718,
		"guestPercent": 0.989803,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 240
	},
	{
		"id": "9aa617f",
		"email": "tickets@roundroompresents.com",
		"name": "Neal",
		"createdDate": "2019-10-20 02:15:16",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 713,
		"totalCountries": 5,
		"totalStates": 53,
		"totalEvents": 397,
		"eventPercent": 0.956562,
		"totalScheduleItems": 4568,
		"schedulePercent": 0.947821,
		"totalGuests": 1128,
		"guestPercent": 0.844259,
		"mostGuestDate": "2023-12-06 00:00:00",
		"mostGuestName": "Terrace Theatre",
		"mostGuestCity": "Long Beach",
		"mostGuestAmount": 73
	},
	{
		"id": "c773913",
		"email": "robert.lepper@mawi-concert.de",
		"name": "Robert",
		"createdDate": "2017-03-22 12:16:25",
		"totalOrganizations": 7,
		"totalTours": 1,
		"totalDays": 25,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 201,
		"schedulePercent": 0.151106,
		"totalGuests": 209,
		"guestPercent": 0.557277,
		"mostGuestDate": "2023-04-08 00:00:00",
		"mostGuestName": "Palladium",
		"mostGuestCity": "Cologne",
		"mostGuestAmount": 56
	},
	{
		"id": "c3462ad",
		"email": "b.stock@mac.com",
		"name": "Barry",
		"createdDate": "2017-02-22 19:48:54",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 152,
		"totalCountries": 9,
		"totalStates": 43,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 1134,
		"schedulePercent": 0.521255,
		"totalGuests": 81,
		"guestPercent": 0.455966,
		"mostGuestDate": "2023-04-29 00:00:00",
		"mostGuestName": "Fiserv Forum",
		"mostGuestCity": "Milwaukee",
		"mostGuestAmount": 21
	},
	{
		"id": "78cc5ea",
		"email": "andrew.leib@redlightmanagement.com",
		"name": "Andrew",
		"createdDate": "2014-09-04 17:41:47",
		"totalOrganizations": 4,
		"totalTours": 14,
		"totalDays": 292,
		"totalCountries": 12,
		"totalStates": 28,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 1117,
		"schedulePercent": 0.515296,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "Le 106",
		"mostGuestCity": "Rouen",
		"mostGuestAmount": 4
	},
	{
		"id": "17a5bbb",
		"email": "lemnycapel@gmail.com",
		"name": "Lemny",
		"createdDate": "2019-09-05 14:53:05",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 290,
		"totalCountries": 3,
		"totalStates": 17,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 971,
		"schedulePercent": 0.47252,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "596eaea",
		"email": "mattschmitz1@gmail.com",
		"name": "Matthew",
		"createdDate": "2014-10-27 00:55:16",
		"totalOrganizations": 27,
		"totalTours": 13,
		"totalDays": 1217,
		"totalCountries": 12,
		"totalStates": 39,
		"totalEvents": 159,
		"eventPercent": 0.726394,
		"totalScheduleItems": 2632,
		"schedulePercent": 0.81221,
		"totalGuests": 1179,
		"guestPercent": 0.853927,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "Palacio de los Deportes",
		"mostGuestCity": "Ciudad de MÃ©xico",
		"mostGuestAmount": 108
	},
	{
		"id": "c0a1e1e",
		"email": "BRIANGRIFFINTM@GMAIL.COM",
		"name": "Brian",
		"createdDate": "2022-07-27 00:14:53",
		"totalOrganizations": 2,
		"totalTours": 7,
		"totalDays": 179,
		"totalCountries": 8,
		"totalStates": 33,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 1312,
		"schedulePercent": 0.569064,
		"totalGuests": 1118,
		"guestPercent": 0.842273,
		"mostGuestDate": "2023-03-21 00:00:00",
		"mostGuestName": "The SSE Arena, Wembley",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 73
	},
	{
		"id": "2ac55b2",
		"email": "georgie@onlyhelix.com",
		"name": "Georgie",
		"createdDate": "2019-06-13 13:14:55",
		"totalOrganizations": 8,
		"totalTours": 15,
		"totalDays": 540,
		"totalCountries": 17,
		"totalStates": 29,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 1170,
		"schedulePercent": 0.531983,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-01-20 00:00:00",
		"mostGuestName": "Tokyo Garden Theater",
		"mostGuestCity": "",
		"mostGuestAmount": 4
	},
	{
		"id": "411cd38",
		"email": "anerae@blocstarevolution.com",
		"name": "X",
		"createdDate": "2023-06-01 06:26:13",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 56,
		"totalCountries": 1,
		"totalStates": 15,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 14,
		"schedulePercent": 0.026619,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bcb712c",
		"email": "anna.busch@allartists.agency",
		"name": "Anna",
		"createdDate": "2022-05-09 07:30:24",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 89,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 981,
		"schedulePercent": 0.475831,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1cd7fdf",
		"email": "Seth_daniel@me.com",
		"name": "Seth",
		"createdDate": "2017-01-04 22:44:28",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 823,
		"totalCountries": 7,
		"totalStates": 44,
		"totalEvents": 186,
		"eventPercent": 0.782016,
		"totalScheduleItems": 2313,
		"schedulePercent": 0.767183,
		"totalGuests": 969,
		"guestPercent": 0.817243,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 58
	},
	{
		"id": "f460804",
		"email": "gabeb_94@yahoo.com",
		"name": "Gabe",
		"createdDate": "2019-04-16 05:04:16",
		"totalOrganizations": 10,
		"totalTours": 14,
		"totalDays": 476,
		"totalCountries": 14,
		"totalStates": 60,
		"totalEvents": 241,
		"eventPercent": 0.862667,
		"totalScheduleItems": 3267,
		"schedulePercent": 0.875646,
		"totalGuests": 1866,
		"guestPercent": 0.925838,
		"mostGuestDate": "2023-08-04 00:00:00",
		"mostGuestName": "Sunset Cove Amphitheater",
		"mostGuestCity": "Boca Raton",
		"mostGuestAmount": 132
	},
	{
		"id": "7781b78",
		"email": "ariel111194@yahoo.com",
		"name": "Ariel",
		"createdDate": "2021-10-03 18:24:01",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 125,
		"totalCountries": 2,
		"totalStates": 35,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 1553,
		"schedulePercent": 0.630645,
		"totalGuests": 495,
		"guestPercent": 0.689975,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "KettleHouse Amphitheater",
		"mostGuestCity": "Bonner-West Riverside",
		"mostGuestAmount": 25
	},
	{
		"id": "20900b9",
		"email": "stubicon@gmail.com",
		"name": "Stuart",
		"createdDate": "2019-01-02 18:20:59",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 45,
		"totalCountries": 3,
		"totalStates": 12,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 530,
		"schedulePercent": 0.312939,
		"totalGuests": 646,
		"guestPercent": 0.737916,
		"mostGuestDate": "2023-11-05 00:00:00",
		"mostGuestName": "The Concert Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 112
	},
	{
		"id": "ca982b6",
		"email": "therigger@mac.com",
		"name": "Mike",
		"createdDate": "2019-01-17 10:56:25",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 319,
		"totalCountries": 2,
		"totalStates": 40,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 2956,
		"schedulePercent": 0.847835,
		"totalGuests": 1043,
		"guestPercent": 0.831545,
		"mostGuestDate": "2023-06-28 00:00:00",
		"mostGuestName": "Rogers Arena",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 178
	},
	{
		"id": "0ab0c1c",
		"email": "sim@simgeuncular.co.uk",
		"name": "Simge",
		"createdDate": "2023-01-24 17:54:11",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 37,
		"totalCountries": 4,
		"totalStates": 5,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 245,
		"schedulePercent": 0.179049,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "881efeb",
		"email": "anthony@lessermatters.com",
		"name": "Anthony",
		"createdDate": "2022-07-25 14:44:47",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 32,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 242,
		"schedulePercent": 0.177195,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-04-24 00:00:00",
		"mostGuestName": "Crescent Ballroom",
		"mostGuestCity": "Phoenix",
		"mostGuestAmount": 3
	},
	{
		"id": "4baa6cf",
		"email": "thevenomouspinks@gmail.com",
		"name": "Venomous",
		"createdDate": "2023-04-06 21:46:53",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 38,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 189,
		"schedulePercent": 0.144219,
		"totalGuests": 220,
		"guestPercent": 0.563237,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 50
	},
	{
		"id": "b48bddc",
		"email": "louiemichaelsound@gmail.com",
		"name": "Louie",
		"createdDate": "2018-09-24 23:01:00",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 490,
		"totalCountries": 16,
		"totalStates": 49,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 1176,
		"schedulePercent": 0.533439,
		"totalGuests": 1220,
		"guestPercent": 0.860681,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 243
	},
	{
		"id": "93e7735",
		"email": "sara.contavalli@gmail.com",
		"name": "Sara",
		"createdDate": "2015-04-13 14:23:11",
		"totalOrganizations": 12,
		"totalTours": 4,
		"totalDays": 227,
		"totalCountries": 1,
		"totalStates": 48,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 1063,
		"schedulePercent": 0.501788,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fb5eb01",
		"email": "TanishaLM@hotmail.com",
		"name": "Tanisha",
		"createdDate": "2017-09-29 00:37:28",
		"totalOrganizations": 18,
		"totalTours": 14,
		"totalDays": 573,
		"totalCountries": 8,
		"totalStates": 59,
		"totalEvents": 324,
		"eventPercent": 0.927692,
		"totalScheduleItems": 4106,
		"schedulePercent": 0.927824,
		"totalGuests": 4413,
		"guestPercent": 0.987419,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 121
	},
	{
		"id": "d528291",
		"email": "j.granados@az-corp.co",
		"name": "Josh",
		"createdDate": "2022-06-21 12:24:33",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 343,
		"totalCountries": 7,
		"totalStates": 38,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 2495,
		"schedulePercent": 0.792743,
		"totalGuests": 1687,
		"guestPercent": 0.911402,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 104
	},
	{
		"id": "326a4f1",
		"email": "ivan.reedent@gmail.com",
		"name": "Lui",
		"createdDate": "2022-06-25 18:00:07",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 83,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 1134,
		"schedulePercent": 0.521255,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c8699c8",
		"email": "abigaillabianca@gmail.com",
		"name": "Abby",
		"createdDate": "2017-10-27 20:00:06",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 442,
		"totalCountries": 6,
		"totalStates": 49,
		"totalEvents": 230,
		"eventPercent": 0.849159,
		"totalScheduleItems": 2791,
		"schedulePercent": 0.831148,
		"totalGuests": 659,
		"guestPercent": 0.741624,
		"mostGuestDate": "2023-12-23 00:00:00",
		"mostGuestName": "Fisher Center for the Performing Arts",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 59
	},
	{
		"id": "3ca6653",
		"email": "stewchown@gmail.com",
		"name": "Stewart",
		"createdDate": "2015-05-04 17:21:24",
		"totalOrganizations": 15,
		"totalTours": 30,
		"totalDays": 439,
		"totalCountries": 14,
		"totalStates": 66,
		"totalEvents": 257,
		"eventPercent": 0.882929,
		"totalScheduleItems": 3336,
		"schedulePercent": 0.88187,
		"totalGuests": 1224,
		"guestPercent": 0.862137,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 90
	},
	{
		"id": "d868746",
		"email": "windjack@birthdaycakemedia.com",
		"name": "Rebecca",
		"createdDate": "2022-04-06 16:43:53",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 151,
		"totalCountries": 8,
		"totalStates": 27,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 697,
		"schedulePercent": 0.379552,
		"totalGuests": 363,
		"guestPercent": 0.637134,
		"mostGuestDate": "2023-05-23 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 44
	},
	{
		"id": "7ee3aa7",
		"email": "oliver@scarimmanagement.com",
		"name": "Oliver",
		"createdDate": "2019-04-27 02:35:53",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 30,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 247,
		"schedulePercent": 0.180373,
		"totalGuests": 123,
		"guestPercent": 0.492782,
		"mostGuestDate": "2023-03-08 00:00:00",
		"mostGuestName": "Gramercy Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 22
	},
	{
		"id": "b57a4c8",
		"email": "cspearsld@me.com",
		"name": "Christopher",
		"createdDate": "2022-08-15 16:50:29",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 402,
		"totalCountries": 2,
		"totalStates": 31,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1324,
		"schedulePercent": 0.572904,
		"totalGuests": 103,
		"guestPercent": 0.478082,
		"mostGuestDate": "2023-04-24 00:00:00",
		"mostGuestName": "Franklin Theatre",
		"mostGuestCity": "Franklin",
		"mostGuestAmount": 32
	},
	{
		"id": "d7a7842",
		"email": "kevin@breitgroup.com",
		"name": "Kevin",
		"createdDate": "2021-06-10 04:05:47",
		"totalOrganizations": 7,
		"totalTours": 12,
		"totalDays": 356,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 205,
		"eventPercent": 0.814859,
		"totalScheduleItems": 4084,
		"schedulePercent": 0.926897,
		"totalGuests": 1841,
		"guestPercent": 0.924646,
		"mostGuestDate": "2023-05-02 00:00:00",
		"mostGuestName": "The Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 99
	},
	{
		"id": "44a21b7",
		"email": "julienlg1@hotmail.com",
		"name": "Julien",
		"createdDate": "2019-10-17 14:22:47",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 512,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 1278,
		"schedulePercent": 0.559529,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "71ef6fa",
		"email": "millersari@mac.com",
		"name": "Sari",
		"createdDate": "2012-11-24 03:32:28",
		"totalOrganizations": 7,
		"totalTours": 14,
		"totalDays": 623,
		"totalCountries": 11,
		"totalStates": 43,
		"totalEvents": 186,
		"eventPercent": 0.782016,
		"totalScheduleItems": 2706,
		"schedulePercent": 0.819229,
		"totalGuests": 395,
		"guestPercent": 0.651569,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 207
	},
	{
		"id": "eed6880",
		"email": "JPTPRO@OUTLOOK.COM",
		"name": "Jose Luis",
		"createdDate": "2021-11-27 06:11:16",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 414,
		"totalCountries": 4,
		"totalStates": 22,
		"totalEvents": 206,
		"eventPercent": 0.816183,
		"totalScheduleItems": 2088,
		"schedulePercent": 0.733148,
		"totalGuests": 196,
		"guestPercent": 0.546947,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "Madison Square Garden",
		"mostGuestCity": "New York",
		"mostGuestAmount": 22
	},
	{
		"id": "cc34f7a",
		"email": "Jody.Mazer@wmg.com",
		"name": "Jody",
		"createdDate": "2017-06-01 20:14:40",
		"totalOrganizations": 58,
		"totalTours": 56,
		"totalDays": 16906,
		"totalCountries": 16,
		"totalStates": 95,
		"totalEvents": 2573,
		"eventPercent": 0.999338,
		"totalScheduleItems": 9921,
		"schedulePercent": 0.996954,
		"totalGuests": 3164,
		"guestPercent": 0.972454,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Paycom Center",
		"mostGuestCity": "Oklahoma City",
		"mostGuestAmount": 167
	},
	{
		"id": "cd16884",
		"email": "aaron@thebeehive.la",
		"name": "Aaron",
		"createdDate": "2018-04-27 19:51:14",
		"totalOrganizations": 37,
		"totalTours": 43,
		"totalDays": 1057,
		"totalCountries": 20,
		"totalStates": 69,
		"totalEvents": 422,
		"eventPercent": 0.963051,
		"totalScheduleItems": 5052,
		"schedulePercent": 0.963316,
		"totalGuests": 2503,
		"guestPercent": 0.954178,
		"mostGuestDate": "2023-06-28 00:00:00",
		"mostGuestName": "The Studio at Webster Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 187
	},
	{
		"id": "ff7903a",
		"email": "lsiegel@cityparksfoundation.org",
		"name": "Larry ",
		"createdDate": "2018-03-12 23:05:45",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 325,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 1684,
		"schedulePercent": 0.66084,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b0a4ad5",
		"email": "Brianenvy@gmail.com",
		"name": "Brian ",
		"createdDate": "2021-08-18 03:08:01",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 298,
		"totalCountries": 6,
		"totalStates": 27,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 994,
		"schedulePercent": 0.482585,
		"totalGuests": 115,
		"guestPercent": 0.48722,
		"mostGuestDate": "2023-07-17 00:00:00",
		"mostGuestName": "Moody Center",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 30
	},
	{
		"id": "6412035",
		"email": "saintmotelmgmt@gmail.com",
		"name": "Saint",
		"createdDate": "2023-07-17 19:00:16",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 71,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 251,
		"schedulePercent": 0.18183,
		"totalGuests": 203,
		"guestPercent": 0.553172,
		"mostGuestDate": "2023-09-14 00:00:00",
		"mostGuestName": "Emo's",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 41
	},
	{
		"id": "6576040",
		"email": "P.gornell@gmail.com",
		"name": "Phil",
		"createdDate": "2018-04-09 22:37:48",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 783,
		"totalCountries": 20,
		"totalStates": 72,
		"totalEvents": 228,
		"eventPercent": 0.845848,
		"totalScheduleItems": 4713,
		"schedulePercent": 0.952324,
		"totalGuests": 129,
		"guestPercent": 0.497815,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 112
	},
	{
		"id": "8f5335e",
		"email": "ashley@schroductions.com",
		"name": "Ashley",
		"createdDate": "2017-08-15 20:01:54",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 479,
		"totalCountries": 11,
		"totalStates": 29,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1420,
		"schedulePercent": 0.596477,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "The Metropolitan Nightclub",
		"mostGuestCity": "New Orleans",
		"mostGuestAmount": 5
	},
	{
		"id": "d2d5021",
		"email": "crew@benkweller.com",
		"name": "Rawk",
		"createdDate": "2023-06-20 05:45:22",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 217,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 737,
		"schedulePercent": 0.394385,
		"totalGuests": 453,
		"guestPercent": 0.67501,
		"mostGuestDate": "2023-11-19 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 88
	},
	{
		"id": "86f7b22",
		"email": "shanepeterb@outlook.com",
		"name": "Shane",
		"createdDate": "2017-03-27 01:31:24",
		"totalOrganizations": 4,
		"totalTours": 15,
		"totalDays": 73,
		"totalCountries": 4,
		"totalStates": 12,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 493,
		"schedulePercent": 0.294663,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f1ef242",
		"email": "alexhugmusic@gmail.com",
		"name": "Alexander",
		"createdDate": "2017-02-10 02:36:23",
		"totalOrganizations": 15,
		"totalTours": 26,
		"totalDays": 990,
		"totalCountries": 13,
		"totalStates": 62,
		"totalEvents": 401,
		"eventPercent": 0.957489,
		"totalScheduleItems": 4028,
		"schedulePercent": 0.924381,
		"totalGuests": 1389,
		"guestPercent": 0.881605,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "London Palladium",
		"mostGuestCity": "London",
		"mostGuestAmount": 53
	},
	{
		"id": "9c0550d",
		"email": "Natelampa1@gmail.com",
		"name": "Nate",
		"createdDate": "2014-04-18 16:28:46",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 653,
		"totalCountries": 4,
		"totalStates": 38,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 2015,
		"schedulePercent": 0.722421,
		"totalGuests": 2185,
		"guestPercent": 0.942789,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Gas South Arena",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 157
	},
	{
		"id": "4ddaec5",
		"email": "sunjeetjaspal@gmail.com",
		"name": "Sunny",
		"createdDate": "2017-04-27 20:42:12",
		"totalOrganizations": 12,
		"totalTours": 18,
		"totalDays": 518,
		"totalCountries": 16,
		"totalStates": 62,
		"totalEvents": 236,
		"eventPercent": 0.857237,
		"totalScheduleItems": 3632,
		"schedulePercent": 0.902794,
		"totalGuests": 475,
		"guestPercent": 0.682426,
		"mostGuestDate": "2023-10-03 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 49
	},
	{
		"id": "26eef97",
		"email": "tom@newvillagemgmt.com",
		"name": "Tom",
		"createdDate": "2022-02-15 16:05:19",
		"totalOrganizations": 11,
		"totalTours": 40,
		"totalDays": 2417,
		"totalCountries": 22,
		"totalStates": 93,
		"totalEvents": 449,
		"eventPercent": 0.967422,
		"totalScheduleItems": 3788,
		"schedulePercent": 0.91127,
		"totalGuests": 51,
		"guestPercent": 0.423785,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Jazz At Lincoln Center",
		"mostGuestCity": "New York",
		"mostGuestAmount": 18
	},
	{
		"id": "2a20e9f",
		"email": "kylie@alist.com.au",
		"name": "Kylie",
		"createdDate": "2022-08-17 04:34:59",
		"totalOrganizations": 5,
		"totalTours": 18,
		"totalDays": 156,
		"totalCountries": 6,
		"totalStates": 12,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 402,
		"schedulePercent": 0.257582,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f74e6ec",
		"email": "melanie@1worldent.com",
		"name": "Melanie",
		"createdDate": "2019-03-19 23:51:09",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 297,
		"totalCountries": 16,
		"totalStates": 48,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 746,
		"schedulePercent": 0.400212,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1a5aad7",
		"email": "Adamgroeninger@gmail.com",
		"name": "Adam",
		"createdDate": "2013-02-19 00:30:53",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 402,
		"totalCountries": 6,
		"totalStates": 39,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 2394,
		"schedulePercent": 0.779897,
		"totalGuests": 2208,
		"guestPercent": 0.944246,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Mattress Firm Amphitheatre",
		"mostGuestCity": "Chula Vista",
		"mostGuestAmount": 136
	},
	{
		"id": "2e0a961",
		"email": "info@highwayholidays.eu",
		"name": "Highway",
		"createdDate": "2018-07-24 09:42:08",
		"totalOrganizations": 28,
		"totalTours": 43,
		"totalDays": 1445,
		"totalCountries": 24,
		"totalStates": 117,
		"totalEvents": 614,
		"eventPercent": 0.983313,
		"totalScheduleItems": 5755,
		"schedulePercent": 0.977222,
		"totalGuests": 1938,
		"guestPercent": 0.931002,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Annexet",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 155
	},
	{
		"id": "6d2a0ea",
		"email": "LilYachtypro@gmail.com",
		"name": "Andrew",
		"createdDate": "2023-05-25 22:48:25",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 72,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 254,
		"schedulePercent": 0.182625,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8cdef9d",
		"email": "timothyrusselljr@gmail.com",
		"name": "Timothy",
		"createdDate": "2012-06-14 16:56:12",
		"totalOrganizations": 13,
		"totalTours": 20,
		"totalDays": 658,
		"totalCountries": 15,
		"totalStates": 63,
		"totalEvents": 240,
		"eventPercent": 0.86174,
		"totalScheduleItems": 2489,
		"schedulePercent": 0.792081,
		"totalGuests": 495,
		"guestPercent": 0.689975,
		"mostGuestDate": "2023-01-26 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 80
	},
	{
		"id": "33cbe9b",
		"email": "meangirlspsm@networkstours.com",
		"name": "Emily",
		"createdDate": "2023-08-11 15:38:12",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 114,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 811,
		"schedulePercent": 0.42352,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "aa573fa",
		"email": "chris.simeone@jurassicquest.com",
		"name": "Chris",
		"createdDate": "2021-04-17 01:12:20",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 1090,
		"totalCountries": 3,
		"totalStates": 48,
		"totalEvents": 587,
		"eventPercent": 0.982254,
		"totalScheduleItems": 6860,
		"schedulePercent": 0.987816,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9da34fa",
		"email": "kerry@spoiledrottentravel.com",
		"name": "Kerry",
		"createdDate": "2013-04-15 17:16:17",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 436,
		"totalCountries": 3,
		"totalStates": 12,
		"totalEvents": 3,
		"eventPercent": 0.0202622,
		"totalScheduleItems": 136,
		"schedulePercent": 0.110581,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3e0b0e5",
		"email": "Bigzacsound@gmail.com",
		"name": "Zac",
		"createdDate": "2018-11-14 13:33:01",
		"totalOrganizations": 6,
		"totalTours": 18,
		"totalDays": 597,
		"totalCountries": 14,
		"totalStates": 65,
		"totalEvents": 299,
		"eventPercent": 0.914978,
		"totalScheduleItems": 4779,
		"schedulePercent": 0.954708,
		"totalGuests": 2789,
		"guestPercent": 0.964111,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "Hard Rock Live",
		"mostGuestCity": "Orlando",
		"mostGuestAmount": 87
	},
	{
		"id": "a1b315e",
		"email": "kh@teambuddys.com",
		"name": "Kalle",
		"createdDate": "2022-04-23 07:16:05",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 37,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 235,
		"schedulePercent": 0.171898,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "673dd48",
		"email": "justinbasch@gmail.com",
		"name": "Justin",
		"createdDate": "2009-07-06 22:18:51",
		"totalOrganizations": 13,
		"totalTours": 8,
		"totalDays": 454,
		"totalCountries": 8,
		"totalStates": 33,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 2019,
		"schedulePercent": 0.723215,
		"totalGuests": 1901,
		"guestPercent": 0.928884,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 281
	},
	{
		"id": "10851cd",
		"email": "levievansmusic@gmail.com",
		"name": "Levi",
		"createdDate": "2023-07-20 17:14:02",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 250,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 589,
		"schedulePercent": 0.335055,
		"totalGuests": 532,
		"guestPercent": 0.703218,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "Palace Theatre",
		"mostGuestCity": "St Paul",
		"mostGuestAmount": 100
	},
	{
		"id": "1380c27",
		"email": "randallkingband@gmail.com",
		"name": "Randall",
		"createdDate": "2017-04-12 21:47:25",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 313,
		"totalCountries": 4,
		"totalStates": 35,
		"totalEvents": 199,
		"eventPercent": 0.804264,
		"totalScheduleItems": 2423,
		"schedulePercent": 0.782943,
		"totalGuests": 380,
		"guestPercent": 0.645477,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Giant Center",
		"mostGuestCity": "Hershey",
		"mostGuestAmount": 72
	},
	{
		"id": "c959c5b",
		"email": "bdennyfoh@gmail.com",
		"name": "Brian",
		"createdDate": "2017-01-02 15:39:10",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 671,
		"totalCountries": 13,
		"totalStates": 48,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 1293,
		"schedulePercent": 0.564429,
		"totalGuests": 260,
		"guestPercent": 0.584293,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Jubilee Auditorium",
		"mostGuestCity": "Calgary",
		"mostGuestAmount": 64
	},
	{
		"id": "4383538",
		"email": "mats@llt.nu",
		"name": "Mats",
		"createdDate": "2016-11-22 12:35:21",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 56,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 199,
		"schedulePercent": 0.149781,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2039843",
		"email": "mercenarymedia@gmail.com",
		"name": "Eric",
		"createdDate": "2019-06-15 05:32:25",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 400,
		"totalCountries": 2,
		"totalStates": 37,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 2532,
		"schedulePercent": 0.796583,
		"totalGuests": 1553,
		"guestPercent": 0.899351,
		"mostGuestDate": "2023-04-11 00:00:00",
		"mostGuestName": "Summit Music Hall",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 63
	},
	{
		"id": "f105d7f",
		"email": "taylor.watson93@sbcglobal.net",
		"name": "Taylor",
		"createdDate": "2018-06-27 22:53:03",
		"totalOrganizations": 24,
		"totalTours": 15,
		"totalDays": 2379,
		"totalCountries": 10,
		"totalStates": 71,
		"totalEvents": 666,
		"eventPercent": 0.985035,
		"totalScheduleItems": 10410,
		"schedulePercent": 0.997616,
		"totalGuests": 8725,
		"guestPercent": 0.999073,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 240
	},
	{
		"id": "e61449c",
		"email": "overdriveuk@icloud.com",
		"name": "Benjamin",
		"createdDate": "2022-01-29 11:07:23",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 196,
		"totalCountries": 9,
		"totalStates": 45,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1243,
		"schedulePercent": 0.550523,
		"totalGuests": 1022,
		"guestPercent": 0.828102,
		"mostGuestDate": "2023-07-09 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 76
	},
	{
		"id": "3c1e300",
		"email": "PAbreu@cityparksfoundation.org",
		"name": "Paula",
		"createdDate": "2012-03-27 17:20:40",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e65fba5",
		"email": "DROSINM@AOL.COM",
		"name": "Mitchell",
		"createdDate": "2021-01-15 22:05:54",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 211,
		"totalCountries": 1,
		"totalStates": 25,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 547,
		"schedulePercent": 0.31956,
		"totalGuests": 125,
		"guestPercent": 0.494636,
		"mostGuestDate": "2023-02-19 00:00:00",
		"mostGuestName": "Fred Kavli Theater",
		"mostGuestCity": "Thousand Oaks",
		"mostGuestAmount": 25
	},
	{
		"id": "7b479e8",
		"email": "lewis.bone@villainymusic.com",
		"name": "Lewis",
		"createdDate": "2022-10-04 00:43:01",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 60,
		"totalCountries": 5,
		"totalStates": 7,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 424,
		"schedulePercent": 0.265528,
		"totalGuests": 299,
		"guestPercent": 0.602039,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Alexandra Palace Theatre",
		"mostGuestCity": "London",
		"mostGuestAmount": 123
	},
	{
		"id": "86df3ef",
		"email": "dynamicrecordsinc1@gmail.com",
		"name": "Carlos",
		"createdDate": "2021-11-12 16:54:15",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 335,
		"totalCountries": 4,
		"totalStates": 14,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 199,
		"schedulePercent": 0.149781,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "252173e",
		"email": "benjamindupuis@yahoo.fr",
		"name": "Benjamin",
		"createdDate": "2021-11-09 11:00:06",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 306,
		"totalCountries": 7,
		"totalStates": 32,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 524,
		"schedulePercent": 0.309098,
		"totalGuests": 21,
		"guestPercent": 0.371342,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "Parc AndrÃ©-CitroÃ«n",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 21
	},
	{
		"id": "4c58cc4",
		"email": "soyconniefuentes@gmail.com",
		"name": "Connie ",
		"createdDate": "2019-02-20 04:10:18",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 476,
		"totalCountries": 8,
		"totalStates": 68,
		"totalEvents": 170,
		"eventPercent": 0.752086,
		"totalScheduleItems": 2018,
		"schedulePercent": 0.722951,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bbaa96b",
		"email": "kimburkart1@gmail.com",
		"name": "Kim",
		"createdDate": "2013-07-11 21:05:16",
		"totalOrganizations": 27,
		"totalTours": 23,
		"totalDays": 1958,
		"totalCountries": 19,
		"totalStates": 69,
		"totalEvents": 620,
		"eventPercent": 0.983711,
		"totalScheduleItems": 6298,
		"schedulePercent": 0.983446,
		"totalGuests": 1102,
		"guestPercent": 0.839756,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 81
	},
	{
		"id": "08a55ce",
		"email": "behn@infinity-staging.de",
		"name": "Ren&#233;",
		"createdDate": "2022-02-10 16:27:28",
		"totalOrganizations": 9,
		"totalTours": 13,
		"totalDays": 429,
		"totalCountries": 18,
		"totalStates": 69,
		"totalEvents": 198,
		"eventPercent": 0.802543,
		"totalScheduleItems": 3164,
		"schedulePercent": 0.866905,
		"totalGuests": 1980,
		"guestPercent": 0.932327,
		"mostGuestDate": "2023-07-23 00:00:00",
		"mostGuestName": "ParkbÃ¼hne Wuhlheide",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 305
	},
	{
		"id": "7766c11",
		"email": "Aaron.stechauner@gmail.com",
		"name": "Aaron ",
		"createdDate": "2018-01-04 18:44:50",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 163,
		"totalCountries": 8,
		"totalStates": 40,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 801,
		"schedulePercent": 0.420871,
		"totalGuests": 188,
		"guestPercent": 0.541518,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "Manchester Academy",
		"mostGuestCity": "Manchester",
		"mostGuestAmount": 55
	},
	{
		"id": "b4b55c3",
		"email": "lynden.gare@colourblind.com.au",
		"name": "Lynden",
		"createdDate": "2013-12-20 07:11:58",
		"totalOrganizations": 9,
		"totalTours": 18,
		"totalDays": 1017,
		"totalCountries": 14,
		"totalStates": 48,
		"totalEvents": 141,
		"eventPercent": 0.678321,
		"totalScheduleItems": 2046,
		"schedulePercent": 0.727321,
		"totalGuests": 900,
		"guestPercent": 0.799629,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 207
	},
	{
		"id": "cd675bc",
		"email": "lamafia@me.com",
		"name": "Armando",
		"createdDate": "2015-09-04 23:03:12",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 88,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 168,
		"schedulePercent": 0.131373,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4d42afa",
		"email": "eb.standard@gmail.com",
		"name": "Andrew",
		"createdDate": "2019-09-11 13:29:47",
		"totalOrganizations": 7,
		"totalTours": 20,
		"totalDays": 574,
		"totalCountries": 4,
		"totalStates": 28,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 1826,
		"schedulePercent": 0.690505,
		"totalGuests": 523,
		"guestPercent": 0.699245,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Tradex",
		"mostGuestCity": "Abbotsford",
		"mostGuestAmount": 44
	},
	{
		"id": "a73ece0",
		"email": "nosal.dylan@gmail.com",
		"name": "Dylan",
		"createdDate": "2021-11-04 00:56:01",
		"totalOrganizations": 7,
		"totalTours": 15,
		"totalDays": 445,
		"totalCountries": 11,
		"totalStates": 52,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 2709,
		"schedulePercent": 0.820554,
		"totalGuests": 400,
		"guestPercent": 0.653291,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "HISTORY",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 58
	},
	{
		"id": "55efa41",
		"email": "crowderproduction@gmail.com",
		"name": "Jay",
		"createdDate": "2013-07-30 19:07:39",
		"totalOrganizations": 17,
		"totalTours": 28,
		"totalDays": 1802,
		"totalCountries": 5,
		"totalStates": 51,
		"totalEvents": 498,
		"eventPercent": 0.974043,
		"totalScheduleItems": 7561,
		"schedulePercent": 0.991524,
		"totalGuests": 970,
		"guestPercent": 0.817375,
		"mostGuestDate": "2023-09-19 00:00:00",
		"mostGuestName": "Wooly's",
		"mostGuestCity": "Des Moines",
		"mostGuestAmount": 34
	},
	{
		"id": "47b7849",
		"email": "Tomandersonaudio@gmail.com",
		"name": "Tom",
		"createdDate": "2016-12-03 01:24:07",
		"totalOrganizations": 154,
		"totalTours": 61,
		"totalDays": 1389,
		"totalCountries": 24,
		"totalStates": 78,
		"totalEvents": 317,
		"eventPercent": 0.924381,
		"totalScheduleItems": 5922,
		"schedulePercent": 0.979208,
		"totalGuests": 731,
		"guestPercent": 0.759634,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 121
	},
	{
		"id": "8ee07b7",
		"email": "Adam.Scheirman@gmail.com",
		"name": "Adam",
		"createdDate": "2021-06-29 15:56:40",
		"totalOrganizations": 9,
		"totalTours": 18,
		"totalDays": 833,
		"totalCountries": 21,
		"totalStates": 70,
		"totalEvents": 328,
		"eventPercent": 0.929943,
		"totalScheduleItems": 5360,
		"schedulePercent": 0.970997,
		"totalGuests": 2685,
		"guestPercent": 0.959873,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "Northwell Health at Jones Beach Theater",
		"mostGuestCity": "Wantagh",
		"mostGuestAmount": 109
	},
	{
		"id": "56b718c",
		"email": "gt@swixxz.com",
		"name": "Gerald",
		"createdDate": "2023-02-01 21:31:39",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 43,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 200,
		"schedulePercent": 0.150444,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "16989bc",
		"email": "henri.saari@gmail.com",
		"name": "Henri",
		"createdDate": "2016-06-25 10:22:26",
		"totalOrganizations": 15,
		"totalTours": 18,
		"totalDays": 395,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 257,
		"eventPercent": 0.882929,
		"totalScheduleItems": 1343,
		"schedulePercent": 0.578599,
		"totalGuests": 604,
		"guestPercent": 0.727453,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Helsinki",
		"mostGuestCity": "",
		"mostGuestAmount": 162
	},
	{
		"id": "a5e74cf",
		"email": "allendukes06@gmail.com",
		"name": "Allen",
		"createdDate": "2012-08-08 20:44:12",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 545,
		"totalCountries": 9,
		"totalStates": 57,
		"totalEvents": 162,
		"eventPercent": 0.732883,
		"totalScheduleItems": 3102,
		"schedulePercent": 0.861608,
		"totalGuests": 3714,
		"guestPercent": 0.980135,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Bank of America Stadium",
		"mostGuestCity": "Charlotte",
		"mostGuestAmount": 243
	},
	{
		"id": "de3580a",
		"email": "Themovementvibe@gmail.com",
		"name": "THE",
		"createdDate": "2018-11-10 18:32:03",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 333,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 910,
		"schedulePercent": 0.454907,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ed1f301",
		"email": "lisamarieocallaghan@gmail.com",
		"name": "Lisa",
		"createdDate": "2020-12-30 14:13:09",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 40,
		"totalCountries": 3,
		"totalStates": 8,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 623,
		"schedulePercent": 0.350682,
		"totalGuests": 97,
		"guestPercent": 0.471461,
		"mostGuestDate": "2023-04-15 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 42
	},
	{
		"id": "06bbdce",
		"email": "connor@theforeverland.com",
		"name": "Connor",
		"createdDate": "2021-09-16 12:55:02",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 706,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 73,
		"schedulePercent": 0.0701894,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3ecd1c2",
		"email": "joelyes@icloud.com",
		"name": "Joe  ",
		"createdDate": "2019-07-30 20:58:58",
		"totalOrganizations": 10,
		"totalTours": 4,
		"totalDays": 328,
		"totalCountries": 4,
		"totalStates": 31,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 1102,
		"schedulePercent": 0.511853,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5767904",
		"email": "shomari7@gmail.com",
		"name": "Duane",
		"createdDate": "2021-08-01 16:41:27",
		"totalOrganizations": 3,
		"totalTours": 12,
		"totalDays": 195,
		"totalCountries": 12,
		"totalStates": 40,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 845,
		"schedulePercent": 0.435969,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "Cannifest",
		"mostGuestCity": "Eureka",
		"mostGuestAmount": 3
	},
	{
		"id": "5c63727",
		"email": "ralph@transparentproductions.com",
		"name": "Ralph",
		"createdDate": "2023-03-20 16:30:54",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 317,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 489,
		"schedulePercent": 0.292544,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "635e103",
		"email": "alex@qprime.com",
		"name": "Alex",
		"createdDate": "2019-05-09 17:10:23",
		"totalOrganizations": 5,
		"totalTours": 19,
		"totalDays": 430,
		"totalCountries": 13,
		"totalStates": 60,
		"totalEvents": 185,
		"eventPercent": 0.780029,
		"totalScheduleItems": 1549,
		"schedulePercent": 0.629586,
		"totalGuests": 1268,
		"guestPercent": 0.867832,
		"mostGuestDate": "2023-10-02 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 405
	},
	{
		"id": "376ae25",
		"email": "producer@sppt.asn.au",
		"name": "Megan",
		"createdDate": "2023-05-05 03:57:26",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 270,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 446,
		"schedulePercent": 0.27493,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "279a3b2",
		"email": "hello@liteup.co.uk",
		"name": "",
		"createdDate": "2022-01-12 16:23:50",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 54,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 472,
		"schedulePercent": 0.285658,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e83ec9c",
		"email": "monbeattie@gmail.com",
		"name": "Monica",
		"createdDate": "2015-08-24 17:19:46",
		"totalOrganizations": 2,
		"totalTours": 7,
		"totalDays": 197,
		"totalCountries": 3,
		"totalStates": 31,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 1755,
		"schedulePercent": 0.675407,
		"totalGuests": 587,
		"guestPercent": 0.721494,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 45
	},
	{
		"id": "368f281",
		"email": "jason@mammothmusicgroup.com",
		"name": "Jason",
		"createdDate": "2017-08-11 18:20:37",
		"totalOrganizations": 13,
		"totalTours": 11,
		"totalDays": 682,
		"totalCountries": 3,
		"totalStates": 40,
		"totalEvents": 156,
		"eventPercent": 0.718051,
		"totalScheduleItems": 2010,
		"schedulePercent": 0.721097,
		"totalGuests": 75,
		"guestPercent": 0.450007,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "The Caverns",
		"mostGuestCity": "Pelham",
		"mostGuestAmount": 26
	},
	{
		"id": "7d11b59",
		"email": "wildcatforever@me.com",
		"name": "Tim",
		"createdDate": "2014-09-15 19:34:13",
		"totalOrganizations": 198,
		"totalTours": 85,
		"totalDays": 2251,
		"totalCountries": 34,
		"totalStates": 115,
		"totalEvents": 615,
		"eventPercent": 0.983446,
		"totalScheduleItems": 9670,
		"schedulePercent": 0.996689,
		"totalGuests": 1752,
		"guestPercent": 0.917097,
		"mostGuestDate": "2023-04-06 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 106
	},
	{
		"id": "179bfea",
		"email": "Maggie.paul12@gmail.com",
		"name": "Maggie",
		"createdDate": "2015-11-06 12:07:32",
		"totalOrganizations": 12,
		"totalTours": 12,
		"totalDays": 621,
		"totalCountries": 8,
		"totalStates": 56,
		"totalEvents": 228,
		"eventPercent": 0.845848,
		"totalScheduleItems": 2900,
		"schedulePercent": 0.841875,
		"totalGuests": 1011,
		"guestPercent": 0.826645,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 68
	},
	{
		"id": "174d17d",
		"email": "emilysmith@bigloud.com",
		"name": "Emily ",
		"createdDate": "2022-01-08 20:13:51",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 477,
		"totalCountries": 3,
		"totalStates": 42,
		"totalEvents": 245,
		"eventPercent": 0.867435,
		"totalScheduleItems": 2635,
		"schedulePercent": 0.81274,
		"totalGuests": 1797,
		"guestPercent": 0.920275,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Nashville Municipal Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 92
	},
	{
		"id": "8a63ee4",
		"email": "ryanneffbandhappy@gmail.com",
		"name": "Ryan",
		"createdDate": "2019-10-28 16:36:07",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 37,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 350,
		"schedulePercent": 0.232949,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "afa01d9",
		"email": "JadeDimi92@gmail.com",
		"name": "Jade",
		"createdDate": "2023-10-28 18:49:10",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 20,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 157,
		"schedulePercent": 0.125281,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "277f254",
		"email": "carly@red-rosette.com",
		"name": "Carly ",
		"createdDate": "2019-01-04 12:46:00",
		"totalOrganizations": 3,
		"totalTours": 12,
		"totalDays": 388,
		"totalCountries": 8,
		"totalStates": 27,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 1839,
		"schedulePercent": 0.693551,
		"totalGuests": 56,
		"guestPercent": 0.429744,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "O2 Institute",
		"mostGuestCity": "Birmingham",
		"mostGuestAmount": 16
	},
	{
		"id": "08abade",
		"email": "sunvemanagement@gmail.com",
		"name": "Diana",
		"createdDate": "2023-09-12 20:03:47",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 38,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 342,
		"schedulePercent": 0.228976,
		"totalGuests": 43,
		"guestPercent": 0.410674,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Palladium Times Square",
		"mostGuestCity": "",
		"mostGuestAmount": 5
	},
	{
		"id": "12dcf56",
		"email": "o.horner1996@gmail.com",
		"name": "Ollie",
		"createdDate": "2017-06-03 10:29:26",
		"totalOrganizations": 17,
		"totalTours": 17,
		"totalDays": 341,
		"totalCountries": 12,
		"totalStates": 24,
		"totalEvents": 196,
		"eventPercent": 0.798835,
		"totalScheduleItems": 2733,
		"schedulePercent": 0.823864,
		"totalGuests": 885,
		"guestPercent": 0.795656,
		"mostGuestDate": "2023-03-20 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 86
	},
	{
		"id": "302a2d3",
		"email": "teejayhealey@me.com",
		"name": "Justin",
		"createdDate": "2013-03-11 12:56:01",
		"totalOrganizations": 40,
		"totalTours": 81,
		"totalDays": 2551,
		"totalCountries": 24,
		"totalStates": 109,
		"totalEvents": 698,
		"eventPercent": 0.986095,
		"totalScheduleItems": 8182,
		"schedulePercent": 0.994041,
		"totalGuests": 1096,
		"guestPercent": 0.839094,
		"mostGuestDate": "2023-03-07 00:00:00",
		"mostGuestName": "The Civic",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 76
	},
	{
		"id": "ad40f37",
		"email": "timothy.prendergast@lobbycall.com",
		"name": "Timothy",
		"createdDate": "2023-04-23 18:06:11",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 42,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 286,
		"schedulePercent": 0.201033,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2d2766a",
		"email": "jonwolfetourman@gmail.com",
		"name": "Jon ",
		"createdDate": "2014-02-12 17:41:49",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 206,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 794,
		"schedulePercent": 0.41809,
		"totalGuests": 339,
		"guestPercent": 0.622964,
		"mostGuestDate": "2023-01-20 00:00:00",
		"mostGuestName": "Billy Bob's",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 57
	},
	{
		"id": "cab6a34",
		"email": "fernando@fcbuch.com",
		"name": "FERNANDO ",
		"createdDate": "2022-07-05 02:55:12",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 288,
		"totalCountries": 7,
		"totalStates": 39,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 1420,
		"schedulePercent": 0.596477,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6bd0b35",
		"email": "travisdejongaudio@gmail.com",
		"name": "Travis",
		"createdDate": "2022-05-21 12:37:51",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 157,
		"totalCountries": 12,
		"totalStates": 19,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 412,
		"schedulePercent": 0.262217,
		"totalGuests": 90,
		"guestPercent": 0.463912,
		"mostGuestDate": "2023-10-12 00:00:00",
		"mostGuestName": "Palais Theatre",
		"mostGuestCity": "St Kilda",
		"mostGuestAmount": 57
	},
	{
		"id": "b141976",
		"email": "james@mminc.co.nz",
		"name": "James",
		"createdDate": "2017-02-23 23:08:02",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 71,
		"totalCountries": 3,
		"totalStates": 11,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 272,
		"schedulePercent": 0.193749,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "14a88d3",
		"email": "matrixv01@gmail.com",
		"name": "Brad",
		"createdDate": "2023-04-27 16:09:31",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 44,
		"totalCountries": 4,
		"totalStates": 26,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 284,
		"schedulePercent": 0.200503,
		"totalGuests": 27,
		"guestPercent": 0.38379,
		"mostGuestDate": "2023-05-04 00:00:00",
		"mostGuestName": "The Brooklyn Monarch",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 16
	},
	{
		"id": "72f15dd",
		"email": "paulisontour@gmail.com",
		"name": "Paul",
		"createdDate": "2012-07-25 00:07:39",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 83,
		"totalCountries": 6,
		"totalStates": 8,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 262,
		"schedulePercent": 0.186465,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1a493a8",
		"email": "emilie.corbo@gmail.com",
		"name": "Emilie",
		"createdDate": "2023-06-15 02:15:03",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 579,
		"totalCountries": 2,
		"totalStates": 33,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 773,
		"schedulePercent": 0.410012,
		"totalGuests": 201,
		"guestPercent": 0.55198,
		"mostGuestDate": "2023-11-27 00:00:00",
		"mostGuestName": "Jannus Live",
		"mostGuestCity": "St. Petersburg",
		"mostGuestAmount": 36
	},
	{
		"id": "6b8ffaf",
		"email": "sabrina@thebeehive.la",
		"name": "Sabrina",
		"createdDate": "2022-05-13 00:47:55",
		"totalOrganizations": 35,
		"totalTours": 79,
		"totalDays": 3281,
		"totalCountries": 30,
		"totalStates": 102,
		"totalEvents": 902,
		"eventPercent": 0.989141,
		"totalScheduleItems": 12625,
		"schedulePercent": 0.998676,
		"totalGuests": 4460,
		"guestPercent": 0.987816,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 300
	},
	{
		"id": "476b62a",
		"email": "john@pubchoir.com.au",
		"name": "John",
		"createdDate": "2022-10-04 03:35:47",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 357,
		"totalCountries": 3,
		"totalStates": 18,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 342,
		"schedulePercent": 0.228976,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3abeb53",
		"email": "raquel@merchgal.com",
		"name": "Raquel",
		"createdDate": "2017-02-12 22:35:54",
		"totalOrganizations": 9,
		"totalTours": 5,
		"totalDays": 201,
		"totalCountries": 8,
		"totalStates": 41,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 2248,
		"schedulePercent": 0.757648,
		"totalGuests": 124,
		"guestPercent": 0.493445,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 48
	},
	{
		"id": "4f8ca3d",
		"email": "mt@radiatemanagement.com",
		"name": "Tour",
		"createdDate": "2021-07-14 08:37:33",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 23,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 143,
		"schedulePercent": 0.115217,
		"totalGuests": 35,
		"guestPercent": 0.396901,
		"mostGuestDate": "2023-05-27 00:00:00",
		"mostGuestName": "O2 Academy Leicester",
		"mostGuestCity": "Leicester",
		"mostGuestAmount": 20
	},
	{
		"id": "4e75b64",
		"email": "laurakakelley@gmail.com",
		"name": "Laura",
		"createdDate": "2015-08-28 18:05:03",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 62,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 538,
		"schedulePercent": 0.316912,
		"totalGuests": 91,
		"guestPercent": 0.465236,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "Fox Theater",
		"mostGuestCity": "Oakland",
		"mostGuestAmount": 51
	},
	{
		"id": "119dd0f",
		"email": "supersonico@me.com",
		"name": "Andrea",
		"createdDate": "2023-09-21 04:12:00",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 91,
		"totalCountries": 1,
		"totalStates": 28,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 357,
		"schedulePercent": 0.236393,
		"totalGuests": 251,
		"guestPercent": 0.579393,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Teatro Alighieri",
		"mostGuestCity": "Ravenna",
		"mostGuestAmount": 36
	},
	{
		"id": "c1b622e",
		"email": "aduncan@elevationchurch.org",
		"name": "Alex",
		"createdDate": "2018-09-10 14:59:02",
		"totalOrganizations": 8,
		"totalTours": 24,
		"totalDays": 441,
		"totalCountries": 3,
		"totalStates": 31,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 2147,
		"schedulePercent": 0.741888,
		"totalGuests": 4157,
		"guestPercent": 0.9853,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "Moody Center",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 314
	},
	{
		"id": "c7cb314",
		"email": "karen@spientertainment.com",
		"name": "Karen ",
		"createdDate": "2015-03-18 23:00:10",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 370,
		"totalCountries": 7,
		"totalStates": 35,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 309,
		"schedulePercent": 0.212422,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9e2ce72",
		"email": "dusty.schultz80@gmail.com",
		"name": "Dustin",
		"createdDate": "2019-03-19 16:44:55",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 530,
		"totalCountries": 12,
		"totalStates": 53,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 3181,
		"schedulePercent": 0.868229,
		"totalGuests": 1619,
		"guestPercent": 0.905178,
		"mostGuestDate": "2023-07-26 00:00:00",
		"mostGuestName": "Santander Arena",
		"mostGuestCity": "Reading",
		"mostGuestAmount": 52
	},
	{
		"id": "8650f01",
		"email": "Rpensado@gmail.com",
		"name": "Rafael ",
		"createdDate": "2018-05-08 18:11:10",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 171,
		"totalCountries": 15,
		"totalStates": 47,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1832,
		"schedulePercent": 0.692094,
		"totalGuests": 979,
		"guestPercent": 0.819229,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Abbotsford Centre",
		"mostGuestCity": "Abbotsford",
		"mostGuestAmount": 66
	},
	{
		"id": "fef0f96",
		"email": "steve@cvamgt.com",
		"name": "Steve",
		"createdDate": "2015-09-17 19:28:06",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 135,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 215,
		"schedulePercent": 0.159846,
		"totalGuests": 10,
		"guestPercent": 0.343531,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "Foxwoods Resort & Casino",
		"mostGuestCity": "Mashantucket",
		"mostGuestAmount": 10
	},
	{
		"id": "36baa65",
		"email": "cade.michals@gmail.com",
		"name": "Cade",
		"createdDate": "2020-01-01 03:34:20",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 70,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 328,
		"schedulePercent": 0.223547,
		"totalGuests": 54,
		"guestPercent": 0.427096,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Pala Casino Spa Resort",
		"mostGuestCity": "Pala",
		"mostGuestAmount": 13
	},
	{
		"id": "1ae2a74",
		"email": "noelle.panepento@gmail.com",
		"name": "Noelle",
		"createdDate": "2016-09-14 21:29:30",
		"totalOrganizations": 17,
		"totalTours": 50,
		"totalDays": 1966,
		"totalCountries": 15,
		"totalStates": 66,
		"totalEvents": 501,
		"eventPercent": 0.974308,
		"totalScheduleItems": 5218,
		"schedulePercent": 0.967686,
		"totalGuests": 1075,
		"guestPercent": 0.835254,
		"mostGuestDate": "2023-08-14 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 61
	},
	{
		"id": "7cea6ea",
		"email": "erica@dasgroup.com",
		"name": "Erica",
		"createdDate": "2014-02-12 03:33:17",
		"totalOrganizations": 12,
		"totalTours": 1,
		"totalDays": 28,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 227,
		"schedulePercent": 0.168057,
		"totalGuests": 17,
		"guestPercent": 0.362469,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "Oak Mountain Amphitheatre",
		"mostGuestCity": "Pelham",
		"mostGuestAmount": 15
	},
	{
		"id": "09227ce",
		"email": "brian.parry@wildhorsesaloon.com",
		"name": "Brian",
		"createdDate": "2019-04-25 16:17:46",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 4994,
		"schedulePercent": 0.961065,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b807f75",
		"email": "ErinsMusicBiz@gmail.com",
		"name": "Erin",
		"createdDate": "2016-09-01 17:36:02",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 599,
		"totalCountries": 11,
		"totalStates": 65,
		"totalEvents": 218,
		"eventPercent": 0.834724,
		"totalScheduleItems": 2666,
		"schedulePercent": 0.816051,
		"totalGuests": 418,
		"guestPercent": 0.66031,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 37
	},
	{
		"id": "e993153",
		"email": "KoolTourManagement@gmail.com",
		"name": "Ibrahim",
		"createdDate": "2023-01-05 18:02:32",
		"totalOrganizations": 1,
		"totalTours": 28,
		"totalDays": 557,
		"totalCountries": 8,
		"totalStates": 29,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 2591,
		"schedulePercent": 0.804794,
		"totalGuests": 444,
		"guestPercent": 0.669845,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "Hard Rock Live at Etess Arena",
		"mostGuestCity": "Atlantic City",
		"mostGuestAmount": 57
	},
	{
		"id": "ef17a2b",
		"email": "charlotte@kttunstallmusic.com",
		"name": "Chris ",
		"createdDate": "2015-07-30 12:41:14",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 60,
		"totalCountries": 5,
		"totalStates": 9,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 423,
		"schedulePercent": 0.265263,
		"totalGuests": 413,
		"guestPercent": 0.659383,
		"mostGuestDate": "2023-03-07 00:00:00",
		"mostGuestName": "London Shepherds Bush Empire",
		"mostGuestCity": "London",
		"mostGuestAmount": 70
	},
	{
		"id": "71dc642",
		"email": "rosullivan.dh@gmail.com",
		"name": "Rosie",
		"createdDate": "2023-01-13 09:08:29",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 23,
		"totalCountries": 8,
		"totalStates": 10,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 199,
		"schedulePercent": 0.149781,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "40a821d",
		"email": "ciztech@me.com",
		"name": "Cyril",
		"createdDate": "2018-05-09 19:21:45",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 271,
		"totalCountries": 16,
		"totalStates": 35,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 1393,
		"schedulePercent": 0.589856,
		"totalGuests": 85,
		"guestPercent": 0.459144,
		"mostGuestDate": "2023-05-28 00:00:00",
		"mostGuestName": "Cross The Tracks Festival",
		"mostGuestCity": "London",
		"mostGuestAmount": 24
	},
	{
		"id": "5765624",
		"email": "hillary@regimemusicgroup.com",
		"name": "Circles",
		"createdDate": "2022-08-03 17:49:03",
		"totalOrganizations": 3,
		"totalTours": 11,
		"totalDays": 248,
		"totalCountries": 7,
		"totalStates": 35,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 753,
		"schedulePercent": 0.403655,
		"totalGuests": 47,
		"guestPercent": 0.417031,
		"mostGuestDate": "2023-01-22 00:00:00",
		"mostGuestName": "The Ardmore Music Hall",
		"mostGuestCity": "Ardmore",
		"mostGuestAmount": 8
	},
	{
		"id": "ea0c8f2",
		"email": "anissa.benier@gmail.com",
		"name": "Anissa",
		"createdDate": "2021-05-31 14:59:16",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 349,
		"totalCountries": 3,
		"totalStates": 11,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 809,
		"schedulePercent": 0.422858,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ca796ba",
		"email": "antonia@offthekerb.co.uk",
		"name": "Antonia",
		"createdDate": "2022-07-06 10:40:19",
		"totalOrganizations": 37,
		"totalTours": 55,
		"totalDays": 4422,
		"totalCountries": 13,
		"totalStates": 40,
		"totalEvents": 1081,
		"eventPercent": 0.990862,
		"totalScheduleItems": 5741,
		"schedulePercent": 0.977089,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-11-29 00:00:00",
		"mostGuestName": "Latter",
		"mostGuestCity": "Oslo",
		"mostGuestAmount": 7
	},
	{
		"id": "08e5940",
		"email": "info@minoman.de",
		"name": "Armin ",
		"createdDate": "2018-02-24 12:01:55",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 48,
		"totalCountries": 4,
		"totalStates": 4,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 231,
		"schedulePercent": 0.169779,
		"totalGuests": 77,
		"guestPercent": 0.452523,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "Odeon of Herodes Atticus",
		"mostGuestCity": "Athina",
		"mostGuestAmount": 17
	},
	{
		"id": "3b4e7c0",
		"email": "garytrew@me.com",
		"name": "Gary",
		"createdDate": "2018-04-04 14:00:10",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 125,
		"totalCountries": 7,
		"totalStates": 24,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 564,
		"schedulePercent": 0.326579,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7917a4a",
		"email": "jamesataylor76@yahoo.com",
		"name": "James",
		"createdDate": "2019-04-20 19:52:49",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 200,
		"totalCountries": 6,
		"totalStates": 41,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1076,
		"schedulePercent": 0.506158,
		"totalGuests": 323,
		"guestPercent": 0.61568,
		"mostGuestDate": "2023-11-06 00:00:00",
		"mostGuestName": "Pantages Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 56
	},
	{
		"id": "871492f",
		"email": "adittomusic@me.com",
		"name": "Allen",
		"createdDate": "2017-11-08 19:27:37",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 380,
		"totalCountries": 1,
		"totalStates": 34,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 917,
		"schedulePercent": 0.456363,
		"totalGuests": 524,
		"guestPercent": 0.699775,
		"mostGuestDate": "2023-11-26 00:00:00",
		"mostGuestName": "Ovens Auditorium",
		"mostGuestCity": "Charlotte",
		"mostGuestAmount": 28
	},
	{
		"id": "6736065",
		"email": "martag.prod@gmail.com",
		"name": "Marta GonÃ§alves",
		"createdDate": "2023-02-02 11:00:03",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 225,
		"totalCountries": 12,
		"totalStates": 28,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 999,
		"schedulePercent": 0.484836,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "471c81d",
		"email": "matias@lauriaweb.com",
		"name": "Matias ",
		"createdDate": "2016-02-02 20:12:34",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 466,
		"totalCountries": 5,
		"totalStates": 23,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 550,
		"schedulePercent": 0.321414,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1195adc",
		"email": "kpayne@617mgmt.com",
		"name": "Brooke",
		"createdDate": "2023-03-08 23:57:07",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 49,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 507,
		"schedulePercent": 0.302741,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "737d07a",
		"email": "devyndillenberg@gmail.com",
		"name": "Devyn",
		"createdDate": "2020-01-31 00:17:21",
		"totalOrganizations": 9,
		"totalTours": 19,
		"totalDays": 551,
		"totalCountries": 10,
		"totalStates": 36,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 3454,
		"schedulePercent": 0.890081,
		"totalGuests": 604,
		"guestPercent": 0.727453,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 45
	},
	{
		"id": "91adb0c",
		"email": "JVKEPM2022@gmail.com",
		"name": "Aaron",
		"createdDate": "2022-05-15 18:43:15",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 893,
		"totalCountries": 4,
		"totalStates": 34,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 2118,
		"schedulePercent": 0.737783,
		"totalGuests": 715,
		"guestPercent": 0.756191,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 158
	},
	{
		"id": "80adb0b",
		"email": "personalmanager@piso21.com.co",
		"name": "Email",
		"createdDate": "2017-12-05 11:10:42",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 215,
		"totalCountries": 7,
		"totalStates": 39,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 309,
		"schedulePercent": 0.212422,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3eb34b7",
		"email": "lifeline.matt@gmail.com",
		"name": "Matt",
		"createdDate": "2017-04-18 04:14:02",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 404,
		"totalCountries": 5,
		"totalStates": 45,
		"totalEvents": 187,
		"eventPercent": 0.784135,
		"totalScheduleItems": 2210,
		"schedulePercent": 0.751424,
		"totalGuests": 1029,
		"guestPercent": 0.828764,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 116
	},
	{
		"id": "90dd679",
		"email": "theau@17ten.co",
		"name": "Theau ",
		"createdDate": "2022-07-04 05:48:45",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 660,
		"totalCountries": 17,
		"totalStates": 49,
		"totalEvents": 261,
		"eventPercent": 0.886902,
		"totalScheduleItems": 2688,
		"schedulePercent": 0.818037,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0f9dc0f",
		"email": "Trevoralbair@gmail.com",
		"name": "Trevor",
		"createdDate": "2016-11-22 02:07:31",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 1999,
		"totalCountries": 25,
		"totalStates": 59,
		"totalEvents": 299,
		"eventPercent": 0.914978,
		"totalScheduleItems": 5534,
		"schedulePercent": 0.973778,
		"totalGuests": 178,
		"guestPercent": 0.534896,
		"mostGuestDate": "2023-02-04 00:00:00",
		"mostGuestName": "Exchange LA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 45
	},
	{
		"id": "960d3ef",
		"email": "ryan@workof-art.com",
		"name": "Ryan",
		"createdDate": "2021-07-26 20:38:27",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 225,
		"totalCountries": 4,
		"totalStates": 31,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 784,
		"schedulePercent": 0.41425,
		"totalGuests": 499,
		"guestPercent": 0.691432,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 75
	},
	{
		"id": "40954de",
		"email": "alex@thepwrhaus.com",
		"name": "Alex",
		"createdDate": "2021-07-16 02:03:53",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 365,
		"totalCountries": 9,
		"totalStates": 55,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 1290,
		"schedulePercent": 0.563766,
		"totalGuests": 833,
		"guestPercent": 0.78387,
		"mostGuestDate": "2023-06-27 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 43
	},
	{
		"id": "22b317e",
		"email": "alex@nutcracker.com",
		"name": "Alexandra",
		"createdDate": "2022-10-12 16:34:24",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 92,
		"totalCountries": 1,
		"totalStates": 37,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 1228,
		"schedulePercent": 0.547345,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "The Music Center @ Strathmore",
		"mostGuestCity": "North Bethesda",
		"mostGuestAmount": 9
	},
	{
		"id": "fed4e53",
		"email": "matthias.derocker@lobbycall.com",
		"name": "matthias",
		"createdDate": "2022-05-29 07:54:23",
		"totalOrganizations": 22,
		"totalTours": 28,
		"totalDays": 637,
		"totalCountries": 26,
		"totalStates": 91,
		"totalEvents": 273,
		"eventPercent": 0.896305,
		"totalScheduleItems": 3810,
		"schedulePercent": 0.912065,
		"totalGuests": 210,
		"guestPercent": 0.557542,
		"mostGuestDate": "2023-03-16 00:00:00",
		"mostGuestName": "The Bowery Ballroom",
		"mostGuestCity": "New York",
		"mostGuestAmount": 33
	},
	{
		"id": "55161dd",
		"email": "hannes_lindstrom@telia.com",
		"name": "Hannes",
		"createdDate": "2017-10-16 10:10:24",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 196,
		"totalCountries": 14,
		"totalStates": 30,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 783,
		"schedulePercent": 0.41372,
		"totalGuests": 375,
		"guestPercent": 0.643358,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "Im Wizemann",
		"mostGuestCity": "Stuttgart",
		"mostGuestAmount": 44
	},
	{
		"id": "ad70f27",
		"email": "management@shwayze.com",
		"name": "Shwayze",
		"createdDate": "2023-03-06 21:27:33",
		"totalOrganizations": 2,
		"totalTours": 14,
		"totalDays": 156,
		"totalCountries": 1,
		"totalStates": 33,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 769,
		"schedulePercent": 0.408555,
		"totalGuests": 338,
		"guestPercent": 0.622699,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Gramercy Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 36
	},
	{
		"id": "92c163b",
		"email": "tdstrunk31@gmail.com",
		"name": "Troy",
		"createdDate": "2022-05-19 20:30:21",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 556,
		"totalCountries": 3,
		"totalStates": 40,
		"totalEvents": 294,
		"eventPercent": 0.9118,
		"totalScheduleItems": 1797,
		"schedulePercent": 0.68534,
		"totalGuests": 66,
		"guestPercent": 0.441001,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Houston Rodeo WIll Call Guest List",
		"mostGuestCity": "",
		"mostGuestAmount": 57
	},
	{
		"id": "031d850",
		"email": "cbird1317@gmail.com",
		"name": "Caroline",
		"createdDate": "2022-12-24 18:26:41",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 206,
		"totalCountries": 4,
		"totalStates": 10,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 623,
		"schedulePercent": 0.350682,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cd67429",
		"email": "jefftrev@gmail.com",
		"name": "Jeff",
		"createdDate": "2022-03-06 18:28:39",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 725,
		"totalCountries": 5,
		"totalStates": 38,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 2120,
		"schedulePercent": 0.738313,
		"totalGuests": 580,
		"guestPercent": 0.718845,
		"mostGuestDate": "2023-03-09 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 142
	},
	{
		"id": "2b6bcf5",
		"email": "ray@craftmgmt.com",
		"name": "Raymond ",
		"createdDate": "2014-02-11 23:44:40",
		"totalOrganizations": 8,
		"totalTours": 7,
		"totalDays": 119,
		"totalCountries": 8,
		"totalStates": 30,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 619,
		"schedulePercent": 0.349755,
		"totalGuests": 591,
		"guestPercent": 0.722818,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Brighton Dome",
		"mostGuestCity": "Brighton",
		"mostGuestAmount": 40
	},
	{
		"id": "ea5a224",
		"email": "smut@semaudio.com",
		"name": "Sebastian",
		"createdDate": "2016-08-12 21:44:15",
		"totalOrganizations": 12,
		"totalTours": 13,
		"totalDays": 760,
		"totalCountries": 6,
		"totalStates": 41,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 2362,
		"schedulePercent": 0.775262,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "HyvinkÃ¤Ã¤ Rockfest",
		"mostGuestCity": "HyvinkÃ¤Ã¤",
		"mostGuestAmount": 2
	},
	{
		"id": "9c31663",
		"email": "hapa.oksanen@warnermusic.com",
		"name": "Harri",
		"createdDate": "2023-01-30 06:49:43",
		"totalOrganizations": 15,
		"totalTours": 28,
		"totalDays": 758,
		"totalCountries": 3,
		"totalStates": 113,
		"totalEvents": 536,
		"eventPercent": 0.977884,
		"totalScheduleItems": 2032,
		"schedulePercent": 0.724407,
		"totalGuests": 699,
		"guestPercent": 0.752748,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Tavara-asema",
		"mostGuestCity": "Tampere",
		"mostGuestAmount": 36
	},
	{
		"id": "263abea",
		"email": "cbrosten@apa-agency.com",
		"name": "Casey",
		"createdDate": "2016-02-11 12:34:39",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 194,
		"totalCountries": 16,
		"totalStates": 54,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 2333,
		"schedulePercent": 0.771421,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-07-09 00:00:00",
		"mostGuestName": "Finsbury Park",
		"mostGuestCity": "London",
		"mostGuestAmount": 2
	},
	{
		"id": "45ce513",
		"email": "patrik@nofp.cz",
		"name": "Patrik",
		"createdDate": "2017-10-17 14:40:05",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 192,
		"totalCountries": 16,
		"totalStates": 61,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 988,
		"schedulePercent": 0.479539,
		"totalGuests": 82,
		"guestPercent": 0.457688,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Lido",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 16
	},
	{
		"id": "46158f3",
		"email": "Kracktharipper@yahoo.com",
		"name": "Krackamya",
		"createdDate": "2017-02-12 03:41:10",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 64,
		"totalCountries": 6,
		"totalStates": 11,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 138,
		"schedulePercent": 0.112171,
		"totalGuests": 51,
		"guestPercent": 0.423785,
		"mostGuestDate": "2023-04-11 00:00:00",
		"mostGuestName": "Rogers Arena",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 10
	},
	{
		"id": "6b694c7",
		"email": "nate@7smgmt.com",
		"name": "Nate ",
		"createdDate": "2015-02-03 18:58:23",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 486,
		"totalCountries": 2,
		"totalStates": 37,
		"totalEvents": 157,
		"eventPercent": 0.720567,
		"totalScheduleItems": 1502,
		"schedulePercent": 0.618329,
		"totalGuests": 1343,
		"guestPercent": 0.876573,
		"mostGuestDate": "2023-11-19 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 88
	},
	{
		"id": "29a46c8",
		"email": "mb@matthewbarnhart.com",
		"name": "Matthew",
		"createdDate": "2017-03-31 05:28:53",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 376,
		"totalCountries": 18,
		"totalStates": 62,
		"totalEvents": 134,
		"eventPercent": 0.658721,
		"totalScheduleItems": 1573,
		"schedulePercent": 0.635413,
		"totalGuests": 379,
		"guestPercent": 0.64455,
		"mostGuestDate": "2023-08-04 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 44
	},
	{
		"id": "ca27caf",
		"email": "grincheacm@xroadslive.com",
		"name": "Company",
		"createdDate": "2023-08-22 17:16:24",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 89,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 624,
		"schedulePercent": 0.351741,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "75f52fb",
		"email": "tw@refugemediagroup.com",
		"name": "Tyler",
		"createdDate": "2023-08-15 22:48:35",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 30,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 378,
		"schedulePercent": 0.247252,
		"totalGuests": 114,
		"guestPercent": 0.486028,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "Music Hall Center for the Performing Arts",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 63
	},
	{
		"id": "bf4f04a",
		"email": "charlton@combsaudio.com",
		"name": "Austin",
		"createdDate": "2015-10-02 00:39:09",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 159,
		"totalCountries": 3,
		"totalStates": 26,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 1478,
		"schedulePercent": 0.609058,
		"totalGuests": 37,
		"guestPercent": 0.400212,
		"mostGuestDate": "2023-05-26 00:00:00",
		"mostGuestName": "Daytona Beach Bandshell",
		"mostGuestCity": "Daytona Beach",
		"mostGuestAmount": 17
	},
	{
		"id": "e4aae86",
		"email": "mfrestedt@gmail.com",
		"name": "Megan",
		"createdDate": "2013-12-10 19:39:22",
		"totalOrganizations": 12,
		"totalTours": 43,
		"totalDays": 734,
		"totalCountries": 12,
		"totalStates": 68,
		"totalEvents": 256,
		"eventPercent": 0.882135,
		"totalScheduleItems": 3573,
		"schedulePercent": 0.898027,
		"totalGuests": 589,
		"guestPercent": 0.722288,
		"mostGuestDate": "2023-08-14 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 61
	},
	{
		"id": "5e84f7d",
		"email": "max@maxmaxson.com",
		"name": "Michael",
		"createdDate": "2013-07-25 14:50:48",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 367,
		"totalCountries": 8,
		"totalStates": 50,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 1232,
		"schedulePercent": 0.547874,
		"totalGuests": 1126,
		"guestPercent": 0.843862,
		"mostGuestDate": "2023-08-23 00:00:00",
		"mostGuestName": "Ravinia",
		"mostGuestCity": "Highland Park",
		"mostGuestAmount": 50
	},
	{
		"id": "a91f8d2",
		"email": "tpigman@hotmail.com",
		"name": "Travis",
		"createdDate": "2016-09-29 06:33:30",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 529,
		"totalCountries": 2,
		"totalStates": 46,
		"totalEvents": 193,
		"eventPercent": 0.792743,
		"totalScheduleItems": 3034,
		"schedulePercent": 0.854191,
		"totalGuests": 2950,
		"guestPercent": 0.969143,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 137
	},
	{
		"id": "0e32dbf",
		"email": "Jafpope11@gmail.com",
		"name": "Aaron",
		"createdDate": "2016-09-19 18:30:29",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 1014,
		"schedulePercent": 0.489339,
		"totalGuests": 649,
		"guestPercent": 0.73924,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 105
	},
	{
		"id": "d3eac32",
		"email": "joel.modin@gmail.com",
		"name": "Joel",
		"createdDate": "2015-06-01 12:31:57",
		"totalOrganizations": 17,
		"totalTours": 9,
		"totalDays": 202,
		"totalCountries": 9,
		"totalStates": 34,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 1153,
		"schedulePercent": 0.527877,
		"totalGuests": 218,
		"guestPercent": 0.561515,
		"mostGuestDate": "2023-03-22 00:00:00",
		"mostGuestName": "L'Olympia",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 101
	},
	{
		"id": "48ba420",
		"email": "brian@spafford.net",
		"name": "Brian",
		"createdDate": "2020-01-07 15:14:25",
		"totalOrganizations": 1,
		"totalTours": 8,
		"totalDays": 311,
		"totalCountries": 1,
		"totalStates": 38,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 1319,
		"schedulePercent": 0.570785,
		"totalGuests": 121,
		"guestPercent": 0.490928,
		"mostGuestDate": "2023-01-13 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 24
	},
	{
		"id": "63c1acf",
		"email": "mikeofinterlock@gmail.com",
		"name": "Michael",
		"createdDate": "2012-01-28 20:47:23",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 94,
		"totalCountries": 1,
		"totalStates": 31,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 768,
		"schedulePercent": 0.40829,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8c9325f",
		"email": "tanja.halonen@warnermusic.com",
		"name": "Tanja",
		"createdDate": "2022-08-12 11:58:18",
		"totalOrganizations": 12,
		"totalTours": 7,
		"totalDays": 234,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 81,
		"schedulePercent": 0.075884,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e5fe40f",
		"email": "paul@thecapitoltheatre.com",
		"name": "Paul",
		"createdDate": "2018-01-10 17:49:48",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 122,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 1025,
		"schedulePercent": 0.491988,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e90385f",
		"email": "gbakerlx@gmail.com",
		"name": "George ",
		"createdDate": "2016-07-18 07:28:21",
		"totalOrganizations": 17,
		"totalTours": 23,
		"totalDays": 580,
		"totalCountries": 20,
		"totalStates": 48,
		"totalEvents": 180,
		"eventPercent": 0.770229,
		"totalScheduleItems": 3383,
		"schedulePercent": 0.886108,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "Good Vibes Festival",
		"mostGuestCity": "Genting Highlands",
		"mostGuestAmount": 3
	},
	{
		"id": "5ee52fd",
		"email": "nicoledwhite1@gmail.com",
		"name": "Nicole",
		"createdDate": "2021-11-04 21:38:38",
		"totalOrganizations": 21,
		"totalTours": 47,
		"totalDays": 710,
		"totalCountries": 14,
		"totalStates": 61,
		"totalEvents": 197,
		"eventPercent": 0.800821,
		"totalScheduleItems": 3040,
		"schedulePercent": 0.854986,
		"totalGuests": 1522,
		"guestPercent": 0.896835,
		"mostGuestDate": "2023-05-07 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 75
	},
	{
		"id": "a481e1a",
		"email": "pad1productions@hotmail.com",
		"name": "Matt",
		"createdDate": "2012-09-13 01:44:51",
		"totalOrganizations": 20,
		"totalTours": 39,
		"totalDays": 559,
		"totalCountries": 11,
		"totalStates": 32,
		"totalEvents": 223,
		"eventPercent": 0.840154,
		"totalScheduleItems": 2901,
		"schedulePercent": 0.84214,
		"totalGuests": 880,
		"guestPercent": 0.794464,
		"mostGuestDate": "2023-06-01 00:00:00",
		"mostGuestName": "The Enmore Theatre",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 107
	},
	{
		"id": "271479f",
		"email": "adam@thinkyellow.co.uk",
		"name": "Adam",
		"createdDate": "2012-05-01 12:37:01",
		"totalOrganizations": 11,
		"totalTours": 7,
		"totalDays": 74,
		"totalCountries": 8,
		"totalStates": 14,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 541,
		"schedulePercent": 0.318236,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a0f618e",
		"email": "seanwdolan@yahoo.com",
		"name": "Sean ",
		"createdDate": "2019-07-01 14:55:59",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 341,
		"totalCountries": 8,
		"totalStates": 68,
		"totalEvents": 251,
		"eventPercent": 0.873792,
		"totalScheduleItems": 2606,
		"schedulePercent": 0.808502,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "667966a",
		"email": "sara@ginmusica.com",
		"name": "sara",
		"createdDate": "2023-11-08 12:59:59",
		"totalOrganizations": 12,
		"totalTours": 4,
		"totalDays": 37,
		"totalCountries": 3,
		"totalStates": 12,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 108,
		"schedulePercent": 0.0956165,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "07c9761",
		"email": "ash@elephantriders.co.uk",
		"name": "Ash",
		"createdDate": "2019-10-14 16:18:56",
		"totalOrganizations": 21,
		"totalTours": 20,
		"totalDays": 857,
		"totalCountries": 26,
		"totalStates": 59,
		"totalEvents": 236,
		"eventPercent": 0.857237,
		"totalScheduleItems": 3339,
		"schedulePercent": 0.8824,
		"totalGuests": 1311,
		"guestPercent": 0.873659,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 125
	},
	{
		"id": "0797805",
		"email": "Jan@gigfoottouring.com",
		"name": "Jan",
		"createdDate": "2022-02-05 21:01:26",
		"totalOrganizations": 9,
		"totalTours": 27,
		"totalDays": 640,
		"totalCountries": 30,
		"totalStates": 80,
		"totalEvents": 268,
		"eventPercent": 0.893127,
		"totalScheduleItems": 4315,
		"schedulePercent": 0.938551,
		"totalGuests": 1831,
		"guestPercent": 0.923189,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 102
	},
	{
		"id": "a0def74",
		"email": "kleffmann@productionoffice.de",
		"name": "Christian",
		"createdDate": "2015-08-01 10:21:43",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 100,
		"totalCountries": 8,
		"totalStates": 22,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 754,
		"schedulePercent": 0.404185,
		"totalGuests": 151,
		"guestPercent": 0.515296,
		"mostGuestDate": "2023-03-21 00:00:00",
		"mostGuestName": "Volkshaus",
		"mostGuestCity": "Zurich",
		"mostGuestAmount": 16
	},
	{
		"id": "8bca9c9",
		"email": "mastertour3@asterios.fr",
		"name": "MasterTour3",
		"createdDate": "2017-10-19 14:26:09",
		"totalOrganizations": 10,
		"totalTours": 4,
		"totalDays": 1064,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 605,
		"schedulePercent": 0.343531,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fe1bffb",
		"email": "sconner@cityparksfoundation.org",
		"name": "Sean",
		"createdDate": "2022-07-19 16:15:00",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "00a6310",
		"email": "info@omgitswande.com",
		"name": "WIN",
		"createdDate": "2022-10-13 13:17:59",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 366,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 229,
		"schedulePercent": 0.169117,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6fc8dc9",
		"email": "omarcrafted@hotmail.com",
		"name": "Omar ",
		"createdDate": "2013-05-05 03:20:54",
		"totalOrganizations": 16,
		"totalTours": 17,
		"totalDays": 669,
		"totalCountries": 7,
		"totalStates": 40,
		"totalEvents": 340,
		"eventPercent": 0.937492,
		"totalScheduleItems": 3642,
		"schedulePercent": 0.903854,
		"totalGuests": 87,
		"guestPercent": 0.460734,
		"mostGuestDate": "2023-05-31 00:00:00",
		"mostGuestName": "The Sound",
		"mostGuestCity": "Del Mar",
		"mostGuestAmount": 20
	},
	{
		"id": "f7bf09b",
		"email": "info@julie-blanche.com",
		"name": "Julie-Blanche",
		"createdDate": "2020-02-10 14:53:14",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 170,
		"totalCountries": 9,
		"totalStates": 16,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 587,
		"schedulePercent": 0.334525,
		"totalGuests": 33,
		"guestPercent": 0.394252,
		"mostGuestDate": "2023-04-25 00:00:00",
		"mostGuestName": "La Cigale",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 30
	},
	{
		"id": "29d1e66",
		"email": "adam@riverjuke.co.uk",
		"name": "Adam",
		"createdDate": "2018-10-11 16:26:38",
		"totalOrganizations": 53,
		"totalTours": 54,
		"totalDays": 3187,
		"totalCountries": 31,
		"totalStates": 118,
		"totalEvents": 986,
		"eventPercent": 0.989935,
		"totalScheduleItems": 16149,
		"schedulePercent": 0.999205,
		"totalGuests": 4631,
		"guestPercent": 0.989405,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "The SSE Arena, Wembley",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 226
	},
	{
		"id": "5eb039d",
		"email": "manu@manu-live.fr",
		"name": "Manu",
		"createdDate": "2022-11-24 09:18:39",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 269,
		"totalCountries": 3,
		"totalStates": 12,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 890,
		"schedulePercent": 0.449609,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c6651db",
		"email": "ringo@infinity-staging.de",
		"name": "Ringo",
		"createdDate": "2022-02-09 16:47:16",
		"totalOrganizations": 4,
		"totalTours": 10,
		"totalDays": 291,
		"totalCountries": 12,
		"totalStates": 55,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 1671,
		"schedulePercent": 0.657794,
		"totalGuests": 1526,
		"guestPercent": 0.897232,
		"mostGuestDate": "2023-01-19 00:00:00",
		"mostGuestName": "Palladium",
		"mostGuestCity": "KÃ¶ln",
		"mostGuestAmount": 184
	},
	{
		"id": "2129594",
		"email": "traceyturner@powderedsugar.co.uk",
		"name": "Tracey",
		"createdDate": "2023-02-09 15:56:41",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 40,
		"totalCountries": 3,
		"totalStates": 8,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 623,
		"schedulePercent": 0.350682,
		"totalGuests": 97,
		"guestPercent": 0.471461,
		"mostGuestDate": "2023-04-15 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 42
	},
	{
		"id": "0173711",
		"email": "brett@ever7mgmt.com",
		"name": "Brett",
		"createdDate": "2018-07-24 19:55:43",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 732,
		"totalCountries": 1,
		"totalStates": 34,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 1511,
		"schedulePercent": 0.62058,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-03-04 00:00:00",
		"mostGuestName": "Crusens Farmington Road",
		"mostGuestCity": "West Peoria",
		"mostGuestAmount": 4
	},
	{
		"id": "cbee683",
		"email": "dmathew1913@gmail.com",
		"name": "Daniel",
		"createdDate": "2022-05-01 18:24:00",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 144,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 876,
		"schedulePercent": 0.445901,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9d0a13e",
		"email": "tylerjamesjohnson@mac.com",
		"name": "Tyler",
		"createdDate": "2017-07-04 01:18:45",
		"totalOrganizations": 14,
		"totalTours": 17,
		"totalDays": 689,
		"totalCountries": 13,
		"totalStates": 51,
		"totalEvents": 206,
		"eventPercent": 0.816183,
		"totalScheduleItems": 2382,
		"schedulePercent": 0.778043,
		"totalGuests": 958,
		"guestPercent": 0.815389,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "The Salt Shed",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 62
	},
	{
		"id": "f1afe9d",
		"email": "SuvonLSmith@gmail.com",
		"name": "Savannah",
		"createdDate": "2022-04-15 06:59:13",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 103,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 562,
		"schedulePercent": 0.326182,
		"totalGuests": 222,
		"guestPercent": 0.564561,
		"mostGuestDate": "2023-01-17 00:00:00",
		"mostGuestName": "Keller Auditorium",
		"mostGuestCity": "Portland",
		"mostGuestAmount": 44
	},
	{
		"id": "0f63e12",
		"email": "meg.macrae@me.com",
		"name": "Meg",
		"createdDate": "2016-01-29 21:47:29",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 395,
		"totalCountries": 13,
		"totalStates": 38,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 2157,
		"schedulePercent": 0.74361,
		"totalGuests": 16,
		"guestPercent": 0.361012,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "The Pavilion at Star Lake",
		"mostGuestCity": "Burgettstown",
		"mostGuestAmount": 10
	},
	{
		"id": "4608c5f",
		"email": "efxglobal@gmail.com",
		"name": "Mark",
		"createdDate": "2017-07-11 01:19:39",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 140,
		"totalCountries": 3,
		"totalStates": 24,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 891,
		"schedulePercent": 0.450007,
		"totalGuests": 148,
		"guestPercent": 0.51225,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Golden Nugget Las Vegas Hotel & Casino",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 32
	},
	{
		"id": "f270c41",
		"email": "moncrieffc@mso.com.au",
		"name": "Callum",
		"createdDate": "2021-09-03 05:04:38",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 33,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 158,
		"schedulePercent": 0.125811,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d730132",
		"email": "rob@361degreesrecords.com",
		"name": "Rob",
		"createdDate": "2022-05-07 23:46:48",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 138,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 1002,
		"schedulePercent": 0.485631,
		"totalGuests": 229,
		"guestPercent": 0.568402,
		"mostGuestDate": "2023-08-06 00:00:00",
		"mostGuestName": "Pine Knob Music Theatre",
		"mostGuestCity": "City of the Village of Clarkston",
		"mostGuestAmount": 43
	},
	{
		"id": "f0b70c2",
		"email": "mathilde@altamira.no",
		"name": "Mathilde",
		"createdDate": "2022-08-09 08:45:24",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 87,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 471,
		"schedulePercent": 0.28473,
		"totalGuests": 26,
		"guestPercent": 0.381274,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "Oslo Konserthus",
		"mostGuestCity": "Oslo",
		"mostGuestAmount": 7
	},
	{
		"id": "23bc0ad",
		"email": "harpjazz@aol.com",
		"name": "Everette",
		"createdDate": "2018-04-25 19:03:11",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 495,
		"totalCountries": 6,
		"totalStates": 33,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 2247,
		"schedulePercent": 0.757251,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-09-03 00:00:00",
		"mostGuestName": "Riverfront Jazz Festival",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 0
	},
	{
		"id": "4f51ca0",
		"email": "crook@crookpro.com",
		"name": "Crook",
		"createdDate": "2016-02-10 18:58:17",
		"totalOrganizations": 7,
		"totalTours": 16,
		"totalDays": 427,
		"totalCountries": 19,
		"totalStates": 72,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 2965,
		"schedulePercent": 0.848364,
		"totalGuests": 916,
		"guestPercent": 0.80294,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 49
	},
	{
		"id": "7b042cc",
		"email": "keitheverettesmith@gmail.com",
		"name": "Keith Everette",
		"createdDate": "2015-08-20 20:22:30",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 266,
		"totalCountries": 3,
		"totalStates": 36,
		"totalEvents": 174,
		"eventPercent": 0.759502,
		"totalScheduleItems": 2178,
		"schedulePercent": 0.747053,
		"totalGuests": 2227,
		"guestPercent": 0.945173,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Gas South Arena",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 157
	},
	{
		"id": "d30e695",
		"email": "colin@sonicbackline.com",
		"name": "Colin ",
		"createdDate": "2021-11-02 16:37:31",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 130,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 530,
		"schedulePercent": 0.312939,
		"totalGuests": 36,
		"guestPercent": 0.397961,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Paterson Park",
		"mostGuestCity": "Delta",
		"mostGuestAmount": 16
	},
	{
		"id": "f3da603",
		"email": "danhallas@comcast.net",
		"name": "Dan ",
		"createdDate": "2015-07-06 03:45:15",
		"totalOrganizations": 10,
		"totalTours": 55,
		"totalDays": 932,
		"totalCountries": 17,
		"totalStates": 67,
		"totalEvents": 245,
		"eventPercent": 0.867435,
		"totalScheduleItems": 3044,
		"schedulePercent": 0.855383,
		"totalGuests": 742,
		"guestPercent": 0.762548,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "CMA Theater at the Country Music Hall of Fame and Museum",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 41
	},
	{
		"id": "f11f23c",
		"email": "matt@regimemusicgroup.com",
		"name": "Matt",
		"createdDate": "2022-06-09 13:26:12",
		"totalOrganizations": 8,
		"totalTours": 12,
		"totalDays": 254,
		"totalCountries": 10,
		"totalStates": 50,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 1273,
		"schedulePercent": 0.55741,
		"totalGuests": 21,
		"guestPercent": 0.371342,
		"mostGuestDate": "2023-06-29 00:00:00",
		"mostGuestName": "Variety Playhouse",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 7
	},
	{
		"id": "63d2deb",
		"email": "ak@es-g.si",
		"name": "A",
		"createdDate": "2022-05-21 16:15:48",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 46,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 270,
		"schedulePercent": 0.19216,
		"totalGuests": 430,
		"guestPercent": 0.664548,
		"mostGuestDate": "2023-10-22 00:00:00",
		"mostGuestName": "Zagreb",
		"mostGuestCity": "Zagreb",
		"mostGuestAmount": 95
	},
	{
		"id": "94b8943",
		"email": "hey@99ways.nl",
		"name": "Chelsea",
		"createdDate": "2023-02-17 21:02:00",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 53,
		"totalCountries": 7,
		"totalStates": 10,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 344,
		"schedulePercent": 0.230168,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3df7ef7",
		"email": "jack@kidgellentertainmentgroup.com",
		"name": "Jack",
		"createdDate": "2023-01-09 06:26:37",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 354,
		"totalCountries": 3,
		"totalStates": 11,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 279,
		"schedulePercent": 0.19759,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9b1bc02",
		"email": "andrewt@vstarentertainment.com",
		"name": "Andrew",
		"createdDate": "2017-12-05 18:48:22",
		"totalOrganizations": 7,
		"totalTours": 22,
		"totalDays": 917,
		"totalCountries": 6,
		"totalStates": 54,
		"totalEvents": 557,
		"eventPercent": 0.980135,
		"totalScheduleItems": 4147,
		"schedulePercent": 0.93087,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fec1c76",
		"email": "atrent@atkinsent.com",
		"name": "Anna",
		"createdDate": "2014-01-07 17:02:44",
		"totalOrganizations": 12,
		"totalTours": 5,
		"totalDays": 382,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 523,
		"schedulePercent": 0.308701,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ddd82ac",
		"email": "lauren@gentrimusic.com",
		"name": "Lauren",
		"createdDate": "2023-03-07 15:38:11",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 31,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 169,
		"schedulePercent": 0.131903,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-03-13 00:00:00",
		"mostGuestName": "Higley Center for the Performing Arts Concert Hall",
		"mostGuestCity": "Gilbert",
		"mostGuestAmount": 15
	},
	{
		"id": "540ae09",
		"email": "alanduggan1990@gmail.com",
		"name": "Alan",
		"createdDate": "2019-04-23 13:39:09",
		"totalOrganizations": 1,
		"totalTours": 6,
		"totalDays": 63,
		"totalCountries": 7,
		"totalStates": 26,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 309,
		"schedulePercent": 0.212422,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9b07545",
		"email": "rogerhobby@gmail.com",
		"name": "Roger",
		"createdDate": "2023-11-03 12:17:46",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 41,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 237,
		"schedulePercent": 0.173355,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "409a2b9",
		"email": "clintboire@mac.com",
		"name": "Clint",
		"createdDate": "2011-05-28 01:19:40",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 701,
		"totalCountries": 4,
		"totalStates": 24,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 997,
		"schedulePercent": 0.484042,
		"totalGuests": 85,
		"guestPercent": 0.459144,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "Westgate Las Vegas Resort & Casino",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 32
	},
	{
		"id": "e88109a",
		"email": "lane@whateverlane.com",
		"name": "Lane",
		"createdDate": "2022-04-04 19:35:53",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 300,
		"totalCountries": 14,
		"totalStates": 50,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 1778,
		"schedulePercent": 0.680705,
		"totalGuests": 1017,
		"guestPercent": 0.82744,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 102
	},
	{
		"id": "6195f4b",
		"email": "andrew.troy@me.com",
		"name": "Andrew",
		"createdDate": "2013-03-15 02:35:22",
		"totalOrganizations": 25,
		"totalTours": 30,
		"totalDays": 605,
		"totalCountries": 28,
		"totalStates": 70,
		"totalEvents": 183,
		"eventPercent": 0.775659,
		"totalScheduleItems": 2525,
		"schedulePercent": 0.795921,
		"totalGuests": 374,
		"guestPercent": 0.642961,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Alexandra Palace Theatre",
		"mostGuestCity": "London",
		"mostGuestAmount": 123
	},
	{
		"id": "f3073d6",
		"email": "donovanmgmt@gmail.com",
		"name": "Steve",
		"createdDate": "2018-07-31 22:40:15",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 64,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 535,
		"schedulePercent": 0.31519,
		"totalGuests": 833,
		"guestPercent": 0.78387,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 121
	},
	{
		"id": "2fd8bda",
		"email": "james.g@productionandtouring.com",
		"name": "James",
		"createdDate": "2018-06-27 18:45:54",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 332,
		"totalCountries": 8,
		"totalStates": 42,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 1347,
		"schedulePercent": 0.579923,
		"totalGuests": 132,
		"guestPercent": 0.499801,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 59
	},
	{
		"id": "2fc5c40",
		"email": "mark@am-touring.co.uk",
		"name": "Mark ",
		"createdDate": "2014-04-15 14:26:43",
		"totalOrganizations": 23,
		"totalTours": 5,
		"totalDays": 163,
		"totalCountries": 7,
		"totalStates": 11,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 860,
		"schedulePercent": 0.440207,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ac91891",
		"email": "caleballemandmusic@gmail.com",
		"name": "Caleb",
		"createdDate": "2022-02-16 02:33:52",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 364,
		"totalCountries": 6,
		"totalStates": 42,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 795,
		"schedulePercent": 0.41862,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "04c2418",
		"email": "shatahadonni@gmail.com",
		"name": "Donni",
		"createdDate": "2022-04-23 15:28:31",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 140,
		"totalCountries": 5,
		"totalStates": 13,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 369,
		"schedulePercent": 0.242749,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8856ebf",
		"email": "bvodinh@mac.com",
		"name": "Brian",
		"createdDate": "2017-01-18 03:49:30",
		"totalOrganizations": 2,
		"totalTours": 10,
		"totalDays": 215,
		"totalCountries": 9,
		"totalStates": 45,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 1304,
		"schedulePercent": 0.56721,
		"totalGuests": 81,
		"guestPercent": 0.455966,
		"mostGuestDate": "2023-04-29 00:00:00",
		"mostGuestName": "Fiserv Forum",
		"mostGuestCity": "Milwaukee",
		"mostGuestAmount": 21
	},
	{
		"id": "8b7e09d",
		"email": "alex@swanseaconstellation.com",
		"name": "Alexander",
		"createdDate": "2022-06-17 12:32:36",
		"totalOrganizations": 10,
		"totalTours": 11,
		"totalDays": 127,
		"totalCountries": 14,
		"totalStates": 19,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 1115,
		"schedulePercent": 0.515031,
		"totalGuests": 118,
		"guestPercent": 0.489207,
		"mostGuestDate": "2023-04-06 00:00:00",
		"mostGuestName": "Voxhall",
		"mostGuestCity": "Aarhus",
		"mostGuestAmount": 17
	},
	{
		"id": "1aaf57b",
		"email": "matt.leost@teglive.com.au",
		"name": "Matt",
		"createdDate": "2022-09-20 03:58:26",
		"totalOrganizations": 20,
		"totalTours": 15,
		"totalDays": 157,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 1021,
		"schedulePercent": 0.491193,
		"totalGuests": 81,
		"guestPercent": 0.455966,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Sydney Opera House",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 22
	},
	{
		"id": "ac8b77f",
		"email": "eddison@machelmontano.com",
		"name": "Eddison",
		"createdDate": "2014-11-28 22:20:51",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 162,
		"totalCountries": 6,
		"totalStates": 11,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 428,
		"schedulePercent": 0.267117,
		"totalGuests": 75,
		"guestPercent": 0.450007,
		"mostGuestDate": "2023-07-23 00:00:00",
		"mostGuestName": "National Botanical Gardens",
		"mostGuestCity": "",
		"mostGuestAmount": 23
	},
	{
		"id": "33880fe",
		"email": "keatonstrombergbusiness@gmail.com",
		"name": "Keaton",
		"createdDate": "2022-10-12 15:44:00",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 80,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 540,
		"schedulePercent": 0.317706,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4cdd661",
		"email": "fiddlerACM@networkstours.com",
		"name": "Company Management",
		"createdDate": "2021-09-16 14:16:16",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 139,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 990,
		"schedulePercent": 0.480201,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "98d221d",
		"email": "ejido@earthlink.net",
		"name": "mark",
		"createdDate": "2010-06-18 01:12:49",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 196,
		"totalCountries": 5,
		"totalStates": 41,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 1159,
		"schedulePercent": 0.529201,
		"totalGuests": 117,
		"guestPercent": 0.488412,
		"mostGuestDate": "2023-07-26 00:00:00",
		"mostGuestName": "Mizner Park Amphitheatre",
		"mostGuestCity": "Boca Raton",
		"mostGuestAmount": 42
	},
	{
		"id": "6e30a18",
		"email": "solene@funkyflymusic.com",
		"name": "Solene ",
		"createdDate": "2022-03-11 11:41:51",
		"totalOrganizations": 11,
		"totalTours": 21,
		"totalDays": 667,
		"totalCountries": 25,
		"totalStates": 75,
		"totalEvents": 181,
		"eventPercent": 0.771818,
		"totalScheduleItems": 2391,
		"schedulePercent": 0.779367,
		"totalGuests": 198,
		"guestPercent": 0.549331,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "Huxleys Neue Welt",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 48
	},
	{
		"id": "62e45b7",
		"email": "Chris@cactuscharmer.com",
		"name": "Chris",
		"createdDate": "2020-11-07 23:46:09",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 407,
		"totalCountries": 13,
		"totalStates": 57,
		"totalEvents": 188,
		"eventPercent": 0.785856,
		"totalScheduleItems": 1884,
		"schedulePercent": 0.701894,
		"totalGuests": 626,
		"guestPercent": 0.733015,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 69
	},
	{
		"id": "f265f04",
		"email": "chris@satellitelive.com.au",
		"name": "Chris",
		"createdDate": "2011-12-08 06:08:52",
		"totalOrganizations": 44,
		"totalTours": 8,
		"totalDays": 165,
		"totalCountries": 3,
		"totalStates": 8,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 704,
		"schedulePercent": 0.382201,
		"totalGuests": 275,
		"guestPercent": 0.59171,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Aware Super Theatre",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 64
	},
	{
		"id": "ec4baca",
		"email": "staceylsinger@gmail.com",
		"name": "Stacey",
		"createdDate": "2012-09-11 12:32:11",
		"totalOrganizations": 3,
		"totalTours": 14,
		"totalDays": 193,
		"totalCountries": 3,
		"totalStates": 22,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 899,
		"schedulePercent": 0.452788,
		"totalGuests": 246,
		"guestPercent": 0.576612,
		"mostGuestDate": "2023-04-08 00:00:00",
		"mostGuestName": "Saban Theatre",
		"mostGuestCity": "Beverly Hills",
		"mostGuestAmount": 84
	},
	{
		"id": "49c2cca",
		"email": "thomas.w.stone@mac.com",
		"name": "Thomas",
		"createdDate": "2016-06-25 17:38:46",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 183,
		"totalCountries": 7,
		"totalStates": 10,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 1133,
		"schedulePercent": 0.520726,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "73b9e8f",
		"email": "tlynn2620@hotmail.com",
		"name": "Teri",
		"createdDate": "2011-06-23 04:48:31",
		"totalOrganizations": 13,
		"totalTours": 3,
		"totalDays": 1095,
		"totalCountries": 13,
		"totalStates": 30,
		"totalEvents": 255,
		"eventPercent": 0.880943,
		"totalScheduleItems": 3464,
		"schedulePercent": 0.891538,
		"totalGuests": 366,
		"guestPercent": 0.639386,
		"mostGuestDate": "2023-01-10 00:00:00",
		"mostGuestName": "The Classic Center",
		"mostGuestCity": "Athens",
		"mostGuestAmount": 68
	},
	{
		"id": "6814fe4",
		"email": "events@lifesurge.com",
		"name": "LIFE",
		"createdDate": "2022-12-09 18:07:43",
		"totalOrganizations": 2,
		"totalTours": 17,
		"totalDays": 90,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 1692,
		"schedulePercent": 0.663223,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "PHX: Dream City Church",
		"mostGuestCity": "Phoenix",
		"mostGuestAmount": 0
	},
	{
		"id": "a4ff3da",
		"email": "katy@offthekerb.co.uk",
		"name": "KATY",
		"createdDate": "2022-10-04 11:16:30",
		"totalOrganizations": 47,
		"totalTours": 61,
		"totalDays": 4561,
		"totalCountries": 14,
		"totalStates": 47,
		"totalEvents": 1201,
		"eventPercent": 0.992451,
		"totalScheduleItems": 6767,
		"schedulePercent": 0.987286,
		"totalGuests": 186,
		"guestPercent": 0.540458,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 57
	},
	{
		"id": "4c23ad0",
		"email": "gary@villamrocks.com",
		"name": "Gary ",
		"createdDate": "2014-10-03 23:04:40",
		"totalOrganizations": 10,
		"totalTours": 6,
		"totalDays": 516,
		"totalCountries": 6,
		"totalStates": 46,
		"totalEvents": 159,
		"eventPercent": 0.726394,
		"totalScheduleItems": 1294,
		"schedulePercent": 0.564826,
		"totalGuests": 467,
		"guestPercent": 0.680307,
		"mostGuestDate": "2023-10-09 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 59
	},
	{
		"id": "b6897f6",
		"email": "luke.shaw@aco.com.au",
		"name": "Luke",
		"createdDate": "2020-11-02 09:10:32",
		"totalOrganizations": 1,
		"totalTours": 20,
		"totalDays": 479,
		"totalCountries": 4,
		"totalStates": 11,
		"totalEvents": 3,
		"eventPercent": 0.0202622,
		"totalScheduleItems": 1703,
		"schedulePercent": 0.665342,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ff13b23",
		"email": "jptmix@hotmail.com",
		"name": "Jose Luis",
		"createdDate": "2013-03-22 18:09:04",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 741,
		"totalCountries": 6,
		"totalStates": 33,
		"totalEvents": 255,
		"eventPercent": 0.880943,
		"totalScheduleItems": 2385,
		"schedulePercent": 0.778837,
		"totalGuests": 196,
		"guestPercent": 0.546947,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "Madison Square Garden",
		"mostGuestCity": "New York",
		"mostGuestAmount": 22
	},
	{
		"id": "35779cb",
		"email": "keith@entershikari.com",
		"name": "keith",
		"createdDate": "2015-05-11 07:55:37",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 76,
		"totalCountries": 8,
		"totalStates": 22,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 316,
		"schedulePercent": 0.216263,
		"totalGuests": 19,
		"guestPercent": 0.366971,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Bottom Lounge",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 19
	},
	{
		"id": "c06df22",
		"email": "twoinchmachine@gmail.com",
		"name": "Rob",
		"createdDate": "2013-06-13 02:13:50",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 545,
		"totalCountries": 3,
		"totalStates": 48,
		"totalEvents": 272,
		"eventPercent": 0.895643,
		"totalScheduleItems": 2708,
		"schedulePercent": 0.820156,
		"totalGuests": 200,
		"guestPercent": 0.550258,
		"mostGuestDate": "2023-02-24 00:00:00",
		"mostGuestName": "Gila River Resorts & Casinos - Wild Horse Pass",
		"mostGuestCity": "Chandler",
		"mostGuestAmount": 36
	},
	{
		"id": "336363d",
		"email": "john@johnedman.com",
		"name": "John",
		"createdDate": "2010-04-02 17:58:15",
		"totalOrganizations": 24,
		"totalTours": 17,
		"totalDays": 796,
		"totalCountries": 7,
		"totalStates": 43,
		"totalEvents": 331,
		"eventPercent": 0.932327,
		"totalScheduleItems": 3002,
		"schedulePercent": 0.851013,
		"totalGuests": 153,
		"guestPercent": 0.515958,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Thornton Winery",
		"mostGuestCity": "Temecula",
		"mostGuestAmount": 21
	},
	{
		"id": "7330b32",
		"email": "courtneydellafiora@gmail.com",
		"name": "Courtney",
		"createdDate": "2018-11-14 01:39:22",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 238,
		"totalCountries": 12,
		"totalStates": 54,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 2045,
		"schedulePercent": 0.726659,
		"totalGuests": 1466,
		"guestPercent": 0.889948,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "Hard Rock Live",
		"mostGuestCity": "Orlando",
		"mostGuestAmount": 87
	},
	{
		"id": "83274b0",
		"email": "seth.hewson@gmail.com",
		"name": "Seth",
		"createdDate": "2015-11-08 05:29:28",
		"totalOrganizations": 9,
		"totalTours": 5,
		"totalDays": 686,
		"totalCountries": 2,
		"totalStates": 48,
		"totalEvents": 207,
		"eventPercent": 0.817905,
		"totalScheduleItems": 3150,
		"schedulePercent": 0.865846,
		"totalGuests": 1874,
		"guestPercent": 0.9265,
		"mostGuestDate": "2023-10-12 00:00:00",
		"mostGuestName": "Walk of Fame Park",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 148
	},
	{
		"id": "98edacd",
		"email": "gdickey273@gmail.com",
		"name": "Graham",
		"createdDate": "2022-01-14 17:41:43",
		"totalOrganizations": 6,
		"totalTours": 11,
		"totalDays": 728,
		"totalCountries": 7,
		"totalStates": 46,
		"totalEvents": 157,
		"eventPercent": 0.720567,
		"totalScheduleItems": 1689,
		"schedulePercent": 0.662561,
		"totalGuests": 1354,
		"guestPercent": 0.877897,
		"mostGuestDate": "2023-04-08 00:00:00",
		"mostGuestName": "MGM Music Hall at Fenway",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 86
	},
	{
		"id": "b5a4bde",
		"email": "mexmag@gmail.com",
		"name": "Mario",
		"createdDate": "2015-02-03 14:43:32",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 1234,
		"totalCountries": 19,
		"totalStates": 62,
		"totalEvents": 167,
		"eventPercent": 0.745332,
		"totalScheduleItems": 2200,
		"schedulePercent": 0.749437,
		"totalGuests": 802,
		"guestPercent": 0.776718,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Irving Plaza",
		"mostGuestCity": "New York",
		"mostGuestAmount": 53
	},
	{
		"id": "9cc0323",
		"email": "bryanwmusic@gmail.com",
		"name": "Bryan",
		"createdDate": "2014-03-06 22:47:06",
		"totalOrganizations": 10,
		"totalTours": 13,
		"totalDays": 1237,
		"totalCountries": 16,
		"totalStates": 64,
		"totalEvents": 209,
		"eventPercent": 0.82201,
		"totalScheduleItems": 3402,
		"schedulePercent": 0.887829,
		"totalGuests": 743,
		"guestPercent": 0.76268,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "iTHINK Financial Amphitheatre",
		"mostGuestCity": "West Palm Beach",
		"mostGuestAmount": 84
	},
	{
		"id": "15dded7",
		"email": "vesti37@gmail.com",
		"name": "Steve",
		"createdDate": "2019-09-03 10:33:55",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 210,
		"totalCountries": 9,
		"totalStates": 28,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 1080,
		"schedulePercent": 0.507747,
		"totalGuests": 311,
		"guestPercent": 0.610118,
		"mostGuestDate": "2023-09-01 00:00:00",
		"mostGuestName": "Ancienne Belgique",
		"mostGuestCity": "Brussel",
		"mostGuestAmount": 126
	},
	{
		"id": "85bbd60",
		"email": "mark@aedesventures.com",
		"name": "Mark",
		"createdDate": "2021-08-13 19:59:21",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 216,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 196,
		"schedulePercent": 0.147663,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "123c9df",
		"email": "toursick@icloud.com",
		"name": "Brad",
		"createdDate": "2019-09-02 16:40:08",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 21,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 256,
		"schedulePercent": 0.183684,
		"totalGuests": 875,
		"guestPercent": 0.793008,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 524
	},
	{
		"id": "a71d367",
		"email": "rcorrea256@aol.com",
		"name": "Dean",
		"createdDate": "2015-06-03 02:54:05",
		"totalOrganizations": 88,
		"totalTours": 21,
		"totalDays": 334,
		"totalCountries": 6,
		"totalStates": 36,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 919,
		"schedulePercent": 0.456893,
		"totalGuests": 70,
		"guestPercent": 0.445636,
		"mostGuestDate": "2023-03-10 00:00:00",
		"mostGuestName": "Yoshi's Oakland",
		"mostGuestCity": "Oakland",
		"mostGuestAmount": 13
	},
	{
		"id": "ba27aac",
		"email": "LANIPURKIS@GMAIL.COM",
		"name": "Lani",
		"createdDate": "2020-12-27 04:27:56",
		"totalOrganizations": 17,
		"totalTours": 38,
		"totalDays": 461,
		"totalCountries": 14,
		"totalStates": 29,
		"totalEvents": 178,
		"eventPercent": 0.767051,
		"totalScheduleItems": 1938,
		"schedulePercent": 0.709045,
		"totalGuests": 101,
		"guestPercent": 0.474904,
		"mostGuestDate": "2023-03-28 00:00:00",
		"mostGuestName": "Le Trianon",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 24
	},
	{
		"id": "f452f48",
		"email": "andy.e@redstreetrecords.com",
		"name": "Andy",
		"createdDate": "2023-03-09 18:55:44",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 450,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 2,
		"eventPercent": 0.0140379,
		"totalScheduleItems": 118,
		"schedulePercent": 0.101973,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "30c552d",
		"email": "wb.unrealcountry@gmail.com",
		"name": "William",
		"createdDate": "2023-09-20 04:01:44",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 96,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 116,
		"schedulePercent": 0.100384,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d31d7f3",
		"email": "neus@rosalia.com",
		"name": "Neus",
		"createdDate": "2019-01-25 11:32:53",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 191,
		"totalCountries": 5,
		"totalStates": 11,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 1516,
		"schedulePercent": 0.622169,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0d27538",
		"email": "cscanlon@aegpresents.com",
		"name": "Chris",
		"createdDate": "2017-05-26 16:01:37",
		"totalOrganizations": 25,
		"totalTours": 6,
		"totalDays": 459,
		"totalCountries": 8,
		"totalStates": 58,
		"totalEvents": 236,
		"eventPercent": 0.857237,
		"totalScheduleItems": 2835,
		"schedulePercent": 0.836181,
		"totalGuests": 61,
		"guestPercent": 0.435571,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Algonquin Commons Theatre",
		"mostGuestCity": "Ottawa",
		"mostGuestAmount": 15
	},
	{
		"id": "aa83f80",
		"email": "fedgepacker@gmail.com",
		"name": "Jeff",
		"createdDate": "2016-03-19 21:13:44",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 129,
		"totalCountries": 10,
		"totalStates": 43,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 1316,
		"schedulePercent": 0.570256,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Alcatraz Festival ",
		"mostGuestCity": "Kortrijk",
		"mostGuestAmount": 0
	},
	{
		"id": "f009250",
		"email": "jaminhines@hotmail.com",
		"name": "Benjamin",
		"createdDate": "2010-03-01 18:32:39",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 397,
		"totalCountries": 4,
		"totalStates": 32,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 1529,
		"schedulePercent": 0.624685,
		"totalGuests": 239,
		"guestPercent": 0.573037,
		"mostGuestDate": "2023-01-13 00:00:00",
		"mostGuestName": "The Mission Ballroom",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 21
	},
	{
		"id": "02d51b6",
		"email": "joev.tour@gmail.com",
		"name": "Joe",
		"createdDate": "2019-11-13 06:50:16",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 237,
		"totalCountries": 5,
		"totalStates": 44,
		"totalEvents": 145,
		"eventPercent": 0.688518,
		"totalScheduleItems": 1269,
		"schedulePercent": 0.557012,
		"totalGuests": 40,
		"guestPercent": 0.405642,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "The Studio at Webster Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 15
	},
	{
		"id": "809d1b1",
		"email": "chrisstinebrink@yahoo.com",
		"name": "Chris ",
		"createdDate": "2011-04-11 15:32:19",
		"totalOrganizations": 6,
		"totalTours": 18,
		"totalDays": 820,
		"totalCountries": 25,
		"totalStates": 66,
		"totalEvents": 300,
		"eventPercent": 0.915508,
		"totalScheduleItems": 5389,
		"schedulePercent": 0.97113,
		"totalGuests": 1156,
		"guestPercent": 0.850351,
		"mostGuestDate": "2023-09-05 00:00:00",
		"mostGuestName": "Singapore Indoor Stadium",
		"mostGuestCity": "Singapore",
		"mostGuestAmount": 78
	},
	{
		"id": "4c3bd67",
		"email": "camila.florez@4jts.com",
		"name": "Camila",
		"createdDate": "2014-09-08 19:39:11",
		"totalOrganizations": 4,
		"totalTours": 14,
		"totalDays": 1041,
		"totalCountries": 11,
		"totalStates": 55,
		"totalEvents": 188,
		"eventPercent": 0.785856,
		"totalScheduleItems": 2365,
		"schedulePercent": 0.775526,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bba14e4",
		"email": "jen@jenochej.com",
		"name": "Jen",
		"createdDate": "2016-11-16 00:21:01",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 169,
		"totalCountries": 7,
		"totalStates": 38,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 632,
		"schedulePercent": 0.355185,
		"totalGuests": 55,
		"guestPercent": 0.42842,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "O2 Academy Islington",
		"mostGuestCity": "London",
		"mostGuestAmount": 15
	},
	{
		"id": "36d74df",
		"email": "lorraine@macmastermusic.com",
		"name": "Lorraine ",
		"createdDate": "2022-11-14 00:01:35",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 171,
		"totalCountries": 4,
		"totalStates": 18,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 224,
		"schedulePercent": 0.166071,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "77d9fa3",
		"email": "rosalinda.ruiz@gmail.com",
		"name": "Rosalinda",
		"createdDate": "2019-04-21 20:41:47",
		"totalOrganizations": 5,
		"totalTours": 11,
		"totalDays": 167,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 398,
		"schedulePercent": 0.256257,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "da66e7e",
		"email": "sean00patrick@gmail.com",
		"name": "Sean",
		"createdDate": "2018-05-24 22:04:18",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 360,
		"totalCountries": 14,
		"totalStates": 45,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 1285,
		"schedulePercent": 0.56125,
		"totalGuests": 1245,
		"guestPercent": 0.863991,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Broncos Stadium at Mile High Station",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 255
	},
	{
		"id": "0fdd00b",
		"email": "chase_bennett@icloud.com",
		"name": "Chase",
		"createdDate": "2013-03-04 18:11:21",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 405,
		"totalCountries": 3,
		"totalStates": 17,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 1346,
		"schedulePercent": 0.579791,
		"totalGuests": 234,
		"guestPercent": 0.570256,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Pine Knob Music Theatre",
		"mostGuestCity": "Village of Clarkston",
		"mostGuestAmount": 37
	},
	{
		"id": "5393cea",
		"email": "cormac@sixshooterrecords.com",
		"name": "Cormac",
		"createdDate": "2022-09-28 19:26:48",
		"totalOrganizations": 6,
		"totalTours": 15,
		"totalDays": 962,
		"totalCountries": 9,
		"totalStates": 40,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 1693,
		"schedulePercent": 0.663356,
		"totalGuests": 353,
		"guestPercent": 0.631307,
		"mostGuestDate": "2023-06-14 00:00:00",
		"mostGuestName": "TD Music Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 65
	},
	{
		"id": "8c53593",
		"email": "neal@slushmanagement.com",
		"name": "Neal",
		"createdDate": "2012-03-26 21:39:29",
		"totalOrganizations": 8,
		"totalTours": 16,
		"totalDays": 213,
		"totalCountries": 11,
		"totalStates": 26,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1129,
		"schedulePercent": 0.519666,
		"totalGuests": 142,
		"guestPercent": 0.506688,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Powerstation",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 73
	},
	{
		"id": "6deef54",
		"email": "piper1978@me.com",
		"name": "Kevin ",
		"createdDate": "2022-02-17 14:10:19",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 152,
		"totalCountries": 5,
		"totalStates": 29,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 472,
		"schedulePercent": 0.285658,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-03-02 00:00:00",
		"mostGuestName": "State Theatre Center for the Arts",
		"mostGuestCity": "Easton",
		"mostGuestAmount": 2
	},
	{
		"id": "91dbffc",
		"email": "maddsoul@mac.com",
		"name": "Alan",
		"createdDate": "2011-08-13 18:03:27",
		"totalOrganizations": 8,
		"totalTours": 7,
		"totalDays": 530,
		"totalCountries": 3,
		"totalStates": 32,
		"totalEvents": 167,
		"eventPercent": 0.745332,
		"totalScheduleItems": 3918,
		"schedulePercent": 0.919878,
		"totalGuests": 171,
		"guestPercent": 0.530658,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "The Fillmore",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 45
	},
	{
		"id": "ad5c2d1",
		"email": "jameskerri100@gmail.com",
		"name": "James",
		"createdDate": "2015-10-28 19:43:46",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 579,
		"totalCountries": 3,
		"totalStates": 35,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 1608,
		"schedulePercent": 0.644153,
		"totalGuests": 433,
		"guestPercent": 0.666402,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 43
	},
	{
		"id": "07c4745",
		"email": "kyle@kcment.com",
		"name": "Kyle",
		"createdDate": "2015-08-31 22:40:27",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 419,
		"totalCountries": 9,
		"totalStates": 41,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 1315,
		"schedulePercent": 0.569991,
		"totalGuests": 1008,
		"guestPercent": 0.825718,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 93
	},
	{
		"id": "367b033",
		"email": "christopherjohnholding@gmail.com",
		"name": "Christopher",
		"createdDate": "2017-05-28 15:21:20",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 135,
		"totalCountries": 13,
		"totalStates": 32,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 684,
		"schedulePercent": 0.374388,
		"totalGuests": 148,
		"guestPercent": 0.51225,
		"mostGuestDate": "2023-07-27 00:00:00",
		"mostGuestName": "Rock City",
		"mostGuestCity": "Nottingham",
		"mostGuestAmount": 31
	},
	{
		"id": "1fe758d",
		"email": "sammy@friendsatwork.com",
		"name": "Samuel",
		"createdDate": "2019-06-24 20:07:47",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 927,
		"totalCountries": 15,
		"totalStates": 61,
		"totalEvents": 467,
		"eventPercent": 0.970203,
		"totalScheduleItems": 7450,
		"schedulePercent": 0.991127,
		"totalGuests": 3807,
		"guestPercent": 0.981857,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 300
	},
	{
		"id": "a89c34f",
		"email": "raymond@soniccity.no",
		"name": "Raymond",
		"createdDate": "2016-11-22 10:45:10",
		"totalOrganizations": 14,
		"totalTours": 27,
		"totalDays": 980,
		"totalCountries": 20,
		"totalStates": 58,
		"totalEvents": 431,
		"eventPercent": 0.964508,
		"totalScheduleItems": 4996,
		"schedulePercent": 0.961594,
		"totalGuests": 1818,
		"guestPercent": 0.921865,
		"mostGuestDate": "2023-05-07 00:00:00",
		"mostGuestName": "O2 Academy Bristol",
		"mostGuestCity": "Bristol",
		"mostGuestAmount": 132
	},
	{
		"id": "7d3ccf4",
		"email": "dale@catpawprod.com",
		"name": "Dale",
		"createdDate": "2019-07-10 00:48:19",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 217,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 4,
		"eventPercent": 0.0288703,
		"totalScheduleItems": 87,
		"schedulePercent": 0.0797245,
		"totalGuests": 76,
		"guestPercent": 0.451596,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "The Zoo",
		"mostGuestCity": "Fortitude Valley",
		"mostGuestAmount": 46
	},
	{
		"id": "5ed4acf",
		"email": "Lauramengel@gmail.com",
		"name": "Laura",
		"createdDate": "2023-02-06 17:24:55",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 122,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 136,
		"schedulePercent": 0.110581,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9daf704",
		"email": "reggie@maolimusic.com",
		"name": "Reggie",
		"createdDate": "2022-12-15 12:05:17",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 69,
		"totalCountries": 3,
		"totalStates": 11,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 503,
		"schedulePercent": 0.29996,
		"totalGuests": 778,
		"guestPercent": 0.771421,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "WaikÄ«kÄ« Shell",
		"mostGuestCity": "Honolulu",
		"mostGuestAmount": 86
	},
	{
		"id": "d259525",
		"email": "drd485389@gmail.com",
		"name": "DAVE",
		"createdDate": "2022-06-22 19:21:49",
		"totalOrganizations": 2,
		"totalTours": 19,
		"totalDays": 558,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 1,
		"eventPercent": 0.00662164,
		"totalScheduleItems": 549,
		"schedulePercent": 0.320355,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6c92d80",
		"email": "lauren.bw@hotmail.co.uk",
		"name": "Lauren",
		"createdDate": "2015-01-18 19:08:21",
		"totalOrganizations": 11,
		"totalTours": 16,
		"totalDays": 1473,
		"totalCountries": 12,
		"totalStates": 47,
		"totalEvents": 539,
		"eventPercent": 0.978546,
		"totalScheduleItems": 7413,
		"schedulePercent": 0.990995,
		"totalGuests": 18,
		"guestPercent": 0.364455,
		"mostGuestDate": "2023-03-14 00:00:00",
		"mostGuestName": "The Lower Third",
		"mostGuestCity": "London",
		"mostGuestAmount": 9
	},
	{
		"id": "1b948dd",
		"email": "cowboy@claywalker.com",
		"name": "Clay",
		"createdDate": "2017-07-22 15:23:47",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 179,
		"totalCountries": 1,
		"totalStates": 29,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 983,
		"schedulePercent": 0.476758,
		"totalGuests": 355,
		"guestPercent": 0.632631,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Billy Bob's",
		"mostGuestCity": "Ft. Worth",
		"mostGuestAmount": 48
	},
	{
		"id": "a857119",
		"email": "Callumjohnbarter@gmail.com",
		"name": "Callum",
		"createdDate": "2017-10-12 09:56:50",
		"totalOrganizations": 8,
		"totalTours": 20,
		"totalDays": 432,
		"totalCountries": 13,
		"totalStates": 50,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 2602,
		"schedulePercent": 0.808105,
		"totalGuests": 1672,
		"guestPercent": 0.909681,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 75
	},
	{
		"id": "2499466",
		"email": "amp-sheffield@live.com",
		"name": "Aaron",
		"createdDate": "2018-09-18 15:39:07",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 225,
		"totalCountries": 4,
		"totalStates": 6,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 579,
		"schedulePercent": 0.331744,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b600e5a",
		"email": "michael@event-productions.co.uk",
		"name": "Michael",
		"createdDate": "2018-07-03 17:13:45",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 506,
		"totalCountries": 12,
		"totalStates": 40,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 2176,
		"schedulePercent": 0.746391,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0c6017b",
		"email": "barenakedladiestm@gmail.com",
		"name": "Barenaked",
		"createdDate": "2020-01-29 01:38:07",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 124,
		"totalCountries": 6,
		"totalStates": 29,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 1091,
		"schedulePercent": 0.510264,
		"totalGuests": 641,
		"guestPercent": 0.736326,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 58
	},
	{
		"id": "f68431a",
		"email": "joelkirschenbaum@gmail.com",
		"name": "Joel",
		"createdDate": "2019-07-28 16:22:31",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 141,
		"totalCountries": 10,
		"totalStates": 43,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 832,
		"schedulePercent": 0.431731,
		"totalGuests": 237,
		"guestPercent": 0.571712,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Cornerstone Craft Beer & Live Music",
		"mostGuestCity": "Berkeley",
		"mostGuestAmount": 32
	},
	{
		"id": "8ec8ef5",
		"email": "durezalive@gmail.com",
		"name": "Filipe",
		"createdDate": "2017-01-25 02:49:42",
		"totalOrganizations": 14,
		"totalTours": 18,
		"totalDays": 415,
		"totalCountries": 26,
		"totalStates": 102,
		"totalEvents": 266,
		"eventPercent": 0.891935,
		"totalScheduleItems": 3388,
		"schedulePercent": 0.88677,
		"totalGuests": 2501,
		"guestPercent": 0.954046,
		"mostGuestDate": "2023-12-16 00:00:00",
		"mostGuestName": "OVO Arena Wembley",
		"mostGuestCity": "London",
		"mostGuestAmount": 119
	},
	{
		"id": "3a12c2d",
		"email": "tombo@mtmshows.com",
		"name": "Miguel",
		"createdDate": "2021-08-11 04:40:49",
		"totalOrganizations": 6,
		"totalTours": 15,
		"totalDays": 1160,
		"totalCountries": 10,
		"totalStates": 66,
		"totalEvents": 234,
		"eventPercent": 0.854324,
		"totalScheduleItems": 1349,
		"schedulePercent": 0.58032,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6047cc5",
		"email": "marcus@mbccreative.com",
		"name": "Marcus",
		"createdDate": "2021-05-04 04:17:05",
		"totalOrganizations": 14,
		"totalTours": 13,
		"totalDays": 494,
		"totalCountries": 22,
		"totalStates": 91,
		"totalEvents": 292,
		"eventPercent": 0.910211,
		"totalScheduleItems": 3908,
		"schedulePercent": 0.919216,
		"totalGuests": 1796,
		"guestPercent": 0.920011,
		"mostGuestDate": "2023-05-08 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 82
	},
	{
		"id": "5c06972",
		"email": "adow325@gmail.com",
		"name": "Alex",
		"createdDate": "2019-04-23 16:08:13",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 180,
		"totalCountries": 7,
		"totalStates": 22,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 484,
		"schedulePercent": 0.290558,
		"totalGuests": 103,
		"guestPercent": 0.478082,
		"mostGuestDate": "2023-09-22 00:00:00",
		"mostGuestName": "Royale",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 45
	},
	{
		"id": "faed353",
		"email": "kierra@sheltermusic.com",
		"name": "Kierra",
		"createdDate": "2021-01-05 20:07:40",
		"totalOrganizations": 14,
		"totalTours": 13,
		"totalDays": 1057,
		"totalCountries": 13,
		"totalStates": 63,
		"totalEvents": 308,
		"eventPercent": 0.919746,
		"totalScheduleItems": 4147,
		"schedulePercent": 0.93087,
		"totalGuests": 799,
		"guestPercent": 0.775924,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Pine Knob Music Theatre",
		"mostGuestCity": "City of the Village of Clarkston",
		"mostGuestAmount": 62
	},
	{
		"id": "373e2d1",
		"email": "Ost_TM@hotmail.com",
		"name": "Ost",
		"createdDate": "2018-07-02 12:57:17",
		"totalOrganizations": 12,
		"totalTours": 13,
		"totalDays": 1188,
		"totalCountries": 15,
		"totalStates": 42,
		"totalEvents": 179,
		"eventPercent": 0.769037,
		"totalScheduleItems": 1718,
		"schedulePercent": 0.66905,
		"totalGuests": 93,
		"guestPercent": 0.468017,
		"mostGuestDate": "2023-09-07 00:00:00",
		"mostGuestName": "Manchester Academy",
		"mostGuestCity": "Manchester",
		"mostGuestAmount": 23
	},
	{
		"id": "dd7adfa",
		"email": "allisonfitts@icloud.com",
		"name": "Allison",
		"createdDate": "2022-04-28 15:33:30",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 665,
		"totalCountries": 3,
		"totalStates": 22,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 638,
		"schedulePercent": 0.357701,
		"totalGuests": 54,
		"guestPercent": 0.427096,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "The Rave Hall",
		"mostGuestCity": "Milwaukee",
		"mostGuestAmount": 17
	},
	{
		"id": "5500583",
		"email": "ninni.palmroos@warnermusic.com",
		"name": "Ninni",
		"createdDate": "2018-09-07 11:19:52",
		"totalOrganizations": 24,
		"totalTours": 20,
		"totalDays": 522,
		"totalCountries": 4,
		"totalStates": 40,
		"totalEvents": 502,
		"eventPercent": 0.97444,
		"totalScheduleItems": 2174,
		"schedulePercent": 0.745861,
		"totalGuests": 246,
		"guestPercent": 0.576612,
		"mostGuestDate": "2023-09-01 00:00:00",
		"mostGuestName": "Tavara-asema",
		"mostGuestCity": "Tampere",
		"mostGuestAmount": 36
	},
	{
		"id": "19b0e7c",
		"email": "holger@tourproduction.de",
		"name": "Holger",
		"createdDate": "2014-07-16 05:54:17",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 26,
		"totalCountries": 5,
		"totalStates": 14,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 306,
		"schedulePercent": 0.211098,
		"totalGuests": 372,
		"guestPercent": 0.641902,
		"mostGuestDate": "2023-11-02 00:00:00",
		"mostGuestName": "Capitol Hannover",
		"mostGuestCity": "Hannover",
		"mostGuestAmount": 74
	},
	{
		"id": "659e001",
		"email": "lydia@catherineharidy.com",
		"name": "Lydia",
		"createdDate": "2019-10-16 02:09:09",
		"totalOrganizations": 7,
		"totalTours": 30,
		"totalDays": 204,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 742,
		"schedulePercent": 0.397431,
		"totalGuests": 294,
		"guestPercent": 0.599656,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "The Rechabite",
		"mostGuestCity": "Northbridge",
		"mostGuestAmount": 34
	},
	{
		"id": "8058907",
		"email": "jani@mp-viihde.net",
		"name": "Jani",
		"createdDate": "2022-05-04 09:00:29",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 325,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 316,
		"eventPercent": 0.923719,
		"totalScheduleItems": 923,
		"schedulePercent": 0.457688,
		"totalGuests": 200,
		"guestPercent": 0.550258,
		"mostGuestDate": "2023-07-05 00:00:00",
		"mostGuestName": "Allas Sea Pool",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 42
	},
	{
		"id": "040c8a9",
		"email": "youngmarymedia@gmail.com",
		"name": "Mary",
		"createdDate": "2021-10-02 14:36:15",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 873,
		"totalCountries": 7,
		"totalStates": 38,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 719,
		"schedulePercent": 0.387763,
		"totalGuests": 245,
		"guestPercent": 0.57595,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 23
	},
	{
		"id": "3b94865",
		"email": "shamusfoh@mac.com",
		"name": "Shamus",
		"createdDate": "2011-11-29 19:00:48",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 133,
		"totalCountries": 1,
		"totalStates": 26,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 634,
		"schedulePercent": 0.355847,
		"totalGuests": 27,
		"guestPercent": 0.38379,
		"mostGuestDate": "2023-04-08 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 12
	},
	{
		"id": "1e7cf87",
		"email": "alexbarneyshows@gmail.com",
		"name": "Alex",
		"createdDate": "2023-09-16 20:41:33",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 105,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 120,
		"schedulePercent": 0.102635,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0d7f221",
		"email": "david@lemontreemusic.com.au",
		"name": "David",
		"createdDate": "2016-11-14 05:26:12",
		"totalOrganizations": 13,
		"totalTours": 21,
		"totalDays": 1234,
		"totalCountries": 21,
		"totalStates": 66,
		"totalEvents": 198,
		"eventPercent": 0.802543,
		"totalScheduleItems": 3430,
		"schedulePercent": 0.889154,
		"totalGuests": 344,
		"guestPercent": 0.624685,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "Kings Theatre",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 34
	},
	{
		"id": "38186c0",
		"email": "bigmusicuk20@mac.com",
		"name": "Graeme",
		"createdDate": "2010-10-14 00:00:21",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 254,
		"totalCountries": 2,
		"totalStates": 40,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 1467,
		"schedulePercent": 0.606807,
		"totalGuests": 813,
		"guestPercent": 0.779235,
		"mostGuestDate": "2023-02-14 00:00:00",
		"mostGuestName": "Eccles Theater",
		"mostGuestCity": "Salt Lake City",
		"mostGuestAmount": 76
	},
	{
		"id": "5118150",
		"email": "Daughtrytm1@mac.com",
		"name": "Jon",
		"createdDate": "2009-07-09 21:43:59",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 359,
		"totalCountries": 5,
		"totalStates": 41,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 1758,
		"schedulePercent": 0.675805,
		"totalGuests": 555,
		"guestPercent": 0.711296,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "del Lago Resort & Casino",
		"mostGuestCity": "Waterloo",
		"mostGuestAmount": 39
	},
	{
		"id": "01e871d",
		"email": "halle@vectormgmt.com",
		"name": "Halle",
		"createdDate": "2022-08-16 22:49:07",
		"totalOrganizations": 4,
		"totalTours": 13,
		"totalDays": 323,
		"totalCountries": 8,
		"totalStates": 48,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 1404,
		"schedulePercent": 0.592372,
		"totalGuests": 67,
		"guestPercent": 0.443253,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Malahide Castle & Gardens",
		"mostGuestCity": "Dublin",
		"mostGuestAmount": 14
	},
	{
		"id": "e9f45d0",
		"email": "rvicens@synergytechpr.com",
		"name": "Richard",
		"createdDate": "2022-03-30 17:26:41",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 71,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 151,
		"schedulePercent": 0.121308,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fee2776",
		"email": "dubray.cory@gmail.com",
		"name": "Cory",
		"createdDate": "2019-07-29 19:47:38",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 532,
		"totalCountries": 17,
		"totalStates": 65,
		"totalEvents": 189,
		"eventPercent": 0.78718,
		"totalScheduleItems": 2493,
		"schedulePercent": 0.792478,
		"totalGuests": 75,
		"guestPercent": 0.450007,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 49
	},
	{
		"id": "0d98f37",
		"email": "dennis@menace.org.uk",
		"name": "Dennis",
		"createdDate": "2013-09-25 00:10:08",
		"totalOrganizations": 10,
		"totalTours": 2,
		"totalDays": 85,
		"totalCountries": 9,
		"totalStates": 17,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 551,
		"schedulePercent": 0.322077,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8f1d27a",
		"email": "marcus@onlyhelix.com",
		"name": "Marcus",
		"createdDate": "2018-08-16 12:15:52",
		"totalOrganizations": 12,
		"totalTours": 22,
		"totalDays": 1246,
		"totalCountries": 25,
		"totalStates": 86,
		"totalEvents": 288,
		"eventPercent": 0.907694,
		"totalScheduleItems": 4003,
		"schedulePercent": 0.923454,
		"totalGuests": 210,
		"guestPercent": 0.557542,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Oxford Art Factory",
		"mostGuestCity": "Darlinghurst",
		"mostGuestAmount": 46
	},
	{
		"id": "dd50cd6",
		"email": "jocelyn.sw.chan@gmail.com",
		"name": "jocelyn",
		"createdDate": "2015-02-12 14:42:27",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 381,
		"totalCountries": 12,
		"totalStates": 51,
		"totalEvents": 170,
		"eventPercent": 0.752086,
		"totalScheduleItems": 2045,
		"schedulePercent": 0.726659,
		"totalGuests": 1250,
		"guestPercent": 0.865051,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Buffalo Outer Harbor Concert Site",
		"mostGuestCity": "Buffalo",
		"mostGuestAmount": 131
	},
	{
		"id": "7793e96",
		"email": "whitfredlund@gmail.com",
		"name": "Whit",
		"createdDate": "2019-07-27 06:59:15",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 415,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 1146,
		"schedulePercent": 0.525493,
		"totalGuests": 37,
		"guestPercent": 0.400212,
		"mostGuestDate": "2023-09-12 00:00:00",
		"mostGuestName": "City Winery - Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 14
	},
	{
		"id": "b52e425",
		"email": "lalmonte@rimasmusic.com",
		"name": "Lisette ",
		"createdDate": "2022-12-20 17:09:16",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 640,
		"totalCountries": 6,
		"totalStates": 24,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 2071,
		"schedulePercent": 0.72997,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "62ec52f",
		"email": "rcmmtequila@gmail.com",
		"name": "Greg",
		"createdDate": "2017-02-27 22:46:17",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 75,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 394,
		"schedulePercent": 0.255066,
		"totalGuests": 38,
		"guestPercent": 0.402198,
		"mostGuestDate": "2023-09-22 00:00:00",
		"mostGuestName": "Belly Up",
		"mostGuestCity": "Solana Beach",
		"mostGuestAmount": 14
	},
	{
		"id": "e32472b",
		"email": "tom.farmer@aco.com.au",
		"name": "Email",
		"createdDate": "2020-09-02 02:56:23",
		"totalOrganizations": 1,
		"totalTours": 16,
		"totalDays": 386,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 1,
		"eventPercent": 0.00662164,
		"totalScheduleItems": 1313,
		"schedulePercent": 0.569726,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2924e8f",
		"email": "GoForMo.Production@gmail.com",
		"name": "Mo",
		"createdDate": "2021-05-05 00:21:41",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 161,
		"totalCountries": 3,
		"totalStates": 21,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 1219,
		"schedulePercent": 0.545093,
		"totalGuests": 695,
		"guestPercent": 0.751689,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "Palacio de los Deportes",
		"mostGuestCity": "Ciudad de MÃ©xico",
		"mostGuestAmount": 79
	},
	{
		"id": "e49b58e",
		"email": "queenblk213@gmail.com",
		"name": "Tasha",
		"createdDate": "2018-08-18 23:44:36",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 657,
		"totalCountries": 9,
		"totalStates": 41,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 1827,
		"schedulePercent": 0.690769,
		"totalGuests": 1078,
		"guestPercent": 0.836313,
		"mostGuestDate": "2023-08-27 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 151
	},
	{
		"id": "9bb4bbf",
		"email": "joel@houseofhazemusic.com",
		"name": "Joel",
		"createdDate": "2022-07-19 19:27:07",
		"totalOrganizations": 13,
		"totalTours": 6,
		"totalDays": 49,
		"totalCountries": 3,
		"totalStates": 12,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 270,
		"schedulePercent": 0.19216,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5234fc4",
		"email": "blake@e1fx.com",
		"name": "Blake ",
		"createdDate": "2019-11-22 19:58:00",
		"totalOrganizations": 44,
		"totalTours": 25,
		"totalDays": 674,
		"totalCountries": 3,
		"totalStates": 40,
		"totalEvents": 450,
		"eventPercent": 0.967554,
		"totalScheduleItems": 684,
		"schedulePercent": 0.374388,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-01-08 00:00:00",
		"mostGuestName": "TIAA Bank Field",
		"mostGuestCity": "Jacksonville",
		"mostGuestAmount": 0
	},
	{
		"id": "dbd9549",
		"email": "matthartless@gmail.com",
		"name": "Matt",
		"createdDate": "2022-06-01 16:54:47",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 365,
		"totalCountries": 5,
		"totalStates": 49,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 2248,
		"schedulePercent": 0.757648,
		"totalGuests": 586,
		"guestPercent": 0.720832,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "Mohegan Sun Arena",
		"mostGuestCity": "Uncasville",
		"mostGuestAmount": 42
	},
	{
		"id": "cfe99cd",
		"email": "leah@flysouthmusic.com",
		"name": "Leah ",
		"createdDate": "2022-09-19 20:02:22",
		"totalOrganizations": 4,
		"totalTours": 10,
		"totalDays": 450,
		"totalCountries": 6,
		"totalStates": 46,
		"totalEvents": 195,
		"eventPercent": 0.796318,
		"totalScheduleItems": 3321,
		"schedulePercent": 0.880016,
		"totalGuests": 2234,
		"guestPercent": 0.945438,
		"mostGuestDate": "2023-08-04 00:00:00",
		"mostGuestName": "Sunset Cove Amphitheater",
		"mostGuestCity": "Boca Raton",
		"mostGuestAmount": 132
	},
	{
		"id": "f9b2304",
		"email": "ryan@sixshooterrecords.com",
		"name": "Ryan",
		"createdDate": "2022-05-19 19:23:01",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 602,
		"totalCountries": 11,
		"totalStates": 47,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 1644,
		"schedulePercent": 0.651967,
		"totalGuests": 711,
		"guestPercent": 0.755397,
		"mostGuestDate": "2023-01-23 00:00:00",
		"mostGuestName": "The Enmore Theatre",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 47
	},
	{
		"id": "117bd52",
		"email": "dlhmgmt@gmail.com",
		"name": "Douglas",
		"createdDate": "2011-04-19 22:25:10",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 396,
		"totalCountries": 2,
		"totalStates": 33,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1121,
		"schedulePercent": 0.516885,
		"totalGuests": 197,
		"guestPercent": 0.548669,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "City Winery - Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 21
	},
	{
		"id": "d9a6216",
		"email": "unn@jubel.se",
		"name": "Unn",
		"createdDate": "2022-08-31 06:03:31",
		"totalOrganizations": 19,
		"totalTours": 37,
		"totalDays": 862,
		"totalCountries": 3,
		"totalStates": 15,
		"totalEvents": 379,
		"eventPercent": 0.951,
		"totalScheduleItems": 2461,
		"schedulePercent": 0.78771,
		"totalGuests": 405,
		"guestPercent": 0.655145,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Uppsala Konsert & Kongress",
		"mostGuestCity": "Uppsala",
		"mostGuestAmount": 40
	},
	{
		"id": "abee308",
		"email": "jenny@bondtheatrical.com",
		"name": "Jenny",
		"createdDate": "2022-07-08 22:29:30",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 827,
		"totalCountries": 1,
		"totalStates": 25,
		"totalEvents": 616,
		"eventPercent": 0.983578,
		"totalScheduleItems": 5699,
		"schedulePercent": 0.976295,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "314a0b3",
		"email": "Williecortes@me.com",
		"name": "William",
		"createdDate": "2014-09-26 23:06:33",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 241,
		"totalCountries": 7,
		"totalStates": 29,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 1157,
		"schedulePercent": 0.528937,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "14d0fbc",
		"email": "vanessa.taub@redlightmanagement.com",
		"name": "Vanessa",
		"createdDate": "2014-04-24 18:15:35",
		"totalOrganizations": 9,
		"totalTours": 4,
		"totalDays": 91,
		"totalCountries": 8,
		"totalStates": 32,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 641,
		"schedulePercent": 0.359025,
		"totalGuests": 122,
		"guestPercent": 0.492253,
		"mostGuestDate": "2023-08-29 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 39
	},
	{
		"id": "292458a",
		"email": "eric@nefeshmountain.com",
		"name": "Eric",
		"createdDate": "2019-10-22 14:53:35",
		"totalOrganizations": 1,
		"totalTours": 14,
		"totalDays": 69,
		"totalCountries": 1,
		"totalStates": 26,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 129,
		"schedulePercent": 0.108198,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "64835ee",
		"email": "egtmco@gmail.com",
		"name": "Eric",
		"createdDate": "2016-04-06 08:20:01",
		"totalOrganizations": 22,
		"totalTours": 9,
		"totalDays": 605,
		"totalCountries": 15,
		"totalStates": 61,
		"totalEvents": 191,
		"eventPercent": 0.790491,
		"totalScheduleItems": 2539,
		"schedulePercent": 0.797245,
		"totalGuests": 1832,
		"guestPercent": 0.923321,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Grand Garden Arena",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 161
	},
	{
		"id": "724035d",
		"email": "mathias.cobbaut@lobbycall.com",
		"name": "Mathias",
		"createdDate": "2019-05-10 22:28:42",
		"totalOrganizations": 64,
		"totalTours": 59,
		"totalDays": 1582,
		"totalCountries": 33,
		"totalStates": 135,
		"totalEvents": 579,
		"eventPercent": 0.981459,
		"totalScheduleItems": 7887,
		"schedulePercent": 0.993511,
		"totalGuests": 859,
		"guestPercent": 0.789829,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 51
	},
	{
		"id": "84f083c",
		"email": "captainmorgantouring@gmail.com",
		"name": "Benjamin",
		"createdDate": "2015-06-05 23:58:24",
		"totalOrganizations": 15,
		"totalTours": 22,
		"totalDays": 800,
		"totalCountries": 18,
		"totalStates": 59,
		"totalEvents": 244,
		"eventPercent": 0.865713,
		"totalScheduleItems": 2545,
		"schedulePercent": 0.79857,
		"totalGuests": 1902,
		"guestPercent": 0.929016,
		"mostGuestDate": "2023-05-19 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 194
	},
	{
		"id": "7d838b3",
		"email": "darrencoldtour@gmail.com",
		"name": "darren",
		"createdDate": "2023-02-14 19:37:02",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 95,
		"totalCountries": 1,
		"totalStates": 28,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 558,
		"schedulePercent": 0.32499,
		"totalGuests": 331,
		"guestPercent": 0.619256,
		"mostGuestDate": "2023-04-15 00:00:00",
		"mostGuestName": "Madison Live",
		"mostGuestCity": "Covington",
		"mostGuestAmount": 24
	},
	{
		"id": "4ef59c4",
		"email": "matt@builtmusic.com",
		"name": "Matt",
		"createdDate": "2019-10-08 01:18:29",
		"totalOrganizations": 9,
		"totalTours": 8,
		"totalDays": 1166,
		"totalCountries": 17,
		"totalStates": 64,
		"totalEvents": 315,
		"eventPercent": 0.923057,
		"totalScheduleItems": 3476,
		"schedulePercent": 0.892465,
		"totalGuests": 1037,
		"guestPercent": 0.830486,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 160
	},
	{
		"id": "127fe52",
		"email": "beckmann.tm@gmail.com",
		"name": "William",
		"createdDate": "2023-01-17 17:20:51",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 338,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 974,
		"schedulePercent": 0.473977,
		"totalGuests": 380,
		"guestPercent": 0.645477,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Gruene Hall",
		"mostGuestCity": "New Braunfels",
		"mostGuestAmount": 33
	},
	{
		"id": "a35bd69",
		"email": "info@abstractentertainment.net",
		"name": "Kerrie",
		"createdDate": "2013-08-10 04:25:24",
		"totalOrganizations": 65,
		"totalTours": 14,
		"totalDays": 324,
		"totalCountries": 3,
		"totalStates": 8,
		"totalEvents": 134,
		"eventPercent": 0.658721,
		"totalScheduleItems": 1279,
		"schedulePercent": 0.559926,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "488cd51",
		"email": "moritz@heart-of-music.net",
		"name": "Moritz",
		"createdDate": "2021-12-01 20:20:23",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 191,
		"totalCountries": 13,
		"totalStates": 28,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 723,
		"schedulePercent": 0.38975,
		"totalGuests": 375,
		"guestPercent": 0.643358,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "Im Wizemann",
		"mostGuestCity": "Stuttgart",
		"mostGuestAmount": 44
	},
	{
		"id": "29a1c5f",
		"email": "shaun@tmelite.co.uk",
		"name": "Shaun",
		"createdDate": "2013-10-04 11:35:19",
		"totalOrganizations": 39,
		"totalTours": 26,
		"totalDays": 735,
		"totalCountries": 17,
		"totalStates": 52,
		"totalEvents": 218,
		"eventPercent": 0.834724,
		"totalScheduleItems": 1953,
		"schedulePercent": 0.712621,
		"totalGuests": 420,
		"guestPercent": 0.661237,
		"mostGuestDate": "2023-05-19 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 59
	},
	{
		"id": "1f94c06",
		"email": "paul@iconconcerts.com",
		"name": "Paul",
		"createdDate": "2010-12-22 21:57:15",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 460,
		"totalCountries": 8,
		"totalStates": 49,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 1866,
		"schedulePercent": 0.698583,
		"totalGuests": 158,
		"guestPercent": 0.520858,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Footprint Center",
		"mostGuestCity": "Phoenix",
		"mostGuestAmount": 38
	},
	{
		"id": "eca3fcd",
		"email": "patbojanic@me.com",
		"name": "Patrick",
		"createdDate": "2019-07-02 06:57:56",
		"totalOrganizations": 10,
		"totalTours": 10,
		"totalDays": 1134,
		"totalCountries": 5,
		"totalStates": 52,
		"totalEvents": 237,
		"eventPercent": 0.858429,
		"totalScheduleItems": 4127,
		"schedulePercent": 0.929148,
		"totalGuests": 2178,
		"guestPercent": 0.942392,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 137
	},
	{
		"id": "a132ac8",
		"email": "veronica.fuchs055@gmail.com",
		"name": "V",
		"createdDate": "2021-07-27 19:51:43",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 179,
		"totalCountries": 11,
		"totalStates": 42,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1432,
		"schedulePercent": 0.599656,
		"totalGuests": 1579,
		"guestPercent": 0.901338,
		"mostGuestDate": "2023-08-30 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 89
	},
	{
		"id": "8fc9373",
		"email": "booking@jakejacobsoncountry.com",
		"name": "Ronald",
		"createdDate": "2022-10-14 23:30:34",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 360,
		"totalCountries": 1,
		"totalStates": 15,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 120,
		"schedulePercent": 0.102635,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-02-22 00:00:00",
		"mostGuestName": "House of Blues Anaheim",
		"mostGuestCity": "Anaheim",
		"mostGuestAmount": 4
	},
	{
		"id": "4caf474",
		"email": "boydcauseymusic@yahoo.com",
		"name": "Boyd",
		"createdDate": "2017-01-08 16:49:39",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 905,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 381,
		"eventPercent": 0.952059,
		"totalScheduleItems": 3134,
		"schedulePercent": 0.864786,
		"totalGuests": 1273,
		"guestPercent": 0.868627,
		"mostGuestDate": "2023-11-21 00:00:00",
		"mostGuestName": "Wildhorse Saloon",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 283
	},
	{
		"id": "a7c3608",
		"email": "Patrick.Sabatini@wmg.com",
		"name": "Patrick",
		"createdDate": "2020-06-30 14:31:58",
		"totalOrganizations": 48,
		"totalTours": 44,
		"totalDays": 15011,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 1913,
		"eventPercent": 0.993908,
		"totalScheduleItems": 742,
		"schedulePercent": 0.397431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "82c15ba",
		"email": "perttu.korteniemi@deeraudio.eu",
		"name": "Perttu",
		"createdDate": "2019-08-22 16:19:26",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 159,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 868,
		"schedulePercent": 0.442061,
		"totalGuests": 1150,
		"guestPercent": 0.849292,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Helsinki",
		"mostGuestCity": "",
		"mostGuestAmount": 162
	},
	{
		"id": "d487942",
		"email": "production@meyerentgroup.com",
		"name": "Casey",
		"createdDate": "2019-09-22 03:54:08",
		"totalOrganizations": 1,
		"totalTours": 14,
		"totalDays": 62,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 281,
		"schedulePercent": 0.198384,
		"totalGuests": 34,
		"guestPercent": 0.395842,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "The Carolina Opry",
		"mostGuestCity": "Myrtle Beach",
		"mostGuestAmount": 12
	},
	{
		"id": "2708421",
		"email": "Salgado.tour@gmail.com",
		"name": "Mike",
		"createdDate": "2014-05-10 18:26:26",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 153,
		"totalCountries": 5,
		"totalStates": 26,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 387,
		"schedulePercent": 0.251622,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4f924e1",
		"email": "noresolvetm@gmail.com",
		"name": "No",
		"createdDate": "2023-03-08 17:26:49",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 55,
		"totalCountries": 1,
		"totalStates": 29,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 481,
		"schedulePercent": 0.289101,
		"totalGuests": 74,
		"guestPercent": 0.449212,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "The Riff",
		"mostGuestCity": "Springfield",
		"mostGuestAmount": 18
	},
	{
		"id": "288f028",
		"email": "brian@envisionmanagement.com",
		"name": "Brian",
		"createdDate": "2022-04-26 16:08:02",
		"totalOrganizations": 12,
		"totalTours": 18,
		"totalDays": 389,
		"totalCountries": 11,
		"totalStates": 45,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 1268,
		"schedulePercent": 0.556747,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "87104fd",
		"email": "reiley09@hotmail.com",
		"name": "Reiley",
		"createdDate": "2022-05-09 18:45:24",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 687,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 771,
		"schedulePercent": 0.409085,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "Waterfront Park",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 3
	},
	{
		"id": "f7e6446",
		"email": "mel.grinberg@gmail.com",
		"name": "Melanie",
		"createdDate": "2018-01-26 18:41:02",
		"totalOrganizations": 9,
		"totalTours": 8,
		"totalDays": 357,
		"totalCountries": 3,
		"totalStates": 42,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 1973,
		"schedulePercent": 0.714607,
		"totalGuests": 666,
		"guestPercent": 0.742948,
		"mostGuestDate": "2023-04-19 00:00:00",
		"mostGuestName": "The Mill & Mine",
		"mostGuestCity": "Knoxville",
		"mostGuestAmount": 49
	},
	{
		"id": "3a3fc78",
		"email": "btessier@herve.ca",
		"name": "Brigitte",
		"createdDate": "2018-02-05 15:16:16",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 573,
		"totalCountries": 4,
		"totalStates": 18,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 1142,
		"schedulePercent": 0.524169,
		"totalGuests": 91,
		"guestPercent": 0.465236,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "ZENITH PYRENEES",
		"mostGuestCity": "Pau",
		"mostGuestAmount": 16
	},
	{
		"id": "ef93470",
		"email": "david@deadstockstudios.la",
		"name": "David",
		"createdDate": "2021-09-20 17:08:23",
		"totalOrganizations": 8,
		"totalTours": 11,
		"totalDays": 709,
		"totalCountries": 12,
		"totalStates": 43,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1191,
		"schedulePercent": 0.538074,
		"totalGuests": 197,
		"guestPercent": 0.548669,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "The Fillmore",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 45
	},
	{
		"id": "e27a5d3",
		"email": "Adamtmcintosh@gmail.com",
		"name": "Adam",
		"createdDate": "2018-09-18 03:18:07",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 179,
		"totalCountries": 8,
		"totalStates": 28,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 873,
		"schedulePercent": 0.444709,
		"totalGuests": 44,
		"guestPercent": 0.411469,
		"mostGuestDate": "2023-06-04 00:00:00",
		"mostGuestName": "Hyatt Regency Newport Beach",
		"mostGuestCity": "Newport Beach",
		"mostGuestAmount": 14
	},
	{
		"id": "1676169",
		"email": "brendon@chippersoncss.com",
		"name": "Brendon",
		"createdDate": "2022-09-15 03:28:18",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 136,
		"totalCountries": 1,
		"totalStates": 24,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 615,
		"schedulePercent": 0.347504,
		"totalGuests": 321,
		"guestPercent": 0.614621,
		"mostGuestDate": "2023-03-29 00:00:00",
		"mostGuestName": "40 Watt Club",
		"mostGuestCity": "Athens",
		"mostGuestAmount": 42
	},
	{
		"id": "f375e82",
		"email": "shelby@goldve.com",
		"name": "Maggie",
		"createdDate": "2017-01-09 18:14:37",
		"totalOrganizations": 10,
		"totalTours": 13,
		"totalDays": 1737,
		"totalCountries": 16,
		"totalStates": 83,
		"totalEvents": 314,
		"eventPercent": 0.922527,
		"totalScheduleItems": 3375,
		"schedulePercent": 0.885578,
		"totalGuests": 1757,
		"guestPercent": 0.917362,
		"mostGuestDate": "2023-01-04 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 94
	},
	{
		"id": "0d14d39",
		"email": "tourdirector@mandarins.org",
		"name": "Keith",
		"createdDate": "2017-05-03 23:18:13",
		"totalOrganizations": 7,
		"totalTours": 2,
		"totalDays": 292,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 594,
		"schedulePercent": 0.337571,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1461a48",
		"email": "aja.pecknold@gmail.com",
		"name": "Aja",
		"createdDate": "2017-03-21 23:38:41",
		"totalOrganizations": 12,
		"totalTours": 15,
		"totalDays": 254,
		"totalCountries": 18,
		"totalStates": 61,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 1857,
		"schedulePercent": 0.696729,
		"totalGuests": 972,
		"guestPercent": 0.81764,
		"mostGuestDate": "2023-11-06 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 74
	},
	{
		"id": "0e59b4c",
		"email": "short.axel@gmail.com",
		"name": "Axel",
		"createdDate": "2023-01-13 18:53:22",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 64,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 171,
		"schedulePercent": 0.13336,
		"totalGuests": 11,
		"guestPercent": 0.345782,
		"mostGuestDate": "2023-01-20 00:00:00",
		"mostGuestName": "Richmond Music Hall",
		"mostGuestCity": "Richmond",
		"mostGuestAmount": 6
	},
	{
		"id": "f70d9eb",
		"email": "eric@ezservices.co",
		"name": "Eric",
		"createdDate": "2016-09-03 11:36:04",
		"totalOrganizations": 26,
		"totalTours": 23,
		"totalDays": 693,
		"totalCountries": 15,
		"totalStates": 57,
		"totalEvents": 232,
		"eventPercent": 0.852073,
		"totalScheduleItems": 3164,
		"schedulePercent": 0.866905,
		"totalGuests": 545,
		"guestPercent": 0.707324,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 53
	},
	{
		"id": "35057b2",
		"email": "antonia.markert@blacksheep-management.de",
		"name": "Toni",
		"createdDate": "2023-03-24 15:25:09",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 72,
		"totalCountries": 8,
		"totalStates": 14,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 606,
		"schedulePercent": 0.343796,
		"totalGuests": 768,
		"guestPercent": 0.769037,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "Arena Leipzig",
		"mostGuestCity": "Leipzig",
		"mostGuestAmount": 143
	},
	{
		"id": "6a5b9f9",
		"email": "john@410mgmt.com",
		"name": "John",
		"createdDate": "2019-06-05 20:19:41",
		"totalOrganizations": 23,
		"totalTours": 25,
		"totalDays": 1204,
		"totalCountries": 17,
		"totalStates": 82,
		"totalEvents": 417,
		"eventPercent": 0.961727,
		"totalScheduleItems": 4362,
		"schedulePercent": 0.94014,
		"totalGuests": 1855,
		"guestPercent": 0.925308,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Buffalo Outer Harbor Concert Site",
		"mostGuestCity": "Buffalo",
		"mostGuestAmount": 131
	},
	{
		"id": "c4115a7",
		"email": "alanhunt10@hotmail.com",
		"name": "Alan",
		"createdDate": "2014-01-22 23:10:19",
		"totalOrganizations": 3,
		"totalTours": 10,
		"totalDays": 142,
		"totalCountries": 8,
		"totalStates": 38,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 1005,
		"schedulePercent": 0.486426,
		"totalGuests": 783,
		"guestPercent": 0.772613,
		"mostGuestDate": "2023-01-31 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 53
	},
	{
		"id": "5041653",
		"email": "MindiPelletier@gmail.com",
		"name": "Mindi",
		"createdDate": "2011-05-23 15:58:48",
		"totalOrganizations": 12,
		"totalTours": 13,
		"totalDays": 906,
		"totalCountries": 25,
		"totalStates": 77,
		"totalEvents": 224,
		"eventPercent": 0.841345,
		"totalScheduleItems": 4423,
		"schedulePercent": 0.942127,
		"totalGuests": 1552,
		"guestPercent": 0.899086,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 98
	},
	{
		"id": "f84a91d",
		"email": "whitney@alternateside.co",
		"name": "Alternate",
		"createdDate": "2020-02-10 19:13:16",
		"totalOrganizations": 23,
		"totalTours": 26,
		"totalDays": 490,
		"totalCountries": 9,
		"totalStates": 48,
		"totalEvents": 226,
		"eventPercent": 0.843464,
		"totalScheduleItems": 2956,
		"schedulePercent": 0.847835,
		"totalGuests": 974,
		"guestPercent": 0.818037,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "The Regent Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 47
	},
	{
		"id": "66b0a97",
		"email": "poppyjeancrawford@gmail.com",
		"name": "Poppy",
		"createdDate": "2023-04-11 03:09:54",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 54,
		"totalCountries": 6,
		"totalStates": 21,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 196,
		"schedulePercent": 0.147663,
		"totalGuests": 41,
		"guestPercent": 0.407231,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Chelsea",
		"mostGuestCity": "Wien",
		"mostGuestAmount": 11
	},
	{
		"id": "be5d6eb",
		"email": "marissa@crushmusic.com",
		"name": "Marissa",
		"createdDate": "2019-09-26 17:42:50",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 347,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 1503,
		"schedulePercent": 0.618858,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Credit One Stadium",
		"mostGuestCity": "Charleston",
		"mostGuestAmount": 4
	},
	{
		"id": "c87175a",
		"email": "samiasalfity@gmail.com",
		"name": "Samia",
		"createdDate": "2015-08-03 17:20:00",
		"totalOrganizations": 6,
		"totalTours": 14,
		"totalDays": 395,
		"totalCountries": 8,
		"totalStates": 48,
		"totalEvents": 168,
		"eventPercent": 0.747451,
		"totalScheduleItems": 3361,
		"schedulePercent": 0.883989,
		"totalGuests": 141,
		"guestPercent": 0.506026,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 41
	},
	{
		"id": "f818a72",
		"email": "liam@howlaroundeg.com",
		"name": "Liam",
		"createdDate": "2012-12-20 01:38:42",
		"totalOrganizations": 30,
		"totalTours": 24,
		"totalDays": 2849,
		"totalCountries": 22,
		"totalStates": 76,
		"totalEvents": 325,
		"eventPercent": 0.928751,
		"totalScheduleItems": 5518,
		"schedulePercent": 0.973381,
		"totalGuests": 3293,
		"guestPercent": 0.97444,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 137
	},
	{
		"id": "8bc0334",
		"email": "Larryd73@aol.com",
		"name": "Larry",
		"createdDate": "2021-04-15 20:34:56",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 375,
		"totalCountries": 6,
		"totalStates": 40,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 1384,
		"schedulePercent": 0.587604,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Concerthouse",
		"mostGuestCity": "GÃ¶teborg",
		"mostGuestAmount": 2
	},
	{
		"id": "c4578a0",
		"email": "tjpsound@gmail.com",
		"name": "Thomas",
		"createdDate": "2017-11-07 12:31:54",
		"totalOrganizations": 16,
		"totalTours": 17,
		"totalDays": 421,
		"totalCountries": 8,
		"totalStates": 45,
		"totalEvents": 231,
		"eventPercent": 0.850616,
		"totalScheduleItems": 2903,
		"schedulePercent": 0.842537,
		"totalGuests": 1333,
		"guestPercent": 0.875646,
		"mostGuestDate": "2023-01-22 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 133
	},
	{
		"id": "3faef1f",
		"email": "mastertour@oakridgeboys.com",
		"name": "oakridgeboys",
		"createdDate": "2016-01-08 21:46:09",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 366,
		"totalCountries": 3,
		"totalStates": 27,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 454,
		"schedulePercent": 0.278109,
		"totalGuests": 552,
		"guestPercent": 0.710369,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Stride Bank Center",
		"mostGuestCity": "Enid",
		"mostGuestAmount": 25
	},
	{
		"id": "77721a6",
		"email": "devindfoley@gmail.com",
		"name": "Devin ",
		"createdDate": "2015-03-02 13:02:18",
		"totalOrganizations": 14,
		"totalTours": 10,
		"totalDays": 1492,
		"totalCountries": 10,
		"totalStates": 40,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 1238,
		"schedulePercent": 0.549331,
		"totalGuests": 512,
		"guestPercent": 0.695934,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 95
	},
	{
		"id": "a5cbca1",
		"email": "caleb.grizzell@me.com",
		"name": "Caleb",
		"createdDate": "2021-12-04 23:16:51",
		"totalOrganizations": 10,
		"totalTours": 14,
		"totalDays": 444,
		"totalCountries": 14,
		"totalStates": 56,
		"totalEvents": 249,
		"eventPercent": 0.870746,
		"totalScheduleItems": 1935,
		"schedulePercent": 0.708118,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Avondale Music Hall",
		"mostGuestCity": "Chicago ",
		"mostGuestAmount": 1
	},
	{
		"id": "d402d02",
		"email": "tvshowbass@gmail.com",
		"name": "Jonathan",
		"createdDate": "2014-01-27 23:15:09",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 317,
		"totalCountries": 10,
		"totalStates": 43,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 1345,
		"schedulePercent": 0.579129,
		"totalGuests": 514,
		"guestPercent": 0.696597,
		"mostGuestDate": "2023-06-14 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 138
	},
	{
		"id": "503696c",
		"email": "mary.lattimore@gmail.com",
		"name": "Mary",
		"createdDate": "2017-01-24 01:22:31",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 83,
		"totalCountries": 4,
		"totalStates": 20,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 197,
		"schedulePercent": 0.148325,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-10-16 00:00:00",
		"mostGuestName": "Polaris Hall",
		"mostGuestCity": "Portland",
		"mostGuestAmount": 2
	},
	{
		"id": "88242cc",
		"email": "nickbrumme@gmail.com",
		"name": "Nick",
		"createdDate": "2018-10-15 01:24:46",
		"totalOrganizations": 5,
		"totalTours": 21,
		"totalDays": 338,
		"totalCountries": 7,
		"totalStates": 52,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 1683,
		"schedulePercent": 0.660575,
		"totalGuests": 803,
		"guestPercent": 0.77738,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Waterfront Park",
		"mostGuestCity": "Burlington",
		"mostGuestAmount": 78
	},
	{
		"id": "6641564",
		"email": "afried90@gmail.com",
		"name": "Alex",
		"createdDate": "2019-01-24 19:42:43",
		"totalOrganizations": 12,
		"totalTours": 8,
		"totalDays": 825,
		"totalCountries": 4,
		"totalStates": 41,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 1521,
		"schedulePercent": 0.623361,
		"totalGuests": 798,
		"guestPercent": 0.775659,
		"mostGuestDate": "2023-01-12 00:00:00",
		"mostGuestName": "Riverside Revival Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 149
	},
	{
		"id": "cee92bc",
		"email": "dawnchorustouring@gmail.com",
		"name": "Joel",
		"createdDate": "2019-08-06 14:25:29",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 79,
		"totalCountries": 9,
		"totalStates": 16,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 443,
		"schedulePercent": 0.274136,
		"totalGuests": 20,
		"guestPercent": 0.36909,
		"mostGuestDate": "2023-04-06 00:00:00",
		"mostGuestName": "Village Underground",
		"mostGuestCity": "London",
		"mostGuestAmount": 20
	},
	{
		"id": "a1b81fb",
		"email": "cassie@directartistmanagement.com",
		"name": "Cassie",
		"createdDate": "2023-07-14 17:59:59",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 50,
		"totalCountries": 1,
		"totalStates": 22,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 371,
		"schedulePercent": 0.244074,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "20e5d2a",
		"email": "tfe.mgmt@gmail.com",
		"name": "Henry-Francois",
		"createdDate": "2019-10-05 20:56:39",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 680,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 494,
		"schedulePercent": 0.295193,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "La Petite Ã‰glise",
		"mostGuestCity": "Saint-Eustache",
		"mostGuestAmount": 3
	},
	{
		"id": "2052ea1",
		"email": "jarredaharris@gmail.com",
		"name": "Jarred",
		"createdDate": "2023-01-11 18:15:24",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 664,
		"totalCountries": 4,
		"totalStates": 53,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 1413,
		"schedulePercent": 0.594358,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ceba736",
		"email": "bonseano@me.com",
		"name": "Sean",
		"createdDate": "2010-07-08 03:17:02",
		"totalOrganizations": 28,
		"totalTours": 29,
		"totalDays": 1049,
		"totalCountries": 11,
		"totalStates": 36,
		"totalEvents": 257,
		"eventPercent": 0.882929,
		"totalScheduleItems": 3173,
		"schedulePercent": 0.867832,
		"totalGuests": 642,
		"guestPercent": 0.736591,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 207
	},
	{
		"id": "fa7a433",
		"email": "h2middleton@gmail.com",
		"name": "Hannah",
		"createdDate": "2017-10-14 21:30:21",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 689,
		"totalCountries": 6,
		"totalStates": 43,
		"totalEvents": 170,
		"eventPercent": 0.752086,
		"totalScheduleItems": 2401,
		"schedulePercent": 0.780824,
		"totalGuests": 514,
		"guestPercent": 0.696597,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 139
	},
	{
		"id": "1beeeed",
		"email": "slowmusicvideo@gmail.com",
		"name": "Ronnie",
		"createdDate": "2023-10-02 18:05:20",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 28,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 13,
		"eventPercent": 0.0892597,
		"totalScheduleItems": 208,
		"schedulePercent": 0.155344,
		"totalGuests": 161,
		"guestPercent": 0.523374,
		"mostGuestDate": "2023-10-22 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 34
	},
	{
		"id": "82310f6",
		"email": "scott@mfmgt.com",
		"name": "Scott",
		"createdDate": "2019-10-22 00:25:55",
		"totalOrganizations": 6,
		"totalTours": 18,
		"totalDays": 556,
		"totalCountries": 21,
		"totalStates": 67,
		"totalEvents": 223,
		"eventPercent": 0.840154,
		"totalScheduleItems": 2733,
		"schedulePercent": 0.823864,
		"totalGuests": 1843,
		"guestPercent": 0.924778,
		"mostGuestDate": "2023-11-20 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 62
	},
	{
		"id": "ca897fa",
		"email": "roadworks@earthlink.net",
		"name": "Chuck",
		"createdDate": "2010-11-07 00:52:41",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 129,
		"totalCountries": 7,
		"totalStates": 19,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 714,
		"schedulePercent": 0.386174,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1b156be",
		"email": "lukejohnston@mail.com",
		"name": "Luke",
		"createdDate": "2012-09-24 16:33:20",
		"totalOrganizations": 49,
		"totalTours": 35,
		"totalDays": 663,
		"totalCountries": 17,
		"totalStates": 64,
		"totalEvents": 277,
		"eventPercent": 0.899616,
		"totalScheduleItems": 3353,
		"schedulePercent": 0.883459,
		"totalGuests": 1453,
		"guestPercent": 0.887962,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Manning Bar",
		"mostGuestCity": "Camperdown",
		"mostGuestAmount": 63
	},
	{
		"id": "f8a3e29",
		"email": "daniela.jagemann@allartists.agency",
		"name": "Daniela",
		"createdDate": "2020-03-04 17:11:40",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 52,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 305,
		"schedulePercent": 0.210171,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a1e6bf1",
		"email": "haileytransue@gmail.com",
		"name": "Hailey",
		"createdDate": "2022-05-24 15:33:29",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 768,
		"totalCountries": 3,
		"totalStates": 34,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 1674,
		"schedulePercent": 0.658721,
		"totalGuests": 438,
		"guestPercent": 0.667461,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 75
	},
	{
		"id": "278d6cb",
		"email": "Michael.Chase@wmg.com",
		"name": "Michael",
		"createdDate": "2020-04-28 23:11:32",
		"totalOrganizations": 52,
		"totalTours": 44,
		"totalDays": 15011,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 1913,
		"eventPercent": 0.993908,
		"totalScheduleItems": 742,
		"schedulePercent": 0.397431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e0a5fb5",
		"email": "dom@8185group.com",
		"name": "Dominick",
		"createdDate": "2019-01-18 07:54:30",
		"totalOrganizations": 21,
		"totalTours": 29,
		"totalDays": 1318,
		"totalCountries": 22,
		"totalStates": 75,
		"totalEvents": 444,
		"eventPercent": 0.967024,
		"totalScheduleItems": 5611,
		"schedulePercent": 0.97497,
		"totalGuests": 3473,
		"guestPercent": 0.976427,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 86
	},
	{
		"id": "9a5b666",
		"email": "spiv022@gmail.com",
		"name": "Genevieve",
		"createdDate": "2023-02-03 20:26:50",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 159,
		"totalCountries": 3,
		"totalStates": 22,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 415,
		"schedulePercent": 0.262747,
		"totalGuests": 97,
		"guestPercent": 0.471461,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "The Mission Ballroom",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 82
	},
	{
		"id": "2d5a364",
		"email": "therebeccacole@gmail.com",
		"name": "Rebecca",
		"createdDate": "2012-09-14 00:12:47",
		"totalOrganizations": 12,
		"totalTours": 14,
		"totalDays": 136,
		"totalCountries": 8,
		"totalStates": 30,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 781,
		"schedulePercent": 0.413058,
		"totalGuests": 438,
		"guestPercent": 0.667461,
		"mostGuestDate": "2023-03-07 00:00:00",
		"mostGuestName": "The Civic",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 76
	},
	{
		"id": "ab681f2",
		"email": "aneesh@silkroad.org",
		"name": "Aneesh",
		"createdDate": "2022-03-22 17:43:00",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 33,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 13,
		"eventPercent": 0.0892597,
		"totalScheduleItems": 198,
		"schedulePercent": 0.148987,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ce3bdd0",
		"email": "lori@inalandscape.org",
		"name": "Lori",
		"createdDate": "2021-07-21 13:53:33",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 76,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 611,
		"schedulePercent": 0.346709,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b5442ec",
		"email": "info@cardiffgiantmgmt.com",
		"name": "Cardiff",
		"createdDate": "2019-10-21 13:45:46",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 383,
		"totalCountries": 16,
		"totalStates": 49,
		"totalEvents": 198,
		"eventPercent": 0.802543,
		"totalScheduleItems": 1489,
		"schedulePercent": 0.615415,
		"totalGuests": 146,
		"guestPercent": 0.510528,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "Manchester Academy",
		"mostGuestCity": "Manchester",
		"mostGuestAmount": 55
	},
	{
		"id": "70e9d7d",
		"email": "captainmac500@gmail.com",
		"name": "Joseph",
		"createdDate": "2023-05-23 20:13:16",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 53,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 522,
		"schedulePercent": 0.308304,
		"totalGuests": 258,
		"guestPercent": 0.583234,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Hard Rock Live at Etess Arena",
		"mostGuestCity": "Atlantic City",
		"mostGuestAmount": 55
	},
	{
		"id": "a8000b3",
		"email": "Darci@stevedixon.com",
		"name": "Darci",
		"createdDate": "2021-07-23 16:47:37",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 670,
		"totalCountries": 2,
		"totalStates": 31,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 1427,
		"schedulePercent": 0.598464,
		"totalGuests": 298,
		"guestPercent": 0.60098,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 65
	},
	{
		"id": "68a8d74",
		"email": "jonb@eighteentwentysix.com",
		"name": "Jon",
		"createdDate": "2016-06-16 16:27:20",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 334,
		"totalCountries": 6,
		"totalStates": 38,
		"totalEvents": 168,
		"eventPercent": 0.747451,
		"totalScheduleItems": 2653,
		"schedulePercent": 0.814594,
		"totalGuests": 946,
		"guestPercent": 0.811416,
		"mostGuestDate": "2023-05-04 00:00:00",
		"mostGuestName": "Radio City Music Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 94
	},
	{
		"id": "d3d0ca1",
		"email": "gjohnson@cityparksfoundation.org",
		"name": "Glynn",
		"createdDate": "2022-03-09 17:49:18",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d34bbd6",
		"email": "pete@moombaproductions.co.uk",
		"name": "Pete",
		"createdDate": "2013-07-02 20:10:10",
		"totalOrganizations": 22,
		"totalTours": 18,
		"totalDays": 646,
		"totalCountries": 22,
		"totalStates": 65,
		"totalEvents": 352,
		"eventPercent": 0.942127,
		"totalScheduleItems": 5189,
		"schedulePercent": 0.967157,
		"totalGuests": 1786,
		"guestPercent": 0.919216,
		"mostGuestDate": "2023-05-04 00:00:00",
		"mostGuestName": "Radio City Music Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 94
	},
	{
		"id": "53b929c",
		"email": "danabollen@gmail.com",
		"name": "Dana",
		"createdDate": "2020-01-21 16:40:57",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 165,
		"totalCountries": 3,
		"totalStates": 41,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1151,
		"schedulePercent": 0.527347,
		"totalGuests": 1784,
		"guestPercent": 0.919084,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 88
	},
	{
		"id": "34ed0f0",
		"email": "johnny@duty-now.com",
		"name": "Johnny",
		"createdDate": "2022-01-04 22:38:07",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 112,
		"totalCountries": 6,
		"totalStates": 44,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 568,
		"schedulePercent": 0.327374,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "664e6c9",
		"email": "LLancaster@hahaha.com",
		"name": "Lee-Anne",
		"createdDate": "2013-09-09 04:30:25",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 162,
		"totalCountries": 4,
		"totalStates": 13,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 1390,
		"schedulePercent": 0.588531,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9ed2132",
		"email": "info@luckychops.com",
		"name": "Josh ",
		"createdDate": "2016-04-28 09:41:44",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 186,
		"totalCountries": 5,
		"totalStates": 28,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 854,
		"schedulePercent": 0.43875,
		"totalGuests": 159,
		"guestPercent": 0.521918,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "The Stone Pony",
		"mostGuestCity": "Asbury Park",
		"mostGuestAmount": 19
	},
	{
		"id": "4fd474d",
		"email": "plotkinthetm@gmail.com",
		"name": "Andrew",
		"createdDate": "2015-04-10 15:05:58",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 77,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 142,
		"schedulePercent": 0.114422,
		"totalGuests": 44,
		"guestPercent": 0.411469,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "Parker Playhouse",
		"mostGuestCity": "Fort Lauderdale",
		"mostGuestAmount": 20
	},
	{
		"id": "5f6bd1e",
		"email": "Dngaskill@gmail.com",
		"name": "Dora",
		"createdDate": "2018-01-06 01:09:35",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 80,
		"totalCountries": 10,
		"totalStates": 34,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 801,
		"schedulePercent": 0.420871,
		"totalGuests": 498,
		"guestPercent": 0.691034,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 50
	},
	{
		"id": "1d25052",
		"email": "sebastien.cote@quebecor.com",
		"name": "Sebastien",
		"createdDate": "2018-10-15 19:53:06",
		"totalOrganizations": 27,
		"totalTours": 14,
		"totalDays": 423,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 380,
		"eventPercent": 0.951794,
		"totalScheduleItems": 2485,
		"schedulePercent": 0.791418,
		"totalGuests": 4113,
		"guestPercent": 0.98477,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "ThÃ©Ã¢tre St-Denis",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 204
	},
	{
		"id": "77b7f54",
		"email": "charlie@vevents.org",
		"name": "Charlie",
		"createdDate": "2019-01-21 12:37:41",
		"totalOrganizations": 24,
		"totalTours": 25,
		"totalDays": 604,
		"totalCountries": 12,
		"totalStates": 28,
		"totalEvents": 142,
		"eventPercent": 0.680705,
		"totalScheduleItems": 1745,
		"schedulePercent": 0.673818,
		"totalGuests": 759,
		"guestPercent": 0.766653,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "London Palladium",
		"mostGuestCity": "London",
		"mostGuestAmount": 132
	},
	{
		"id": "a50334b",
		"email": "Kaseypercussion@gmail.com",
		"name": "Kasey",
		"createdDate": "2016-06-20 19:20:12",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 567,
		"totalCountries": 5,
		"totalStates": 32,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 853,
		"schedulePercent": 0.438485,
		"totalGuests": 171,
		"guestPercent": 0.530658,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "The Axis Club Theatre",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 41
	},
	{
		"id": "c3ac220",
		"email": "Jonathan.syverson@gmail.com",
		"name": "Jonathan",
		"createdDate": "2013-09-24 21:13:14",
		"totalOrganizations": 16,
		"totalTours": 20,
		"totalDays": 546,
		"totalCountries": 21,
		"totalStates": 93,
		"totalEvents": 329,
		"eventPercent": 0.930738,
		"totalScheduleItems": 4489,
		"schedulePercent": 0.944643,
		"totalGuests": 1460,
		"guestPercent": 0.888889,
		"mostGuestDate": "2023-05-11 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 87
	},
	{
		"id": "56294d5",
		"email": "earldriggers@icloud.com",
		"name": "Earl",
		"createdDate": "2018-01-24 21:26:56",
		"totalOrganizations": 6,
		"totalTours": 15,
		"totalDays": 193,
		"totalCountries": 4,
		"totalStates": 37,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 1548,
		"schedulePercent": 0.629453,
		"totalGuests": 382,
		"guestPercent": 0.646272,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Saban Theatre",
		"mostGuestCity": "Beverly Hills",
		"mostGuestAmount": 43
	},
	{
		"id": "80208d8",
		"email": "jacob@northsideartists.com",
		"name": "Jacob",
		"createdDate": "2021-10-08 14:48:03",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 365,
		"totalCountries": 5,
		"totalStates": 36,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 843,
		"schedulePercent": 0.435704,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "347bb82",
		"email": "Sonnypmusic@gmail.com",
		"name": "Sonny",
		"createdDate": "2015-09-16 15:56:59",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 117,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 343,
		"schedulePercent": 0.229638,
		"totalGuests": 157,
		"guestPercent": 0.520196,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Mayo Performing Arts Center",
		"mostGuestCity": "Morristown",
		"mostGuestAmount": 19
	},
	{
		"id": "16316f1",
		"email": "daveskelton@mac.com",
		"name": "Dave",
		"createdDate": "2012-02-23 17:18:26",
		"totalOrganizations": 13,
		"totalTours": 18,
		"totalDays": 297,
		"totalCountries": 16,
		"totalStates": 59,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 2130,
		"schedulePercent": 0.738843,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-03-21 00:00:00",
		"mostGuestName": "The Danforth Music Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 0
	},
	{
		"id": "7b6730f",
		"email": "kiefershackelford@gmail.com",
		"name": "Kiefer ",
		"createdDate": "2018-10-02 20:27:45",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 45,
		"totalCountries": 2,
		"totalStates": 17,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 237,
		"schedulePercent": 0.173355,
		"totalGuests": 28,
		"guestPercent": 0.386306,
		"mostGuestDate": "2023-09-19 00:00:00",
		"mostGuestName": "World Cafe Live - Philadelphia (UPSTAIRS)",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 8
	},
	{
		"id": "ead92a0",
		"email": "jaz@eqt.co",
		"name": "Jaz",
		"createdDate": "2022-04-05 20:20:08",
		"totalOrganizations": 3,
		"totalTours": 11,
		"totalDays": 89,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 179,
		"schedulePercent": 0.137598,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bd8d099",
		"email": "gadmgmt@yahoo.com",
		"name": "Gaius",
		"createdDate": "2010-09-01 01:05:15",
		"totalOrganizations": 2,
		"totalTours": 11,
		"totalDays": 152,
		"totalCountries": 11,
		"totalStates": 33,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 825,
		"schedulePercent": 0.428023,
		"totalGuests": 289,
		"guestPercent": 0.597802,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "Yaamava' Resort & Casino",
		"mostGuestCity": "Highland",
		"mostGuestAmount": 29
	},
	{
		"id": "cdb5f7f",
		"email": "breweryan@gmail.com",
		"name": "Ryan",
		"createdDate": "2017-04-01 22:55:51",
		"totalOrganizations": 8,
		"totalTours": 16,
		"totalDays": 346,
		"totalCountries": 8,
		"totalStates": 45,
		"totalEvents": 201,
		"eventPercent": 0.808237,
		"totalScheduleItems": 1786,
		"schedulePercent": 0.682294,
		"totalGuests": 801,
		"guestPercent": 0.776453,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "Factory Theatre",
		"mostGuestCity": "Marrickville",
		"mostGuestAmount": 102
	},
	{
		"id": "40c10f7",
		"email": "magnetic.recording@gmail.com",
		"name": "Jeremy",
		"createdDate": "2018-02-13 20:33:17",
		"totalOrganizations": 6,
		"totalTours": 27,
		"totalDays": 271,
		"totalCountries": 17,
		"totalStates": 55,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 1347,
		"schedulePercent": 0.579923,
		"totalGuests": 532,
		"guestPercent": 0.703218,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Finsbury Park",
		"mostGuestCity": "London",
		"mostGuestAmount": 60
	},
	{
		"id": "30886ad",
		"email": "produccion@33producciones.es",
		"name": "ProducciÃ³n",
		"createdDate": "2018-03-29 19:10:48",
		"totalOrganizations": 27,
		"totalTours": 22,
		"totalDays": 850,
		"totalCountries": 8,
		"totalStates": 47,
		"totalEvents": 208,
		"eventPercent": 0.819891,
		"totalScheduleItems": 2091,
		"schedulePercent": 0.73381,
		"totalGuests": 130,
		"guestPercent": 0.498874,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "SALA BUT",
		"mostGuestCity": "Madrid",
		"mostGuestAmount": 80
	},
	{
		"id": "ef2242b",
		"email": "saltnpep2022@gmail.com",
		"name": "Sandra",
		"createdDate": "2023-05-23 18:32:48",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 124,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 526,
		"schedulePercent": 0.310025,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "87db2bc",
		"email": "brett@cmgthelabel.com",
		"name": "Brett",
		"createdDate": "2023-01-20 03:15:16",
		"totalOrganizations": 2,
		"totalTours": 22,
		"totalDays": 141,
		"totalCountries": 4,
		"totalStates": 29,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 549,
		"schedulePercent": 0.320355,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0d0ebce",
		"email": "anna@thebeehive.la",
		"name": "Anna",
		"createdDate": "2022-06-06 18:09:30",
		"totalOrganizations": 18,
		"totalTours": 33,
		"totalDays": 966,
		"totalCountries": 16,
		"totalStates": 67,
		"totalEvents": 395,
		"eventPercent": 0.956032,
		"totalScheduleItems": 3263,
		"schedulePercent": 0.874851,
		"totalGuests": 1627,
		"guestPercent": 0.905708,
		"mostGuestDate": "2023-08-13 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 125
	},
	{
		"id": "5c6b5f7",
		"email": "jasonfeldkamp@icloud.com",
		"name": "Jason",
		"createdDate": "2013-10-08 18:27:54",
		"totalOrganizations": 13,
		"totalTours": 15,
		"totalDays": 1457,
		"totalCountries": 17,
		"totalStates": 65,
		"totalEvents": 307,
		"eventPercent": 0.919216,
		"totalScheduleItems": 5663,
		"schedulePercent": 0.975632,
		"totalGuests": 1424,
		"guestPercent": 0.884916,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 63
	},
	{
		"id": "73f1c36",
		"email": "PJ-17@HOTMAIL.CO.UK",
		"name": "PHILLIPPA",
		"createdDate": "2023-03-21 19:25:54",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 516,
		"totalCountries": 16,
		"totalStates": 48,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1701,
		"schedulePercent": 0.664945,
		"totalGuests": 602,
		"guestPercent": 0.726791,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 130
	},
	{
		"id": "9187471",
		"email": "b.herbote@msk-live.de",
		"name": "Britta",
		"createdDate": "2021-11-22 09:56:29",
		"totalOrganizations": 12,
		"totalTours": 11,
		"totalDays": 345,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 175,
		"eventPercent": 0.761753,
		"totalScheduleItems": 1979,
		"schedulePercent": 0.715932,
		"totalGuests": 371,
		"guestPercent": 0.641769,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "BonnLive Open Air",
		"mostGuestCity": "Bonn",
		"mostGuestAmount": 61
	},
	{
		"id": "72b8366",
		"email": "Philippe.lattoni@wanadoo.fr",
		"name": "Philippe",
		"createdDate": "2022-01-28 12:02:54",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 235,
		"totalCountries": 6,
		"totalStates": 21,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 1744,
		"schedulePercent": 0.673553,
		"totalGuests": 205,
		"guestPercent": 0.554231,
		"mostGuestDate": "2023-06-25 00:00:00",
		"mostGuestName": "WEX S.A.",
		"mostGuestCity": "Marche-en-Famenne",
		"mostGuestAmount": 21
	},
	{
		"id": "b827d95",
		"email": "sina@boogieprod.com",
		"name": "Sina",
		"createdDate": "2020-03-05 15:52:15",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 25,
		"totalCountries": 3,
		"totalStates": 5,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 214,
		"schedulePercent": 0.159184,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "aa69722",
		"email": "matt@nomadsound.net",
		"name": "Matt",
		"createdDate": "2014-01-09 05:23:41",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 450,
		"totalCountries": 7,
		"totalStates": 47,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 2060,
		"schedulePercent": 0.728645,
		"totalGuests": 1229,
		"guestPercent": 0.862402,
		"mostGuestDate": "2023-05-18 00:00:00",
		"mostGuestName": "Mohegan Sun Arena At Casey Plaza",
		"mostGuestCity": "Wilkes-Barre",
		"mostGuestAmount": 98
	},
	{
		"id": "8d8c4e2",
		"email": "kurt.shanks@me.com",
		"name": "Kurt",
		"createdDate": "2019-11-19 02:36:00",
		"totalOrganizations": 2,
		"totalTours": 21,
		"totalDays": 143,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 691,
		"schedulePercent": 0.377301,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2a9e1f6",
		"email": "particlekid@gmail.com",
		"name": "Alexandra",
		"createdDate": "2019-07-16 19:32:01",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 229,
		"totalCountries": 4,
		"totalStates": 38,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 1586,
		"schedulePercent": 0.638326,
		"totalGuests": 1285,
		"guestPercent": 0.869951,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "The Tulsa Theater",
		"mostGuestCity": "Tulsa",
		"mostGuestAmount": 147
	},
	{
		"id": "b4dd0ae",
		"email": "zach.kyker@gmail.com",
		"name": "Zach",
		"createdDate": "2019-09-02 03:38:05",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 103,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 120,
		"schedulePercent": 0.102635,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "eecce37",
		"email": "grahamnbl7@gmail.com",
		"name": "Graham",
		"createdDate": "2023-02-09 15:59:56",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 180,
		"totalCountries": 8,
		"totalStates": 17,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 559,
		"schedulePercent": 0.32552,
		"totalGuests": 123,
		"guestPercent": 0.492782,
		"mostGuestDate": "2023-08-16 00:00:00",
		"mostGuestName": "Edinburgh Corn Exchange",
		"mostGuestCity": "Edinburgh",
		"mostGuestAmount": 58
	},
	{
		"id": "720db63",
		"email": "mattmaly@gmail.com",
		"name": "Matt",
		"createdDate": "2012-05-30 01:14:42",
		"totalOrganizations": 19,
		"totalTours": 27,
		"totalDays": 767,
		"totalCountries": 6,
		"totalStates": 65,
		"totalEvents": 408,
		"eventPercent": 0.959078,
		"totalScheduleItems": 4715,
		"schedulePercent": 0.952589,
		"totalGuests": 3686,
		"guestPercent": 0.979605,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 183
	},
	{
		"id": "ade3743",
		"email": "ruelwithme@gmail.com",
		"name": "Muziek",
		"createdDate": "2023-02-15 05:59:54",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 186,
		"totalCountries": 23,
		"totalStates": 50,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 1104,
		"schedulePercent": 0.512647,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f2b308e",
		"email": "chelerocks@gmail.com",
		"name": "Michele",
		"createdDate": "2018-09-22 20:13:56",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 265,
		"totalCountries": 1,
		"totalStates": 29,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 1646,
		"schedulePercent": 0.652761,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7c9e7a5",
		"email": "wasylenkoMGMT@gmail.com",
		"name": "Michael",
		"createdDate": "2016-08-24 21:48:56",
		"totalOrganizations": 17,
		"totalTours": 14,
		"totalDays": 386,
		"totalCountries": 4,
		"totalStates": 42,
		"totalEvents": 227,
		"eventPercent": 0.844391,
		"totalScheduleItems": 3027,
		"schedulePercent": 0.853529,
		"totalGuests": 1566,
		"guestPercent": 0.900013,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 70
	},
	{
		"id": "5cce367",
		"email": "CP@oswaldentertainment.com",
		"name": "Charlie",
		"createdDate": "2019-01-30 04:01:09",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 519,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 413,
		"schedulePercent": 0.262349,
		"totalGuests": 56,
		"guestPercent": 0.429744,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Temecula Stampede",
		"mostGuestCity": "Temecula",
		"mostGuestAmount": 34
	},
	{
		"id": "29b99b7",
		"email": "production@millsentertainment.com",
		"name": "Mills",
		"createdDate": "2016-06-01 18:54:30",
		"totalOrganizations": 52,
		"totalTours": 14,
		"totalDays": 1089,
		"totalCountries": 4,
		"totalStates": 44,
		"totalEvents": 261,
		"eventPercent": 0.886902,
		"totalScheduleItems": 2590,
		"schedulePercent": 0.804662,
		"totalGuests": 295,
		"guestPercent": 0.599788,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Proctors",
		"mostGuestCity": "Schenectady",
		"mostGuestAmount": 40
	},
	{
		"id": "5447bee",
		"email": "s.ballardin@msk-live.de",
		"name": "s",
		"createdDate": "2022-11-07 16:05:43",
		"totalOrganizations": 12,
		"totalTours": 11,
		"totalDays": 244,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 1040,
		"schedulePercent": 0.494769,
		"totalGuests": 523,
		"guestPercent": 0.699245,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "E-Werk",
		"mostGuestCity": "Koln",
		"mostGuestAmount": 82
	},
	{
		"id": "8f616de",
		"email": "chenry@cityparksfoundation.org",
		"name": "Ceyla",
		"createdDate": "2023-05-17 21:31:09",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 220,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 1475,
		"schedulePercent": 0.607999,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0ddc600",
		"email": "yayaa.trejo@gmail.com",
		"name": "Trejo",
		"createdDate": "2015-05-14 18:33:58",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 369,
		"totalCountries": 5,
		"totalStates": 27,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 774,
		"schedulePercent": 0.410674,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Arena Ciudad de Mexico",
		"mostGuestCity": "Mexico D.F.",
		"mostGuestAmount": 5
	},
	{
		"id": "1ffd436",
		"email": "sruiz@totalconcert.com",
		"name": "Silvia",
		"createdDate": "2021-07-08 15:44:50",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 538,
		"totalCountries": 10,
		"totalStates": 42,
		"totalEvents": 221,
		"eventPercent": 0.83777,
		"totalScheduleItems": 3911,
		"schedulePercent": 0.919348,
		"totalGuests": 446,
		"guestPercent": 0.671434,
		"mostGuestDate": "2023-07-25 00:00:00",
		"mostGuestName": "Hordern Pavilion",
		"mostGuestCity": "Moore Park",
		"mostGuestAmount": 90
	},
	{
		"id": "9a66280",
		"email": "vbyer22@yahoo.com",
		"name": "Vickie",
		"createdDate": "2019-04-01 17:49:08",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 91,
		"totalCountries": 7,
		"totalStates": 21,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 550,
		"schedulePercent": 0.321414,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "43020a8",
		"email": "cib1@me.com",
		"name": "Bengt \"Elak\"",
		"createdDate": "2013-09-23 13:29:46",
		"totalOrganizations": 13,
		"totalTours": 3,
		"totalDays": 62,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 328,
		"schedulePercent": 0.223547,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Avicii Arena",
		"mostGuestCity": "Johanneshov",
		"mostGuestAmount": 3
	},
	{
		"id": "4017b22",
		"email": "melanie@seg.ca",
		"name": "Mel",
		"createdDate": "2015-01-22 22:53:09",
		"totalOrganizations": 9,
		"totalTours": 13,
		"totalDays": 584,
		"totalCountries": 9,
		"totalStates": 61,
		"totalEvents": 256,
		"eventPercent": 0.882135,
		"totalScheduleItems": 2535,
		"schedulePercent": 0.796981,
		"totalGuests": 1091,
		"guestPercent": 0.837637,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 54
	},
	{
		"id": "9ba3cfe",
		"email": "larkinpoe@gmail.com",
		"name": "Larkin",
		"createdDate": "2018-09-30 14:41:16",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 366,
		"totalCountries": 11,
		"totalStates": 40,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 1710,
		"schedulePercent": 0.667461,
		"totalGuests": 1049,
		"guestPercent": 0.832605,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 69
	},
	{
		"id": "094f813",
		"email": "christiantcoffey@gmail.com",
		"name": "Christian",
		"createdDate": "2014-06-08 23:09:24",
		"totalOrganizations": 21,
		"totalTours": 23,
		"totalDays": 2498,
		"totalCountries": 20,
		"totalStates": 66,
		"totalEvents": 425,
		"eventPercent": 0.963449,
		"totalScheduleItems": 7622,
		"schedulePercent": 0.991922,
		"totalGuests": 444,
		"guestPercent": 0.669845,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Rose Bowl Stadium",
		"mostGuestCity": "Pasadena",
		"mostGuestAmount": 45
	},
	{
		"id": "1c693d9",
		"email": "goldsmith.james@gmail.com",
		"name": "James",
		"createdDate": "2017-11-19 22:47:08",
		"totalOrganizations": 13,
		"totalTours": 13,
		"totalDays": 127,
		"totalCountries": 3,
		"totalStates": 9,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 590,
		"schedulePercent": 0.33585,
		"totalGuests": 384,
		"guestPercent": 0.646669,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "Powerstation",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 83
	},
	{
		"id": "97ab8d8",
		"email": "chris@famousfriendsmgmt.com",
		"name": "Chris",
		"createdDate": "2021-10-30 16:58:23",
		"totalOrganizations": 9,
		"totalTours": 8,
		"totalDays": 494,
		"totalCountries": 14,
		"totalStates": 37,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 809,
		"schedulePercent": 0.422858,
		"totalGuests": 309,
		"guestPercent": 0.609058,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 81
	},
	{
		"id": "2f79151",
		"email": "SAM@THETOURMANAGERS.COM",
		"name": "SAM",
		"createdDate": "2016-10-04 09:55:51",
		"totalOrganizations": 15,
		"totalTours": 19,
		"totalDays": 499,
		"totalCountries": 22,
		"totalStates": 61,
		"totalEvents": 193,
		"eventPercent": 0.792743,
		"totalScheduleItems": 2592,
		"schedulePercent": 0.805986,
		"totalGuests": 1210,
		"guestPercent": 0.858827,
		"mostGuestDate": "2023-01-25 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 67
	},
	{
		"id": "cba7e34",
		"email": "annieacm@troika.com",
		"name": "Company",
		"createdDate": "2023-02-02 21:16:32",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 248,
		"totalCountries": 1,
		"totalStates": 26,
		"totalEvents": 187,
		"eventPercent": 0.784135,
		"totalScheduleItems": 1291,
		"schedulePercent": 0.563899,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fc7c6ac",
		"email": "francesco@swexbooking.com",
		"name": "Francesco",
		"createdDate": "2018-12-08 22:03:20",
		"totalOrganizations": 9,
		"totalTours": 8,
		"totalDays": 314,
		"totalCountries": 18,
		"totalStates": 89,
		"totalEvents": 167,
		"eventPercent": 0.745332,
		"totalScheduleItems": 2501,
		"schedulePercent": 0.79367,
		"totalGuests": 1397,
		"guestPercent": 0.882532,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Mizner Park Amphitheatre",
		"mostGuestCity": "Boca Raton",
		"mostGuestAmount": 80
	},
	{
		"id": "4c7453e",
		"email": "jamie.graves@wmg.com",
		"name": "Jamie",
		"createdDate": "2022-10-25 20:04:11",
		"totalOrganizations": 42,
		"totalTours": 40,
		"totalDays": 13556,
		"totalCountries": 15,
		"totalStates": 87,
		"totalEvents": 1602,
		"eventPercent": 0.993246,
		"totalScheduleItems": 737,
		"schedulePercent": 0.394385,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3931ddc",
		"email": "javan@jvnlive.com",
		"name": "Javan",
		"createdDate": "2023-01-22 22:15:11",
		"totalOrganizations": 6,
		"totalTours": 21,
		"totalDays": 221,
		"totalCountries": 8,
		"totalStates": 17,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 842,
		"schedulePercent": 0.434909,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-03-16 00:00:00",
		"mostGuestName": "Holeâ´â´",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 4
	},
	{
		"id": "94cd7e9",
		"email": "ccrecords@mac.com",
		"name": "Linda",
		"createdDate": "2022-07-25 18:38:38",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 104,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 730,
		"schedulePercent": 0.392266,
		"totalGuests": 227,
		"guestPercent": 0.567474,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Saban Theatre",
		"mostGuestCity": "Beverly Hills",
		"mostGuestAmount": 43
	},
	{
		"id": "e20f255",
		"email": "witchbone666@gmail.com",
		"name": "Ales",
		"createdDate": "2014-11-08 17:39:52",
		"totalOrganizations": 8,
		"totalTours": 12,
		"totalDays": 203,
		"totalCountries": 16,
		"totalStates": 58,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 1707,
		"schedulePercent": 0.666269,
		"totalGuests": 98,
		"guestPercent": 0.472917,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Roadrunner",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 24
	},
	{
		"id": "ecbefc2",
		"email": "produzione@magellanoconcerti.it",
		"name": "Roberta",
		"createdDate": "2022-11-04 22:37:05",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 144,
		"totalCountries": 1,
		"totalStates": 31,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 966,
		"schedulePercent": 0.469872,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "PARCO DI  SAN GIULIANO MESTRE",
		"mostGuestCity": "VENEZIA",
		"mostGuestAmount": 17
	},
	{
		"id": "ec9adb9",
		"email": "emdamaschin@gmail.com",
		"name": "Em",
		"createdDate": "2022-05-20 17:02:32",
		"totalOrganizations": 6,
		"totalTours": 14,
		"totalDays": 71,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 388,
		"schedulePercent": 0.252152,
		"totalGuests": 41,
		"guestPercent": 0.407231,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "The Colosseum at Caesars Windsor",
		"mostGuestCity": "Windsor",
		"mostGuestAmount": 15
	},
	{
		"id": "520222d",
		"email": "Kayla.Burnett@wmg.com",
		"name": "Kayla",
		"createdDate": "2020-11-26 04:19:05",
		"totalOrganizations": 52,
		"totalTours": 48,
		"totalDays": 15641,
		"totalCountries": 16,
		"totalStates": 89,
		"totalEvents": 2154,
		"eventPercent": 0.998146,
		"totalScheduleItems": 4222,
		"schedulePercent": 0.934843,
		"totalGuests": 570,
		"guestPercent": 0.716726,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "South Fork Dirt Riders",
		"mostGuestCity": "Taylorville",
		"mostGuestAmount": 29
	},
	{
		"id": "3e644d6",
		"email": "kelly@londonfilmmusicorchestra.com",
		"name": "kellija",
		"createdDate": "2023-08-25 10:06:24",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 45,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 359,
		"schedulePercent": 0.23732,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d83f876",
		"email": "evanuschenko@gmail.com",
		"name": "Evan",
		"createdDate": "2022-03-10 01:03:35",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 89,
		"totalCountries": 8,
		"totalStates": 19,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 429,
		"schedulePercent": 0.267779,
		"totalGuests": 44,
		"guestPercent": 0.411469,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "Saint John at Hackney",
		"mostGuestCity": "London",
		"mostGuestAmount": 14
	},
	{
		"id": "8d73635",
		"email": "lubrady@verizon.net",
		"name": "Lucy Marie",
		"createdDate": "2016-12-13 18:04:14",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 736,
		"totalCountries": 5,
		"totalStates": 48,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 2455,
		"schedulePercent": 0.786916,
		"totalGuests": 447,
		"guestPercent": 0.672096,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Coachman Park Amphitheater",
		"mostGuestCity": "Clearwater",
		"mostGuestAmount": 31
	},
	{
		"id": "01906c8",
		"email": "john@samhunttouring.com",
		"name": "John",
		"createdDate": "2016-03-22 15:44:15",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 373,
		"totalCountries": 2,
		"totalStates": 31,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1941,
		"schedulePercent": 0.70984,
		"totalGuests": 1186,
		"guestPercent": 0.854721,
		"mostGuestDate": "2023-09-07 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 118
	},
	{
		"id": "587485f",
		"email": "marc.allan@redlightmanagement.com",
		"name": "Marc",
		"createdDate": "2012-02-28 08:58:42",
		"totalOrganizations": 17,
		"totalTours": 17,
		"totalDays": 820,
		"totalCountries": 9,
		"totalStates": 50,
		"totalEvents": 248,
		"eventPercent": 0.869554,
		"totalScheduleItems": 2698,
		"schedulePercent": 0.818567,
		"totalGuests": 420,
		"guestPercent": 0.661237,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 87
	},
	{
		"id": "b655ec1",
		"email": "oaksld@aol.com",
		"name": "Dave",
		"createdDate": "2016-01-10 17:36:07",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 366,
		"totalCountries": 3,
		"totalStates": 27,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 454,
		"schedulePercent": 0.278109,
		"totalGuests": 552,
		"guestPercent": 0.710369,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Stride Bank Center",
		"mostGuestCity": "Enid",
		"mostGuestAmount": 25
	},
	{
		"id": "e3013dd",
		"email": "chrissy@headlineconcerts.de",
		"name": "Christian",
		"createdDate": "2020-03-06 19:32:47",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 284,
		"totalCountries": 3,
		"totalStates": 16,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 712,
		"schedulePercent": 0.385512,
		"totalGuests": 113,
		"guestPercent": 0.485366,
		"mostGuestDate": "2023-10-31 00:00:00",
		"mostGuestName": "LÃ¶wensaal",
		"mostGuestCity": "NÃ¼rnberg",
		"mostGuestAmount": 47
	},
	{
		"id": "53f0075",
		"email": "michael@solidrocklive.com",
		"name": "Michael",
		"createdDate": "2018-12-12 19:36:51",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 75,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 169,
		"schedulePercent": 0.131903,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "05cf1d0",
		"email": "charliekopa@me.com",
		"name": "Charlie",
		"createdDate": "2018-11-01 14:13:32",
		"totalOrganizations": 20,
		"totalTours": 32,
		"totalDays": 761,
		"totalCountries": 12,
		"totalStates": 41,
		"totalEvents": 172,
		"eventPercent": 0.755661,
		"totalScheduleItems": 2761,
		"schedulePercent": 0.827175,
		"totalGuests": 506,
		"guestPercent": 0.694213,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Forum Melbourne",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 30
	},
	{
		"id": "e51b635",
		"email": "jessicaroxana91@gmail.com",
		"name": "Jessica",
		"createdDate": "2023-01-23 16:56:18",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 606,
		"totalCountries": 13,
		"totalStates": 33,
		"totalEvents": 209,
		"eventPercent": 0.82201,
		"totalScheduleItems": 2255,
		"schedulePercent": 0.759767,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "96ed41e",
		"email": "sam@spinningtopmusic.com",
		"name": "Sam",
		"createdDate": "2022-05-20 19:18:26",
		"totalOrganizations": 13,
		"totalTours": 41,
		"totalDays": 833,
		"totalCountries": 4,
		"totalStates": 27,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 1323,
		"schedulePercent": 0.57211,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "Vinyl",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 1
	},
	{
		"id": "7d6b3ea",
		"email": "cyril@regiegro.fr",
		"name": "Cyril",
		"createdDate": "2016-11-14 15:19:20",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 147,
		"totalCountries": 8,
		"totalStates": 19,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 375,
		"schedulePercent": 0.245928,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "KOLORZ FEST",
		"mostGuestCity": "Carpentras",
		"mostGuestAmount": 4
	},
	{
		"id": "a818f69",
		"email": "sxwproductions@gmail.com",
		"name": "Samuel",
		"createdDate": "2014-08-09 05:24:02",
		"totalOrganizations": 8,
		"totalTours": 15,
		"totalDays": 649,
		"totalCountries": 18,
		"totalStates": 52,
		"totalEvents": 244,
		"eventPercent": 0.865713,
		"totalScheduleItems": 4551,
		"schedulePercent": 0.947027,
		"totalGuests": 257,
		"guestPercent": 0.582969,
		"mostGuestDate": "2023-02-06 00:00:00",
		"mostGuestName": "Grand Ole Opry",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 131
	},
	{
		"id": "f807868",
		"email": "DerekWilsonSR@gmail.com",
		"name": "Derek",
		"createdDate": "2013-01-29 01:56:31",
		"totalOrganizations": 32,
		"totalTours": 11,
		"totalDays": 603,
		"totalCountries": 22,
		"totalStates": 59,
		"totalEvents": 350,
		"eventPercent": 0.941597,
		"totalScheduleItems": 3493,
		"schedulePercent": 0.893524,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "Hard Rock Live",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 0
	},
	{
		"id": "6456882",
		"email": "nr@soundways.dk",
		"name": "Nikolai",
		"createdDate": "2016-01-20 12:57:58",
		"totalOrganizations": 14,
		"totalTours": 10,
		"totalDays": 371,
		"totalCountries": 9,
		"totalStates": 18,
		"totalEvents": 293,
		"eventPercent": 0.911005,
		"totalScheduleItems": 2855,
		"schedulePercent": 0.838432,
		"totalGuests": 1340,
		"guestPercent": 0.876308,
		"mostGuestDate": "2023-03-12 00:00:00",
		"mostGuestName": "Verti Music Hall",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 63
	},
	{
		"id": "f8e3321",
		"email": "LGmastertour@gmail.com",
		"name": "DAVID",
		"createdDate": "2022-04-23 12:09:54",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 498,
		"totalCountries": 3,
		"totalStates": 5,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 423,
		"schedulePercent": 0.265263,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "48c9d33",
		"email": "Scott.Hendricks@wmg.com",
		"name": "Scott",
		"createdDate": "2017-08-10 03:07:32",
		"totalOrganizations": 50,
		"totalTours": 45,
		"totalDays": 15070,
		"totalCountries": 15,
		"totalStates": 89,
		"totalEvents": 1955,
		"eventPercent": 0.995895,
		"totalScheduleItems": 1582,
		"schedulePercent": 0.637399,
		"totalGuests": 1674,
		"guestPercent": 0.909946,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Paycom Center",
		"mostGuestCity": "Oklahoma City",
		"mostGuestAmount": 167
	},
	{
		"id": "2dd3b03",
		"email": "STARSETtouring@gmail.com",
		"name": "STARSET",
		"createdDate": "2019-03-21 00:00:41",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 72,
		"totalCountries": 9,
		"totalStates": 12,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 720,
		"schedulePercent": 0.388028,
		"totalGuests": 90,
		"guestPercent": 0.463912,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 26
	},
	{
		"id": "5886fab",
		"email": "moutonmoutal@hotmail.com",
		"name": "Guillaume",
		"createdDate": "2018-09-20 21:49:25",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 39,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 225,
		"schedulePercent": 0.1666,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e2d291b",
		"email": "tareef@wumusicgroup.com",
		"name": "Tareef",
		"createdDate": "2019-04-05 01:01:41",
		"totalOrganizations": 6,
		"totalTours": 9,
		"totalDays": 410,
		"totalCountries": 15,
		"totalStates": 40,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 1368,
		"schedulePercent": 0.584956,
		"totalGuests": 1703,
		"guestPercent": 0.913521,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Grand Garden Arena",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 161
	},
	{
		"id": "c6499c3",
		"email": "atkolea1@outlook.com",
		"name": "Alexandra",
		"createdDate": "2022-08-27 18:51:15",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 677,
		"totalCountries": 3,
		"totalStates": 43,
		"totalEvents": 234,
		"eventPercent": 0.854324,
		"totalScheduleItems": 2606,
		"schedulePercent": 0.808502,
		"totalGuests": 1744,
		"guestPercent": 0.916303,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "Dickies Arena",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 93
	},
	{
		"id": "91faa08",
		"email": "christophermiranda21@gmail.com",
		"name": "Christopher",
		"createdDate": "2022-03-10 16:18:45",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 555,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 227,
		"eventPercent": 0.844391,
		"totalScheduleItems": 2512,
		"schedulePercent": 0.794994,
		"totalGuests": 1832,
		"guestPercent": 0.923321,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 105
	},
	{
		"id": "2e3f95e",
		"email": "gloriamarce@glomc.com",
		"name": "Gl&#242;ria",
		"createdDate": "2019-11-22 14:31:11",
		"totalOrganizations": 5,
		"totalTours": 24,
		"totalDays": 280,
		"totalCountries": 16,
		"totalStates": 42,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1657,
		"schedulePercent": 0.654748,
		"totalGuests": 237,
		"guestPercent": 0.571712,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "Iveagh Gardens",
		"mostGuestCity": "Dublin 2",
		"mostGuestAmount": 56
	},
	{
		"id": "2ae238d",
		"email": "christine.vorasane@gmail.com",
		"name": "Christine",
		"createdDate": "2022-10-03 18:54:10",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 274,
		"totalCountries": 11,
		"totalStates": 46,
		"totalEvents": 179,
		"eventPercent": 0.769037,
		"totalScheduleItems": 1526,
		"schedulePercent": 0.623891,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1f4a560",
		"email": "andreas@bergenlive.no",
		"name": "Andreas ",
		"createdDate": "2014-08-27 07:14:03",
		"totalOrganizations": 5,
		"totalTours": 17,
		"totalDays": 136,
		"totalCountries": 9,
		"totalStates": 31,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 1193,
		"schedulePercent": 0.538472,
		"totalGuests": 258,
		"guestPercent": 0.583234,
		"mostGuestDate": "2023-09-29 00:00:00",
		"mostGuestName": "BAM",
		"mostGuestCity": "Metz",
		"mostGuestAmount": 18
	},
	{
		"id": "c7cab22",
		"email": "Gerard@wearelive.us",
		"name": "Gerard",
		"createdDate": "2019-04-05 22:39:40",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 527,
		"totalCountries": 13,
		"totalStates": 57,
		"totalEvents": 216,
		"eventPercent": 0.831943,
		"totalScheduleItems": 3653,
		"schedulePercent": 0.904251,
		"totalGuests": 2295,
		"guestPercent": 0.947557,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Glen Helen Amphitheater",
		"mostGuestCity": "San Bernardino",
		"mostGuestAmount": 201
	},
	{
		"id": "7bbf3c3",
		"email": "kyle@watchtowerentertainment.com",
		"name": "Kyle",
		"createdDate": "2023-02-21 10:45:26",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 319,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 225,
		"schedulePercent": 0.1666,
		"totalGuests": 49,
		"guestPercent": 0.419944,
		"mostGuestDate": "2023-07-23 00:00:00",
		"mostGuestName": "Stages Music Arts",
		"mostGuestCity": "Cockeysville",
		"mostGuestAmount": 12
	},
	{
		"id": "436e2f4",
		"email": "amielciprianogonzales@gmail.com",
		"name": "Amiel",
		"createdDate": "2019-12-20 02:11:23",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 775,
		"totalCountries": 7,
		"totalStates": 51,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 2819,
		"schedulePercent": 0.834591,
		"totalGuests": 772,
		"guestPercent": 0.770097,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "Palacio de los Deportes",
		"mostGuestCity": "Ciudad de MÃ©xico",
		"mostGuestAmount": 79
	},
	{
		"id": "710bd40",
		"email": "roaddayz@gmail.com",
		"name": "Chris",
		"createdDate": "2020-02-24 16:13:23",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 923,
		"totalCountries": 4,
		"totalStates": 39,
		"totalEvents": 168,
		"eventPercent": 0.747451,
		"totalScheduleItems": 2240,
		"schedulePercent": 0.756191,
		"totalGuests": 589,
		"guestPercent": 0.722288,
		"mostGuestDate": "2023-11-26 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 41
	},
	{
		"id": "c6b0e2b",
		"email": "celina@rollonent.com",
		"name": "Celina",
		"createdDate": "2017-04-20 16:36:18",
		"totalOrganizations": 11,
		"totalTours": 9,
		"totalDays": 328,
		"totalCountries": 24,
		"totalStates": 52,
		"totalEvents": 145,
		"eventPercent": 0.688518,
		"totalScheduleItems": 2636,
		"schedulePercent": 0.812872,
		"totalGuests": 1401,
		"guestPercent": 0.882929,
		"mostGuestDate": "2023-03-20 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 86
	},
	{
		"id": "4e88917",
		"email": "jk@greenroom.travel",
		"name": "Jeff",
		"createdDate": "2023-08-19 12:43:56",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 112,
		"totalCountries": 5,
		"totalStates": 31,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 81,
		"schedulePercent": 0.075884,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "17028ac",
		"email": "rjpurcell@mac.com",
		"name": "Rick",
		"createdDate": "2012-05-26 07:57:12",
		"totalOrganizations": 14,
		"totalTours": 5,
		"totalDays": 597,
		"totalCountries": 9,
		"totalStates": 51,
		"totalEvents": 251,
		"eventPercent": 0.873792,
		"totalScheduleItems": 4738,
		"schedulePercent": 0.953119,
		"totalGuests": 1281,
		"guestPercent": 0.869024,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 139
	},
	{
		"id": "502e414",
		"email": "mikieweiss@aol.com",
		"name": "Michael",
		"createdDate": "2014-03-07 20:46:35",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 299,
		"totalCountries": 6,
		"totalStates": 53,
		"totalEvents": 142,
		"eventPercent": 0.680705,
		"totalScheduleItems": 2177,
		"schedulePercent": 0.746921,
		"totalGuests": 2734,
		"guestPercent": 0.961727,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "T-Mobile Arena",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 118
	},
	{
		"id": "1347de9",
		"email": "joslyn@split--second.com",
		"name": "Joslyn",
		"createdDate": "2019-09-04 22:07:23",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 1154,
		"totalCountries": 8,
		"totalStates": 37,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1489,
		"schedulePercent": 0.615415,
		"totalGuests": 1178,
		"guestPercent": 0.853529,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 93
	},
	{
		"id": "7dce0c9",
		"email": "ajdicarlo3@gmail.com",
		"name": "Andrew",
		"createdDate": "2018-02-02 02:25:45",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 833,
		"totalCountries": 4,
		"totalStates": 50,
		"totalEvents": 254,
		"eventPercent": 0.879619,
		"totalScheduleItems": 4178,
		"schedulePercent": 0.932857,
		"totalGuests": 3579,
		"guestPercent": 0.978546,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 102
	},
	{
		"id": "167227e",
		"email": "thenationalparksTM@gmail.com",
		"name": "TNP",
		"createdDate": "2022-04-21 16:49:15",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 204,
		"totalCountries": 1,
		"totalStates": 29,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 966,
		"schedulePercent": 0.469872,
		"totalGuests": 455,
		"guestPercent": 0.675805,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "The Union Event Center",
		"mostGuestCity": "Salt Lake City",
		"mostGuestAmount": 53
	},
	{
		"id": "669d3ab",
		"email": "john@24-7productions.co.uk",
		"name": "John",
		"createdDate": "2013-05-14 03:49:07",
		"totalOrganizations": 42,
		"totalTours": 32,
		"totalDays": 1102,
		"totalCountries": 23,
		"totalStates": 68,
		"totalEvents": 377,
		"eventPercent": 0.950073,
		"totalScheduleItems": 4116,
		"schedulePercent": 0.928751,
		"totalGuests": 509,
		"guestPercent": 0.695007,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 102
	},
	{
		"id": "d9ce2cd",
		"email": "deanmuncie@gmail.com",
		"name": "Dean",
		"createdDate": "2019-07-09 14:53:47",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 315,
		"totalCountries": 12,
		"totalStates": 47,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 1358,
		"schedulePercent": 0.582704,
		"totalGuests": 288,
		"guestPercent": 0.597404,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Regent Theater DTLA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 46
	},
	{
		"id": "4fbb9d9",
		"email": "jacob@lowlylabs.io",
		"name": "Jacob",
		"createdDate": "2022-12-13 10:31:09",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 66,
		"totalCountries": 9,
		"totalStates": 16,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 289,
		"schedulePercent": 0.20249,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-12-01 00:00:00",
		"mostGuestName": "Oxford Art Factory",
		"mostGuestCity": "Darlinghurst",
		"mostGuestAmount": 0
	},
	{
		"id": "4ef367b",
		"email": "jeremy@suresoundandlighting.com",
		"name": "Jeremy",
		"createdDate": "2019-05-22 00:39:13",
		"totalOrganizations": 2,
		"totalTours": 19,
		"totalDays": 558,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 1,
		"eventPercent": 0.00662164,
		"totalScheduleItems": 549,
		"schedulePercent": 0.320355,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "322cf8b",
		"email": "dave@custommade.com.au",
		"name": "Dave",
		"createdDate": "2012-05-28 03:57:42",
		"totalOrganizations": 19,
		"totalTours": 46,
		"totalDays": 505,
		"totalCountries": 9,
		"totalStates": 37,
		"totalEvents": 232,
		"eventPercent": 0.852073,
		"totalScheduleItems": 2181,
		"schedulePercent": 0.747716,
		"totalGuests": 590,
		"guestPercent": 0.722553,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "Palais Theatre",
		"mostGuestCity": "St Kilda",
		"mostGuestAmount": 94
	},
	{
		"id": "94ec715",
		"email": "grantframpton@gmail.com",
		"name": "Grant",
		"createdDate": "2014-11-06 11:33:16",
		"totalOrganizations": 12,
		"totalTours": 11,
		"totalDays": 216,
		"totalCountries": 22,
		"totalStates": 64,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 1701,
		"schedulePercent": 0.664945,
		"totalGuests": 940,
		"guestPercent": 0.809826,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 61
	},
	{
		"id": "da5ea7b",
		"email": "jeppe@theartist.eu",
		"name": "Peter",
		"createdDate": "2018-06-06 08:04:05",
		"totalOrganizations": 73,
		"totalTours": 39,
		"totalDays": 1109,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 884,
		"eventPercent": 0.988876,
		"totalScheduleItems": 5863,
		"schedulePercent": 0.978546,
		"totalGuests": 1656,
		"guestPercent": 0.907694,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Royal Arena",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 140
	},
	{
		"id": "3da4b1b",
		"email": "bookings@e1fx.com",
		"name": "Event ",
		"createdDate": "2023-04-18 17:51:16",
		"totalOrganizations": 48,
		"totalTours": 24,
		"totalDays": 538,
		"totalCountries": 3,
		"totalStates": 40,
		"totalEvents": 387,
		"eventPercent": 0.954046,
		"totalScheduleItems": 470,
		"schedulePercent": 0.284598,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "629dd04",
		"email": "ryannoel87@gmail.com",
		"name": "Ryan",
		"createdDate": "2016-06-14 20:08:31",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 331,
		"totalCountries": 9,
		"totalStates": 35,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 2475,
		"schedulePercent": 0.789829,
		"totalGuests": 3476,
		"guestPercent": 0.976559,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Raymond James Stadium",
		"mostGuestCity": "Tampa ",
		"mostGuestAmount": 248
	},
	{
		"id": "612a5a2",
		"email": "rmstokes@mac.com",
		"name": "Robertson",
		"createdDate": "2012-08-16 20:39:01",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 141,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 617,
		"schedulePercent": 0.348431,
		"totalGuests": 307,
		"guestPercent": 0.607072,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Wolf Trap National Park for the Performing Arts",
		"mostGuestCity": "Vienna",
		"mostGuestAmount": 45
	},
	{
		"id": "925c6ba",
		"email": "craig@gifted.net.nz",
		"name": "Craig",
		"createdDate": "2017-12-01 04:20:19",
		"totalOrganizations": 8,
		"totalTours": 20,
		"totalDays": 79,
		"totalCountries": 3,
		"totalStates": 5,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 524,
		"schedulePercent": 0.309098,
		"totalGuests": 87,
		"guestPercent": 0.460734,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "The Great Club",
		"mostGuestCity": "Marrickville",
		"mostGuestAmount": 30
	},
	{
		"id": "3a63e67",
		"email": "COOPER@thegroupprojects.co",
		"name": "Cooper ",
		"createdDate": "2021-11-24 20:09:30",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 151,
		"totalCountries": 3,
		"totalStates": 31,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 1000,
		"schedulePercent": 0.485234,
		"totalGuests": 894,
		"guestPercent": 0.798702,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "Red Rock Casino Resort and Spa",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 59
	},
	{
		"id": "0b5a2a8",
		"email": "brenton.miles@gmail.com",
		"name": "Brenton",
		"createdDate": "2018-09-10 22:12:34",
		"totalOrganizations": 11,
		"totalTours": 14,
		"totalDays": 1576,
		"totalCountries": 4,
		"totalStates": 32,
		"totalEvents": 208,
		"eventPercent": 0.819891,
		"totalScheduleItems": 3657,
		"schedulePercent": 0.904648,
		"totalGuests": 862,
		"guestPercent": 0.790491,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Texas Trust CU Theatre",
		"mostGuestCity": "Grand Prairie",
		"mostGuestAmount": 69
	},
	{
		"id": "f9ee8ca",
		"email": "TM@maliere.co.uk",
		"name": "Alexander",
		"createdDate": "2015-10-20 09:51:58",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 64,
		"totalCountries": 3,
		"totalStates": 24,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "030554c",
		"email": "cara@badtasteempire.com",
		"name": "Cara",
		"createdDate": "2023-01-09 14:06:04",
		"totalOrganizations": 6,
		"totalTours": 15,
		"totalDays": 576,
		"totalCountries": 11,
		"totalStates": 24,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 1146,
		"schedulePercent": 0.525493,
		"totalGuests": 119,
		"guestPercent": 0.489604,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "Debaser Strand",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 31
	},
	{
		"id": "7ccdf2b",
		"email": "jake@broadwayjake.com",
		"name": "Jacob",
		"createdDate": "2021-10-22 17:29:10",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 361,
		"totalCountries": 1,
		"totalStates": 35,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 641,
		"schedulePercent": 0.359025,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-05-31 00:00:00",
		"mostGuestName": "Marquee Theatre",
		"mostGuestCity": "Tempe",
		"mostGuestAmount": 12
	},
	{
		"id": "5266e6d",
		"email": "helmi.manninen@warnermusic.com",
		"name": "Helmi",
		"createdDate": "2018-12-11 01:10:57",
		"totalOrganizations": 34,
		"totalTours": 47,
		"totalDays": 555,
		"totalCountries": 3,
		"totalStates": 196,
		"totalEvents": 534,
		"eventPercent": 0.977619,
		"totalScheduleItems": 1618,
		"schedulePercent": 0.646272,
		"totalGuests": 33,
		"guestPercent": 0.394252,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Tapiola Festivaali",
		"mostGuestCity": "Espoo",
		"mostGuestAmount": 13
	},
	{
		"id": "e026702",
		"email": "Hatplaysbass@gmail.com",
		"name": "Sean",
		"createdDate": "2015-01-08 04:14:26",
		"totalOrganizations": 5,
		"totalTours": 23,
		"totalDays": 486,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 208,
		"eventPercent": 0.819891,
		"totalScheduleItems": 2453,
		"schedulePercent": 0.786518,
		"totalGuests": 223,
		"guestPercent": 0.564958,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Rockin Rogers - Rogers Community Center",
		"mostGuestCity": "Rogers",
		"mostGuestAmount": 26
	},
	{
		"id": "ccfc81b",
		"email": "marlonb@mac.com",
		"name": "Marlon",
		"createdDate": "2011-08-16 11:34:57",
		"totalOrganizations": 10,
		"totalTours": 10,
		"totalDays": 1060,
		"totalCountries": 11,
		"totalStates": 42,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 2559,
		"schedulePercent": 0.800424,
		"totalGuests": 1366,
		"guestPercent": 0.879883,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "The Orion Amphitheater",
		"mostGuestCity": "Huntsville",
		"mostGuestAmount": 80
	},
	{
		"id": "b11cd66",
		"email": "brybarian@gmail.com",
		"name": "Bryan",
		"createdDate": "2022-11-03 20:59:42",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 186,
		"totalCountries": 10,
		"totalStates": 49,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 1178,
		"schedulePercent": 0.534234,
		"totalGuests": 1102,
		"guestPercent": 0.839756,
		"mostGuestDate": "2023-10-08 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 116
	},
	{
		"id": "ccd57f9",
		"email": "jamsscott1999@gmail.com",
		"name": "Jamison",
		"createdDate": "2022-07-22 20:36:54",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 220,
		"totalCountries": 2,
		"totalStates": 40,
		"totalEvents": 142,
		"eventPercent": 0.680705,
		"totalScheduleItems": 2005,
		"schedulePercent": 0.720037,
		"totalGuests": 2019,
		"guestPercent": 0.934843,
		"mostGuestDate": "2023-08-04 00:00:00",
		"mostGuestName": "Sunset Cove Amphitheater",
		"mostGuestCity": "Boca Raton",
		"mostGuestAmount": 132
	},
	{
		"id": "3747b02",
		"email": "caselliaudio@gmail.com",
		"name": "Vander",
		"createdDate": "2020-01-22 22:02:38",
		"totalOrganizations": 6,
		"totalTours": 14,
		"totalDays": 271,
		"totalCountries": 12,
		"totalStates": 48,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 1974,
		"schedulePercent": 0.71527,
		"totalGuests": 84,
		"guestPercent": 0.459012,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The Aztec Theatre",
		"mostGuestCity": "San Antonio",
		"mostGuestAmount": 70
	},
	{
		"id": "6ac7e36",
		"email": "jacksonmccormack18@gmail.com",
		"name": "Jackson",
		"createdDate": "2019-05-13 15:46:35",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 294,
		"totalCountries": 2,
		"totalStates": 35,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 1626,
		"schedulePercent": 0.647861,
		"totalGuests": 54,
		"guestPercent": 0.427096,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Marathon Music Works",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 43
	},
	{
		"id": "7863bfd",
		"email": "marcflack@yahoo.com",
		"name": "Marc",
		"createdDate": "2013-07-16 20:32:07",
		"totalOrganizations": 8,
		"totalTours": 3,
		"totalDays": 433,
		"totalCountries": 3,
		"totalStates": 28,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 1588,
		"schedulePercent": 0.638856,
		"totalGuests": 43,
		"guestPercent": 0.410674,
		"mostGuestDate": "2023-04-13 00:00:00",
		"mostGuestName": "Abba's House Academy",
		"mostGuestCity": "Hixson",
		"mostGuestAmount": 23
	},
	{
		"id": "ef5ff43",
		"email": "chadgalactic@gmail.com",
		"name": "Chad",
		"createdDate": "2018-09-03 03:41:37",
		"totalOrganizations": 16,
		"totalTours": 12,
		"totalDays": 149,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 990,
		"schedulePercent": 0.480201,
		"totalGuests": 11,
		"guestPercent": 0.345782,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "The Capitol Theatre",
		"mostGuestCity": "Port Chester",
		"mostGuestAmount": 9
	},
	{
		"id": "a9ae30b",
		"email": "productionmansam@gmail.com",
		"name": "Samuel",
		"createdDate": "2015-05-27 11:28:05",
		"totalOrganizations": 12,
		"totalTours": 9,
		"totalDays": 205,
		"totalCountries": 12,
		"totalStates": 39,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1492,
		"schedulePercent": 0.61621,
		"totalGuests": 1918,
		"guestPercent": 0.930075,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "Don Haskins Center",
		"mostGuestCity": "El Paso",
		"mostGuestAmount": 213
	},
	{
		"id": "6077b88",
		"email": "ben.bshorten@gmail.com",
		"name": "Ben",
		"createdDate": "2019-04-18 16:47:10",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 94,
		"totalCountries": 11,
		"totalStates": 30,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 700,
		"schedulePercent": 0.380744,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d6af477",
		"email": "smb.productions@yahoo.com",
		"name": "Byron",
		"createdDate": "2013-12-18 14:18:17",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 52,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 243,
		"schedulePercent": 0.17799,
		"totalGuests": 171,
		"guestPercent": 0.530658,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "The Fillmore",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 45
	},
	{
		"id": "06641b9",
		"email": "nataliadavilagzz@gmail.com",
		"name": "Natalia ",
		"createdDate": "2023-06-22 14:02:29",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 191,
		"totalCountries": 5,
		"totalStates": 25,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b092c13",
		"email": "keithdunn@hotmail.com",
		"name": "Keith",
		"createdDate": "2018-06-08 09:22:03",
		"totalOrganizations": 5,
		"totalTours": 18,
		"totalDays": 151,
		"totalCountries": 13,
		"totalStates": 30,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 646,
		"schedulePercent": 0.361542,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "79ad533",
		"email": "charliebybee@gmail.com",
		"name": "Charles",
		"createdDate": "2013-01-21 03:26:17",
		"totalOrganizations": 12,
		"totalTours": 11,
		"totalDays": 586,
		"totalCountries": 19,
		"totalStates": 53,
		"totalEvents": 217,
		"eventPercent": 0.833267,
		"totalScheduleItems": 4227,
		"schedulePercent": 0.935108,
		"totalGuests": 419,
		"guestPercent": 0.660707,
		"mostGuestDate": "2023-02-06 00:00:00",
		"mostGuestName": "Grand Ole Opry",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 131
	},
	{
		"id": "36599d3",
		"email": "rikkfeulner1@gmail.com",
		"name": "Richard",
		"createdDate": "2017-05-26 17:35:04",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 109,
		"totalCountries": 4,
		"totalStates": 32,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 930,
		"schedulePercent": 0.459939,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "Albert Hall",
		"mostGuestCity": "Manchester",
		"mostGuestAmount": 3
	},
	{
		"id": "086cc6c",
		"email": "eleanormkohl@gmail.com",
		"name": "Eleanor",
		"createdDate": "2021-08-25 16:03:08",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 900,
		"totalCountries": 3,
		"totalStates": 34,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 1131,
		"schedulePercent": 0.519931,
		"totalGuests": 176,
		"guestPercent": 0.533969,
		"mostGuestDate": "2023-10-02 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 73
	},
	{
		"id": "ef208cd",
		"email": "Timothyburkhead@gmail.com",
		"name": "Timothy",
		"createdDate": "2016-07-19 20:44:59",
		"totalOrganizations": 13,
		"totalTours": 8,
		"totalDays": 1385,
		"totalCountries": 3,
		"totalStates": 62,
		"totalEvents": 210,
		"eventPercent": 0.823732,
		"totalScheduleItems": 2005,
		"schedulePercent": 0.720037,
		"totalGuests": 418,
		"guestPercent": 0.66031,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 40
	},
	{
		"id": "aee14e1",
		"email": "betty@alist.com.au",
		"name": "Betty",
		"createdDate": "2022-05-30 05:10:07",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 55,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 245,
		"schedulePercent": 0.179049,
		"totalGuests": 28,
		"guestPercent": 0.386306,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Nautilus Arts Centre",
		"mostGuestCity": "Port Lincoln",
		"mostGuestAmount": 15
	},
	{
		"id": "ff4efce",
		"email": "ryanbwalsh89@gmail.com",
		"name": "Ryan",
		"createdDate": "2017-10-16 20:08:12",
		"totalOrganizations": 10,
		"totalTours": 6,
		"totalDays": 502,
		"totalCountries": 3,
		"totalStates": 35,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 2998,
		"schedulePercent": 0.850483,
		"totalGuests": 4004,
		"guestPercent": 0.983711,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Dickies Arena",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 433
	},
	{
		"id": "380ee98",
		"email": "stefan@showlab.no",
		"name": "Stefan",
		"createdDate": "2016-08-17 08:47:32",
		"totalOrganizations": 20,
		"totalTours": 10,
		"totalDays": 220,
		"totalCountries": 12,
		"totalStates": 22,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 1369,
		"schedulePercent": 0.585088,
		"totalGuests": 869,
		"guestPercent": 0.791816,
		"mostGuestDate": "2023-05-07 00:00:00",
		"mostGuestName": "O2 Academy Bristol",
		"mostGuestCity": "Bristol",
		"mostGuestAmount": 132
	},
	{
		"id": "8060cec",
		"email": "marisanchristie@gmail.com",
		"name": "Marisa",
		"createdDate": "2021-12-30 04:09:18",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 706,
		"totalCountries": 5,
		"totalStates": 53,
		"totalEvents": 390,
		"eventPercent": 0.954576,
		"totalScheduleItems": 4551,
		"schedulePercent": 0.947027,
		"totalGuests": 1128,
		"guestPercent": 0.844259,
		"mostGuestDate": "2023-12-06 00:00:00",
		"mostGuestName": "Terrace Theatre",
		"mostGuestCity": "Long Beach",
		"mostGuestAmount": 73
	},
	{
		"id": "0989567",
		"email": "bmfsam@aol.com",
		"name": "Sam",
		"createdDate": "2019-10-01 07:50:25",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 497,
		"totalCountries": 3,
		"totalStates": 16,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 727,
		"schedulePercent": 0.391604,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "95fd4d4",
		"email": "jackschneidermusic@gmail.com",
		"name": "Jack",
		"createdDate": "2019-06-19 13:29:20",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 705,
		"totalCountries": 1,
		"totalStates": 29,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 313,
		"schedulePercent": 0.214674,
		"totalGuests": 115,
		"guestPercent": 0.48722,
		"mostGuestDate": "2023-02-25 00:00:00",
		"mostGuestName": "Acrisure Arena",
		"mostGuestCity": "Palm Springs",
		"mostGuestAmount": 13
	},
	{
		"id": "47d44cc",
		"email": "wilsontouring@gmail.com",
		"name": "William",
		"createdDate": "2015-04-17 00:26:35",
		"totalOrganizations": 14,
		"totalTours": 16,
		"totalDays": 754,
		"totalCountries": 14,
		"totalStates": 65,
		"totalEvents": 265,
		"eventPercent": 0.890743,
		"totalScheduleItems": 4848,
		"schedulePercent": 0.956694,
		"totalGuests": 3016,
		"guestPercent": 0.96954,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Petco Park",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 186
	},
	{
		"id": "2f5c8e6",
		"email": "tylerbradenmusic@gmail.com",
		"name": "Tyler",
		"createdDate": "2020-01-13 17:43:38",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 5,
		"totalStates": 40,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 1259,
		"schedulePercent": 0.554231,
		"totalGuests": 181,
		"guestPercent": 0.537147,
		"mostGuestDate": "2023-05-21 00:00:00",
		"mostGuestName": "George E. Potter Center",
		"mostGuestCity": "Jackson",
		"mostGuestAmount": 31
	},
	{
		"id": "0093192",
		"email": "lauren.urbanowicz@premierproductions.com",
		"name": "Lauren",
		"createdDate": "2019-09-25 17:01:10",
		"totalOrganizations": 9,
		"totalTours": 3,
		"totalDays": 332,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 1005,
		"schedulePercent": 0.486426,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "31a4639",
		"email": "info@halocene.com",
		"name": "Bradley",
		"createdDate": "2023-05-25 11:38:31",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 73,
		"totalCountries": 8,
		"totalStates": 30,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 471,
		"schedulePercent": 0.28473,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fa91863",
		"email": "rjpm103@gmail.com",
		"name": "Ryan",
		"createdDate": "2022-02-02 18:21:28",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 97,
		"totalCountries": 1,
		"totalStates": 42,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 441,
		"schedulePercent": 0.272944,
		"totalGuests": 33,
		"guestPercent": 0.394252,
		"mostGuestDate": "2023-11-29 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 33
	},
	{
		"id": "c179d63",
		"email": "jeff@jgtouring.com",
		"name": "Jeff",
		"createdDate": "2018-01-13 16:56:30",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 1698,
		"schedulePercent": 0.664415,
		"totalGuests": 1209,
		"guestPercent": 0.858429,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Giant Center",
		"mostGuestCity": "Hershey",
		"mostGuestAmount": 72
	},
	{
		"id": "1c5268b",
		"email": "abbey.degiovanni@hillsong.com",
		"name": "Abbey",
		"createdDate": "2019-04-24 06:39:41",
		"totalOrganizations": 10,
		"totalTours": 22,
		"totalDays": 736,
		"totalCountries": 3,
		"totalStates": 33,
		"totalEvents": 181,
		"eventPercent": 0.771818,
		"totalScheduleItems": 3805,
		"schedulePercent": 0.911535,
		"totalGuests": 1680,
		"guestPercent": 0.91074,
		"mostGuestDate": "2023-05-02 00:00:00",
		"mostGuestName": "The Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 99
	},
	{
		"id": "35be07c",
		"email": "jonathan.maingre@orange.fr",
		"name": "Jonathan",
		"createdDate": "2013-12-18 08:41:30",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 122,
		"totalCountries": 4,
		"totalStates": 17,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 1344,
		"schedulePercent": 0.578864,
		"totalGuests": 916,
		"guestPercent": 0.80294,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "6MIC",
		"mostGuestCity": "Aix-en-Provence",
		"mostGuestAmount": 37
	},
	{
		"id": "1c340a2",
		"email": "harleydawson@hotmail.co.uk",
		"name": "Harley",
		"createdDate": "2016-08-22 20:46:31",
		"totalOrganizations": 7,
		"totalTours": 2,
		"totalDays": 494,
		"totalCountries": 14,
		"totalStates": 43,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 1535,
		"schedulePercent": 0.626937,
		"totalGuests": 602,
		"guestPercent": 0.726791,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 130
	},
	{
		"id": "1608389",
		"email": "micayla@goodforshowproductions.com",
		"name": "Micayla",
		"createdDate": "2022-11-11 15:32:48",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 1231,
		"totalCountries": 7,
		"totalStates": 52,
		"totalEvents": 210,
		"eventPercent": 0.823732,
		"totalScheduleItems": 2096,
		"schedulePercent": 0.73487,
		"totalGuests": 117,
		"guestPercent": 0.488412,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 64
	},
	{
		"id": "311a1a7",
		"email": "TheMikeBishopSmith@gmail.com",
		"name": "Mike",
		"createdDate": "2023-07-09 18:53:43",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 172,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 181,
		"schedulePercent": 0.139054,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7bdbc64",
		"email": "BLAKE@MCALLISTERPRODUCTIONS.COM",
		"name": "Blake",
		"createdDate": "2021-10-29 23:33:12",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 532,
		"totalCountries": 4,
		"totalStates": 42,
		"totalEvents": 253,
		"eventPercent": 0.877765,
		"totalScheduleItems": 2642,
		"schedulePercent": 0.8138,
		"totalGuests": 260,
		"guestPercent": 0.584293,
		"mostGuestDate": "2023-03-26 00:00:00",
		"mostGuestName": "Crisp Arena",
		"mostGuestCity": "McKenzie",
		"mostGuestAmount": 36
	},
	{
		"id": "0c4a888",
		"email": "tdb@fullstopmgmt.com",
		"name": "Trinity",
		"createdDate": "2018-07-03 18:25:13",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 152,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 847,
		"schedulePercent": 0.436631,
		"totalGuests": 1832,
		"guestPercent": 0.923321,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 105
	},
	{
		"id": "40f6ce7",
		"email": "rpclegg@gmail.com",
		"name": "Paul",
		"createdDate": "2012-05-10 01:20:35",
		"totalOrganizations": 9,
		"totalTours": 8,
		"totalDays": 130,
		"totalCountries": 7,
		"totalStates": 32,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 742,
		"schedulePercent": 0.397431,
		"totalGuests": 569,
		"guestPercent": 0.715799,
		"mostGuestDate": "2023-04-09 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 76
	},
	{
		"id": "4dcd782",
		"email": "giorgio.nesci@gmail.com",
		"name": "Giorgio",
		"createdDate": "2018-05-24 10:52:54",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 50,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 337,
		"schedulePercent": 0.226857,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Vox Club",
		"mostGuestCity": "Nonantola",
		"mostGuestAmount": 1
	},
	{
		"id": "5ce2521",
		"email": "gregoryb@vstarentertainment.com",
		"name": "Greg",
		"createdDate": "2022-03-14 15:00:24",
		"totalOrganizations": 2,
		"totalTours": 9,
		"totalDays": 318,
		"totalCountries": 2,
		"totalStates": 31,
		"totalEvents": 172,
		"eventPercent": 0.755661,
		"totalScheduleItems": 1305,
		"schedulePercent": 0.567607,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8cd3737",
		"email": "evanmiles@me.com",
		"name": "Evan ",
		"createdDate": "2014-09-17 16:48:15",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 345,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 1534,
		"schedulePercent": 0.626407,
		"totalGuests": 18,
		"guestPercent": 0.364455,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Tingley Coliseum",
		"mostGuestCity": "Albuquerque",
		"mostGuestAmount": 18
	},
	{
		"id": "055053a",
		"email": "bryant@urich.co",
		"name": "Bryant ",
		"createdDate": "2021-06-12 04:45:30",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 244,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 156,
		"schedulePercent": 0.124752,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "05cba45",
		"email": "Chaserobbs@gmail.com",
		"name": "Chase",
		"createdDate": "2016-11-04 03:02:51",
		"totalOrganizations": 11,
		"totalTours": 15,
		"totalDays": 415,
		"totalCountries": 15,
		"totalStates": 62,
		"totalEvents": 217,
		"eventPercent": 0.833267,
		"totalScheduleItems": 3532,
		"schedulePercent": 0.896173,
		"totalGuests": 274,
		"guestPercent": 0.590783,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 147
	},
	{
		"id": "5b4f860",
		"email": "sjpearson50@gmail.com",
		"name": "Sarah Jane",
		"createdDate": "2018-10-19 23:06:53",
		"totalOrganizations": 13,
		"totalTours": 12,
		"totalDays": 1254,
		"totalCountries": 18,
		"totalStates": 70,
		"totalEvents": 281,
		"eventPercent": 0.903059,
		"totalScheduleItems": 3702,
		"schedulePercent": 0.907429,
		"totalGuests": 1031,
		"guestPercent": 0.829294,
		"mostGuestDate": "2023-09-24 00:00:00",
		"mostGuestName": "Barbican Centre",
		"mostGuestCity": "London",
		"mostGuestAmount": 48
	},
	{
		"id": "b9a6111",
		"email": "booking@slamdisques.com",
		"name": "FÃ©lix",
		"createdDate": "2022-09-13 18:35:56",
		"totalOrganizations": 22,
		"totalTours": 20,
		"totalDays": 4917,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 213,
		"eventPercent": 0.827837,
		"totalScheduleItems": 883,
		"schedulePercent": 0.447623,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7e9944b",
		"email": "jarett@merchbox.ca",
		"name": "Jarett",
		"createdDate": "2014-01-21 22:17:48",
		"totalOrganizations": 14,
		"totalTours": 18,
		"totalDays": 188,
		"totalCountries": 13,
		"totalStates": 41,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 2065,
		"schedulePercent": 0.729307,
		"totalGuests": 878,
		"guestPercent": 0.794067,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Lake Tahoe Outdoor Arena",
		"mostGuestCity": "Stateline",
		"mostGuestAmount": 75
	},
	{
		"id": "928097c",
		"email": "erinoharaa@gmail.com",
		"name": "Erin",
		"createdDate": "2023-08-21 18:13:33",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 31,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 140,
		"schedulePercent": 0.113892,
		"totalGuests": 101,
		"guestPercent": 0.474904,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "Vinyl",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 22
	},
	{
		"id": "61d61a7",
		"email": "taylorjanetd@mac.com",
		"name": "Janet",
		"createdDate": "2009-09-09 18:03:48",
		"totalOrganizations": 39,
		"totalTours": 22,
		"totalDays": 1044,
		"totalCountries": 20,
		"totalStates": 62,
		"totalEvents": 406,
		"eventPercent": 0.958549,
		"totalScheduleItems": 5855,
		"schedulePercent": 0.978413,
		"totalGuests": 748,
		"guestPercent": 0.764005,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 98
	},
	{
		"id": "f3d19b6",
		"email": "egyptaminaali@gmail.com",
		"name": "Egypt",
		"createdDate": "2021-12-21 21:31:08",
		"totalOrganizations": 8,
		"totalTours": 12,
		"totalDays": 269,
		"totalCountries": 4,
		"totalStates": 26,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1549,
		"schedulePercent": 0.629586,
		"totalGuests": 28,
		"guestPercent": 0.386306,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "Provincetown Town Hall",
		"mostGuestCity": "Provincetown",
		"mostGuestAmount": 9
	},
	{
		"id": "d04798f",
		"email": "jrjmtm2@gmail.com",
		"name": "J.R. ",
		"createdDate": "2016-08-13 21:57:05",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 176,
		"totalCountries": 3,
		"totalStates": 32,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 1143,
		"schedulePercent": 0.524699,
		"totalGuests": 930,
		"guestPercent": 0.806913,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Stranahan Theatre",
		"mostGuestCity": "Toledo",
		"mostGuestAmount": 51
	},
	{
		"id": "60fc06c",
		"email": "brennancarter@gmail.com",
		"name": "Brennan",
		"createdDate": "2016-02-28 19:46:08",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 106,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 393,
		"schedulePercent": 0.254801,
		"totalGuests": 338,
		"guestPercent": 0.622699,
		"mostGuestDate": "2023-02-25 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 43
	},
	{
		"id": "cb7a40b",
		"email": "charliespencer84@gmail.com",
		"name": "Charles",
		"createdDate": "2022-05-30 13:38:28",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 190,
		"totalCountries": 7,
		"totalStates": 42,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 1058,
		"schedulePercent": 0.500463,
		"totalGuests": 1368,
		"guestPercent": 0.880016,
		"mostGuestDate": "2023-04-16 00:00:00",
		"mostGuestName": "The Danforth Music Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 101
	},
	{
		"id": "4e2726f",
		"email": "mirre@krall.se",
		"name": "Mirre",
		"createdDate": "2022-10-17 11:22:31",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 67,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 321,
		"schedulePercent": 0.218779,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "84d11e8",
		"email": "dom@dmbtourmanagement.com",
		"name": "Dom",
		"createdDate": "2021-06-10 11:30:28",
		"totalOrganizations": 19,
		"totalTours": 18,
		"totalDays": 700,
		"totalCountries": 7,
		"totalStates": 18,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 1175,
		"schedulePercent": 0.533174,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "100 Club",
		"mostGuestCity": "London",
		"mostGuestAmount": 10
	},
	{
		"id": "bc24064",
		"email": "katie@riverjuke.com",
		"name": "Katie",
		"createdDate": "2023-03-12 17:10:50",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 834,
		"totalCountries": 18,
		"totalStates": 59,
		"totalEvents": 205,
		"eventPercent": 0.814859,
		"totalScheduleItems": 4278,
		"schedulePercent": 0.937094,
		"totalGuests": 1661,
		"guestPercent": 0.908489,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 125
	},
	{
		"id": "111a641",
		"email": "greg.tamblyn@comcast.net",
		"name": "Greg",
		"createdDate": "2016-09-30 19:49:28",
		"totalOrganizations": 4,
		"totalTours": 24,
		"totalDays": 208,
		"totalCountries": 7,
		"totalStates": 36,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 2552,
		"schedulePercent": 0.799497,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1dbd350",
		"email": "diluted23@gmail.com",
		"name": "Alexandros",
		"createdDate": "2011-04-08 06:44:57",
		"totalOrganizations": 8,
		"totalTours": 21,
		"totalDays": 691,
		"totalCountries": 16,
		"totalStates": 84,
		"totalEvents": 361,
		"eventPercent": 0.946894,
		"totalScheduleItems": 4332,
		"schedulePercent": 0.938816,
		"totalGuests": 3764,
		"guestPercent": 0.98093,
		"mostGuestDate": "2023-08-04 00:00:00",
		"mostGuestName": "Sunset Cove Amphitheater",
		"mostGuestCity": "Boca Raton",
		"mostGuestAmount": 132
	},
	{
		"id": "ab780be",
		"email": "ana.moroianu@redlightmanagement.com",
		"name": "Ana",
		"createdDate": "2019-01-08 22:48:14",
		"totalOrganizations": 7,
		"totalTours": 39,
		"totalDays": 142,
		"totalCountries": 4,
		"totalStates": 29,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 394,
		"schedulePercent": 0.255066,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ad1cdff",
		"email": "shanehaase@gmail.com",
		"name": "Shane",
		"createdDate": "2010-10-08 21:31:35",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 239,
		"totalCountries": 8,
		"totalStates": 25,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 1795,
		"schedulePercent": 0.684545,
		"totalGuests": 1349,
		"guestPercent": 0.877367,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 147
	},
	{
		"id": "43e1fd8",
		"email": "najwaf@wildkratts.com",
		"name": "Najwa",
		"createdDate": "2019-06-03 18:21:59",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 38,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 436,
		"schedulePercent": 0.270825,
		"totalGuests": 156,
		"guestPercent": 0.518607,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Meridian Theatres @ Centrepointe",
		"mostGuestCity": "Ottawa",
		"mostGuestAmount": 37
	},
	{
		"id": "60b9baf",
		"email": "heatherbey@gmail.com",
		"name": "Heather",
		"createdDate": "2019-01-20 16:45:56",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 66,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 330,
		"schedulePercent": 0.224209,
		"totalGuests": 133,
		"guestPercent": 0.500596,
		"mostGuestDate": "2023-02-28 00:00:00",
		"mostGuestName": "The Echo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 30
	},
	{
		"id": "c376f1b",
		"email": "cecilia@mtmshows.com",
		"name": "Cecilia",
		"createdDate": "2022-02-17 14:06:21",
		"totalOrganizations": 5,
		"totalTours": 14,
		"totalDays": 1336,
		"totalCountries": 10,
		"totalStates": 74,
		"totalEvents": 309,
		"eventPercent": 0.920408,
		"totalScheduleItems": 1707,
		"schedulePercent": 0.666269,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "62ca116",
		"email": "arkona.gm@gmail.com",
		"name": "Anton",
		"createdDate": "2016-06-02 10:42:02",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 133,
		"totalCountries": 15,
		"totalStates": 45,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 1436,
		"schedulePercent": 0.600848,
		"totalGuests": 326,
		"guestPercent": 0.617137,
		"mostGuestDate": "2023-03-22 00:00:00",
		"mostGuestName": "The Regent",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 27
	},
	{
		"id": "a1b32ad",
		"email": "anjelmusic@me.com",
		"name": "Jason ",
		"createdDate": "2015-05-02 04:09:36",
		"totalOrganizations": 8,
		"totalTours": 12,
		"totalDays": 511,
		"totalCountries": 5,
		"totalStates": 49,
		"totalEvents": 264,
		"eventPercent": 0.889419,
		"totalScheduleItems": 3829,
		"schedulePercent": 0.913919,
		"totalGuests": 2178,
		"guestPercent": 0.942392,
		"mostGuestDate": "2023-06-13 00:00:00",
		"mostGuestName": "Northwell Health at Jones Beach Theater",
		"mostGuestCity": "Wantagh",
		"mostGuestAmount": 75
	},
	{
		"id": "209e3ce",
		"email": "dora.somrani@gmail.com",
		"name": "Dora",
		"createdDate": "2019-11-20 13:33:40",
		"totalOrganizations": 9,
		"totalTours": 13,
		"totalDays": 255,
		"totalCountries": 7,
		"totalStates": 21,
		"totalEvents": 148,
		"eventPercent": 0.696332,
		"totalScheduleItems": 1420,
		"schedulePercent": 0.596477,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-01-19 00:00:00",
		"mostGuestName": "Zenith de Strasbourg",
		"mostGuestCity": "Eckbolsheim",
		"mostGuestAmount": 10
	},
	{
		"id": "2e27c31",
		"email": "booking@engst-musik.de",
		"name": "Thomas",
		"createdDate": "2022-02-12 20:51:31",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 38,
		"totalCountries": 3,
		"totalStates": 11,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 282,
		"schedulePercent": 0.199311,
		"totalGuests": 246,
		"guestPercent": 0.576612,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "Columbia Theater",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 48
	},
	{
		"id": "9f65bae",
		"email": "scottbozack@biggigproductions.com",
		"name": "Scott",
		"createdDate": "2014-09-07 16:03:21",
		"totalOrganizations": 18,
		"totalTours": 42,
		"totalDays": 383,
		"totalCountries": 8,
		"totalStates": 45,
		"totalEvents": 229,
		"eventPercent": 0.847305,
		"totalScheduleItems": 3081,
		"schedulePercent": 0.859092,
		"totalGuests": 1832,
		"guestPercent": 0.923321,
		"mostGuestDate": "2023-06-04 00:00:00",
		"mostGuestName": "Central Park SummerStage",
		"mostGuestCity": "New York",
		"mostGuestAmount": 74
	},
	{
		"id": "2109e5b",
		"email": "janna@bretmichaels.com",
		"name": "Janna",
		"createdDate": "2022-06-05 00:08:12",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 237,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 176,
		"schedulePercent": 0.136008,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "83b6deb",
		"email": "Tristan.jmh@gmail.com",
		"name": "Tristan",
		"createdDate": "2016-10-05 10:19:33",
		"totalOrganizations": 10,
		"totalTours": 8,
		"totalDays": 663,
		"totalCountries": 20,
		"totalStates": 52,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 1824,
		"schedulePercent": 0.689975,
		"totalGuests": 591,
		"guestPercent": 0.722818,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Brighton Dome",
		"mostGuestCity": "Brighton",
		"mostGuestAmount": 40
	},
	{
		"id": "06a2de0",
		"email": "alicefraser@gmail.com",
		"name": "Alice",
		"createdDate": "2015-08-27 04:10:45",
		"totalOrganizations": 16,
		"totalTours": 24,
		"totalDays": 1156,
		"totalCountries": 10,
		"totalStates": 51,
		"totalEvents": 221,
		"eventPercent": 0.83777,
		"totalScheduleItems": 4304,
		"schedulePercent": 0.938021,
		"totalGuests": 142,
		"guestPercent": 0.506688,
		"mostGuestDate": "2023-02-25 00:00:00",
		"mostGuestName": "The Mothership",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 29
	},
	{
		"id": "7e8f954",
		"email": "Samantha@onlyhelix.com",
		"name": "Samantha",
		"createdDate": "2022-04-03 16:29:13",
		"totalOrganizations": 6,
		"totalTours": 14,
		"totalDays": 261,
		"totalCountries": 17,
		"totalStates": 36,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 1036,
		"schedulePercent": 0.493709,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Victoria Warehouse",
		"mostGuestCity": "Stretford",
		"mostGuestAmount": 2
	},
	{
		"id": "67a958f",
		"email": "tour@paulafugahawaii.com",
		"name": "Paula",
		"createdDate": "2022-10-28 21:39:26",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 42,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 139,
		"schedulePercent": 0.113362,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-02-19 00:00:00",
		"mostGuestName": "Marina Green Park",
		"mostGuestCity": "Long Beach",
		"mostGuestAmount": 5
	},
	{
		"id": "44c09f6",
		"email": "yvette@yuprod.com",
		"name": "Yvette",
		"createdDate": "2013-03-09 14:46:01",
		"totalOrganizations": 6,
		"totalTours": 9,
		"totalDays": 318,
		"totalCountries": 3,
		"totalStates": 46,
		"totalEvents": 156,
		"eventPercent": 0.718051,
		"totalScheduleItems": 2644,
		"schedulePercent": 0.814197,
		"totalGuests": 2442,
		"guestPercent": 0.952059,
		"mostGuestDate": "2023-01-29 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 229
	},
	{
		"id": "d0c00ae",
		"email": "nick.aguigui@gmail.com",
		"name": "Nick",
		"createdDate": "2018-01-19 22:27:46",
		"totalOrganizations": 10,
		"totalTours": 14,
		"totalDays": 279,
		"totalCountries": 3,
		"totalStates": 31,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 2381,
		"schedulePercent": 0.777513,
		"totalGuests": 1606,
		"guestPercent": 0.903589,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 293
	},
	{
		"id": "08c8956",
		"email": "lasantacecilia@yahoo.com",
		"name": "Eva",
		"createdDate": "2023-05-05 20:04:59",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 281,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 183,
		"schedulePercent": 0.140908,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8dd5311",
		"email": "marsoundagency@gmail.com",
		"name": "Mario",
		"createdDate": "2018-12-19 23:34:12",
		"totalOrganizations": 3,
		"totalTours": 10,
		"totalDays": 450,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 1077,
		"schedulePercent": 0.506423,
		"totalGuests": 243,
		"guestPercent": 0.574758,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Commodore Ballroom",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 58
	},
	{
		"id": "f2d3fe7",
		"email": "Sophia.Tuffin@aco.com.au",
		"name": "Sophia",
		"createdDate": "2023-11-08 01:46:15",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 38,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 185,
		"schedulePercent": 0.142233,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c5630d9",
		"email": "hello@luovapr.com",
		"name": "Chelsea",
		"createdDate": "2023-09-29 17:29:45",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 33,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 146,
		"schedulePercent": 0.117071,
		"totalGuests": 46,
		"guestPercent": 0.415044,
		"mostGuestDate": "2023-10-23 00:00:00",
		"mostGuestName": "O2 Academy Leeds",
		"mostGuestCity": "",
		"mostGuestAmount": 24
	},
	{
		"id": "1512ea6",
		"email": "Dave@eccles.co.nz",
		"name": "Dave",
		"createdDate": "2012-12-18 19:26:58",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 102,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 467,
		"schedulePercent": 0.283406,
		"totalGuests": 462,
		"guestPercent": 0.678056,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "Christchurch Town Hall of the Performing Arts",
		"mostGuestCity": "Christchurch",
		"mostGuestAmount": 93
	},
	{
		"id": "4491f61",
		"email": "nbujisic@gmail.com",
		"name": "Nemanja",
		"createdDate": "2011-07-27 19:07:44",
		"totalOrganizations": 10,
		"totalTours": 1,
		"totalDays": 198,
		"totalCountries": 10,
		"totalStates": 22,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 969,
		"schedulePercent": 0.471063,
		"totalGuests": 622,
		"guestPercent": 0.731956,
		"mostGuestDate": "2023-09-22 00:00:00",
		"mostGuestName": "Wynn // DJ SET",
		"mostGuestCity": "Las Vegas,",
		"mostGuestAmount": 66
	},
	{
		"id": "b7ca7e2",
		"email": "josefine@luger.dk",
		"name": "Josefine",
		"createdDate": "2020-10-22 10:06:18",
		"totalOrganizations": 15,
		"totalTours": 4,
		"totalDays": 42,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 185,
		"schedulePercent": 0.142233,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4e20a3e",
		"email": "ernestTM@bigloud.com",
		"name": "Matt",
		"createdDate": "2023-02-06 21:08:27",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 340,
		"totalCountries": 5,
		"totalStates": 24,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 1704,
		"schedulePercent": 0.665607,
		"totalGuests": 1005,
		"guestPercent": 0.825056,
		"mostGuestDate": "2023-04-20 00:00:00",
		"mostGuestName": "KFC Yum! Center",
		"mostGuestCity": "Louisville",
		"mostGuestAmount": 154
	},
	{
		"id": "d20ca5c",
		"email": "ft.sabin@btinternet.com",
		"name": "Mark",
		"createdDate": "2016-03-11 00:08:22",
		"totalOrganizations": 18,
		"totalTours": 18,
		"totalDays": 436,
		"totalCountries": 9,
		"totalStates": 26,
		"totalEvents": 148,
		"eventPercent": 0.696332,
		"totalScheduleItems": 1598,
		"schedulePercent": 0.642564,
		"totalGuests": 659,
		"guestPercent": 0.741624,
		"mostGuestDate": "2023-03-23 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 62
	},
	{
		"id": "ac47493",
		"email": "tourandeventmanagement@gmail.com",
		"name": "Mark",
		"createdDate": "2015-05-18 13:22:38",
		"totalOrganizations": 18,
		"totalTours": 5,
		"totalDays": 660,
		"totalCountries": 8,
		"totalStates": 40,
		"totalEvents": 142,
		"eventPercent": 0.680705,
		"totalScheduleItems": 2291,
		"schedulePercent": 0.764005,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "Saint John at Hackney",
		"mostGuestCity": "London",
		"mostGuestAmount": 2
	},
	{
		"id": "07c04d1",
		"email": "smeunier@gestev.com",
		"name": "Sylvie",
		"createdDate": "2021-12-14 16:54:14",
		"totalOrganizations": 34,
		"totalTours": 18,
		"totalDays": 546,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 500,
		"eventPercent": 0.974176,
		"totalScheduleItems": 2486,
		"schedulePercent": 0.791551,
		"totalGuests": 4992,
		"guestPercent": 0.991922,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "ThÃ©Ã¢tre St-Denis",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 204
	},
	{
		"id": "e45beaf",
		"email": "michellepekrol@me.com",
		"name": "Michelle",
		"createdDate": "2011-07-07 22:28:59",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 104,
		"totalCountries": 5,
		"totalStates": 8,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 492,
		"schedulePercent": 0.293604,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "Royal Festival Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 4
	},
	{
		"id": "db1644f",
		"email": "thomas@fullsteam.fi",
		"name": "Thomas",
		"createdDate": "2023-06-30 09:54:50",
		"totalOrganizations": 91,
		"totalTours": 68,
		"totalDays": 489,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 487,
		"eventPercent": 0.972322,
		"totalScheduleItems": 1945,
		"schedulePercent": 0.710634,
		"totalGuests": 45,
		"guestPercent": 0.412925,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "On The Rocks",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 10
	},
	{
		"id": "a913743",
		"email": "zbtouring@gmail.com",
		"name": "Zack",
		"createdDate": "2019-03-02 01:16:17",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 798,
		"totalCountries": 16,
		"totalStates": 27,
		"totalEvents": 331,
		"eventPercent": 0.932327,
		"totalScheduleItems": 3899,
		"schedulePercent": 0.918289,
		"totalGuests": 6793,
		"guestPercent": 0.996689,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Wembley Stadium",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 625
	},
	{
		"id": "059979c",
		"email": "darrion@dlglive.net",
		"name": "Darrion",
		"createdDate": "2022-08-10 03:07:54",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 1223,
		"totalCountries": 25,
		"totalStates": 57,
		"totalEvents": 280,
		"eventPercent": 0.901867,
		"totalScheduleItems": 3820,
		"schedulePercent": 0.912859,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8dc5b3c",
		"email": "aaron.kudler@gmail.com",
		"name": "Aaron",
		"createdDate": "2013-07-09 06:35:28",
		"totalOrganizations": 12,
		"totalTours": 17,
		"totalDays": 683,
		"totalCountries": 16,
		"totalStates": 55,
		"totalEvents": 251,
		"eventPercent": 0.873792,
		"totalScheduleItems": 3386,
		"schedulePercent": 0.886505,
		"totalGuests": 633,
		"guestPercent": 0.73487,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Regent Theater DTLA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 50
	},
	{
		"id": "8e3fd7f",
		"email": "wharia@gmail.com",
		"name": "Anna",
		"createdDate": "2022-10-13 16:21:54",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 127,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 747,
		"schedulePercent": 0.400609,
		"totalGuests": 345,
		"guestPercent": 0.62601,
		"mostGuestDate": "2023-04-27 00:00:00",
		"mostGuestName": "Brooklyn Made",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 36
	},
	{
		"id": "43c234a",
		"email": "Eden@deorro.com",
		"name": "Eden ",
		"createdDate": "2022-05-23 21:45:30",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 354,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 31,
		"schedulePercent": 0.0405244,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5368173",
		"email": "liz@lizhobbsgroup.com",
		"name": "Liz",
		"createdDate": "2016-08-31 08:28:17",
		"totalOrganizations": 85,
		"totalTours": 46,
		"totalDays": 117,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 756,
		"schedulePercent": 0.405244,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Cartmel Racecourse",
		"mostGuestCity": "Grange-over-Sands",
		"mostGuestAmount": 0
	},
	{
		"id": "09f7c0f",
		"email": "joeltmfoh@gmail.com",
		"name": "Joel",
		"createdDate": "2017-04-28 05:31:28",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 748,
		"totalCountries": 4,
		"totalStates": 40,
		"totalEvents": 181,
		"eventPercent": 0.771818,
		"totalScheduleItems": 803,
		"schedulePercent": 0.421666,
		"totalGuests": 18,
		"guestPercent": 0.364455,
		"mostGuestDate": "2023-06-27 00:00:00",
		"mostGuestName": "Knitting Factory - North Hollywood",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 18
	},
	{
		"id": "155ac17",
		"email": "hopedarstmusic@gmail.com",
		"name": "Hope",
		"createdDate": "2023-04-13 01:12:38",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 513,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 262,
		"schedulePercent": 0.186465,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6a03e06",
		"email": "manny@foulplay.co",
		"name": "Manny",
		"createdDate": "2022-12-02 17:37:00",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 91,
		"totalCountries": 8,
		"totalStates": 37,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 831,
		"schedulePercent": 0.431334,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "13323ab",
		"email": "tigermixx@gmail.com",
		"name": "Adrian",
		"createdDate": "2021-09-23 22:25:14",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 731,
		"totalCountries": 5,
		"totalStates": 35,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 122,
		"schedulePercent": 0.104092,
		"totalGuests": 93,
		"guestPercent": 0.468017,
		"mostGuestDate": "2023-08-27 00:00:00",
		"mostGuestName": "The Pavilion At Toyota Music Factory",
		"mostGuestCity": "Irving",
		"mostGuestAmount": 8
	},
	{
		"id": "002ec52",
		"email": "darian@4amprojects.com",
		"name": "Darian",
		"createdDate": "2022-04-20 00:57:18",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 266,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 983,
		"schedulePercent": 0.476758,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fec17be",
		"email": "eb@atcmgmt.com",
		"name": "Edward",
		"createdDate": "2019-04-26 03:53:18",
		"totalOrganizations": 3,
		"totalTours": 11,
		"totalDays": 97,
		"totalCountries": 2,
		"totalStates": 17,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 618,
		"schedulePercent": 0.34949,
		"totalGuests": 828,
		"guestPercent": 0.78334,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "Palacio de los Deportes",
		"mostGuestCity": "Ciudad de MÃ©xico",
		"mostGuestAmount": 108
	},
	{
		"id": "12d75f6",
		"email": "coltfordproduction@gmail.com",
		"name": "Anthony",
		"createdDate": "2015-08-17 21:00:57",
		"totalOrganizations": 2,
		"totalTours": 12,
		"totalDays": 331,
		"totalCountries": 1,
		"totalStates": 30,
		"totalEvents": 188,
		"eventPercent": 0.785856,
		"totalScheduleItems": 1264,
		"schedulePercent": 0.55582,
		"totalGuests": 507,
		"guestPercent": 0.694478,
		"mostGuestDate": "2023-04-20 00:00:00",
		"mostGuestName": "Varsity Theater",
		"mostGuestCity": "Minneapolis",
		"mostGuestAmount": 62
	},
	{
		"id": "184f97d",
		"email": "Anfield@aol.com",
		"name": "Keith ",
		"createdDate": "2013-03-05 07:31:25",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 108,
		"totalCountries": 12,
		"totalStates": 33,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 623,
		"schedulePercent": 0.350682,
		"totalGuests": 947,
		"guestPercent": 0.811813,
		"mostGuestDate": "2023-04-03 00:00:00",
		"mostGuestName": "Royal Albert Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 102
	},
	{
		"id": "badeb6a",
		"email": "EQsoundz@aol.com",
		"name": "Tim",
		"createdDate": "2011-09-16 19:21:32",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 210,
		"totalCountries": 4,
		"totalStates": 30,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 947,
		"schedulePercent": 0.464707,
		"totalGuests": 333,
		"guestPercent": 0.619653,
		"mostGuestDate": "2023-08-23 00:00:00",
		"mostGuestName": "Count Basie Center for the Arts",
		"mostGuestCity": "Red Bank",
		"mostGuestAmount": 29
	},
	{
		"id": "d32dcd5",
		"email": "wilsonwendy@mac.com",
		"name": "Kathleen",
		"createdDate": "2016-03-11 00:12:23",
		"totalOrganizations": 13,
		"totalTours": 14,
		"totalDays": 820,
		"totalCountries": 16,
		"totalStates": 69,
		"totalEvents": 258,
		"eventPercent": 0.883989,
		"totalScheduleItems": 3606,
		"schedulePercent": 0.90094,
		"totalGuests": 2259,
		"guestPercent": 0.9461,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 281
	},
	{
		"id": "48d3bb2",
		"email": "cw@contrapromotion.com",
		"name": "Carsten",
		"createdDate": "2022-05-21 10:55:10",
		"totalOrganizations": 30,
		"totalTours": 31,
		"totalDays": 1681,
		"totalCountries": 11,
		"totalStates": 25,
		"totalEvents": 259,
		"eventPercent": 0.884783,
		"totalScheduleItems": 1636,
		"schedulePercent": 0.649848,
		"totalGuests": 409,
		"guestPercent": 0.657264,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "Circus Krone",
		"mostGuestCity": "MÃ¼nchen",
		"mostGuestAmount": 41
	},
	{
		"id": "c5bef6b",
		"email": "reynosoy1@gmail.com",
		"name": "Diego",
		"createdDate": "2017-02-02 23:34:01",
		"totalOrganizations": 10,
		"totalTours": 6,
		"totalDays": 146,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 886,
		"schedulePercent": 0.448682,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cbcf5ec",
		"email": "kester@jarrahrecords.com",
		"name": "Kester ",
		"createdDate": "2011-06-03 07:12:17",
		"totalOrganizations": 7,
		"totalTours": 12,
		"totalDays": 235,
		"totalCountries": 3,
		"totalStates": 9,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 1143,
		"schedulePercent": 0.524699,
		"totalGuests": 713,
		"guestPercent": 0.755794,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Palais Theatre",
		"mostGuestCity": "St Kilda",
		"mostGuestAmount": 62
	},
	{
		"id": "0202def",
		"email": "Z.Nolan@8thdaysound.com",
		"name": "Zionya",
		"createdDate": "2022-01-17 23:18:46",
		"totalOrganizations": 4,
		"totalTours": 10,
		"totalDays": 578,
		"totalCountries": 13,
		"totalStates": 56,
		"totalEvents": 163,
		"eventPercent": 0.735134,
		"totalScheduleItems": 2228,
		"schedulePercent": 0.754867,
		"totalGuests": 2070,
		"guestPercent": 0.938021,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 142
	},
	{
		"id": "5eaea8b",
		"email": "andy.flick@wmg.com",
		"name": "Andy",
		"createdDate": "2021-08-31 18:45:42",
		"totalOrganizations": 45,
		"totalTours": 44,
		"totalDays": 15011,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 1913,
		"eventPercent": 0.993908,
		"totalScheduleItems": 742,
		"schedulePercent": 0.397431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "eb759a7",
		"email": "rowan@nem.fi",
		"name": "Rowan",
		"createdDate": "2019-04-11 11:43:37",
		"totalOrganizations": 17,
		"totalTours": 17,
		"totalDays": 225,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 225,
		"eventPercent": 0.842008,
		"totalScheduleItems": 1128,
		"schedulePercent": 0.519401,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "48541df",
		"email": "willyregie@gmail.com",
		"name": "Willy",
		"createdDate": "2018-01-31 13:31:18",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 554,
		"totalCountries": 3,
		"totalStates": 35,
		"totalEvents": 187,
		"eventPercent": 0.784135,
		"totalScheduleItems": 2556,
		"schedulePercent": 0.799894,
		"totalGuests": 1589,
		"guestPercent": 0.902265,
		"mostGuestDate": "2023-03-29 00:00:00",
		"mostGuestName": "Zenith Paris - La Villette",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 203
	},
	{
		"id": "20c6e0d",
		"email": "tk@fullstopmgmt.com",
		"name": "Tina",
		"createdDate": "2021-10-12 02:57:34",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 364,
		"totalCountries": 6,
		"totalStates": 15,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 874,
		"schedulePercent": 0.444974,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1a17334",
		"email": "solanarivas@gmail.com",
		"name": "Solana",
		"createdDate": "2010-05-26 22:10:50",
		"totalOrganizations": 25,
		"totalTours": 10,
		"totalDays": 158,
		"totalCountries": 4,
		"totalStates": 26,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 991,
		"schedulePercent": 0.480996,
		"totalGuests": 311,
		"guestPercent": 0.610118,
		"mostGuestDate": "2023-10-10 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 34
	},
	{
		"id": "bdde0a4",
		"email": "sylvia@wobroberts.com",
		"name": "Sylvia",
		"createdDate": "2019-05-06 01:51:31",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 74,
		"totalCountries": 12,
		"totalStates": 17,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 629,
		"schedulePercent": 0.354125,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b09a9c3",
		"email": "DHMasterTour@gmail.com",
		"name": "Derek",
		"createdDate": "2023-06-12 20:35:49",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 177,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1286,
		"schedulePercent": 0.562177,
		"totalGuests": 1465,
		"guestPercent": 0.889683,
		"mostGuestDate": "2023-11-27 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 86
	},
	{
		"id": "f097343",
		"email": "jonny@production-worx.com",
		"name": "Jonny ",
		"createdDate": "2019-05-31 08:50:45",
		"totalOrganizations": 8,
		"totalTours": 16,
		"totalDays": 608,
		"totalCountries": 19,
		"totalStates": 62,
		"totalEvents": 297,
		"eventPercent": 0.914051,
		"totalScheduleItems": 4286,
		"schedulePercent": 0.937227,
		"totalGuests": 1041,
		"guestPercent": 0.831016,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 86
	},
	{
		"id": "f6ecd0b",
		"email": "soundman00@gmail.com",
		"name": "Charles",
		"createdDate": "2011-04-07 23:29:49",
		"totalOrganizations": 19,
		"totalTours": 9,
		"totalDays": 759,
		"totalCountries": 15,
		"totalStates": 56,
		"totalEvents": 196,
		"eventPercent": 0.798835,
		"totalScheduleItems": 3508,
		"schedulePercent": 0.894716,
		"totalGuests": 2768,
		"guestPercent": 0.963713,
		"mostGuestDate": "2023-07-03 00:00:00",
		"mostGuestName": "Banc of California Stadium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 219
	},
	{
		"id": "7936d2a",
		"email": "abate@pilobolus.org",
		"name": "Anna",
		"createdDate": "2021-08-03 15:12:46",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 388,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1177,
		"schedulePercent": 0.533704,
		"totalGuests": 233,
		"guestPercent": 0.569726,
		"mostGuestDate": "2023-03-12 00:00:00",
		"mostGuestName": "Shubert Theatre",
		"mostGuestCity": "New Haven",
		"mostGuestAmount": 18
	},
	{
		"id": "0e7e683",
		"email": "tim@candy-artists.com",
		"name": "Tim",
		"createdDate": "2021-07-16 12:40:56",
		"totalOrganizations": 6,
		"totalTours": 9,
		"totalDays": 189,
		"totalCountries": 19,
		"totalStates": 54,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 1461,
		"schedulePercent": 0.605748,
		"totalGuests": 661,
		"guestPercent": 0.742286,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 61
	},
	{
		"id": "55bfffb",
		"email": "minhzilla@gmail.com",
		"name": "Minh",
		"createdDate": "2014-08-18 04:03:09",
		"totalOrganizations": 10,
		"totalTours": 6,
		"totalDays": 158,
		"totalCountries": 14,
		"totalStates": 40,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 1299,
		"schedulePercent": 0.566018,
		"totalGuests": 699,
		"guestPercent": 0.752748,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Abbotsford Centre",
		"mostGuestCity": "Abbotsford",
		"mostGuestAmount": 66
	},
	{
		"id": "b7f5722",
		"email": "mechanicalsounds@gmail.com",
		"name": "Todd",
		"createdDate": "2017-02-08 23:27:16",
		"totalOrganizations": 14,
		"totalTours": 10,
		"totalDays": 189,
		"totalCountries": 13,
		"totalStates": 40,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 1366,
		"schedulePercent": 0.584426,
		"totalGuests": 351,
		"guestPercent": 0.630513,
		"mostGuestDate": "2023-08-10 00:00:00",
		"mostGuestName": "(Le) Poisson Rouge",
		"mostGuestCity": "New York",
		"mostGuestAmount": 37
	},
	{
		"id": "fc9109e",
		"email": "info@weareblackbox.com",
		"name": "Ian",
		"createdDate": "2014-11-10 20:35:42",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 542,
		"totalCountries": 9,
		"totalStates": 60,
		"totalEvents": 226,
		"eventPercent": 0.843464,
		"totalScheduleItems": 3030,
		"schedulePercent": 0.853794,
		"totalGuests": 1530,
		"guestPercent": 0.897762,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "Save On Foods Centre",
		"mostGuestCity": "Victoria",
		"mostGuestAmount": 50
	},
	{
		"id": "281af7a",
		"email": "Guylouis.sound@gmail.com",
		"name": "Guy",
		"createdDate": "2019-05-08 07:32:08",
		"totalOrganizations": 10,
		"totalTours": 18,
		"totalDays": 315,
		"totalCountries": 5,
		"totalStates": 29,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 548,
		"schedulePercent": 0.319958,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4f16639",
		"email": "weismanav@gmail.com",
		"name": "Adam",
		"createdDate": "2023-08-28 17:22:41",
		"totalOrganizations": 5,
		"totalTours": 14,
		"totalDays": 406,
		"totalCountries": 8,
		"totalStates": 50,
		"totalEvents": 224,
		"eventPercent": 0.841345,
		"totalScheduleItems": 2235,
		"schedulePercent": 0.755661,
		"totalGuests": 1120,
		"guestPercent": 0.8432,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "The Town Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 49
	},
	{
		"id": "0118141",
		"email": "philiphaneytm@gmail.com",
		"name": "Philip",
		"createdDate": "2017-12-27 20:46:15",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 1061,
		"totalCountries": 6,
		"totalStates": 41,
		"totalEvents": 243,
		"eventPercent": 0.864786,
		"totalScheduleItems": 5823,
		"schedulePercent": 0.978016,
		"totalGuests": 5452,
		"guestPercent": 0.993776,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 240
	},
	{
		"id": "4601f06",
		"email": "machomanandysavage@gmail.com",
		"name": "Andrew",
		"createdDate": "2016-03-25 22:50:52",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 31,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 198,
		"schedulePercent": 0.148987,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "14cd178",
		"email": "lucbeaugrand@me.com",
		"name": "Luc",
		"createdDate": "2019-08-18 17:00:39",
		"totalOrganizations": 7,
		"totalTours": 12,
		"totalDays": 323,
		"totalCountries": 3,
		"totalStates": 8,
		"totalEvents": 293,
		"eventPercent": 0.911005,
		"totalScheduleItems": 2020,
		"schedulePercent": 0.72348,
		"totalGuests": 1474,
		"guestPercent": 0.890743,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "Salle Wilfrid-Pelletier",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 293
	},
	{
		"id": "08e51d5",
		"email": "jbrist@cityparksfoundation.org",
		"name": "Jeff",
		"createdDate": "2022-04-13 17:03:03",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "95ab7d8",
		"email": "warren@thirdcoasttouring.com",
		"name": "Warren",
		"createdDate": "2023-02-12 22:50:17",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 697,
		"totalCountries": 3,
		"totalStates": 41,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 2900,
		"schedulePercent": 0.841875,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2593617",
		"email": "ajpgphoto@gmail.com",
		"name": "Amanda",
		"createdDate": "2022-02-14 22:45:10",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 661,
		"totalCountries": 2,
		"totalStates": 42,
		"totalEvents": 204,
		"eventPercent": 0.813005,
		"totalScheduleItems": 2487,
		"schedulePercent": 0.791816,
		"totalGuests": 936,
		"guestPercent": 0.808635,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 74
	},
	{
		"id": "16af323",
		"email": "chealbrighton@me.com",
		"name": "Che",
		"createdDate": "2016-05-12 15:23:00",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 151,
		"totalCountries": 11,
		"totalStates": 34,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 994,
		"schedulePercent": 0.482585,
		"totalGuests": 219,
		"guestPercent": 0.562575,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Bath",
		"mostGuestAmount": 41
	},
	{
		"id": "28578a9",
		"email": "leslie@newentertainmentcompany.com",
		"name": "Leslie",
		"createdDate": "2018-08-16 17:04:49",
		"totalOrganizations": 10,
		"totalTours": 4,
		"totalDays": 114,
		"totalCountries": 6,
		"totalStates": 25,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 481,
		"schedulePercent": 0.289101,
		"totalGuests": 639,
		"guestPercent": 0.735797,
		"mostGuestDate": "2023-04-27 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 189
	},
	{
		"id": "896d69b",
		"email": "laurenalbanese12@gmail.com",
		"name": "Lauren",
		"createdDate": "2018-06-19 17:22:33",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 30,
		"totalCountries": 8,
		"totalStates": 9,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 240,
		"schedulePercent": 0.175738,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d723906",
		"email": "stuschuster1@gmail.com",
		"name": "Stu",
		"createdDate": "2017-04-08 01:00:54",
		"totalOrganizations": 6,
		"totalTours": 16,
		"totalDays": 476,
		"totalCountries": 12,
		"totalStates": 58,
		"totalEvents": 216,
		"eventPercent": 0.831943,
		"totalScheduleItems": 2710,
		"schedulePercent": 0.820686,
		"totalGuests": 278,
		"guestPercent": 0.593564,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "MTELUS",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 21
	},
	{
		"id": "537386b",
		"email": "eivind@artist.vision",
		"name": "Eivind",
		"createdDate": "2017-06-20 11:29:10",
		"totalOrganizations": 10,
		"totalTours": 4,
		"totalDays": 204,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 141,
		"eventPercent": 0.678321,
		"totalScheduleItems": 1098,
		"schedulePercent": 0.511323,
		"totalGuests": 45,
		"guestPercent": 0.412925,
		"mostGuestDate": "2023-04-17 00:00:00",
		"mostGuestName": "Whisky a Go Go",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 16
	},
	{
		"id": "b42aae2",
		"email": "joshtrymusic@gmail.com",
		"name": "Josh",
		"createdDate": "2019-01-24 15:56:17",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 158,
		"totalCountries": 3,
		"totalStates": 20,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 821,
		"schedulePercent": 0.426831,
		"totalGuests": 323,
		"guestPercent": 0.61568,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "London Music Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 34
	},
	{
		"id": "9687482",
		"email": "colinrogers1@gmail.com",
		"name": "Colin",
		"createdDate": "2010-06-07 16:48:21",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 58,
		"totalCountries": 5,
		"totalStates": 6,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 359,
		"schedulePercent": 0.23732,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4d914d6",
		"email": "securtis@gmail.com",
		"name": "Suzie",
		"createdDate": "2021-09-16 12:59:29",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 66,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 523,
		"schedulePercent": 0.308701,
		"totalGuests": 48,
		"guestPercent": 0.419415,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Sydney Opera House",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 19
	},
	{
		"id": "0d6f59b",
		"email": "Lynjeffs@gmail.com",
		"name": "Lyn",
		"createdDate": "2018-10-01 12:16:02",
		"totalOrganizations": 5,
		"totalTours": 12,
		"totalDays": 311,
		"totalCountries": 16,
		"totalStates": 68,
		"totalEvents": 202,
		"eventPercent": 0.810091,
		"totalScheduleItems": 2213,
		"schedulePercent": 0.751953,
		"totalGuests": 1622,
		"guestPercent": 0.905443,
		"mostGuestDate": "2023-04-22 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 63
	},
	{
		"id": "b85db0e",
		"email": "toddsteelyontour@gmail.com",
		"name": "Todd",
		"createdDate": "2013-10-28 20:23:51",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 2,
		"totalStates": 33,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 2337,
		"schedulePercent": 0.772348,
		"totalGuests": 830,
		"guestPercent": 0.783472,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Hard Rock Casino Cincinnati",
		"mostGuestCity": "Cincinnati",
		"mostGuestAmount": 40
	},
	{
		"id": "2229954",
		"email": "patrickffaa@gmail.com",
		"name": "Pat",
		"createdDate": "2015-02-08 15:09:51",
		"totalOrganizations": 7,
		"totalTours": 12,
		"totalDays": 296,
		"totalCountries": 14,
		"totalStates": 61,
		"totalEvents": 177,
		"eventPercent": 0.765594,
		"totalScheduleItems": 2241,
		"schedulePercent": 0.756589,
		"totalGuests": 2063,
		"guestPercent": 0.937624,
		"mostGuestDate": "2023-03-21 00:00:00",
		"mostGuestName": "1720",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 67
	},
	{
		"id": "0dabe7c",
		"email": "coryleechenoweth@gmail.com",
		"name": "Cory",
		"createdDate": "2021-12-29 04:24:45",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 286,
		"totalCountries": 2,
		"totalStates": 35,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 1560,
		"schedulePercent": 0.632367,
		"totalGuests": 46,
		"guestPercent": 0.415044,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Marathon Music Works",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 43
	},
	{
		"id": "1f1ff22",
		"email": "james.paul.riley@gmail.com",
		"name": "James",
		"createdDate": "2015-11-10 00:39:57",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 109,
		"totalCountries": 10,
		"totalStates": 33,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 700,
		"schedulePercent": 0.380744,
		"totalGuests": 134,
		"guestPercent": 0.501258,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Soma",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 77
	},
	{
		"id": "57964bf",
		"email": "rolotomassi@gmail.com",
		"name": "James",
		"createdDate": "2022-07-28 12:41:18",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 28,
		"totalCountries": 4,
		"totalStates": 10,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 178,
		"schedulePercent": 0.137333,
		"totalGuests": 94,
		"guestPercent": 0.469474,
		"mostGuestDate": "2023-06-15 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 19
	},
	{
		"id": "2fc8847",
		"email": "jamesgallo20@gmail.com",
		"name": "James",
		"createdDate": "2022-06-19 22:36:54",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 26,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 484,
		"schedulePercent": 0.290558,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f1fae76",
		"email": "ccorystone@aol.com",
		"name": "Cory",
		"createdDate": "2013-06-21 03:00:15",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 740,
		"totalCountries": 9,
		"totalStates": 51,
		"totalEvents": 241,
		"eventPercent": 0.862667,
		"totalScheduleItems": 2240,
		"schedulePercent": 0.756191,
		"totalGuests": 645,
		"guestPercent": 0.737651,
		"mostGuestDate": "2023-01-04 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 94
	},
	{
		"id": "ad1f2dd",
		"email": "yasmin@lostmotel.com",
		"name": "Yasmin ",
		"createdDate": "2020-02-09 21:59:19",
		"totalOrganizations": 34,
		"totalTours": 34,
		"totalDays": 1715,
		"totalCountries": 22,
		"totalStates": 98,
		"totalEvents": 535,
		"eventPercent": 0.977751,
		"totalScheduleItems": 7566,
		"schedulePercent": 0.991657,
		"totalGuests": 2333,
		"guestPercent": 0.948616,
		"mostGuestDate": "2023-06-01 00:00:00",
		"mostGuestName": "The Enmore Theatre",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 107
	},
	{
		"id": "f3372a9",
		"email": "xlittlemonsterx@gmail.com",
		"name": "Christopher",
		"createdDate": "2014-04-12 17:08:18",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 84,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 718,
		"schedulePercent": 0.387498,
		"totalGuests": 637,
		"guestPercent": 0.735532,
		"mostGuestDate": "2023-03-21 00:00:00",
		"mostGuestName": "Irving Plaza",
		"mostGuestCity": "New York",
		"mostGuestAmount": 47
	},
	{
		"id": "dea685b",
		"email": "sjbanko@gmail.com",
		"name": "Simon",
		"createdDate": "2010-01-11 16:47:38",
		"totalOrganizations": 52,
		"totalTours": 30,
		"totalDays": 596,
		"totalCountries": 23,
		"totalStates": 57,
		"totalEvents": 273,
		"eventPercent": 0.896305,
		"totalScheduleItems": 4108,
		"schedulePercent": 0.927957,
		"totalGuests": 2386,
		"guestPercent": 0.950205,
		"mostGuestDate": "2023-04-15 00:00:00",
		"mostGuestName": "OVO Arena",
		"mostGuestCity": "London",
		"mostGuestAmount": 113
	},
	{
		"id": "7e18fe6",
		"email": "jennifer@qmanagementgroup.com",
		"name": "Jennifer",
		"createdDate": "2013-10-21 19:55:37",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 395,
		"totalCountries": 2,
		"totalStates": 47,
		"totalEvents": 253,
		"eventPercent": 0.877765,
		"totalScheduleItems": 3763,
		"schedulePercent": 0.910078,
		"totalGuests": 1025,
		"guestPercent": 0.828235,
		"mostGuestDate": "2023-02-21 00:00:00",
		"mostGuestName": "Eagles Ballroom",
		"mostGuestCity": "Milwaukee",
		"mostGuestAmount": 63
	},
	{
		"id": "81763ab",
		"email": "dcpetestahl@icloud.com",
		"name": "Peter",
		"createdDate": "2014-10-20 19:15:48",
		"totalOrganizations": 9,
		"totalTours": 14,
		"totalDays": 519,
		"totalCountries": 13,
		"totalStates": 58,
		"totalEvents": 282,
		"eventPercent": 0.904119,
		"totalScheduleItems": 4215,
		"schedulePercent": 0.934313,
		"totalGuests": 1726,
		"guestPercent": 0.914846,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "AB Banan-Kompaniet",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 84
	},
	{
		"id": "78c8ba2",
		"email": "daniel@merchworld.se",
		"name": "Daniel",
		"createdDate": "2015-05-03 21:56:43",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 639,
		"totalCountries": 12,
		"totalStates": 40,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 1189,
		"schedulePercent": 0.537015,
		"totalGuests": 447,
		"guestPercent": 0.672096,
		"mostGuestDate": "2023-11-06 00:00:00",
		"mostGuestName": "The Theatre at Ace Hotel",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 44
	},
	{
		"id": "dca5123",
		"email": "philip.brillo@gmail.com",
		"name": "Philip",
		"createdDate": "2017-05-27 01:30:18",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 1018,
		"totalCountries": 14,
		"totalStates": 44,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 1325,
		"schedulePercent": 0.573037,
		"totalGuests": 546,
		"guestPercent": 0.707853,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Annexet",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 155
	},
	{
		"id": "fd5dd93",
		"email": "lymansierra@gmail.com",
		"name": "Sierra",
		"createdDate": "2022-10-20 18:22:02",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 90,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 353,
		"schedulePercent": 0.234936,
		"totalGuests": 21,
		"guestPercent": 0.371342,
		"mostGuestDate": "2023-07-27 00:00:00",
		"mostGuestName": "Coca-Cola Roxy",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 13
	},
	{
		"id": "b3690ba",
		"email": "Laura@ascot-travel.com",
		"name": "Laura",
		"createdDate": "2019-12-31 22:25:07",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 328,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 1680,
		"schedulePercent": 0.659913,
		"totalGuests": 974,
		"guestPercent": 0.818037,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 78
	},
	{
		"id": "057f2bd",
		"email": "Hallee@g4dproductions.com",
		"name": "Hallee",
		"createdDate": "2023-06-16 13:18:22",
		"totalOrganizations": 3,
		"totalTours": 13,
		"totalDays": 247,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 621,
		"schedulePercent": 0.35002,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f0868bd",
		"email": "fortunateyouth@gmail.com",
		"name": "Fortunate",
		"createdDate": "2017-02-27 21:45:30",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 110,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 932,
		"schedulePercent": 0.460469,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d1a0328",
		"email": "ryan@ryanlindsaymusic.com",
		"name": "Ryan",
		"createdDate": "2021-08-15 18:05:05",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 86,
		"totalCountries": 3,
		"totalStates": 5,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 598,
		"schedulePercent": 0.339955,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "NewRoads Performing Arts Centre",
		"mostGuestCity": "Newmarket",
		"mostGuestAmount": 12
	},
	{
		"id": "271aad5",
		"email": "emilymiller2049@gmail.com",
		"name": "Emily",
		"createdDate": "2022-06-06 22:10:28",
		"totalOrganizations": 5,
		"totalTours": 14,
		"totalDays": 781,
		"totalCountries": 11,
		"totalStates": 54,
		"totalEvents": 228,
		"eventPercent": 0.845848,
		"totalScheduleItems": 3188,
		"schedulePercent": 0.868759,
		"totalGuests": 1365,
		"guestPercent": 0.879354,
		"mostGuestDate": "2023-04-08 00:00:00",
		"mostGuestName": "MGM Music Hall at Fenway",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 86
	},
	{
		"id": "ae463a0",
		"email": "Anic3entertainment@gmail.com",
		"name": "Alisha",
		"createdDate": "2022-03-02 01:38:27",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 566,
		"totalCountries": 4,
		"totalStates": 40,
		"totalEvents": 213,
		"eventPercent": 0.827837,
		"totalScheduleItems": 5271,
		"schedulePercent": 0.969408,
		"totalGuests": 698,
		"guestPercent": 0.752218,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Barclays Center",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 76
	},
	{
		"id": "0ad4f41",
		"email": "kosta.zafiriou@bottomrow.com",
		"name": "Kosta",
		"createdDate": "2013-08-22 13:36:49",
		"totalOrganizations": 2,
		"totalTours": 10,
		"totalDays": 74,
		"totalCountries": 9,
		"totalStates": 21,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 621,
		"schedulePercent": 0.35002,
		"totalGuests": 21,
		"guestPercent": 0.371342,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "The Factory in Deep Ellum",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 15
	},
	{
		"id": "78732a8",
		"email": "kate@big-agenda.com",
		"name": "Kate",
		"createdDate": "2023-07-25 17:58:27",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 21,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 150,
		"schedulePercent": 0.120117,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "57d999d",
		"email": "gaia@auguriproductions.com",
		"name": "Blotas",
		"createdDate": "2018-01-04 14:13:41",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 904,
		"totalCountries": 6,
		"totalStates": 28,
		"totalEvents": 207,
		"eventPercent": 0.817905,
		"totalScheduleItems": 3053,
		"schedulePercent": 0.856443,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "32edcaa",
		"email": "feirinwilson@gmail.com",
		"name": "Feirin",
		"createdDate": "2019-06-20 02:56:06",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 361,
		"totalCountries": 6,
		"totalStates": 30,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 3331,
		"schedulePercent": 0.88081,
		"totalGuests": 4773,
		"guestPercent": 0.990332,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 240
	},
	{
		"id": "3e49fe2",
		"email": "nachpi@juno.com",
		"name": "Andrew",
		"createdDate": "2017-04-17 20:01:32",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 364,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1345,
		"schedulePercent": 0.579129,
		"totalGuests": 439,
		"guestPercent": 0.668256,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Coachman Park Amphitheater",
		"mostGuestCity": "Clearwater",
		"mostGuestAmount": 31
	},
	{
		"id": "8092bb4",
		"email": "proaudiodude@cox.net",
		"name": "Jason",
		"createdDate": "2017-05-03 00:40:05",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 189,
		"totalCountries": 2,
		"totalStates": 39,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1425,
		"schedulePercent": 0.597802,
		"totalGuests": 1486,
		"guestPercent": 0.892465,
		"mostGuestDate": "2023-01-29 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 229
	},
	{
		"id": "fc43476",
		"email": "ibruder@cityparksfoundation.org",
		"name": "Isabella",
		"createdDate": "2022-03-09 17:42:01",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1450d6d",
		"email": "wayne.strummer@gmail.com",
		"name": "Wayne",
		"createdDate": "2023-01-03 17:15:43",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 125,
		"totalCountries": 1,
		"totalStates": 29,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 951,
		"schedulePercent": 0.465634,
		"totalGuests": 667,
		"guestPercent": 0.743478,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "The Paramount Theatre",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 47
	},
	{
		"id": "9800a3d",
		"email": "mitch@moonfever.com",
		"name": "Mitch",
		"createdDate": "2020-12-29 19:05:47",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 368,
		"totalCountries": 1,
		"totalStates": 30,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 117,
		"schedulePercent": 0.100914,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f4bfefb",
		"email": "Shaunfuller@live.com",
		"name": "Shaun",
		"createdDate": "2016-10-18 07:09:29",
		"totalOrganizations": 15,
		"totalTours": 34,
		"totalDays": 446,
		"totalCountries": 17,
		"totalStates": 66,
		"totalEvents": 275,
		"eventPercent": 0.897629,
		"totalScheduleItems": 2836,
		"schedulePercent": 0.836578,
		"totalGuests": 675,
		"guestPercent": 0.745597,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The Metro Theatre",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 65
	},
	{
		"id": "57a92bf",
		"email": "coordinator@gentrytouring.com",
		"name": "Tiffany",
		"createdDate": "2021-09-18 20:15:17",
		"totalOrganizations": 10,
		"totalTours": 9,
		"totalDays": 1457,
		"totalCountries": 16,
		"totalStates": 53,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 2955,
		"schedulePercent": 0.84757,
		"totalGuests": 852,
		"guestPercent": 0.787975,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "The New Parish",
		"mostGuestCity": "Oakland",
		"mostGuestAmount": 273
	},
	{
		"id": "76d1863",
		"email": "bradleycoxmusic@gmail.com",
		"name": "Brad",
		"createdDate": "2019-12-04 11:35:05",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 704,
		"schedulePercent": 0.382201,
		"totalGuests": 344,
		"guestPercent": 0.624685,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Manning Bar",
		"mostGuestCity": "Camperdown",
		"mostGuestAmount": 50
	},
	{
		"id": "9d2f185",
		"email": "tim@makeoutmusic.co",
		"name": "Tim",
		"createdDate": "2023-03-01 23:24:24",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 40,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 1,
		"schedulePercent": 0.00741624,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d43e759",
		"email": "carolyn@iconconcerts.com",
		"name": "Carolyn",
		"createdDate": "2022-08-23 17:52:36",
		"totalOrganizations": 7,
		"totalTours": 2,
		"totalDays": 365,
		"totalCountries": 6,
		"totalStates": 41,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 1432,
		"schedulePercent": 0.599656,
		"totalGuests": 764,
		"guestPercent": 0.76758,
		"mostGuestDate": "2023-11-26 00:00:00",
		"mostGuestName": "Toyota Arena",
		"mostGuestCity": "Ontario",
		"mostGuestAmount": 176
	},
	{
		"id": "e5cbf12",
		"email": "scott@barkhamcreative.com",
		"name": "Michael",
		"createdDate": "2015-08-12 08:21:07",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 387,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 504,
		"schedulePercent": 0.30049,
		"totalGuests": 421,
		"guestPercent": 0.662032,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 121
	},
	{
		"id": "ccd2c68",
		"email": "dre@drevelarde.com",
		"name": "andrea",
		"createdDate": "2019-07-23 19:33:32",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 163,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 365,
		"schedulePercent": 0.24063,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a04ffc9",
		"email": "chrismichener@gmail.com",
		"name": "Christopher",
		"createdDate": "2015-09-08 17:41:20",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 438,
		"totalCountries": 16,
		"totalStates": 59,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 2311,
		"schedulePercent": 0.766521,
		"totalGuests": 688,
		"guestPercent": 0.750364,
		"mostGuestDate": "2023-09-09 00:00:00",
		"mostGuestName": "The Paramount",
		"mostGuestCity": "Huntington",
		"mostGuestAmount": 43
	},
	{
		"id": "0e86caf",
		"email": "tcwillia3@gmail.com",
		"name": "Taylor",
		"createdDate": "2022-03-29 22:33:52",
		"totalOrganizations": 6,
		"totalTours": 17,
		"totalDays": 653,
		"totalCountries": 15,
		"totalStates": 49,
		"totalEvents": 175,
		"eventPercent": 0.761753,
		"totalScheduleItems": 2662,
		"schedulePercent": 0.815256,
		"totalGuests": 361,
		"guestPercent": 0.635413,
		"mostGuestDate": "2023-05-23 00:00:00",
		"mostGuestName": "The Warfield",
		"mostGuestCity": "San Francisco",
		"mostGuestAmount": 63
	},
	{
		"id": "ce4450a",
		"email": "mattguice@mac.com",
		"name": "Matthew",
		"createdDate": "2013-07-09 01:21:17",
		"totalOrganizations": 22,
		"totalTours": 15,
		"totalDays": 1260,
		"totalCountries": 5,
		"totalStates": 49,
		"totalEvents": 265,
		"eventPercent": 0.890743,
		"totalScheduleItems": 4181,
		"schedulePercent": 0.933121,
		"totalGuests": 655,
		"guestPercent": 0.740697,
		"mostGuestDate": "2023-05-21 00:00:00",
		"mostGuestName": "Calvary Community Church",
		"mostGuestCity": "Westlake Village",
		"mostGuestAmount": 47
	},
	{
		"id": "f93c558",
		"email": "james.baseley@me.com",
		"name": "James",
		"createdDate": "2012-03-19 21:03:35",
		"totalOrganizations": 5,
		"totalTours": 14,
		"totalDays": 779,
		"totalCountries": 12,
		"totalStates": 25,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 1644,
		"schedulePercent": 0.651967,
		"totalGuests": 190,
		"guestPercent": 0.542445,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "22e8c90",
		"email": "maja.ostling@blixten.se",
		"name": "Email",
		"createdDate": "2017-04-18 09:14:41",
		"totalOrganizations": 48,
		"totalTours": 11,
		"totalDays": 132,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 832,
		"schedulePercent": 0.431731,
		"totalGuests": 150,
		"guestPercent": 0.514766,
		"mostGuestDate": "2023-01-05 00:00:00",
		"mostGuestName": "Pustervik",
		"mostGuestCity": "Gothenburg",
		"mostGuestAmount": 75
	},
	{
		"id": "050a68a",
		"email": "pjrice24@gmail.com",
		"name": "Patrick",
		"createdDate": "2020-09-03 16:00:45",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 111,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 799,
		"schedulePercent": 0.420209,
		"totalGuests": 283,
		"guestPercent": 0.595153,
		"mostGuestDate": "2023-10-03 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 76
	},
	{
		"id": "117876f",
		"email": "Fkowl1@aol.com",
		"name": "Fred",
		"createdDate": "2009-10-23 00:08:58",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 85,
		"totalCountries": 7,
		"totalStates": 8,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 327,
		"schedulePercent": 0.222752,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "90e6f74",
		"email": "rick@eighteentwentysix.com",
		"name": "Rick",
		"createdDate": "2021-09-02 06:02:15",
		"totalOrganizations": 8,
		"totalTours": 12,
		"totalDays": 1025,
		"totalCountries": 13,
		"totalStates": 41,
		"totalEvents": 218,
		"eventPercent": 0.834724,
		"totalScheduleItems": 2453,
		"schedulePercent": 0.786518,
		"totalGuests": 745,
		"guestPercent": 0.76321,
		"mostGuestDate": "2023-05-04 00:00:00",
		"mostGuestName": "Radio City Music Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 94
	},
	{
		"id": "9ad44e9",
		"email": "cassandra.odell@livenation.se",
		"name": "Cassandra",
		"createdDate": "2022-03-02 13:22:19",
		"totalOrganizations": 89,
		"totalTours": 82,
		"totalDays": 1288,
		"totalCountries": 16,
		"totalStates": 70,
		"totalEvents": 894,
		"eventPercent": 0.989008,
		"totalScheduleItems": 6177,
		"schedulePercent": 0.981857,
		"totalGuests": 614,
		"guestPercent": 0.72997,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "Mosebacketerrassen",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 66
	},
	{
		"id": "eca11e7",
		"email": "Max@hwy-x.com",
		"name": "Max",
		"createdDate": "2021-08-16 19:24:03",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 47,
		"totalCountries": 3,
		"totalStates": 5,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 149,
		"schedulePercent": 0.118925,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "Royal Festival Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 4
	},
	{
		"id": "6906253",
		"email": "Nicole.Baturin@unifiedmusicgroup.com",
		"name": "Nicole",
		"createdDate": "2023-02-06 21:12:32",
		"totalOrganizations": 6,
		"totalTours": 14,
		"totalDays": 346,
		"totalCountries": 3,
		"totalStates": 49,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 1489,
		"schedulePercent": 0.615415,
		"totalGuests": 681,
		"guestPercent": 0.748643,
		"mostGuestDate": "2023-10-04 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 71
	},
	{
		"id": "40a521e",
		"email": "karel@stage-access.com",
		"name": "Karel",
		"createdDate": "2019-10-04 18:37:21",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 199,
		"totalCountries": 7,
		"totalStates": 32,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 857,
		"schedulePercent": 0.439544,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ea7a608",
		"email": "Anoidinc01@gmail.com",
		"name": "Dion",
		"createdDate": "2015-09-24 04:27:51",
		"totalOrganizations": 27,
		"totalTours": 32,
		"totalDays": 469,
		"totalCountries": 11,
		"totalStates": 25,
		"totalEvents": 197,
		"eventPercent": 0.800821,
		"totalScheduleItems": 2662,
		"schedulePercent": 0.815256,
		"totalGuests": 455,
		"guestPercent": 0.675805,
		"mostGuestDate": "2023-04-20 00:00:00",
		"mostGuestName": "The Corner Hotel",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 87
	},
	{
		"id": "05da9fe",
		"email": "manny@ayslivesound.com",
		"name": "Manny",
		"createdDate": "2021-09-09 03:19:26",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 733,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 668,
		"schedulePercent": 0.368296,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "dce680e",
		"email": "Lipsmanagement@hellfireltd.com",
		"name": "Scott",
		"createdDate": "2017-07-24 20:39:01",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 90,
		"totalCountries": 4,
		"totalStates": 31,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 679,
		"schedulePercent": 0.371871,
		"totalGuests": 1284,
		"guestPercent": 0.869421,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "The Tulsa Theater",
		"mostGuestCity": "Tulsa",
		"mostGuestAmount": 147
	},
	{
		"id": "e3379fb",
		"email": "katharina_adler@gmx.net",
		"name": "Katharina",
		"createdDate": "2023-04-05 11:10:48",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 89,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 981,
		"schedulePercent": 0.475831,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a8bd27c",
		"email": "pietari.reijonen@gmail.com",
		"name": "Pietari",
		"createdDate": "2023-01-10 12:37:46",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 124,
		"totalCountries": 9,
		"totalStates": 68,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 471,
		"schedulePercent": 0.28473,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6a15f24",
		"email": "leshg1206@gmail.com",
		"name": "Grahame",
		"createdDate": "2021-04-28 22:54:35",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 37,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 272,
		"schedulePercent": 0.193749,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "The Capitol Theatre",
		"mostGuestCity": "Port Chester",
		"mostGuestAmount": 9
	},
	{
		"id": "76a030c",
		"email": "lindarockermom@gmail.com",
		"name": "Linda",
		"createdDate": "2018-06-12 01:16:09",
		"totalOrganizations": 2,
		"totalTours": 9,
		"totalDays": 92,
		"totalCountries": 7,
		"totalStates": 17,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 357,
		"schedulePercent": 0.236393,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3f545e5",
		"email": "jgriffin5@me.com",
		"name": "Jeff",
		"createdDate": "2013-07-19 21:46:47",
		"totalOrganizations": 8,
		"totalTours": 2,
		"totalDays": 422,
		"totalCountries": 4,
		"totalStates": 38,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 2662,
		"schedulePercent": 0.815256,
		"totalGuests": 2445,
		"guestPercent": 0.952192,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Truist Park",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 168
	},
	{
		"id": "7332ef4",
		"email": "hlubov@cityparksfoundation.org",
		"name": "Heather",
		"createdDate": "2017-06-14 17:38:38",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5118482",
		"email": "christhorsteinson@gmail.com",
		"name": "Chris",
		"createdDate": "2014-04-06 22:34:16",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 177,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1029,
		"schedulePercent": 0.492385,
		"totalGuests": 100,
		"guestPercent": 0.473977,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "Chatham Capitol Theatre",
		"mostGuestCity": "Chatham",
		"mostGuestAmount": 23
	},
	{
		"id": "1e4ea51",
		"email": "justin@brilliantcorners.com",
		"name": "Justin",
		"createdDate": "2019-01-24 22:09:30",
		"totalOrganizations": 19,
		"totalTours": 33,
		"totalDays": 763,
		"totalCountries": 17,
		"totalStates": 85,
		"totalEvents": 399,
		"eventPercent": 0.957092,
		"totalScheduleItems": 4807,
		"schedulePercent": 0.955238,
		"totalGuests": 2286,
		"guestPercent": 0.946894,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 59
	},
	{
		"id": "d4c804b",
		"email": "allison.forslund@gmail.com",
		"name": "Allison",
		"createdDate": "2022-06-22 02:35:00",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 4,
		"totalStates": 39,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 2707,
		"schedulePercent": 0.819494,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0d71af8",
		"email": "nikkita@resistancerd.com",
		"name": "Nikkita",
		"createdDate": "2021-09-20 19:50:13",
		"totalOrganizations": 7,
		"totalTours": 14,
		"totalDays": 1028,
		"totalCountries": 10,
		"totalStates": 44,
		"totalEvents": 221,
		"eventPercent": 0.83777,
		"totalScheduleItems": 2575,
		"schedulePercent": 0.802278,
		"totalGuests": 235,
		"guestPercent": 0.57052,
		"mostGuestDate": "2023-05-25 00:00:00",
		"mostGuestName": "The Regent",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 68
	},
	{
		"id": "eb8b1e4",
		"email": "rcaldeo@gmail.com",
		"name": "Ranel",
		"createdDate": "2009-10-24 17:40:08",
		"totalOrganizations": 11,
		"totalTours": 6,
		"totalDays": 910,
		"totalCountries": 14,
		"totalStates": 65,
		"totalEvents": 254,
		"eventPercent": 0.879619,
		"totalScheduleItems": 4956,
		"schedulePercent": 0.960667,
		"totalGuests": 6320,
		"guestPercent": 0.996159,
		"mostGuestDate": "2023-07-03 00:00:00",
		"mostGuestName": "Banc of California Stadium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 219
	},
	{
		"id": "379f6c8",
		"email": "jesse.barron.totp@gmail.com",
		"name": "Jesse",
		"createdDate": "2022-04-18 15:20:07",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 212,
		"totalCountries": 7,
		"totalStates": 34,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 754,
		"schedulePercent": 0.404185,
		"totalGuests": 334,
		"guestPercent": 0.620183,
		"mostGuestDate": "2023-06-14 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 138
	},
	{
		"id": "ad768c5",
		"email": "Thaddeustrw@gmail.com",
		"name": "thaddeus",
		"createdDate": "2021-07-08 00:07:00",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 1382,
		"totalCountries": 1,
		"totalStates": 25,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 2319,
		"schedulePercent": 0.768243,
		"totalGuests": 485,
		"guestPercent": 0.685207,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Macon Centreplex- Coliseum",
		"mostGuestCity": "Macon",
		"mostGuestAmount": 51
	},
	{
		"id": "ec2f7b0",
		"email": "liporacedaniella@gmail.com",
		"name": "Daniella",
		"createdDate": "2023-05-23 17:38:35",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 221,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 610,
		"schedulePercent": 0.345914,
		"totalGuests": 42,
		"guestPercent": 0.40882,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "The Windjammer",
		"mostGuestCity": "Isle of Palms",
		"mostGuestAmount": 21
	},
	{
		"id": "840b52d",
		"email": "alihardaway94@gmail.com",
		"name": "Ali",
		"createdDate": "2023-11-06 03:49:47",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 84,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 700,
		"schedulePercent": 0.380744,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "74e4216",
		"email": "knixmusic7@gmail.com",
		"name": "Kyle ",
		"createdDate": "2022-03-25 14:08:05",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 618,
		"totalCountries": 1,
		"totalStates": 26,
		"totalEvents": 156,
		"eventPercent": 0.718051,
		"totalScheduleItems": 1391,
		"schedulePercent": 0.589193,
		"totalGuests": 996,
		"guestPercent": 0.822805,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "BOK Center",
		"mostGuestCity": "Tulsa",
		"mostGuestAmount": 122
	},
	{
		"id": "9a89ed3",
		"email": "Tim.lougee@gmail.com",
		"name": "Tim",
		"createdDate": "2019-01-11 13:26:08",
		"totalOrganizations": 11,
		"totalTours": 8,
		"totalDays": 960,
		"totalCountries": 13,
		"totalStates": 45,
		"totalEvents": 174,
		"eventPercent": 0.759502,
		"totalScheduleItems": 2178,
		"schedulePercent": 0.747053,
		"totalGuests": 29,
		"guestPercent": 0.387896,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "San Antonio Stock Show & Rodeo",
		"mostGuestCity": "San Antonio",
		"mostGuestAmount": 25
	},
	{
		"id": "d6922c2",
		"email": "stuart@jaystreet.net",
		"name": "Stuart",
		"createdDate": "2020-10-27 05:55:18",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 547,
		"totalCountries": 18,
		"totalStates": 53,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1820,
		"schedulePercent": 0.68918,
		"totalGuests": 602,
		"guestPercent": 0.726791,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 130
	},
	{
		"id": "5a1aba7",
		"email": "3D.touring@gmail.com",
		"name": "Mark",
		"createdDate": "2017-01-30 19:00:07",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 234,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 232,
		"schedulePercent": 0.171103,
		"totalGuests": 104,
		"guestPercent": 0.478877,
		"mostGuestDate": "2023-11-26 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 41
	},
	{
		"id": "bb2ef66",
		"email": "info@lifelight-design.com",
		"name": "Kilian",
		"createdDate": "2018-11-04 21:00:27",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 150,
		"totalCountries": 8,
		"totalStates": 18,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 992,
		"schedulePercent": 0.481658,
		"totalGuests": 880,
		"guestPercent": 0.794464,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 91
	},
	{
		"id": "d70e4f0",
		"email": "chardstuff@aol.com",
		"name": "Richard",
		"createdDate": "2009-08-28 05:08:54",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 236,
		"totalCountries": 6,
		"totalStates": 34,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 1067,
		"schedulePercent": 0.503642,
		"totalGuests": 539,
		"guestPercent": 0.705602,
		"mostGuestDate": "2023-04-19 00:00:00",
		"mostGuestName": "Schermerhorn Symphony Hall",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 34
	},
	{
		"id": "a33e40b",
		"email": "xtina@theblindyouth.com",
		"name": "Xtina",
		"createdDate": "2016-05-29 05:21:14",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 432,
		"totalCountries": 5,
		"totalStates": 18,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 500,
		"schedulePercent": 0.298504,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "31cf15d",
		"email": "sarah@mickperrin.com",
		"name": "Sarah ",
		"createdDate": "2019-04-26 19:56:00",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 105,
		"totalCountries": 3,
		"totalStates": 22,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 722,
		"schedulePercent": 0.389485,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-09-11 00:00:00",
		"mostGuestName": "Warner Theatre",
		"mostGuestCity": "Washington",
		"mostGuestAmount": 2
	},
	{
		"id": "7a983a8",
		"email": "diego@brocolirecords.com",
		"name": "Diego",
		"createdDate": "2022-10-06 23:44:54",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 178,
		"totalCountries": 7,
		"totalStates": 29,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 617,
		"schedulePercent": 0.348431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4030585",
		"email": "jon@kelpmanagement.com",
		"name": "Jon",
		"createdDate": "2018-08-29 06:47:22",
		"totalOrganizations": 10,
		"totalTours": 11,
		"totalDays": 984,
		"totalCountries": 16,
		"totalStates": 45,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 1427,
		"schedulePercent": 0.598464,
		"totalGuests": 156,
		"guestPercent": 0.518607,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Northcote Theatre",
		"mostGuestCity": "Northcote",
		"mostGuestAmount": 47
	},
	{
		"id": "79dd294",
		"email": "info@fmly.agency",
		"name": "FMLY",
		"createdDate": "2021-10-25 10:46:53",
		"totalOrganizations": 13,
		"totalTours": 21,
		"totalDays": 451,
		"totalCountries": 21,
		"totalStates": 64,
		"totalEvents": 184,
		"eventPercent": 0.77791,
		"totalScheduleItems": 1748,
		"schedulePercent": 0.67448,
		"totalGuests": 470,
		"guestPercent": 0.680969,
		"mostGuestDate": "2023-09-29 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 68
	},
	{
		"id": "2e7d1df",
		"email": "benncav@gmail.com",
		"name": "Ben",
		"createdDate": "2016-03-16 06:28:31",
		"totalOrganizations": 17,
		"totalTours": 31,
		"totalDays": 573,
		"totalCountries": 23,
		"totalStates": 55,
		"totalEvents": 180,
		"eventPercent": 0.770229,
		"totalScheduleItems": 2896,
		"schedulePercent": 0.841213,
		"totalGuests": 686,
		"guestPercent": 0.749702,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Alexandra Palace Theatre",
		"mostGuestCity": "London",
		"mostGuestAmount": 123
	},
	{
		"id": "77c25a8",
		"email": "trond@trondheimlyd.no",
		"name": "Trond",
		"createdDate": "2017-08-21 10:35:21",
		"totalOrganizations": 9,
		"totalTours": 5,
		"totalDays": 115,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 858,
		"schedulePercent": 0.439677,
		"totalGuests": 146,
		"guestPercent": 0.510528,
		"mostGuestDate": "2023-12-13 00:00:00",
		"mostGuestName": "Oslo Domkirke",
		"mostGuestCity": "Oslo",
		"mostGuestAmount": 20
	},
	{
		"id": "891dd71",
		"email": "admin@redclaystrays.com",
		"name": "Cody",
		"createdDate": "2022-11-15 17:56:49",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 369,
		"totalCountries": 1,
		"totalStates": 34,
		"totalEvents": 137,
		"eventPercent": 0.666402,
		"totalScheduleItems": 830,
		"schedulePercent": 0.430539,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2e9313b",
		"email": "jade@streamlineeventagency.com",
		"name": "Jade",
		"createdDate": "2023-04-04 12:52:56",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 78,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 609,
		"schedulePercent": 0.345517,
		"totalGuests": 120,
		"guestPercent": 0.490134,
		"mostGuestDate": "2023-09-06 00:00:00",
		"mostGuestName": "Ponte Vedra Concert Hall",
		"mostGuestCity": "Ponte Vedra Beach",
		"mostGuestAmount": 26
	},
	{
		"id": "5841f02",
		"email": "mikeybdrums@gmail.com",
		"name": "Michael",
		"createdDate": "2013-07-01 18:26:32",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 491,
		"totalCountries": 5,
		"totalStates": 47,
		"totalEvents": 186,
		"eventPercent": 0.782016,
		"totalScheduleItems": 3541,
		"schedulePercent": 0.89657,
		"totalGuests": 3052,
		"guestPercent": 0.969805,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Paycom Center",
		"mostGuestCity": "Oklahoma City",
		"mostGuestAmount": 167
	},
	{
		"id": "53b3b66",
		"email": "steven10km@gmail.com",
		"name": "Steven",
		"createdDate": "2022-04-26 22:03:20",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 253,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 124,
		"schedulePercent": 0.105681,
		"totalGuests": 74,
		"guestPercent": 0.449212,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "Scharmann Theater",
		"mostGuestCity": "Jamestown",
		"mostGuestAmount": 39
	},
	{
		"id": "62111f1",
		"email": "ck@beastmodeworld.com",
		"name": "Candace",
		"createdDate": "2022-09-13 05:45:39",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 82,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 616,
		"schedulePercent": 0.347901,
		"totalGuests": 88,
		"guestPercent": 0.461661,
		"mostGuestDate": "2023-04-30 00:00:00",
		"mostGuestName": "Northcote Theatre",
		"mostGuestCity": "Northcote",
		"mostGuestAmount": 32
	},
	{
		"id": "3b20a5f",
		"email": "tonystephens418@gmail.com",
		"name": "Tony",
		"createdDate": "2016-02-15 18:21:20",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 36,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 217,
		"schedulePercent": 0.160906,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a58e6f0",
		"email": "andresrecio@arsls.com",
		"name": "Andres",
		"createdDate": "2014-02-14 13:49:18",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 153,
		"totalCountries": 4,
		"totalStates": 7,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 457,
		"schedulePercent": 0.279566,
		"totalGuests": 27,
		"guestPercent": 0.38379,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "Machaca Fest",
		"mostGuestCity": "Monterrey",
		"mostGuestAmount": 23
	},
	{
		"id": "01f445b",
		"email": "elin.bolmstrand@allthingslive.se",
		"name": "elin",
		"createdDate": "2019-01-14 09:06:03",
		"totalOrganizations": 14,
		"totalTours": 9,
		"totalDays": 853,
		"totalCountries": 3,
		"totalStates": 22,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 903,
		"schedulePercent": 0.453317,
		"totalGuests": 346,
		"guestPercent": 0.626672,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "Stortorget",
		"mostGuestCity": "Falkenberg",
		"mostGuestAmount": 39
	},
	{
		"id": "b555cca",
		"email": "inaperfectworldproductions@gmail.com",
		"name": "Shannon ",
		"createdDate": "2022-09-06 14:53:36",
		"totalOrganizations": 6,
		"totalTours": 9,
		"totalDays": 267,
		"totalCountries": 11,
		"totalStates": 46,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 1196,
		"schedulePercent": 0.539134,
		"totalGuests": 279,
		"guestPercent": 0.593961,
		"mostGuestDate": "2023-11-29 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 33
	},
	{
		"id": "07c1c1a",
		"email": "rob@thehenhouse.com.au",
		"name": "Rob",
		"createdDate": "2017-11-15 06:51:48",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 245,
		"totalCountries": 11,
		"totalStates": 42,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 1079,
		"schedulePercent": 0.507218,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-05-27 00:00:00",
		"mostGuestName": "The Crowbar",
		"mostGuestCity": "Wagga Wagga",
		"mostGuestAmount": 5
	},
	{
		"id": "9bffbf7",
		"email": "alex.wakem@gmail.com",
		"name": "Alex",
		"createdDate": "2017-07-26 07:03:39",
		"totalOrganizations": 20,
		"totalTours": 13,
		"totalDays": 375,
		"totalCountries": 14,
		"totalStates": 29,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 1797,
		"schedulePercent": 0.68534,
		"totalGuests": 492,
		"guestPercent": 0.689048,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Manning Bar",
		"mostGuestCity": "Camperdown",
		"mostGuestAmount": 63
	},
	{
		"id": "f05fbb0",
		"email": "rkopels@gmail.com",
		"name": "Raymie",
		"createdDate": "2015-09-05 16:08:04",
		"totalOrganizations": 7,
		"totalTours": 13,
		"totalDays": 426,
		"totalCountries": 7,
		"totalStates": 40,
		"totalEvents": 172,
		"eventPercent": 0.755661,
		"totalScheduleItems": 2494,
		"schedulePercent": 0.79261,
		"totalGuests": 798,
		"guestPercent": 0.775659,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 45
	},
	{
		"id": "0b10971",
		"email": "Maikel@littlefish.nl",
		"name": "Maikel",
		"createdDate": "2020-03-12 19:56:00",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 25,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 170,
		"schedulePercent": 0.132698,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "49bccb4",
		"email": "gbowden823@gmail.com",
		"name": "Gerard",
		"createdDate": "2022-06-18 20:56:50",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 481,
		"totalCountries": 11,
		"totalStates": 38,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 1647,
		"schedulePercent": 0.653026,
		"totalGuests": 216,
		"guestPercent": 0.560058,
		"mostGuestDate": "2023-04-30 00:00:00",
		"mostGuestName": "The Peppermint Club",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 27
	},
	{
		"id": "8246fc5",
		"email": "rob.fisher.music@gmail.com",
		"name": "Robert",
		"createdDate": "2013-09-23 20:10:57",
		"totalOrganizations": 15,
		"totalTours": 14,
		"totalDays": 331,
		"totalCountries": 18,
		"totalStates": 57,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 1911,
		"schedulePercent": 0.705734,
		"totalGuests": 508,
		"guestPercent": 0.69461,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 102
	},
	{
		"id": "cd1f07a",
		"email": "maddy@ourness.com",
		"name": "Maddy",
		"createdDate": "2021-06-09 00:17:23",
		"totalOrganizations": 7,
		"totalTours": 19,
		"totalDays": 755,
		"totalCountries": 11,
		"totalStates": 41,
		"totalEvents": 215,
		"eventPercent": 0.831016,
		"totalScheduleItems": 1862,
		"schedulePercent": 0.697788,
		"totalGuests": 175,
		"guestPercent": 0.533439,
		"mostGuestDate": "2023-08-22 00:00:00",
		"mostGuestName": "Oslo",
		"mostGuestCity": "London",
		"mostGuestAmount": 45
	},
	{
		"id": "7d563f9",
		"email": "colin@goldtheoryartists.com",
		"name": "Colin",
		"createdDate": "2019-04-29 18:59:24",
		"totalOrganizations": 20,
		"totalTours": 48,
		"totalDays": 1307,
		"totalCountries": 22,
		"totalStates": 119,
		"totalEvents": 754,
		"eventPercent": 0.987286,
		"totalScheduleItems": 8366,
		"schedulePercent": 0.994835,
		"totalGuests": 3159,
		"guestPercent": 0.972189,
		"mostGuestDate": "2023-10-10 00:00:00",
		"mostGuestName": "Regent Theater DTLA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 71
	},
	{
		"id": "41e49fd",
		"email": "meg@brokenlevee.xyz",
		"name": "Megan",
		"createdDate": "2021-10-25 19:23:59",
		"totalOrganizations": 12,
		"totalTours": 15,
		"totalDays": 262,
		"totalCountries": 10,
		"totalStates": 37,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 1904,
		"schedulePercent": 0.704807,
		"totalGuests": 488,
		"guestPercent": 0.687591,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 71
	},
	{
		"id": "eea94fd",
		"email": "roadawgs@gmail.com",
		"name": "Andrew",
		"createdDate": "2011-04-24 08:19:38",
		"totalOrganizations": 38,
		"totalTours": 12,
		"totalDays": 333,
		"totalCountries": 17,
		"totalStates": 48,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 2223,
		"schedulePercent": 0.754205,
		"totalGuests": 468,
		"guestPercent": 0.680572,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "Kings Theatre",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 61
	},
	{
		"id": "b5c46a4",
		"email": "Anika.jacobs@gmail.com",
		"name": "Anika",
		"createdDate": "2013-08-05 18:29:11",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 246,
		"totalCountries": 10,
		"totalStates": 44,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 2426,
		"schedulePercent": 0.783472,
		"totalGuests": 2738,
		"guestPercent": 0.962124,
		"mostGuestDate": "2023-04-21 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 156
	},
	{
		"id": "3e8102d",
		"email": "stoffe@high5ive.se",
		"name": "Christoffer",
		"createdDate": "2019-07-03 22:02:24",
		"totalOrganizations": 9,
		"totalTours": 4,
		"totalDays": 53,
		"totalCountries": 5,
		"totalStates": 18,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 555,
		"schedulePercent": 0.323798,
		"totalGuests": 118,
		"guestPercent": 0.489207,
		"mostGuestDate": "2023-04-25 00:00:00",
		"mostGuestName": "Cassiopeia",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 24
	},
	{
		"id": "b2ecd4b",
		"email": "michaelkelly01@gmail.com",
		"name": "Michael",
		"createdDate": "2018-11-07 12:04:57",
		"totalOrganizations": 9,
		"totalTours": 12,
		"totalDays": 317,
		"totalCountries": 6,
		"totalStates": 46,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 2320,
		"schedulePercent": 0.768772,
		"totalGuests": 1450,
		"guestPercent": 0.8873,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 70
	},
	{
		"id": "067bae3",
		"email": "patdick78@gmail.com",
		"name": "Patrick",
		"createdDate": "2011-04-28 02:48:34",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 274,
		"totalCountries": 7,
		"totalStates": 29,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1503,
		"schedulePercent": 0.618858,
		"totalGuests": 1475,
		"guestPercent": 0.891008,
		"mostGuestDate": "2023-08-20 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 99
	},
	{
		"id": "2a5f5c0",
		"email": "diegodoesaudio@gmail.com",
		"name": "Diego",
		"createdDate": "2021-10-11 20:38:04",
		"totalOrganizations": 5,
		"totalTours": 11,
		"totalDays": 267,
		"totalCountries": 4,
		"totalStates": 42,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 1858,
		"schedulePercent": 0.697126,
		"totalGuests": 2200,
		"guestPercent": 0.943584,
		"mostGuestDate": "2023-08-04 00:00:00",
		"mostGuestName": "Sunset Cove Amphitheater",
		"mostGuestCity": "Boca Raton",
		"mostGuestAmount": 132
	},
	{
		"id": "746df2f",
		"email": "lisamhadley@me.com",
		"name": "Lisa",
		"createdDate": "2018-07-09 21:50:16",
		"totalOrganizations": 3,
		"totalTours": 10,
		"totalDays": 451,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 1032,
		"schedulePercent": 0.492782,
		"totalGuests": 124,
		"guestPercent": 0.493445,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "The Hamilton",
		"mostGuestCity": "Washington",
		"mostGuestAmount": 16
	},
	{
		"id": "2776acb",
		"email": "ben@stageandevents.com",
		"name": "Beni",
		"createdDate": "2017-03-18 12:58:45",
		"totalOrganizations": 11,
		"totalTours": 25,
		"totalDays": 399,
		"totalCountries": 7,
		"totalStates": 14,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 1452,
		"schedulePercent": 0.604158,
		"totalGuests": 361,
		"guestPercent": 0.635413,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "London Palladium",
		"mostGuestCity": "London",
		"mostGuestAmount": 53
	},
	{
		"id": "096d746",
		"email": "ramalama@online.no",
		"name": "Steinar",
		"createdDate": "2018-04-11 11:00:26",
		"totalOrganizations": 10,
		"totalTours": 6,
		"totalDays": 2092,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 449,
		"schedulePercent": 0.27599,
		"totalGuests": 259,
		"guestPercent": 0.583764,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "Sentrum Scene",
		"mostGuestCity": "Oslo",
		"mostGuestAmount": 73
	},
	{
		"id": "8e23439",
		"email": "jackson@nowheretalent.com",
		"name": "Jackson",
		"createdDate": "2023-07-09 12:49:33",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 36,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 259,
		"schedulePercent": 0.185273,
		"totalGuests": 177,
		"guestPercent": 0.534366,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "Orpheum Theater",
		"mostGuestCity": "Los Angeles ",
		"mostGuestAmount": 28
	},
	{
		"id": "e554d86",
		"email": "mike.malyan@gmail.com",
		"name": "Mike",
		"createdDate": "2017-06-27 07:55:48",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 137,
		"totalCountries": 16,
		"totalStates": 54,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 908,
		"schedulePercent": 0.454244,
		"totalGuests": 379,
		"guestPercent": 0.64455,
		"mostGuestDate": "2023-02-12 00:00:00",
		"mostGuestName": "Salle Pleyel",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 38
	},
	{
		"id": "2fb8896",
		"email": "ernstjan@me.com",
		"name": "Ernst Jan",
		"createdDate": "2014-03-15 10:57:35",
		"totalOrganizations": 6,
		"totalTours": 11,
		"totalDays": 208,
		"totalCountries": 10,
		"totalStates": 22,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 995,
		"schedulePercent": 0.483115,
		"totalGuests": 346,
		"guestPercent": 0.626672,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Brighton Dome",
		"mostGuestCity": "Brighton",
		"mostGuestAmount": 40
	},
	{
		"id": "2ab7af2",
		"email": "exocarter@gmail.com",
		"name": "Carter",
		"createdDate": "2023-01-18 21:40:12",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 33,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 193,
		"schedulePercent": 0.145941,
		"totalGuests": 46,
		"guestPercent": 0.415044,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "EartH (Evolutionary Arts Hackney)",
		"mostGuestCity": "London",
		"mostGuestAmount": 22
	},
	{
		"id": "afec491",
		"email": "lina@travtours.com",
		"name": "Lina",
		"createdDate": "2022-11-11 01:58:00",
		"totalOrganizations": 13,
		"totalTours": 11,
		"totalDays": 197,
		"totalCountries": 9,
		"totalStates": 23,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1775,
		"schedulePercent": 0.679778,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a1ae991",
		"email": "caitlin@banishedmusic.com",
		"name": "Caitlin",
		"createdDate": "2021-03-04 04:51:51",
		"totalOrganizations": 66,
		"totalTours": 70,
		"totalDays": 697,
		"totalCountries": 17,
		"totalStates": 56,
		"totalEvents": 299,
		"eventPercent": 0.914978,
		"totalScheduleItems": 3372,
		"schedulePercent": 0.885446,
		"totalGuests": 2867,
		"guestPercent": 0.966759,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Powerstation",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 88
	},
	{
		"id": "ce28302",
		"email": "jimrungetm@mac.com",
		"name": "Jim",
		"createdDate": "2009-06-03 17:29:57",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 466,
		"totalCountries": 4,
		"totalStates": 38,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 1738,
		"schedulePercent": 0.672361,
		"totalGuests": 350,
		"guestPercent": 0.629718,
		"mostGuestDate": "2023-04-08 00:00:00",
		"mostGuestName": "Wild Horse Pass Hotel & Casino",
		"mostGuestCity": "Chandler",
		"mostGuestAmount": 38
	},
	{
		"id": "4361416",
		"email": "dastouringllc@gmail.com",
		"name": "Darrell",
		"createdDate": "2012-02-06 00:23:14",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 369,
		"totalCountries": 11,
		"totalStates": 47,
		"totalEvents": 134,
		"eventPercent": 0.658721,
		"totalScheduleItems": 1373,
		"schedulePercent": 0.586148,
		"totalGuests": 780,
		"guestPercent": 0.771686,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 207
	},
	{
		"id": "b7a4ae7",
		"email": "mattschofield.msp@gmail.com",
		"name": "Matthew",
		"createdDate": "2021-11-21 20:04:45",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 71,
		"totalCountries": 5,
		"totalStates": 21,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 71,
		"schedulePercent": 0.0688651,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-10-10 00:00:00",
		"mostGuestName": "Tumbleroot Brewery and Distillery",
		"mostGuestCity": "Santa Fe",
		"mostGuestAmount": 11
	},
	{
		"id": "b225206",
		"email": "erassophia@gmail.com",
		"name": "Sophia ",
		"createdDate": "2021-11-30 21:51:55",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 810,
		"totalCountries": 16,
		"totalStates": 58,
		"totalEvents": 243,
		"eventPercent": 0.864786,
		"totalScheduleItems": 2504,
		"schedulePercent": 0.793802,
		"totalGuests": 1188,
		"guestPercent": 0.855383,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 59
	},
	{
		"id": "8d9b220",
		"email": "harvey@cls-live.co.uk",
		"name": "Harvey",
		"createdDate": "2022-11-03 18:58:26",
		"totalOrganizations": 10,
		"totalTours": 29,
		"totalDays": 506,
		"totalCountries": 25,
		"totalStates": 97,
		"totalEvents": 324,
		"eventPercent": 0.927692,
		"totalScheduleItems": 4267,
		"schedulePercent": 0.936565,
		"totalGuests": 1172,
		"guestPercent": 0.852867,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Royal Festival Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 35
	},
	{
		"id": "7c5f0b3",
		"email": "pi@pukproaudio.dk",
		"name": "Peter Klokker FInk",
		"createdDate": "2022-11-02 18:38:27",
		"totalOrganizations": 5,
		"totalTours": 14,
		"totalDays": 107,
		"totalCountries": 3,
		"totalStates": 5,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 760,
		"schedulePercent": 0.406039,
		"totalGuests": 21,
		"guestPercent": 0.371342,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "Oslo Concert Hall",
		"mostGuestCity": "Oslo",
		"mostGuestAmount": 10
	},
	{
		"id": "e5990cd",
		"email": "Isabella@quietfireent.com",
		"name": "Isabella",
		"createdDate": "2016-09-29 16:10:01",
		"totalOrganizations": 12,
		"totalTours": 4,
		"totalDays": 192,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 924,
		"schedulePercent": 0.457953,
		"totalGuests": 1832,
		"guestPercent": 0.923321,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 105
	},
	{
		"id": "1d921bb",
		"email": "rshimer@blackhawkaudio.com",
		"name": "Rick",
		"createdDate": "2017-09-13 18:24:34",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 216,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 1238,
		"schedulePercent": 0.549331,
		"totalGuests": 649,
		"guestPercent": 0.73924,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "The Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 130
	},
	{
		"id": "c75f502",
		"email": "ehm278@nyu.edu",
		"name": "Emma",
		"createdDate": "2018-03-19 05:15:33",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 93,
		"totalCountries": 1,
		"totalStates": 28,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 914,
		"schedulePercent": 0.455569,
		"totalGuests": 191,
		"guestPercent": 0.543769,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Tennessee Performing Arts Center - Polk Hall",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 27
	},
	{
		"id": "334a0a9",
		"email": "quentincuff@gmail.com",
		"name": "Quentin",
		"createdDate": "2011-09-11 21:20:45",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 533,
		"totalCountries": 18,
		"totalStates": 52,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 1037,
		"schedulePercent": 0.494107,
		"totalGuests": 313,
		"guestPercent": 0.61078,
		"mostGuestDate": "2023-01-30 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 74
	},
	{
		"id": "d877da0",
		"email": "darren@eighteentwentysix.com",
		"name": "Darren",
		"createdDate": "2016-04-15 23:20:51",
		"totalOrganizations": 10,
		"totalTours": 2,
		"totalDays": 39,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 545,
		"schedulePercent": 0.319295,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "071cda2",
		"email": "nick@fitzhart.com",
		"name": "Nick",
		"createdDate": "2013-08-22 14:06:52",
		"totalOrganizations": 7,
		"totalTours": 4,
		"totalDays": 598,
		"totalCountries": 5,
		"totalStates": 38,
		"totalEvents": 217,
		"eventPercent": 0.833267,
		"totalScheduleItems": 2044,
		"schedulePercent": 0.726394,
		"totalGuests": 980,
		"guestPercent": 0.819362,
		"mostGuestDate": "2023-11-21 00:00:00",
		"mostGuestName": "Wildhorse Saloon",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 283
	},
	{
		"id": "d6790fe",
		"email": "chris@blackeyedproductions.com",
		"name": "Chris",
		"createdDate": "2014-08-07 19:16:59",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 93,
		"totalCountries": 6,
		"totalStates": 17,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 448,
		"schedulePercent": 0.275593,
		"totalGuests": 390,
		"guestPercent": 0.64945,
		"mostGuestDate": "2023-10-10 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 47
	},
	{
		"id": "4e78643",
		"email": "nicktkalac@gmail.com",
		"name": "Nicholas ",
		"createdDate": "2012-10-11 14:06:20",
		"totalOrganizations": 49,
		"totalTours": 37,
		"totalDays": 833,
		"totalCountries": 28,
		"totalStates": 74,
		"totalEvents": 320,
		"eventPercent": 0.925705,
		"totalScheduleItems": 5224,
		"schedulePercent": 0.967951,
		"totalGuests": 950,
		"guestPercent": 0.812872,
		"mostGuestDate": "2023-04-06 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 106
	},
	{
		"id": "fa78f03",
		"email": "bill@onelouder.com.au",
		"name": "Bill",
		"createdDate": "2011-05-30 03:19:21",
		"totalOrganizations": 23,
		"totalTours": 10,
		"totalDays": 159,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 438,
		"schedulePercent": 0.272017,
		"totalGuests": 20,
		"guestPercent": 0.36909,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Hindley Street Music Hall",
		"mostGuestCity": "Adelaide",
		"mostGuestAmount": 20
	},
	{
		"id": "50b4684",
		"email": "amy@novoproductionsllc.com",
		"name": "Amy",
		"createdDate": "2021-05-07 04:35:53",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 366,
		"totalCountries": 9,
		"totalStates": 25,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 289,
		"schedulePercent": 0.20249,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "104cdf0",
		"email": "INFO@gellmanmgmt.com",
		"name": "Gellman",
		"createdDate": "2013-12-13 04:17:39",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 237,
		"totalCountries": 3,
		"totalStates": 28,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1061,
		"schedulePercent": 0.501126,
		"totalGuests": 438,
		"guestPercent": 0.667461,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 48
	},
	{
		"id": "2345a7a",
		"email": "rebeccajoylindley@gmail.com",
		"name": "Rebecca",
		"createdDate": "2016-09-10 21:31:51",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 458,
		"totalCountries": 1,
		"totalStates": 22,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 1690,
		"schedulePercent": 0.662826,
		"totalGuests": 143,
		"guestPercent": 0.508277,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Murphey Performance Hall",
		"mostGuestCity": "San Angelo",
		"mostGuestAmount": 50
	},
	{
		"id": "988ae5d",
		"email": "andrew@ententemgmt.com",
		"name": "Andrew",
		"createdDate": "2018-06-27 16:26:58",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 95,
		"totalCountries": 9,
		"totalStates": 26,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 608,
		"schedulePercent": 0.345252,
		"totalGuests": 77,
		"guestPercent": 0.452523,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "Howler",
		"mostGuestCity": "Brunswick",
		"mostGuestAmount": 23
	},
	{
		"id": "ef0f6a7",
		"email": "joelsimpson1@icloud.com",
		"name": "Joel",
		"createdDate": "2023-02-02 18:33:35",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 59,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 353,
		"schedulePercent": 0.234936,
		"totalGuests": 445,
		"guestPercent": 0.670905,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "Constellation Room",
		"mostGuestCity": "Santa Ana",
		"mostGuestAmount": 65
	},
	{
		"id": "529b510",
		"email": "JR@JRSchumann.com",
		"name": "JR",
		"createdDate": "2023-04-04 17:05:08",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 83,
		"totalCountries": 1,
		"totalStates": 26,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 301,
		"schedulePercent": 0.208449,
		"totalGuests": 208,
		"guestPercent": 0.55688,
		"mostGuestDate": "2023-09-01 00:00:00",
		"mostGuestName": "Majestic Theatre",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 45
	},
	{
		"id": "2b900b9",
		"email": "tim@thewhitlams.com",
		"name": "Tim",
		"createdDate": "2014-07-29 03:28:01",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 350,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 323,
		"schedulePercent": 0.220501,
		"totalGuests": 208,
		"guestPercent": 0.55688,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "Factory Theatre",
		"mostGuestCity": "Marrickville",
		"mostGuestAmount": 63
	},
	{
		"id": "e07ff2b",
		"email": "oli@plymouthmusiccollective.com",
		"name": "Oli",
		"createdDate": "2011-12-05 12:18:40",
		"totalOrganizations": 14,
		"totalTours": 30,
		"totalDays": 223,
		"totalCountries": 9,
		"totalStates": 36,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 1079,
		"schedulePercent": 0.507218,
		"totalGuests": 329,
		"guestPercent": 0.618064,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 67
	},
	{
		"id": "146f897",
		"email": "matthias@toechtersoehne.org",
		"name": "Matthias",
		"createdDate": "2018-04-16 12:25:56",
		"totalOrganizations": 25,
		"totalTours": 19,
		"totalDays": 283,
		"totalCountries": 4,
		"totalStates": 31,
		"totalEvents": 262,
		"eventPercent": 0.887962,
		"totalScheduleItems": 2089,
		"schedulePercent": 0.733413,
		"totalGuests": 1310,
		"guestPercent": 0.873262,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "MARX HALLE",
		"mostGuestCity": "Wien",
		"mostGuestAmount": 159
	},
	{
		"id": "5087af0",
		"email": "nikkihudson265@gmail.com",
		"name": "Nikki",
		"createdDate": "2022-09-16 00:20:30",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 298,
		"totalCountries": 7,
		"totalStates": 20,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 1479,
		"schedulePercent": 0.609191,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "177f29d",
		"email": "Studelksound@gmail.com",
		"name": "Stu",
		"createdDate": "2019-10-21 20:14:33",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 362,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 821,
		"schedulePercent": 0.426831,
		"totalGuests": 195,
		"guestPercent": 0.546418,
		"mostGuestDate": "2023-05-22 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 42
	},
	{
		"id": "1c4bc84",
		"email": "otto@peltomaagroup.fi",
		"name": "Otto",
		"createdDate": "2022-02-14 21:27:25",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 110,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 299,
		"schedulePercent": 0.207787,
		"totalGuests": 187,
		"guestPercent": 0.540988,
		"mostGuestDate": "2023-07-05 00:00:00",
		"mostGuestName": "Allas Sea Pool",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 42
	},
	{
		"id": "03c0155",
		"email": "Victoria@ekonmgmt.com",
		"name": "Victoria",
		"createdDate": "2015-09-25 16:45:38",
		"totalOrganizations": 13,
		"totalTours": 18,
		"totalDays": 209,
		"totalCountries": 9,
		"totalStates": 40,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 798,
		"schedulePercent": 0.419547,
		"totalGuests": 124,
		"guestPercent": 0.493445,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 41
	},
	{
		"id": "01ac2cd",
		"email": "aaronquintana23@gmail.com",
		"name": "Aaron",
		"createdDate": "2021-11-12 18:05:40",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 81,
		"totalCountries": 1,
		"totalStates": 34,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 696,
		"schedulePercent": 0.379023,
		"totalGuests": 172,
		"guestPercent": 0.531585,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "Paramount Theatre",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 24
	},
	{
		"id": "45b77fd",
		"email": "Triniv.martinez@gmail.com",
		"name": "Trinidad",
		"createdDate": "2018-03-26 17:03:34",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 584,
		"totalCountries": 18,
		"totalStates": 62,
		"totalEvents": 142,
		"eventPercent": 0.680705,
		"totalScheduleItems": 2761,
		"schedulePercent": 0.827175,
		"totalGuests": 2452,
		"guestPercent": 0.952721,
		"mostGuestDate": "2023-04-15 00:00:00",
		"mostGuestName": "OVO Arena",
		"mostGuestCity": "London",
		"mostGuestAmount": 113
	},
	{
		"id": "1f49a99",
		"email": "needforreed@gmail.com",
		"name": "Billy",
		"createdDate": "2011-08-30 16:06:40",
		"totalOrganizations": 11,
		"totalTours": 6,
		"totalDays": 777,
		"totalCountries": 16,
		"totalStates": 50,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 2971,
		"schedulePercent": 0.848497,
		"totalGuests": 477,
		"guestPercent": 0.682823,
		"mostGuestDate": "2023-09-05 00:00:00",
		"mostGuestName": "Singapore Indoor Stadium",
		"mostGuestCity": "Singapore",
		"mostGuestAmount": 78
	},
	{
		"id": "881455c",
		"email": "jo@foreigneronline.com",
		"name": "Jo ",
		"createdDate": "2016-10-03 17:12:05",
		"totalOrganizations": 2,
		"totalTours": 7,
		"totalDays": 468,
		"totalCountries": 3,
		"totalStates": 40,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 2890,
		"schedulePercent": 0.840418,
		"totalGuests": 2685,
		"guestPercent": 0.959873,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "Northwell Health at Jones Beach Theater",
		"mostGuestCity": "Wantagh",
		"mostGuestAmount": 109
	},
	{
		"id": "251ef93",
		"email": "liligunderson@gmail.com",
		"name": "Lisa ",
		"createdDate": "2017-07-19 10:48:58",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 624,
		"totalCountries": 9,
		"totalStates": 36,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 2193,
		"schedulePercent": 0.748775,
		"totalGuests": 366,
		"guestPercent": 0.639386,
		"mostGuestDate": "2023-01-10 00:00:00",
		"mostGuestName": "The Classic Center",
		"mostGuestCity": "Athens",
		"mostGuestAmount": 68
	},
	{
		"id": "623d964",
		"email": "jamiehamburgmusic@gmail.com",
		"name": "Jamie",
		"createdDate": "2021-09-07 19:24:48",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 915,
		"totalCountries": 7,
		"totalStates": 46,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 3123,
		"schedulePercent": 0.863462,
		"totalGuests": 298,
		"guestPercent": 0.60098,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 65
	},
	{
		"id": "dd88700",
		"email": "Ash@destroyalllines.com",
		"name": "Ash",
		"createdDate": "2014-09-01 05:23:59",
		"totalOrganizations": 81,
		"totalTours": 56,
		"totalDays": 812,
		"totalCountries": 15,
		"totalStates": 56,
		"totalEvents": 417,
		"eventPercent": 0.961727,
		"totalScheduleItems": 5098,
		"schedulePercent": 0.964773,
		"totalGuests": 546,
		"guestPercent": 0.707853,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 42
	},
	{
		"id": "c0f319b",
		"email": "mic_q@mac.com",
		"name": "Michael",
		"createdDate": "2013-04-24 05:40:25",
		"totalOrganizations": 8,
		"totalTours": 3,
		"totalDays": 376,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 9,
		"eventPercent": 0.0607866,
		"totalScheduleItems": 262,
		"schedulePercent": 0.186465,
		"totalGuests": 11,
		"guestPercent": 0.345782,
		"mostGuestDate": "2023-03-19 00:00:00",
		"mostGuestName": "Cine Joia ",
		"mostGuestCity": "SÃ£o Paulo",
		"mostGuestAmount": 5
	},
	{
		"id": "159c729",
		"email": "searley@paramountny.com",
		"name": "Sean",
		"createdDate": "2013-03-02 18:04:17",
		"totalOrganizations": 10,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 1433,
		"schedulePercent": 0.600053,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "37ca0bf",
		"email": "vicky@northernmusic.co.uk",
		"name": "Vicky",
		"createdDate": "2015-08-06 10:44:22",
		"totalOrganizations": 4,
		"totalTours": 36,
		"totalDays": 242,
		"totalCountries": 25,
		"totalStates": 72,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 1751,
		"schedulePercent": 0.674745,
		"totalGuests": 126,
		"guestPercent": 0.495564,
		"mostGuestDate": "2023-02-25 00:00:00",
		"mostGuestName": "Fryshuset",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 13
	},
	{
		"id": "5974ccb",
		"email": "touringcompany@secondcity.com",
		"name": "Bryon",
		"createdDate": "2022-11-23 17:05:37",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 170,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 616,
		"schedulePercent": 0.347901,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "35e21ef",
		"email": "dan@roundhouseent.com",
		"name": "Dan",
		"createdDate": "2022-08-25 23:50:13",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 181,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 314,
		"schedulePercent": 0.215203,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f0c2388",
		"email": "bryan@1212mgmt.com",
		"name": "Bryan",
		"createdDate": "2015-06-10 04:50:00",
		"totalOrganizations": 14,
		"totalTours": 18,
		"totalDays": 339,
		"totalCountries": 3,
		"totalStates": 36,
		"totalEvents": 178,
		"eventPercent": 0.767051,
		"totalScheduleItems": 2727,
		"schedulePercent": 0.82307,
		"totalGuests": 5043,
		"guestPercent": 0.992319,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "The Pacific Amphitheatre",
		"mostGuestCity": "Costa Mesa",
		"mostGuestAmount": 238
	},
	{
		"id": "910b735",
		"email": "nealcduffy@gmail.com",
		"name": "Neal ",
		"createdDate": "2013-06-12 03:30:06",
		"totalOrganizations": 19,
		"totalTours": 15,
		"totalDays": 268,
		"totalCountries": 12,
		"totalStates": 66,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 1566,
		"schedulePercent": 0.634088,
		"totalGuests": 544,
		"guestPercent": 0.707191,
		"mostGuestDate": "2023-10-25 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 44
	},
	{
		"id": "01fb114",
		"email": "stoiersound@gmail.com",
		"name": "Rasmus ",
		"createdDate": "2022-03-21 12:40:47",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 49,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 328,
		"schedulePercent": 0.223547,
		"totalGuests": 337,
		"guestPercent": 0.621375,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "Fredagsrock i Tivoli",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 50
	},
	{
		"id": "005cdb8",
		"email": "tommy.svela@gmail.com",
		"name": "Tommy",
		"createdDate": "2012-06-08 15:51:02",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 203,
		"totalCountries": 8,
		"totalStates": 12,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 1262,
		"schedulePercent": 0.555423,
		"totalGuests": 133,
		"guestPercent": 0.500596,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Debaser Hornstulls Strand",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 24
	},
	{
		"id": "286ed03",
		"email": "ben.malone@gmail.com",
		"name": "Ben",
		"createdDate": "2015-09-09 14:54:27",
		"totalOrganizations": 18,
		"totalTours": 9,
		"totalDays": 688,
		"totalCountries": 13,
		"totalStates": 63,
		"totalEvents": 229,
		"eventPercent": 0.847305,
		"totalScheduleItems": 4050,
		"schedulePercent": 0.92544,
		"totalGuests": 1578,
		"guestPercent": 0.90094,
		"mostGuestDate": "2023-04-21 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 85
	},
	{
		"id": "5d3745f",
		"email": "patrickmurray61@gmail.com",
		"name": "Patrick",
		"createdDate": "2011-05-17 15:51:53",
		"totalOrganizations": 15,
		"totalTours": 50,
		"totalDays": 1779,
		"totalCountries": 27,
		"totalStates": 106,
		"totalEvents": 489,
		"eventPercent": 0.972719,
		"totalScheduleItems": 6057,
		"schedulePercent": 0.980797,
		"totalGuests": 1663,
		"guestPercent": 0.908754,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Royal Festival Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 35
	},
	{
		"id": "2be7b5d",
		"email": "april.lawrencemedia@gmail.com",
		"name": "April",
		"createdDate": "2022-03-18 05:08:05",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 364,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 659,
		"schedulePercent": 0.364985,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "McMenamins Edgefield",
		"mostGuestCity": "Troutdale",
		"mostGuestAmount": 8
	},
	{
		"id": "541d6a4",
		"email": "j.lewisuk@gmail.com",
		"name": "Jonathan",
		"createdDate": "2015-07-28 18:12:02",
		"totalOrganizations": 13,
		"totalTours": 19,
		"totalDays": 690,
		"totalCountries": 11,
		"totalStates": 49,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 1840,
		"schedulePercent": 0.693683,
		"totalGuests": 484,
		"guestPercent": 0.684942,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Rose Bowl Stadium",
		"mostGuestCity": "Pasadena",
		"mostGuestAmount": 45
	},
	{
		"id": "d2f3ca7",
		"email": "magnus@unitedvision.se",
		"name": "Magnus",
		"createdDate": "2014-06-10 08:58:52",
		"totalOrganizations": 19,
		"totalTours": 13,
		"totalDays": 510,
		"totalCountries": 7,
		"totalStates": 26,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 1281,
		"schedulePercent": 0.560588,
		"totalGuests": 443,
		"guestPercent": 0.669315,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Goteborgs Konserthus Big Hall",
		"mostGuestCity": "Goteborg",
		"mostGuestAmount": 48
	},
	{
		"id": "ba20a80",
		"email": "danbombings@uptoourhips.com",
		"name": "Dan",
		"createdDate": "2013-07-23 05:24:15",
		"totalOrganizations": 44,
		"totalTours": 73,
		"totalDays": 1991,
		"totalCountries": 26,
		"totalStates": 103,
		"totalEvents": 581,
		"eventPercent": 0.981724,
		"totalScheduleItems": 7903,
		"schedulePercent": 0.993776,
		"totalGuests": 1270,
		"guestPercent": 0.868097,
		"mostGuestDate": "2023-05-25 00:00:00",
		"mostGuestName": "The Regent",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 68
	},
	{
		"id": "4dcf61d",
		"email": "ethstrnk@gmail.com",
		"name": "Ethan",
		"createdDate": "2015-01-19 16:21:38",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 734,
		"totalCountries": 9,
		"totalStates": 52,
		"totalEvents": 198,
		"eventPercent": 0.802543,
		"totalScheduleItems": 3735,
		"schedulePercent": 0.908886,
		"totalGuests": 3565,
		"guestPercent": 0.978413,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Raymond James Stadium",
		"mostGuestCity": "Tampa ",
		"mostGuestAmount": 248
	},
	{
		"id": "dacfb6a",
		"email": "bendeem@gmail.com",
		"name": "Ben",
		"createdDate": "2016-09-14 20:52:23",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 146,
		"totalCountries": 2,
		"totalStates": 35,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1125,
		"schedulePercent": 0.51768,
		"totalGuests": 593,
		"guestPercent": 0.723878,
		"mostGuestDate": "2023-03-06 00:00:00",
		"mostGuestName": "Florida Strawberry Festival",
		"mostGuestCity": "Plant City",
		"mostGuestAmount": 40
	},
	{
		"id": "42ccabf",
		"email": "josh.mockerman@me.com",
		"name": "Josh",
		"createdDate": "2014-11-10 15:37:11",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 1093,
		"totalCountries": 11,
		"totalStates": 54,
		"totalEvents": 251,
		"eventPercent": 0.873792,
		"totalScheduleItems": 3462,
		"schedulePercent": 0.891405,
		"totalGuests": 1644,
		"guestPercent": 0.906767,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 102
	},
	{
		"id": "51f0fa5",
		"email": "timkrieg@me.com",
		"name": "Tim",
		"createdDate": "2018-03-06 14:46:13",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 209,
		"totalCountries": 4,
		"totalStates": 32,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 1414,
		"schedulePercent": 0.594623,
		"totalGuests": 1996,
		"guestPercent": 0.933254,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Gorge Amphitheatre",
		"mostGuestCity": "George",
		"mostGuestAmount": 205
	},
	{
		"id": "6507a7c",
		"email": "caja@totallive.no",
		"name": "Caja ",
		"createdDate": "2022-08-15 13:48:39",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 48,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 315,
		"schedulePercent": 0.215733,
		"totalGuests": 91,
		"guestPercent": 0.465236,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Grieghallen",
		"mostGuestCity": "Bergen",
		"mostGuestAmount": 16
	},
	{
		"id": "2877187",
		"email": "office@recklesskelly.com",
		"name": "Chris ",
		"createdDate": "2019-10-21 20:41:24",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 98,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 473,
		"schedulePercent": 0.286187,
		"totalGuests": 218,
		"guestPercent": 0.561515,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Stubb's BBQ - Waller Creek Amphitheater",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 46
	},
	{
		"id": "a1a77ca",
		"email": "younglion937@yahoo.com",
		"name": "Hossein",
		"createdDate": "2012-07-20 17:51:58",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 129,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 765,
		"schedulePercent": 0.407098,
		"totalGuests": 520,
		"guestPercent": 0.698715,
		"mostGuestDate": "2023-07-09 00:00:00",
		"mostGuestName": "Salvage Station",
		"mostGuestCity": "Asheville",
		"mostGuestAmount": 44
	},
	{
		"id": "be19ac4",
		"email": "chyannel2015@gmail.com",
		"name": "Chyanne",
		"createdDate": "2022-03-16 21:07:36",
		"totalOrganizations": 5,
		"totalTours": 16,
		"totalDays": 589,
		"totalCountries": 18,
		"totalStates": 63,
		"totalEvents": 190,
		"eventPercent": 0.788505,
		"totalScheduleItems": 3485,
		"schedulePercent": 0.892994,
		"totalGuests": 2492,
		"guestPercent": 0.953649,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 171
	},
	{
		"id": "78c6564",
		"email": "travis@basismanagement.com",
		"name": "Travis",
		"createdDate": "2014-02-12 00:36:00",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 50,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 262,
		"schedulePercent": 0.186465,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5ae477a",
		"email": "slimscol@hotmail.com",
		"name": "Sean",
		"createdDate": "2018-07-26 01:20:01",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 143,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 13,
		"eventPercent": 0.0892597,
		"totalScheduleItems": 159,
		"schedulePercent": 0.126208,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-06-09 00:00:00",
		"mostGuestName": "The Bluebird",
		"mostGuestCity": "Bloomington",
		"mostGuestAmount": 10
	},
	{
		"id": "412a5d0",
		"email": "brehm.tony@googlemail.com",
		"name": "Tony",
		"createdDate": "2023-01-08 12:42:20",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 26,
		"totalCountries": 9,
		"totalStates": 13,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 220,
		"schedulePercent": 0.163554,
		"totalGuests": 134,
		"guestPercent": 0.501258,
		"mostGuestDate": "2023-01-26 00:00:00",
		"mostGuestName": "Underworld",
		"mostGuestCity": "London",
		"mostGuestAmount": 19
	},
	{
		"id": "2d724e5",
		"email": "db@radicalismusic.com",
		"name": "David",
		"createdDate": "2023-04-20 09:41:59",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 222,
		"totalCountries": 4,
		"totalStates": 7,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 360,
		"schedulePercent": 0.237982,
		"totalGuests": 81,
		"guestPercent": 0.455966,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Z7 Konzertfabrik",
		"mostGuestCity": "Pratteln",
		"mostGuestAmount": 20
	},
	{
		"id": "a4957ef",
		"email": "graham@soundadviceri.com",
		"name": "Graham",
		"createdDate": "2019-07-02 19:36:10",
		"totalOrganizations": 14,
		"totalTours": 11,
		"totalDays": 614,
		"totalCountries": 14,
		"totalStates": 57,
		"totalEvents": 191,
		"eventPercent": 0.790491,
		"totalScheduleItems": 3310,
		"schedulePercent": 0.878294,
		"totalGuests": 1608,
		"guestPercent": 0.903721,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 74
	},
	{
		"id": "eee0193",
		"email": "tourdogdave@gmail.com",
		"name": "Dave",
		"createdDate": "2015-10-14 23:05:39",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 546,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 1211,
		"schedulePercent": 0.542842,
		"totalGuests": 518,
		"guestPercent": 0.698053,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "The Observatory",
		"mostGuestCity": "Santa Ana",
		"mostGuestAmount": 93
	},
	{
		"id": "34c737b",
		"email": "kodywest16@me.com",
		"name": "Kody",
		"createdDate": "2015-04-17 17:29:03",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 69,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 210,
		"schedulePercent": 0.156006,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bf61b9f",
		"email": "a-meid@t-online.de",
		"name": "Andi",
		"createdDate": "2023-11-10 15:53:53",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 41,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 237,
		"schedulePercent": 0.173355,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "43c578c",
		"email": "mikebess303@gmail.com",
		"name": "Michael ",
		"createdDate": "2010-01-07 18:52:21",
		"totalOrganizations": 11,
		"totalTours": 4,
		"totalDays": 139,
		"totalCountries": 8,
		"totalStates": 14,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 376,
		"schedulePercent": 0.246457,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bffee11",
		"email": "SKYMUSICNY@gmail.com",
		"name": "SKY",
		"createdDate": "2023-05-05 16:54:10",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 716,
		"totalCountries": 6,
		"totalStates": 19,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 265,
		"schedulePercent": 0.189511,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0b0af13",
		"email": "richarddhawkins1@gmail.com",
		"name": "Richard ",
		"createdDate": "2019-07-27 07:05:52",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 117,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 263,
		"schedulePercent": 0.187922,
		"totalGuests": 240,
		"guestPercent": 0.573434,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 44
	},
	{
		"id": "f05f365",
		"email": "dwremedy@gmail.com",
		"name": "Justin",
		"createdDate": "2015-07-03 23:50:20",
		"totalOrganizations": 11,
		"totalTours": 22,
		"totalDays": 627,
		"totalCountries": 21,
		"totalStates": 95,
		"totalEvents": 330,
		"eventPercent": 0.931665,
		"totalScheduleItems": 4109,
		"schedulePercent": 0.928221,
		"totalGuests": 317,
		"guestPercent": 0.612502,
		"mostGuestDate": "2023-06-01 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 37
	},
	{
		"id": "a2e3122",
		"email": "aaron@space44.com.au",
		"name": "Aaron",
		"createdDate": "2017-01-24 06:22:18",
		"totalOrganizations": 14,
		"totalTours": 35,
		"totalDays": 252,
		"totalCountries": 4,
		"totalStates": 19,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 691,
		"schedulePercent": 0.377301,
		"totalGuests": 31,
		"guestPercent": 0.390942,
		"mostGuestDate": "2023-06-09 00:00:00",
		"mostGuestName": "The Gasometer Hotel",
		"mostGuestCity": "Collingwood",
		"mostGuestAmount": 18
	},
	{
		"id": "e83f0f0",
		"email": "Scarlett@worshipinitiative.com",
		"name": "Scarlett",
		"createdDate": "2022-08-26 16:45:31",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 31,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 185,
		"schedulePercent": 0.142233,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7f4cd7e",
		"email": "Coryaedwards@me.com",
		"name": "Cory",
		"createdDate": "2015-01-06 23:48:58",
		"totalOrganizations": 13,
		"totalTours": 9,
		"totalDays": 265,
		"totalCountries": 3,
		"totalStates": 30,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 3150,
		"schedulePercent": 0.865846,
		"totalGuests": 4932,
		"guestPercent": 0.991524,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "Moody Center",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 314
	},
	{
		"id": "7250ee4",
		"email": "mrbilliams@gmail.com",
		"name": "Matthew",
		"createdDate": "2011-02-16 18:33:35",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 855,
		"totalCountries": 4,
		"totalStates": 50,
		"totalEvents": 178,
		"eventPercent": 0.767051,
		"totalScheduleItems": 3322,
		"schedulePercent": 0.880148,
		"totalGuests": 1398,
		"guestPercent": 0.882665,
		"mostGuestDate": "2023-01-12 00:00:00",
		"mostGuestName": "Riverside Revival Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 149
	},
	{
		"id": "4eda15a",
		"email": "mastertours@networkstours.com",
		"name": "NETworks",
		"createdDate": "2022-07-26 16:21:15",
		"totalOrganizations": 15,
		"totalTours": 11,
		"totalDays": 1615,
		"totalCountries": 3,
		"totalStates": 50,
		"totalEvents": 1177,
		"eventPercent": 0.992054,
		"totalScheduleItems": 12437,
		"schedulePercent": 0.998411,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b3199ce",
		"email": "christian@themusiccompany.dk",
		"name": "Christian ",
		"createdDate": "2019-05-13 10:39:03",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 195,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 184,
		"eventPercent": 0.77791,
		"totalScheduleItems": 958,
		"schedulePercent": 0.468282,
		"totalGuests": 162,
		"guestPercent": 0.524169,
		"mostGuestDate": "2023-05-12 00:00:00",
		"mostGuestName": "Tivoli",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 36
	},
	{
		"id": "d7b38ce",
		"email": "theblackmoodsmusic@gmail.com",
		"name": "Chico",
		"createdDate": "2018-11-28 03:02:12",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 110,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 208,
		"schedulePercent": 0.155344,
		"totalGuests": 27,
		"guestPercent": 0.38379,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Seaside Park",
		"mostGuestCity": "Bridgeport",
		"mostGuestAmount": 8
	},
	{
		"id": "4d23645",
		"email": "heikki@fullsteam.fi",
		"name": "Heikki",
		"createdDate": "2011-03-01 15:00:51",
		"totalOrganizations": 95,
		"totalTours": 76,
		"totalDays": 1155,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 1152,
		"eventPercent": 0.991524,
		"totalScheduleItems": 3951,
		"schedulePercent": 0.921202,
		"totalGuests": 61,
		"guestPercent": 0.435571,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "On The Rocks",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 10
	},
	{
		"id": "b4bd0fc",
		"email": "efainproduction@gmail.com",
		"name": "Eric",
		"createdDate": "2021-08-28 23:19:47",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 342,
		"totalCountries": 6,
		"totalStates": 33,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 2504,
		"schedulePercent": 0.793802,
		"totalGuests": 55,
		"guestPercent": 0.42842,
		"mostGuestDate": "2023-05-23 00:00:00",
		"mostGuestName": "Spectrum Center",
		"mostGuestCity": "Charlotte",
		"mostGuestAmount": 55
	},
	{
		"id": "3030e12",
		"email": "ericxtour@gmail.com",
		"name": "Eric",
		"createdDate": "2023-03-14 13:47:52",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 601,
		"totalCountries": 2,
		"totalStates": 17,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 526,
		"schedulePercent": 0.310025,
		"totalGuests": 559,
		"guestPercent": 0.713415,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "HISTORY",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 58
	},
	{
		"id": "e2a7d7e",
		"email": "jorgepfjjj@hotmail.com",
		"name": "Jorge",
		"createdDate": "2013-09-30 23:08:21",
		"totalOrganizations": 13,
		"totalTours": 12,
		"totalDays": 1024,
		"totalCountries": 10,
		"totalStates": 54,
		"totalEvents": 231,
		"eventPercent": 0.850616,
		"totalScheduleItems": 4604,
		"schedulePercent": 0.948616,
		"totalGuests": 1513,
		"guestPercent": 0.89604,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 76
	},
	{
		"id": "6c6dc3d",
		"email": "chris@hardinbourke.com",
		"name": "Chris",
		"createdDate": "2016-01-09 11:09:43",
		"totalOrganizations": 6,
		"totalTours": 17,
		"totalDays": 244,
		"totalCountries": 14,
		"totalStates": 55,
		"totalEvents": 137,
		"eventPercent": 0.666402,
		"totalScheduleItems": 1947,
		"schedulePercent": 0.711032,
		"totalGuests": 884,
		"guestPercent": 0.795259,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "Backstage Kulturzentrum",
		"mostGuestCity": "MÃ¼nchen",
		"mostGuestAmount": 64
	},
	{
		"id": "a7a8679",
		"email": "lewis.thorn65@gmail.com",
		"name": "Lewis",
		"createdDate": "2012-12-28 15:53:38",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 161,
		"totalCountries": 6,
		"totalStates": 10,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 749,
		"schedulePercent": 0.401404,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1f3b9df",
		"email": "david@nhtours.co.uk",
		"name": "David",
		"createdDate": "2017-07-02 13:09:45",
		"totalOrganizations": 10,
		"totalTours": 20,
		"totalDays": 716,
		"totalCountries": 21,
		"totalStates": 70,
		"totalEvents": 217,
		"eventPercent": 0.833267,
		"totalScheduleItems": 2680,
		"schedulePercent": 0.817375,
		"totalGuests": 142,
		"guestPercent": 0.506688,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "The Drake Hotel",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 34
	},
	{
		"id": "08e570d",
		"email": "gradyhenshaw@gmail.com",
		"name": "Grady",
		"createdDate": "2018-12-21 06:52:05",
		"totalOrganizations": 28,
		"totalTours": 79,
		"totalDays": 1281,
		"totalCountries": 11,
		"totalStates": 45,
		"totalEvents": 353,
		"eventPercent": 0.943319,
		"totalScheduleItems": 3519,
		"schedulePercent": 0.895643,
		"totalGuests": 516,
		"guestPercent": 0.697656,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "Factory Theatre",
		"mostGuestCity": "Marrickville",
		"mostGuestAmount": 102
	},
	{
		"id": "a6b4f3d",
		"email": "nmishko@10and8management.com",
		"name": "Nicholas",
		"createdDate": "2023-10-30 23:07:31",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 83,
		"totalCountries": 2,
		"totalStates": 17,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 179,
		"schedulePercent": 0.137598,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Knitting Factory - North Hollywood",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 4
	},
	{
		"id": "3150235",
		"email": "thisisdavidash@gmail.com",
		"name": "David",
		"createdDate": "2017-09-19 17:12:00",
		"totalOrganizations": 17,
		"totalTours": 19,
		"totalDays": 854,
		"totalCountries": 20,
		"totalStates": 63,
		"totalEvents": 189,
		"eventPercent": 0.78718,
		"totalScheduleItems": 2640,
		"schedulePercent": 0.813667,
		"totalGuests": 1468,
		"guestPercent": 0.890213,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 243
	},
	{
		"id": "57e39c9",
		"email": "IanNoeTM@gmail.com",
		"name": "Carlos",
		"createdDate": "2019-11-26 00:15:04",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 726,
		"totalCountries": 7,
		"totalStates": 46,
		"totalEvents": 201,
		"eventPercent": 0.808237,
		"totalScheduleItems": 1535,
		"schedulePercent": 0.626937,
		"totalGuests": 631,
		"guestPercent": 0.734207,
		"mostGuestDate": "2023-07-03 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 32
	},
	{
		"id": "c75d42b",
		"email": "jaysmix@mac.com",
		"name": "Jay",
		"createdDate": "2010-02-12 18:52:02",
		"totalOrganizations": 14,
		"totalTours": 10,
		"totalDays": 939,
		"totalCountries": 3,
		"totalStates": 46,
		"totalEvents": 218,
		"eventPercent": 0.834724,
		"totalScheduleItems": 3158,
		"schedulePercent": 0.866773,
		"totalGuests": 2691,
		"guestPercent": 0.960535,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "State Farm Arena",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 133
	},
	{
		"id": "e0e259c",
		"email": "mikel@caelumterra.com",
		"name": "mikel",
		"createdDate": "2021-12-13 23:40:19",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 41,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 408,
		"schedulePercent": 0.260363,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2cd2bd1",
		"email": "annika.flygge@allthingslive.se",
		"name": "Annika",
		"createdDate": "2023-01-10 12:50:48",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 519,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 681,
		"schedulePercent": 0.373328,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5e5edba",
		"email": "Matt@t3musicgroup.com",
		"name": "Email",
		"createdDate": "2016-10-05 04:03:30",
		"totalOrganizations": 12,
		"totalTours": 6,
		"totalDays": 442,
		"totalCountries": 12,
		"totalStates": 28,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 869,
		"schedulePercent": 0.44259,
		"totalGuests": 372,
		"guestPercent": 0.641902,
		"mostGuestDate": "2023-08-01 00:00:00",
		"mostGuestName": "The Bellweather",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 82
	},
	{
		"id": "f45155a",
		"email": "Sophie@urokmanagement.com",
		"name": "Sophie",
		"createdDate": "2023-03-20 15:53:02",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 529,
		"totalCountries": 4,
		"totalStates": 8,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 507,
		"schedulePercent": 0.302741,
		"totalGuests": 24,
		"guestPercent": 0.375712,
		"mostGuestDate": "2023-06-07 00:00:00",
		"mostGuestName": "The Lower Third",
		"mostGuestCity": "London",
		"mostGuestAmount": 10
	},
	{
		"id": "c469b3d",
		"email": "liam@heavylifting.co",
		"name": "Liam",
		"createdDate": "2023-05-02 15:47:54",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 303,
		"totalCountries": 4,
		"totalStates": 40,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 1592,
		"schedulePercent": 0.640313,
		"totalGuests": 129,
		"guestPercent": 0.497815,
		"mostGuestDate": "2023-10-23 00:00:00",
		"mostGuestName": "Outernet London",
		"mostGuestCity": "London",
		"mostGuestAmount": 59
	},
	{
		"id": "ee0a76d",
		"email": "sami@eclipsemusicgrp.com",
		"name": "Neal",
		"createdDate": "2018-10-10 19:44:20",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 2005,
		"totalCountries": 13,
		"totalStates": 67,
		"totalEvents": 338,
		"eventPercent": 0.936432,
		"totalScheduleItems": 2345,
		"schedulePercent": 0.773408,
		"totalGuests": 222,
		"guestPercent": 0.564561,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Mars Music Hall",
		"mostGuestCity": "Huntsville",
		"mostGuestAmount": 26
	},
	{
		"id": "6e190d8",
		"email": "bsimpson@rocknconcepts.com",
		"name": "Bryce",
		"createdDate": "2021-09-14 17:09:08",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 429,
		"totalCountries": 17,
		"totalStates": 63,
		"totalEvents": 201,
		"eventPercent": 0.808237,
		"totalScheduleItems": 3776,
		"schedulePercent": 0.910873,
		"totalGuests": 1033,
		"guestPercent": 0.829824,
		"mostGuestDate": "2023-08-27 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 151
	},
	{
		"id": "60fa100",
		"email": "jamesvprisco@gmail.com",
		"name": "Jim",
		"createdDate": "2014-09-22 18:26:27",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 685,
		"totalCountries": 6,
		"totalStates": 44,
		"totalEvents": 187,
		"eventPercent": 0.784135,
		"totalScheduleItems": 3413,
		"schedulePercent": 0.888359,
		"totalGuests": 3098,
		"guestPercent": 0.970732,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Busch Stadium",
		"mostGuestCity": "St Louis",
		"mostGuestAmount": 238
	},
	{
		"id": "c2aad44",
		"email": "patinamusik@gmail.com",
		"name": "PATINA",
		"createdDate": "2022-01-24 17:38:02",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 76,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 714,
		"schedulePercent": 0.386174,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d7680a1",
		"email": "shannon1098@gmail.com",
		"name": "Shannon",
		"createdDate": "2021-10-13 17:13:03",
		"totalOrganizations": 5,
		"totalTours": 15,
		"totalDays": 535,
		"totalCountries": 3,
		"totalStates": 39,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 1742,
		"schedulePercent": 0.672891,
		"totalGuests": 121,
		"guestPercent": 0.490928,
		"mostGuestDate": "2023-07-26 00:00:00",
		"mostGuestName": "Mizner Park Amphitheatre",
		"mostGuestCity": "Boca Raton",
		"mostGuestAmount": 42
	},
	{
		"id": "b049e2a",
		"email": "robinlaananen@gmail.com",
		"name": "Robin",
		"createdDate": "2016-01-24 18:39:23",
		"totalOrganizations": 17,
		"totalTours": 31,
		"totalDays": 1023,
		"totalCountries": 24,
		"totalStates": 88,
		"totalEvents": 377,
		"eventPercent": 0.950073,
		"totalScheduleItems": 4710,
		"schedulePercent": 0.951794,
		"totalGuests": 2664,
		"guestPercent": 0.958946,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "Forest Hills Stadium",
		"mostGuestCity": "Forest Hills",
		"mostGuestAmount": 184
	},
	{
		"id": "fb056e7",
		"email": "ycbandtm@gmail.com",
		"name": "Taylor",
		"createdDate": "2023-10-27 20:59:21",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 65,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 94,
		"schedulePercent": 0.0852867,
		"totalGuests": 19,
		"guestPercent": 0.366971,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Revel",
		"mostGuestCity": "Albuquerque",
		"mostGuestAmount": 10
	},
	{
		"id": "e18094a",
		"email": "brent@skatunenetwork.com",
		"name": "Brent",
		"createdDate": "2022-04-13 00:36:57",
		"totalOrganizations": 14,
		"totalTours": 21,
		"totalDays": 130,
		"totalCountries": 3,
		"totalStates": 35,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 895,
		"schedulePercent": 0.451993,
		"totalGuests": 39,
		"guestPercent": 0.404185,
		"mostGuestDate": "2023-07-18 00:00:00",
		"mostGuestName": "Rams Head Live!",
		"mostGuestCity": "Baltimore",
		"mostGuestAmount": 8
	},
	{
		"id": "f50f17c",
		"email": "jake@crushsouth.com",
		"name": "Jake",
		"createdDate": "2019-08-17 17:22:25",
		"totalOrganizations": 14,
		"totalTours": 26,
		"totalDays": 1622,
		"totalCountries": 19,
		"totalStates": 94,
		"totalEvents": 543,
		"eventPercent": 0.979076,
		"totalScheduleItems": 5610,
		"schedulePercent": 0.974838,
		"totalGuests": 1320,
		"guestPercent": 0.874321,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "AB Banan-Kompaniet",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 42
	},
	{
		"id": "a76d39e",
		"email": "jonathandeboise@gmail.com",
		"name": "Jonathan",
		"createdDate": "2023-05-11 12:47:22",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 173,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 222,
		"schedulePercent": 0.164879,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b966e34",
		"email": "leemitchell@me.com",
		"name": "Lee",
		"createdDate": "2012-02-22 14:35:17",
		"totalOrganizations": 18,
		"totalTours": 12,
		"totalDays": 581,
		"totalCountries": 15,
		"totalStates": 67,
		"totalEvents": 189,
		"eventPercent": 0.78718,
		"totalScheduleItems": 3095,
		"schedulePercent": 0.86121,
		"totalGuests": 422,
		"guestPercent": 0.662694,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "Rocketown",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 123
	},
	{
		"id": "fb31b82",
		"email": "shelley_harris@me.com",
		"name": "Shelley",
		"createdDate": "2013-06-16 20:11:00",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 999,
		"totalCountries": 6,
		"totalStates": 38,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 3500,
		"schedulePercent": 0.894186,
		"totalGuests": 316,
		"guestPercent": 0.612104,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Berkeley",
		"mostGuestAmount": 63
	},
	{
		"id": "0fbf596",
		"email": "Jaycotterellmusic@gmail.com",
		"name": "Jason",
		"createdDate": "2014-10-04 00:25:55",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 282,
		"totalCountries": 3,
		"totalStates": 23,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 1226,
		"schedulePercent": 0.546815,
		"totalGuests": 101,
		"guestPercent": 0.474904,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "Yankee Stadium",
		"mostGuestCity": "Bronx",
		"mostGuestAmount": 46
	},
	{
		"id": "d2b51c8",
		"email": "ontourservices@gmail.com",
		"name": "Eddie ",
		"createdDate": "2019-03-08 06:37:35",
		"totalOrganizations": 10,
		"totalTours": 5,
		"totalDays": 160,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 1919,
		"schedulePercent": 0.706397,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c51a88e",
		"email": "d.escobar@lcmentertainment.com",
		"name": "Damien",
		"createdDate": "2016-07-15 15:57:03",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 336,
		"totalCountries": 1,
		"totalStates": 15,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 379,
		"schedulePercent": 0.248311,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0a11061",
		"email": "destiny@husetunderbron.se",
		"name": "Destiny Natacha",
		"createdDate": "2022-04-22 14:34:15",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 194,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 1140,
		"schedulePercent": 0.523507,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "811d58f",
		"email": "wreeves3@aol.com",
		"name": "Bill",
		"createdDate": "2017-04-10 21:16:10",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 48,
		"totalCountries": 1,
		"totalStates": 18,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 455,
		"schedulePercent": 0.278639,
		"totalGuests": 102,
		"guestPercent": 0.477155,
		"mostGuestDate": "2023-05-18 00:00:00",
		"mostGuestName": "Fisher Theatre",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 14
	},
	{
		"id": "e2f58e7",
		"email": "o.jarvelainen@gmail.com",
		"name": "Olli",
		"createdDate": "2018-03-06 19:08:16",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 93,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 190,
		"schedulePercent": 0.144617,
		"totalGuests": 299,
		"guestPercent": 0.602039,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "Tavastia",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 40
	},
	{
		"id": "cbe8d6d",
		"email": "johnhelps@mac.com",
		"name": "John",
		"createdDate": "2013-01-16 14:30:32",
		"totalOrganizations": 22,
		"totalTours": 29,
		"totalDays": 657,
		"totalCountries": 20,
		"totalStates": 85,
		"totalEvents": 332,
		"eventPercent": 0.933916,
		"totalScheduleItems": 4660,
		"schedulePercent": 0.950338,
		"totalGuests": 1634,
		"guestPercent": 0.906238,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "House of Blues Orlando",
		"mostGuestCity": "Lake Buena Vista",
		"mostGuestAmount": 81
	},
	{
		"id": "8ce3b57",
		"email": "TMStripes13@gmail.com",
		"name": "Randy",
		"createdDate": "2019-03-04 23:48:56",
		"totalOrganizations": 13,
		"totalTours": 8,
		"totalDays": 782,
		"totalCountries": 1,
		"totalStates": 29,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 1544,
		"schedulePercent": 0.628526,
		"totalGuests": 541,
		"guestPercent": 0.706264,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 51
	},
	{
		"id": "80d9563",
		"email": "tomcarlson@gmail.com",
		"name": "Kevin",
		"createdDate": "2010-10-17 08:38:34",
		"totalOrganizations": 28,
		"totalTours": 26,
		"totalDays": 1104,
		"totalCountries": 15,
		"totalStates": 71,
		"totalEvents": 363,
		"eventPercent": 0.947424,
		"totalScheduleItems": 4949,
		"schedulePercent": 0.960535,
		"totalGuests": 1598,
		"guestPercent": 0.902794,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "Ben And Jerrys Concerts On The Green",
		"mostGuestCity": "Shelburne",
		"mostGuestAmount": 71
	},
	{
		"id": "4ba2d92",
		"email": "aroha@massiverecords.co.nz",
		"name": "Aroha",
		"createdDate": "2021-11-04 19:35:35",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 39,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 258,
		"schedulePercent": 0.184744,
		"totalGuests": 97,
		"guestPercent": 0.471461,
		"mostGuestDate": "2023-06-27 00:00:00",
		"mostGuestName": "Arrowtown Athenaeum Hall",
		"mostGuestCity": "Arrowtown",
		"mostGuestAmount": 29
	},
	{
		"id": "3314e47",
		"email": "cori@affectivemusic.com",
		"name": "Cori",
		"createdDate": "2022-03-26 17:57:45",
		"totalOrganizations": 3,
		"totalTours": 13,
		"totalDays": 31,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 215,
		"schedulePercent": 0.159846,
		"totalGuests": 13,
		"guestPercent": 0.352536,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Capital Jazz Fest",
		"mostGuestCity": "Columbia",
		"mostGuestAmount": 7
	},
	{
		"id": "8c1817b",
		"email": "murdbirgit@gmail.com",
		"name": "Brigit",
		"createdDate": "2021-12-08 14:29:00",
		"totalOrganizations": 7,
		"totalTours": 12,
		"totalDays": 525,
		"totalCountries": 13,
		"totalStates": 29,
		"totalEvents": 231,
		"eventPercent": 0.850616,
		"totalScheduleItems": 2440,
		"schedulePercent": 0.785062,
		"totalGuests": 180,
		"guestPercent": 0.536485,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "StÃ¥ngebrofÃ¤ltet",
		"mostGuestCity": "LinkÃ¶ping",
		"mostGuestAmount": 28
	},
	{
		"id": "e44946c",
		"email": "jwwms.prod@gmail.com",
		"name": "John",
		"createdDate": "2022-09-09 13:51:45",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 3,
		"totalStates": 31,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 1526,
		"schedulePercent": 0.623891,
		"totalGuests": 1790,
		"guestPercent": 0.919481,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 137
	},
	{
		"id": "79a31e0",
		"email": "Brianmichaelsouthall@gmail.com",
		"name": "Brian",
		"createdDate": "2013-06-29 04:58:21",
		"totalOrganizations": 9,
		"totalTours": 4,
		"totalDays": 616,
		"totalCountries": 5,
		"totalStates": 23,
		"totalEvents": 245,
		"eventPercent": 0.867435,
		"totalScheduleItems": 2858,
		"schedulePercent": 0.838697,
		"totalGuests": 66,
		"guestPercent": 0.441001,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "Allegiant Stadium",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 66
	},
	{
		"id": "1a63fd2",
		"email": "akcreative.org@gmail.com",
		"name": "Austin",
		"createdDate": "2021-08-25 18:56:11",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 456,
		"totalCountries": 10,
		"totalStates": 51,
		"totalEvents": 261,
		"eventPercent": 0.886902,
		"totalScheduleItems": 2638,
		"schedulePercent": 0.813535,
		"totalGuests": 183,
		"guestPercent": 0.538737,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 41
	},
	{
		"id": "15bbeaf",
		"email": "Carl@nobodymanagement.com",
		"name": "Carl",
		"createdDate": "2022-04-26 21:28:35",
		"totalOrganizations": 14,
		"totalTours": 11,
		"totalDays": 534,
		"totalCountries": 17,
		"totalStates": 25,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 837,
		"schedulePercent": 0.433453,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "Newcastle University",
		"mostGuestCity": "Newcastle upon Tyne",
		"mostGuestAmount": 4
	},
	{
		"id": "c53b0df",
		"email": "mat@bus-records.com",
		"name": "Matthieu",
		"createdDate": "2015-03-31 16:18:38",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 70,
		"totalCountries": 5,
		"totalStates": 20,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 213,
		"schedulePercent": 0.158125,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "14af978",
		"email": "anderstorp@me.com",
		"name": "Anders",
		"createdDate": "2015-06-23 08:12:07",
		"totalOrganizations": 26,
		"totalTours": 23,
		"totalDays": 542,
		"totalCountries": 21,
		"totalStates": 42,
		"totalEvents": 345,
		"eventPercent": 0.939478,
		"totalScheduleItems": 3186,
		"schedulePercent": 0.868494,
		"totalGuests": 446,
		"guestPercent": 0.671434,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "GÃ¦stgiveren ApS",
		"mostGuestCity": "Allinge",
		"mostGuestAmount": 37
	},
	{
		"id": "b9676e2",
		"email": "drewmagid@gmail.com",
		"name": "Drew",
		"createdDate": "2011-06-14 13:52:40",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 1335,
		"totalCountries": 4,
		"totalStates": 53,
		"totalEvents": 326,
		"eventPercent": 0.929281,
		"totalScheduleItems": 3634,
		"schedulePercent": 0.903324,
		"totalGuests": 583,
		"guestPercent": 0.719905,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 60
	},
	{
		"id": "4460664",
		"email": "Hayley.rippy@gmail.com",
		"name": "Hayley",
		"createdDate": "2016-12-23 20:12:29",
		"totalOrganizations": 17,
		"totalTours": 19,
		"totalDays": 340,
		"totalCountries": 7,
		"totalStates": 49,
		"totalEvents": 191,
		"eventPercent": 0.790491,
		"totalScheduleItems": 2691,
		"schedulePercent": 0.818302,
		"totalGuests": 1077,
		"guestPercent": 0.835916,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Cow Palace",
		"mostGuestCity": "Daly City",
		"mostGuestAmount": 226
	},
	{
		"id": "b139d16",
		"email": "clare@uptoeleven.com",
		"name": "Clare",
		"createdDate": "2020-01-25 01:02:22",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 212,
		"totalCountries": 11,
		"totalStates": 63,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 809,
		"schedulePercent": 0.422858,
		"totalGuests": 360,
		"guestPercent": 0.635015,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 29
	},
	{
		"id": "c0b3662",
		"email": "lindsey@fitzhart.com",
		"name": "Lindsey",
		"createdDate": "2013-08-12 20:27:57",
		"totalOrganizations": 10,
		"totalTours": 8,
		"totalDays": 1227,
		"totalCountries": 6,
		"totalStates": 56,
		"totalEvents": 391,
		"eventPercent": 0.95537,
		"totalScheduleItems": 4003,
		"schedulePercent": 0.923454,
		"totalGuests": 1726,
		"guestPercent": 0.914846,
		"mostGuestDate": "2023-11-21 00:00:00",
		"mostGuestName": "Wildhorse Saloon",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 283
	},
	{
		"id": "f4df504",
		"email": "laurieholzer@gmail.com",
		"name": "Laurie ",
		"createdDate": "2023-02-28 13:59:01",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 133,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 303,
		"schedulePercent": 0.209509,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "84eaaac",
		"email": "aaronsweatt@me.com",
		"name": "Aaron",
		"createdDate": "2016-08-29 17:10:17",
		"totalOrganizations": 27,
		"totalTours": 26,
		"totalDays": 877,
		"totalCountries": 15,
		"totalStates": 70,
		"totalEvents": 368,
		"eventPercent": 0.948749,
		"totalScheduleItems": 5079,
		"schedulePercent": 0.964111,
		"totalGuests": 2802,
		"guestPercent": 0.96464,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 300
	},
	{
		"id": "051a083",
		"email": "sayshesheproduction@gmail.com",
		"name": "Piya",
		"createdDate": "2023-01-21 23:01:59",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 260,
		"totalCountries": 9,
		"totalStates": 41,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1234,
		"schedulePercent": 0.548537,
		"totalGuests": 524,
		"guestPercent": 0.699775,
		"mostGuestDate": "2023-09-29 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 68
	},
	{
		"id": "dffe83c",
		"email": "danc@sarahmclachlan.com",
		"name": "Daniel",
		"createdDate": "2012-07-31 21:29:05",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 80,
		"totalCountries": 3,
		"totalStates": 23,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 284,
		"schedulePercent": 0.200503,
		"totalGuests": 275,
		"guestPercent": 0.59171,
		"mostGuestDate": "2023-04-12 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 45
	},
	{
		"id": "7d2fd34",
		"email": "Pointblankmusic@hotmail.com",
		"name": "Miguel",
		"createdDate": "2015-01-15 01:13:50",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 209,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 1349,
		"schedulePercent": 0.58032,
		"totalGuests": 504,
		"guestPercent": 0.693021,
		"mostGuestDate": "2023-09-13 00:00:00",
		"mostGuestName": "Byline Bank Aragon Ballroom",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 65
	},
	{
		"id": "380d132",
		"email": "conorcfriel@gmail.com",
		"name": "Conor",
		"createdDate": "2014-09-24 00:16:45",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 669,
		"totalCountries": 5,
		"totalStates": 12,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 366,
		"schedulePercent": 0.24116,
		"totalGuests": 78,
		"guestPercent": 0.45398,
		"mostGuestDate": "2023-02-02 00:00:00",
		"mostGuestName": "The Academy",
		"mostGuestCity": "Dublin 1",
		"mostGuestAmount": 39
	},
	{
		"id": "cec0747",
		"email": "bryanfostercontact@gmail.com",
		"name": "Bryan",
		"createdDate": "2017-08-30 06:51:53",
		"totalOrganizations": 9,
		"totalTours": 14,
		"totalDays": 385,
		"totalCountries": 11,
		"totalStates": 48,
		"totalEvents": 148,
		"eventPercent": 0.696332,
		"totalScheduleItems": 1306,
		"schedulePercent": 0.567872,
		"totalGuests": 293,
		"guestPercent": 0.599258,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Softball City",
		"mostGuestCity": "Surrey",
		"mostGuestAmount": 42
	},
	{
		"id": "f2f8bc3",
		"email": "kamryn@open-mgmt.com",
		"name": "Kamryn ",
		"createdDate": "2022-03-08 17:14:15",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 457,
		"totalCountries": 7,
		"totalStates": 41,
		"totalEvents": 159,
		"eventPercent": 0.726394,
		"totalScheduleItems": 1435,
		"schedulePercent": 0.600715,
		"totalGuests": 980,
		"guestPercent": 0.819362,
		"mostGuestDate": "2023-03-10 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 180
	},
	{
		"id": "ddd7bad",
		"email": "gafase72@gmail.com",
		"name": "FABIAN ",
		"createdDate": "2022-08-01 20:28:02",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 189,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 182,
		"schedulePercent": 0.139849,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e4d0687",
		"email": "jvitalari@me.com",
		"name": "Joey",
		"createdDate": "2018-02-13 19:50:25",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 671,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 785,
		"schedulePercent": 0.415044,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0fe6e0e",
		"email": "monilo1976@gmail.com",
		"name": "Camilo",
		"createdDate": "2023-01-17 18:19:40",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 299,
		"totalCountries": 8,
		"totalStates": 18,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 636,
		"schedulePercent": 0.356774,
		"totalGuests": 426,
		"guestPercent": 0.663488,
		"mostGuestDate": "2023-07-25 00:00:00",
		"mostGuestName": "Hordern Pavilion",
		"mostGuestCity": "Moore Park",
		"mostGuestAmount": 90
	},
	{
		"id": "fed318f",
		"email": "akief@stilettoentertainment.com",
		"name": "Adam",
		"createdDate": "2014-12-05 00:02:34",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 438,
		"totalCountries": 5,
		"totalStates": 27,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 2929,
		"schedulePercent": 0.845451,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1fd33a4",
		"email": "Luke.buckbee@gmail.com",
		"name": "Luke",
		"createdDate": "2009-12-29 23:10:01",
		"totalOrganizations": 12,
		"totalTours": 28,
		"totalDays": 603,
		"totalCountries": 24,
		"totalStates": 99,
		"totalEvents": 330,
		"eventPercent": 0.931665,
		"totalScheduleItems": 4241,
		"schedulePercent": 0.935373,
		"totalGuests": 1082,
		"guestPercent": 0.83671,
		"mostGuestDate": "2023-01-15 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 63
	},
	{
		"id": "2db2fa0",
		"email": "sgirmant@mac.com",
		"name": "Steven",
		"createdDate": "2009-07-03 17:01:42",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 197,
		"totalCountries": 3,
		"totalStates": 31,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 1755,
		"schedulePercent": 0.675407,
		"totalGuests": 587,
		"guestPercent": 0.721494,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 45
	},
	{
		"id": "e33b877",
		"email": "stephenafasano@gmail.com",
		"name": "Steve",
		"createdDate": "2014-10-02 19:22:48",
		"totalOrganizations": 14,
		"totalTours": 10,
		"totalDays": 436,
		"totalCountries": 12,
		"totalStates": 52,
		"totalEvents": 156,
		"eventPercent": 0.718051,
		"totalScheduleItems": 2075,
		"schedulePercent": 0.730764,
		"totalGuests": 956,
		"guestPercent": 0.814727,
		"mostGuestDate": "2023-07-19 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 51
	},
	{
		"id": "6efe845",
		"email": "kaykaleka3@gmail.com",
		"name": "Kay ",
		"createdDate": "2022-06-21 13:33:25",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 1042,
		"totalCountries": 5,
		"totalStates": 49,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 2424,
		"schedulePercent": 0.783075,
		"totalGuests": 1310,
		"guestPercent": 0.873262,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "Forest Hills Stadium",
		"mostGuestCity": "Forest Hills",
		"mostGuestAmount": 184
	},
	{
		"id": "43bccc6",
		"email": "nick@onlytours.co.uk",
		"name": "Nick",
		"createdDate": "2017-08-11 08:12:11",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 750,
		"totalCountries": 22,
		"totalStates": 64,
		"totalEvents": 229,
		"eventPercent": 0.847305,
		"totalScheduleItems": 3818,
		"schedulePercent": 0.912727,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "96aa769",
		"email": "rob@gnrentertainment.com",
		"name": "Rob",
		"createdDate": "2018-07-20 16:35:46",
		"totalOrganizations": 12,
		"totalTours": 8,
		"totalDays": 445,
		"totalCountries": 6,
		"totalStates": 27,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 845,
		"schedulePercent": 0.435969,
		"totalGuests": 144,
		"guestPercent": 0.509337,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "The Axis Club Theatre",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 41
	},
	{
		"id": "df56b3b",
		"email": "marciskolnick@gmail.com",
		"name": "Marci",
		"createdDate": "2021-06-02 01:59:59",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 155,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 788,
		"schedulePercent": 0.415574,
		"totalGuests": 833,
		"guestPercent": 0.78387,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "FirstBank Amphitheater",
		"mostGuestCity": "Franklin",
		"mostGuestAmount": 104
	},
	{
		"id": "716ffc6",
		"email": "avo@yosonic.com",
		"name": "Richard",
		"createdDate": "2011-10-09 18:37:02",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 170,
		"totalCountries": 5,
		"totalStates": 8,
		"totalEvents": 6,
		"eventPercent": 0.0397298,
		"totalScheduleItems": 1050,
		"schedulePercent": 0.497153,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a3629ef",
		"email": "pkennedy3333@gmail.com",
		"name": "Pat",
		"createdDate": "2019-10-23 03:52:21",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 57,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 438,
		"schedulePercent": 0.272017,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "HI-FI Indy",
		"mostGuestCity": "Indianapolis",
		"mostGuestAmount": 6
	},
	{
		"id": "dc53e1e",
		"email": "Dinovillanuevamusic@gmail.com",
		"name": "Dino",
		"createdDate": "2016-07-05 23:51:12",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 383,
		"totalCountries": 2,
		"totalStates": 35,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 938,
		"schedulePercent": 0.462058,
		"totalGuests": 250,
		"guestPercent": 0.578599,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Billy Bob's Texas",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 56
	},
	{
		"id": "6e36b9c",
		"email": "chadstirling@me.com",
		"name": "Chad",
		"createdDate": "2011-09-18 02:18:09",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 636,
		"totalCountries": 13,
		"totalStates": 51,
		"totalEvents": 185,
		"eventPercent": 0.780029,
		"totalScheduleItems": 2321,
		"schedulePercent": 0.769302,
		"totalGuests": 1389,
		"guestPercent": 0.881605,
		"mostGuestDate": "2023-06-02 00:00:00",
		"mostGuestName": "Avant Gardner",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 133
	},
	{
		"id": "daece6e",
		"email": "rob@invsble.biz",
		"name": "Rob",
		"createdDate": "2023-02-21 19:56:12",
		"totalOrganizations": 3,
		"totalTours": 12,
		"totalDays": 427,
		"totalCountries": 8,
		"totalStates": 35,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 784,
		"schedulePercent": 0.41425,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-09-21 00:00:00",
		"mostGuestName": "UPMC Events Center",
		"mostGuestCity": "Moon Twp",
		"mostGuestAmount": 1
	},
	{
		"id": "3b8ecb9",
		"email": "christian@rektor.no",
		"name": "Christian",
		"createdDate": "2019-02-25 19:24:19",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 532,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 1247,
		"schedulePercent": 0.551583,
		"totalGuests": 141,
		"guestPercent": 0.506026,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "StÃ¸periet scene",
		"mostGuestCity": "TÃ¸nsberg",
		"mostGuestAmount": 35
	},
	{
		"id": "bfcad88",
		"email": "kayzomusic@gmail.com",
		"name": "Hayden",
		"createdDate": "2015-11-10 11:40:00",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 427,
		"totalCountries": 13,
		"totalStates": 47,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 789,
		"schedulePercent": 0.415971,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-02-23 00:00:00",
		"mostGuestName": "Big Night Live",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 6
	},
	{
		"id": "8281bcb",
		"email": "eddiek@montgomerygentry.com",
		"name": "Eddie",
		"createdDate": "2013-06-09 00:32:13",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 744,
		"schedulePercent": 0.399947,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "54531bb",
		"email": "gwen@atcmanagement.com",
		"name": "Gwen",
		"createdDate": "2023-06-16 15:21:08",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 29,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 138,
		"schedulePercent": 0.112171,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c7d8b08",
		"email": "d.markclark@gmail.com",
		"name": "David",
		"createdDate": "2016-10-18 22:32:45",
		"totalOrganizations": 15,
		"totalTours": 18,
		"totalDays": 1658,
		"totalCountries": 18,
		"totalStates": 64,
		"totalEvents": 332,
		"eventPercent": 0.933916,
		"totalScheduleItems": 4671,
		"schedulePercent": 0.951,
		"totalGuests": 1094,
		"guestPercent": 0.8383,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "Palacio de los Deportes",
		"mostGuestCity": "Ciudad de MÃ©xico",
		"mostGuestAmount": 79
	},
	{
		"id": "fcf8ac4",
		"email": "Christina@mutinypr.com",
		"name": "Christina",
		"createdDate": "2023-05-22 21:05:04",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 242,
		"totalCountries": 4,
		"totalStates": 29,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 1293,
		"schedulePercent": 0.564429,
		"totalGuests": 990,
		"guestPercent": 0.821481,
		"mostGuestDate": "2023-08-27 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 151
	},
	{
		"id": "44c40d7",
		"email": "frank@thepianoguys.com",
		"name": "Frank",
		"createdDate": "2016-01-13 15:26:42",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 73,
		"totalCountries": 4,
		"totalStates": 20,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 583,
		"schedulePercent": 0.333068,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f8913a5",
		"email": "torill@ramalama.net",
		"name": "Torill",
		"createdDate": "2018-04-11 10:00:57",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 2092,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 449,
		"schedulePercent": 0.27599,
		"totalGuests": 259,
		"guestPercent": 0.583764,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "Sentrum Scene",
		"mostGuestCity": "Oslo",
		"mostGuestAmount": 73
	},
	{
		"id": "76afcf2",
		"email": "toddf379@gmail.com",
		"name": "Todd",
		"createdDate": "2016-06-01 19:13:12",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 368,
		"totalCountries": 1,
		"totalStates": 34,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 1076,
		"schedulePercent": 0.506158,
		"totalGuests": 26,
		"guestPercent": 0.381274,
		"mostGuestDate": "2023-06-01 00:00:00",
		"mostGuestName": "Devon Lakeshore Amphitheater",
		"mostGuestCity": "Decatur",
		"mostGuestAmount": 26
	},
	{
		"id": "1546cc4",
		"email": "info@monowhales.com",
		"name": "Zach",
		"createdDate": "2021-07-06 14:21:54",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 142,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 521,
		"schedulePercent": 0.308039,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-07-24 00:00:00",
		"mostGuestName": "Guelph Concert Theatre",
		"mostGuestCity": "Guelph",
		"mostGuestAmount": 2
	},
	{
		"id": "7b4fc7c",
		"email": "duluthsound@gmail.com",
		"name": "Keenan",
		"createdDate": "2021-05-25 02:13:24",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 122,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 1065,
		"schedulePercent": 0.50298,
		"totalGuests": 142,
		"guestPercent": 0.506688,
		"mostGuestDate": "2023-07-18 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 21
	},
	{
		"id": "52138f3",
		"email": "dillon@thefrequency.group",
		"name": "Dillon",
		"createdDate": "2022-08-08 22:14:42",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 175,
		"totalCountries": 8,
		"totalStates": 23,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 393,
		"schedulePercent": 0.254801,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "EPH Shanghai",
		"mostGuestCity": "",
		"mostGuestAmount": 3
	},
	{
		"id": "53c1a50",
		"email": "INFO@PONDEROSA.IT",
		"name": "Titti",
		"createdDate": "2018-01-29 16:43:18",
		"totalOrganizations": 68,
		"totalTours": 13,
		"totalDays": 237,
		"totalCountries": 15,
		"totalStates": 48,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1537,
		"schedulePercent": 0.627467,
		"totalGuests": 559,
		"guestPercent": 0.713415,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Vivo Rio",
		"mostGuestCity": "Rio de Janeiro",
		"mostGuestAmount": 26
	},
	{
		"id": "00ad964",
		"email": "caseyfatch@gmail.com",
		"name": "Casey",
		"createdDate": "2022-06-30 01:35:18",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 90,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 377,
		"schedulePercent": 0.246855,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bd7e93b",
		"email": "ianbussaudio@gmail.com",
		"name": "Ian",
		"createdDate": "2022-06-16 20:33:01",
		"totalOrganizations": 3,
		"totalTours": 15,
		"totalDays": 322,
		"totalCountries": 5,
		"totalStates": 50,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 876,
		"schedulePercent": 0.445901,
		"totalGuests": 155,
		"guestPercent": 0.51768,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Zebulon",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 31
	},
	{
		"id": "5b0dd06",
		"email": "tommykopecek@gmail.com",
		"name": "Tom",
		"createdDate": "2014-11-19 12:26:43",
		"totalOrganizations": 72,
		"totalTours": 69,
		"totalDays": 1017,
		"totalCountries": 18,
		"totalStates": 61,
		"totalEvents": 408,
		"eventPercent": 0.959078,
		"totalScheduleItems": 3920,
		"schedulePercent": 0.920011,
		"totalGuests": 1160,
		"guestPercent": 0.851278,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Manning Bar",
		"mostGuestCity": "Camperdown",
		"mostGuestAmount": 63
	},
	{
		"id": "1da5553",
		"email": "totaltour@gmail.com",
		"name": "Darren",
		"createdDate": "2019-10-02 18:40:44",
		"totalOrganizations": 6,
		"totalTours": 9,
		"totalDays": 982,
		"totalCountries": 15,
		"totalStates": 52,
		"totalEvents": 320,
		"eventPercent": 0.925705,
		"totalScheduleItems": 4059,
		"schedulePercent": 0.925705,
		"totalGuests": 1290,
		"guestPercent": 0.870746,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 172
	},
	{
		"id": "e717889",
		"email": "soy_emily@hotmail.com",
		"name": "Emily",
		"createdDate": "2019-02-01 10:13:07",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 83,
		"totalCountries": 9,
		"totalStates": 16,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 374,
		"schedulePercent": 0.24553,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "713 Music Hall",
		"mostGuestCity": "Houston",
		"mostGuestAmount": 2
	},
	{
		"id": "3337b70",
		"email": "Trygve@optilux.no",
		"name": "Trygve",
		"createdDate": "2023-11-24 13:07:53",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 23,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 117,
		"schedulePercent": 0.100914,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "96d8342",
		"email": "rickywattsmusic@gmail.com",
		"name": "Ricky",
		"createdDate": "2017-08-12 16:18:51",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 1259,
		"totalCountries": 7,
		"totalStates": 50,
		"totalEvents": 133,
		"eventPercent": 0.656602,
		"totalScheduleItems": 1884,
		"schedulePercent": 0.701894,
		"totalGuests": 420,
		"guestPercent": 0.661237,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Centerstage- The Loft",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 24
	},
	{
		"id": "c7f837b",
		"email": "smith.beccalynn@gmail.com",
		"name": "Becca",
		"createdDate": "2018-04-26 13:08:51",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 731,
		"totalCountries": 5,
		"totalStates": 54,
		"totalEvents": 397,
		"eventPercent": 0.956562,
		"totalScheduleItems": 5062,
		"schedulePercent": 0.963449,
		"totalGuests": 1128,
		"guestPercent": 0.844259,
		"mostGuestDate": "2023-12-06 00:00:00",
		"mostGuestName": "Terrace Theatre",
		"mostGuestCity": "Long Beach",
		"mostGuestAmount": 73
	},
	{
		"id": "e3c38bf",
		"email": "jimdigby@showmakers.com",
		"name": "James",
		"createdDate": "2019-01-04 21:21:54",
		"totalOrganizations": 7,
		"totalTours": 1,
		"totalDays": 40,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 495,
		"schedulePercent": 0.295722,
		"totalGuests": 363,
		"guestPercent": 0.637134,
		"mostGuestDate": "2023-05-09 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 55
	},
	{
		"id": "90a929a",
		"email": "Jordan@agartists.com",
		"name": "Jordan",
		"createdDate": "2015-09-03 01:15:24",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 176,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 645,
		"schedulePercent": 0.360482,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "Prudential Center",
		"mostGuestCity": "Newark",
		"mostGuestAmount": 1
	},
	{
		"id": "c3364cb",
		"email": "ant.carr@mac.com",
		"name": "Anthony",
		"createdDate": "2013-10-23 21:48:02",
		"totalOrganizations": 30,
		"totalTours": 13,
		"totalDays": 1407,
		"totalCountries": 15,
		"totalStates": 33,
		"totalEvents": 213,
		"eventPercent": 0.827837,
		"totalScheduleItems": 3383,
		"schedulePercent": 0.886108,
		"totalGuests": 142,
		"guestPercent": 0.506688,
		"mostGuestDate": "2023-02-02 00:00:00",
		"mostGuestName": "The Academy",
		"mostGuestCity": "Dublin 1",
		"mostGuestAmount": 39
	},
	{
		"id": "3f75931",
		"email": "rob@glassvillemusic.com",
		"name": "Rob",
		"createdDate": "2022-09-02 11:06:33",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 36,
		"totalCountries": 8,
		"totalStates": 15,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 442,
		"schedulePercent": 0.273606,
		"totalGuests": 357,
		"guestPercent": 0.633558,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "Manchester Academy",
		"mostGuestCity": "Manchester",
		"mostGuestAmount": 38
	},
	{
		"id": "b381fcf",
		"email": "neilsharptm@gmail.com",
		"name": "Neil",
		"createdDate": "2010-11-08 09:56:32",
		"totalOrganizations": 31,
		"totalTours": 10,
		"totalDays": 532,
		"totalCountries": 11,
		"totalStates": 23,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 1616,
		"schedulePercent": 0.645875,
		"totalGuests": 326,
		"guestPercent": 0.617137,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "The Downs",
		"mostGuestCity": "Bristol",
		"mostGuestAmount": 44
	},
	{
		"id": "92920e6",
		"email": "raheemamlani@gmail.com",
		"name": "Raheem",
		"createdDate": "2019-10-16 23:02:46",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 733,
		"totalCountries": 8,
		"totalStates": 70,
		"totalEvents": 276,
		"eventPercent": 0.898292,
		"totalScheduleItems": 2780,
		"schedulePercent": 0.829294,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "Middle C Jazz Club",
		"mostGuestCity": "Charlotte",
		"mostGuestAmount": 2
	},
	{
		"id": "3bb987d",
		"email": "patch@patchtheatre.org.au",
		"name": "Jaecinta",
		"createdDate": "2023-06-26 05:59:09",
		"totalOrganizations": 2,
		"totalTours": 13,
		"totalDays": 473,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 498,
		"schedulePercent": 0.297576,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d73221b",
		"email": "t.busskamp@buskamo.com",
		"name": "Tobias",
		"createdDate": "2015-06-19 11:15:21",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 274,
		"totalCountries": 4,
		"totalStates": 19,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 1759,
		"schedulePercent": 0.676202,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1e8efed",
		"email": "luc@devhynes.com",
		"name": "Luc",
		"createdDate": "2023-03-07 14:08:54",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 30,
		"totalCountries": 4,
		"totalStates": 9,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 95,
		"schedulePercent": 0.0860813,
		"totalGuests": 54,
		"guestPercent": 0.427096,
		"mostGuestDate": "2023-04-11 00:00:00",
		"mostGuestName": "Huxleys Neue Welt",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 27
	},
	{
		"id": "12bef96",
		"email": "bergfeld3@gmail.com",
		"name": "David",
		"createdDate": "2020-03-06 17:14:52",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 842,
		"totalCountries": 15,
		"totalStates": 56,
		"totalEvents": 268,
		"eventPercent": 0.893127,
		"totalScheduleItems": 3458,
		"schedulePercent": 0.890743,
		"totalGuests": 372,
		"guestPercent": 0.641902,
		"mostGuestDate": "2023-08-01 00:00:00",
		"mostGuestName": "The Bellweather",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 82
	},
	{
		"id": "361cf21",
		"email": "mgmt@rittmom.com",
		"name": "Jake",
		"createdDate": "2022-02-19 00:09:21",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 42,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 154,
		"schedulePercent": 0.123692,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "36b6117",
		"email": "jordin@underscore.works",
		"name": "Jordin",
		"createdDate": "2022-12-06 18:44:53",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 489,
		"totalCountries": 2,
		"totalStates": 40,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1178,
		"schedulePercent": 0.534234,
		"totalGuests": 117,
		"guestPercent": 0.488412,
		"mostGuestDate": "2023-10-12 00:00:00",
		"mostGuestName": "Buckhead Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 25
	},
	{
		"id": "6a5f506",
		"email": "stutenold@gmail.com",
		"name": "Stu ",
		"createdDate": "2015-03-02 19:23:56",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 125,
		"totalCountries": 7,
		"totalStates": 29,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 1575,
		"schedulePercent": 0.635677,
		"totalGuests": 2662,
		"guestPercent": 0.958416,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The ICON Festival Stage",
		"mostGuestCity": "Cincinnati",
		"mostGuestAmount": 238
	},
	{
		"id": "b955553",
		"email": "gtproduction@mac.com",
		"name": "Glen",
		"createdDate": "2017-06-16 08:14:29",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 208,
		"totalCountries": 7,
		"totalStates": 19,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 685,
		"schedulePercent": 0.37505,
		"totalGuests": 505,
		"guestPercent": 0.693815,
		"mostGuestDate": "2023-10-02 00:00:00",
		"mostGuestName": "Regent Theater DTLA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 59
	},
	{
		"id": "fdbf5ea",
		"email": "MacKenzie@ascot-travel.com",
		"name": "MacKenzie",
		"createdDate": "2022-01-07 22:48:35",
		"totalOrganizations": 31,
		"totalTours": 47,
		"totalDays": 8354,
		"totalCountries": 31,
		"totalStates": 122,
		"totalEvents": 2073,
		"eventPercent": 0.997351,
		"totalScheduleItems": 30627,
		"schedulePercent": 0.999338,
		"totalGuests": 9668,
		"guestPercent": 0.999205,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 240
	},
	{
		"id": "c8b27c9",
		"email": "flatblack2023@gmail.com",
		"name": "Jason",
		"createdDate": "2023-08-02 21:55:24",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 81,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 627,
		"schedulePercent": 0.352669,
		"totalGuests": 147,
		"guestPercent": 0.511323,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Bakkt Theater",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 21
	},
	{
		"id": "6d95a25",
		"email": "tanglejames@hotmail.com",
		"name": "James",
		"createdDate": "2013-12-11 11:17:59",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 363,
		"totalCountries": 6,
		"totalStates": 45,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 827,
		"schedulePercent": 0.429215,
		"totalGuests": 825,
		"guestPercent": 0.782281,
		"mostGuestDate": "2023-11-02 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 81
	},
	{
		"id": "1fe05bc",
		"email": "kedanger@gmail.com",
		"name": "Kylie",
		"createdDate": "2018-04-18 16:05:05",
		"totalOrganizations": 3,
		"totalTours": 18,
		"totalDays": 469,
		"totalCountries": 6,
		"totalStates": 41,
		"totalEvents": 210,
		"eventPercent": 0.823732,
		"totalScheduleItems": 2699,
		"schedulePercent": 0.8187,
		"totalGuests": 740,
		"guestPercent": 0.762151,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Kings Theatre",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 88
	},
	{
		"id": "cf13488",
		"email": "mastertour@underholdningsorkester.dk",
		"name": "Gitte",
		"createdDate": "2021-09-28 13:02:29",
		"totalOrganizations": 10,
		"totalTours": 5,
		"totalDays": 61,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 430,
		"schedulePercent": 0.268176,
		"totalGuests": 246,
		"guestPercent": 0.576612,
		"mostGuestDate": "2023-01-15 00:00:00",
		"mostGuestName": "DR Koncerthuset",
		"mostGuestCity": "Kobenhavn",
		"mostGuestAmount": 24
	},
	{
		"id": "d08a1c9",
		"email": "guidob247@gmail.com",
		"name": "Guido",
		"createdDate": "2012-03-29 18:56:33",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 196,
		"totalCountries": 4,
		"totalStates": 27,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 904,
		"schedulePercent": 0.453582,
		"totalGuests": 731,
		"guestPercent": 0.759634,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 87
	},
	{
		"id": "711e5c0",
		"email": "ericofdena@gmail.com",
		"name": "Eric",
		"createdDate": "2019-02-15 04:12:13",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 536,
		"totalCountries": 20,
		"totalStates": 53,
		"totalEvents": 273,
		"eventPercent": 0.896305,
		"totalScheduleItems": 2711,
		"schedulePercent": 0.821481,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2bab19b",
		"email": "advance@supermgmt.com",
		"name": "Super Music Group",
		"createdDate": "2020-02-03 18:04:44",
		"totalOrganizations": 10,
		"totalTours": 6,
		"totalDays": 223,
		"totalCountries": 5,
		"totalStates": 30,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 499,
		"schedulePercent": 0.298239,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4a1ab77",
		"email": "diggindirt.promotions@gmail.com",
		"name": "Tyler ",
		"createdDate": "2022-08-16 00:32:02",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 174,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 21,
		"schedulePercent": 0.0327109,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d791d5b",
		"email": "Beeehive@me.com",
		"name": "Mark",
		"createdDate": "2017-11-06 20:47:27",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 380,
		"totalCountries": 1,
		"totalStates": 34,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 917,
		"schedulePercent": 0.456363,
		"totalGuests": 524,
		"guestPercent": 0.699775,
		"mostGuestDate": "2023-11-26 00:00:00",
		"mostGuestName": "Ovens Auditorium",
		"mostGuestCity": "Charlotte",
		"mostGuestAmount": 28
	},
	{
		"id": "e447285",
		"email": "rob@idolighting.net",
		"name": "Rob",
		"createdDate": "2015-10-07 11:31:15",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 415,
		"totalCountries": 2,
		"totalStates": 47,
		"totalEvents": 208,
		"eventPercent": 0.819891,
		"totalScheduleItems": 2669,
		"schedulePercent": 0.816448,
		"totalGuests": 2013,
		"guestPercent": 0.934313,
		"mostGuestDate": "2023-01-29 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 229
	},
	{
		"id": "a7d6f45",
		"email": "alex@roussomusic.com",
		"name": "Alex",
		"createdDate": "2020-04-27 22:32:58",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 865,
		"totalCountries": 19,
		"totalStates": 64,
		"totalEvents": 186,
		"eventPercent": 0.782016,
		"totalScheduleItems": 1933,
		"schedulePercent": 0.707721,
		"totalGuests": 860,
		"guestPercent": 0.790226,
		"mostGuestDate": "2023-08-01 00:00:00",
		"mostGuestName": "The Bellweather",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 82
	},
	{
		"id": "b02cca9",
		"email": "marina@dbe.ag",
		"name": "Marina",
		"createdDate": "2022-05-12 13:18:09",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 39,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 321,
		"schedulePercent": 0.218779,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "142194a",
		"email": "vanessa.vakharia@gmail.com",
		"name": "vanessa",
		"createdDate": "2023-07-13 19:10:13",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 36,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 207,
		"schedulePercent": 0.154417,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "08a88bc",
		"email": "dhangley@gmail.com",
		"name": "Dave",
		"createdDate": "2023-03-23 16:02:51",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 125,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 212,
		"schedulePercent": 0.157595,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "Tulsa State Fair",
		"mostGuestCity": "Tulsa",
		"mostGuestAmount": 9
	},
	{
		"id": "923dde9",
		"email": "ns@tcob.biz",
		"name": "Niklas ",
		"createdDate": "2019-12-12 11:05:54",
		"totalOrganizations": 22,
		"totalTours": 23,
		"totalDays": 146,
		"totalCountries": 4,
		"totalStates": 6,
		"totalEvents": 137,
		"eventPercent": 0.666402,
		"totalScheduleItems": 887,
		"schedulePercent": 0.448947,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b8d4758",
		"email": "info@fabianlahme.de",
		"name": "Fabian",
		"createdDate": "2023-10-03 12:20:34",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 91,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 572,
		"schedulePercent": 0.328963,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5a008f6",
		"email": "Drsonics@gmail.com",
		"name": "James",
		"createdDate": "2016-03-23 22:44:00",
		"totalOrganizations": 10,
		"totalTours": 3,
		"totalDays": 120,
		"totalCountries": 3,
		"totalStates": 30,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 1006,
		"schedulePercent": 0.487088,
		"totalGuests": 456,
		"guestPercent": 0.676334,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "Ben And Jerrys Concerts On The Green",
		"mostGuestCity": "Shelburne",
		"mostGuestAmount": 71
	},
	{
		"id": "08c34be",
		"email": "sam@rtatouring.com",
		"name": "Sam ",
		"createdDate": "2018-07-16 03:15:07",
		"totalOrganizations": 19,
		"totalTours": 38,
		"totalDays": 892,
		"totalCountries": 20,
		"totalStates": 59,
		"totalEvents": 276,
		"eventPercent": 0.898292,
		"totalScheduleItems": 4022,
		"schedulePercent": 0.924248,
		"totalGuests": 1305,
		"guestPercent": 0.872202,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 243
	},
	{
		"id": "f27d8e6",
		"email": "iampaulfrost@me.com",
		"name": "Paul ",
		"createdDate": "2015-09-07 11:20:21",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 63,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 272,
		"schedulePercent": 0.193749,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d220e00",
		"email": "ben@constantartists.com",
		"name": "Ben",
		"createdDate": "2011-09-07 19:36:59",
		"totalOrganizations": 20,
		"totalTours": 16,
		"totalDays": 740,
		"totalCountries": 17,
		"totalStates": 73,
		"totalEvents": 253,
		"eventPercent": 0.877765,
		"totalScheduleItems": 3915,
		"schedulePercent": 0.919613,
		"totalGuests": 144,
		"guestPercent": 0.509337,
		"mostGuestDate": "2023-05-29 00:00:00",
		"mostGuestName": "Powerstation",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 57
	},
	{
		"id": "4acfd2d",
		"email": "Tommywood11@icloud.com",
		"name": "Tommy",
		"createdDate": "2022-08-23 19:29:28",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 876,
		"totalCountries": 4,
		"totalStates": 53,
		"totalEvents": 196,
		"eventPercent": 0.798835,
		"totalScheduleItems": 1900,
		"schedulePercent": 0.704013,
		"totalGuests": 189,
		"guestPercent": 0.54218,
		"mostGuestDate": "2023-10-02 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 73
	},
	{
		"id": "582c9d7",
		"email": "Johnnyunitas92@gmail.com",
		"name": "John",
		"createdDate": "2019-08-06 03:38:07",
		"totalOrganizations": 11,
		"totalTours": 13,
		"totalDays": 691,
		"totalCountries": 13,
		"totalStates": 58,
		"totalEvents": 337,
		"eventPercent": 0.936035,
		"totalScheduleItems": 3758,
		"schedulePercent": 0.909681,
		"totalGuests": 154,
		"guestPercent": 0.51662,
		"mostGuestDate": "2023-07-24 00:00:00",
		"mostGuestName": "Greensboro Coliseum Complex",
		"mostGuestCity": "Greensboro",
		"mostGuestAmount": 20
	},
	{
		"id": "91643ca",
		"email": "shinks@hahaha.com",
		"name": "Suzanne",
		"createdDate": "2012-02-24 18:33:58",
		"totalOrganizations": 15,
		"totalTours": 7,
		"totalDays": 162,
		"totalCountries": 4,
		"totalStates": 13,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 1390,
		"schedulePercent": 0.588531,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "132d3b7",
		"email": "KELLI@E1FX.COM",
		"name": "KELLI",
		"createdDate": "2021-08-10 16:45:04",
		"totalOrganizations": 46,
		"totalTours": 28,
		"totalDays": 393,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 252,
		"eventPercent": 0.876308,
		"totalScheduleItems": 299,
		"schedulePercent": 0.207787,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-01-08 00:00:00",
		"mostGuestName": "TIAA Bank Field",
		"mostGuestCity": "Jacksonville",
		"mostGuestAmount": 0
	},
	{
		"id": "ba37e14",
		"email": "henriette@showlab.no",
		"name": "Henriette",
		"createdDate": "2018-09-05 09:04:43",
		"totalOrganizations": 18,
		"totalTours": 10,
		"totalDays": 839,
		"totalCountries": 4,
		"totalStates": 15,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1072,
		"schedulePercent": 0.505364,
		"totalGuests": 275,
		"guestPercent": 0.59171,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Danebu Kongsgaard - Boutique Hotell",
		"mostGuestCity": "Aurdal",
		"mostGuestAmount": 40
	},
	{
		"id": "c9d488b",
		"email": "Markwjthrelfall@hotmail.com",
		"name": "Mark",
		"createdDate": "2018-04-22 20:33:21",
		"totalOrganizations": 13,
		"totalTours": 40,
		"totalDays": 1477,
		"totalCountries": 18,
		"totalStates": 90,
		"totalEvents": 438,
		"eventPercent": 0.966362,
		"totalScheduleItems": 4083,
		"schedulePercent": 0.926765,
		"totalGuests": 162,
		"guestPercent": 0.524169,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Centerstage- The Loft",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 24
	},
	{
		"id": "8d3cb76",
		"email": "luca@ludica.info",
		"name": "Luca Ruggiero",
		"createdDate": "2022-04-16 11:46:45",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 303,
		"totalCountries": 3,
		"totalStates": 46,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 988,
		"schedulePercent": 0.479539,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4fbba80",
		"email": "kwood24@me.com",
		"name": "Kyle",
		"createdDate": "2012-05-31 15:40:37",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 1461,
		"totalCountries": 6,
		"totalStates": 58,
		"totalEvents": 237,
		"eventPercent": 0.858429,
		"totalScheduleItems": 3546,
		"schedulePercent": 0.896967,
		"totalGuests": 2872,
		"guestPercent": 0.967422,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "Forest Hills Stadium",
		"mostGuestCity": "Forest Hills",
		"mostGuestAmount": 184
	},
	{
		"id": "f115d14",
		"email": "emir@act.as",
		"name": "Emir",
		"createdDate": "2022-07-07 08:13:30",
		"totalOrganizations": 21,
		"totalTours": 16,
		"totalDays": 343,
		"totalCountries": 4,
		"totalStates": 17,
		"totalEvents": 235,
		"eventPercent": 0.855516,
		"totalScheduleItems": 1725,
		"schedulePercent": 0.670242,
		"totalGuests": 174,
		"guestPercent": 0.532115,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Sentrum Scene",
		"mostGuestCity": "Oslo",
		"mostGuestAmount": 69
	},
	{
		"id": "34d4087",
		"email": "fraivillig1@gmail.com",
		"name": "Gabriel",
		"createdDate": "2022-05-17 11:50:26",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 177,
		"totalCountries": 3,
		"totalStates": 37,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 490,
		"schedulePercent": 0.292676,
		"totalGuests": 44,
		"guestPercent": 0.411469,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Philip Geiger Performing Arts Center",
		"mostGuestCity": "Houston",
		"mostGuestAmount": 12
	},
	{
		"id": "736083b",
		"email": "ruwankaru@gmail.com",
		"name": "Susantha",
		"createdDate": "2013-08-21 03:04:39",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 146,
		"totalCountries": 3,
		"totalStates": 21,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 972,
		"schedulePercent": 0.473447,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-09-03 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 3
	},
	{
		"id": "ad02d63",
		"email": "matthew.vanwinkle.87@gmail.com",
		"name": "Matt ",
		"createdDate": "2019-05-11 21:30:38",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 363,
		"totalCountries": 1,
		"totalStates": 25,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 969,
		"schedulePercent": 0.471063,
		"totalGuests": 29,
		"guestPercent": 0.387896,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "del Lago Resort & Casino",
		"mostGuestCity": "Waterloo",
		"mostGuestAmount": 10
	},
	{
		"id": "381d06e",
		"email": "sam@atlas-touring.com",
		"name": "Sam",
		"createdDate": "2021-04-23 14:27:05",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 842,
		"totalCountries": 7,
		"totalStates": 47,
		"totalEvents": 321,
		"eventPercent": 0.9265,
		"totalScheduleItems": 2871,
		"schedulePercent": 0.839756,
		"totalGuests": 532,
		"guestPercent": 0.703218,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 74
	},
	{
		"id": "898f935",
		"email": "shannon.steele@opera.org.au",
		"name": "Shannon",
		"createdDate": "2022-04-21 00:22:37",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 71,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 421,
		"schedulePercent": 0.264468,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "85dc01b",
		"email": "natalie.hosselton@gmail.com",
		"name": "Natalie",
		"createdDate": "2014-08-02 19:48:28",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 517,
		"totalCountries": 16,
		"totalStates": 59,
		"totalEvents": 234,
		"eventPercent": 0.854324,
		"totalScheduleItems": 4819,
		"schedulePercent": 0.9559,
		"totalGuests": 2368,
		"guestPercent": 0.949146,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 171
	},
	{
		"id": "1750569",
		"email": "joslama@gmail.com",
		"name": "Joanne",
		"createdDate": "2023-06-12 22:02:11",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 39,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 202,
		"schedulePercent": 0.1519,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-07-02 00:00:00",
		"mostGuestName": "Knockdown Center",
		"mostGuestCity": "Maspeth",
		"mostGuestAmount": 12
	},
	{
		"id": "b42cbc5",
		"email": "mike@mikechilders.me",
		"name": "Michael",
		"createdDate": "2010-01-27 05:03:58",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 591,
		"totalCountries": 3,
		"totalStates": 42,
		"totalEvents": 290,
		"eventPercent": 0.909019,
		"totalScheduleItems": 4427,
		"schedulePercent": 0.942524,
		"totalGuests": 2808,
		"guestPercent": 0.964773,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Paycom Center",
		"mostGuestCity": "Oklahoma City",
		"mostGuestAmount": 167
	},
	{
		"id": "58e07d8",
		"email": "ollie@17ten.co",
		"name": "Ollie",
		"createdDate": "2016-07-05 18:11:11",
		"totalOrganizations": 7,
		"totalTours": 1,
		"totalDays": 332,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 233,
		"schedulePercent": 0.171368,
		"totalGuests": 95,
		"guestPercent": 0.470269,
		"mostGuestDate": "2023-02-04 00:00:00",
		"mostGuestName": "Exchange LA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 45
	},
	{
		"id": "e240c39",
		"email": "andrew.borstein@redlightmanagement.com",
		"name": "Andrew",
		"createdDate": "2022-09-16 15:19:17",
		"totalOrganizations": 4,
		"totalTours": 14,
		"totalDays": 267,
		"totalCountries": 3,
		"totalStates": 36,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 1211,
		"schedulePercent": 0.542842,
		"totalGuests": 727,
		"guestPercent": 0.759105,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "The Eastern",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 45
	},
	{
		"id": "21438d4",
		"email": "Dwnstg@aol.com",
		"name": "Ivan",
		"createdDate": "2009-09-17 21:23:49",
		"totalOrganizations": 12,
		"totalTours": 13,
		"totalDays": 271,
		"totalCountries": 5,
		"totalStates": 39,
		"totalEvents": 160,
		"eventPercent": 0.72838,
		"totalScheduleItems": 2540,
		"schedulePercent": 0.79804,
		"totalGuests": 2494,
		"guestPercent": 0.953781,
		"mostGuestDate": "2023-07-18 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 170
	},
	{
		"id": "ac56f89",
		"email": "helgaviridiana@gmail.com",
		"name": "Helga",
		"createdDate": "2019-04-15 03:43:29",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 654,
		"totalCountries": 3,
		"totalStates": 43,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1967,
		"schedulePercent": 0.713813,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e332497",
		"email": "Lara.Bothe98@gmail.com",
		"name": "Lara",
		"createdDate": "2022-04-19 08:34:39",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 165,
		"totalCountries": 7,
		"totalStates": 16,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 766,
		"schedulePercent": 0.407496,
		"totalGuests": 197,
		"guestPercent": 0.548669,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "Zebulon",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 28
	},
	{
		"id": "af6429c",
		"email": "paulftouring@gmail.com",
		"name": "Email ",
		"createdDate": "2017-03-10 21:51:08",
		"totalOrganizations": 7,
		"totalTours": 19,
		"totalDays": 414,
		"totalCountries": 17,
		"totalStates": 56,
		"totalEvents": 166,
		"eventPercent": 0.74308,
		"totalScheduleItems": 1082,
		"schedulePercent": 0.50841,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8268ffb",
		"email": "graham@luckiepierre.com",
		"name": "Graham",
		"createdDate": "2017-06-17 02:38:09",
		"totalOrganizations": 3,
		"totalTours": 38,
		"totalDays": 238,
		"totalCountries": 4,
		"totalStates": 39,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 2314,
		"schedulePercent": 0.767448,
		"totalGuests": 533,
		"guestPercent": 0.703748,
		"mostGuestDate": "2023-11-02 00:00:00",
		"mostGuestName": "The Theatre at Ace Hotel",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 70
	},
	{
		"id": "08cc126",
		"email": "marisa@darkroastmgmt.com",
		"name": "Marisa",
		"createdDate": "2023-02-07 21:03:59",
		"totalOrganizations": 7,
		"totalTours": 16,
		"totalDays": 1486,
		"totalCountries": 15,
		"totalStates": 75,
		"totalEvents": 182,
		"eventPercent": 0.773408,
		"totalScheduleItems": 1958,
		"schedulePercent": 0.713283,
		"totalGuests": 301,
		"guestPercent": 0.603099,
		"mostGuestDate": "2023-03-20 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 69
	},
	{
		"id": "e64afbb",
		"email": "marina@nubyagarcia.com",
		"name": "Marina",
		"createdDate": "2020-01-13 16:52:06",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 47,
		"totalCountries": 8,
		"totalStates": 9,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 290,
		"schedulePercent": 0.203152,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-07-26 00:00:00",
		"mostGuestName": "Marseille Jazz Festival",
		"mostGuestCity": "marseille",
		"mostGuestAmount": 5
	},
	{
		"id": "cf9bb2d",
		"email": "walle@450-agency.com",
		"name": "Walle",
		"createdDate": "2019-06-06 10:43:07",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 246,
		"totalCountries": 10,
		"totalStates": 23,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1265,
		"schedulePercent": 0.555953,
		"totalGuests": 1156,
		"guestPercent": 0.850351,
		"mostGuestDate": "2023-07-19 00:00:00",
		"mostGuestName": "Schlossplatz Stuttgart",
		"mostGuestCity": "Stuttgart",
		"mostGuestAmount": 214
	},
	{
		"id": "8c6b3a4",
		"email": "tomwindish@gmail.com",
		"name": "Tom",
		"createdDate": "2022-04-19 20:06:35",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 431,
		"totalCountries": 6,
		"totalStates": 41,
		"totalEvents": 141,
		"eventPercent": 0.678321,
		"totalScheduleItems": 1221,
		"schedulePercent": 0.545491,
		"totalGuests": 447,
		"guestPercent": 0.672096,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 50
	},
	{
		"id": "04f0aa7",
		"email": "mamie@threesixzero.com",
		"name": "Mamie",
		"createdDate": "2023-01-09 15:35:03",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 267,
		"totalCountries": 8,
		"totalStates": 34,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 694,
		"schedulePercent": 0.37836,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-11-26 00:00:00",
		"mostGuestName": "Vogue Theatre",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 8
	},
	{
		"id": "4f17b9e",
		"email": "carrie.todesco@gmail.com",
		"name": "Carrie",
		"createdDate": "2011-09-22 02:29:45",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 500,
		"totalCountries": 5,
		"totalStates": 35,
		"totalEvents": 252,
		"eventPercent": 0.876308,
		"totalScheduleItems": 2733,
		"schedulePercent": 0.823864,
		"totalGuests": 196,
		"guestPercent": 0.546947,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "Madison Square Garden",
		"mostGuestCity": "New York",
		"mostGuestAmount": 22
	},
	{
		"id": "9d59420",
		"email": "andyshillito@mac.com",
		"name": "Andy",
		"createdDate": "2015-11-27 20:56:35",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 133,
		"totalCountries": 10,
		"totalStates": 34,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 990,
		"schedulePercent": 0.480201,
		"totalGuests": 703,
		"guestPercent": 0.753807,
		"mostGuestDate": "2023-02-04 00:00:00",
		"mostGuestName": "OVO Arena, Wembley",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 110
	},
	{
		"id": "a174041",
		"email": "maria@cmg-live.com",
		"name": "Maria",
		"createdDate": "2018-09-07 14:52:32",
		"totalOrganizations": 10,
		"totalTours": 3,
		"totalDays": 37,
		"totalCountries": 4,
		"totalStates": 8,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 310,
		"schedulePercent": 0.213349,
		"totalGuests": 166,
		"guestPercent": 0.52748,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Nordichallen",
		"mostGuestCity": "Sundsvall",
		"mostGuestAmount": 103
	},
	{
		"id": "a77f7f7",
		"email": "emailchristhacker@gmail.com",
		"name": "Christopher",
		"createdDate": "2013-03-26 00:27:23",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 53,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 165,
		"schedulePercent": 0.129917,
		"totalGuests": 34,
		"guestPercent": 0.395842,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "Newport Music Hall",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 12
	},
	{
		"id": "63ddb0c",
		"email": "info@liveniteevents.com",
		"name": "Info",
		"createdDate": "2023-04-19 20:10:17",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 1350,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1374,
		"schedulePercent": 0.58628,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "79085f1",
		"email": "lersummer@yahoo.com",
		"name": "Lindsay ",
		"createdDate": "2015-09-16 00:35:08",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 423,
		"totalCountries": 9,
		"totalStates": 11,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 102,
		"schedulePercent": 0.0920408,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "80132fb",
		"email": "nreidco@me.com",
		"name": "Neville",
		"createdDate": "2023-03-17 01:38:49",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 65,
		"totalCountries": 2,
		"totalStates": 17,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 577,
		"schedulePercent": 0.33095,
		"totalGuests": 153,
		"guestPercent": 0.515958,
		"mostGuestDate": "2023-10-10 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 34
	},
	{
		"id": "ac14714",
		"email": "Dan@cubsport.com",
		"name": "Daniel",
		"createdDate": "2018-07-25 22:18:56",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 396,
		"totalCountries": 4,
		"totalStates": 29,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 526,
		"schedulePercent": 0.310025,
		"totalGuests": 67,
		"guestPercent": 0.443253,
		"mostGuestDate": "2023-05-04 00:00:00",
		"mostGuestName": "Lafayette",
		"mostGuestCity": "London",
		"mostGuestAmount": 61
	},
	{
		"id": "bc84dde",
		"email": "Tyler@bisonbear.com",
		"name": "Tyler ",
		"createdDate": "2020-11-02 23:01:38",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 1270,
		"totalCountries": 1,
		"totalStates": 27,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 102,
		"schedulePercent": 0.0920408,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e08fffc",
		"email": "libbydostart@me.com",
		"name": "Libby",
		"createdDate": "2013-06-04 13:09:14",
		"totalOrganizations": 13,
		"totalTours": 15,
		"totalDays": 783,
		"totalCountries": 15,
		"totalStates": 56,
		"totalEvents": 322,
		"eventPercent": 0.927162,
		"totalScheduleItems": 4912,
		"schedulePercent": 0.958946,
		"totalGuests": 395,
		"guestPercent": 0.651569,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 207
	},
	{
		"id": "321e355",
		"email": "kvmitchell@me.com",
		"name": "Ken",
		"createdDate": "2014-03-18 17:06:16",
		"totalOrganizations": 9,
		"totalTours": 5,
		"totalDays": 140,
		"totalCountries": 7,
		"totalStates": 35,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 1430,
		"schedulePercent": 0.599258,
		"totalGuests": 1414,
		"guestPercent": 0.884254,
		"mostGuestDate": "2023-08-30 00:00:00",
		"mostGuestName": "Hollywood Casino Amphitheatre",
		"mostGuestCity": "Tinley Park",
		"mostGuestAmount": 122
	},
	{
		"id": "4a4f4e2",
		"email": "hellokaylareid@gmail.com",
		"name": "Kayla",
		"createdDate": "2021-07-11 23:23:09",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 79,
		"totalCountries": 3,
		"totalStates": 18,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 830,
		"schedulePercent": 0.430539,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "HyvinkÃ¤Ã¤ Rockfest",
		"mostGuestCity": "HyvinkÃ¤Ã¤",
		"mostGuestAmount": 2
	},
	{
		"id": "b81861b",
		"email": "lmillar05@gmail.com",
		"name": "Lauren",
		"createdDate": "2019-01-07 20:52:41",
		"totalOrganizations": 4,
		"totalTours": 10,
		"totalDays": 535,
		"totalCountries": 14,
		"totalStates": 47,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 1907,
		"schedulePercent": 0.705205,
		"totalGuests": 406,
		"guestPercent": 0.65594,
		"mostGuestDate": "2023-05-08 00:00:00",
		"mostGuestName": "Crystal Ballroom",
		"mostGuestCity": "Portland",
		"mostGuestAmount": 48
	},
	{
		"id": "49f9abf",
		"email": "zwtourmanagement@gmail.com",
		"name": "Nathan",
		"createdDate": "2021-01-22 00:48:11",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 287,
		"totalCountries": 2,
		"totalStates": 39,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 2302,
		"schedulePercent": 0.765726,
		"totalGuests": 1696,
		"guestPercent": 0.912594,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "State Farm Arena",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 133
	},
	{
		"id": "858c575",
		"email": "factotumartists@gmail.com",
		"name": "Bill",
		"createdDate": "2016-02-01 17:32:55",
		"totalOrganizations": 11,
		"totalTours": 22,
		"totalDays": 425,
		"totalCountries": 4,
		"totalStates": 15,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 1080,
		"schedulePercent": 0.507747,
		"totalGuests": 463,
		"guestPercent": 0.678321,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "McPherson Playhouse",
		"mostGuestCity": "Victoria",
		"mostGuestAmount": 42
	},
	{
		"id": "784388d",
		"email": "Danjstork@gmail.com",
		"name": "Dan",
		"createdDate": "2013-10-09 14:21:00",
		"totalOrganizations": 16,
		"totalTours": 43,
		"totalDays": 624,
		"totalCountries": 16,
		"totalStates": 64,
		"totalEvents": 257,
		"eventPercent": 0.882929,
		"totalScheduleItems": 2749,
		"schedulePercent": 0.825718,
		"totalGuests": 202,
		"guestPercent": 0.552774,
		"mostGuestDate": "2023-02-14 00:00:00",
		"mostGuestName": "Forum Melbourne",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 41
	},
	{
		"id": "c1637fa",
		"email": "trftourmanager@distillermusic.com",
		"name": "John ",
		"createdDate": "2022-07-05 09:26:07",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 36,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 178,
		"schedulePercent": 0.137333,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-03-29 00:00:00",
		"mostGuestName": "The Sinclair",
		"mostGuestCity": "Cambridge",
		"mostGuestAmount": 5
	},
	{
		"id": "5ee4774",
		"email": "olemikkelsen1@mac.com",
		"name": "Ole",
		"createdDate": "2017-10-23 12:57:56",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 123,
		"totalCountries": 3,
		"totalStates": 4,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 1005,
		"schedulePercent": 0.486426,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-03-22 00:00:00",
		"mostGuestName": "Harpa Concert Hall and Conference Centre",
		"mostGuestCity": "RVK",
		"mostGuestAmount": 4
	},
	{
		"id": "bb3e9c1",
		"email": "pablomathiason@mac.com",
		"name": "Pablo",
		"createdDate": "2022-06-20 13:31:01",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 182,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 460,
		"schedulePercent": 0.280625,
		"totalGuests": 429,
		"guestPercent": 0.664283,
		"mostGuestDate": "2023-11-19 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 88
	},
	{
		"id": "7b29dc2",
		"email": "zoe@acomicsoul.com",
		"name": "Zoe",
		"createdDate": "2022-12-12 10:27:32",
		"totalOrganizations": 63,
		"totalTours": 59,
		"totalDays": 1104,
		"totalCountries": 24,
		"totalStates": 49,
		"totalEvents": 319,
		"eventPercent": 0.925175,
		"totalScheduleItems": 2353,
		"schedulePercent": 0.774335,
		"totalGuests": 56,
		"guestPercent": 0.429744,
		"mostGuestDate": "2023-09-26 00:00:00",
		"mostGuestName": "Teatro BorrÃ¡s",
		"mostGuestCity": "Barcelona",
		"mostGuestAmount": 17
	},
	{
		"id": "cb60218",
		"email": "ckdf.touring@gmail.com",
		"name": "Chris ",
		"createdDate": "2022-04-22 11:37:06",
		"totalOrganizations": 11,
		"totalTours": 21,
		"totalDays": 438,
		"totalCountries": 23,
		"totalStates": 67,
		"totalEvents": 234,
		"eventPercent": 0.854324,
		"totalScheduleItems": 3992,
		"schedulePercent": 0.922792,
		"totalGuests": 572,
		"guestPercent": 0.717786,
		"mostGuestDate": "2023-03-07 00:00:00",
		"mostGuestName": "London Shepherds Bush Empire",
		"mostGuestCity": "London",
		"mostGuestAmount": 70
	},
	{
		"id": "af0da9e",
		"email": "watt.lauren@gmail.com",
		"name": "Lauren",
		"createdDate": "2016-09-15 18:50:24",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 877,
		"totalCountries": 3,
		"totalStates": 30,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 1817,
		"schedulePercent": 0.688386,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4555507",
		"email": "info@tjptours.com",
		"name": "TJP",
		"createdDate": "2022-07-23 11:50:16",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 235,
		"totalCountries": 8,
		"totalStates": 43,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 1334,
		"schedulePercent": 0.576083,
		"totalGuests": 1243,
		"guestPercent": 0.863727,
		"mostGuestDate": "2023-01-22 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 133
	},
	{
		"id": "f42186d",
		"email": "aimeejanisch@gmail.com",
		"name": "Aimee",
		"createdDate": "2017-01-13 05:47:18",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 454,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 565,
		"schedulePercent": 0.326844,
		"totalGuests": 92,
		"guestPercent": 0.466163,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Barclays Center",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 81
	},
	{
		"id": "a74180d",
		"email": "mattboyer1@live.com",
		"name": "Matt",
		"createdDate": "2022-01-19 20:05:10",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 391,
		"totalCountries": 14,
		"totalStates": 58,
		"totalEvents": 182,
		"eventPercent": 0.773408,
		"totalScheduleItems": 3287,
		"schedulePercent": 0.876705,
		"totalGuests": 3967,
		"guestPercent": 0.983446,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 221
	},
	{
		"id": "3aa2357",
		"email": "brad@dblblk.com",
		"name": "Bradley",
		"createdDate": "2019-08-14 19:13:15",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 711,
		"totalCountries": 12,
		"totalStates": 30,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 734,
		"schedulePercent": 0.39359,
		"totalGuests": 251,
		"guestPercent": 0.579393,
		"mostGuestDate": "2023-08-01 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 57
	},
	{
		"id": "de88803",
		"email": "steviedoherty1968@gmail.com",
		"name": "Stephen",
		"createdDate": "2017-07-23 14:46:48",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 221,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 617,
		"schedulePercent": 0.348431,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Winspear Centre",
		"mostGuestCity": "Edmonton",
		"mostGuestAmount": 14
	},
	{
		"id": "123f07c",
		"email": "olietrethewey.music@gmail.com",
		"name": "Olie",
		"createdDate": "2017-02-07 18:24:18",
		"totalOrganizations": 18,
		"totalTours": 7,
		"totalDays": 224,
		"totalCountries": 14,
		"totalStates": 53,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 1107,
		"schedulePercent": 0.513309,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-07-26 00:00:00",
		"mostGuestName": "Marseille Jazz Festival",
		"mostGuestCity": "marseille",
		"mostGuestAmount": 5
	},
	{
		"id": "b5fcc66",
		"email": "thisisgonegonebeyond@gmail.com",
		"name": "Katherine",
		"createdDate": "2022-03-18 18:07:17",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 49,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 228,
		"schedulePercent": 0.168719,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "aa85e79",
		"email": "eivind@dde.no",
		"name": "DDE-",
		"createdDate": "2017-08-19 10:09:31",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 51,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 351,
		"schedulePercent": 0.233611,
		"totalGuests": 450,
		"guestPercent": 0.67395,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "Rockefeller Music Hall",
		"mostGuestCity": "Oslo",
		"mostGuestAmount": 86
	},
	{
		"id": "70e8b4f",
		"email": "andries@toutpartout.be",
		"name": "Andries",
		"createdDate": "2022-03-15 15:08:51",
		"totalOrganizations": 35,
		"totalTours": 32,
		"totalDays": 794,
		"totalCountries": 23,
		"totalStates": 108,
		"totalEvents": 470,
		"eventPercent": 0.970732,
		"totalScheduleItems": 3916,
		"schedulePercent": 0.919746,
		"totalGuests": 1683,
		"guestPercent": 0.911005,
		"mostGuestDate": "2023-03-14 00:00:00",
		"mostGuestName": "Huxley's",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 64
	},
	{
		"id": "83c21ae",
		"email": "hairspraycm@networkstours.com",
		"name": "Co.",
		"createdDate": "2021-09-29 23:51:23",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 193,
		"totalCountries": 3,
		"totalStates": 26,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 1790,
		"schedulePercent": 0.682823,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "841e443",
		"email": "ryanmatthias@yahoo.com",
		"name": "Ryan ",
		"createdDate": "2013-08-31 19:22:45",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 294,
		"totalCountries": 4,
		"totalStates": 33,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 854,
		"schedulePercent": 0.43875,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "50abd9d",
		"email": "chelseaalbanesi@gmail.com",
		"name": "Chelsea",
		"createdDate": "2021-01-12 06:49:51",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 676,
		"totalCountries": 3,
		"totalStates": 56,
		"totalEvents": 505,
		"eventPercent": 0.974838,
		"totalScheduleItems": 4927,
		"schedulePercent": 0.959873,
		"totalGuests": 58,
		"guestPercent": 0.431863,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "Kauffman Center for the Performing Arts",
		"mostGuestCity": "Kansas City",
		"mostGuestAmount": 18
	},
	{
		"id": "3f91c7e",
		"email": "matthewmilesrennie@gmail.com",
		"name": "Matthew ",
		"createdDate": "2021-08-11 13:59:24",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 366,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 835,
		"schedulePercent": 0.432923,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2b64e17",
		"email": "davidsoundguy@gmail.com",
		"name": "David ",
		"createdDate": "2012-07-20 16:22:35",
		"totalOrganizations": 8,
		"totalTours": 2,
		"totalDays": 199,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 206,
		"schedulePercent": 0.153754,
		"totalGuests": 177,
		"guestPercent": 0.534366,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Ophelia's Electric Soapbox",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 30
	},
	{
		"id": "9104b2a",
		"email": "michaelpsullivantm@gmail.com",
		"name": "Michael",
		"createdDate": "2019-06-19 00:28:50",
		"totalOrganizations": 7,
		"totalTours": 1,
		"totalDays": 286,
		"totalCountries": 14,
		"totalStates": 41,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 921,
		"schedulePercent": 0.457423,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "70155d1",
		"email": "will@sheltermusic.com",
		"name": "Will",
		"createdDate": "2017-03-02 22:10:25",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 372,
		"totalCountries": 10,
		"totalStates": 51,
		"totalEvents": 195,
		"eventPercent": 0.796318,
		"totalScheduleItems": 2928,
		"schedulePercent": 0.845186,
		"totalGuests": 528,
		"guestPercent": 0.701629,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The Aztec Theatre",
		"mostGuestCity": "San Antonio",
		"mostGuestAmount": 70
	},
	{
		"id": "52aef43",
		"email": "joona@avpro.fi",
		"name": "Joona",
		"createdDate": "2022-01-11 10:10:42",
		"totalOrganizations": 48,
		"totalTours": 49,
		"totalDays": 1288,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 1193,
		"eventPercent": 0.992186,
		"totalScheduleItems": 4500,
		"schedulePercent": 0.944908,
		"totalGuests": 26,
		"guestPercent": 0.381274,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Ruisrock",
		"mostGuestCity": "Turku",
		"mostGuestAmount": 6
	},
	{
		"id": "a63865b",
		"email": "jesper@roadogz.com",
		"name": "Jesper",
		"createdDate": "2014-06-17 18:34:55",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 92,
		"totalCountries": 4,
		"totalStates": 20,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 689,
		"schedulePercent": 0.376242,
		"totalGuests": 179,
		"guestPercent": 0.535426,
		"mostGuestDate": "2023-11-06 00:00:00",
		"mostGuestName": "Pantages Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 56
	},
	{
		"id": "e355bed",
		"email": "andrewdangeloaudiollc@gmail.com",
		"name": "andrew",
		"createdDate": "2023-03-13 04:36:17",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 81,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 463,
		"schedulePercent": 0.282082,
		"totalGuests": 177,
		"guestPercent": 0.534366,
		"mostGuestDate": "2023-04-08 00:00:00",
		"mostGuestName": "House of Blues Chicago",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 51
	},
	{
		"id": "af4ba4e",
		"email": "arniejweil@gmail.com",
		"name": "Arnie",
		"createdDate": "2014-11-10 06:06:29",
		"totalOrganizations": 13,
		"totalTours": 15,
		"totalDays": 311,
		"totalCountries": 11,
		"totalStates": 51,
		"totalEvents": 177,
		"eventPercent": 0.765594,
		"totalScheduleItems": 1908,
		"schedulePercent": 0.705469,
		"totalGuests": 2609,
		"guestPercent": 0.956562,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 121
	},
	{
		"id": "88ca04b",
		"email": "carmen.negrelli@gmail.com",
		"name": "Carmen",
		"createdDate": "2015-08-11 20:29:30",
		"totalOrganizations": 10,
		"totalTours": 23,
		"totalDays": 896,
		"totalCountries": 12,
		"totalStates": 60,
		"totalEvents": 303,
		"eventPercent": 0.916832,
		"totalScheduleItems": 3543,
		"schedulePercent": 0.896702,
		"totalGuests": 1795,
		"guestPercent": 0.919878,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "Forest Hills Stadium",
		"mostGuestCity": "Forest Hills",
		"mostGuestAmount": 184
	},
	{
		"id": "a5ddfc8",
		"email": "info@disquesfarwest.com",
		"name": "Philippe",
		"createdDate": "2021-11-11 17:02:49",
		"totalOrganizations": 10,
		"totalTours": 9,
		"totalDays": 1565,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 578,
		"schedulePercent": 0.331082,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "915645a",
		"email": "sarah@seventyhertz.com",
		"name": "Sarah ",
		"createdDate": "2023-05-08 19:40:00",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 276,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 186,
		"schedulePercent": 0.143027,
		"totalGuests": 10,
		"guestPercent": 0.343531,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "All Points East",
		"mostGuestCity": "London",
		"mostGuestAmount": 5
	},
	{
		"id": "48aeca7",
		"email": "Eric@lightcave.com",
		"name": "Eric",
		"createdDate": "2019-05-21 06:09:48",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 530,
		"totalCountries": 3,
		"totalStates": 46,
		"totalEvents": 170,
		"eventPercent": 0.752086,
		"totalScheduleItems": 2572,
		"schedulePercent": 0.802013,
		"totalGuests": 1382,
		"guestPercent": 0.881208,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "Vina Robles Amphitheatre",
		"mostGuestCity": "Paso Robles",
		"mostGuestAmount": 52
	},
	{
		"id": "623e030",
		"email": "zach.sutton@redlightmanagement.com",
		"name": "Zach",
		"createdDate": "2017-09-20 19:55:07",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 745,
		"totalCountries": 6,
		"totalStates": 56,
		"totalEvents": 229,
		"eventPercent": 0.847305,
		"totalScheduleItems": 5545,
		"schedulePercent": 0.974176,
		"totalGuests": 2130,
		"guestPercent": 0.940538,
		"mostGuestDate": "2023-10-12 00:00:00",
		"mostGuestName": "Walk of Fame Park",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 148
	},
	{
		"id": "3f3085d",
		"email": "tom@rendcollectiveexperiment.com",
		"name": "Tom",
		"createdDate": "2014-05-09 14:09:01",
		"totalOrganizations": 18,
		"totalTours": 30,
		"totalDays": 1240,
		"totalCountries": 4,
		"totalStates": 44,
		"totalEvents": 411,
		"eventPercent": 0.960138,
		"totalScheduleItems": 7007,
		"schedulePercent": 0.989405,
		"totalGuests": 2652,
		"guestPercent": 0.958019,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 293
	},
	{
		"id": "d9b723d",
		"email": "wablackburn303@gmail.com",
		"name": "Will ",
		"createdDate": "2023-08-31 20:09:54",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 79,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 132,
		"schedulePercent": 0.108992,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-09-09 00:00:00",
		"mostGuestName": "Music Hall of Williamsburg",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 1
	},
	{
		"id": "d097dfe",
		"email": "roland@schilcher-showtechnik.at",
		"name": "Roland",
		"createdDate": "2022-05-04 16:17:27",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 533,
		"totalCountries": 13,
		"totalStates": 55,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 2601,
		"schedulePercent": 0.80784,
		"totalGuests": 135,
		"guestPercent": 0.50245,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 112
	},
	{
		"id": "2e20f84",
		"email": "Nick@npbcompanies.com",
		"name": "Nicholas",
		"createdDate": "2021-05-12 23:08:25",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 135,
		"totalCountries": 6,
		"totalStates": 30,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 1780,
		"schedulePercent": 0.680969,
		"totalGuests": 4325,
		"guestPercent": 0.985962,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Fenway Park",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 322
	},
	{
		"id": "522c356",
		"email": "sofie@tajmer.dk",
		"name": "Sofie",
		"createdDate": "2021-06-16 08:02:43",
		"totalOrganizations": 10,
		"totalTours": 5,
		"totalDays": 143,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 831,
		"schedulePercent": 0.431334,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b4a204e",
		"email": "donna.mischelle@yahoo.com",
		"name": "Donna",
		"createdDate": "2019-08-06 22:26:18",
		"totalOrganizations": 2,
		"totalTours": 19,
		"totalDays": 558,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 1,
		"eventPercent": 0.00662164,
		"totalScheduleItems": 549,
		"schedulePercent": 0.320355,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4681b8b",
		"email": "jbernouis@me.com",
		"name": "Julie",
		"createdDate": "2014-02-04 18:30:54",
		"totalOrganizations": 24,
		"totalTours": 9,
		"totalDays": 488,
		"totalCountries": 16,
		"totalStates": 53,
		"totalEvents": 134,
		"eventPercent": 0.658721,
		"totalScheduleItems": 2291,
		"schedulePercent": 0.764005,
		"totalGuests": 1485,
		"guestPercent": 0.892332,
		"mostGuestDate": "2023-05-19 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 194
	},
	{
		"id": "7da238c",
		"email": "anthony@redlightmanagement.com",
		"name": "Anthony",
		"createdDate": "2010-06-22 21:42:55",
		"totalOrganizations": 8,
		"totalTours": 7,
		"totalDays": 180,
		"totalCountries": 7,
		"totalStates": 25,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 749,
		"schedulePercent": 0.401404,
		"totalGuests": 204,
		"guestPercent": 0.553834,
		"mostGuestDate": "2023-09-11 00:00:00",
		"mostGuestName": "The National Jazz Museum in Harlem",
		"mostGuestCity": "New York",
		"mostGuestAmount": 75
	},
	{
		"id": "26b8c55",
		"email": "sarah@rainmakerartists.com",
		"name": "Sarah",
		"createdDate": "2017-09-29 22:18:20",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 545,
		"totalCountries": 7,
		"totalStates": 53,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 1769,
		"schedulePercent": 0.678453,
		"totalGuests": 64,
		"guestPercent": 0.439015,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "The Danforth Music Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 8
	},
	{
		"id": "246ede3",
		"email": "AaronM5M@gmail.com",
		"name": "Aaron",
		"createdDate": "2022-03-22 23:45:04",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 444,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 390,
		"eventPercent": 0.954576,
		"totalScheduleItems": 3598,
		"schedulePercent": 0.900278,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7d8adee",
		"email": "hashley300@gmail.com",
		"name": "Harper",
		"createdDate": "2023-08-23 15:29:05",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 78,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 310,
		"schedulePercent": 0.213349,
		"totalGuests": 96,
		"guestPercent": 0.471063,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "El Club",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 30
	},
	{
		"id": "0cc55ac",
		"email": "davidbaqi@gmail.com",
		"name": "David",
		"createdDate": "2023-04-24 20:14:32",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 163,
		"totalCountries": 4,
		"totalStates": 40,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 1023,
		"schedulePercent": 0.491723,
		"totalGuests": 52,
		"guestPercent": 0.425374,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Bottom Lounge",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 26
	},
	{
		"id": "23b292a",
		"email": "demetriusm@me.com",
		"name": "Demetrius ",
		"createdDate": "2013-03-01 23:10:12",
		"totalOrganizations": 14,
		"totalTours": 18,
		"totalDays": 594,
		"totalCountries": 14,
		"totalStates": 61,
		"totalEvents": 270,
		"eventPercent": 0.894451,
		"totalScheduleItems": 1699,
		"schedulePercent": 0.664813,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-11-26 00:00:00",
		"mostGuestName": "Vogue Theatre",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 8
	},
	{
		"id": "77ec648",
		"email": "chasemcdanielmusic@gmail.com",
		"name": "Chase",
		"createdDate": "2022-10-14 00:54:04",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 360,
		"totalCountries": 1,
		"totalStates": 37,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 657,
		"schedulePercent": 0.364058,
		"totalGuests": 11,
		"guestPercent": 0.345782,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "Big Top Brewing Company",
		"mostGuestCity": "Sarasota",
		"mostGuestAmount": 4
	},
	{
		"id": "0a693d4",
		"email": "elizabeth.j.atwood@gmail.com",
		"name": "Elizabeth",
		"createdDate": "2023-05-07 04:11:53",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 165,
		"totalCountries": 3,
		"totalStates": 18,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 874,
		"schedulePercent": 0.444974,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ca521b3",
		"email": "spilling@acomicsoul.com",
		"name": "Spilling",
		"createdDate": "2022-04-25 16:02:36",
		"totalOrganizations": 70,
		"totalTours": 66,
		"totalDays": 891,
		"totalCountries": 27,
		"totalStates": 57,
		"totalEvents": 365,
		"eventPercent": 0.948086,
		"totalScheduleItems": 2465,
		"schedulePercent": 0.788505,
		"totalGuests": 333,
		"guestPercent": 0.619653,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 57
	},
	{
		"id": "b5bf776",
		"email": "irelamaizm@gmail.com",
		"name": "Irela",
		"createdDate": "2014-10-21 22:00:39",
		"totalOrganizations": 7,
		"totalTours": 4,
		"totalDays": 366,
		"totalCountries": 9,
		"totalStates": 62,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 2002,
		"schedulePercent": 0.719375,
		"totalGuests": 1942,
		"guestPercent": 0.931532,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Glen Helen Amphitheater",
		"mostGuestCity": "San Bernardino",
		"mostGuestAmount": 201
	},
	{
		"id": "1845eca",
		"email": "evelien@riverjuke.com",
		"name": "Evelien",
		"createdDate": "2022-11-23 15:28:43",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 322,
		"totalCountries": 5,
		"totalStates": 11,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 448,
		"schedulePercent": 0.275593,
		"totalGuests": 217,
		"guestPercent": 0.560853,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Emirates Old Trafford (Arctic Monkeys)",
		"mostGuestCity": "Manchester",
		"mostGuestAmount": 20
	},
	{
		"id": "ae28a1f",
		"email": "umjabug@gmail.com",
		"name": "Jeff",
		"createdDate": "2018-05-18 17:36:53",
		"totalOrganizations": 10,
		"totalTours": 53,
		"totalDays": 977,
		"totalCountries": 16,
		"totalStates": 63,
		"totalEvents": 277,
		"eventPercent": 0.899616,
		"totalScheduleItems": 2527,
		"schedulePercent": 0.796186,
		"totalGuests": 458,
		"guestPercent": 0.677129,
		"mostGuestDate": "2023-06-21 00:00:00",
		"mostGuestName": "Sony Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 40
	},
	{
		"id": "865361e",
		"email": "production@philstrongenterprises.com",
		"name": "Phil",
		"createdDate": "2010-07-22 17:14:43",
		"totalOrganizations": 21,
		"totalTours": 12,
		"totalDays": 715,
		"totalCountries": 7,
		"totalStates": 45,
		"totalEvents": 347,
		"eventPercent": 0.940273,
		"totalScheduleItems": 4243,
		"schedulePercent": 0.935505,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "61c1d33",
		"email": "j.s.wyman@gmail.com",
		"name": "Jason",
		"createdDate": "2019-12-01 20:01:30",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 159,
		"totalCountries": 10,
		"totalStates": 36,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 916,
		"schedulePercent": 0.456099,
		"totalGuests": 387,
		"guestPercent": 0.647596,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 57
	},
	{
		"id": "81e5b1d",
		"email": "jaredrossh@gmail.com",
		"name": "Jared",
		"createdDate": "2013-01-07 21:59:02",
		"totalOrganizations": 20,
		"totalTours": 29,
		"totalDays": 958,
		"totalCountries": 6,
		"totalStates": 41,
		"totalEvents": 281,
		"eventPercent": 0.903059,
		"totalScheduleItems": 3455,
		"schedulePercent": 0.890213,
		"totalGuests": 2574,
		"guestPercent": 0.955767,
		"mostGuestDate": "2023-04-27 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 66
	},
	{
		"id": "f2d9d19",
		"email": "aaron@princeivan.com",
		"name": "Aaron",
		"createdDate": "2021-10-05 22:46:03",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 295,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 495,
		"schedulePercent": 0.295722,
		"totalGuests": 274,
		"guestPercent": 0.590783,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Saban Theatre",
		"mostGuestCity": "Beverly Hills",
		"mostGuestAmount": 118
	},
	{
		"id": "11e8eab",
		"email": "molotov.oficial666@gmail.com",
		"name": "Molotov",
		"createdDate": "2022-01-13 20:26:34",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 1081,
		"totalCountries": 4,
		"totalStates": 35,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 696,
		"schedulePercent": 0.379023,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2936649",
		"email": "maiju.lahteenmaki@warnermusic.com",
		"name": "Maiju",
		"createdDate": "2018-05-25 07:12:17",
		"totalOrganizations": 16,
		"totalTours": 20,
		"totalDays": 385,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 307,
		"eventPercent": 0.919216,
		"totalScheduleItems": 1494,
		"schedulePercent": 0.61674,
		"totalGuests": 583,
		"guestPercent": 0.719905,
		"mostGuestDate": "2023-10-08 00:00:00",
		"mostGuestName": "Tavastia",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 58
	},
	{
		"id": "2d9c52d",
		"email": "chris@thrillhillmusic.com",
		"name": "Chris",
		"createdDate": "2013-03-11 08:49:51",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 275,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 808,
		"schedulePercent": 0.422593,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9e221cd",
		"email": "Info@dali-productions.se",
		"name": "David",
		"createdDate": "2015-02-07 04:44:54",
		"totalOrganizations": 13,
		"totalTours": 19,
		"totalDays": 269,
		"totalCountries": 7,
		"totalStates": 33,
		"totalEvents": 183,
		"eventPercent": 0.775659,
		"totalScheduleItems": 1860,
		"schedulePercent": 0.697391,
		"totalGuests": 1189,
		"guestPercent": 0.855781,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 90
	},
	{
		"id": "b77e78d",
		"email": "logistics@yosonic.com",
		"name": "Suzie",
		"createdDate": "2023-01-18 15:33:08",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 169,
		"totalCountries": 5,
		"totalStates": 8,
		"totalEvents": 6,
		"eventPercent": 0.0397298,
		"totalScheduleItems": 1049,
		"schedulePercent": 0.49702,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7866888",
		"email": "harrison@whyandhow.com",
		"name": "Harrison",
		"createdDate": "2021-03-18 16:41:44",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 657,
		"totalCountries": 5,
		"totalStates": 53,
		"totalEvents": 222,
		"eventPercent": 0.839227,
		"totalScheduleItems": 2285,
		"schedulePercent": 0.763078,
		"totalGuests": 58,
		"guestPercent": 0.431863,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "Billy Bob's",
		"mostGuestCity": "Ft. Worth",
		"mostGuestAmount": 18
	},
	{
		"id": "c16b8c8",
		"email": "Kasmikha.george@gmail.com",
		"name": "George",
		"createdDate": "2017-09-13 00:57:57",
		"totalOrganizations": 9,
		"totalTours": 16,
		"totalDays": 352,
		"totalCountries": 4,
		"totalStates": 47,
		"totalEvents": 185,
		"eventPercent": 0.780029,
		"totalScheduleItems": 2324,
		"schedulePercent": 0.769567,
		"totalGuests": 1293,
		"guestPercent": 0.87101,
		"mostGuestDate": "2023-11-30 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 63
	},
	{
		"id": "6b9fcf2",
		"email": "morgan@sassandsasssourcing.co.uk",
		"name": "Morgan",
		"createdDate": "2022-06-14 07:55:11",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 26,
		"totalCountries": 4,
		"totalStates": 6,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 176,
		"schedulePercent": 0.136008,
		"totalGuests": 30,
		"guestPercent": 0.389485,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "John Dee Live Club & Pub",
		"mostGuestCity": "Oslo",
		"mostGuestAmount": 15
	},
	{
		"id": "76693d8",
		"email": "elliot@re.tours",
		"name": "Elliot",
		"createdDate": "2023-11-12 08:13:28",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 29,
		"totalCountries": 5,
		"totalStates": 10,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 219,
		"schedulePercent": 0.16276,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-11-20 00:00:00",
		"mostGuestName": "Auditorio La Isla MÃ©rida",
		"mostGuestCity": "MÃ©rida",
		"mostGuestAmount": 10
	},
	{
		"id": "446c461",
		"email": "admin@lemontreemusic.com.au",
		"name": "LTM",
		"createdDate": "2018-11-27 04:57:52",
		"totalOrganizations": 16,
		"totalTours": 29,
		"totalDays": 1813,
		"totalCountries": 24,
		"totalStates": 81,
		"totalEvents": 280,
		"eventPercent": 0.901867,
		"totalScheduleItems": 4113,
		"schedulePercent": 0.928619,
		"totalGuests": 1700,
		"guestPercent": 0.913257,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 243
	},
	{
		"id": "332a5f6",
		"email": "allie@whyandhow.com",
		"name": "Alexandra",
		"createdDate": "2022-07-11 16:50:49",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 471,
		"totalCountries": 6,
		"totalStates": 35,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 1941,
		"schedulePercent": 0.70984,
		"totalGuests": 325,
		"guestPercent": 0.61674,
		"mostGuestDate": "2023-05-18 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 60
	},
	{
		"id": "eb8728d",
		"email": "RGMusic7780@gmail.com",
		"name": "Ryder",
		"createdDate": "2023-04-19 17:21:01",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 86,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 11,
		"schedulePercent": 0.0237055,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b1ad999",
		"email": "trusreymusic@gmail.com",
		"name": "Stephen",
		"createdDate": "2022-07-20 20:51:35",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 387,
		"totalCountries": 2,
		"totalStates": 43,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 1564,
		"schedulePercent": 0.633558,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "The Machine Shop",
		"mostGuestCity": "Flint",
		"mostGuestAmount": 2
	},
	{
		"id": "ce3ea95",
		"email": "topsactours@gmail.com",
		"name": "Danny",
		"createdDate": "2023-05-31 09:57:08",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 23,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 152,
		"schedulePercent": 0.121971,
		"totalGuests": 105,
		"guestPercent": 0.480069,
		"mostGuestDate": "2023-07-05 00:00:00",
		"mostGuestName": "Underworld",
		"mostGuestCity": "London",
		"mostGuestAmount": 33
	},
	{
		"id": "190c402",
		"email": "leila@supersonicevents.com.au",
		"name": "Leila",
		"createdDate": "2020-01-14 08:10:15",
		"totalOrganizations": 6,
		"totalTours": 32,
		"totalDays": 771,
		"totalCountries": 12,
		"totalStates": 40,
		"totalEvents": 195,
		"eventPercent": 0.796318,
		"totalScheduleItems": 2249,
		"schedulePercent": 0.758178,
		"totalGuests": 754,
		"guestPercent": 0.765859,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "f8b1f8e",
		"email": "ajadams819@gmail.com",
		"name": "Anthony",
		"createdDate": "2013-10-07 16:30:06",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 179,
		"totalCountries": 1,
		"totalStates": 38,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 1913,
		"schedulePercent": 0.706132,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "The Music Center @ Strathmore",
		"mostGuestCity": "North Bethesda",
		"mostGuestAmount": 9
	},
	{
		"id": "fa9d093",
		"email": "kisstm@live.com",
		"name": "Brian",
		"createdDate": "2012-01-20 02:58:35",
		"totalOrganizations": 10,
		"totalTours": 7,
		"totalDays": 170,
		"totalCountries": 2,
		"totalStates": 43,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 1449,
		"schedulePercent": 0.603364,
		"totalGuests": 1172,
		"guestPercent": 0.852867,
		"mostGuestDate": "2023-02-19 00:00:00",
		"mostGuestName": "City National Grove of Anaheim",
		"mostGuestCity": "Anaheim",
		"mostGuestAmount": 57
	},
	{
		"id": "ecafb5e",
		"email": "madners@gmail.com",
		"name": "Sinead",
		"createdDate": "2018-09-28 06:23:02",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 133,
		"totalCountries": 11,
		"totalStates": 23,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 776,
		"schedulePercent": 0.411601,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f25cf1f",
		"email": "BEN@jokoy.com",
		"name": "BEN",
		"createdDate": "2022-10-25 21:47:19",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 255,
		"totalCountries": 7,
		"totalStates": 36,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1054,
		"schedulePercent": 0.498609,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Toyota Center",
		"mostGuestCity": "Houston",
		"mostGuestAmount": 2
	},
	{
		"id": "31871de",
		"email": "steven.mclean0@gmail.com",
		"name": "steven",
		"createdDate": "2014-03-01 02:10:27",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 638,
		"totalCountries": 17,
		"totalStates": 41,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 1758,
		"schedulePercent": 0.675805,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "08dcbe0",
		"email": "barbara@outshinetalent.com",
		"name": "Barbara",
		"createdDate": "2023-04-30 19:21:30",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 90,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 335,
		"schedulePercent": 0.225798,
		"totalGuests": 77,
		"guestPercent": 0.452523,
		"mostGuestDate": "2023-07-19 00:00:00",
		"mostGuestName": "Visulite Theatre",
		"mostGuestCity": "Charlotte",
		"mostGuestAmount": 15
	},
	{
		"id": "9395585",
		"email": "nicolenoelmorgan@stmusicgroup.com",
		"name": "Maria Alejandra",
		"createdDate": "2021-06-28 20:28:23",
		"totalOrganizations": 21,
		"totalTours": 9,
		"totalDays": 287,
		"totalCountries": 6,
		"totalStates": 52,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 2179,
		"schedulePercent": 0.747451,
		"totalGuests": 632,
		"guestPercent": 0.734737,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Cal Coast Credit Union Amphitheater",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 54
	},
	{
		"id": "de6248f",
		"email": "booking@willbanister.com",
		"name": "Will",
		"createdDate": "2023-05-22 13:39:40",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 37,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 123,
		"schedulePercent": 0.105019,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ceff3fe",
		"email": "rafoster96@icloud.com",
		"name": "Drew",
		"createdDate": "2014-08-04 17:55:37",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 311,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 2019,
		"schedulePercent": 0.723215,
		"totalGuests": 3085,
		"guestPercent": 0.9706,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 298
	},
	{
		"id": "e8e91cd",
		"email": "daniel@blindowlsd.com",
		"name": "Daniel",
		"createdDate": "2023-07-16 18:10:48",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 65,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 57,
		"schedulePercent": 0.0599921,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5eed816",
		"email": "babecornerband@gmail.com",
		"name": "Lindsay",
		"createdDate": "2023-06-09 02:55:07",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 75,
		"totalCountries": 3,
		"totalStates": 23,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 270,
		"schedulePercent": 0.19216,
		"totalGuests": 198,
		"guestPercent": 0.549331,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "Vic Theatre",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 48
	},
	{
		"id": "310c7b5",
		"email": "elimurph@usc.edu",
		"name": "Eli",
		"createdDate": "2021-11-16 03:35:51",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 177,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 2,
		"eventPercent": 0.0140379,
		"totalScheduleItems": 137,
		"schedulePercent": 0.111244,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-05-31 00:00:00",
		"mostGuestName": "Marquee Theatre",
		"mostGuestCity": "Tempe",
		"mostGuestAmount": 12
	},
	{
		"id": "89e0608",
		"email": "BeachBoyTim@gmail.com",
		"name": "Tim",
		"createdDate": "2012-01-29 19:54:15",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 191,
		"totalCountries": 3,
		"totalStates": 37,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 1497,
		"schedulePercent": 0.617402,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-05-17 00:00:00",
		"mostGuestName": "Montgomery Performing Arts Centre",
		"mostGuestCity": "Montgomery",
		"mostGuestAmount": 1
	},
	{
		"id": "d0b9b66",
		"email": "get@carlcapers.com",
		"name": "Carl",
		"createdDate": "2013-11-08 07:54:00",
		"totalOrganizations": 11,
		"totalTours": 8,
		"totalDays": 643,
		"totalCountries": 13,
		"totalStates": 41,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 472,
		"schedulePercent": 0.285658,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-09-21 00:00:00",
		"mostGuestName": "UPMC Events Center",
		"mostGuestCity": "Moon Twp",
		"mostGuestAmount": 1
	},
	{
		"id": "a17461d",
		"email": "belmontin@aol.com",
		"name": "Cathy ",
		"createdDate": "2013-10-09 01:25:54",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 154,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 1353,
		"schedulePercent": 0.581248,
		"totalGuests": 684,
		"guestPercent": 0.749172,
		"mostGuestDate": "2023-03-22 00:00:00",
		"mostGuestName": "Dolby Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 76
	},
	{
		"id": "1112be4",
		"email": "alison@pinchgutopera.com.au",
		"name": "Alison",
		"createdDate": "2022-09-03 04:21:50",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 108,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 205,
		"schedulePercent": 0.153357,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "99a520f",
		"email": "kelsey@blueskyartistmgmt.com",
		"name": "Kelsey",
		"createdDate": "2022-05-11 21:21:36",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 458,
		"totalCountries": 2,
		"totalStates": 36,
		"totalEvents": 255,
		"eventPercent": 0.880943,
		"totalScheduleItems": 1559,
		"schedulePercent": 0.631837,
		"totalGuests": 144,
		"guestPercent": 0.509337,
		"mostGuestDate": "2023-06-11 00:00:00",
		"mostGuestName": "The Riviera",
		"mostGuestCity": "Charleston",
		"mostGuestAmount": 12
	},
	{
		"id": "7a72d35",
		"email": "mrbradkline@gmail.com",
		"name": "Bradley",
		"createdDate": "2014-08-02 03:24:38",
		"totalOrganizations": 10,
		"totalTours": 7,
		"totalDays": 722,
		"totalCountries": 14,
		"totalStates": 42,
		"totalEvents": 191,
		"eventPercent": 0.790491,
		"totalScheduleItems": 2224,
		"schedulePercent": 0.754337,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e1039e3",
		"email": "morgan.dibona@gmail.com",
		"name": "Morgan",
		"createdDate": "2022-07-06 16:04:50",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 260,
		"totalCountries": 12,
		"totalStates": 31,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 2087,
		"schedulePercent": 0.732883,
		"totalGuests": 472,
		"guestPercent": 0.681764,
		"mostGuestDate": "2023-09-05 00:00:00",
		"mostGuestName": "Singapore Indoor Stadium",
		"mostGuestCity": "Singapore",
		"mostGuestAmount": 78
	},
	{
		"id": "e882f1b",
		"email": "daniel@sherpatouring.com",
		"name": "Daniel",
		"createdDate": "2018-11-05 15:39:54",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 337,
		"totalCountries": 13,
		"totalStates": 24,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 699,
		"schedulePercent": 0.380215,
		"totalGuests": 51,
		"guestPercent": 0.423785,
		"mostGuestDate": "2023-04-11 00:00:00",
		"mostGuestName": "Rogers Arena",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 10
	},
	{
		"id": "5e47dc5",
		"email": "toreseww@gmail.com",
		"name": "Tora Terese",
		"createdDate": "2021-08-19 06:26:10",
		"totalOrganizations": 9,
		"totalTours": 5,
		"totalDays": 94,
		"totalCountries": 3,
		"totalStates": 12,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 545,
		"schedulePercent": 0.319295,
		"totalGuests": 42,
		"guestPercent": 0.40882,
		"mostGuestDate": "2023-09-29 00:00:00",
		"mostGuestName": "Spira Culture Center",
		"mostGuestCity": "Jonkoping",
		"mostGuestAmount": 16
	},
	{
		"id": "e50b057",
		"email": "savannahgconley@gmail.com",
		"name": "Savannah",
		"createdDate": "2018-02-13 21:08:28",
		"totalOrganizations": 6,
		"totalTours": 12,
		"totalDays": 119,
		"totalCountries": 5,
		"totalStates": 30,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 501,
		"schedulePercent": 0.298768,
		"totalGuests": 288,
		"guestPercent": 0.597404,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 54
	},
	{
		"id": "c964764",
		"email": "tasha.serna@gmail.com",
		"name": "Tasha",
		"createdDate": "2019-04-20 06:50:01",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 609,
		"totalCountries": 12,
		"totalStates": 33,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 501,
		"schedulePercent": 0.298768,
		"totalGuests": 134,
		"guestPercent": 0.501258,
		"mostGuestDate": "2023-06-06 00:00:00",
		"mostGuestName": "Paradise Rock Club",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 33
	},
	{
		"id": "9c6c609",
		"email": "david@volumemusic.se",
		"name": "David ",
		"createdDate": "2023-03-06 14:46:46",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 70,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 441,
		"schedulePercent": 0.272944,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Lollapalooza",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 12
	},
	{
		"id": "36bb05b",
		"email": "lindsayell@me.com",
		"name": "Lindsay",
		"createdDate": "2016-02-05 23:09:34",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 3,
		"totalStates": 26,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 832,
		"schedulePercent": 0.431731,
		"totalGuests": 225,
		"guestPercent": 0.566018,
		"mostGuestDate": "2023-08-10 00:00:00",
		"mostGuestName": "Skydeck on Broadway",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 37
	},
	{
		"id": "e6abd03",
		"email": "dean@terracotta.cc",
		"name": "Dean",
		"createdDate": "2021-06-28 16:18:31",
		"totalOrganizations": 10,
		"totalTours": 20,
		"totalDays": 178,
		"totalCountries": 5,
		"totalStates": 34,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 894,
		"schedulePercent": 0.451331,
		"totalGuests": 781,
		"guestPercent": 0.771951,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "Kings Theatre",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 59
	},
	{
		"id": "563ce6d",
		"email": "scender@ang3rmgmt.com",
		"name": "Freddie",
		"createdDate": "2012-06-01 20:34:10",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 321,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 1338,
		"schedulePercent": 0.57701,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-11-15 00:00:00",
		"mostGuestName": "Seminole Hard Rock Hotel & Casino Tampa",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 2
	},
	{
		"id": "a4e866c",
		"email": "jfbellino@gmail.com",
		"name": "Jean-Francois",
		"createdDate": "2019-08-30 16:14:30",
		"totalOrganizations": 3,
		"totalTours": 22,
		"totalDays": 176,
		"totalCountries": 7,
		"totalStates": 35,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 2273,
		"schedulePercent": 0.762548,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c0b5690",
		"email": "aj@northback.co.uk",
		"name": "AJ",
		"createdDate": "2018-05-16 14:02:21",
		"totalOrganizations": 19,
		"totalTours": 51,
		"totalDays": 694,
		"totalCountries": 22,
		"totalStates": 72,
		"totalEvents": 324,
		"eventPercent": 0.927692,
		"totalScheduleItems": 5045,
		"schedulePercent": 0.963051,
		"totalGuests": 1694,
		"guestPercent": 0.912462,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 178
	},
	{
		"id": "611bab9",
		"email": "suecollier@mac.com",
		"name": "Sue",
		"createdDate": "2023-03-28 12:48:21",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 453,
		"totalCountries": 10,
		"totalStates": 15,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 575,
		"schedulePercent": 0.33042,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "108bac1",
		"email": "e.brunngraeber@me.com",
		"name": "Eileen ",
		"createdDate": "2014-09-08 10:44:04",
		"totalOrganizations": 19,
		"totalTours": 6,
		"totalDays": 274,
		"totalCountries": 5,
		"totalStates": 16,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 1825,
		"schedulePercent": 0.69024,
		"totalGuests": 1667,
		"guestPercent": 0.909284,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "Mercedes-Benz Arena Berlin",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 154
	},
	{
		"id": "3fdede7",
		"email": "agustin@rosalia.com",
		"name": "Agustin ",
		"createdDate": "2019-01-17 09:26:41",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 218,
		"totalCountries": 6,
		"totalStates": 12,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 1596,
		"schedulePercent": 0.641769,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f69352d",
		"email": "rorydoherty30@hotmail.com",
		"name": "Rory",
		"createdDate": "2019-05-05 20:43:57",
		"totalOrganizations": 10,
		"totalTours": 17,
		"totalDays": 304,
		"totalCountries": 11,
		"totalStates": 47,
		"totalEvents": 148,
		"eventPercent": 0.696332,
		"totalScheduleItems": 1392,
		"schedulePercent": 0.589458,
		"totalGuests": 265,
		"guestPercent": 0.586942,
		"mostGuestDate": "2023-03-21 00:00:00",
		"mostGuestName": "The SSE Arena, Wembley",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 17
	},
	{
		"id": "b58644f",
		"email": "elliott.andrews@me.com",
		"name": "Elliott",
		"createdDate": "2017-05-22 12:20:23",
		"totalOrganizations": 13,
		"totalTours": 18,
		"totalDays": 985,
		"totalCountries": 15,
		"totalStates": 49,
		"totalEvents": 196,
		"eventPercent": 0.798835,
		"totalScheduleItems": 1663,
		"schedulePercent": 0.656602,
		"totalGuests": 301,
		"guestPercent": 0.603099,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "La Cigale",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 46
	},
	{
		"id": "fcfad5a",
		"email": "coliesawinski@gmail.com",
		"name": "Colie",
		"createdDate": "2013-07-30 15:45:36",
		"totalOrganizations": 12,
		"totalTours": 5,
		"totalDays": 445,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 1041,
		"schedulePercent": 0.495034,
		"totalGuests": 37,
		"guestPercent": 0.400212,
		"mostGuestDate": "2023-08-15 00:00:00",
		"mostGuestName": "Rockingham County Fair",
		"mostGuestCity": "Harrisonburg",
		"mostGuestAmount": 9
	},
	{
		"id": "b6f0cea",
		"email": "wiwi.weber@gmail.com",
		"name": "William",
		"createdDate": "2018-10-03 22:46:17",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 586,
		"totalCountries": 4,
		"totalStates": 36,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 2250,
		"schedulePercent": 0.75831,
		"totalGuests": 1589,
		"guestPercent": 0.902265,
		"mostGuestDate": "2023-03-29 00:00:00",
		"mostGuestName": "Zenith Paris - La Villette",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 203
	},
	{
		"id": "9721e47",
		"email": "drmrsentertainment@gmail.com",
		"name": "Sergio",
		"createdDate": "2023-02-23 23:25:26",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 53,
		"totalCountries": 6,
		"totalStates": 22,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 280,
		"schedulePercent": 0.198252,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "Blondie",
		"mostGuestCity": "Santiago",
		"mostGuestAmount": 9
	},
	{
		"id": "5d093aa",
		"email": "george.gpmprod@gmail.com",
		"name": "MELEY",
		"createdDate": "2023-04-17 15:00:25",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 150,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 517,
		"schedulePercent": 0.306847,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d09aad7",
		"email": "lloyd@bigloud.com",
		"name": "Lloyd",
		"createdDate": "2018-11-26 18:22:28",
		"totalOrganizations": 10,
		"totalTours": 10,
		"totalDays": 594,
		"totalCountries": 3,
		"totalStates": 39,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 1196,
		"schedulePercent": 0.539134,
		"totalGuests": 442,
		"guestPercent": 0.669183,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Russel and Roots",
		"mostGuestCity": "Surrey",
		"mostGuestAmount": 48
	},
	{
		"id": "144c754",
		"email": "rob.thomson@lobbycall.com",
		"name": "Rob",
		"createdDate": "2022-06-27 11:22:57",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 6,
		"totalStates": 16,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 705,
		"schedulePercent": 0.383128,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4870713",
		"email": "Boostikaka@gmail.com",
		"name": "Chris",
		"createdDate": "2016-05-16 19:27:23",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 48,
		"totalCountries": 6,
		"totalStates": 19,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 272,
		"schedulePercent": 0.193749,
		"totalGuests": 111,
		"guestPercent": 0.483115,
		"mostGuestDate": "2023-05-08 00:00:00",
		"mostGuestName": "Union Transfer",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 27
	},
	{
		"id": "4ae1207",
		"email": "ruth@24-7productions.com",
		"name": "Ruth",
		"createdDate": "2022-04-25 09:58:05",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 30,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 179,
		"schedulePercent": 0.137598,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "27e1c97",
		"email": "Dustinj.jenkins@gmail.com",
		"name": "Dustin ",
		"createdDate": "2014-03-17 22:11:56",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 309,
		"totalCountries": 9,
		"totalStates": 45,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 2014,
		"schedulePercent": 0.722024,
		"totalGuests": 1044,
		"guestPercent": 0.832208,
		"mostGuestDate": "2023-05-19 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 59
	},
	{
		"id": "0d55352",
		"email": "quinn@eighteentwentysix.com",
		"name": "Quinn",
		"createdDate": "2022-04-29 22:58:32",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 1553,
		"totalCountries": 17,
		"totalStates": 60,
		"totalEvents": 273,
		"eventPercent": 0.896305,
		"totalScheduleItems": 3052,
		"schedulePercent": 0.856046,
		"totalGuests": 745,
		"guestPercent": 0.76321,
		"mostGuestDate": "2023-05-04 00:00:00",
		"mostGuestName": "Radio City Music Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 94
	},
	{
		"id": "f60a772",
		"email": "abigailpotter@me.com",
		"name": "Abigail",
		"createdDate": "2016-03-02 01:28:54",
		"totalOrganizations": 10,
		"totalTours": 5,
		"totalDays": 40,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 239,
		"schedulePercent": 0.175209,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "88b65c1",
		"email": "tony@take6.com",
		"name": "Antonio",
		"createdDate": "2014-03-19 14:23:36",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 104,
		"totalCountries": 1,
		"totalStates": 24,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 729,
		"schedulePercent": 0.391869,
		"totalGuests": 123,
		"guestPercent": 0.492782,
		"mostGuestDate": "2023-10-22 00:00:00",
		"mostGuestName": "The Carlyle Room",
		"mostGuestCity": "Washington DC",
		"mostGuestAmount": 19
	},
	{
		"id": "9253905",
		"email": "kirkwillie@gmail.com",
		"name": "Kirk",
		"createdDate": "2010-03-05 21:20:38",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 396,
		"totalCountries": 2,
		"totalStates": 33,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 840,
		"schedulePercent": 0.433982,
		"totalGuests": 874,
		"guestPercent": 0.792875,
		"mostGuestDate": "2023-12-01 00:00:00",
		"mostGuestName": "Von Braun Center (Probst Arena)",
		"mostGuestCity": "Huntsville",
		"mostGuestAmount": 97
	},
	{
		"id": "fb4fb57",
		"email": "pia@opoagency.com",
		"name": "Pia",
		"createdDate": "2022-11-23 02:15:05",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 24,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 7,
		"eventPercent": 0.0458217,
		"totalScheduleItems": 168,
		"schedulePercent": 0.131373,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "20da011",
		"email": "bex@beloproductions.co.nz",
		"name": "Bex",
		"createdDate": "2022-10-21 00:44:18",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 318,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 203,
		"schedulePercent": 0.152165,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0b81214",
		"email": "acrowell1080@yahoo.com",
		"name": "Mandy",
		"createdDate": "2013-07-13 00:37:59",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 230,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 261,
		"schedulePercent": 0.185936,
		"totalGuests": 63,
		"guestPercent": 0.437558,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 49
	},
	{
		"id": "8b753e4",
		"email": "petehanlon3@mac.com",
		"name": "Pete",
		"createdDate": "2011-09-12 21:41:50",
		"totalOrganizations": 8,
		"totalTours": 2,
		"totalDays": 104,
		"totalCountries": 5,
		"totalStates": 8,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 492,
		"schedulePercent": 0.293604,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "Royal Festival Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 4
	},
	{
		"id": "69ad348",
		"email": "marta@goeddesoundandlight.com",
		"name": "Marta",
		"createdDate": "2020-10-12 04:25:09",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 297,
		"totalCountries": 6,
		"totalStates": 41,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 2820,
		"schedulePercent": 0.834724,
		"totalGuests": 265,
		"guestPercent": 0.586942,
		"mostGuestDate": "2023-04-19 00:00:00",
		"mostGuestName": "The Mill & Mine",
		"mostGuestCity": "Knoxville",
		"mostGuestAmount": 49
	},
	{
		"id": "ae5cdd4",
		"email": "chanceyw@gmail.com",
		"name": "Chancey",
		"createdDate": "2019-05-15 17:49:47",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 206,
		"totalCountries": 1,
		"totalStates": 29,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 869,
		"schedulePercent": 0.44259,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b9c1dd2",
		"email": "cory.baker@harlemglobetrotters.com",
		"name": "Cory",
		"createdDate": "2022-12-07 15:51:13",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 67,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 570,
		"schedulePercent": 0.327771,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "929567f",
		"email": "gift@bnatural.nyc",
		"name": "Gift",
		"createdDate": "2022-07-26 19:46:45",
		"totalOrganizations": 1,
		"totalTours": 8,
		"totalDays": 173,
		"totalCountries": 12,
		"totalStates": 33,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 799,
		"schedulePercent": 0.420209,
		"totalGuests": 10,
		"guestPercent": 0.343531,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "Younes and Soraya Nazarian Center for the Performing Arts",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 6
	},
	{
		"id": "dc1c0df",
		"email": "charlie.leonard@live.co.uk",
		"name": "Charlie",
		"createdDate": "2019-05-09 10:51:48",
		"totalOrganizations": 17,
		"totalTours": 9,
		"totalDays": 1037,
		"totalCountries": 9,
		"totalStates": 18,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 783,
		"schedulePercent": 0.41372,
		"totalGuests": 481,
		"guestPercent": 0.683751,
		"mostGuestDate": "2023-03-07 00:00:00",
		"mostGuestName": "NX Newcastle",
		"mostGuestCity": "Newcastle upon Tyne",
		"mostGuestAmount": 166
	},
	{
		"id": "e910be1",
		"email": "browntownnj@gmail.com",
		"name": "Stuart",
		"createdDate": "2012-03-09 14:59:31",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 559,
		"totalCountries": 4,
		"totalStates": 35,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 2849,
		"schedulePercent": 0.838167,
		"totalGuests": 1416,
		"guestPercent": 0.884519,
		"mostGuestDate": "2023-10-12 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 90
	},
	{
		"id": "ba608b2",
		"email": "hector.dewolff@gmail.com",
		"name": "Hector",
		"createdDate": "2022-09-08 16:14:54",
		"totalOrganizations": 1,
		"totalTours": 8,
		"totalDays": 78,
		"totalCountries": 7,
		"totalStates": 17,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 372,
		"schedulePercent": 0.244868,
		"totalGuests": 49,
		"guestPercent": 0.419944,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "PHIL Haarlem",
		"mostGuestCity": "Haarlem",
		"mostGuestAmount": 13
	},
	{
		"id": "be68ab0",
		"email": "bk@the-trenches.com",
		"name": "Brian",
		"createdDate": "2021-10-27 20:15:25",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 6,
		"eventPercent": 0.0397298,
		"totalScheduleItems": 53,
		"schedulePercent": 0.0561515,
		"totalGuests": 26,
		"guestPercent": 0.381274,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "Mercury Lounge",
		"mostGuestCity": "New York",
		"mostGuestAmount": 10
	},
	{
		"id": "919fc0b",
		"email": "kyle@gravity.productions",
		"name": "Kyle",
		"createdDate": "2023-05-24 20:54:20",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 28,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 435,
		"schedulePercent": 0.270295,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "76cacdc",
		"email": "ristinrock@gmail.com",
		"name": "Robert",
		"createdDate": "2014-03-27 05:33:01",
		"totalOrganizations": 12,
		"totalTours": 10,
		"totalDays": 303,
		"totalCountries": 7,
		"totalStates": 45,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1596,
		"schedulePercent": 0.641769,
		"totalGuests": 588,
		"guestPercent": 0.722024,
		"mostGuestDate": "2023-10-10 00:00:00",
		"mostGuestName": "Regent Theater DTLA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 71
	},
	{
		"id": "26aa546",
		"email": "john@bosinysurf.com",
		"name": "John",
		"createdDate": "2023-03-16 10:30:35",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 137,
		"totalCountries": 12,
		"totalStates": 25,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 716,
		"schedulePercent": 0.386836,
		"totalGuests": 50,
		"guestPercent": 0.421798,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 20
	},
	{
		"id": "e62a032",
		"email": "jeremyh@vstarentertainment.com",
		"name": "Jeremy",
		"createdDate": "2022-03-14 15:01:25",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 121,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 595,
		"schedulePercent": 0.337968,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "308bc9a",
		"email": "tanner@nuworld.industries",
		"name": "Tanner",
		"createdDate": "2023-04-13 16:09:16",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 44,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 197,
		"schedulePercent": 0.148325,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d0f4a23",
		"email": "soundguysimon@gmail.com",
		"name": "Simon",
		"createdDate": "2018-06-13 14:53:24",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 103,
		"totalCountries": 2,
		"totalStates": 17,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 501,
		"schedulePercent": 0.298768,
		"totalGuests": 201,
		"guestPercent": 0.55198,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "The Danforth Music Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 54
	},
	{
		"id": "70ec06e",
		"email": "kduncan14@uco.edu",
		"name": "Kameron",
		"createdDate": "2021-05-25 18:26:19",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 90,
		"totalCountries": 4,
		"totalStates": 31,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 679,
		"schedulePercent": 0.371871,
		"totalGuests": 1284,
		"guestPercent": 0.869421,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "The Tulsa Theater",
		"mostGuestCity": "Tulsa",
		"mostGuestAmount": 147
	},
	{
		"id": "c429391",
		"email": "hussstephen@gmail.com",
		"name": "Stephen",
		"createdDate": "2017-08-02 16:48:39",
		"totalOrganizations": 11,
		"totalTours": 8,
		"totalDays": 589,
		"totalCountries": 3,
		"totalStates": 34,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 1772,
		"schedulePercent": 0.678983,
		"totalGuests": 679,
		"guestPercent": 0.748113,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "Hard Rock Live",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 88
	},
	{
		"id": "c76254a",
		"email": "erikaparissi@hotmail.com",
		"name": "Erika",
		"createdDate": "2022-06-11 18:48:56",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 202,
		"totalCountries": 5,
		"totalStates": 10,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 350,
		"schedulePercent": 0.232949,
		"totalGuests": 346,
		"guestPercent": 0.626672,
		"mostGuestDate": "2023-09-09 00:00:00",
		"mostGuestName": "The Midway",
		"mostGuestCity": "San Francisco",
		"mostGuestAmount": 87
	},
	{
		"id": "3ded236",
		"email": "nataliedrillings@gmail.com",
		"name": "Natalie",
		"createdDate": "2019-08-20 14:20:53",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 68,
		"totalCountries": 11,
		"totalStates": 20,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 126,
		"schedulePercent": 0.106741,
		"totalGuests": 27,
		"guestPercent": 0.38379,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "Masonic Lodge at Hollywood Forever",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 23
	},
	{
		"id": "89f5349",
		"email": "zachpierce.pm@gmail.com",
		"name": "Zach",
		"createdDate": "2019-11-27 04:41:34",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 716,
		"totalCountries": 3,
		"totalStates": 26,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 2319,
		"schedulePercent": 0.768243,
		"totalGuests": 1058,
		"guestPercent": 0.834062,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Nissan Stadium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 90
	},
	{
		"id": "ca1c2da",
		"email": "jurgen@concert-touring.com",
		"name": "Jurgen",
		"createdDate": "2022-05-04 11:16:11",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 169,
		"totalCountries": 16,
		"totalStates": 35,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 1222,
		"schedulePercent": 0.54602,
		"totalGuests": 334,
		"guestPercent": 0.620183,
		"mostGuestDate": "2023-04-20 00:00:00",
		"mostGuestName": "Vooruit",
		"mostGuestCity": "Ghent",
		"mostGuestAmount": 28
	},
	{
		"id": "c80185f",
		"email": "zackirimcadam@gmail.com",
		"name": "Zackiri",
		"createdDate": "2021-11-23 12:53:58",
		"totalOrganizations": 5,
		"totalTours": 20,
		"totalDays": 386,
		"totalCountries": 9,
		"totalStates": 54,
		"totalEvents": 243,
		"eventPercent": 0.864786,
		"totalScheduleItems": 2868,
		"schedulePercent": 0.839094,
		"totalGuests": 2824,
		"guestPercent": 0.964905,
		"mostGuestDate": "2023-08-04 00:00:00",
		"mostGuestName": "Sunset Cove Amphitheater",
		"mostGuestCity": "Boca Raton",
		"mostGuestAmount": 132
	},
	{
		"id": "a353a90",
		"email": "denystommy@gmail.com",
		"name": "Tommy",
		"createdDate": "2017-07-12 17:43:23",
		"totalOrganizations": 17,
		"totalTours": 19,
		"totalDays": 428,
		"totalCountries": 13,
		"totalStates": 37,
		"totalEvents": 154,
		"eventPercent": 0.71368,
		"totalScheduleItems": 887,
		"schedulePercent": 0.448947,
		"totalGuests": 76,
		"guestPercent": 0.451596,
		"mostGuestDate": "2023-04-02 00:00:00",
		"mostGuestName": "Monk Club",
		"mostGuestCity": "Roma",
		"mostGuestAmount": 20
	},
	{
		"id": "bcd32f8",
		"email": "patrick@npwproductions.com.au",
		"name": "Patrick",
		"createdDate": "2016-08-01 02:15:44",
		"totalOrganizations": 20,
		"totalTours": 31,
		"totalDays": 1696,
		"totalCountries": 23,
		"totalStates": 62,
		"totalEvents": 206,
		"eventPercent": 0.816183,
		"totalScheduleItems": 2794,
		"schedulePercent": 0.831678,
		"totalGuests": 344,
		"guestPercent": 0.624685,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Manning Bar",
		"mostGuestCity": "Camperdown",
		"mostGuestAmount": 50
	},
	{
		"id": "5c6b822",
		"email": "hello@joesethcarter.com",
		"name": "Joe",
		"createdDate": "2018-10-24 16:49:21",
		"totalOrganizations": 11,
		"totalTours": 20,
		"totalDays": 400,
		"totalCountries": 22,
		"totalStates": 76,
		"totalEvents": 207,
		"eventPercent": 0.817905,
		"totalScheduleItems": 3052,
		"schedulePercent": 0.856046,
		"totalGuests": 1272,
		"guestPercent": 0.868229,
		"mostGuestDate": "2023-01-20 00:00:00",
		"mostGuestName": "Powerstation",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 45
	},
	{
		"id": "912b379",
		"email": "artistehubuk@gmail.com",
		"name": "Audrey",
		"createdDate": "2013-12-06 18:06:55",
		"totalOrganizations": 16,
		"totalTours": 1,
		"totalDays": 75,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 347,
		"schedulePercent": 0.231757,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "84151d2",
		"email": "Amber@3tent.com",
		"name": "Amber",
		"createdDate": "2019-03-15 01:22:27",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 182,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1674,
		"schedulePercent": 0.658721,
		"totalGuests": 904,
		"guestPercent": 0.800556,
		"mostGuestDate": "2023-08-27 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 151
	},
	{
		"id": "bd2eb7e",
		"email": "justinameri92@gmail.com",
		"name": "Justin",
		"createdDate": "2022-06-17 09:29:39",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 339,
		"totalCountries": 3,
		"totalStates": 48,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1051,
		"schedulePercent": 0.497682,
		"totalGuests": 545,
		"guestPercent": 0.707324,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 88
	},
	{
		"id": "bfd24fc",
		"email": "JeremyBarretoTM@Gmail.com",
		"name": "Jeremy",
		"createdDate": "2022-07-26 02:08:27",
		"totalOrganizations": 14,
		"totalTours": 2,
		"totalDays": 93,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 733,
		"schedulePercent": 0.393325,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "Doug Mitchell Thunderbird Sports Centre",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 2
	},
	{
		"id": "4fdd7c5",
		"email": "gulya@gorskaya-hartwick.com",
		"name": "Gulya",
		"createdDate": "2022-08-28 16:20:24",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 215,
		"totalCountries": 1,
		"totalStates": 40,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 2247,
		"schedulePercent": 0.757251,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e2bede4",
		"email": "marik.curet@gmail.com",
		"name": "Marik",
		"createdDate": "2018-04-17 17:20:58",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 323,
		"totalCountries": 7,
		"totalStates": 45,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 1499,
		"schedulePercent": 0.617931,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4f8940e",
		"email": "nick_gosling@me.com",
		"name": "Nicholas ",
		"createdDate": "2015-05-22 07:20:04",
		"totalOrganizations": 8,
		"totalTours": 7,
		"totalDays": 394,
		"totalCountries": 15,
		"totalStates": 51,
		"totalEvents": 190,
		"eventPercent": 0.788505,
		"totalScheduleItems": 2899,
		"schedulePercent": 0.841743,
		"totalGuests": 485,
		"guestPercent": 0.685207,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "Warwick Castle",
		"mostGuestCity": "Warwick",
		"mostGuestAmount": 23
	},
	{
		"id": "7b68ae8",
		"email": "joebull.arstudios@gmail.com",
		"name": "Joe",
		"createdDate": "2018-02-15 20:07:36",
		"totalOrganizations": 10,
		"totalTours": 12,
		"totalDays": 1188,
		"totalCountries": 22,
		"totalStates": 62,
		"totalEvents": 229,
		"eventPercent": 0.847305,
		"totalScheduleItems": 3302,
		"schedulePercent": 0.877765,
		"totalGuests": 676,
		"guestPercent": 0.746259,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "London Palladium",
		"mostGuestCity": "London",
		"mostGuestAmount": 104
	},
	{
		"id": "dbf6d28",
		"email": "laretta@eighteentwentysix.com",
		"name": "Laretta ",
		"createdDate": "2022-03-15 07:59:26",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 1231,
		"totalCountries": 15,
		"totalStates": 49,
		"totalEvents": 208,
		"eventPercent": 0.819891,
		"totalScheduleItems": 3384,
		"schedulePercent": 0.886373,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "UshuaÃ¯a Beach Club Ibiza",
		"mostGuestCity": "Sant Josep de sa Talaia",
		"mostGuestAmount": 3
	},
	{
		"id": "0a8800a",
		"email": "anxietymachine@gmail.com",
		"name": "Mark",
		"createdDate": "2016-09-01 19:11:17",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 97,
		"totalCountries": 2,
		"totalStates": 40,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 555,
		"schedulePercent": 0.323798,
		"totalGuests": 161,
		"guestPercent": 0.523374,
		"mostGuestDate": "2023-05-28 00:00:00",
		"mostGuestName": "Adjacent Festival - Beach Stage",
		"mostGuestCity": "Atlantic",
		"mostGuestAmount": 25
	},
	{
		"id": "792ecb9",
		"email": "cheryllhall@me.com",
		"name": "Cheryl",
		"createdDate": "2014-11-02 18:45:57",
		"totalOrganizations": 12,
		"totalTours": 11,
		"totalDays": 443,
		"totalCountries": 14,
		"totalStates": 65,
		"totalEvents": 133,
		"eventPercent": 0.656602,
		"totalScheduleItems": 1661,
		"schedulePercent": 0.656204,
		"totalGuests": 207,
		"guestPercent": 0.555953,
		"mostGuestDate": "2023-02-26 00:00:00",
		"mostGuestName": "VyStar Veterans Memorial Arena",
		"mostGuestCity": "Jacksonville",
		"mostGuestAmount": 43
	},
	{
		"id": "036e6c8",
		"email": "samu@greybeard.fi",
		"name": "Samu",
		"createdDate": "2015-03-09 08:58:45",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 121,
		"totalCountries": 7,
		"totalStates": 31,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 1077,
		"schedulePercent": 0.506423,
		"totalGuests": 305,
		"guestPercent": 0.606277,
		"mostGuestDate": "2023-03-22 00:00:00",
		"mostGuestName": "The Regent",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 27
	},
	{
		"id": "5fdc8e1",
		"email": "tourmngr@icloud.com",
		"name": "Paul",
		"createdDate": "2022-12-03 03:54:05",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 398,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 180,
		"eventPercent": 0.770229,
		"totalScheduleItems": 2643,
		"schedulePercent": 0.813932,
		"totalGuests": 1064,
		"guestPercent": 0.834194,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "iTHINK Financial Amphitheatre",
		"mostGuestCity": "West Palm Beach",
		"mostGuestAmount": 84
	},
	{
		"id": "6f2580b",
		"email": "petericci@ymail.com",
		"name": "Peter",
		"createdDate": "2013-07-01 03:59:04",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 730,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 170,
		"eventPercent": 0.752086,
		"totalScheduleItems": 1559,
		"schedulePercent": 0.631837,
		"totalGuests": 1077,
		"guestPercent": 0.835916,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "St. Josephâ€™s Health Amphitheater at Lakeview",
		"mostGuestCity": "Syracuse",
		"mostGuestAmount": 46
	},
	{
		"id": "b9f7d13",
		"email": "pierre.lecoguiec@me.com",
		"name": "Pierre",
		"createdDate": "2020-01-14 09:34:57",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 59,
		"totalCountries": 4,
		"totalStates": 12,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 516,
		"schedulePercent": 0.306185,
		"totalGuests": 331,
		"guestPercent": 0.619256,
		"mostGuestDate": "2023-06-13 00:00:00",
		"mostGuestName": "Les Francofolies De Montreal",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 43
	},
	{
		"id": "f6a1854",
		"email": "kieren.killerqueen@gmail.com",
		"name": "Kieren",
		"createdDate": "2019-08-11 19:18:55",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 100,
		"totalCountries": 1,
		"totalStates": 26,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 967,
		"schedulePercent": 0.470269,
		"totalGuests": 142,
		"guestPercent": 0.506688,
		"mostGuestDate": "2023-07-18 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 21
	},
	{
		"id": "527d7d2",
		"email": "alexboenaudio@gmail.com",
		"name": "Alex ",
		"createdDate": "2019-03-22 18:11:47",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 363,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 585,
		"schedulePercent": 0.334128,
		"totalGuests": 109,
		"guestPercent": 0.481393,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "CoolToday Park",
		"mostGuestCity": "Venice",
		"mostGuestAmount": 12
	},
	{
		"id": "382a0c6",
		"email": "dtownsend033@gmail.com",
		"name": "Don",
		"createdDate": "2019-06-05 14:06:56",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 266,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 527,
		"schedulePercent": 0.311349,
		"totalGuests": 370,
		"guestPercent": 0.64124,
		"mostGuestDate": "2023-08-14 00:00:00",
		"mostGuestName": "Grand Ole Opry",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 35
	},
	{
		"id": "52205e3",
		"email": "jamiedarc@gmail.com",
		"name": "Jamie",
		"createdDate": "2019-11-29 21:16:44",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 804,
		"totalCountries": 11,
		"totalStates": 39,
		"totalEvents": 174,
		"eventPercent": 0.759502,
		"totalScheduleItems": 1793,
		"schedulePercent": 0.683751,
		"totalGuests": 2036,
		"guestPercent": 0.935505,
		"mostGuestDate": "2023-09-03 00:00:00",
		"mostGuestName": "The Cosmopolitan of Las Vegas",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 138
	},
	{
		"id": "ff479fc",
		"email": "Macklam@mfmgt.com",
		"name": "Steve",
		"createdDate": "2010-03-08 16:09:12",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 242,
		"totalCountries": 11,
		"totalStates": 40,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 913,
		"schedulePercent": 0.455436,
		"totalGuests": 530,
		"guestPercent": 0.702556,
		"mostGuestDate": "2023-04-12 00:00:00",
		"mostGuestName": "XOYO",
		"mostGuestCity": "London",
		"mostGuestAmount": 45
	},
	{
		"id": "13e2cd2",
		"email": "Olausson.jimmy@gmail.com",
		"name": "Jimmy",
		"createdDate": "2016-11-25 12:36:25",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 376,
		"totalCountries": 18,
		"totalStates": 78,
		"totalEvents": 223,
		"eventPercent": 0.840154,
		"totalScheduleItems": 3056,
		"schedulePercent": 0.857105,
		"totalGuests": 835,
		"guestPercent": 0.784399,
		"mostGuestDate": "2023-04-08 00:00:00",
		"mostGuestName": "Annexet",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 78
	},
	{
		"id": "0c9098f",
		"email": "bighippocrew@gmail.com",
		"name": "Big",
		"createdDate": "2023-09-06 18:24:13",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 458,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 84,
		"schedulePercent": 0.0784002,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8457476",
		"email": "will@patchworklondon.co.uk",
		"name": "Will",
		"createdDate": "2022-01-31 11:15:36",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 397,
		"totalCountries": 16,
		"totalStates": 27,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1511,
		"schedulePercent": 0.62058,
		"totalGuests": 129,
		"guestPercent": 0.497815,
		"mostGuestDate": "2023-01-09 00:00:00",
		"mostGuestName": "Powerstation",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 37
	},
	{
		"id": "8a60913",
		"email": "jeremy@lonechiefrecords.com",
		"name": "Jeremy",
		"createdDate": "2018-12-08 22:45:25",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 145,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 659,
		"schedulePercent": 0.364985,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "51451c0",
		"email": "eacl781@aol.com",
		"name": "Aimee",
		"createdDate": "2013-11-22 22:17:25",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 144,
		"totalCountries": 3,
		"totalStates": 33,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 1555,
		"schedulePercent": 0.631042,
		"totalGuests": 1511,
		"guestPercent": 0.895643,
		"mostGuestDate": "2023-08-09 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 135
	},
	{
		"id": "2ffe331",
		"email": "naomi@soundsplash.co.nz",
		"name": "Naomi",
		"createdDate": "2022-10-20 01:14:46",
		"totalOrganizations": 2,
		"totalTours": 8,
		"totalDays": 126,
		"totalCountries": 4,
		"totalStates": 15,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 602,
		"schedulePercent": 0.342471,
		"totalGuests": 97,
		"guestPercent": 0.471461,
		"mostGuestDate": "2023-11-02 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 41
	},
	{
		"id": "fddf17a",
		"email": "hwhopkins@aol.com",
		"name": "Howard",
		"createdDate": "2009-06-05 03:17:22",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 374,
		"totalCountries": 12,
		"totalStates": 49,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 1661,
		"schedulePercent": 0.656204,
		"totalGuests": 661,
		"guestPercent": 0.742286,
		"mostGuestDate": "2023-02-14 00:00:00",
		"mostGuestName": "Eccles Theater",
		"mostGuestCity": "Salt Lake City",
		"mostGuestAmount": 76
	},
	{
		"id": "fadd508",
		"email": "mlondra@venuworks.com",
		"name": "Michael ",
		"createdDate": "2022-02-11 16:59:04",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 61,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 195,
		"schedulePercent": 0.147265,
		"totalGuests": 89,
		"guestPercent": 0.462323,
		"mostGuestDate": "2023-04-29 00:00:00",
		"mostGuestName": "Casino Miami",
		"mostGuestCity": "Miami",
		"mostGuestAmount": 17
	},
	{
		"id": "5101189",
		"email": "info@simonerecords.net",
		"name": "Sandrine",
		"createdDate": "2019-04-23 19:44:39",
		"totalOrganizations": 26,
		"totalTours": 25,
		"totalDays": 471,
		"totalCountries": 4,
		"totalStates": 20,
		"totalEvents": 384,
		"eventPercent": 0.953119,
		"totalScheduleItems": 1973,
		"schedulePercent": 0.714607,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cb0053b",
		"email": "Jesusaaraujo@gmail.com",
		"name": "Jesus ",
		"createdDate": "2016-01-26 22:14:34",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 120,
		"totalCountries": 9,
		"totalStates": 44,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 770,
		"schedulePercent": 0.408952,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2e5733b",
		"email": "chriswalkowski@gmail.com",
		"name": "Christopher",
		"createdDate": "2015-06-23 17:52:01",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 750,
		"totalCountries": 14,
		"totalStates": 63,
		"totalEvents": 249,
		"eventPercent": 0.870746,
		"totalScheduleItems": 3886,
		"schedulePercent": 0.917892,
		"totalGuests": 1941,
		"guestPercent": 0.9314,
		"mostGuestDate": "2023-11-05 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 82
	},
	{
		"id": "a38e3a1",
		"email": "ocmstour@gmail.com",
		"name": "Old Crow",
		"createdDate": "2021-05-04 18:33:25",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 364,
		"totalCountries": 5,
		"totalStates": 37,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 1143,
		"schedulePercent": 0.524699,
		"totalGuests": 774,
		"guestPercent": 0.770494,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "Live Oak Bank Pavilion",
		"mostGuestCity": "Wilmington",
		"mostGuestAmount": 46
	},
	{
		"id": "5912977",
		"email": "kierencanderson@gmail.com",
		"name": "Kieren ",
		"createdDate": "2020-02-18 18:35:43",
		"totalOrganizations": 4,
		"totalTours": 16,
		"totalDays": 108,
		"totalCountries": 9,
		"totalStates": 17,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 295,
		"schedulePercent": 0.205668,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fb00673",
		"email": "jedwards@wastedpotential.net",
		"name": "Jordan ",
		"createdDate": "2019-11-08 00:23:33",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 229,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 187,
		"schedulePercent": 0.143557,
		"totalGuests": 10,
		"guestPercent": 0.343531,
		"mostGuestDate": "2023-06-14 00:00:00",
		"mostGuestName": "Place Bell",
		"mostGuestCity": "Laval",
		"mostGuestAmount": 8
	},
	{
		"id": "01abb7c",
		"email": "mmiller2154@gmail.com",
		"name": "Megan",
		"createdDate": "2021-07-30 15:58:58",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 941,
		"totalCountries": 9,
		"totalStates": 45,
		"totalEvents": 186,
		"eventPercent": 0.782016,
		"totalScheduleItems": 3593,
		"schedulePercent": 0.899351,
		"totalGuests": 4249,
		"guestPercent": 0.985697,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Raymond James Stadium",
		"mostGuestCity": "Tampa ",
		"mostGuestAmount": 248
	},
	{
		"id": "b36fa68",
		"email": "grato@goldtheoryartists.com",
		"name": "James",
		"createdDate": "2022-03-30 16:03:49",
		"totalOrganizations": 10,
		"totalTours": 17,
		"totalDays": 531,
		"totalCountries": 15,
		"totalStates": 82,
		"totalEvents": 252,
		"eventPercent": 0.876308,
		"totalScheduleItems": 2391,
		"schedulePercent": 0.779367,
		"totalGuests": 713,
		"guestPercent": 0.755794,
		"mostGuestDate": "2023-06-07 00:00:00",
		"mostGuestName": "Verti Music Hall",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 61
	},
	{
		"id": "56809fa",
		"email": "MJTHC2023@gmail.com",
		"name": "Jamie Kurtz (HC)",
		"createdDate": "2023-09-21 22:21:02",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 101,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 509,
		"schedulePercent": 0.303536,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8094761",
		"email": "rachael@rksband.com",
		"name": "Rachael",
		"createdDate": "2018-01-23 19:14:03",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 225,
		"totalCountries": 13,
		"totalStates": 41,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 688,
		"schedulePercent": 0.375579,
		"totalGuests": 236,
		"guestPercent": 0.571447,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 43
	},
	{
		"id": "39cb2dd",
		"email": "karimakingsley@gmail.com",
		"name": "Karima",
		"createdDate": "2018-04-24 06:18:07",
		"totalOrganizations": 15,
		"totalTours": 12,
		"totalDays": 517,
		"totalCountries": 20,
		"totalStates": 35,
		"totalEvents": 235,
		"eventPercent": 0.855516,
		"totalScheduleItems": 3218,
		"schedulePercent": 0.871275,
		"totalGuests": 136,
		"guestPercent": 0.503377,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Reading Festival",
		"mostGuestCity": "Reading",
		"mostGuestAmount": 23
	},
	{
		"id": "f570633",
		"email": "jaimeeharris@gmail.com",
		"name": "Jaimee",
		"createdDate": "2017-10-04 08:58:35",
		"totalOrganizations": 1,
		"totalTours": 13,
		"totalDays": 108,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 145,
		"schedulePercent": 0.116144,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "Rockwood Music Hall Stage 3",
		"mostGuestCity": "New York",
		"mostGuestAmount": 2
	},
	{
		"id": "ff27497",
		"email": "battlefldproduction@gmail.com",
		"name": "Jesse",
		"createdDate": "2023-03-29 00:13:24",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 105,
		"totalCountries": 3,
		"totalStates": 23,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 783,
		"schedulePercent": 0.41372,
		"totalGuests": 207,
		"guestPercent": 0.555953,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "Piedmont Hall",
		"mostGuestCity": "Greensboro",
		"mostGuestAmount": 27
	},
	{
		"id": "4941fa5",
		"email": "records@lakewood.cc",
		"name": "Road",
		"createdDate": "2022-10-13 18:58:08",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 68,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 173,
		"schedulePercent": 0.134684,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f14448b",
		"email": "wyatt13s@me.com",
		"name": "Wyatt ",
		"createdDate": "2012-03-08 18:31:24",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 487,
		"totalCountries": 3,
		"totalStates": 44,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 1261,
		"schedulePercent": 0.555158,
		"totalGuests": 624,
		"guestPercent": 0.732751,
		"mostGuestDate": "2023-08-15 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 52
	},
	{
		"id": "f2e158f",
		"email": "Isabell.Flagstad@unitedstage.dk",
		"name": "Isabell",
		"createdDate": "2021-09-01 09:28:19",
		"totalOrganizations": 92,
		"totalTours": 56,
		"totalDays": 1593,
		"totalCountries": 5,
		"totalStates": 15,
		"totalEvents": 1467,
		"eventPercent": 0.992981,
		"totalScheduleItems": 6894,
		"schedulePercent": 0.988478,
		"totalGuests": 126,
		"guestPercent": 0.495564,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Tivoli Friheden",
		"mostGuestCity": "Aarhus",
		"mostGuestAmount": 36
	},
	{
		"id": "2aae26f",
		"email": "derek@themwsgroup.com",
		"name": "Derek",
		"createdDate": "2022-01-20 16:43:13",
		"totalOrganizations": 6,
		"totalTours": 24,
		"totalDays": 530,
		"totalCountries": 7,
		"totalStates": 38,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 2082,
		"schedulePercent": 0.732221,
		"totalGuests": 864,
		"guestPercent": 0.790756,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "The Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 130
	},
	{
		"id": "2206ebb",
		"email": "mark.mackay@mac.com",
		"name": "Mark",
		"createdDate": "2018-08-29 04:48:44",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 123,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 463,
		"schedulePercent": 0.282082,
		"totalGuests": 23,
		"guestPercent": 0.37452,
		"mostGuestDate": "2023-03-12 00:00:00",
		"mostGuestName": "San Juan Outpost",
		"mostGuestCity": "San Juan Capistrano",
		"mostGuestAmount": 13
	},
	{
		"id": "65068e4",
		"email": "matthooper15@gmail.com",
		"name": "Matthew",
		"createdDate": "2017-03-02 22:37:53",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 141,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 796,
		"schedulePercent": 0.41915,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "GoldenSky Fest",
		"mostGuestCity": "Sacramento",
		"mostGuestAmount": 2
	},
	{
		"id": "ea0572c",
		"email": "emmywindelhed@gmail.com",
		"name": "Emmy",
		"createdDate": "2020-02-06 08:45:19",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 139,
		"totalCountries": 12,
		"totalStates": 33,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 1048,
		"schedulePercent": 0.496358,
		"totalGuests": 689,
		"guestPercent": 0.750497,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Borgholms Slott",
		"mostGuestCity": "Borgholm",
		"mostGuestAmount": 45
	},
	{
		"id": "96b5462",
		"email": "nate@samhunttouring.com",
		"name": "Nathan",
		"createdDate": "2016-03-18 22:40:59",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 443,
		"totalCountries": 2,
		"totalStates": 31,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 2100,
		"schedulePercent": 0.735134,
		"totalGuests": 1186,
		"guestPercent": 0.854721,
		"mostGuestDate": "2023-09-07 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 118
	},
	{
		"id": "b57d732",
		"email": "jillian.ferris@me.com",
		"name": "Jillian",
		"createdDate": "2013-04-12 16:05:45",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 468,
		"totalCountries": 3,
		"totalStates": 34,
		"totalEvents": 180,
		"eventPercent": 0.770229,
		"totalScheduleItems": 3064,
		"schedulePercent": 0.858032,
		"totalGuests": 1653,
		"guestPercent": 0.907032,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Raising Cane's River Center",
		"mostGuestCity": "Baton Rouge",
		"mostGuestAmount": 122
	},
	{
		"id": "92811cb",
		"email": "David@uplighttechnologies.com",
		"name": "David",
		"createdDate": "2021-06-17 21:07:55",
		"totalOrganizations": 1,
		"totalTours": 78,
		"totalDays": 383,
		"totalCountries": 1,
		"totalStates": 22,
		"totalEvents": 249,
		"eventPercent": 0.870746,
		"totalScheduleItems": 1273,
		"schedulePercent": 0.55741,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "380ea02",
		"email": "ryan@rynot.co",
		"name": "Ryan",
		"createdDate": "2023-07-02 17:27:46",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 89,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 496,
		"schedulePercent": 0.296385,
		"totalGuests": 587,
		"guestPercent": 0.721494,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "MetLife Stadium",
		"mostGuestCity": "East Rutherford",
		"mostGuestAmount": 66
	},
	{
		"id": "3580038",
		"email": "hoodwilliamspt@gmail.com",
		"name": "Hood",
		"createdDate": "2019-05-28 07:44:44",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 181,
		"totalCountries": 1,
		"totalStates": 42,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1216,
		"schedulePercent": 0.544034,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7e538c4",
		"email": "rolds@roundroomlive.com",
		"name": "Rob",
		"createdDate": "2023-01-18 17:55:28",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 471,
		"totalCountries": 3,
		"totalStates": 41,
		"totalEvents": 238,
		"eventPercent": 0.860019,
		"totalScheduleItems": 3588,
		"schedulePercent": 0.899219,
		"totalGuests": 766,
		"guestPercent": 0.768243,
		"mostGuestDate": "2023-12-06 00:00:00",
		"mostGuestName": "Terrace Theatre",
		"mostGuestCity": "Long Beach",
		"mostGuestAmount": 73
	},
	{
		"id": "d1fe77a",
		"email": "adrianshinn@gmail.com",
		"name": "Adrian",
		"createdDate": "2014-12-10 01:25:53",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 128,
		"totalCountries": 10,
		"totalStates": 13,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 749,
		"schedulePercent": 0.401404,
		"totalGuests": 183,
		"guestPercent": 0.538737,
		"mostGuestDate": "2023-06-07 00:00:00",
		"mostGuestName": "ParkbÃ¼hne",
		"mostGuestCity": "Leipzig",
		"mostGuestAmount": 48
	},
	{
		"id": "adb8fe6",
		"email": "ifan23@me.com",
		"name": "Ifan",
		"createdDate": "2010-10-24 03:57:50",
		"totalOrganizations": 19,
		"totalTours": 10,
		"totalDays": 107,
		"totalCountries": 5,
		"totalStates": 29,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 850,
		"schedulePercent": 0.437955,
		"totalGuests": 391,
		"guestPercent": 0.649715,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Orpheum Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 55
	},
	{
		"id": "3fb332d",
		"email": "sabrina@passportsrequired.com",
		"name": "Sabrina ",
		"createdDate": "2021-09-25 03:03:00",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 100,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 220,
		"schedulePercent": 0.163554,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1fc5885",
		"email": "shaunmacdon@gmail.com",
		"name": "Shaun",
		"createdDate": "2017-09-08 19:28:15",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 121,
		"totalCountries": 13,
		"totalStates": 31,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 597,
		"schedulePercent": 0.339558,
		"totalGuests": 23,
		"guestPercent": 0.37452,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "Vega",
		"mostGuestCity": "Kobenhavn",
		"mostGuestAmount": 11
	},
	{
		"id": "e7f6071",
		"email": "leigh@handshakemgmt.com",
		"name": "Leigh",
		"createdDate": "2016-11-07 12:26:37",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 137,
		"totalCountries": 9,
		"totalStates": 36,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 864,
		"schedulePercent": 0.440869,
		"totalGuests": 648,
		"guestPercent": 0.738843,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 100
	},
	{
		"id": "8617d28",
		"email": "rpattontours@gmail.com",
		"name": "Robert",
		"createdDate": "2023-03-16 18:56:59",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 119,
		"totalCountries": 3,
		"totalStates": 32,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 928,
		"schedulePercent": 0.459012,
		"totalGuests": 323,
		"guestPercent": 0.61568,
		"mostGuestDate": "2023-10-31 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 85
	},
	{
		"id": "75b6f05",
		"email": "abullock@bullocks.co.uk",
		"name": "Adrian ",
		"createdDate": "2013-09-15 23:11:59",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 135,
		"totalCountries": 4,
		"totalStates": 26,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 877,
		"schedulePercent": 0.446431,
		"totalGuests": 104,
		"guestPercent": 0.478877,
		"mostGuestDate": "2023-07-03 00:00:00",
		"mostGuestName": "Thomas Wolfe Auditorium",
		"mostGuestCity": "Asheville",
		"mostGuestAmount": 29
	},
	{
		"id": "e6de57f",
		"email": "dayah@awrightworld.com",
		"name": "Dayah",
		"createdDate": "2018-05-13 04:43:39",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 892,
		"totalCountries": 8,
		"totalStates": 19,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 2243,
		"schedulePercent": 0.756986,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b8fac8d",
		"email": "admin@410mgmt.com",
		"name": "Admin",
		"createdDate": "2023-09-18 15:30:08",
		"totalOrganizations": 16,
		"totalTours": 17,
		"totalDays": 523,
		"totalCountries": 10,
		"totalStates": 49,
		"totalEvents": 182,
		"eventPercent": 0.773408,
		"totalScheduleItems": 2161,
		"schedulePercent": 0.74414,
		"totalGuests": 1000,
		"guestPercent": 0.823864,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Meridian Centre",
		"mostGuestCity": "St. Catharines",
		"mostGuestAmount": 110
	},
	{
		"id": "f4310ba",
		"email": "ryan@whelpley.org",
		"name": "Ryan",
		"createdDate": "2010-04-13 23:50:29",
		"totalOrganizations": 73,
		"totalTours": 63,
		"totalDays": 11277,
		"totalCountries": 16,
		"totalStates": 98,
		"totalEvents": 2730,
		"eventPercent": 0.999603,
		"totalScheduleItems": 33792,
		"schedulePercent": 0.999603,
		"totalGuests": 10432,
		"guestPercent": 0.99947,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Dos Equis Pavilion",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 155
	},
	{
		"id": "869d446",
		"email": "adamboatman@me.com",
		"name": "Adam",
		"createdDate": "2014-05-16 17:56:28",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 977,
		"totalCountries": 4,
		"totalStates": 38,
		"totalEvents": 202,
		"eventPercent": 0.810091,
		"totalScheduleItems": 3555,
		"schedulePercent": 0.897762,
		"totalGuests": 1960,
		"guestPercent": 0.931797,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Raising Cane's River Center",
		"mostGuestCity": "Baton Rouge",
		"mostGuestAmount": 122
	},
	{
		"id": "d6ac1ba",
		"email": "johan.ekblad@unitedvision.se",
		"name": "Johan",
		"createdDate": "2018-04-10 13:32:37",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 77,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 498,
		"schedulePercent": 0.297576,
		"totalGuests": 319,
		"guestPercent": 0.613826,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Uppsala Konsert & Kongress",
		"mostGuestCity": "Uppsala",
		"mostGuestAmount": 40
	},
	{
		"id": "14cf768",
		"email": "taylorveraldi@gmail.com",
		"name": "Taylor",
		"createdDate": "2017-07-14 17:58:41",
		"totalOrganizations": 10,
		"totalTours": 11,
		"totalDays": 396,
		"totalCountries": 3,
		"totalStates": 47,
		"totalEvents": 190,
		"eventPercent": 0.788505,
		"totalScheduleItems": 2981,
		"schedulePercent": 0.849556,
		"totalGuests": 304,
		"guestPercent": 0.605218,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The Aztec Theatre",
		"mostGuestCity": "San Antonio",
		"mostGuestAmount": 70
	},
	{
		"id": "8191f49",
		"email": "jacob@cphmusic.dk",
		"name": "Jacob ",
		"createdDate": "2019-03-21 13:26:59",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 195,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 183,
		"eventPercent": 0.775659,
		"totalScheduleItems": 790,
		"schedulePercent": 0.416501,
		"totalGuests": 126,
		"guestPercent": 0.495564,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Tivoli Friheden",
		"mostGuestCity": "Aarhus",
		"mostGuestAmount": 36
	},
	{
		"id": "eac7d20",
		"email": "justin@jusmanagement.com",
		"name": "Jus.",
		"createdDate": "2019-11-21 22:39:22",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 31,
		"totalCountries": 5,
		"totalStates": 8,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 190,
		"schedulePercent": 0.144617,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b2fa0be",
		"email": "itstrevor@mac.com",
		"name": "Trevor",
		"createdDate": "2010-06-17 12:20:07",
		"totalOrganizations": 12,
		"totalTours": 7,
		"totalDays": 270,
		"totalCountries": 8,
		"totalStates": 15,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 1155,
		"schedulePercent": 0.528142,
		"totalGuests": 724,
		"guestPercent": 0.758443,
		"mostGuestDate": "2023-04-06 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 106
	},
	{
		"id": "e538ed1",
		"email": "dusty.wint@gmail.com",
		"name": "Dusty",
		"createdDate": "2018-10-07 03:37:43",
		"totalOrganizations": 20,
		"totalTours": 37,
		"totalDays": 894,
		"totalCountries": 19,
		"totalStates": 87,
		"totalEvents": 453,
		"eventPercent": 0.967951,
		"totalScheduleItems": 6565,
		"schedulePercent": 0.985697,
		"totalGuests": 2100,
		"guestPercent": 0.939743,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "The Observatory",
		"mostGuestCity": "Santa Ana",
		"mostGuestAmount": 75
	},
	{
		"id": "4ed0a25",
		"email": "audioarnold@gmail.com",
		"name": "Christian",
		"createdDate": "2016-12-30 18:19:46",
		"totalOrganizations": 11,
		"totalTours": 14,
		"totalDays": 862,
		"totalCountries": 11,
		"totalStates": 58,
		"totalEvents": 261,
		"eventPercent": 0.886902,
		"totalScheduleItems": 4198,
		"schedulePercent": 0.933916,
		"totalGuests": 855,
		"guestPercent": 0.788637,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 55
	},
	{
		"id": "226f491",
		"email": "andrew@greenspringmgmt.com",
		"name": "Andrew",
		"createdDate": "2011-09-06 23:44:12",
		"totalOrganizations": 9,
		"totalTours": 12,
		"totalDays": 195,
		"totalCountries": 19,
		"totalStates": 46,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 1323,
		"schedulePercent": 0.57211,
		"totalGuests": 778,
		"guestPercent": 0.771421,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 57
	},
	{
		"id": "291e1e7",
		"email": "Ariel@iconforhire.net",
		"name": "Ariel",
		"createdDate": "2017-05-08 17:01:34",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 102,
		"totalCountries": 2,
		"totalStates": 37,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 699,
		"schedulePercent": 0.380215,
		"totalGuests": 158,
		"guestPercent": 0.520858,
		"mostGuestDate": "2023-05-21 00:00:00",
		"mostGuestName": "The End",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 28
	},
	{
		"id": "dc23ecd",
		"email": "martin@skou.eu",
		"name": "Martin",
		"createdDate": "2018-09-24 20:46:45",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 150,
		"totalCountries": 8,
		"totalStates": 18,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 992,
		"schedulePercent": 0.481658,
		"totalGuests": 880,
		"guestPercent": 0.794464,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 91
	},
	{
		"id": "20ece48",
		"email": "blakecitro@gmail.com",
		"name": "Blake",
		"createdDate": "2018-05-21 20:41:01",
		"totalOrganizations": 6,
		"totalTours": 20,
		"totalDays": 202,
		"totalCountries": 2,
		"totalStates": 17,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 1298,
		"schedulePercent": 0.565885,
		"totalGuests": 553,
		"guestPercent": 0.710899,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "The Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 130
	},
	{
		"id": "894ee6a",
		"email": "band@judahandthelion.com",
		"name": "Claire",
		"createdDate": "2022-07-18 16:36:26",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 730,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 1131,
		"schedulePercent": 0.519931,
		"totalGuests": 529,
		"guestPercent": 0.701894,
		"mostGuestDate": "2023-11-15 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 63
	},
	{
		"id": "6c35b17",
		"email": "alexagra@gmail.com",
		"name": "Alejandro",
		"createdDate": "2009-10-22 14:37:49",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 690,
		"totalCountries": 6,
		"totalStates": 41,
		"totalEvents": 249,
		"eventPercent": 0.870746,
		"totalScheduleItems": 3337,
		"schedulePercent": 0.882267,
		"totalGuests": 196,
		"guestPercent": 0.546947,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "Madison Square Garden",
		"mostGuestCity": "New York",
		"mostGuestAmount": 22
	},
	{
		"id": "a6bd8de",
		"email": "alexnrivas@gmail.com",
		"name": "Alex",
		"createdDate": "2014-05-21 03:26:26",
		"totalOrganizations": 13,
		"totalTours": 8,
		"totalDays": 1134,
		"totalCountries": 8,
		"totalStates": 39,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1884,
		"schedulePercent": 0.701894,
		"totalGuests": 147,
		"guestPercent": 0.511323,
		"mostGuestDate": "2023-10-25 00:00:00",
		"mostGuestName": "Royal Oak Music Theatre",
		"mostGuestCity": "Royal Oak",
		"mostGuestAmount": 24
	},
	{
		"id": "68589cd",
		"email": "milo@21stca.com",
		"name": "Milo",
		"createdDate": "2022-03-01 17:30:19",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 430,
		"totalCountries": 3,
		"totalStates": 20,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 1067,
		"schedulePercent": 0.503642,
		"totalGuests": 193,
		"guestPercent": 0.545093,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "Rainbow Lagoon Park",
		"mostGuestCity": "Long Beach",
		"mostGuestAmount": 24
	},
	{
		"id": "3f52586",
		"email": "tim.reitnouer@gmail.com",
		"name": "Tim",
		"createdDate": "2013-06-28 19:17:28",
		"totalOrganizations": 19,
		"totalTours": 19,
		"totalDays": 1213,
		"totalCountries": 7,
		"totalStates": 51,
		"totalEvents": 390,
		"eventPercent": 0.954576,
		"totalScheduleItems": 5012,
		"schedulePercent": 0.962257,
		"totalGuests": 3514,
		"guestPercent": 0.977751,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Gorge Amphitheatre",
		"mostGuestCity": "George",
		"mostGuestAmount": 205
	},
	{
		"id": "8a8905b",
		"email": "ann.gibson@frontiertouring.com",
		"name": "Ann",
		"createdDate": "2017-10-02 14:29:49",
		"totalOrganizations": 24,
		"totalTours": 44,
		"totalDays": 1200,
		"totalCountries": 14,
		"totalStates": 33,
		"totalEvents": 253,
		"eventPercent": 0.877765,
		"totalScheduleItems": 3459,
		"schedulePercent": 0.891008,
		"totalGuests": 836,
		"guestPercent": 0.784664,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "3cde75b",
		"email": "erikastageart@gmail.com",
		"name": "Erika",
		"createdDate": "2023-02-10 01:25:57",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 185,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 704,
		"schedulePercent": 0.382201,
		"totalGuests": 308,
		"guestPercent": 0.608131,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "The Long Center for the Performing Arts",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 26
	},
	{
		"id": "53c2f00",
		"email": "Bethany@prathertouring.com",
		"name": "Bethany",
		"createdDate": "2023-08-19 19:46:53",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 192,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 617,
		"schedulePercent": 0.348431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a438721",
		"email": "paul_cawley@mac.com",
		"name": "Paul",
		"createdDate": "2018-09-05 20:32:17",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 62,
		"totalCountries": 3,
		"totalStates": 6,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 501,
		"schedulePercent": 0.298768,
		"totalGuests": 299,
		"guestPercent": 0.602039,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Brighton Dome",
		"mostGuestCity": "Brighton",
		"mostGuestAmount": 40
	},
	{
		"id": "942c20d",
		"email": "zjurkovich@gmail.com",
		"name": "Zach",
		"createdDate": "2021-09-27 20:59:19",
		"totalOrganizations": 12,
		"totalTours": 18,
		"totalDays": 674,
		"totalCountries": 9,
		"totalStates": 46,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 1637,
		"schedulePercent": 0.650377,
		"totalGuests": 193,
		"guestPercent": 0.545093,
		"mostGuestDate": "2023-09-27 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 20
	},
	{
		"id": "1a08419",
		"email": "pjschloss@gmail.com",
		"name": "Philip",
		"createdDate": "2015-12-07 19:06:20",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 321,
		"totalCountries": 8,
		"totalStates": 44,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1069,
		"schedulePercent": 0.504701,
		"totalGuests": 266,
		"guestPercent": 0.587869,
		"mostGuestDate": "2023-08-04 00:00:00",
		"mostGuestName": "The Vogel",
		"mostGuestCity": "Red Bank",
		"mostGuestAmount": 35
	},
	{
		"id": "1eaf162",
		"email": "harry@futureclassic.com",
		"name": "Harry ",
		"createdDate": "2018-05-11 01:22:37",
		"totalOrganizations": 9,
		"totalTours": 13,
		"totalDays": 388,
		"totalCountries": 9,
		"totalStates": 45,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 1922,
		"schedulePercent": 0.706661,
		"totalGuests": 603,
		"guestPercent": 0.727321,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 207
	},
	{
		"id": "a8bc0c0",
		"email": "jaime@damngoodtours.com",
		"name": "Jaime",
		"createdDate": "2021-08-09 15:21:34",
		"totalOrganizations": 16,
		"totalTours": 16,
		"totalDays": 884,
		"totalCountries": 20,
		"totalStates": 69,
		"totalEvents": 250,
		"eventPercent": 0.872335,
		"totalScheduleItems": 3023,
		"schedulePercent": 0.853264,
		"totalGuests": 485,
		"guestPercent": 0.685207,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Northcote Theatre",
		"mostGuestCity": "Northcote",
		"mostGuestAmount": 47
	},
	{
		"id": "5046243",
		"email": "Duncjohnson@me.com",
		"name": "Duncan ",
		"createdDate": "2016-12-15 22:49:40",
		"totalOrganizations": 8,
		"totalTours": 3,
		"totalDays": 305,
		"totalCountries": 4,
		"totalStates": 30,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 3089,
		"schedulePercent": 0.860283,
		"totalGuests": 4694,
		"guestPercent": 0.989538,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 240
	},
	{
		"id": "5e40552",
		"email": "max@constantartists.com",
		"name": "Max",
		"createdDate": "2020-01-07 19:04:43",
		"totalOrganizations": 9,
		"totalTours": 14,
		"totalDays": 653,
		"totalCountries": 18,
		"totalStates": 75,
		"totalEvents": 244,
		"eventPercent": 0.865713,
		"totalScheduleItems": 3148,
		"schedulePercent": 0.865713,
		"totalGuests": 440,
		"guestPercent": 0.668653,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 125
	},
	{
		"id": "9911378",
		"email": "mikesutherland@me.com",
		"name": "Mike",
		"createdDate": "2013-05-09 05:10:19",
		"totalOrganizations": 12,
		"totalTours": 14,
		"totalDays": 899,
		"totalCountries": 12,
		"totalStates": 52,
		"totalEvents": 241,
		"eventPercent": 0.862667,
		"totalScheduleItems": 4238,
		"schedulePercent": 0.93524,
		"totalGuests": 1974,
		"guestPercent": 0.932062,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 137
	},
	{
		"id": "b0126b1",
		"email": "adamlammers8@gmail.com",
		"name": "Adam",
		"createdDate": "2015-07-10 00:07:55",
		"totalOrganizations": 8,
		"totalTours": 7,
		"totalDays": 407,
		"totalCountries": 4,
		"totalStates": 46,
		"totalEvents": 276,
		"eventPercent": 0.898292,
		"totalScheduleItems": 3263,
		"schedulePercent": 0.874851,
		"totalGuests": 1111,
		"guestPercent": 0.841081,
		"mostGuestDate": "2023-01-05 00:00:00",
		"mostGuestName": "The Chicago Theatre",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 63
	},
	{
		"id": "fcaa538",
		"email": "jproc@jp2.cc",
		"name": "John",
		"createdDate": "2014-08-06 22:11:55",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 101,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 596,
		"schedulePercent": 0.338763,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f246809",
		"email": "Kevinhowardji@gmail.com",
		"name": "Kevin",
		"createdDate": "2012-01-25 03:18:28",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 67,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 90,
		"schedulePercent": 0.0826381,
		"totalGuests": 86,
		"guestPercent": 0.460204,
		"mostGuestDate": "2023-04-27 00:00:00",
		"mostGuestName": "MJM",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 34
	},
	{
		"id": "469fc1d",
		"email": "koyaanisqatsi_@hotmail.com",
		"name": "Simon",
		"createdDate": "2013-04-08 20:46:05",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 96,
		"totalCountries": 13,
		"totalStates": 22,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 324,
		"schedulePercent": 0.221428,
		"totalGuests": 87,
		"guestPercent": 0.460734,
		"mostGuestDate": "2023-09-27 00:00:00",
		"mostGuestName": "London Palladium",
		"mostGuestCity": "London",
		"mostGuestAmount": 45
	},
	{
		"id": "03cf4be",
		"email": "Hippiesabotagetour@gmail.com",
		"name": "Brandon",
		"createdDate": "2018-01-09 20:25:17",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 50,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 297,
		"schedulePercent": 0.206595,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b863fe8",
		"email": "johnpeterhasson@gmail.com",
		"name": "John-Peter",
		"createdDate": "2015-12-02 03:08:11",
		"totalOrganizations": 10,
		"totalTours": 5,
		"totalDays": 110,
		"totalCountries": 4,
		"totalStates": 32,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 821,
		"schedulePercent": 0.426831,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "31909d3",
		"email": "legendrenicoregie@gmail.com",
		"name": "Nicolas",
		"createdDate": "2017-05-02 08:25:41",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 61,
		"totalCountries": 4,
		"totalStates": 11,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 484,
		"schedulePercent": 0.290558,
		"totalGuests": 254,
		"guestPercent": 0.581248,
		"mostGuestDate": "2023-06-18 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 40
	},
	{
		"id": "4eeb801",
		"email": "Sahir@mdrums.com",
		"name": "Sahir",
		"createdDate": "2012-10-08 23:04:31",
		"totalOrganizations": 10,
		"totalTours": 11,
		"totalDays": 1128,
		"totalCountries": 11,
		"totalStates": 45,
		"totalEvents": 194,
		"eventPercent": 0.794597,
		"totalScheduleItems": 2868,
		"schedulePercent": 0.839094,
		"totalGuests": 700,
		"guestPercent": 0.753145,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 185
	},
	{
		"id": "c02126e",
		"email": "brande@lokahioutreach.org",
		"name": "Brande",
		"createdDate": "2014-03-04 20:32:06",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 852,
		"totalCountries": 18,
		"totalStates": 32,
		"totalEvents": 341,
		"eventPercent": 0.937757,
		"totalScheduleItems": 4110,
		"schedulePercent": 0.928354,
		"totalGuests": 6793,
		"guestPercent": 0.996689,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Wembley Stadium",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 625
	},
	{
		"id": "925de36",
		"email": "hello@larktouring.com",
		"name": "Lark",
		"createdDate": "2023-09-12 10:28:47",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 355,
		"totalCountries": 6,
		"totalStates": 31,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1082,
		"schedulePercent": 0.50841,
		"totalGuests": 45,
		"guestPercent": 0.412925,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "The End",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 26
	},
	{
		"id": "a51c900",
		"email": "henrik@t-management.se",
		"name": "Henrik",
		"createdDate": "2020-08-14 19:51:13",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 168,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 318,
		"schedulePercent": 0.217322,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ae83ec2",
		"email": "beckylocke@hotmail.ca",
		"name": "Becky",
		"createdDate": "2014-11-13 04:45:05",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 86,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 388,
		"schedulePercent": 0.252152,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f25d3df",
		"email": "stumpyis@aol.com",
		"name": "Fraser",
		"createdDate": "2016-10-28 11:15:20",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 127,
		"totalCountries": 15,
		"totalStates": 39,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1074,
		"schedulePercent": 0.505628,
		"totalGuests": 864,
		"guestPercent": 0.790756,
		"mostGuestDate": "2023-05-23 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 54
	},
	{
		"id": "0395635",
		"email": "nsgoldman29@gmail.com",
		"name": "Noah",
		"createdDate": "2014-06-17 01:52:01",
		"totalOrganizations": 18,
		"totalTours": 4,
		"totalDays": 187,
		"totalCountries": 3,
		"totalStates": 37,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1721,
		"schedulePercent": 0.669845,
		"totalGuests": 707,
		"guestPercent": 0.754867,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 40
	},
	{
		"id": "1fae7cb",
		"email": "thevandoliers@gmail.com",
		"name": "Rachel",
		"createdDate": "2022-06-04 15:29:12",
		"totalOrganizations": 5,
		"totalTours": 16,
		"totalDays": 488,
		"totalCountries": 11,
		"totalStates": 66,
		"totalEvents": 315,
		"eventPercent": 0.923057,
		"totalScheduleItems": 2403,
		"schedulePercent": 0.780956,
		"totalGuests": 2171,
		"guestPercent": 0.942127,
		"mostGuestDate": "2023-08-29 00:00:00",
		"mostGuestName": "Roseland Theater",
		"mostGuestCity": "Portland",
		"mostGuestAmount": 172
	},
	{
		"id": "7a3a046",
		"email": "advancing@michaeljr.com",
		"name": "Michael Jr. ",
		"createdDate": "2017-02-22 02:42:02",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 481,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1306,
		"schedulePercent": 0.567872,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "87d1c6a",
		"email": "bec@etcmusic.co.uk",
		"name": "Rebecca",
		"createdDate": "2019-08-17 15:07:11",
		"totalOrganizations": 13,
		"totalTours": 16,
		"totalDays": 603,
		"totalCountries": 19,
		"totalStates": 62,
		"totalEvents": 338,
		"eventPercent": 0.936432,
		"totalScheduleItems": 5171,
		"schedulePercent": 0.966759,
		"totalGuests": 2169,
		"guestPercent": 0.941994,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 185
	},
	{
		"id": "ec06980",
		"email": "soteromanagement@gmail.com",
		"name": "Paulo",
		"createdDate": "2018-08-08 21:20:41",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 106,
		"totalCountries": 13,
		"totalStates": 21,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 590,
		"schedulePercent": 0.33585,
		"totalGuests": 115,
		"guestPercent": 0.48722,
		"mostGuestDate": "2023-11-20 00:00:00",
		"mostGuestName": "Zeche",
		"mostGuestCity": "Bochum",
		"mostGuestAmount": 23
	},
	{
		"id": "d6e12c8",
		"email": "Colucci@mikecolucci.com",
		"name": "Mike",
		"createdDate": "2022-09-26 16:19:39",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 368,
		"totalCountries": 9,
		"totalStates": 29,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 61,
		"schedulePercent": 0.0622434,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "59611af",
		"email": "mgmt@poloandfomo.com",
		"name": "LAUNDRY",
		"createdDate": "2019-04-11 18:29:25",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 293,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 278,
		"schedulePercent": 0.196795,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2eb540f",
		"email": "deniz@cphmusic.dk",
		"name": "Deniz",
		"createdDate": "2019-01-21 11:54:27",
		"totalOrganizations": 59,
		"totalTours": 33,
		"totalDays": 947,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 907,
		"eventPercent": 0.989273,
		"totalScheduleItems": 3392,
		"schedulePercent": 0.887035,
		"totalGuests": 23,
		"guestPercent": 0.37452,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Eksercerhuset",
		"mostGuestCity": "Fredericia",
		"mostGuestAmount": 5
	},
	{
		"id": "15240b4",
		"email": "trentw4president@yahoo.com",
		"name": "Stoney",
		"createdDate": "2015-05-16 19:20:29",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 149,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 771,
		"schedulePercent": 0.409085,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "55e010b",
		"email": "nicole@rightangleent.com",
		"name": "Nicole",
		"createdDate": "2022-09-22 18:09:47",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 342,
		"totalCountries": 2,
		"totalStates": 37,
		"totalEvents": 218,
		"eventPercent": 0.834724,
		"totalScheduleItems": 2609,
		"schedulePercent": 0.809959,
		"totalGuests": 18,
		"guestPercent": 0.364455,
		"mostGuestDate": "2023-01-13 00:00:00",
		"mostGuestName": "Pantages Theater",
		"mostGuestCity": "Tacoma",
		"mostGuestAmount": 8
	},
	{
		"id": "77188a3",
		"email": "miska.nipuli@livenation.fi",
		"name": "Miska",
		"createdDate": "2023-11-02 09:00:54",
		"totalOrganizations": 17,
		"totalTours": 10,
		"totalDays": 51,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 223,
		"schedulePercent": 0.165673,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Bar IHKU Helsinki",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 15
	},
	{
		"id": "9564dc5",
		"email": "lijne@forartisticreasons.com",
		"name": "Lijne",
		"createdDate": "2018-05-02 14:12:33",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 34,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 259,
		"schedulePercent": 0.185273,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "FESTIVAL Primavera",
		"mostGuestCity": "Madrid",
		"mostGuestAmount": 2
	},
	{
		"id": "6b49c85",
		"email": "Mastertour@sidewalkprophets.com",
		"name": "sidewalk",
		"createdDate": "2022-10-04 18:33:36",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 658,
		"totalCountries": 1,
		"totalStates": 15,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 1832,
		"schedulePercent": 0.692094,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e8a08ff",
		"email": "daisy@bigloud.com",
		"name": "Daisy",
		"createdDate": "2019-05-16 19:17:11",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 277,
		"totalCountries": 5,
		"totalStates": 27,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 2869,
		"schedulePercent": 0.839359,
		"totalGuests": 4052,
		"guestPercent": 0.984108,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 240
	},
	{
		"id": "5cf830e",
		"email": "aliciaestelletaylor@gmail.com",
		"name": "Alicia",
		"createdDate": "2021-03-29 16:30:30",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 669,
		"totalCountries": 15,
		"totalStates": 45,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 1054,
		"schedulePercent": 0.498609,
		"totalGuests": 670,
		"guestPercent": 0.744272,
		"mostGuestDate": "2023-09-29 00:00:00",
		"mostGuestName": "Brooklyn Bowl Las Vegas",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 111
	},
	{
		"id": "40c51ec",
		"email": "reid@midcitizen.com",
		"name": "Gray",
		"createdDate": "2021-09-22 14:35:41",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 242,
		"totalCountries": 3,
		"totalStates": 34,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 464,
		"schedulePercent": 0.282612,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-09-03 00:00:00",
		"mostGuestName": "Tower Theatre",
		"mostGuestCity": "Oklahoma City",
		"mostGuestAmount": 2
	},
	{
		"id": "ef3baca",
		"email": "ang.treff@icloud.com",
		"name": "Angelica",
		"createdDate": "2023-07-19 16:24:52",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 165,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 487,
		"schedulePercent": 0.291882,
		"totalGuests": 378,
		"guestPercent": 0.644418,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 101
	},
	{
		"id": "b82ff1e",
		"email": "kim@killmenowproductions.com",
		"name": "Kimberly",
		"createdDate": "2019-03-06 21:24:41",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 471,
		"totalCountries": 14,
		"totalStates": 48,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 2971,
		"schedulePercent": 0.848497,
		"totalGuests": 20,
		"guestPercent": 0.36909,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "The Pavilion at Star Lake",
		"mostGuestCity": "Burgettstown",
		"mostGuestAmount": 10
	},
	{
		"id": "d98447c",
		"email": "Lukemcnees@gmail.com",
		"name": "Luke",
		"createdDate": "2015-10-22 18:27:23",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 73,
		"totalCountries": 6,
		"totalStates": 23,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 221,
		"schedulePercent": 0.163952,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2bbc5bb",
		"email": "claireeshunt@gmail.com",
		"name": "Claire",
		"createdDate": "2017-10-07 20:25:52",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 110,
		"totalCountries": 7,
		"totalStates": 12,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 517,
		"schedulePercent": 0.306847,
		"totalGuests": 63,
		"guestPercent": 0.437558,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Finsbury Park",
		"mostGuestCity": "London",
		"mostGuestAmount": 24
	},
	{
		"id": "993ecf9",
		"email": "FantasticTouring@gmail.com",
		"name": "Christopher",
		"createdDate": "2023-05-23 12:11:57",
		"totalOrganizations": 51,
		"totalTours": 27,
		"totalDays": 901,
		"totalCountries": 12,
		"totalStates": 52,
		"totalEvents": 512,
		"eventPercent": 0.975632,
		"totalScheduleItems": 5238,
		"schedulePercent": 0.968216,
		"totalGuests": 4057,
		"guestPercent": 0.98424,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 221
	},
	{
		"id": "5a5d013",
		"email": "ruthcmoody@gmail.com",
		"name": "Ruth",
		"createdDate": "2023-09-26 15:35:07",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 62,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 8,
		"eventPercent": 0.0535028,
		"totalScheduleItems": 120,
		"schedulePercent": 0.102635,
		"totalGuests": 31,
		"guestPercent": 0.390942,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "Barrymore Theatre",
		"mostGuestCity": "Madison",
		"mostGuestAmount": 12
	},
	{
		"id": "032c3b7",
		"email": "nick@sixbynine.com.au",
		"name": "Nicholas",
		"createdDate": "2018-09-22 05:04:54",
		"totalOrganizations": 10,
		"totalTours": 5,
		"totalDays": 76,
		"totalCountries": 3,
		"totalStates": 6,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 231,
		"schedulePercent": 0.169779,
		"totalGuests": 13,
		"guestPercent": 0.352536,
		"mostGuestDate": "2023-02-09 00:00:00",
		"mostGuestName": "Palais Theatre",
		"mostGuestCity": "St Kilda",
		"mostGuestAmount": 8
	},
	{
		"id": "8d41672",
		"email": "harri.wright@riverjuke.co.uk",
		"name": "Harri",
		"createdDate": "2021-07-21 10:03:52",
		"totalOrganizations": 37,
		"totalTours": 40,
		"totalDays": 2601,
		"totalCountries": 29,
		"totalStates": 103,
		"totalEvents": 688,
		"eventPercent": 0.985432,
		"totalScheduleItems": 11876,
		"schedulePercent": 0.998278,
		"totalGuests": 2790,
		"guestPercent": 0.964243,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "The SSE Arena, Wembley",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 226
	},
	{
		"id": "a06b9b7",
		"email": "kurt@mstmevents.org",
		"name": "Kurt",
		"createdDate": "2023-07-25 14:07:50",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 158,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 50,
		"schedulePercent": 0.0540326,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bf78490",
		"email": "carolynrosenfeld@me.com",
		"name": "Carolyn ",
		"createdDate": "2010-03-11 23:25:04",
		"totalOrganizations": 7,
		"totalTours": 4,
		"totalDays": 805,
		"totalCountries": 1,
		"totalStates": 26,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 1146,
		"schedulePercent": 0.525493,
		"totalGuests": 482,
		"guestPercent": 0.68428,
		"mostGuestDate": "2023-01-10 00:00:00",
		"mostGuestName": "The Classic Center",
		"mostGuestCity": "Athens",
		"mostGuestAmount": 68
	},
	{
		"id": "2d6f825",
		"email": "mberube@gestev.com",
		"name": "Maude",
		"createdDate": "2022-06-02 15:11:47",
		"totalOrganizations": 35,
		"totalTours": 19,
		"totalDays": 553,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 507,
		"eventPercent": 0.97497,
		"totalScheduleItems": 2486,
		"schedulePercent": 0.791551,
		"totalGuests": 5104,
		"guestPercent": 0.992451,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "ThÃ©Ã¢tre St-Denis",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 204
	},
	{
		"id": "4fc55f3",
		"email": "ms@kaika.dk",
		"name": "Maria",
		"createdDate": "2015-06-22 07:12:24",
		"totalOrganizations": 32,
		"totalTours": 17,
		"totalDays": 375,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 358,
		"eventPercent": 0.945305,
		"totalScheduleItems": 3101,
		"schedulePercent": 0.861343,
		"totalGuests": 275,
		"guestPercent": 0.59171,
		"mostGuestDate": "2023-04-21 00:00:00",
		"mostGuestName": "Det Kongelige Teater, Gamle Scene",
		"mostGuestCity": "K&#248;benhavn K",
		"mostGuestAmount": 61
	},
	{
		"id": "27ad3cd",
		"email": "lauren@aepevents.net",
		"name": "Lauren",
		"createdDate": "2019-09-24 19:41:43",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 108,
		"totalCountries": 9,
		"totalStates": 19,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 571,
		"schedulePercent": 0.328036,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "507d620",
		"email": "chrisbowman.tm@gmail.com",
		"name": "Chris",
		"createdDate": "2022-07-25 15:02:12",
		"totalOrganizations": 13,
		"totalTours": 21,
		"totalDays": 577,
		"totalCountries": 13,
		"totalStates": 72,
		"totalEvents": 341,
		"eventPercent": 0.937757,
		"totalScheduleItems": 4159,
		"schedulePercent": 0.931797,
		"totalGuests": 2488,
		"guestPercent": 0.953384,
		"mostGuestDate": "2023-06-28 00:00:00",
		"mostGuestName": "The Studio at Webster Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 187
	},
	{
		"id": "5c7069c",
		"email": "kvolodyak@gmail.com",
		"name": "Vladimir",
		"createdDate": "2022-12-22 13:43:49",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 251,
		"totalCountries": 15,
		"totalStates": 37,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 1392,
		"schedulePercent": 0.589458,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5f73e7a",
		"email": "candelaria@newvillagemgmt.com",
		"name": "Candelaria",
		"createdDate": "2022-10-14 14:56:23",
		"totalOrganizations": 12,
		"totalTours": 43,
		"totalDays": 2440,
		"totalCountries": 22,
		"totalStates": 93,
		"totalEvents": 460,
		"eventPercent": 0.969276,
		"totalScheduleItems": 3857,
		"schedulePercent": 0.915773,
		"totalGuests": 51,
		"guestPercent": 0.423785,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Jazz At Lincoln Center",
		"mostGuestCity": "New York",
		"mostGuestAmount": 18
	},
	{
		"id": "8c8d573",
		"email": "infokusrecordings@gmail.com",
		"name": "Nicholas",
		"createdDate": "2019-02-10 23:33:05",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 455,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 1427,
		"schedulePercent": 0.598464,
		"totalGuests": 217,
		"guestPercent": 0.560853,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "FivePoint Amphitheatre",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 33
	},
	{
		"id": "51f63d5",
		"email": "sergi.bautista@oigovisiones.com",
		"name": "Sergi",
		"createdDate": "2022-05-06 10:07:46",
		"totalOrganizations": 1,
		"totalTours": 6,
		"totalDays": 1777,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 630,
		"schedulePercent": 0.354523,
		"totalGuests": 22,
		"guestPercent": 0.373725,
		"mostGuestDate": "2023-08-10 00:00:00",
		"mostGuestName": "Festival Porta Ferrada",
		"mostGuestCity": "Sant Feliu de GuÃ­xols",
		"mostGuestAmount": 22
	},
	{
		"id": "a74cb89",
		"email": "Hannah3183@gmail.com",
		"name": "Hannah",
		"createdDate": "2019-10-15 23:50:53",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 77,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 339,
		"schedulePercent": 0.227122,
		"totalGuests": 92,
		"guestPercent": 0.466163,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Harrah's Cherokee Casino Resort",
		"mostGuestCity": "Cherokee",
		"mostGuestAmount": 30
	},
	{
		"id": "68f7bac",
		"email": "weiland.mikey@gmail.com",
		"name": "Michael",
		"createdDate": "2016-01-21 20:55:05",
		"totalOrganizations": 11,
		"totalTours": 13,
		"totalDays": 579,
		"totalCountries": 18,
		"totalStates": 50,
		"totalEvents": 171,
		"eventPercent": 0.75394,
		"totalScheduleItems": 2812,
		"schedulePercent": 0.833664,
		"totalGuests": 284,
		"guestPercent": 0.59608,
		"mostGuestDate": "2023-10-16 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 50
	},
	{
		"id": "5544376",
		"email": "zane.aph@gmail.com",
		"name": "Zane",
		"createdDate": "2017-06-29 05:40:51",
		"totalOrganizations": 9,
		"totalTours": 19,
		"totalDays": 1567,
		"totalCountries": 15,
		"totalStates": 60,
		"totalEvents": 350,
		"eventPercent": 0.941597,
		"totalScheduleItems": 6364,
		"schedulePercent": 0.98424,
		"totalGuests": 1382,
		"guestPercent": 0.881208,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "The Brooklyn Mirage",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 179
	},
	{
		"id": "a341ed0",
		"email": "jasonharrisaudio@outlook.com",
		"name": "Jason",
		"createdDate": "2022-06-27 22:23:01",
		"totalOrganizations": 7,
		"totalTours": 14,
		"totalDays": 570,
		"totalCountries": 8,
		"totalStates": 52,
		"totalEvents": 178,
		"eventPercent": 0.767051,
		"totalScheduleItems": 1801,
		"schedulePercent": 0.686134,
		"totalGuests": 943,
		"guestPercent": 0.810754,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "Manchester Academy",
		"mostGuestCity": "Manchester",
		"mostGuestAmount": 55
	},
	{
		"id": "317e180",
		"email": "lisa@versitymusic.se",
		"name": "Lisa",
		"createdDate": "2017-12-29 09:39:22",
		"totalOrganizations": 15,
		"totalTours": 9,
		"totalDays": 152,
		"totalCountries": 11,
		"totalStates": 25,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 1270,
		"schedulePercent": 0.557145,
		"totalGuests": 1176,
		"guestPercent": 0.853264,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "Betongparken",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 126
	},
	{
		"id": "8d3f736",
		"email": "zzwardmgmt@gmail.com",
		"name": "Zuszsanna",
		"createdDate": "2018-11-07 22:19:18",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 144,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 579,
		"schedulePercent": 0.331744,
		"totalGuests": 185,
		"guestPercent": 0.539664,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 37
	},
	{
		"id": "90686bd",
		"email": "snlontour@gmail.com",
		"name": "Sara",
		"createdDate": "2023-08-29 13:47:55",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 122,
		"totalCountries": 8,
		"totalStates": 21,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 27,
		"schedulePercent": 0.0380082,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8110951",
		"email": "TAZINIPRODUCTION@AOL.COM",
		"name": "Ed",
		"createdDate": "2012-06-02 05:08:48",
		"totalOrganizations": 11,
		"totalTours": 6,
		"totalDays": 178,
		"totalCountries": 14,
		"totalStates": 52,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 1197,
		"schedulePercent": 0.539531,
		"totalGuests": 712,
		"guestPercent": 0.755529,
		"mostGuestDate": "2023-07-23 00:00:00",
		"mostGuestName": "House of Blues Orlando Barry Camarda Production cell::407-963-8140  barrycamarda@livenation.com  ",
		"mostGuestCity": "Lake Buena Vista",
		"mostGuestAmount": 43
	},
	{
		"id": "b1d974e",
		"email": "tonykopa@gmail.com",
		"name": "TONY",
		"createdDate": "2017-04-01 02:14:58",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 34,
		"totalCountries": 3,
		"totalStates": 6,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 256,
		"schedulePercent": 0.183684,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4208997",
		"email": "Jenna.johnson@wmg.com",
		"name": "Jenna",
		"createdDate": "2019-10-10 15:09:50",
		"totalOrganizations": 52,
		"totalTours": 45,
		"totalDays": 15358,
		"totalCountries": 15,
		"totalStates": 89,
		"totalEvents": 2012,
		"eventPercent": 0.997086,
		"totalScheduleItems": 1991,
		"schedulePercent": 0.717124,
		"totalGuests": 181,
		"guestPercent": 0.537147,
		"mostGuestDate": "2023-05-21 00:00:00",
		"mostGuestName": "George E. Potter Center",
		"mostGuestCity": "Jackson",
		"mostGuestAmount": 31
	},
	{
		"id": "bedc6ec",
		"email": "danny@waxmotif.com",
		"name": "Daniel",
		"createdDate": "2014-05-09 17:54:26",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 352,
		"totalCountries": 6,
		"totalStates": 31,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 152,
		"schedulePercent": 0.121971,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "436cec6",
		"email": "Natalie@westbethent.com",
		"name": "Natalie",
		"createdDate": "2016-03-24 19:43:49",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 110,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 703,
		"schedulePercent": 0.381671,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-09-11 00:00:00",
		"mostGuestName": "Warner Theatre",
		"mostGuestCity": "Washington",
		"mostGuestAmount": 2
	},
	{
		"id": "0d9feb3",
		"email": "brandon@breitgroup.com",
		"name": "Brandon",
		"createdDate": "2014-08-22 17:30:14",
		"totalOrganizations": 16,
		"totalTours": 27,
		"totalDays": 383,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 203,
		"eventPercent": 0.811681,
		"totalScheduleItems": 3320,
		"schedulePercent": 0.879883,
		"totalGuests": 2188,
		"guestPercent": 0.943054,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 293
	},
	{
		"id": "91ade57",
		"email": "Starlinsnd@gmail.com",
		"name": "Starlin",
		"createdDate": "2023-02-23 06:26:02",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 266,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 975,
		"schedulePercent": 0.474242,
		"totalGuests": 127,
		"guestPercent": 0.497153,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "Yankee Stadium",
		"mostGuestCity": "Bronx",
		"mostGuestAmount": 46
	},
	{
		"id": "6167ac4",
		"email": "james@jamesbundysound.com",
		"name": "James",
		"createdDate": "2022-03-12 05:53:14",
		"totalOrganizations": 10,
		"totalTours": 14,
		"totalDays": 588,
		"totalCountries": 4,
		"totalStates": 46,
		"totalEvents": 187,
		"eventPercent": 0.784135,
		"totalScheduleItems": 2631,
		"schedulePercent": 0.811945,
		"totalGuests": 616,
		"guestPercent": 0.730499,
		"mostGuestDate": "2023-05-02 00:00:00",
		"mostGuestName": "The Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 99
	},
	{
		"id": "f75449c",
		"email": "phyllisjwattis@gmail.com",
		"name": "Phyllis",
		"createdDate": "2022-09-26 04:03:55",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 408,
		"totalCountries": 14,
		"totalStates": 46,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 847,
		"schedulePercent": 0.436631,
		"totalGuests": 143,
		"guestPercent": 0.508277,
		"mostGuestDate": "2023-03-29 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 37
	},
	{
		"id": "4f03c79",
		"email": "taylor.niemi@wmg.com",
		"name": "Taylor",
		"createdDate": "2020-11-30 19:16:12",
		"totalOrganizations": 51,
		"totalTours": 44,
		"totalDays": 15011,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 1913,
		"eventPercent": 0.993908,
		"totalScheduleItems": 742,
		"schedulePercent": 0.397431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "211a24b",
		"email": "Jawsh@AudioRifle.com",
		"name": "Jawsh",
		"createdDate": "2011-03-05 15:58:23",
		"totalOrganizations": 35,
		"totalTours": 13,
		"totalDays": 737,
		"totalCountries": 13,
		"totalStates": 52,
		"totalEvents": 250,
		"eventPercent": 0.872335,
		"totalScheduleItems": 2534,
		"schedulePercent": 0.796848,
		"totalGuests": 980,
		"guestPercent": 0.819362,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 58
	},
	{
		"id": "822a02b",
		"email": "eric.shenyo@icloud.com",
		"name": "Eric",
		"createdDate": "2021-08-03 14:42:33",
		"totalOrganizations": 4,
		"totalTours": 19,
		"totalDays": 261,
		"totalCountries": 15,
		"totalStates": 59,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 3328,
		"schedulePercent": 0.880546,
		"totalGuests": 3785,
		"guestPercent": 0.981195,
		"mostGuestDate": "2023-04-21 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 156
	},
	{
		"id": "68c9201",
		"email": "jay@drelondon.com",
		"name": "Jay",
		"createdDate": "2019-01-07 03:27:53",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 357,
		"totalCountries": 15,
		"totalStates": 43,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 2595,
		"schedulePercent": 0.807045,
		"totalGuests": 5153,
		"guestPercent": 0.992584,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Glen Helen Amphitheater",
		"mostGuestCity": "San Bernardino",
		"mostGuestAmount": 250
	},
	{
		"id": "c277caa",
		"email": "Chrisboini@gmail.com",
		"name": "Chris",
		"createdDate": "2018-11-01 21:05:34",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 113,
		"totalCountries": 1,
		"totalStates": 24,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 726,
		"schedulePercent": 0.391074,
		"totalGuests": 35,
		"guestPercent": 0.396901,
		"mostGuestDate": "2023-09-26 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 21
	},
	{
		"id": "759a6a3",
		"email": "frank.scherk@upcoming-events.de",
		"name": "Frank",
		"createdDate": "2022-06-09 09:53:09",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 26,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 8,
		"eventPercent": 0.0535028,
		"totalScheduleItems": 127,
		"schedulePercent": 0.107535,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2b29738",
		"email": "pam@feelsrealmgmt.com",
		"name": "Pam",
		"createdDate": "2023-09-20 19:24:43",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 71,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 582,
		"schedulePercent": 0.332804,
		"totalGuests": 216,
		"guestPercent": 0.560058,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "El Rey Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 43
	},
	{
		"id": "a2fe24e",
		"email": "samanthasp90@gmail.com",
		"name": "Sami",
		"createdDate": "2022-10-12 12:57:51",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 136,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 530,
		"schedulePercent": 0.312939,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0cc66e7",
		"email": "cadyclaterbaugh@gmail.com",
		"name": "Cady",
		"createdDate": "2021-09-25 21:39:33",
		"totalOrganizations": 20,
		"totalTours": 22,
		"totalDays": 3507,
		"totalCountries": 15,
		"totalStates": 84,
		"totalEvents": 761,
		"eventPercent": 0.987419,
		"totalScheduleItems": 8195,
		"schedulePercent": 0.994173,
		"totalGuests": 1457,
		"guestPercent": 0.888756,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 64
	},
	{
		"id": "066640c",
		"email": "info@jordanstcyr.com",
		"name": "Jordan",
		"createdDate": "2021-10-26 03:39:08",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 411,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 687,
		"schedulePercent": 0.375447,
		"totalGuests": 46,
		"guestPercent": 0.415044,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Crosswater Community Church",
		"mostGuestCity": "Ponte Vedra Beach",
		"mostGuestAmount": 16
	},
	{
		"id": "ff83d8b",
		"email": "igrindmedia@gmail.com",
		"name": "Stephan",
		"createdDate": "2018-11-01 04:20:27",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 413,
		"totalCountries": 10,
		"totalStates": 40,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 1533,
		"schedulePercent": 0.625612,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1b02366",
		"email": "effimarie@gmail.com",
		"name": "Hallie",
		"createdDate": "2018-10-12 20:09:42",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 156,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 856,
		"schedulePercent": 0.439015,
		"totalGuests": 460,
		"guestPercent": 0.677659,
		"mostGuestDate": "2023-03-22 00:00:00",
		"mostGuestName": "The Factory in Deep Ellum",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 39
	},
	{
		"id": "b54cbb8",
		"email": "advance@dcLive.co.nz",
		"name": "Advance",
		"createdDate": "2023-06-20 00:37:30",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 53,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 354,
		"schedulePercent": 0.235598,
		"totalGuests": 50,
		"guestPercent": 0.421798,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "Ngaio Marsh Theatre",
		"mostGuestCity": "Christchurch",
		"mostGuestAmount": 21
	},
	{
		"id": "f735a72",
		"email": "jonsiebels@gmail.com",
		"name": "Jonathan",
		"createdDate": "2012-03-12 00:00:05",
		"totalOrganizations": 13,
		"totalTours": 3,
		"totalDays": 194,
		"totalCountries": 4,
		"totalStates": 31,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 737,
		"schedulePercent": 0.394385,
		"totalGuests": 152,
		"guestPercent": 0.515693,
		"mostGuestDate": "2023-09-12 00:00:00",
		"mostGuestName": "9:30 Club",
		"mostGuestCity": "Washington",
		"mostGuestAmount": 25
	},
	{
		"id": "9607777",
		"email": "tucker@jet-mgmt.com",
		"name": "Tucker ",
		"createdDate": "2021-06-02 02:11:08",
		"totalOrganizations": 6,
		"totalTours": 15,
		"totalDays": 760,
		"totalCountries": 14,
		"totalStates": 58,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 1413,
		"schedulePercent": 0.594358,
		"totalGuests": 540,
		"guestPercent": 0.705867,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "El Rey Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 83
	},
	{
		"id": "ead6261",
		"email": "music.gabrielle@gmail.com",
		"name": "Lisa",
		"createdDate": "2023-08-14 17:14:03",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 65,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 755,
		"schedulePercent": 0.404715,
		"totalGuests": 341,
		"guestPercent": 0.623758,
		"mostGuestDate": "2023-10-31 00:00:00",
		"mostGuestName": "Royal Albert Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 48
	},
	{
		"id": "da4d1c4",
		"email": "jessica.oliney@me.com",
		"name": "Jess",
		"createdDate": "2022-04-24 17:23:17",
		"totalOrganizations": 13,
		"totalTours": 21,
		"totalDays": 855,
		"totalCountries": 7,
		"totalStates": 40,
		"totalEvents": 280,
		"eventPercent": 0.901867,
		"totalScheduleItems": 3593,
		"schedulePercent": 0.899351,
		"totalGuests": 865,
		"guestPercent": 0.791286,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Barclays Center",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 76
	},
	{
		"id": "59a0405",
		"email": "carlosnovais@mac.com",
		"name": "Carlos",
		"createdDate": "2009-10-25 19:47:39",
		"totalOrganizations": 10,
		"totalTours": 6,
		"totalDays": 592,
		"totalCountries": 5,
		"totalStates": 56,
		"totalEvents": 172,
		"eventPercent": 0.755661,
		"totalScheduleItems": 2220,
		"schedulePercent": 0.753807,
		"totalGuests": 1484,
		"guestPercent": 0.8922,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 89
	},
	{
		"id": "fc7d440",
		"email": "karen@elevenmusic.com",
		"name": "Eleven",
		"createdDate": "2012-05-22 02:31:13",
		"totalOrganizations": 9,
		"totalTours": 25,
		"totalDays": 412,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 1168,
		"schedulePercent": 0.53185,
		"totalGuests": 322,
		"guestPercent": 0.614753,
		"mostGuestDate": "2023-09-01 00:00:00",
		"mostGuestName": "Dee Why RSL Club",
		"mostGuestCity": "Dee Why",
		"mostGuestAmount": 25
	},
	{
		"id": "48c7438",
		"email": "zuza@morsatouring.com",
		"name": "Zuza",
		"createdDate": "2023-09-17 18:01:18",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 34,
		"totalCountries": 5,
		"totalStates": 6,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 193,
		"schedulePercent": 0.145941,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "998fd19",
		"email": "devonhunter21@gmail.com",
		"name": "Devon",
		"createdDate": "2021-04-28 19:18:55",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 187,
		"totalCountries": 7,
		"totalStates": 32,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 2228,
		"schedulePercent": 0.754867,
		"totalGuests": 5030,
		"guestPercent": 0.992186,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Fenway Park",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 322
	},
	{
		"id": "d19c357",
		"email": "macklongmusic@gmail.com",
		"name": "Mack",
		"createdDate": "2013-09-17 17:49:21",
		"totalOrganizations": 8,
		"totalTours": 12,
		"totalDays": 348,
		"totalCountries": 10,
		"totalStates": 40,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1099,
		"schedulePercent": 0.511588,
		"totalGuests": 181,
		"guestPercent": 0.537147,
		"mostGuestDate": "2023-03-23 00:00:00",
		"mostGuestName": "The Bowery Ballroom",
		"mostGuestCity": "New York",
		"mostGuestAmount": 21
	},
	{
		"id": "4422d7e",
		"email": "zoelouvard.ei@gmail.com",
		"name": "Zoe",
		"createdDate": "2023-05-10 13:55:59",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 53,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 370,
		"schedulePercent": 0.243809,
		"totalGuests": 10,
		"guestPercent": 0.343531,
		"mostGuestDate": "2023-08-06 00:00:00",
		"mostGuestName": "Festival Du Bout Du Monde",
		"mostGuestCity": "Lanveoc",
		"mostGuestAmount": 10
	},
	{
		"id": "4cd8cf6",
		"email": "rhighcroft@gmail.com",
		"name": "Rob",
		"createdDate": "2019-09-12 02:30:15",
		"totalOrganizations": 8,
		"totalTours": 16,
		"totalDays": 719,
		"totalCountries": 17,
		"totalStates": 66,
		"totalEvents": 239,
		"eventPercent": 0.860681,
		"totalScheduleItems": 2816,
		"schedulePercent": 0.834327,
		"totalGuests": 333,
		"guestPercent": 0.619653,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Acrisure Arena",
		"mostGuestCity": "Palm Desert",
		"mostGuestAmount": 77
	},
	{
		"id": "3cdf144",
		"email": "bill@hankanded.com",
		"name": "Bill",
		"createdDate": "2018-01-05 03:57:31",
		"totalOrganizations": 1,
		"totalTours": 12,
		"totalDays": 256,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 3039,
		"schedulePercent": 0.854721,
		"totalGuests": 2200,
		"guestPercent": 0.943584,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "Pine Knob Music Theatre",
		"mostGuestCity": "Clarkston",
		"mostGuestAmount": 104
	},
	{
		"id": "0603440",
		"email": "jacobharelick@gmail.com",
		"name": "Jacob",
		"createdDate": "2022-03-28 22:58:22",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 619,
		"totalCountries": 9,
		"totalStates": 52,
		"totalEvents": 155,
		"eventPercent": 0.716197,
		"totalScheduleItems": 2331,
		"schedulePercent": 0.771156,
		"totalGuests": 535,
		"guestPercent": 0.704675,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 75
	},
	{
		"id": "a257a12",
		"email": "tobiasiddison@gmail.com",
		"name": "Tobias",
		"createdDate": "2012-01-10 17:27:50",
		"totalOrganizations": 22,
		"totalTours": 16,
		"totalDays": 254,
		"totalCountries": 5,
		"totalStates": 10,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 969,
		"schedulePercent": 0.471063,
		"totalGuests": 194,
		"guestPercent": 0.54602,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "930 Mateo Street",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 111
	},
	{
		"id": "9ab6259",
		"email": "steve.kahler@gmail.com",
		"name": "Stephen",
		"createdDate": "2015-01-12 20:20:44",
		"totalOrganizations": 15,
		"totalTours": 7,
		"totalDays": 444,
		"totalCountries": 2,
		"totalStates": 40,
		"totalEvents": 260,
		"eventPercent": 0.885975,
		"totalScheduleItems": 3595,
		"schedulePercent": 0.899616,
		"totalGuests": 1715,
		"guestPercent": 0.914316,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "State Farm Arena",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 133
	},
	{
		"id": "35c556e",
		"email": "anthony@statusmgmt.com",
		"name": "Anthony",
		"createdDate": "2022-11-18 04:14:34",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 171,
		"totalCountries": 12,
		"totalStates": 42,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 606,
		"schedulePercent": 0.343796,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Avondale Music Hall",
		"mostGuestCity": "Chicago ",
		"mostGuestAmount": 1
	},
	{
		"id": "95d6c50",
		"email": "jerryguidroz@hotmail.com",
		"name": "Jerry",
		"createdDate": "2014-08-15 19:16:25",
		"totalOrganizations": 8,
		"totalTours": 3,
		"totalDays": 348,
		"totalCountries": 13,
		"totalStates": 23,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 408,
		"schedulePercent": 0.260363,
		"totalGuests": 22,
		"guestPercent": 0.373725,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Klubben",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 20
	},
	{
		"id": "817cae7",
		"email": "jane@xb-xj.com",
		"name": "Jane",
		"createdDate": "2016-02-15 17:45:30",
		"totalOrganizations": 6,
		"totalTours": 26,
		"totalDays": 170,
		"totalCountries": 11,
		"totalStates": 24,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1364,
		"schedulePercent": 0.583896,
		"totalGuests": 70,
		"guestPercent": 0.445636,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 41
	},
	{
		"id": "8f0f6f0",
		"email": "todd.wallenbrock@gmail.com",
		"name": "Todd",
		"createdDate": "2016-01-26 19:05:30",
		"totalOrganizations": 20,
		"totalTours": 52,
		"totalDays": 322,
		"totalCountries": 5,
		"totalStates": 25,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 1517,
		"schedulePercent": 0.622434,
		"totalGuests": 60,
		"guestPercent": 0.434644,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Cervantes' Masterpiece Ballroom & Cervantes' Other Side",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 21
	},
	{
		"id": "cb0c241",
		"email": "iain@prodnorth.com",
		"name": "Iain",
		"createdDate": "2017-02-10 11:37:22",
		"totalOrganizations": 21,
		"totalTours": 9,
		"totalDays": 226,
		"totalCountries": 4,
		"totalStates": 11,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 1894,
		"schedulePercent": 0.703218,
		"totalGuests": 130,
		"guestPercent": 0.498874,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Motorpoint Arena",
		"mostGuestCity": "Nottingham",
		"mostGuestAmount": 20
	},
	{
		"id": "0c9b6b7",
		"email": "jon@accessallareas.pro",
		"name": "Jon",
		"createdDate": "2016-11-06 13:16:26",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 169,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 627,
		"schedulePercent": 0.352669,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-04-08 00:00:00",
		"mostGuestName": "MULTIESPACIO RABASA",
		"mostGuestCity": "Alacant",
		"mostGuestAmount": 3
	},
	{
		"id": "5fc15a5",
		"email": "george@mountainhighmanagement.com",
		"name": "George",
		"createdDate": "2019-04-10 03:52:32",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 137,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 1333,
		"schedulePercent": 0.57542,
		"totalGuests": 104,
		"guestPercent": 0.478877,
		"mostGuestDate": "2023-10-29 00:00:00",
		"mostGuestName": "Don Lucas Reserve",
		"mostGuestCity": "Cronulla",
		"mostGuestAmount": 32
	},
	{
		"id": "a655856",
		"email": "Panicpez@aol.com",
		"name": "Steve",
		"createdDate": "2009-07-06 20:34:26",
		"totalOrganizations": 12,
		"totalTours": 6,
		"totalDays": 136,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 733,
		"schedulePercent": 0.393325,
		"totalGuests": 11,
		"guestPercent": 0.345782,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "St. Augustine Amphitheatre",
		"mostGuestCity": "St. Augustine",
		"mostGuestAmount": 5
	},
	{
		"id": "f501657",
		"email": "mccormackaudio@gmail.com",
		"name": "Michael",
		"createdDate": "2018-02-08 21:16:10",
		"totalOrganizations": 10,
		"totalTours": 16,
		"totalDays": 1121,
		"totalCountries": 6,
		"totalStates": 46,
		"totalEvents": 541,
		"eventPercent": 0.978943,
		"totalScheduleItems": 4921,
		"schedulePercent": 0.959476,
		"totalGuests": 461,
		"guestPercent": 0.677923,
		"mostGuestDate": "2023-04-10 00:00:00",
		"mostGuestName": "The Fisher Center for the Performing Arts",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 139
	},
	{
		"id": "39c8706",
		"email": "twiddletourmanage@gmail.com",
		"name": "Alex",
		"createdDate": "2015-10-08 01:51:39",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 209,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1126,
		"schedulePercent": 0.518607,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "Denver Deluxe Festival",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 8
	},
	{
		"id": "e74eef7",
		"email": "david@dbdlive.com",
		"name": "David",
		"createdDate": "2018-06-14 10:53:38",
		"totalOrganizations": 11,
		"totalTours": 12,
		"totalDays": 1126,
		"totalCountries": 15,
		"totalStates": 33,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 1182,
		"schedulePercent": 0.535293,
		"totalGuests": 690,
		"guestPercent": 0.751026,
		"mostGuestDate": "2023-03-07 00:00:00",
		"mostGuestName": "NX Newcastle",
		"mostGuestCity": "Newcastle upon Tyne",
		"mostGuestAmount": 166
	},
	{
		"id": "91391a3",
		"email": "evabophoto@gmail.com",
		"name": "eva",
		"createdDate": "2023-05-22 12:51:54",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 99,
		"totalCountries": 12,
		"totalStates": 37,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 627,
		"schedulePercent": 0.352669,
		"totalGuests": 455,
		"guestPercent": 0.675805,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "Somerset House",
		"mostGuestCity": "London",
		"mostGuestAmount": 72
	},
	{
		"id": "03ecd88",
		"email": "Kasloco@me.com",
		"name": "Jonathan",
		"createdDate": "2014-10-31 18:33:47",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 72,
		"totalCountries": 5,
		"totalStates": 18,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 526,
		"schedulePercent": 0.310025,
		"totalGuests": 29,
		"guestPercent": 0.387896,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Liberty Hall",
		"mostGuestCity": "Moore Park",
		"mostGuestAmount": 24
	},
	{
		"id": "07cda54",
		"email": "JessicaLeAnneHill@gmail.com",
		"name": "Jessica",
		"createdDate": "2017-07-08 17:17:53",
		"totalOrganizations": 11,
		"totalTours": 20,
		"totalDays": 698,
		"totalCountries": 16,
		"totalStates": 76,
		"totalEvents": 393,
		"eventPercent": 0.955635,
		"totalScheduleItems": 6224,
		"schedulePercent": 0.982386,
		"totalGuests": 518,
		"guestPercent": 0.698053,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The Aztec Theatre",
		"mostGuestCity": "San Antonio",
		"mostGuestAmount": 70
	},
	{
		"id": "8444e04",
		"email": "vagosofia@gmail.com",
		"name": "Sofia",
		"createdDate": "2022-06-21 18:26:54",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 86,
		"totalCountries": 5,
		"totalStates": 11,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 460,
		"schedulePercent": 0.280625,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "dfba96b",
		"email": "jessicasbijvoet@gmail.com",
		"name": "Jessica",
		"createdDate": "2022-09-27 10:07:13",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 99,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 429,
		"schedulePercent": 0.267779,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2014f71",
		"email": "pimpgig@me.com",
		"name": "Oscar",
		"createdDate": "2018-01-19 05:01:49",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 175,
		"totalCountries": 7,
		"totalStates": 22,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 759,
		"schedulePercent": 0.405774,
		"totalGuests": 135,
		"guestPercent": 0.50245,
		"mostGuestDate": "2023-11-02 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 41
	},
	{
		"id": "52f15a0",
		"email": "diony1@me.com",
		"name": "Diony",
		"createdDate": "2017-01-15 15:50:18",
		"totalOrganizations": 7,
		"totalTours": 12,
		"totalDays": 930,
		"totalCountries": 19,
		"totalStates": 75,
		"totalEvents": 377,
		"eventPercent": 0.950073,
		"totalScheduleItems": 4727,
		"schedulePercent": 0.952854,
		"totalGuests": 1287,
		"guestPercent": 0.870348,
		"mostGuestDate": "2023-05-18 00:00:00",
		"mostGuestName": "Mohegan Sun Arena At Casey Plaza",
		"mostGuestCity": "Wilkes-Barre",
		"mostGuestAmount": 98
	},
	{
		"id": "8b09736",
		"email": "mar.grzeszczyk@gmail.com",
		"name": "Maria ",
		"createdDate": "2022-03-09 18:11:41",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 495,
		"totalCountries": 13,
		"totalStates": 30,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 1885,
		"schedulePercent": 0.702291,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c081fdb",
		"email": "katie.flood@opera.org.au",
		"name": "Katie",
		"createdDate": "2023-02-09 00:56:29",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 71,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 421,
		"schedulePercent": 0.264468,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "82f9ee0",
		"email": "anyaeligearhart@gmail.com",
		"name": "Anya",
		"createdDate": "2022-03-01 06:14:43",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 57,
		"totalCountries": 6,
		"totalStates": 9,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 318,
		"schedulePercent": 0.217322,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ab1d7d5",
		"email": "pauldougliao@me.com",
		"name": "Paul",
		"createdDate": "2016-09-05 09:45:17",
		"totalOrganizations": 19,
		"totalTours": 89,
		"totalDays": 515,
		"totalCountries": 10,
		"totalStates": 42,
		"totalEvents": 208,
		"eventPercent": 0.819891,
		"totalScheduleItems": 2135,
		"schedulePercent": 0.739902,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-04-24 00:00:00",
		"mostGuestName": "Forum Melbourne",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 6
	},
	{
		"id": "c48b249",
		"email": "maya@satanssystrar.se",
		"name": "Maya",
		"createdDate": "2019-01-21 15:15:49",
		"totalOrganizations": 15,
		"totalTours": 15,
		"totalDays": 214,
		"totalCountries": 19,
		"totalStates": 47,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 1285,
		"schedulePercent": 0.56125,
		"totalGuests": 752,
		"guestPercent": 0.765064,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Annexet",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 155
	},
	{
		"id": "b3d52dc",
		"email": "thebestcoasttn@gmail.com",
		"name": "Keith ",
		"createdDate": "2023-05-29 19:22:21",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 79,
		"totalCountries": 4,
		"totalStates": 26,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 262,
		"schedulePercent": 0.186465,
		"totalGuests": 16,
		"guestPercent": 0.361012,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "Dakota Cafe",
		"mostGuestCity": "Minneapolis",
		"mostGuestAmount": 9
	},
	{
		"id": "3b5d12e",
		"email": "pra@prarecords.com",
		"name": "Stephanie ",
		"createdDate": "2018-05-02 18:56:01",
		"totalOrganizations": 4,
		"totalTours": 12,
		"totalDays": 50,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 254,
		"schedulePercent": 0.182625,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "796ffee",
		"email": "siennagraceproductions@gmail.com",
		"name": "Sienna ",
		"createdDate": "2022-10-21 17:06:50",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 611,
		"totalCountries": 8,
		"totalStates": 41,
		"totalEvents": 190,
		"eventPercent": 0.788505,
		"totalScheduleItems": 2907,
		"schedulePercent": 0.842802,
		"totalGuests": 876,
		"guestPercent": 0.793537,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Shrine Auditorium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 76
	},
	{
		"id": "60659e9",
		"email": "jerryslone@aol.com",
		"name": "Jerry",
		"createdDate": "2016-03-29 21:48:59",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 368,
		"totalCountries": 9,
		"totalStates": 31,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 2088,
		"schedulePercent": 0.733148,
		"totalGuests": 3476,
		"guestPercent": 0.976559,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Raymond James Stadium",
		"mostGuestCity": "Tampa ",
		"mostGuestAmount": 248
	},
	{
		"id": "ca04f8b",
		"email": "One979@mac.com",
		"name": "Jeffrey ",
		"createdDate": "2014-06-18 16:53:54",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 245,
		"totalCountries": 13,
		"totalStates": 40,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 218,
		"schedulePercent": 0.1617,
		"totalGuests": 813,
		"guestPercent": 0.779235,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Banc of California Stadium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 133
	},
	{
		"id": "5e4645f",
		"email": "tina@acomicsoul.com",
		"name": "A Comic Soul",
		"createdDate": "2023-02-09 13:38:55",
		"totalOrganizations": 71,
		"totalTours": 61,
		"totalDays": 1026,
		"totalCountries": 25,
		"totalStates": 49,
		"totalEvents": 305,
		"eventPercent": 0.918554,
		"totalScheduleItems": 2259,
		"schedulePercent": 0.760429,
		"totalGuests": 109,
		"guestPercent": 0.481393,
		"mostGuestDate": "2023-08-16 00:00:00",
		"mostGuestName": "Boom Chicago",
		"mostGuestCity": "Amsterdam",
		"mostGuestAmount": 18
	},
	{
		"id": "df08332",
		"email": "tjsilljer@gmail.com",
		"name": "Tyler",
		"createdDate": "2015-02-23 17:07:56",
		"totalOrganizations": 13,
		"totalTours": 15,
		"totalDays": 945,
		"totalCountries": 21,
		"totalStates": 72,
		"totalEvents": 328,
		"eventPercent": 0.929943,
		"totalScheduleItems": 4293,
		"schedulePercent": 0.937492,
		"totalGuests": 2801,
		"guestPercent": 0.964508,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 240
	},
	{
		"id": "652d82f",
		"email": "melodymarieholley@gmail.com",
		"name": "Melody",
		"createdDate": "2023-10-25 21:37:18",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 173,
		"totalCountries": 4,
		"totalStates": 14,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 675,
		"schedulePercent": 0.370547,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "85d023e",
		"email": "sarah@sarahsfoster.com",
		"name": "Sarah",
		"createdDate": "2019-11-05 00:39:09",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 365,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 506,
		"schedulePercent": 0.301814,
		"totalGuests": 188,
		"guestPercent": 0.541518,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "aea78e6",
		"email": "kris@kriscampbell.com",
		"name": "Kristopher",
		"createdDate": "2019-06-19 11:15:31",
		"totalOrganizations": 5,
		"totalTours": 20,
		"totalDays": 493,
		"totalCountries": 8,
		"totalStates": 50,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 1712,
		"schedulePercent": 0.667991,
		"totalGuests": 888,
		"guestPercent": 0.796451,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "The Strand Ballroom",
		"mostGuestCity": "Providence",
		"mostGuestAmount": 48
	},
	{
		"id": "de7f3bc",
		"email": "rileytouring@gmail.com",
		"name": "Riley ",
		"createdDate": "2021-07-22 02:13:41",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 977,
		"totalCountries": 15,
		"totalStates": 51,
		"totalEvents": 162,
		"eventPercent": 0.732883,
		"totalScheduleItems": 2479,
		"schedulePercent": 0.790359,
		"totalGuests": 653,
		"guestPercent": 0.739902,
		"mostGuestDate": "2023-05-19 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 59
	},
	{
		"id": "2f8ac08",
		"email": "steve@coplancreativeconsulting.com",
		"name": "Steve",
		"createdDate": "2023-08-01 20:21:04",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 180,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 326,
		"schedulePercent": 0.222487,
		"totalGuests": 110,
		"guestPercent": 0.482453,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Jubilee Auditorium",
		"mostGuestCity": "Calgary",
		"mostGuestAmount": 64
	},
	{
		"id": "3d722b4",
		"email": "mcintosh.kieran@gmail.com",
		"name": "Kieran",
		"createdDate": "2016-09-13 15:47:13",
		"totalOrganizations": 10,
		"totalTours": 9,
		"totalDays": 954,
		"totalCountries": 13,
		"totalStates": 56,
		"totalEvents": 232,
		"eventPercent": 0.852073,
		"totalScheduleItems": 4679,
		"schedulePercent": 0.951132,
		"totalGuests": 2043,
		"guestPercent": 0.936432,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 104
	},
	{
		"id": "d744a24",
		"email": "bktouring615@gmail.com",
		"name": "Miles ",
		"createdDate": "2023-01-31 18:38:59",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 263,
		"totalCountries": 2,
		"totalStates": 36,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 1281,
		"schedulePercent": 0.560588,
		"totalGuests": 1889,
		"guestPercent": 0.927824,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 298
	},
	{
		"id": "1ca83f6",
		"email": "mjlenderman99@gmail.com",
		"name": "MJ",
		"createdDate": "2022-04-04 12:20:06",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 214,
		"totalCountries": 10,
		"totalStates": 46,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 993,
		"schedulePercent": 0.482188,
		"totalGuests": 487,
		"guestPercent": 0.686929,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Union Transfer",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 36
	},
	{
		"id": "909f917",
		"email": "lanny@brilliantcorners.com",
		"name": "Lanny",
		"createdDate": "2022-03-16 01:23:44",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 154,
		"totalCountries": 9,
		"totalStates": 46,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1763,
		"schedulePercent": 0.677129,
		"totalGuests": 2111,
		"guestPercent": 0.939875,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 171
	},
	{
		"id": "5e4b34f",
		"email": "hello@undertowmusic.com",
		"name": "1",
		"createdDate": "2018-01-04 17:25:43",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 54,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 409,
		"schedulePercent": 0.261157,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-04-11 00:00:00",
		"mostGuestName": "The Waiting Room Lounge",
		"mostGuestCity": "Omaha",
		"mostGuestAmount": 2
	},
	{
		"id": "37d745d",
		"email": "frank.engelhardt@nena-musikverlag.de",
		"name": "Frank",
		"createdDate": "2019-05-04 12:29:57",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 93,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 642,
		"schedulePercent": 0.359423,
		"totalGuests": 524,
		"guestPercent": 0.699775,
		"mostGuestDate": "2023-07-01 00:00:00",
		"mostGuestName": "Stadtpark",
		"mostGuestCity": "Hamburg",
		"mostGuestAmount": 123
	},
	{
		"id": "023b2ff",
		"email": "quinnmsheppard@gmail.com",
		"name": "Quinn",
		"createdDate": "2015-09-19 21:37:24",
		"totalOrganizations": 7,
		"totalTours": 14,
		"totalDays": 460,
		"totalCountries": 7,
		"totalStates": 49,
		"totalEvents": 259,
		"eventPercent": 0.884783,
		"totalScheduleItems": 3652,
		"schedulePercent": 0.903986,
		"totalGuests": 1453,
		"guestPercent": 0.887962,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 147
	},
	{
		"id": "c5a0b81",
		"email": "james@xleventlab.com",
		"name": "James",
		"createdDate": "2023-07-21 14:49:15",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 440,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 263,
		"schedulePercent": 0.187922,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "49e0199",
		"email": "celishamusic@gmail.com",
		"name": "Casey ",
		"createdDate": "2019-06-18 20:59:34",
		"totalOrganizations": 19,
		"totalTours": 27,
		"totalDays": 1025,
		"totalCountries": 15,
		"totalStates": 70,
		"totalEvents": 307,
		"eventPercent": 0.919216,
		"totalScheduleItems": 3465,
		"schedulePercent": 0.891802,
		"totalGuests": 723,
		"guestPercent": 0.758178,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 53
	},
	{
		"id": "e78a1ea",
		"email": "jps@produisons.com",
		"name": "Jean Paul",
		"createdDate": "2015-11-19 20:52:02",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 997,
		"totalCountries": 9,
		"totalStates": 33,
		"totalEvents": 261,
		"eventPercent": 0.886902,
		"totalScheduleItems": 3870,
		"schedulePercent": 0.916303,
		"totalGuests": 600,
		"guestPercent": 0.726261,
		"mostGuestDate": "2023-02-25 00:00:00",
		"mostGuestName": "Casino du Lac-Leamy",
		"mostGuestCity": "Gatineau",
		"mostGuestAmount": 37
	},
	{
		"id": "6476fab",
		"email": "sanchia@allenholdings.co",
		"name": "Malcolm",
		"createdDate": "2023-02-24 04:16:21",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 304,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 578,
		"schedulePercent": 0.331082,
		"totalGuests": 248,
		"guestPercent": 0.577804,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "The Venue @ Horseshoe Casino",
		"mostGuestCity": "Hammond",
		"mostGuestAmount": 35
	},
	{
		"id": "89c4f45",
		"email": "noth.christoph@gmail.com",
		"name": "Christoph",
		"createdDate": "2018-05-10 19:08:59",
		"totalOrganizations": 12,
		"totalTours": 21,
		"totalDays": 192,
		"totalCountries": 16,
		"totalStates": 49,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 1379,
		"schedulePercent": 0.58681,
		"totalGuests": 303,
		"guestPercent": 0.604158,
		"mostGuestDate": "2023-03-22 00:00:00",
		"mostGuestName": "L'Olympia",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 101
	},
	{
		"id": "0f61f2f",
		"email": "Brendandreaper@gmail.com",
		"name": "Brendan",
		"createdDate": "2019-10-10 01:42:46",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 180,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 639,
		"schedulePercent": 0.357966,
		"totalGuests": 328,
		"guestPercent": 0.617667,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "The Dell Music Center",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 61
	},
	{
		"id": "02310cc",
		"email": "nickwaterhousetm@gmail.com",
		"name": "Nicholas",
		"createdDate": "2023-08-09 21:52:20",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 76,
		"totalCountries": 7,
		"totalStates": 19,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 250,
		"schedulePercent": 0.181565,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9c3d4cd",
		"email": "wadekwalters@gmail.com",
		"name": "Wade",
		"createdDate": "2018-02-14 02:58:03",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 83,
		"totalCountries": 1,
		"totalStates": 29,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 492,
		"schedulePercent": 0.293604,
		"totalGuests": 154,
		"guestPercent": 0.51662,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "The Beacham",
		"mostGuestCity": "Orlando",
		"mostGuestAmount": 21
	},
	{
		"id": "3cafce8",
		"email": "brentbuvick@gmail.com",
		"name": "Brent",
		"createdDate": "2022-11-23 21:06:03",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 198,
		"totalCountries": 10,
		"totalStates": 22,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 969,
		"schedulePercent": 0.471063,
		"totalGuests": 622,
		"guestPercent": 0.731956,
		"mostGuestDate": "2023-09-22 00:00:00",
		"mostGuestName": "Wynn // DJ SET",
		"mostGuestCity": "Las Vegas,",
		"mostGuestAmount": 66
	},
	{
		"id": "8154a34",
		"email": "jtrabue@gmail.com",
		"name": "Jason",
		"createdDate": "2012-07-08 02:05:04",
		"totalOrganizations": 13,
		"totalTours": 9,
		"totalDays": 895,
		"totalCountries": 10,
		"totalStates": 44,
		"totalEvents": 321,
		"eventPercent": 0.9265,
		"totalScheduleItems": 3841,
		"schedulePercent": 0.914846,
		"totalGuests": 894,
		"guestPercent": 0.798702,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "Allegiant Stadium",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 66
	},
	{
		"id": "e3bf460",
		"email": "RobertMcLeod97@gmail.com",
		"name": "Robbie",
		"createdDate": "2017-05-30 19:48:34",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 64,
		"totalCountries": 11,
		"totalStates": 14,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 207,
		"schedulePercent": 0.154417,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f99dc18",
		"email": "allison@aedesent.com",
		"name": "Allison",
		"createdDate": "2022-10-08 20:09:37",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 216,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 196,
		"schedulePercent": 0.147663,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cae1095",
		"email": "acepiva@gmail.com",
		"name": "Ace ",
		"createdDate": "2012-01-18 17:11:07",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 1016,
		"totalCountries": 4,
		"totalStates": 24,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 836,
		"schedulePercent": 0.433188,
		"totalGuests": 155,
		"guestPercent": 0.51768,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "Town Ballroom",
		"mostGuestCity": "Buffalo",
		"mostGuestAmount": 32
	},
	{
		"id": "efda2c4",
		"email": "amanda.marie.hellsten@gmail.com",
		"name": "Amanda",
		"createdDate": "2023-10-19 14:47:04",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 33,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 165,
		"schedulePercent": 0.129917,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Nordichallen",
		"mostGuestCity": "Sundsvall",
		"mostGuestAmount": 2
	},
	{
		"id": "fdd0d49",
		"email": "jon@productionstuff.co.uk",
		"name": "Jonathan",
		"createdDate": "2022-05-20 11:19:50",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 168,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 123,
		"schedulePercent": 0.105019,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b6b3c07",
		"email": "daniel.mirchevski@allthingslive.se",
		"name": "Daniel",
		"createdDate": "2022-02-09 13:02:31",
		"totalOrganizations": 20,
		"totalTours": 16,
		"totalDays": 189,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 752,
		"schedulePercent": 0.402861,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "88fee69",
		"email": "billyjoe22@hotmail.com",
		"name": "Billy Joe",
		"createdDate": "2014-04-11 22:03:26",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 337,
		"totalCountries": 9,
		"totalStates": 28,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1190,
		"schedulePercent": 0.537942,
		"totalGuests": 276,
		"guestPercent": 0.592902,
		"mostGuestDate": "2023-05-09 00:00:00",
		"mostGuestName": "Islington Assembly Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 29
	},
	{
		"id": "8867a5b",
		"email": "kirstie@liaisontribe.com",
		"name": "Kirstie",
		"createdDate": "2022-10-03 15:28:26",
		"totalOrganizations": 4,
		"totalTours": 12,
		"totalDays": 222,
		"totalCountries": 9,
		"totalStates": 44,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 1733,
		"schedulePercent": 0.671567,
		"totalGuests": 360,
		"guestPercent": 0.635015,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Orpheum Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 55
	},
	{
		"id": "b30df29",
		"email": "kerinec.manu@gmail.com",
		"name": "Manu",
		"createdDate": "2017-12-08 13:27:19",
		"totalOrganizations": 4,
		"totalTours": 34,
		"totalDays": 525,
		"totalCountries": 20,
		"totalStates": 93,
		"totalEvents": 360,
		"eventPercent": 0.946497,
		"totalScheduleItems": 5707,
		"schedulePercent": 0.976427,
		"totalGuests": 1221,
		"guestPercent": 0.86121,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Royal Festival Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 35
	},
	{
		"id": "4f37feb",
		"email": "music.jordanharvey@gmail.com",
		"name": "Jordan ",
		"createdDate": "2023-05-17 22:08:24",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 228,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 441,
		"schedulePercent": 0.272944,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8521dfd",
		"email": "eddro42@gmail.com",
		"name": "Eddie",
		"createdDate": "2011-06-05 18:51:36",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 268,
		"totalCountries": 6,
		"totalStates": 40,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 1183,
		"schedulePercent": 0.535558,
		"totalGuests": 764,
		"guestPercent": 0.76758,
		"mostGuestDate": "2023-11-26 00:00:00",
		"mostGuestName": "Toyota Arena",
		"mostGuestCity": "Ontario",
		"mostGuestAmount": 176
	},
	{
		"id": "2f762e6",
		"email": "welshie_touring@me.com",
		"name": "Welshie",
		"createdDate": "2012-01-05 18:35:40",
		"totalOrganizations": 8,
		"totalTours": 26,
		"totalDays": 535,
		"totalCountries": 17,
		"totalStates": 66,
		"totalEvents": 206,
		"eventPercent": 0.816183,
		"totalScheduleItems": 3832,
		"schedulePercent": 0.914316,
		"totalGuests": 1536,
		"guestPercent": 0.898159,
		"mostGuestDate": "2023-10-31 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 85
	},
	{
		"id": "78264d0",
		"email": "hayleyc@f2entertainmentgroup.com",
		"name": "Hayley",
		"createdDate": "2018-11-29 20:46:10",
		"totalOrganizations": 7,
		"totalTours": 4,
		"totalDays": 756,
		"totalCountries": 1,
		"totalStates": 22,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 497,
		"schedulePercent": 0.296914,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "90e219b",
		"email": "benn_newby@me.com",
		"name": "Benn",
		"createdDate": "2013-05-21 21:55:42",
		"totalOrganizations": 31,
		"totalTours": 87,
		"totalDays": 1873,
		"totalCountries": 18,
		"totalStates": 74,
		"totalEvents": 539,
		"eventPercent": 0.978546,
		"totalScheduleItems": 6387,
		"schedulePercent": 0.984373,
		"totalGuests": 1472,
		"guestPercent": 0.890478,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 243
	},
	{
		"id": "015c19a",
		"email": "info@oldbullmgmt.com",
		"name": "Old",
		"createdDate": "2021-10-18 15:49:45",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 919,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 3101,
		"schedulePercent": 0.861343,
		"totalGuests": 2000,
		"guestPercent": 0.933386,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 137
	},
	{
		"id": "6dd2c25",
		"email": "davidwhastings@gmail.com",
		"name": "David",
		"createdDate": "2018-01-05 23:05:44",
		"totalOrganizations": 7,
		"totalTours": 12,
		"totalDays": 780,
		"totalCountries": 12,
		"totalStates": 72,
		"totalEvents": 360,
		"eventPercent": 0.946497,
		"totalScheduleItems": 3669,
		"schedulePercent": 0.905178,
		"totalGuests": 919,
		"guestPercent": 0.803999,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 50
	},
	{
		"id": "2c2e3a5",
		"email": "kari.makimartti@gmail.com",
		"name": "Kari",
		"createdDate": "2017-05-11 17:55:15",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 178,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 173,
		"eventPercent": 0.757516,
		"totalScheduleItems": 737,
		"schedulePercent": 0.394385,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Suvilahti",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 11
	},
	{
		"id": "761d8ca",
		"email": "kevin@die-da.de",
		"name": "Kevin",
		"createdDate": "2023-04-03 14:56:47",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 65,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 791,
		"schedulePercent": 0.417296,
		"totalGuests": 1102,
		"guestPercent": 0.839756,
		"mostGuestDate": "2023-07-19 00:00:00",
		"mostGuestName": "Schlossplatz Stuttgart",
		"mostGuestCity": "Stuttgart",
		"mostGuestAmount": 214
	},
	{
		"id": "597185d",
		"email": "adam@tandemmgmt.co",
		"name": "Adam",
		"createdDate": "2021-10-28 23:16:11",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 238,
		"totalCountries": 2,
		"totalStates": 40,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 2167,
		"schedulePercent": 0.745332,
		"totalGuests": 930,
		"guestPercent": 0.806913,
		"mostGuestDate": "2023-08-29 00:00:00",
		"mostGuestName": "Riverside Municipal Auditorium",
		"mostGuestCity": "Riverside",
		"mostGuestAmount": 64
	},
	{
		"id": "2596027",
		"email": "frozensoulmetal@gmail.com",
		"name": "Frozen",
		"createdDate": "2023-02-01 07:03:55",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 92,
		"totalCountries": 2,
		"totalStates": 36,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 910,
		"schedulePercent": 0.454907,
		"totalGuests": 914,
		"guestPercent": 0.802278,
		"mostGuestDate": "2023-05-11 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 76
	},
	{
		"id": "0908ba6",
		"email": "hilary@jensenartist.com",
		"name": "Hilary",
		"createdDate": "2019-02-07 19:01:09",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 732,
		"totalCountries": 5,
		"totalStates": 40,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1786,
		"schedulePercent": 0.682294,
		"totalGuests": 274,
		"guestPercent": 0.590783,
		"mostGuestDate": "2023-04-08 00:00:00",
		"mostGuestName": "Wild Horse Pass Hotel & Casino",
		"mostGuestCity": "Chandler",
		"mostGuestAmount": 38
	},
	{
		"id": "54e4fca",
		"email": "jack@jmcaudio.co.uk",
		"name": "Jack",
		"createdDate": "2013-10-05 15:36:50",
		"totalOrganizations": 18,
		"totalTours": 30,
		"totalDays": 840,
		"totalCountries": 16,
		"totalStates": 53,
		"totalEvents": 192,
		"eventPercent": 0.791683,
		"totalScheduleItems": 2232,
		"schedulePercent": 0.755397,
		"totalGuests": 1090,
		"guestPercent": 0.837373,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "a784e42",
		"email": "manager@chuckbriseno.com",
		"name": "CHARLES",
		"createdDate": "2020-02-01 20:52:41",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 897,
		"schedulePercent": 0.452523,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "402b3cc",
		"email": "Theport74@gmail.com",
		"name": "Richard",
		"createdDate": "2022-04-20 09:57:01",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 363,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 414,
		"schedulePercent": 0.262482,
		"totalGuests": 256,
		"guestPercent": 0.582307,
		"mostGuestDate": "2023-05-12 00:00:00",
		"mostGuestName": "O2 Academy Leeds",
		"mostGuestCity": "",
		"mostGuestAmount": 31
	},
	{
		"id": "8c93664",
		"email": "mia@innergamemgmt.com",
		"name": "Mia",
		"createdDate": "2019-11-21 19:57:00",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 107,
		"totalCountries": 8,
		"totalStates": 19,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 615,
		"schedulePercent": 0.347504,
		"totalGuests": 249,
		"guestPercent": 0.578334,
		"mostGuestDate": "2023-04-25 00:00:00",
		"mostGuestName": "Lafayette",
		"mostGuestCity": "London",
		"mostGuestAmount": 32
	},
	{
		"id": "26f5793",
		"email": "Shannon.Cornett@gmail.com",
		"name": "Shannon",
		"createdDate": "2011-09-30 05:37:55",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 159,
		"totalCountries": 9,
		"totalStates": 29,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 680,
		"schedulePercent": 0.372533,
		"totalGuests": 95,
		"guestPercent": 0.470269,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Queen Elizabeth Theatre",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 33
	},
	{
		"id": "3737a87",
		"email": "rltouchette@gmail.com",
		"name": "Ross",
		"createdDate": "2015-09-11 22:51:57",
		"totalOrganizations": 12,
		"totalTours": 4,
		"totalDays": 765,
		"totalCountries": 5,
		"totalStates": 53,
		"totalEvents": 167,
		"eventPercent": 0.745332,
		"totalScheduleItems": 2326,
		"schedulePercent": 0.769964,
		"totalGuests": 2011,
		"guestPercent": 0.934181,
		"mostGuestDate": "2023-12-09 00:00:00",
		"mostGuestName": "The Orion Amphitheater",
		"mostGuestCity": "Huntsville",
		"mostGuestAmount": 109
	},
	{
		"id": "1771a9d",
		"email": "cececoakley@icloud.com",
		"name": "Cece",
		"createdDate": "2022-03-17 00:48:00",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 202,
		"totalCountries": 3,
		"totalStates": 33,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 971,
		"schedulePercent": 0.47252,
		"totalGuests": 149,
		"guestPercent": 0.513972,
		"mostGuestDate": "2023-02-16 00:00:00",
		"mostGuestName": "Irving Plaza",
		"mostGuestCity": "New York",
		"mostGuestAmount": 42
	},
	{
		"id": "5388742",
		"email": "sarah@unitedvision.se",
		"name": "Sarah",
		"createdDate": "2018-03-28 09:45:14",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 198,
		"totalCountries": 5,
		"totalStates": 20,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 953,
		"schedulePercent": 0.466826,
		"totalGuests": 318,
		"guestPercent": 0.613031,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Slottsskogsvallen",
		"mostGuestCity": "GÃ¶teborg",
		"mostGuestAmount": 72
	},
	{
		"id": "6af73dc",
		"email": "morten.bakke@bary.no",
		"name": "Morten",
		"createdDate": "2015-07-25 07:43:31",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 71,
		"totalCountries": 11,
		"totalStates": 16,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 652,
		"schedulePercent": 0.362998,
		"totalGuests": 869,
		"guestPercent": 0.791816,
		"mostGuestDate": "2023-05-07 00:00:00",
		"mostGuestName": "O2 Academy Bristol",
		"mostGuestCity": "Bristol",
		"mostGuestAmount": 132
	},
	{
		"id": "1bf9d5d",
		"email": "Ruralsultan@gmail.com",
		"name": "Travis",
		"createdDate": "2017-04-15 01:12:44",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 78,
		"totalCountries": 1,
		"totalStates": 26,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 311,
		"schedulePercent": 0.214144,
		"totalGuests": 46,
		"guestPercent": 0.415044,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "The Salt Shed",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 16
	},
	{
		"id": "7aab559",
		"email": "sdutcher@jerseysurf.org",
		"name": "Stephanie",
		"createdDate": "2016-02-07 03:17:51",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 52,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 13,
		"eventPercent": 0.0892597,
		"totalScheduleItems": 560,
		"schedulePercent": 0.325652,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c44141d",
		"email": "ultmt.music@gmail.com",
		"name": "Marcus",
		"createdDate": "2023-07-20 21:09:32",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 138,
		"totalCountries": 1,
		"totalStates": 25,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 61,
		"schedulePercent": 0.0622434,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "86cf344",
		"email": "avgemmingen@web.de",
		"name": "Alexandra",
		"createdDate": "2021-11-25 22:57:34",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 89,
		"totalCountries": 11,
		"totalStates": 16,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 829,
		"schedulePercent": 0.430009,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5f54fe3",
		"email": "daniel@kohai.com",
		"name": "Daniel",
		"createdDate": "2022-01-26 22:58:02",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 243,
		"totalCountries": 8,
		"totalStates": 31,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 1156,
		"schedulePercent": 0.528672,
		"totalGuests": 465,
		"guestPercent": 0.678718,
		"mostGuestDate": "2023-01-25 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 67
	},
	{
		"id": "01a70f6",
		"email": "felix@subversiveinc.com",
		"name": "Felix",
		"createdDate": "2010-10-19 18:23:38",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 82,
		"totalCountries": 9,
		"totalStates": 21,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 366,
		"schedulePercent": 0.24116,
		"totalGuests": 176,
		"guestPercent": 0.533969,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Carre Des Docks",
		"mostGuestCity": "Le Havre",
		"mostGuestAmount": 30
	},
	{
		"id": "8130d76",
		"email": "samhanson@morsatouring.com",
		"name": "Sam",
		"createdDate": "2018-09-14 15:19:25",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 61,
		"totalCountries": 6,
		"totalStates": 9,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 310,
		"schedulePercent": 0.213349,
		"totalGuests": 539,
		"guestPercent": 0.705602,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "Avant Gardner EARLY SHOW",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 101
	},
	{
		"id": "6678cd8",
		"email": "gareth@queenforever.com.au",
		"name": "Gareth",
		"createdDate": "2022-06-12 03:03:44",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 44,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 15,
		"schedulePercent": 0.0276785,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-05-27 00:00:00",
		"mostGuestName": "The Palms at Crown",
		"mostGuestCity": "Southbank",
		"mostGuestAmount": 14
	},
	{
		"id": "470daec",
		"email": "richietaylor@hotmail.co.uk",
		"name": "Richie",
		"createdDate": "2014-02-11 11:02:50",
		"totalOrganizations": 22,
		"totalTours": 42,
		"totalDays": 1021,
		"totalCountries": 16,
		"totalStates": 60,
		"totalEvents": 214,
		"eventPercent": 0.829559,
		"totalScheduleItems": 2512,
		"schedulePercent": 0.794994,
		"totalGuests": 893,
		"guestPercent": 0.797908,
		"mostGuestDate": "2023-11-02 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 81
	},
	{
		"id": "82d40b8",
		"email": "leigh@maddjett.com",
		"name": "Leigh",
		"createdDate": "2015-09-22 21:38:52",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 364,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 2710,
		"schedulePercent": 0.820686,
		"totalGuests": 1653,
		"guestPercent": 0.907032,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Raising Cane's River Center",
		"mostGuestCity": "Baton Rouge",
		"mostGuestAmount": 122
	},
	{
		"id": "1368869",
		"email": "lva@produisons.com",
		"name": "Luc",
		"createdDate": "2016-01-07 15:10:51",
		"totalOrganizations": 6,
		"totalTours": 9,
		"totalDays": 476,
		"totalCountries": 9,
		"totalStates": 28,
		"totalEvents": 260,
		"eventPercent": 0.885975,
		"totalScheduleItems": 2836,
		"schedulePercent": 0.836578,
		"totalGuests": 509,
		"guestPercent": 0.695007,
		"mostGuestDate": "2023-02-25 00:00:00",
		"mostGuestName": "Casino du Lac-Leamy",
		"mostGuestCity": "Gatineau",
		"mostGuestAmount": 37
	},
	{
		"id": "a406bf9",
		"email": "info@riverjuke.co.uk",
		"name": "Harry",
		"createdDate": "2019-01-08 13:55:48",
		"totalOrganizations": 33,
		"totalTours": 30,
		"totalDays": 2202,
		"totalCountries": 24,
		"totalStates": 88,
		"totalEvents": 515,
		"eventPercent": 0.975897,
		"totalScheduleItems": 9400,
		"schedulePercent": 0.996424,
		"totalGuests": 3138,
		"guestPercent": 0.971659,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "The SSE Arena, Wembley",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 226
	},
	{
		"id": "7b5cd95",
		"email": "regina@lostandfound.agency",
		"name": "Regina",
		"createdDate": "2023-01-05 03:37:51",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 217,
		"totalCountries": 10,
		"totalStates": 47,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 1165,
		"schedulePercent": 0.530526,
		"totalGuests": 487,
		"guestPercent": 0.686929,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Union Transfer",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 36
	},
	{
		"id": "9659ae1",
		"email": "jordanleetito@gmail.com",
		"name": "Jordan",
		"createdDate": "2023-05-14 12:27:26",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 99,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 605,
		"schedulePercent": 0.343531,
		"totalGuests": 28,
		"guestPercent": 0.386306,
		"mostGuestDate": "2023-10-25 00:00:00",
		"mostGuestName": "Commodore Ballroom",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 14
	},
	{
		"id": "d2a7b31",
		"email": "georg.weissmair@hotmail.com",
		"name": "Georg",
		"createdDate": "2022-04-09 07:25:16",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 210,
		"totalCountries": 8,
		"totalStates": 25,
		"totalEvents": 133,
		"eventPercent": 0.656602,
		"totalScheduleItems": 1253,
		"schedulePercent": 0.553569,
		"totalGuests": 358,
		"guestPercent": 0.634221,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Arena Wien",
		"mostGuestCity": "Vienna",
		"mostGuestAmount": 67
	},
	{
		"id": "034578d",
		"email": "gelhc.booking@gmail.com",
		"name": "Gel",
		"createdDate": "2022-12-02 22:09:48",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 117,
		"totalCountries": 2,
		"totalStates": 41,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 824,
		"schedulePercent": 0.427758,
		"totalGuests": 818,
		"guestPercent": 0.779764,
		"mostGuestDate": "2023-06-08 00:00:00",
		"mostGuestName": "Brooklyn Monarch",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 80
	},
	{
		"id": "dde3c3b",
		"email": "hierodomino@gmail.com",
		"name": "Domino",
		"createdDate": "2013-09-06 16:27:25",
		"totalOrganizations": 9,
		"totalTours": 2,
		"totalDays": 209,
		"totalCountries": 2,
		"totalStates": 33,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 365,
		"schedulePercent": 0.24063,
		"totalGuests": 221,
		"guestPercent": 0.563634,
		"mostGuestDate": "2023-06-19 00:00:00",
		"mostGuestName": "The Brooklyn Monarch",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 69
	},
	{
		"id": "43b52fa",
		"email": "zoux@me.com",
		"name": "Zoux",
		"createdDate": "2016-02-02 00:48:23",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 65,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 256,
		"schedulePercent": 0.183684,
		"totalGuests": 65,
		"guestPercent": 0.440207,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Patchogue Theatre for the Performing Arts",
		"mostGuestCity": "Patchogue",
		"mostGuestAmount": 20
	},
	{
		"id": "16a9181",
		"email": "tysonroyle@gmail.com",
		"name": "TYSON",
		"createdDate": "2014-09-26 03:25:44",
		"totalOrganizations": 42,
		"totalTours": 26,
		"totalDays": 649,
		"totalCountries": 8,
		"totalStates": 36,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 2255,
		"schedulePercent": 0.759767,
		"totalGuests": 221,
		"guestPercent": 0.563634,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "Hindley Street Music Hall",
		"mostGuestCity": "Adelaide",
		"mostGuestAmount": 42
	},
	{
		"id": "5df31f1",
		"email": "ainserra@cityparksfoundation.org",
		"name": "Antonella",
		"createdDate": "2019-07-15 17:08:58",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b88c08b",
		"email": "racheladispenza@gmail.com",
		"name": "Rachel",
		"createdDate": "2017-01-27 18:58:14",
		"totalOrganizations": 4,
		"totalTours": 14,
		"totalDays": 589,
		"totalCountries": 7,
		"totalStates": 41,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 2053,
		"schedulePercent": 0.728248,
		"totalGuests": 139,
		"guestPercent": 0.505364,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Walt Disney Concert Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 39
	},
	{
		"id": "1c592ae",
		"email": "eric@lighterair.com",
		"name": "Eric",
		"createdDate": "2022-01-24 17:59:37",
		"totalOrganizations": 9,
		"totalTours": 15,
		"totalDays": 338,
		"totalCountries": 7,
		"totalStates": 28,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 1392,
		"schedulePercent": 0.589458,
		"totalGuests": 1166,
		"guestPercent": 0.852205,
		"mostGuestDate": "2023-03-09 00:00:00",
		"mostGuestName": "La Sala Rossa",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 70
	},
	{
		"id": "6887505",
		"email": "samanthajpauly@gmail.com",
		"name": "Samantha",
		"createdDate": "2014-06-04 19:49:18",
		"totalOrganizations": 11,
		"totalTours": 2,
		"totalDays": 322,
		"totalCountries": 11,
		"totalStates": 38,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 2023,
		"schedulePercent": 0.723878,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3bdfef4",
		"email": "zangientertainment@gmail.com",
		"name": "",
		"createdDate": "2023-10-20 03:37:47",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 39,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 209,
		"schedulePercent": 0.155609,
		"totalGuests": 154,
		"guestPercent": 0.51662,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Masonic Lodge at Hollywood Forever",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 50
	},
	{
		"id": "27bd26a",
		"email": "ronartisii@gmail.com",
		"name": "Ron",
		"createdDate": "2022-05-30 17:42:41",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 102,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 56,
		"schedulePercent": 0.0589326,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9a3efee",
		"email": "cynaminjones@gmail.com",
		"name": "Jeantte",
		"createdDate": "2023-09-24 18:57:34",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 98,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 197,
		"schedulePercent": 0.148325,
		"totalGuests": 1208,
		"guestPercent": 0.858297,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Grand Garden Arena",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 161
	},
	{
		"id": "4613e52",
		"email": "sarah4jvke@gmail.com",
		"name": "Sarah",
		"createdDate": "2023-06-13 02:57:03",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 530,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 1697,
		"schedulePercent": 0.664283,
		"totalGuests": 592,
		"guestPercent": 0.723613,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 158
	},
	{
		"id": "b90c55a",
		"email": "awing16@gmail.com",
		"name": "Alec",
		"createdDate": "2018-09-20 22:08:22",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 110,
		"totalCountries": 6,
		"totalStates": 29,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 176,
		"schedulePercent": 0.136008,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "765b9d4",
		"email": "adamshoji@gmail.com",
		"name": "Adam",
		"createdDate": "2016-10-14 15:32:55",
		"totalOrganizations": 11,
		"totalTours": 12,
		"totalDays": 1336,
		"totalCountries": 9,
		"totalStates": 53,
		"totalEvents": 206,
		"eventPercent": 0.816183,
		"totalScheduleItems": 2578,
		"schedulePercent": 0.802808,
		"totalGuests": 836,
		"guestPercent": 0.784664,
		"mostGuestDate": "2023-07-19 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 51
	},
	{
		"id": "83e8eb4",
		"email": "Will@morrishigham.com",
		"name": "Will",
		"createdDate": "2015-03-19 19:05:44",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 837,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 170,
		"eventPercent": 0.752086,
		"totalScheduleItems": 3192,
		"schedulePercent": 0.869156,
		"totalGuests": 1866,
		"guestPercent": 0.925838,
		"mostGuestDate": "2023-05-28 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 121
	},
	{
		"id": "7e2fc14",
		"email": "dan@anotherplanetent.com",
		"name": "Dan",
		"createdDate": "2013-09-06 21:42:59",
		"totalOrganizations": 16,
		"totalTours": 12,
		"totalDays": 348,
		"totalCountries": 10,
		"totalStates": 50,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 2132,
		"schedulePercent": 0.739505,
		"totalGuests": 541,
		"guestPercent": 0.706264,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 50
	},
	{
		"id": "80039ee",
		"email": "dickmeredith@mac.com",
		"name": "Richard",
		"createdDate": "2011-09-14 14:29:59",
		"totalOrganizations": 12,
		"totalTours": 11,
		"totalDays": 375,
		"totalCountries": 6,
		"totalStates": 37,
		"totalEvents": 209,
		"eventPercent": 0.82201,
		"totalScheduleItems": 3778,
		"schedulePercent": 0.911138,
		"totalGuests": 1057,
		"guestPercent": 0.833797,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 187
	},
	{
		"id": "be47af9",
		"email": "chris_fritz@gmx.net",
		"name": "Chris",
		"createdDate": "2018-04-21 08:08:31",
		"totalOrganizations": 16,
		"totalTours": 13,
		"totalDays": 394,
		"totalCountries": 18,
		"totalStates": 75,
		"totalEvents": 245,
		"eventPercent": 0.867435,
		"totalScheduleItems": 3553,
		"schedulePercent": 0.897629,
		"totalGuests": 1527,
		"guestPercent": 0.897365,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 91
	},
	{
		"id": "64039c1",
		"email": "Puzzleboston@gmail.com",
		"name": "Corey",
		"createdDate": "2016-08-17 18:26:26",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 1004,
		"totalCountries": 6,
		"totalStates": 45,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 2116,
		"schedulePercent": 0.737518,
		"totalGuests": 678,
		"guestPercent": 0.747451,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 95
	},
	{
		"id": "5bf7967",
		"email": "mastertour1@bleucitron.net",
		"name": "Email",
		"createdDate": "2022-06-04 14:13:58",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 37,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 318,
		"schedulePercent": 0.217322,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a01d67d",
		"email": "jason.callewaert@lobbycall.com",
		"name": "Jason",
		"createdDate": "2022-04-29 17:14:50",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 335,
		"totalCountries": 17,
		"totalStates": 36,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 1151,
		"schedulePercent": 0.527347,
		"totalGuests": 337,
		"guestPercent": 0.621375,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 33
	},
	{
		"id": "336b1fd",
		"email": "Reneeka.versey@gmail.com",
		"name": "Reneeka",
		"createdDate": "2012-06-18 16:18:45",
		"totalOrganizations": 9,
		"totalTours": 8,
		"totalDays": 161,
		"totalCountries": 3,
		"totalStates": 23,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1000,
		"schedulePercent": 0.485234,
		"totalGuests": 546,
		"guestPercent": 0.707853,
		"mostGuestDate": "2023-11-05 00:00:00",
		"mostGuestName": "Hammerstein Ballroom",
		"mostGuestCity": "New York",
		"mostGuestAmount": 93
	},
	{
		"id": "8a99583",
		"email": "Whereisemmy@gmail.com",
		"name": "Emmy",
		"createdDate": "2015-09-11 18:49:00",
		"totalOrganizations": 19,
		"totalTours": 31,
		"totalDays": 1076,
		"totalCountries": 19,
		"totalStates": 88,
		"totalEvents": 407,
		"eventPercent": 0.958681,
		"totalScheduleItems": 4987,
		"schedulePercent": 0.9608,
		"totalGuests": 1328,
		"guestPercent": 0.875116,
		"mostGuestDate": "2023-02-16 00:00:00",
		"mostGuestName": "Irving Plaza",
		"mostGuestCity": "New York",
		"mostGuestAmount": 42
	},
	{
		"id": "ca30369",
		"email": "cDwight.Production@gmail.com",
		"name": "Chris",
		"createdDate": "2017-05-18 05:55:13",
		"totalOrganizations": 11,
		"totalTours": 13,
		"totalDays": 321,
		"totalCountries": 14,
		"totalStates": 52,
		"totalEvents": 180,
		"eventPercent": 0.770229,
		"totalScheduleItems": 1968,
		"schedulePercent": 0.714078,
		"totalGuests": 545,
		"guestPercent": 0.707324,
		"mostGuestDate": "2023-06-26 00:00:00",
		"mostGuestName": "Madison Square Garden",
		"mostGuestCity": "New York",
		"mostGuestAmount": 40
	},
	{
		"id": "13a2e20",
		"email": "amanda@mbmbam.com",
		"name": "Amanda",
		"createdDate": "2018-04-15 17:40:54",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 34,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 249,
		"schedulePercent": 0.181168,
		"totalGuests": 148,
		"guestPercent": 0.51225,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Palace Theatre",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 17
	},
	{
		"id": "6c7fe2d",
		"email": "Charlie.charron@gmail.com",
		"name": "Charles",
		"createdDate": "2022-11-02 21:57:48",
		"totalOrganizations": 2,
		"totalTours": 7,
		"totalDays": 204,
		"totalCountries": 6,
		"totalStates": 33,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1763,
		"schedulePercent": 0.677129,
		"totalGuests": 1145,
		"guestPercent": 0.848497,
		"mostGuestDate": "2023-05-07 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 75
	},
	{
		"id": "24c8f6d",
		"email": "Rolandbeckerle@aol.com",
		"name": "Roland",
		"createdDate": "2023-07-25 11:51:52",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 82,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 536,
		"schedulePercent": 0.315455,
		"totalGuests": 774,
		"guestPercent": 0.770494,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "Mercedes-Benz Arena Berlin",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 154
	},
	{
		"id": "3879674",
		"email": "extremewillis@mac.com",
		"name": "Aaron",
		"createdDate": "2010-07-21 21:42:20",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 586,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 1260,
		"schedulePercent": 0.554893,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "803961f",
		"email": "heather@quatroentertainment.com",
		"name": "Heather",
		"createdDate": "2019-08-26 21:25:34",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 332,
		"totalCountries": 2,
		"totalStates": 51,
		"totalEvents": 225,
		"eventPercent": 0.842008,
		"totalScheduleItems": 2599,
		"schedulePercent": 0.807443,
		"totalGuests": 850,
		"guestPercent": 0.78771,
		"mostGuestDate": "2023-02-14 00:00:00",
		"mostGuestName": "Eccles Theater",
		"mostGuestCity": "Salt Lake City",
		"mostGuestAmount": 76
	},
	{
		"id": "9b101be",
		"email": "weroedge@gmail.com",
		"name": "Ruben",
		"createdDate": "2014-12-10 13:02:29",
		"totalOrganizations": 13,
		"totalTours": 11,
		"totalDays": 666,
		"totalCountries": 14,
		"totalStates": 53,
		"totalEvents": 167,
		"eventPercent": 0.745332,
		"totalScheduleItems": 2201,
		"schedulePercent": 0.750099,
		"totalGuests": 557,
		"guestPercent": 0.712753,
		"mostGuestDate": "2023-08-09 00:00:00",
		"mostGuestName": "Plaza de Toros Alicante",
		"mostGuestCity": "Alacant",
		"mostGuestAmount": 28
	},
	{
		"id": "08d20a4",
		"email": "scottissound@googlemail.com",
		"name": "Scott",
		"createdDate": "2011-03-06 12:37:19",
		"totalOrganizations": 18,
		"totalTours": 4,
		"totalDays": 472,
		"totalCountries": 11,
		"totalStates": 55,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 1637,
		"schedulePercent": 0.650377,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-05-03 00:00:00",
		"mostGuestName": "Riverstage",
		"mostGuestCity": "Brisbane City",
		"mostGuestAmount": 6
	},
	{
		"id": "3764f8a",
		"email": "joshuatm@losct.com",
		"name": "joshua",
		"createdDate": "2022-06-03 18:10:26",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 145,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 68,
		"schedulePercent": 0.0678056,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0f2ec3f",
		"email": "caitlinc@rktztouring.com.au",
		"name": "Caitlin",
		"createdDate": "2021-06-01 05:13:47",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 239,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 838,
		"schedulePercent": 0.433717,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "167cb6b",
		"email": "ariel@maplehousestudios.com",
		"name": "Ariel",
		"createdDate": "2023-07-07 20:27:01",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 176,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 309,
		"schedulePercent": 0.212422,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "Rogers Place",
		"mostGuestCity": "Edmonton",
		"mostGuestAmount": 6
	},
	{
		"id": "ea57c17",
		"email": "primnificent@gmail.com",
		"name": "Brandon",
		"createdDate": "2022-08-06 07:02:42",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 179,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1208,
		"schedulePercent": 0.542047,
		"totalGuests": 340,
		"guestPercent": 0.623494,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "The Bowery Ballroom",
		"mostGuestCity": "New York",
		"mostGuestAmount": 65
	},
	{
		"id": "1a66eb4",
		"email": "jfranks8641@gmail.com",
		"name": "Jessica ",
		"createdDate": "2021-11-11 16:59:19",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 841,
		"totalCountries": 3,
		"totalStates": 24,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 1306,
		"schedulePercent": 0.567872,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5cce653",
		"email": "ryanmhug@gmail.com",
		"name": "Ryan",
		"createdDate": "2011-07-27 17:29:01",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 259,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 978,
		"schedulePercent": 0.474772,
		"totalGuests": 213,
		"guestPercent": 0.558866,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "The Mission Ballroom",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 86
	},
	{
		"id": "05f8d42",
		"email": "MMartin@taketwoprod.com",
		"name": "Michael",
		"createdDate": "2019-08-29 00:24:21",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 308,
		"totalCountries": 3,
		"totalStates": 41,
		"totalEvents": 178,
		"eventPercent": 0.767051,
		"totalScheduleItems": 3213,
		"schedulePercent": 0.870746,
		"totalGuests": 2699,
		"guestPercent": 0.960932,
		"mostGuestDate": "2023-08-16 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 154
	},
	{
		"id": "a8864ee",
		"email": "ericmilby@livenation.com",
		"name": "Eric ",
		"createdDate": "2022-05-11 04:17:10",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 335,
		"totalCountries": 3,
		"totalStates": 28,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 830,
		"schedulePercent": 0.430539,
		"totalGuests": 2750,
		"guestPercent": 0.962521,
		"mostGuestDate": "2023-09-24 00:00:00",
		"mostGuestName": "PNC Music Pavilion",
		"mostGuestCity": "Charlotte",
		"mostGuestAmount": 141
	},
	{
		"id": "cf10977",
		"email": "ajlin18@gmail.com",
		"name": "Andrew",
		"createdDate": "2016-02-03 15:00:30",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 232,
		"totalCountries": 10,
		"totalStates": 49,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 1461,
		"schedulePercent": 0.605748,
		"totalGuests": 411,
		"guestPercent": 0.658191,
		"mostGuestDate": "2023-08-17 00:00:00",
		"mostGuestName": "The Mann Center",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 33
	},
	{
		"id": "9a3c6a3",
		"email": "Mike@mhpsound.com",
		"name": "Michael",
		"createdDate": "2022-02-18 21:06:00",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 393,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 156,
		"eventPercent": 0.718051,
		"totalScheduleItems": 1565,
		"schedulePercent": 0.633956,
		"totalGuests": 838,
		"guestPercent": 0.785591,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "Ogden Theatre",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 62
	},
	{
		"id": "79a6d96",
		"email": "fohheff@gmail.com",
		"name": "Jeff",
		"createdDate": "2013-04-18 22:17:40",
		"totalOrganizations": 16,
		"totalTours": 7,
		"totalDays": 881,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 202,
		"eventPercent": 0.810091,
		"totalScheduleItems": 2287,
		"schedulePercent": 0.763475,
		"totalGuests": 1091,
		"guestPercent": 0.837637,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "St. Josephâ€™s Health Amphitheater at Lakeview",
		"mostGuestCity": "Syracuse",
		"mostGuestAmount": 46
	},
	{
		"id": "06df135",
		"email": "steve.ingersent@gmail.com",
		"name": "Steve",
		"createdDate": "2015-07-29 10:04:22",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 218,
		"totalCountries": 13,
		"totalStates": 24,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 1900,
		"schedulePercent": 0.704013,
		"totalGuests": 73,
		"guestPercent": 0.447755,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Reading Festival",
		"mostGuestCity": "Reading",
		"mostGuestAmount": 23
	},
	{
		"id": "750be3b",
		"email": "brandon.shew@gmail.com",
		"name": "Brandon",
		"createdDate": "2021-12-14 21:58:18",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 645,
		"totalCountries": 3,
		"totalStates": 42,
		"totalEvents": 173,
		"eventPercent": 0.757516,
		"totalScheduleItems": 3013,
		"schedulePercent": 0.852073,
		"totalGuests": 227,
		"guestPercent": 0.567474,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Wilkes Community College",
		"mostGuestCity": "Wilkesboro",
		"mostGuestAmount": 29
	},
	{
		"id": "2927136",
		"email": "elizabethrchan@gmail.com",
		"name": "Elizabeth",
		"createdDate": "2014-08-07 14:36:45",
		"totalOrganizations": 19,
		"totalTours": 11,
		"totalDays": 1259,
		"totalCountries": 5,
		"totalStates": 50,
		"totalEvents": 376,
		"eventPercent": 0.949676,
		"totalScheduleItems": 4069,
		"schedulePercent": 0.926103,
		"totalGuests": 307,
		"guestPercent": 0.607072,
		"mostGuestDate": "2023-08-10 00:00:00",
		"mostGuestName": "Skydeck on Broadway",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 37
	},
	{
		"id": "04efe68",
		"email": "mrbenwhiteley@gmail.com",
		"name": "Ben",
		"createdDate": "2017-02-09 04:06:20",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 90,
		"totalCountries": 5,
		"totalStates": 24,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 459,
		"schedulePercent": 0.280228,
		"totalGuests": 323,
		"guestPercent": 0.61568,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "GrÃ¶na Lund",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 59
	},
	{
		"id": "7bb6902",
		"email": "peter.hosek@imagevienna.com",
		"name": "Peter",
		"createdDate": "2018-07-31 13:11:19",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 66,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 191,
		"schedulePercent": 0.145146,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "84510d0",
		"email": "rosie@paperclip.no",
		"name": "Rosie",
		"createdDate": "2021-11-18 12:31:43",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 131,
		"totalCountries": 4,
		"totalStates": 31,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 370,
		"schedulePercent": 0.243809,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4e9ea27",
		"email": "carlpires23@gmail.com",
		"name": "Carl",
		"createdDate": "2016-04-16 16:10:24",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 194,
		"totalCountries": 11,
		"totalStates": 31,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 871,
		"schedulePercent": 0.443517,
		"totalGuests": 235,
		"guestPercent": 0.57052,
		"mostGuestDate": "2023-05-25 00:00:00",
		"mostGuestName": "The Regent",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 68
	},
	{
		"id": "60be1f5",
		"email": "ithomas215@icloud.com",
		"name": "Illiana",
		"createdDate": "2018-03-31 19:44:35",
		"totalOrganizations": 17,
		"totalTours": 10,
		"totalDays": 1038,
		"totalCountries": 13,
		"totalStates": 51,
		"totalEvents": 241,
		"eventPercent": 0.862667,
		"totalScheduleItems": 3053,
		"schedulePercent": 0.856443,
		"totalGuests": 75,
		"guestPercent": 0.450007,
		"mostGuestDate": "2023-07-24 00:00:00",
		"mostGuestName": "Greensboro Coliseum Complex",
		"mostGuestCity": "Greensboro",
		"mostGuestAmount": 20
	},
	{
		"id": "691d0cb",
		"email": "david.ball.tds@gmail.com",
		"name": "David",
		"createdDate": "2019-09-18 13:02:39",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 251,
		"totalCountries": 16,
		"totalStates": 62,
		"totalEvents": 157,
		"eventPercent": 0.720567,
		"totalScheduleItems": 2043,
		"schedulePercent": 0.726261,
		"totalGuests": 1625,
		"guestPercent": 0.905575,
		"mostGuestDate": "2023-01-22 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 133
	},
	{
		"id": "e14b2c4",
		"email": "andrew@24-7productions.co.uk",
		"name": "Andrew",
		"createdDate": "2012-03-13 07:20:20",
		"totalOrganizations": 30,
		"totalTours": 15,
		"totalDays": 1224,
		"totalCountries": 23,
		"totalStates": 61,
		"totalEvents": 298,
		"eventPercent": 0.914448,
		"totalScheduleItems": 3972,
		"schedulePercent": 0.921467,
		"totalGuests": 69,
		"guestPercent": 0.445107,
		"mostGuestDate": "2023-03-14 00:00:00",
		"mostGuestName": "Kings Theatre",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 16
	},
	{
		"id": "f9f9d93",
		"email": "a.munozproduccion@gmail.com",
		"name": "Gabriel",
		"createdDate": "2023-06-01 04:24:13",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 84,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 142,
		"schedulePercent": 0.114422,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "68f98b3",
		"email": "dylan@foundationsmgmt.com",
		"name": "Dylan",
		"createdDate": "2019-08-05 18:58:30",
		"totalOrganizations": 7,
		"totalTours": 14,
		"totalDays": 590,
		"totalCountries": 6,
		"totalStates": 49,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 2722,
		"schedulePercent": 0.822408,
		"totalGuests": 1740,
		"guestPercent": 0.91564,
		"mostGuestDate": "2023-11-15 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 142
	},
	{
		"id": "3742d74",
		"email": "sally@salvins.co.uk",
		"name": "Sally",
		"createdDate": "2022-03-23 20:51:06",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 946,
		"totalCountries": 5,
		"totalStates": 27,
		"totalEvents": 382,
		"eventPercent": 0.952457,
		"totalScheduleItems": 5102,
		"schedulePercent": 0.965038,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Lincoln Financial Field",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 4
	},
	{
		"id": "bc69515",
		"email": "rubentoursound@gmail.com",
		"name": "Ruben",
		"createdDate": "2017-07-13 20:42:14",
		"totalOrganizations": 19,
		"totalTours": 19,
		"totalDays": 1319,
		"totalCountries": 17,
		"totalStates": 59,
		"totalEvents": 344,
		"eventPercent": 0.939213,
		"totalScheduleItems": 4191,
		"schedulePercent": 0.933651,
		"totalGuests": 2736,
		"guestPercent": 0.961859,
		"mostGuestDate": "2023-09-03 00:00:00",
		"mostGuestName": "The Cosmopolitan of Las Vegas",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 138
	},
	{
		"id": "bce9de9",
		"email": "van@ottercreekent.com",
		"name": "Email",
		"createdDate": "2015-07-17 20:33:13",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 3,
		"totalStates": 42,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 2781,
		"schedulePercent": 0.829691,
		"totalGuests": 2379,
		"guestPercent": 0.949808,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 86
	},
	{
		"id": "98bcbad",
		"email": "taran@musictourbrasil.com",
		"name": "Carlos ",
		"createdDate": "2018-04-10 22:32:35",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 238,
		"totalCountries": 12,
		"totalStates": 40,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 1540,
		"schedulePercent": 0.627599,
		"totalGuests": 1181,
		"guestPercent": 0.854324,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "Arena Hall",
		"mostGuestCity": "Belo Horizonte",
		"mostGuestAmount": 93
	},
	{
		"id": "9cd4d17",
		"email": "max_i._million@hotmail.com",
		"name": "Janet",
		"createdDate": "2018-10-16 16:09:03",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 40,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 235,
		"schedulePercent": 0.171898,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "874c4d9",
		"email": "georgesheriffsewell@gmail.com",
		"name": "George",
		"createdDate": "2019-08-13 17:10:24",
		"totalOrganizations": 7,
		"totalTours": 19,
		"totalDays": 491,
		"totalCountries": 16,
		"totalStates": 63,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 1766,
		"schedulePercent": 0.677923,
		"totalGuests": 351,
		"guestPercent": 0.630513,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "La Cigale",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 46
	},
	{
		"id": "70e30a1",
		"email": "jeffrey@symbioticevents.com",
		"name": "Symbiotic ",
		"createdDate": "2019-03-14 23:00:44",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 379,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 1765,
		"schedulePercent": 0.677659,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Stubbs BBQ",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 1
	},
	{
		"id": "19f1e4c",
		"email": "hello@shotbyridout.uk",
		"name": "Jonny",
		"createdDate": "2022-07-05 14:10:51",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 478,
		"totalCountries": 6,
		"totalStates": 12,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 776,
		"schedulePercent": 0.411601,
		"totalGuests": 158,
		"guestPercent": 0.520858,
		"mostGuestDate": "2023-07-09 00:00:00",
		"mostGuestName": "Bootle Canalside",
		"mostGuestCity": "Liverpool",
		"mostGuestAmount": 48
	},
	{
		"id": "e3f2272",
		"email": "amy@amywatts.com",
		"name": "Amy",
		"createdDate": "2015-12-28 12:46:15",
		"totalOrganizations": 23,
		"totalTours": 25,
		"totalDays": 965,
		"totalCountries": 25,
		"totalStates": 47,
		"totalEvents": 216,
		"eventPercent": 0.831943,
		"totalScheduleItems": 4532,
		"schedulePercent": 0.946497,
		"totalGuests": 309,
		"guestPercent": 0.609058,
		"mostGuestDate": "2023-11-21 00:00:00",
		"mostGuestName": "Western Springs Stadium and Park",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 115
	},
	{
		"id": "53e7379",
		"email": "gulli.sekse@ncb.no",
		"name": "Gulli",
		"createdDate": "2021-11-04 10:46:04",
		"totalOrganizations": 1,
		"totalTours": 15,
		"totalDays": 91,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 184,
		"schedulePercent": 0.141306,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "490ca2f",
		"email": "info@artistgardenentertainment.com",
		"name": "David C",
		"createdDate": "2023-06-06 17:49:17",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 53,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 724,
		"schedulePercent": 0.390147,
		"totalGuests": 279,
		"guestPercent": 0.593961,
		"mostGuestDate": "2023-10-04 00:00:00",
		"mostGuestName": "South Lindsay Baptist Church",
		"mostGuestCity": "Oklahoma City",
		"mostGuestAmount": 104
	},
	{
		"id": "3dbcdd7",
		"email": "ida@themusiccompany.dk",
		"name": "Ida",
		"createdDate": "2015-01-14 09:30:32",
		"totalOrganizations": 35,
		"totalTours": 13,
		"totalDays": 239,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 230,
		"eventPercent": 0.849159,
		"totalScheduleItems": 1167,
		"schedulePercent": 0.531453,
		"totalGuests": 162,
		"guestPercent": 0.524169,
		"mostGuestDate": "2023-05-12 00:00:00",
		"mostGuestName": "Tivoli",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 36
	},
	{
		"id": "8f8b738",
		"email": "intern@17ten.co",
		"name": "Raymond",
		"createdDate": "2023-02-12 09:55:58",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 398,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 600,
		"schedulePercent": 0.340882,
		"totalGuests": 50,
		"guestPercent": 0.421798,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 30
	},
	{
		"id": "b3d23e9",
		"email": "langtouring@gmail.com",
		"name": "Jake",
		"createdDate": "2018-02-26 20:55:22",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 494,
		"totalCountries": 16,
		"totalStates": 59,
		"totalEvents": 172,
		"eventPercent": 0.755661,
		"totalScheduleItems": 2151,
		"schedulePercent": 0.742551,
		"totalGuests": 508,
		"guestPercent": 0.69461,
		"mostGuestDate": "2023-06-07 00:00:00",
		"mostGuestName": "Verti Music Hall",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 61
	},
	{
		"id": "7bae646",
		"email": "iamkyliejackson@gmail.com",
		"name": "Kylie",
		"createdDate": "2019-05-16 23:33:54",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 67,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 385,
		"schedulePercent": 0.251093,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1f3a88e",
		"email": "davidskaya@gmail.com",
		"name": "David-Etienne",
		"createdDate": "2016-06-29 09:17:39",
		"totalOrganizations": 13,
		"totalTours": 19,
		"totalDays": 255,
		"totalCountries": 9,
		"totalStates": 38,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 943,
		"schedulePercent": 0.463382,
		"totalGuests": 13,
		"guestPercent": 0.352536,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Higher Ground",
		"mostGuestCity": "South Burlington",
		"mostGuestAmount": 13
	},
	{
		"id": "f179264",
		"email": "reggie@mortonrecords.com",
		"name": "Reginald",
		"createdDate": "2022-05-11 22:36:57",
		"totalOrganizations": 4,
		"totalTours": 12,
		"totalDays": 180,
		"totalCountries": 4,
		"totalStates": 14,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 301,
		"schedulePercent": 0.208449,
		"totalGuests": 32,
		"guestPercent": 0.392531,
		"mostGuestDate": "2023-09-26 00:00:00",
		"mostGuestName": "The Enmore Theatre",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 19
	},
	{
		"id": "60bccb3",
		"email": "ehaiderproduction@gmail.com",
		"name": "Elliot",
		"createdDate": "2015-08-18 00:21:47",
		"totalOrganizations": 7,
		"totalTours": 4,
		"totalDays": 707,
		"totalCountries": 1,
		"totalStates": 31,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 1286,
		"schedulePercent": 0.562177,
		"totalGuests": 182,
		"guestPercent": 0.538074,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "Colonial Life Arena",
		"mostGuestCity": "Columbia",
		"mostGuestAmount": 20
	},
	{
		"id": "105b7d2",
		"email": "bodowd@me.com",
		"name": "Brian ",
		"createdDate": "2011-03-05 21:56:11",
		"totalOrganizations": 8,
		"totalTours": 2,
		"totalDays": 541,
		"totalCountries": 15,
		"totalStates": 45,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1676,
		"schedulePercent": 0.658986,
		"totalGuests": 601,
		"guestPercent": 0.726526,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 77
	},
	{
		"id": "49965f5",
		"email": "timhib@me.com",
		"name": "Timothy",
		"createdDate": "2015-07-26 18:25:59",
		"totalOrganizations": 15,
		"totalTours": 23,
		"totalDays": 782,
		"totalCountries": 15,
		"totalStates": 49,
		"totalEvents": 404,
		"eventPercent": 0.958019,
		"totalScheduleItems": 4340,
		"schedulePercent": 0.939081,
		"totalGuests": 910,
		"guestPercent": 0.801616,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 81
	},
	{
		"id": "d47b602",
		"email": "patbrownmusic@gmail.com",
		"name": "Pat ",
		"createdDate": "2015-02-27 19:33:17",
		"totalOrganizations": 12,
		"totalTours": 27,
		"totalDays": 1018,
		"totalCountries": 19,
		"totalStates": 61,
		"totalEvents": 352,
		"eventPercent": 0.942127,
		"totalScheduleItems": 5179,
		"schedulePercent": 0.967024,
		"totalGuests": 1095,
		"guestPercent": 0.838564,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "Kings Theatre",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 59
	},
	{
		"id": "a6ccabd",
		"email": "Russellaudio@gmail.com",
		"name": "Matt",
		"createdDate": "2014-03-17 15:39:44",
		"totalOrganizations": 14,
		"totalTours": 2,
		"totalDays": 730,
		"totalCountries": 11,
		"totalStates": 36,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 1179,
		"schedulePercent": 0.534631,
		"totalGuests": 895,
		"guestPercent": 0.798967,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 92
	},
	{
		"id": "2cb11f2",
		"email": "arucci@c3mgmt.com",
		"name": "Anthony",
		"createdDate": "2021-07-02 20:45:58",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 157,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 1772,
		"schedulePercent": 0.678983,
		"totalGuests": 4600,
		"guestPercent": 0.989273,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "The Pacific Amphitheatre",
		"mostGuestCity": "Costa Mesa",
		"mostGuestAmount": 238
	},
	{
		"id": "599b86a",
		"email": "jaredpettinato@me.com",
		"name": "Jared",
		"createdDate": "2021-06-29 00:27:42",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 81,
		"totalCountries": 3,
		"totalStates": 27,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 715,
		"schedulePercent": 0.386571,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c5e2097",
		"email": "jasonbolennyc@gmail.com",
		"name": "Jason",
		"createdDate": "2021-07-08 20:46:12",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1aa053e",
		"email": "charlieray98@gmail.com",
		"name": "Charlie",
		"createdDate": "2022-02-09 14:57:37",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 250,
		"totalCountries": 1,
		"totalStates": 36,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 815,
		"schedulePercent": 0.424844,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Sandiwood Farm",
		"mostGuestCity": "Wolcott",
		"mostGuestAmount": 3
	},
	{
		"id": "74fca6e",
		"email": "magikbooking@gmail.com",
		"name": "Andrew",
		"createdDate": "2017-01-30 15:42:59",
		"totalOrganizations": 12,
		"totalTours": 11,
		"totalDays": 793,
		"totalCountries": 21,
		"totalStates": 72,
		"totalEvents": 213,
		"eventPercent": 0.827837,
		"totalScheduleItems": 2592,
		"schedulePercent": 0.805986,
		"totalGuests": 992,
		"guestPercent": 0.821878,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 51
	},
	{
		"id": "e20a3b0",
		"email": "tayaofficial89@gmail.com",
		"name": "Abbey ",
		"createdDate": "2022-07-23 01:13:57",
		"totalOrganizations": 5,
		"totalTours": 18,
		"totalDays": 249,
		"totalCountries": 2,
		"totalStates": 31,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 2660,
		"schedulePercent": 0.814859,
		"totalGuests": 1406,
		"guestPercent": 0.883327,
		"mostGuestDate": "2023-05-02 00:00:00",
		"mostGuestName": "The Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 99
	},
	{
		"id": "359ee77",
		"email": "jamessound.audio@gmail.com",
		"name": "James",
		"createdDate": "2011-08-18 21:12:22",
		"totalOrganizations": 12,
		"totalTours": 10,
		"totalDays": 500,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 1962,
		"schedulePercent": 0.713548,
		"totalGuests": 358,
		"guestPercent": 0.634221,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "20 Monroe Live",
		"mostGuestCity": "Grand Rapids",
		"mostGuestAmount": 28
	},
	{
		"id": "435af97",
		"email": "Productionguy@ymail.com",
		"name": "Maceo",
		"createdDate": "2015-12-04 01:15:45",
		"totalOrganizations": 18,
		"totalTours": 16,
		"totalDays": 653,
		"totalCountries": 4,
		"totalStates": 31,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 962,
		"schedulePercent": 0.469077,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c5661c3",
		"email": "raluca.bogdan@gmail.com",
		"name": "Raluca",
		"createdDate": "2018-01-13 22:33:35",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 74,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 216,
		"schedulePercent": 0.160509,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ed0ad3c",
		"email": "jmgoss11@aol.com",
		"name": "Jeff",
		"createdDate": "2012-02-21 16:08:11",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 673,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 2462,
		"schedulePercent": 0.788108,
		"totalGuests": 336,
		"guestPercent": 0.620977,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "Thompson Boling Arena",
		"mostGuestCity": "Knoxville",
		"mostGuestAmount": 47
	},
	{
		"id": "3605c25",
		"email": "andy@bratlandindustries.com",
		"name": "William",
		"createdDate": "2019-10-15 21:44:15",
		"totalOrganizations": 7,
		"totalTours": 39,
		"totalDays": 128,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 674,
		"schedulePercent": 0.37015,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-01-18 00:00:00",
		"mostGuestName": "The Boca Raton",
		"mostGuestCity": "Boca Raton",
		"mostGuestAmount": 1
	},
	{
		"id": "23582d0",
		"email": "cameron.jb.stewart@gmail.com",
		"name": "Cameron",
		"createdDate": "2012-04-24 04:40:54",
		"totalOrganizations": 19,
		"totalTours": 10,
		"totalDays": 682,
		"totalCountries": 17,
		"totalStates": 56,
		"totalEvents": 157,
		"eventPercent": 0.720567,
		"totalScheduleItems": 1948,
		"schedulePercent": 0.711561,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d49b66c",
		"email": "ktotheb@me.com",
		"name": "Kb (Ken)",
		"createdDate": "2021-07-01 02:45:58",
		"totalOrganizations": 9,
		"totalTours": 12,
		"totalDays": 967,
		"totalCountries": 20,
		"totalStates": 71,
		"totalEvents": 264,
		"eventPercent": 0.889419,
		"totalScheduleItems": 5223,
		"schedulePercent": 0.967819,
		"totalGuests": 1416,
		"guestPercent": 0.884519,
		"mostGuestDate": "2023-08-13 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 125
	},
	{
		"id": "1af0ac4",
		"email": "brandon@marshmellomusic.com",
		"name": "Brandon",
		"createdDate": "2021-06-04 11:29:56",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 733,
		"totalCountries": 10,
		"totalStates": 25,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1732,
		"schedulePercent": 0.671434,
		"totalGuests": 134,
		"guestPercent": 0.501258,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "XS Las Vegas",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 33
	},
	{
		"id": "61d4040",
		"email": "elenapelse@gmail.com",
		"name": "Elena",
		"createdDate": "2021-10-27 09:41:58",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 186,
		"totalCountries": 14,
		"totalStates": 26,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1135,
		"schedulePercent": 0.52205,
		"totalGuests": 121,
		"guestPercent": 0.490928,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Reading Festival",
		"mostGuestCity": "Reading",
		"mostGuestAmount": 23
	},
	{
		"id": "3a4c4d2",
		"email": "brisselli.rojas@gmail.com",
		"name": "Brisselli",
		"createdDate": "2017-05-30 22:29:04",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 222,
		"totalCountries": 3,
		"totalStates": 28,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1279,
		"schedulePercent": 0.559926,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "51f6bc7",
		"email": "benericksontouring@gmail.com",
		"name": "Ben",
		"createdDate": "2009-11-04 08:45:34",
		"totalOrganizations": 16,
		"totalTours": 9,
		"totalDays": 936,
		"totalCountries": 7,
		"totalStates": 52,
		"totalEvents": 270,
		"eventPercent": 0.894451,
		"totalScheduleItems": 4902,
		"schedulePercent": 0.958416,
		"totalGuests": 646,
		"guestPercent": 0.737916,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 139
	},
	{
		"id": "bd16d06",
		"email": "simonmaccoll2@icloud.com",
		"name": "Simon",
		"createdDate": "2023-04-14 09:25:31",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 57,
		"totalCountries": 4,
		"totalStates": 6,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 420,
		"schedulePercent": 0.263939,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "420746e",
		"email": "bilmurimusic@gmail.com",
		"name": "Paul",
		"createdDate": "2022-10-11 16:46:31",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 148,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1056,
		"schedulePercent": 0.499669,
		"totalGuests": 368,
		"guestPercent": 0.64071,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 51
	},
	{
		"id": "2d66dcc",
		"email": "drsmcewen@gmail.com",
		"name": "Shannon",
		"createdDate": "2016-01-27 22:03:54",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 175,
		"totalCountries": 10,
		"totalStates": 43,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1237,
		"schedulePercent": 0.549199,
		"totalGuests": 344,
		"guestPercent": 0.624685,
		"mostGuestDate": "2023-10-22 00:00:00",
		"mostGuestName": "THE PUNK ROCK MUSEUM",
		"mostGuestCity": "LAS VEGAS",
		"mostGuestAmount": 58
	},
	{
		"id": "6052678",
		"email": "baileykretz.tm@gmail.com",
		"name": "Bailey",
		"createdDate": "2023-03-14 20:55:16",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 130,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 842,
		"schedulePercent": 0.434909,
		"totalGuests": 200,
		"guestPercent": 0.550258,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Franklin Music Hall",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 41
	},
	{
		"id": "adc4cc0",
		"email": "sachabambadji@gmail.com",
		"name": "Sacha",
		"createdDate": "2014-03-21 19:12:41",
		"totalOrganizations": 15,
		"totalTours": 37,
		"totalDays": 1269,
		"totalCountries": 26,
		"totalStates": 85,
		"totalEvents": 470,
		"eventPercent": 0.970732,
		"totalScheduleItems": 8700,
		"schedulePercent": 0.995365,
		"totalGuests": 2669,
		"guestPercent": 0.959211,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 98
	},
	{
		"id": "a1ee250",
		"email": "lsdunes@theassemblymusic.com",
		"name": "L.S.",
		"createdDate": "2023-09-13 14:55:26",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 40,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 301,
		"schedulePercent": 0.208449,
		"totalGuests": 10,
		"guestPercent": 0.343531,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "Santander Arena",
		"mostGuestCity": "Reading",
		"mostGuestAmount": 10
	},
	{
		"id": "d3e435d",
		"email": "troy@clovermwx.com",
		"name": "Troy",
		"createdDate": "2023-05-07 21:05:24",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 102,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 317,
		"schedulePercent": 0.216395,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Whirling Tiger",
		"mostGuestCity": "Louisville",
		"mostGuestAmount": 4
	},
	{
		"id": "30f40e2",
		"email": "alison@tmelite.co.uk",
		"name": "Alison",
		"createdDate": "2021-09-21 10:17:22",
		"totalOrganizations": 39,
		"totalTours": 25,
		"totalDays": 707,
		"totalCountries": 17,
		"totalStates": 51,
		"totalEvents": 207,
		"eventPercent": 0.817905,
		"totalScheduleItems": 1653,
		"schedulePercent": 0.654086,
		"totalGuests": 325,
		"guestPercent": 0.61674,
		"mostGuestDate": "2023-04-09 00:00:00",
		"mostGuestName": "Phoenix Concert Theatre",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 58
	},
	{
		"id": "d4a5154",
		"email": "roadkat@gmail.com",
		"name": "Kat",
		"createdDate": "2018-08-06 21:46:37",
		"totalOrganizations": 10,
		"totalTours": 25,
		"totalDays": 344,
		"totalCountries": 4,
		"totalStates": 36,
		"totalEvents": 134,
		"eventPercent": 0.658721,
		"totalScheduleItems": 1349,
		"schedulePercent": 0.58032,
		"totalGuests": 303,
		"guestPercent": 0.604158,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 37
	},
	{
		"id": "8dc1c45",
		"email": "jessjacobsengineering@gmail.com",
		"name": "Jess",
		"createdDate": "2017-08-10 01:59:16",
		"totalOrganizations": 16,
		"totalTours": 21,
		"totalDays": 782,
		"totalCountries": 16,
		"totalStates": 63,
		"totalEvents": 233,
		"eventPercent": 0.853662,
		"totalScheduleItems": 2984,
		"schedulePercent": 0.849689,
		"totalGuests": 915,
		"guestPercent": 0.802543,
		"mostGuestDate": "2023-09-27 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 80
	},
	{
		"id": "27b6e9c",
		"email": "clement@houseoflove.net",
		"name": "ClÃ©ment",
		"createdDate": "2021-10-25 08:45:17",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 363,
		"totalCountries": 11,
		"totalStates": 20,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 967,
		"schedulePercent": 0.470269,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2722bb9",
		"email": "mattgreer333@gmail.com",
		"name": "Matt",
		"createdDate": "2018-10-09 00:33:46",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 86,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 446,
		"schedulePercent": 0.27493,
		"totalGuests": 459,
		"guestPercent": 0.677394,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "The Anthem",
		"mostGuestCity": "Washington",
		"mostGuestAmount": 29
	},
	{
		"id": "2e7512e",
		"email": "Haley@makewake.net",
		"name": "Haley",
		"createdDate": "2021-04-16 22:23:13",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 724,
		"totalCountries": 2,
		"totalStates": 45,
		"totalEvents": 210,
		"eventPercent": 0.823732,
		"totalScheduleItems": 2592,
		"schedulePercent": 0.805986,
		"totalGuests": 250,
		"guestPercent": 0.578599,
		"mostGuestDate": "2023-02-04 00:00:00",
		"mostGuestName": "Renfro Valley Entertainment Center",
		"mostGuestCity": "Mt Vernon",
		"mostGuestAmount": 27
	},
	{
		"id": "1df6d23",
		"email": "susteadman@me.com",
		"name": "Susan",
		"createdDate": "2010-09-24 13:13:27",
		"totalOrganizations": 8,
		"totalTours": 2,
		"totalDays": 143,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 379,
		"schedulePercent": 0.248311,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "021b08d",
		"email": "Mullinixparker@yahoo.com",
		"name": "Parker",
		"createdDate": "2018-02-16 19:25:15",
		"totalOrganizations": 13,
		"totalTours": 8,
		"totalDays": 320,
		"totalCountries": 12,
		"totalStates": 53,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 2190,
		"schedulePercent": 0.748378,
		"totalGuests": 125,
		"guestPercent": 0.494636,
		"mostGuestDate": "2023-05-26 00:00:00",
		"mostGuestName": "Zenith Arena Lille",
		"mostGuestCity": "Lille",
		"mostGuestAmount": 30
	},
	{
		"id": "f3ccf2a",
		"email": "kristin.carlen@lifeline.se",
		"name": "Kristin",
		"createdDate": "2023-08-16 11:42:06",
		"totalOrganizations": 13,
		"totalTours": 7,
		"totalDays": 388,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 544,
		"schedulePercent": 0.319031,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4ed5169",
		"email": "antoine.k.richard@gmail.com",
		"name": "Antoine",
		"createdDate": "2014-10-05 11:54:23",
		"totalOrganizations": 9,
		"totalTours": 12,
		"totalDays": 368,
		"totalCountries": 15,
		"totalStates": 45,
		"totalEvents": 163,
		"eventPercent": 0.735134,
		"totalScheduleItems": 1999,
		"schedulePercent": 0.718315,
		"totalGuests": 57,
		"guestPercent": 0.430804,
		"mostGuestDate": "2023-11-15 00:00:00",
		"mostGuestName": "Music Hall of Williamsburg",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 43
	},
	{
		"id": "ba7e10b",
		"email": "dradburn1@gmail.com",
		"name": "Daniel",
		"createdDate": "2017-02-14 07:21:35",
		"totalOrganizations": 8,
		"totalTours": 68,
		"totalDays": 431,
		"totalCountries": 10,
		"totalStates": 30,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 1838,
		"schedulePercent": 0.693286,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0f1d72d",
		"email": "dschultz099@gmail.com",
		"name": "David",
		"createdDate": "2017-03-14 23:33:56",
		"totalOrganizations": 11,
		"totalTours": 11,
		"totalDays": 1815,
		"totalCountries": 12,
		"totalStates": 70,
		"totalEvents": 526,
		"eventPercent": 0.977089,
		"totalScheduleItems": 6881,
		"schedulePercent": 0.988346,
		"totalGuests": 1051,
		"guestPercent": 0.83287,
		"mostGuestDate": "2023-08-04 00:00:00",
		"mostGuestName": "Royal Albert Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 42
	},
	{
		"id": "935411e",
		"email": "imranorchid@gmail.com",
		"name": "Imran",
		"createdDate": "2011-04-21 08:34:29",
		"totalOrganizations": 7,
		"totalTours": 14,
		"totalDays": 345,
		"totalCountries": 17,
		"totalStates": 67,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 1854,
		"schedulePercent": 0.695669,
		"totalGuests": 1012,
		"guestPercent": 0.826778,
		"mostGuestDate": "2023-11-19 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 57
	},
	{
		"id": "0daa7e9",
		"email": "rawdonshane@gmail.com",
		"name": "Shane",
		"createdDate": "2019-03-22 22:17:22",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 428,
		"totalCountries": 12,
		"totalStates": 49,
		"totalEvents": 163,
		"eventPercent": 0.735134,
		"totalScheduleItems": 2791,
		"schedulePercent": 0.831148,
		"totalGuests": 1810,
		"guestPercent": 0.92107,
		"mostGuestDate": "2023-03-01 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 135
	},
	{
		"id": "7b46ece",
		"email": "elisa@terapiaproductions.com",
		"name": "Elisa ",
		"createdDate": "2022-06-06 16:24:46",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 438,
		"totalCountries": 4,
		"totalStates": 22,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 536,
		"schedulePercent": 0.315455,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "634c387",
		"email": "dom@boyh.co.uk",
		"name": "Dominic",
		"createdDate": "2021-03-23 18:53:02",
		"totalOrganizations": 4,
		"totalTours": 35,
		"totalDays": 225,
		"totalCountries": 15,
		"totalStates": 25,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 671,
		"schedulePercent": 0.36909,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a147ed8",
		"email": "papas.stage@gmail.com",
		"name": "ANDRES",
		"createdDate": "2023-01-17 10:35:15",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 354,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 842,
		"schedulePercent": 0.434909,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6a95eeb",
		"email": "krystall@tru-tribe.co.uk",
		"name": "Krystall",
		"createdDate": "2022-06-12 10:41:08",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 334,
		"totalCountries": 7,
		"totalStates": 16,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 365,
		"schedulePercent": 0.24063,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e1cc443",
		"email": "olivervangent@nt-rp.com",
		"name": "Ollie",
		"createdDate": "2019-02-18 04:12:20",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 367,
		"totalCountries": 17,
		"totalStates": 32,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 1277,
		"schedulePercent": 0.559264,
		"totalGuests": 117,
		"guestPercent": 0.488412,
		"mostGuestDate": "2023-07-03 00:00:00",
		"mostGuestName": "Brooklyn Mirage",
		"mostGuestCity": "",
		"mostGuestAmount": 29
	},
	{
		"id": "60ca9d6",
		"email": "naomi.black88@icloud.com",
		"name": "Naomi",
		"createdDate": "2018-05-29 06:01:44",
		"totalOrganizations": 12,
		"totalTours": 10,
		"totalDays": 868,
		"totalCountries": 16,
		"totalStates": 46,
		"totalEvents": 185,
		"eventPercent": 0.780029,
		"totalScheduleItems": 2848,
		"schedulePercent": 0.838035,
		"totalGuests": 650,
		"guestPercent": 0.739637,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Motorpoint Arena Nottingham",
		"mostGuestCity": "Nottingham",
		"mostGuestAmount": 58
	},
	{
		"id": "5a19f09",
		"email": "lauren.passionfortalentmgmt@gmail.com",
		"name": "Lauren",
		"createdDate": "2022-08-26 07:54:53",
		"totalOrganizations": 8,
		"totalTours": 17,
		"totalDays": 115,
		"totalCountries": 8,
		"totalStates": 17,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 633,
		"schedulePercent": 0.35545,
		"totalGuests": 157,
		"guestPercent": 0.520196,
		"mostGuestDate": "2023-09-19 00:00:00",
		"mostGuestName": "Baby's All Right",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 27
	},
	{
		"id": "8f627f5",
		"email": "benjamin.maebert@livenation.de",
		"name": "Benjamin",
		"createdDate": "2022-04-27 20:41:54",
		"totalOrganizations": 13,
		"totalTours": 7,
		"totalDays": 449,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 240,
		"schedulePercent": 0.175738,
		"totalGuests": 408,
		"guestPercent": 0.656999,
		"mostGuestDate": "2023-01-12 00:00:00",
		"mostGuestName": "Metropol",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 109
	},
	{
		"id": "85decbb",
		"email": "athugorodriguez@gmail.com",
		"name": "Hugo",
		"createdDate": "2021-10-28 01:25:06",
		"totalOrganizations": 9,
		"totalTours": 9,
		"totalDays": 2066,
		"totalCountries": 9,
		"totalStates": 44,
		"totalEvents": 188,
		"eventPercent": 0.785856,
		"totalScheduleItems": 4308,
		"schedulePercent": 0.938286,
		"totalGuests": 530,
		"guestPercent": 0.702556,
		"mostGuestDate": "2023-05-08 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 82
	},
	{
		"id": "5cea917",
		"email": "stephen@axiommediagroup.co",
		"name": "Stephen",
		"createdDate": "2022-05-18 13:18:47",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 43,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 237,
		"schedulePercent": 0.173355,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a6b1353",
		"email": "davischitlik@yahoo.com",
		"name": "davis",
		"createdDate": "2022-07-19 19:11:04",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 186,
		"totalCountries": 7,
		"totalStates": 33,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 2084,
		"schedulePercent": 0.732618,
		"totalGuests": 1462,
		"guestPercent": 0.889021,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "The Rooftop at Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 176
	},
	{
		"id": "75eb2f7",
		"email": "restrella2323@gmail.com",
		"name": "RJ",
		"createdDate": "2022-06-11 01:59:45",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 441,
		"totalCountries": 9,
		"totalStates": 38,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 2433,
		"schedulePercent": 0.784135,
		"totalGuests": 3544,
		"guestPercent": 0.978149,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Raymond James Stadium",
		"mostGuestCity": "Tampa ",
		"mostGuestAmount": 248
	},
	{
		"id": "2b5bf58",
		"email": "pernilla.thagaard@blixten.se",
		"name": "Pernilla ",
		"createdDate": "2017-02-06 10:14:29",
		"totalOrganizations": 13,
		"totalTours": 14,
		"totalDays": 1227,
		"totalCountries": 2,
		"totalStates": 17,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 554,
		"schedulePercent": 0.323136,
		"totalGuests": 13,
		"guestPercent": 0.352536,
		"mostGuestDate": "2023-12-09 00:00:00",
		"mostGuestName": "MalmÃ¶ Live",
		"mostGuestCity": "MalmÃ¶",
		"mostGuestAmount": 11
	},
	{
		"id": "779431b",
		"email": "pnkcmusic@yahoo.com",
		"name": "Danette",
		"createdDate": "2022-02-09 18:17:46",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 87,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 725,
		"schedulePercent": 0.390677,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "499b049",
		"email": "jaxontour76@gmail.com",
		"name": "Jackie",
		"createdDate": "2018-01-30 04:12:55",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 169,
		"totalCountries": 10,
		"totalStates": 26,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 1327,
		"schedulePercent": 0.573831,
		"totalGuests": 955,
		"guestPercent": 0.814064,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Qudos Bank Arena",
		"mostGuestCity": "Sydney Olympic Park",
		"mostGuestAmount": 64
	},
	{
		"id": "c42e91c",
		"email": "candacenordine@rforh.com",
		"name": "Candace",
		"createdDate": "2021-04-20 01:25:21",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 2555,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 269,
		"schedulePercent": 0.191498,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8f59f20",
		"email": "dponder127@gmail.com",
		"name": "Danielle",
		"createdDate": "2022-05-29 15:59:05",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 431,
		"totalCountries": 6,
		"totalStates": 41,
		"totalEvents": 141,
		"eventPercent": 0.678321,
		"totalScheduleItems": 1221,
		"schedulePercent": 0.545491,
		"totalGuests": 447,
		"guestPercent": 0.672096,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 50
	},
	{
		"id": "0d49715",
		"email": "cstasinos@yahoo.com",
		"name": "CJ",
		"createdDate": "2010-08-31 19:08:45",
		"totalOrganizations": 24,
		"totalTours": 20,
		"totalDays": 450,
		"totalCountries": 17,
		"totalStates": 54,
		"totalEvents": 230,
		"eventPercent": 0.849159,
		"totalScheduleItems": 2509,
		"schedulePercent": 0.794464,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4ca2d70",
		"email": "cindy.chapman@me.com",
		"name": "Cindy",
		"createdDate": "2011-12-20 17:27:02",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 91,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 337,
		"schedulePercent": 0.226857,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-04-12 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 3
	},
	{
		"id": "240e662",
		"email": "Michael@borderbreakers.com",
		"name": "Michael",
		"createdDate": "2015-06-14 19:51:53",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 86,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 377,
		"schedulePercent": 0.246855,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e1459b2",
		"email": "christopherlukeroach@gmail.com",
		"name": "Christopher",
		"createdDate": "2019-10-17 14:09:37",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 383,
		"totalCountries": 7,
		"totalStates": 45,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 2418,
		"schedulePercent": 0.782413,
		"totalGuests": 1300,
		"guestPercent": 0.871673,
		"mostGuestDate": "2023-07-26 00:00:00",
		"mostGuestName": "Santander Arena",
		"mostGuestCity": "Reading",
		"mostGuestAmount": 52
	},
	{
		"id": "38fd704",
		"email": "joshua.a.snyder712@gmail.com",
		"name": "Josh",
		"createdDate": "2023-01-02 23:53:07",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 625,
		"totalCountries": 4,
		"totalStates": 48,
		"totalEvents": 223,
		"eventPercent": 0.840154,
		"totalScheduleItems": 2901,
		"schedulePercent": 0.84214,
		"totalGuests": 1578,
		"guestPercent": 0.90094,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Dos Equis Pavilion",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 155
	},
	{
		"id": "e00dd35",
		"email": "mdfishermgmt@gmail.com",
		"name": "Marcus",
		"createdDate": "2019-01-09 19:32:28",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 46,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 9,
		"schedulePercent": 0.0210568,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "702cfd7",
		"email": "charlie@stentmusicgroup.com",
		"name": "Charlie",
		"createdDate": "2019-08-09 15:02:06",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 117,
		"totalCountries": 6,
		"totalStates": 29,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 394,
		"schedulePercent": 0.255066,
		"totalGuests": 58,
		"guestPercent": 0.431863,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "OMEARA",
		"mostGuestCity": "London",
		"mostGuestAmount": 16
	},
	{
		"id": "b288dbb",
		"email": "ab@opakmedia.com",
		"name": "Annick",
		"createdDate": "2021-03-17 13:41:14",
		"totalOrganizations": 20,
		"totalTours": 65,
		"totalDays": 525,
		"totalCountries": 11,
		"totalStates": 33,
		"totalEvents": 178,
		"eventPercent": 0.767051,
		"totalScheduleItems": 1357,
		"schedulePercent": 0.582572,
		"totalGuests": 57,
		"guestPercent": 0.430804,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Higher Ground",
		"mostGuestCity": "South Burlington",
		"mostGuestAmount": 13
	},
	{
		"id": "7f34428",
		"email": "kylepward@yahoo.com",
		"name": "Kyle",
		"createdDate": "2016-01-04 04:58:33",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 213,
		"totalCountries": 13,
		"totalStates": 44,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 1549,
		"schedulePercent": 0.629586,
		"totalGuests": 821,
		"guestPercent": 0.781221,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "Angel Of The Winds Arena",
		"mostGuestCity": "Everett",
		"mostGuestAmount": 59
	},
	{
		"id": "d49c1cb",
		"email": "produktion@benjaminsill.de",
		"name": "Produktion",
		"createdDate": "2022-03-03 13:50:48",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 137,
		"totalCountries": 4,
		"totalStates": 12,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 1562,
		"schedulePercent": 0.633029,
		"totalGuests": 1614,
		"guestPercent": 0.904648,
		"mostGuestDate": "2023-07-19 00:00:00",
		"mostGuestName": "Schlossplatz Stuttgart",
		"mostGuestCity": "Stuttgart",
		"mostGuestAmount": 214
	},
	{
		"id": "a3d4401",
		"email": "pinci@t-online.de",
		"name": "Thomas",
		"createdDate": "2021-07-21 14:00:43",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 72,
		"totalCountries": 4,
		"totalStates": 15,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 689,
		"schedulePercent": 0.376242,
		"totalGuests": 266,
		"guestPercent": 0.587869,
		"mostGuestDate": "2023-04-17 00:00:00",
		"mostGuestName": "Salle Wilfrid-Pelletier, EntrÃ©e des artistes",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 29
	},
	{
		"id": "67bf2f0",
		"email": "carllewis9@icloud.com",
		"name": "Carl",
		"createdDate": "2014-02-09 15:00:56",
		"totalOrganizations": 12,
		"totalTours": 22,
		"totalDays": 860,
		"totalCountries": 13,
		"totalStates": 40,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 1549,
		"schedulePercent": 0.629586,
		"totalGuests": 193,
		"guestPercent": 0.545093,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 42
	},
	{
		"id": "322a99e",
		"email": "nakiabetz1@gmail.com",
		"name": "Nakia",
		"createdDate": "2023-01-20 01:11:56",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 1065,
		"totalCountries": 7,
		"totalStates": 52,
		"totalEvents": 255,
		"eventPercent": 0.880943,
		"totalScheduleItems": 3368,
		"schedulePercent": 0.884783,
		"totalGuests": 644,
		"guestPercent": 0.737253,
		"mostGuestDate": "2023-03-09 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 259
	},
	{
		"id": "5bdbe46",
		"email": "marc@yourtownentertainment.com",
		"name": "Marc",
		"createdDate": "2016-12-08 15:57:18",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 570,
		"totalCountries": 9,
		"totalStates": 48,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 2559,
		"schedulePercent": 0.800424,
		"totalGuests": 1684,
		"guestPercent": 0.911138,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 102
	},
	{
		"id": "833927a",
		"email": "Eric@RareAmericans.com",
		"name": "Eric",
		"createdDate": "2023-01-25 23:36:52",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 66,
		"totalCountries": 8,
		"totalStates": 17,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 228,
		"schedulePercent": 0.168719,
		"totalGuests": 54,
		"guestPercent": 0.427096,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "Fuga",
		"mostGuestCity": "Bratislava-StarÃ© Mesto",
		"mostGuestAmount": 22
	},
	{
		"id": "b7083d0",
		"email": "jenna@jenstarproductions.com",
		"name": "Jenna",
		"createdDate": "2010-02-05 23:00:34",
		"totalOrganizations": 26,
		"totalTours": 47,
		"totalDays": 282,
		"totalCountries": 5,
		"totalStates": 22,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 1251,
		"schedulePercent": 0.552774,
		"totalGuests": 45,
		"guestPercent": 0.412925,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Cervantes' Masterpiece Ballroom & Cervantes' Other Side",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 21
	},
	{
		"id": "329be47",
		"email": "yonta@xixentertainment.com",
		"name": "Now",
		"createdDate": "2018-01-10 19:49:57",
		"totalOrganizations": 10,
		"totalTours": 11,
		"totalDays": 748,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 1249,
		"schedulePercent": 0.551847,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "37b10f4",
		"email": "olie@riverjuke.co.uk",
		"name": "Olie",
		"createdDate": "2023-02-27 18:54:36",
		"totalOrganizations": 10,
		"totalTours": 12,
		"totalDays": 1260,
		"totalCountries": 21,
		"totalStates": 61,
		"totalEvents": 336,
		"eventPercent": 0.935108,
		"totalScheduleItems": 5300,
		"schedulePercent": 0.969805,
		"totalGuests": 1204,
		"guestPercent": 0.8579,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 125
	},
	{
		"id": "a7e0dae",
		"email": "gaberosemata@gmail.com",
		"name": "Gabreanna",
		"createdDate": "2022-09-07 20:20:14",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 237,
		"totalCountries": 8,
		"totalStates": 39,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1999,
		"schedulePercent": 0.718315,
		"totalGuests": 251,
		"guestPercent": 0.579393,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "The Smith Center- Reynolds Hall",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 49
	},
	{
		"id": "28ea0ed",
		"email": "berkley@starstruck.net",
		"name": "Berkley",
		"createdDate": "2015-03-12 18:06:00",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 1090,
		"totalCountries": 3,
		"totalStates": 51,
		"totalEvents": 296,
		"eventPercent": 0.912992,
		"totalScheduleItems": 4656,
		"schedulePercent": 0.94994,
		"totalGuests": 3324,
		"guestPercent": 0.974573,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Paycom Center",
		"mostGuestCity": "Oklahoma City",
		"mostGuestAmount": 167
	},
	{
		"id": "d0e03a7",
		"email": "will.ccpt@gmail.com",
		"name": "Will",
		"createdDate": "2021-11-20 18:18:52",
		"totalOrganizations": 8,
		"totalTours": 35,
		"totalDays": 822,
		"totalCountries": 10,
		"totalStates": 67,
		"totalEvents": 300,
		"eventPercent": 0.915508,
		"totalScheduleItems": 4277,
		"schedulePercent": 0.936962,
		"totalGuests": 1260,
		"guestPercent": 0.866508,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Budweiser Stage",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 44
	},
	{
		"id": "59487ee",
		"email": "seanmagovern@aol.com",
		"name": "Sean",
		"createdDate": "2022-03-12 17:05:49",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 104,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 16,
		"schedulePercent": 0.0288703,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ef01714",
		"email": "slipper@abrownaudio.com",
		"name": "Andrew",
		"createdDate": "2016-09-13 15:59:21",
		"totalOrganizations": 16,
		"totalTours": 12,
		"totalDays": 984,
		"totalCountries": 9,
		"totalStates": 49,
		"totalEvents": 222,
		"eventPercent": 0.839227,
		"totalScheduleItems": 3237,
		"schedulePercent": 0.872732,
		"totalGuests": 1601,
		"guestPercent": 0.903192,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Buffalo Outer Harbor Concert Site",
		"mostGuestCity": "Buffalo",
		"mostGuestAmount": 131
	},
	{
		"id": "ecd80e6",
		"email": "julius@invisiblemgmt.com",
		"name": "Julius",
		"createdDate": "2022-11-12 21:15:22",
		"totalOrganizations": 18,
		"totalTours": 5,
		"totalDays": 106,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 380,
		"schedulePercent": 0.249106,
		"totalGuests": 141,
		"guestPercent": 0.506026,
		"mostGuestDate": "2023-04-11 00:00:00",
		"mostGuestName": "Scotiabank Arena",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 16
	},
	{
		"id": "68ace94",
		"email": "chrismilnesmusic@gmail.com",
		"name": "Chris",
		"createdDate": "2023-09-11 16:09:07",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 140,
		"totalCountries": 9,
		"totalStates": 12,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 510,
		"schedulePercent": 0.304198,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-11-27 00:00:00",
		"mostGuestName": "The Louisiana",
		"mostGuestCity": "Bathurst Terrace",
		"mostGuestAmount": 3
	},
	{
		"id": "dc8b817",
		"email": "dave@davedecrophoto.com",
		"name": "Dave ",
		"createdDate": "2019-12-31 21:56:24",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 205,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 1171,
		"schedulePercent": 0.532512,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "Denver Deluxe Festival",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 8
	},
	{
		"id": "b6ce41f",
		"email": "smelladelphia@gmail.com",
		"name": "Jacob",
		"createdDate": "2014-09-04 07:14:14",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 87,
		"totalCountries": 3,
		"totalStates": 26,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 396,
		"schedulePercent": 0.25586,
		"totalGuests": 363,
		"guestPercent": 0.637134,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 43
	},
	{
		"id": "103f46f",
		"email": "bo@skandinavian.dk",
		"name": "Bo ",
		"createdDate": "2015-05-07 11:38:58",
		"totalOrganizations": 8,
		"totalTours": 2,
		"totalDays": 92,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 519,
		"schedulePercent": 0.307641,
		"totalGuests": 105,
		"guestPercent": 0.480069,
		"mostGuestDate": "2023-02-24 00:00:00",
		"mostGuestName": "VÃ¦rket",
		"mostGuestCity": "Randers",
		"mostGuestAmount": 18
	},
	{
		"id": "b42636b",
		"email": "sean@brixtonagency.com",
		"name": "Sean",
		"createdDate": "2017-04-06 02:16:39",
		"totalOrganizations": 15,
		"totalTours": 22,
		"totalDays": 382,
		"totalCountries": 9,
		"totalStates": 52,
		"totalEvents": 239,
		"eventPercent": 0.860681,
		"totalScheduleItems": 1841,
		"schedulePercent": 0.693815,
		"totalGuests": 280,
		"guestPercent": 0.594623,
		"mostGuestDate": "2023-05-15 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 30
	},
	{
		"id": "0b24e6c",
		"email": "hardytourmanager@gmail.com",
		"name": "Michael",
		"createdDate": "2018-11-26 20:51:05",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 366,
		"totalCountries": 3,
		"totalStates": 37,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 2293,
		"schedulePercent": 0.764667,
		"totalGuests": 1818,
		"guestPercent": 0.921865,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Nashville Municipal Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 92
	},
	{
		"id": "7b854c5",
		"email": "emma@kohai.com",
		"name": "Emma",
		"createdDate": "2022-01-26 22:59:36",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 169,
		"totalCountries": 6,
		"totalStates": 26,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 786,
		"schedulePercent": 0.415309,
		"totalGuests": 463,
		"guestPercent": 0.678321,
		"mostGuestDate": "2023-01-25 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 67
	},
	{
		"id": "eda2649",
		"email": "tmkeith@me.com",
		"name": "Keith",
		"createdDate": "2009-09-04 07:58:03",
		"totalOrganizations": 6,
		"totalTours": 22,
		"totalDays": 207,
		"totalCountries": 10,
		"totalStates": 32,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 1560,
		"schedulePercent": 0.632367,
		"totalGuests": 1002,
		"guestPercent": 0.824262,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 159
	},
	{
		"id": "1aca282",
		"email": "AmpAdmin@sjcfl.us",
		"name": "Amp",
		"createdDate": "2022-08-03 15:48:51",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 129,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 1394,
		"schedulePercent": 0.590253,
		"totalGuests": 45,
		"guestPercent": 0.412925,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "St. Augustine Amphitheatre",
		"mostGuestCity": "St. Augustine",
		"mostGuestAmount": 18
	},
	{
		"id": "6321d50",
		"email": "viatours@viaentertainment.net",
		"name": "Dean",
		"createdDate": "2018-02-07 19:49:28",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3592ce4",
		"email": "Matthew.fish@me.com",
		"name": "Matt",
		"createdDate": "2013-07-05 00:44:23",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 309,
		"totalCountries": 7,
		"totalStates": 50,
		"totalEvents": 175,
		"eventPercent": 0.761753,
		"totalScheduleItems": 2414,
		"schedulePercent": 0.781883,
		"totalGuests": 272,
		"guestPercent": 0.590385,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Rose Bowl Stadium",
		"mostGuestCity": "Pasadena",
		"mostGuestAmount": 45
	},
	{
		"id": "b1ccd29",
		"email": "catherine@thebadloves.com.au",
		"name": "Cath",
		"createdDate": "2018-10-29 09:41:32",
		"totalOrganizations": 11,
		"totalTours": 7,
		"totalDays": 332,
		"totalCountries": 3,
		"totalStates": 11,
		"totalEvents": 202,
		"eventPercent": 0.810091,
		"totalScheduleItems": 1252,
		"schedulePercent": 0.553437,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "Missing Gorilla",
		"mostGuestCity": "Eltham",
		"mostGuestAmount": 2
	},
	{
		"id": "0b24716",
		"email": "scott@scottbiram.com",
		"name": "Scott ",
		"createdDate": "2015-08-07 19:23:23",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 33,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 111,
		"schedulePercent": 0.0966759,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bd8824d",
		"email": "Ashley.Petro@wmg.com",
		"name": "Ashley",
		"createdDate": "2019-08-08 16:52:11",
		"totalOrganizations": 48,
		"totalTours": 44,
		"totalDays": 15011,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 1913,
		"eventPercent": 0.993908,
		"totalScheduleItems": 742,
		"schedulePercent": 0.397431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "25dc138",
		"email": "miresantarosa@gmail.com",
		"name": "Miredys",
		"createdDate": "2017-09-21 23:25:49",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 537,
		"totalCountries": 11,
		"totalStates": 20,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 100,
		"schedulePercent": 0.0900543,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "17e18c5",
		"email": "gerald.isaac.music@gmail.com",
		"name": "Gerald",
		"createdDate": "2018-10-08 08:58:44",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 397,
		"totalCountries": 2,
		"totalStates": 31,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 2434,
		"schedulePercent": 0.784399,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0063b88",
		"email": "hughstonwebb@gmail.com",
		"name": "HUGH",
		"createdDate": "2011-06-23 10:22:15",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 146,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 541,
		"schedulePercent": 0.318236,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Cairns Showgrounds",
		"mostGuestCity": "Parramatta Park",
		"mostGuestAmount": 13
	},
	{
		"id": "823adc9",
		"email": "kumpdennis@gmail.com",
		"name": "Dennis",
		"createdDate": "2015-05-27 13:53:30",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 696,
		"totalCountries": 3,
		"totalStates": 49,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 2190,
		"schedulePercent": 0.748378,
		"totalGuests": 689,
		"guestPercent": 0.750497,
		"mostGuestDate": "2023-03-22 00:00:00",
		"mostGuestName": "The Factory in Deep Ellum",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 39
	},
	{
		"id": "09cc39a",
		"email": "heidi.tuominen@livenation.fi",
		"name": "Heidi",
		"createdDate": "2023-01-26 13:16:57",
		"totalOrganizations": 10,
		"totalTours": 13,
		"totalDays": 152,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 465,
		"schedulePercent": 0.282876,
		"totalGuests": 11,
		"guestPercent": 0.345782,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Salo Friends & Music Festival",
		"mostGuestCity": "Salo",
		"mostGuestAmount": 10
	},
	{
		"id": "dfa9d1d",
		"email": "ryan@spacecamp.la",
		"name": "Ryan",
		"createdDate": "2019-07-24 23:32:49",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 6,
		"totalStates": 34,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 595,
		"schedulePercent": 0.337968,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ca59295",
		"email": "santos.dominick@gmail.com",
		"name": "Dominick",
		"createdDate": "2015-10-11 03:11:03",
		"totalOrganizations": 7,
		"totalTours": 19,
		"totalDays": 373,
		"totalCountries": 20,
		"totalStates": 52,
		"totalEvents": 187,
		"eventPercent": 0.784135,
		"totalScheduleItems": 2600,
		"schedulePercent": 0.807708,
		"totalGuests": 847,
		"guestPercent": 0.787313,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 57
	},
	{
		"id": "eec043a",
		"email": "productionkurt@gmail.com",
		"name": "Kurt",
		"createdDate": "2016-05-22 23:01:01",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 360,
		"totalCountries": 4,
		"totalStates": 39,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 956,
		"schedulePercent": 0.467753,
		"totalGuests": 216,
		"guestPercent": 0.560058,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Heartwood Soundstage",
		"mostGuestCity": "Gainesville",
		"mostGuestAmount": 39
	},
	{
		"id": "09f0f46",
		"email": "assistant@fullsteam.fi",
		"name": "Thomas",
		"createdDate": "2022-02-08 10:32:38",
		"totalOrganizations": 95,
		"totalTours": 76,
		"totalDays": 989,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 983,
		"eventPercent": 0.989803,
		"totalScheduleItems": 3927,
		"schedulePercent": 0.920143,
		"totalGuests": 61,
		"guestPercent": 0.435571,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "On The Rocks",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 10
	},
	{
		"id": "116d27b",
		"email": "csmuktour@sixthemusical.com",
		"name": "Lisa",
		"createdDate": "2022-06-28 19:18:54",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 372,
		"totalCountries": 3,
		"totalStates": 4,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 1534,
		"schedulePercent": 0.626407,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b54b68f",
		"email": "emily@bigloud.com",
		"name": "Emily",
		"createdDate": "2022-01-06 23:44:41",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 1410,
		"totalCountries": 5,
		"totalStates": 61,
		"totalEvents": 411,
		"eventPercent": 0.960138,
		"totalScheduleItems": 5502,
		"schedulePercent": 0.973116,
		"totalGuests": 3221,
		"guestPercent": 0.973646,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "HISTORY",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 117
	},
	{
		"id": "cd04828",
		"email": "Brian@go-time.co.uk",
		"name": "Brian",
		"createdDate": "2019-06-05 09:25:10",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 252,
		"totalCountries": 5,
		"totalStates": 15,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 294,
		"schedulePercent": 0.205006,
		"totalGuests": 244,
		"guestPercent": 0.575288,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "Outernet London",
		"mostGuestCity": "London",
		"mostGuestAmount": 139
	},
	{
		"id": "d62a19f",
		"email": "marie@simonerecords.net",
		"name": "marie",
		"createdDate": "2022-02-28 21:47:40",
		"totalOrganizations": 26,
		"totalTours": 25,
		"totalDays": 471,
		"totalCountries": 4,
		"totalStates": 20,
		"totalEvents": 384,
		"eventPercent": 0.953119,
		"totalScheduleItems": 1973,
		"schedulePercent": 0.714607,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "73d45d7",
		"email": "moderncolorband@gmail.com",
		"name": "Modern",
		"createdDate": "2021-12-07 16:40:42",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 41,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 321,
		"schedulePercent": 0.218779,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "HI-FI Indy",
		"mostGuestCity": "Indianapolis",
		"mostGuestAmount": 6
	},
	{
		"id": "5440d16",
		"email": "projectmanager@diggit.com",
		"name": "Bill ",
		"createdDate": "2022-12-22 16:38:39",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 200,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 711,
		"schedulePercent": 0.384982,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "327680e",
		"email": "brendan@tenatoms.com",
		"name": "email",
		"createdDate": "2019-01-24 17:54:56",
		"totalOrganizations": 10,
		"totalTours": 19,
		"totalDays": 865,
		"totalCountries": 6,
		"totalStates": 56,
		"totalEvents": 507,
		"eventPercent": 0.97497,
		"totalScheduleItems": 4445,
		"schedulePercent": 0.943451,
		"totalGuests": 1335,
		"guestPercent": 0.875778,
		"mostGuestDate": "2023-09-29 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 68
	},
	{
		"id": "d62b8bb",
		"email": "cipaget@hotmail.com",
		"name": "Chris",
		"createdDate": "2016-08-10 11:48:47",
		"totalOrganizations": 8,
		"totalTours": 14,
		"totalDays": 208,
		"totalCountries": 4,
		"totalStates": 13,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 1080,
		"schedulePercent": 0.507747,
		"totalGuests": 312,
		"guestPercent": 0.610648,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "University of New South Wales Roundhouse",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 95
	},
	{
		"id": "2d94683",
		"email": "celine@tomorrowland.com",
		"name": "Celine",
		"createdDate": "2022-09-19 14:07:05",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 65,
		"totalCountries": 7,
		"totalStates": 9,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 565,
		"schedulePercent": 0.326844,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "49cbc90",
		"email": "harri.oksanen@huvipaja.fi",
		"name": "Harri",
		"createdDate": "2012-12-02 13:17:52",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 248,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 96,
		"schedulePercent": 0.086611,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "aaa1bcf",
		"email": "carolyne@fortwilliammanagement.com",
		"name": "Carolyne",
		"createdDate": "2014-08-25 21:53:50",
		"totalOrganizations": 20,
		"totalTours": 20,
		"totalDays": 330,
		"totalCountries": 19,
		"totalStates": 65,
		"totalEvents": 175,
		"eventPercent": 0.761753,
		"totalScheduleItems": 2288,
		"schedulePercent": 0.763872,
		"totalGuests": 972,
		"guestPercent": 0.81764,
		"mostGuestDate": "2023-11-06 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 74
	},
	{
		"id": "f76783f",
		"email": "jay.mighall@me.com",
		"name": "Jay",
		"createdDate": "2014-04-03 19:51:24",
		"totalOrganizations": 5,
		"totalTours": 12,
		"totalDays": 772,
		"totalCountries": 16,
		"totalStates": 41,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 872,
		"schedulePercent": 0.443915,
		"totalGuests": 134,
		"guestPercent": 0.501258,
		"mostGuestDate": "2023-06-06 00:00:00",
		"mostGuestName": "Paradise Rock Club",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 33
	},
	{
		"id": "2f8adea",
		"email": "logistics@bigeasytouring.com",
		"name": "Lee",
		"createdDate": "2023-05-10 12:14:22",
		"totalOrganizations": 8,
		"totalTours": 17,
		"totalDays": 484,
		"totalCountries": 8,
		"totalStates": 18,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 803,
		"schedulePercent": 0.421666,
		"totalGuests": 29,
		"guestPercent": 0.387896,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "The Brighton Centre",
		"mostGuestCity": "Brighton",
		"mostGuestAmount": 15
	},
	{
		"id": "396546b",
		"email": "GreenDoorArtistManagement@gmail.com",
		"name": "Eli ",
		"createdDate": "2022-01-25 14:27:27",
		"totalOrganizations": 12,
		"totalTours": 62,
		"totalDays": 2813,
		"totalCountries": 5,
		"totalStates": 22,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 504,
		"schedulePercent": 0.30049,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0c464fc",
		"email": "nate.erwin@redlightmanagement.com",
		"name": "Nate",
		"createdDate": "2014-01-30 17:17:14",
		"totalOrganizations": 6,
		"totalTours": 32,
		"totalDays": 705,
		"totalCountries": 13,
		"totalStates": 51,
		"totalEvents": 163,
		"eventPercent": 0.735134,
		"totalScheduleItems": 2013,
		"schedulePercent": 0.721626,
		"totalGuests": 385,
		"guestPercent": 0.646934,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "CMA Theater at the Country Music Hall of Fame and Museum",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 41
	},
	{
		"id": "da5128a",
		"email": "Tom@phvx.com",
		"name": "Tom",
		"createdDate": "2013-10-09 18:51:19",
		"totalOrganizations": 18,
		"totalTours": 9,
		"totalDays": 367,
		"totalCountries": 7,
		"totalStates": 71,
		"totalEvents": 163,
		"eventPercent": 0.735134,
		"totalScheduleItems": 2227,
		"schedulePercent": 0.754734,
		"totalGuests": 1026,
		"guestPercent": 0.828367,
		"mostGuestDate": "2023-02-19 00:00:00",
		"mostGuestName": "James L. Knight Center",
		"mostGuestCity": "Miami",
		"mostGuestAmount": 60
	},
	{
		"id": "37c11dc",
		"email": "nick.prebble@googlemail.com",
		"name": "Nick",
		"createdDate": "2018-07-13 08:04:46",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 531,
		"totalCountries": 9,
		"totalStates": 31,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 1261,
		"schedulePercent": 0.555158,
		"totalGuests": 27,
		"guestPercent": 0.38379,
		"mostGuestDate": "2023-09-26 00:00:00",
		"mostGuestName": "Colours Hoxton",
		"mostGuestCity": "London",
		"mostGuestAmount": 27
	},
	{
		"id": "b34e82c",
		"email": "mary.sparr@gmail.com",
		"name": "Mary",
		"createdDate": "2017-04-15 19:38:19",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 111,
		"totalCountries": 7,
		"totalStates": 39,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 650,
		"schedulePercent": 0.362336,
		"totalGuests": 268,
		"guestPercent": 0.588664,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 23
	},
	{
		"id": "ca20618",
		"email": "joshuaallynevans@gmail.com",
		"name": "Josh",
		"createdDate": "2022-04-02 03:07:23",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 549,
		"totalCountries": 2,
		"totalStates": 35,
		"totalEvents": 160,
		"eventPercent": 0.72838,
		"totalScheduleItems": 2143,
		"schedulePercent": 0.741094,
		"totalGuests": 431,
		"guestPercent": 0.66521,
		"mostGuestDate": "2023-03-27 00:00:00",
		"mostGuestName": "City National Grove of Anaheim",
		"mostGuestCity": "Anaheim",
		"mostGuestAmount": 35
	},
	{
		"id": "c8624ed",
		"email": "elleharding01@gmail.com",
		"name": "Ellen",
		"createdDate": "2023-10-12 23:34:39",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 57,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 117,
		"schedulePercent": 0.100914,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8fe7c1f",
		"email": "ivonne@avocado-booking.com",
		"name": "Ivonne",
		"createdDate": "2010-09-23 18:06:31",
		"totalOrganizations": 7,
		"totalTours": 12,
		"totalDays": 281,
		"totalCountries": 11,
		"totalStates": 49,
		"totalEvents": 172,
		"eventPercent": 0.755661,
		"totalScheduleItems": 2391,
		"schedulePercent": 0.779367,
		"totalGuests": 2675,
		"guestPercent": 0.959608,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Forum Melbourne",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 70
	},
	{
		"id": "e2d6c2a",
		"email": "info@trueartist.org",
		"name": "Philip",
		"createdDate": "2015-04-22 22:15:23",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 315,
		"totalCountries": 3,
		"totalStates": 33,
		"totalEvents": 209,
		"eventPercent": 0.82201,
		"totalScheduleItems": 2200,
		"schedulePercent": 0.749437,
		"totalGuests": 2898,
		"guestPercent": 0.968084,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Gas South Arena",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 157
	},
	{
		"id": "b2ee7de",
		"email": "event@husetunderbron.se",
		"name": "Event",
		"createdDate": "2022-04-22 14:43:05",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 194,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 1140,
		"schedulePercent": 0.523507,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "968c7d3",
		"email": "stilettoflatsinc@gmail.com",
		"name": "Ia",
		"createdDate": "2014-04-08 18:23:25",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 360,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 676,
		"schedulePercent": 0.370944,
		"totalGuests": 90,
		"guestPercent": 0.463912,
		"mostGuestDate": "2023-01-26 00:00:00",
		"mostGuestName": "City Winery",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 11
	},
	{
		"id": "e41a79d",
		"email": "hannahchapstouring@gmail.com",
		"name": "Hannah",
		"createdDate": "2022-05-11 13:58:49",
		"totalOrganizations": 8,
		"totalTours": 7,
		"totalDays": 1175,
		"totalCountries": 21,
		"totalStates": 64,
		"totalEvents": 336,
		"eventPercent": 0.935108,
		"totalScheduleItems": 4820,
		"schedulePercent": 0.956032,
		"totalGuests": 1268,
		"guestPercent": 0.867832,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Qudos Bank Arena",
		"mostGuestCity": "Sydney Olympic Park",
		"mostGuestAmount": 67
	},
	{
		"id": "977621a",
		"email": "aarwrob@gmail.com",
		"name": "Aaron",
		"createdDate": "2016-07-08 13:29:36",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 969,
		"totalCountries": 5,
		"totalStates": 53,
		"totalEvents": 192,
		"eventPercent": 0.791683,
		"totalScheduleItems": 2752,
		"schedulePercent": 0.825983,
		"totalGuests": 1365,
		"guestPercent": 0.879354,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "The Tulsa Theater",
		"mostGuestCity": "Tulsa",
		"mostGuestAmount": 147
	},
	{
		"id": "724c540",
		"email": "jake@cardiffgiantmgmt.com",
		"name": "Jake",
		"createdDate": "2022-02-02 19:32:41",
		"totalOrganizations": 7,
		"totalTours": 28,
		"totalDays": 1096,
		"totalCountries": 21,
		"totalStates": 63,
		"totalEvents": 373,
		"eventPercent": 0.949411,
		"totalScheduleItems": 2618,
		"schedulePercent": 0.810754,
		"totalGuests": 1128,
		"guestPercent": 0.844259,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "b9036bb",
		"email": "davidadiaz7@gmail.com",
		"name": "David",
		"createdDate": "2018-02-08 21:32:15",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 317,
		"totalCountries": 19,
		"totalStates": 36,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 937,
		"schedulePercent": 0.461528,
		"totalGuests": 1472,
		"guestPercent": 0.890478,
		"mostGuestDate": "2023-05-07 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 145
	},
	{
		"id": "5f5ef58",
		"email": "anders@bobproduction.dk",
		"name": "Anders",
		"createdDate": "2012-03-26 12:17:50",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 349,
		"totalCountries": 11,
		"totalStates": 36,
		"totalEvents": 327,
		"eventPercent": 0.929413,
		"totalScheduleItems": 2729,
		"schedulePercent": 0.823202,
		"totalGuests": 911,
		"guestPercent": 0.801881,
		"mostGuestDate": "2023-03-12 00:00:00",
		"mostGuestName": "Verti Music Hall",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 63
	},
	{
		"id": "781a799",
		"email": "jadonsherrill@icloud.com",
		"name": "Jadon",
		"createdDate": "2021-10-05 20:25:10",
		"totalOrganizations": 11,
		"totalTours": 13,
		"totalDays": 1299,
		"totalCountries": 1,
		"totalStates": 27,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 3129,
		"schedulePercent": 0.864256,
		"totalGuests": 453,
		"guestPercent": 0.67501,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Texas Trust CU Theatre",
		"mostGuestCity": "Grand Prairie",
		"mostGuestAmount": 69
	},
	{
		"id": "08b57a1",
		"email": "tristankirkbride@gmail.com",
		"name": "Tristan",
		"createdDate": "2022-06-24 20:10:21",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 470,
		"totalCountries": 1,
		"totalStates": 30,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 1055,
		"schedulePercent": 0.499139,
		"totalGuests": 484,
		"guestPercent": 0.684942,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Greensboro Coliseum Complex",
		"mostGuestCity": "Greensboro",
		"mostGuestAmount": 43
	},
	{
		"id": "b1df97f",
		"email": "jessicahelenemary@gmail.com",
		"name": "Jess",
		"createdDate": "2017-07-15 16:16:27",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 37,
		"totalCountries": 6,
		"totalStates": 8,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 263,
		"schedulePercent": 0.187922,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "74b44f8",
		"email": "Kate.Myers@wmg.com",
		"name": "Kate",
		"createdDate": "2017-08-08 19:35:20",
		"totalOrganizations": 52,
		"totalTours": 47,
		"totalDays": 15377,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 2006,
		"eventPercent": 0.996557,
		"totalScheduleItems": 1872,
		"schedulePercent": 0.699775,
		"totalGuests": 26,
		"guestPercent": 0.381274,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Music Farm",
		"mostGuestCity": "Charleston",
		"mostGuestAmount": 7
	},
	{
		"id": "666e948",
		"email": "gregpolsdofer@me.com",
		"name": "Greg",
		"createdDate": "2010-11-18 00:06:14",
		"totalOrganizations": 11,
		"totalTours": 20,
		"totalDays": 2491,
		"totalCountries": 22,
		"totalStates": 96,
		"totalEvents": 487,
		"eventPercent": 0.972322,
		"totalScheduleItems": 6255,
		"schedulePercent": 0.982916,
		"totalGuests": 1824,
		"guestPercent": 0.922659,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "O2 Shepherd's Bush Empire",
		"mostGuestCity": "London",
		"mostGuestAmount": 49
	},
	{
		"id": "7755778",
		"email": "elle@centerstageindustries.com",
		"name": "Elle",
		"createdDate": "2017-06-09 17:39:38",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 666,
		"totalCountries": 13,
		"totalStates": 50,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 1725,
		"schedulePercent": 0.670242,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-02-23 00:00:00",
		"mostGuestName": "Big Night Live",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 6
	},
	{
		"id": "361e703",
		"email": "maxrayhandler@gmail.com",
		"name": "Max",
		"createdDate": "2022-08-10 18:04:48",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 94,
		"totalCountries": 3,
		"totalStates": 24,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 343,
		"schedulePercent": 0.229638,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e22bf0c",
		"email": "ronharris4299@gmail.com",
		"name": "Ron",
		"createdDate": "2019-12-25 04:48:43",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 415,
		"totalCountries": 2,
		"totalStates": 33,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 1794,
		"schedulePercent": 0.68428,
		"totalGuests": 255,
		"guestPercent": 0.58191,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Chester Frost Park",
		"mostGuestCity": "Hixson",
		"mostGuestAmount": 44
	},
	{
		"id": "4f45757",
		"email": "touring@lvjyonline.com",
		"name": "Derek",
		"createdDate": "2023-08-08 18:25:33",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 40,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 298,
		"schedulePercent": 0.207257,
		"totalGuests": 205,
		"guestPercent": 0.554231,
		"mostGuestDate": "2023-09-29 00:00:00",
		"mostGuestName": "Brighton Dome",
		"mostGuestCity": "Brighton",
		"mostGuestAmount": 48
	},
	{
		"id": "0f712fa",
		"email": "tmaxwellmgmt@gmail.com",
		"name": "Max",
		"createdDate": "2022-03-04 21:49:28",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 760,
		"totalCountries": 2,
		"totalStates": 31,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 608,
		"schedulePercent": 0.345252,
		"totalGuests": 30,
		"guestPercent": 0.389485,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Hargray Capitol Theatre",
		"mostGuestCity": "Macon",
		"mostGuestAmount": 26
	},
	{
		"id": "a6e0098",
		"email": "Wesbutler27@gmail.com",
		"name": "Weston",
		"createdDate": "2014-05-05 17:49:24",
		"totalOrganizations": 11,
		"totalTours": 23,
		"totalDays": 840,
		"totalCountries": 4,
		"totalStates": 44,
		"totalEvents": 337,
		"eventPercent": 0.936035,
		"totalScheduleItems": 5979,
		"schedulePercent": 0.980135,
		"totalGuests": 3751,
		"guestPercent": 0.980797,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Gas South Arena",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 157
	},
	{
		"id": "43e2ba0",
		"email": "nmj1@bigpond.com",
		"name": "Nanette",
		"createdDate": "2013-12-30 09:27:46",
		"totalOrganizations": 10,
		"totalTours": 3,
		"totalDays": 91,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 28,
		"schedulePercent": 0.0388028,
		"totalGuests": 303,
		"guestPercent": 0.604158,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "Palais Theatre",
		"mostGuestCity": "St Kilda",
		"mostGuestAmount": 94
	},
	{
		"id": "2c6c8e3",
		"email": "designertraci@gmail.com",
		"name": "TRACI",
		"createdDate": "2020-01-20 23:43:54",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 198,
		"totalCountries": 9,
		"totalStates": 39,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1579,
		"schedulePercent": 0.636472,
		"totalGuests": 1298,
		"guestPercent": 0.87154,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 142
	},
	{
		"id": "fa66831",
		"email": "kris@truzzi.com",
		"name": "Kris",
		"createdDate": "2013-08-15 18:00:31",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 58,
		"totalCountries": 5,
		"totalStates": 16,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 153,
		"schedulePercent": 0.122765,
		"totalGuests": 38,
		"guestPercent": 0.402198,
		"mostGuestDate": "2023-05-10 00:00:00",
		"mostGuestName": "20th Century Theater",
		"mostGuestCity": "Cincinnati",
		"mostGuestAmount": 7
	},
	{
		"id": "a22133d",
		"email": "pippen@interlude-studios.com",
		"name": "George",
		"createdDate": "2019-07-22 19:28:07",
		"totalOrganizations": 13,
		"totalTours": 20,
		"totalDays": 840,
		"totalCountries": 20,
		"totalStates": 63,
		"totalEvents": 222,
		"eventPercent": 0.839227,
		"totalScheduleItems": 3512,
		"schedulePercent": 0.894848,
		"totalGuests": 995,
		"guestPercent": 0.822408,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 130
	},
	{
		"id": "97caa64",
		"email": "larissa@blacksatellite.com",
		"name": "Larissa",
		"createdDate": "2021-11-05 15:10:57",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 77,
		"totalCountries": 1,
		"totalStates": 24,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 335,
		"schedulePercent": 0.225798,
		"totalGuests": 11,
		"guestPercent": 0.345782,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Crafthouse",
		"mostGuestCity": "Pittsburgh",
		"mostGuestAmount": 11
	},
	{
		"id": "1de2fdb",
		"email": "matt@s2pinc.com",
		"name": "Matt",
		"createdDate": "2023-10-27 13:32:57",
		"totalOrganizations": 1,
		"totalTours": 8,
		"totalDays": 26,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 100,
		"schedulePercent": 0.0900543,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6c3c1df",
		"email": "Scoeyj24@gmail.com",
		"name": "Joshua ",
		"createdDate": "2017-01-06 14:13:22",
		"totalOrganizations": 14,
		"totalTours": 9,
		"totalDays": 1109,
		"totalCountries": 15,
		"totalStates": 49,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 1936,
		"schedulePercent": 0.708515,
		"totalGuests": 365,
		"guestPercent": 0.638591,
		"mostGuestDate": "2023-03-07 00:00:00",
		"mostGuestName": "NX Newcastle",
		"mostGuestCity": "Newcastle upon Tyne",
		"mostGuestAmount": 166
	},
	{
		"id": "6c6ffb7",
		"email": "band@kingdomcollapse.com",
		"name": "Aaron",
		"createdDate": "2021-07-20 18:33:28",
		"totalOrganizations": 3,
		"totalTours": 12,
		"totalDays": 329,
		"totalCountries": 10,
		"totalStates": 51,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 2006,
		"schedulePercent": 0.720434,
		"totalGuests": 268,
		"guestPercent": 0.588664,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The Aztec Theatre",
		"mostGuestCity": "San Antonio",
		"mostGuestAmount": 70
	},
	{
		"id": "345dbb8",
		"email": "Leefgomila@gmail.com",
		"name": "Lee",
		"createdDate": "2016-12-27 02:32:52",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 448,
		"totalCountries": 7,
		"totalStates": 49,
		"totalEvents": 157,
		"eventPercent": 0.720567,
		"totalScheduleItems": 2637,
		"schedulePercent": 0.813005,
		"totalGuests": 4035,
		"guestPercent": 0.983843,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 104
	},
	{
		"id": "3923c0e",
		"email": "ssproductionstwo@gmail.com",
		"name": "Nick",
		"createdDate": "2019-10-24 16:22:18",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 380,
		"totalCountries": 2,
		"totalStates": 43,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 825,
		"schedulePercent": 0.428023,
		"totalGuests": 39,
		"guestPercent": 0.404185,
		"mostGuestDate": "2023-03-04 00:00:00",
		"mostGuestName": "Varsity Theater",
		"mostGuestCity": "Minneapolis",
		"mostGuestAmount": 10
	},
	{
		"id": "1aa4cb4",
		"email": "jsuarez191@aol.com",
		"name": "Jack",
		"createdDate": "2010-03-01 23:03:23",
		"totalOrganizations": 6,
		"totalTours": 9,
		"totalDays": 454,
		"totalCountries": 16,
		"totalStates": 77,
		"totalEvents": 225,
		"eventPercent": 0.842008,
		"totalScheduleItems": 3211,
		"schedulePercent": 0.870613,
		"totalGuests": 1413,
		"guestPercent": 0.884121,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "Angel Of The Winds Arena",
		"mostGuestCity": "Everett",
		"mostGuestAmount": 59
	},
	{
		"id": "d6db1b9",
		"email": "uncleluciusmanagement@gmail.com",
		"name": "Tim",
		"createdDate": "2023-02-20 18:32:12",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 95,
		"totalCountries": 1,
		"totalStates": 28,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 533,
		"schedulePercent": 0.314395,
		"totalGuests": 34,
		"guestPercent": 0.395842,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Ophelia's Electric Soapbox",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 15
	},
	{
		"id": "e26b309",
		"email": "michaelTmcgee@comcast.net",
		"name": "Michael",
		"createdDate": "2009-08-10 19:43:39",
		"totalOrganizations": 10,
		"totalTours": 2,
		"totalDays": 70,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 155,
		"schedulePercent": 0.124354,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "62da996",
		"email": "dmonz7@yahoo.com",
		"name": "Daniel",
		"createdDate": "2022-02-24 13:47:01",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 156,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 218,
		"schedulePercent": 0.1617,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ebf25d4",
		"email": "kristin.rinden@gmail.com",
		"name": "Kristin",
		"createdDate": "2016-06-04 03:02:16",
		"totalOrganizations": 9,
		"totalTours": 9,
		"totalDays": 694,
		"totalCountries": 14,
		"totalStates": 50,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 3740,
		"schedulePercent": 0.909151,
		"totalGuests": 1571,
		"guestPercent": 0.900543,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 98
	},
	{
		"id": "692fb0a",
		"email": "timbizzlegibbons@gmail.com",
		"name": "Tim",
		"createdDate": "2023-07-10 19:26:44",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 173,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 275,
		"schedulePercent": 0.195736,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "The Peace Center",
		"mostGuestCity": "Greenville",
		"mostGuestAmount": 7
	},
	{
		"id": "de4e695",
		"email": "tlsauer@gmail.com",
		"name": "Tawnya",
		"createdDate": "2023-10-18 04:05:03",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 55,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 271,
		"schedulePercent": 0.192822,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "22e92b2",
		"email": "Joeysupak@gmail.com",
		"name": "Joey",
		"createdDate": "2014-03-28 15:29:07",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 417,
		"totalCountries": 1,
		"totalStates": 37,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 1833,
		"schedulePercent": 0.692623,
		"totalGuests": 196,
		"guestPercent": 0.546947,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 64
	},
	{
		"id": "9c380b6",
		"email": "chasst@chelseahandler.com",
		"name": "Karla ",
		"createdDate": "2021-09-19 15:11:31",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 134,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 584,
		"schedulePercent": 0.333731,
		"totalGuests": 920,
		"guestPercent": 0.804264,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Pantages Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 215
	},
	{
		"id": "652dabc",
		"email": "mattiwebb@me.com",
		"name": "Matt",
		"createdDate": "2014-03-16 20:52:49",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 28,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 151,
		"schedulePercent": 0.121308,
		"totalGuests": 42,
		"guestPercent": 0.40882,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Softball City",
		"mostGuestCity": "Surrey",
		"mostGuestAmount": 42
	},
	{
		"id": "f4d9648",
		"email": "royal@pbrigging.com",
		"name": "Royal",
		"createdDate": "2019-08-29 22:51:24",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 822,
		"totalCountries": 17,
		"totalStates": 59,
		"totalEvents": 166,
		"eventPercent": 0.74308,
		"totalScheduleItems": 2620,
		"schedulePercent": 0.810886,
		"totalGuests": 1710,
		"guestPercent": 0.913786,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Broncos Stadium at Mile High Station",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 255
	},
	{
		"id": "c5fe3d7",
		"email": "aubrey@feelingsarestupid.com",
		"name": "Aubrey",
		"createdDate": "2022-02-17 05:23:09",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 160,
		"totalCountries": 3,
		"totalStates": 15,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 572,
		"schedulePercent": 0.328963,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "Prudential Center",
		"mostGuestCity": "Newark",
		"mostGuestAmount": 1
	},
	{
		"id": "ae1a6b9",
		"email": "EWFRoadMap@me.com",
		"name": "Raymond",
		"createdDate": "2017-07-12 15:34:19",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 152,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 847,
		"schedulePercent": 0.436631,
		"totalGuests": 1832,
		"guestPercent": 0.923321,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 105
	},
	{
		"id": "ffe8d39",
		"email": "kyokoandrivers@gmail.com",
		"name": "Rivers",
		"createdDate": "2022-06-26 13:03:06",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 692,
		"totalCountries": 5,
		"totalStates": 42,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 1449,
		"schedulePercent": 0.603364,
		"totalGuests": 2303,
		"guestPercent": 0.947954,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "Forest Hills Stadium",
		"mostGuestCity": "Forest Hills",
		"mostGuestAmount": 184
	},
	{
		"id": "696c110",
		"email": "benmullentm@gmail.com",
		"name": "Ben",
		"createdDate": "2019-11-18 11:01:55",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 91,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 341,
		"schedulePercent": 0.228314,
		"totalGuests": 155,
		"guestPercent": 0.51768,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "Marcus Center for the Performing Arts",
		"mostGuestCity": "Milwaukee",
		"mostGuestAmount": 23
	},
	{
		"id": "f634175",
		"email": "mersivtour@gmail.com",
		"name": "Mersiv",
		"createdDate": "2022-07-21 23:11:56",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 127,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 416,
		"schedulePercent": 0.263144,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "The Lincoln",
		"mostGuestCity": "Cheyenne",
		"mostGuestAmount": 3
	},
	{
		"id": "40a4dc9",
		"email": "keithbutlermusic@gmail.com",
		"name": "Keith",
		"createdDate": "2019-01-14 19:45:44",
		"totalOrganizations": 9,
		"totalTours": 12,
		"totalDays": 513,
		"totalCountries": 16,
		"totalStates": 58,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 2069,
		"schedulePercent": 0.729572,
		"totalGuests": 435,
		"guestPercent": 0.666667,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 55
	},
	{
		"id": "e011585",
		"email": "enrique@iconconcerts.com",
		"name": "Enrique",
		"createdDate": "2022-08-23 17:50:10",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 335,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 712,
		"schedulePercent": 0.385512,
		"totalGuests": 156,
		"guestPercent": 0.518607,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Footprint Center",
		"mostGuestCity": "Phoenix",
		"mostGuestAmount": 38
	},
	{
		"id": "c3bd370",
		"email": "fjoajoe@yahoo.com",
		"name": "Joey",
		"createdDate": "2014-09-02 06:35:56",
		"totalOrganizations": 18,
		"totalTours": 11,
		"totalDays": 934,
		"totalCountries": 2,
		"totalStates": 35,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 1063,
		"schedulePercent": 0.501788,
		"totalGuests": 268,
		"guestPercent": 0.588664,
		"mostGuestDate": "2023-07-09 00:00:00",
		"mostGuestName": "Gruene Hall",
		"mostGuestCity": "New Braunfels",
		"mostGuestAmount": 36
	},
	{
		"id": "816a5c9",
		"email": "julius@thegameplanagency.com",
		"name": "Julius",
		"createdDate": "2017-04-27 02:09:31",
		"totalOrganizations": 12,
		"totalTours": 5,
		"totalDays": 331,
		"totalCountries": 3,
		"totalStates": 21,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 737,
		"schedulePercent": 0.394385,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "Bellco Theatre",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 4
	},
	{
		"id": "d2f56a2",
		"email": "hparman@ascot-travel.com",
		"name": "Hayley",
		"createdDate": "2022-09-15 15:23:27",
		"totalOrganizations": 36,
		"totalTours": 44,
		"totalDays": 8896,
		"totalCountries": 17,
		"totalStates": 99,
		"totalEvents": 2197,
		"eventPercent": 0.998278,
		"totalScheduleItems": 33530,
		"schedulePercent": 0.99947,
		"totalGuests": 12763,
		"guestPercent": 0.999603,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 240
	},
	{
		"id": "7340bcb",
		"email": "kemroadmanager@gmail.com",
		"name": "Willie",
		"createdDate": "2021-06-17 22:32:22",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 242,
		"totalCountries": 4,
		"totalStates": 30,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 2731,
		"schedulePercent": 0.8236,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "69fa2ce",
		"email": "paul@prosetouring.com",
		"name": "Paul ",
		"createdDate": "2016-05-29 13:30:15",
		"totalOrganizations": 35,
		"totalTours": 17,
		"totalDays": 2198,
		"totalCountries": 24,
		"totalStates": 49,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 2672,
		"schedulePercent": 0.816713,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1792060",
		"email": "whurnaudio@gmail.com",
		"name": "Will",
		"createdDate": "2019-04-22 17:28:26",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 447,
		"totalCountries": 3,
		"totalStates": 12,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 865,
		"schedulePercent": 0.441531,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-03-15 00:00:00",
		"mostGuestName": "O2 Shepherd's Bush Empire",
		"mostGuestCity": "London",
		"mostGuestAmount": 6
	},
	{
		"id": "656d68e",
		"email": "mark@mttm.co",
		"name": "Mark",
		"createdDate": "2015-12-07 17:37:22",
		"totalOrganizations": 12,
		"totalTours": 26,
		"totalDays": 934,
		"totalCountries": 33,
		"totalStates": 88,
		"totalEvents": 335,
		"eventPercent": 0.934976,
		"totalScheduleItems": 5092,
		"schedulePercent": 0.964508,
		"totalGuests": 1688,
		"guestPercent": 0.9118,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 134
	},
	{
		"id": "236b829",
		"email": "berger@harlemglobetrotters.com",
		"name": "James",
		"createdDate": "2016-03-22 19:26:37",
		"totalOrganizations": 21,
		"totalTours": 7,
		"totalDays": 341,
		"totalCountries": 8,
		"totalStates": 68,
		"totalEvents": 251,
		"eventPercent": 0.873792,
		"totalScheduleItems": 2606,
		"schedulePercent": 0.808502,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "98e5878",
		"email": "alan@travelledmusic.co.uk",
		"name": "Alan",
		"createdDate": "2011-11-01 21:33:41",
		"totalOrganizations": 38,
		"totalTours": 28,
		"totalDays": 718,
		"totalCountries": 23,
		"totalStates": 81,
		"totalEvents": 381,
		"eventPercent": 0.952059,
		"totalScheduleItems": 3243,
		"schedulePercent": 0.873659,
		"totalGuests": 893,
		"guestPercent": 0.797908,
		"mostGuestDate": "2023-10-25 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 122
	},
	{
		"id": "6c13d2f",
		"email": "natewethy@gmail.com",
		"name": "Nate",
		"createdDate": "2014-10-17 03:19:53",
		"totalOrganizations": 22,
		"totalTours": 34,
		"totalDays": 1044,
		"totalCountries": 15,
		"totalStates": 69,
		"totalEvents": 521,
		"eventPercent": 0.976824,
		"totalScheduleItems": 6910,
		"schedulePercent": 0.988611,
		"totalGuests": 4563,
		"guestPercent": 0.988876,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 171
	},
	{
		"id": "77c1ac9",
		"email": "sabkells@gmail.com",
		"name": "Sabrina",
		"createdDate": "2018-06-27 13:11:14",
		"totalOrganizations": 6,
		"totalTours": 20,
		"totalDays": 462,
		"totalCountries": 8,
		"totalStates": 42,
		"totalEvents": 180,
		"eventPercent": 0.770229,
		"totalScheduleItems": 2103,
		"schedulePercent": 0.735929,
		"totalGuests": 351,
		"guestPercent": 0.630513,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 66
	},
	{
		"id": "0cba395",
		"email": "punchy7175@gmail.com",
		"name": "Paul",
		"createdDate": "2018-02-28 22:14:21",
		"totalOrganizations": 5,
		"totalTours": 17,
		"totalDays": 282,
		"totalCountries": 5,
		"totalStates": 32,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 1472,
		"schedulePercent": 0.607734,
		"totalGuests": 91,
		"guestPercent": 0.465236,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Cain's Ballroom",
		"mostGuestCity": "Tulsa",
		"mostGuestAmount": 31
	},
	{
		"id": "fcf35c1",
		"email": "kelly@castingcrowns.com",
		"name": "Kelly",
		"createdDate": "2023-01-09 18:37:42",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 143,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 1715,
		"schedulePercent": 0.668653,
		"totalGuests": 809,
		"guestPercent": 0.778175,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "Macon Centreplex - City Auditorium",
		"mostGuestCity": "Macon",
		"mostGuestAmount": 60
	},
	{
		"id": "f09d2fe",
		"email": "chasecooper371@gmail.com",
		"name": "JT",
		"createdDate": "2019-08-15 18:48:06",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 615,
		"totalCountries": 2,
		"totalStates": 37,
		"totalEvents": 224,
		"eventPercent": 0.841345,
		"totalScheduleItems": 2835,
		"schedulePercent": 0.836181,
		"totalGuests": 292,
		"guestPercent": 0.598596,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Marathon Music Works",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 43
	},
	{
		"id": "0a2e693",
		"email": "tobias@stageandmusic.se",
		"name": "Tobias",
		"createdDate": "2016-01-23 14:53:23",
		"totalOrganizations": 19,
		"totalTours": 19,
		"totalDays": 628,
		"totalCountries": 4,
		"totalStates": 29,
		"totalEvents": 276,
		"eventPercent": 0.898292,
		"totalScheduleItems": 2101,
		"schedulePercent": 0.735532,
		"totalGuests": 645,
		"guestPercent": 0.737651,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Uppsala Konsert & Kongress",
		"mostGuestCity": "Uppsala",
		"mostGuestAmount": 40
	},
	{
		"id": "9a8ad55",
		"email": "me@michaelkeithlewis.com",
		"name": "Michael",
		"createdDate": "2021-06-05 20:57:15",
		"totalOrganizations": 21,
		"totalTours": 34,
		"totalDays": 2065,
		"totalCountries": 15,
		"totalStates": 75,
		"totalEvents": 655,
		"eventPercent": 0.984373,
		"totalScheduleItems": 8545,
		"schedulePercent": 0.995232,
		"totalGuests": 4122,
		"guestPercent": 0.984903,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 104
	},
	{
		"id": "242b927",
		"email": "gideon.hogan@me.com",
		"name": "Gideon",
		"createdDate": "2023-06-01 20:27:50",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 87,
		"totalCountries": 7,
		"totalStates": 15,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 554,
		"schedulePercent": 0.323136,
		"totalGuests": 529,
		"guestPercent": 0.701894,
		"mostGuestDate": "2023-06-09 00:00:00",
		"mostGuestName": "Dreamland Margate - Scenic Stage",
		"mostGuestCity": "Margate",
		"mostGuestAmount": 77
	},
	{
		"id": "6c3b549",
		"email": "tours@charliekenny.com",
		"name": "Charlie",
		"createdDate": "2019-06-19 01:03:04",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 108,
		"totalCountries": 11,
		"totalStates": 22,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 452,
		"schedulePercent": 0.277447,
		"totalGuests": 143,
		"guestPercent": 0.508277,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Pepsi Center WTC",
		"mostGuestCity": "Benito Juarez",
		"mostGuestAmount": 31
	},
	{
		"id": "c7884c1",
		"email": "dadproductiondk@gmail.com",
		"name": "D-A-D",
		"createdDate": "2021-05-04 13:25:29",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 66,
		"totalCountries": 3,
		"totalStates": 6,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 450,
		"schedulePercent": 0.27652,
		"totalGuests": 19,
		"guestPercent": 0.366971,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "Rotationen",
		"mostGuestCity": "HolbÃ¦k",
		"mostGuestAmount": 8
	},
	{
		"id": "2e0febc",
		"email": "produccioncarlos@gmail.com",
		"name": "Carlos",
		"createdDate": "2017-06-06 23:28:59",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 175,
		"totalCountries": 10,
		"totalStates": 32,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 861,
		"schedulePercent": 0.440339,
		"totalGuests": 928,
		"guestPercent": 0.806251,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Glen Helen Amphitheater",
		"mostGuestCity": "San Bernardino",
		"mostGuestAmount": 201
	},
	{
		"id": "3ef1702",
		"email": "lutonpete@googlemail.com",
		"name": "Pete",
		"createdDate": "2014-07-09 14:12:02",
		"totalOrganizations": 14,
		"totalTours": 22,
		"totalDays": 705,
		"totalCountries": 15,
		"totalStates": 37,
		"totalEvents": 176,
		"eventPercent": 0.763475,
		"totalScheduleItems": 2637,
		"schedulePercent": 0.813005,
		"totalGuests": 870,
		"guestPercent": 0.792345,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Motorpoint Arena Nottingham",
		"mostGuestCity": "Nottingham",
		"mostGuestAmount": 58
	},
	{
		"id": "d37e62f",
		"email": "aubrey@awrightworld.com",
		"name": "Aubrey",
		"createdDate": "2019-08-08 21:20:29",
		"totalOrganizations": 17,
		"totalTours": 5,
		"totalDays": 332,
		"totalCountries": 15,
		"totalStates": 47,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1439,
		"schedulePercent": 0.60151,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e6074a6",
		"email": "paulchanon@bellsouth.net",
		"name": "Paul ",
		"createdDate": "2009-09-10 18:34:06",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 135,
		"totalCountries": 6,
		"totalStates": 30,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 1780,
		"schedulePercent": 0.680969,
		"totalGuests": 4325,
		"guestPercent": 0.985962,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Fenway Park",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 322
	},
	{
		"id": "e07bebd",
		"email": "joeman.11@yahoo.com",
		"name": "Joey",
		"createdDate": "2018-09-07 20:18:00",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 90,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 428,
		"schedulePercent": 0.267117,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Ironstone Amphitheatre",
		"mostGuestCity": "Murphys",
		"mostGuestAmount": 4
	},
	{
		"id": "f687d6b",
		"email": "kyle.connors@gmx.com",
		"name": "Kyle",
		"createdDate": "2022-08-01 20:19:10",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 179,
		"totalCountries": 8,
		"totalStates": 33,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 1312,
		"schedulePercent": 0.569064,
		"totalGuests": 1118,
		"guestPercent": 0.842273,
		"mostGuestDate": "2023-03-21 00:00:00",
		"mostGuestName": "The SSE Arena, Wembley",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 73
	},
	{
		"id": "ba27f50",
		"email": "philipp.palm@laughandpeas.de",
		"name": "Philipp",
		"createdDate": "2017-11-27 12:58:58",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 87,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 639,
		"schedulePercent": 0.357966,
		"totalGuests": 524,
		"guestPercent": 0.699775,
		"mostGuestDate": "2023-07-01 00:00:00",
		"mostGuestName": "Stadtpark",
		"mostGuestCity": "Hamburg",
		"mostGuestAmount": 123
	},
	{
		"id": "bb09b84",
		"email": "karlyhartzman1@gmail.com",
		"name": "Karly",
		"createdDate": "2022-04-04 12:19:26",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 214,
		"totalCountries": 10,
		"totalStates": 46,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 993,
		"schedulePercent": 0.482188,
		"totalGuests": 487,
		"guestPercent": 0.686929,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Union Transfer",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 36
	},
	{
		"id": "605e746",
		"email": "theinvisiblecity@gmail.com",
		"name": "Michael",
		"createdDate": "2015-08-06 09:26:35",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 163,
		"totalCountries": 4,
		"totalStates": 39,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 949,
		"schedulePercent": 0.465236,
		"totalGuests": 925,
		"guestPercent": 0.805191,
		"mostGuestDate": "2023-06-01 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 55
	},
	{
		"id": "15690ab",
		"email": "mbclewis@me.com",
		"name": "Martin",
		"createdDate": "2012-07-30 17:45:40",
		"totalOrganizations": 8,
		"totalTours": 12,
		"totalDays": 266,
		"totalCountries": 6,
		"totalStates": 54,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 1513,
		"schedulePercent": 0.621507,
		"totalGuests": 569,
		"guestPercent": 0.715799,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "The Factory",
		"mostGuestCity": "Chesterfield",
		"mostGuestAmount": 29
	},
	{
		"id": "608a09b",
		"email": "sebastian@octane-entertainment.com",
		"name": "Sebastian",
		"createdDate": "2023-08-09 04:23:25",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 48,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 321,
		"schedulePercent": 0.218779,
		"totalGuests": 45,
		"guestPercent": 0.412925,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "University of New South Wales Roundhouse",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 28
	},
	{
		"id": "c5b52b8",
		"email": "dennis@qprime.com",
		"name": "Dennis ",
		"createdDate": "2015-12-30 18:35:29",
		"totalOrganizations": 43,
		"totalTours": 57,
		"totalDays": 1407,
		"totalCountries": 20,
		"totalStates": 98,
		"totalEvents": 657,
		"eventPercent": 0.984505,
		"totalScheduleItems": 9550,
		"schedulePercent": 0.996557,
		"totalGuests": 5023,
		"guestPercent": 0.992054,
		"mostGuestDate": "2023-10-02 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 405
	},
	{
		"id": "d929c0b",
		"email": "info.flatblack@gmail.com",
		"name": "Rienk",
		"createdDate": "2016-02-22 01:56:21",
		"totalOrganizations": 7,
		"totalTours": 2,
		"totalDays": 157,
		"totalCountries": 6,
		"totalStates": 36,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 1013,
		"schedulePercent": 0.488942,
		"totalGuests": 541,
		"guestPercent": 0.706264,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 50
	},
	{
		"id": "093ac10",
		"email": "markbentmusic@gmail.com",
		"name": "Mark",
		"createdDate": "2013-09-26 18:01:51",
		"totalOrganizations": 20,
		"totalTours": 59,
		"totalDays": 277,
		"totalCountries": 14,
		"totalStates": 34,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 1508,
		"schedulePercent": 0.62005,
		"totalGuests": 591,
		"guestPercent": 0.722818,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Finsbury Park",
		"mostGuestCity": "London",
		"mostGuestAmount": 60
	},
	{
		"id": "e11040a",
		"email": "ldworrell@gmail.com",
		"name": "Logan Dean",
		"createdDate": "2019-06-16 15:27:56",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 105,
		"totalCountries": 9,
		"totalStates": 39,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 699,
		"schedulePercent": 0.380215,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-05-02 00:00:00",
		"mostGuestName": "The Woodward Theater",
		"mostGuestCity": "Cincinnati",
		"mostGuestAmount": 14
	},
	{
		"id": "3621e7e",
		"email": "sun@riverjuke.co.uk",
		"name": "Sun",
		"createdDate": "2023-08-15 16:55:20",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 505,
		"totalCountries": 11,
		"totalStates": 37,
		"totalEvents": 134,
		"eventPercent": 0.658721,
		"totalScheduleItems": 2392,
		"schedulePercent": 0.779764,
		"totalGuests": 820,
		"guestPercent": 0.780691,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 125
	},
	{
		"id": "099e23c",
		"email": "iacopo.bigagli@gmail.com",
		"name": "Iacopo",
		"createdDate": "2023-04-29 00:43:23",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 35,
		"totalCountries": 8,
		"totalStates": 12,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 203,
		"schedulePercent": 0.152165,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e4f29eb",
		"email": "stephanie.admin@averysunshine.com",
		"name": "Stephanie",
		"createdDate": "2022-08-22 19:59:17",
		"totalOrganizations": 2,
		"totalTours": 69,
		"totalDays": 174,
		"totalCountries": 1,
		"totalStates": 26,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 1064,
		"schedulePercent": 0.502318,
		"totalGuests": 164,
		"guestPercent": 0.526155,
		"mostGuestDate": "2023-05-03 00:00:00",
		"mostGuestName": "Yoshi's Oakland",
		"mostGuestCity": "Oakland",
		"mostGuestAmount": 11
	},
	{
		"id": "95a4ab4",
		"email": "ness.geraldtm@gmail.com",
		"name": "Gerald",
		"createdDate": "2013-08-22 20:22:44",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 186,
		"totalCountries": 14,
		"totalStates": 35,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 971,
		"schedulePercent": 0.47252,
		"totalGuests": 307,
		"guestPercent": 0.607072,
		"mostGuestDate": "2023-06-09 00:00:00",
		"mostGuestName": "Irving Plaza",
		"mostGuestCity": "New York",
		"mostGuestAmount": 50
	},
	{
		"id": "42d1511",
		"email": "weareseaforth@gmail.com",
		"name": "",
		"createdDate": "2021-07-23 12:51:02",
		"totalOrganizations": 9,
		"totalTours": 19,
		"totalDays": 1371,
		"totalCountries": 8,
		"totalStates": 42,
		"totalEvents": 226,
		"eventPercent": 0.843464,
		"totalScheduleItems": 3836,
		"schedulePercent": 0.914713,
		"totalGuests": 705,
		"guestPercent": 0.75447,
		"mostGuestDate": "2023-04-19 00:00:00",
		"mostGuestName": "The Mill & Mine",
		"mostGuestCity": "Knoxville",
		"mostGuestAmount": 49
	},
	{
		"id": "3495341",
		"email": "wesley@thewesleydavis.com",
		"name": "Wesley",
		"createdDate": "2017-02-27 18:12:09",
		"totalOrganizations": 13,
		"totalTours": 13,
		"totalDays": 814,
		"totalCountries": 3,
		"totalStates": 42,
		"totalEvents": 283,
		"eventPercent": 0.904516,
		"totalScheduleItems": 4881,
		"schedulePercent": 0.957622,
		"totalGuests": 3794,
		"guestPercent": 0.981592,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 293
	},
	{
		"id": "e41d72e",
		"email": "holly@hollyturnerphoto.com",
		"name": "Holly",
		"createdDate": "2022-03-08 22:51:25",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 115,
		"totalCountries": 1,
		"totalStates": 35,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 794,
		"schedulePercent": 0.41809,
		"totalGuests": 199,
		"guestPercent": 0.549729,
		"mostGuestDate": "2023-05-08 00:00:00",
		"mostGuestName": "The End",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 28
	},
	{
		"id": "10bd1c1",
		"email": "mkellman24@gmail.com",
		"name": "Mitchell",
		"createdDate": "2015-01-06 18:40:22",
		"totalOrganizations": 5,
		"totalTours": 12,
		"totalDays": 359,
		"totalCountries": 11,
		"totalStates": 50,
		"totalEvents": 175,
		"eventPercent": 0.761753,
		"totalScheduleItems": 4144,
		"schedulePercent": 0.930605,
		"totalGuests": 737,
		"guestPercent": 0.761224,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 178
	},
	{
		"id": "b1b7e1b",
		"email": "morgan.durand@r-cube.tv",
		"name": "Morgan",
		"createdDate": "2023-09-21 12:32:35",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 111,
		"totalCountries": 3,
		"totalStates": 15,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 907,
		"schedulePercent": 0.45398,
		"totalGuests": 31,
		"guestPercent": 0.390942,
		"mostGuestDate": "2023-11-21 00:00:00",
		"mostGuestName": "Cirque Royal",
		"mostGuestCity": "Bruxelles",
		"mostGuestAmount": 17
	},
	{
		"id": "2d652f8",
		"email": "carolljd@yahoo.com",
		"name": "Caroll",
		"createdDate": "2012-04-13 17:05:32",
		"totalOrganizations": 20,
		"totalTours": 16,
		"totalDays": 1481,
		"totalCountries": 6,
		"totalStates": 53,
		"totalEvents": 378,
		"eventPercent": 0.950603,
		"totalScheduleItems": 3604,
		"schedulePercent": 0.900808,
		"totalGuests": 578,
		"guestPercent": 0.71858,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Budweiser Stage",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 44
	},
	{
		"id": "f959635",
		"email": "isaiahelixaudio@gmail.com",
		"name": "Isaiah",
		"createdDate": "2021-11-07 20:54:18",
		"totalOrganizations": 10,
		"totalTours": 10,
		"totalDays": 293,
		"totalCountries": 4,
		"totalStates": 41,
		"totalEvents": 174,
		"eventPercent": 0.759502,
		"totalScheduleItems": 2499,
		"schedulePercent": 0.793272,
		"totalGuests": 1395,
		"guestPercent": 0.882135,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 70
	},
	{
		"id": "f42a8ca",
		"email": "steve@shenandoahband.com",
		"name": "Steve",
		"createdDate": "2022-05-16 16:10:29",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 25,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 623,
		"schedulePercent": 0.350682,
		"totalGuests": 447,
		"guestPercent": 0.672096,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Billy Bob's Texas",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 46
	},
	{
		"id": "b6ec195",
		"email": "twigens@me.com",
		"name": "Tony",
		"createdDate": "2014-05-21 19:38:14",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 153,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 302,
		"schedulePercent": 0.209244,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bef9683",
		"email": "info@iemusic.co.uk",
		"name": "Andre",
		"createdDate": "2018-09-27 05:25:52",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 83,
		"totalCountries": 8,
		"totalStates": 15,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 258,
		"schedulePercent": 0.184744,
		"totalGuests": 10,
		"guestPercent": 0.343531,
		"mostGuestDate": "2023-06-11 00:00:00",
		"mostGuestName": "La Boule Noire",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 5
	},
	{
		"id": "6679eb3",
		"email": "archechoband@gmail.com",
		"name": "Joey",
		"createdDate": "2019-09-04 07:06:34",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 67,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 207,
		"schedulePercent": 0.154417,
		"totalGuests": 45,
		"guestPercent": 0.412925,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "Elsewhere",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 12
	},
	{
		"id": "ea94ac8",
		"email": "aljudd@aljudd.com",
		"name": "Al",
		"createdDate": "2013-02-13 15:50:04",
		"totalOrganizations": 13,
		"totalTours": 8,
		"totalDays": 266,
		"totalCountries": 7,
		"totalStates": 20,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 885,
		"schedulePercent": 0.44802,
		"totalGuests": 666,
		"guestPercent": 0.742948,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 108
	},
	{
		"id": "3677cf0",
		"email": "Tenenbaum.asst@cmpent.com",
		"name": "Abigail",
		"createdDate": "2013-04-15 19:08:21",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 1204,
		"totalCountries": 1,
		"totalStates": 30,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1330,
		"schedulePercent": 0.575023,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "26fce3f",
		"email": "melissa@salxco.com",
		"name": "Melissa",
		"createdDate": "2017-02-28 19:19:43",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 922,
		"totalCountries": 18,
		"totalStates": 51,
		"totalEvents": 389,
		"eventPercent": 0.954311,
		"totalScheduleItems": 4505,
		"schedulePercent": 0.945305,
		"totalGuests": 6934,
		"guestPercent": 0.997616,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Wembley Stadium",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 625
	},
	{
		"id": "e4c78b7",
		"email": "Mattbod@me.com",
		"name": "Matt ",
		"createdDate": "2019-04-09 19:51:56",
		"totalOrganizations": 5,
		"totalTours": 12,
		"totalDays": 125,
		"totalCountries": 1,
		"totalStates": 33,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 966,
		"schedulePercent": 0.469872,
		"totalGuests": 347,
		"guestPercent": 0.627467,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "White Eagle Hall",
		"mostGuestCity": "Jersey City",
		"mostGuestAmount": 41
	},
	{
		"id": "3162776",
		"email": "Jonatan.Duran@GetIn.com.mx",
		"name": "Jonatan",
		"createdDate": "2018-05-30 10:46:37",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 1009,
		"totalCountries": 8,
		"totalStates": 59,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 914,
		"schedulePercent": 0.455569,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "690f6bc",
		"email": "bittersweetmanagement@stryper.com",
		"name": "Lisa",
		"createdDate": "2018-03-04 00:39:42",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 360,
		"totalCountries": 3,
		"totalStates": 34,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 891,
		"schedulePercent": 0.450007,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "37e4f35",
		"email": "taylor.jenae@icloud.com",
		"name": "Taylor",
		"createdDate": "2018-12-31 22:15:45",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 696,
		"totalCountries": 3,
		"totalStates": 24,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 2154,
		"schedulePercent": 0.74308,
		"totalGuests": 987,
		"guestPercent": 0.820554,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Nissan Stadium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 90
	},
	{
		"id": "16fb0d2",
		"email": "bigbenaudio@me.com",
		"name": "Ben",
		"createdDate": "2013-04-24 12:44:00",
		"totalOrganizations": 19,
		"totalTours": 15,
		"totalDays": 696,
		"totalCountries": 15,
		"totalStates": 40,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 1484,
		"schedulePercent": 0.610383,
		"totalGuests": 124,
		"guestPercent": 0.493445,
		"mostGuestDate": "2023-08-27 00:00:00",
		"mostGuestName": "Carfest South",
		"mostGuestCity": "Hampshire",
		"mostGuestAmount": 24
	},
	{
		"id": "f5bf4eb",
		"email": "claire.newbegin@gmail.com",
		"name": "Claire",
		"createdDate": "2023-05-18 09:24:41",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 229,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 181,
		"schedulePercent": 0.139054,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "aea5b8e",
		"email": "andyshauf@kelpmanagement.com",
		"name": "Andy ",
		"createdDate": "2023-02-08 16:11:25",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 204,
		"totalCountries": 7,
		"totalStates": 24,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 135,
		"schedulePercent": 0.110317,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "874f405",
		"email": "egruen90@gmail.com",
		"name": "Elliott",
		"createdDate": "2022-10-09 08:44:20",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 162,
		"totalCountries": 3,
		"totalStates": 43,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 974,
		"schedulePercent": 0.473977,
		"totalGuests": 757,
		"guestPercent": 0.766124,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "The Observatory",
		"mostGuestCity": "Santa Ana",
		"mostGuestAmount": 75
	},
	{
		"id": "f3bd506",
		"email": "ch@kaika.dk",
		"name": "Email",
		"createdDate": "2015-06-22 08:24:39",
		"totalOrganizations": 19,
		"totalTours": 10,
		"totalDays": 93,
		"totalCountries": 3,
		"totalStates": 4,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 767,
		"schedulePercent": 0.408025,
		"totalGuests": 40,
		"guestPercent": 0.405642,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "Det Kongelige Teater",
		"mostGuestCity": "KÃ¸benhavn K",
		"mostGuestAmount": 17
	},
	{
		"id": "9ea25f6",
		"email": "brentcobbtm@gmail.com",
		"name": "Brent",
		"createdDate": "2021-12-01 14:38:22",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 437,
		"totalCountries": 2,
		"totalStates": 40,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 698,
		"schedulePercent": 0.37995,
		"totalGuests": 17,
		"guestPercent": 0.362469,
		"mostGuestDate": "2023-02-09 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 10
	},
	{
		"id": "60e7c74",
		"email": "jdussek@cityparksfoundation.org",
		"name": "Josy",
		"createdDate": "2010-05-14 18:15:06",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f5a22a8",
		"email": "schmootzj@hotmail.com",
		"name": "Jay",
		"createdDate": "2021-10-15 03:49:59",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 148,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 1053,
		"schedulePercent": 0.498477,
		"totalGuests": 1456,
		"guestPercent": 0.888624,
		"mostGuestDate": "2023-03-01 00:00:00",
		"mostGuestName": "Houston Livestock Show and Rodeo",
		"mostGuestCity": "Houston",
		"mostGuestAmount": 100
	},
	{
		"id": "7bb48c9",
		"email": "s_richesin@yahoo.com",
		"name": "Shawn",
		"createdDate": "2017-01-09 20:22:37",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 144,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 1600,
		"schedulePercent": 0.642829,
		"totalGuests": 2760,
		"guestPercent": 0.963184,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 104
	},
	{
		"id": "e3a8e32",
		"email": "toniosound@gmail.com",
		"name": "ANTONY",
		"createdDate": "2022-10-18 15:56:15",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 318,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 721,
		"schedulePercent": 0.38869,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cc2b442",
		"email": "angelina@highwayholidays.eu",
		"name": "Angelina",
		"createdDate": "2022-02-20 18:45:57",
		"totalOrganizations": 10,
		"totalTours": 17,
		"totalDays": 566,
		"totalCountries": 17,
		"totalStates": 75,
		"totalEvents": 249,
		"eventPercent": 0.870746,
		"totalScheduleItems": 2347,
		"schedulePercent": 0.773672,
		"totalGuests": 421,
		"guestPercent": 0.662032,
		"mostGuestDate": "2023-04-27 00:00:00",
		"mostGuestName": "PICA (Port Melbourne Industrial Centre for the Arts)",
		"mostGuestCity": "Port Melbourne",
		"mostGuestAmount": 34
	},
	{
		"id": "4d09ab1",
		"email": "chadsmithhomeward@gmail.com",
		"name": "Chad",
		"createdDate": "2017-09-19 13:56:39",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 330,
		"totalCountries": 1,
		"totalStates": 38,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 1712,
		"schedulePercent": 0.667991,
		"totalGuests": 337,
		"guestPercent": 0.621375,
		"mostGuestDate": "2023-05-27 00:00:00",
		"mostGuestName": "The Caverns",
		"mostGuestCity": "Pelham",
		"mostGuestAmount": 30
	},
	{
		"id": "4fe2e76",
		"email": "john.rupp.audio@gmail.com",
		"name": "John",
		"createdDate": "2013-01-17 09:50:16",
		"totalOrganizations": 14,
		"totalTours": 9,
		"totalDays": 1384,
		"totalCountries": 11,
		"totalStates": 57,
		"totalEvents": 278,
		"eventPercent": 0.900278,
		"totalScheduleItems": 2703,
		"schedulePercent": 0.819097,
		"totalGuests": 676,
		"guestPercent": 0.746259,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 66
	},
	{
		"id": "7fffc5a",
		"email": "anthonypaterno@me.com",
		"name": "Anthony",
		"createdDate": "2013-03-15 19:37:05",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 73,
		"totalCountries": 11,
		"totalStates": 20,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 342,
		"schedulePercent": 0.228976,
		"totalGuests": 10,
		"guestPercent": 0.343531,
		"mostGuestDate": "2023-09-05 00:00:00",
		"mostGuestName": "Cal Coast Credit Union Amphitheater",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 9
	},
	{
		"id": "79aae77",
		"email": "llmcarthy@me.com",
		"name": "Liam",
		"createdDate": "2017-02-16 18:15:08",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 65,
		"totalCountries": 3,
		"totalStates": 20,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 491,
		"schedulePercent": 0.293206,
		"totalGuests": 267,
		"guestPercent": 0.588399,
		"mostGuestDate": "2023-02-21 00:00:00",
		"mostGuestName": "Millennium Forum Theatre & Conference Centre",
		"mostGuestCity": "Londonderry",
		"mostGuestAmount": 40
	},
	{
		"id": "508efd6",
		"email": "grantsimmon@hotmail.com",
		"name": "Grant",
		"createdDate": "2021-08-29 16:38:51",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 239,
		"totalCountries": 3,
		"totalStates": 20,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 1139,
		"schedulePercent": 0.523242,
		"totalGuests": 113,
		"guestPercent": 0.485366,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "Riverfront Park Amphitheater",
		"mostGuestCity": "Wilmington",
		"mostGuestAmount": 49
	},
	{
		"id": "b0a6ccd",
		"email": "johnwilliams@jwtouring.com",
		"name": "John",
		"createdDate": "2013-01-15 22:05:22",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 875,
		"totalCountries": 12,
		"totalStates": 49,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 4476,
		"schedulePercent": 0.944378,
		"totalGuests": 1892,
		"guestPercent": 0.927957,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 98
	},
	{
		"id": "17b67f5",
		"email": "jamal@backlashproductions.com",
		"name": "Jamal",
		"createdDate": "2021-07-09 16:14:13",
		"totalOrganizations": 14,
		"totalTours": 42,
		"totalDays": 741,
		"totalCountries": 21,
		"totalStates": 74,
		"totalEvents": 227,
		"eventPercent": 0.844391,
		"totalScheduleItems": 3072,
		"schedulePercent": 0.858429,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fc6fdec",
		"email": "petebernhard79@gmail.com",
		"name": "Pete",
		"createdDate": "2015-09-13 19:24:34",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 35,
		"totalCountries": 3,
		"totalStates": 6,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 206,
		"schedulePercent": 0.153754,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-06-06 00:00:00",
		"mostGuestName": "Frannz Club",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 16
	},
	{
		"id": "9c6e3cd",
		"email": "trollpuke@hotmail.com",
		"name": "Philip",
		"createdDate": "2021-08-16 06:34:34",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 118,
		"totalCountries": 7,
		"totalStates": 15,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 1182,
		"schedulePercent": 0.535293,
		"totalGuests": 1188,
		"guestPercent": 0.855383,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The ICON Festival Stage",
		"mostGuestCity": "Cincinnati",
		"mostGuestAmount": 238
	},
	{
		"id": "4487626",
		"email": "ducruband@gmail.com",
		"name": "Andrew",
		"createdDate": "2015-09-17 21:30:25",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 669,
		"totalCountries": 9,
		"totalStates": 43,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 1887,
		"schedulePercent": 0.702821,
		"totalGuests": 1164,
		"guestPercent": 0.851808,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 93
	},
	{
		"id": "4ab5fa8",
		"email": "vix.simpson@icloud.com",
		"name": "Vix",
		"createdDate": "2017-07-15 17:56:51",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 141,
		"totalCountries": 5,
		"totalStates": 15,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 640,
		"schedulePercent": 0.358496,
		"totalGuests": 398,
		"guestPercent": 0.653026,
		"mostGuestDate": "2023-05-02 00:00:00",
		"mostGuestName": "Avant Gardner",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 60
	},
	{
		"id": "092c8b5",
		"email": "brandonepstein@gmail.com",
		"name": "Brandon",
		"createdDate": "2019-12-02 19:33:24",
		"totalOrganizations": 9,
		"totalTours": 16,
		"totalDays": 521,
		"totalCountries": 9,
		"totalStates": 43,
		"totalEvents": 141,
		"eventPercent": 0.678321,
		"totalScheduleItems": 1551,
		"schedulePercent": 0.63038,
		"totalGuests": 574,
		"guestPercent": 0.718051,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 75
	},
	{
		"id": "8bc5c16",
		"email": "adam@theproductionhouse.co.uk",
		"name": "Adam",
		"createdDate": "2019-01-05 23:57:40",
		"totalOrganizations": 6,
		"totalTours": 11,
		"totalDays": 115,
		"totalCountries": 4,
		"totalStates": 9,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 1015,
		"schedulePercent": 0.489736,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "656f912",
		"email": "grant@straightxnarrow.com",
		"name": "Grant",
		"createdDate": "2017-09-17 02:47:11",
		"totalOrganizations": 5,
		"totalTours": 15,
		"totalDays": 328,
		"totalCountries": 4,
		"totalStates": 38,
		"totalEvents": 134,
		"eventPercent": 0.658721,
		"totalScheduleItems": 1155,
		"schedulePercent": 0.528142,
		"totalGuests": 417,
		"guestPercent": 0.660177,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 52
	},
	{
		"id": "a81cf37",
		"email": "nick.john.rayner@gmail.com",
		"name": "Nick",
		"createdDate": "2018-10-01 11:14:38",
		"totalOrganizations": 10,
		"totalTours": 20,
		"totalDays": 389,
		"totalCountries": 10,
		"totalStates": 41,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1098,
		"schedulePercent": 0.511323,
		"totalGuests": 252,
		"guestPercent": 0.58032,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "The Danforth Music Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 54
	},
	{
		"id": "8525801",
		"email": "bree@sadiejean.com",
		"name": "Bree",
		"createdDate": "2023-02-04 22:23:21",
		"totalOrganizations": 2,
		"totalTours": 7,
		"totalDays": 181,
		"totalCountries": 9,
		"totalStates": 24,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 798,
		"schedulePercent": 0.419547,
		"totalGuests": 251,
		"guestPercent": 0.579393,
		"mostGuestDate": "2023-03-20 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 69
	},
	{
		"id": "260bff7",
		"email": "erinjmasuda@gmail.com",
		"name": "Erin",
		"createdDate": "2012-04-07 21:10:00",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 142,
		"totalCountries": 5,
		"totalStates": 10,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 581,
		"schedulePercent": 0.332406,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2cad7b5",
		"email": "ricky@parkwood-ent.com",
		"name": "Ricky",
		"createdDate": "2021-09-06 18:30:49",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 529,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 712,
		"schedulePercent": 0.385512,
		"totalGuests": 375,
		"guestPercent": 0.643358,
		"mostGuestDate": "2023-05-03 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 125
	},
	{
		"id": "e4cacd3",
		"email": "Paul@rainmakerartists.com",
		"name": "Paul",
		"createdDate": "2012-02-17 19:29:57",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 291,
		"totalCountries": 7,
		"totalStates": 53,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 1763,
		"schedulePercent": 0.677129,
		"totalGuests": 64,
		"guestPercent": 0.439015,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "The Danforth Music Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 8
	},
	{
		"id": "7c1d676",
		"email": "edwardajackson@gmail.com",
		"name": "Eddie",
		"createdDate": "2014-07-02 22:45:45",
		"totalOrganizations": 11,
		"totalTours": 13,
		"totalDays": 488,
		"totalCountries": 14,
		"totalStates": 48,
		"totalEvents": 171,
		"eventPercent": 0.75394,
		"totalScheduleItems": 2793,
		"schedulePercent": 0.831545,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2f54b44",
		"email": "aklevit@cox.net",
		"name": "Anna",
		"createdDate": "2022-10-29 02:19:47",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 117,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 197,
		"schedulePercent": 0.148325,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5707f23",
		"email": "jeffyocalypse@gmail.com",
		"name": "Jeffy",
		"createdDate": "2020-01-19 23:33:06",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 164,
		"totalCountries": 1,
		"totalStates": 44,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 1189,
		"schedulePercent": 0.537015,
		"totalGuests": 165,
		"guestPercent": 0.526818,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "The Vogue",
		"mostGuestCity": "Indianapolis",
		"mostGuestAmount": 26
	},
	{
		"id": "654dd59",
		"email": "shannon@risingstartravel.biz",
		"name": "Shannon",
		"createdDate": "2013-03-19 22:35:37",
		"totalOrganizations": 66,
		"totalTours": 69,
		"totalDays": 17153,
		"totalCountries": 26,
		"totalStates": 135,
		"totalEvents": 4112,
		"eventPercent": 0.999868,
		"totalScheduleItems": 55299,
		"schedulePercent": 0.999868,
		"totalGuests": 27965,
		"guestPercent": 0.999868,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "Forest Hills Stadium",
		"mostGuestCity": "Forest Hills",
		"mostGuestAmount": 368
	},
	{
		"id": "39eece1",
		"email": "chrisleonardfoh@me.com",
		"name": "Chris",
		"createdDate": "2014-06-05 03:50:46",
		"totalOrganizations": 14,
		"totalTours": 14,
		"totalDays": 1975,
		"totalCountries": 10,
		"totalStates": 51,
		"totalEvents": 336,
		"eventPercent": 0.935108,
		"totalScheduleItems": 4601,
		"schedulePercent": 0.948484,
		"totalGuests": 1070,
		"guestPercent": 0.834724,
		"mostGuestDate": "2023-11-15 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 63
	},
	{
		"id": "0374a6f",
		"email": "flo@zoobook-agency.com",
		"name": "Flo",
		"createdDate": "2022-10-25 12:02:35",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 133,
		"totalCountries": 5,
		"totalStates": 16,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 697,
		"schedulePercent": 0.379552,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "75f5fcd",
		"email": "hope@refugemediagroup.com",
		"name": "Hope",
		"createdDate": "2023-05-12 22:54:16",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 30,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 378,
		"schedulePercent": 0.247252,
		"totalGuests": 114,
		"guestPercent": 0.486028,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "Music Hall Center for the Performing Arts",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 63
	},
	{
		"id": "a6ab44b",
		"email": "nathanbiggs97@outlook.com",
		"name": "Nathan",
		"createdDate": "2023-02-08 16:13:00",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 82,
		"totalCountries": 8,
		"totalStates": 11,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 269,
		"schedulePercent": 0.191498,
		"totalGuests": 221,
		"guestPercent": 0.563634,
		"mostGuestDate": "2023-07-05 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 62
	},
	{
		"id": "2cd8b19",
		"email": "kyana.white@gmail.com",
		"name": "Kyana",
		"createdDate": "2018-09-27 19:14:31",
		"totalOrganizations": 16,
		"totalTours": 13,
		"totalDays": 378,
		"totalCountries": 13,
		"totalStates": 46,
		"totalEvents": 174,
		"eventPercent": 0.759502,
		"totalScheduleItems": 2225,
		"schedulePercent": 0.75447,
		"totalGuests": 2144,
		"guestPercent": 0.941465,
		"mostGuestDate": "2023-11-06 00:00:00",
		"mostGuestName": "Avant Gardner",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 124
	},
	{
		"id": "c056b7a",
		"email": "gabethemexican@gmail.com",
		"name": "Gabe",
		"createdDate": "2015-05-14 17:49:42",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 100,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 322,
		"schedulePercent": 0.219706,
		"totalGuests": 155,
		"guestPercent": 0.51768,
		"mostGuestDate": "2023-09-25 00:00:00",
		"mostGuestName": "Music Hall of Williamsburg",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 29
	},
	{
		"id": "7918d9e",
		"email": "kchan@aegpresents.com",
		"name": "Kira",
		"createdDate": "2023-07-20 03:02:34",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 87,
		"totalCountries": 7,
		"totalStates": 13,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 318,
		"schedulePercent": 0.217322,
		"totalGuests": 101,
		"guestPercent": 0.474904,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "Toyosu PIT",
		"mostGuestCity": "",
		"mostGuestAmount": 20
	},
	{
		"id": "e5dd406",
		"email": "infamousdetroit@gmail.com",
		"name": "Detroit",
		"createdDate": "2013-03-10 20:15:58",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 382,
		"totalCountries": 13,
		"totalStates": 30,
		"totalEvents": 269,
		"eventPercent": 0.893789,
		"totalScheduleItems": 3547,
		"schedulePercent": 0.897232,
		"totalGuests": 6187,
		"guestPercent": 0.995762,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Olympiastadion Berlin",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 794
	},
	{
		"id": "52d30db",
		"email": "lepricano@mac.com",
		"name": "Mark",
		"createdDate": "2012-05-24 08:25:12",
		"totalOrganizations": 14,
		"totalTours": 7,
		"totalDays": 243,
		"totalCountries": 2,
		"totalStates": 17,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 1249,
		"schedulePercent": 0.551847,
		"totalGuests": 73,
		"guestPercent": 0.447755,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "The Mission Ballroom",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 25
	},
	{
		"id": "850e89f",
		"email": "stevenmetzmusic@gmail.com",
		"name": "Steven",
		"createdDate": "2021-08-03 11:12:53",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 517,
		"totalCountries": 3,
		"totalStates": 44,
		"totalEvents": 133,
		"eventPercent": 0.656602,
		"totalScheduleItems": 2076,
		"schedulePercent": 0.731029,
		"totalGuests": 804,
		"guestPercent": 0.777778,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "Rupp Arena",
		"mostGuestCity": "Lexington",
		"mostGuestAmount": 59
	},
	{
		"id": "4a9b804",
		"email": "normanwirtz@icloud.com",
		"name": "NORMAN",
		"createdDate": "2022-11-11 16:52:00",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 76,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 217,
		"schedulePercent": 0.160906,
		"totalGuests": 20,
		"guestPercent": 0.36909,
		"mostGuestDate": "2023-01-08 00:00:00",
		"mostGuestName": "ThÃ©Ã¢tre de l'Atelier",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 10
	},
	{
		"id": "27045d7",
		"email": "MFisher@vectormgmt.com",
		"name": "Michele",
		"createdDate": "2013-09-29 20:07:34",
		"totalOrganizations": 10,
		"totalTours": 12,
		"totalDays": 1191,
		"totalCountries": 6,
		"totalStates": 51,
		"totalEvents": 336,
		"eventPercent": 0.935108,
		"totalScheduleItems": 4767,
		"schedulePercent": 0.954178,
		"totalGuests": 987,
		"guestPercent": 0.820554,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Cheekwood",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 106
	},
	{
		"id": "973b52f",
		"email": "rudolphheadcarp@gmail.com",
		"name": "Ashlee",
		"createdDate": "2022-10-29 01:58:55",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 72,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 469,
		"schedulePercent": 0.284201,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-11-22 00:00:00",
		"mostGuestName": "H. Ric Luhrs Performing Arts Center",
		"mostGuestCity": "Shippensburg",
		"mostGuestAmount": 2
	},
	{
		"id": "e9c90f6",
		"email": "mattbiglermusic@gmail.com",
		"name": "Matt",
		"createdDate": "2022-06-17 04:50:00",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 31,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 859,
		"schedulePercent": 0.439942,
		"totalGuests": 453,
		"guestPercent": 0.67501,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "Dos Lagos Amphitheater",
		"mostGuestCity": "Corona",
		"mostGuestAmount": 29
	},
	{
		"id": "cb094a9",
		"email": "raven@diggingrootsmusic.com",
		"name": "Raven",
		"createdDate": "2019-04-15 22:44:28",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 104,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 554,
		"schedulePercent": 0.323136,
		"totalGuests": 17,
		"guestPercent": 0.362469,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "RBC Ottawa BluesFest",
		"mostGuestCity": "Ottawa",
		"mostGuestAmount": 7
	},
	{
		"id": "bfa2902",
		"email": "nicholasaweir@gmail.com",
		"name": "Nick",
		"createdDate": "2013-01-10 17:36:08",
		"totalOrganizations": 6,
		"totalTours": 1,
		"totalDays": 364,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 2710,
		"schedulePercent": 0.820686,
		"totalGuests": 1653,
		"guestPercent": 0.907032,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Raising Cane's River Center",
		"mostGuestCity": "Baton Rouge",
		"mostGuestAmount": 122
	},
	{
		"id": "cce6223",
		"email": "kwproduction7@gmail.com",
		"name": "Yoyo",
		"createdDate": "2023-06-27 06:22:50",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 153,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 778,
		"schedulePercent": 0.412263,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-07-27 00:00:00",
		"mostGuestName": "Headwaters Country Jam",
		"mostGuestCity": "Cardwell",
		"mostGuestAmount": 8
	},
	{
		"id": "b46a3bc",
		"email": "cheilonhemmerling@yahoo.ca",
		"name": "Cheilon",
		"createdDate": "2016-05-16 17:39:32",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 103,
		"totalCountries": 4,
		"totalStates": 6,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 339,
		"schedulePercent": 0.227122,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "East Elgin Community Complex",
		"mostGuestCity": "Aylmer",
		"mostGuestAmount": 23
	},
	{
		"id": "593b72c",
		"email": "fernandocs.produccion@gmail.com",
		"name": "Fernando",
		"createdDate": "2017-02-02 15:29:21",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 26,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 218,
		"schedulePercent": 0.1617,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d2a9edd",
		"email": "MalakaTouring@gmail.com",
		"name": "Nicholas",
		"createdDate": "2019-11-25 22:32:18",
		"totalOrganizations": 8,
		"totalTours": 15,
		"totalDays": 388,
		"totalCountries": 25,
		"totalStates": 89,
		"totalEvents": 246,
		"eventPercent": 0.868759,
		"totalScheduleItems": 3242,
		"schedulePercent": 0.873527,
		"totalGuests": 1843,
		"guestPercent": 0.924778,
		"mostGuestDate": "2023-05-11 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 76
	},
	{
		"id": "dd39f3d",
		"email": "dylanolivertm@gmail.com",
		"name": "Dylan",
		"createdDate": "2021-12-05 21:12:26",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 299,
		"totalCountries": 5,
		"totalStates": 45,
		"totalEvents": 170,
		"eventPercent": 0.752086,
		"totalScheduleItems": 1340,
		"schedulePercent": 0.577804,
		"totalGuests": 949,
		"guestPercent": 0.812475,
		"mostGuestDate": "2023-10-08 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 116
	},
	{
		"id": "84c71e6",
		"email": "eddie.moses46@gmail.com",
		"name": "Edward",
		"createdDate": "2023-06-12 19:07:03",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 202,
		"totalCountries": 3,
		"totalStates": 9,
		"totalEvents": 9,
		"eventPercent": 0.0607866,
		"totalScheduleItems": 207,
		"schedulePercent": 0.154417,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c70696b",
		"email": "jamezcanty@gmail.com",
		"name": "James",
		"createdDate": "2017-09-04 15:25:46",
		"totalOrganizations": 5,
		"totalTours": 14,
		"totalDays": 159,
		"totalCountries": 6,
		"totalStates": 34,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 1030,
		"schedulePercent": 0.49265,
		"totalGuests": 1110,
		"guestPercent": 0.840948,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Minnesota State Fair",
		"mostGuestCity": "St Paul",
		"mostGuestAmount": 65
	},
	{
		"id": "4d35a97",
		"email": "lauraduran85@gmail.com",
		"name": "Laura",
		"createdDate": "2015-11-10 21:56:12",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 852,
		"totalCountries": 7,
		"totalStates": 44,
		"totalEvents": 184,
		"eventPercent": 0.77791,
		"totalScheduleItems": 2563,
		"schedulePercent": 0.800954,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d741e04",
		"email": "nickwg14@gmail.com",
		"name": "Nick",
		"createdDate": "2012-05-07 16:15:54",
		"totalOrganizations": 13,
		"totalTours": 11,
		"totalDays": 692,
		"totalCountries": 23,
		"totalStates": 56,
		"totalEvents": 172,
		"eventPercent": 0.755661,
		"totalScheduleItems": 2379,
		"schedulePercent": 0.777248,
		"totalGuests": 298,
		"guestPercent": 0.60098,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "Pepsi center",
		"mostGuestCity": "Ciudad de MÃ©xico",
		"mostGuestAmount": 57
	},
	{
		"id": "fe71e12",
		"email": "brentokuley@gmail.com",
		"name": "Brent",
		"createdDate": "2011-09-15 03:11:17",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 50,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 396,
		"schedulePercent": 0.25586,
		"totalGuests": 681,
		"guestPercent": 0.748643,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Billy Bob's",
		"mostGuestCity": "Ft. Worth",
		"mostGuestAmount": 65
	},
	{
		"id": "5f26a59",
		"email": "mark@rosedingo.com",
		"name": "Mark",
		"createdDate": "2013-02-07 21:20:25",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 179,
		"totalCountries": 5,
		"totalStates": 40,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 1627,
		"schedulePercent": 0.648259,
		"totalGuests": 637,
		"guestPercent": 0.735532,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 59
	},
	{
		"id": "e830ee0",
		"email": "rodneymastertour@gmail.com",
		"name": "Rodney",
		"createdDate": "2020-01-21 15:41:00",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 363,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 585,
		"schedulePercent": 0.334128,
		"totalGuests": 109,
		"guestPercent": 0.481393,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "CoolToday Park",
		"mostGuestCity": "Venice",
		"mostGuestAmount": 12
	},
	{
		"id": "9ee3be9",
		"email": "jan.winkel@goodliveartists.com",
		"name": "Winkel",
		"createdDate": "2018-11-01 15:31:24",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 68,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 804,
		"schedulePercent": 0.421931,
		"totalGuests": 1102,
		"guestPercent": 0.839756,
		"mostGuestDate": "2023-07-19 00:00:00",
		"mostGuestName": "Schlossplatz Stuttgart",
		"mostGuestCity": "Stuttgart",
		"mostGuestAmount": 214
	},
	{
		"id": "13f3fe7",
		"email": "teddyroberson@mac.com",
		"name": "Ted",
		"createdDate": "2016-07-30 19:55:29",
		"totalOrganizations": 6,
		"totalTours": 9,
		"totalDays": 780,
		"totalCountries": 1,
		"totalStates": 40,
		"totalEvents": 148,
		"eventPercent": 0.696332,
		"totalScheduleItems": 1711,
		"schedulePercent": 0.667859,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Beech Mountain Ski Resort",
		"mostGuestCity": "Beech Mountain",
		"mostGuestAmount": 3
	},
	{
		"id": "7999077",
		"email": "mylissa.scully@wmg.com",
		"name": "Mylissa",
		"createdDate": "2022-04-05 21:05:12",
		"totalOrganizations": 46,
		"totalTours": 43,
		"totalDays": 14651,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 1796,
		"eventPercent": 0.993643,
		"totalScheduleItems": 741,
		"schedulePercent": 0.396636,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d53a29e",
		"email": "daniel@showlab.no",
		"name": "Daniel Kolstad",
		"createdDate": "2017-09-19 12:07:35",
		"totalOrganizations": 10,
		"totalTours": 3,
		"totalDays": 93,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 752,
		"schedulePercent": 0.402861,
		"totalGuests": 169,
		"guestPercent": 0.529466,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Danebu Kongsgaard - Boutique Hotell",
		"mostGuestCity": "Aurdal",
		"mostGuestAmount": 40
	},
	{
		"id": "2084577",
		"email": "struggletouring@gmail.com",
		"name": "Aaron",
		"createdDate": "2022-09-12 20:10:27",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 401,
		"totalCountries": 6,
		"totalStates": 47,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 2328,
		"schedulePercent": 0.770362,
		"totalGuests": 3060,
		"guestPercent": 0.969938,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 185
	},
	{
		"id": "18b9931",
		"email": "martin@mk-management.com",
		"name": "Martin",
		"createdDate": "2014-02-21 08:54:58",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 146,
		"totalCountries": 12,
		"totalStates": 19,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 363,
		"schedulePercent": 0.239703,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f76f66c",
		"email": "dinapolipaul@gmail.com",
		"name": "Paul",
		"createdDate": "2021-08-08 18:05:16",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 89,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 8,
		"eventPercent": 0.0535028,
		"totalScheduleItems": 462,
		"schedulePercent": 0.281817,
		"totalGuests": 721,
		"guestPercent": 0.757913,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Truist Park",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 168
	},
	{
		"id": "59b76d4",
		"email": "diana.chowdhury@rammstein-management.de",
		"name": "Diana",
		"createdDate": "2019-04-08 19:11:32",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 285,
		"totalCountries": 11,
		"totalStates": 16,
		"totalEvents": 232,
		"eventPercent": 0.852073,
		"totalScheduleItems": 2840,
		"schedulePercent": 0.836975,
		"totalGuests": 6182,
		"guestPercent": 0.995365,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Olympiastadion Berlin",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 794
	},
	{
		"id": "6353027",
		"email": "davercoe@gmail.com",
		"name": "Dave",
		"createdDate": "2012-09-26 01:22:17",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 316,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 1691,
		"schedulePercent": 0.662959,
		"totalGuests": 549,
		"guestPercent": 0.709707,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Budweiser Stage",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 44
	},
	{
		"id": "fa5987a",
		"email": "bradley21210@gmail.com",
		"name": "Bradley",
		"createdDate": "2023-08-10 00:19:09",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 99,
		"totalCountries": 1,
		"totalStates": 36,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 846,
		"schedulePercent": 0.436498,
		"totalGuests": 172,
		"guestPercent": 0.531585,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "Paramount Theatre",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 24
	},
	{
		"id": "a0196a6",
		"email": "gerrywilkes@claranet.co.uk",
		"name": "Gerry",
		"createdDate": "2013-07-01 21:50:10",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 342,
		"totalCountries": 4,
		"totalStates": 21,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 381,
		"schedulePercent": 0.249371,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bd5b7c0",
		"email": "gary@stabana.com",
		"name": "Gary",
		"createdDate": "2013-04-05 18:00:43",
		"totalOrganizations": 6,
		"totalTours": 15,
		"totalDays": 250,
		"totalCountries": 14,
		"totalStates": 37,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 917,
		"schedulePercent": 0.456363,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "740679e",
		"email": "sy.sebart@me.com",
		"name": "Sy",
		"createdDate": "2016-10-19 09:46:48",
		"totalOrganizations": 6,
		"totalTours": 39,
		"totalDays": 155,
		"totalCountries": 9,
		"totalStates": 34,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 748,
		"schedulePercent": 0.401006,
		"totalGuests": 21,
		"guestPercent": 0.371342,
		"mostGuestDate": "2023-09-14 00:00:00",
		"mostGuestName": "Horseshoe Tavern",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 6
	},
	{
		"id": "92b10ef",
		"email": "jdeancleveland@gmail.com",
		"name": "Jimmy",
		"createdDate": "2016-11-29 12:37:27",
		"totalOrganizations": 10,
		"totalTours": 3,
		"totalDays": 72,
		"totalCountries": 7,
		"totalStates": 13,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 638,
		"schedulePercent": 0.357701,
		"totalGuests": 251,
		"guestPercent": 0.579393,
		"mostGuestDate": "2023-03-29 00:00:00",
		"mostGuestName": "Royal Albert Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 65
	},
	{
		"id": "a242f47",
		"email": "mfinn511@gmail.com",
		"name": "Mike",
		"createdDate": "2009-11-18 03:53:56",
		"totalOrganizations": 9,
		"totalTours": 16,
		"totalDays": 1652,
		"totalCountries": 20,
		"totalStates": 87,
		"totalEvents": 478,
		"eventPercent": 0.971659,
		"totalScheduleItems": 6481,
		"schedulePercent": 0.98477,
		"totalGuests": 6465,
		"guestPercent": 0.996424,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 240
	},
	{
		"id": "a98eaf0",
		"email": "phantommusicworks@yahoo.com",
		"name": "Rus",
		"createdDate": "2015-10-20 18:06:37",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 316,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 144,
		"schedulePercent": 0.115614,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a458f5d",
		"email": "john@hdlnr.group",
		"name": "John",
		"createdDate": "2021-04-21 00:54:16",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 246,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 356,
		"schedulePercent": 0.235863,
		"totalGuests": 160,
		"guestPercent": 0.522845,
		"mostGuestDate": "2023-11-26 00:00:00",
		"mostGuestName": "Truist Arena",
		"mostGuestCity": "Highland Heights",
		"mostGuestAmount": 92
	},
	{
		"id": "895c584",
		"email": "rob.mirhadi@gmail.com",
		"name": "Robin",
		"createdDate": "2012-06-05 00:00:38",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 818,
		"totalCountries": 18,
		"totalStates": 71,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 2347,
		"schedulePercent": 0.773672,
		"totalGuests": 316,
		"guestPercent": 0.612104,
		"mostGuestDate": "2023-01-04 00:00:00",
		"mostGuestName": "Paramount Theatre",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 53
	},
	{
		"id": "6ab8fc2",
		"email": "bw@apgoldintl.com",
		"name": "Brad",
		"createdDate": "2023-08-24 23:16:37",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 381,
		"totalCountries": 3,
		"totalStates": 31,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 765,
		"schedulePercent": 0.407098,
		"totalGuests": 385,
		"guestPercent": 0.646934,
		"mostGuestDate": "2023-09-01 00:00:00",
		"mostGuestName": "Grand Park",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 185
	},
	{
		"id": "171bb02",
		"email": "tm@moe.org",
		"name": "Adam",
		"createdDate": "2022-08-25 21:00:39",
		"totalOrganizations": 6,
		"totalTours": 11,
		"totalDays": 330,
		"totalCountries": 1,
		"totalStates": 41,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1412,
		"schedulePercent": 0.593829,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "355ac65",
		"email": "araya@thehawksnestmusic.com",
		"name": "Whit",
		"createdDate": "2018-10-04 20:54:54",
		"totalOrganizations": 15,
		"totalTours": 12,
		"totalDays": 1637,
		"totalCountries": 4,
		"totalStates": 40,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 1947,
		"schedulePercent": 0.711032,
		"totalGuests": 196,
		"guestPercent": 0.546947,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "The Eastern",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 33
	},
	{
		"id": "dbea191",
		"email": "carlinag100@gmail.com",
		"name": "Carlina",
		"createdDate": "2019-01-24 22:47:51",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 353,
		"totalCountries": 14,
		"totalStates": 40,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 2937,
		"schedulePercent": 0.846113,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2a62584",
		"email": "anthony.amor@gmail.com",
		"name": "Anthony",
		"createdDate": "2010-12-15 20:10:23",
		"totalOrganizations": 10,
		"totalTours": 9,
		"totalDays": 792,
		"totalCountries": 8,
		"totalStates": 38,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 1886,
		"schedulePercent": 0.702556,
		"totalGuests": 1153,
		"guestPercent": 0.849556,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 95
	},
	{
		"id": "f2a9398",
		"email": "justine@riverjuke.co.uk",
		"name": "Justine",
		"createdDate": "2022-12-30 15:20:52",
		"totalOrganizations": 10,
		"totalTours": 15,
		"totalDays": 1279,
		"totalCountries": 22,
		"totalStates": 69,
		"totalEvents": 303,
		"eventPercent": 0.916832,
		"totalScheduleItems": 5651,
		"schedulePercent": 0.9755,
		"totalGuests": 2191,
		"guestPercent": 0.943186,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 125
	},
	{
		"id": "d9aa03f",
		"email": "annakellam@gmail.com",
		"name": "Anna",
		"createdDate": "2022-09-13 14:34:15",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 827,
		"totalCountries": 11,
		"totalStates": 57,
		"totalEvents": 278,
		"eventPercent": 0.900278,
		"totalScheduleItems": 3575,
		"schedulePercent": 0.898159,
		"totalGuests": 842,
		"guestPercent": 0.785989,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 139
	},
	{
		"id": "8cb108e",
		"email": "lem@easystar.com",
		"name": "Lem",
		"createdDate": "2012-09-04 14:10:53",
		"totalOrganizations": 2,
		"totalTours": 8,
		"totalDays": 50,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 314,
		"schedulePercent": 0.215203,
		"totalGuests": 72,
		"guestPercent": 0.446828,
		"mostGuestDate": "2023-08-17 00:00:00",
		"mostGuestName": "The Fleece",
		"mostGuestCity": "Bristol",
		"mostGuestAmount": 20
	},
	{
		"id": "1262238",
		"email": "rafaelxmadeira@gmail.com",
		"name": "Rafael",
		"createdDate": "2018-05-31 11:25:10",
		"totalOrganizations": 9,
		"totalTours": 2,
		"totalDays": 53,
		"totalCountries": 7,
		"totalStates": 15,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 303,
		"schedulePercent": 0.209509,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f27fc94",
		"email": "StubbsAudio@Gmail.com",
		"name": "Michael",
		"createdDate": "2013-07-23 18:25:57",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 191,
		"totalCountries": 1,
		"totalStates": 25,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 683,
		"schedulePercent": 0.37399,
		"totalGuests": 92,
		"guestPercent": 0.466163,
		"mostGuestDate": "2023-02-02 00:00:00",
		"mostGuestName": "The Fillmore Silver Spring",
		"mostGuestCity": "Silver Spring",
		"mostGuestAmount": 14
	},
	{
		"id": "0790d5e",
		"email": "laurenharton@gmail.com",
		"name": "Lauren",
		"createdDate": "2011-01-29 20:36:07",
		"totalOrganizations": 13,
		"totalTours": 11,
		"totalDays": 374,
		"totalCountries": 5,
		"totalStates": 42,
		"totalEvents": 190,
		"eventPercent": 0.788505,
		"totalScheduleItems": 1848,
		"schedulePercent": 0.694875,
		"totalGuests": 998,
		"guestPercent": 0.823202,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "Islington Assembly Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 67
	},
	{
		"id": "63bf976",
		"email": "Michaelandrewmusic1@gmail.com",
		"name": "Michael",
		"createdDate": "2022-10-06 18:07:54",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 513,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 788,
		"schedulePercent": 0.415574,
		"totalGuests": 139,
		"guestPercent": 0.505364,
		"mostGuestDate": "2023-05-26 00:00:00",
		"mostGuestName": "Bottlerock",
		"mostGuestCity": "Napa",
		"mostGuestAmount": 18
	},
	{
		"id": "16ad706",
		"email": "brianhardaswick@gmail.com",
		"name": "Brian",
		"createdDate": "2014-03-08 18:18:09",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 294,
		"totalCountries": 11,
		"totalStates": 54,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 2378,
		"schedulePercent": 0.777116,
		"totalGuests": 915,
		"guestPercent": 0.802543,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 43
	},
	{
		"id": "92fb7c6",
		"email": "nick.rhodes@redlightmanagement.com",
		"name": "Nick",
		"createdDate": "2022-06-20 15:11:06",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 594,
		"totalCountries": 2,
		"totalStates": 45,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 2789,
		"schedulePercent": 0.830618,
		"totalGuests": 2207,
		"guestPercent": 0.944113,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Dos Equis Pavilion",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 155
	},
	{
		"id": "16ccd44",
		"email": "tm@flatlandcavalry.com",
		"name": "Roy",
		"createdDate": "2023-05-20 23:10:02",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 548,
		"totalCountries": 1,
		"totalStates": 33,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 1995,
		"schedulePercent": 0.717521,
		"totalGuests": 1158,
		"guestPercent": 0.851013,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Moody Center",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 140
	},
	{
		"id": "7852905",
		"email": "nancysefton@gsmgmt.net",
		"name": "Nancy",
		"createdDate": "2014-06-03 10:03:00",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 61,
		"totalCountries": 2,
		"totalStates": 17,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 281,
		"schedulePercent": 0.198384,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b7a3e7e",
		"email": "georgia@slingsby.net.au",
		"name": "Georgia",
		"createdDate": "2023-08-29 00:04:36",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 60,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 323,
		"schedulePercent": 0.220501,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1635470",
		"email": "laurensass@me.com",
		"name": "Lauren",
		"createdDate": "2023-10-06 02:34:24",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 106,
		"totalCountries": 3,
		"totalStates": 4,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 488,
		"schedulePercent": 0.292014,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "Venetian Hotel",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 4
	},
	{
		"id": "8e5048c",
		"email": "ashleeappling00@gmail.com",
		"name": "Ashlee",
		"createdDate": "2022-05-02 17:13:12",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 24,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 1,
		"eventPercent": 0.00662164,
		"totalScheduleItems": 124,
		"schedulePercent": 0.105681,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "edb658d",
		"email": "Callum.bate@gmail.com",
		"name": "Callum",
		"createdDate": "2018-07-22 16:16:45",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 572,
		"totalCountries": 14,
		"totalStates": 35,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 1696,
		"schedulePercent": 0.66415,
		"totalGuests": 675,
		"guestPercent": 0.745597,
		"mostGuestDate": "2023-04-20 00:00:00",
		"mostGuestName": "O2 Academy Bristol",
		"mostGuestCity": "Bristol",
		"mostGuestAmount": 54
	},
	{
		"id": "16ae8c5",
		"email": "stan@involvedmanagement.com",
		"name": "Stan",
		"createdDate": "2017-10-30 18:13:15",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 372,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 1163,
		"schedulePercent": 0.529996,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c8f8fb7",
		"email": "sweatyprod@me.com",
		"name": "Alan",
		"createdDate": "2010-08-20 20:05:13",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 85,
		"totalCountries": 7,
		"totalStates": 8,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 327,
		"schedulePercent": 0.222752,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "afc2f9f",
		"email": "soozz.graham@yahoo.com.au",
		"name": "Soozz",
		"createdDate": "2023-02-05 05:15:50",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 36,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 167,
		"schedulePercent": 0.130711,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "efb5a3a",
		"email": "kb@kbeventservices.com",
		"name": "Klaus",
		"createdDate": "2014-05-14 20:00:17",
		"totalOrganizations": 7,
		"totalTours": 1,
		"totalDays": 23,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 9,
		"eventPercent": 0.0607866,
		"totalScheduleItems": 148,
		"schedulePercent": 0.118395,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0ec2a70",
		"email": "alex@theozofficial.com",
		"name": "Alex",
		"createdDate": "2022-05-01 18:12:09",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 369,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 16,
		"schedulePercent": 0.0288703,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8cbab28",
		"email": "admin@wierok.com",
		"name": "Tammie",
		"createdDate": "2022-12-02 17:33:28",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 158,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 872,
		"schedulePercent": 0.443915,
		"totalGuests": 2824,
		"guestPercent": 0.964905,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 298
	},
	{
		"id": "c4d3409",
		"email": "mishenka@eccles.co.nz",
		"name": "Mishenka ",
		"createdDate": "2019-02-02 06:57:24",
		"totalOrganizations": 9,
		"totalTours": 9,
		"totalDays": 124,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 548,
		"schedulePercent": 0.319958,
		"totalGuests": 677,
		"guestPercent": 0.747053,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "The Tuning Fork",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 117
	},
	{
		"id": "41a2843",
		"email": "johnwallace5057@gmail.com",
		"name": "John",
		"createdDate": "2018-06-18 21:55:00",
		"totalOrganizations": 17,
		"totalTours": 18,
		"totalDays": 438,
		"totalCountries": 20,
		"totalStates": 73,
		"totalEvents": 190,
		"eventPercent": 0.788505,
		"totalScheduleItems": 2840,
		"schedulePercent": 0.836975,
		"totalGuests": 177,
		"guestPercent": 0.534366,
		"mostGuestDate": "2023-11-02 00:00:00",
		"mostGuestName": "Batschkapp",
		"mostGuestCity": "Frankfurt am Main",
		"mostGuestAmount": 16
	},
	{
		"id": "e1148f7",
		"email": "touringpip@gmail.com",
		"name": "Pip",
		"createdDate": "2023-03-01 04:45:48",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 380,
		"totalCountries": 1,
		"totalStates": 34,
		"totalEvents": 156,
		"eventPercent": 0.718051,
		"totalScheduleItems": 1391,
		"schedulePercent": 0.589193,
		"totalGuests": 305,
		"guestPercent": 0.606277,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 36
	},
	{
		"id": "6a066b4",
		"email": "minitreesband@gmail.com",
		"name": "Lexi",
		"createdDate": "2021-09-09 15:34:12",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 113,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 928,
		"schedulePercent": 0.459012,
		"totalGuests": 37,
		"guestPercent": 0.400212,
		"mostGuestDate": "2023-05-31 00:00:00",
		"mostGuestName": "The Sound",
		"mostGuestCity": "Del Mar",
		"mostGuestAmount": 20
	},
	{
		"id": "3684493",
		"email": "production@c22agency.com",
		"name": "Pro",
		"createdDate": "2023-08-25 17:07:49",
		"totalOrganizations": 8,
		"totalTours": 7,
		"totalDays": 49,
		"totalCountries": 5,
		"totalStates": 9,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 327,
		"schedulePercent": 0.222752,
		"totalGuests": 64,
		"guestPercent": 0.439015,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "Headcrash",
		"mostGuestCity": "Hamburg",
		"mostGuestAmount": 12
	},
	{
		"id": "bbdcbdf",
		"email": "brandonellismusic@gmail.com",
		"name": "Brandon",
		"createdDate": "2022-07-18 18:40:51",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 554,
		"totalCountries": 2,
		"totalStates": 48,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 1754,
		"schedulePercent": 0.675275,
		"totalGuests": 89,
		"guestPercent": 0.462323,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "Mars Music Hall",
		"mostGuestCity": "Huntsville",
		"mostGuestAmount": 18
	},
	{
		"id": "3ef873f",
		"email": "szagami@bigpond.com",
		"name": "Stephen",
		"createdDate": "2012-07-09 05:36:02",
		"totalOrganizations": 62,
		"totalTours": 14,
		"totalDays": 395,
		"totalCountries": 8,
		"totalStates": 19,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 1420,
		"schedulePercent": 0.596477,
		"totalGuests": 22,
		"guestPercent": 0.373725,
		"mostGuestDate": "2023-03-23 00:00:00",
		"mostGuestName": "Christchurch Town Hall",
		"mostGuestCity": "Christchurch",
		"mostGuestAmount": 10
	},
	{
		"id": "5f34642",
		"email": "patricia@ep-rent.de",
		"name": "Patricia",
		"createdDate": "2022-12-07 11:46:37",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 294,
		"totalCountries": 15,
		"totalStates": 41,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 1638,
		"schedulePercent": 0.650642,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fe7cd58",
		"email": "n.bader@nb-mc.de",
		"name": "Nicolai",
		"createdDate": "2014-05-18 14:17:33",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 33,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 164,
		"schedulePercent": 0.129652,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-05-26 00:00:00",
		"mostGuestName": "Moritzbastei",
		"mostGuestCity": "Leipzig",
		"mostGuestAmount": 4
	},
	{
		"id": "a7d13e4",
		"email": "Cris.Lacy@wmg.com",
		"name": "Cris",
		"createdDate": "2017-08-10 02:21:18",
		"totalOrganizations": 52,
		"totalTours": 47,
		"totalDays": 15495,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 2010,
		"eventPercent": 0.996954,
		"totalScheduleItems": 1882,
		"schedulePercent": 0.701496,
		"totalGuests": 276,
		"guestPercent": 0.592902,
		"mostGuestDate": "2023-07-11 00:00:00",
		"mostGuestName": "Madison Square Garden",
		"mostGuestCity": "New York",
		"mostGuestAmount": 31
	},
	{
		"id": "7362230",
		"email": "Lucy@onlytours.co.uk",
		"name": "Lucy",
		"createdDate": "2017-06-15 22:08:38",
		"totalOrganizations": 12,
		"totalTours": 29,
		"totalDays": 691,
		"totalCountries": 17,
		"totalStates": 62,
		"totalEvents": 251,
		"eventPercent": 0.873792,
		"totalScheduleItems": 3091,
		"schedulePercent": 0.860946,
		"totalGuests": 1244,
		"guestPercent": 0.863859,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 80
	},
	{
		"id": "192f382",
		"email": "Tom.Martens@wmg.com",
		"name": "Tom",
		"createdDate": "2017-08-10 03:12:25",
		"totalOrganizations": 51,
		"totalTours": 45,
		"totalDays": 15229,
		"totalCountries": 16,
		"totalStates": 88,
		"totalEvents": 1990,
		"eventPercent": 0.996292,
		"totalScheduleItems": 1533,
		"schedulePercent": 0.625612,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7f63c60",
		"email": "Allyson.Kim@wmg.com",
		"name": "Allyson",
		"createdDate": "2023-01-24 17:51:47",
		"totalOrganizations": 42,
		"totalTours": 40,
		"totalDays": 12692,
		"totalCountries": 15,
		"totalStates": 86,
		"totalEvents": 1563,
		"eventPercent": 0.993114,
		"totalScheduleItems": 737,
		"schedulePercent": 0.394385,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "01ab3b7",
		"email": "lisa.stragne@lifeline.se",
		"name": "Lisa",
		"createdDate": "2023-01-16 08:56:10",
		"totalOrganizations": 12,
		"totalTours": 8,
		"totalDays": 443,
		"totalCountries": 3,
		"totalStates": 18,
		"totalEvents": 217,
		"eventPercent": 0.833267,
		"totalScheduleItems": 1653,
		"schedulePercent": 0.654086,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2ef234a",
		"email": "EvanMidds@gmail.com",
		"name": "Evan",
		"createdDate": "2022-09-12 15:57:27",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 99,
		"totalCountries": 8,
		"totalStates": 22,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 353,
		"schedulePercent": 0.234936,
		"totalGuests": 210,
		"guestPercent": 0.557542,
		"mostGuestDate": "2023-03-19 00:00:00",
		"mostGuestName": "Bridgeworks",
		"mostGuestCity": "Hamilton",
		"mostGuestAmount": 42
	},
	{
		"id": "aa693be",
		"email": "emilylichter@gmail.com",
		"name": "Emily ",
		"createdDate": "2016-02-23 23:15:42",
		"totalOrganizations": 19,
		"totalTours": 34,
		"totalDays": 1068,
		"totalCountries": 9,
		"totalStates": 55,
		"totalEvents": 303,
		"eventPercent": 0.916832,
		"totalScheduleItems": 2629,
		"schedulePercent": 0.811813,
		"totalGuests": 1810,
		"guestPercent": 0.92107,
		"mostGuestDate": "2023-04-29 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 63
	},
	{
		"id": "95f3873",
		"email": "julie@604records.com",
		"name": "Julie",
		"createdDate": "2012-08-21 17:33:57",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 28,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 151,
		"schedulePercent": 0.121308,
		"totalGuests": 42,
		"guestPercent": 0.40882,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Softball City",
		"mostGuestCity": "Surrey",
		"mostGuestAmount": 42
	},
	{
		"id": "32c8eec",
		"email": "kevin@traffic.mg",
		"name": "Kevin ",
		"createdDate": "2023-06-05 15:03:35",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 93,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 201,
		"schedulePercent": 0.151106,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "43b536f",
		"email": "biancahouston@hotmail.com",
		"name": "Bianca ",
		"createdDate": "2017-10-22 17:23:55",
		"totalOrganizations": 10,
		"totalTours": 5,
		"totalDays": 427,
		"totalCountries": 3,
		"totalStates": 24,
		"totalEvents": 141,
		"eventPercent": 0.678321,
		"totalScheduleItems": 1021,
		"schedulePercent": 0.491193,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3f7874e",
		"email": "rapidtoursuk@gmail.com",
		"name": "Steve",
		"createdDate": "2021-09-22 11:11:45",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 272,
		"totalCountries": 17,
		"totalStates": 62,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 1703,
		"schedulePercent": 0.665342,
		"totalGuests": 832,
		"guestPercent": 0.783737,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 47
	},
	{
		"id": "fa77305",
		"email": "info@foodsoul-catering.de",
		"name": "Patrick",
		"createdDate": "2020-02-20 12:54:45",
		"totalOrganizations": 7,
		"totalTours": 13,
		"totalDays": 354,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 142,
		"eventPercent": 0.680705,
		"totalScheduleItems": 475,
		"schedulePercent": 0.287247,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "277cb46",
		"email": "Audiojoeproductions@gmail.com",
		"name": "Joe ",
		"createdDate": "2017-01-20 03:01:19",
		"totalOrganizations": 5,
		"totalTours": 15,
		"totalDays": 494,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 2101,
		"schedulePercent": 0.735532,
		"totalGuests": 4758,
		"guestPercent": 0.990068,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "The Pacific Amphitheatre",
		"mostGuestCity": "Costa Mesa",
		"mostGuestAmount": 238
	},
	{
		"id": "9fbfa0c",
		"email": "matt@badomensofficial.com",
		"name": "Matt",
		"createdDate": "2020-01-27 21:41:42",
		"totalOrganizations": 4,
		"totalTours": 13,
		"totalDays": 356,
		"totalCountries": 12,
		"totalStates": 59,
		"totalEvents": 212,
		"eventPercent": 0.826778,
		"totalScheduleItems": 2511,
		"schedulePercent": 0.794862,
		"totalGuests": 1242,
		"guestPercent": 0.863594,
		"mostGuestDate": "2023-10-08 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 116
	},
	{
		"id": "f21cc22",
		"email": "lucasjhand@gmail.com",
		"name": "Lucas",
		"createdDate": "2017-09-14 19:05:58",
		"totalOrganizations": 8,
		"totalTours": 14,
		"totalDays": 682,
		"totalCountries": 15,
		"totalStates": 68,
		"totalEvents": 214,
		"eventPercent": 0.829559,
		"totalScheduleItems": 2190,
		"schedulePercent": 0.748378,
		"totalGuests": 877,
		"guestPercent": 0.79367,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 524
	},
	{
		"id": "49a9fe8",
		"email": "grantgibsonmusic@gmail.com",
		"name": "Grant",
		"createdDate": "2022-05-31 22:45:23",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 718,
		"totalCountries": 3,
		"totalStates": 47,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1166,
		"schedulePercent": 0.531188,
		"totalGuests": 44,
		"guestPercent": 0.411469,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Ace of Spades",
		"mostGuestCity": "Sacramento",
		"mostGuestAmount": 12
	},
	{
		"id": "93f252c",
		"email": "menendezmartu@gmail.com",
		"name": "Martina",
		"createdDate": "2017-07-03 22:27:51",
		"totalOrganizations": 7,
		"totalTours": 2,
		"totalDays": 195,
		"totalCountries": 5,
		"totalStates": 20,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 1096,
		"schedulePercent": 0.511058,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f33daab",
		"email": "markus@mgnordbye.no",
		"name": "Markus ",
		"createdDate": "2023-11-26 18:30:48",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 23,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 117,
		"schedulePercent": 0.100914,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d9a34ad",
		"email": "mistyroberts@me.com",
		"name": "Misty",
		"createdDate": "2009-12-18 08:41:40",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 432,
		"totalCountries": 5,
		"totalStates": 21,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 1645,
		"schedulePercent": 0.652496,
		"totalGuests": 166,
		"guestPercent": 0.52748,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "SoFi Stadium",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 29
	},
	{
		"id": "e8c5de2",
		"email": "jake@bigsilverllc.com",
		"name": "Jacobsen",
		"createdDate": "2023-04-10 18:54:12",
		"totalOrganizations": 6,
		"totalTours": 9,
		"totalDays": 342,
		"totalCountries": 7,
		"totalStates": 56,
		"totalEvents": 168,
		"eventPercent": 0.747451,
		"totalScheduleItems": 2145,
		"schedulePercent": 0.741359,
		"totalGuests": 2935,
		"guestPercent": 0.969011,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Glen Helen Amphitheater",
		"mostGuestCity": "San Bernardino",
		"mostGuestAmount": 250
	},
	{
		"id": "69c8f66",
		"email": "lauren.sass@sxglobal.com",
		"name": "Lauren",
		"createdDate": "2023-06-12 21:50:38",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 44,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 325,
		"schedulePercent": 0.222222,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0848715",
		"email": "paul.lee@blackberrysmoke.com",
		"name": "PAUL ",
		"createdDate": "2019-01-13 23:11:57",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 409,
		"totalCountries": 13,
		"totalStates": 51,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 1835,
		"schedulePercent": 0.692756,
		"totalGuests": 1610,
		"guestPercent": 0.903854,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 74
	},
	{
		"id": "daadd63",
		"email": "teodori.diane@gmail.com",
		"name": "Diane",
		"createdDate": "2020-02-17 13:15:15",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 244,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1356,
		"schedulePercent": 0.582175,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1aa368e",
		"email": "James@JRNTouring.com",
		"name": "James",
		"createdDate": "2019-09-24 14:39:47",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 799,
		"totalCountries": 13,
		"totalStates": 27,
		"totalEvents": 206,
		"eventPercent": 0.816183,
		"totalScheduleItems": 1626,
		"schedulePercent": 0.647861,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d4b8a69",
		"email": "chris@decorrecords.com",
		"name": "Chris",
		"createdDate": "2018-07-05 18:37:03",
		"totalOrganizations": 8,
		"totalTours": 11,
		"totalDays": 390,
		"totalCountries": 10,
		"totalStates": 28,
		"totalEvents": 191,
		"eventPercent": 0.790491,
		"totalScheduleItems": 1251,
		"schedulePercent": 0.552774,
		"totalGuests": 90,
		"guestPercent": 0.463912,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "The Garage",
		"mostGuestCity": "London",
		"mostGuestAmount": 29
	},
	{
		"id": "98ff76e",
		"email": "production@livenation.co.nz",
		"name": "Production",
		"createdDate": "2023-01-12 03:45:10",
		"totalOrganizations": 419,
		"totalTours": 127,
		"totalDays": 3317,
		"totalCountries": 8,
		"totalStates": 47,
		"totalEvents": 1043,
		"eventPercent": 0.99073,
		"totalScheduleItems": 11642,
		"schedulePercent": 0.998146,
		"totalGuests": 987,
		"guestPercent": 0.820554,
		"mostGuestDate": "2023-02-25 00:00:00",
		"mostGuestName": "The Mothership",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 58
	},
	{
		"id": "f147307",
		"email": "jvatuk@gmail.com",
		"name": "Jai Dev",
		"createdDate": "2014-06-09 10:14:03",
		"totalOrganizations": 22,
		"totalTours": 39,
		"totalDays": 1944,
		"totalCountries": 10,
		"totalStates": 73,
		"totalEvents": 688,
		"eventPercent": 0.985432,
		"totalScheduleItems": 8903,
		"schedulePercent": 0.99563,
		"totalGuests": 4395,
		"guestPercent": 0.987154,
		"mostGuestDate": "2023-06-18 00:00:00",
		"mostGuestName": "Red Rocks Park and Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 183
	},
	{
		"id": "9cccf7b",
		"email": "rickrosabella@mac.com",
		"name": "Rick",
		"createdDate": "2014-08-19 22:44:02",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 222,
		"totalCountries": 4,
		"totalStates": 21,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 1034,
		"schedulePercent": 0.49318,
		"totalGuests": 248,
		"guestPercent": 0.577804,
		"mostGuestDate": "2023-11-06 00:00:00",
		"mostGuestName": "Pantages Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 56
	},
	{
		"id": "a94fa88",
		"email": "vampreen1234@gmail.com",
		"name": "Drew",
		"createdDate": "2019-07-08 00:27:21",
		"totalOrganizations": 11,
		"totalTours": 15,
		"totalDays": 411,
		"totalCountries": 16,
		"totalStates": 58,
		"totalEvents": 212,
		"eventPercent": 0.826778,
		"totalScheduleItems": 3335,
		"schedulePercent": 0.881605,
		"totalGuests": 571,
		"guestPercent": 0.716991,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The Aztec Theatre",
		"mostGuestCity": "San Antonio",
		"mostGuestAmount": 70
	},
	{
		"id": "af7a078",
		"email": "Zachary.d.singer@gmail.com",
		"name": "Zachary",
		"createdDate": "2016-07-13 18:24:20",
		"totalOrganizations": 10,
		"totalTours": 12,
		"totalDays": 235,
		"totalCountries": 12,
		"totalStates": 26,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 1652,
		"schedulePercent": 0.653821,
		"totalGuests": 888,
		"guestPercent": 0.796451,
		"mostGuestDate": "2023-10-02 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 405
	},
	{
		"id": "47d8d5e",
		"email": "foocheese@aol.com",
		"name": "The Dude",
		"createdDate": "2019-06-22 10:30:45",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 138,
		"totalCountries": 5,
		"totalStates": 9,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 891,
		"schedulePercent": 0.450007,
		"totalGuests": 20,
		"guestPercent": 0.36909,
		"mostGuestDate": "2023-03-23 00:00:00",
		"mostGuestName": "Christchurch Town Hall",
		"mostGuestCity": "Christchurch",
		"mostGuestAmount": 10
	},
	{
		"id": "ee907e6",
		"email": "bolagetrecords@gmail.com",
		"name": "William",
		"createdDate": "2022-05-16 11:32:14",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 373,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 167,
		"schedulePercent": 0.130711,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c004154",
		"email": "gareth@wichitamgmt.com",
		"name": "Gareth",
		"createdDate": "2021-09-07 09:22:56",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 130,
		"totalCountries": 4,
		"totalStates": 29,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 381,
		"schedulePercent": 0.249371,
		"totalGuests": 93,
		"guestPercent": 0.468017,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 44
	},
	{
		"id": "adac707",
		"email": "daniellegoretn@gmail.com",
		"name": "Dani",
		"createdDate": "2015-12-15 19:54:32",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 725,
		"totalCountries": 20,
		"totalStates": 74,
		"totalEvents": 244,
		"eventPercent": 0.865713,
		"totalScheduleItems": 4206,
		"schedulePercent": 0.934048,
		"totalGuests": 716,
		"guestPercent": 0.756853,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 77
	},
	{
		"id": "14c8d0e",
		"email": "mari.pettersson@riksteatern.se",
		"name": "Mari",
		"createdDate": "2023-01-09 10:13:50",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 130,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 299,
		"schedulePercent": 0.207787,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "90c2121",
		"email": "Jmayerjones@gmail.com",
		"name": "Jessica ",
		"createdDate": "2022-07-06 14:35:42",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 144,
		"totalCountries": 3,
		"totalStates": 26,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 1385,
		"schedulePercent": 0.587869,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "921ddf0",
		"email": "Austen@awrightworld.com",
		"name": "Austen",
		"createdDate": "2018-09-10 20:30:15",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 1654,
		"totalCountries": 17,
		"totalStates": 62,
		"totalEvents": 288,
		"eventPercent": 0.907694,
		"totalScheduleItems": 3347,
		"schedulePercent": 0.882797,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8ae53a8",
		"email": "Jessy@twisted-talent.com",
		"name": "Jessy",
		"createdDate": "2022-11-11 12:52:32",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 155,
		"totalCountries": 17,
		"totalStates": 21,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 930,
		"schedulePercent": 0.459939,
		"totalGuests": 376,
		"guestPercent": 0.643888,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Stiftung Kultur Palast",
		"mostGuestCity": "Hamburg",
		"mostGuestAmount": 49
	},
	{
		"id": "c554577",
		"email": "production@iamhopp.com",
		"name": "Larry",
		"createdDate": "2021-07-23 23:01:58",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 33,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 13,
		"eventPercent": 0.0892597,
		"totalScheduleItems": 294,
		"schedulePercent": 0.205006,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d6ca169",
		"email": "Ryansteelmccluskey@icloud.com",
		"name": "Ryan",
		"createdDate": "2022-03-08 23:01:21",
		"totalOrganizations": 34,
		"totalTours": 55,
		"totalDays": 1348,
		"totalCountries": 27,
		"totalStates": 85,
		"totalEvents": 457,
		"eventPercent": 0.968878,
		"totalScheduleItems": 5246,
		"schedulePercent": 0.968613,
		"totalGuests": 2471,
		"guestPercent": 0.953119,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 125
	},
	{
		"id": "0a06c9d",
		"email": "Danieltadiello@gmail.com",
		"name": "Daniel",
		"createdDate": "2022-09-11 16:28:23",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 174,
		"totalCountries": 2,
		"totalStates": 31,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 1240,
		"schedulePercent": 0.549861,
		"totalGuests": 1241,
		"guestPercent": 0.863329,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "FirstBank Amphitheater",
		"mostGuestCity": "Franklin",
		"mostGuestAmount": 104
	},
	{
		"id": "7adc8cd",
		"email": "yohenakyo@gmail.com",
		"name": "Kyo",
		"createdDate": "2022-07-16 03:40:03",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 92,
		"totalCountries": 4,
		"totalStates": 20,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 689,
		"schedulePercent": 0.376242,
		"totalGuests": 179,
		"guestPercent": 0.535426,
		"mostGuestDate": "2023-11-06 00:00:00",
		"mostGuestName": "Pantages Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 56
	},
	{
		"id": "5e0188c",
		"email": "josephlloyd2@me.com",
		"name": "Joseph ",
		"createdDate": "2015-12-03 01:11:15",
		"totalOrganizations": 11,
		"totalTours": 15,
		"totalDays": 806,
		"totalCountries": 5,
		"totalStates": 25,
		"totalEvents": 320,
		"eventPercent": 0.925705,
		"totalScheduleItems": 3710,
		"schedulePercent": 0.907694,
		"totalGuests": 66,
		"guestPercent": 0.441001,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "Allegiant Stadium",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 66
	},
	{
		"id": "2c8b3ba",
		"email": "saloondoorproductions@gmail.com",
		"name": "Gemma",
		"createdDate": "2017-05-26 01:15:00",
		"totalOrganizations": 10,
		"totalTours": 4,
		"totalDays": 553,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 643,
		"schedulePercent": 0.360085,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "03046d9",
		"email": "ZopUK9g@gmail.com",
		"name": "David",
		"createdDate": "2017-09-26 09:08:31",
		"totalOrganizations": 15,
		"totalTours": 3,
		"totalDays": 238,
		"totalCountries": 5,
		"totalStates": 14,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 645,
		"schedulePercent": 0.360482,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-09-22 00:00:00",
		"mostGuestName": "O2 Academy Birmingham",
		"mostGuestCity": "Birmingham",
		"mostGuestAmount": 5
	},
	{
		"id": "62c4300",
		"email": "basak@eighteenco.com",
		"name": "Basak",
		"createdDate": "2021-06-08 15:08:27",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 1097,
		"totalCountries": 9,
		"totalStates": 57,
		"totalEvents": 346,
		"eventPercent": 0.939875,
		"totalScheduleItems": 4175,
		"schedulePercent": 0.932592,
		"totalGuests": 1864,
		"guestPercent": 0.925573,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "Royal Oak Music Theatre",
		"mostGuestCity": "Royal Oak",
		"mostGuestAmount": 43
	},
	{
		"id": "d9a8625",
		"email": "charl.carlito@gmail.com",
		"name": "Charles",
		"createdDate": "2017-08-25 19:32:05",
		"totalOrganizations": 15,
		"totalTours": 15,
		"totalDays": 453,
		"totalCountries": 13,
		"totalStates": 119,
		"totalEvents": 237,
		"eventPercent": 0.858429,
		"totalScheduleItems": 3438,
		"schedulePercent": 0.889419,
		"totalGuests": 1006,
		"guestPercent": 0.825189,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "AB Banan-Kompaniet",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 42
	},
	{
		"id": "8b9071b",
		"email": "emil@standup.no",
		"name": "Emil",
		"createdDate": "2021-11-10 15:07:07",
		"totalOrganizations": 10,
		"totalTours": 4,
		"totalDays": 124,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 401,
		"schedulePercent": 0.25692,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1ddbfa9",
		"email": "antoniojarard@gmail.com",
		"name": "Antonio",
		"createdDate": "2016-07-18 04:29:17",
		"totalOrganizations": 24,
		"totalTours": 11,
		"totalDays": 366,
		"totalCountries": 4,
		"totalStates": 46,
		"totalEvents": 194,
		"eventPercent": 0.794597,
		"totalScheduleItems": 2836,
		"schedulePercent": 0.836578,
		"totalGuests": 2459,
		"guestPercent": 0.952854,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 221
	},
	{
		"id": "5d0aae6",
		"email": "derrick@3feetnopressure.com",
		"name": "Derrick",
		"createdDate": "2023-07-08 18:52:24",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 39,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 204,
		"schedulePercent": 0.153225,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0472205",
		"email": "prodmgr1@gmail.com",
		"name": "Daniel",
		"createdDate": "2023-02-16 00:36:33",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 216,
		"totalCountries": 4,
		"totalStates": 26,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 1520,
		"schedulePercent": 0.623229,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "Hard Rock Live",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 0
	},
	{
		"id": "5e40f2c",
		"email": "wayan@brooklynbowl.com",
		"name": "Adam",
		"createdDate": "2019-03-28 16:49:45",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 256,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 260,
		"schedulePercent": 0.185671,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b93afbe",
		"email": "mattias@indielifemedia.com",
		"name": "Mattias",
		"createdDate": "2015-09-08 17:40:01",
		"totalOrganizations": 18,
		"totalTours": 4,
		"totalDays": 162,
		"totalCountries": 9,
		"totalStates": 41,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1650,
		"schedulePercent": 0.653423,
		"totalGuests": 1118,
		"guestPercent": 0.842273,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "Riverside Municipal Auditorium",
		"mostGuestCity": "Riverside",
		"mostGuestAmount": 78
	},
	{
		"id": "0c7455f",
		"email": "cadethompsonmusic@gmail.com",
		"name": "Cade",
		"createdDate": "2020-03-07 01:02:49",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 130,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 954,
		"schedulePercent": 0.46709,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a5997de",
		"email": "marc.grant@wwecorp.com",
		"name": "Marc",
		"createdDate": "2018-10-08 16:23:23",
		"totalOrganizations": 6,
		"totalTours": 78,
		"totalDays": 349,
		"totalCountries": 8,
		"totalStates": 55,
		"totalEvents": 204,
		"eventPercent": 0.813005,
		"totalScheduleItems": 3055,
		"schedulePercent": 0.856708,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c0fb13f",
		"email": "brett.m.maxwell@gmail.com",
		"name": "Brett",
		"createdDate": "2019-08-04 21:52:41",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 364,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 2710,
		"schedulePercent": 0.820686,
		"totalGuests": 1653,
		"guestPercent": 0.907032,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Raising Cane's River Center",
		"mostGuestCity": "Baton Rouge",
		"mostGuestAmount": 122
	},
	{
		"id": "b9a9574",
		"email": "peterarsen.ma@gmail.com",
		"name": "Peter",
		"createdDate": "2012-03-28 18:02:13",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 285,
		"totalCountries": 7,
		"totalStates": 23,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 781,
		"schedulePercent": 0.413058,
		"totalGuests": 554,
		"guestPercent": 0.711164,
		"mostGuestDate": "2023-02-14 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 67
	},
	{
		"id": "2439b5b",
		"email": "reiss.gillian@gmail.com",
		"name": "Gillian",
		"createdDate": "2012-09-28 04:24:25",
		"totalOrganizations": 11,
		"totalTours": 15,
		"totalDays": 527,
		"totalCountries": 7,
		"totalStates": 42,
		"totalEvents": 229,
		"eventPercent": 0.847305,
		"totalScheduleItems": 2376,
		"schedulePercent": 0.776851,
		"totalGuests": 1036,
		"guestPercent": 0.830089,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "Queen Elizabeth Theatre",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 49
	},
	{
		"id": "b1fc2d9",
		"email": "ryan@rollinproduction.com.au",
		"name": "Ryan",
		"createdDate": "2023-05-10 15:39:16",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 72,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 563,
		"schedulePercent": 0.326314,
		"totalGuests": 271,
		"guestPercent": 0.589988,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "Variety Playhouse",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 26
	},
	{
		"id": "1041906",
		"email": "gnotary@cityparksfoundation.org",
		"name": "Grace",
		"createdDate": "2018-05-10 15:20:10",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d63d89c",
		"email": "drugchurchmusic@gmail.com",
		"name": "Chris",
		"createdDate": "2016-12-04 10:18:19",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 108,
		"totalCountries": 3,
		"totalStates": 37,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 579,
		"schedulePercent": 0.331744,
		"totalGuests": 787,
		"guestPercent": 0.773805,
		"mostGuestDate": "2023-06-08 00:00:00",
		"mostGuestName": "Brooklyn Monarch",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 80
	},
	{
		"id": "4b841c2",
		"email": "mt@prosetouring.com",
		"name": "Paul",
		"createdDate": "2018-06-04 20:52:39",
		"totalOrganizations": 25,
		"totalTours": 9,
		"totalDays": 1321,
		"totalCountries": 18,
		"totalStates": 30,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 958,
		"schedulePercent": 0.468282,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4ff6fc5",
		"email": "kevin@bendelacreme.com",
		"name": "Kevin",
		"createdDate": "2023-08-29 21:41:50",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 137,
		"totalCountries": 3,
		"totalStates": 21,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 618,
		"schedulePercent": 0.34949,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "Masonic Cathedral Theatre",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 1
	},
	{
		"id": "4cd0b50",
		"email": "info@kidsontour.ca",
		"name": "Kids on tour",
		"createdDate": "2022-11-08 16:56:04",
		"totalOrganizations": 37,
		"totalTours": 19,
		"totalDays": 502,
		"totalCountries": 9,
		"totalStates": 47,
		"totalEvents": 338,
		"eventPercent": 0.936432,
		"totalScheduleItems": 1988,
		"schedulePercent": 0.716859,
		"totalGuests": 929,
		"guestPercent": 0.806781,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Irving Plaza",
		"mostGuestCity": "New York",
		"mostGuestAmount": 79
	},
	{
		"id": "e8cc6f7",
		"email": "ferreira@acomicsoul.com",
		"name": "Joana",
		"createdDate": "2023-03-17 10:15:56",
		"totalOrganizations": 73,
		"totalTours": 56,
		"totalDays": 974,
		"totalCountries": 26,
		"totalStates": 52,
		"totalEvents": 302,
		"eventPercent": 0.916567,
		"totalScheduleItems": 2324,
		"schedulePercent": 0.769567,
		"totalGuests": 106,
		"guestPercent": 0.480466,
		"mostGuestDate": "2023-08-16 00:00:00",
		"mostGuestName": "Boom Chicago",
		"mostGuestCity": "Amsterdam",
		"mostGuestAmount": 18
	},
	{
		"id": "d91d058",
		"email": "arcangel@5bam.com",
		"name": "Justin ",
		"createdDate": "2011-12-26 23:43:12",
		"totalOrganizations": 11,
		"totalTours": 13,
		"totalDays": 466,
		"totalCountries": 25,
		"totalStates": 85,
		"totalEvents": 221,
		"eventPercent": 0.83777,
		"totalScheduleItems": 3423,
		"schedulePercent": 0.888889,
		"totalGuests": 1610,
		"guestPercent": 0.903854,
		"mostGuestDate": "2023-01-15 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 63
	},
	{
		"id": "31aedce",
		"email": "Paul_Barnabee@fbmm.com",
		"name": "Joel",
		"createdDate": "2019-04-27 00:24:48",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 272,
		"totalCountries": 2,
		"totalStates": 31,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1106,
		"schedulePercent": 0.513045,
		"totalGuests": 1004,
		"guestPercent": 0.824791,
		"mostGuestDate": "2023-12-08 00:00:00",
		"mostGuestName": "The Chelsea",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 53
	},
	{
		"id": "b502ca3",
		"email": "Mahalia.barnes@gmail.com",
		"name": "Mahalia",
		"createdDate": "2016-10-06 19:01:07",
		"totalOrganizations": 7,
		"totalTours": 19,
		"totalDays": 265,
		"totalCountries": 12,
		"totalStates": 21,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 637,
		"schedulePercent": 0.357171,
		"totalGuests": 162,
		"guestPercent": 0.524169,
		"mostGuestDate": "2023-06-18 00:00:00",
		"mostGuestName": "Joan Sutherland Performing Arts Centre",
		"mostGuestCity": "Penrith",
		"mostGuestAmount": 58
	},
	{
		"id": "3d007f8",
		"email": "haley.freedlund@gmail.com",
		"name": "Haley",
		"createdDate": "2018-10-01 03:29:18",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 133,
		"totalCountries": 1,
		"totalStates": 37,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 718,
		"schedulePercent": 0.387498,
		"totalGuests": 166,
		"guestPercent": 0.52748,
		"mostGuestDate": "2023-02-15 00:00:00",
		"mostGuestName": "The Grand Theater",
		"mostGuestCity": "Wausau",
		"mostGuestAmount": 20
	},
	{
		"id": "16ec021",
		"email": "paul@paulandstorm.com",
		"name": "Paul",
		"createdDate": "2014-02-18 01:36:33",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 44,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 317,
		"schedulePercent": 0.216395,
		"totalGuests": 165,
		"guestPercent": 0.526818,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Palace Theatre",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 17
	},
	{
		"id": "096082e",
		"email": "charneymarshall@me.com",
		"name": "Charney",
		"createdDate": "2012-05-07 06:45:40",
		"totalOrganizations": 75,
		"totalTours": 18,
		"totalDays": 802,
		"totalCountries": 19,
		"totalStates": 62,
		"totalEvents": 331,
		"eventPercent": 0.932327,
		"totalScheduleItems": 4640,
		"schedulePercent": 0.949278,
		"totalGuests": 522,
		"guestPercent": 0.69898,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "SoFi Stadium",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 40
	},
	{
		"id": "ee5b29b",
		"email": "corinne.sumner@gmail.com",
		"name": "Corinne",
		"createdDate": "2022-03-29 21:03:40",
		"totalOrganizations": 8,
		"totalTours": 15,
		"totalDays": 494,
		"totalCountries": 17,
		"totalStates": 81,
		"totalEvents": 291,
		"eventPercent": 0.909548,
		"totalScheduleItems": 4040,
		"schedulePercent": 0.924911,
		"totalGuests": 3255,
		"guestPercent": 0.973911,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 147
	},
	{
		"id": "5e099cf",
		"email": "andydeveau2@mac.com",
		"name": "Andy",
		"createdDate": "2014-08-14 15:16:43",
		"totalOrganizations": 14,
		"totalTours": 23,
		"totalDays": 954,
		"totalCountries": 14,
		"totalStates": 72,
		"totalEvents": 380,
		"eventPercent": 0.951794,
		"totalScheduleItems": 6256,
		"schedulePercent": 0.983049,
		"totalGuests": 1933,
		"guestPercent": 0.930473,
		"mostGuestDate": "2023-02-21 00:00:00",
		"mostGuestName": "Eagles Ballroom",
		"mostGuestCity": "Milwaukee",
		"mostGuestAmount": 63
	},
	{
		"id": "7617517",
		"email": "jadtotah@gmail.com",
		"name": "Jad",
		"createdDate": "2019-08-26 05:37:57",
		"totalOrganizations": 27,
		"totalTours": 33,
		"totalDays": 1586,
		"totalCountries": 26,
		"totalStates": 83,
		"totalEvents": 438,
		"eventPercent": 0.966362,
		"totalScheduleItems": 6304,
		"schedulePercent": 0.983711,
		"totalGuests": 1806,
		"guestPercent": 0.920805,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 102
	},
	{
		"id": "be5aa6c",
		"email": "eddierocha@me.com",
		"name": "Eddie",
		"createdDate": "2016-08-11 12:24:11",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 94,
		"totalCountries": 9,
		"totalStates": 15,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 1339,
		"schedulePercent": 0.577539,
		"totalGuests": 953,
		"guestPercent": 0.813402,
		"mostGuestDate": "2023-04-15 00:00:00",
		"mostGuestName": "OVO Arena",
		"mostGuestCity": "London",
		"mostGuestAmount": 113
	},
	{
		"id": "1e673f7",
		"email": "haydenl615@gmail.com",
		"name": "Hayden",
		"createdDate": "2011-08-31 21:48:35",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 429,
		"totalCountries": 4,
		"totalStates": 36,
		"totalEvents": 258,
		"eventPercent": 0.883989,
		"totalScheduleItems": 3316,
		"schedulePercent": 0.879354,
		"totalGuests": 2855,
		"guestPercent": 0.966495,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Gas South Arena",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 157
	},
	{
		"id": "d4d3d3d",
		"email": "Kasperdue@mac.com",
		"name": "Kasper",
		"createdDate": "2009-08-19 20:25:50",
		"totalOrganizations": 11,
		"totalTours": 6,
		"totalDays": 173,
		"totalCountries": 5,
		"totalStates": 23,
		"totalEvents": 216,
		"eventPercent": 0.831943,
		"totalScheduleItems": 1291,
		"schedulePercent": 0.563899,
		"totalGuests": 286,
		"guestPercent": 0.596477,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "Riverside Municipal Auditorium",
		"mostGuestCity": "Riverside",
		"mostGuestAmount": 46
	},
	{
		"id": "36c3fe6",
		"email": "veronica.soderberg@livenation.se",
		"name": "Veronica",
		"createdDate": "2023-01-10 12:13:14",
		"totalOrganizations": 31,
		"totalTours": 38,
		"totalDays": 514,
		"totalCountries": 7,
		"totalStates": 34,
		"totalEvents": 401,
		"eventPercent": 0.957489,
		"totalScheduleItems": 2602,
		"schedulePercent": 0.808105,
		"totalGuests": 121,
		"guestPercent": 0.490928,
		"mostGuestDate": "2023-04-15 00:00:00",
		"mostGuestName": "Nefertiti",
		"mostGuestCity": "GÃ¶teborg",
		"mostGuestAmount": 33
	},
	{
		"id": "f750011",
		"email": "bkarpacz1992@gmail.com",
		"name": "Brooke",
		"createdDate": "2021-08-05 17:51:16",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 633,
		"totalCountries": 4,
		"totalStates": 35,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1118,
		"schedulePercent": 0.515693,
		"totalGuests": 436,
		"guestPercent": 0.667064,
		"mostGuestDate": "2023-05-12 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 44
	},
	{
		"id": "fe6f2fa",
		"email": "allenm@divineindustries.com",
		"name": "Allen",
		"createdDate": "2017-05-11 22:51:26",
		"totalOrganizations": 7,
		"totalTours": 28,
		"totalDays": 187,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 759,
		"schedulePercent": 0.405774,
		"totalGuests": 273,
		"guestPercent": 0.59065,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Commodore Ballroom",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 58
	},
	{
		"id": "118184f",
		"email": "Kris.sound@live.co.uk",
		"name": "Kristian",
		"createdDate": "2018-04-24 17:49:44",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 284,
		"totalCountries": 18,
		"totalStates": 45,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 1216,
		"schedulePercent": 0.544034,
		"totalGuests": 80,
		"guestPercent": 0.455304,
		"mostGuestDate": "2023-04-03 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 13
	},
	{
		"id": "b9a7a5c",
		"email": "markus@lempsch.com",
		"name": "Markus",
		"createdDate": "2015-09-15 02:07:03",
		"totalOrganizations": 12,
		"totalTours": 13,
		"totalDays": 546,
		"totalCountries": 18,
		"totalStates": 37,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 1601,
		"schedulePercent": 0.643358,
		"totalGuests": 914,
		"guestPercent": 0.802278,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Jahrhunderthalle",
		"mostGuestCity": "Frankfurt",
		"mostGuestAmount": 60
	},
	{
		"id": "a147d1d",
		"email": "grace@xixentertainment.com",
		"name": "Grace",
		"createdDate": "2021-02-13 03:10:27",
		"totalOrganizations": 5,
		"totalTours": 11,
		"totalDays": 748,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 1249,
		"schedulePercent": 0.551847,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3797817",
		"email": "tmpmgreg@gmail.com",
		"name": "Greg",
		"createdDate": "2017-02-06 19:36:43",
		"totalOrganizations": 10,
		"totalTours": 30,
		"totalDays": 867,
		"totalCountries": 10,
		"totalStates": 52,
		"totalEvents": 290,
		"eventPercent": 0.909019,
		"totalScheduleItems": 4332,
		"schedulePercent": 0.938816,
		"totalGuests": 784,
		"guestPercent": 0.773408,
		"mostGuestDate": "2023-12-23 00:00:00",
		"mostGuestName": "Fisher Center for the Performing Arts",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 59
	},
	{
		"id": "858f7a6",
		"email": "dan.wright@phantom-music.com",
		"name": "Daniel",
		"createdDate": "2019-04-23 11:20:09",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 145,
		"totalCountries": 10,
		"totalStates": 19,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 526,
		"schedulePercent": 0.310025,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9fb4039",
		"email": "jackielapham@icloud.com",
		"name": "Jackie",
		"createdDate": "2023-10-07 16:02:20",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 85,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 6,
		"eventPercent": 0.0397298,
		"totalScheduleItems": 125,
		"schedulePercent": 0.106079,
		"totalGuests": 54,
		"guestPercent": 0.427096,
		"mostGuestDate": "2023-11-06 00:00:00",
		"mostGuestName": "The Bowery Ballroom",
		"mostGuestCity": "New York",
		"mostGuestAmount": 46
	},
	{
		"id": "cbd878e",
		"email": "photosbycorbin@gmail.com",
		"name": "Corbin",
		"createdDate": "2015-08-26 22:45:38",
		"totalOrganizations": 18,
		"totalTours": 21,
		"totalDays": 574,
		"totalCountries": 15,
		"totalStates": 74,
		"totalEvents": 356,
		"eventPercent": 0.944643,
		"totalScheduleItems": 4742,
		"schedulePercent": 0.953384,
		"totalGuests": 4072,
		"guestPercent": 0.984505,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Saint Andrewâ€™s Hall",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 80
	},
	{
		"id": "733def0",
		"email": "jhill@notfitforsociety.com",
		"name": "J",
		"createdDate": "2019-02-20 19:33:19",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 1018,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 404,
		"schedulePercent": 0.259039,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0fbd439",
		"email": "atranber@aol.com",
		"name": "Bertrand",
		"createdDate": "2020-06-26 15:06:04",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 3,
		"totalStates": 9,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 483,
		"schedulePercent": 0.290028,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "42f287b",
		"email": "xsheepx@gmail.com",
		"name": "Sheep",
		"createdDate": "2010-07-29 21:12:05",
		"totalOrganizations": 19,
		"totalTours": 18,
		"totalDays": 1045,
		"totalCountries": 17,
		"totalStates": 63,
		"totalEvents": 252,
		"eventPercent": 0.876308,
		"totalScheduleItems": 3670,
		"schedulePercent": 0.905443,
		"totalGuests": 2491,
		"guestPercent": 0.953516,
		"mostGuestDate": "2023-06-28 00:00:00",
		"mostGuestName": "The Complex - Rockwell",
		"mostGuestCity": "Salt Lake City",
		"mostGuestAmount": 91
	},
	{
		"id": "8c2246a",
		"email": "tonymoon@me.com",
		"name": "Tony",
		"createdDate": "2009-08-17 16:21:28",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 299,
		"totalCountries": 3,
		"totalStates": 41,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 2256,
		"schedulePercent": 0.760032,
		"totalGuests": 728,
		"guestPercent": 0.75937,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "iTHINK Financial Amphitheatre",
		"mostGuestCity": "West Palm Beach",
		"mostGuestAmount": 84
	},
	{
		"id": "65a9df4",
		"email": "Harry@gmajormgmt.com",
		"name": "Harry",
		"createdDate": "2015-10-28 22:59:40",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 4,
		"totalStates": 39,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 2707,
		"schedulePercent": 0.819494,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3b98956",
		"email": "connor@glyndwr.design",
		"name": "Connor",
		"createdDate": "2022-10-30 03:56:22",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 133,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 13,
		"eventPercent": 0.0892597,
		"totalScheduleItems": 241,
		"schedulePercent": 0.1764,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "15c9ae6",
		"email": "dwightkendall777@gmail.com",
		"name": "Dwight",
		"createdDate": "2022-07-17 02:25:53",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 604,
		"totalCountries": 3,
		"totalStates": 35,
		"totalEvents": 255,
		"eventPercent": 0.880943,
		"totalScheduleItems": 4138,
		"schedulePercent": 0.929943,
		"totalGuests": 1285,
		"guestPercent": 0.869951,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "Abba's House ",
		"mostGuestCity": "Hixson",
		"mostGuestAmount": 37
	},
	{
		"id": "89dcdbc",
		"email": "joshualampkins@gmail.com",
		"name": "Josh",
		"createdDate": "2012-11-16 23:06:11",
		"totalOrganizations": 16,
		"totalTours": 10,
		"totalDays": 634,
		"totalCountries": 4,
		"totalStates": 47,
		"totalEvents": 207,
		"eventPercent": 0.817905,
		"totalScheduleItems": 4182,
		"schedulePercent": 0.933254,
		"totalGuests": 3586,
		"guestPercent": 0.978811,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 298
	},
	{
		"id": "170104a",
		"email": "jeremy.dylan@entertainmentedge.com.au",
		"name": "Jeremy",
		"createdDate": "2017-05-16 14:11:44",
		"totalOrganizations": 6,
		"totalTours": 11,
		"totalDays": 511,
		"totalCountries": 10,
		"totalStates": 52,
		"totalEvents": 201,
		"eventPercent": 0.808237,
		"totalScheduleItems": 1672,
		"schedulePercent": 0.658323,
		"totalGuests": 269,
		"guestPercent": 0.589193,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "Factory Theatre",
		"mostGuestCity": "Marrickville",
		"mostGuestAmount": 102
	},
	{
		"id": "e22efaa",
		"email": "d.johnson@dayofshow.net",
		"name": "Davon",
		"createdDate": "2023-01-07 06:56:18",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 375,
		"totalCountries": 3,
		"totalStates": 35,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 1517,
		"schedulePercent": 0.622434,
		"totalGuests": 38,
		"guestPercent": 0.402198,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "Axis Club",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 26
	},
	{
		"id": "1cc3b94",
		"email": "mark@freefocus.co.uk",
		"name": "Mark",
		"createdDate": "2022-05-20 09:01:13",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 83,
		"totalCountries": 4,
		"totalStates": 25,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 680,
		"schedulePercent": 0.372533,
		"totalGuests": 164,
		"guestPercent": 0.526155,
		"mostGuestDate": "2023-10-24 00:00:00",
		"mostGuestName": "The Dome",
		"mostGuestCity": "London",
		"mostGuestAmount": 49
	},
	{
		"id": "d6de47f",
		"email": "Scott@ultrasound.audio",
		"name": "Scott",
		"createdDate": "2019-06-22 09:11:27",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 584,
		"totalCountries": 4,
		"totalStates": 47,
		"totalEvents": 162,
		"eventPercent": 0.732883,
		"totalScheduleItems": 3718,
		"schedulePercent": 0.908092,
		"totalGuests": 56,
		"guestPercent": 0.429744,
		"mostGuestDate": "2023-05-04 00:00:00",
		"mostGuestName": "Fiddler's Green Amphitheatre",
		"mostGuestCity": "Englewood",
		"mostGuestAmount": 30
	},
	{
		"id": "8da41ef",
		"email": "kelleyamplante@gmail.com",
		"name": "Kelley",
		"createdDate": "2018-05-14 18:20:33",
		"totalOrganizations": 4,
		"totalTours": 10,
		"totalDays": 995,
		"totalCountries": 11,
		"totalStates": 56,
		"totalEvents": 154,
		"eventPercent": 0.71368,
		"totalScheduleItems": 2158,
		"schedulePercent": 0.743743,
		"totalGuests": 993,
		"guestPercent": 0.822143,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 92
	},
	{
		"id": "6e0d775",
		"email": "mackenzie@dreamproductiongroup.com",
		"name": "Mackenzie",
		"createdDate": "2018-08-24 00:34:34",
		"totalOrganizations": 8,
		"totalTours": 21,
		"totalDays": 479,
		"totalCountries": 15,
		"totalStates": 48,
		"totalEvents": 209,
		"eventPercent": 0.82201,
		"totalScheduleItems": 2789,
		"schedulePercent": 0.830618,
		"totalGuests": 2294,
		"guestPercent": 0.947424,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 86
	},
	{
		"id": "7d177d5",
		"email": "derbierad@gmail.com",
		"name": "Jeromy",
		"createdDate": "2014-09-08 02:33:44",
		"totalOrganizations": 12,
		"totalTours": 11,
		"totalDays": 694,
		"totalCountries": 15,
		"totalStates": 59,
		"totalEvents": 183,
		"eventPercent": 0.775659,
		"totalScheduleItems": 2809,
		"schedulePercent": 0.83287,
		"totalGuests": 1307,
		"guestPercent": 0.8726,
		"mostGuestDate": "2023-05-18 00:00:00",
		"mostGuestName": "Mohegan Sun Arena At Casey Plaza",
		"mostGuestCity": "Wilkes-Barre",
		"mostGuestAmount": 98
	},
	{
		"id": "da30468",
		"email": "tiffany@jarrahrecords.com",
		"name": "Tiffany",
		"createdDate": "2016-12-14 09:16:17",
		"totalOrganizations": 14,
		"totalTours": 20,
		"totalDays": 420,
		"totalCountries": 8,
		"totalStates": 38,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 1865,
		"schedulePercent": 0.698186,
		"totalGuests": 893,
		"guestPercent": 0.797908,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Palais Theatre",
		"mostGuestCity": "St Kilda",
		"mostGuestAmount": 62
	},
	{
		"id": "a92750d",
		"email": "bobbyduckett1@aol.com",
		"name": "Robert",
		"createdDate": "2015-04-12 13:17:32",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 302,
		"totalCountries": 1,
		"totalStates": 22,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 1456,
		"schedulePercent": 0.604556,
		"totalGuests": 559,
		"guestPercent": 0.713415,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Cadence Bank Amphitheatre at Chastain Park",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 67
	},
	{
		"id": "676bd51",
		"email": "unspokentm@gmail.com",
		"name": "Chad",
		"createdDate": "2016-06-02 16:50:28",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 101,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 902,
		"schedulePercent": 0.453185,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3593f45",
		"email": "Justin.Newell@wmg.com",
		"name": "Justin",
		"createdDate": "2017-06-01 20:09:31",
		"totalOrganizations": 54,
		"totalTours": 50,
		"totalDays": 15932,
		"totalCountries": 16,
		"totalStates": 89,
		"totalEvents": 2300,
		"eventPercent": 0.998808,
		"totalScheduleItems": 3669,
		"schedulePercent": 0.905178,
		"totalGuests": 92,
		"guestPercent": 0.466163,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Houston Rodeo WIll Call Guest List",
		"mostGuestCity": "",
		"mostGuestAmount": 57
	},
	{
		"id": "7104e0c",
		"email": "Peterfoxrecording@gmail.com",
		"name": "Peter",
		"createdDate": "2017-08-23 07:42:27",
		"totalOrganizations": 6,
		"totalTours": 20,
		"totalDays": 162,
		"totalCountries": 12,
		"totalStates": 28,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 667,
		"schedulePercent": 0.367898,
		"totalGuests": 236,
		"guestPercent": 0.571447,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 52
	},
	{
		"id": "7e27ea0",
		"email": "mikaelabeando@gmail.com",
		"name": "Mikaela",
		"createdDate": "2023-03-04 20:52:57",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 36,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 149,
		"schedulePercent": 0.118925,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1f4dda9",
		"email": "antbck@gmail.com",
		"name": "Antoine",
		"createdDate": "2016-06-21 12:15:35",
		"totalOrganizations": 12,
		"totalTours": 21,
		"totalDays": 781,
		"totalCountries": 18,
		"totalStates": 59,
		"totalEvents": 203,
		"eventPercent": 0.811681,
		"totalScheduleItems": 2750,
		"schedulePercent": 0.825851,
		"totalGuests": 295,
		"guestPercent": 0.599788,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "MTELUS",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 23
	},
	{
		"id": "c8a2b40",
		"email": "jaredsegawa@gmail.com",
		"name": "Jared",
		"createdDate": "2015-12-23 19:40:25",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 110,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 932,
		"schedulePercent": 0.460469,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c609af9",
		"email": "trevor@gentrytouring.com",
		"name": "Trevor",
		"createdDate": "2022-01-11 20:42:27",
		"totalOrganizations": 13,
		"totalTours": 12,
		"totalDays": 2492,
		"totalCountries": 12,
		"totalStates": 58,
		"totalEvents": 378,
		"eventPercent": 0.950603,
		"totalScheduleItems": 5666,
		"schedulePercent": 0.975897,
		"totalGuests": 1155,
		"guestPercent": 0.850086,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 81
	},
	{
		"id": "3df3af9",
		"email": "rosedexter.work@gmail.com",
		"name": "Rose",
		"createdDate": "2018-02-07 13:06:45",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 1008,
		"totalCountries": 17,
		"totalStates": 49,
		"totalEvents": 159,
		"eventPercent": 0.726394,
		"totalScheduleItems": 3197,
		"schedulePercent": 0.869554,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6b85c2c",
		"email": "jballinger222@me.com",
		"name": "Jay",
		"createdDate": "2013-03-26 16:37:13",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 596,
		"totalCountries": 14,
		"totalStates": 54,
		"totalEvents": 141,
		"eventPercent": 0.678321,
		"totalScheduleItems": 2406,
		"schedulePercent": 0.781221,
		"totalGuests": 1070,
		"guestPercent": 0.834724,
		"mostGuestDate": "2023-03-09 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 259
	},
	{
		"id": "897e28f",
		"email": "johnathancole5@yahoo.com",
		"name": "Johnathan",
		"createdDate": "2019-06-05 16:14:07",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 94,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 678,
		"schedulePercent": 0.371739,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "af0acf6",
		"email": "WILL@HATCHBACKLLC.COM",
		"name": "William",
		"createdDate": "2018-04-05 19:08:20",
		"totalOrganizations": 12,
		"totalTours": 3,
		"totalDays": 32,
		"totalCountries": 4,
		"totalStates": 10,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 191,
		"schedulePercent": 0.145146,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b256a67",
		"email": "mookie@wearefree.com",
		"name": "Mookie",
		"createdDate": "2015-04-03 03:09:41",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 482,
		"totalCountries": 16,
		"totalStates": 39,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 2318,
		"schedulePercent": 0.767978,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c089cca",
		"email": "lino@lowereastcoast.com",
		"name": "Lino",
		"createdDate": "2019-11-24 19:29:47",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 164,
		"totalCountries": 8,
		"totalStates": 19,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 420,
		"schedulePercent": 0.263939,
		"totalGuests": 32,
		"guestPercent": 0.392531,
		"mostGuestDate": "2023-04-25 00:00:00",
		"mostGuestName": "Shed 10",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 32
	},
	{
		"id": "57301e1",
		"email": "amanda@am-touring.co.uk",
		"name": "Amanda",
		"createdDate": "2014-04-15 14:19:45",
		"totalOrganizations": 20,
		"totalTours": 12,
		"totalDays": 653,
		"totalCountries": 8,
		"totalStates": 28,
		"totalEvents": 213,
		"eventPercent": 0.827837,
		"totalScheduleItems": 3362,
		"schedulePercent": 0.884254,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Lincoln Financial Field",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 4
	},
	{
		"id": "1c02782",
		"email": "chris@boyh.co.uk",
		"name": "Chris",
		"createdDate": "2022-09-14 11:27:18",
		"totalOrganizations": 3,
		"totalTours": 12,
		"totalDays": 136,
		"totalCountries": 9,
		"totalStates": 17,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 309,
		"schedulePercent": 0.212422,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "04ba146",
		"email": "Shelby.Marvel@gmail.com",
		"name": "Shelby",
		"createdDate": "2019-05-30 15:30:35",
		"totalOrganizations": 7,
		"totalTours": 12,
		"totalDays": 820,
		"totalCountries": 3,
		"totalStates": 39,
		"totalEvents": 159,
		"eventPercent": 0.726394,
		"totalScheduleItems": 2117,
		"schedulePercent": 0.737651,
		"totalGuests": 518,
		"guestPercent": 0.698053,
		"mostGuestDate": "2023-06-06 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 68
	},
	{
		"id": "f992926",
		"email": "maria@brutalevents.org",
		"name": "Maria",
		"createdDate": "2018-12-03 13:21:36",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 28,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 8,
		"eventPercent": 0.0535028,
		"totalScheduleItems": 313,
		"schedulePercent": 0.214674,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e2f7ca4",
		"email": "assistente@selorelicario.com.br",
		"name": "Bruno",
		"createdDate": "2023-04-10 21:38:50",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 38,
		"totalCountries": 6,
		"totalStates": 10,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 236,
		"schedulePercent": 0.172295,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ad46079",
		"email": "Ascensionmgmtfirm@gmail.com",
		"name": "Amber ",
		"createdDate": "2023-07-02 02:06:07",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 153,
		"totalCountries": 6,
		"totalStates": 17,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 122,
		"schedulePercent": 0.104092,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4b9a3a7",
		"email": "eva@eventosmh.es",
		"name": "Eva",
		"createdDate": "2022-02-21 23:39:47",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 130,
		"totalCountries": 12,
		"totalStates": 35,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 1299,
		"schedulePercent": 0.566018,
		"totalGuests": 350,
		"guestPercent": 0.629718,
		"mostGuestDate": "2023-03-10 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 82
	},
	{
		"id": "1a39328",
		"email": "ronni@sound4events.dk",
		"name": "Ronni",
		"createdDate": "2022-07-14 17:01:13",
		"totalOrganizations": 10,
		"totalTours": 6,
		"totalDays": 687,
		"totalCountries": 5,
		"totalStates": 6,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 858,
		"schedulePercent": 0.439677,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1cdb834",
		"email": "paul@rnzb.org.nz",
		"name": "Paul",
		"createdDate": "2018-10-11 20:46:34",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 226,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 1999,
		"schedulePercent": 0.718315,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "577ec17",
		"email": "danielle.catherine.head@gmail.com",
		"name": "Danielle",
		"createdDate": "2022-08-31 16:45:57",
		"totalOrganizations": 1,
		"totalTours": 6,
		"totalDays": 103,
		"totalCountries": 9,
		"totalStates": 35,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 842,
		"schedulePercent": 0.434909,
		"totalGuests": 259,
		"guestPercent": 0.583764,
		"mostGuestDate": "2023-03-04 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 40
	},
	{
		"id": "9ac7b09",
		"email": "kelsey@kelsey-wagner.com",
		"name": "Kelsey",
		"createdDate": "2023-07-07 19:56:17",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 179,
		"totalCountries": 3,
		"totalStates": 36,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 604,
		"schedulePercent": 0.343266,
		"totalGuests": 270,
		"guestPercent": 0.589591,
		"mostGuestDate": "2023-08-15 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 52
	},
	{
		"id": "b2387dc",
		"email": "andyrobinson01@icloud.com",
		"name": "Andy",
		"createdDate": "2016-06-29 10:42:14",
		"totalOrganizations": 15,
		"totalTours": 22,
		"totalDays": 696,
		"totalCountries": 19,
		"totalStates": 62,
		"totalEvents": 237,
		"eventPercent": 0.858429,
		"totalScheduleItems": 3769,
		"schedulePercent": 0.910343,
		"totalGuests": 1378,
		"guestPercent": 0.88081,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "Somerset House",
		"mostGuestCity": "London",
		"mostGuestAmount": 97
	},
	{
		"id": "1f554d2",
		"email": "jmarmel@levitytalent.com",
		"name": "Judi",
		"createdDate": "2018-06-05 13:45:36",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 360,
		"totalCountries": 9,
		"totalStates": 55,
		"totalEvents": 167,
		"eventPercent": 0.745332,
		"totalScheduleItems": 2427,
		"schedulePercent": 0.783605,
		"totalGuests": 2201,
		"guestPercent": 0.943848,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "T-Mobile Arena",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 118
	},
	{
		"id": "7f94f9a",
		"email": "paul@chavarrianyc.com",
		"name": "Paul",
		"createdDate": "2015-03-17 16:40:11",
		"totalOrganizations": 10,
		"totalTours": 8,
		"totalDays": 220,
		"totalCountries": 4,
		"totalStates": 39,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1558,
		"schedulePercent": 0.63144,
		"totalGuests": 1538,
		"guestPercent": 0.898292,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "FirstBank Amphitheater",
		"mostGuestCity": "Franklin",
		"mostGuestAmount": 104
	},
	{
		"id": "080c1da",
		"email": "mary.broadbent@gmail.com",
		"name": "Mary",
		"createdDate": "2012-07-20 07:13:06",
		"totalOrganizations": 20,
		"totalTours": 41,
		"totalDays": 864,
		"totalCountries": 10,
		"totalStates": 58,
		"totalEvents": 208,
		"eventPercent": 0.819891,
		"totalScheduleItems": 3219,
		"schedulePercent": 0.87154,
		"totalGuests": 1315,
		"guestPercent": 0.873792,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 75
	},
	{
		"id": "e60626c",
		"email": "schafferlauryn11@gmail.com",
		"name": "Lauryn",
		"createdDate": "2023-08-28 18:42:47",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 38,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 436,
		"schedulePercent": 0.270825,
		"totalGuests": 156,
		"guestPercent": 0.518607,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Meridian Theatres @ Centrepointe",
		"mostGuestCity": "Ottawa",
		"mostGuestAmount": 37
	},
	{
		"id": "c3c0fc1",
		"email": "Mathewhyun@keel.la",
		"name": "Mathew",
		"createdDate": "2022-10-25 20:51:20",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 304,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 1218,
		"schedulePercent": 0.544828,
		"totalGuests": 418,
		"guestPercent": 0.66031,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 64
	},
	{
		"id": "51fb09f",
		"email": "rjorda@cityparksfoundation.org",
		"name": "Rosemary",
		"createdDate": "2017-04-26 18:33:32",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b15f037",
		"email": "kris030@gmail.com",
		"name": "Kristian",
		"createdDate": "2015-11-09 08:01:16",
		"totalOrganizations": 4,
		"totalTours": 12,
		"totalDays": 268,
		"totalCountries": 9,
		"totalStates": 41,
		"totalEvents": 171,
		"eventPercent": 0.75394,
		"totalScheduleItems": 2087,
		"schedulePercent": 0.732883,
		"totalGuests": 2048,
		"guestPercent": 0.936962,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Forum Melbourne",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 70
	},
	{
		"id": "bfabaa7",
		"email": "jordanjonesprod@icloud.com",
		"name": "Jordan",
		"createdDate": "2019-03-04 04:16:37",
		"totalOrganizations": 9,
		"totalTours": 5,
		"totalDays": 493,
		"totalCountries": 3,
		"totalStates": 46,
		"totalEvents": 198,
		"eventPercent": 0.802543,
		"totalScheduleItems": 3596,
		"schedulePercent": 0.900013,
		"totalGuests": 2909,
		"guestPercent": 0.968349,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 86
	},
	{
		"id": "b41b17b",
		"email": "daniel@m3music.co",
		"name": "Daniel ",
		"createdDate": "2016-09-19 20:45:42",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 88,
		"totalCountries": 7,
		"totalStates": 13,
		"totalEvents": 1,
		"eventPercent": 0.00662164,
		"totalScheduleItems": 490,
		"schedulePercent": 0.292676,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a9f8677",
		"email": "sooze@methodmusic.co.uk",
		"name": "Sooze",
		"createdDate": "2017-05-30 02:36:10",
		"totalOrganizations": 10,
		"totalTours": 9,
		"totalDays": 257,
		"totalCountries": 12,
		"totalStates": 36,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1429,
		"schedulePercent": 0.599126,
		"totalGuests": 686,
		"guestPercent": 0.749702,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "930 Mateo Street",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 111
	},
	{
		"id": "e62ef29",
		"email": "carine.kowalik@gmail.com",
		"name": "Carine",
		"createdDate": "2021-11-06 01:20:32",
		"totalOrganizations": 12,
		"totalTours": 18,
		"totalDays": 507,
		"totalCountries": 18,
		"totalStates": 70,
		"totalEvents": 262,
		"eventPercent": 0.887962,
		"totalScheduleItems": 3523,
		"schedulePercent": 0.895775,
		"totalGuests": 47,
		"guestPercent": 0.417031,
		"mostGuestDate": "2023-06-14 00:00:00",
		"mostGuestName": "Brick By Brick",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 12
	},
	{
		"id": "aa1d76c",
		"email": "simone.ubaldi@gmail.com",
		"name": "Simone",
		"createdDate": "2018-05-11 09:57:13",
		"totalOrganizations": 3,
		"totalTours": 12,
		"totalDays": 278,
		"totalCountries": 15,
		"totalStates": 51,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1289,
		"schedulePercent": 0.563634,
		"totalGuests": 555,
		"guestPercent": 0.711296,
		"mostGuestDate": "2023-02-06 00:00:00",
		"mostGuestName": "The Vermont Hollywood",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 92
	},
	{
		"id": "8515333",
		"email": "boyharsher@gmail.com",
		"name": "Bailey",
		"createdDate": "2021-12-08 08:31:52",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 64,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 68,
		"schedulePercent": 0.0678056,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a242eac",
		"email": "celine@highwayholidays.eu",
		"name": "C&#233;line",
		"createdDate": "2023-05-25 11:55:53",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 67,
		"totalCountries": 10,
		"totalStates": 18,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 449,
		"schedulePercent": 0.27599,
		"totalGuests": 46,
		"guestPercent": 0.415044,
		"mostGuestDate": "2023-09-21 00:00:00",
		"mostGuestName": "Trabendo",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 18
	},
	{
		"id": "1523c66",
		"email": "ricardologan.tour@gmail.com",
		"name": "Ricardo",
		"createdDate": "2022-09-06 18:24:15",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 177,
		"totalCountries": 8,
		"totalStates": 28,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 530,
		"schedulePercent": 0.312939,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-11-15 00:00:00",
		"mostGuestName": "Maida Vale",
		"mostGuestCity": "London",
		"mostGuestAmount": 4
	},
	{
		"id": "af53b7e",
		"email": "kristof@zweite-heimat-tourservice.de",
		"name": "Kristof",
		"createdDate": "2013-06-10 13:15:44",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 113,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 283,
		"schedulePercent": 0.199841,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e73ecdb",
		"email": "andrewcleahey@gmail.com",
		"name": "Andrew",
		"createdDate": "2018-12-21 20:01:45",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 557,
		"totalCountries": 12,
		"totalStates": 31,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 856,
		"schedulePercent": 0.439015,
		"totalGuests": 780,
		"guestPercent": 0.771686,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 74
	},
	{
		"id": "dd14aac",
		"email": "j@nowucme.com",
		"name": "J",
		"createdDate": "2018-06-05 22:33:11",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 383,
		"totalCountries": 14,
		"totalStates": 36,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 584,
		"schedulePercent": 0.333731,
		"totalGuests": 13,
		"guestPercent": 0.352536,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "W Lounge",
		"mostGuestCity": "Amsterdam",
		"mostGuestAmount": 7
	},
	{
		"id": "e36a85f",
		"email": "KLGinther@gmail.com",
		"name": "Kristin",
		"createdDate": "2019-02-18 18:55:01",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 209,
		"totalCountries": 3,
		"totalStates": 39,
		"totalEvents": 154,
		"eventPercent": 0.71368,
		"totalScheduleItems": 1416,
		"schedulePercent": 0.594888,
		"totalGuests": 1465,
		"guestPercent": 0.889683,
		"mostGuestDate": "2023-11-27 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 86
	},
	{
		"id": "bd79ea5",
		"email": "bridgetbuch@gmail.com",
		"name": "Bridget",
		"createdDate": "2022-07-04 15:14:37",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 76,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 516,
		"schedulePercent": 0.306185,
		"totalGuests": 23,
		"guestPercent": 0.37452,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "Knitting Factory - North Hollywood",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 10
	},
	{
		"id": "afdc06c",
		"email": "karlkevinvincent@gmail.com",
		"name": "Karl",
		"createdDate": "2021-06-17 13:51:45",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 81,
		"totalCountries": 9,
		"totalStates": 20,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 527,
		"schedulePercent": 0.311349,
		"totalGuests": 129,
		"guestPercent": 0.497815,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "Slim Jim's Liquor Store",
		"mostGuestCity": "London",
		"mostGuestAmount": 73
	},
	{
		"id": "1260b9b",
		"email": "Kepsljud@gmail.com",
		"name": "Anders",
		"createdDate": "2013-07-23 09:38:15",
		"totalOrganizations": 13,
		"totalTours": 8,
		"totalDays": 164,
		"totalCountries": 5,
		"totalStates": 27,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 965,
		"schedulePercent": 0.469607,
		"totalGuests": 313,
		"guestPercent": 0.61078,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "Mosebacketerrassen",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 66
	},
	{
		"id": "c0a6c75",
		"email": "igniteband93@gmail.com",
		"name": "Brett",
		"createdDate": "2023-06-13 17:19:38",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 29,
		"totalCountries": 12,
		"totalStates": 16,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 158,
		"schedulePercent": 0.125811,
		"totalGuests": 54,
		"guestPercent": 0.427096,
		"mostGuestDate": "2023-07-10 00:00:00",
		"mostGuestName": "Conne Island",
		"mostGuestCity": "Leipzig",
		"mostGuestAmount": 10
	},
	{
		"id": "14e7e39",
		"email": "blairwoods@mac.com",
		"name": "Blair",
		"createdDate": "2013-01-21 20:33:43",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 165,
		"totalCountries": 7,
		"totalStates": 28,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 971,
		"schedulePercent": 0.47252,
		"totalGuests": 501,
		"guestPercent": 0.692226,
		"mostGuestDate": "2023-09-11 00:00:00",
		"mostGuestName": "Theatre Royal Drury Lane",
		"mostGuestCity": "London",
		"mostGuestAmount": 28
	},
	{
		"id": "f6829d7",
		"email": "info@lettucefunk.com",
		"name": "Hillary",
		"createdDate": "2021-02-04 16:29:25",
		"totalOrganizations": 9,
		"totalTours": 43,
		"totalDays": 955,
		"totalCountries": 17,
		"totalStates": 75,
		"totalEvents": 365,
		"eventPercent": 0.948086,
		"totalScheduleItems": 3147,
		"schedulePercent": 0.865316,
		"totalGuests": 1728,
		"guestPercent": 0.915243,
		"mostGuestDate": "2023-04-15 00:00:00",
		"mostGuestName": "St. Augustine Amphitheatre",
		"mostGuestCity": "St. Augustine",
		"mostGuestAmount": 104
	},
	{
		"id": "23edb26",
		"email": "colin@irationmusic.com",
		"name": "Colin",
		"createdDate": "2013-05-07 21:11:59",
		"totalOrganizations": 7,
		"totalTours": 23,
		"totalDays": 952,
		"totalCountries": 7,
		"totalStates": 48,
		"totalEvents": 265,
		"eventPercent": 0.890743,
		"totalScheduleItems": 4882,
		"schedulePercent": 0.957754,
		"totalGuests": 6966,
		"guestPercent": 0.998278,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "The Pacific Amphitheatre",
		"mostGuestCity": "Costa Mesa",
		"mostGuestAmount": 238
	},
	{
		"id": "62872ec",
		"email": "tamara@tric-agency.com",
		"name": "Tamara",
		"createdDate": "2022-12-09 09:43:39",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 29,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 335,
		"schedulePercent": 0.225798,
		"totalGuests": 195,
		"guestPercent": 0.546418,
		"mostGuestDate": "2023-10-29 00:00:00",
		"mostGuestName": "Jannus Live",
		"mostGuestCity": "St. Petersburg",
		"mostGuestAmount": 38
	},
	{
		"id": "8cec9e5",
		"email": "thekingannie@gmail.com",
		"name": "Annie",
		"createdDate": "2023-05-04 00:43:03",
		"totalOrganizations": 2,
		"totalTours": 11,
		"totalDays": 95,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 573,
		"schedulePercent": 0.330023,
		"totalGuests": 40,
		"guestPercent": 0.405642,
		"mostGuestDate": "2023-05-10 00:00:00",
		"mostGuestName": "Belly Up",
		"mostGuestCity": "Solana Beach",
		"mostGuestAmount": 23
	},
	{
		"id": "55784eb",
		"email": "ancillary@weareblackbox.com",
		"name": "Ben",
		"createdDate": "2022-08-31 17:33:19",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 89,
		"totalCountries": 1,
		"totalStates": 25,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 613,
		"schedulePercent": 0.346974,
		"totalGuests": 263,
		"guestPercent": 0.585618,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 98
	},
	{
		"id": "40fd066",
		"email": "imjustinthyme2@gmail.com",
		"name": "Team",
		"createdDate": "2022-03-15 18:06:26",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 166,
		"totalCountries": 3,
		"totalStates": 23,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 558,
		"schedulePercent": 0.32499,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "de2c108",
		"email": "ben@clearlampav.com",
		"name": "Ben",
		"createdDate": "2018-07-31 21:45:50",
		"totalOrganizations": 6,
		"totalTours": 11,
		"totalDays": 83,
		"totalCountries": 4,
		"totalStates": 22,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 634,
		"schedulePercent": 0.355847,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f542cfe",
		"email": "Kristen@iconforhire.net",
		"name": "Kristen",
		"createdDate": "2020-02-19 04:16:38",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 102,
		"totalCountries": 2,
		"totalStates": 37,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 699,
		"schedulePercent": 0.380215,
		"totalGuests": 158,
		"guestPercent": 0.520858,
		"mostGuestDate": "2023-05-21 00:00:00",
		"mostGuestName": "The End",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 28
	},
	{
		"id": "a074e97",
		"email": "wcswitzer@gmail.com",
		"name": "Wesley",
		"createdDate": "2013-05-05 03:19:37",
		"totalOrganizations": 20,
		"totalTours": 12,
		"totalDays": 504,
		"totalCountries": 6,
		"totalStates": 41,
		"totalEvents": 223,
		"eventPercent": 0.840154,
		"totalScheduleItems": 2072,
		"schedulePercent": 0.730102,
		"totalGuests": 182,
		"guestPercent": 0.538074,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "The Enmore Theatre",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 25
	},
	{
		"id": "36c0631",
		"email": "e.evaherisson@gmail.com",
		"name": "Eva",
		"createdDate": "2022-09-02 17:14:24",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 101,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 526,
		"schedulePercent": 0.310025,
		"totalGuests": 959,
		"guestPercent": 0.815654,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "Folies BergÃ¨re",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 87
	},
	{
		"id": "8962e6d",
		"email": "gio.vazquez.TM@gmail.com",
		"name": "Giovanna",
		"createdDate": "2021-06-15 20:01:13",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 101,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 167,
		"schedulePercent": 0.130711,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4a28e72",
		"email": "ryland.close@redlightmanagement.com",
		"name": "Ryland",
		"createdDate": "2023-09-05 20:01:58",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 116,
		"totalCountries": 1,
		"totalStates": 15,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 217,
		"schedulePercent": 0.160906,
		"totalGuests": 61,
		"guestPercent": 0.435571,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Barrelhouse Ballroom",
		"mostGuestCity": "Chattanooga",
		"mostGuestAmount": 15
	},
	{
		"id": "af5ce66",
		"email": "steven.lieberman@protravelinc.com",
		"name": "steven",
		"createdDate": "2019-05-14 15:55:48",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 737,
		"totalCountries": 2,
		"totalStates": 39,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 3334,
		"schedulePercent": 0.881473,
		"totalGuests": 955,
		"guestPercent": 0.814064,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Cadence Bank Amphitheatre at Chastain Park",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 67
	},
	{
		"id": "5bc9bb3",
		"email": "jennifer@fitzhart.com",
		"name": "Jennifer",
		"createdDate": "2013-09-05 16:36:16",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 24,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 262,
		"schedulePercent": 0.186465,
		"totalGuests": 115,
		"guestPercent": 0.48722,
		"mostGuestDate": "2023-02-25 00:00:00",
		"mostGuestName": "Acrisure Arena",
		"mostGuestCity": "Palm Springs",
		"mostGuestAmount": 13
	},
	{
		"id": "f652773",
		"email": "smith_trey@me.com",
		"name": "Theodore",
		"createdDate": "2010-08-20 17:27:35",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 383,
		"totalCountries": 4,
		"totalStates": 39,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 2756,
		"schedulePercent": 0.826381,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3d28a74",
		"email": "tanyahart@lulaproductions.ca",
		"name": "Tanya",
		"createdDate": "2016-10-17 22:58:40",
		"totalOrganizations": 4,
		"totalTours": 17,
		"totalDays": 340,
		"totalCountries": 7,
		"totalStates": 31,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1563,
		"schedulePercent": 0.633426,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ec9988b",
		"email": "hellochiiild@gmail.com",
		"name": "Hello",
		"createdDate": "2019-08-22 19:27:45",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 66,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 319,
		"schedulePercent": 0.218117,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8adad29",
		"email": "bettisonsimon@gmail.com",
		"name": "Simon",
		"createdDate": "2018-05-22 11:19:45",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 77,
		"totalCountries": 4,
		"totalStates": 27,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 681,
		"schedulePercent": 0.373328,
		"totalGuests": 389,
		"guestPercent": 0.648656,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "London Palladium",
		"mostGuestCity": "London",
		"mostGuestAmount": 104
	},
	{
		"id": "ef84c4f",
		"email": "laurahansford@icloud.com",
		"name": "Laura",
		"createdDate": "2023-04-28 02:39:10",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 65,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 371,
		"schedulePercent": 0.244074,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d50085a",
		"email": "jlcouleard@gmail.com",
		"name": "JEAN LOUIS",
		"createdDate": "2020-01-16 21:09:21",
		"totalOrganizations": 6,
		"totalTours": 15,
		"totalDays": 746,
		"totalCountries": 15,
		"totalStates": 38,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 2091,
		"schedulePercent": 0.73381,
		"totalGuests": 630,
		"guestPercent": 0.733943,
		"mostGuestDate": "2023-08-06 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 126
	},
	{
		"id": "b1b4b2b",
		"email": "zach@7smgmt.com",
		"name": "Zach",
		"createdDate": "2014-12-27 21:17:57",
		"totalOrganizations": 7,
		"totalTours": 18,
		"totalDays": 287,
		"totalCountries": 13,
		"totalStates": 41,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 1879,
		"schedulePercent": 0.701099,
		"totalGuests": 2185,
		"guestPercent": 0.942789,
		"mostGuestDate": "2023-08-23 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 310
	},
	{
		"id": "e866080",
		"email": "tmr.hewitt@gmail.com",
		"name": "Rich",
		"createdDate": "2020-01-06 10:11:32",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 762,
		"totalCountries": 3,
		"totalStates": 12,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 1691,
		"schedulePercent": 0.662959,
		"totalGuests": 755,
		"guestPercent": 0.765991,
		"mostGuestDate": "2023-11-29 00:00:00",
		"mostGuestName": "G Live",
		"mostGuestCity": "Guildford",
		"mostGuestAmount": 82
	},
	{
		"id": "a135950",
		"email": "bastyduellmann@justmusic-productions.com",
		"name": "Basty ",
		"createdDate": "2017-09-28 14:03:17",
		"totalOrganizations": 15,
		"totalTours": 12,
		"totalDays": 424,
		"totalCountries": 13,
		"totalStates": 37,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 2305,
		"schedulePercent": 0.765859,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6c75373",
		"email": "jeff@e1fx.com",
		"name": "Jeff",
		"createdDate": "2020-01-08 22:20:47",
		"totalOrganizations": 43,
		"totalTours": 29,
		"totalDays": 787,
		"totalCountries": 3,
		"totalStates": 43,
		"totalEvents": 537,
		"eventPercent": 0.978016,
		"totalScheduleItems": 773,
		"schedulePercent": 0.410012,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-01-08 00:00:00",
		"mostGuestName": "TIAA Bank Field",
		"mostGuestCity": "Jacksonville",
		"mostGuestAmount": 0
	},
	{
		"id": "9fb4a30",
		"email": "mr211167@aol.com",
		"name": "Michael",
		"createdDate": "2013-03-06 17:45:54",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 318,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 1259,
		"schedulePercent": 0.554231,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6685ef3",
		"email": "pa311@me.com",
		"name": "Eriko",
		"createdDate": "2018-09-30 22:33:16",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 668,
		"totalCountries": 17,
		"totalStates": 54,
		"totalEvents": 137,
		"eventPercent": 0.666402,
		"totalScheduleItems": 2204,
		"schedulePercent": 0.750762,
		"totalGuests": 18,
		"guestPercent": 0.364455,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Tingley Coliseum",
		"mostGuestCity": "Albuquerque",
		"mostGuestAmount": 18
	},
	{
		"id": "8af6888",
		"email": "info@lankfordmedia.com",
		"name": "Gary",
		"createdDate": "2023-03-08 18:58:42",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 175,
		"totalCountries": 1,
		"totalStates": 31,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 520,
		"schedulePercent": 0.307906,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5243471",
		"email": "josh@davekozcruise.com",
		"name": "Josh",
		"createdDate": "2021-11-09 03:51:26",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 130,
		"totalCountries": 1,
		"totalStates": 23,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 886,
		"schedulePercent": 0.448682,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "74de81b",
		"email": "timdurfey@aol.com",
		"name": "Timothy",
		"createdDate": "2015-12-12 05:45:32",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2df89ad",
		"email": "dontemailalan@mac.com",
		"name": "Alan",
		"createdDate": "2018-02-09 12:35:11",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 325,
		"totalCountries": 13,
		"totalStates": 24,
		"totalEvents": 155,
		"eventPercent": 0.716197,
		"totalScheduleItems": 1765,
		"schedulePercent": 0.677659,
		"totalGuests": 72,
		"guestPercent": 0.446828,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "StÃ¥ngebrofÃ¤ltet",
		"mostGuestCity": "LinkÃ¶ping",
		"mostGuestAmount": 28
	},
	{
		"id": "e7b4198",
		"email": "bandbabysitter@gmail.com",
		"name": "Katie",
		"createdDate": "2016-03-31 09:23:36",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 169,
		"totalCountries": 8,
		"totalStates": 32,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 1119,
		"schedulePercent": 0.515958,
		"totalGuests": 58,
		"guestPercent": 0.431863,
		"mostGuestDate": "2023-11-29 00:00:00",
		"mostGuestName": "Spark Arena",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 38
	},
	{
		"id": "5fb6bd6",
		"email": "bandprod@aol.com",
		"name": "Brian",
		"createdDate": "2012-04-16 03:06:17",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 49,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 95,
		"schedulePercent": 0.0860813,
		"totalGuests": 40,
		"guestPercent": 0.405642,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "USC -  McCarthy Quad ",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 24
	},
	{
		"id": "e0f6748",
		"email": "gdiaz@fpment.com",
		"name": "Gabriela",
		"createdDate": "2021-05-10 18:23:51",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 359,
		"totalCountries": 8,
		"totalStates": 26,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 683,
		"schedulePercent": 0.37399,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "990bc69",
		"email": "manueldelavega@me.com",
		"name": "Marc-Emmanuel",
		"createdDate": "2018-10-01 20:29:59",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 528,
		"totalCountries": 5,
		"totalStates": 21,
		"totalEvents": 141,
		"eventPercent": 0.678321,
		"totalScheduleItems": 2026,
		"schedulePercent": 0.72401,
		"totalGuests": 20,
		"guestPercent": 0.36909,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "Les dÃ©ferlantes sud de France",
		"mostGuestCity": "CÃ©ret",
		"mostGuestAmount": 18
	},
	{
		"id": "9d4c8fb",
		"email": "georgiewharrison@gmail.com",
		"name": "Georgie",
		"createdDate": "2018-03-02 17:01:54",
		"totalOrganizations": 16,
		"totalTours": 18,
		"totalDays": 1002,
		"totalCountries": 13,
		"totalStates": 39,
		"totalEvents": 222,
		"eventPercent": 0.839227,
		"totalScheduleItems": 2556,
		"schedulePercent": 0.799894,
		"totalGuests": 114,
		"guestPercent": 0.486028,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Zebulon",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 27
	},
	{
		"id": "a049ede",
		"email": "feal.lerouzic@gmail.com",
		"name": "Philippe",
		"createdDate": "2021-06-29 11:22:34",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 224,
		"totalCountries": 3,
		"totalStates": 17,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 991,
		"schedulePercent": 0.480996,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e0ec2a8",
		"email": "stephen@stoventours.com",
		"name": "Stephen",
		"createdDate": "2022-12-29 20:27:31",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 98,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 396,
		"schedulePercent": 0.25586,
		"totalGuests": 188,
		"guestPercent": 0.541518,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Regent Theater DTLA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 47
	},
	{
		"id": "b5d3512",
		"email": "mark@slingsandarrowsmgmt.com",
		"name": "Mark",
		"createdDate": "2022-10-01 01:51:10",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 191,
		"totalCountries": 12,
		"totalStates": 38,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 798,
		"schedulePercent": 0.419547,
		"totalGuests": 65,
		"guestPercent": 0.440207,
		"mostGuestDate": "2023-06-05 00:00:00",
		"mostGuestName": "Heaven",
		"mostGuestCity": "London",
		"mostGuestAmount": 9
	},
	{
		"id": "e532d1a",
		"email": "Tyler@detoursllc.com",
		"name": "Tyler",
		"createdDate": "2022-02-04 17:55:08",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 481,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 554,
		"schedulePercent": 0.323136,
		"totalGuests": 298,
		"guestPercent": 0.60098,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 65
	},
	{
		"id": "f7a51c7",
		"email": "paulcprod@gmail.com",
		"name": "Paul",
		"createdDate": "2010-01-14 16:48:24",
		"totalOrganizations": 17,
		"totalTours": 15,
		"totalDays": 830,
		"totalCountries": 5,
		"totalStates": 50,
		"totalEvents": 336,
		"eventPercent": 0.935108,
		"totalScheduleItems": 3279,
		"schedulePercent": 0.876175,
		"totalGuests": 1206,
		"guestPercent": 0.858032,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "HISTORY",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 117
	},
	{
		"id": "15a8859",
		"email": "taylerbock@hotmail.com",
		"name": "Tayler",
		"createdDate": "2018-06-29 16:41:32",
		"totalOrganizations": 15,
		"totalTours": 16,
		"totalDays": 947,
		"totalCountries": 3,
		"totalStates": 46,
		"totalEvents": 195,
		"eventPercent": 0.796318,
		"totalScheduleItems": 1193,
		"schedulePercent": 0.538472,
		"totalGuests": 187,
		"guestPercent": 0.540988,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Banita Creek Hall",
		"mostGuestCity": "Nacogdoches",
		"mostGuestAmount": 27
	},
	{
		"id": "ad4622f",
		"email": "belinda@gomcircus.com.au",
		"name": "Belinda",
		"createdDate": "2018-06-27 18:13:23",
		"totalOrganizations": 1,
		"totalTours": 9,
		"totalDays": 480,
		"totalCountries": 13,
		"totalStates": 43,
		"totalEvents": 214,
		"eventPercent": 0.829559,
		"totalScheduleItems": 1658,
		"schedulePercent": 0.655145,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7cd981b",
		"email": "brandonma728@gmail.com",
		"name": "Brandon",
		"createdDate": "2022-04-24 01:55:31",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 175,
		"totalCountries": 3,
		"totalStates": 6,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 642,
		"schedulePercent": 0.359423,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4895bcd",
		"email": "andrew.kim.lewis@gmail.com",
		"name": "Andrew",
		"createdDate": "2023-02-02 23:48:32",
		"totalOrganizations": 2,
		"totalTours": 8,
		"totalDays": 70,
		"totalCountries": 3,
		"totalStates": 22,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 555,
		"schedulePercent": 0.323798,
		"totalGuests": 622,
		"guestPercent": 0.731956,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 188
	},
	{
		"id": "276e78d",
		"email": "production.stanleyclarke@gmail.com",
		"name": "GREGOIRE",
		"createdDate": "2023-04-13 08:11:07",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 26,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 13,
		"eventPercent": 0.0892597,
		"totalScheduleItems": 166,
		"schedulePercent": 0.130314,
		"totalGuests": 19,
		"guestPercent": 0.366971,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "ThÃ©Ã¢tre Jean Vilar",
		"mostGuestCity": "SÃ¨te",
		"mostGuestAmount": 7
	},
	{
		"id": "5680101",
		"email": "kasey@forwardartist.management",
		"name": "Kasey",
		"createdDate": "2022-05-12 15:35:11",
		"totalOrganizations": 8,
		"totalTours": 11,
		"totalDays": 462,
		"totalCountries": 13,
		"totalStates": 39,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 1547,
		"schedulePercent": 0.629188,
		"totalGuests": 1771,
		"guestPercent": 0.918289,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Grand Garden Arena",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 161
	},
	{
		"id": "5203299",
		"email": "robbright76@gmail.com",
		"name": "Rob",
		"createdDate": "2017-10-01 01:14:08",
		"totalOrganizations": 6,
		"totalTours": 1,
		"totalDays": 221,
		"totalCountries": 8,
		"totalStates": 42,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1481,
		"schedulePercent": 0.609456,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "Vivint Smart Home Arena",
		"mostGuestCity": "Salt Lake City",
		"mostGuestAmount": 15
	},
	{
		"id": "876e2d8",
		"email": "mark.gray@fishbowlspirits.com",
		"name": "Mark",
		"createdDate": "2022-03-23 12:59:14",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 173,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 230,
		"schedulePercent": 0.169646,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c20508a",
		"email": "megan@peachtreeent.com",
		"name": "Megan",
		"createdDate": "2023-03-13 17:31:37",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 680,
		"totalCountries": 6,
		"totalStates": 35,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 680,
		"schedulePercent": 0.372533,
		"totalGuests": 89,
		"guestPercent": 0.462323,
		"mostGuestDate": "2023-10-12 00:00:00",
		"mostGuestName": "Walk of Fame Park",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 27
	},
	{
		"id": "c7ba3ef",
		"email": "nb@46entertainment.net",
		"name": "Nathan",
		"createdDate": "2014-07-15 22:06:33",
		"totalOrganizations": 6,
		"totalTours": 1,
		"totalDays": 146,
		"totalCountries": 2,
		"totalStates": 35,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1125,
		"schedulePercent": 0.51768,
		"totalGuests": 593,
		"guestPercent": 0.723878,
		"mostGuestDate": "2023-03-06 00:00:00",
		"mostGuestName": "Florida Strawberry Festival",
		"mostGuestCity": "Plant City",
		"mostGuestAmount": 40
	},
	{
		"id": "1f79e24",
		"email": "laurashotton@gmail.com",
		"name": "Laura",
		"createdDate": "2020-01-27 14:48:18",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 745,
		"totalCountries": 7,
		"totalStates": 33,
		"totalEvents": 352,
		"eventPercent": 0.942127,
		"totalScheduleItems": 4817,
		"schedulePercent": 0.955635,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Lincoln Financial Field",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 4
	},
	{
		"id": "67ce65d",
		"email": "coobraa1@yahoo.com",
		"name": "Dave",
		"createdDate": "2012-05-07 23:37:58",
		"totalOrganizations": 3,
		"totalTours": 21,
		"totalDays": 881,
		"totalCountries": 6,
		"totalStates": 42,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 1121,
		"schedulePercent": 0.516885,
		"totalGuests": 46,
		"guestPercent": 0.415044,
		"mostGuestDate": "2023-03-10 00:00:00",
		"mostGuestName": "Paramount Theatre, VT",
		"mostGuestCity": "Rutland",
		"mostGuestAmount": 8
	},
	{
		"id": "c967644",
		"email": "JeffClemensKY@gmail.com",
		"name": "Jeff",
		"createdDate": "2023-02-17 23:28:06",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 185,
		"totalCountries": 2,
		"totalStates": 47,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 2073,
		"schedulePercent": 0.730499,
		"totalGuests": 1510,
		"guestPercent": 0.895246,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Mizner Park Amphitheatre",
		"mostGuestCity": "Boca Raton",
		"mostGuestAmount": 80
	},
	{
		"id": "9abb838",
		"email": "bmgtourcm@networkstours.com",
		"name": "Alanna",
		"createdDate": "2022-01-07 21:19:09",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 102,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 680,
		"schedulePercent": 0.372533,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9ce44a2",
		"email": "bbv@bbvband.com",
		"name": "Jeremy",
		"createdDate": "2022-05-05 21:21:42",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 165,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 152,
		"schedulePercent": 0.121971,
		"totalGuests": 35,
		"guestPercent": 0.396901,
		"mostGuestDate": "2023-06-02 00:00:00",
		"mostGuestName": "Grand Annex (Taran Schindler)",
		"mostGuestCity": "San Pedro",
		"mostGuestAmount": 20
	},
	{
		"id": "36450a2",
		"email": "veera.tarvonen@warnermusic.com",
		"name": "Veera",
		"createdDate": "2022-04-21 08:59:50",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 86,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 302,
		"schedulePercent": 0.209244,
		"totalGuests": 27,
		"guestPercent": 0.38379,
		"mostGuestDate": "2023-08-04 00:00:00",
		"mostGuestName": "Power Festival",
		"mostGuestCity": "JÃ¤rvenpÃ¤Ã¤",
		"mostGuestAmount": 15
	},
	{
		"id": "c341bce",
		"email": "markglade@gmail.com",
		"name": "Mark",
		"createdDate": "2017-04-22 14:20:58",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 53,
		"totalCountries": 4,
		"totalStates": 5,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 153,
		"schedulePercent": 0.122765,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9d092d7",
		"email": "technik@labrassbanda.com",
		"name": "Stefan",
		"createdDate": "2015-05-04 14:59:44",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 56,
		"totalCountries": 6,
		"totalStates": 14,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 703,
		"schedulePercent": 0.381671,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-06-09 00:00:00",
		"mostGuestName": "Zeltfestival Rhein-Neckar",
		"mostGuestCity": "Mannheim",
		"mostGuestAmount": 4
	},
	{
		"id": "8f346c9",
		"email": "nina@hard8mgt.com",
		"name": "Nina",
		"createdDate": "2020-02-24 11:37:38",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 30,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 159,
		"schedulePercent": 0.126208,
		"totalGuests": 10,
		"guestPercent": 0.343531,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 7
	},
	{
		"id": "66e820a",
		"email": "payhype@gmail.com",
		"name": "Walter",
		"createdDate": "2017-11-04 19:55:46",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 367,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 994,
		"schedulePercent": 0.482585,
		"totalGuests": 68,
		"guestPercent": 0.444312,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Lakefront Arena",
		"mostGuestCity": "New Orleans",
		"mostGuestAmount": 38
	},
	{
		"id": "72b5015",
		"email": "tiffany@kriscar.com",
		"name": "Tiffany ",
		"createdDate": "2021-06-22 01:52:36",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 729,
		"totalCountries": 3,
		"totalStates": 17,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 1947,
		"schedulePercent": 0.711032,
		"totalGuests": 934,
		"guestPercent": 0.808105,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Julian B Lane Riverfront Park",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 92
	},
	{
		"id": "9451771",
		"email": "tldellavecchia@gmail.com",
		"name": "thomas",
		"createdDate": "2022-06-27 17:37:26",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 143,
		"totalCountries": 3,
		"totalStates": 22,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 482,
		"schedulePercent": 0.289631,
		"totalGuests": 43,
		"guestPercent": 0.410674,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "Elsewhere",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 33
	},
	{
		"id": "031bb02",
		"email": "neiceeoakley@gmail.com",
		"name": "NEICEE ",
		"createdDate": "2016-06-08 17:19:33",
		"totalOrganizations": 9,
		"totalTours": 16,
		"totalDays": 691,
		"totalCountries": 11,
		"totalStates": 19,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 1240,
		"schedulePercent": 0.549861,
		"totalGuests": 33,
		"guestPercent": 0.394252,
		"mostGuestDate": "2023-07-05 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 21
	},
	{
		"id": "e06a7ad",
		"email": "benwilco@gmail.com",
		"name": "Ben",
		"createdDate": "2013-01-28 16:14:01",
		"totalOrganizations": 21,
		"totalTours": 17,
		"totalDays": 1152,
		"totalCountries": 20,
		"totalStates": 71,
		"totalEvents": 309,
		"eventPercent": 0.920408,
		"totalScheduleItems": 4058,
		"schedulePercent": 0.925573,
		"totalGuests": 1176,
		"guestPercent": 0.853264,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Petco Park",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 186
	},
	{
		"id": "bc7f1d9",
		"email": "jonas@tcob.biz",
		"name": "Jonas",
		"createdDate": "2022-05-12 19:16:41",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 307,
		"totalCountries": 12,
		"totalStates": 30,
		"totalEvents": 303,
		"eventPercent": 0.916832,
		"totalScheduleItems": 2765,
		"schedulePercent": 0.827705,
		"totalGuests": 1987,
		"guestPercent": 0.933121,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Olympiastadion",
		"mostGuestCity": "Munich",
		"mostGuestAmount": 329
	},
	{
		"id": "725302a",
		"email": "Dan.Potts@redlightmanagement.com",
		"name": "Dan",
		"createdDate": "2019-05-31 10:07:13",
		"totalOrganizations": 8,
		"totalTours": 21,
		"totalDays": 261,
		"totalCountries": 11,
		"totalStates": 44,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 1596,
		"schedulePercent": 0.641769,
		"totalGuests": 283,
		"guestPercent": 0.595153,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Brighton Beach",
		"mostGuestCity": "Brighton ",
		"mostGuestAmount": 35
	},
	{
		"id": "70c3830",
		"email": "JRosenberg@primarywave.com",
		"name": "Jared",
		"createdDate": "2021-07-09 21:49:25",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 103,
		"totalCountries": 3,
		"totalStates": 16,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 597,
		"schedulePercent": 0.339558,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b48fcd2",
		"email": "greg@fsavl.com",
		"name": "Greg",
		"createdDate": "2018-07-23 15:20:44",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 127,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 122,
		"schedulePercent": 0.104092,
		"totalGuests": 100,
		"guestPercent": 0.473977,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Marion Cultural and Civic Center",
		"mostGuestCity": "Marion",
		"mostGuestAmount": 21
	},
	{
		"id": "dfb7cb0",
		"email": "dmosley@aegpresents.com",
		"name": "Dan",
		"createdDate": "2023-02-02 01:34:37",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 349,
		"totalCountries": 15,
		"totalStates": 30,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 1341,
		"schedulePercent": 0.577937,
		"totalGuests": 202,
		"guestPercent": 0.552774,
		"mostGuestDate": "2023-02-24 00:00:00",
		"mostGuestName": "Yebisu Garden Place Tower",
		"mostGuestCity": "",
		"mostGuestAmount": 23
	},
	{
		"id": "bfd2411",
		"email": "kennecraig@gmail.com",
		"name": "Kenn",
		"createdDate": "2013-10-28 21:12:40",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 2,
		"totalStates": 33,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 2337,
		"schedulePercent": 0.772348,
		"totalGuests": 830,
		"guestPercent": 0.783472,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Hard Rock Casino Cincinnati",
		"mostGuestCity": "Cincinnati",
		"mostGuestAmount": 40
	},
	{
		"id": "1abdb09",
		"email": "mindy@pjandth.com",
		"name": "Mindy",
		"createdDate": "2023-07-04 14:23:01",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 50,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 96,
		"schedulePercent": 0.086611,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "98fdd75",
		"email": "barbara.satine@gmail.com",
		"name": "Barbara",
		"createdDate": "2023-06-24 19:00:41",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 157,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 439,
		"schedulePercent": 0.272547,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "965ae02",
		"email": "kp@mpeckable.biz",
		"name": "Kevin",
		"createdDate": "2019-10-31 22:53:07",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 731,
		"totalCountries": 5,
		"totalStates": 35,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 122,
		"schedulePercent": 0.104092,
		"totalGuests": 93,
		"guestPercent": 0.468017,
		"mostGuestDate": "2023-08-27 00:00:00",
		"mostGuestName": "The Pavilion At Toyota Music Factory",
		"mostGuestCity": "Irving",
		"mostGuestAmount": 8
	},
	{
		"id": "f298a95",
		"email": "carstenjull@gmail.com",
		"name": "Carsten",
		"createdDate": "2019-05-13 20:24:29",
		"totalOrganizations": 9,
		"totalTours": 13,
		"totalDays": 279,
		"totalCountries": 4,
		"totalStates": 9,
		"totalEvents": 262,
		"eventPercent": 0.887962,
		"totalScheduleItems": 1297,
		"schedulePercent": 0.56562,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-01-07 00:00:00",
		"mostGuestName": "Royal Stage",
		"mostGuestCity": "HillerÃ¸d",
		"mostGuestAmount": 0
	},
	{
		"id": "3c784a2",
		"email": "fglyd@me.com",
		"name": "Frank",
		"createdDate": "2014-05-27 12:28:47",
		"totalOrganizations": 13,
		"totalTours": 8,
		"totalDays": 368,
		"totalCountries": 11,
		"totalStates": 36,
		"totalEvents": 356,
		"eventPercent": 0.944643,
		"totalScheduleItems": 2714,
		"schedulePercent": 0.821745,
		"totalGuests": 858,
		"guestPercent": 0.789564,
		"mostGuestDate": "2023-03-12 00:00:00",
		"mostGuestName": "Verti Music Hall",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 63
	},
	{
		"id": "41444c9",
		"email": "robyn@jelleffandco.com.au",
		"name": "Robyn",
		"createdDate": "2012-05-02 08:14:25",
		"totalOrganizations": 69,
		"totalTours": 49,
		"totalDays": 1432,
		"totalCountries": 24,
		"totalStates": 84,
		"totalEvents": 394,
		"eventPercent": 0.955767,
		"totalScheduleItems": 5567,
		"schedulePercent": 0.97444,
		"totalGuests": 1511,
		"guestPercent": 0.895643,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 130
	},
	{
		"id": "ea651ef",
		"email": "management@criteriaent.com",
		"name": "Angela",
		"createdDate": "2019-09-21 01:50:21",
		"totalOrganizations": 8,
		"totalTours": 7,
		"totalDays": 977,
		"totalCountries": 7,
		"totalStates": 47,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 1562,
		"schedulePercent": 0.633029,
		"totalGuests": 37,
		"guestPercent": 0.400212,
		"mostGuestDate": "2023-04-15 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 20
	},
	{
		"id": "9db8270",
		"email": "airyn.lancaster@gmail.com",
		"name": "Airyn",
		"createdDate": "2013-02-19 21:14:48",
		"totalOrganizations": 11,
		"totalTours": 9,
		"totalDays": 477,
		"totalCountries": 5,
		"totalStates": 32,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 878,
		"schedulePercent": 0.446696,
		"totalGuests": 147,
		"guestPercent": 0.511323,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "The Masonic",
		"mostGuestCity": "San Francisco",
		"mostGuestAmount": 29
	},
	{
		"id": "15d611b",
		"email": "follinartistmgmt@gmail.com",
		"name": "Heidi",
		"createdDate": "2012-01-05 18:40:02",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 191,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 356,
		"schedulePercent": 0.235863,
		"totalGuests": 98,
		"guestPercent": 0.472917,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "The Basement",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 35
	},
	{
		"id": "e2e6039",
		"email": "sp@imag.dk",
		"name": "Soren",
		"createdDate": "2013-05-13 13:53:57",
		"totalOrganizations": 28,
		"totalTours": 14,
		"totalDays": 308,
		"totalCountries": 3,
		"totalStates": 6,
		"totalEvents": 253,
		"eventPercent": 0.877765,
		"totalScheduleItems": 1561,
		"schedulePercent": 0.632764,
		"totalGuests": 252,
		"guestPercent": 0.58032,
		"mostGuestDate": "2023-01-15 00:00:00",
		"mostGuestName": "DR Koncerthuset",
		"mostGuestCity": "Kobenhavn",
		"mostGuestAmount": 24
	},
	{
		"id": "26aed58",
		"email": "tim@vavoom.club",
		"name": "Vavoom",
		"createdDate": "2022-04-19 10:35:01",
		"totalOrganizations": 4,
		"totalTours": 14,
		"totalDays": 921,
		"totalCountries": 19,
		"totalStates": 45,
		"totalEvents": 196,
		"eventPercent": 0.798835,
		"totalScheduleItems": 1860,
		"schedulePercent": 0.697391,
		"totalGuests": 880,
		"guestPercent": 0.794464,
		"mostGuestDate": "2023-06-01 00:00:00",
		"mostGuestName": "The Enmore Theatre",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 107
	},
	{
		"id": "edc0f45",
		"email": "sextonlp@gmail.com",
		"name": "Liam ",
		"createdDate": "2014-10-14 11:36:56",
		"totalOrganizations": 16,
		"totalTours": 61,
		"totalDays": 300,
		"totalCountries": 14,
		"totalStates": 35,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 1621,
		"schedulePercent": 0.647199,
		"totalGuests": 626,
		"guestPercent": 0.733015,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Finsbury Park",
		"mostGuestCity": "London",
		"mostGuestAmount": 60
	},
	{
		"id": "6c6ea1d",
		"email": "societe1988@gmail.com",
		"name": "Ava",
		"createdDate": "2022-02-14 10:08:12",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 1256,
		"totalCountries": 6,
		"totalStates": 14,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 649,
		"schedulePercent": 0.362204,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-04-16 00:00:00",
		"mostGuestName": "Festival Mythos",
		"mostGuestCity": "Rennes",
		"mostGuestAmount": 8
	},
	{
		"id": "2740446",
		"email": "maximepbrunet@gmail.com",
		"name": "Maxime ",
		"createdDate": "2016-05-23 20:25:16",
		"totalOrganizations": 28,
		"totalTours": 31,
		"totalDays": 694,
		"totalCountries": 11,
		"totalStates": 60,
		"totalEvents": 242,
		"eventPercent": 0.864389,
		"totalScheduleItems": 3323,
		"schedulePercent": 0.880281,
		"totalGuests": 1453,
		"guestPercent": 0.887962,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 98
	},
	{
		"id": "dddeb5d",
		"email": "bookings@davidmichaelproductionsuk.com",
		"name": "Kieran",
		"createdDate": "2022-07-11 09:56:08",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 72,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 305,
		"schedulePercent": 0.210171,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1625533",
		"email": "camdenmoser777@gmail.com",
		"name": "Camden ",
		"createdDate": "2016-09-26 22:15:22",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 214,
		"totalCountries": 4,
		"totalStates": 24,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 1065,
		"schedulePercent": 0.50298,
		"totalGuests": 78,
		"guestPercent": 0.45398,
		"mostGuestDate": "2023-04-13 00:00:00",
		"mostGuestName": "The Pomona Fox Theater",
		"mostGuestCity": "Pomona",
		"mostGuestAmount": 33
	},
	{
		"id": "efa9d4c",
		"email": "caitlin@activist.co",
		"name": "Caitlin",
		"createdDate": "2018-04-27 05:21:43",
		"totalOrganizations": 18,
		"totalTours": 2,
		"totalDays": 689,
		"totalCountries": 6,
		"totalStates": 40,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 2017,
		"schedulePercent": 0.722686,
		"totalGuests": 49,
		"guestPercent": 0.419944,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Friends Field North",
		"mostGuestCity": "Mill Valley",
		"mostGuestAmount": 18
	},
	{
		"id": "53abec4",
		"email": "jordan@lostmotel.com",
		"name": "Jordan",
		"createdDate": "2021-05-03 22:40:33",
		"totalOrganizations": 22,
		"totalTours": 33,
		"totalDays": 832,
		"totalCountries": 19,
		"totalStates": 86,
		"totalEvents": 355,
		"eventPercent": 0.944246,
		"totalScheduleItems": 4039,
		"schedulePercent": 0.924778,
		"totalGuests": 782,
		"guestPercent": 0.77248,
		"mostGuestDate": "2023-05-25 00:00:00",
		"mostGuestName": "The Regent",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 68
	},
	{
		"id": "3179a58",
		"email": "jamesmoore1717@gmail.com",
		"name": "James",
		"createdDate": "2012-11-22 09:08:55",
		"totalOrganizations": 12,
		"totalTours": 13,
		"totalDays": 545,
		"totalCountries": 15,
		"totalStates": 58,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 3227,
		"schedulePercent": 0.871938,
		"totalGuests": 827,
		"guestPercent": 0.782943,
		"mostGuestDate": "2023-01-31 00:00:00",
		"mostGuestName": "O2 Shepherd's Bush Empire",
		"mostGuestCity": "London",
		"mostGuestAmount": 58
	},
	{
		"id": "1ed3d5f",
		"email": "griff@egtouring.com",
		"name": "Gordon",
		"createdDate": "2022-06-24 10:41:35",
		"totalOrganizations": 12,
		"totalTours": 9,
		"totalDays": 523,
		"totalCountries": 12,
		"totalStates": 43,
		"totalEvents": 233,
		"eventPercent": 0.853662,
		"totalScheduleItems": 2555,
		"schedulePercent": 0.799762,
		"totalGuests": 1696,
		"guestPercent": 0.912594,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Don Valley Bowl",
		"mostGuestCity": "Sheffield",
		"mostGuestAmount": 108
	},
	{
		"id": "702516f",
		"email": "bellaluminiarek@gmail.com",
		"name": "Bella ",
		"createdDate": "2023-01-12 22:18:49",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 312,
		"totalCountries": 2,
		"totalStates": 36,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 2166,
		"schedulePercent": 0.745199,
		"totalGuests": 491,
		"guestPercent": 0.688915,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "TCU Amphitheater at White River State Park",
		"mostGuestCity": "Indianapolis",
		"mostGuestAmount": 48
	},
	{
		"id": "be4b3fc",
		"email": "jacob@gooddyeyoung.com",
		"name": "Jacob",
		"createdDate": "2023-05-09 14:48:49",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 70,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 41,
		"schedulePercent": 0.0471461,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d36ca80",
		"email": "cambirchilltm@gmail.com",
		"name": "Cameron",
		"createdDate": "2016-08-23 16:17:36",
		"totalOrganizations": 6,
		"totalTours": 20,
		"totalDays": 367,
		"totalCountries": 16,
		"totalStates": 65,
		"totalEvents": 197,
		"eventPercent": 0.800821,
		"totalScheduleItems": 3675,
		"schedulePercent": 0.905708,
		"totalGuests": 2114,
		"guestPercent": 0.940008,
		"mostGuestDate": "2023-06-28 00:00:00",
		"mostGuestName": "The Complex - Rockwell",
		"mostGuestCity": "Salt Lake City",
		"mostGuestAmount": 91
	},
	{
		"id": "4dbdfef",
		"email": "vphillipstm@gmail.com",
		"name": "Vanessa",
		"createdDate": "2022-03-08 20:35:44",
		"totalOrganizations": 7,
		"totalTours": 13,
		"totalDays": 827,
		"totalCountries": 15,
		"totalStates": 63,
		"totalEvents": 257,
		"eventPercent": 0.882929,
		"totalScheduleItems": 4020,
		"schedulePercent": 0.923984,
		"totalGuests": 1770,
		"guestPercent": 0.918024,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 137
	},
	{
		"id": "7a106c8",
		"email": "jake@jakeviache.com",
		"name": "Jake",
		"createdDate": "2022-04-09 13:44:03",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 1096,
		"totalCountries": 15,
		"totalStates": 62,
		"totalEvents": 252,
		"eventPercent": 0.876308,
		"totalScheduleItems": 2952,
		"schedulePercent": 0.847305,
		"totalGuests": 1768,
		"guestPercent": 0.917759,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 171
	},
	{
		"id": "6c75cb7",
		"email": "phill.m.day@gmail.com",
		"name": "Phillip",
		"createdDate": "2017-02-23 01:30:49",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 922,
		"totalCountries": 12,
		"totalStates": 64,
		"totalEvents": 251,
		"eventPercent": 0.873792,
		"totalScheduleItems": 3654,
		"schedulePercent": 0.904384,
		"totalGuests": 1814,
		"guestPercent": 0.921732,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Billy Bob's",
		"mostGuestCity": "Ft. Worth",
		"mostGuestAmount": 51
	},
	{
		"id": "e716180",
		"email": "mec@opakmedia.com",
		"name": "Mathilde",
		"createdDate": "2018-08-07 16:19:55",
		"totalOrganizations": 17,
		"totalTours": 59,
		"totalDays": 446,
		"totalCountries": 11,
		"totalStates": 30,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 1126,
		"schedulePercent": 0.518607,
		"totalGuests": 56,
		"guestPercent": 0.429744,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Higher Ground",
		"mostGuestCity": "South Burlington",
		"mostGuestAmount": 13
	},
	{
		"id": "a9c1781",
		"email": "1978Ambrosia@gmail.com",
		"name": "Shannon ",
		"createdDate": "2018-05-06 22:10:16",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 347,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 383,
		"schedulePercent": 0.250563,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d8b24fd",
		"email": "darcy@darcybeck.com",
		"name": "Darcy",
		"createdDate": "2022-11-03 02:03:14",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 734,
		"totalCountries": 8,
		"totalStates": 40,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 845,
		"schedulePercent": 0.435969,
		"totalGuests": 97,
		"guestPercent": 0.471461,
		"mostGuestDate": "2023-08-21 00:00:00",
		"mostGuestName": "Canadian National Exhibition",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 21
	},
	{
		"id": "1c09b1c",
		"email": "tix@mammothmusicgroup.com",
		"name": "Marvel",
		"createdDate": "2021-09-28 23:45:17",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 659,
		"totalCountries": 5,
		"totalStates": 47,
		"totalEvents": 173,
		"eventPercent": 0.757516,
		"totalScheduleItems": 1475,
		"schedulePercent": 0.607999,
		"totalGuests": 80,
		"guestPercent": 0.455304,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "The Caverns",
		"mostGuestCity": "Pelham",
		"mostGuestAmount": 26
	},
	{
		"id": "7b1a896",
		"email": "travis.hogan@mushroomgroup.com",
		"name": "Travis",
		"createdDate": "2023-06-20 11:17:00",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 269,
		"totalCountries": 12,
		"totalStates": 35,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 1915,
		"schedulePercent": 0.706264,
		"totalGuests": 425,
		"guestPercent": 0.663356,
		"mostGuestDate": "2023-07-25 00:00:00",
		"mostGuestName": "Hordern Pavilion",
		"mostGuestCity": "Moore Park",
		"mostGuestAmount": 90
	},
	{
		"id": "5198121",
		"email": "sethloeser@mac.com",
		"name": "Seth",
		"createdDate": "2010-04-15 18:25:24",
		"totalOrganizations": 20,
		"totalTours": 20,
		"totalDays": 390,
		"totalCountries": 13,
		"totalStates": 64,
		"totalEvents": 182,
		"eventPercent": 0.773408,
		"totalScheduleItems": 2452,
		"schedulePercent": 0.785989,
		"totalGuests": 1138,
		"guestPercent": 0.847173,
		"mostGuestDate": "2023-03-02 00:00:00",
		"mostGuestName": "Radio City Music Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 72
	},
	{
		"id": "6ba60d8",
		"email": "ignatious2197@gmail.com",
		"name": "James",
		"createdDate": "2022-07-19 18:03:31",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 1950,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 233,
		"eventPercent": 0.853662,
		"totalScheduleItems": 1430,
		"schedulePercent": 0.599258,
		"totalGuests": 200,
		"guestPercent": 0.550258,
		"mostGuestDate": "2023-09-03 00:00:00",
		"mostGuestName": "Ocean Resort Casino - Ovation Hall",
		"mostGuestCity": "Atlantic City",
		"mostGuestAmount": 37
	},
	{
		"id": "04accc3",
		"email": "apanalogaudio@gmail.com",
		"name": "Asa",
		"createdDate": "2017-04-18 13:58:22",
		"totalOrganizations": 11,
		"totalTours": 13,
		"totalDays": 605,
		"totalCountries": 11,
		"totalStates": 52,
		"totalEvents": 171,
		"eventPercent": 0.75394,
		"totalScheduleItems": 2259,
		"schedulePercent": 0.760429,
		"totalGuests": 1201,
		"guestPercent": 0.85737,
		"mostGuestDate": "2023-03-02 00:00:00",
		"mostGuestName": "Radio City Music Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 72
	},
	{
		"id": "72acfd1",
		"email": "touring@blueylive.com.au",
		"name": "Bluey",
		"createdDate": "2023-06-11 16:39:35",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 231,
		"totalCountries": 3,
		"totalStates": 37,
		"totalEvents": 173,
		"eventPercent": 0.757516,
		"totalScheduleItems": 1495,
		"schedulePercent": 0.616872,
		"totalGuests": 42,
		"guestPercent": 0.40882,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "Kauffman Center for the Performing Arts",
		"mostGuestCity": "Kansas City",
		"mostGuestAmount": 18
	},
	{
		"id": "5e01fb7",
		"email": "scooterspiceris@gmail.com",
		"name": "Scooter",
		"createdDate": "2020-01-08 00:27:54",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 453,
		"totalCountries": 3,
		"totalStates": 40,
		"totalEvents": 201,
		"eventPercent": 0.808237,
		"totalScheduleItems": 2287,
		"schedulePercent": 0.763475,
		"totalGuests": 1424,
		"guestPercent": 0.884916,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 68
	},
	{
		"id": "3c6d353",
		"email": "jorden@closerartists.com",
		"name": "Jordan",
		"createdDate": "2022-02-16 10:54:03",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 103,
		"totalCountries": 8,
		"totalStates": 13,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 1005,
		"schedulePercent": 0.486426,
		"totalGuests": 724,
		"guestPercent": 0.758443,
		"mostGuestDate": "2023-04-06 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 106
	},
	{
		"id": "3ebe217",
		"email": "stephmnavarro@gmail.com",
		"name": "Stephanie",
		"createdDate": "2015-01-20 22:19:32",
		"totalOrganizations": 16,
		"totalTours": 6,
		"totalDays": 849,
		"totalCountries": 6,
		"totalStates": 44,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 2626,
		"schedulePercent": 0.811548,
		"totalGuests": 1522,
		"guestPercent": 0.896835,
		"mostGuestDate": "2023-09-07 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 118
	},
	{
		"id": "b276b9c",
		"email": "chris@truegritmanagement.com",
		"name": "Chris ",
		"createdDate": "2021-07-07 20:35:03",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 700,
		"totalCountries": 1,
		"totalStates": 41,
		"totalEvents": 212,
		"eventPercent": 0.826778,
		"totalScheduleItems": 2066,
		"schedulePercent": 0.72944,
		"totalGuests": 702,
		"guestPercent": 0.753543,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Deadwood Mountain Grand Hotel and Casino",
		"mostGuestCity": "Deadwood",
		"mostGuestAmount": 50
	},
	{
		"id": "84e927b",
		"email": "macruz@aegpresents.com",
		"name": "Marjorie",
		"createdDate": "2023-05-08 05:52:46",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 226,
		"totalCountries": 9,
		"totalStates": 20,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 630,
		"schedulePercent": 0.354523,
		"totalGuests": 101,
		"guestPercent": 0.474904,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "Toyosu PIT",
		"mostGuestCity": "",
		"mostGuestAmount": 20
	},
	{
		"id": "8686d1d",
		"email": "daniel@craetiveco.com",
		"name": "Daniel",
		"createdDate": "2023-09-13 20:11:17",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 56,
		"totalCountries": 8,
		"totalStates": 23,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 126,
		"schedulePercent": 0.106741,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4a5339c",
		"email": "mattwalst@me.com",
		"name": "Matt",
		"createdDate": "2017-02-22 19:47:45",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 152,
		"totalCountries": 9,
		"totalStates": 43,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 1134,
		"schedulePercent": 0.521255,
		"totalGuests": 81,
		"guestPercent": 0.455966,
		"mostGuestDate": "2023-04-29 00:00:00",
		"mostGuestName": "Fiserv Forum",
		"mostGuestCity": "Milwaukee",
		"mostGuestAmount": 21
	},
	{
		"id": "683b37a",
		"email": "dagnymusicofficial@gmail.com",
		"name": "Little Daggers Touring AS",
		"createdDate": "2023-05-22 18:10:03",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 214,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 479,
		"schedulePercent": 0.288703,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3d39ee2",
		"email": "michaelthomasmcgee@gmail.com",
		"name": "Michael",
		"createdDate": "2021-09-06 15:55:05",
		"totalOrganizations": 12,
		"totalTours": 10,
		"totalDays": 524,
		"totalCountries": 21,
		"totalStates": 64,
		"totalEvents": 157,
		"eventPercent": 0.720567,
		"totalScheduleItems": 2745,
		"schedulePercent": 0.825189,
		"totalGuests": 1490,
		"guestPercent": 0.892994,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 76
	},
	{
		"id": "8dfad2a",
		"email": "kaylee@happyduck.dk",
		"name": "Kaylee",
		"createdDate": "2018-09-17 08:43:23",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 647,
		"totalCountries": 14,
		"totalStates": 23,
		"totalEvents": 197,
		"eventPercent": 0.800821,
		"totalScheduleItems": 1800,
		"schedulePercent": 0.686002,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0216608",
		"email": "stevemillertour@aol.com",
		"name": "Jeffrey",
		"createdDate": "2009-10-20 21:17:24",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 122,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 1151,
		"schedulePercent": 0.527347,
		"totalGuests": 1042,
		"guestPercent": 0.831148,
		"mostGuestDate": "2023-09-11 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 69
	},
	{
		"id": "39db08b",
		"email": "mikeswillson@gmail.com",
		"name": "Mike",
		"createdDate": "2018-06-19 02:52:20",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 39,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 180,
		"schedulePercent": 0.138392,
		"totalGuests": 237,
		"guestPercent": 0.571712,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Los Angeles Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 44
	},
	{
		"id": "39327ac",
		"email": "domrom1@me.com",
		"name": "Dominique",
		"createdDate": "2015-10-29 16:05:48",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 172,
		"totalCountries": 6,
		"totalStates": 14,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 569,
		"schedulePercent": 0.327639,
		"totalGuests": 304,
		"guestPercent": 0.605218,
		"mostGuestDate": "2023-02-25 00:00:00",
		"mostGuestName": "Casino du Lac-Leamy",
		"mostGuestCity": "Gatineau",
		"mostGuestAmount": 37
	},
	{
		"id": "a31ee9e",
		"email": "bittersweetheartsband@gmail.com",
		"name": "Bittersweethearts",
		"createdDate": "2022-12-22 22:12:18",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 45,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 114,
		"schedulePercent": 0.0986624,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-05-03 00:00:00",
		"mostGuestName": "The Eighth Room",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 8
	},
	{
		"id": "ee9a32e",
		"email": "maxgoulding91@gmail.com",
		"name": "Max",
		"createdDate": "2018-07-13 20:31:25",
		"totalOrganizations": 6,
		"totalTours": 18,
		"totalDays": 376,
		"totalCountries": 23,
		"totalStates": 65,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 1829,
		"schedulePercent": 0.691299,
		"totalGuests": 773,
		"guestPercent": 0.770362,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 81
	},
	{
		"id": "18391e9",
		"email": "adam@plazaliveorlando.com",
		"name": "Adam ",
		"createdDate": "2012-02-07 17:34:53",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 46,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 13,
		"eventPercent": 0.0892597,
		"totalScheduleItems": 153,
		"schedulePercent": 0.122765,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3b6b3e7",
		"email": "morten@showlab.no",
		"name": "Morten",
		"createdDate": "2015-05-03 20:18:59",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 498,
		"totalCountries": 6,
		"totalStates": 15,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 955,
		"schedulePercent": 0.467355,
		"totalGuests": 304,
		"guestPercent": 0.605218,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Goteborgs Konserthus Big Hall",
		"mostGuestCity": "Goteborg",
		"mostGuestAmount": 48
	},
	{
		"id": "c95f4e9",
		"email": "sweitzer.clay@gmail.com",
		"name": "Clay",
		"createdDate": "2022-03-03 18:51:44",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 1169,
		"totalCountries": 8,
		"totalStates": 54,
		"totalEvents": 235,
		"eventPercent": 0.855516,
		"totalScheduleItems": 4032,
		"schedulePercent": 0.924513,
		"totalGuests": 818,
		"guestPercent": 0.779764,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 51
	},
	{
		"id": "9b7694d",
		"email": "eddie@juiceboxlive.co.uk",
		"name": "Eddie",
		"createdDate": "2020-01-17 22:58:05",
		"totalOrganizations": 22,
		"totalTours": 33,
		"totalDays": 442,
		"totalCountries": 13,
		"totalStates": 28,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 1067,
		"schedulePercent": 0.503642,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "77b4af0",
		"email": "joe@rightangleent.com",
		"name": "Joe",
		"createdDate": "2022-09-10 17:20:58",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 318,
		"totalCountries": 2,
		"totalStates": 41,
		"totalEvents": 231,
		"eventPercent": 0.850616,
		"totalScheduleItems": 3484,
		"schedulePercent": 0.892862,
		"totalGuests": 21,
		"guestPercent": 0.371342,
		"mostGuestDate": "2023-01-13 00:00:00",
		"mostGuestName": "Pantages Theater",
		"mostGuestCity": "Tacoma",
		"mostGuestAmount": 8
	},
	{
		"id": "a01d950",
		"email": "owendrew@hdpproductions.co.uk",
		"name": "Owen",
		"createdDate": "2014-02-08 16:54:59",
		"totalOrganizations": 67,
		"totalTours": 66,
		"totalDays": 1188,
		"totalCountries": 23,
		"totalStates": 58,
		"totalEvents": 327,
		"eventPercent": 0.929413,
		"totalScheduleItems": 3367,
		"schedulePercent": 0.884519,
		"totalGuests": 659,
		"guestPercent": 0.741624,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "347f63b",
		"email": "Maryaglur@aol.com",
		"name": "Marya",
		"createdDate": "2011-12-06 21:19:56",
		"totalOrganizations": 19,
		"totalTours": 23,
		"totalDays": 1905,
		"totalCountries": 9,
		"totalStates": 70,
		"totalEvents": 407,
		"eventPercent": 0.958681,
		"totalScheduleItems": 4492,
		"schedulePercent": 0.944776,
		"totalGuests": 1917,
		"guestPercent": 0.929811,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "Islington Assembly Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 67
	},
	{
		"id": "b341aeb",
		"email": "nosmond8@gmail.com",
		"name": "Nolan",
		"createdDate": "2019-02-16 02:05:18",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 146,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 1150,
		"schedulePercent": 0.527083,
		"totalGuests": 604,
		"guestPercent": 0.727453,
		"mostGuestDate": "2023-06-09 00:00:00",
		"mostGuestName": "The Foundry",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 48
	},
	{
		"id": "7e4f557",
		"email": "bertassist@bertbertbert.com",
		"name": "Peter",
		"createdDate": "2022-01-12 06:36:53",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 303,
		"totalCountries": 11,
		"totalStates": 59,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 2383,
		"schedulePercent": 0.778307,
		"totalGuests": 1809,
		"guestPercent": 0.920938,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "T-Mobile Arena",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 118
	},
	{
		"id": "9017beb",
		"email": "tothegraveau@outlook.com",
		"name": "Matt",
		"createdDate": "2022-10-18 01:53:59",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 95,
		"totalCountries": 12,
		"totalStates": 36,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 657,
		"schedulePercent": 0.364058,
		"totalGuests": 166,
		"guestPercent": 0.52748,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "O2 Academy Islington",
		"mostGuestCity": "London",
		"mostGuestAmount": 24
	},
	{
		"id": "776fb62",
		"email": "tavi@prabhumusic.net",
		"name": "Tavi",
		"createdDate": "2017-02-13 19:40:13",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 105,
		"totalCountries": 12,
		"totalStates": 16,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 664,
		"schedulePercent": 0.366574,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "14643a5",
		"email": "stephescoto@gmail.com",
		"name": "Stephanie",
		"createdDate": "2023-05-30 03:59:13",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 303,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 150,
		"schedulePercent": 0.120117,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "The Guild Theatre",
		"mostGuestCity": "Menlo Park",
		"mostGuestAmount": 4
	},
	{
		"id": "60be734",
		"email": "faith.r.benson@gmail.com",
		"name": "Faith",
		"createdDate": "2017-03-16 06:15:05",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 709,
		"totalCountries": 5,
		"totalStates": 44,
		"totalEvents": 199,
		"eventPercent": 0.804264,
		"totalScheduleItems": 3387,
		"schedulePercent": 0.886638,
		"totalGuests": 902,
		"guestPercent": 0.800291,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "del Lago Resort & Casino",
		"mostGuestCity": "Waterloo",
		"mostGuestAmount": 39
	},
	{
		"id": "84dacfe",
		"email": "natalie@mattmahermusic.com",
		"name": "Natalie",
		"createdDate": "2016-01-04 18:24:50",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 44,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 407,
		"schedulePercent": 0.260098,
		"totalGuests": 248,
		"guestPercent": 0.577804,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Central Park SummerStage",
		"mostGuestCity": "New York",
		"mostGuestAmount": 74
	},
	{
		"id": "1575d43",
		"email": "parkervandenberg@gmail.com",
		"name": "Parker",
		"createdDate": "2016-04-04 12:38:44",
		"totalOrganizations": 7,
		"totalTours": 25,
		"totalDays": 1245,
		"totalCountries": 6,
		"totalStates": 48,
		"totalEvents": 278,
		"eventPercent": 0.900278,
		"totalScheduleItems": 4090,
		"schedulePercent": 0.92703,
		"totalGuests": 1398,
		"guestPercent": 0.882665,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "032db6a",
		"email": "ectravelstuff@gmail.com",
		"name": "Elizabeth",
		"createdDate": "2022-02-20 20:44:37",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 826,
		"totalCountries": 13,
		"totalStates": 53,
		"totalEvents": 148,
		"eventPercent": 0.696332,
		"totalScheduleItems": 2072,
		"schedulePercent": 0.730102,
		"totalGuests": 1529,
		"guestPercent": 0.897629,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 74
	},
	{
		"id": "d25f32c",
		"email": "kidang@mac.com",
		"name": "Angie",
		"createdDate": "2009-10-06 21:42:55",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 399,
		"totalCountries": 16,
		"totalStates": 47,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 3007,
		"schedulePercent": 0.85141,
		"totalGuests": 5153,
		"guestPercent": 0.992584,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Glen Helen Amphitheater",
		"mostGuestCity": "San Bernardino",
		"mostGuestAmount": 250
	},
	{
		"id": "30fcd30",
		"email": "ddrehrig@gmail.com",
		"name": "Dustin ",
		"createdDate": "2012-09-12 19:21:55",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 129,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 711,
		"schedulePercent": 0.384982,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "The Lamp Theatre",
		"mostGuestCity": "Irwin",
		"mostGuestAmount": 1
	},
	{
		"id": "e88bc22",
		"email": "wayneholmes@mac.com",
		"name": "Wayne",
		"createdDate": "2016-06-14 21:44:31",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 374,
		"totalCountries": 3,
		"totalStates": 18,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 1871,
		"schedulePercent": 0.69951,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6d7267a",
		"email": "james@steelcitystudio.co.uk",
		"name": "James",
		"createdDate": "2015-08-16 02:06:54",
		"totalOrganizations": 14,
		"totalTours": 15,
		"totalDays": 285,
		"totalCountries": 17,
		"totalStates": 51,
		"totalEvents": 145,
		"eventPercent": 0.688518,
		"totalScheduleItems": 1870,
		"schedulePercent": 0.699378,
		"totalGuests": 374,
		"guestPercent": 0.642961,
		"mostGuestDate": "2023-01-31 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 53
	},
	{
		"id": "3b8fca7",
		"email": "troynachtigal@gmail.com",
		"name": "Troy",
		"createdDate": "2021-11-18 03:12:57",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 40,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 495,
		"schedulePercent": 0.295722,
		"totalGuests": 363,
		"guestPercent": 0.637134,
		"mostGuestDate": "2023-05-09 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 55
	},
	{
		"id": "f37809c",
		"email": "ksandra.prod@gmail.com",
		"name": "Cassandra",
		"createdDate": "2023-03-24 13:40:35",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 57,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 492,
		"schedulePercent": 0.293604,
		"totalGuests": 115,
		"guestPercent": 0.48722,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Salle de l'Alpilium",
		"mostGuestCity": "Saint-RÃ©my-de-Provence ",
		"mostGuestAmount": 18
	},
	{
		"id": "936eb95",
		"email": "miska.nipuli@gmail.com",
		"name": "Miska",
		"createdDate": "2019-03-12 08:56:51",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 143,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 560,
		"schedulePercent": 0.325652,
		"totalGuests": 291,
		"guestPercent": 0.598199,
		"mostGuestDate": "2023-07-06 00:00:00",
		"mostGuestName": "TykkimÃ¤en huvipuisto",
		"mostGuestCity": "Kouvola",
		"mostGuestAmount": 24
	},
	{
		"id": "7aa510e",
		"email": "kyle@flowstatemusic.co.uk",
		"name": "Kyle",
		"createdDate": "2018-02-05 12:58:45",
		"totalOrganizations": 12,
		"totalTours": 5,
		"totalDays": 129,
		"totalCountries": 7,
		"totalStates": 27,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 362,
		"schedulePercent": 0.238776,
		"totalGuests": 46,
		"guestPercent": 0.415044,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "Paradise Cultural Society, Vancouver",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 12
	},
	{
		"id": "379970b",
		"email": "kieren@qprime.com",
		"name": "Kieren",
		"createdDate": "2015-06-29 16:26:51",
		"totalOrganizations": 25,
		"totalTours": 49,
		"totalDays": 1110,
		"totalCountries": 18,
		"totalStates": 87,
		"totalEvents": 516,
		"eventPercent": 0.97603,
		"totalScheduleItems": 6770,
		"schedulePercent": 0.987419,
		"totalGuests": 3975,
		"guestPercent": 0.983578,
		"mostGuestDate": "2023-10-02 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 405
	},
	{
		"id": "8269350",
		"email": "jon@aliveandaware.co.uk",
		"name": "Jon",
		"createdDate": "2016-08-26 16:06:58",
		"totalOrganizations": 24,
		"totalTours": 40,
		"totalDays": 1055,
		"totalCountries": 26,
		"totalStates": 78,
		"totalEvents": 323,
		"eventPercent": 0.927559,
		"totalScheduleItems": 4009,
		"schedulePercent": 0.923719,
		"totalGuests": 1975,
		"guestPercent": 0.932194,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 524
	},
	{
		"id": "6f40159",
		"email": "nicolai@cphmgmt.com",
		"name": "Nicolai",
		"createdDate": "2021-04-20 07:09:21",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 75,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 516,
		"schedulePercent": 0.306185,
		"totalGuests": 163,
		"guestPercent": 0.525493,
		"mostGuestDate": "2023-04-29 00:00:00",
		"mostGuestName": "Store Vega",
		"mostGuestCity": "Copenhagen",
		"mostGuestAmount": 60
	},
	{
		"id": "3746be4",
		"email": "Vassarrr@gmail.com",
		"name": "Matthew",
		"createdDate": "2013-09-13 20:59:23",
		"totalOrganizations": 10,
		"totalTours": 4,
		"totalDays": 727,
		"totalCountries": 3,
		"totalStates": 46,
		"totalEvents": 234,
		"eventPercent": 0.854324,
		"totalScheduleItems": 2645,
		"schedulePercent": 0.814329,
		"totalGuests": 1393,
		"guestPercent": 0.882002,
		"mostGuestDate": "2023-11-19 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 57
	},
	{
		"id": "f16f3f7",
		"email": "abenielsen@gmail.com",
		"name": "Abe",
		"createdDate": "2015-03-15 20:31:27",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 375,
		"totalCountries": 6,
		"totalStates": 40,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 1384,
		"schedulePercent": 0.587604,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Concerthouse",
		"mostGuestCity": "GÃ¶teborg",
		"mostGuestAmount": 2
	},
	{
		"id": "90fbd48",
		"email": "dougie@nohalfmeasures.com",
		"name": "Dougie",
		"createdDate": "2017-06-30 12:43:27",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 1123,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 947,
		"schedulePercent": 0.464707,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "Let's Rock Scotland",
		"mostGuestCity": "Dalkeith ",
		"mostGuestAmount": 3
	},
	{
		"id": "1e63096",
		"email": "carylo@me.com",
		"name": "CARYL ",
		"createdDate": "2012-08-14 14:43:08",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 156,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 218,
		"schedulePercent": 0.1617,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f17f8fb",
		"email": "jmsjmusic@gmail.com",
		"name": "James",
		"createdDate": "2019-07-25 20:24:26",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 735,
		"totalCountries": 5,
		"totalStates": 44,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 2622,
		"schedulePercent": 0.811151,
		"totalGuests": 1408,
		"guestPercent": 0.883592,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Cadence Bank Amphitheatre at Chastain Park",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 67
	},
	{
		"id": "e7205b4",
		"email": "lh141@hotmail.com",
		"name": "Aleksandar",
		"createdDate": "2022-04-25 15:16:15",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 86,
		"totalCountries": 7,
		"totalStates": 50,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 574,
		"schedulePercent": 0.330155,
		"totalGuests": 126,
		"guestPercent": 0.495564,
		"mostGuestDate": "2023-10-24 00:00:00",
		"mostGuestName": "The Fillmore",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 25
	},
	{
		"id": "fbc051b",
		"email": "scott@ebmediapr.com",
		"name": "Scott",
		"createdDate": "2013-09-26 15:15:14",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 311,
		"totalCountries": 2,
		"totalStates": 37,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 2370,
		"schedulePercent": 0.776321,
		"totalGuests": 1008,
		"guestPercent": 0.825718,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Coyote Joe's",
		"mostGuestCity": "Charlotte",
		"mostGuestAmount": 44
	},
	{
		"id": "5b8b573",
		"email": "Craigleachsounds@gmail.com",
		"name": "Craig",
		"createdDate": "2014-05-29 11:01:49",
		"totalOrganizations": 26,
		"totalTours": 41,
		"totalDays": 783,
		"totalCountries": 17,
		"totalStates": 91,
		"totalEvents": 446,
		"eventPercent": 0.967157,
		"totalScheduleItems": 5395,
		"schedulePercent": 0.971262,
		"totalGuests": 2199,
		"guestPercent": 0.943451,
		"mostGuestDate": "2023-03-20 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 69
	},
	{
		"id": "91490ca",
		"email": "brian@dogsinapileofficial.com",
		"name": "Brian",
		"createdDate": "2022-09-07 17:24:12",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 427,
		"totalCountries": 1,
		"totalStates": 43,
		"totalEvents": 145,
		"eventPercent": 0.688518,
		"totalScheduleItems": 1046,
		"schedulePercent": 0.496093,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Sandiwood Farm",
		"mostGuestCity": "Wolcott",
		"mostGuestAmount": 3
	},
	{
		"id": "01ea700",
		"email": "jlarsen@nilerodgers.com",
		"name": "Jen",
		"createdDate": "2018-12-11 14:18:07",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 219,
		"totalCountries": 15,
		"totalStates": 45,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1454,
		"schedulePercent": 0.604291,
		"totalGuests": 485,
		"guestPercent": 0.685207,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "Warwick Castle",
		"mostGuestCity": "Warwick",
		"mostGuestAmount": 23
	},
	{
		"id": "7599233",
		"email": "Skellydtm@gmail.com",
		"name": "Cedric",
		"createdDate": "2019-11-02 19:41:50",
		"totalOrganizations": 2,
		"totalTours": 13,
		"totalDays": 307,
		"totalCountries": 8,
		"totalStates": 52,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 1251,
		"schedulePercent": 0.552774,
		"totalGuests": 21,
		"guestPercent": 0.371342,
		"mostGuestDate": "2023-06-29 00:00:00",
		"mostGuestName": "Variety Playhouse",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 7
	},
	{
		"id": "7f2dec3",
		"email": "stubner.stefanie@semmel.de",
		"name": "Stefanie",
		"createdDate": "2021-11-02 12:17:11",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 89,
		"totalCountries": 11,
		"totalStates": 16,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 829,
		"schedulePercent": 0.430009,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c3f3b20",
		"email": "madeline@themwsgroup.com",
		"name": "Madeline",
		"createdDate": "2022-01-20 20:07:57",
		"totalOrganizations": 7,
		"totalTours": 24,
		"totalDays": 529,
		"totalCountries": 7,
		"totalStates": 38,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 2082,
		"schedulePercent": 0.732221,
		"totalGuests": 864,
		"guestPercent": 0.790756,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "The Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 130
	},
	{
		"id": "01c9b6d",
		"email": "mmatatourmgmt@gmail.com",
		"name": "Nicole",
		"createdDate": "2021-08-03 19:38:05",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 128,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 914,
		"schedulePercent": 0.455569,
		"totalGuests": 23,
		"guestPercent": 0.37452,
		"mostGuestDate": "2023-03-23 00:00:00",
		"mostGuestName": "The Echo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 9
	},
	{
		"id": "cba8b59",
		"email": "johan@thetourmanager.com",
		"name": "Johan",
		"createdDate": "2014-05-13 22:33:40",
		"totalOrganizations": 8,
		"totalTours": 2,
		"totalDays": 153,
		"totalCountries": 11,
		"totalStates": 28,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 580,
		"schedulePercent": 0.332141,
		"totalGuests": 344,
		"guestPercent": 0.624685,
		"mostGuestDate": "2023-11-06 00:00:00",
		"mostGuestName": "The Theatre at Ace Hotel",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 44
	},
	{
		"id": "664c8c7",
		"email": "Andy@nowheretalent.com",
		"name": "Andy",
		"createdDate": "2023-05-03 14:04:02",
		"totalOrganizations": 2,
		"totalTours": 8,
		"totalDays": 125,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 789,
		"schedulePercent": 0.415971,
		"totalGuests": 771,
		"guestPercent": 0.769832,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "South Facing Festival",
		"mostGuestCity": "London",
		"mostGuestAmount": 68
	},
	{
		"id": "5fb4693",
		"email": "caroline_sound@btinternet.com",
		"name": "Caroline",
		"createdDate": "2012-06-01 17:28:16",
		"totalOrganizations": 34,
		"totalTours": 9,
		"totalDays": 143,
		"totalCountries": 9,
		"totalStates": 20,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 783,
		"schedulePercent": 0.41372,
		"totalGuests": 219,
		"guestPercent": 0.562575,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Bath",
		"mostGuestAmount": 41
	},
	{
		"id": "f4e490b",
		"email": "gijodi@me.com",
		"name": "Jodi",
		"createdDate": "2019-05-29 14:38:39",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 471,
		"totalCountries": 6,
		"totalStates": 31,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 715,
		"schedulePercent": 0.386571,
		"totalGuests": 707,
		"guestPercent": 0.754867,
		"mostGuestDate": "2023-03-09 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 259
	},
	{
		"id": "43fb3f4",
		"email": "samuelaaronstein@gmail.com",
		"name": "Sam",
		"createdDate": "2023-08-30 19:03:54",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 34,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 210,
		"schedulePercent": 0.156006,
		"totalGuests": 271,
		"guestPercent": 0.589988,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 47
	},
	{
		"id": "137dc29",
		"email": "jannika@allday.fi",
		"name": "Jannika",
		"createdDate": "2022-04-26 09:13:19",
		"totalOrganizations": 16,
		"totalTours": 25,
		"totalDays": 715,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 425,
		"eventPercent": 0.963449,
		"totalScheduleItems": 1468,
		"schedulePercent": 0.606939,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1cbd28e",
		"email": "davidwright@fastmail.com",
		"name": "David",
		"createdDate": "2019-03-27 16:56:58",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 65,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 355,
		"schedulePercent": 0.23573,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "13d2c85",
		"email": "jkinneymusic@gmail.com",
		"name": "Jeremy",
		"createdDate": "2020-02-14 16:08:53",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 428,
		"totalCountries": 11,
		"totalStates": 30,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1386,
		"schedulePercent": 0.588134,
		"totalGuests": 593,
		"guestPercent": 0.723878,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 105
	},
	{
		"id": "c9ee207",
		"email": "katy@kayakayarecords.co.uk",
		"name": "Katy",
		"createdDate": "2022-08-24 10:38:12",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 57,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 320,
		"schedulePercent": 0.218514,
		"totalGuests": 303,
		"guestPercent": 0.604158,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Don Valley Bowl",
		"mostGuestCity": "Sheffield",
		"mostGuestAmount": 108
	},
	{
		"id": "609b743",
		"email": "stapesco75@gmail.com",
		"name": "Chris",
		"createdDate": "2022-03-21 13:11:22",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 150,
		"totalCountries": 15,
		"totalStates": 29,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 910,
		"schedulePercent": 0.454907,
		"totalGuests": 3084,
		"guestPercent": 0.970335,
		"mostGuestDate": "2023-03-28 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 137
	},
	{
		"id": "d593b2c",
		"email": "danmercer99@me.com",
		"name": "Daniel",
		"createdDate": "2017-01-26 18:35:34",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 1197,
		"totalCountries": 18,
		"totalStates": 48,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 2584,
		"schedulePercent": 0.803602,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-06-25 00:00:00",
		"mostGuestName": "Brita-Arena",
		"mostGuestCity": "Wiesbaden",
		"mostGuestAmount": 5
	},
	{
		"id": "f202278",
		"email": "ipgworld@gmail.com",
		"name": "Richard",
		"createdDate": "2016-06-15 19:34:54",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 132,
		"totalCountries": 13,
		"totalStates": 37,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 1125,
		"schedulePercent": 0.51768,
		"totalGuests": 438,
		"guestPercent": 0.667461,
		"mostGuestDate": "2023-06-01 00:00:00",
		"mostGuestName": "Troxy",
		"mostGuestCity": "London",
		"mostGuestAmount": 48
	},
	{
		"id": "68bc8b5",
		"email": "lcjtasst@djjazzyjeff.com",
		"name": "Danielle",
		"createdDate": "2023-08-17 14:25:53",
		"totalOrganizations": 1,
		"totalTours": 6,
		"totalDays": 87,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 201,
		"schedulePercent": 0.151106,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-09-03 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 6
	},
	{
		"id": "c7a9f77",
		"email": "marcianoagabon@me.com",
		"name": "Marciano",
		"createdDate": "2022-05-30 07:55:52",
		"totalOrganizations": 7,
		"totalTours": 22,
		"totalDays": 469,
		"totalCountries": 4,
		"totalStates": 28,
		"totalEvents": 228,
		"eventPercent": 0.845848,
		"totalScheduleItems": 2477,
		"schedulePercent": 0.790094,
		"totalGuests": 983,
		"guestPercent": 0.820156,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "Palacio de los Deportes",
		"mostGuestCity": "Ciudad de MÃ©xico",
		"mostGuestAmount": 108
	},
	{
		"id": "c351e63",
		"email": "djstephe@yahoo.com",
		"name": "Dave",
		"createdDate": "2014-02-27 21:01:08",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 170,
		"totalCountries": 11,
		"totalStates": 51,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1240,
		"schedulePercent": 0.549861,
		"totalGuests": 1810,
		"guestPercent": 0.92107,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Saint Andrewâ€™s Hall",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 80
	},
	{
		"id": "85dd9e5",
		"email": "apashe.tm@gmail.com",
		"name": "Samuel",
		"createdDate": "2023-07-27 11:03:24",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 94,
		"totalCountries": 4,
		"totalStates": 26,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 313,
		"schedulePercent": 0.214674,
		"totalGuests": 349,
		"guestPercent": 0.629453,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "MTELUS",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 211
	},
	{
		"id": "4894e3c",
		"email": "simoaalto@gmail.com",
		"name": "Simo",
		"createdDate": "2015-04-08 12:53:57",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 110,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 391,
		"schedulePercent": 0.253344,
		"totalGuests": 49,
		"guestPercent": 0.419944,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Kulttuurikeskus Skantz",
		"mostGuestCity": "Kantti",
		"mostGuestAmount": 9
	},
	{
		"id": "fb55643",
		"email": "markcjones@mac.com",
		"name": "Mark",
		"createdDate": "2009-12-30 23:41:54",
		"totalOrganizations": 11,
		"totalTours": 52,
		"totalDays": 859,
		"totalCountries": 21,
		"totalStates": 73,
		"totalEvents": 333,
		"eventPercent": 0.934181,
		"totalScheduleItems": 5322,
		"schedulePercent": 0.970468,
		"totalGuests": 615,
		"guestPercent": 0.730234,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "Iveagh Gardens",
		"mostGuestCity": "Dublin 2",
		"mostGuestAmount": 56
	},
	{
		"id": "d8c6a89",
		"email": "joshuasolom@gmail.com",
		"name": "Joshua",
		"createdDate": "2019-03-12 22:57:13",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 111,
		"totalCountries": 1,
		"totalStates": 27,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 401,
		"schedulePercent": 0.25692,
		"totalGuests": 26,
		"guestPercent": 0.381274,
		"mostGuestDate": "2023-04-02 00:00:00",
		"mostGuestName": "City Winery NY (Pier 57)",
		"mostGuestCity": "New York",
		"mostGuestAmount": 14
	},
	{
		"id": "b1d3a21",
		"email": "Bullyb4@aol.com",
		"name": "Tony",
		"createdDate": "2011-08-07 03:41:42",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 152,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 847,
		"schedulePercent": 0.436631,
		"totalGuests": 1832,
		"guestPercent": 0.923321,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 105
	},
	{
		"id": "379fa34",
		"email": "segierw@gmail.com",
		"name": "Ward",
		"createdDate": "2018-01-16 23:42:00",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 279,
		"totalCountries": 16,
		"totalStates": 60,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 976,
		"schedulePercent": 0.474374,
		"totalGuests": 42,
		"guestPercent": 0.40882,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "Ivy Room",
		"mostGuestCity": "Albany",
		"mostGuestAmount": 10
	},
	{
		"id": "c6af8f5",
		"email": "tracixcollins@gmail.com",
		"name": "Traci ",
		"createdDate": "2019-03-14 15:37:54",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 53,
		"totalCountries": 1,
		"totalStates": 18,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 93,
		"schedulePercent": 0.0843597,
		"totalGuests": 78,
		"guestPercent": 0.45398,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Carnegie Library Music Hall",
		"mostGuestCity": "Munhall",
		"mostGuestAmount": 18
	},
	{
		"id": "dd2a438",
		"email": "stefani.riskijevic@lobbycall.com",
		"name": "Stefani",
		"createdDate": "2022-06-06 11:59:45",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 341,
		"totalCountries": 14,
		"totalStates": 34,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1355,
		"schedulePercent": 0.582042,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "46fc008",
		"email": "callie.whitlock@redlightmanagement.com",
		"name": "Callie",
		"createdDate": "2018-02-22 00:26:58",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 376,
		"totalCountries": 6,
		"totalStates": 50,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 3345,
		"schedulePercent": 0.882665,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "65fd816",
		"email": "leafsteve@gmail.com",
		"name": "Steve",
		"createdDate": "2018-10-29 15:21:27",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 220,
		"totalCountries": 8,
		"totalStates": 35,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 993,
		"schedulePercent": 0.482188,
		"totalGuests": 103,
		"guestPercent": 0.478082,
		"mostGuestDate": "2023-10-10 00:00:00",
		"mostGuestName": "City Winery",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 11
	},
	{
		"id": "eb63d5e",
		"email": "glynda.maxey@gmail.com",
		"name": "Glynda",
		"createdDate": "2017-02-01 16:43:19",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 1288,
		"schedulePercent": 0.563237,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "754a8a5",
		"email": "hello@brace-productions.com",
		"name": "Finn",
		"createdDate": "2019-01-07 13:57:39",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 384,
		"totalCountries": 10,
		"totalStates": 14,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 547,
		"schedulePercent": 0.31956,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cb2a00e",
		"email": "david@drunkenpiano.com",
		"name": "David",
		"createdDate": "2014-09-08 22:35:59",
		"totalOrganizations": 8,
		"totalTours": 13,
		"totalDays": 376,
		"totalCountries": 10,
		"totalStates": 36,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 1102,
		"schedulePercent": 0.511853,
		"totalGuests": 462,
		"guestPercent": 0.678056,
		"mostGuestDate": "2023-06-13 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 40
	},
	{
		"id": "390b0a1",
		"email": "Bennieharris4@yahoo.com",
		"name": "Doug",
		"createdDate": "2014-07-10 16:16:29",
		"totalOrganizations": 27,
		"totalTours": 24,
		"totalDays": 1711,
		"totalCountries": 7,
		"totalStates": 48,
		"totalEvents": 472,
		"eventPercent": 0.970997,
		"totalScheduleItems": 8361,
		"schedulePercent": 0.994703,
		"totalGuests": 1036,
		"guestPercent": 0.830089,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Barclays Center",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 76
	},
	{
		"id": "e8d3870",
		"email": "storeykm@outlook.com",
		"name": "Katie ",
		"createdDate": "2016-04-05 19:40:37",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 156,
		"totalCountries": 3,
		"totalStates": 36,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 1720,
		"schedulePercent": 0.669713,
		"totalGuests": 2760,
		"guestPercent": 0.963184,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 104
	},
	{
		"id": "e5856b0",
		"email": "lina@thesatchisix.com",
		"name": "Lina",
		"createdDate": "2022-09-12 19:32:30",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 48,
		"totalCountries": 3,
		"totalStates": 8,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 157,
		"schedulePercent": 0.125281,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b5cf589",
		"email": "ebricking@gmail.com",
		"name": "Eric",
		"createdDate": "2023-10-11 22:21:54",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 72,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 469,
		"schedulePercent": 0.284201,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-11-22 00:00:00",
		"mostGuestName": "H. Ric Luhrs Performing Arts Center",
		"mostGuestCity": "Shippensburg",
		"mostGuestAmount": 2
	},
	{
		"id": "a5abdf2",
		"email": "jennitackett@gmail.com",
		"name": "Jenni",
		"createdDate": "2023-01-26 16:09:02",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 403,
		"totalCountries": 1,
		"totalStates": 48,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 1067,
		"schedulePercent": 0.503642,
		"totalGuests": 93,
		"guestPercent": 0.468017,
		"mostGuestDate": "2023-05-01 00:00:00",
		"mostGuestName": "CMA Theater at the Country Music Hall of Fame and Museum",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 18
	},
	{
		"id": "1ec2bc0",
		"email": "sbliveproduction@gmail.com",
		"name": "Siobhain",
		"createdDate": "2018-12-22 11:54:51",
		"totalOrganizations": 15,
		"totalTours": 56,
		"totalDays": 607,
		"totalCountries": 21,
		"totalStates": 67,
		"totalEvents": 297,
		"eventPercent": 0.914051,
		"totalScheduleItems": 3603,
		"schedulePercent": 0.900675,
		"totalGuests": 1180,
		"guestPercent": 0.854059,
		"mostGuestDate": "2023-11-29 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 222
	},
	{
		"id": "acb49d8",
		"email": "prgroup@hotmail.com",
		"name": "Michael",
		"createdDate": "2011-01-07 19:51:29",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 105,
		"totalCountries": 6,
		"totalStates": 16,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 674,
		"schedulePercent": 0.37015,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1410989",
		"email": "windwakerofficial@gmail.com",
		"name": "Windwaker TM",
		"createdDate": "2019-07-10 03:21:53",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 78,
		"totalCountries": 5,
		"totalStates": 31,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 607,
		"schedulePercent": 0.34459,
		"totalGuests": 54,
		"guestPercent": 0.427096,
		"mostGuestDate": "2023-01-19 00:00:00",
		"mostGuestName": "O2 Academy Bristol",
		"mostGuestCity": "Bristol",
		"mostGuestAmount": 10
	},
	{
		"id": "69d51ad",
		"email": "nick@theokatzman.com",
		"name": "Nick",
		"createdDate": "2023-01-25 22:04:45",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 79,
		"totalCountries": 8,
		"totalStates": 33,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 549,
		"schedulePercent": 0.320355,
		"totalGuests": 749,
		"guestPercent": 0.76427,
		"mostGuestDate": "2023-04-29 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 63
	},
	{
		"id": "d56fe6a",
		"email": "tristonmarez@gmail.com",
		"name": "Triston",
		"createdDate": "2019-05-28 23:09:44",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 290,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 200,
		"schedulePercent": 0.150444,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-03-23 00:00:00",
		"mostGuestName": "EMP REHEARSAL",
		"mostGuestCity": "Arlington",
		"mostGuestAmount": 5
	},
	{
		"id": "097baea",
		"email": "flyingsilvereagle@gmail.com",
		"name": "Jamey",
		"createdDate": "2015-02-12 01:57:02",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 77,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 339,
		"schedulePercent": 0.227122,
		"totalGuests": 92,
		"guestPercent": 0.466163,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Harrah's Cherokee Casino Resort",
		"mostGuestCity": "Cherokee",
		"mostGuestAmount": 30
	},
	{
		"id": "d075827",
		"email": "andyhuntaudio@gmail.com",
		"name": "Andrew",
		"createdDate": "2015-03-31 23:24:57",
		"totalOrganizations": 19,
		"totalTours": 25,
		"totalDays": 700,
		"totalCountries": 29,
		"totalStates": 67,
		"totalEvents": 154,
		"eventPercent": 0.71368,
		"totalScheduleItems": 2384,
		"schedulePercent": 0.778572,
		"totalGuests": 94,
		"guestPercent": 0.469474,
		"mostGuestDate": "2023-06-26 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 33
	},
	{
		"id": "b1c9512",
		"email": "Stephanie.Hagerty@wmg.com",
		"name": "Stephanie",
		"createdDate": "2015-02-13 19:06:57",
		"totalOrganizations": 59,
		"totalTours": 52,
		"totalDays": 16430,
		"totalCountries": 16,
		"totalStates": 89,
		"totalEvents": 2323,
		"eventPercent": 0.998941,
		"totalScheduleItems": 6568,
		"schedulePercent": 0.98583,
		"totalGuests": 716,
		"guestPercent": 0.756853,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "South Fork Dirt Riders",
		"mostGuestCity": "Taylorville",
		"mostGuestAmount": 29
	},
	{
		"id": "ff5e6be",
		"email": "Jennawilliamstm@gmail.com",
		"name": "Jenna",
		"createdDate": "2022-03-09 19:26:13",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 1212,
		"totalCountries": 5,
		"totalStates": 46,
		"totalEvents": 183,
		"eventPercent": 0.775659,
		"totalScheduleItems": 2999,
		"schedulePercent": 0.850616,
		"totalGuests": 2315,
		"guestPercent": 0.948351,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "Forest Hills Stadium",
		"mostGuestCity": "Forest Hills",
		"mostGuestAmount": 184
	},
	{
		"id": "04a92af",
		"email": "3strandsmanagement@gmail.com",
		"name": "Krystal",
		"createdDate": "2021-07-26 03:49:24",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 430,
		"totalCountries": 2,
		"totalStates": 31,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 1584,
		"schedulePercent": 0.637929,
		"totalGuests": 612,
		"guestPercent": 0.72944,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "Macon Centreplex - City Auditorium",
		"mostGuestCity": "Macon",
		"mostGuestAmount": 60
	},
	{
		"id": "0f5d671",
		"email": "ron@misuenomusic.com",
		"name": "Ron",
		"createdDate": "2023-04-14 18:18:12",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 104,
		"totalCountries": 8,
		"totalStates": 46,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 1143,
		"schedulePercent": 0.524699,
		"totalGuests": 431,
		"guestPercent": 0.66521,
		"mostGuestDate": "2023-04-27 00:00:00",
		"mostGuestName": "Das Bett",
		"mostGuestCity": "Frankfurt am Main",
		"mostGuestAmount": 33
	},
	{
		"id": "1559327",
		"email": "dezhughes@me.com",
		"name": "Dez",
		"createdDate": "2013-01-19 20:46:14",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 162,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 117,
		"schedulePercent": 0.100914,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cb3cd72",
		"email": "madfamily@me.com",
		"name": "Tarek August",
		"createdDate": "2019-03-19 17:22:06",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 35,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 309,
		"schedulePercent": 0.212422,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2057f9f",
		"email": "greta.cantos@gmail.com",
		"name": "Greta",
		"createdDate": "2023-04-14 10:04:41",
		"totalOrganizations": 2,
		"totalTours": 7,
		"totalDays": 204,
		"totalCountries": 13,
		"totalStates": 32,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 1403,
		"schedulePercent": 0.592107,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0f18aec",
		"email": "produccion@pandaartistmanagement.net",
		"name": "Panda",
		"createdDate": "2022-05-09 14:08:38",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 48,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 486,
		"schedulePercent": 0.291352,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0270aa1",
		"email": "jrhee@cityparksfoundation.org",
		"name": "June",
		"createdDate": "2017-05-18 20:02:37",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2528d07",
		"email": "kat@qprime.com",
		"name": "Kat",
		"createdDate": "2016-05-04 21:03:14",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 516,
		"totalCountries": 4,
		"totalStates": 45,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 1512,
		"schedulePercent": 0.621242,
		"totalGuests": 481,
		"guestPercent": 0.683751,
		"mostGuestDate": "2023-05-11 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 85
	},
	{
		"id": "bdfa683",
		"email": "georgiakaykeavey@gmail.com",
		"name": "Georgia",
		"createdDate": "2022-09-12 19:16:31",
		"totalOrganizations": 3,
		"totalTours": 16,
		"totalDays": 152,
		"totalCountries": 7,
		"totalStates": 39,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 1338,
		"schedulePercent": 0.57701,
		"totalGuests": 854,
		"guestPercent": 0.788505,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 90
	},
	{
		"id": "77015fc",
		"email": "squirrelpm@parkermccollum.com",
		"name": "Jarrett",
		"createdDate": "2022-06-14 20:13:00",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 180,
		"totalCountries": 3,
		"totalStates": 22,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 1907,
		"schedulePercent": 0.705205,
		"totalGuests": 3267,
		"guestPercent": 0.974176,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 240
	},
	{
		"id": "d834f6a",
		"email": "stagemanager@patchtheatre.org.au",
		"name": "PATCH",
		"createdDate": "2023-02-28 02:14:13",
		"totalOrganizations": 1,
		"totalTours": 12,
		"totalDays": 670,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 688,
		"schedulePercent": 0.375579,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bb3ebc5",
		"email": "shanjvan@gmail.com",
		"name": "Shannon",
		"createdDate": "2018-10-01 17:10:40",
		"totalOrganizations": 34,
		"totalTours": 44,
		"totalDays": 1493,
		"totalCountries": 23,
		"totalStates": 61,
		"totalEvents": 357,
		"eventPercent": 0.94504,
		"totalScheduleItems": 4376,
		"schedulePercent": 0.940803,
		"totalGuests": 309,
		"guestPercent": 0.609058,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "0269549",
		"email": "nathan@completetours.co.uk",
		"name": "Nathan",
		"createdDate": "2015-08-24 15:15:24",
		"totalOrganizations": 17,
		"totalTours": 1,
		"totalDays": 35,
		"totalCountries": 3,
		"totalStates": 4,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 194,
		"schedulePercent": 0.146736,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "Dingwalls",
		"mostGuestCity": "Camden Lock",
		"mostGuestAmount": 2
	},
	{
		"id": "72c51d5",
		"email": "iamgciv@gmail.com",
		"name": "Gino",
		"createdDate": "2018-11-04 21:43:58",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 166,
		"totalCountries": 10,
		"totalStates": 44,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 1321,
		"schedulePercent": 0.571845,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-03-09 00:00:00",
		"mostGuestName": "Smoothie King Center",
		"mostGuestCity": "New Orleans",
		"mostGuestAmount": 2
	},
	{
		"id": "7581560",
		"email": "gilbride.patrick@gmail.com",
		"name": "Patrick",
		"createdDate": "2023-08-08 23:41:33",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 41,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 240,
		"schedulePercent": 0.175738,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c45f20b",
		"email": "jessesandler@mac.com",
		"name": "Jesse",
		"createdDate": "2009-12-23 14:38:42",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 410,
		"totalCountries": 2,
		"totalStates": 44,
		"totalEvents": 176,
		"eventPercent": 0.763475,
		"totalScheduleItems": 3820,
		"schedulePercent": 0.912859,
		"totalGuests": 1043,
		"guestPercent": 0.831545,
		"mostGuestDate": "2023-06-28 00:00:00",
		"mostGuestName": "Rogers Arena",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 178
	},
	{
		"id": "aeab07b",
		"email": "Eddie.Gowan@gmail.com",
		"name": "Eddie",
		"createdDate": "2009-12-09 17:43:26",
		"totalOrganizations": 9,
		"totalTours": 4,
		"totalDays": 376,
		"totalCountries": 2,
		"totalStates": 35,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 1323,
		"schedulePercent": 0.57211,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-01-07 00:00:00",
		"mostGuestName": "The Helix",
		"mostGuestCity": "Whitehall",
		"mostGuestAmount": 5
	},
	{
		"id": "ed78770",
		"email": "fredy@wiredproductions.live",
		"name": "Fredy",
		"createdDate": "2023-03-16 03:38:29",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 144,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 876,
		"schedulePercent": 0.445901,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c79c68a",
		"email": "privatefunctionrulz@gmail.com",
		"name": "Aidan",
		"createdDate": "2023-05-09 13:48:21",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 146,
		"totalCountries": 9,
		"totalStates": 22,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 474,
		"schedulePercent": 0.286982,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-05-18 00:00:00",
		"mostGuestName": "Crowbar Sydney & Ultimate Pig",
		"mostGuestCity": "Leichhardt",
		"mostGuestAmount": 2
	},
	{
		"id": "418dd24",
		"email": "Austinsoundy@gmail.com",
		"name": "Austin",
		"createdDate": "2012-04-25 18:04:37",
		"totalOrganizations": 11,
		"totalTours": 3,
		"totalDays": 654,
		"totalCountries": 11,
		"totalStates": 39,
		"totalEvents": 241,
		"eventPercent": 0.862667,
		"totalScheduleItems": 3546,
		"schedulePercent": 0.896967,
		"totalGuests": 435,
		"guestPercent": 0.666667,
		"mostGuestDate": "2023-10-31 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 91
	},
	{
		"id": "357915b",
		"email": "julian.farrell98@gmail.com",
		"name": "Julian",
		"createdDate": "2019-06-07 18:37:00",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 552,
		"totalCountries": 6,
		"totalStates": 53,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 4134,
		"schedulePercent": 0.929678,
		"totalGuests": 71,
		"guestPercent": 0.446431,
		"mostGuestDate": "2023-11-13 00:00:00",
		"mostGuestName": "The Bowery Ballroom",
		"mostGuestCity": "New York",
		"mostGuestAmount": 40
	},
	{
		"id": "71bb143",
		"email": "laura@littlelionmgmt.com",
		"name": "Laura",
		"createdDate": "2022-03-08 06:14:58",
		"totalOrganizations": 2,
		"totalTours": 15,
		"totalDays": 497,
		"totalCountries": 16,
		"totalStates": 57,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 2561,
		"schedulePercent": 0.800689,
		"totalGuests": 1455,
		"guestPercent": 0.888492,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 90
	},
	{
		"id": "47e7194",
		"email": "blossomwright@gmail.com",
		"name": "Blossom",
		"createdDate": "2018-02-27 17:56:05",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 23,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 7,
		"eventPercent": 0.0458217,
		"totalScheduleItems": 75,
		"schedulePercent": 0.0712488,
		"totalGuests": 362,
		"guestPercent": 0.636472,
		"mostGuestDate": "2023-01-13 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 146
	},
	{
		"id": "1cb5341",
		"email": "barry.nagin@lhcgroup.com",
		"name": "Barry",
		"createdDate": "2015-07-03 18:59:19",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 656,
		"totalCountries": 3,
		"totalStates": 32,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 1483,
		"schedulePercent": 0.610118,
		"totalGuests": 978,
		"guestPercent": 0.818832,
		"mostGuestDate": "2023-11-21 00:00:00",
		"mostGuestName": "Wildhorse Saloon",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 283
	},
	{
		"id": "a543e7b",
		"email": "caroline.didier@lateralevents.com",
		"name": "Caroline",
		"createdDate": "2019-07-16 00:06:05",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 54,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 203,
		"schedulePercent": 0.152165,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7e3afad",
		"email": "Nikolaj@allthingslive.com",
		"name": "Nikolaj",
		"createdDate": "2021-09-09 10:49:15",
		"totalOrganizations": 42,
		"totalTours": 40,
		"totalDays": 538,
		"totalCountries": 3,
		"totalStates": 5,
		"totalEvents": 520,
		"eventPercent": 0.976559,
		"totalScheduleItems": 2361,
		"schedulePercent": 0.775129,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "Drivhuset",
		"mostGuestCity": "Aalborg",
		"mostGuestAmount": 1
	},
	{
		"id": "804b14c",
		"email": "george@georgesaidmgmt.com",
		"name": "George",
		"createdDate": "2018-04-26 03:12:30",
		"totalOrganizations": 10,
		"totalTours": 2,
		"totalDays": 86,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 367,
		"schedulePercent": 0.241557,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "018d5bf",
		"email": "al@applejackmusic.com",
		"name": "Jeremy",
		"createdDate": "2018-03-28 01:55:27",
		"totalOrganizations": 6,
		"totalTours": 20,
		"totalDays": 483,
		"totalCountries": 13,
		"totalStates": 43,
		"totalEvents": 156,
		"eventPercent": 0.718051,
		"totalScheduleItems": 2156,
		"schedulePercent": 0.743478,
		"totalGuests": 859,
		"guestPercent": 0.789829,
		"mostGuestDate": "2023-04-26 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 62
	},
	{
		"id": "80e2d54",
		"email": "Kristi.Bradshaw@wmg.com",
		"name": "Kristi",
		"createdDate": "2018-08-23 17:20:52",
		"totalOrganizations": 49,
		"totalTours": 44,
		"totalDays": 15011,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 1913,
		"eventPercent": 0.993908,
		"totalScheduleItems": 742,
		"schedulePercent": 0.397431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "942613d",
		"email": "Jes@vectormgmt.com",
		"name": "Jes ",
		"createdDate": "2020-01-15 18:16:27",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 131,
		"totalCountries": 4,
		"totalStates": 15,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 234,
		"schedulePercent": 0.171765,
		"totalGuests": 184,
		"guestPercent": 0.539266,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "Live Oak Bank Pavilion",
		"mostGuestCity": "Wilmington",
		"mostGuestAmount": 46
	},
	{
		"id": "d6b1455",
		"email": "Max@maximum-sounds.com",
		"name": "Max",
		"createdDate": "2022-08-10 15:35:00",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 376,
		"totalCountries": 7,
		"totalStates": 10,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 266,
		"schedulePercent": 0.190041,
		"totalGuests": 19,
		"guestPercent": 0.366971,
		"mostGuestDate": "2023-03-14 00:00:00",
		"mostGuestName": "silent green Kulturquartier",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 14
	},
	{
		"id": "2761427",
		"email": "marvinmtorres@gmail.com",
		"name": "Marvin",
		"createdDate": "2012-05-12 16:45:32",
		"totalOrganizations": 8,
		"totalTours": 20,
		"totalDays": 494,
		"totalCountries": 24,
		"totalStates": 85,
		"totalEvents": 296,
		"eventPercent": 0.912992,
		"totalScheduleItems": 4441,
		"schedulePercent": 0.943186,
		"totalGuests": 1893,
		"guestPercent": 0.928089,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "The Pageant",
		"mostGuestCity": "St. Louis",
		"mostGuestAmount": 94
	},
	{
		"id": "ea558fb",
		"email": "aaronwatsonmusic@gmail.com",
		"name": "Omar",
		"createdDate": "2020-08-11 18:21:44",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 367,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 1274,
		"schedulePercent": 0.557674,
		"totalGuests": 556,
		"guestPercent": 0.712356,
		"mostGuestDate": "2023-03-10 00:00:00",
		"mostGuestName": "Texas Live!",
		"mostGuestCity": "Arlington",
		"mostGuestAmount": 50
	},
	{
		"id": "3272d80",
		"email": "tim@timmontana.com",
		"name": "Timothy",
		"createdDate": "2017-08-08 20:30:02",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 32,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 1015,
		"schedulePercent": 0.489736,
		"totalGuests": 501,
		"guestPercent": 0.692226,
		"mostGuestDate": "2023-04-22 00:00:00",
		"mostGuestName": "Foxwoods Resort & Casino",
		"mostGuestCity": "Mashantucket",
		"mostGuestAmount": 27
	},
	{
		"id": "aa837a0",
		"email": "mrflores10@gmail.com",
		"name": "Carlos",
		"createdDate": "2015-09-22 06:04:54",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 120,
		"totalCountries": 7,
		"totalStates": 22,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 243,
		"schedulePercent": 0.17799,
		"totalGuests": 41,
		"guestPercent": 0.407231,
		"mostGuestDate": "2023-07-19 00:00:00",
		"mostGuestName": "Huntington Center",
		"mostGuestCity": "Toledo",
		"mostGuestAmount": 41
	},
	{
		"id": "c49231d",
		"email": "tia@redlightmanagement.com",
		"name": "Tia",
		"createdDate": "2013-08-28 18:24:45",
		"totalOrganizations": 11,
		"totalTours": 22,
		"totalDays": 1101,
		"totalCountries": 14,
		"totalStates": 65,
		"totalEvents": 296,
		"eventPercent": 0.912992,
		"totalScheduleItems": 3720,
		"schedulePercent": 0.908224,
		"totalGuests": 825,
		"guestPercent": 0.782281,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 147
	},
	{
		"id": "b05a062",
		"email": "team3protection@gmail.com",
		"name": "Robert",
		"createdDate": "2016-01-15 19:43:20",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 449,
		"totalCountries": 3,
		"totalStates": 22,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 1591,
		"schedulePercent": 0.63965,
		"totalGuests": 987,
		"guestPercent": 0.820554,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Nissan Stadium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 90
	},
	{
		"id": "85a8e8e",
		"email": "Kim@mosfelt.dk",
		"name": "Kim",
		"createdDate": "2015-04-25 15:01:57",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 131,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 703,
		"schedulePercent": 0.381671,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c82211d",
		"email": "m.sellars747@gmail.com",
		"name": "Michael",
		"createdDate": "2021-12-01 05:49:46",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 1134,
		"totalCountries": 6,
		"totalStates": 36,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 494,
		"schedulePercent": 0.295193,
		"totalGuests": 49,
		"guestPercent": 0.419944,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "The Vanguard",
		"mostGuestCity": "Orlando",
		"mostGuestAmount": 14
	},
	{
		"id": "416dbb8",
		"email": "tmsteve@me.com",
		"name": "Steve",
		"createdDate": "2013-08-28 00:15:41",
		"totalOrganizations": 10,
		"totalTours": 24,
		"totalDays": 923,
		"totalCountries": 18,
		"totalStates": 74,
		"totalEvents": 276,
		"eventPercent": 0.898292,
		"totalScheduleItems": 5042,
		"schedulePercent": 0.962919,
		"totalGuests": 1284,
		"guestPercent": 0.869421,
		"mostGuestDate": "2023-03-29 00:00:00",
		"mostGuestName": "OVO Arena",
		"mostGuestCity": "London",
		"mostGuestAmount": 104
	},
	{
		"id": "21a9c1c",
		"email": "sebastian@vsd-hamburg.de",
		"name": "Sebastian",
		"createdDate": "2012-12-03 17:19:24",
		"totalOrganizations": 12,
		"totalTours": 5,
		"totalDays": 316,
		"totalCountries": 3,
		"totalStates": 11,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 1762,
		"schedulePercent": 0.676996,
		"totalGuests": 916,
		"guestPercent": 0.80294,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Schloss Oranienburg",
		"mostGuestCity": "Oranienburg",
		"mostGuestAmount": 95
	},
	{
		"id": "98110f4",
		"email": "justinfry@livenation.com",
		"name": "Justin",
		"createdDate": "2019-05-29 21:54:10",
		"totalOrganizations": 11,
		"totalTours": 14,
		"totalDays": 612,
		"totalCountries": 16,
		"totalStates": 58,
		"totalEvents": 190,
		"eventPercent": 0.788505,
		"totalScheduleItems": 3586,
		"schedulePercent": 0.898821,
		"totalGuests": 1515,
		"guestPercent": 0.896173,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 91
	},
	{
		"id": "1e01f75",
		"email": "maca@mcommunity.co",
		"name": "macarena ",
		"createdDate": "2022-09-29 23:57:54",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 176,
		"totalCountries": 3,
		"totalStates": 6,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 647,
		"schedulePercent": 0.361806,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a7a2a6f",
		"email": "alex.proctor.touring@gmail.com",
		"name": "ALEX",
		"createdDate": "2014-06-12 12:02:22",
		"totalOrganizations": 17,
		"totalTours": 52,
		"totalDays": 692,
		"totalCountries": 7,
		"totalStates": 53,
		"totalEvents": 284,
		"eventPercent": 0.905708,
		"totalScheduleItems": 4922,
		"schedulePercent": 0.959608,
		"totalGuests": 787,
		"guestPercent": 0.773805,
		"mostGuestDate": "2023-11-02 00:00:00",
		"mostGuestName": "The Theatre at Ace Hotel",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 70
	},
	{
		"id": "b3b3d9c",
		"email": "sharpegaz@me.com",
		"name": "Gary",
		"createdDate": "2016-08-17 06:09:42",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 89,
		"totalCountries": 10,
		"totalStates": 23,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 708,
		"schedulePercent": 0.383923,
		"totalGuests": 368,
		"guestPercent": 0.64071,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 46
	},
	{
		"id": "c9cdd50",
		"email": "aglcompanymanagement@gmail.com",
		"name": "Katie",
		"createdDate": "2023-09-02 02:43:19",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 99,
		"totalCountries": 1,
		"totalStates": 15,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 160,
		"schedulePercent": 0.126871,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9153f78",
		"email": "tobias@methodmusic.co.uk",
		"name": "Tobias",
		"createdDate": "2022-05-07 23:24:43",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 397,
		"totalCountries": 10,
		"totalStates": 18,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 526,
		"schedulePercent": 0.310025,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "73ceaa0",
		"email": "COREYMILLERTOURING@GMAIL.COM",
		"name": "Corey",
		"createdDate": "2019-08-21 16:59:51",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 733,
		"totalCountries": 2,
		"totalStates": 45,
		"totalEvents": 206,
		"eventPercent": 0.816183,
		"totalScheduleItems": 2405,
		"schedulePercent": 0.781089,
		"totalGuests": 157,
		"guestPercent": 0.520196,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 60
	},
	{
		"id": "9b5bfc6",
		"email": "mariannemattos@me.com",
		"name": "Marianne",
		"createdDate": "2013-01-16 20:01:59",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 98,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 92,
		"schedulePercent": 0.0836975,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "18092d9",
		"email": "events@wkp915.com",
		"name": "Oscar",
		"createdDate": "2022-03-24 19:11:13",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 359,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 724,
		"schedulePercent": 0.390147,
		"totalGuests": 221,
		"guestPercent": 0.563634,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Daily's Place",
		"mostGuestCity": "Jacksonville",
		"mostGuestAmount": 79
	},
	{
		"id": "b693d9f",
		"email": "brianna@thethorn.com",
		"name": "Brianna",
		"createdDate": "2014-05-22 21:18:31",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 154,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 694,
		"schedulePercent": 0.37836,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fd4afb4",
		"email": "simonshrimptm@gmail.com",
		"name": "simon",
		"createdDate": "2017-08-14 00:58:52",
		"totalOrganizations": 53,
		"totalTours": 64,
		"totalDays": 1100,
		"totalCountries": 23,
		"totalStates": 98,
		"totalEvents": 580,
		"eventPercent": 0.981592,
		"totalScheduleItems": 8472,
		"schedulePercent": 0.9951,
		"totalGuests": 2394,
		"guestPercent": 0.950338,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Qudos Bank Arena",
		"mostGuestCity": "Sydney Olympic Park",
		"mostGuestAmount": 97
	},
	{
		"id": "3a9dd3a",
		"email": "ldjanfreitag@gmx.de",
		"name": "Jan",
		"createdDate": "2021-10-12 19:29:30",
		"totalOrganizations": 2,
		"totalTours": 8,
		"totalDays": 45,
		"totalCountries": 7,
		"totalStates": 8,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 334,
		"schedulePercent": 0.225533,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Water Square",
		"mostGuestCity": "Kallithea",
		"mostGuestAmount": 0
	},
	{
		"id": "369fab2",
		"email": "stevecampbell65@me.com",
		"name": "Steve",
		"createdDate": "2010-08-24 21:06:51",
		"totalOrganizations": 2,
		"totalTours": 7,
		"totalDays": 460,
		"totalCountries": 2,
		"totalStates": 35,
		"totalEvents": 154,
		"eventPercent": 0.71368,
		"totalScheduleItems": 2110,
		"schedulePercent": 0.736724,
		"totalGuests": 685,
		"guestPercent": 0.74957,
		"mostGuestDate": "2023-05-02 00:00:00",
		"mostGuestName": "The Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 99
	},
	{
		"id": "fed8066",
		"email": "BigSeanwf@gmail.com",
		"name": "Dennis",
		"createdDate": "2016-01-04 20:35:53",
		"totalOrganizations": 4,
		"totalTours": 10,
		"totalDays": 907,
		"totalCountries": 16,
		"totalStates": 53,
		"totalEvents": 203,
		"eventPercent": 0.811681,
		"totalScheduleItems": 3578,
		"schedulePercent": 0.898292,
		"totalGuests": 5473,
		"guestPercent": 0.993908,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Glen Helen Amphitheater",
		"mostGuestCity": "San Bernardino",
		"mostGuestAmount": 250
	},
	{
		"id": "179b82a",
		"email": "SSantamaria@planetevents.es",
		"name": "SABELA",
		"createdDate": "2019-01-10 11:16:02",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 32,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 7,
		"eventPercent": 0.0458217,
		"totalScheduleItems": 192,
		"schedulePercent": 0.145676,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f557fe7",
		"email": "justin.taylor.long@gmail.com",
		"name": "Justin",
		"createdDate": "2022-01-20 08:31:14",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 340,
		"totalCountries": 2,
		"totalStates": 53,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 1458,
		"schedulePercent": 0.605085,
		"totalGuests": 396,
		"guestPercent": 0.652232,
		"mostGuestDate": "2023-03-02 00:00:00",
		"mostGuestName": "Gramps",
		"mostGuestCity": "Miami",
		"mostGuestAmount": 38
	},
	{
		"id": "5e09688",
		"email": "GrahamCochrane@adlib.co.uk",
		"name": "Graham",
		"createdDate": "2021-10-01 10:09:09",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 33,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 305,
		"schedulePercent": 0.210171,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4bd1ad6",
		"email": "joe@410mgmt.com",
		"name": "Joe",
		"createdDate": "2012-11-12 08:07:36",
		"totalOrganizations": 48,
		"totalTours": 45,
		"totalDays": 1926,
		"totalCountries": 16,
		"totalStates": 81,
		"totalEvents": 688,
		"eventPercent": 0.985432,
		"totalScheduleItems": 7208,
		"schedulePercent": 0.9902,
		"totalGuests": 3354,
		"guestPercent": 0.975103,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Buffalo Outer Harbor Concert Site",
		"mostGuestCity": "Buffalo",
		"mostGuestAmount": 131
	},
	{
		"id": "0a8f6fb",
		"email": "soundbob2@yahoo.com",
		"name": "Robert",
		"createdDate": "2023-06-27 19:15:32",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 186,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 172,
		"schedulePercent": 0.133757,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f6d00d5",
		"email": "tommy@tfdpresents.ca",
		"name": "Tommy",
		"createdDate": "2022-08-09 22:27:57",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 571,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 281,
		"schedulePercent": 0.198384,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e2db500",
		"email": "jakebushband@gmail.com",
		"name": "Jake",
		"createdDate": "2020-09-29 22:27:52",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 135,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 511,
		"schedulePercent": 0.304595,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2b05a48",
		"email": "Nicolette@nappyboyent.com",
		"name": "Nicolette ",
		"createdDate": "2021-05-20 20:43:22",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 86,
		"totalCountries": 4,
		"totalStates": 14,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 473,
		"schedulePercent": 0.286187,
		"totalGuests": 247,
		"guestPercent": 0.577672,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Coca-Cola Roxy",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 112
	},
	{
		"id": "011a4a5",
		"email": "internationaltouring23@gmail.com",
		"name": "international ",
		"createdDate": "2019-10-02 15:05:35",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 50,
		"totalCountries": 3,
		"totalStates": 18,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 9,
		"schedulePercent": 0.0210568,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e4882c0",
		"email": "ian.hutchinson@hotmail.co.uk",
		"name": "IAN",
		"createdDate": "2011-11-07 15:54:00",
		"totalOrganizations": 18,
		"totalTours": 23,
		"totalDays": 919,
		"totalCountries": 12,
		"totalStates": 59,
		"totalEvents": 185,
		"eventPercent": 0.780029,
		"totalScheduleItems": 1822,
		"schedulePercent": 0.689313,
		"totalGuests": 36,
		"guestPercent": 0.397961,
		"mostGuestDate": "2023-03-04 00:00:00",
		"mostGuestName": "John Dee Live Club & Pub",
		"mostGuestCity": "Oslo",
		"mostGuestAmount": 14
	},
	{
		"id": "9e94ef2",
		"email": "JimmyButera@gmail.com",
		"name": "James",
		"createdDate": "2013-08-29 17:20:18",
		"totalOrganizations": 23,
		"totalTours": 23,
		"totalDays": 2215,
		"totalCountries": 23,
		"totalStates": 71,
		"totalEvents": 467,
		"eventPercent": 0.970203,
		"totalScheduleItems": 6033,
		"schedulePercent": 0.980665,
		"totalGuests": 168,
		"guestPercent": 0.528804,
		"mostGuestDate": "2023-05-18 00:00:00",
		"mostGuestName": "EXIT/IN",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 35
	},
	{
		"id": "16facdf",
		"email": "asta.frangen@warnermusic.com",
		"name": "Asta",
		"createdDate": "2022-06-29 07:52:32",
		"totalOrganizations": 38,
		"totalTours": 63,
		"totalDays": 1138,
		"totalCountries": 2,
		"totalStates": 44,
		"totalEvents": 1042,
		"eventPercent": 0.990597,
		"totalScheduleItems": 4469,
		"schedulePercent": 0.944246,
		"totalGuests": 906,
		"guestPercent": 0.800954,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "Tavastia",
		"mostGuestCity": "Helsingfors",
		"mostGuestAmount": 40
	},
	{
		"id": "c98ff34",
		"email": "ips_productions@yahoo.com.au",
		"name": "Paulie",
		"createdDate": "2016-06-15 02:59:42",
		"totalOrganizations": 54,
		"totalTours": 12,
		"totalDays": 275,
		"totalCountries": 13,
		"totalStates": 26,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1434,
		"schedulePercent": 0.60045,
		"totalGuests": 298,
		"guestPercent": 0.60098,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "Manning Bar",
		"mostGuestCity": "Camperdown",
		"mostGuestAmount": 37
	},
	{
		"id": "8b23ef5",
		"email": "flynnseanjoe@gmail.com",
		"name": "Sean",
		"createdDate": "2023-02-19 01:22:58",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 232,
		"totalCountries": 3,
		"totalStates": 36,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 1219,
		"schedulePercent": 0.545093,
		"totalGuests": 520,
		"guestPercent": 0.698715,
		"mostGuestDate": "2023-11-15 00:00:00",
		"mostGuestName": "JJ's Beer Garden & Brewing Co.",
		"mostGuestCity": "Fayetteville",
		"mostGuestAmount": 28
	},
	{
		"id": "739d6db",
		"email": "ncventimiglia@gmail.com",
		"name": "Nick",
		"createdDate": "2022-06-16 18:10:02",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 63,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 557,
		"schedulePercent": 0.324725,
		"totalGuests": 354,
		"guestPercent": 0.632102,
		"mostGuestDate": "2023-04-08 00:00:00",
		"mostGuestName": "Crescent Ballroom",
		"mostGuestCity": "Phoenix",
		"mostGuestAmount": 44
	},
	{
		"id": "fdc8098",
		"email": "gmcnatt@gmail.com",
		"name": "Gabe",
		"createdDate": "2012-06-06 17:00:56",
		"totalOrganizations": 11,
		"totalTours": 10,
		"totalDays": 744,
		"totalCountries": 8,
		"totalStates": 44,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 2483,
		"schedulePercent": 0.791153,
		"totalGuests": 354,
		"guestPercent": 0.632102,
		"mostGuestDate": "2023-03-29 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 41
	},
	{
		"id": "0ea6910",
		"email": "olli@thirteenfourteen.de",
		"name": "Oliver",
		"createdDate": "2018-02-12 20:37:55",
		"totalOrganizations": 16,
		"totalTours": 11,
		"totalDays": 733,
		"totalCountries": 18,
		"totalStates": 42,
		"totalEvents": 194,
		"eventPercent": 0.794597,
		"totalScheduleItems": 3301,
		"schedulePercent": 0.8775,
		"totalGuests": 533,
		"guestPercent": 0.703748,
		"mostGuestDate": "2023-07-23 00:00:00",
		"mostGuestName": "ParkbÃ¼hne Wuhlheide",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 305
	},
	{
		"id": "29c3774",
		"email": "bveltre@gmail.com",
		"name": "Bianca ",
		"createdDate": "2017-06-06 13:51:59",
		"totalOrganizations": 9,
		"totalTours": 9,
		"totalDays": 1285,
		"totalCountries": 20,
		"totalStates": 67,
		"totalEvents": 294,
		"eventPercent": 0.9118,
		"totalScheduleItems": 4888,
		"schedulePercent": 0.957886,
		"totalGuests": 1917,
		"guestPercent": 0.929811,
		"mostGuestDate": "2023-08-20 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 99
	},
	{
		"id": "3c799f3",
		"email": "abbyroberts@me.com",
		"name": "Abby",
		"createdDate": "2012-03-16 13:00:54",
		"totalOrganizations": 22,
		"totalTours": 16,
		"totalDays": 447,
		"totalCountries": 3,
		"totalStates": 36,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 2188,
		"schedulePercent": 0.748245,
		"totalGuests": 148,
		"guestPercent": 0.51225,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Pearl Concert Theater",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 37
	},
	{
		"id": "48be032",
		"email": "paigeelizabethleslie@gmail.com",
		"name": "Paige",
		"createdDate": "2019-08-22 01:11:05",
		"totalOrganizations": 7,
		"totalTours": 2,
		"totalDays": 89,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 189,
		"schedulePercent": 0.144219,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b64e972",
		"email": "info@raikulilive.fi",
		"name": "Mira ",
		"createdDate": "2022-12-12 10:28:11",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 66,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 317,
		"schedulePercent": 0.216395,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bcf82d7",
		"email": "Paul.villarreal@thunderaudioinc.com",
		"name": "Paul",
		"createdDate": "2014-05-15 13:33:42",
		"totalOrganizations": 7,
		"totalTours": 22,
		"totalDays": 590,
		"totalCountries": 20,
		"totalStates": 75,
		"totalEvents": 264,
		"eventPercent": 0.889419,
		"totalScheduleItems": 3663,
		"schedulePercent": 0.904913,
		"totalGuests": 2408,
		"guestPercent": 0.951132,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "Forest Hills Stadium",
		"mostGuestCity": "Forest Hills",
		"mostGuestAmount": 184
	},
	{
		"id": "eab116d",
		"email": "teckoff.mastertour@gmail.com",
		"name": "TECKOFF",
		"createdDate": "2021-09-14 19:11:13",
		"totalOrganizations": 8,
		"totalTours": 13,
		"totalDays": 1356,
		"totalCountries": 4,
		"totalStates": 28,
		"totalEvents": 287,
		"eventPercent": 0.9069,
		"totalScheduleItems": 4431,
		"schedulePercent": 0.942789,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f412e82",
		"email": "jud.campbell@axleevents.com",
		"name": "Jud",
		"createdDate": "2022-10-30 07:10:10",
		"totalOrganizations": 17,
		"totalTours": 16,
		"totalDays": 228,
		"totalCountries": 6,
		"totalStates": 15,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 1585,
		"schedulePercent": 0.638194,
		"totalGuests": 238,
		"guestPercent": 0.572639,
		"mostGuestDate": "2023-11-19 00:00:00",
		"mostGuestName": "The Metro Theatre",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 35
	},
	{
		"id": "f4e135b",
		"email": "anthony@mam.land",
		"name": "Anthony",
		"createdDate": "2021-12-16 05:14:28",
		"totalOrganizations": 10,
		"totalTours": 3,
		"totalDays": 129,
		"totalCountries": 3,
		"totalStates": 11,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 184,
		"schedulePercent": 0.141306,
		"totalGuests": 30,
		"guestPercent": 0.389485,
		"mostGuestDate": "2023-08-13 00:00:00",
		"mostGuestName": "Pioneer Courthouse Square",
		"mostGuestCity": "Portland",
		"mostGuestAmount": 15
	},
	{
		"id": "ecf00d5",
		"email": "greenbergbryan2@gmail.com",
		"name": "Bryan",
		"createdDate": "2016-02-15 21:59:30",
		"totalOrganizations": 8,
		"totalTours": 17,
		"totalDays": 573,
		"totalCountries": 16,
		"totalStates": 79,
		"totalEvents": 352,
		"eventPercent": 0.942127,
		"totalScheduleItems": 4448,
		"schedulePercent": 0.943584,
		"totalGuests": 447,
		"guestPercent": 0.672096,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Soma",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 77
	},
	{
		"id": "2a64ddd",
		"email": "deedersmcgee@gmail.com",
		"name": "DeAnna",
		"createdDate": "2010-02-09 20:14:11",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 244,
		"totalCountries": 12,
		"totalStates": 41,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 2265,
		"schedulePercent": 0.761356,
		"totalGuests": 256,
		"guestPercent": 0.582307,
		"mostGuestDate": "2023-08-10 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 38
	},
	{
		"id": "e2449bb",
		"email": "estimatedprophet299@hotmail.com",
		"name": "Richard",
		"createdDate": "2022-08-17 02:41:10",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 326,
		"totalCountries": 3,
		"totalStates": 26,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 832,
		"schedulePercent": 0.431731,
		"totalGuests": 225,
		"guestPercent": 0.566018,
		"mostGuestDate": "2023-08-10 00:00:00",
		"mostGuestName": "Skydeck on Broadway",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 37
	},
	{
		"id": "0f6dd40",
		"email": "heath@east-westmusic.com",
		"name": "Heath",
		"createdDate": "2019-02-28 20:01:17",
		"totalOrganizations": 5,
		"totalTours": 21,
		"totalDays": 639,
		"totalCountries": 4,
		"totalStates": 6,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 453,
		"schedulePercent": 0.277844,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5bc760f",
		"email": "ToniCrowder@gmail.com",
		"name": "Toni",
		"createdDate": "2014-07-21 20:12:06",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 368,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 2219,
		"schedulePercent": 0.75341,
		"totalGuests": 320,
		"guestPercent": 0.614091,
		"mostGuestDate": "2023-03-26 00:00:00",
		"mostGuestName": "United Supermarkets Arena",
		"mostGuestCity": "Lubbock",
		"mostGuestAmount": 26
	},
	{
		"id": "4a3ee2c",
		"email": "caitwilliamsmusic@gmail.com",
		"name": "Cait",
		"createdDate": "2022-06-13 22:08:29",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 360,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 217,
		"schedulePercent": 0.160906,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7aa2fc3",
		"email": "maryclaireginn1@gmail.com",
		"name": "Claire",
		"createdDate": "2017-10-25 23:04:53",
		"totalOrganizations": 7,
		"totalTours": 43,
		"totalDays": 298,
		"totalCountries": 12,
		"totalStates": 47,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 812,
		"schedulePercent": 0.423917,
		"totalGuests": 134,
		"guestPercent": 0.501258,
		"mostGuestDate": "2023-05-15 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 30
	},
	{
		"id": "90cf675",
		"email": "events@lproof.org",
		"name": "Danielle",
		"createdDate": "2021-01-26 21:49:53",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 32,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 301,
		"schedulePercent": 0.208449,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1b28c27",
		"email": "mbawden101@hotmail.com",
		"name": "Mark",
		"createdDate": "2013-06-27 06:46:13",
		"totalOrganizations": 55,
		"totalTours": 119,
		"totalDays": 2134,
		"totalCountries": 13,
		"totalStates": 77,
		"totalEvents": 517,
		"eventPercent": 0.976162,
		"totalScheduleItems": 6347,
		"schedulePercent": 0.983976,
		"totalGuests": 1079,
		"guestPercent": 0.836446,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "7e635db",
		"email": "steffybeckett@gmail.com",
		"name": "Steffy",
		"createdDate": "2013-11-20 22:24:24",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 49,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 172,
		"schedulePercent": 0.133757,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8361abc",
		"email": "jchin@cityparksfoundation.org",
		"name": "Jeanna",
		"createdDate": "2022-07-21 16:26:56",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c8364ef",
		"email": "angela@thelittleredcompany.com",
		"name": "Angela",
		"createdDate": "2023-01-25 04:20:44",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 57,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 243,
		"schedulePercent": 0.17799,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9162927",
		"email": "austin.sprynczynatyk@gmail.com",
		"name": "Austin",
		"createdDate": "2016-09-13 22:43:15",
		"totalOrganizations": 8,
		"totalTours": 12,
		"totalDays": 373,
		"totalCountries": 8,
		"totalStates": 39,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 613,
		"schedulePercent": 0.346974,
		"totalGuests": 42,
		"guestPercent": 0.40882,
		"mostGuestDate": "2023-04-08 00:00:00",
		"mostGuestName": "The Catalyst",
		"mostGuestCity": "Santa Cruz",
		"mostGuestAmount": 17
	},
	{
		"id": "1a59d7f",
		"email": "lalo@monotoneinc.com",
		"name": "Lalo",
		"createdDate": "2009-12-03 00:02:15",
		"totalOrganizations": 15,
		"totalTours": 5,
		"totalDays": 71,
		"totalCountries": 3,
		"totalStates": 18,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 426,
		"schedulePercent": 0.26619,
		"totalGuests": 308,
		"guestPercent": 0.608131,
		"mostGuestDate": "2023-06-26 00:00:00",
		"mostGuestName": "Madison Square Garden",
		"mostGuestCity": "New York",
		"mostGuestAmount": 40
	},
	{
		"id": "d79c281",
		"email": "impactclass@lifesurge.com",
		"name": "Brittany ",
		"createdDate": "2022-03-08 19:32:03",
		"totalOrganizations": 6,
		"totalTours": 162,
		"totalDays": 760,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 650,
		"eventPercent": 0.98424,
		"totalScheduleItems": 2607,
		"schedulePercent": 0.809694,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "PHX: Dream City Church",
		"mostGuestCity": "Phoenix",
		"mostGuestAmount": 0
	},
	{
		"id": "df49bf4",
		"email": "contactripetheband@gmail.com",
		"name": "Jonathan",
		"createdDate": "2018-03-06 03:17:31",
		"totalOrganizations": 1,
		"totalTours": 6,
		"totalDays": 414,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 737,
		"schedulePercent": 0.394385,
		"totalGuests": 569,
		"guestPercent": 0.715799,
		"mostGuestDate": "2023-04-08 00:00:00",
		"mostGuestName": "MGM Music Hall at Fenway",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 86
	},
	{
		"id": "954007c",
		"email": "gabriel.gonzalez@gmail.com",
		"name": "Gabriel",
		"createdDate": "2009-06-03 21:55:17",
		"totalOrganizations": 12,
		"totalTours": 10,
		"totalDays": 118,
		"totalCountries": 9,
		"totalStates": 24,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 1068,
		"schedulePercent": 0.504436,
		"totalGuests": 1305,
		"guestPercent": 0.872202,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Brookside Golf Course",
		"mostGuestCity": "Pasadena",
		"mostGuestAmount": 125
	},
	{
		"id": "a1b75ec",
		"email": "amanda@am-music.net",
		"name": "Amanda",
		"createdDate": "2021-09-15 12:12:57",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 114,
		"totalCountries": 5,
		"totalStates": 9,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 1083,
		"schedulePercent": 0.508807,
		"totalGuests": 85,
		"guestPercent": 0.459144,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 29
	},
	{
		"id": "d6f2dac",
		"email": "pritchettkian29@icloud.com",
		"name": "Kian",
		"createdDate": "2019-08-23 18:18:37",
		"totalOrganizations": 12,
		"totalTours": 24,
		"totalDays": 931,
		"totalCountries": 19,
		"totalStates": 68,
		"totalEvents": 241,
		"eventPercent": 0.862667,
		"totalScheduleItems": 3501,
		"schedulePercent": 0.894319,
		"totalGuests": 1094,
		"guestPercent": 0.8383,
		"mostGuestDate": "2023-09-05 00:00:00",
		"mostGuestName": "Singapore Indoor Stadium",
		"mostGuestCity": "Singapore",
		"mostGuestAmount": 78
	},
	{
		"id": "e2104ff",
		"email": "maxproduction.starentertainment@gmail.com",
		"name": "Maksym",
		"createdDate": "2023-02-02 14:29:46",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 227,
		"totalCountries": 12,
		"totalStates": 28,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 1020,
		"schedulePercent": 0.490928,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a3fa1fe",
		"email": "nestor@cartelrecords.com",
		"name": "Nestor",
		"createdDate": "2016-12-19 03:18:18",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 70,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 414,
		"schedulePercent": 0.262482,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ed20193",
		"email": "tourhouse@me.com",
		"name": "Steve ",
		"createdDate": "2015-01-19 19:46:25",
		"totalOrganizations": 9,
		"totalTours": 8,
		"totalDays": 604,
		"totalCountries": 7,
		"totalStates": 33,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 1928,
		"schedulePercent": 0.707456,
		"totalGuests": 445,
		"guestPercent": 0.670905,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 63
	},
	{
		"id": "c58cdda",
		"email": "flemeste@yahoo.fr",
		"name": "Fabien",
		"createdDate": "2015-01-16 17:13:12",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 531,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 995,
		"schedulePercent": 0.483115,
		"totalGuests": 1589,
		"guestPercent": 0.902265,
		"mostGuestDate": "2023-03-29 00:00:00",
		"mostGuestName": "Zenith Paris - La Villette",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 203
	},
	{
		"id": "bc25b7e",
		"email": "kyleturk3@gmail.com",
		"name": "Kyle",
		"createdDate": "2022-07-08 17:44:53",
		"totalOrganizations": 4,
		"totalTours": 13,
		"totalDays": 650,
		"totalCountries": 3,
		"totalStates": 41,
		"totalEvents": 263,
		"eventPercent": 0.888889,
		"totalScheduleItems": 2490,
		"schedulePercent": 0.792345,
		"totalGuests": 2413,
		"guestPercent": 0.951397,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 137
	},
	{
		"id": "32bb153",
		"email": "gianmarco@invisiblemgmt.com",
		"name": "Gianmarco",
		"createdDate": "2022-03-18 23:22:23",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 248,
		"totalCountries": 7,
		"totalStates": 36,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 941,
		"schedulePercent": 0.462853,
		"totalGuests": 141,
		"guestPercent": 0.506026,
		"mostGuestDate": "2023-04-11 00:00:00",
		"mostGuestName": "Scotiabank Arena",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 16
	},
	{
		"id": "0206690",
		"email": "dylan@fusionmusicmgmt.com",
		"name": "Dylan",
		"createdDate": "2021-03-27 17:50:31",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 1022,
		"totalCountries": 2,
		"totalStates": 49,
		"totalEvents": 253,
		"eventPercent": 0.877765,
		"totalScheduleItems": 4417,
		"schedulePercent": 0.941994,
		"totalGuests": 3369,
		"guestPercent": 0.975367,
		"mostGuestDate": "2023-10-12 00:00:00",
		"mostGuestName": "Walk of Fame Park",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 148
	},
	{
		"id": "19f2286",
		"email": "kvelertak@gmail.com",
		"name": "Kvelertak",
		"createdDate": "2011-03-25 15:55:57",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 67,
		"totalCountries": 8,
		"totalStates": 10,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 341,
		"schedulePercent": 0.228314,
		"totalGuests": 133,
		"guestPercent": 0.500596,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Debaser Hornstulls Strand",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 24
	},
	{
		"id": "99d35ca",
		"email": "ptmoore37@gmail.com",
		"name": "Patrick",
		"createdDate": "2015-08-12 21:22:59",
		"totalOrganizations": 25,
		"totalTours": 24,
		"totalDays": 780,
		"totalCountries": 12,
		"totalStates": 57,
		"totalEvents": 208,
		"eventPercent": 0.819891,
		"totalScheduleItems": 3268,
		"schedulePercent": 0.875778,
		"totalGuests": 390,
		"guestPercent": 0.64945,
		"mostGuestDate": "2023-09-26 00:00:00",
		"mostGuestName": "Live Oak Bank Pavilion",
		"mostGuestCity": "Wilmington",
		"mostGuestAmount": 60
	},
	{
		"id": "23ab92c",
		"email": "jk@picklesounds.com",
		"name": "Pickle  ",
		"createdDate": "2022-06-01 20:57:48",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 727,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 131,
		"schedulePercent": 0.10833,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "aa72cad",
		"email": "matt@ratbagrecords.com",
		"name": "Matty",
		"createdDate": "2019-08-21 10:16:22",
		"totalOrganizations": 12,
		"totalTours": 29,
		"totalDays": 413,
		"totalCountries": 16,
		"totalStates": 64,
		"totalEvents": 172,
		"eventPercent": 0.755661,
		"totalScheduleItems": 1611,
		"schedulePercent": 0.644683,
		"totalGuests": 949,
		"guestPercent": 0.812475,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 100
	},
	{
		"id": "f4712a3",
		"email": "songdoc1@mac.com",
		"name": "TW",
		"createdDate": "2015-02-06 07:38:42",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 77,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 339,
		"schedulePercent": 0.227122,
		"totalGuests": 92,
		"guestPercent": 0.466163,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Harrah's Cherokee Casino Resort",
		"mostGuestCity": "Cherokee",
		"mostGuestAmount": 30
	},
	{
		"id": "0e11a05",
		"email": "dan@mcgeemanagement.com",
		"name": "Daniel",
		"createdDate": "2013-07-09 05:35:46",
		"totalOrganizations": 7,
		"totalTours": 15,
		"totalDays": 470,
		"totalCountries": 16,
		"totalStates": 61,
		"totalEvents": 228,
		"eventPercent": 0.845848,
		"totalScheduleItems": 2215,
		"schedulePercent": 0.752748,
		"totalGuests": 587,
		"guestPercent": 0.721494,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "MetLife Stadium",
		"mostGuestCity": "East Rutherford",
		"mostGuestAmount": 66
	},
	{
		"id": "d73b037",
		"email": "2Jakes@usa.net",
		"name": "Jake",
		"createdDate": "2011-04-20 10:41:08",
		"totalOrganizations": 8,
		"totalTours": 40,
		"totalDays": 249,
		"totalCountries": 7,
		"totalStates": 42,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 938,
		"schedulePercent": 0.462058,
		"totalGuests": 56,
		"guestPercent": 0.429744,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "Brighton Music Hall",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 21
	},
	{
		"id": "0e503e8",
		"email": "CLiberatore@sjcfl.us",
		"name": "Carl",
		"createdDate": "2022-08-03 15:44:40",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 231,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 229,
		"eventPercent": 0.847305,
		"totalScheduleItems": 2336,
		"schedulePercent": 0.771818,
		"totalGuests": 47,
		"guestPercent": 0.417031,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "St. Augustine Amphitheatre",
		"mostGuestCity": "St. Augustine",
		"mostGuestAmount": 18
	},
	{
		"id": "eb419a2",
		"email": "jaimee.bews@gmail.com",
		"name": "Jaimee",
		"createdDate": "2021-09-11 11:23:23",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 280,
		"totalCountries": 11,
		"totalStates": 17,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 880,
		"schedulePercent": 0.446961,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-09-21 00:00:00",
		"mostGuestName": "Ancienne Belgique",
		"mostGuestCity": "Brussel",
		"mostGuestAmount": 2
	},
	{
		"id": "3615e11",
		"email": "jackfunk@round3mgt.com",
		"name": "Jack",
		"createdDate": "2013-03-26 03:11:23",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 490,
		"totalCountries": 5,
		"totalStates": 41,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 961,
		"schedulePercent": 0.468812,
		"totalGuests": 660,
		"guestPercent": 0.742021,
		"mostGuestDate": "2023-08-14 00:00:00",
		"mostGuestName": "Grand Ole Opry",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 35
	},
	{
		"id": "d8bb1d3",
		"email": "djcplus@yahoo.com",
		"name": "Clarence",
		"createdDate": "2018-01-10 15:20:04",
		"totalOrganizations": 9,
		"totalTours": 8,
		"totalDays": 494,
		"totalCountries": 13,
		"totalStates": 45,
		"totalEvents": 237,
		"eventPercent": 0.858429,
		"totalScheduleItems": 3205,
		"schedulePercent": 0.870216,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8ba4563",
		"email": "kirkland.middleton@gmail.com",
		"name": "Kirkland",
		"createdDate": "2023-09-07 16:51:40",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 199,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 114,
		"schedulePercent": 0.0986624,
		"totalGuests": 78,
		"guestPercent": 0.45398,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The Broadside",
		"mostGuestCity": "New Orleans",
		"mostGuestAmount": 21
	},
	{
		"id": "830c177",
		"email": "interruptersmastertour@gmail.com",
		"name": "THE",
		"createdDate": "2021-06-24 19:52:17",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 151,
		"totalCountries": 12,
		"totalStates": 63,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1141,
		"schedulePercent": 0.524037,
		"totalGuests": 341,
		"guestPercent": 0.623758,
		"mostGuestDate": "2023-06-02 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 29
	},
	{
		"id": "1b2eda3",
		"email": "dannylac@gmail.com",
		"name": "Danny ",
		"createdDate": "2013-10-17 19:53:46",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 130,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 814,
		"schedulePercent": 0.424447,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e7264f2",
		"email": "yelawolfcfo@gmail.com",
		"name": "Aaron",
		"createdDate": "2023-09-26 15:23:59",
		"totalOrganizations": 11,
		"totalTours": 7,
		"totalDays": 513,
		"totalCountries": 4,
		"totalStates": 27,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 795,
		"schedulePercent": 0.41862,
		"totalGuests": 49,
		"guestPercent": 0.419944,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "The Essex",
		"mostGuestCity": "Rochester",
		"mostGuestAmount": 19
	},
	{
		"id": "1a02d5f",
		"email": "matt@directartistmgmt.com",
		"name": "Matt",
		"createdDate": "2019-08-03 04:46:08",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 50,
		"totalCountries": 1,
		"totalStates": 22,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 371,
		"schedulePercent": 0.244074,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3bee085",
		"email": "abbyfusco@gmail.com",
		"name": "Abby",
		"createdDate": "2015-10-05 17:58:50",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 99,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 864,
		"schedulePercent": 0.440869,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "eb14fda",
		"email": "paula@elvolcanmusica.com",
		"name": "Paula",
		"createdDate": "2022-03-16 12:41:41",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 1883,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 278,
		"schedulePercent": 0.196795,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "76150d3",
		"email": "jonathan@ncoab.com",
		"name": "Jonathan",
		"createdDate": "2022-05-25 11:01:55",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 109,
		"totalCountries": 3,
		"totalStates": 11,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 536,
		"schedulePercent": 0.315455,
		"totalGuests": 149,
		"guestPercent": 0.513972,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Betongparken",
		"mostGuestCity": "Johanneshov",
		"mostGuestAmount": 61
	},
	{
		"id": "908b07c",
		"email": "tylershapard1@gmail.com",
		"name": "Tyler ",
		"createdDate": "2014-08-09 09:31:46",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 148,
		"totalCountries": 4,
		"totalStates": 36,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 1172,
		"schedulePercent": 0.532645,
		"totalGuests": 200,
		"guestPercent": 0.550258,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "The Theater at Virgin Hotels",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 40
	},
	{
		"id": "a5510b1",
		"email": "dulce@kaleidoscope.mx",
		"name": "Dulce",
		"createdDate": "2014-11-02 00:20:14",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 385,
		"totalCountries": 15,
		"totalStates": 42,
		"totalEvents": 174,
		"eventPercent": 0.759502,
		"totalScheduleItems": 3331,
		"schedulePercent": 0.88081,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e125738",
		"email": "Andrewpaulmack@gmail.com",
		"name": "Paul",
		"createdDate": "2018-07-17 19:47:31",
		"totalOrganizations": 25,
		"totalTours": 32,
		"totalDays": 508,
		"totalCountries": 8,
		"totalStates": 58,
		"totalEvents": 259,
		"eventPercent": 0.884783,
		"totalScheduleItems": 3862,
		"schedulePercent": 0.915905,
		"totalGuests": 1791,
		"guestPercent": 0.919613,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 98
	},
	{
		"id": "40ef684",
		"email": "alejandroiragorri@gmail.com",
		"name": "Alejandro",
		"createdDate": "2012-02-07 23:03:32",
		"totalOrganizations": 5,
		"totalTours": 23,
		"totalDays": 284,
		"totalCountries": 10,
		"totalStates": 46,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 1621,
		"schedulePercent": 0.647199,
		"totalGuests": 580,
		"guestPercent": 0.718845,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Berkeley",
		"mostGuestAmount": 42
	},
	{
		"id": "7e5a449",
		"email": "charlesexbrayat@gmail.com",
		"name": "Charles",
		"createdDate": "2017-12-27 23:33:25",
		"totalOrganizations": 8,
		"totalTours": 1,
		"totalDays": 98,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 1044,
		"schedulePercent": 0.495564,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0e16ca8",
		"email": "davepeteaudio@gmail.com",
		"name": "David",
		"createdDate": "2021-09-14 15:33:50",
		"totalOrganizations": 12,
		"totalTours": 13,
		"totalDays": 1124,
		"totalCountries": 14,
		"totalStates": 75,
		"totalEvents": 213,
		"eventPercent": 0.827837,
		"totalScheduleItems": 3109,
		"schedulePercent": 0.862535,
		"totalGuests": 932,
		"guestPercent": 0.807708,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "O2 Forum",
		"mostGuestCity": "London",
		"mostGuestAmount": 49
	},
	{
		"id": "3196898",
		"email": "getwallaced@gmail.com",
		"name": "Donovan",
		"createdDate": "2022-12-31 05:30:26",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 134,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 454,
		"schedulePercent": 0.278109,
		"totalGuests": 221,
		"guestPercent": 0.563634,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "Hard Rock Live at Etess Arena",
		"mostGuestCity": "Atlantic City",
		"mostGuestAmount": 34
	},
	{
		"id": "3949eb5",
		"email": "robertmasel@gmail.com",
		"name": "Robert",
		"createdDate": "2017-02-12 06:35:12",
		"totalOrganizations": 6,
		"totalTours": 11,
		"totalDays": 388,
		"totalCountries": 19,
		"totalStates": 63,
		"totalEvents": 201,
		"eventPercent": 0.808237,
		"totalScheduleItems": 3422,
		"schedulePercent": 0.888624,
		"totalGuests": 837,
		"guestPercent": 0.785194,
		"mostGuestDate": "2023-07-23 00:00:00",
		"mostGuestName": "ParkbÃ¼hne Wuhlheide",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 305
	},
	{
		"id": "89329fa",
		"email": "joeptouring@gmail.com",
		"name": "Joe",
		"createdDate": "2020-02-04 16:46:59",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 665,
		"totalCountries": 4,
		"totalStates": 45,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 1908,
		"schedulePercent": 0.705469,
		"totalGuests": 803,
		"guestPercent": 0.77738,
		"mostGuestDate": "2023-10-09 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 59
	},
	{
		"id": "2643ef7",
		"email": "finessemx@gmail.com",
		"name": "David",
		"createdDate": "2019-08-07 01:42:06",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 104,
		"totalCountries": 3,
		"totalStates": 30,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 367,
		"schedulePercent": 0.241557,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ac7a39b",
		"email": "tommygunninghamproduction@gmail.com",
		"name": "Tommy ",
		"createdDate": "2023-04-26 09:46:50",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 274,
		"totalCountries": 11,
		"totalStates": 40,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 690,
		"schedulePercent": 0.376771,
		"totalGuests": 46,
		"guestPercent": 0.415044,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Electric Brixton",
		"mostGuestCity": "London",
		"mostGuestAmount": 26
	},
	{
		"id": "8127340",
		"email": "jjmowarin@gmail.com",
		"name": "Joshua",
		"createdDate": "2023-08-29 23:51:02",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 55,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 213,
		"schedulePercent": 0.158125,
		"totalGuests": 132,
		"guestPercent": 0.499801,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "Irving Plaza",
		"mostGuestCity": "New York",
		"mostGuestAmount": 65
	},
	{
		"id": "9b4608e",
		"email": "harrisonstormmusic@gmail.com",
		"name": "Harrison",
		"createdDate": "2018-09-04 04:37:32",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 62,
		"totalCountries": 6,
		"totalStates": 12,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 198,
		"schedulePercent": 0.148987,
		"totalGuests": 10,
		"guestPercent": 0.343531,
		"mostGuestDate": "2023-06-11 00:00:00",
		"mostGuestName": "La Boule Noire",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 5
	},
	{
		"id": "8ae6fb0",
		"email": "louisemoe@hitorchestra.dk",
		"name": "Louise",
		"createdDate": "2022-10-04 11:33:06",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 37,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 451,
		"schedulePercent": 0.277049,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2a964b5",
		"email": "bhorn@earlyhour.com",
		"name": "Early",
		"createdDate": "2014-12-02 07:21:05",
		"totalOrganizations": 19,
		"totalTours": 13,
		"totalDays": 556,
		"totalCountries": 18,
		"totalStates": 53,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 1230,
		"schedulePercent": 0.547477,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "052cbde",
		"email": "rsconcerts@icloud.com",
		"name": "Robert",
		"createdDate": "2017-07-25 13:46:24",
		"totalOrganizations": 8,
		"totalTours": 31,
		"totalDays": 244,
		"totalCountries": 14,
		"totalStates": 49,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 1806,
		"schedulePercent": 0.687061,
		"totalGuests": 347,
		"guestPercent": 0.627467,
		"mostGuestDate": "2023-04-17 00:00:00",
		"mostGuestName": "London Palladium",
		"mostGuestCity": "London",
		"mostGuestAmount": 73
	},
	{
		"id": "28df286",
		"email": "greenskyontour@hotmail.com",
		"name": "Jason",
		"createdDate": "2023-08-25 15:24:21",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 52,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 465,
		"schedulePercent": 0.282876,
		"totalGuests": 226,
		"guestPercent": 0.566945,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 59
	},
	{
		"id": "7e073c2",
		"email": "hirewillforyourmusic@gmail.com",
		"name": "Will",
		"createdDate": "2021-06-01 01:46:38",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 307,
		"totalCountries": 5,
		"totalStates": 6,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 75,
		"schedulePercent": 0.0712488,
		"totalGuests": 36,
		"guestPercent": 0.397961,
		"mostGuestDate": "2023-03-04 00:00:00",
		"mostGuestName": "John Dee Live Club & Pub",
		"mostGuestCity": "Oslo",
		"mostGuestAmount": 14
	},
	{
		"id": "5220f66",
		"email": "dave@dclive.co.nz",
		"name": "Dave",
		"createdDate": "2018-12-23 04:24:49",
		"totalOrganizations": 44,
		"totalTours": 60,
		"totalDays": 838,
		"totalCountries": 17,
		"totalStates": 47,
		"totalEvents": 339,
		"eventPercent": 0.937094,
		"totalScheduleItems": 5139,
		"schedulePercent": 0.965965,
		"totalGuests": 485,
		"guestPercent": 0.685207,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 50
	},
	{
		"id": "d2751dd",
		"email": "Lttgermany@aol.com",
		"name": "Frank",
		"createdDate": "2012-03-25 02:37:14",
		"totalOrganizations": 20,
		"totalTours": 22,
		"totalDays": 453,
		"totalCountries": 20,
		"totalStates": 61,
		"totalEvents": 199,
		"eventPercent": 0.804264,
		"totalScheduleItems": 2046,
		"schedulePercent": 0.727321,
		"totalGuests": 1008,
		"guestPercent": 0.825718,
		"mostGuestDate": "2023-08-05 00:00:00",
		"mostGuestName": "Roadrunner",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 71
	},
	{
		"id": "beb054d",
		"email": "robert@laatuaani.fi",
		"name": "Robert",
		"createdDate": "2017-01-31 14:04:55",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 252,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 885,
		"schedulePercent": 0.44802,
		"totalGuests": 918,
		"guestPercent": 0.803735,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Kulttuuritalo",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 107
	},
	{
		"id": "fcfd110",
		"email": "sarah@odmanagement.co.uk",
		"name": "Sarah",
		"createdDate": "2021-08-02 12:06:43",
		"totalOrganizations": 2,
		"totalTours": 19,
		"totalDays": 109,
		"totalCountries": 5,
		"totalStates": 14,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 508,
		"schedulePercent": 0.303139,
		"totalGuests": 33,
		"guestPercent": 0.394252,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "HBF Stadium",
		"mostGuestCity": "Mount Claremont",
		"mostGuestAmount": 33
	},
	{
		"id": "6e1e02b",
		"email": "tj.pigeondetectives@gmail.com",
		"name": "Tom",
		"createdDate": "2023-10-11 18:06:03",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 34,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 182,
		"schedulePercent": 0.139849,
		"totalGuests": 39,
		"guestPercent": 0.404185,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "Electric Ballroom",
		"mostGuestCity": "London",
		"mostGuestAmount": 17
	},
	{
		"id": "72ec949",
		"email": "daniel@flagrantartists.com",
		"name": "Daniel",
		"createdDate": "2018-08-06 04:05:55",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 203,
		"totalCountries": 23,
		"totalStates": 50,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 1189,
		"schedulePercent": 0.537015,
		"totalGuests": 13,
		"guestPercent": 0.352536,
		"mostGuestDate": "2023-02-09 00:00:00",
		"mostGuestName": "Palais Theatre",
		"mostGuestCity": "St Kilda",
		"mostGuestAmount": 8
	},
	{
		"id": "c0ccdda",
		"email": "xjennydouglasx@gmail.com",
		"name": "Jenny",
		"createdDate": "2014-03-13 05:38:27",
		"totalOrganizations": 20,
		"totalTours": 19,
		"totalDays": 563,
		"totalCountries": 31,
		"totalStates": 99,
		"totalEvents": 274,
		"eventPercent": 0.897232,
		"totalScheduleItems": 4428,
		"schedulePercent": 0.942657,
		"totalGuests": 1610,
		"guestPercent": 0.903854,
		"mostGuestDate": "2023-01-15 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 63
	},
	{
		"id": "bd78110",
		"email": "hoskinscarly@gmail.com",
		"name": "Carly",
		"createdDate": "2015-07-30 19:44:01",
		"totalOrganizations": 23,
		"totalTours": 22,
		"totalDays": 482,
		"totalCountries": 12,
		"totalStates": 49,
		"totalEvents": 176,
		"eventPercent": 0.763475,
		"totalScheduleItems": 2753,
		"schedulePercent": 0.826248,
		"totalGuests": 877,
		"guestPercent": 0.79367,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 185
	},
	{
		"id": "9753dad",
		"email": "raphiki@hidefmedia.net",
		"name": "Cory",
		"createdDate": "2015-06-15 20:26:59",
		"totalOrganizations": 48,
		"totalTours": 49,
		"totalDays": 1238,
		"totalCountries": 21,
		"totalStates": 74,
		"totalEvents": 454,
		"eventPercent": 0.968084,
		"totalScheduleItems": 3237,
		"schedulePercent": 0.872732,
		"totalGuests": 758,
		"guestPercent": 0.766256,
		"mostGuestDate": "2023-09-14 00:00:00",
		"mostGuestName": "The Brooklyn Mirage",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 140
	},
	{
		"id": "5ef5ceb",
		"email": "erinkellyobrien@icloud.com",
		"name": "Erin",
		"createdDate": "2016-06-17 02:24:09",
		"totalOrganizations": 10,
		"totalTours": 5,
		"totalDays": 217,
		"totalCountries": 3,
		"totalStates": 33,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 832,
		"schedulePercent": 0.431731,
		"totalGuests": 1317,
		"guestPercent": 0.873924,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 243
	},
	{
		"id": "37af79a",
		"email": "rdalelynch@gmail.com",
		"name": "Dale",
		"createdDate": "2009-09-23 17:57:10",
		"totalOrganizations": 10,
		"totalTours": 22,
		"totalDays": 300,
		"totalCountries": 20,
		"totalStates": 59,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 2366,
		"schedulePercent": 0.775924,
		"totalGuests": 643,
		"guestPercent": 0.736988,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 58
	},
	{
		"id": "94678f9",
		"email": "mn.touring@gmail.com",
		"name": "Madison",
		"createdDate": "2022-09-07 16:56:30",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 109,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 681,
		"schedulePercent": 0.373328,
		"totalGuests": 265,
		"guestPercent": 0.586942,
		"mostGuestDate": "2023-03-14 00:00:00",
		"mostGuestName": "Paradise Rock Club",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 20
	},
	{
		"id": "917ff24",
		"email": "patrick@amfmmusic.com",
		"name": "Heather",
		"createdDate": "2019-07-01 15:16:38",
		"totalOrganizations": 11,
		"totalTours": 9,
		"totalDays": 861,
		"totalCountries": 3,
		"totalStates": 52,
		"totalEvents": 251,
		"eventPercent": 0.873792,
		"totalScheduleItems": 2975,
		"schedulePercent": 0.849027,
		"totalGuests": 998,
		"guestPercent": 0.823202,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 44
	},
	{
		"id": "44a481a",
		"email": "Lousurfs@aol.com",
		"name": "Christopher ",
		"createdDate": "2017-03-21 04:04:06",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 59,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 288,
		"schedulePercent": 0.201828,
		"totalGuests": 64,
		"guestPercent": 0.439015,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 41
	},
	{
		"id": "31da2dc",
		"email": "rylandlynch@gmail.com",
		"name": "Ryland",
		"createdDate": "2012-09-02 03:37:39",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 308,
		"totalCountries": 8,
		"totalStates": 25,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 937,
		"schedulePercent": 0.461528,
		"totalGuests": 363,
		"guestPercent": 0.637134,
		"mostGuestDate": "2023-06-14 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 138
	},
	{
		"id": "2dd89e5",
		"email": "whisner@hotmail.com",
		"name": "Sean",
		"createdDate": "2012-01-09 03:42:06",
		"totalOrganizations": 12,
		"totalTours": 9,
		"totalDays": 935,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 157,
		"eventPercent": 0.720567,
		"totalScheduleItems": 3122,
		"schedulePercent": 0.863197,
		"totalGuests": 2000,
		"guestPercent": 0.933386,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 137
	},
	{
		"id": "d147531",
		"email": "Tkosound@gmail.com",
		"name": "Timothy",
		"createdDate": "2014-04-01 20:52:35",
		"totalOrganizations": 13,
		"totalTours": 18,
		"totalDays": 862,
		"totalCountries": 3,
		"totalStates": 49,
		"totalEvents": 665,
		"eventPercent": 0.984903,
		"totalScheduleItems": 5045,
		"schedulePercent": 0.963051,
		"totalGuests": 1190,
		"guestPercent": 0.856178,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "State Farm Arena",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 133
	},
	{
		"id": "bc4cb65",
		"email": "sferrellpr@gmail.com",
		"name": "Stephanie",
		"createdDate": "2023-06-07 18:13:51",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 375,
		"totalCountries": 9,
		"totalStates": 28,
		"totalEvents": 134,
		"eventPercent": 0.658721,
		"totalScheduleItems": 2944,
		"schedulePercent": 0.84651,
		"totalGuests": 1912,
		"guestPercent": 0.929546,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The ICON Festival Stage",
		"mostGuestCity": "Cincinnati",
		"mostGuestAmount": 238
	},
	{
		"id": "5fd8332",
		"email": "rosemary.ellis17@gmail.com",
		"name": "Rosemary",
		"createdDate": "2022-08-10 17:11:54",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 169,
		"totalCountries": 7,
		"totalStates": 37,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 1281,
		"schedulePercent": 0.560588,
		"totalGuests": 97,
		"guestPercent": 0.471461,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "Zebulon",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 28
	},
	{
		"id": "7721857",
		"email": "allisonponthier@gmail.com",
		"name": "Allison",
		"createdDate": "2021-08-23 16:33:35",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 25,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 182,
		"schedulePercent": 0.139849,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-04-07 00:00:00",
		"mostGuestName": "The Fillmore Philadelphia",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 7
	},
	{
		"id": "1f4f262",
		"email": "Brennantookit@gmail.com",
		"name": "Brennan",
		"createdDate": "2023-05-03 02:11:11",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 411,
		"totalCountries": 4,
		"totalStates": 41,
		"totalEvents": 166,
		"eventPercent": 0.74308,
		"totalScheduleItems": 1131,
		"schedulePercent": 0.519931,
		"totalGuests": 245,
		"guestPercent": 0.57595,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Whitewater Amphitheater",
		"mostGuestCity": "New Braunfels",
		"mostGuestAmount": 38
	},
	{
		"id": "bc8159f",
		"email": "max_straub@yahoo.com",
		"name": "Max",
		"createdDate": "2022-03-09 18:54:16",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 30,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 286,
		"schedulePercent": 0.201033,
		"totalGuests": 261,
		"guestPercent": 0.584823,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 40
	},
	{
		"id": "ca635d8",
		"email": "builttospilltour@gmail.com",
		"name": "Isa",
		"createdDate": "2023-09-30 04:52:37",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 86,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 191,
		"schedulePercent": 0.145146,
		"totalGuests": 35,
		"guestPercent": 0.396901,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "El Rey Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 8
	},
	{
		"id": "3ee1df3",
		"email": "JamieLStephens12@gmail.com",
		"name": "Jamie",
		"createdDate": "2015-01-28 17:11:22",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 462,
		"totalCountries": 6,
		"totalStates": 15,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 1048,
		"schedulePercent": 0.496358,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2d5919a",
		"email": "katie@propermgmt.com",
		"name": "Katie",
		"createdDate": "2014-06-10 16:25:19",
		"totalOrganizations": 20,
		"totalTours": 19,
		"totalDays": 1856,
		"totalCountries": 5,
		"totalStates": 51,
		"totalEvents": 660,
		"eventPercent": 0.984638,
		"totalScheduleItems": 10003,
		"schedulePercent": 0.997351,
		"totalGuests": 4903,
		"guestPercent": 0.991259,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Viejas Arena",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 166
	},
	{
		"id": "e529b1e",
		"email": "alexivestour@gmail.com",
		"name": "Alex",
		"createdDate": "2023-04-18 17:22:12",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 418,
		"totalCountries": 3,
		"totalStates": 11,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 133,
		"schedulePercent": 0.109389,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6e46c9f",
		"email": "keanu.huerta@gmail.com",
		"name": "Keanu",
		"createdDate": "2021-11-08 07:38:34",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 38,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 222,
		"schedulePercent": 0.164879,
		"totalGuests": 210,
		"guestPercent": 0.557542,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Cornerstone Craft Beer & Live Music",
		"mostGuestCity": "Berkeley",
		"mostGuestAmount": 32
	},
	{
		"id": "56cda10",
		"email": "fairwavemedia@gmail.com",
		"name": "Ajani",
		"createdDate": "2023-01-26 19:49:36",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 24,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 9,
		"eventPercent": 0.0607866,
		"totalScheduleItems": 120,
		"schedulePercent": 0.102635,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "90c9f96",
		"email": "production1979@gmail.com",
		"name": "Teresa",
		"createdDate": "2013-02-19 01:16:38",
		"totalOrganizations": 18,
		"totalTours": 17,
		"totalDays": 409,
		"totalCountries": 15,
		"totalStates": 59,
		"totalEvents": 170,
		"eventPercent": 0.752086,
		"totalScheduleItems": 2262,
		"schedulePercent": 0.760959,
		"totalGuests": 956,
		"guestPercent": 0.814727,
		"mostGuestDate": "2023-05-23 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 54
	},
	{
		"id": "bec6599",
		"email": "julia.isberg@yaay.se",
		"name": "Julia",
		"createdDate": "2023-01-20 10:10:05",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 29,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 384,
		"schedulePercent": 0.250695,
		"totalGuests": 114,
		"guestPercent": 0.486028,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Tovastugan",
		"mostGuestCity": "NykÃ¶ping",
		"mostGuestAmount": 17
	},
	{
		"id": "6f374f5",
		"email": "Helen@eccles.co.nz",
		"name": "Helen",
		"createdDate": "2015-09-04 04:26:05",
		"totalOrganizations": 13,
		"totalTours": 5,
		"totalDays": 578,
		"totalCountries": 6,
		"totalStates": 15,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 892,
		"schedulePercent": 0.450536,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b80a2b6",
		"email": "nshibou@mac.com",
		"name": "Nikki",
		"createdDate": "2011-01-25 02:55:08",
		"totalOrganizations": 6,
		"totalTours": 12,
		"totalDays": 552,
		"totalCountries": 8,
		"totalStates": 54,
		"totalEvents": 221,
		"eventPercent": 0.83777,
		"totalScheduleItems": 2472,
		"schedulePercent": 0.789167,
		"totalGuests": 1668,
		"guestPercent": 0.909416,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Pantages Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 215
	},
	{
		"id": "cce9329",
		"email": "dave@silentpartnermanagement.com",
		"name": "Dave",
		"createdDate": "2022-06-14 20:25:56",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 203,
		"totalCountries": 3,
		"totalStates": 32,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 866,
		"schedulePercent": 0.441928,
		"totalGuests": 205,
		"guestPercent": 0.554231,
		"mostGuestDate": "2023-07-25 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 32
	},
	{
		"id": "f8a7369",
		"email": "tyler.davis09@yahoo.com",
		"name": "Tyler",
		"createdDate": "2023-07-03 16:27:45",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 522,
		"totalCountries": 9,
		"totalStates": 49,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 1852,
		"schedulePercent": 0.695405,
		"totalGuests": 1895,
		"guestPercent": 0.928221,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Raymond James Stadium",
		"mostGuestCity": "Tampa ",
		"mostGuestAmount": 248
	},
	{
		"id": "2db9850",
		"email": "jaydechamberlain.music@gmail.com",
		"name": "Jayde",
		"createdDate": "2022-11-25 18:41:47",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 24,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 149,
		"schedulePercent": 0.118925,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "28fd449",
		"email": "dbuck9@hotmail.com",
		"name": "Dave",
		"createdDate": "2012-01-12 17:53:59",
		"totalOrganizations": 14,
		"totalTours": 31,
		"totalDays": 754,
		"totalCountries": 17,
		"totalStates": 80,
		"totalEvents": 349,
		"eventPercent": 0.940803,
		"totalScheduleItems": 4248,
		"schedulePercent": 0.935638,
		"totalGuests": 1927,
		"guestPercent": 0.93034,
		"mostGuestDate": "2023-08-04 00:00:00",
		"mostGuestName": "Sunset Cove Amphitheater",
		"mostGuestCity": "Boca Raton",
		"mostGuestAmount": 132
	},
	{
		"id": "c77cce6",
		"email": "rblake@blakeentertain.com.au",
		"name": "Rebecca",
		"createdDate": "2022-12-02 00:43:33",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 85,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 283,
		"schedulePercent": 0.199841,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1d0422c",
		"email": "spatkins@gmail.com",
		"name": "Sean",
		"createdDate": "2016-01-08 23:56:06",
		"totalOrganizations": 15,
		"totalTours": 4,
		"totalDays": 760,
		"totalCountries": 5,
		"totalStates": 32,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 680,
		"schedulePercent": 0.372533,
		"totalGuests": 191,
		"guestPercent": 0.543769,
		"mostGuestDate": "2023-05-26 00:00:00",
		"mostGuestName": "Coca-Cola Roxy",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 76
	},
	{
		"id": "cf7e12b",
		"email": "alex@1720.la",
		"name": "Alex",
		"createdDate": "2022-05-19 03:37:29",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 90,
		"totalCountries": 5,
		"totalStates": 36,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 765,
		"schedulePercent": 0.407098,
		"totalGuests": 873,
		"guestPercent": 0.79261,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "1720",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 134
	},
	{
		"id": "0a5db06",
		"email": "twistedrobot@gmail.com",
		"name": "Paul",
		"createdDate": "2022-02-21 21:26:48",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 30,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 216,
		"schedulePercent": 0.160509,
		"totalGuests": 18,
		"guestPercent": 0.364455,
		"mostGuestDate": "2023-01-13 00:00:00",
		"mostGuestName": "Teatro Circo Price",
		"mostGuestCity": "Madrid",
		"mostGuestAmount": 7
	},
	{
		"id": "67d68ff",
		"email": "hartfordgrant@gmail.com",
		"name": "Grant",
		"createdDate": "2014-06-11 22:06:24",
		"totalOrganizations": 3,
		"totalTours": 11,
		"totalDays": 282,
		"totalCountries": 6,
		"totalStates": 47,
		"totalEvents": 214,
		"eventPercent": 0.829559,
		"totalScheduleItems": 2119,
		"schedulePercent": 0.738048,
		"totalGuests": 541,
		"guestPercent": 0.706264,
		"mostGuestDate": "2023-04-10 00:00:00",
		"mostGuestName": "The Fisher Center for the Performing Arts",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 139
	},
	{
		"id": "51d1073",
		"email": "ricky@reclaimmusicgroup.com",
		"name": "Ricky",
		"createdDate": "2018-01-29 21:10:25",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 139,
		"totalCountries": 7,
		"totalStates": 41,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 762,
		"schedulePercent": 0.406436,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-10-03 00:00:00",
		"mostGuestName": "Stage AE",
		"mostGuestCity": "Pittsburgh",
		"mostGuestAmount": 6
	},
	{
		"id": "c15fd08",
		"email": "beaukingtm@aol.com",
		"name": "Beau",
		"createdDate": "2012-06-05 18:00:02",
		"totalOrganizations": 16,
		"totalTours": 8,
		"totalDays": 668,
		"totalCountries": 11,
		"totalStates": 54,
		"totalEvents": 160,
		"eventPercent": 0.72838,
		"totalScheduleItems": 3513,
		"schedulePercent": 0.895246,
		"totalGuests": 2871,
		"guestPercent": 0.967024,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "Northwell Health at Jones Beach Theater",
		"mostGuestCity": "Wantagh",
		"mostGuestAmount": 109
	},
	{
		"id": "4f937d4",
		"email": "lucy.robertson@tegdainty.com",
		"name": "Lucy",
		"createdDate": "2019-11-12 01:48:48",
		"totalOrganizations": 2,
		"totalTours": 29,
		"totalDays": 107,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 737,
		"schedulePercent": 0.394385,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3511258",
		"email": "tedwox@gmail.com",
		"name": "Ted",
		"createdDate": "2016-03-22 01:22:25",
		"totalOrganizations": 14,
		"totalTours": 13,
		"totalDays": 441,
		"totalCountries": 8,
		"totalStates": 44,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 1660,
		"schedulePercent": 0.655675,
		"totalGuests": 1773,
		"guestPercent": 0.918421,
		"mostGuestDate": "2023-05-19 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 194
	},
	{
		"id": "da57fa4",
		"email": "dirk.tscherner@dt-m.de",
		"name": "Dirk",
		"createdDate": "2022-05-24 18:07:36",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 65,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 362,
		"schedulePercent": 0.238776,
		"totalGuests": 342,
		"guestPercent": 0.624156,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Burg Eisenhardt",
		"mostGuestCity": "Bad Belzig",
		"mostGuestAmount": 46
	},
	{
		"id": "cc83120",
		"email": "brooklynn.g@redstreetrecords.com",
		"name": "Brooklynn",
		"createdDate": "2023-03-08 20:12:58",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 745,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 6,
		"eventPercent": 0.0397298,
		"totalScheduleItems": 276,
		"schedulePercent": 0.196001,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6b84c47",
		"email": "worldofaldo@gmail.com",
		"name": "Alexander",
		"createdDate": "2013-12-05 02:32:43",
		"totalOrganizations": 16,
		"totalTours": 37,
		"totalDays": 819,
		"totalCountries": 20,
		"totalStates": 71,
		"totalEvents": 291,
		"eventPercent": 0.909548,
		"totalScheduleItems": 4553,
		"schedulePercent": 0.947292,
		"totalGuests": 551,
		"guestPercent": 0.710105,
		"mostGuestDate": "2023-05-18 00:00:00",
		"mostGuestName": "History",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 51
	},
	{
		"id": "848bf58",
		"email": "cwrightsman@me.com",
		"name": "Chris",
		"createdDate": "2010-03-09 22:49:27",
		"totalOrganizations": 12,
		"totalTours": 2,
		"totalDays": 154,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 1397,
		"schedulePercent": 0.59065,
		"totalGuests": 111,
		"guestPercent": 0.483115,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "Fantasy Springs Casino",
		"mostGuestCity": "Indio",
		"mostGuestAmount": 36
	},
	{
		"id": "dffa0ef",
		"email": "dave@cp-au.com",
		"name": "Dave ",
		"createdDate": "2023-02-22 02:54:34",
		"totalOrganizations": 8,
		"totalTours": 14,
		"totalDays": 323,
		"totalCountries": 17,
		"totalStates": 55,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 2429,
		"schedulePercent": 0.783737,
		"totalGuests": 376,
		"guestPercent": 0.643888,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "Kings Theatre",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 34
	},
	{
		"id": "0492fdf",
		"email": "johnmcraeproductions@gmail.com",
		"name": "John",
		"createdDate": "2019-01-05 04:12:08",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 309,
		"totalCountries": 3,
		"totalStates": 42,
		"totalEvents": 154,
		"eventPercent": 0.71368,
		"totalScheduleItems": 1877,
		"schedulePercent": 0.700702,
		"totalGuests": 956,
		"guestPercent": 0.814727,
		"mostGuestDate": "2023-11-05 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 82
	},
	{
		"id": "58279f4",
		"email": "gaffer@shell.tech",
		"name": "Kieran",
		"createdDate": "2023-05-16 18:09:01",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 265,
		"totalCountries": 6,
		"totalStates": 11,
		"totalEvents": 8,
		"eventPercent": 0.0535028,
		"totalScheduleItems": 228,
		"schedulePercent": 0.168719,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "176f6e6",
		"email": "caseylatter@gmail.com",
		"name": "Casey",
		"createdDate": "2019-03-28 18:18:24",
		"totalOrganizations": 12,
		"totalTours": 18,
		"totalDays": 801,
		"totalCountries": 6,
		"totalStates": 52,
		"totalEvents": 315,
		"eventPercent": 0.923057,
		"totalScheduleItems": 6780,
		"schedulePercent": 0.987551,
		"totalGuests": 1141,
		"guestPercent": 0.847702,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "The Salt Shed",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 45
	},
	{
		"id": "f60ec35",
		"email": "jc@bluejstrategies.com",
		"name": "Jimmy",
		"createdDate": "2016-09-06 16:49:27",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 124,
		"totalCountries": 3,
		"totalStates": 30,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 978,
		"schedulePercent": 0.474772,
		"totalGuests": 1493,
		"guestPercent": 0.893392,
		"mostGuestDate": "2023-08-09 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 135
	},
	{
		"id": "9e4b3d3",
		"email": "WILLIAMTHETM@GMAIL.COM",
		"name": "William",
		"createdDate": "2023-09-12 03:40:37",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 110,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 664,
		"schedulePercent": 0.366574,
		"totalGuests": 98,
		"guestPercent": 0.472917,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 49
	},
	{
		"id": "84cb106",
		"email": "lewisallman@me.com",
		"name": "Lewis",
		"createdDate": "2014-10-15 15:00:03",
		"totalOrganizations": 12,
		"totalTours": 7,
		"totalDays": 242,
		"totalCountries": 11,
		"totalStates": 16,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 823,
		"schedulePercent": 0.427493,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-02-14 00:00:00",
		"mostGuestName": "Columbiahalle",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 2
	},
	{
		"id": "77a013e",
		"email": "tyecoleman535@gmail.com",
		"name": "Tye",
		"createdDate": "2023-10-24 22:01:43",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 92,
		"totalCountries": 1,
		"totalStates": 18,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 222,
		"schedulePercent": 0.164879,
		"totalGuests": 17,
		"guestPercent": 0.362469,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "The Bourbon Theatre",
		"mostGuestCity": "Lincoln",
		"mostGuestAmount": 10
	},
	{
		"id": "edad05a",
		"email": "al@chuckwalla.co.uk",
		"name": "Alistair",
		"createdDate": "2022-01-31 23:19:14",
		"totalOrganizations": 11,
		"totalTours": 32,
		"totalDays": 829,
		"totalCountries": 24,
		"totalStates": 74,
		"totalEvents": 246,
		"eventPercent": 0.868759,
		"totalScheduleItems": 2706,
		"schedulePercent": 0.819229,
		"totalGuests": 654,
		"guestPercent": 0.740299,
		"mostGuestDate": "2023-09-29 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 68
	},
	{
		"id": "c18a310",
		"email": "tdouglasaudio@gmail.com",
		"name": "tyler",
		"createdDate": "2023-08-21 18:10:16",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 79,
		"totalCountries": 3,
		"totalStates": 27,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 1442,
		"schedulePercent": 0.601907,
		"totalGuests": 534,
		"guestPercent": 0.704278,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Viejas Arena",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 166
	},
	{
		"id": "59f7e9d",
		"email": "jackson@wormdiscoclub.co.uk",
		"name": "Jackson",
		"createdDate": "2022-07-19 13:52:32",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 35,
		"totalCountries": 3,
		"totalStates": 5,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 221,
		"schedulePercent": 0.163952,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "521a3b0",
		"email": "c.david@vp-lights.de",
		"name": "Christian ",
		"createdDate": "2018-01-03 09:52:21",
		"totalOrganizations": 9,
		"totalTours": 5,
		"totalDays": 131,
		"totalCountries": 16,
		"totalStates": 39,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 1234,
		"schedulePercent": 0.548537,
		"totalGuests": 303,
		"guestPercent": 0.604158,
		"mostGuestDate": "2023-04-02 00:00:00",
		"mostGuestName": "Slaktkyrkan",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 42
	},
	{
		"id": "6390b91",
		"email": "erniemartinmusic@gmail.com",
		"name": "Ernest",
		"createdDate": "2019-09-20 11:15:07",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 107,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 149,
		"schedulePercent": 0.118925,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "84ab6d6",
		"email": "willburnettproduction@gmail.com",
		"name": "Willie",
		"createdDate": "2020-03-06 04:53:00",
		"totalOrganizations": 6,
		"totalTours": 9,
		"totalDays": 730,
		"totalCountries": 3,
		"totalStates": 35,
		"totalEvents": 195,
		"eventPercent": 0.796318,
		"totalScheduleItems": 4354,
		"schedulePercent": 0.939875,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4067765",
		"email": "deven.craige@gmail.com",
		"name": "Deven ",
		"createdDate": "2017-05-09 00:14:58",
		"totalOrganizations": 6,
		"totalTours": 20,
		"totalDays": 684,
		"totalCountries": 10,
		"totalStates": 50,
		"totalEvents": 203,
		"eventPercent": 0.811681,
		"totalScheduleItems": 2666,
		"schedulePercent": 0.816051,
		"totalGuests": 400,
		"guestPercent": 0.653291,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "The Marlin Room at Webster Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 58
	},
	{
		"id": "01ac752",
		"email": "bostonbrittney@gmail.com",
		"name": "Brittney",
		"createdDate": "2019-09-20 18:11:56",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 48,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 461,
		"schedulePercent": 0.28142,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9654895",
		"email": "ttomcomedy@gmail.com",
		"name": "Taylor",
		"createdDate": "2023-03-27 15:43:03",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 137,
		"totalCountries": 4,
		"totalStates": 21,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 567,
		"schedulePercent": 0.327109,
		"totalGuests": 405,
		"guestPercent": 0.655145,
		"mostGuestDate": "2023-09-09 00:00:00",
		"mostGuestName": "Radio City Music Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 81
	},
	{
		"id": "d34ac9c",
		"email": "ds@domprojects.co",
		"name": "Dominic",
		"createdDate": "2021-12-28 18:21:00",
		"totalOrganizations": 9,
		"totalTours": 14,
		"totalDays": 342,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 2239,
		"schedulePercent": 0.755926,
		"totalGuests": 1135,
		"guestPercent": 0.846113,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "The Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 130
	},
	{
		"id": "d6ab17c",
		"email": "info.ghostlykisses@gmail.com",
		"name": "Margaux",
		"createdDate": "2019-08-16 15:09:03",
		"totalOrganizations": 2,
		"totalTours": 7,
		"totalDays": 408,
		"totalCountries": 13,
		"totalStates": 39,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1002,
		"schedulePercent": 0.485631,
		"totalGuests": 253,
		"guestPercent": 0.580718,
		"mostGuestDate": "2023-09-27 00:00:00",
		"mostGuestName": "Imperial Bell",
		"mostGuestCity": "Quebec",
		"mostGuestAmount": 36
	},
	{
		"id": "45205a5",
		"email": "dariairene.burla@gmail.com",
		"name": "daria irene",
		"createdDate": "2023-05-31 14:29:47",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 56,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 251,
		"schedulePercent": 0.18183,
		"totalGuests": 24,
		"guestPercent": 0.375712,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "PARCO DI  SAN GIULIANO MESTRE",
		"mostGuestCity": "VENEZIA",
		"mostGuestAmount": 17
	},
	{
		"id": "1f18f9c",
		"email": "sebrakoto83@gmail.com",
		"name": "sebastien",
		"createdDate": "2022-01-12 09:59:23",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 312,
		"totalCountries": 3,
		"totalStates": 20,
		"totalEvents": 157,
		"eventPercent": 0.720567,
		"totalScheduleItems": 2041,
		"schedulePercent": 0.725599,
		"totalGuests": 365,
		"guestPercent": 0.638591,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "U Palatinu",
		"mostGuestCity": "Ajaccio",
		"mostGuestAmount": 52
	},
	{
		"id": "28c9ad4",
		"email": "mark@thembcompany.com",
		"name": "Mark",
		"createdDate": "2012-04-23 21:07:31",
		"totalOrganizations": 20,
		"totalTours": 14,
		"totalDays": 441,
		"totalCountries": 13,
		"totalStates": 25,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1327,
		"schedulePercent": 0.573831,
		"totalGuests": 36,
		"guestPercent": 0.397961,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Ridgefield Playhouse",
		"mostGuestCity": "Ridgefield",
		"mostGuestAmount": 16
	},
	{
		"id": "3a7f9eb",
		"email": "owain.richards@me.com",
		"name": "Owain",
		"createdDate": "2014-01-16 17:45:11",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 138,
		"totalCountries": 3,
		"totalStates": 16,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 400,
		"schedulePercent": 0.256787,
		"totalGuests": 206,
		"guestPercent": 0.555291,
		"mostGuestDate": "2023-04-11 00:00:00",
		"mostGuestName": "Bimbo's 365 Club",
		"mostGuestCity": "San Francisco",
		"mostGuestAmount": 34
	},
	{
		"id": "ddcb397",
		"email": "management@moonhooch.com",
		"name": "Moon",
		"createdDate": "2022-03-11 23:02:41",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 154,
		"totalCountries": 7,
		"totalStates": 47,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1102,
		"schedulePercent": 0.511853,
		"totalGuests": 225,
		"guestPercent": 0.566018,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "The Stone Pony",
		"mostGuestCity": "Asbury Park",
		"mostGuestAmount": 19
	},
	{
		"id": "a2b076f",
		"email": "justin.rozinski@gmail.com",
		"name": "Justin",
		"createdDate": "2021-11-11 23:07:04",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 645,
		"totalCountries": 5,
		"totalStates": 53,
		"totalEvents": 378,
		"eventPercent": 0.950603,
		"totalScheduleItems": 4451,
		"schedulePercent": 0.943716,
		"totalGuests": 1128,
		"guestPercent": 0.844259,
		"mostGuestDate": "2023-12-06 00:00:00",
		"mostGuestName": "Terrace Theatre",
		"mostGuestCity": "Long Beach",
		"mostGuestAmount": 73
	},
	{
		"id": "28bc4c8",
		"email": "borja@sfmusic.es",
		"name": "Borja",
		"createdDate": "2019-12-02 16:24:38",
		"totalOrganizations": 5,
		"totalTours": 130,
		"totalDays": 363,
		"totalCountries": 11,
		"totalStates": 49,
		"totalEvents": 254,
		"eventPercent": 0.879619,
		"totalScheduleItems": 707,
		"schedulePercent": 0.383525,
		"totalGuests": 1247,
		"guestPercent": 0.864654,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Teatro PavÃ³n",
		"mostGuestCity": "Madrid",
		"mostGuestAmount": 55
	},
	{
		"id": "85230f1",
		"email": "daniel@dtmmedia.com",
		"name": "Daniel",
		"createdDate": "2015-12-16 17:41:51",
		"totalOrganizations": 8,
		"totalTours": 21,
		"totalDays": 561,
		"totalCountries": 3,
		"totalStates": 28,
		"totalEvents": 408,
		"eventPercent": 0.959078,
		"totalScheduleItems": 2588,
		"schedulePercent": 0.804397,
		"totalGuests": 316,
		"guestPercent": 0.612104,
		"mostGuestDate": "2023-04-10 00:00:00",
		"mostGuestName": "The Fisher Center for the Performing Arts",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 139
	},
	{
		"id": "ad9b604",
		"email": "john.lavender504@gmail.com",
		"name": "John",
		"createdDate": "2022-08-19 14:29:22",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 320,
		"totalCountries": 3,
		"totalStates": 23,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 119,
		"schedulePercent": 0.102106,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "The Intersection",
		"mostGuestCity": "Grand Rapids",
		"mostGuestAmount": 9
	},
	{
		"id": "0b96130",
		"email": "viltsu.haapala@gmail.com",
		"name": "ESKO",
		"createdDate": "2015-07-27 10:52:38",
		"totalOrganizations": 11,
		"totalTours": 6,
		"totalDays": 191,
		"totalCountries": 18,
		"totalStates": 51,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1402,
		"schedulePercent": 0.59171,
		"totalGuests": 523,
		"guestPercent": 0.699245,
		"mostGuestDate": "2023-09-09 00:00:00",
		"mostGuestName": "Riverside Municipal Auditorium",
		"mostGuestCity": "Riverside",
		"mostGuestAmount": 33
	},
	{
		"id": "90e047f",
		"email": "deedapar@hotmail.com",
		"name": "Amanda",
		"createdDate": "2018-01-02 19:40:28",
		"totalOrganizations": 7,
		"totalTours": 4,
		"totalDays": 107,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 430,
		"schedulePercent": 0.268176,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c6cace7",
		"email": "Sue@republicmedia.net",
		"name": "Sue",
		"createdDate": "2017-09-05 23:54:01",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 127,
		"totalCountries": 12,
		"totalStates": 30,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 929,
		"schedulePercent": 0.459409,
		"totalGuests": 387,
		"guestPercent": 0.647596,
		"mostGuestDate": "2023-07-16 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 75
	},
	{
		"id": "eb85894",
		"email": "mgmt@eggymusic.com",
		"name": "Email",
		"createdDate": "2021-09-15 00:12:00",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 721,
		"totalCountries": 1,
		"totalStates": 32,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 647,
		"schedulePercent": 0.361806,
		"totalGuests": 213,
		"guestPercent": 0.558866,
		"mostGuestDate": "2023-08-05 00:00:00",
		"mostGuestName": "The Cutting Room",
		"mostGuestCity": "New York",
		"mostGuestAmount": 40
	},
	{
		"id": "617e1a1",
		"email": "JRSmith@sjcfl.us",
		"name": "John",
		"createdDate": "2022-08-03 15:46:35",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 231,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 229,
		"eventPercent": 0.847305,
		"totalScheduleItems": 2336,
		"schedulePercent": 0.771818,
		"totalGuests": 47,
		"guestPercent": 0.417031,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "St. Augustine Amphitheatre",
		"mostGuestCity": "St. Augustine",
		"mostGuestAmount": 18
	},
	{
		"id": "f7c6335",
		"email": "charlotte@powerhousemgmt.co",
		"name": "Charlotte",
		"createdDate": "2022-02-24 03:59:58",
		"totalOrganizations": 11,
		"totalTours": 36,
		"totalDays": 481,
		"totalCountries": 12,
		"totalStates": 25,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 2413,
		"schedulePercent": 0.781751,
		"totalGuests": 1674,
		"guestPercent": 0.909946,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "The SSE Arena, Wembley",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 226
	},
	{
		"id": "2968452",
		"email": "maite.waag@outlook.com",
		"name": "Ma&#239;t&#233; ",
		"createdDate": "2023-10-26 13:33:25",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 50,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 115,
		"schedulePercent": 0.0993246,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4953204",
		"email": "luizcipher@aol.com",
		"name": "Jens",
		"createdDate": "2022-06-20 17:05:22",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 176,
		"totalCountries": 20,
		"totalStates": 53,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 1708,
		"schedulePercent": 0.666932,
		"totalGuests": 581,
		"guestPercent": 0.719243,
		"mostGuestDate": "2023-04-02 00:00:00",
		"mostGuestName": "Slaktkyrkan",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 42
	},
	{
		"id": "ddfd96d",
		"email": "andrewacrosbie@hotmail.com",
		"name": "Andrew",
		"createdDate": "2012-07-09 06:05:35",
		"totalOrganizations": 29,
		"totalTours": 32,
		"totalDays": 597,
		"totalCountries": 8,
		"totalStates": 47,
		"totalEvents": 214,
		"eventPercent": 0.829559,
		"totalScheduleItems": 1941,
		"schedulePercent": 0.70984,
		"totalGuests": 1278,
		"guestPercent": 0.868759,
		"mostGuestDate": "2023-05-19 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 194
	},
	{
		"id": "f59d7a3",
		"email": "production@livenation.com.au",
		"name": "Production ",
		"createdDate": "2018-05-09 01:01:07",
		"totalOrganizations": 467,
		"totalTours": 176,
		"totalDays": 4734,
		"totalCountries": 18,
		"totalStates": 71,
		"totalEvents": 1338,
		"eventPercent": 0.992849,
		"totalScheduleItems": 13050,
		"schedulePercent": 0.998808,
		"totalGuests": 1722,
		"guestPercent": 0.914713,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "Palais Theatre",
		"mostGuestCity": "St Kilda",
		"mostGuestAmount": 117
	},
	{
		"id": "1197d07",
		"email": "kpwtouring@gmail.com",
		"name": "Kyle",
		"createdDate": "2023-05-23 19:43:26",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 688,
		"totalCountries": 6,
		"totalStates": 48,
		"totalEvents": 281,
		"eventPercent": 0.903059,
		"totalScheduleItems": 4549,
		"schedulePercent": 0.946894,
		"totalGuests": 1449,
		"guestPercent": 0.887035,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Hollywood Casino Amphitheatre",
		"mostGuestCity": "Maryland Heights",
		"mostGuestAmount": 53
	},
	{
		"id": "9121bc9",
		"email": "tom.webster.au@gmail.com",
		"name": "Tom",
		"createdDate": "2015-06-30 02:15:02",
		"totalOrganizations": 81,
		"totalTours": 48,
		"totalDays": 1318,
		"totalCountries": 18,
		"totalStates": 76,
		"totalEvents": 426,
		"eventPercent": 0.963713,
		"totalScheduleItems": 4391,
		"schedulePercent": 0.940935,
		"totalGuests": 540,
		"guestPercent": 0.705867,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "385072b",
		"email": "karli@concertideas.com",
		"name": "Karli",
		"createdDate": "2023-02-22 16:49:23",
		"totalOrganizations": 4,
		"totalTours": 13,
		"totalDays": 238,
		"totalCountries": 3,
		"totalStates": 39,
		"totalEvents": 141,
		"eventPercent": 0.678321,
		"totalScheduleItems": 1245,
		"schedulePercent": 0.55092,
		"totalGuests": 32,
		"guestPercent": 0.392531,
		"mostGuestDate": "2023-03-19 00:00:00",
		"mostGuestName": "MilkBoy Philadelphia",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 7
	},
	{
		"id": "bcaca7b",
		"email": "damian@elasticentertainment.com.au",
		"name": "Damian",
		"createdDate": "2013-05-21 07:10:35",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 24,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 183,
		"schedulePercent": 0.140908,
		"totalGuests": 70,
		"guestPercent": 0.445636,
		"mostGuestDate": "2023-02-21 00:00:00",
		"mostGuestName": "The Hollywood Avondale",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 32
	},
	{
		"id": "ab19872",
		"email": "talenarose@googlemail.com",
		"name": "Talena ",
		"createdDate": "2018-06-14 15:49:26",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 547,
		"totalCountries": 13,
		"totalStates": 50,
		"totalEvents": 198,
		"eventPercent": 0.802543,
		"totalScheduleItems": 2908,
		"schedulePercent": 0.843332,
		"totalGuests": 512,
		"guestPercent": 0.695934,
		"mostGuestDate": "2023-02-19 00:00:00",
		"mostGuestName": "City National Grove of Anaheim",
		"mostGuestCity": "Anaheim",
		"mostGuestAmount": 57
	},
	{
		"id": "40c8ca5",
		"email": "audreyfinney@me.com",
		"name": "Audrey",
		"createdDate": "2019-07-24 23:40:28",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 224,
		"totalCountries": 4,
		"totalStates": 36,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 1227,
		"schedulePercent": 0.547212,
		"totalGuests": 623,
		"guestPercent": 0.732353,
		"mostGuestDate": "2023-06-19 00:00:00",
		"mostGuestName": "Moore Theatre",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 133
	},
	{
		"id": "3cb646a",
		"email": "schloss@concretemgmt.com",
		"name": "Stephen",
		"createdDate": "2018-05-14 17:29:38",
		"totalOrganizations": 8,
		"totalTours": 11,
		"totalDays": 850,
		"totalCountries": 16,
		"totalStates": 35,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 1235,
		"schedulePercent": 0.548934,
		"totalGuests": 304,
		"guestPercent": 0.605218,
		"mostGuestDate": "2023-04-27 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 189
	},
	{
		"id": "f4d6f6f",
		"email": "gregg.goldman@ticketmaster.com",
		"name": "gregg",
		"createdDate": "2019-09-05 04:44:13",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 30,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 81,
		"schedulePercent": 0.075884,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-08-01 00:00:00",
		"mostGuestName": "Madison Square Garden",
		"mostGuestCity": "New York",
		"mostGuestAmount": 1
	},
	{
		"id": "93ac0db",
		"email": "vanhaelter.a@gmail.com",
		"name": "Adrien",
		"createdDate": "2019-09-17 12:55:56",
		"totalOrganizations": 6,
		"totalTours": 15,
		"totalDays": 298,
		"totalCountries": 15,
		"totalStates": 39,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 2221,
		"schedulePercent": 0.75394,
		"totalGuests": 1040,
		"guestPercent": 0.830883,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Annexet",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 155
	},
	{
		"id": "081fcbe",
		"email": "harris.justin84@gmail.com",
		"name": "Justin",
		"createdDate": "2013-07-12 00:29:11",
		"totalOrganizations": 5,
		"totalTours": 21,
		"totalDays": 482,
		"totalCountries": 14,
		"totalStates": 57,
		"totalEvents": 195,
		"eventPercent": 0.796318,
		"totalScheduleItems": 3183,
		"schedulePercent": 0.868362,
		"totalGuests": 710,
		"guestPercent": 0.755132,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Firstbank Amphitheater",
		"mostGuestCity": "Franklin",
		"mostGuestAmount": 47
	},
	{
		"id": "c271ca9",
		"email": "kea@spinningtopmusic.com",
		"name": "Kea ",
		"createdDate": "2016-05-03 02:34:26",
		"totalOrganizations": 14,
		"totalTours": 40,
		"totalDays": 782,
		"totalCountries": 4,
		"totalStates": 26,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 1251,
		"schedulePercent": 0.552774,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "Vinyl",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 1
	},
	{
		"id": "85b477c",
		"email": "jayden@augustavenue.co.nz",
		"name": "Jayden",
		"createdDate": "2019-08-28 06:58:25",
		"totalOrganizations": 8,
		"totalTours": 13,
		"totalDays": 102,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 570,
		"schedulePercent": 0.327771,
		"totalGuests": 220,
		"guestPercent": 0.563237,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "The Tuning Fork",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 117
	},
	{
		"id": "974c7d5",
		"email": "brodytours@gmail.com",
		"name": "Aaron",
		"createdDate": "2012-08-28 21:23:54",
		"totalOrganizations": 11,
		"totalTours": 10,
		"totalDays": 647,
		"totalCountries": 11,
		"totalStates": 63,
		"totalEvents": 217,
		"eventPercent": 0.833267,
		"totalScheduleItems": 3050,
		"schedulePercent": 0.855913,
		"totalGuests": 2397,
		"guestPercent": 0.950735,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Kings Theatre",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 88
	},
	{
		"id": "b8ab07b",
		"email": "abigail@cardinalartists.com",
		"name": "Abigail",
		"createdDate": "2018-02-10 19:10:46",
		"totalOrganizations": 30,
		"totalTours": 13,
		"totalDays": 203,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 287,
		"schedulePercent": 0.201695,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "34dabb2",
		"email": "faroux.regie@gmail.com",
		"name": "Fran&#231;ois ",
		"createdDate": "2022-03-30 19:24:52",
		"totalOrganizations": 6,
		"totalTours": 18,
		"totalDays": 638,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 190,
		"eventPercent": 0.788505,
		"totalScheduleItems": 2547,
		"schedulePercent": 0.798702,
		"totalGuests": 31,
		"guestPercent": 0.390942,
		"mostGuestDate": "2023-11-21 00:00:00",
		"mostGuestName": "Cirque Royal",
		"mostGuestCity": "Bruxelles",
		"mostGuestAmount": 17
	},
	{
		"id": "5f4072d",
		"email": "j2sound@me.com",
		"name": "Justin",
		"createdDate": "2010-05-03 22:17:32",
		"totalOrganizations": 13,
		"totalTours": 7,
		"totalDays": 343,
		"totalCountries": 9,
		"totalStates": 32,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 952,
		"schedulePercent": 0.466163,
		"totalGuests": 101,
		"guestPercent": 0.474904,
		"mostGuestDate": "2023-08-23 00:00:00",
		"mostGuestName": "House of Blues Anaheim",
		"mostGuestCity": "Anaheim",
		"mostGuestAmount": 47
	},
	{
		"id": "c232062",
		"email": "ethan.ullman@millsentertainment.com",
		"name": "Ethan",
		"createdDate": "2018-02-06 19:30:13",
		"totalOrganizations": 26,
		"totalTours": 4,
		"totalDays": 459,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 847,
		"schedulePercent": 0.436631,
		"totalGuests": 211,
		"guestPercent": 0.558204,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Proctors",
		"mostGuestCity": "Schenectady",
		"mostGuestAmount": 40
	},
	{
		"id": "411eb0b",
		"email": "ywildan@gmail.com",
		"name": "Wild",
		"createdDate": "2022-04-18 18:05:33",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 1128,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 190,
		"eventPercent": 0.788505,
		"totalScheduleItems": 276,
		"schedulePercent": 0.196001,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "68205c1",
		"email": "aloggan1@gmail.com",
		"name": "Alicia",
		"createdDate": "2011-08-27 02:10:37",
		"totalOrganizations": 18,
		"totalTours": 8,
		"totalDays": 238,
		"totalCountries": 6,
		"totalStates": 21,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 2685,
		"schedulePercent": 0.817905,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "41239fb",
		"email": "info@stmusicgroup.com",
		"name": "Antonio",
		"createdDate": "2019-06-12 20:52:44",
		"totalOrganizations": 48,
		"totalTours": 10,
		"totalDays": 322,
		"totalCountries": 9,
		"totalStates": 69,
		"totalEvents": 163,
		"eventPercent": 0.735134,
		"totalScheduleItems": 2345,
		"schedulePercent": 0.773408,
		"totalGuests": 883,
		"guestPercent": 0.795126,
		"mostGuestDate": "2023-02-19 00:00:00",
		"mostGuestName": "James L. Knight Center",
		"mostGuestCity": "Miami",
		"mostGuestAmount": 60
	},
	{
		"id": "3f5f13c",
		"email": "keith@keithalbrizzi.com",
		"name": "Keith",
		"createdDate": "2014-07-02 18:55:55",
		"totalOrganizations": 17,
		"totalTours": 15,
		"totalDays": 1434,
		"totalCountries": 22,
		"totalStates": 74,
		"totalEvents": 248,
		"eventPercent": 0.869554,
		"totalScheduleItems": 5278,
		"schedulePercent": 0.96954,
		"totalGuests": 317,
		"guestPercent": 0.612502,
		"mostGuestDate": "2023-07-05 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 57
	},
	{
		"id": "750fdac",
		"email": "gala.santos@gmail.com",
		"name": "Gala",
		"createdDate": "2017-05-24 13:16:25",
		"totalOrganizations": 12,
		"totalTours": 22,
		"totalDays": 1308,
		"totalCountries": 19,
		"totalStates": 74,
		"totalEvents": 517,
		"eventPercent": 0.976162,
		"totalScheduleItems": 6717,
		"schedulePercent": 0.987022,
		"totalGuests": 227,
		"guestPercent": 0.567474,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "Madison Square Garden",
		"mostGuestCity": "New York",
		"mostGuestAmount": 22
	},
	{
		"id": "723696b",
		"email": "alejandra@qprime.com",
		"name": "Alejandra",
		"createdDate": "2022-08-09 16:00:16",
		"totalOrganizations": 2,
		"totalTours": 7,
		"totalDays": 195,
		"totalCountries": 8,
		"totalStates": 34,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 847,
		"schedulePercent": 0.436631,
		"totalGuests": 654,
		"guestPercent": 0.740299,
		"mostGuestDate": "2023-09-27 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 80
	},
	{
		"id": "cf0578b",
		"email": "WarddavisTM@GMAIL.COM",
		"name": "James",
		"createdDate": "2023-03-12 13:34:41",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 87,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 433,
		"schedulePercent": 0.269766,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-05-27 00:00:00",
		"mostGuestName": "Breaker's Marina",
		"mostGuestCity": "Buchanan",
		"mostGuestAmount": 2
	},
	{
		"id": "799af5b",
		"email": "Jakestumph@gmail.com",
		"name": "Jake",
		"createdDate": "2022-11-24 04:05:22",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 473,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 668,
		"schedulePercent": 0.368296,
		"totalGuests": 970,
		"guestPercent": 0.817375,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Forest Hills Stadium",
		"mostGuestCity": "Forest Hills",
		"mostGuestAmount": 191
	},
	{
		"id": "4e50b04",
		"email": "manderson@manderson.co",
		"name": "Matt",
		"createdDate": "2014-09-30 23:35:43",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 506,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 2801,
		"schedulePercent": 0.832473,
		"totalGuests": 1774,
		"guestPercent": 0.918554,
		"mostGuestDate": "2023-05-28 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 121
	},
	{
		"id": "b86173a",
		"email": "grahamiain@mac.com",
		"name": "Iain",
		"createdDate": "2022-02-07 16:28:12",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 943,
		"totalCountries": 8,
		"totalStates": 40,
		"totalEvents": 145,
		"eventPercent": 0.688518,
		"totalScheduleItems": 1224,
		"schedulePercent": 0.54655,
		"totalGuests": 237,
		"guestPercent": 0.571712,
		"mostGuestDate": "2023-06-14 00:00:00",
		"mostGuestName": "TD Music Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 65
	},
	{
		"id": "186d769",
		"email": "russell@fbproductions.net",
		"name": "Russell ",
		"createdDate": "2023-04-10 14:48:52",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 358,
		"totalCountries": 6,
		"totalStates": 33,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 1185,
		"schedulePercent": 0.535955,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "33bf01a",
		"email": "Tibcookie@yahoo.com",
		"name": "Glo",
		"createdDate": "2022-08-11 14:31:41",
		"totalOrganizations": 3,
		"totalTours": 19,
		"totalDays": 558,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 1,
		"eventPercent": 0.00662164,
		"totalScheduleItems": 549,
		"schedulePercent": 0.320355,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f9d1494",
		"email": "nickevanslovesyou@gmail.com",
		"name": "Nick",
		"createdDate": "2017-05-30 02:23:09",
		"totalOrganizations": 11,
		"totalTours": 13,
		"totalDays": 361,
		"totalCountries": 3,
		"totalStates": 41,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 1793,
		"schedulePercent": 0.683751,
		"totalGuests": 573,
		"guestPercent": 0.717918,
		"mostGuestDate": "2023-11-21 00:00:00",
		"mostGuestName": "Racket",
		"mostGuestCity": "NYC",
		"mostGuestAmount": 59
	},
	{
		"id": "f62552b",
		"email": "marckinchen@gmail.com",
		"name": "Marc",
		"createdDate": "2022-06-22 18:18:08",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 252,
		"totalCountries": 8,
		"totalStates": 30,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 651,
		"schedulePercent": 0.362601,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2a1e9e9",
		"email": "courtney@thetempus.co",
		"name": "Courtney",
		"createdDate": "2017-12-14 19:32:10",
		"totalOrganizations": 8,
		"totalTours": 17,
		"totalDays": 875,
		"totalCountries": 12,
		"totalStates": 57,
		"totalEvents": 346,
		"eventPercent": 0.939875,
		"totalScheduleItems": 5540,
		"schedulePercent": 0.973911,
		"totalGuests": 2697,
		"guestPercent": 0.960667,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Hard Rock Live",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 221
	},
	{
		"id": "735d9b8",
		"email": "dimartino.jn@gmail.com",
		"name": "Julia ",
		"createdDate": "2021-10-15 16:33:53",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 175,
		"totalCountries": 12,
		"totalStates": 47,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 1327,
		"schedulePercent": 0.573831,
		"totalGuests": 328,
		"guestPercent": 0.617667,
		"mostGuestDate": "2023-01-15 00:00:00",
		"mostGuestName": "Electric Brixton",
		"mostGuestCity": "London",
		"mostGuestAmount": 45
	},
	{
		"id": "7868aee",
		"email": "dan@fdfbtouring.com",
		"name": "Dan",
		"createdDate": "2017-04-18 09:36:56",
		"totalOrganizations": 19,
		"totalTours": 12,
		"totalDays": 1258,
		"totalCountries": 21,
		"totalStates": 70,
		"totalEvents": 194,
		"eventPercent": 0.794597,
		"totalScheduleItems": 2795,
		"schedulePercent": 0.831943,
		"totalGuests": 651,
		"guestPercent": 0.73977,
		"mostGuestDate": "2023-05-28 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 40
	},
	{
		"id": "6d8ad5d",
		"email": "iowawong@mac.com",
		"name": "Iowa",
		"createdDate": "2016-11-13 23:47:35",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 673,
		"totalCountries": 10,
		"totalStates": 60,
		"totalEvents": 272,
		"eventPercent": 0.895643,
		"totalScheduleItems": 5076,
		"schedulePercent": 0.963846,
		"totalGuests": 2527,
		"guestPercent": 0.955105,
		"mostGuestDate": "2023-06-28 00:00:00",
		"mostGuestName": "Rogers Arena",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 178
	},
	{
		"id": "1780ac0",
		"email": "hanford.pittman@icloud.com",
		"name": "Hanford",
		"createdDate": "2023-05-19 03:22:59",
		"totalOrganizations": 10,
		"totalTours": 11,
		"totalDays": 318,
		"totalCountries": 8,
		"totalStates": 24,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 1221,
		"schedulePercent": 0.545491,
		"totalGuests": 956,
		"guestPercent": 0.814727,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Gorge Amphitheatre",
		"mostGuestCity": "George",
		"mostGuestAmount": 205
	},
	{
		"id": "18ca899",
		"email": "jesspricefilm@gmail.com",
		"name": "Jessica",
		"createdDate": "2018-03-20 15:43:16",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 185,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 1618,
		"schedulePercent": 0.646272,
		"totalGuests": 598,
		"guestPercent": 0.725599,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "House of Blues Orlando",
		"mostGuestCity": "Lake Buena Vista",
		"mostGuestAmount": 81
	},
	{
		"id": "2cb6558",
		"email": "lmartines@aol.com",
		"name": "Les",
		"createdDate": "2016-08-05 22:49:28",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 366,
		"totalCountries": 3,
		"totalStates": 37,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 1310,
		"schedulePercent": 0.568799,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f1fca72",
		"email": "hard2handle@me.com",
		"name": "Merrie",
		"createdDate": "2016-07-10 22:01:41",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 468,
		"totalCountries": 3,
		"totalStates": 40,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 2890,
		"schedulePercent": 0.840418,
		"totalGuests": 2685,
		"guestPercent": 0.959873,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "Northwell Health at Jones Beach Theater",
		"mostGuestCity": "Wantagh",
		"mostGuestAmount": 109
	},
	{
		"id": "944be9a",
		"email": "sammy.lizotte@me.com",
		"name": "Sammy",
		"createdDate": "2019-08-25 10:42:06",
		"totalOrganizations": 4,
		"totalTours": 22,
		"totalDays": 277,
		"totalCountries": 6,
		"totalStates": 13,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 583,
		"schedulePercent": 0.333068,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5151f90",
		"email": "carlopolli@gmail.com",
		"name": "Carlo",
		"createdDate": "2016-03-15 17:57:51",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 163,
		"totalCountries": 11,
		"totalStates": 37,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 945,
		"schedulePercent": 0.464045,
		"totalGuests": 329,
		"guestPercent": 0.618064,
		"mostGuestDate": "2023-06-27 00:00:00",
		"mostGuestName": "Kings Theatre",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 51
	},
	{
		"id": "7102be9",
		"email": "david@davidandrewloy.com",
		"name": "David",
		"createdDate": "2015-08-20 15:49:14",
		"totalOrganizations": 14,
		"totalTours": 15,
		"totalDays": 1879,
		"totalCountries": 9,
		"totalStates": 56,
		"totalEvents": 286,
		"eventPercent": 0.906502,
		"totalScheduleItems": 5303,
		"schedulePercent": 0.969938,
		"totalGuests": 1493,
		"guestPercent": 0.893392,
		"mostGuestDate": "2023-05-18 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 60
	},
	{
		"id": "050d810",
		"email": "edenmyall@live.co.uk",
		"name": "Eden",
		"createdDate": "2018-02-27 23:34:24",
		"totalOrganizations": 6,
		"totalTours": 18,
		"totalDays": 477,
		"totalCountries": 18,
		"totalStates": 48,
		"totalEvents": 220,
		"eventPercent": 0.83671,
		"totalScheduleItems": 3824,
		"schedulePercent": 0.913257,
		"totalGuests": 809,
		"guestPercent": 0.778175,
		"mostGuestDate": "2023-04-06 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 106
	},
	{
		"id": "30befd8",
		"email": "mikey@darkside-production.com",
		"name": "MICHAL",
		"createdDate": "2015-11-30 09:39:22",
		"totalOrganizations": 5,
		"totalTours": 16,
		"totalDays": 334,
		"totalCountries": 8,
		"totalStates": 45,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 1956,
		"schedulePercent": 0.713018,
		"totalGuests": 827,
		"guestPercent": 0.782943,
		"mostGuestDate": "2023-06-19 00:00:00",
		"mostGuestName": "Moore Theatre",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 133
	},
	{
		"id": "d9af944",
		"email": "saxbypullen@gmail.com",
		"name": "Thomas",
		"createdDate": "2019-08-23 10:17:49",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 285,
		"totalCountries": 12,
		"totalStates": 44,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 1431,
		"schedulePercent": 0.599523,
		"totalGuests": 191,
		"guestPercent": 0.543769,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "",
		"mostGuestAmount": 40
	},
	{
		"id": "07e2de5",
		"email": "brettbachemin@me.com",
		"name": "Brett",
		"createdDate": "2013-09-21 23:41:55",
		"totalOrganizations": 9,
		"totalTours": 15,
		"totalDays": 606,
		"totalCountries": 14,
		"totalStates": 60,
		"totalEvents": 254,
		"eventPercent": 0.879619,
		"totalScheduleItems": 3745,
		"schedulePercent": 0.909284,
		"totalGuests": 675,
		"guestPercent": 0.745597,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Arena Birmingham",
		"mostGuestCity": "Birmingham",
		"mostGuestAmount": 209
	},
	{
		"id": "145b34a",
		"email": "rs@loyaltmgmt.com",
		"name": "Rich",
		"createdDate": "2015-03-11 01:12:27",
		"totalOrganizations": 13,
		"totalTours": 5,
		"totalDays": 521,
		"totalCountries": 16,
		"totalStates": 45,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 2174,
		"schedulePercent": 0.745861,
		"totalGuests": 886,
		"guestPercent": 0.795789,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 158
	},
	{
		"id": "12dac16",
		"email": "zackAmarshall@gmail.com",
		"name": "Zack",
		"createdDate": "2014-01-21 11:27:01",
		"totalOrganizations": 14,
		"totalTours": 35,
		"totalDays": 299,
		"totalCountries": 4,
		"totalStates": 13,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 95,
		"guestPercent": 0.470269,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "Sidney Myer Music Bowl",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 25
	},
	{
		"id": "aa79a83",
		"email": "eric@ejhproductions.com",
		"name": "Eric",
		"createdDate": "2019-07-26 19:46:13",
		"totalOrganizations": 10,
		"totalTours": 11,
		"totalDays": 734,
		"totalCountries": 12,
		"totalStates": 53,
		"totalEvents": 241,
		"eventPercent": 0.862667,
		"totalScheduleItems": 3285,
		"schedulePercent": 0.87644,
		"totalGuests": 1229,
		"guestPercent": 0.862402,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 293
	},
	{
		"id": "e86e986",
		"email": "fedeleal@me.com",
		"name": "Federico ",
		"createdDate": "2017-02-15 00:26:32",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 338,
		"totalCountries": 4,
		"totalStates": 19,
		"totalEvents": 220,
		"eventPercent": 0.83671,
		"totalScheduleItems": 1095,
		"schedulePercent": 0.510661,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1221535",
		"email": "touring@farlandcreative.com",
		"name": "Far Land",
		"createdDate": "2020-12-19 03:57:00",
		"totalOrganizations": 7,
		"totalTours": 18,
		"totalDays": 165,
		"totalCountries": 5,
		"totalStates": 15,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 794,
		"schedulePercent": 0.41809,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-11-15 00:00:00",
		"mostGuestName": "Maida Vale",
		"mostGuestCity": "London",
		"mostGuestAmount": 4
	},
	{
		"id": "6c25434",
		"email": "kelsey@globalproductionpartners.com",
		"name": "Kelsey",
		"createdDate": "2022-11-15 11:38:45",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 109,
		"totalCountries": 9,
		"totalStates": 19,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 799,
		"schedulePercent": 0.420209,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c0c39b2",
		"email": "jamicrockett@me.com",
		"name": "Jami",
		"createdDate": "2019-12-30 21:47:16",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 191,
		"totalCountries": 1,
		"totalStates": 27,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 1142,
		"schedulePercent": 0.524169,
		"totalGuests": 1006,
		"guestPercent": 0.825189,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 293
	},
	{
		"id": "1418c10",
		"email": "joelforman@mac.com",
		"name": "Joel",
		"createdDate": "2009-12-09 21:36:04",
		"totalOrganizations": 23,
		"totalTours": 13,
		"totalDays": 476,
		"totalCountries": 13,
		"totalStates": 34,
		"totalEvents": 188,
		"eventPercent": 0.785856,
		"totalScheduleItems": 3490,
		"schedulePercent": 0.893259,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5973cc9",
		"email": "Karen@alist.com.au",
		"name": "Karen",
		"createdDate": "2016-04-18 15:49:42",
		"totalOrganizations": 33,
		"totalTours": 59,
		"totalDays": 1655,
		"totalCountries": 7,
		"totalStates": 22,
		"totalEvents": 1021,
		"eventPercent": 0.990332,
		"totalScheduleItems": 4501,
		"schedulePercent": 0.94504,
		"totalGuests": 28,
		"guestPercent": 0.386306,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Nautilus Arts Centre",
		"mostGuestCity": "Port Lincoln",
		"mostGuestAmount": 15
	},
	{
		"id": "a5ed3f0",
		"email": "KevinF4107@me.com",
		"name": "Kevin",
		"createdDate": "2015-10-13 21:16:38",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 602,
		"schedulePercent": 0.342471,
		"totalGuests": 495,
		"guestPercent": 0.689975,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Julian B Lane Riverfront Park",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 92
	},
	{
		"id": "8d6141d",
		"email": "carolyn@snellycat.com",
		"name": "Carolyn",
		"createdDate": "2010-01-06 00:22:59",
		"totalOrganizations": 13,
		"totalTours": 6,
		"totalDays": 250,
		"totalCountries": 7,
		"totalStates": 36,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1687,
		"schedulePercent": 0.661899,
		"totalGuests": 2033,
		"guestPercent": 0.93524,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Gorge Amphitheatre",
		"mostGuestCity": "George",
		"mostGuestAmount": 205
	},
	{
		"id": "64fe037",
		"email": "viktoria@twisted-talent.com",
		"name": "Viktoria",
		"createdDate": "2022-08-16 13:04:49",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 195,
		"totalCountries": 19,
		"totalStates": 33,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 1450,
		"schedulePercent": 0.603629,
		"totalGuests": 1002,
		"guestPercent": 0.824262,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "AFAS Live",
		"mostGuestCity": "Amsterdam",
		"mostGuestAmount": 74
	},
	{
		"id": "9c1a572",
		"email": "patrick@wtlr.com.au",
		"name": "Patrick",
		"createdDate": "2015-11-04 02:16:07",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 412,
		"totalCountries": 18,
		"totalStates": 53,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 848,
		"schedulePercent": 0.437558,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0bc66c7",
		"email": "brettdbrock@gmail.com",
		"name": "Brett",
		"createdDate": "2017-10-19 16:11:47",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 946,
		"totalCountries": 10,
		"totalStates": 60,
		"totalEvents": 346,
		"eventPercent": 0.939875,
		"totalScheduleItems": 3248,
		"schedulePercent": 0.873924,
		"totalGuests": 1052,
		"guestPercent": 0.833135,
		"mostGuestDate": "2023-01-04 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 94
	},
	{
		"id": "fab1be0",
		"email": "klcn678@gmail.com",
		"name": "Karen",
		"createdDate": "2016-09-05 21:01:31",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 208,
		"totalCountries": 4,
		"totalStates": 26,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 1459,
		"schedulePercent": 0.605218,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "Hard Rock Live",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 0
	},
	{
		"id": "7fb12e3",
		"email": "aled@al-touring.com",
		"name": "Aled ",
		"createdDate": "2019-04-27 12:25:16",
		"totalOrganizations": 13,
		"totalTours": 22,
		"totalDays": 987,
		"totalCountries": 4,
		"totalStates": 7,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 2045,
		"schedulePercent": 0.726659,
		"totalGuests": 2300,
		"guestPercent": 0.947821,
		"mostGuestDate": "2023-05-17 00:00:00",
		"mostGuestName": "Royal Albert Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 155
	},
	{
		"id": "e5c34ba",
		"email": "adamroffax@gmail.com",
		"name": "Adam",
		"createdDate": "2018-06-03 01:31:13",
		"totalOrganizations": 6,
		"totalTours": 1,
		"totalDays": 291,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 651,
		"schedulePercent": 0.362601,
		"totalGuests": 344,
		"guestPercent": 0.624685,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Manning Bar",
		"mostGuestCity": "Camperdown",
		"mostGuestAmount": 50
	},
	{
		"id": "04381c4",
		"email": "haley@hnmcreative.com",
		"name": "Haley",
		"createdDate": "2020-02-28 20:24:27",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 175,
		"totalCountries": 10,
		"totalStates": 38,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1051,
		"schedulePercent": 0.497682,
		"totalGuests": 481,
		"guestPercent": 0.683751,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "The Regent Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 47
	},
	{
		"id": "65df1df",
		"email": "courtneymattina@gmail.com",
		"name": "Courtney",
		"createdDate": "2023-09-18 22:54:20",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 83,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 1034,
		"schedulePercent": 0.49318,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "deb95c2",
		"email": "Inferimetal@gmail.com",
		"name": "Malcolm",
		"createdDate": "2019-10-14 17:56:25",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 99,
		"totalCountries": 2,
		"totalStates": 37,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 611,
		"schedulePercent": 0.346709,
		"totalGuests": 811,
		"guestPercent": 0.779102,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "1720",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 134
	},
	{
		"id": "d8bfa7a",
		"email": "philippe@bandwerkmusic.com",
		"name": "Philippe",
		"createdDate": "2021-07-31 20:01:30",
		"totalOrganizations": 22,
		"totalTours": 19,
		"totalDays": 325,
		"totalCountries": 10,
		"totalStates": 40,
		"totalEvents": 325,
		"eventPercent": 0.928751,
		"totalScheduleItems": 1740,
		"schedulePercent": 0.672626,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-08-05 00:00:00",
		"mostGuestName": "Suikerrock",
		"mostGuestCity": "Tienen",
		"mostGuestAmount": 1
	},
	{
		"id": "4f513bb",
		"email": "hillary@salxco.com",
		"name": "Hillary",
		"createdDate": "2021-11-01 22:44:28",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 922,
		"totalCountries": 18,
		"totalStates": 51,
		"totalEvents": 389,
		"eventPercent": 0.954311,
		"totalScheduleItems": 4505,
		"schedulePercent": 0.945305,
		"totalGuests": 6934,
		"guestPercent": 0.997616,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Wembley Stadium",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 625
	},
	{
		"id": "a0a1da8",
		"email": "b.henbest@icloud.com",
		"name": "Brandon",
		"createdDate": "2016-01-16 08:57:12",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 544,
		"totalCountries": 4,
		"totalStates": 49,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 1660,
		"schedulePercent": 0.655675,
		"totalGuests": 858,
		"guestPercent": 0.789564,
		"mostGuestDate": "2023-02-04 00:00:00",
		"mostGuestName": "The SSE Arena, Wembley",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 56
	},
	{
		"id": "bd05106",
		"email": "catherine.bessette@musicor.quebecor.com",
		"name": "Catherine",
		"createdDate": "2023-07-06 20:05:35",
		"totalOrganizations": 35,
		"totalTours": 14,
		"totalDays": 196,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 182,
		"eventPercent": 0.773408,
		"totalScheduleItems": 872,
		"schedulePercent": 0.443915,
		"totalGuests": 1713,
		"guestPercent": 0.914051,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "ThÃ©Ã¢tre St-Denis",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 204
	},
	{
		"id": "75c0696",
		"email": "sethdg@gmail.com",
		"name": "Seth",
		"createdDate": "2023-02-08 14:25:40",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 395,
		"totalCountries": 17,
		"totalStates": 53,
		"totalEvents": 182,
		"eventPercent": 0.773408,
		"totalScheduleItems": 885,
		"schedulePercent": 0.44802,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1cb57bd",
		"email": "andrewl@rnzb.org.nz",
		"name": "Andrew",
		"createdDate": "2014-05-08 04:49:34",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 226,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 1999,
		"schedulePercent": 0.718315,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9b9fcd5",
		"email": "santeri.koppelo@gmail.com",
		"name": "Santeri",
		"createdDate": "2018-06-13 20:13:32",
		"totalOrganizations": 7,
		"totalTours": 13,
		"totalDays": 263,
		"totalCountries": 14,
		"totalStates": 32,
		"totalEvents": 156,
		"eventPercent": 0.718051,
		"totalScheduleItems": 2045,
		"schedulePercent": 0.726659,
		"totalGuests": 1166,
		"guestPercent": 0.852205,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "Olympiahalle",
		"mostGuestCity": "MÃ¼nchen",
		"mostGuestAmount": 135
	},
	{
		"id": "3c857fc",
		"email": "mbrown@cityparksfoundation.org",
		"name": "Maralina",
		"createdDate": "2022-03-09 17:50:48",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a35b9ff",
		"email": "Elisabettalfonso@gmail.com",
		"name": "Elisabetta",
		"createdDate": "2015-04-21 17:47:30",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 456,
		"totalCountries": 12,
		"totalStates": 34,
		"totalEvents": 134,
		"eventPercent": 0.658721,
		"totalScheduleItems": 2070,
		"schedulePercent": 0.729705,
		"totalGuests": 192,
		"guestPercent": 0.544564,
		"mostGuestDate": "2023-03-23 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 56
	},
	{
		"id": "5125832",
		"email": "RAFA@DUARS.COM",
		"name": "Rafael",
		"createdDate": "2021-06-03 15:57:57",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 643,
		"totalCountries": 7,
		"totalStates": 39,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 1420,
		"schedulePercent": 0.596477,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "23553f4",
		"email": "jasono@esglobalsolutions.com",
		"name": "Jason",
		"createdDate": "2023-05-14 15:03:29",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 147,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 186,
		"schedulePercent": 0.143027,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0715e72",
		"email": "vanessa.reddin@gmail.com",
		"name": "Vanessa",
		"createdDate": "2018-06-02 02:16:36",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 778,
		"totalCountries": 5,
		"totalStates": 42,
		"totalEvents": 189,
		"eventPercent": 0.78718,
		"totalScheduleItems": 2637,
		"schedulePercent": 0.813005,
		"totalGuests": 2219,
		"guestPercent": 0.944776,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "Forest Hills Stadium",
		"mostGuestCity": "Forest Hills",
		"mostGuestAmount": 184
	},
	{
		"id": "998c177",
		"email": "jbarron211@gmail.com",
		"name": "Jeremy",
		"createdDate": "2015-10-13 14:19:16",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 221,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 553,
		"schedulePercent": 0.322871,
		"totalGuests": 83,
		"guestPercent": 0.45835,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "Agua Caliente Casino",
		"mostGuestCity": "Rancho Mirage",
		"mostGuestAmount": 22
	},
	{
		"id": "df456ec",
		"email": "mitchgeorge@mac.com",
		"name": "Mitchell",
		"createdDate": "2010-10-31 01:16:19",
		"totalOrganizations": 8,
		"totalTours": 7,
		"totalDays": 973,
		"totalCountries": 8,
		"totalStates": 41,
		"totalEvents": 145,
		"eventPercent": 0.688518,
		"totalScheduleItems": 948,
		"schedulePercent": 0.465104,
		"totalGuests": 96,
		"guestPercent": 0.471063,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Dos Equis Pavilion",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 13
	},
	{
		"id": "b7ff95a",
		"email": "bennie@interceptor.co.uk",
		"name": "Bennie",
		"createdDate": "2019-06-24 12:19:59",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 124,
		"totalCountries": 11,
		"totalStates": 21,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 717,
		"schedulePercent": 0.387233,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b7f8ca9",
		"email": "danny.peschke@livenation.de",
		"name": "Danny",
		"createdDate": "2022-09-15 07:28:24",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 54,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 690,
		"schedulePercent": 0.376771,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "03215d4",
		"email": "Niilo.keskilusa@gmail.com",
		"name": "Niilo",
		"createdDate": "2022-03-12 22:00:57",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 92,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 305,
		"schedulePercent": 0.210171,
		"totalGuests": 55,
		"guestPercent": 0.42842,
		"mostGuestDate": "2023-02-24 00:00:00",
		"mostGuestName": "Sorsapuistosali",
		"mostGuestCity": "Tampere",
		"mostGuestAmount": 21
	},
	{
		"id": "53a1d06",
		"email": "sam@pretiummanagement.co.uk",
		"name": "email",
		"createdDate": "2022-07-21 19:30:38",
		"totalOrganizations": 11,
		"totalTours": 23,
		"totalDays": 1337,
		"totalCountries": 17,
		"totalStates": 57,
		"totalEvents": 177,
		"eventPercent": 0.765594,
		"totalScheduleItems": 1903,
		"schedulePercent": 0.704675,
		"totalGuests": 164,
		"guestPercent": 0.526155,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Regent Theater DTLA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 46
	},
	{
		"id": "4edaafa",
		"email": "rottunrecordings@gmail.com",
		"name": "Brett",
		"createdDate": "2013-11-29 03:26:46",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 367,
		"totalCountries": 2,
		"totalStates": 17,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 598,
		"schedulePercent": 0.339955,
		"totalGuests": 57,
		"guestPercent": 0.430804,
		"mostGuestDate": "2023-02-09 00:00:00",
		"mostGuestName": "The Mission Ballroom",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 21
	},
	{
		"id": "4e49ef1",
		"email": "ktgleas32@gmail.com",
		"name": "Katherine",
		"createdDate": "2018-01-04 17:26:45",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 1035,
		"totalCountries": 6,
		"totalStates": 48,
		"totalEvents": 262,
		"eventPercent": 0.887962,
		"totalScheduleItems": 4138,
		"schedulePercent": 0.929943,
		"totalGuests": 1329,
		"guestPercent": 0.875381,
		"mostGuestDate": "2023-09-09 00:00:00",
		"mostGuestName": "The Midway",
		"mostGuestCity": "San Francisco",
		"mostGuestAmount": 87
	},
	{
		"id": "2fee57d",
		"email": "caikenaudiomgmt@gmail.com",
		"name": "Chason",
		"createdDate": "2021-07-26 16:46:25",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 214,
		"totalCountries": 4,
		"totalStates": 22,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 999,
		"schedulePercent": 0.484836,
		"totalGuests": 174,
		"guestPercent": 0.532115,
		"mostGuestDate": "2023-01-20 00:00:00",
		"mostGuestName": "Billy Bob's",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 57
	},
	{
		"id": "63d870d",
		"email": "db.ny@mac.com",
		"name": "Email",
		"createdDate": "2012-09-08 20:44:42",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 228,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 763,
		"schedulePercent": 0.406569,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4323e23",
		"email": "jazz@goldtoothinc.com",
		"name": "Brandon ",
		"createdDate": "2016-09-12 05:19:20",
		"totalOrganizations": 8,
		"totalTours": 7,
		"totalDays": 502,
		"totalCountries": 11,
		"totalStates": 41,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 939,
		"schedulePercent": 0.462323,
		"totalGuests": 767,
		"guestPercent": 0.768772,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "Nissan Stadium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 227
	},
	{
		"id": "4d6de86",
		"email": "bosma@poolhouserecords.com.au",
		"name": "Chris",
		"createdDate": "2019-01-15 03:18:38",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 111,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 281,
		"schedulePercent": 0.198384,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-03-23 00:00:00",
		"mostGuestName": "Miami Marketta",
		"mostGuestCity": "Miami",
		"mostGuestAmount": 1
	},
	{
		"id": "3a8efa8",
		"email": "247studiosltd@gmail.com",
		"name": "Raj",
		"createdDate": "2022-05-16 13:08:08",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 229,
		"totalCountries": 11,
		"totalStates": 22,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 361,
		"schedulePercent": 0.238511,
		"totalGuests": 101,
		"guestPercent": 0.474904,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 54
	},
	{
		"id": "b8a0d94",
		"email": "merch@cityparksfoundation.org",
		"name": "SummerStage",
		"createdDate": "2018-06-05 16:47:21",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0e2b5c1",
		"email": "marc@marcrogers.com",
		"name": "Marc",
		"createdDate": "2016-10-22 00:10:55",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 194,
		"schedulePercent": 0.146736,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7473256",
		"email": "nick@rcm.tv",
		"name": "Nick",
		"createdDate": "2023-04-09 17:09:54",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 114,
		"totalCountries": 11,
		"totalStates": 19,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 737,
		"schedulePercent": 0.394385,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "832fb70",
		"email": "mail@willnash.com",
		"name": "Will",
		"createdDate": "2022-10-07 20:58:00",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 118,
		"totalCountries": 3,
		"totalStates": 18,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 868,
		"schedulePercent": 0.442061,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "463a70c",
		"email": "justinmagaldi@gmail.com",
		"name": "Justin",
		"createdDate": "2015-08-27 14:45:22",
		"totalOrganizations": 14,
		"totalTours": 6,
		"totalDays": 484,
		"totalCountries": 4,
		"totalStates": 43,
		"totalEvents": 287,
		"eventPercent": 0.9069,
		"totalScheduleItems": 3618,
		"schedulePercent": 0.902397,
		"totalGuests": 3711,
		"guestPercent": 0.980003,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Gas South Arena",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 157
	},
	{
		"id": "831de88",
		"email": "rachael@jr-adventures.com",
		"name": "Sal ",
		"createdDate": "2011-05-04 17:21:17",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 129,
		"totalCountries": 2,
		"totalStates": 36,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 610,
		"schedulePercent": 0.345914,
		"totalGuests": 148,
		"guestPercent": 0.51225,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Bomhard Theater",
		"mostGuestCity": "Louisville",
		"mostGuestAmount": 17
	},
	{
		"id": "18e46a0",
		"email": "ms@markus-sorger.de",
		"name": "Markus",
		"createdDate": "2022-11-04 14:05:13",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 193,
		"totalCountries": 4,
		"totalStates": 20,
		"totalEvents": 166,
		"eventPercent": 0.74308,
		"totalScheduleItems": 1672,
		"schedulePercent": 0.658323,
		"totalGuests": 389,
		"guestPercent": 0.648656,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "Hauptkirche St. Petri 16:00",
		"mostGuestCity": "Hamburg",
		"mostGuestAmount": 100
	},
	{
		"id": "2ada125",
		"email": "Leejenksprofessional@gmail.com",
		"name": "Lee",
		"createdDate": "2016-10-13 00:36:23",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 62,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 503,
		"schedulePercent": 0.29996,
		"totalGuests": 379,
		"guestPercent": 0.64455,
		"mostGuestDate": "2023-03-23 00:00:00",
		"mostGuestName": "1720",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 51
	},
	{
		"id": "d88a19e",
		"email": "calebmartinez10@gmail.com",
		"name": "Caleb",
		"createdDate": "2022-08-11 23:19:42",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 32,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 225,
		"schedulePercent": 0.1666,
		"totalGuests": 214,
		"guestPercent": 0.559264,
		"mostGuestDate": "2023-03-16 00:00:00",
		"mostGuestName": "Mercury Lounge",
		"mostGuestCity": "New York",
		"mostGuestAmount": 24
	},
	{
		"id": "253578f",
		"email": "stephaniesuess14@gmail.com",
		"name": "Steph",
		"createdDate": "2018-06-27 13:58:08",
		"totalOrganizations": 14,
		"totalTours": 13,
		"totalDays": 390,
		"totalCountries": 10,
		"totalStates": 38,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 2660,
		"schedulePercent": 0.814859,
		"totalGuests": 1307,
		"guestPercent": 0.8726,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 104
	},
	{
		"id": "db5dd5b",
		"email": "jonas@showlab.no",
		"name": "Jonas",
		"createdDate": "2018-02-06 07:17:31",
		"totalOrganizations": 15,
		"totalTours": 10,
		"totalDays": 261,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 190,
		"eventPercent": 0.788505,
		"totalScheduleItems": 1501,
		"schedulePercent": 0.618196,
		"totalGuests": 125,
		"guestPercent": 0.494636,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Hvalstrandfestivalen",
		"mostGuestCity": "Nesbru",
		"mostGuestAmount": 25
	},
	{
		"id": "5a5e8dc",
		"email": "eileen@purefreedom.org",
		"name": "Eileen",
		"createdDate": "2013-07-15 15:33:03",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 207,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 1681,
		"schedulePercent": 0.660177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0c90baa",
		"email": "traftonjwelsh@gmail.com",
		"name": "Trafton",
		"createdDate": "2022-09-23 20:15:49",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 356,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 482,
		"schedulePercent": 0.289631,
		"totalGuests": 175,
		"guestPercent": 0.533439,
		"mostGuestDate": "2023-02-04 00:00:00",
		"mostGuestName": "Ogden Theatre",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 45
	},
	{
		"id": "548daed",
		"email": "Jonathan.m.salyer@gmail.com",
		"name": "Jonathan",
		"createdDate": "2022-03-11 19:42:03",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 285,
		"totalCountries": 1,
		"totalStates": 38,
		"totalEvents": 204,
		"eventPercent": 0.813005,
		"totalScheduleItems": 1456,
		"schedulePercent": 0.604556,
		"totalGuests": 154,
		"guestPercent": 0.51662,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Cottonwood Creek Church",
		"mostGuestCity": "Allen",
		"mostGuestAmount": 21
	},
	{
		"id": "a8fe981",
		"email": "dannywmarks@gmail.com",
		"name": "Danny",
		"createdDate": "2020-02-27 21:33:59",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 582,
		"totalCountries": 2,
		"totalStates": 41,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 1259,
		"schedulePercent": 0.554231,
		"totalGuests": 430,
		"guestPercent": 0.664548,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "Pompano Beach Amphitheatre",
		"mostGuestCity": "Pompano Beach",
		"mostGuestAmount": 39
	},
	{
		"id": "fd797d9",
		"email": "mitch@therelay.group",
		"name": "Mitch",
		"createdDate": "2017-08-18 19:42:04",
		"totalOrganizations": 11,
		"totalTours": 21,
		"totalDays": 1700,
		"totalCountries": 16,
		"totalStates": 70,
		"totalEvents": 418,
		"eventPercent": 0.962257,
		"totalScheduleItems": 6002,
		"schedulePercent": 0.980532,
		"totalGuests": 3652,
		"guestPercent": 0.97934,
		"mostGuestDate": "2023-10-03 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 104
	},
	{
		"id": "96c4f22",
		"email": "lucy@theplayse.com",
		"name": "Lucy",
		"createdDate": "2023-04-18 16:08:28",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 113,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 279,
		"schedulePercent": 0.19759,
		"totalGuests": 99,
		"guestPercent": 0.473845,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "El Rey Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 66
	},
	{
		"id": "7365199",
		"email": "Motherfolkmusic@gmail.com",
		"name": "Nathan",
		"createdDate": "2017-05-08 23:54:11",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 30,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 137,
		"schedulePercent": 0.111244,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d656d47",
		"email": "francisco@lasordera.com",
		"name": "Francisco",
		"createdDate": "2021-09-04 14:42:04",
		"totalOrganizations": 10,
		"totalTours": 4,
		"totalDays": 40,
		"totalCountries": 3,
		"totalStates": 3,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 169,
		"schedulePercent": 0.131903,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cb511bf",
		"email": "johnnyliar@icloud.com",
		"name": "John",
		"createdDate": "2018-07-26 22:34:57",
		"totalOrganizations": 14,
		"totalTours": 11,
		"totalDays": 471,
		"totalCountries": 9,
		"totalStates": 50,
		"totalEvents": 199,
		"eventPercent": 0.804264,
		"totalScheduleItems": 2162,
		"schedulePercent": 0.744537,
		"totalGuests": 586,
		"guestPercent": 0.720832,
		"mostGuestDate": "2023-06-01 00:00:00",
		"mostGuestName": "Troxy",
		"mostGuestCity": "London",
		"mostGuestAmount": 48
	},
	{
		"id": "9b7c1e1",
		"email": "jon@jonluton.com",
		"name": "Jon",
		"createdDate": "2015-11-18 10:49:43",
		"totalOrganizations": 23,
		"totalTours": 14,
		"totalDays": 1266,
		"totalCountries": 13,
		"totalStates": 59,
		"totalEvents": 432,
		"eventPercent": 0.96464,
		"totalScheduleItems": 3544,
		"schedulePercent": 0.896835,
		"totalGuests": 2092,
		"guestPercent": 0.939611,
		"mostGuestDate": "2023-11-29 00:00:00",
		"mostGuestName": "G Live",
		"mostGuestCity": "Guildford",
		"mostGuestAmount": 82
	},
	{
		"id": "c674ec1",
		"email": "bridgecitysinners@gmail.com",
		"name": "Jason",
		"createdDate": "2023-04-28 23:14:19",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 68,
		"totalCountries": 7,
		"totalStates": 32,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 306,
		"schedulePercent": 0.211098,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-09-24 00:00:00",
		"mostGuestName": "Underworld",
		"mostGuestCity": "London",
		"mostGuestAmount": 1
	},
	{
		"id": "80e4d93",
		"email": "produccion@mperine.com",
		"name": "ProducciÃ³n ",
		"createdDate": "2022-10-11 00:41:56",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 160,
		"totalCountries": 4,
		"totalStates": 17,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 642,
		"schedulePercent": 0.359423,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "aa11152",
		"email": "asomaproduction@gmail.com",
		"name": "Jesse",
		"createdDate": "2022-09-10 21:43:01",
		"totalOrganizations": 8,
		"totalTours": 15,
		"totalDays": 410,
		"totalCountries": 6,
		"totalStates": 52,
		"totalEvents": 148,
		"eventPercent": 0.696332,
		"totalScheduleItems": 1426,
		"schedulePercent": 0.598199,
		"totalGuests": 636,
		"guestPercent": 0.735399,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 61
	},
	{
		"id": "c30449b",
		"email": "Jhedge@pyrotekfx.com",
		"name": "Josh",
		"createdDate": "2017-08-30 15:07:46",
		"totalOrganizations": 17,
		"totalTours": 18,
		"totalDays": 1310,
		"totalCountries": 15,
		"totalStates": 64,
		"totalEvents": 427,
		"eventPercent": 0.963846,
		"totalScheduleItems": 7833,
		"schedulePercent": 0.993114,
		"totalGuests": 7264,
		"guestPercent": 0.998808,
		"mostGuestDate": "2023-07-03 00:00:00",
		"mostGuestName": "Banc of California Stadium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 219
	},
	{
		"id": "c16e00e",
		"email": "post@ma-cc.com",
		"name": "MACC",
		"createdDate": "2022-02-28 10:03:10",
		"totalOrganizations": 13,
		"totalTours": 12,
		"totalDays": 189,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 941,
		"schedulePercent": 0.462853,
		"totalGuests": 380,
		"guestPercent": 0.645477,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "Admiralspalast",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 30
	},
	{
		"id": "9e0bcfc",
		"email": "alongi.regina@gmail.com",
		"name": "Regina",
		"createdDate": "2018-03-07 17:50:32",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 295,
		"totalCountries": 4,
		"totalStates": 21,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 1010,
		"schedulePercent": 0.488147,
		"totalGuests": 235,
		"guestPercent": 0.57052,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "House of Blues",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 36
	},
	{
		"id": "8c50a66",
		"email": "elodie.d@live.be",
		"name": "Elodie",
		"createdDate": "2018-06-03 14:19:48",
		"totalOrganizations": 15,
		"totalTours": 12,
		"totalDays": 487,
		"totalCountries": 13,
		"totalStates": 47,
		"totalEvents": 157,
		"eventPercent": 0.720567,
		"totalScheduleItems": 1883,
		"schedulePercent": 0.701629,
		"totalGuests": 676,
		"guestPercent": 0.746259,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 86
	},
	{
		"id": "07180f9",
		"email": "mick.jerkincrocus@googlemail.com",
		"name": "Mick",
		"createdDate": "2009-08-21 19:33:24",
		"totalOrganizations": 55,
		"totalTours": 14,
		"totalDays": 337,
		"totalCountries": 18,
		"totalStates": 56,
		"totalEvents": 159,
		"eventPercent": 0.726394,
		"totalScheduleItems": 1126,
		"schedulePercent": 0.518607,
		"totalGuests": 250,
		"guestPercent": 0.578599,
		"mostGuestDate": "2023-03-27 00:00:00",
		"mostGuestName": "Bush Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 25
	},
	{
		"id": "56437dc",
		"email": "olifsono@gmail.com",
		"name": "Olivier",
		"createdDate": "2019-01-19 11:57:40",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 308,
		"totalCountries": 5,
		"totalStates": 19,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 1016,
		"schedulePercent": 0.490001,
		"totalGuests": 20,
		"guestPercent": 0.36909,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "Les dÃ©ferlantes sud de France",
		"mostGuestCity": "CÃ©ret",
		"mostGuestAmount": 18
	},
	{
		"id": "8b9145b",
		"email": "ANDYEPA@gmail.com",
		"name": "Andy",
		"createdDate": "2014-05-17 14:55:04",
		"totalOrganizations": 13,
		"totalTours": 8,
		"totalDays": 866,
		"totalCountries": 19,
		"totalStates": 61,
		"totalEvents": 273,
		"eventPercent": 0.896305,
		"totalScheduleItems": 1769,
		"schedulePercent": 0.678453,
		"totalGuests": 1202,
		"guestPercent": 0.857502,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Petco Park",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 186
	},
	{
		"id": "dd47c0f",
		"email": "vinhamaluf@gmail.com",
		"name": "Flavia ",
		"createdDate": "2012-09-05 00:57:15",
		"totalOrganizations": 7,
		"totalTours": 12,
		"totalDays": 433,
		"totalCountries": 10,
		"totalStates": 31,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 1418,
		"schedulePercent": 0.595418,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c5917b7",
		"email": "martinezcami@gmail.com",
		"name": "Cami",
		"createdDate": "2023-04-28 18:30:57",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 64,
		"totalCountries": 7,
		"totalStates": 9,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 368,
		"schedulePercent": 0.24222,
		"totalGuests": 311,
		"guestPercent": 0.610118,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 57
	},
	{
		"id": "f9a4967",
		"email": "lauriepaquette12@gmail.com",
		"name": "Laurie",
		"createdDate": "2019-10-30 15:54:06",
		"totalOrganizations": 10,
		"totalTours": 10,
		"totalDays": 252,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 241,
		"eventPercent": 0.862667,
		"totalScheduleItems": 1079,
		"schedulePercent": 0.507218,
		"totalGuests": 604,
		"guestPercent": 0.727453,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Cabaret du Casino",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 91
	},
	{
		"id": "e59610d",
		"email": "mdoyle@majorbob.com",
		"name": "Michael",
		"createdDate": "2015-03-28 09:23:12",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 86,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 142,
		"schedulePercent": 0.114422,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "55a2e32",
		"email": "rachel@whyandhow.com",
		"name": "Rachel",
		"createdDate": "2013-06-28 20:01:14",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 278,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 1120,
		"schedulePercent": 0.516223,
		"totalGuests": 388,
		"guestPercent": 0.648523,
		"mostGuestDate": "2023-09-14 00:00:00",
		"mostGuestName": "Emo's",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 41
	},
	{
		"id": "32fdfea",
		"email": "jeffbrya@gmail.com",
		"name": "Jeff",
		"createdDate": "2010-07-30 02:34:47",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 106,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 617,
		"schedulePercent": 0.348431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f01dd5d",
		"email": "marfagiant@gmail.com",
		"name": "Donnie",
		"createdDate": "2014-08-24 00:50:53",
		"totalOrganizations": 6,
		"totalTours": 29,
		"totalDays": 1133,
		"totalCountries": 15,
		"totalStates": 45,
		"totalEvents": 220,
		"eventPercent": 0.83671,
		"totalScheduleItems": 2576,
		"schedulePercent": 0.80241,
		"totalGuests": 190,
		"guestPercent": 0.542445,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "ad73ba8",
		"email": "julio@supermusicgroup.com",
		"name": "Julio",
		"createdDate": "2023-11-01 16:55:16",
		"totalOrganizations": 12,
		"totalTours": 5,
		"totalDays": 40,
		"totalCountries": 4,
		"totalStates": 12,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 126,
		"schedulePercent": 0.106741,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d8d1dd8",
		"email": "jwortman@mac.com",
		"name": "Jerry",
		"createdDate": "2009-07-16 18:31:27",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 128,
		"totalCountries": 7,
		"totalStates": 30,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 288,
		"schedulePercent": 0.201828,
		"totalGuests": 523,
		"guestPercent": 0.699245,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Kings Theatre",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 20
	},
	{
		"id": "b4c7a47",
		"email": "gretchen@magicspace.net",
		"name": "Gretchen",
		"createdDate": "2014-12-12 08:43:13",
		"totalOrganizations": 38,
		"totalTours": 8,
		"totalDays": 298,
		"totalCountries": 3,
		"totalStates": 46,
		"totalEvents": 241,
		"eventPercent": 0.862667,
		"totalScheduleItems": 2626,
		"schedulePercent": 0.811548,
		"totalGuests": 1036,
		"guestPercent": 0.830089,
		"mostGuestDate": "2023-12-23 00:00:00",
		"mostGuestName": "Orpheum Theater",
		"mostGuestCity": "Omaha",
		"mostGuestAmount": 74
	},
	{
		"id": "efd83fc",
		"email": "iamdavedanger@gmail.com",
		"name": "David",
		"createdDate": "2016-11-17 16:58:35",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 75,
		"totalCountries": 7,
		"totalStates": 12,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 502,
		"schedulePercent": 0.299563,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "342da2c",
		"email": "eddiebuchanan@mac.com",
		"name": "Eddie ",
		"createdDate": "2018-01-13 20:52:07",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 21,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 77,
		"schedulePercent": 0.0731029,
		"totalGuests": 24,
		"guestPercent": 0.375712,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Hanover Vegetable Farm",
		"mostGuestCity": "Ashland",
		"mostGuestAmount": 22
	},
	{
		"id": "8279ffa",
		"email": "domondemand@gmail.com",
		"name": "DOMINIQUE",
		"createdDate": "2018-03-30 22:15:07",
		"totalOrganizations": 11,
		"totalTours": 15,
		"totalDays": 727,
		"totalCountries": 10,
		"totalStates": 39,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 4913,
		"schedulePercent": 0.959211,
		"totalGuests": 190,
		"guestPercent": 0.542445,
		"mostGuestDate": "2023-11-22 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 24
	},
	{
		"id": "928be8b",
		"email": "northernlightstouring@gmail.com",
		"name": "James",
		"createdDate": "2022-01-03 01:31:57",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 60,
		"totalCountries": 2,
		"totalStates": 17,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 59,
		"schedulePercent": 0.0611839,
		"totalGuests": 660,
		"guestPercent": 0.742021,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Phoenix Concert Theatre",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 158
	},
	{
		"id": "a4d7191",
		"email": "buddy@bossradioatx.com",
		"name": "Buddy",
		"createdDate": "2019-11-03 20:23:45",
		"totalOrganizations": 11,
		"totalTours": 17,
		"totalDays": 315,
		"totalCountries": 14,
		"totalStates": 49,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 1277,
		"schedulePercent": 0.559264,
		"totalGuests": 1117,
		"guestPercent": 0.84214,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "Don Haskins Center",
		"mostGuestCity": "El Paso",
		"mostGuestAmount": 213
	},
	{
		"id": "8adf0d8",
		"email": "larryschachner@gmail.com",
		"name": "Larry",
		"createdDate": "2019-03-10 15:54:26",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 366,
		"totalCountries": 3,
		"totalStates": 20,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 1185,
		"schedulePercent": 0.535955,
		"totalGuests": 318,
		"guestPercent": 0.613031,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "The Mission Ballroom",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 86
	},
	{
		"id": "38cd377",
		"email": "radaudioworks@yahoo.com",
		"name": "David",
		"createdDate": "2012-06-19 05:28:13",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 390,
		"totalCountries": 3,
		"totalStates": 40,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 1582,
		"schedulePercent": 0.637399,
		"totalGuests": 17,
		"guestPercent": 0.362469,
		"mostGuestDate": "2023-12-01 00:00:00",
		"mostGuestName": "EPIC Event Center",
		"mostGuestCity": "Green Bay",
		"mostGuestAmount": 13
	},
	{
		"id": "faadb6d",
		"email": "rpgsound@gmail.com",
		"name": "Ronald",
		"createdDate": "2009-09-09 17:13:03",
		"totalOrganizations": 14,
		"totalTours": 11,
		"totalDays": 571,
		"totalCountries": 14,
		"totalStates": 76,
		"totalEvents": 252,
		"eventPercent": 0.876308,
		"totalScheduleItems": 3579,
		"schedulePercent": 0.898424,
		"totalGuests": 3942,
		"guestPercent": 0.983049,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 185
	},
	{
		"id": "f0ef6e0",
		"email": "coltonplace@hotmail.com",
		"name": "Colton",
		"createdDate": "2021-08-16 00:28:18",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 46,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 145,
		"schedulePercent": 0.116144,
		"totalGuests": 68,
		"guestPercent": 0.444312,
		"mostGuestDate": "2023-10-04 00:00:00",
		"mostGuestName": "Last Exit Live",
		"mostGuestCity": "Phoenix",
		"mostGuestAmount": 21
	},
	{
		"id": "b29e21e",
		"email": "marie@glassbeamsmusic.com",
		"name": "Marie",
		"createdDate": "2023-02-23 09:50:21",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 275,
		"totalCountries": 10,
		"totalStates": 11,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 262,
		"schedulePercent": 0.186465,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-06-07 00:00:00",
		"mostGuestName": "The Lexington",
		"mostGuestCity": "London",
		"mostGuestAmount": 6
	},
	{
		"id": "1dd53ec",
		"email": "ole@kultmanagement.com",
		"name": "Jan Ole",
		"createdDate": "2019-01-16 15:09:44",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 290,
		"totalCountries": 22,
		"totalStates": 60,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1682,
		"schedulePercent": 0.660442,
		"totalGuests": 547,
		"guestPercent": 0.708648,
		"mostGuestDate": "2023-03-22 00:00:00",
		"mostGuestName": "The Regent",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 27
	},
	{
		"id": "fe919cf",
		"email": "wmedina@sauceboyz.com",
		"name": "Walter",
		"createdDate": "2023-10-02 19:53:34",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 49,
		"totalCountries": 3,
		"totalStates": 12,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 308,
		"schedulePercent": 0.212157,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "574c2cd",
		"email": "patrickshamus@me.com",
		"name": "Patrick",
		"createdDate": "2022-08-22 04:59:00",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 293,
		"totalCountries": 9,
		"totalStates": 35,
		"totalEvents": 155,
		"eventPercent": 0.716197,
		"totalScheduleItems": 1616,
		"schedulePercent": 0.645875,
		"totalGuests": 1138,
		"guestPercent": 0.847173,
		"mostGuestDate": "2023-03-09 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 65
	},
	{
		"id": "dbb5a11",
		"email": "alyssa@piamusic.com",
		"name": "Alyssa",
		"createdDate": "2023-05-30 17:41:04",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 84,
		"totalCountries": 1,
		"totalStates": 28,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 505,
		"schedulePercent": 0.301152,
		"totalGuests": 29,
		"guestPercent": 0.387896,
		"mostGuestDate": "2023-06-02 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 14
	},
	{
		"id": "84054c3",
		"email": "shawn@revivalrecs.com",
		"name": "Shawn",
		"createdDate": "2016-09-08 20:20:11",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 39,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 348,
		"schedulePercent": 0.232155,
		"totalGuests": 291,
		"guestPercent": 0.598199,
		"mostGuestDate": "2023-11-13 00:00:00",
		"mostGuestName": "Whisky a Go Go",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 50
	},
	{
		"id": "c8b8d01",
		"email": "morganbuckles@me.com",
		"name": "Morgan",
		"createdDate": "2022-05-21 23:31:47",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 38,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 175,
		"schedulePercent": 0.135744,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6ca3678",
		"email": "jackjtosi@gmail.com",
		"name": "Jack",
		"createdDate": "2017-06-28 23:24:09",
		"totalOrganizations": 15,
		"totalTours": 6,
		"totalDays": 100,
		"totalCountries": 6,
		"totalStates": 15,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 434,
		"schedulePercent": 0.27003,
		"totalGuests": 135,
		"guestPercent": 0.50245,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Regent Theater DTLA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 46
	},
	{
		"id": "e818853",
		"email": "alexandra.bagios@gmail.com",
		"name": "Alexandra ",
		"createdDate": "2016-03-06 04:23:05",
		"totalOrganizations": 20,
		"totalTours": 39,
		"totalDays": 815,
		"totalCountries": 13,
		"totalStates": 71,
		"totalEvents": 384,
		"eventPercent": 0.953119,
		"totalScheduleItems": 4263,
		"schedulePercent": 0.936432,
		"totalGuests": 2147,
		"guestPercent": 0.941597,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 171
	},
	{
		"id": "02a74ab",
		"email": "aannaa11g@gmail.com",
		"name": "Ana Georgina",
		"createdDate": "2017-08-07 18:52:40",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 543,
		"totalCountries": 12,
		"totalStates": 45,
		"totalEvents": 236,
		"eventPercent": 0.857237,
		"totalScheduleItems": 2826,
		"schedulePercent": 0.835386,
		"totalGuests": 40,
		"guestPercent": 0.405642,
		"mostGuestDate": "2023-01-22 00:00:00",
		"mostGuestName": "Crypto.com Arena",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 20
	},
	{
		"id": "22e2c0b",
		"email": "pritcharal@gmail.com",
		"name": "Alistair",
		"createdDate": "2016-06-21 10:35:08",
		"totalOrganizations": 21,
		"totalTours": 17,
		"totalDays": 1673,
		"totalCountries": 30,
		"totalStates": 76,
		"totalEvents": 316,
		"eventPercent": 0.923719,
		"totalScheduleItems": 4408,
		"schedulePercent": 0.941332,
		"totalGuests": 1475,
		"guestPercent": 0.891008,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 86
	},
	{
		"id": "79ef1d1",
		"email": "Rileyclemmonsmusic@gmail.com",
		"name": "Riley",
		"createdDate": "2018-03-24 18:03:53",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 525,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 1514,
		"schedulePercent": 0.621639,
		"totalGuests": 655,
		"guestPercent": 0.740697,
		"mostGuestDate": "2023-04-13 00:00:00",
		"mostGuestName": "The Fisher Center for the Performing Arts",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 104
	},
	{
		"id": "56503c9",
		"email": "bartersontour@me.com",
		"name": "Martin",
		"createdDate": "2019-01-17 10:45:53",
		"totalOrganizations": 6,
		"totalTours": 1,
		"totalDays": 53,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 376,
		"schedulePercent": 0.246457,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ebce573",
		"email": "rusty@theglowmgmt.com",
		"name": "Rusty",
		"createdDate": "2015-10-03 17:59:10",
		"totalOrganizations": 19,
		"totalTours": 26,
		"totalDays": 728,
		"totalCountries": 18,
		"totalStates": 74,
		"totalEvents": 390,
		"eventPercent": 0.954576,
		"totalScheduleItems": 4341,
		"schedulePercent": 0.939213,
		"totalGuests": 2525,
		"guestPercent": 0.95484,
		"mostGuestDate": "2023-05-14 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 77
	},
	{
		"id": "e160a64",
		"email": "gjerdrum@icloud.com",
		"name": "Eric",
		"createdDate": "2013-11-19 12:33:35",
		"totalOrganizations": 15,
		"totalTours": 12,
		"totalDays": 479,
		"totalCountries": 19,
		"totalStates": 47,
		"totalEvents": 336,
		"eventPercent": 0.935108,
		"totalScheduleItems": 4441,
		"schedulePercent": 0.943186,
		"totalGuests": 7339,
		"guestPercent": 0.998941,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Olympiastadion Berlin",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 794
	},
	{
		"id": "1f9efcd",
		"email": "anna-tiina.timonen@warnermusic.com",
		"name": "Anna-Tiina",
		"createdDate": "2017-05-18 09:34:15",
		"totalOrganizations": 23,
		"totalTours": 34,
		"totalDays": 548,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 459,
		"eventPercent": 0.969011,
		"totalScheduleItems": 2148,
		"schedulePercent": 0.742153,
		"totalGuests": 488,
		"guestPercent": 0.687591,
		"mostGuestDate": "2023-10-08 00:00:00",
		"mostGuestName": "Tavastia",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 58
	},
	{
		"id": "0316622",
		"email": "steve@hillsartists.com",
		"name": "Steve",
		"createdDate": "2023-09-11 02:34:13",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 99,
		"totalCountries": 8,
		"totalStates": 18,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 335,
		"schedulePercent": 0.225798,
		"totalGuests": 195,
		"guestPercent": 0.546418,
		"mostGuestDate": "2023-11-19 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 34
	},
	{
		"id": "0a3e889",
		"email": "celine@spntribe.com",
		"name": "C&#233;line",
		"createdDate": "2022-07-08 09:16:25",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 333,
		"totalCountries": 13,
		"totalStates": 32,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 483,
		"schedulePercent": 0.290028,
		"totalGuests": 66,
		"guestPercent": 0.441001,
		"mostGuestDate": "2023-05-07 00:00:00",
		"mostGuestName": "Admiralspalast",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 31
	},
	{
		"id": "c97194d",
		"email": "steve@onlyhelix.com",
		"name": "Steven",
		"createdDate": "2019-01-04 13:18:39",
		"totalOrganizations": 18,
		"totalTours": 15,
		"totalDays": 1131,
		"totalCountries": 20,
		"totalStates": 65,
		"totalEvents": 199,
		"eventPercent": 0.804264,
		"totalScheduleItems": 2584,
		"schedulePercent": 0.803602,
		"totalGuests": 204,
		"guestPercent": 0.553834,
		"mostGuestDate": "2023-06-14 00:00:00",
		"mostGuestName": "Granville Theatre",
		"mostGuestCity": "Ramsgate",
		"mostGuestAmount": 23
	},
	{
		"id": "00d3782",
		"email": "dexta@undergroundproduction.co.nz",
		"name": "Bevan ",
		"createdDate": "2017-09-20 05:44:35",
		"totalOrganizations": 13,
		"totalTours": 4,
		"totalDays": 81,
		"totalCountries": 9,
		"totalStates": 14,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 602,
		"schedulePercent": 0.342471,
		"totalGuests": 21,
		"guestPercent": 0.371342,
		"mostGuestDate": "2023-03-07 00:00:00",
		"mostGuestName": "The Civic",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 19
	},
	{
		"id": "a62a00e",
		"email": "david@saladdaysagency.com",
		"name": "David",
		"createdDate": "2019-11-05 11:55:17",
		"totalOrganizations": 8,
		"totalTours": 7,
		"totalDays": 194,
		"totalCountries": 18,
		"totalStates": 41,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 894,
		"schedulePercent": 0.451331,
		"totalGuests": 75,
		"guestPercent": 0.450007,
		"mostGuestDate": "2023-01-19 00:00:00",
		"mostGuestName": "Factory Theatre",
		"mostGuestCity": "Marrickville",
		"mostGuestAmount": 14
	},
	{
		"id": "97760ac",
		"email": "inge@offtherecord.la",
		"name": "Inge",
		"createdDate": "2022-07-07 06:12:54",
		"totalOrganizations": 2,
		"totalTours": 8,
		"totalDays": 126,
		"totalCountries": 12,
		"totalStates": 36,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 536,
		"schedulePercent": 0.315455,
		"totalGuests": 138,
		"guestPercent": 0.504966,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "The Bowery Ballroom",
		"mostGuestCity": "New York",
		"mostGuestAmount": 31
	},
	{
		"id": "ca7b662",
		"email": "hb@moschusmusic.de",
		"name": "H",
		"createdDate": "2018-06-08 10:54:31",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 48,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 576,
		"schedulePercent": 0.330685,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b34df6e",
		"email": "roshad5@yahoo.com",
		"name": "Roshad",
		"createdDate": "2009-09-01 00:45:55",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 536,
		"totalCountries": 10,
		"totalStates": 28,
		"totalEvents": 172,
		"eventPercent": 0.755661,
		"totalScheduleItems": 231,
		"schedulePercent": 0.169779,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d818144",
		"email": "Arlynne@petemuller.com",
		"name": "Arlynne",
		"createdDate": "2023-06-26 09:38:49",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 21,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 119,
		"schedulePercent": 0.102106,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "The Word Barn",
		"mostGuestCity": "Exeter",
		"mostGuestAmount": 5
	},
	{
		"id": "f350d0b",
		"email": "dogoodworkmgmt@gmail.com",
		"name": "Lane",
		"createdDate": "2022-06-30 00:44:48",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 395,
		"totalCountries": 7,
		"totalStates": 19,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 726,
		"schedulePercent": 0.391074,
		"totalGuests": 28,
		"guestPercent": 0.386306,
		"mostGuestDate": "2023-02-16 00:00:00",
		"mostGuestName": "Beach Hotel",
		"mostGuestCity": "Byron Bay",
		"mostGuestAmount": 28
	},
	{
		"id": "e22a31c",
		"email": "ryan@kmmltd.com",
		"name": "Ryan",
		"createdDate": "2018-03-31 15:53:26",
		"totalOrganizations": 11,
		"totalTours": 16,
		"totalDays": 388,
		"totalCountries": 11,
		"totalStates": 55,
		"totalEvents": 210,
		"eventPercent": 0.823732,
		"totalScheduleItems": 2374,
		"schedulePercent": 0.776586,
		"totalGuests": 347,
		"guestPercent": 0.627467,
		"mostGuestDate": "2023-07-26 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 40
	},
	{
		"id": "b72f029",
		"email": "joshbriand@gmail.com",
		"name": "Josh",
		"createdDate": "2011-03-04 03:15:32",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 45,
		"totalCountries": 4,
		"totalStates": 6,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 351,
		"schedulePercent": 0.233611,
		"totalGuests": 109,
		"guestPercent": 0.481393,
		"mostGuestDate": "2023-05-25 00:00:00",
		"mostGuestName": "OLG Stage at Fallsview Casino Resort",
		"mostGuestCity": "Niagara Falls",
		"mostGuestAmount": 27
	},
	{
		"id": "77ed2aa",
		"email": "reese@kuenstlermanagement.de",
		"name": "Kerstin",
		"createdDate": "2021-09-08 11:25:28",
		"totalOrganizations": 16,
		"totalTours": 15,
		"totalDays": 874,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 248,
		"eventPercent": 0.869554,
		"totalScheduleItems": 667,
		"schedulePercent": 0.367898,
		"totalGuests": 51,
		"guestPercent": 0.423785,
		"mostGuestDate": "2023-09-26 00:00:00",
		"mostGuestName": "Mojo Club",
		"mostGuestCity": "Hamburg",
		"mostGuestAmount": 20
	},
	{
		"id": "9f00f01",
		"email": "taylor.heineman@gmail.com",
		"name": "Taylor",
		"createdDate": "2018-03-07 03:33:19",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 3,
		"totalStates": 40,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 1645,
		"schedulePercent": 0.652496,
		"totalGuests": 890,
		"guestPercent": 0.797378,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Billy Bob's Texas",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 58
	},
	{
		"id": "939eb8a",
		"email": "michael@makprod.com",
		"name": "Michael",
		"createdDate": "2019-08-19 16:54:24",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 308,
		"totalCountries": 3,
		"totalStates": 41,
		"totalEvents": 178,
		"eventPercent": 0.767051,
		"totalScheduleItems": 3213,
		"schedulePercent": 0.870746,
		"totalGuests": 2699,
		"guestPercent": 0.960932,
		"mostGuestDate": "2023-08-16 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 154
	},
	{
		"id": "efcb032",
		"email": "gretchenannaklein@gmail.com",
		"name": "Gretchen",
		"createdDate": "2016-01-03 20:14:07",
		"totalOrganizations": 9,
		"totalTours": 27,
		"totalDays": 477,
		"totalCountries": 9,
		"totalStates": 56,
		"totalEvents": 264,
		"eventPercent": 0.889419,
		"totalScheduleItems": 3312,
		"schedulePercent": 0.878956,
		"totalGuests": 2354,
		"guestPercent": 0.948881,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 90
	},
	{
		"id": "bdbcb51",
		"email": "simonmorris3@gmail.com",
		"name": "Simon",
		"createdDate": "2011-11-08 19:52:24",
		"totalOrganizations": 26,
		"totalTours": 11,
		"totalDays": 291,
		"totalCountries": 5,
		"totalStates": 9,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 632,
		"schedulePercent": 0.355185,
		"totalGuests": 205,
		"guestPercent": 0.554231,
		"mostGuestDate": "2023-03-23 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 19
	},
	{
		"id": "ad23110",
		"email": "nicotouring@icloud.com",
		"name": "Nick",
		"createdDate": "2023-03-28 19:03:28",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 161,
		"totalCountries": 6,
		"totalStates": 15,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 310,
		"schedulePercent": 0.213349,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "88874fb",
		"email": "maubarba.tm@gmail.com",
		"name": "Mau",
		"createdDate": "2023-09-14 13:38:55",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 36,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 381,
		"schedulePercent": 0.249371,
		"totalGuests": 201,
		"guestPercent": 0.55198,
		"mostGuestDate": "2023-10-22 00:00:00",
		"mostGuestName": "Astra Kulturhaus",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 37
	},
	{
		"id": "16d1b77",
		"email": "talalayeh@gmail.com",
		"name": "Talayeh",
		"createdDate": "2018-02-10 19:27:30",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 337,
		"totalCountries": 4,
		"totalStates": 6,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 1332,
		"schedulePercent": 0.575288,
		"totalGuests": 802,
		"guestPercent": 0.776718,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 105
	},
	{
		"id": "be3163d",
		"email": "isekeita@gmail.com",
		"name": "keita",
		"createdDate": "2019-10-28 05:16:45",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 58,
		"totalCountries": 5,
		"totalStates": 7,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 517,
		"schedulePercent": 0.306847,
		"totalGuests": 64,
		"guestPercent": 0.439015,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "MOTH Club",
		"mostGuestCity": "London",
		"mostGuestAmount": 12
	},
	{
		"id": "4f6298f",
		"email": "sherwin.shapouri@gmail.com",
		"name": "Sherwin ",
		"createdDate": "2017-09-14 17:59:19",
		"totalOrganizations": 12,
		"totalTours": 16,
		"totalDays": 463,
		"totalCountries": 14,
		"totalStates": 68,
		"totalEvents": 269,
		"eventPercent": 0.893789,
		"totalScheduleItems": 2905,
		"schedulePercent": 0.84267,
		"totalGuests": 63,
		"guestPercent": 0.437558,
		"mostGuestDate": "2023-05-08 00:00:00",
		"mostGuestName": "The Observatory",
		"mostGuestCity": "Santa Ana",
		"mostGuestAmount": 28
	},
	{
		"id": "9f85460",
		"email": "Mike@7smgmt.com",
		"name": "Mike ",
		"createdDate": "2014-04-15 19:08:29",
		"totalOrganizations": 27,
		"totalTours": 90,
		"totalDays": 2497,
		"totalCountries": 6,
		"totalStates": 72,
		"totalEvents": 675,
		"eventPercent": 0.985168,
		"totalScheduleItems": 5642,
		"schedulePercent": 0.975367,
		"totalGuests": 435,
		"guestPercent": 0.666667,
		"mostGuestDate": "2023-01-21 00:00:00",
		"mostGuestName": "Cervantes' Masterpiece Ballroom & Cervantes' Other Side",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 50
	},
	{
		"id": "a7a3d8e",
		"email": "aj@urbnmind.com",
		"name": "Anthony",
		"createdDate": "2014-09-20 20:49:10",
		"totalOrganizations": 141,
		"totalTours": 32,
		"totalDays": 872,
		"totalCountries": 26,
		"totalStates": 62,
		"totalEvents": 210,
		"eventPercent": 0.823732,
		"totalScheduleItems": 2742,
		"schedulePercent": 0.824791,
		"totalGuests": 421,
		"guestPercent": 0.662032,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 121
	},
	{
		"id": "8304bd5",
		"email": "amosontour@yahoo.com",
		"name": "Amos",
		"createdDate": "2015-05-06 19:59:29",
		"totalOrganizations": 25,
		"totalTours": 14,
		"totalDays": 178,
		"totalCountries": 11,
		"totalStates": 37,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 870,
		"schedulePercent": 0.442988,
		"totalGuests": 440,
		"guestPercent": 0.668653,
		"mostGuestDate": "2023-02-04 00:00:00",
		"mostGuestName": "The Music Center @ Strathmore",
		"mostGuestCity": "North Bethesda",
		"mostGuestAmount": 68
	},
	{
		"id": "bbeb9a7",
		"email": "Jared@noble-management.com",
		"name": "Jared",
		"createdDate": "2022-08-02 02:58:17",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 243,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 2337,
		"schedulePercent": 0.772348,
		"totalGuests": 3049,
		"guestPercent": 0.969673,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 298
	},
	{
		"id": "e33d741",
		"email": "chad@premierproductions.com",
		"name": "Chad",
		"createdDate": "2018-12-03 17:13:12",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 616,
		"totalCountries": 1,
		"totalStates": 33,
		"totalEvents": 174,
		"eventPercent": 0.759502,
		"totalScheduleItems": 2806,
		"schedulePercent": 0.832605,
		"totalGuests": 1027,
		"guestPercent": 0.8285,
		"mostGuestDate": "2023-10-25 00:00:00",
		"mostGuestName": "Cedar Valley Church",
		"mostGuestCity": "Minneapolis",
		"mostGuestAmount": 38
	},
	{
		"id": "4499d7a",
		"email": "xandyedgex@gmail.com",
		"name": "Andy",
		"createdDate": "2012-06-23 21:38:09",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 73,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 552,
		"schedulePercent": 0.322606,
		"totalGuests": 164,
		"guestPercent": 0.526155,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 147
	},
	{
		"id": "0bf5efe",
		"email": "nefferland@yahoo.com",
		"name": "David",
		"createdDate": "2022-01-07 01:24:33",
		"totalOrganizations": 3,
		"totalTours": 15,
		"totalDays": 333,
		"totalCountries": 17,
		"totalStates": 64,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 2454,
		"schedulePercent": 0.786783,
		"totalGuests": 90,
		"guestPercent": 0.463912,
		"mostGuestDate": "2023-09-26 00:00:00",
		"mostGuestName": "Kiri Te Kanawa Theatre, Aotea Centre",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 31
	},
	{
		"id": "bf8dd1c",
		"email": "tonytonedog@gmail.com",
		"name": "Tony",
		"createdDate": "2016-07-07 20:23:15",
		"totalOrganizations": 14,
		"totalTours": 9,
		"totalDays": 266,
		"totalCountries": 3,
		"totalStates": 34,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 1493,
		"schedulePercent": 0.616342,
		"totalGuests": 409,
		"guestPercent": 0.657264,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "State Farm Arena",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 133
	},
	{
		"id": "f45da3e",
		"email": "kkilgore02@gmail.com",
		"name": "Keith",
		"createdDate": "2016-07-26 14:54:49",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 367,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 1007,
		"schedulePercent": 0.48722,
		"totalGuests": 100,
		"guestPercent": 0.473977,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Deadwood Mountain Grand Hotel and Casino",
		"mostGuestCity": "Deadwood",
		"mostGuestAmount": 50
	},
	{
		"id": "8b58df7",
		"email": "waipea@yahoo.com",
		"name": "Marcel",
		"createdDate": "2022-10-14 22:51:59",
		"totalOrganizations": 10,
		"totalTours": 22,
		"totalDays": 608,
		"totalCountries": 7,
		"totalStates": 50,
		"totalEvents": 196,
		"eventPercent": 0.798835,
		"totalScheduleItems": 2420,
		"schedulePercent": 0.782545,
		"totalGuests": 159,
		"guestPercent": 0.521918,
		"mostGuestDate": "2023-08-30 00:00:00",
		"mostGuestName": "The Ford",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 26
	},
	{
		"id": "98181af",
		"email": "willowgamberg@gmail.com",
		"name": "Willow ",
		"createdDate": "2023-04-17 02:46:17",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 44,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 6,
		"eventPercent": 0.0397298,
		"totalScheduleItems": 161,
		"schedulePercent": 0.127135,
		"totalGuests": 35,
		"guestPercent": 0.396901,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Irving Plaza",
		"mostGuestCity": "New York",
		"mostGuestAmount": 27
	},
	{
		"id": "6cbf28b",
		"email": "rustonkellyFOH@gmail.com",
		"name": "Alex",
		"createdDate": "2019-08-26 19:57:17",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 224,
		"totalCountries": 4,
		"totalStates": 38,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 870,
		"schedulePercent": 0.442988,
		"totalGuests": 76,
		"guestPercent": 0.451596,
		"mostGuestDate": "2023-01-08 00:00:00",
		"mostGuestName": "Belly Up",
		"mostGuestCity": "Aspen",
		"mostGuestAmount": 20
	},
	{
		"id": "c0f6640",
		"email": "bctaylor29@gmail.com",
		"name": "William",
		"createdDate": "2017-03-27 18:53:54",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 391,
		"totalCountries": 3,
		"totalStates": 44,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 2329,
		"schedulePercent": 0.770626,
		"totalGuests": 1346,
		"guestPercent": 0.87697,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "Vina Robles Amphitheatre",
		"mostGuestCity": "Paso Robles",
		"mostGuestAmount": 52
	},
	{
		"id": "393ba17",
		"email": "coaxle.touring@gmail.com",
		"name": "Harrison ",
		"createdDate": "2021-10-11 16:36:50",
		"totalOrganizations": 4,
		"totalTours": 13,
		"totalDays": 264,
		"totalCountries": 19,
		"totalStates": 65,
		"totalEvents": 141,
		"eventPercent": 0.678321,
		"totalScheduleItems": 1876,
		"schedulePercent": 0.700569,
		"totalGuests": 590,
		"guestPercent": 0.722553,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 61
	},
	{
		"id": "af63a14",
		"email": "Jedwardsaudio@gmail.com",
		"name": "John",
		"createdDate": "2022-07-26 22:43:23",
		"totalOrganizations": 6,
		"totalTours": 14,
		"totalDays": 331,
		"totalCountries": 13,
		"totalStates": 58,
		"totalEvents": 244,
		"eventPercent": 0.865713,
		"totalScheduleItems": 2669,
		"schedulePercent": 0.816448,
		"totalGuests": 2697,
		"guestPercent": 0.960667,
		"mostGuestDate": "2023-05-11 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 76
	},
	{
		"id": "35ea736",
		"email": "mimisouthwest@gmail.com",
		"name": "marion",
		"createdDate": "2023-03-16 16:17:56",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 221,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 24,
		"schedulePercent": 0.0360217,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a873cca",
		"email": "mmoers@parkhaus-studio.de",
		"name": "Mattis",
		"createdDate": "2016-10-12 15:07:16",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 27,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 194,
		"schedulePercent": 0.146736,
		"totalGuests": 133,
		"guestPercent": 0.500596,
		"mostGuestDate": "2023-05-12 00:00:00",
		"mostGuestName": "Huxleys Neue Welt",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 55
	},
	{
		"id": "aaabc09",
		"email": "fohspike@aol.com",
		"name": "spike",
		"createdDate": "2022-10-02 18:24:44",
		"totalOrganizations": 1,
		"totalTours": 11,
		"totalDays": 90,
		"totalCountries": 6,
		"totalStates": 9,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 495,
		"schedulePercent": 0.295722,
		"totalGuests": 190,
		"guestPercent": 0.542445,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "02 ARENA",
		"mostGuestCity": "LONDON",
		"mostGuestAmount": 23
	},
	{
		"id": "76d53bf",
		"email": "daniel@westridgeaudio.se",
		"name": "Daniel",
		"createdDate": "2013-02-17 21:41:03",
		"totalOrganizations": 9,
		"totalTours": 13,
		"totalDays": 715,
		"totalCountries": 9,
		"totalStates": 37,
		"totalEvents": 159,
		"eventPercent": 0.726394,
		"totalScheduleItems": 1816,
		"schedulePercent": 0.688121,
		"totalGuests": 57,
		"guestPercent": 0.430804,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Quality Hotel Sundsvall",
		"mostGuestCity": "Sundsvall",
		"mostGuestAmount": 25
	},
	{
		"id": "f008aa9",
		"email": "a.paulave@gmail.com",
		"name": "Ana Paula",
		"createdDate": "2016-10-25 16:46:43",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 457,
		"totalCountries": 8,
		"totalStates": 53,
		"totalEvents": 148,
		"eventPercent": 0.696332,
		"totalScheduleItems": 1640,
		"schedulePercent": 0.651569,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "530d918",
		"email": "sarahkufelproduction@gmail.com",
		"name": "Sarah",
		"createdDate": "2022-02-11 18:21:04",
		"totalOrganizations": 15,
		"totalTours": 15,
		"totalDays": 1162,
		"totalCountries": 7,
		"totalStates": 44,
		"totalEvents": 184,
		"eventPercent": 0.77791,
		"totalScheduleItems": 2104,
		"schedulePercent": 0.736061,
		"totalGuests": 1107,
		"guestPercent": 0.840683,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 67
	},
	{
		"id": "b72ebfc",
		"email": "Management@karolgmusic.com",
		"name": "Road",
		"createdDate": "2021-06-28 15:42:25",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 362,
		"totalCountries": 5,
		"totalStates": 17,
		"totalEvents": 195,
		"eventPercent": 0.796318,
		"totalScheduleItems": 2496,
		"schedulePercent": 0.792875,
		"totalGuests": 40,
		"guestPercent": 0.405642,
		"mostGuestDate": "2023-01-22 00:00:00",
		"mostGuestName": "Crypto.com Arena",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 20
	},
	{
		"id": "ee1dbbb",
		"email": "production@bonsound.com",
		"name": "Production",
		"createdDate": "2021-10-26 00:55:06",
		"totalOrganizations": 10,
		"totalTours": 15,
		"totalDays": 462,
		"totalCountries": 5,
		"totalStates": 32,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 986,
		"schedulePercent": 0.47848,
		"totalGuests": 146,
		"guestPercent": 0.510528,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "Imperial Bell",
		"mostGuestCity": "Quebec",
		"mostGuestAmount": 21
	},
	{
		"id": "b8df328",
		"email": "bassida@gmail.com",
		"name": "Ida",
		"createdDate": "2019-07-08 00:09:30",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 363,
		"totalCountries": 7,
		"totalStates": 16,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 574,
		"schedulePercent": 0.330155,
		"totalGuests": 27,
		"guestPercent": 0.38379,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "Porgy & Bess",
		"mostGuestCity": "Wien",
		"mostGuestAmount": 10
	},
	{
		"id": "d1fe967",
		"email": "maja.ostling@allthingslive.se",
		"name": "Maja",
		"createdDate": "2022-02-25 17:03:09",
		"totalOrganizations": 39,
		"totalTours": 11,
		"totalDays": 313,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 249,
		"eventPercent": 0.870746,
		"totalScheduleItems": 1656,
		"schedulePercent": 0.654483,
		"totalGuests": 411,
		"guestPercent": 0.658191,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Borgholms Slott",
		"mostGuestCity": "Borgholm",
		"mostGuestAmount": 45
	},
	{
		"id": "6e1b9f3",
		"email": "martin@24-7productions.co.uk",
		"name": "Martin",
		"createdDate": "2021-08-18 13:45:28",
		"totalOrganizations": 10,
		"totalTours": 13,
		"totalDays": 619,
		"totalCountries": 13,
		"totalStates": 32,
		"totalEvents": 179,
		"eventPercent": 0.769037,
		"totalScheduleItems": 2398,
		"schedulePercent": 0.780426,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Echo Arena",
		"mostGuestCity": "Liverpool",
		"mostGuestAmount": 2
	},
	{
		"id": "f0182af",
		"email": "chris.rahubka@gmail.com",
		"name": "Christina ",
		"createdDate": "2018-02-12 03:32:03",
		"totalOrganizations": 16,
		"totalTours": 15,
		"totalDays": 344,
		"totalCountries": 7,
		"totalStates": 52,
		"totalEvents": 197,
		"eventPercent": 0.800821,
		"totalScheduleItems": 2917,
		"schedulePercent": 0.844127,
		"totalGuests": 476,
		"guestPercent": 0.682559,
		"mostGuestDate": "2023-11-26 00:00:00",
		"mostGuestName": "Chain Reaction",
		"mostGuestCity": "Anaheim",
		"mostGuestAmount": 38
	},
	{
		"id": "e02f840",
		"email": "mikegonzales@mac.com",
		"name": "Michael",
		"createdDate": "2012-04-18 02:52:25",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 548,
		"totalCountries": 13,
		"totalStates": 37,
		"totalEvents": 133,
		"eventPercent": 0.656602,
		"totalScheduleItems": 2228,
		"schedulePercent": 0.754867,
		"totalGuests": 1750,
		"guestPercent": 0.9167,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 101
	},
	{
		"id": "3d05508",
		"email": "thomassound@bellsouth.net",
		"name": "Randy",
		"createdDate": "2019-01-17 18:02:57",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 424,
		"totalCountries": 4,
		"totalStates": 40,
		"totalEvents": 156,
		"eventPercent": 0.718051,
		"totalScheduleItems": 1649,
		"schedulePercent": 0.653291,
		"totalGuests": 101,
		"guestPercent": 0.474904,
		"mostGuestDate": "2023-01-21 00:00:00",
		"mostGuestName": "Billy Bob's Texas",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 17
	},
	{
		"id": "81eef05",
		"email": "codeorangetours@gmail.com",
		"name": "Code",
		"createdDate": "2022-02-19 20:54:41",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 80,
		"totalCountries": 6,
		"totalStates": 14,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 301,
		"schedulePercent": 0.208449,
		"totalGuests": 17,
		"guestPercent": 0.362469,
		"mostGuestDate": "2023-06-19 00:00:00",
		"mostGuestName": "Holeâ´â´",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 11
	},
	{
		"id": "44833ee",
		"email": "Frank.BullockJr@wwecorp.com",
		"name": "Frank",
		"createdDate": "2018-11-21 19:13:06",
		"totalOrganizations": 6,
		"totalTours": 77,
		"totalDays": 293,
		"totalCountries": 8,
		"totalStates": 55,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 2591,
		"schedulePercent": 0.804794,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "60fcc4b",
		"email": "hoodie.davidson@gmail.com",
		"name": "Hoda",
		"createdDate": "2022-03-28 19:42:46",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 46,
		"totalCountries": 5,
		"totalStates": 9,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 286,
		"schedulePercent": 0.201033,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-10-31 00:00:00",
		"mostGuestName": "Wolfbrook Arena",
		"mostGuestCity": "Christchurch",
		"mostGuestAmount": 12
	},
	{
		"id": "42577ce",
		"email": "gdixmcgrath1@gmail.com",
		"name": "George",
		"createdDate": "2022-01-26 14:35:59",
		"totalOrganizations": 10,
		"totalTours": 22,
		"totalDays": 372,
		"totalCountries": 20,
		"totalStates": 71,
		"totalEvents": 193,
		"eventPercent": 0.792743,
		"totalScheduleItems": 2592,
		"schedulePercent": 0.805986,
		"totalGuests": 746,
		"guestPercent": 0.763475,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 134
	},
	{
		"id": "163e345",
		"email": "Chris.marinaccio@gmail.com",
		"name": "Chris",
		"createdDate": "2016-09-26 23:35:30",
		"totalOrganizations": 15,
		"totalTours": 6,
		"totalDays": 576,
		"totalCountries": 15,
		"totalStates": 54,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 1719,
		"schedulePercent": 0.669315,
		"totalGuests": 563,
		"guestPercent": 0.714872,
		"mostGuestDate": "2023-08-01 00:00:00",
		"mostGuestName": "The Bellweather",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 82
	},
	{
		"id": "66512c8",
		"email": "denny.rch@gmail.com",
		"name": "Denny",
		"createdDate": "2015-01-16 21:58:36",
		"totalOrganizations": 7,
		"totalTours": 14,
		"totalDays": 644,
		"totalCountries": 15,
		"totalStates": 56,
		"totalEvents": 322,
		"eventPercent": 0.927162,
		"totalScheduleItems": 4912,
		"schedulePercent": 0.958946,
		"totalGuests": 395,
		"guestPercent": 0.651569,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 207
	},
	{
		"id": "e46a9df",
		"email": "thellsson@telia.com",
		"name": "Sven",
		"createdDate": "2013-02-06 09:45:35",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 98,
		"totalCountries": 3,
		"totalStates": 6,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 459,
		"schedulePercent": 0.280228,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8a2df1c",
		"email": "jnichols1812@yahoo.com",
		"name": "Jordan",
		"createdDate": "2022-03-07 18:37:39",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 105,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 845,
		"schedulePercent": 0.435969,
		"totalGuests": 821,
		"guestPercent": 0.781221,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "State Farm Arena",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 133
	},
	{
		"id": "76a2fa4",
		"email": "Keith.Heggelke@wwecorp.com",
		"name": "Keith",
		"createdDate": "2022-09-14 01:03:14",
		"totalOrganizations": 1,
		"totalTours": 77,
		"totalDays": 293,
		"totalCountries": 8,
		"totalStates": 55,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 2591,
		"schedulePercent": 0.804794,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3e589d2",
		"email": "singprod@aol.com",
		"name": "Mark",
		"createdDate": "2019-04-03 19:53:27",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 731,
		"totalCountries": 6,
		"totalStates": 44,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 2918,
		"schedulePercent": 0.844259,
		"totalGuests": 383,
		"guestPercent": 0.646404,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "Thompson Boling Arena",
		"mostGuestCity": "Knoxville",
		"mostGuestAmount": 47
	},
	{
		"id": "193c4ec",
		"email": "s.lange@msk-live.de",
		"name": "Sari",
		"createdDate": "2022-06-22 10:01:43",
		"totalOrganizations": 10,
		"totalTours": 10,
		"totalDays": 341,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 171,
		"eventPercent": 0.75394,
		"totalScheduleItems": 2035,
		"schedulePercent": 0.724805,
		"totalGuests": 678,
		"guestPercent": 0.747451,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "E-Werk",
		"mostGuestCity": "Koln",
		"mostGuestAmount": 82
	},
	{
		"id": "f7af817",
		"email": "taorminamiller@icloud.com",
		"name": "Taormina",
		"createdDate": "2022-07-09 11:21:22",
		"totalOrganizations": 3,
		"totalTours": 10,
		"totalDays": 168,
		"totalCountries": 6,
		"totalStates": 11,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 749,
		"schedulePercent": 0.401404,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "285763a",
		"email": "whatwhatoconnor@gmail.com",
		"name": "Wayne",
		"createdDate": "2020-09-02 11:38:05",
		"totalOrganizations": 25,
		"totalTours": 20,
		"totalDays": 1975,
		"totalCountries": 5,
		"totalStates": 50,
		"totalEvents": 1096,
		"eventPercent": 0.990995,
		"totalScheduleItems": 1757,
		"schedulePercent": 0.675672,
		"totalGuests": 171,
		"guestPercent": 0.530658,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Rebecca Cohn Auditorium",
		"mostGuestCity": "Halifax",
		"mostGuestAmount": 18
	},
	{
		"id": "61cea9f",
		"email": "sara.akerman@fkpscorpio.se",
		"name": "Sara",
		"createdDate": "2022-04-16 09:31:16",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 85,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 282,
		"schedulePercent": 0.199311,
		"totalGuests": 353,
		"guestPercent": 0.631307,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Gullmarsvallen",
		"mostGuestCity": "Lysekil",
		"mostGuestAmount": 159
	},
	{
		"id": "8043c92",
		"email": "mike@emarty.co.uk",
		"name": "Mike",
		"createdDate": "2011-08-16 22:39:00",
		"totalOrganizations": 19,
		"totalTours": 13,
		"totalDays": 1118,
		"totalCountries": 9,
		"totalStates": 52,
		"totalEvents": 237,
		"eventPercent": 0.858429,
		"totalScheduleItems": 3199,
		"schedulePercent": 0.869819,
		"totalGuests": 676,
		"guestPercent": 0.746259,
		"mostGuestDate": "2023-01-05 00:00:00",
		"mostGuestName": "The Chicago Theatre",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 63
	},
	{
		"id": "7c2234e",
		"email": "ambproduction6@gmail.com",
		"name": "Morgan",
		"createdDate": "2019-02-26 21:15:41",
		"totalOrganizations": 3,
		"totalTours": 10,
		"totalDays": 508,
		"totalCountries": 15,
		"totalStates": 39,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 2202,
		"schedulePercent": 0.750497,
		"totalGuests": 946,
		"guestPercent": 0.811416,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 293
	},
	{
		"id": "65883a4",
		"email": "Dime_faced@hotmail.com",
		"name": "Dimitar",
		"createdDate": "2016-11-13 16:40:18",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 410,
		"totalCountries": 17,
		"totalStates": 63,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 2114,
		"schedulePercent": 0.737253,
		"totalGuests": 1580,
		"guestPercent": 0.901602,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "FivePoint Amphitheatre",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 104
	},
	{
		"id": "6856d6f",
		"email": "wigalsound@yahoo.com",
		"name": "Zac",
		"createdDate": "2015-07-07 07:01:34",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 125,
		"totalCountries": 2,
		"totalStates": 31,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 581,
		"schedulePercent": 0.332406,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Ironstone Amphitheatre",
		"mostGuestCity": "Murphys",
		"mostGuestAmount": 4
	},
	{
		"id": "3468c71",
		"email": "Rimbec@gmail.com",
		"name": "Rebecca",
		"createdDate": "2020-03-03 08:33:56",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 370,
		"totalCountries": 3,
		"totalStates": 30,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 1557,
		"schedulePercent": 0.631307,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-09-03 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 3
	},
	{
		"id": "978f011",
		"email": "Shane.Tarleton@wmg.com",
		"name": "Shane",
		"createdDate": "2017-06-01 20:15:24",
		"totalOrganizations": 52,
		"totalTours": 47,
		"totalDays": 15493,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 2026,
		"eventPercent": 0.997219,
		"totalScheduleItems": 1872,
		"schedulePercent": 0.699775,
		"totalGuests": 26,
		"guestPercent": 0.381274,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Music Farm",
		"mostGuestCity": "Charleston",
		"mostGuestAmount": 7
	},
	{
		"id": "8d662d1",
		"email": "unpopularprod@gmail.com",
		"name": "Michael ",
		"createdDate": "2011-08-01 18:30:25",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 400,
		"totalCountries": 5,
		"totalStates": 29,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 927,
		"schedulePercent": 0.45835,
		"totalGuests": 114,
		"guestPercent": 0.486028,
		"mostGuestDate": "2023-02-25 00:00:00",
		"mostGuestName": "Fortitude Music Hall",
		"mostGuestCity": "Fortitude Valley",
		"mostGuestAmount": 34
	},
	{
		"id": "5efd234",
		"email": "britti@foundationsmgmt.com",
		"name": "Britti",
		"createdDate": "2021-12-21 20:11:52",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 73,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 589,
		"schedulePercent": 0.335055,
		"totalGuests": 357,
		"guestPercent": 0.633558,
		"mostGuestDate": "2023-04-20 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 93
	},
	{
		"id": "70a5eaa",
		"email": "Diane.monk@wmg.com",
		"name": "Diane",
		"createdDate": "2015-02-13 19:03:36",
		"totalOrganizations": 59,
		"totalTours": 52,
		"totalDays": 16654,
		"totalCountries": 16,
		"totalStates": 92,
		"totalEvents": 2413,
		"eventPercent": 0.999205,
		"totalScheduleItems": 7833,
		"schedulePercent": 0.993114,
		"totalGuests": 880,
		"guestPercent": 0.794464,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 64
	},
	{
		"id": "74bf1dd",
		"email": "psmusic@aol.com",
		"name": "Paul",
		"createdDate": "2016-08-28 21:12:42",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 337,
		"totalCountries": 12,
		"totalStates": 49,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 1939,
		"schedulePercent": 0.70931,
		"totalGuests": 600,
		"guestPercent": 0.726261,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "Backstage Kulturzentrum",
		"mostGuestCity": "MÃ¼nchen",
		"mostGuestAmount": 64
	},
	{
		"id": "b98013e",
		"email": "jochen.list@me.com",
		"name": "Jochen",
		"createdDate": "2017-07-08 16:50:43",
		"totalOrganizations": 2,
		"totalTours": 24,
		"totalDays": 319,
		"totalCountries": 12,
		"totalStates": 38,
		"totalEvents": 157,
		"eventPercent": 0.720567,
		"totalScheduleItems": 2114,
		"schedulePercent": 0.737253,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e649f40",
		"email": "cma@midtowntalent.com",
		"name": "Chris",
		"createdDate": "2015-11-04 19:57:09",
		"totalOrganizations": 9,
		"totalTours": 16,
		"totalDays": 555,
		"totalCountries": 17,
		"totalStates": 76,
		"totalEvents": 324,
		"eventPercent": 0.927692,
		"totalScheduleItems": 4331,
		"schedulePercent": 0.938684,
		"totalGuests": 2641,
		"guestPercent": 0.957092,
		"mostGuestDate": "2023-11-27 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 86
	},
	{
		"id": "0377fe8",
		"email": "tim@noisemakermanagement.com",
		"name": "Tim",
		"createdDate": "2018-02-06 19:11:41",
		"totalOrganizations": 14,
		"totalTours": 55,
		"totalDays": 927,
		"totalCountries": 8,
		"totalStates": 42,
		"totalEvents": 253,
		"eventPercent": 0.877765,
		"totalScheduleItems": 1625,
		"schedulePercent": 0.647596,
		"totalGuests": 187,
		"guestPercent": 0.540988,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "Town Ballroom",
		"mostGuestCity": "Buffalo",
		"mostGuestAmount": 32
	},
	{
		"id": "427e3a1",
		"email": "jackson@thecodyjohnsonband.com",
		"name": "Jackson",
		"createdDate": "2015-07-06 20:58:00",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 556,
		"totalCountries": 3,
		"totalStates": 40,
		"totalEvents": 294,
		"eventPercent": 0.9118,
		"totalScheduleItems": 1797,
		"schedulePercent": 0.68534,
		"totalGuests": 66,
		"guestPercent": 0.441001,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Houston Rodeo WIll Call Guest List",
		"mostGuestCity": "",
		"mostGuestAmount": 57
	},
	{
		"id": "3028e03",
		"email": "joerusby@gmail.com",
		"name": "Joe",
		"createdDate": "2022-09-14 10:23:03",
		"totalOrganizations": 3,
		"totalTours": 11,
		"totalDays": 519,
		"totalCountries": 16,
		"totalStates": 66,
		"totalEvents": 186,
		"eventPercent": 0.782016,
		"totalScheduleItems": 2070,
		"schedulePercent": 0.729705,
		"totalGuests": 38,
		"guestPercent": 0.402198,
		"mostGuestDate": "2023-08-22 00:00:00",
		"mostGuestName": "Lafayette",
		"mostGuestCity": "London",
		"mostGuestAmount": 16
	},
	{
		"id": "054eeca",
		"email": "joni@nem.fi",
		"name": "Joni",
		"createdDate": "2019-04-11 09:42:00",
		"totalOrganizations": 29,
		"totalTours": 26,
		"totalDays": 268,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 266,
		"eventPercent": 0.891935,
		"totalScheduleItems": 1352,
		"schedulePercent": 0.580983,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b31a372",
		"email": "productionsean4@gmail.com",
		"name": "Sean",
		"createdDate": "2019-04-03 00:32:09",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 1506,
		"schedulePercent": 0.619521,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Credit One Stadium",
		"mostGuestCity": "Charleston",
		"mostGuestAmount": 4
	},
	{
		"id": "d9ebc00",
		"email": "jules@classaconsultancy.co.uk",
		"name": "Julian ",
		"createdDate": "2023-05-01 06:01:40",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 190,
		"totalCountries": 17,
		"totalStates": 30,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 1189,
		"schedulePercent": 0.537015,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "af07892",
		"email": "naomi@theprincesstheatre.com.au",
		"name": "Naomi ",
		"createdDate": "2023-04-13 08:18:35",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 235,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 224,
		"schedulePercent": 0.166071,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ca590f1",
		"email": "ruth@thechoirofman.co.uk",
		"name": "Ruth",
		"createdDate": "2022-10-03 13:57:48",
		"totalOrganizations": 11,
		"totalTours": 6,
		"totalDays": 554,
		"totalCountries": 3,
		"totalStates": 52,
		"totalEvents": 429,
		"eventPercent": 0.963978,
		"totalScheduleItems": 4122,
		"schedulePercent": 0.928884,
		"totalGuests": 58,
		"guestPercent": 0.431863,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "Kauffman Center for the Performing Arts",
		"mostGuestCity": "Kansas City",
		"mostGuestAmount": 18
	},
	{
		"id": "5b7fcdd",
		"email": "fiona_gibney@bocpix.com",
		"name": "Fiona",
		"createdDate": "2013-02-25 19:23:49",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 103,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 589,
		"schedulePercent": 0.335055,
		"totalGuests": 526,
		"guestPercent": 0.700702,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "The SSE Hydro Evening Show ",
		"mostGuestCity": "Glasgow",
		"mostGuestAmount": 38
	},
	{
		"id": "6b01aa7",
		"email": "berntsen@kaizerscrew.com",
		"name": "Johan",
		"createdDate": "2009-11-16 18:50:04",
		"totalOrganizations": 12,
		"totalTours": 4,
		"totalDays": 91,
		"totalCountries": 4,
		"totalStates": 13,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 640,
		"schedulePercent": 0.358496,
		"totalGuests": 150,
		"guestPercent": 0.514766,
		"mostGuestDate": "2023-06-14 00:00:00",
		"mostGuestName": "Over Oslo Hovedscenen",
		"mostGuestCity": "Oslo",
		"mostGuestAmount": 25
	},
	{
		"id": "35803e6",
		"email": "fischbachersabine@gmail.com",
		"name": "Sabine",
		"createdDate": "2022-09-05 07:46:46",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 49,
		"totalCountries": 8,
		"totalStates": 16,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 607,
		"schedulePercent": 0.34459,
		"totalGuests": 303,
		"guestPercent": 0.604158,
		"mostGuestDate": "2023-03-22 00:00:00",
		"mostGuestName": "L'Olympia",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 101
	},
	{
		"id": "9b598fa",
		"email": "csi.lighting@gmail.com",
		"name": "Carter",
		"createdDate": "2019-12-27 23:37:14",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 97,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 382,
		"schedulePercent": 0.250166,
		"totalGuests": 131,
		"guestPercent": 0.499272,
		"mostGuestDate": "2023-10-29 00:00:00",
		"mostGuestName": "Mayo Performing Arts Center",
		"mostGuestCity": "Morristown",
		"mostGuestAmount": 24
	},
	{
		"id": "d0c67b8",
		"email": "jakehartsfield@gmail.com",
		"name": "Jake",
		"createdDate": "2013-01-12 19:35:31",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 310,
		"totalCountries": 13,
		"totalStates": 52,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 1972,
		"schedulePercent": 0.714342,
		"totalGuests": 940,
		"guestPercent": 0.809826,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "Avant Gardner EARLY SHOW",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 101
	},
	{
		"id": "3adb802",
		"email": "alyssa@theservicemusic.com",
		"name": "Alyssa",
		"createdDate": "2022-05-27 14:30:17",
		"totalOrganizations": 1,
		"totalTours": 9,
		"totalDays": 536,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 296,
		"schedulePercent": 0.205933,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "88204e0",
		"email": "claudia.cottee@teglive.com.au",
		"name": "Claudia",
		"createdDate": "2019-01-17 00:36:10",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 70,
		"totalCountries": 5,
		"totalStates": 10,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 679,
		"schedulePercent": 0.371871,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "889a5f5",
		"email": "Roadiechad@gmail.com",
		"name": "Chad",
		"createdDate": "2016-08-26 17:44:37",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 77,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 339,
		"schedulePercent": 0.227122,
		"totalGuests": 92,
		"guestPercent": 0.466163,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Harrah's Cherokee Casino Resort",
		"mostGuestCity": "Cherokee",
		"mostGuestAmount": 30
	},
	{
		"id": "77f3037",
		"email": "DRUMMERTROY@YAHOO.COM",
		"name": "TROY PATRICK",
		"createdDate": "2023-03-31 15:00:55",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 22,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 9,
		"eventPercent": 0.0607866,
		"totalScheduleItems": 57,
		"schedulePercent": 0.0599921,
		"totalGuests": 26,
		"guestPercent": 0.381274,
		"mostGuestDate": "2023-04-17 00:00:00",
		"mostGuestName": "Whisky a Go Go",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 16
	},
	{
		"id": "03822a4",
		"email": "princedaddie420@gmail.com",
		"name": "Luis",
		"createdDate": "2022-04-05 22:37:48",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 129,
		"totalCountries": 1,
		"totalStates": 31,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 745,
		"schedulePercent": 0.400079,
		"totalGuests": 381,
		"guestPercent": 0.645875,
		"mostGuestDate": "2023-11-21 00:00:00",
		"mostGuestName": "Regent Theater DTLA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 44
	},
	{
		"id": "5335c90",
		"email": "jermaine267@gmail.com",
		"name": "Jermaine",
		"createdDate": "2017-09-25 14:42:50",
		"totalOrganizations": 14,
		"totalTours": 10,
		"totalDays": 1112,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 171,
		"eventPercent": 0.75394,
		"totalScheduleItems": 1709,
		"schedulePercent": 0.667196,
		"totalGuests": 1363,
		"guestPercent": 0.878956,
		"mostGuestDate": "2023-11-06 00:00:00",
		"mostGuestName": "Avant Gardner",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 124
	},
	{
		"id": "c42cd26",
		"email": "michael.vincent@jurassicquest.com",
		"name": "Michael",
		"createdDate": "2023-04-05 19:47:41",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 810,
		"totalCountries": 3,
		"totalStates": 42,
		"totalEvents": 478,
		"eventPercent": 0.971659,
		"totalScheduleItems": 5103,
		"schedulePercent": 0.96517,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "61f2210",
		"email": "t.berkermann@feinplan.de",
		"name": "Thomas",
		"createdDate": "2018-05-26 20:53:14",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 254,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 1571,
		"schedulePercent": 0.63475,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8766cc3",
		"email": "keithyancsurak@gmail.com",
		"name": "Keith",
		"createdDate": "2015-04-29 21:21:59",
		"totalOrganizations": 16,
		"totalTours": 23,
		"totalDays": 416,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 174,
		"eventPercent": 0.759502,
		"totalScheduleItems": 1589,
		"schedulePercent": 0.638988,
		"totalGuests": 168,
		"guestPercent": 0.528804,
		"mostGuestDate": "2023-05-12 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 44
	},
	{
		"id": "12a84cc",
		"email": "floz.panseri@gmail.com",
		"name": "Floz",
		"createdDate": "2018-04-16 05:00:58",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 69,
		"totalCountries": 7,
		"totalStates": 23,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 210,
		"schedulePercent": 0.156006,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "109fba0",
		"email": "stevewarrenband@yahoo.com",
		"name": "Steve",
		"createdDate": "2020-01-30 10:41:21",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 334,
		"totalCountries": 1,
		"totalStates": 39,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 599,
		"schedulePercent": 0.340352,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-02-19 00:00:00",
		"mostGuestName": "Hangar Theatre",
		"mostGuestCity": "Ithaca",
		"mostGuestAmount": 2
	},
	{
		"id": "93acd68",
		"email": "josh@josh-smith.co",
		"name": "Josh",
		"createdDate": "2018-12-24 09:27:42",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 97,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 781,
		"schedulePercent": 0.413058,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "82574a6",
		"email": "gunnar@kulturservice.no",
		"name": "Gunnar",
		"createdDate": "2017-07-05 10:08:48",
		"totalOrganizations": 14,
		"totalTours": 2,
		"totalDays": 38,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 263,
		"schedulePercent": 0.187922,
		"totalGuests": 38,
		"guestPercent": 0.402198,
		"mostGuestDate": "2023-06-09 00:00:00",
		"mostGuestName": "Kulturfabrikken",
		"mostGuestCity": "Kristiansund",
		"mostGuestAmount": 12
	},
	{
		"id": "411131b",
		"email": "gregduncan.toursupport@gmail.com",
		"name": "Greg",
		"createdDate": "2014-01-13 17:23:25",
		"totalOrganizations": 13,
		"totalTours": 13,
		"totalDays": 374,
		"totalCountries": 8,
		"totalStates": 17,
		"totalEvents": 154,
		"eventPercent": 0.71368,
		"totalScheduleItems": 2201,
		"schedulePercent": 0.750099,
		"totalGuests": 938,
		"guestPercent": 0.808767,
		"mostGuestDate": "2023-08-17 00:00:00",
		"mostGuestName": "Rebellion",
		"mostGuestCity": "Manchester",
		"mostGuestAmount": 52
	},
	{
		"id": "f4a986e",
		"email": "jake@17ten.co",
		"name": "Jacob",
		"createdDate": "2023-02-08 22:05:16",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 333,
		"totalCountries": 3,
		"totalStates": 28,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 543,
		"schedulePercent": 0.318633,
		"totalGuests": 50,
		"guestPercent": 0.421798,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 30
	},
	{
		"id": "477fde3",
		"email": "brad.sands@redlightmanagement.com",
		"name": "Brad",
		"createdDate": "2017-05-01 18:05:06",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 117,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 802,
		"schedulePercent": 0.421269,
		"totalGuests": 74,
		"guestPercent": 0.449212,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "Fox Theater",
		"mostGuestCity": "Oakland",
		"mostGuestAmount": 51
	},
	{
		"id": "4dc49e1",
		"email": "makayamccraven@gmail.com",
		"name": "Makaya",
		"createdDate": "2018-05-24 19:59:01",
		"totalOrganizations": 6,
		"totalTours": 12,
		"totalDays": 668,
		"totalCountries": 10,
		"totalStates": 29,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 940,
		"schedulePercent": 0.462588,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Teatro Romano di Fiesole",
		"mostGuestCity": "Fiesole",
		"mostGuestAmount": 2
	},
	{
		"id": "bd6f4de",
		"email": "cristasimirigliamgmt@gmail.com",
		"name": "Crista",
		"createdDate": "2016-06-16 14:53:13",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 29,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 2,
		"eventPercent": 0.0140379,
		"totalScheduleItems": 46,
		"schedulePercent": 0.0516488,
		"totalGuests": 16,
		"guestPercent": 0.361012,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "Big White Ski Resort",
		"mostGuestCity": "Kelowna",
		"mostGuestAmount": 13
	},
	{
		"id": "b169b88",
		"email": "wunderlich.amaro@extern.semmel.de",
		"name": "Amaro",
		"createdDate": "2023-04-21 16:33:50",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 66,
		"totalCountries": 10,
		"totalStates": 15,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 677,
		"schedulePercent": 0.371342,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "484dd59",
		"email": "ellie@thecultnation.com",
		"name": "Ellie",
		"createdDate": "2022-06-10 18:18:18",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 202,
		"totalCountries": 8,
		"totalStates": 33,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 1225,
		"schedulePercent": 0.546683,
		"totalGuests": 1042,
		"guestPercent": 0.831148,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Irving Plaza",
		"mostGuestCity": "New York",
		"mostGuestAmount": 79
	},
	{
		"id": "03e034f",
		"email": "Jamie.Younger@wmg.com",
		"name": "Jamie",
		"createdDate": "2017-06-01 20:05:49",
		"totalOrganizations": 52,
		"totalTours": 48,
		"totalDays": 15580,
		"totalCountries": 15,
		"totalStates": 89,
		"totalEvents": 2081,
		"eventPercent": 0.997484,
		"totalScheduleItems": 3392,
		"schedulePercent": 0.887035,
		"totalGuests": 511,
		"guestPercent": 0.695669,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "South Fork Dirt Riders",
		"mostGuestCity": "Taylorville",
		"mostGuestAmount": 29
	},
	{
		"id": "523f463",
		"email": "gerrywindus@gmail.com",
		"name": "Gerry",
		"createdDate": "2022-04-22 22:20:19",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 358,
		"totalCountries": 13,
		"totalStates": 71,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 1613,
		"schedulePercent": 0.645213,
		"totalGuests": 620,
		"guestPercent": 0.731161,
		"mostGuestDate": "2023-06-07 00:00:00",
		"mostGuestName": "Verti Music Hall",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 61
	},
	{
		"id": "7533bd3",
		"email": "damriel@gmail.com",
		"name": "Aleksi",
		"createdDate": "2022-04-13 08:38:48",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 107,
		"totalCountries": 15,
		"totalStates": 26,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 818,
		"schedulePercent": 0.425904,
		"totalGuests": 214,
		"guestPercent": 0.559264,
		"mostGuestDate": "2023-01-26 00:00:00",
		"mostGuestName": "Underworld",
		"mostGuestCity": "London",
		"mostGuestAmount": 19
	},
	{
		"id": "5797bdc",
		"email": "stefan@unitedproductions.se",
		"name": "Stefan",
		"createdDate": "2022-04-18 08:42:19",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 304,
		"totalCountries": 10,
		"totalStates": 25,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 1657,
		"schedulePercent": 0.654748,
		"totalGuests": 740,
		"guestPercent": 0.762151,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Slottsskogsvallen",
		"mostGuestCity": "GÃ¶teborg",
		"mostGuestAmount": 72
	},
	{
		"id": "e705892",
		"email": "adam@OneTwentyOne.live",
		"name": "Adam",
		"createdDate": "2022-10-17 17:18:59",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 133,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 506,
		"schedulePercent": 0.301814,
		"totalGuests": 352,
		"guestPercent": 0.631042,
		"mostGuestDate": "2023-03-29 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 41
	},
	{
		"id": "b90c12f",
		"email": "gbellon@me.com",
		"name": "Gregg",
		"createdDate": "2013-09-30 23:05:33",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 68,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 443,
		"schedulePercent": 0.274136,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-09-11 00:00:00",
		"mostGuestName": "Warner Theatre",
		"mostGuestCity": "Washington",
		"mostGuestAmount": 2
	},
	{
		"id": "be3f93e",
		"email": "brahmanbusinessmail@gmail.com",
		"name": "Karen",
		"createdDate": "2022-06-22 21:08:36",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 138,
		"totalCountries": 10,
		"totalStates": 40,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 870,
		"schedulePercent": 0.442988,
		"totalGuests": 201,
		"guestPercent": 0.55198,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "White Oak Music Hall",
		"mostGuestCity": "Houston",
		"mostGuestAmount": 34
	},
	{
		"id": "b574c5a",
		"email": "tourmanagement@icloud.com",
		"name": "Chris",
		"createdDate": "2014-11-23 13:59:40",
		"totalOrganizations": 18,
		"totalTours": 17,
		"totalDays": 323,
		"totalCountries": 24,
		"totalStates": 74,
		"totalEvents": 172,
		"eventPercent": 0.755661,
		"totalScheduleItems": 3218,
		"schedulePercent": 0.871275,
		"totalGuests": 345,
		"guestPercent": 0.62601,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "Kings Theatre",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 34
	},
	{
		"id": "a0c42b9",
		"email": "kamal@sleepercellproductions.co.uk",
		"name": "Kamal",
		"createdDate": "2019-09-02 21:05:17",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 136,
		"totalCountries": 9,
		"totalStates": 20,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 674,
		"schedulePercent": 0.37015,
		"totalGuests": 63,
		"guestPercent": 0.437558,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Union Chapel",
		"mostGuestCity": "London",
		"mostGuestAmount": 37
	},
	{
		"id": "73b38ef",
		"email": "fhtourmanagement@outlook.com",
		"name": "Felicity",
		"createdDate": "2018-01-19 17:42:11",
		"totalOrganizations": 55,
		"totalTours": 51,
		"totalDays": 1082,
		"totalCountries": 27,
		"totalStates": 99,
		"totalEvents": 468,
		"eventPercent": 0.9706,
		"totalScheduleItems": 5285,
		"schedulePercent": 0.969673,
		"totalGuests": 758,
		"guestPercent": 0.766256,
		"mostGuestDate": "2023-11-29 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 222
	},
	{
		"id": "a415af7",
		"email": "graham@synapsedesign.ca",
		"name": "Graham",
		"createdDate": "2015-03-01 03:10:49",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 553,
		"totalCountries": 13,
		"totalStates": 63,
		"totalEvents": 174,
		"eventPercent": 0.759502,
		"totalScheduleItems": 3029,
		"schedulePercent": 0.853662,
		"totalGuests": 878,
		"guestPercent": 0.794067,
		"mostGuestDate": "2023-03-29 00:00:00",
		"mostGuestName": "OVO Arena",
		"mostGuestCity": "London",
		"mostGuestAmount": 104
	},
	{
		"id": "0f709fa",
		"email": "audiobyeamon@gmail.com",
		"name": "Eamon",
		"createdDate": "2020-01-15 02:14:56",
		"totalOrganizations": 14,
		"totalTours": 38,
		"totalDays": 690,
		"totalCountries": 12,
		"totalStates": 32,
		"totalEvents": 293,
		"eventPercent": 0.911005,
		"totalScheduleItems": 3248,
		"schedulePercent": 0.873924,
		"totalGuests": 163,
		"guestPercent": 0.525493,
		"mostGuestDate": "2023-03-14 00:00:00",
		"mostGuestName": "Underworld",
		"mostGuestCity": "London",
		"mostGuestAmount": 23
	},
	{
		"id": "a06fcc9",
		"email": "omar@iconconcerts.com",
		"name": "Omar",
		"createdDate": "2022-08-23 17:53:40",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 825,
		"totalCountries": 10,
		"totalStates": 62,
		"totalEvents": 269,
		"eventPercent": 0.893789,
		"totalScheduleItems": 3298,
		"schedulePercent": 0.877367,
		"totalGuests": 922,
		"guestPercent": 0.804529,
		"mostGuestDate": "2023-11-26 00:00:00",
		"mostGuestName": "Toyota Arena",
		"mostGuestCity": "Ontario",
		"mostGuestAmount": 176
	},
	{
		"id": "26be63b",
		"email": "isabella@headstomp.com",
		"name": "Isabella \"Morot\"",
		"createdDate": "2016-11-18 12:19:13",
		"totalOrganizations": 67,
		"totalTours": 47,
		"totalDays": 554,
		"totalCountries": 10,
		"totalStates": 55,
		"totalEvents": 433,
		"eventPercent": 0.965038,
		"totalScheduleItems": 3632,
		"schedulePercent": 0.902794,
		"totalGuests": 302,
		"guestPercent": 0.603496,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Goteborgs Konserthus Big Hall",
		"mostGuestCity": "Goteborg",
		"mostGuestAmount": 48
	},
	{
		"id": "2f39ff6",
		"email": "mediumbuildxoxo@gmail.com",
		"name": "Nicholas",
		"createdDate": "2023-08-14 18:12:08",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 33,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 149,
		"schedulePercent": 0.118925,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a0bcf9e",
		"email": "mandolinorange@fiveheadentertainment.com",
		"name": "Sean ",
		"createdDate": "2019-01-10 16:38:49",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 139,
		"totalCountries": 2,
		"totalStates": 31,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 784,
		"schedulePercent": 0.41425,
		"totalGuests": 89,
		"guestPercent": 0.462323,
		"mostGuestDate": "2023-08-30 00:00:00",
		"mostGuestName": "Chautauqua Auditorium",
		"mostGuestCity": "Boulder",
		"mostGuestAmount": 18
	},
	{
		"id": "2117135",
		"email": "amanda@yourgenerationinconcert.com",
		"name": "Amanda",
		"createdDate": "2019-07-22 17:09:22",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 363,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 373,
		"schedulePercent": 0.245266,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0f2c47a",
		"email": "asgresetnikov@gmail.com",
		"name": "Alexander",
		"createdDate": "2019-03-29 06:20:29",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 292,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 2256,
		"schedulePercent": 0.760032,
		"totalGuests": 1325,
		"guestPercent": 0.874719,
		"mostGuestDate": "2023-06-18 00:00:00",
		"mostGuestName": "Red Rocks Park and Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 183
	},
	{
		"id": "12753ce",
		"email": "carlette@headlinejamaica.com",
		"name": "Carlette",
		"createdDate": "2017-07-24 21:18:37",
		"totalOrganizations": 2,
		"totalTours": 28,
		"totalDays": 148,
		"totalCountries": 12,
		"totalStates": 32,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 1007,
		"schedulePercent": 0.48722,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2c8d549",
		"email": "Nathanmcmurdo@gmail.com",
		"name": "Nathan",
		"createdDate": "2021-06-29 01:39:03",
		"totalOrganizations": 7,
		"totalTours": 13,
		"totalDays": 638,
		"totalCountries": 3,
		"totalStates": 50,
		"totalEvents": 235,
		"eventPercent": 0.855516,
		"totalScheduleItems": 4353,
		"schedulePercent": 0.939611,
		"totalGuests": 1255,
		"guestPercent": 0.865846,
		"mostGuestDate": "2023-06-28 00:00:00",
		"mostGuestName": "Rogers Arena",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 178
	},
	{
		"id": "436f412",
		"email": "ambitionisthekey@gmail.com",
		"name": "Sebastien",
		"createdDate": "2023-01-11 21:03:28",
		"totalOrganizations": 1,
		"totalTours": 16,
		"totalDays": 132,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 642,
		"schedulePercent": 0.359423,
		"totalGuests": 157,
		"guestPercent": 0.520196,
		"mostGuestDate": "2023-04-17 00:00:00",
		"mostGuestName": "Fred Kavli Theater",
		"mostGuestCity": "Thousand Oaks",
		"mostGuestAmount": 25
	},
	{
		"id": "b327d25",
		"email": "Cp@1065mgmt.com",
		"name": "CP",
		"createdDate": "2023-10-10 22:43:34",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 25,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 236,
		"schedulePercent": 0.172295,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "714fcd4",
		"email": "saschaenns@islandnet.com",
		"name": "Sascha",
		"createdDate": "2023-02-01 19:20:02",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 96,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 584,
		"schedulePercent": 0.333731,
		"totalGuests": 358,
		"guestPercent": 0.634221,
		"mostGuestDate": "2023-03-09 00:00:00",
		"mostGuestName": "Commodore Ballroom",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 82
	},
	{
		"id": "00e9670",
		"email": "sofielundmark@icloud.com",
		"name": "Sofie",
		"createdDate": "2022-08-31 15:30:41",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 221,
		"totalCountries": 4,
		"totalStates": 17,
		"totalEvents": 145,
		"eventPercent": 0.688518,
		"totalScheduleItems": 1211,
		"schedulePercent": 0.542842,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ab19fec",
		"email": "kylierebeccaphotography@gmail.com",
		"name": "Kylie",
		"createdDate": "2018-09-24 06:57:33",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 152,
		"totalCountries": 7,
		"totalStates": 36,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 779,
		"schedulePercent": 0.412396,
		"totalGuests": 244,
		"guestPercent": 0.575288,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "Music Hall of Williamsburg",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 33
	},
	{
		"id": "d5487f2",
		"email": "nadia@12wired.com",
		"name": "Nadia",
		"createdDate": "2023-07-06 04:37:06",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 39,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 145,
		"schedulePercent": 0.116144,
		"totalGuests": 23,
		"guestPercent": 0.37452,
		"mostGuestDate": "2023-08-13 00:00:00",
		"mostGuestName": "Gramercy Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 9
	},
	{
		"id": "fac9c77",
		"email": "hb@loyaltmgmt.com",
		"name": "Henry",
		"createdDate": "2022-10-06 00:11:37",
		"totalOrganizations": 5,
		"totalTours": 16,
		"totalDays": 590,
		"totalCountries": 6,
		"totalStates": 51,
		"totalEvents": 174,
		"eventPercent": 0.759502,
		"totalScheduleItems": 2269,
		"schedulePercent": 0.761753,
		"totalGuests": 784,
		"guestPercent": 0.773408,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 68
	},
	{
		"id": "0752a5a",
		"email": "lisa@ellpee.com.au",
		"name": "Lisa",
		"createdDate": "2018-09-08 12:37:28",
		"totalOrganizations": 25,
		"totalTours": 33,
		"totalDays": 714,
		"totalCountries": 6,
		"totalStates": 18,
		"totalEvents": 261,
		"eventPercent": 0.886902,
		"totalScheduleItems": 2536,
		"schedulePercent": 0.797113,
		"totalGuests": 724,
		"guestPercent": 0.758443,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "Palais Theatre",
		"mostGuestCity": "St Kilda",
		"mostGuestAmount": 94
	},
	{
		"id": "2990fc7",
		"email": "ivanbarry13@gmail.com",
		"name": "Ivan",
		"createdDate": "2017-09-11 02:23:45",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 363,
		"totalCountries": 10,
		"totalStates": 40,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 2275,
		"schedulePercent": 0.76268,
		"totalGuests": 1000,
		"guestPercent": 0.823864,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Cadence Bank Amphitheatre at Chastain Park",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 67
	},
	{
		"id": "19004ca",
		"email": "fletcher.lyon@redlightmanagement.com",
		"name": "Fletcher",
		"createdDate": "2022-04-13 21:30:48",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 709,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 2254,
		"schedulePercent": 0.759237,
		"totalGuests": 212,
		"guestPercent": 0.558469,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "Thompson Boling Arena",
		"mostGuestCity": "Knoxville",
		"mostGuestAmount": 47
	},
	{
		"id": "ae16b66",
		"email": "jamila@made.no",
		"name": "Jamila",
		"createdDate": "2023-06-20 13:38:36",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 65,
		"totalCountries": 5,
		"totalStates": 6,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 430,
		"schedulePercent": 0.268176,
		"totalGuests": 111,
		"guestPercent": 0.483115,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "Foynhagen",
		"mostGuestCity": "TÃ¸nsberg",
		"mostGuestAmount": 23
	},
	{
		"id": "8fcea78",
		"email": "akay.knight@gmail.com",
		"name": "Anna",
		"createdDate": "2015-10-21 03:18:05",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 443,
		"totalCountries": 10,
		"totalStates": 17,
		"totalEvents": 133,
		"eventPercent": 0.656602,
		"totalScheduleItems": 1481,
		"schedulePercent": 0.609456,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "08db8c2",
		"email": "m.mahar43@gmail.com",
		"name": "Michael",
		"createdDate": "2013-02-15 16:28:32",
		"totalOrganizations": 9,
		"totalTours": 14,
		"totalDays": 264,
		"totalCountries": 15,
		"totalStates": 59,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 1900,
		"schedulePercent": 0.704013,
		"totalGuests": 499,
		"guestPercent": 0.691432,
		"mostGuestDate": "2023-06-04 00:00:00",
		"mostGuestName": "AFAS Live",
		"mostGuestCity": "Amsterdam",
		"mostGuestAmount": 49
	},
	{
		"id": "c24fb20",
		"email": "luscombe.james@gmail.com",
		"name": "James",
		"createdDate": "2015-11-11 03:33:55",
		"totalOrganizations": 37,
		"totalTours": 80,
		"totalDays": 1930,
		"totalCountries": 23,
		"totalStates": 86,
		"totalEvents": 549,
		"eventPercent": 0.97934,
		"totalScheduleItems": 7545,
		"schedulePercent": 0.991392,
		"totalGuests": 2406,
		"guestPercent": 0.951,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "The Enmore Theatre",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 111
	},
	{
		"id": "8c689cd",
		"email": "mike@subversiveinc.com",
		"name": "Mike",
		"createdDate": "2010-10-01 21:23:48",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 81,
		"totalCountries": 9,
		"totalStates": 21,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 361,
		"schedulePercent": 0.238511,
		"totalGuests": 176,
		"guestPercent": 0.533969,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Carre Des Docks",
		"mostGuestCity": "Le Havre",
		"mostGuestAmount": 30
	},
	{
		"id": "6f56ce0",
		"email": "chris@monotoneinc.com",
		"name": "Christopher",
		"createdDate": "2014-07-22 21:09:27",
		"totalOrganizations": 32,
		"totalTours": 32,
		"totalDays": 1014,
		"totalCountries": 21,
		"totalStates": 85,
		"totalEvents": 333,
		"eventPercent": 0.934181,
		"totalScheduleItems": 5077,
		"schedulePercent": 0.963978,
		"totalGuests": 2290,
		"guestPercent": 0.947027,
		"mostGuestDate": "2023-01-12 00:00:00",
		"mostGuestName": "Riverside Revival Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 149
	},
	{
		"id": "fbb3c9e",
		"email": "band@proximaparadamusic.com",
		"name": "Kevin",
		"createdDate": "2019-05-20 18:06:21",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 74,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 195,
		"schedulePercent": 0.147265,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "Valley Bar",
		"mostGuestCity": "Phoenix",
		"mostGuestAmount": 1
	},
	{
		"id": "8e344fb",
		"email": "thomassm57@gmail.com",
		"name": "Thomas",
		"createdDate": "2022-05-27 00:11:21",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 183,
		"totalCountries": 8,
		"totalStates": 22,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 298,
		"schedulePercent": 0.207257,
		"totalGuests": 77,
		"guestPercent": 0.452523,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "Slim Jim's Liquor Store",
		"mostGuestCity": "London",
		"mostGuestAmount": 73
	},
	{
		"id": "abd0336",
		"email": "kennye433@yahoo.com",
		"name": "Kenny",
		"createdDate": "2015-06-15 16:04:20",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 25,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 409,
		"schedulePercent": 0.261157,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "81f898a",
		"email": "rebellious1photo@gmail.com",
		"name": "Kaddy ",
		"createdDate": "2022-07-07 16:09:55",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 33,
		"totalCountries": 5,
		"totalStates": 10,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 166,
		"schedulePercent": 0.130314,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5611cfa",
		"email": "stefan@stuff-music.de",
		"name": "Stefan",
		"createdDate": "2018-12-12 08:09:57",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 123,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 511,
		"schedulePercent": 0.304595,
		"totalGuests": 82,
		"guestPercent": 0.457688,
		"mostGuestDate": "2023-01-19 00:00:00",
		"mostGuestName": "Admiralspalast",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 7
	},
	{
		"id": "d2a48f7",
		"email": "paul@pmlbusinessmgmt.com",
		"name": "Paul",
		"createdDate": "2021-08-18 21:13:25",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 54,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 146,
		"schedulePercent": 0.117071,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "70b1e60",
		"email": "jordan@relationshipmusic.com",
		"name": "Jordan",
		"createdDate": "2015-02-10 17:35:20",
		"totalOrganizations": 17,
		"totalTours": 14,
		"totalDays": 1615,
		"totalCountries": 9,
		"totalStates": 67,
		"totalEvents": 410,
		"eventPercent": 0.960005,
		"totalScheduleItems": 7757,
		"schedulePercent": 0.992716,
		"totalGuests": 2876,
		"guestPercent": 0.967554,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Dos Equis Pavilion",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 155
	},
	{
		"id": "bb406a9",
		"email": "p_a_marie@yahoo.fr",
		"name": "Pierre Alexandre",
		"createdDate": "2014-06-04 11:36:47",
		"totalOrganizations": 10,
		"totalTours": 8,
		"totalDays": 223,
		"totalCountries": 13,
		"totalStates": 24,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 1475,
		"schedulePercent": 0.607999,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fe90d3f",
		"email": "ldoyon@gestev.com",
		"name": "Lucie",
		"createdDate": "2022-01-07 19:16:36",
		"totalOrganizations": 35,
		"totalTours": 18,
		"totalDays": 507,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 472,
		"eventPercent": 0.970997,
		"totalScheduleItems": 2212,
		"schedulePercent": 0.751689,
		"totalGuests": 5276,
		"guestPercent": 0.993378,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "ThÃ©Ã¢tre St-Denis",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 204
	},
	{
		"id": "3cf9431",
		"email": "fiddlerpsm@networkstours.com",
		"name": "Abby",
		"createdDate": "2021-10-09 19:49:15",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 139,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 990,
		"schedulePercent": 0.480201,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d628319",
		"email": "yannis.sada@orange.fr",
		"name": "Yannis",
		"createdDate": "2013-03-18 14:18:44",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 573,
		"totalCountries": 4,
		"totalStates": 18,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 1142,
		"schedulePercent": 0.524169,
		"totalGuests": 91,
		"guestPercent": 0.465236,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "ZENITH PYRENEES",
		"mostGuestCity": "Pau",
		"mostGuestAmount": 16
	},
	{
		"id": "329f6a4",
		"email": "costanza.bizzarri@gmail.com",
		"name": "Costanza",
		"createdDate": "2022-01-05 18:45:03",
		"totalOrganizations": 8,
		"totalTours": 20,
		"totalDays": 570,
		"totalCountries": 17,
		"totalStates": 74,
		"totalEvents": 228,
		"eventPercent": 0.845848,
		"totalScheduleItems": 3727,
		"schedulePercent": 0.908356,
		"totalGuests": 998,
		"guestPercent": 0.823202,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 104
	},
	{
		"id": "7bd3ab2",
		"email": "Tillmann@schulzundso.de",
		"name": "Tillmann",
		"createdDate": "2023-05-10 11:11:36",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 88,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 536,
		"schedulePercent": 0.315455,
		"totalGuests": 774,
		"guestPercent": 0.770494,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "Mercedes-Benz Arena Berlin",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 154
	},
	{
		"id": "1190f39",
		"email": "gailboyd@gailboyd.com",
		"name": "Gail",
		"createdDate": "2019-09-28 04:26:37",
		"totalOrganizations": 8,
		"totalTours": 23,
		"totalDays": 156,
		"totalCountries": 7,
		"totalStates": 33,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 702,
		"schedulePercent": 0.381274,
		"totalGuests": 166,
		"guestPercent": 0.52748,
		"mostGuestDate": "2023-01-20 00:00:00",
		"mostGuestName": "Walt Disney Concert Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 23
	},
	{
		"id": "efabb23",
		"email": "info@godownbelieving.com",
		"name": "Allen",
		"createdDate": "2017-08-01 06:24:58",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 163,
		"totalCountries": 14,
		"totalStates": 55,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1459,
		"schedulePercent": 0.605218,
		"totalGuests": 45,
		"guestPercent": 0.412925,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "Cynthia Woods Mitchell Pavilion",
		"mostGuestCity": "The Woodlands",
		"mostGuestAmount": 10
	},
	{
		"id": "2d45967",
		"email": "soundmanbud@gmail.com",
		"name": "Budd ",
		"createdDate": "2012-07-19 06:50:59",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 120,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 552,
		"schedulePercent": 0.322606,
		"totalGuests": 217,
		"guestPercent": 0.560853,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Russel and Roots",
		"mostGuestCity": "Surrey",
		"mostGuestAmount": 48
	},
	{
		"id": "b767be0",
		"email": "Georgios.Kalafatidis@livenation.se",
		"name": "Georgios",
		"createdDate": "2019-08-28 06:40:46",
		"totalOrganizations": 60,
		"totalTours": 39,
		"totalDays": 655,
		"totalCountries": 9,
		"totalStates": 39,
		"totalEvents": 517,
		"eventPercent": 0.976162,
		"totalScheduleItems": 3216,
		"schedulePercent": 0.87101,
		"totalGuests": 680,
		"guestPercent": 0.748378,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "Mosebacketerrassen",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 66
	},
	{
		"id": "7d6eee4",
		"email": "matt@slowdripshows.com",
		"name": "Matt",
		"createdDate": "2019-07-12 15:09:27",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 337,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 432,
		"schedulePercent": 0.269103,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3386e6d",
		"email": "hayleyfrancesm@gmail.com",
		"name": "Hayley ",
		"createdDate": "2012-06-12 05:38:05",
		"totalOrganizations": 9,
		"totalTours": 5,
		"totalDays": 80,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 321,
		"schedulePercent": 0.218779,
		"totalGuests": 246,
		"guestPercent": 0.576612,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Palais Theatre",
		"mostGuestCity": "St Kilda",
		"mostGuestAmount": 51
	},
	{
		"id": "219006c",
		"email": "creyes.management@gmail.com",
		"name": "Christina",
		"createdDate": "2021-10-08 12:01:11",
		"totalOrganizations": 10,
		"totalTours": 9,
		"totalDays": 553,
		"totalCountries": 2,
		"totalStates": 35,
		"totalEvents": 159,
		"eventPercent": 0.726394,
		"totalScheduleItems": 3210,
		"schedulePercent": 0.870481,
		"totalGuests": 5729,
		"guestPercent": 0.994703,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Dickies Arena",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 433
	},
	{
		"id": "a54b513",
		"email": "alicia.blake@gmail.com",
		"name": "Alicia",
		"createdDate": "2011-07-27 04:57:33",
		"totalOrganizations": 14,
		"totalTours": 19,
		"totalDays": 319,
		"totalCountries": 13,
		"totalStates": 56,
		"totalEvents": 170,
		"eventPercent": 0.752086,
		"totalScheduleItems": 2474,
		"schedulePercent": 0.789432,
		"totalGuests": 2509,
		"guestPercent": 0.954311,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "The Armory",
		"mostGuestCity": "Minneapolis",
		"mostGuestAmount": 76
	},
	{
		"id": "d7d240f",
		"email": "janelle@tourmusiclive.com",
		"name": "Janelle",
		"createdDate": "2019-04-04 15:06:53",
		"totalOrganizations": 17,
		"totalTours": 31,
		"totalDays": 226,
		"totalCountries": 12,
		"totalStates": 47,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 1814,
		"schedulePercent": 0.687591,
		"totalGuests": 1294,
		"guestPercent": 0.871143,
		"mostGuestDate": "2023-05-07 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 75
	},
	{
		"id": "af5608a",
		"email": "jberrios4387@gmail.com",
		"name": "Javier",
		"createdDate": "2023-01-10 16:40:47",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 701,
		"totalCountries": 7,
		"totalStates": 14,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 1057,
		"schedulePercent": 0.500066,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ea9b4f0",
		"email": "joeyarias81@gmail.com",
		"name": "Joseph",
		"createdDate": "2016-07-19 12:13:16",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 141,
		"totalCountries": 3,
		"totalStates": 25,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 946,
		"schedulePercent": 0.464177,
		"totalGuests": 104,
		"guestPercent": 0.478877,
		"mostGuestDate": "2023-06-20 00:00:00",
		"mostGuestName": "Medina Entertainment Center",
		"mostGuestCity": "Medina",
		"mostGuestAmount": 13
	},
	{
		"id": "0f020f5",
		"email": "woalights@gmail.com",
		"name": "Steve",
		"createdDate": "2022-03-15 16:20:09",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 146,
		"totalCountries": 3,
		"totalStates": 36,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 827,
		"schedulePercent": 0.429215,
		"totalGuests": 60,
		"guestPercent": 0.434644,
		"mostGuestDate": "2023-05-26 00:00:00",
		"mostGuestName": "Mahaffey Theater",
		"mostGuestCity": "St. Petersburg",
		"mostGuestAmount": 37
	},
	{
		"id": "b260a34",
		"email": "rosswjohnson@gmail.com",
		"name": "Ross",
		"createdDate": "2018-09-13 19:36:48",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 169,
		"totalCountries": 3,
		"totalStates": 36,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1592,
		"schedulePercent": 0.640313,
		"totalGuests": 653,
		"guestPercent": 0.739902,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 40
	},
	{
		"id": "d32e80b",
		"email": "matt@southeast.co.nz",
		"name": "Matt",
		"createdDate": "2018-10-23 05:51:51",
		"totalOrganizations": 10,
		"totalTours": 17,
		"totalDays": 1034,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 435,
		"schedulePercent": 0.270295,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8b0650d",
		"email": "dombresky.tour@gmail.com",
		"name": "Quentin",
		"createdDate": "2023-07-17 22:37:11",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 163,
		"totalCountries": 5,
		"totalStates": 27,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 348,
		"schedulePercent": 0.232155,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "495ce79",
		"email": "naomi.wood@umusic.com",
		"name": "Naomi",
		"createdDate": "2023-04-03 13:01:55",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 61,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 250,
		"schedulePercent": 0.181565,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b7b7b17",
		"email": "ardenvailx@gmail.com",
		"name": "Arden",
		"createdDate": "2023-02-03 16:08:35",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 23,
		"totalCountries": 7,
		"totalStates": 8,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 152,
		"schedulePercent": 0.121971,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-04-04 00:00:00",
		"mostGuestName": "Feierabendhaus der BASF",
		"mostGuestCity": "Ludwigshafen am Rhein",
		"mostGuestAmount": 6
	},
	{
		"id": "33ae14d",
		"email": "lirekassa@gmail.com",
		"name": "&#197;ge",
		"createdDate": "2023-02-04 18:28:32",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 251,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 236,
		"schedulePercent": 0.172295,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2eaa81a",
		"email": "ben@eccles.co.nz",
		"name": "Ben",
		"createdDate": "2022-05-13 00:36:19",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 35,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 213,
		"schedulePercent": 0.158125,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cdb121e",
		"email": "adegolyer@gmail.com",
		"name": "Andrew",
		"createdDate": "2018-01-16 19:48:02",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 751,
		"totalCountries": 4,
		"totalStates": 32,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 665,
		"schedulePercent": 0.367236,
		"totalGuests": 27,
		"guestPercent": 0.38379,
		"mostGuestDate": "2023-01-25 00:00:00",
		"mostGuestName": "Arts Commons",
		"mostGuestCity": "Calgary",
		"mostGuestAmount": 8
	},
	{
		"id": "6e93936",
		"email": "jessmhughes82@gmail.com",
		"name": "Jess",
		"createdDate": "2022-06-05 10:01:28",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 232,
		"totalCountries": 13,
		"totalStates": 29,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 1707,
		"schedulePercent": 0.666269,
		"totalGuests": 1339,
		"guestPercent": 0.876175,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 91
	},
	{
		"id": "679c0f4",
		"email": "thetourmeister@gmail.com",
		"name": "Dan",
		"createdDate": "2015-01-07 17:19:05",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 740,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 173,
		"schedulePercent": 0.134684,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9cc8fef",
		"email": "royaltytour1@gmail.com",
		"name": "Dion",
		"createdDate": "2020-01-20 14:22:19",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 180,
		"totalCountries": 2,
		"totalStates": 31,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 450,
		"schedulePercent": 0.27652,
		"totalGuests": 92,
		"guestPercent": 0.466163,
		"mostGuestDate": "2023-04-02 00:00:00",
		"mostGuestName": "Fillmore Auditorium",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 15
	},
	{
		"id": "4060cb3",
		"email": "jj@helterskelter.dk",
		"name": "JÃ¸rn",
		"createdDate": "2022-12-18 11:02:15",
		"totalOrganizations": 13,
		"totalTours": 19,
		"totalDays": 378,
		"totalCountries": 4,
		"totalStates": 9,
		"totalEvents": 355,
		"eventPercent": 0.944246,
		"totalScheduleItems": 1811,
		"schedulePercent": 0.687459,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "sÃ¸nderborg",
		"mostGuestCity": "SÃ¸nderborg",
		"mostGuestAmount": 4
	},
	{
		"id": "00bf9c0",
		"email": "martinruizmas@gmail.com",
		"name": "Martin",
		"createdDate": "2019-03-14 23:02:56",
		"totalOrganizations": 2,
		"totalTours": 10,
		"totalDays": 729,
		"totalCountries": 10,
		"totalStates": 53,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 1275,
		"schedulePercent": 0.558337,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4d278a1",
		"email": "woodymomix@gmail.com",
		"name": "Woodrow",
		"createdDate": "2015-01-24 16:04:50",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 124,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 645,
		"schedulePercent": 0.360482,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c8dbfd1",
		"email": "ss@lordsofmanagement.com",
		"name": "Scott",
		"createdDate": "2023-07-13 16:30:32",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 54,
		"totalCountries": 7,
		"totalStates": 24,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 172,
		"schedulePercent": 0.133757,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f46050e",
		"email": "BigShineMusic@gmail.com",
		"name": "Dana",
		"createdDate": "2017-01-14 01:32:24",
		"totalOrganizations": 1,
		"totalTours": 69,
		"totalDays": 174,
		"totalCountries": 1,
		"totalStates": 26,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 1064,
		"schedulePercent": 0.502318,
		"totalGuests": 164,
		"guestPercent": 0.526155,
		"mostGuestDate": "2023-05-03 00:00:00",
		"mostGuestName": "Yoshi's Oakland",
		"mostGuestCity": "Oakland",
		"mostGuestAmount": 11
	},
	{
		"id": "677120c",
		"email": "ali@bleedfromwithin.com",
		"name": "Ali",
		"createdDate": "2019-04-23 11:50:27",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 159,
		"totalCountries": 11,
		"totalStates": 46,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 951,
		"schedulePercent": 0.465634,
		"totalGuests": 243,
		"guestPercent": 0.574758,
		"mostGuestDate": "2023-03-21 00:00:00",
		"mostGuestName": "The SSE Arena, Wembley",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 17
	},
	{
		"id": "aaf6a3f",
		"email": "anders@inoutproductions.com",
		"name": "Anders",
		"createdDate": "2014-03-14 20:43:43",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 795,
		"totalCountries": 3,
		"totalStates": 22,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 2200,
		"schedulePercent": 0.749437,
		"totalGuests": 465,
		"guestPercent": 0.678718,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "Zappos Theater",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 40
	},
	{
		"id": "b70c94e",
		"email": "agneta@agnes.se",
		"name": "Agneta",
		"createdDate": "2013-03-18 09:48:11",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 250,
		"totalCountries": 3,
		"totalStates": 8,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 660,
		"schedulePercent": 0.365382,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ad6a9e4",
		"email": "angelskyyoverton@gmail.com",
		"name": "Angel",
		"createdDate": "2023-06-05 20:48:50",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 78,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 578,
		"schedulePercent": 0.331082,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6b0a73a",
		"email": "ryancharles@buckarooviews.com",
		"name": "Ryan ",
		"createdDate": "2022-05-23 18:55:20",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 37,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 1829,
		"schedulePercent": 0.691299,
		"totalGuests": 196,
		"guestPercent": 0.546947,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 64
	},
	{
		"id": "d3f8307",
		"email": "sarahslatonmusic@gmail.com",
		"name": "Sarah",
		"createdDate": "2021-08-24 07:43:45",
		"totalOrganizations": 8,
		"totalTours": 19,
		"totalDays": 586,
		"totalCountries": 13,
		"totalStates": 50,
		"totalEvents": 253,
		"eventPercent": 0.877765,
		"totalScheduleItems": 2995,
		"schedulePercent": 0.850351,
		"totalGuests": 2036,
		"guestPercent": 0.935505,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 86
	},
	{
		"id": "e20592a",
		"email": "Travisalaws@gmail.com",
		"name": "Travis",
		"createdDate": "2013-10-03 12:39:32",
		"totalOrganizations": 8,
		"totalTours": 3,
		"totalDays": 190,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 1432,
		"schedulePercent": 0.599656,
		"totalGuests": 740,
		"guestPercent": 0.762151,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "Abba's House ",
		"mostGuestCity": "Hixson",
		"mostGuestAmount": 37
	},
	{
		"id": "6e47e13",
		"email": "tom@tb-productions.co.uk",
		"name": "Tom",
		"createdDate": "2017-12-29 20:12:14",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 28,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 232,
		"schedulePercent": 0.171103,
		"totalGuests": 253,
		"guestPercent": 0.580718,
		"mostGuestDate": "2023-07-01 00:00:00",
		"mostGuestName": "The Piece Hall",
		"mostGuestCity": "Halifax",
		"mostGuestAmount": 98
	},
	{
		"id": "4fdc01a",
		"email": "janik@productionsgrandv.com",
		"name": "Janik",
		"createdDate": "2022-05-10 18:02:53",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 371,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 378,
		"schedulePercent": 0.247252,
		"totalGuests": 281,
		"guestPercent": 0.595021,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "MTELUS",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 102
	},
	{
		"id": "eea8320",
		"email": "joewilliammusic@gmail.com",
		"name": "Joe",
		"createdDate": "2016-11-04 00:38:13",
		"totalOrganizations": 7,
		"totalTours": 14,
		"totalDays": 185,
		"totalCountries": 15,
		"totalStates": 46,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 830,
		"schedulePercent": 0.430539,
		"totalGuests": 1004,
		"guestPercent": 0.824791,
		"mostGuestDate": "2023-10-04 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 71
	},
	{
		"id": "4511484",
		"email": "THOMAS@REDMANAGEMENT.INFO",
		"name": "THOMAS",
		"createdDate": "2023-05-01 09:08:27",
		"totalOrganizations": 1,
		"totalTours": 6,
		"totalDays": 121,
		"totalCountries": 13,
		"totalStates": 30,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 840,
		"schedulePercent": 0.433982,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bb4fc5c",
		"email": "lisa@platinumtouring.co.uk",
		"name": "Lisa",
		"createdDate": "2022-03-09 16:45:50",
		"totalOrganizations": 4,
		"totalTours": 20,
		"totalDays": 282,
		"totalCountries": 9,
		"totalStates": 14,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 1385,
		"schedulePercent": 0.587869,
		"totalGuests": 341,
		"guestPercent": 0.623758,
		"mostGuestDate": "2023-10-31 00:00:00",
		"mostGuestName": "Royal Albert Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 48
	},
	{
		"id": "2bf9a14",
		"email": "max@agauchedelalune.com",
		"name": "Maxime",
		"createdDate": "2022-05-25 01:29:45",
		"totalOrganizations": 3,
		"totalTours": 10,
		"totalDays": 1007,
		"totalCountries": 7,
		"totalStates": 37,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 882,
		"schedulePercent": 0.447358,
		"totalGuests": 501,
		"guestPercent": 0.692226,
		"mostGuestDate": "2023-05-23 00:00:00",
		"mostGuestName": "Le Trianon",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 26
	},
	{
		"id": "7755f69",
		"email": "jared@mpackent.co",
		"name": "Jared",
		"createdDate": "2019-06-20 04:13:19",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 107,
		"totalCountries": 10,
		"totalStates": 18,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 276,
		"schedulePercent": 0.196001,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "54392c7",
		"email": "jmageau@rocnation.com",
		"name": "Jason",
		"createdDate": "2020-02-13 16:06:04",
		"totalOrganizations": 8,
		"totalTours": 18,
		"totalDays": 528,
		"totalCountries": 18,
		"totalStates": 73,
		"totalEvents": 234,
		"eventPercent": 0.854324,
		"totalScheduleItems": 3082,
		"schedulePercent": 0.859356,
		"totalGuests": 546,
		"guestPercent": 0.707853,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 79
	},
	{
		"id": "90a97bf",
		"email": "mrleonbanks@gmail.com",
		"name": "Leon",
		"createdDate": "2012-12-09 15:55:58",
		"totalOrganizations": 12,
		"totalTours": 12,
		"totalDays": 463,
		"totalCountries": 10,
		"totalStates": 38,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 1777,
		"schedulePercent": 0.680572,
		"totalGuests": 884,
		"guestPercent": 0.795259,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Motorpoint Arena Nottingham",
		"mostGuestCity": "Nottingham",
		"mostGuestAmount": 58
	},
	{
		"id": "5920e5a",
		"email": "hometeamwsp@yahoo.com",
		"name": "Ben",
		"createdDate": "2017-08-19 19:28:28",
		"totalOrganizations": 9,
		"totalTours": 9,
		"totalDays": 144,
		"totalCountries": 3,
		"totalStates": 12,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 506,
		"schedulePercent": 0.301814,
		"totalGuests": 11,
		"guestPercent": 0.345782,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "St. Augustine Amphitheatre",
		"mostGuestCity": "St. Augustine",
		"mostGuestAmount": 5
	},
	{
		"id": "66039b7",
		"email": "fameonfireofficial@gmail.com",
		"name": "Fame On",
		"createdDate": "2023-07-26 20:08:37",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 115,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 630,
		"schedulePercent": 0.354523,
		"totalGuests": 156,
		"guestPercent": 0.518607,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "The Social",
		"mostGuestCity": "Orlando",
		"mostGuestAmount": 26
	},
	{
		"id": "57d3c12",
		"email": "tcbharlow@gmail.com",
		"name": "Brian",
		"createdDate": "2021-11-16 17:37:15",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 246,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 356,
		"schedulePercent": 0.235863,
		"totalGuests": 160,
		"guestPercent": 0.522845,
		"mostGuestDate": "2023-11-26 00:00:00",
		"mostGuestName": "Truist Arena",
		"mostGuestCity": "Highland Heights",
		"mostGuestAmount": 92
	},
	{
		"id": "196bed8",
		"email": "neea@portionboys.fi",
		"name": "Neea",
		"createdDate": "2023-05-10 12:58:32",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 88,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 350,
		"schedulePercent": 0.232949,
		"totalGuests": 50,
		"guestPercent": 0.421798,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "Power Festival",
		"mostGuestCity": "JÃ¤rvenpÃ¤Ã¤",
		"mostGuestAmount": 8
	},
	{
		"id": "16be90d",
		"email": "jez@gigfoottouring.com",
		"name": "Jez",
		"createdDate": "2009-11-23 23:26:31",
		"totalOrganizations": 23,
		"totalTours": 30,
		"totalDays": 484,
		"totalCountries": 13,
		"totalStates": 41,
		"totalEvents": 223,
		"eventPercent": 0.840154,
		"totalScheduleItems": 4173,
		"schedulePercent": 0.932459,
		"totalGuests": 2451,
		"guestPercent": 0.952589,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 178
	},
	{
		"id": "3c6723b",
		"email": "chrisbrunton@mac.com",
		"name": "Chris",
		"createdDate": "2019-10-29 22:57:44",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 107,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 359,
		"schedulePercent": 0.23732,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6e3212c",
		"email": "ysarpong@cityparksfoundation.org",
		"name": "Yaw",
		"createdDate": "2022-05-30 22:32:41",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d437d66",
		"email": "irelandgabriela@gmail.com",
		"name": "Ireland",
		"createdDate": "2023-07-07 19:13:37",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 172,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 275,
		"schedulePercent": 0.195736,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "The Peace Center",
		"mostGuestCity": "Greenville",
		"mostGuestAmount": 7
	},
	{
		"id": "3065c03",
		"email": "spencerjonesaudio@gmail.com",
		"name": "Spencer",
		"createdDate": "2014-11-17 18:29:59",
		"totalOrganizations": 13,
		"totalTours": 19,
		"totalDays": 1049,
		"totalCountries": 22,
		"totalStates": 76,
		"totalEvents": 266,
		"eventPercent": 0.891935,
		"totalScheduleItems": 4299,
		"schedulePercent": 0.937624,
		"totalGuests": 1272,
		"guestPercent": 0.868229,
		"mostGuestDate": "2023-08-29 00:00:00",
		"mostGuestName": "The Bowery Ballroom",
		"mostGuestCity": "New York",
		"mostGuestAmount": 76
	},
	{
		"id": "9475f74",
		"email": "a.mbaye@madoky.com",
		"name": "Abibou",
		"createdDate": "2023-04-21 15:18:00",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 131,
		"totalCountries": 6,
		"totalStates": 15,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 322,
		"schedulePercent": 0.219706,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e7fd95f",
		"email": "henrik@volumemusic.se",
		"name": "Henrik ",
		"createdDate": "2023-03-06 12:17:55",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 93,
		"totalCountries": 3,
		"totalStates": 12,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 540,
		"schedulePercent": 0.317706,
		"totalGuests": 31,
		"guestPercent": 0.390942,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Lollapalooza",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 12
	},
	{
		"id": "adc7a11",
		"email": "rachelbarocio92@gmail.com",
		"name": "Rach&#234;l",
		"createdDate": "2019-05-21 22:54:46",
		"totalOrganizations": 13,
		"totalTours": 14,
		"totalDays": 724,
		"totalCountries": 11,
		"totalStates": 45,
		"totalEvents": 170,
		"eventPercent": 0.752086,
		"totalScheduleItems": 2141,
		"schedulePercent": 0.740564,
		"totalGuests": 1266,
		"guestPercent": 0.867435,
		"mostGuestDate": "2023-11-06 00:00:00",
		"mostGuestName": "Avant Gardner",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 124
	},
	{
		"id": "8177fc8",
		"email": "danielboiv@gmail.com",
		"name": "Daniel",
		"createdDate": "2015-09-24 15:26:49",
		"totalOrganizations": 16,
		"totalTours": 9,
		"totalDays": 487,
		"totalCountries": 12,
		"totalStates": 32,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 708,
		"schedulePercent": 0.383923,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "43fc280",
		"email": "asha@mindsandmakers.co",
		"name": "Asha",
		"createdDate": "2022-04-05 01:40:47",
		"totalOrganizations": 6,
		"totalTours": 9,
		"totalDays": 309,
		"totalCountries": 14,
		"totalStates": 36,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 1926,
		"schedulePercent": 0.707059,
		"totalGuests": 494,
		"guestPercent": 0.68971,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "House of Blues Anaheim",
		"mostGuestCity": "Anaheim",
		"mostGuestAmount": 74
	},
	{
		"id": "17732f0",
		"email": "crhrwork@gmail.com",
		"name": "Christopher",
		"createdDate": "2022-02-14 08:57:14",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 249,
		"totalCountries": 20,
		"totalStates": 50,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 1530,
		"schedulePercent": 0.625083,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "420ec1e",
		"email": "joe@anothermgmtco.com",
		"name": "Joe ",
		"createdDate": "2018-02-20 22:20:19",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 37,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 117,
		"schedulePercent": 0.100914,
		"totalGuests": 205,
		"guestPercent": 0.554231,
		"mostGuestDate": "2023-06-21 00:00:00",
		"mostGuestName": "Cat's Cradle",
		"mostGuestCity": "Carrboro",
		"mostGuestAmount": 26
	},
	{
		"id": "5dc7c57",
		"email": "sue@mikechadwickmgmt.com",
		"name": "Sue",
		"createdDate": "2017-03-03 12:05:10",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 211,
		"totalCountries": 15,
		"totalStates": 53,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1440,
		"schedulePercent": 0.601642,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ccf8cb8",
		"email": "joe01martinez@gmail.com",
		"name": "Jose",
		"createdDate": "2019-05-16 23:10:26",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 449,
		"totalCountries": 11,
		"totalStates": 40,
		"totalEvents": 194,
		"eventPercent": 0.794597,
		"totalScheduleItems": 2380,
		"schedulePercent": 0.77738,
		"totalGuests": 20,
		"guestPercent": 0.36909,
		"mostGuestDate": "2023-08-10 00:00:00",
		"mostGuestName": "Allegiant Stadium",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 20
	},
	{
		"id": "aa7c09d",
		"email": "jeneen.anderson34@gmail.com",
		"name": "Jeneen",
		"createdDate": "2015-08-12 18:27:27",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 224,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 1005,
		"schedulePercent": 0.486426,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-02-07 00:00:00",
		"mostGuestName": "The Recher",
		"mostGuestCity": "Towson",
		"mostGuestAmount": 6
	},
	{
		"id": "ca53f29",
		"email": "yura@kohai.com",
		"name": "Jessica",
		"createdDate": "2022-01-27 04:54:07",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 243,
		"totalCountries": 8,
		"totalStates": 31,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 1156,
		"schedulePercent": 0.528672,
		"totalGuests": 465,
		"guestPercent": 0.678718,
		"mostGuestDate": "2023-01-25 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 67
	},
	{
		"id": "302157b",
		"email": "tomas.zakopal@gmail.com",
		"name": "Tomas",
		"createdDate": "2013-10-14 12:05:54",
		"totalOrganizations": 16,
		"totalTours": 22,
		"totalDays": 430,
		"totalCountries": 19,
		"totalStates": 71,
		"totalEvents": 240,
		"eventPercent": 0.86174,
		"totalScheduleItems": 2833,
		"schedulePercent": 0.835916,
		"totalGuests": 1125,
		"guestPercent": 0.843597,
		"mostGuestDate": "2023-02-14 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 54
	},
	{
		"id": "336bf7b",
		"email": "parkercbeasley@gmail.com",
		"name": "Parker",
		"createdDate": "2020-01-07 23:39:49",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 337,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1680,
		"schedulePercent": 0.659913,
		"totalGuests": 1972,
		"guestPercent": 0.93193,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Dickies Arena",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 433
	},
	{
		"id": "9dbc35f",
		"email": "chris@pinshows.com",
		"name": "Chris",
		"createdDate": "2019-03-27 20:09:19",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 440,
		"totalCountries": 6,
		"totalStates": 35,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 848,
		"schedulePercent": 0.437558,
		"totalGuests": 353,
		"guestPercent": 0.631307,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 71
	},
	{
		"id": "c18e50d",
		"email": "rudy@supermusicgroup.com",
		"name": "Rudy",
		"createdDate": "2018-01-26 19:55:26",
		"totalOrganizations": 13,
		"totalTours": 12,
		"totalDays": 264,
		"totalCountries": 6,
		"totalStates": 35,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 720,
		"schedulePercent": 0.388028,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "44fc63b",
		"email": "support@kulturecity.org",
		"name": "Kulture",
		"createdDate": "2023-03-01 19:42:10",
		"totalOrganizations": 1,
		"totalTours": 28,
		"totalDays": 150,
		"totalCountries": 3,
		"totalStates": 21,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 239,
		"schedulePercent": 0.175209,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2790d24",
		"email": "tourmgr@notp.com",
		"name": "Tour",
		"createdDate": "2016-10-10 13:21:46",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 107,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 590,
		"schedulePercent": 0.33585,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3769285",
		"email": "morganlmessick@gmail.com",
		"name": "Morgan",
		"createdDate": "2022-01-17 20:28:07",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 936,
		"totalCountries": 15,
		"totalStates": 59,
		"totalEvents": 260,
		"eventPercent": 0.885975,
		"totalScheduleItems": 4822,
		"schedulePercent": 0.956165,
		"totalGuests": 1146,
		"guestPercent": 0.848629,
		"mostGuestDate": "2023-03-22 00:00:00",
		"mostGuestName": "Dolby Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 76
	},
	{
		"id": "7507264",
		"email": "josh@subvrt.com",
		"name": "Josh",
		"createdDate": "2022-05-26 05:13:40",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 30,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 2,
		"eventPercent": 0.0140379,
		"totalScheduleItems": 538,
		"schedulePercent": 0.316912,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4a2ee30",
		"email": "fredrik@cometaudio.se",
		"name": "Fredrik",
		"createdDate": "2013-11-08 17:16:49",
		"totalOrganizations": 12,
		"totalTours": 2,
		"totalDays": 149,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 148,
		"schedulePercent": 0.118395,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "041a3e6",
		"email": "Cj@gutsymgmt.com",
		"name": "CJ",
		"createdDate": "2016-01-04 23:20:25",
		"totalOrganizations": 69,
		"totalTours": 27,
		"totalDays": 584,
		"totalCountries": 16,
		"totalStates": 50,
		"totalEvents": 154,
		"eventPercent": 0.71368,
		"totalScheduleItems": 2225,
		"schedulePercent": 0.75447,
		"totalGuests": 993,
		"guestPercent": 0.822143,
		"mostGuestDate": "2023-04-11 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 111
	},
	{
		"id": "725794b",
		"email": "Restlesstours@gmail.com",
		"name": "Boris",
		"createdDate": "2017-10-30 13:37:29",
		"totalOrganizations": 14,
		"totalTours": 13,
		"totalDays": 778,
		"totalCountries": 14,
		"totalStates": 53,
		"totalEvents": 166,
		"eventPercent": 0.74308,
		"totalScheduleItems": 1846,
		"schedulePercent": 0.694478,
		"totalGuests": 309,
		"guestPercent": 0.609058,
		"mostGuestDate": "2023-06-09 00:00:00",
		"mostGuestName": "Irving Plaza",
		"mostGuestCity": "New York",
		"mostGuestAmount": 50
	},
	{
		"id": "12559a5",
		"email": "cole.karaba@gmail.com",
		"name": "Cole",
		"createdDate": "2018-01-10 23:21:44",
		"totalOrganizations": 6,
		"totalTours": 13,
		"totalDays": 677,
		"totalCountries": 19,
		"totalStates": 62,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 2099,
		"schedulePercent": 0.735002,
		"totalGuests": 802,
		"guestPercent": 0.776718,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 57
	},
	{
		"id": "d8e6548",
		"email": "hayleybedrosian@gmail.com",
		"name": "Hayley",
		"createdDate": "2022-05-03 23:10:41",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 85,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 494,
		"schedulePercent": 0.295193,
		"totalGuests": 186,
		"guestPercent": 0.540458,
		"mostGuestDate": "2023-03-10 00:00:00",
		"mostGuestName": "The Ritz Ybor",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 26
	},
	{
		"id": "58821ae",
		"email": "Mariam@dannyzeliskopresents.com",
		"name": "Mariam",
		"createdDate": "2023-05-19 16:17:38",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 254,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 56,
		"schedulePercent": 0.0589326,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "25ade16",
		"email": "pyry.raty@me.com",
		"name": "Pyry",
		"createdDate": "2013-09-27 18:34:41",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 56,
		"totalCountries": 2,
		"totalStates": 1,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 254,
		"schedulePercent": 0.182625,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4acdefe",
		"email": "Hgray88844@aol.com",
		"name": "Doug",
		"createdDate": "2017-01-13 15:13:44",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 347,
		"totalCountries": 1,
		"totalStates": 33,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 572,
		"schedulePercent": 0.328963,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "30e04b0",
		"email": "garethedlindsay@gmail.com",
		"name": "Gareth Ed",
		"createdDate": "2015-01-20 01:56:21",
		"totalOrganizations": 42,
		"totalTours": 25,
		"totalDays": 366,
		"totalCountries": 10,
		"totalStates": 38,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 2161,
		"schedulePercent": 0.74414,
		"totalGuests": 258,
		"guestPercent": 0.583234,
		"mostGuestDate": "2023-04-30 00:00:00",
		"mostGuestName": "Northcote Theatre",
		"mostGuestCity": "Northcote",
		"mostGuestAmount": 32
	},
	{
		"id": "ad9d288",
		"email": "mporter@cityparksfoundation.org",
		"name": "Martin",
		"createdDate": "2023-06-06 15:09:07",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 191,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 1425,
		"schedulePercent": 0.597802,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "38e5049",
		"email": "holger@travtours.com",
		"name": "Holger",
		"createdDate": "2022-11-10 22:21:12",
		"totalOrganizations": 14,
		"totalTours": 11,
		"totalDays": 197,
		"totalCountries": 9,
		"totalStates": 23,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1775,
		"schedulePercent": 0.679778,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e5d0a71",
		"email": "carashaw10@gmail.com",
		"name": "Cara",
		"createdDate": "2014-03-08 16:42:01",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 305,
		"totalCountries": 8,
		"totalStates": 21,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 1183,
		"schedulePercent": 0.535558,
		"totalGuests": 631,
		"guestPercent": 0.734207,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 101
	},
	{
		"id": "f944d7d",
		"email": "gonzo@redlightmanagement.com",
		"name": "Gonzo",
		"createdDate": "2021-12-02 00:59:35",
		"totalOrganizations": 6,
		"totalTours": 18,
		"totalDays": 455,
		"totalCountries": 11,
		"totalStates": 40,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 1039,
		"schedulePercent": 0.494636,
		"totalGuests": 675,
		"guestPercent": 0.745597,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 188
	},
	{
		"id": "bee9ca6",
		"email": "rachael@secretsounds.com",
		"name": "Rachael",
		"createdDate": "2011-06-15 16:15:52",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 192,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 760,
		"schedulePercent": 0.406039,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Cairns Showgrounds",
		"mostGuestCity": "Parramatta Park",
		"mostGuestAmount": 13
	},
	{
		"id": "3f679f2",
		"email": "andrewscottcrow@gmail.com",
		"name": "Andrew",
		"createdDate": "2018-03-14 14:22:49",
		"totalOrganizations": 10,
		"totalTours": 12,
		"totalDays": 484,
		"totalCountries": 11,
		"totalStates": 46,
		"totalEvents": 214,
		"eventPercent": 0.829559,
		"totalScheduleItems": 3883,
		"schedulePercent": 0.917494,
		"totalGuests": 286,
		"guestPercent": 0.596477,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The Aztec Theatre",
		"mostGuestCity": "San Antonio",
		"mostGuestAmount": 70
	},
	{
		"id": "56f3137",
		"email": "jeff.james@rocklightertouring.com",
		"name": "Jeff",
		"createdDate": "2023-03-07 00:47:03",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 139,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 837,
		"schedulePercent": 0.433453,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "287a7b3",
		"email": "Jagbaykr@gmail.com",
		"name": "James",
		"createdDate": "2019-06-27 19:11:29",
		"totalOrganizations": 3,
		"totalTours": 19,
		"totalDays": 517,
		"totalCountries": 1,
		"totalStates": 15,
		"totalEvents": 1,
		"eventPercent": 0.00662164,
		"totalScheduleItems": 538,
		"schedulePercent": 0.316912,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a0a38e6",
		"email": "raweyhrauch@gmail.com",
		"name": "Rebecca",
		"createdDate": "2016-11-08 00:19:41",
		"totalOrganizations": 15,
		"totalTours": 9,
		"totalDays": 1959,
		"totalCountries": 11,
		"totalStates": 36,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 3825,
		"schedulePercent": 0.913389,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f17e4be",
		"email": "bmfproinfo@gmail.com",
		"name": "Amanda",
		"createdDate": "2023-05-30 12:51:19",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 369,
		"totalCountries": 3,
		"totalStates": 15,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 597,
		"schedulePercent": 0.339558,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "970089b",
		"email": "ruth@solidentertainment.co.nz",
		"name": "Ruth",
		"createdDate": "2022-11-02 01:29:35",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 53,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 243,
		"schedulePercent": 0.17799,
		"totalGuests": 250,
		"guestPercent": 0.578599,
		"mostGuestDate": "2023-03-07 00:00:00",
		"mostGuestName": "The Civic",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 76
	},
	{
		"id": "ba78959",
		"email": "pouramandine@gmail.com",
		"name": "Amandine",
		"createdDate": "2019-06-17 15:09:12",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 560,
		"totalCountries": 8,
		"totalStates": 24,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 2297,
		"schedulePercent": 0.765197,
		"totalGuests": 938,
		"guestPercent": 0.808767,
		"mostGuestDate": "2023-07-06 00:00:00",
		"mostGuestName": "Cabaret Sauvage",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 47
	},
	{
		"id": "9e8d8b0",
		"email": "phoebegmillard@gmail.com",
		"name": "Phoebe",
		"createdDate": "2018-04-23 18:17:29",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 323,
		"totalCountries": 16,
		"totalStates": 54,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 3938,
		"schedulePercent": 0.920673,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "899619b",
		"email": "brookestonystark@aol.com",
		"name": "Anthony",
		"createdDate": "2014-06-25 16:44:56",
		"totalOrganizations": 8,
		"totalTours": 14,
		"totalDays": 1014,
		"totalCountries": 11,
		"totalStates": 22,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 1333,
		"schedulePercent": 0.57542,
		"totalGuests": 72,
		"guestPercent": 0.446828,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 41
	},
	{
		"id": "1f6c5ad",
		"email": "henna@avpro.fi",
		"name": "Henna",
		"createdDate": "2022-06-30 08:50:12",
		"totalOrganizations": 46,
		"totalTours": 46,
		"totalDays": 1209,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 1113,
		"eventPercent": 0.991259,
		"totalScheduleItems": 4074,
		"schedulePercent": 0.926367,
		"totalGuests": 26,
		"guestPercent": 0.381274,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Ruisrock",
		"mostGuestCity": "Turku",
		"mostGuestAmount": 6
	},
	{
		"id": "dc29acd",
		"email": "debybunyard@gmail.com",
		"name": "Deby",
		"createdDate": "2023-01-08 17:27:06",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 619,
		"totalCountries": 18,
		"totalStates": 47,
		"totalEvents": 311,
		"eventPercent": 0.921335,
		"totalScheduleItems": 4502,
		"schedulePercent": 0.945173,
		"totalGuests": 1136,
		"guestPercent": 0.846246,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Qudos Bank Arena",
		"mostGuestCity": "Sydney Olympic Park",
		"mostGuestAmount": 67
	},
	{
		"id": "39345ea",
		"email": "cassiasounds@gmail.com",
		"name": "Louis",
		"createdDate": "2022-08-15 11:07:43",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 124,
		"totalCountries": 3,
		"totalStates": 8,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 229,
		"schedulePercent": 0.169117,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "601b708",
		"email": "dhvanitamit@gmail.com",
		"name": "Dhvanit",
		"createdDate": "2022-09-16 14:25:19",
		"totalOrganizations": 1,
		"totalTours": 8,
		"totalDays": 311,
		"totalCountries": 1,
		"totalStates": 38,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 1319,
		"schedulePercent": 0.570785,
		"totalGuests": 121,
		"guestPercent": 0.490928,
		"mostGuestDate": "2023-01-13 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 24
	},
	{
		"id": "e39a301",
		"email": "jessedanieltm@gmail.com",
		"name": "Tyler",
		"createdDate": "2022-03-17 20:52:27",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 155,
		"totalCountries": 3,
		"totalStates": 30,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 91,
		"schedulePercent": 0.0833002,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-04-19 00:00:00",
		"mostGuestName": "Mississippi Studios and Bar Bar",
		"mostGuestCity": "Portland",
		"mostGuestAmount": 2
	},
	{
		"id": "9515063",
		"email": "janice9991@icloud.com",
		"name": "Janice",
		"createdDate": "2017-02-10 19:05:11",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 298,
		"totalCountries": 1,
		"totalStates": 44,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 2175,
		"schedulePercent": 0.746259,
		"totalGuests": 3491,
		"guestPercent": 0.976824,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 185
	},
	{
		"id": "34907b0",
		"email": "pat@bloomartists.net",
		"name": "Pat ",
		"createdDate": "2021-12-17 02:08:21",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 590,
		"totalCountries": 10,
		"totalStates": 53,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 595,
		"schedulePercent": 0.337968,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-02-23 00:00:00",
		"mostGuestName": "Big Night Live",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 6
	},
	{
		"id": "2307876",
		"email": "codynorton40@gmail.com",
		"name": "Cody",
		"createdDate": "2018-01-29 23:57:25",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 366,
		"totalCountries": 6,
		"totalStates": 30,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 3331,
		"schedulePercent": 0.88081,
		"totalGuests": 4773,
		"guestPercent": 0.990332,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 240
	},
	{
		"id": "7b5f536",
		"email": "mickey@daysworkmanagement.com",
		"name": "Michael",
		"createdDate": "2021-07-26 14:48:54",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 379,
		"totalCountries": 11,
		"totalStates": 38,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 1495,
		"schedulePercent": 0.616872,
		"totalGuests": 27,
		"guestPercent": 0.38379,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Browns Island",
		"mostGuestCity": "Richmond",
		"mostGuestAmount": 10
	},
	{
		"id": "da4d50b",
		"email": "fraas.jacob@gmail.com",
		"name": "Jacob",
		"createdDate": "2021-08-30 08:20:16",
		"totalOrganizations": 30,
		"totalTours": 12,
		"totalDays": 188,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 181,
		"eventPercent": 0.771818,
		"totalScheduleItems": 595,
		"schedulePercent": 0.337968,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "93297cd",
		"email": "reneesharplesstm@gmail.com",
		"name": "Renee",
		"createdDate": "2023-03-10 19:02:59",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 192,
		"totalCountries": 1,
		"totalStates": 35,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 1246,
		"schedulePercent": 0.551185,
		"totalGuests": 69,
		"guestPercent": 0.445107,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "Goodyear Theater",
		"mostGuestCity": "Akron",
		"mostGuestAmount": 17
	},
	{
		"id": "0322dc3",
		"email": "Jlawsound@gmail.com",
		"name": "Josh",
		"createdDate": "2017-06-27 14:10:49",
		"totalOrganizations": 9,
		"totalTours": 24,
		"totalDays": 520,
		"totalCountries": 20,
		"totalStates": 67,
		"totalEvents": 162,
		"eventPercent": 0.732883,
		"totalScheduleItems": 1819,
		"schedulePercent": 0.689048,
		"totalGuests": 647,
		"guestPercent": 0.738445,
		"mostGuestDate": "2023-02-15 00:00:00",
		"mostGuestName": "Heaven",
		"mostGuestCity": "London",
		"mostGuestAmount": 70
	},
	{
		"id": "bd10e5c",
		"email": "travis@nateteam.com",
		"name": "Travis",
		"createdDate": "2022-02-02 18:37:06",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 287,
		"totalCountries": 6,
		"totalStates": 43,
		"totalEvents": 181,
		"eventPercent": 0.771818,
		"totalScheduleItems": 1250,
		"schedulePercent": 0.552377,
		"totalGuests": 1444,
		"guestPercent": 0.88624,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 44
	},
	{
		"id": "0f28998",
		"email": "ben@tourcloud.co.uk",
		"name": "Ben",
		"createdDate": "2013-12-04 19:00:28",
		"totalOrganizations": 23,
		"totalTours": 26,
		"totalDays": 1100,
		"totalCountries": 19,
		"totalStates": 65,
		"totalEvents": 531,
		"eventPercent": 0.977354,
		"totalScheduleItems": 6657,
		"schedulePercent": 0.986492,
		"totalGuests": 595,
		"guestPercent": 0.724805,
		"mostGuestDate": "2023-11-29 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 222
	},
	{
		"id": "492f585",
		"email": "robbel.tommy@gmail.com",
		"name": "Tommy",
		"createdDate": "2022-07-14 13:27:40",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 103,
		"totalCountries": 8,
		"totalStates": 11,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 374,
		"schedulePercent": 0.24553,
		"totalGuests": 42,
		"guestPercent": 0.40882,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "Halle 101 Speyer - Kulturzentrum",
		"mostGuestCity": "Speyer",
		"mostGuestAmount": 18
	},
	{
		"id": "b28f98d",
		"email": "beau@phntmlabs.com",
		"name": "Beau",
		"createdDate": "2021-11-24 01:01:43",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 289,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 1244,
		"schedulePercent": 0.550788,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cac8bb0",
		"email": "dbrunetto@me.com",
		"name": "David",
		"createdDate": "2021-10-26 17:48:23",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 116,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 458,
		"schedulePercent": 0.27983,
		"totalGuests": 265,
		"guestPercent": 0.586942,
		"mostGuestDate": "2023-04-19 00:00:00",
		"mostGuestName": "The Mill & Mine",
		"mostGuestCity": "Knoxville",
		"mostGuestAmount": 49
	},
	{
		"id": "9956d7f",
		"email": "livecat69@me.com",
		"name": "Michael",
		"createdDate": "2010-03-18 02:40:52",
		"totalOrganizations": 9,
		"totalTours": 27,
		"totalDays": 296,
		"totalCountries": 5,
		"totalStates": 32,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 2374,
		"schedulePercent": 0.776586,
		"totalGuests": 483,
		"guestPercent": 0.684545,
		"mostGuestDate": "2023-06-29 00:00:00",
		"mostGuestName": "Radio City Music Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 47
	},
	{
		"id": "06f7159",
		"email": "Bgirman3@gmail.com",
		"name": "Bernie",
		"createdDate": "2017-10-10 14:31:41",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 171,
		"totalCountries": 3,
		"totalStates": 37,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 1444,
		"schedulePercent": 0.602304,
		"totalGuests": 1045,
		"guestPercent": 0.832473,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 100
	},
	{
		"id": "61ba2ec",
		"email": "leesa.ellem@gmail.com",
		"name": "Leesa",
		"createdDate": "2010-11-09 11:38:43",
		"totalOrganizations": 14,
		"totalTours": 12,
		"totalDays": 548,
		"totalCountries": 19,
		"totalStates": 54,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 2474,
		"schedulePercent": 0.789432,
		"totalGuests": 45,
		"guestPercent": 0.412925,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Columbus Zoo 4-D Theater",
		"mostGuestCity": "Powell",
		"mostGuestAmount": 20
	},
	{
		"id": "30c626c",
		"email": "Josbro04@gmail.com",
		"name": "Joseph",
		"createdDate": "2022-06-11 13:09:47",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 99,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 572,
		"schedulePercent": 0.328963,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b7d765f",
		"email": "mrice@bassicblack.com",
		"name": "Marshay ",
		"createdDate": "2019-01-04 21:30:50",
		"totalOrganizations": 11,
		"totalTours": 18,
		"totalDays": 1077,
		"totalCountries": 15,
		"totalStates": 57,
		"totalEvents": 309,
		"eventPercent": 0.920408,
		"totalScheduleItems": 3459,
		"schedulePercent": 0.891008,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6f25f7d",
		"email": "fohsound13@gmail.com",
		"name": "Tony",
		"createdDate": "2021-06-09 16:33:41",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 57,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 365,
		"schedulePercent": 0.24063,
		"totalGuests": 337,
		"guestPercent": 0.621375,
		"mostGuestDate": "2023-09-09 00:00:00",
		"mostGuestName": "The Wellmont Theater",
		"mostGuestCity": "Montclair",
		"mostGuestAmount": 43
	},
	{
		"id": "b259b55",
		"email": "zelda@goboproductions.co.uk",
		"name": "Zelda",
		"createdDate": "2023-06-20 15:49:16",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 67,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 262,
		"schedulePercent": 0.186465,
		"totalGuests": 30,
		"guestPercent": 0.389485,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "3TEN Austin City Limits Live",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 10
	},
	{
		"id": "2a8f958",
		"email": "robhylton@mac.com",
		"name": "Rob",
		"createdDate": "2022-06-11 08:44:14",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 58,
		"totalCountries": 4,
		"totalStates": 5,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 149,
		"schedulePercent": 0.118925,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "23c5ef9",
		"email": "cobramanband@gmail.com",
		"name": "Sarah",
		"createdDate": "2022-02-22 20:53:08",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 25,
		"totalCountries": 1,
		"totalStates": 15,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 174,
		"schedulePercent": 0.135346,
		"totalGuests": 178,
		"guestPercent": 0.534896,
		"mostGuestDate": "2023-05-11 00:00:00",
		"mostGuestName": "Irving Plaza",
		"mostGuestCity": "New York",
		"mostGuestAmount": 47
	},
	{
		"id": "c212db2",
		"email": "INFO@THEHIGHROADENT.COM",
		"name": "Stacy",
		"createdDate": "2022-12-28 00:50:05",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 243,
		"totalCountries": 1,
		"totalStates": 31,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 1165,
		"schedulePercent": 0.530526,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Bottom Lounge",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 1
	},
	{
		"id": "2d97415",
		"email": "produccionesfp1@gmail.com",
		"name": "Felipe",
		"createdDate": "2022-07-29 05:10:53",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 145,
		"totalCountries": 6,
		"totalStates": 38,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 242,
		"schedulePercent": 0.177195,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a739deb",
		"email": "aren@apgoldintl.com",
		"name": "Aren",
		"createdDate": "2018-04-14 21:40:07",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 658,
		"totalCountries": 12,
		"totalStates": 48,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 1529,
		"schedulePercent": 0.624685,
		"totalGuests": 887,
		"guestPercent": 0.796054,
		"mostGuestDate": "2023-09-01 00:00:00",
		"mostGuestName": "Grand Park",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 185
	},
	{
		"id": "42998ec",
		"email": "nrengle@mac.com",
		"name": "Nick",
		"createdDate": "2017-04-22 03:03:46",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 325,
		"totalCountries": 15,
		"totalStates": 57,
		"totalEvents": 167,
		"eventPercent": 0.745332,
		"totalScheduleItems": 3175,
		"schedulePercent": 0.867965,
		"totalGuests": 1670,
		"guestPercent": 0.909548,
		"mostGuestDate": "2023-08-30 00:00:00",
		"mostGuestName": "Hollywood Casino Amphitheatre",
		"mostGuestCity": "Tinley Park",
		"mostGuestAmount": 122
	},
	{
		"id": "15125e8",
		"email": "dan@tcoartists.biz",
		"name": "Dan ",
		"createdDate": "2020-01-18 00:52:34",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 497,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 344,
		"schedulePercent": 0.230168,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "846266a",
		"email": "kim@brickent.com",
		"name": "Kim",
		"createdDate": "2012-11-05 22:16:37",
		"totalOrganizations": 6,
		"totalTours": 12,
		"totalDays": 208,
		"totalCountries": 3,
		"totalStates": 32,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 1994,
		"schedulePercent": 0.717388,
		"totalGuests": 1235,
		"guestPercent": 0.8628,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "State Farm Arena",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 133
	},
	{
		"id": "e6d2c73",
		"email": "drew.latham@jurassicquest.com",
		"name": "Drew",
		"createdDate": "2021-04-17 01:17:11",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 1090,
		"totalCountries": 3,
		"totalStates": 48,
		"totalEvents": 587,
		"eventPercent": 0.982254,
		"totalScheduleItems": 6860,
		"schedulePercent": 0.987816,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "78c9bb5",
		"email": "dantegrazianitm@gmail.com",
		"name": "Dante",
		"createdDate": "2017-01-19 16:00:17",
		"totalOrganizations": 7,
		"totalTours": 2,
		"totalDays": 132,
		"totalCountries": 3,
		"totalStates": 36,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1215,
		"schedulePercent": 0.543769,
		"totalGuests": 82,
		"guestPercent": 0.457688,
		"mostGuestDate": "2023-04-03 00:00:00",
		"mostGuestName": "The Mohawk",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 24
	},
	{
		"id": "706cc33",
		"email": "michael@thepazgroup.com",
		"name": "Michael",
		"createdDate": "2009-11-18 20:38:30",
		"totalOrganizations": 40,
		"totalTours": 94,
		"totalDays": 1640,
		"totalCountries": 14,
		"totalStates": 69,
		"totalEvents": 443,
		"eventPercent": 0.966892,
		"totalScheduleItems": 3532,
		"schedulePercent": 0.896173,
		"totalGuests": 396,
		"guestPercent": 0.652232,
		"mostGuestDate": "2023-04-29 00:00:00",
		"mostGuestName": "The Broadside",
		"mostGuestCity": "New Orleans",
		"mostGuestAmount": 43
	},
	{
		"id": "6210488",
		"email": "adh.foh@gmail.com",
		"name": "Ana",
		"createdDate": "2015-01-28 21:06:09",
		"totalOrganizations": 12,
		"totalTours": 17,
		"totalDays": 669,
		"totalCountries": 11,
		"totalStates": 65,
		"totalEvents": 234,
		"eventPercent": 0.854324,
		"totalScheduleItems": 1713,
		"schedulePercent": 0.668388,
		"totalGuests": 350,
		"guestPercent": 0.629718,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "Greenfield Lake Amphitheatre",
		"mostGuestCity": "Wilmington",
		"mostGuestAmount": 20
	},
	{
		"id": "31dbf93",
		"email": "joan@thedenla.com",
		"name": "Joan",
		"createdDate": "2023-09-12 22:26:00",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 68,
		"totalCountries": 6,
		"totalStates": 21,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 462,
		"schedulePercent": 0.281817,
		"totalGuests": 89,
		"guestPercent": 0.462323,
		"mostGuestDate": "2023-10-12 00:00:00",
		"mostGuestName": "The Dome",
		"mostGuestCity": "London",
		"mostGuestAmount": 16
	},
	{
		"id": "5e2121b",
		"email": "chris@chpn.se",
		"name": "Christian",
		"createdDate": "2020-01-17 12:19:32",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 55,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 315,
		"schedulePercent": 0.215733,
		"totalGuests": 11,
		"guestPercent": 0.345782,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Studio Acusticum - Backyard",
		"mostGuestCity": "PiteÃ¥",
		"mostGuestAmount": 4
	},
	{
		"id": "3c36880",
		"email": "soundbry77@mac.com",
		"name": "Bryan",
		"createdDate": "2011-08-24 00:11:31",
		"totalOrganizations": 14,
		"totalTours": 44,
		"totalDays": 539,
		"totalCountries": 22,
		"totalStates": 72,
		"totalEvents": 315,
		"eventPercent": 0.923057,
		"totalScheduleItems": 3301,
		"schedulePercent": 0.8775,
		"totalGuests": 771,
		"guestPercent": 0.769832,
		"mostGuestDate": "2023-01-05 00:00:00",
		"mostGuestName": "The Chicago Theatre",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 63
	},
	{
		"id": "7d4592f",
		"email": "BORDERSONTOUR@GMAIL.COM",
		"name": "MATTHEW",
		"createdDate": "2021-11-28 03:18:32",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 243,
		"totalCountries": 8,
		"totalStates": 31,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 1336,
		"schedulePercent": 0.57648,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a413502",
		"email": "press@greycoda.com",
		"name": "Angel",
		"createdDate": "2017-04-20 15:17:01",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 363,
		"totalCountries": 7,
		"totalStates": 38,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 1336,
		"schedulePercent": 0.57648,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3c7a4ea",
		"email": "carleealowe@gmail.com",
		"name": "Carlee",
		"createdDate": "2015-02-08 03:48:03",
		"totalOrganizations": 14,
		"totalTours": 20,
		"totalDays": 549,
		"totalCountries": 13,
		"totalStates": 57,
		"totalEvents": 257,
		"eventPercent": 0.882929,
		"totalScheduleItems": 3847,
		"schedulePercent": 0.915111,
		"totalGuests": 3371,
		"guestPercent": 0.9755,
		"mostGuestDate": "2023-05-19 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 194
	},
	{
		"id": "04cf226",
		"email": "escobosa@33producciones.es",
		"name": "Carlos ",
		"createdDate": "2018-03-29 19:12:37",
		"totalOrganizations": 28,
		"totalTours": 31,
		"totalDays": 965,
		"totalCountries": 7,
		"totalStates": 58,
		"totalEvents": 296,
		"eventPercent": 0.912992,
		"totalScheduleItems": 2080,
		"schedulePercent": 0.731691,
		"totalGuests": 161,
		"guestPercent": 0.523374,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "SALA BUT",
		"mostGuestCity": "Madrid",
		"mostGuestAmount": 80
	},
	{
		"id": "e1028d8",
		"email": "anthony@silkroad.org",
		"name": "Anthony",
		"createdDate": "2022-03-24 17:23:54",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 20,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 322,
		"schedulePercent": 0.219706,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-11-05 00:00:00",
		"mostGuestName": "George Mason University Center For The Arts ",
		"mostGuestCity": "Fairfax",
		"mostGuestAmount": 2
	},
	{
		"id": "49807c6",
		"email": "mikeronaut@gmail.com",
		"name": "Mike",
		"createdDate": "2011-06-09 22:30:07",
		"totalOrganizations": 12,
		"totalTours": 11,
		"totalDays": 1076,
		"totalCountries": 8,
		"totalStates": 44,
		"totalEvents": 181,
		"eventPercent": 0.771818,
		"totalScheduleItems": 1616,
		"schedulePercent": 0.645875,
		"totalGuests": 958,
		"guestPercent": 0.815389,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 128
	},
	{
		"id": "54c4f63",
		"email": "geraldinebosquet@hotmail.com",
		"name": "G&#233;raldine",
		"createdDate": "2023-02-02 10:22:37",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 92,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 225,
		"schedulePercent": 0.1666,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c24deb1",
		"email": "scottsimpsontours@gmail.com",
		"name": "Scott",
		"createdDate": "2014-07-14 13:17:27",
		"totalOrganizations": 7,
		"totalTours": 20,
		"totalDays": 305,
		"totalCountries": 8,
		"totalStates": 39,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 1937,
		"schedulePercent": 0.708648,
		"totalGuests": 1318,
		"guestPercent": 0.874056,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 90
	},
	{
		"id": "9410df4",
		"email": "kmsucher@gmail.com",
		"name": "Kevin",
		"createdDate": "2009-10-19 21:30:38",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 206,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 390,
		"schedulePercent": 0.252947,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "37b5718",
		"email": "tecnica@selorelicario.com.br",
		"name": "William",
		"createdDate": "2023-04-10 21:38:11",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 38,
		"totalCountries": 6,
		"totalStates": 10,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 236,
		"schedulePercent": 0.172295,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2397983",
		"email": "jesuspiecehc@gmail.com",
		"name": "David",
		"createdDate": "2023-01-11 00:56:15",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 44,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 239,
		"schedulePercent": 0.175209,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6154a14",
		"email": "jenny@cmcnashville.com",
		"name": "Jenny",
		"createdDate": "2015-11-11 14:06:51",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 50,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 195,
		"schedulePercent": 0.147265,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e9f8840",
		"email": "timmyd007@comcast.net",
		"name": "Tim",
		"createdDate": "2012-03-08 21:12:56",
		"totalOrganizations": 6,
		"totalTours": 14,
		"totalDays": 678,
		"totalCountries": 9,
		"totalStates": 44,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 2846,
		"schedulePercent": 0.83777,
		"totalGuests": 1560,
		"guestPercent": 0.899748,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 97
	},
	{
		"id": "01120b9",
		"email": "pduquefois@gmail.com",
		"name": "Pia",
		"createdDate": "2023-06-03 03:25:14",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 178,
		"totalCountries": 11,
		"totalStates": 29,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 471,
		"schedulePercent": 0.28473,
		"totalGuests": 92,
		"guestPercent": 0.466163,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "Auditorio Marina Sur",
		"mostGuestCity": "Valencia",
		"mostGuestAmount": 31
	},
	{
		"id": "3a21f44",
		"email": "melchorah@gmail.com",
		"name": "adam",
		"createdDate": "2019-07-10 22:58:25",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 755,
		"totalCountries": 8,
		"totalStates": 51,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 2215,
		"schedulePercent": 0.752748,
		"totalGuests": 1188,
		"guestPercent": 0.855383,
		"mostGuestDate": "2023-03-10 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 180
	},
	{
		"id": "db2e690",
		"email": "greg@thefullspectrum.org",
		"name": "Greg",
		"createdDate": "2014-07-08 17:31:25",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 103,
		"totalCountries": 9,
		"totalStates": 20,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 455,
		"schedulePercent": 0.278639,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "BASE Milano",
		"mostGuestCity": "Milano",
		"mostGuestAmount": 1
	},
	{
		"id": "910bfee",
		"email": "SuttonAmira@gmail.com",
		"name": "Amira",
		"createdDate": "2018-10-30 19:52:13",
		"totalOrganizations": 14,
		"totalTours": 11,
		"totalDays": 352,
		"totalCountries": 4,
		"totalStates": 33,
		"totalEvents": 173,
		"eventPercent": 0.757516,
		"totalScheduleItems": 2734,
		"schedulePercent": 0.824262,
		"totalGuests": 3205,
		"guestPercent": 0.973116,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 221
	},
	{
		"id": "7e9069f",
		"email": "jimmy.healey1@gmail.com",
		"name": "Jimmy",
		"createdDate": "2016-12-28 21:49:31",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 1227,
		"totalCountries": 6,
		"totalStates": 54,
		"totalEvents": 352,
		"eventPercent": 0.942127,
		"totalScheduleItems": 5234,
		"schedulePercent": 0.968084,
		"totalGuests": 4376,
		"guestPercent": 0.987022,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Paycom Center",
		"mostGuestCity": "Oklahoma City",
		"mostGuestAmount": 167
	},
	{
		"id": "5730d40",
		"email": "avmusic@me.com",
		"name": "Allan",
		"createdDate": "2012-07-13 19:47:45",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 152,
		"totalCountries": 9,
		"totalStates": 43,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 1134,
		"schedulePercent": 0.521255,
		"totalGuests": 81,
		"guestPercent": 0.455966,
		"mostGuestDate": "2023-04-29 00:00:00",
		"mostGuestName": "Fiserv Forum",
		"mostGuestCity": "Milwaukee",
		"mostGuestAmount": 21
	},
	{
		"id": "2726b49",
		"email": "taijalaw@gmail.com",
		"name": "Taija ",
		"createdDate": "2017-08-03 15:23:55",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 72,
		"totalCountries": 3,
		"totalStates": 26,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 550,
		"schedulePercent": 0.321414,
		"totalGuests": 191,
		"guestPercent": 0.543769,
		"mostGuestDate": "2023-05-26 00:00:00",
		"mostGuestName": "Coca-Cola Roxy",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 76
	},
	{
		"id": "cb790fd",
		"email": "anders@velour.nu",
		"name": "Anders",
		"createdDate": "2016-06-10 11:04:29",
		"totalOrganizations": 16,
		"totalTours": 17,
		"totalDays": 267,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 257,
		"eventPercent": 0.882929,
		"totalScheduleItems": 1669,
		"schedulePercent": 0.657396,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-01-21 00:00:00",
		"mostGuestName": "Ideal Bar",
		"mostGuestCity": "Copenhagen",
		"mostGuestAmount": 2
	},
	{
		"id": "d403507",
		"email": "firestoneleanna@gmail.com",
		"name": "Leanna",
		"createdDate": "2023-01-28 19:18:48",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 47,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 371,
		"schedulePercent": 0.244074,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1e60a01",
		"email": "crleidhecker@gmail.com",
		"name": "Chris",
		"createdDate": "2018-03-06 15:44:17",
		"totalOrganizations": 8,
		"totalTours": 23,
		"totalDays": 343,
		"totalCountries": 9,
		"totalStates": 40,
		"totalEvents": 241,
		"eventPercent": 0.862667,
		"totalScheduleItems": 2797,
		"schedulePercent": 0.832075,
		"totalGuests": 291,
		"guestPercent": 0.598199,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "Music Hall Center for the Performing Arts",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 63
	},
	{
		"id": "4814def",
		"email": "devinmauch@gmail.com",
		"name": "Devin",
		"createdDate": "2015-11-15 01:40:26",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 917,
		"totalCountries": 3,
		"totalStates": 28,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 847,
		"schedulePercent": 0.436631,
		"totalGuests": 483,
		"guestPercent": 0.684545,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 96
	},
	{
		"id": "9716044",
		"email": "shari.roth@wmg.com",
		"name": "Shari",
		"createdDate": "2015-09-05 20:58:07",
		"totalOrganizations": 58,
		"totalTours": 49,
		"totalDays": 15958,
		"totalCountries": 16,
		"totalStates": 88,
		"totalEvents": 2151,
		"eventPercent": 0.998013,
		"totalScheduleItems": 3632,
		"schedulePercent": 0.902794,
		"totalGuests": 55,
		"guestPercent": 0.42842,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "del Lago Resort & Casino",
		"mostGuestCity": "Waterloo",
		"mostGuestAmount": 10
	},
	{
		"id": "db8f906",
		"email": "fredm@vstarentertainment.com",
		"name": "Fred",
		"createdDate": "2019-04-25 19:48:08",
		"totalOrganizations": 2,
		"totalTours": 9,
		"totalDays": 394,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 242,
		"eventPercent": 0.864389,
		"totalScheduleItems": 1521,
		"schedulePercent": 0.623361,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d445dc8",
		"email": "AndreaHowat@gmail.com",
		"name": "Andrea",
		"createdDate": "2015-05-20 15:21:59",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 391,
		"totalCountries": 10,
		"totalStates": 37,
		"totalEvents": 181,
		"eventPercent": 0.771818,
		"totalScheduleItems": 2081,
		"schedulePercent": 0.731956,
		"totalGuests": 679,
		"guestPercent": 0.748113,
		"mostGuestDate": "2023-02-14 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 67
	},
	{
		"id": "0177c2a",
		"email": "Tiaassist@koolandthegang.com",
		"name": "Lydell",
		"createdDate": "2023-01-05 16:49:10",
		"totalOrganizations": 2,
		"totalTours": 30,
		"totalDays": 932,
		"totalCountries": 8,
		"totalStates": 29,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 2591,
		"schedulePercent": 0.804794,
		"totalGuests": 444,
		"guestPercent": 0.669845,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "Hard Rock Live at Etess Arena",
		"mostGuestCity": "Atlantic City",
		"mostGuestAmount": 57
	},
	{
		"id": "093d0f9",
		"email": "kemtourasst@gmail.com",
		"name": "Tamika",
		"createdDate": "2022-03-08 20:57:17",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 306,
		"totalCountries": 1,
		"totalStates": 28,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 2623,
		"schedulePercent": 0.811283,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ff71692",
		"email": "eharris24@gmail.com",
		"name": "Ed",
		"createdDate": "2017-06-28 21:44:12",
		"totalOrganizations": 2,
		"totalTours": 15,
		"totalDays": 218,
		"totalCountries": 23,
		"totalStates": 55,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 1422,
		"schedulePercent": 0.597272,
		"totalGuests": 2114,
		"guestPercent": 0.940008,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "Don Haskins Center",
		"mostGuestCity": "El Paso",
		"mostGuestAmount": 213
	},
	{
		"id": "ab2c61b",
		"email": "jax@sheltermusic.com",
		"name": "Jacqueline",
		"createdDate": "2023-04-13 18:52:33",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 342,
		"totalCountries": 3,
		"totalStates": 42,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 1600,
		"schedulePercent": 0.642829,
		"totalGuests": 53,
		"guestPercent": 0.426434,
		"mostGuestDate": "2023-05-04 00:00:00",
		"mostGuestName": "Fiddler's Green Amphitheatre",
		"mostGuestCity": "Englewood",
		"mostGuestAmount": 30
	},
	{
		"id": "86ba1b5",
		"email": "scott@aisle6.com.au",
		"name": "Scott",
		"createdDate": "2014-12-17 22:46:56",
		"totalOrganizations": 11,
		"totalTours": 15,
		"totalDays": 331,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 1386,
		"schedulePercent": 0.588134,
		"totalGuests": 802,
		"guestPercent": 0.776718,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "Hindley Street Music Hall",
		"mostGuestCity": "Adelaide",
		"mostGuestAmount": 96
	},
	{
		"id": "9c21ae3",
		"email": "joesoundllc@gmail.com",
		"name": "Joe",
		"createdDate": "2018-05-03 06:01:54",
		"totalOrganizations": 13,
		"totalTours": 7,
		"totalDays": 660,
		"totalCountries": 8,
		"totalStates": 43,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 1791,
		"schedulePercent": 0.683088,
		"totalGuests": 286,
		"guestPercent": 0.596477,
		"mostGuestDate": "2023-09-03 00:00:00",
		"mostGuestName": "iTHINK Financial Amphitheatre",
		"mostGuestCity": "West Palm Beach",
		"mostGuestAmount": 29
	},
	{
		"id": "2305e53",
		"email": "saulband@gmail.com",
		"name": "Zach ",
		"createdDate": "2019-07-05 20:27:23",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 31,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 217,
		"schedulePercent": 0.160906,
		"totalGuests": 154,
		"guestPercent": 0.51662,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "The Beacham",
		"mostGuestCity": "Orlando",
		"mostGuestAmount": 21
	},
	{
		"id": "2db67cc",
		"email": "krism.simms@gmail.com",
		"name": "Kris",
		"createdDate": "2022-10-21 20:56:42",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 142,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 951,
		"schedulePercent": 0.465634,
		"totalGuests": 1246,
		"guestPercent": 0.864389,
		"mostGuestDate": "2023-11-06 00:00:00",
		"mostGuestName": "Avant Gardner",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 124
	},
	{
		"id": "c2775f5",
		"email": "Tj.abbonizio@gmail.com",
		"name": "TJ",
		"createdDate": "2014-01-29 21:53:43",
		"totalOrganizations": 15,
		"totalTours": 14,
		"totalDays": 485,
		"totalCountries": 18,
		"totalStates": 64,
		"totalEvents": 173,
		"eventPercent": 0.757516,
		"totalScheduleItems": 2251,
		"schedulePercent": 0.758575,
		"totalGuests": 389,
		"guestPercent": 0.648656,
		"mostGuestDate": "2023-09-11 00:00:00",
		"mostGuestName": "Theatre Royal Drury Lane",
		"mostGuestCity": "London",
		"mostGuestAmount": 28
	},
	{
		"id": "d602d63",
		"email": "simran@mgplive.com",
		"name": "Simran",
		"createdDate": "2023-06-16 19:53:38",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 227,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 9,
		"eventPercent": 0.0607866,
		"totalScheduleItems": 174,
		"schedulePercent": 0.135346,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2b87cd8",
		"email": "produktionen@queen-machine.dk",
		"name": "Per",
		"createdDate": "2017-06-02 07:59:26",
		"totalOrganizations": 18,
		"totalTours": 7,
		"totalDays": 102,
		"totalCountries": 4,
		"totalStates": 7,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 784,
		"schedulePercent": 0.41425,
		"totalGuests": 490,
		"guestPercent": 0.688518,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "BirkerÃ¸d IdrÃ¦tscenter",
		"mostGuestCity": "BirkerÃ¸d",
		"mostGuestAmount": 35
	},
	{
		"id": "4c038fe",
		"email": "saborn@cityparksfoundation.org",
		"name": "Shoshie",
		"createdDate": "2021-07-26 16:18:18",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1580614",
		"email": "dayseekerband@gmail.com",
		"name": "Rory",
		"createdDate": "2015-07-06 17:52:12",
		"totalOrganizations": 7,
		"totalTours": 17,
		"totalDays": 274,
		"totalCountries": 9,
		"totalStates": 63,
		"totalEvents": 163,
		"eventPercent": 0.735134,
		"totalScheduleItems": 2000,
		"schedulePercent": 0.71911,
		"totalGuests": 794,
		"guestPercent": 0.775394,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "House of Blues Orlando",
		"mostGuestCity": "Lake Buena Vista",
		"mostGuestAmount": 81
	},
	{
		"id": "f724634",
		"email": "averyschiff@gmail.com",
		"name": "Avery",
		"createdDate": "2017-04-07 20:26:12",
		"totalOrganizations": 23,
		"totalTours": 34,
		"totalDays": 1225,
		"totalCountries": 18,
		"totalStates": 73,
		"totalEvents": 511,
		"eventPercent": 0.9755,
		"totalScheduleItems": 5238,
		"schedulePercent": 0.968216,
		"totalGuests": 1813,
		"guestPercent": 0.921467,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Palladium Times Square",
		"mostGuestCity": "New York",
		"mostGuestAmount": 97
	},
	{
		"id": "bd32e37",
		"email": "m.riousset@outlook.com",
		"name": "Mathieu",
		"createdDate": "2019-04-13 17:20:31",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 128,
		"totalCountries": 8,
		"totalStates": 22,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 991,
		"schedulePercent": 0.480996,
		"totalGuests": 19,
		"guestPercent": 0.366971,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "Santeria Social Club",
		"mostGuestCity": "Milano",
		"mostGuestAmount": 7
	},
	{
		"id": "b93d07b",
		"email": "chris.nary@lmgllc.me",
		"name": "Chris",
		"createdDate": "2012-06-16 00:29:52",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 369,
		"totalCountries": 17,
		"totalStates": 65,
		"totalEvents": 220,
		"eventPercent": 0.83671,
		"totalScheduleItems": 2200,
		"schedulePercent": 0.749437,
		"totalGuests": 695,
		"guestPercent": 0.751689,
		"mostGuestDate": "2023-10-22 00:00:00",
		"mostGuestName": "THE PUNK ROCK MUSEUM",
		"mostGuestCity": "LAS VEGAS",
		"mostGuestAmount": 58
	},
	{
		"id": "da76ab8",
		"email": "fritch1134@gmail.com",
		"name": "Jacob",
		"createdDate": "2011-05-18 17:20:58",
		"totalOrganizations": 8,
		"totalTours": 19,
		"totalDays": 390,
		"totalCountries": 10,
		"totalStates": 49,
		"totalEvents": 195,
		"eventPercent": 0.796318,
		"totalScheduleItems": 3830,
		"schedulePercent": 0.914051,
		"totalGuests": 4881,
		"guestPercent": 0.991127,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The ICON Festival Stage",
		"mostGuestCity": "Cincinnati",
		"mostGuestAmount": 238
	},
	{
		"id": "3ed0b8e",
		"email": "melissa@fatbackmanagement.com",
		"name": "Melissa",
		"createdDate": "2023-08-24 14:58:18",
		"totalOrganizations": 13,
		"totalTours": 32,
		"totalDays": 278,
		"totalCountries": 2,
		"totalStates": 37,
		"totalEvents": 193,
		"eventPercent": 0.792743,
		"totalScheduleItems": 842,
		"schedulePercent": 0.434909,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "47c6fb3",
		"email": "nick@lainglighting.com",
		"name": "Nick",
		"createdDate": "2019-08-29 19:56:56",
		"totalOrganizations": 7,
		"totalTours": 4,
		"totalDays": 606,
		"totalCountries": 3,
		"totalStates": 47,
		"totalEvents": 204,
		"eventPercent": 0.813005,
		"totalScheduleItems": 3264,
		"schedulePercent": 0.875248,
		"totalGuests": 1613,
		"guestPercent": 0.904516,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "BOK Center",
		"mostGuestCity": "Tulsa",
		"mostGuestAmount": 119
	},
	{
		"id": "ab70bd8",
		"email": "brianorloff@gmail.com",
		"name": "Brian",
		"createdDate": "2011-02-09 00:32:29",
		"totalOrganizations": 13,
		"totalTours": 14,
		"totalDays": 235,
		"totalCountries": 3,
		"totalStates": 5,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1367,
		"schedulePercent": 0.584691,
		"totalGuests": 322,
		"guestPercent": 0.614753,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "Variety Playhouse",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 26
	},
	{
		"id": "986c6e4",
		"email": "lucy@jadeartistservices.com",
		"name": "Lucille",
		"createdDate": "2016-10-09 08:09:00",
		"totalOrganizations": 37,
		"totalTours": 40,
		"totalDays": 1360,
		"totalCountries": 20,
		"totalStates": 92,
		"totalEvents": 434,
		"eventPercent": 0.965303,
		"totalScheduleItems": 5890,
		"schedulePercent": 0.978811,
		"totalGuests": 1573,
		"guestPercent": 0.900675,
		"mostGuestDate": "2023-02-15 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 38
	},
	{
		"id": "4134a69",
		"email": "zach@skytrackstudios.com",
		"name": "Zach",
		"createdDate": "2022-08-11 21:31:03",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 235,
		"totalCountries": 12,
		"totalStates": 63,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 2072,
		"schedulePercent": 0.730102,
		"totalGuests": 470,
		"guestPercent": 0.680969,
		"mostGuestDate": "2023-10-24 00:00:00",
		"mostGuestName": "History",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 37
	},
	{
		"id": "669bf2f",
		"email": "kyleyocumlessons@gmail.com",
		"name": "Kyle",
		"createdDate": "2021-08-18 22:11:55",
		"totalOrganizations": 3,
		"totalTours": 19,
		"totalDays": 413,
		"totalCountries": 19,
		"totalStates": 88,
		"totalEvents": 278,
		"eventPercent": 0.900278,
		"totalScheduleItems": 2980,
		"schedulePercent": 0.849424,
		"totalGuests": 336,
		"guestPercent": 0.620977,
		"mostGuestDate": "2023-01-15 00:00:00",
		"mostGuestName": "Electric Brixton",
		"mostGuestCity": "London",
		"mostGuestAmount": 45
	},
	{
		"id": "d496e75",
		"email": "jane@janedonald.com",
		"name": "Jane",
		"createdDate": "2012-06-05 11:20:05",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 798,
		"totalCountries": 16,
		"totalStates": 27,
		"totalEvents": 331,
		"eventPercent": 0.932327,
		"totalScheduleItems": 3899,
		"schedulePercent": 0.918289,
		"totalGuests": 6793,
		"guestPercent": 0.996689,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Wembley Stadium",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 625
	},
	{
		"id": "f35069e",
		"email": "sibilla@languageofsound.com",
		"name": "Sibilla",
		"createdDate": "2020-02-04 16:41:09",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 41,
		"totalCountries": 3,
		"totalStates": 11,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 241,
		"schedulePercent": 0.1764,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "69ed9f3",
		"email": "mark@bandshellartists.com",
		"name": "Mark",
		"createdDate": "2012-01-06 14:33:45",
		"totalOrganizations": 6,
		"totalTours": 28,
		"totalDays": 183,
		"totalCountries": 4,
		"totalStates": 33,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 876,
		"schedulePercent": 0.445901,
		"totalGuests": 111,
		"guestPercent": 0.483115,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 33
	},
	{
		"id": "67eabbf",
		"email": "joseph.atkproductions@gmail.com",
		"name": "Joseph",
		"createdDate": "2019-12-03 19:52:59",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 816,
		"totalCountries": 15,
		"totalStates": 47,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 1200,
		"schedulePercent": 0.540326,
		"totalGuests": 340,
		"guestPercent": 0.623494,
		"mostGuestDate": "2023-09-09 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 40
	},
	{
		"id": "4975d02",
		"email": "spcmediaqc@gmail.com",
		"name": "Francois",
		"createdDate": "2018-03-17 14:33:16",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 295,
		"totalCountries": 27,
		"totalStates": 76,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 1533,
		"schedulePercent": 0.625612,
		"totalGuests": 253,
		"guestPercent": 0.580718,
		"mostGuestDate": "2023-08-17 00:00:00",
		"mostGuestName": "Huskvarna Folkets Park",
		"mostGuestCity": "Huskvarna",
		"mostGuestAmount": 25
	},
	{
		"id": "0d45318",
		"email": "anders.friberg@livenation.se",
		"name": "Anders",
		"createdDate": "2013-03-18 08:39:51",
		"totalOrganizations": 124,
		"totalTours": 99,
		"totalDays": 1783,
		"totalCountries": 14,
		"totalStates": 64,
		"totalEvents": 1101,
		"eventPercent": 0.991127,
		"totalScheduleItems": 7698,
		"schedulePercent": 0.992186,
		"totalGuests": 719,
		"guestPercent": 0.757648,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "Mosebacketerrassen",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 66
	},
	{
		"id": "5fe1152",
		"email": "Davisnix9@gmail.com",
		"name": "Davis",
		"createdDate": "2013-02-10 23:58:57",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 531,
		"totalCountries": 5,
		"totalStates": 43,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 1448,
		"schedulePercent": 0.603099,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "Credit One Stadium",
		"mostGuestCity": "Charleston",
		"mostGuestAmount": 3
	},
	{
		"id": "2658591",
		"email": "victoriav561@gmail.com",
		"name": "Victoria ",
		"createdDate": "2023-06-10 04:03:25",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 338,
		"totalCountries": 5,
		"totalStates": 26,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 731,
		"schedulePercent": 0.392928,
		"totalGuests": 2445,
		"guestPercent": 0.952192,
		"mostGuestDate": "2023-09-25 00:00:00",
		"mostGuestName": "State Farm Arena",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 130
	},
	{
		"id": "e9c7e9d",
		"email": "randy@rhymesayers.com",
		"name": "Randall",
		"createdDate": "2014-05-08 21:19:44",
		"totalOrganizations": 6,
		"totalTours": 13,
		"totalDays": 230,
		"totalCountries": 7,
		"totalStates": 45,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 2147,
		"schedulePercent": 0.741888,
		"totalGuests": 965,
		"guestPercent": 0.816713,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 133
	},
	{
		"id": "6d68880",
		"email": "HelloDanCooper@gmail.com",
		"name": "Daniel",
		"createdDate": "2019-06-08 19:09:27",
		"totalOrganizations": 4,
		"totalTours": 21,
		"totalDays": 578,
		"totalCountries": 12,
		"totalStates": 52,
		"totalEvents": 209,
		"eventPercent": 0.82201,
		"totalScheduleItems": 2765,
		"schedulePercent": 0.827705,
		"totalGuests": 836,
		"guestPercent": 0.784664,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Firstbank Amphitheater",
		"mostGuestCity": "Franklin",
		"mostGuestAmount": 47
	},
	{
		"id": "bd414ce",
		"email": "onalvarado@gmail.com",
		"name": "Omar",
		"createdDate": "2015-06-12 20:59:19",
		"totalOrganizations": 11,
		"totalTours": 20,
		"totalDays": 560,
		"totalCountries": 9,
		"totalStates": 57,
		"totalEvents": 211,
		"eventPercent": 0.825586,
		"totalScheduleItems": 3270,
		"schedulePercent": 0.87591,
		"totalGuests": 1492,
		"guestPercent": 0.893259,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "The Strand Ballroom",
		"mostGuestCity": "Providence",
		"mostGuestAmount": 48
	},
	{
		"id": "6d3b94a",
		"email": "dara@rgkentertainment.com",
		"name": "Emily",
		"createdDate": "2016-02-22 16:16:42",
		"totalOrganizations": 14,
		"totalTours": 8,
		"totalDays": 387,
		"totalCountries": 2,
		"totalStates": 10,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1129,
		"schedulePercent": 0.519666,
		"totalGuests": 174,
		"guestPercent": 0.532115,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "River Run Centre",
		"mostGuestCity": "Guelph",
		"mostGuestAmount": 27
	},
	{
		"id": "45c5be8",
		"email": "cylvanna@invisiblemgmt.com",
		"name": "Cylvanna",
		"createdDate": "2023-02-06 18:39:42",
		"totalOrganizations": 9,
		"totalTours": 14,
		"totalDays": 1149,
		"totalCountries": 23,
		"totalStates": 62,
		"totalEvents": 492,
		"eventPercent": 0.973249,
		"totalScheduleItems": 5253,
		"schedulePercent": 0.968878,
		"totalGuests": 6934,
		"guestPercent": 0.997616,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Wembley Stadium",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 625
	},
	{
		"id": "e06d2a6",
		"email": "sarah@transparentproductions.com",
		"name": "Sarah",
		"createdDate": "2023-02-21 22:14:46",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 101,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 797,
		"schedulePercent": 0.419415,
		"totalGuests": 58,
		"guestPercent": 0.431863,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Elevate Life Church Frisco",
		"mostGuestCity": "Frisco",
		"mostGuestAmount": 21
	},
	{
		"id": "f28322a",
		"email": "elizabeth.jo.dean@gmail.com",
		"name": "Elizabeth",
		"createdDate": "2020-01-27 19:12:18",
		"totalOrganizations": 9,
		"totalTours": 8,
		"totalDays": 900,
		"totalCountries": 3,
		"totalStates": 50,
		"totalEvents": 281,
		"eventPercent": 0.903059,
		"totalScheduleItems": 4370,
		"schedulePercent": 0.940538,
		"totalGuests": 3390,
		"guestPercent": 0.975632,
		"mostGuestDate": "2023-09-07 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 118
	},
	{
		"id": "a8b38d5",
		"email": "camideluca@icloud.com",
		"name": "Camila",
		"createdDate": "2022-03-26 20:29:48",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 196,
		"totalCountries": 5,
		"totalStates": 12,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 499,
		"schedulePercent": 0.298239,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "517e371",
		"email": "erik@dwproductions.mx",
		"name": "Erik ",
		"createdDate": "2022-10-06 06:51:18",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 139,
		"totalCountries": 2,
		"totalStates": 40,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1191,
		"schedulePercent": 0.538074,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4fd4afb",
		"email": "melissabritton73@gmail.com",
		"name": "Melissa",
		"createdDate": "2017-06-02 02:02:13",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 53,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 306,
		"schedulePercent": 0.211098,
		"totalGuests": 11,
		"guestPercent": 0.345782,
		"mostGuestDate": "2023-04-29 00:00:00",
		"mostGuestName": "Dignity Health Tennis Center",
		"mostGuestCity": "Carson",
		"mostGuestAmount": 8
	},
	{
		"id": "bcabd49",
		"email": "chris@audioprocess.com",
		"name": "Chris",
		"createdDate": "2015-03-30 17:25:28",
		"totalOrganizations": 10,
		"totalTours": 39,
		"totalDays": 891,
		"totalCountries": 20,
		"totalStates": 67,
		"totalEvents": 259,
		"eventPercent": 0.884783,
		"totalScheduleItems": 2973,
		"schedulePercent": 0.848894,
		"totalGuests": 275,
		"guestPercent": 0.59171,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "MTELUS",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 23
	},
	{
		"id": "7a75bed",
		"email": "chris@illuminatedprod.com",
		"name": "Chris",
		"createdDate": "2020-08-13 23:54:59",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 124,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 134,
		"schedulePercent": 0.110184,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cd728bb",
		"email": "mark@flysouthmusic.com",
		"name": "Mark",
		"createdDate": "2010-07-21 23:35:10",
		"totalOrganizations": 11,
		"totalTours": 8,
		"totalDays": 274,
		"totalCountries": 12,
		"totalStates": 40,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 906,
		"schedulePercent": 0.453847,
		"totalGuests": 81,
		"guestPercent": 0.455966,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "Kia Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 20
	},
	{
		"id": "1f84acd",
		"email": "Naomi.Gordon@jurassicquest.com",
		"name": "Naomi ",
		"createdDate": "2023-03-23 23:22:22",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 849,
		"totalCountries": 3,
		"totalStates": 43,
		"totalEvents": 494,
		"eventPercent": 0.973646,
		"totalScheduleItems": 5347,
		"schedulePercent": 0.970865,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6b66fd3",
		"email": "Jacobroper90@gmail.com",
		"name": "Jacob ",
		"createdDate": "2016-09-13 16:24:36",
		"totalOrganizations": 10,
		"totalTours": 9,
		"totalDays": 785,
		"totalCountries": 3,
		"totalStates": 45,
		"totalEvents": 481,
		"eventPercent": 0.971924,
		"totalScheduleItems": 6207,
		"schedulePercent": 0.982254,
		"totalGuests": 4470,
		"guestPercent": 0.987949,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Gas South Arena",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 157
	},
	{
		"id": "456c01c",
		"email": "Scottminkley@me.com",
		"name": "Scott",
		"createdDate": "2017-03-17 08:32:58",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 200,
		"totalCountries": 1,
		"totalStates": 32,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 1276,
		"schedulePercent": 0.558734,
		"totalGuests": 856,
		"guestPercent": 0.788902,
		"mostGuestDate": "2023-02-09 00:00:00",
		"mostGuestName": "Phoenix Open Golf Tournament",
		"mostGuestCity": "Scottsdale",
		"mostGuestAmount": 94
	},
	{
		"id": "e37ce1b",
		"email": "emil@aarhusbackline.dk",
		"name": "Emil",
		"createdDate": "2017-08-10 18:41:01",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 37,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 360,
		"schedulePercent": 0.237982,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c2dc9c6",
		"email": "josh@thirdwavemgmt.com",
		"name": "Joshua",
		"createdDate": "2018-04-20 18:56:40",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 248,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 817,
		"schedulePercent": 0.425374,
		"totalGuests": 935,
		"guestPercent": 0.80837,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "Bayfront Festival Park",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 162
	},
	{
		"id": "d45dd25",
		"email": "duncanladkin@hotmail.com",
		"name": "Duncan",
		"createdDate": "2012-06-04 14:59:04",
		"totalOrganizations": 10,
		"totalTours": 12,
		"totalDays": 769,
		"totalCountries": 23,
		"totalStates": 58,
		"totalEvents": 259,
		"eventPercent": 0.884783,
		"totalScheduleItems": 3423,
		"schedulePercent": 0.888889,
		"totalGuests": 67,
		"guestPercent": 0.443253,
		"mostGuestDate": "2023-03-14 00:00:00",
		"mostGuestName": "Kings Theatre",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 16
	},
	{
		"id": "59aef7b",
		"email": "pamatoms@mac.com",
		"name": "Pam",
		"createdDate": "2012-05-26 08:13:18",
		"totalOrganizations": 8,
		"totalTours": 2,
		"totalDays": 229,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 1418,
		"schedulePercent": 0.595418,
		"totalGuests": 111,
		"guestPercent": 0.483115,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "Fantasy Springs Casino",
		"mostGuestCity": "Indio",
		"mostGuestAmount": 36
	},
	{
		"id": "0551f6e",
		"email": "Merkulestm@gmail.com",
		"name": "Peter",
		"createdDate": "2019-10-05 02:42:13",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 53,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 210,
		"schedulePercent": 0.156006,
		"totalGuests": 223,
		"guestPercent": 0.564958,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "Capital Ballroom",
		"mostGuestCity": "Victoria",
		"mostGuestAmount": 54
	},
	{
		"id": "9de2604",
		"email": "alex@1066touring.com",
		"name": "Alex",
		"createdDate": "2023-06-20 22:26:04",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 363,
		"totalCountries": 10,
		"totalStates": 22,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 1003,
		"schedulePercent": 0.485896,
		"totalGuests": 27,
		"guestPercent": 0.38379,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Browns Island",
		"mostGuestCity": "Richmond",
		"mostGuestAmount": 10
	},
	{
		"id": "fbbfde9",
		"email": "natesrichter@gmail.com",
		"name": "Nathan",
		"createdDate": "2017-12-27 02:25:19",
		"totalOrganizations": 5,
		"totalTours": 22,
		"totalDays": 611,
		"totalCountries": 14,
		"totalStates": 63,
		"totalEvents": 253,
		"eventPercent": 0.877765,
		"totalScheduleItems": 3951,
		"schedulePercent": 0.921202,
		"totalGuests": 1240,
		"guestPercent": 0.863197,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 79
	},
	{
		"id": "4d75307",
		"email": "crazycatladytech@gmail.com",
		"name": "Ali",
		"createdDate": "2022-11-29 09:51:15",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 105,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 761,
		"schedulePercent": 0.406304,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9bdd4bf",
		"email": "richardhagan@mac.com",
		"name": "Richard",
		"createdDate": "2011-08-15 18:02:14",
		"totalOrganizations": 15,
		"totalTours": 11,
		"totalDays": 401,
		"totalCountries": 12,
		"totalStates": 39,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1950,
		"schedulePercent": 0.711826,
		"totalGuests": 1044,
		"guestPercent": 0.832208,
		"mostGuestDate": "2023-05-18 00:00:00",
		"mostGuestName": "History",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 51
	},
	{
		"id": "05adf79",
		"email": "racheldavis1@mac.com",
		"name": "Rachel",
		"createdDate": "2010-11-29 20:16:49",
		"totalOrganizations": 3,
		"totalTours": 16,
		"totalDays": 191,
		"totalCountries": 14,
		"totalStates": 36,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1365,
		"schedulePercent": 0.584029,
		"totalGuests": 326,
		"guestPercent": 0.617137,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "Iveagh Gardens",
		"mostGuestCity": "Dublin 2",
		"mostGuestAmount": 56
	},
	{
		"id": "af790cd",
		"email": "rbarnacott@scenelis.fr",
		"name": "Rebecca",
		"createdDate": "2020-01-12 18:53:31",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 425,
		"totalCountries": 10,
		"totalStates": 46,
		"totalEvents": 196,
		"eventPercent": 0.798835,
		"totalScheduleItems": 2510,
		"schedulePercent": 0.794597,
		"totalGuests": 887,
		"guestPercent": 0.796054,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "ArkÃ©a Arena",
		"mostGuestCity": "Floirac",
		"mostGuestAmount": 60
	},
	{
		"id": "ae1b6eb",
		"email": "monica@fbcontheweb.com",
		"name": "Monica",
		"createdDate": "2023-09-04 18:16:50",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 83,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 218,
		"schedulePercent": 0.1617,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "16c479a",
		"email": "t@a-av.net",
		"name": "Toby",
		"createdDate": "2017-11-07 10:42:00",
		"totalOrganizations": 36,
		"totalTours": 68,
		"totalDays": 1272,
		"totalCountries": 18,
		"totalStates": 63,
		"totalEvents": 363,
		"eventPercent": 0.947424,
		"totalScheduleItems": 3826,
		"schedulePercent": 0.913654,
		"totalGuests": 924,
		"guestPercent": 0.804794,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 58
	},
	{
		"id": "2a9a35d",
		"email": "adwashington@gmail.com",
		"name": "Adam",
		"createdDate": "2022-08-25 10:58:38",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 106,
		"totalCountries": 16,
		"totalStates": 30,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 820,
		"schedulePercent": 0.426566,
		"totalGuests": 22,
		"guestPercent": 0.373725,
		"mostGuestDate": "2023-06-06 00:00:00",
		"mostGuestName": "Oslo",
		"mostGuestCity": "London",
		"mostGuestAmount": 9
	},
	{
		"id": "21de311",
		"email": "coreyaroberson@gmail.com",
		"name": "Corey",
		"createdDate": "2019-01-31 20:47:40",
		"totalOrganizations": 9,
		"totalTours": 3,
		"totalDays": 656,
		"totalCountries": 3,
		"totalStates": 48,
		"totalEvents": 205,
		"eventPercent": 0.814859,
		"totalScheduleItems": 4437,
		"schedulePercent": 0.943054,
		"totalGuests": 2583,
		"guestPercent": 0.9559,
		"mostGuestDate": "2023-06-28 00:00:00",
		"mostGuestName": "Rogers Arena",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 178
	},
	{
		"id": "ea7cb94",
		"email": "mandelyn.monchick@redlightmanagement.com",
		"name": "Mandelyn",
		"createdDate": "2019-08-15 19:24:24",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 800,
		"totalCountries": 4,
		"totalStates": 51,
		"totalEvents": 253,
		"eventPercent": 0.877765,
		"totalScheduleItems": 1719,
		"schedulePercent": 0.669315,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 7
	},
	{
		"id": "3747a45",
		"email": "alexandranoele1@gmail.com",
		"name": "Allie",
		"createdDate": "2017-08-30 14:05:21",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 83,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 819,
		"schedulePercent": 0.426169,
		"totalGuests": 2824,
		"guestPercent": 0.964905,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 298
	},
	{
		"id": "333984e",
		"email": "stacey@awakeningevents.com",
		"name": "Stacey",
		"createdDate": "2014-08-02 13:59:22",
		"totalOrganizations": 17,
		"totalTours": 10,
		"totalDays": 836,
		"totalCountries": 3,
		"totalStates": 45,
		"totalEvents": 508,
		"eventPercent": 0.975235,
		"totalScheduleItems": 6564,
		"schedulePercent": 0.985565,
		"totalGuests": 4516,
		"guestPercent": 0.988213,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Gas South Arena",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 157
	},
	{
		"id": "d13e7ab",
		"email": "johnpeterfarrell@gmail.com",
		"name": "JOHN ",
		"createdDate": "2019-01-13 23:57:19",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 409,
		"totalCountries": 13,
		"totalStates": 51,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 1835,
		"schedulePercent": 0.692756,
		"totalGuests": 1610,
		"guestPercent": 0.903854,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 74
	},
	{
		"id": "d723307",
		"email": "olivia@whyandhow.com",
		"name": "Olivia",
		"createdDate": "2021-07-22 15:34:36",
		"totalOrganizations": 8,
		"totalTours": 12,
		"totalDays": 1616,
		"totalCountries": 7,
		"totalStates": 54,
		"totalEvents": 245,
		"eventPercent": 0.867435,
		"totalScheduleItems": 3147,
		"schedulePercent": 0.865316,
		"totalGuests": 656,
		"guestPercent": 0.741094,
		"mostGuestDate": "2023-05-18 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 60
	},
	{
		"id": "b7d3d3f",
		"email": "Emarielars@gmail.com",
		"name": "Erin",
		"createdDate": "2017-07-13 19:38:33",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 244,
		"totalCountries": 12,
		"totalStates": 47,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 983,
		"schedulePercent": 0.476758,
		"totalGuests": 93,
		"guestPercent": 0.468017,
		"mostGuestDate": "2023-04-02 00:00:00",
		"mostGuestName": "Fillmore Auditorium",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 15
	},
	{
		"id": "5f60f49",
		"email": "perusikakaire@gmail.com",
		"name": "Perusi",
		"createdDate": "2020-10-24 10:19:48",
		"totalOrganizations": 10,
		"totalTours": 13,
		"totalDays": 464,
		"totalCountries": 21,
		"totalStates": 45,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 1047,
		"schedulePercent": 0.496226,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f9c23b8",
		"email": "lachlanjharper@gmail.com",
		"name": "Lachlan",
		"createdDate": "2018-07-26 14:12:05",
		"totalOrganizations": 1,
		"totalTours": 9,
		"totalDays": 480,
		"totalCountries": 13,
		"totalStates": 43,
		"totalEvents": 214,
		"eventPercent": 0.829559,
		"totalScheduleItems": 1658,
		"schedulePercent": 0.655145,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3c35b63",
		"email": "elin.nilsson@livenation.se",
		"name": "Elin",
		"createdDate": "2022-02-01 12:07:16",
		"totalOrganizations": 54,
		"totalTours": 59,
		"totalDays": 770,
		"totalCountries": 8,
		"totalStates": 38,
		"totalEvents": 538,
		"eventPercent": 0.978281,
		"totalScheduleItems": 4011,
		"schedulePercent": 0.923851,
		"totalGuests": 477,
		"guestPercent": 0.682823,
		"mostGuestDate": "2023-01-26 00:00:00",
		"mostGuestName": "Debaser Hornstulls Strand",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 38
	},
	{
		"id": "98fbf48",
		"email": "emmetstutt@gmail.com",
		"name": "Emmet",
		"createdDate": "2013-03-27 17:10:30",
		"totalOrganizations": 22,
		"totalTours": 8,
		"totalDays": 193,
		"totalCountries": 3,
		"totalStates": 23,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 1112,
		"schedulePercent": 0.514369,
		"totalGuests": 184,
		"guestPercent": 0.539266,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Tradex",
		"mostGuestCity": "Abbotsford",
		"mostGuestAmount": 44
	},
	{
		"id": "3670173",
		"email": "aliejo18@gmail.com",
		"name": "Alie",
		"createdDate": "2014-02-12 00:25:34",
		"totalOrganizations": 9,
		"totalTours": 1,
		"totalDays": 33,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 277,
		"schedulePercent": 0.19653,
		"totalGuests": 17,
		"guestPercent": 0.362469,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "Oak Mountain Amphitheatre",
		"mostGuestCity": "Pelham",
		"mostGuestAmount": 15
	},
	{
		"id": "53c1838",
		"email": "mastertour@badtasteempire.com",
		"name": "Mastertour",
		"createdDate": "2023-04-04 13:22:17",
		"totalOrganizations": 5,
		"totalTours": 14,
		"totalDays": 618,
		"totalCountries": 11,
		"totalStates": 23,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 1837,
		"schedulePercent": 0.693153,
		"totalGuests": 238,
		"guestPercent": 0.572639,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "Debaser Strand",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 62
	},
	{
		"id": "de67e8d",
		"email": "jasonmichaelmills@gmail.com",
		"name": "Jason",
		"createdDate": "2017-05-01 21:10:16",
		"totalOrganizations": 7,
		"totalTours": 13,
		"totalDays": 307,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 1953,
		"schedulePercent": 0.712621,
		"totalGuests": 74,
		"guestPercent": 0.449212,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "Fox Theater",
		"mostGuestCity": "Oakland",
		"mostGuestAmount": 51
	},
	{
		"id": "e8079ac",
		"email": "ladonna@ericgalesband.com",
		"name": "LaDonna ",
		"createdDate": "2017-06-29 21:23:40",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 363,
		"totalCountries": 6,
		"totalStates": 46,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 1344,
		"schedulePercent": 0.578864,
		"totalGuests": 283,
		"guestPercent": 0.595153,
		"mostGuestDate": "2023-02-28 00:00:00",
		"mostGuestName": "3rd & Lindsley Bar & Grill",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 22
	},
	{
		"id": "636cbbd",
		"email": "shussein7@gmail.com",
		"name": "Steve",
		"createdDate": "2018-07-31 23:39:24",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 232,
		"totalCountries": 12,
		"totalStates": 54,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 2336,
		"schedulePercent": 0.771818,
		"totalGuests": 132,
		"guestPercent": 0.499801,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 112
	},
	{
		"id": "afda7fa",
		"email": "manoel@magiccirclemusic.com",
		"name": "Manoel",
		"createdDate": "2012-05-29 00:53:58",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 188,
		"totalCountries": 11,
		"totalStates": 21,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 1200,
		"schedulePercent": 0.540326,
		"totalGuests": 995,
		"guestPercent": 0.822408,
		"mostGuestDate": "2023-02-25 00:00:00",
		"mostGuestName": "Velodrom",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 138
	},
	{
		"id": "f81bfb7",
		"email": "siannmmcfee@gmail.com",
		"name": "Siann",
		"createdDate": "2022-12-30 11:05:39",
		"totalOrganizations": 24,
		"totalTours": 79,
		"totalDays": 1811,
		"totalCountries": 23,
		"totalStates": 96,
		"totalEvents": 711,
		"eventPercent": 0.986492,
		"totalScheduleItems": 7488,
		"schedulePercent": 0.991259,
		"totalGuests": 930,
		"guestPercent": 0.806913,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 69
	},
	{
		"id": "c41ae20",
		"email": "lovelythebandtm@gmail.com",
		"name": "Drew",
		"createdDate": "2021-07-14 18:57:51",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 540,
		"totalCountries": 9,
		"totalStates": 44,
		"totalEvents": 157,
		"eventPercent": 0.720567,
		"totalScheduleItems": 2565,
		"schedulePercent": 0.801218,
		"totalGuests": 2141,
		"guestPercent": 0.941332,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 142
	},
	{
		"id": "1f1ac26",
		"email": "mccourytravel@gmail.com",
		"name": "Chris",
		"createdDate": "2022-02-14 22:38:21",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 691,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 410,
		"schedulePercent": 0.261422,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cb9bdb8",
		"email": "johan@pangurmusic.com",
		"name": "Johan",
		"createdDate": "2019-01-17 19:40:56",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 154,
		"totalCountries": 7,
		"totalStates": 19,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 642,
		"schedulePercent": 0.359423,
		"totalGuests": 389,
		"guestPercent": 0.648656,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Gothenburg Film Studios",
		"mostGuestCity": "GÃ¶teborg",
		"mostGuestAmount": 78
	},
	{
		"id": "0bdd623",
		"email": "STPMasterTour@gmail.com",
		"name": "Glenn",
		"createdDate": "2022-11-21 20:47:06",
		"totalOrganizations": 1,
		"totalTours": 10,
		"totalDays": 158,
		"totalCountries": 6,
		"totalStates": 19,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 222,
		"schedulePercent": 0.164879,
		"totalGuests": 128,
		"guestPercent": 0.49755,
		"mostGuestDate": "2023-08-09 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 42
	},
	{
		"id": "8c91005",
		"email": "production@l-productions.fr",
		"name": "Productions",
		"createdDate": "2022-06-16 16:22:08",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 654,
		"totalCountries": 8,
		"totalStates": 28,
		"totalEvents": 184,
		"eventPercent": 0.77791,
		"totalScheduleItems": 1886,
		"schedulePercent": 0.702556,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6c417cd",
		"email": "drewaarnold@gmail.com",
		"name": "Drew",
		"createdDate": "2023-05-05 20:07:56",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 45,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 262,
		"schedulePercent": 0.186465,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "46dc947",
		"email": "Jen.Danielson@wmg.com",
		"name": "Jen",
		"createdDate": "2022-04-13 16:43:09",
		"totalOrganizations": 49,
		"totalTours": 44,
		"totalDays": 15011,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 1913,
		"eventPercent": 0.993908,
		"totalScheduleItems": 742,
		"schedulePercent": 0.397431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0a4aaf8",
		"email": "zach@triple8mgmt.com",
		"name": "Zach",
		"createdDate": "2022-10-12 22:29:50",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 589,
		"totalCountries": 2,
		"totalStates": 41,
		"totalEvents": 162,
		"eventPercent": 0.732883,
		"totalScheduleItems": 1764,
		"schedulePercent": 0.677526,
		"totalGuests": 640,
		"guestPercent": 0.735929,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 51
	},
	{
		"id": "441958c",
		"email": "soundguypatrick@gmail.com",
		"name": "Patrick",
		"createdDate": "2013-05-23 18:54:59",
		"totalOrganizations": 11,
		"totalTours": 8,
		"totalDays": 918,
		"totalCountries": 9,
		"totalStates": 64,
		"totalEvents": 329,
		"eventPercent": 0.930738,
		"totalScheduleItems": 4111,
		"schedulePercent": 0.928486,
		"totalGuests": 1617,
		"guestPercent": 0.905046,
		"mostGuestDate": "2023-10-12 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 90
	},
	{
		"id": "a668fa0",
		"email": "adamgerber42@gmail.com",
		"name": "Adam",
		"createdDate": "2016-07-02 02:39:06",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 343,
		"totalCountries": 6,
		"totalStates": 46,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1390,
		"schedulePercent": 0.588531,
		"totalGuests": 893,
		"guestPercent": 0.797908,
		"mostGuestDate": "2023-12-07 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 39
	},
	{
		"id": "68c0db7",
		"email": "Larry@togetherwemarch.com",
		"name": "Larry",
		"createdDate": "2015-08-12 05:53:07",
		"totalOrganizations": 10,
		"totalTours": 4,
		"totalDays": 190,
		"totalCountries": 7,
		"totalStates": 35,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 707,
		"schedulePercent": 0.383525,
		"totalGuests": 101,
		"guestPercent": 0.474904,
		"mostGuestDate": "2023-10-25 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 44
	},
	{
		"id": "d621e4d",
		"email": "lukew@ccaproductions.com.au",
		"name": "Luke",
		"createdDate": "2012-08-06 05:30:22",
		"totalOrganizations": 15,
		"totalTours": 30,
		"totalDays": 452,
		"totalCountries": 25,
		"totalStates": 57,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 2052,
		"schedulePercent": 0.727851,
		"totalGuests": 452,
		"guestPercent": 0.674613,
		"mostGuestDate": "2023-02-09 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 76
	},
	{
		"id": "f11b14b",
		"email": "sara@vidisco.pt",
		"name": "Sara",
		"createdDate": "2022-08-10 15:02:03",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 423,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 676,
		"schedulePercent": 0.370944,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b58bc97",
		"email": "NorwoodWood@gmail.com",
		"name": "Norwood",
		"createdDate": "2015-11-08 04:22:30",
		"totalOrganizations": 11,
		"totalTours": 9,
		"totalDays": 1126,
		"totalCountries": 7,
		"totalStates": 60,
		"totalEvents": 263,
		"eventPercent": 0.888889,
		"totalScheduleItems": 4669,
		"schedulePercent": 0.950867,
		"totalGuests": 1796,
		"guestPercent": 0.920011,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Nissan Stadium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 90
	},
	{
		"id": "cf4434f",
		"email": "liamhicksj@hotmail.com",
		"name": "Liam",
		"createdDate": "2018-09-19 18:20:07",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 423,
		"totalCountries": 11,
		"totalStates": 46,
		"totalEvents": 166,
		"eventPercent": 0.74308,
		"totalScheduleItems": 2013,
		"schedulePercent": 0.721626,
		"totalGuests": 407,
		"guestPercent": 0.656337,
		"mostGuestDate": "2023-03-20 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 69
	},
	{
		"id": "4376a4a",
		"email": "abelocasio@gmail.com",
		"name": "Abel",
		"createdDate": "2019-03-08 19:29:53",
		"totalOrganizations": 10,
		"totalTours": 10,
		"totalDays": 758,
		"totalCountries": 7,
		"totalStates": 49,
		"totalEvents": 209,
		"eventPercent": 0.82201,
		"totalScheduleItems": 2928,
		"schedulePercent": 0.845186,
		"totalGuests": 863,
		"guestPercent": 0.790624,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 102
	},
	{
		"id": "f3176e7",
		"email": "info@momix.com",
		"name": "Paula",
		"createdDate": "2017-02-27 20:01:10",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 124,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 645,
		"schedulePercent": 0.360482,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2844f80",
		"email": "d@supermgmt.com",
		"name": "Derek",
		"createdDate": "2020-01-30 21:16:45",
		"totalOrganizations": 10,
		"totalTours": 12,
		"totalDays": 264,
		"totalCountries": 6,
		"totalStates": 35,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 720,
		"schedulePercent": 0.388028,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a5d43b7",
		"email": "rbennett443@gmail.com",
		"name": "Rachel",
		"createdDate": "2023-05-03 04:20:18",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 43,
		"totalCountries": 2,
		"totalStates": 17,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 227,
		"schedulePercent": 0.168057,
		"totalGuests": 134,
		"guestPercent": 0.501258,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Zebulon",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 20
	},
	{
		"id": "62102dc",
		"email": "trjashton@gmail.com",
		"name": "Taylor",
		"createdDate": "2017-04-24 12:26:09",
		"totalOrganizations": 6,
		"totalTours": 13,
		"totalDays": 258,
		"totalCountries": 8,
		"totalStates": 20,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 1082,
		"schedulePercent": 0.50841,
		"totalGuests": 930,
		"guestPercent": 0.806913,
		"mostGuestDate": "2023-01-20 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 50
	},
	{
		"id": "baeeac1",
		"email": "p.g.banos@gmail.com",
		"name": "Pedro",
		"createdDate": "2017-07-08 17:04:36",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 215,
		"totalCountries": 12,
		"totalStates": 44,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1187,
		"schedulePercent": 0.536618,
		"totalGuests": 1095,
		"guestPercent": 0.838564,
		"mostGuestDate": "2023-05-07 00:00:00",
		"mostGuestName": "Palacio de los Deportes",
		"mostGuestCity": "Ciudad de Mexico",
		"mostGuestAmount": 70
	},
	{
		"id": "89f6d96",
		"email": "Jcarroccio@altatouring.com",
		"name": "Jim",
		"createdDate": "2019-01-20 13:25:12",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 293,
		"totalCountries": 10,
		"totalStates": 52,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 1329,
		"schedulePercent": 0.574758,
		"totalGuests": 1119,
		"guestPercent": 0.842802,
		"mostGuestDate": "2023-06-09 00:00:00",
		"mostGuestName": "Dreamland Margate - Scenic Stage",
		"mostGuestCity": "Margate",
		"mostGuestAmount": 77
	},
	{
		"id": "54c1bb1",
		"email": "kate.hays@braggmanagement.com",
		"name": "Bragg",
		"createdDate": "2019-01-13 20:52:34",
		"totalOrganizations": 11,
		"totalTours": 10,
		"totalDays": 289,
		"totalCountries": 2,
		"totalStates": 40,
		"totalEvents": 232,
		"eventPercent": 0.852073,
		"totalScheduleItems": 2547,
		"schedulePercent": 0.798702,
		"totalGuests": 752,
		"guestPercent": 0.765064,
		"mostGuestDate": "2023-04-10 00:00:00",
		"mostGuestName": "The Fisher Center for the Performing Arts",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 139
	},
	{
		"id": "e2fc2a3",
		"email": "Louise.larsson@lifeline.se",
		"name": "Louise ",
		"createdDate": "2021-07-05 06:54:15",
		"totalOrganizations": 16,
		"totalTours": 6,
		"totalDays": 297,
		"totalCountries": 3,
		"totalStates": 16,
		"totalEvents": 211,
		"eventPercent": 0.825586,
		"totalScheduleItems": 1984,
		"schedulePercent": 0.716594,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "03f43e3",
		"email": "olympe.dumoulin@onj.org",
		"name": "Olympe",
		"createdDate": "2016-10-11 15:22:55",
		"totalOrganizations": 10,
		"totalTours": 6,
		"totalDays": 61,
		"totalCountries": 3,
		"totalStates": 5,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 330,
		"schedulePercent": 0.224209,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8c81a81",
		"email": "exactabox77@gmail.com",
		"name": "Mike ",
		"createdDate": "2011-09-05 15:44:16",
		"totalOrganizations": 11,
		"totalTours": 44,
		"totalDays": 513,
		"totalCountries": 11,
		"totalStates": 51,
		"totalEvents": 291,
		"eventPercent": 0.909548,
		"totalScheduleItems": 2381,
		"schedulePercent": 0.777513,
		"totalGuests": 194,
		"guestPercent": 0.54602,
		"mostGuestDate": "2023-06-06 00:00:00",
		"mostGuestName": "Paradise Rock Club",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 33
	},
	{
		"id": "cb29469",
		"email": "joe@thehustlemode.com",
		"name": "Joe",
		"createdDate": "2023-09-12 01:00:37",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 68,
		"totalCountries": 1,
		"totalStates": 25,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 437,
		"schedulePercent": 0.271355,
		"totalGuests": 75,
		"guestPercent": 0.450007,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Nile Theater",
		"mostGuestCity": "Mesa",
		"mostGuestAmount": 22
	},
	{
		"id": "efcef72",
		"email": "zakbonin@gmail.com",
		"name": "Zachary",
		"createdDate": "2018-01-24 22:50:58",
		"totalOrganizations": 12,
		"totalTours": 34,
		"totalDays": 492,
		"totalCountries": 15,
		"totalStates": 64,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 1614,
		"schedulePercent": 0.64561,
		"totalGuests": 1321,
		"guestPercent": 0.874454,
		"mostGuestDate": "2023-09-27 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 80
	},
	{
		"id": "dd62f4a",
		"email": "urneband@gmail.com",
		"name": "Joe",
		"createdDate": "2023-04-20 11:55:31",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 42,
		"totalCountries": 4,
		"totalStates": 6,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 416,
		"schedulePercent": 0.263144,
		"totalGuests": 225,
		"guestPercent": 0.566018,
		"mostGuestDate": "2023-07-02 00:00:00",
		"mostGuestName": "Festival de Nimes",
		"mostGuestCity": "NÃ®mes",
		"mostGuestAmount": 66
	},
	{
		"id": "83ea4bb",
		"email": "mckaytouring@mac.com",
		"name": "Daniel ",
		"createdDate": "2009-10-03 02:27:58",
		"totalOrganizations": 15,
		"totalTours": 5,
		"totalDays": 556,
		"totalCountries": 9,
		"totalStates": 47,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 1684,
		"schedulePercent": 0.66084,
		"totalGuests": 2002,
		"guestPercent": 0.933651,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 183
	},
	{
		"id": "06fa9de",
		"email": "gunnar@skyskraperartist.no",
		"name": "Gunnar",
		"createdDate": "2020-10-13 17:49:31",
		"totalOrganizations": 14,
		"totalTours": 5,
		"totalDays": 116,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 751,
		"schedulePercent": 0.402331,
		"totalGuests": 122,
		"guestPercent": 0.492253,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Kolben",
		"mostGuestCity": "Kolbotn",
		"mostGuestAmount": 25
	},
	{
		"id": "086ffd7",
		"email": "epluggmusik@gmail.com",
		"name": "Everett",
		"createdDate": "2018-10-10 18:15:39",
		"totalOrganizations": 2,
		"totalTours": 7,
		"totalDays": 122,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 635,
		"schedulePercent": 0.356112,
		"totalGuests": 419,
		"guestPercent": 0.660707,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "Warner Theatre",
		"mostGuestCity": "Washington",
		"mostGuestAmount": 40
	},
	{
		"id": "782fa9d",
		"email": "rich.goodwin@greatvalley.co.uk",
		"name": "Rich",
		"createdDate": "2021-09-17 19:11:17",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 28,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 310,
		"schedulePercent": 0.213349,
		"totalGuests": 154,
		"guestPercent": 0.51662,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Connexin Live, Hull",
		"mostGuestCity": "",
		"mostGuestAmount": 47
	},
	{
		"id": "9581c0d",
		"email": "sophie@lostmotel.com",
		"name": "Sophie ",
		"createdDate": "2020-02-09 22:01:44",
		"totalOrganizations": 31,
		"totalTours": 47,
		"totalDays": 1399,
		"totalCountries": 20,
		"totalStates": 94,
		"totalEvents": 559,
		"eventPercent": 0.9804,
		"totalScheduleItems": 7062,
		"schedulePercent": 0.98967,
		"totalGuests": 1770,
		"guestPercent": 0.918024,
		"mostGuestDate": "2023-06-01 00:00:00",
		"mostGuestName": "The Enmore Theatre",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 107
	},
	{
		"id": "5ccab21",
		"email": "alan@nxav.co.uk",
		"name": "Alan",
		"createdDate": "2015-10-06 15:13:33",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 306,
		"totalCountries": 4,
		"totalStates": 15,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 564,
		"schedulePercent": 0.326579,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "052dac2",
		"email": "matt@46entertainment.com",
		"name": "Matt",
		"createdDate": "2020-01-02 13:12:00",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 146,
		"totalCountries": 2,
		"totalStates": 35,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1125,
		"schedulePercent": 0.51768,
		"totalGuests": 593,
		"guestPercent": 0.723878,
		"mostGuestDate": "2023-03-06 00:00:00",
		"mostGuestName": "Florida Strawberry Festival",
		"mostGuestCity": "Plant City",
		"mostGuestAmount": 40
	},
	{
		"id": "18d7cfc",
		"email": "djm.design@yahoo.com",
		"name": "David",
		"createdDate": "2018-03-14 04:39:12",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 86,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 540,
		"schedulePercent": 0.317706,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9b00e97",
		"email": "steve.dalmer@invasiongroup.com",
		"name": "Steve",
		"createdDate": "2017-08-11 15:47:03",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 71,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 408,
		"schedulePercent": 0.260363,
		"totalGuests": 293,
		"guestPercent": 0.599258,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Le Poisson Rouge",
		"mostGuestCity": "New York",
		"mostGuestAmount": 29
	},
	{
		"id": "81b2b13",
		"email": "shannonward1@gmail.com",
		"name": "Shannon ",
		"createdDate": "2023-01-09 02:00:16",
		"totalOrganizations": 12,
		"totalTours": 13,
		"totalDays": 525,
		"totalCountries": 6,
		"totalStates": 15,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1335,
		"schedulePercent": 0.576215,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "YARRABAH SPORTS FIELD",
		"mostGuestCity": "YARRABAH",
		"mostGuestAmount": 6
	},
	{
		"id": "5a97de3",
		"email": "steve@608soundandlight.com",
		"name": "Steve",
		"createdDate": "2021-08-20 01:44:14",
		"totalOrganizations": 7,
		"totalTours": 16,
		"totalDays": 391,
		"totalCountries": 17,
		"totalStates": 69,
		"totalEvents": 231,
		"eventPercent": 0.850616,
		"totalScheduleItems": 3241,
		"schedulePercent": 0.873262,
		"totalGuests": 1261,
		"guestPercent": 0.866773,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 91
	},
	{
		"id": "f0145ad",
		"email": "cook383@ymail.com",
		"name": "Jason",
		"createdDate": "2019-08-18 00:39:02",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 306,
		"totalCountries": 2,
		"totalStates": 40,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 2952,
		"schedulePercent": 0.847305,
		"totalGuests": 1043,
		"guestPercent": 0.831545,
		"mostGuestDate": "2023-06-28 00:00:00",
		"mostGuestName": "Rogers Arena",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 178
	},
	{
		"id": "8ae15e0",
		"email": "victor.reich@initial-artistes.com",
		"name": "Reich",
		"createdDate": "2018-03-05 14:10:58",
		"totalOrganizations": 11,
		"totalTours": 6,
		"totalDays": 656,
		"totalCountries": 4,
		"totalStates": 24,
		"totalEvents": 228,
		"eventPercent": 0.845848,
		"totalScheduleItems": 2815,
		"schedulePercent": 0.834194,
		"totalGuests": 126,
		"guestPercent": 0.495564,
		"mostGuestDate": "2023-02-08 00:00:00",
		"mostGuestName": "Stereolux",
		"mostGuestCity": "Nantes",
		"mostGuestAmount": 31
	},
	{
		"id": "c6b8696",
		"email": "gabriel.colbert@gmail.com",
		"name": "Gabriel",
		"createdDate": "2017-08-30 05:24:55",
		"totalOrganizations": 8,
		"totalTours": 1,
		"totalDays": 347,
		"totalCountries": 10,
		"totalStates": 18,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 665,
		"schedulePercent": 0.367236,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "91e6b46",
		"email": "agneta@twimc.se",
		"name": "Agneta",
		"createdDate": "2023-02-02 12:28:02",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 69,
		"totalCountries": 4,
		"totalStates": 15,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 30,
		"schedulePercent": 0.0401271,
		"totalGuests": 66,
		"guestPercent": 0.441001,
		"mostGuestDate": "2023-03-02 00:00:00",
		"mostGuestName": "EartH (Evolutionary Arts Hackney)",
		"mostGuestCity": "London",
		"mostGuestAmount": 13
	},
	{
		"id": "8201804",
		"email": "katie@sandboxmgmt.com",
		"name": "Katie",
		"createdDate": "2014-02-27 22:47:30",
		"totalOrganizations": 11,
		"totalTours": 9,
		"totalDays": 2231,
		"totalCountries": 7,
		"totalStates": 57,
		"totalEvents": 342,
		"eventPercent": 0.938154,
		"totalScheduleItems": 6586,
		"schedulePercent": 0.986095,
		"totalGuests": 1747,
		"guestPercent": 0.916567,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Nissan Stadium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 90
	},
	{
		"id": "774da4e",
		"email": "danavery.tm@gmail.com",
		"name": "Dan",
		"createdDate": "2021-09-10 14:36:09",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 301,
		"totalCountries": 12,
		"totalStates": 38,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1774,
		"schedulePercent": 0.679513,
		"totalGuests": 476,
		"guestPercent": 0.682559,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 59
	},
	{
		"id": "22a6fd4",
		"email": "atm@twangtouring.com",
		"name": "Chelsae",
		"createdDate": "2022-05-24 22:03:51",
		"totalOrganizations": 7,
		"totalTours": 14,
		"totalDays": 1647,
		"totalCountries": 8,
		"totalStates": 56,
		"totalEvents": 227,
		"eventPercent": 0.844391,
		"totalScheduleItems": 2823,
		"schedulePercent": 0.834989,
		"totalGuests": 344,
		"guestPercent": 0.624685,
		"mostGuestDate": "2023-12-07 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 33
	},
	{
		"id": "d3a5b7d",
		"email": "laurapeasetm@gmail.com",
		"name": "Laura",
		"createdDate": "2012-02-15 20:53:33",
		"totalOrganizations": 15,
		"totalTours": 7,
		"totalDays": 324,
		"totalCountries": 6,
		"totalStates": 30,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1818,
		"schedulePercent": 0.68865,
		"totalGuests": 1910,
		"guestPercent": 0.929413,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 281
	},
	{
		"id": "d89342a",
		"email": "hrsparkle@icloud.com",
		"name": "Pamela",
		"createdDate": "2016-04-29 15:35:19",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 336,
		"totalCountries": 6,
		"totalStates": 27,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 1109,
		"schedulePercent": 0.513839,
		"totalGuests": 684,
		"guestPercent": 0.749172,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 108
	},
	{
		"id": "5b75507",
		"email": "matt@majikmgt.com",
		"name": "Matt",
		"createdDate": "2023-01-17 19:26:27",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 696,
		"totalCountries": 10,
		"totalStates": 54,
		"totalEvents": 176,
		"eventPercent": 0.763475,
		"totalScheduleItems": 2061,
		"schedulePercent": 0.72891,
		"totalGuests": 575,
		"guestPercent": 0.718315,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "The Brooklyn Mirage",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 96
	},
	{
		"id": "0553196",
		"email": "joseph@humblemonsters.com",
		"name": "Joseph",
		"createdDate": "2022-03-14 00:01:40",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 306,
		"totalCountries": 3,
		"totalStates": 18,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 506,
		"schedulePercent": 0.301814,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f4c1513",
		"email": "maine.samantha@gmail.com",
		"name": "Sammy",
		"createdDate": "2022-07-19 15:46:55",
		"totalOrganizations": 3,
		"totalTours": 11,
		"totalDays": 171,
		"totalCountries": 9,
		"totalStates": 32,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 1863,
		"schedulePercent": 0.697921,
		"totalGuests": 2662,
		"guestPercent": 0.958416,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The ICON Festival Stage",
		"mostGuestCity": "Cincinnati",
		"mostGuestAmount": 238
	},
	{
		"id": "0239f55",
		"email": "mfeldman@cityparksfoundation.org",
		"name": "Madeleine",
		"createdDate": "2020-02-24 15:35:02",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3e83c91",
		"email": "petergreenaudio@gmail.com",
		"name": "Peter",
		"createdDate": "2018-02-26 21:49:32",
		"totalOrganizations": 12,
		"totalTours": 39,
		"totalDays": 617,
		"totalCountries": 7,
		"totalStates": 51,
		"totalEvents": 305,
		"eventPercent": 0.918554,
		"totalScheduleItems": 2551,
		"schedulePercent": 0.799364,
		"totalGuests": 420,
		"guestPercent": 0.661237,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Revelree Entertainment Inc.",
		"mostGuestCity": "Sarnia",
		"mostGuestAmount": 36
	},
	{
		"id": "a295ab3",
		"email": "arturo@410Grand.com",
		"name": "Arturo",
		"createdDate": "2022-04-15 19:02:29",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 1018,
		"totalCountries": 4,
		"totalStates": 15,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 288,
		"schedulePercent": 0.201828,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "53ece69",
		"email": "lindseywiler@gmail.com",
		"name": "Lindsey",
		"createdDate": "2016-01-30 21:50:43",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 153,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 984,
		"schedulePercent": 0.477553,
		"totalGuests": 148,
		"guestPercent": 0.51225,
		"mostGuestDate": "2023-02-24 00:00:00",
		"mostGuestName": "Amalie Arena",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 25
	},
	{
		"id": "978472d",
		"email": "alexander@tenonezero.com",
		"name": "Alex",
		"createdDate": "2020-02-19 04:06:23",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 104,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 271,
		"schedulePercent": 0.192822,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "933f8ef",
		"email": "rootzproductions@aol.com",
		"name": "Kenneth",
		"createdDate": "2017-06-19 00:17:13",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 764,
		"totalCountries": 4,
		"totalStates": 23,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 483,
		"schedulePercent": 0.290028,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "Bellco Theatre",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 4
	},
	{
		"id": "9d56db3",
		"email": "hb@makewake.net",
		"name": "Helen",
		"createdDate": "2022-01-05 20:37:10",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 1707,
		"totalCountries": 9,
		"totalStates": 62,
		"totalEvents": 416,
		"eventPercent": 0.96133,
		"totalScheduleItems": 5475,
		"schedulePercent": 0.972719,
		"totalGuests": 3708,
		"guestPercent": 0.97987,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Raymond James Stadium",
		"mostGuestCity": "Tampa ",
		"mostGuestAmount": 248
	},
	{
		"id": "c6c72f6",
		"email": "jens@bobproduction.dk",
		"name": "Jens",
		"createdDate": "2018-03-23 08:58:57",
		"totalOrganizations": 13,
		"totalTours": 9,
		"totalDays": 289,
		"totalCountries": 10,
		"totalStates": 17,
		"totalEvents": 223,
		"eventPercent": 0.840154,
		"totalScheduleItems": 1943,
		"schedulePercent": 0.710237,
		"totalGuests": 576,
		"guestPercent": 0.718448,
		"mostGuestDate": "2023-03-12 00:00:00",
		"mostGuestName": "Verti Music Hall",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 63
	},
	{
		"id": "08b9af0",
		"email": "Gina@tulippoint.com",
		"name": "Gina",
		"createdDate": "2015-07-24 14:41:06",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 742,
		"totalCountries": 5,
		"totalStates": 54,
		"totalEvents": 412,
		"eventPercent": 0.960535,
		"totalScheduleItems": 5130,
		"schedulePercent": 0.965435,
		"totalGuests": 1128,
		"guestPercent": 0.844259,
		"mostGuestDate": "2023-12-06 00:00:00",
		"mostGuestName": "Terrace Theatre",
		"mostGuestCity": "Long Beach",
		"mostGuestAmount": 73
	},
	{
		"id": "21ef029",
		"email": "ada@morsatouring.com",
		"name": "Ada ",
		"createdDate": "2023-09-17 20:13:02",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 34,
		"totalCountries": 5,
		"totalStates": 6,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 193,
		"schedulePercent": 0.145941,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2b96ef8",
		"email": "sophie@bigleague.org",
		"name": "Sophie",
		"createdDate": "2019-12-21 01:21:29",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 199,
		"totalCountries": 2,
		"totalStates": 35,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 583,
		"schedulePercent": 0.333068,
		"totalGuests": 758,
		"guestPercent": 0.766256,
		"mostGuestDate": "2023-05-21 00:00:00",
		"mostGuestName": "Kravis Center",
		"mostGuestCity": "West Palm Beach",
		"mostGuestAmount": 41
	},
	{
		"id": "9365fb5",
		"email": "achaiss@gmail.com",
		"name": "Antoine",
		"createdDate": "2018-05-14 10:46:59",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 115,
		"totalCountries": 14,
		"totalStates": 20,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 688,
		"schedulePercent": 0.375579,
		"totalGuests": 241,
		"guestPercent": 0.574361,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "Philharmonie de Paris",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 30
	},
	{
		"id": "ff94f2b",
		"email": "stevemillerprod@aol.com",
		"name": "Brett",
		"createdDate": "2009-09-02 21:26:10",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 114,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 1113,
		"schedulePercent": 0.514634,
		"totalGuests": 1042,
		"guestPercent": 0.831148,
		"mostGuestDate": "2023-09-11 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 69
	},
	{
		"id": "4557a59",
		"email": "mastertour@harrymiree.com",
		"name": "Harry",
		"createdDate": "2017-01-30 21:24:17",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 731,
		"totalCountries": 4,
		"totalStates": 51,
		"totalEvents": 193,
		"eventPercent": 0.792743,
		"totalScheduleItems": 3125,
		"schedulePercent": 0.863727,
		"totalGuests": 2043,
		"guestPercent": 0.936432,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Nashville Municipal Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 92
	},
	{
		"id": "8c5559d",
		"email": "doug.goodman@earthlink.net",
		"name": "Doug",
		"createdDate": "2011-07-06 08:12:30",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 381,
		"totalCountries": 4,
		"totalStates": 36,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 1285,
		"schedulePercent": 0.56125,
		"totalGuests": 2045,
		"guestPercent": 0.936697,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "Forest Hills Stadium",
		"mostGuestCity": "Forest Hills",
		"mostGuestAmount": 184
	},
	{
		"id": "2e7ab85",
		"email": "maxconde@me.com",
		"name": "Max",
		"createdDate": "2020-02-29 20:50:30",
		"totalOrganizations": 1,
		"totalTours": 9,
		"totalDays": 154,
		"totalCountries": 4,
		"totalStates": 42,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1307,
		"schedulePercent": 0.568534,
		"totalGuests": 571,
		"guestPercent": 0.716991,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "The Pacific Amphitheatre",
		"mostGuestCity": "Costa Mesa",
		"mostGuestAmount": 42
	},
	{
		"id": "986e390",
		"email": "Wavforhim@gmail.com",
		"name": "Randall",
		"createdDate": "2018-06-27 05:42:07",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 409,
		"totalCountries": 2,
		"totalStates": 44,
		"totalEvents": 208,
		"eventPercent": 0.819891,
		"totalScheduleItems": 2438,
		"schedulePercent": 0.784929,
		"totalGuests": 330,
		"guestPercent": 0.618858,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Blackhawk Ministries",
		"mostGuestCity": "Fort Wayne",
		"mostGuestAmount": 21
	},
	{
		"id": "173af6d",
		"email": "intern@fullsteam.fi",
		"name": "Pinja ",
		"createdDate": "2023-06-16 16:16:02",
		"totalOrganizations": 93,
		"totalTours": 72,
		"totalDays": 632,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 630,
		"eventPercent": 0.983976,
		"totalScheduleItems": 2516,
		"schedulePercent": 0.795524,
		"totalGuests": 51,
		"guestPercent": 0.423785,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "On The Rocks",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 10
	},
	{
		"id": "66c2f39",
		"email": "a.luna820@gmail.com",
		"name": "Austin",
		"createdDate": "2015-08-18 23:26:10",
		"totalOrganizations": 12,
		"totalTours": 5,
		"totalDays": 460,
		"totalCountries": 12,
		"totalStates": 57,
		"totalEvents": 193,
		"eventPercent": 0.792743,
		"totalScheduleItems": 2923,
		"schedulePercent": 0.844789,
		"totalGuests": 1687,
		"guestPercent": 0.911402,
		"mostGuestDate": "2023-10-03 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 104
	},
	{
		"id": "02cdeb8",
		"email": "kristoffer.dezsi@allthingslive.se",
		"name": "Kristoffer",
		"createdDate": "2022-02-03 12:53:36",
		"totalOrganizations": 15,
		"totalTours": 12,
		"totalDays": 188,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 734,
		"schedulePercent": 0.39359,
		"totalGuests": 27,
		"guestPercent": 0.38379,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Stora Teatern",
		"mostGuestCity": "Gothenburg",
		"mostGuestAmount": 11
	},
	{
		"id": "c925337",
		"email": "jacksonseale@gmail.com",
		"name": "Jackson",
		"createdDate": "2017-10-16 23:08:57",
		"totalOrganizations": 17,
		"totalTours": 20,
		"totalDays": 827,
		"totalCountries": 3,
		"totalStates": 39,
		"totalEvents": 264,
		"eventPercent": 0.889419,
		"totalScheduleItems": 3316,
		"schedulePercent": 0.879354,
		"totalGuests": 723,
		"guestPercent": 0.758178,
		"mostGuestDate": "2023-04-22 00:00:00",
		"mostGuestName": "Great Southern Bank Arena",
		"mostGuestCity": "Springfield",
		"mostGuestAmount": 68
	},
	{
		"id": "3ad31d0",
		"email": "dane@runforcoverrecords.com",
		"name": "Dane",
		"createdDate": "2022-11-03 16:20:09",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 37,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 353,
		"schedulePercent": 0.234936,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a44e3c6",
		"email": "gregoirejoshua@gmail.com",
		"name": "Joshua",
		"createdDate": "2017-03-14 23:34:15",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 396,
		"totalCountries": 6,
		"totalStates": 19,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 371,
		"schedulePercent": 0.244074,
		"totalGuests": 146,
		"guestPercent": 0.510528,
		"mostGuestDate": "2023-02-04 00:00:00",
		"mostGuestName": "Exchange LA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 45
	},
	{
		"id": "5a68a72",
		"email": "tre.london@gmail.com",
		"name": "Tre",
		"createdDate": "2010-09-17 04:10:54",
		"totalOrganizations": 17,
		"totalTours": 19,
		"totalDays": 434,
		"totalCountries": 19,
		"totalStates": 75,
		"totalEvents": 207,
		"eventPercent": 0.817905,
		"totalScheduleItems": 2769,
		"schedulePercent": 0.828367,
		"totalGuests": 262,
		"guestPercent": 0.585221,
		"mostGuestDate": "2023-06-02 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 29
	},
	{
		"id": "3691dda",
		"email": "chris@1325.co.uk",
		"name": "Chris",
		"createdDate": "2020-01-08 12:59:23",
		"totalOrganizations": 12,
		"totalTours": 14,
		"totalDays": 1047,
		"totalCountries": 7,
		"totalStates": 33,
		"totalEvents": 491,
		"eventPercent": 0.972851,
		"totalScheduleItems": 6969,
		"schedulePercent": 0.989008,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Lincoln Financial Field",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 4
	},
	{
		"id": "c1e1a52",
		"email": "atrent@chandygroup.com",
		"name": "Anna",
		"createdDate": "2018-10-30 00:22:02",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 107,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 430,
		"schedulePercent": 0.268176,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "61985e6",
		"email": "cath@thewhitlams.com",
		"name": "Cath",
		"createdDate": "2020-02-26 23:46:25",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 350,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 323,
		"schedulePercent": 0.220501,
		"totalGuests": 208,
		"guestPercent": 0.55688,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "Factory Theatre",
		"mostGuestCity": "Marrickville",
		"mostGuestAmount": 63
	},
	{
		"id": "6095df0",
		"email": "boneloaf@gmail.com",
		"name": "Michael",
		"createdDate": "2021-08-19 14:07:59",
		"totalOrganizations": 7,
		"totalTours": 17,
		"totalDays": 697,
		"totalCountries": 7,
		"totalStates": 50,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 1650,
		"schedulePercent": 0.653423,
		"totalGuests": 285,
		"guestPercent": 0.596345,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "Fox Theater",
		"mostGuestCity": "Oakland",
		"mostGuestAmount": 51
	},
	{
		"id": "8491d51",
		"email": "carla@sternmgmt.com",
		"name": "Carla",
		"createdDate": "2022-08-03 19:39:40",
		"totalOrganizations": 7,
		"totalTours": 14,
		"totalDays": 299,
		"totalCountries": 15,
		"totalStates": 61,
		"totalEvents": 160,
		"eventPercent": 0.72838,
		"totalScheduleItems": 2014,
		"schedulePercent": 0.722024,
		"totalGuests": 348,
		"guestPercent": 0.628658,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 37
	},
	{
		"id": "08b7120",
		"email": "djjpaul@gmail.com",
		"name": "Jared",
		"createdDate": "2014-08-13 23:46:54",
		"totalOrganizations": 13,
		"totalTours": 5,
		"totalDays": 1173,
		"totalCountries": 15,
		"totalStates": 53,
		"totalEvents": 237,
		"eventPercent": 0.858429,
		"totalScheduleItems": 2832,
		"schedulePercent": 0.835783,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "The Queen Mary",
		"mostGuestCity": "Long Beach",
		"mostGuestAmount": 1
	},
	{
		"id": "2c77ceb",
		"email": "caio@mirror-am.com",
		"name": "Caio",
		"createdDate": "2023-02-23 14:44:47",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 53,
		"totalCountries": 9,
		"totalStates": 19,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 399,
		"schedulePercent": 0.25639,
		"totalGuests": 71,
		"guestPercent": 0.446431,
		"mostGuestDate": "2023-11-20 00:00:00",
		"mostGuestName": "Zeche",
		"mostGuestCity": "Bochum",
		"mostGuestAmount": 23
	},
	{
		"id": "1fc11bd",
		"email": "tom@outof.events",
		"name": "Tom",
		"createdDate": "2023-01-02 16:08:22",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 164,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 671,
		"schedulePercent": 0.36909,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "Schloss Eyrichshof",
		"mostGuestCity": "Ebern",
		"mostGuestAmount": 9
	},
	{
		"id": "774acee",
		"email": "znewton@gmail.com",
		"name": "Zachariah",
		"createdDate": "2011-05-27 00:55:08",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 144,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 903,
		"schedulePercent": 0.453317,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0f1efc9",
		"email": "jaymznis@gmail.com",
		"name": "James",
		"createdDate": "2015-06-22 17:26:30",
		"totalOrganizations": 16,
		"totalTours": 12,
		"totalDays": 962,
		"totalCountries": 21,
		"totalStates": 62,
		"totalEvents": 173,
		"eventPercent": 0.757516,
		"totalScheduleItems": 3498,
		"schedulePercent": 0.893921,
		"totalGuests": 1520,
		"guestPercent": 0.896702,
		"mostGuestDate": "2023-04-27 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 189
	},
	{
		"id": "0372251",
		"email": "cjmoytour@gmail.com",
		"name": "CJ ",
		"createdDate": "2023-04-12 15:23:48",
		"totalOrganizations": 19,
		"totalTours": 23,
		"totalDays": 732,
		"totalCountries": 12,
		"totalStates": 44,
		"totalEvents": 185,
		"eventPercent": 0.780029,
		"totalScheduleItems": 2529,
		"schedulePercent": 0.796318,
		"totalGuests": 1983,
		"guestPercent": 0.932592,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 300
	},
	{
		"id": "d1018db",
		"email": "elisenicole325@gmail.com",
		"name": "Elise",
		"createdDate": "2022-12-05 02:37:49",
		"totalOrganizations": 1,
		"totalTours": 8,
		"totalDays": 193,
		"totalCountries": 2,
		"totalStates": 33,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1135,
		"schedulePercent": 0.52205,
		"totalGuests": 621,
		"guestPercent": 0.731426,
		"mostGuestDate": "2023-06-19 00:00:00",
		"mostGuestName": "Moore Theatre",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 133
	},
	{
		"id": "37239bb",
		"email": "gavin@gloveontour.com",
		"name": "Gavin",
		"createdDate": "2015-02-10 19:01:25",
		"totalOrganizations": 9,
		"totalTours": 8,
		"totalDays": 630,
		"totalCountries": 23,
		"totalStates": 48,
		"totalEvents": 154,
		"eventPercent": 0.71368,
		"totalScheduleItems": 2410,
		"schedulePercent": 0.781353,
		"totalGuests": 992,
		"guestPercent": 0.821878,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 147
	},
	{
		"id": "d43d584",
		"email": "froggy@bryancross.com",
		"name": "Bryan",
		"createdDate": "2012-02-08 23:26:18",
		"totalOrganizations": 11,
		"totalTours": 4,
		"totalDays": 142,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 145,
		"schedulePercent": 0.116144,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "MGM Grand Garden Arena",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 6
	},
	{
		"id": "120bba0",
		"email": "fohpete@gmail.com",
		"name": "Pete",
		"createdDate": "2014-04-23 19:37:15",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 139,
		"totalCountries": 13,
		"totalStates": 54,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 1338,
		"schedulePercent": 0.57701,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c1868a8",
		"email": "michaelsimonarmstrong@gmail.com",
		"name": "Michael",
		"createdDate": "2019-04-28 18:22:49",
		"totalOrganizations": 7,
		"totalTours": 15,
		"totalDays": 210,
		"totalCountries": 6,
		"totalStates": 36,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 885,
		"schedulePercent": 0.44802,
		"totalGuests": 283,
		"guestPercent": 0.595153,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 54
	},
	{
		"id": "8a8792a",
		"email": "corbin.hand@gmail.com",
		"name": "Corbin",
		"createdDate": "2010-12-25 18:06:04",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 243,
		"totalCountries": 11,
		"totalStates": 38,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 1564,
		"schedulePercent": 0.633558,
		"totalGuests": 662,
		"guestPercent": 0.742683,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "The Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 130
	},
	{
		"id": "2f82f42",
		"email": "willjreading@gmail.com",
		"name": "Will",
		"createdDate": "2016-11-17 18:15:22",
		"totalOrganizations": 15,
		"totalTours": 10,
		"totalDays": 145,
		"totalCountries": 10,
		"totalStates": 26,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 635,
		"schedulePercent": 0.356112,
		"totalGuests": 104,
		"guestPercent": 0.478877,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "O2 Academy Leeds",
		"mostGuestCity": "Leeds",
		"mostGuestAmount": 93
	},
	{
		"id": "61d58c6",
		"email": "jermtemptours@gmail.com",
		"name": "Jeremy",
		"createdDate": "2023-10-04 17:35:47",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 45,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 157,
		"schedulePercent": 0.125281,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2c3d2e8",
		"email": "mike.platt.live@gmail.com",
		"name": "Michael",
		"createdDate": "2017-10-11 00:48:47",
		"totalOrganizations": 5,
		"totalTours": 18,
		"totalDays": 506,
		"totalCountries": 13,
		"totalStates": 39,
		"totalEvents": 162,
		"eventPercent": 0.732883,
		"totalScheduleItems": 2030,
		"schedulePercent": 0.724142,
		"totalGuests": 1142,
		"guestPercent": 0.847967,
		"mostGuestDate": "2023-08-06 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 126
	},
	{
		"id": "23edfec",
		"email": "jmberger20@gmail.com",
		"name": "Jack",
		"createdDate": "2022-05-31 17:34:54",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 529,
		"totalCountries": 3,
		"totalStates": 52,
		"totalEvents": 155,
		"eventPercent": 0.716197,
		"totalScheduleItems": 1095,
		"schedulePercent": 0.510661,
		"totalGuests": 77,
		"guestPercent": 0.452523,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Mickey's Black Box",
		"mostGuestCity": "Lititz",
		"mostGuestAmount": 13
	},
	{
		"id": "87b0721",
		"email": "liv@skyagency.no",
		"name": "liv",
		"createdDate": "2019-03-29 13:33:11",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 86,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 392,
		"schedulePercent": 0.254139,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3e0dfd4",
		"email": "rob@rjkproductions.com",
		"name": "Rob",
		"createdDate": "2013-07-08 21:55:29",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 29,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 193,
		"schedulePercent": 0.145941,
		"totalGuests": 23,
		"guestPercent": 0.37452,
		"mostGuestDate": "2023-05-10 00:00:00",
		"mostGuestName": "Winspear Opera House",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 11
	},
	{
		"id": "b5f5c25",
		"email": "terry@bassetaudio.com",
		"name": "Terry",
		"createdDate": "2016-02-22 06:54:06",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 158,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 603,
		"schedulePercent": 0.343001,
		"totalGuests": 185,
		"guestPercent": 0.539664,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "Marcus Center for the Performing Arts",
		"mostGuestCity": "Milwaukee",
		"mostGuestAmount": 23
	},
	{
		"id": "5b64eaf",
		"email": "maartenflip@gmail.com",
		"name": "Maarten",
		"createdDate": "2018-12-07 21:09:45",
		"totalOrganizations": 15,
		"totalTours": 28,
		"totalDays": 904,
		"totalCountries": 16,
		"totalStates": 59,
		"totalEvents": 349,
		"eventPercent": 0.940803,
		"totalScheduleItems": 2774,
		"schedulePercent": 0.828897,
		"totalGuests": 261,
		"guestPercent": 0.584823,
		"mostGuestDate": "2023-02-06 00:00:00",
		"mostGuestName": "MOTH Club",
		"mostGuestCity": "London",
		"mostGuestAmount": 29
	},
	{
		"id": "299681c",
		"email": "acm1013@gmail.com",
		"name": "Alex",
		"createdDate": "2022-08-23 20:15:56",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 108,
		"totalCountries": 9,
		"totalStates": 20,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 594,
		"schedulePercent": 0.337571,
		"totalGuests": 305,
		"guestPercent": 0.606277,
		"mostGuestDate": "2023-07-18 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 65
	},
	{
		"id": "e22708c",
		"email": "lisa@lisamcclowry.com",
		"name": "Lisa",
		"createdDate": "2023-02-15 23:39:13",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 441,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 240,
		"schedulePercent": 0.175738,
		"totalGuests": 13,
		"guestPercent": 0.352536,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Arcada Theatre",
		"mostGuestCity": "St. Charles",
		"mostGuestAmount": 8
	},
	{
		"id": "d3c4ec2",
		"email": "soto.arianna@gmail.com",
		"name": "Arianna",
		"createdDate": "2019-08-24 02:27:57",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 140,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 862,
		"schedulePercent": 0.440604,
		"totalGuests": 466,
		"guestPercent": 0.680175,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "The Regent Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 47
	},
	{
		"id": "8efbfb8",
		"email": "john@davisartistmanagement.com",
		"name": "John",
		"createdDate": "2011-09-28 18:46:43",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 322,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 324,
		"schedulePercent": 0.221428,
		"totalGuests": 336,
		"guestPercent": 0.620977,
		"mostGuestDate": "2023-09-29 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 70
	},
	{
		"id": "de80ac3",
		"email": "Kiley@bigloud.com",
		"name": "Kiley",
		"createdDate": "2021-10-18 17:37:28",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 1046,
		"totalCountries": 7,
		"totalStates": 44,
		"totalEvents": 333,
		"eventPercent": 0.934181,
		"totalScheduleItems": 5086,
		"schedulePercent": 0.964376,
		"totalGuests": 5157,
		"guestPercent": 0.992981,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 240
	},
	{
		"id": "0e3053a",
		"email": "quinnanthonymusic@gmail.com",
		"name": "Quinn",
		"createdDate": "2019-06-06 03:22:45",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 63,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 458,
		"schedulePercent": 0.27983,
		"totalGuests": 70,
		"guestPercent": 0.445636,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The Aztec Theatre",
		"mostGuestCity": "San Antonio",
		"mostGuestAmount": 70
	},
	{
		"id": "4454458",
		"email": "Soulstarmgmt@gmail.com",
		"name": "Luvanmusiq ",
		"createdDate": "2016-04-18 17:34:40",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 89,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1069,
		"schedulePercent": 0.504701,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3af3b68",
		"email": "melanie@songsanddaughters.com",
		"name": "Melanie",
		"createdDate": "2022-09-13 16:43:43",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 861,
		"totalCountries": 2,
		"totalStates": 40,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 1294,
		"schedulePercent": 0.564826,
		"totalGuests": 264,
		"guestPercent": 0.58628,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "Exit/In",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 43
	},
	{
		"id": "138c529",
		"email": "chris@grangersmith.com",
		"name": "Chris",
		"createdDate": "2013-08-13 04:46:30",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 27,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 666,
		"schedulePercent": 0.367633,
		"totalGuests": 235,
		"guestPercent": 0.57052,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Billy Bob's",
		"mostGuestCity": "Ft. Worth",
		"mostGuestAmount": 34
	},
	{
		"id": "91794f8",
		"email": "gloris737@icloud.com",
		"name": "GLORIA",
		"createdDate": "2023-01-23 02:38:35",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 161,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 142,
		"schedulePercent": 0.114422,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "dc4d646",
		"email": "dctourmanager@gmail.com",
		"name": "Will",
		"createdDate": "2023-04-05 18:48:32",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 269,
		"totalCountries": 1,
		"totalStates": 33,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 729,
		"schedulePercent": 0.391869,
		"totalGuests": 89,
		"guestPercent": 0.462323,
		"mostGuestDate": "2023-06-29 00:00:00",
		"mostGuestName": "Riverfront Live ",
		"mostGuestCity": "Cincinnati ",
		"mostGuestAmount": 13
	},
	{
		"id": "a7d144e",
		"email": "risa@pr1comm.com",
		"name": "Risa",
		"createdDate": "2015-09-22 22:14:35",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 214,
		"totalCountries": 3,
		"totalStates": 28,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 2718,
		"schedulePercent": 0.82201,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8625171",
		"email": "schoepfjoel@gmail.com",
		"name": "Joel",
		"createdDate": "2022-06-15 20:45:32",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 102,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 539,
		"schedulePercent": 0.317309,
		"totalGuests": 200,
		"guestPercent": 0.550258,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 48
	},
	{
		"id": "97df61c",
		"email": "sonictours89@gmail.com",
		"name": "Tour",
		"createdDate": "2023-09-13 16:30:21",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 33,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 249,
		"schedulePercent": 0.181168,
		"totalGuests": 28,
		"guestPercent": 0.386306,
		"mostGuestDate": "2023-09-21 00:00:00",
		"mostGuestName": "The Rave Hall",
		"mostGuestCity": "Milwaukee",
		"mostGuestAmount": 24
	},
	{
		"id": "0a76a3b",
		"email": "casper47@mac.com",
		"name": "Mark",
		"createdDate": "2010-08-12 03:53:26",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 1003,
		"totalCountries": 10,
		"totalStates": 57,
		"totalEvents": 229,
		"eventPercent": 0.847305,
		"totalScheduleItems": 3873,
		"schedulePercent": 0.916567,
		"totalGuests": 401,
		"guestPercent": 0.654218,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "Thompson Boling Arena",
		"mostGuestCity": "Knoxville",
		"mostGuestAmount": 47
	},
	{
		"id": "2ee2fa4",
		"email": "kamalsoliman@icloud.com",
		"name": "Kamal",
		"createdDate": "2016-04-14 15:42:18",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 585,
		"totalCountries": 7,
		"totalStates": 39,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 1295,
		"schedulePercent": 0.565356,
		"totalGuests": 1212,
		"guestPercent": 0.859224,
		"mostGuestDate": "2023-09-09 00:00:00",
		"mostGuestName": "The Midway",
		"mostGuestCity": "San Francisco",
		"mostGuestAmount": 87
	},
	{
		"id": "b81870d",
		"email": "janice@damnably.com",
		"name": "Janice",
		"createdDate": "2022-09-23 19:35:58",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 38,
		"totalCountries": 4,
		"totalStates": 13,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 189,
		"schedulePercent": 0.144219,
		"totalGuests": 32,
		"guestPercent": 0.392531,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "The Empty Bottle",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 8
	},
	{
		"id": "600406b",
		"email": "lexi@paquinentertainment.com",
		"name": "Lexi",
		"createdDate": "2017-08-24 04:16:36",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 91,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 256,
		"schedulePercent": 0.183684,
		"totalGuests": 54,
		"guestPercent": 0.427096,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "The Grove Theatre",
		"mostGuestCity": "Fenelon Falls",
		"mostGuestAmount": 15
	},
	{
		"id": "6933051",
		"email": "rachel@heartstopmusic.com",
		"name": "Rachel",
		"createdDate": "2022-03-10 15:07:43",
		"totalOrganizations": 12,
		"totalTours": 13,
		"totalDays": 249,
		"totalCountries": 7,
		"totalStates": 44,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1009,
		"schedulePercent": 0.487882,
		"totalGuests": 704,
		"guestPercent": 0.754072,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "Christchurch Town Hall of the Performing Arts",
		"mostGuestCity": "Christchurch",
		"mostGuestAmount": 93
	},
	{
		"id": "c983af8",
		"email": "furioussoundprod@hotmail.com",
		"name": "Jeff",
		"createdDate": "2018-06-12 17:33:46",
		"totalOrganizations": 10,
		"totalTours": 19,
		"totalDays": 1741,
		"totalCountries": 8,
		"totalStates": 40,
		"totalEvents": 253,
		"eventPercent": 0.877765,
		"totalScheduleItems": 2237,
		"schedulePercent": 0.755794,
		"totalGuests": 727,
		"guestPercent": 0.759105,
		"mostGuestDate": "2023-11-22 00:00:00",
		"mostGuestName": "Town Ballroom",
		"mostGuestCity": "Buffalo",
		"mostGuestAmount": 32
	},
	{
		"id": "52cc232",
		"email": "alex@micotoronto.com",
		"name": "Alexander",
		"createdDate": "2023-09-05 14:17:55",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 83,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 233,
		"schedulePercent": 0.171368,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8585831",
		"email": "wamtourmanager@gmail.com",
		"name": "WAM",
		"createdDate": "2022-04-05 15:10:02",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 141,
		"totalCountries": 4,
		"totalStates": 32,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 1975,
		"schedulePercent": 0.715402,
		"totalGuests": 542,
		"guestPercent": 0.706794,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Viejas Arena",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 166
	},
	{
		"id": "08ffd76",
		"email": "huhmanj@gmail.com",
		"name": "Jeff",
		"createdDate": "2016-10-07 18:27:18",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 76,
		"totalCountries": 1,
		"totalStates": 24,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 348,
		"schedulePercent": 0.232155,
		"totalGuests": 465,
		"guestPercent": 0.678718,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Showbox SoDo",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 55
	},
	{
		"id": "ebe5a60",
		"email": "cole@johnstoneent.com",
		"name": "Cole",
		"createdDate": "2015-06-21 07:44:14",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 25,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 623,
		"schedulePercent": 0.350682,
		"totalGuests": 447,
		"guestPercent": 0.672096,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Billy Bob's Texas",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 46
	},
	{
		"id": "3c33519",
		"email": "andrewfrost04@gmail.com",
		"name": "Andrew",
		"createdDate": "2015-07-08 19:42:24",
		"totalOrganizations": 12,
		"totalTours": 23,
		"totalDays": 1233,
		"totalCountries": 9,
		"totalStates": 57,
		"totalEvents": 320,
		"eventPercent": 0.925705,
		"totalScheduleItems": 4096,
		"schedulePercent": 0.927294,
		"totalGuests": 1934,
		"guestPercent": 0.930605,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Forest Hills Stadium",
		"mostGuestCity": "Forest Hills",
		"mostGuestAmount": 191
	},
	{
		"id": "1a1d949",
		"email": "curtiswbaldwin@gmail.com",
		"name": "Curtis",
		"createdDate": "2017-04-17 22:11:08",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 221,
		"totalCountries": 8,
		"totalStates": 42,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1481,
		"schedulePercent": 0.609456,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "Vivint Smart Home Arena",
		"mostGuestCity": "Salt Lake City",
		"mostGuestAmount": 15
	},
	{
		"id": "3e77465",
		"email": "phil@marfleetevents.com",
		"name": "Philip",
		"createdDate": "2018-09-05 13:18:43",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 130,
		"totalCountries": 12,
		"totalStates": 35,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 1299,
		"schedulePercent": 0.566018,
		"totalGuests": 350,
		"guestPercent": 0.629718,
		"mostGuestDate": "2023-03-10 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 82
	},
	{
		"id": "9a87f94",
		"email": "tomallentm@gmail.com",
		"name": "Thomas",
		"createdDate": "2013-09-12 14:04:10",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 180,
		"totalCountries": 19,
		"totalStates": 55,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1170,
		"schedulePercent": 0.531983,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d403fa0",
		"email": "robertrudd@mac.com",
		"name": "Robert",
		"createdDate": "2010-02-09 20:36:39",
		"totalOrganizations": 20,
		"totalTours": 16,
		"totalDays": 1472,
		"totalCountries": 4,
		"totalStates": 61,
		"totalEvents": 411,
		"eventPercent": 0.960138,
		"totalScheduleItems": 5711,
		"schedulePercent": 0.976559,
		"totalGuests": 1679,
		"guestPercent": 0.910608,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Pine Knob Music Theatre",
		"mostGuestCity": "City of the Village of Clarkston",
		"mostGuestAmount": 62
	},
	{
		"id": "4ebb046",
		"email": "liam@roundonesportsandentertainment.com",
		"name": "Liam",
		"createdDate": "2018-10-22 21:40:35",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 45,
		"totalCountries": 3,
		"totalStates": 12,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 530,
		"schedulePercent": 0.312939,
		"totalGuests": 646,
		"guestPercent": 0.737916,
		"mostGuestDate": "2023-11-05 00:00:00",
		"mostGuestName": "The Concert Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 112
	},
	{
		"id": "45d331f",
		"email": "mtbeventservices@gmail.com",
		"name": "Mathew",
		"createdDate": "2018-09-05 17:11:23",
		"totalOrganizations": 8,
		"totalTours": 11,
		"totalDays": 1072,
		"totalCountries": 16,
		"totalStates": 65,
		"totalEvents": 207,
		"eventPercent": 0.817905,
		"totalScheduleItems": 2489,
		"schedulePercent": 0.792081,
		"totalGuests": 396,
		"guestPercent": 0.652232,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 47
	},
	{
		"id": "c8849fd",
		"email": "chris@kbfmgmt.com",
		"name": "Chris ",
		"createdDate": "2019-07-30 15:34:38",
		"totalOrganizations": 7,
		"totalTours": 19,
		"totalDays": 2105,
		"totalCountries": 12,
		"totalStates": 71,
		"totalEvents": 547,
		"eventPercent": 0.979208,
		"totalScheduleItems": 5425,
		"schedulePercent": 0.972189,
		"totalGuests": 1540,
		"guestPercent": 0.898556,
		"mostGuestDate": "2023-11-21 00:00:00",
		"mostGuestName": "Wildhorse Saloon",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 283
	},
	{
		"id": "b1358eb",
		"email": "kamcheatham@gmail.com",
		"name": "Kam",
		"createdDate": "2017-08-17 17:02:27",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 955,
		"totalCountries": 3,
		"totalStates": 52,
		"totalEvents": 177,
		"eventPercent": 0.765594,
		"totalScheduleItems": 5029,
		"schedulePercent": 0.962654,
		"totalGuests": 6080,
		"guestPercent": 0.9951,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 240
	},
	{
		"id": "3c8dea6",
		"email": "ben@on-tour.org",
		"name": "Ben",
		"createdDate": "2010-03-18 16:27:23",
		"totalOrganizations": 18,
		"totalTours": 21,
		"totalDays": 514,
		"totalCountries": 12,
		"totalStates": 45,
		"totalEvents": 208,
		"eventPercent": 0.819891,
		"totalScheduleItems": 3291,
		"schedulePercent": 0.877102,
		"totalGuests": 1638,
		"guestPercent": 0.906502,
		"mostGuestDate": "2023-05-17 00:00:00",
		"mostGuestName": "Royal Albert Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 155
	},
	{
		"id": "3630be9",
		"email": "scott_mclaughlin@hotmail.com",
		"name": "Scott",
		"createdDate": "2010-03-03 23:05:34",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 764,
		"totalCountries": 7,
		"totalStates": 53,
		"totalEvents": 253,
		"eventPercent": 0.877765,
		"totalScheduleItems": 3089,
		"schedulePercent": 0.860283,
		"totalGuests": 1938,
		"guestPercent": 0.931002,
		"mostGuestDate": "2023-04-21 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 85
	},
	{
		"id": "027a0d3",
		"email": "alicia.black@me.com",
		"name": "Alicia",
		"createdDate": "2016-03-22 18:09:05",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 306,
		"totalCountries": 15,
		"totalStates": 65,
		"totalEvents": 166,
		"eventPercent": 0.74308,
		"totalScheduleItems": 1951,
		"schedulePercent": 0.712091,
		"totalGuests": 163,
		"guestPercent": 0.525493,
		"mostGuestDate": "2023-01-17 00:00:00",
		"mostGuestName": "Keller Auditorium",
		"mostGuestCity": "Portland",
		"mostGuestAmount": 44
	},
	{
		"id": "76c8aa2",
		"email": "sharon@steeltoemgmt.com",
		"name": "Sharon",
		"createdDate": "2017-02-16 03:11:38",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 67,
		"totalCountries": 1,
		"totalStates": 27,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 381,
		"schedulePercent": 0.249371,
		"totalGuests": 178,
		"guestPercent": 0.534896,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Delmar Hall",
		"mostGuestCity": "St. Louis",
		"mostGuestAmount": 21
	},
	{
		"id": "d618132",
		"email": "Justintrotta@gmail.com",
		"name": "Justin",
		"createdDate": "2016-05-19 21:24:19",
		"totalOrganizations": 14,
		"totalTours": 11,
		"totalDays": 905,
		"totalCountries": 10,
		"totalStates": 35,
		"totalEvents": 178,
		"eventPercent": 0.767051,
		"totalScheduleItems": 2708,
		"schedulePercent": 0.820156,
		"totalGuests": 330,
		"guestPercent": 0.618858,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 112
	},
	{
		"id": "6c1e6b7",
		"email": "john@thedohertycompany.com",
		"name": "John ",
		"createdDate": "2023-09-28 22:08:07",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 64,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 256,
		"schedulePercent": 0.183684,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bdb492e",
		"email": "info@megan-hood.com",
		"name": "Meg",
		"createdDate": "2020-03-02 22:46:13",
		"totalOrganizations": 6,
		"totalTours": 25,
		"totalDays": 330,
		"totalCountries": 13,
		"totalStates": 28,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 2133,
		"schedulePercent": 0.73977,
		"totalGuests": 1192,
		"guestPercent": 0.85631,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Accor Arena",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 138
	},
	{
		"id": "95835e1",
		"email": "sam@optimysticendeavors.com",
		"name": "Sam",
		"createdDate": "2023-10-02 22:42:25",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 142,
		"totalCountries": 2,
		"totalStates": 31,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 308,
		"schedulePercent": 0.212157,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "The Lincoln",
		"mostGuestCity": "Cheyenne",
		"mostGuestAmount": 3
	},
	{
		"id": "8e2ec75",
		"email": "tylerlessardproductions@gmail.com",
		"name": "Stephen",
		"createdDate": "2022-10-17 16:46:23",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 378,
		"totalCountries": 1,
		"totalStates": 35,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 838,
		"schedulePercent": 0.433717,
		"totalGuests": 124,
		"guestPercent": 0.493445,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Gruene Hall",
		"mostGuestCity": "New Braunfels",
		"mostGuestAmount": 22
	},
	{
		"id": "5457ed5",
		"email": "wojtek@malkotourservice.pl",
		"name": "Wojciech",
		"createdDate": "2016-01-16 23:48:48",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 654,
		"totalCountries": 17,
		"totalStates": 76,
		"totalEvents": 261,
		"eventPercent": 0.886902,
		"totalScheduleItems": 3085,
		"schedulePercent": 0.859489,
		"totalGuests": 1082,
		"guestPercent": 0.83671,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 57
	},
	{
		"id": "d082b65",
		"email": "graham@23av.com",
		"name": "Graham",
		"createdDate": "2019-12-27 04:02:13",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 565,
		"totalCountries": 3,
		"totalStates": 43,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 2269,
		"schedulePercent": 0.761753,
		"totalGuests": 112,
		"guestPercent": 0.484836,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "Tennessee Performing Arts Center- Andrew Jackson Hall",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 29
	},
	{
		"id": "9994a98",
		"email": "nikki@midnightartists.com",
		"name": "Nicole",
		"createdDate": "2018-04-04 23:48:51",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 294,
		"totalCountries": 4,
		"totalStates": 37,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1639,
		"schedulePercent": 0.651172,
		"totalGuests": 44,
		"guestPercent": 0.411469,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Friends Field North",
		"mostGuestCity": "Mill Valley",
		"mostGuestAmount": 18
	},
	{
		"id": "8e748d4",
		"email": "Minnebo.d@gmail.com",
		"name": "Dylan",
		"createdDate": "2022-11-09 00:40:08",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 23,
		"totalCountries": 7,
		"totalStates": 10,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 150,
		"schedulePercent": 0.120117,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0571b75",
		"email": "aitorgoomezz@gmail.com",
		"name": "Aitor",
		"createdDate": "2021-08-15 17:11:13",
		"totalOrganizations": 10,
		"totalTours": 7,
		"totalDays": 158,
		"totalCountries": 5,
		"totalStates": 28,
		"totalEvents": 156,
		"eventPercent": 0.718051,
		"totalScheduleItems": 682,
		"schedulePercent": 0.373725,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ddc5322",
		"email": "production@joyriderecs.com",
		"name": "Simon",
		"createdDate": "2018-07-04 15:07:45",
		"totalOrganizations": 27,
		"totalTours": 13,
		"totalDays": 301,
		"totalCountries": 4,
		"totalStates": 19,
		"totalEvents": 210,
		"eventPercent": 0.823732,
		"totalScheduleItems": 811,
		"schedulePercent": 0.42352,
		"totalGuests": 17,
		"guestPercent": 0.362469,
		"mostGuestDate": "2023-04-07 00:00:00",
		"mostGuestName": "theatre Alphonse desjardins ",
		"mostGuestCity": "Repentigny",
		"mostGuestAmount": 6
	},
	{
		"id": "2fe12b4",
		"email": "jasonbonham@me.com",
		"name": "Jason ",
		"createdDate": "2017-05-09 01:32:21",
		"totalOrganizations": 6,
		"totalTours": 13,
		"totalDays": 386,
		"totalCountries": 5,
		"totalStates": 37,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 1487,
		"schedulePercent": 0.611045,
		"totalGuests": 1193,
		"guestPercent": 0.856443,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "Hard Rock Live",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 88
	},
	{
		"id": "86a52d8",
		"email": "megan.letts@redlightmanagement.com",
		"name": "Megan",
		"createdDate": "2020-02-10 18:43:45",
		"totalOrganizations": 14,
		"totalTours": 19,
		"totalDays": 1144,
		"totalCountries": 5,
		"totalStates": 44,
		"totalEvents": 266,
		"eventPercent": 0.891935,
		"totalScheduleItems": 2424,
		"schedulePercent": 0.783075,
		"totalGuests": 173,
		"guestPercent": 0.53185,
		"mostGuestDate": "2023-09-26 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 21
	},
	{
		"id": "855af50",
		"email": "toby@gigcontrol.com.au",
		"name": "Toby",
		"createdDate": "2023-11-12 22:57:10",
		"totalOrganizations": 10,
		"totalTours": 11,
		"totalDays": 176,
		"totalCountries": 5,
		"totalStates": 15,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 619,
		"schedulePercent": 0.349755,
		"totalGuests": 128,
		"guestPercent": 0.49755,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 58
	},
	{
		"id": "58d6f1e",
		"email": "livesupportoz@gmail.com",
		"name": "John",
		"createdDate": "2019-01-23 05:08:23",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 532,
		"totalCountries": 3,
		"totalStates": 15,
		"totalEvents": 311,
		"eventPercent": 0.921335,
		"totalScheduleItems": 1627,
		"schedulePercent": 0.648259,
		"totalGuests": 28,
		"guestPercent": 0.386306,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Nautilus Arts Centre",
		"mostGuestCity": "Port Lincoln",
		"mostGuestAmount": 15
	},
	{
		"id": "ec72097",
		"email": "Jackson.ammons@wmg.com",
		"name": "Jackson",
		"createdDate": "2022-09-29 20:54:47",
		"totalOrganizations": 50,
		"totalTours": 48,
		"totalDays": 15291,
		"totalCountries": 16,
		"totalStates": 88,
		"totalEvents": 2096,
		"eventPercent": 0.997749,
		"totalScheduleItems": 2208,
		"schedulePercent": 0.751291,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 13
	},
	{
		"id": "25c48ad",
		"email": "ms@loftent.com",
		"name": "Michelle",
		"createdDate": "2023-07-06 12:58:58",
		"totalOrganizations": 22,
		"totalTours": 6,
		"totalDays": 97,
		"totalCountries": 3,
		"totalStates": 11,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 290,
		"schedulePercent": 0.203152,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5e52da7",
		"email": "riina.ries@gmail.com",
		"name": "Riina",
		"createdDate": "2022-10-17 20:42:31",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 49,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 273,
		"schedulePercent": 0.194676,
		"totalGuests": 88,
		"guestPercent": 0.461661,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Baths of Caracalla",
		"mostGuestCity": "Roma",
		"mostGuestAmount": 82
	},
	{
		"id": "1e5f8b6",
		"email": "ryangillaspie86@gmail.com",
		"name": "Ryan",
		"createdDate": "2017-11-23 22:08:24",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 490,
		"totalCountries": 15,
		"totalStates": 60,
		"totalEvents": 177,
		"eventPercent": 0.765594,
		"totalScheduleItems": 2508,
		"schedulePercent": 0.794332,
		"totalGuests": 947,
		"guestPercent": 0.811813,
		"mostGuestDate": "2023-10-08 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 116
	},
	{
		"id": "c0c0dcf",
		"email": "mookie@tapmgmt.com",
		"name": "Mookie",
		"createdDate": "2021-03-26 15:00:33",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 486,
		"totalCountries": 16,
		"totalStates": 39,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 2331,
		"schedulePercent": 0.771156,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "abb373b",
		"email": "czakoor@hotmail.com",
		"name": "Chris ",
		"createdDate": "2016-03-02 23:26:58",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 354,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 1553,
		"schedulePercent": 0.630645,
		"totalGuests": 295,
		"guestPercent": 0.599788,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "Hard Rock Live at Etess Arena",
		"mostGuestCity": "Atlantic City",
		"mostGuestAmount": 34
	},
	{
		"id": "024f9d2",
		"email": "info@agentur-zwei-punkt-null.de",
		"name": "Carsten",
		"createdDate": "2023-02-01 20:54:13",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 970,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 153,
		"schedulePercent": 0.122765,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3958456",
		"email": "jason@313llc.com",
		"name": "Jason",
		"createdDate": "2014-02-07 17:10:44",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 128,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 488,
		"schedulePercent": 0.292014,
		"totalGuests": 203,
		"guestPercent": 0.553172,
		"mostGuestDate": "2023-09-14 00:00:00",
		"mostGuestName": "Emo's",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 41
	},
	{
		"id": "74aa99d",
		"email": "chrisdiener@gmail.com",
		"name": "Chris",
		"createdDate": "2011-06-27 06:12:30",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 797,
		"totalCountries": 8,
		"totalStates": 55,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 2710,
		"schedulePercent": 0.820686,
		"totalGuests": 26,
		"guestPercent": 0.381274,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Music Farm",
		"mostGuestCity": "Charleston",
		"mostGuestAmount": 7
	},
	{
		"id": "a00529a",
		"email": "imcaramelli.sm@gmail.com",
		"name": "Indy",
		"createdDate": "2023-08-27 20:38:03",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 47,
		"totalCountries": 2,
		"totalStates": 35,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 7,
		"schedulePercent": 0.0181433,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-09-13 00:00:00",
		"mostGuestName": "Saban Theatre",
		"mostGuestCity": "Beverly Hills",
		"mostGuestAmount": 5
	},
	{
		"id": "ef39b62",
		"email": "jam.has.sound@gmail.com",
		"name": "James",
		"createdDate": "2016-07-15 20:46:29",
		"totalOrganizations": 6,
		"totalTours": 11,
		"totalDays": 620,
		"totalCountries": 5,
		"totalStates": 44,
		"totalEvents": 178,
		"eventPercent": 0.767051,
		"totalScheduleItems": 1847,
		"schedulePercent": 0.694742,
		"totalGuests": 676,
		"guestPercent": 0.746259,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 64
	},
	{
		"id": "0a14498",
		"email": "simon.cook@blakdance.org.au",
		"name": "Simon",
		"createdDate": "2022-07-19 01:37:27",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 183,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 738,
		"schedulePercent": 0.395974,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "43ea03a",
		"email": "Jameson.h.willey@gmail.com",
		"name": "Jameson ",
		"createdDate": "2015-01-21 18:27:33",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 550,
		"totalCountries": 2,
		"totalStates": 36,
		"totalEvents": 408,
		"eventPercent": 0.959078,
		"totalScheduleItems": 3457,
		"schedulePercent": 0.890346,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d25e071",
		"email": "harryjpickett@me.com",
		"name": "Harry",
		"createdDate": "2023-02-08 10:38:16",
		"totalOrganizations": 9,
		"totalTours": 9,
		"totalDays": 450,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 182,
		"eventPercent": 0.773408,
		"totalScheduleItems": 1199,
		"schedulePercent": 0.540193,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "54d3c0f",
		"email": "charlesgodwinmusic@gmail.com",
		"name": "Charles",
		"createdDate": "2021-10-22 16:04:54",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 39,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1013,
		"schedulePercent": 0.488942,
		"totalGuests": 557,
		"guestPercent": 0.712753,
		"mostGuestDate": "2023-12-07 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 39
	},
	{
		"id": "43174b1",
		"email": "lex.buddy101@gmail.com",
		"name": "Nicholas",
		"createdDate": "2023-02-27 17:07:12",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 514,
		"totalCountries": 2,
		"totalStates": 39,
		"totalEvents": 172,
		"eventPercent": 0.755661,
		"totalScheduleItems": 1291,
		"schedulePercent": 0.563899,
		"totalGuests": 317,
		"guestPercent": 0.612502,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 60
	},
	{
		"id": "11f8d90",
		"email": "dowen76@earthlink.net",
		"name": "Daniel",
		"createdDate": "2009-12-02 23:17:03",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 336,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 822,
		"schedulePercent": 0.427361,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "83a4966",
		"email": "warren@roadmgmt.com",
		"name": "Warren",
		"createdDate": "2009-10-07 19:38:59",
		"totalOrganizations": 13,
		"totalTours": 12,
		"totalDays": 592,
		"totalCountries": 12,
		"totalStates": 54,
		"totalEvents": 207,
		"eventPercent": 0.817905,
		"totalScheduleItems": 3177,
		"schedulePercent": 0.868097,
		"totalGuests": 2715,
		"guestPercent": 0.961462,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Hard Rock Live",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 221
	},
	{
		"id": "5f2728d",
		"email": "kevin@travtours.com",
		"name": "Kevin",
		"createdDate": "2015-06-16 17:51:17",
		"totalOrganizations": 15,
		"totalTours": 11,
		"totalDays": 197,
		"totalCountries": 9,
		"totalStates": 23,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1775,
		"schedulePercent": 0.679778,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bbb09db",
		"email": "miiro.varjus@wavescape.fi",
		"name": "Miiro",
		"createdDate": "2019-08-08 15:01:25",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 613,
		"totalCountries": 19,
		"totalStates": 51,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 1751,
		"schedulePercent": 0.674745,
		"totalGuests": 917,
		"guestPercent": 0.803337,
		"mostGuestDate": "2023-05-12 00:00:00",
		"mostGuestName": "Pepsi Center WTC",
		"mostGuestCity": "Mexico City",
		"mostGuestAmount": 60
	},
	{
		"id": "a7e4210",
		"email": "demunjones@yahoo.com",
		"name": "Demun",
		"createdDate": "2014-10-09 23:13:25",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 132,
		"totalCountries": 1,
		"totalStates": 26,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 248,
		"schedulePercent": 0.180771,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ec6baa0",
		"email": "shelby.shariat@lobbycall.com",
		"name": "Shelby",
		"createdDate": "2022-07-19 16:35:44",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 73,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 146,
		"schedulePercent": 0.117071,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-01-02 00:00:00",
		"mostGuestName": "Rosa Negra Beach",
		"mostGuestCity": "TULUM",
		"mostGuestAmount": 9
	},
	{
		"id": "a8befad",
		"email": "natalieblass@gmail.com",
		"name": "natalie",
		"createdDate": "2022-08-25 13:49:37",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 579,
		"totalCountries": 16,
		"totalStates": 39,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 2719,
		"schedulePercent": 0.822143,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "72e8da1",
		"email": "mr.zane.landreth@gmail.com",
		"name": "Zane",
		"createdDate": "2017-08-10 19:19:23",
		"totalOrganizations": 12,
		"totalTours": 23,
		"totalDays": 305,
		"totalCountries": 16,
		"totalStates": 62,
		"totalEvents": 172,
		"eventPercent": 0.755661,
		"totalScheduleItems": 1319,
		"schedulePercent": 0.570785,
		"totalGuests": 272,
		"guestPercent": 0.590385,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 50
	},
	{
		"id": "9c60bc3",
		"email": "mbavec1@gmail.com",
		"name": "Michael",
		"createdDate": "2017-10-11 16:35:09",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 119,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 721,
		"schedulePercent": 0.38869,
		"totalGuests": 66,
		"guestPercent": 0.441001,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 35
	},
	{
		"id": "75d3188",
		"email": "Kev.vidou@gmail.com",
		"name": "Kevin",
		"createdDate": "2022-05-18 02:14:26",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 247,
		"totalCountries": 4,
		"totalStates": 29,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 1325,
		"schedulePercent": 0.573037,
		"totalGuests": 555,
		"guestPercent": 0.711296,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "del Lago Resort & Casino",
		"mostGuestCity": "Waterloo",
		"mostGuestAmount": 39
	},
	{
		"id": "74a107f",
		"email": "daniela@agoraaq.it",
		"name": "Daniela",
		"createdDate": "2019-11-06 11:53:09",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 91,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 4,
		"schedulePercent": 0.0139054,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "52b664c",
		"email": "pshtm@me.com",
		"name": "Peter",
		"createdDate": "2009-09-14 22:12:17",
		"totalOrganizations": 8,
		"totalTours": 22,
		"totalDays": 697,
		"totalCountries": 18,
		"totalStates": 50,
		"totalEvents": 205,
		"eventPercent": 0.814859,
		"totalScheduleItems": 2873,
		"schedulePercent": 0.839889,
		"totalGuests": 115,
		"guestPercent": 0.48722,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 18
	},
	{
		"id": "6f439f8",
		"email": "adam0926@me.com",
		"name": "Adam ",
		"createdDate": "2013-11-13 20:24:20",
		"totalOrganizations": 11,
		"totalTours": 12,
		"totalDays": 1455,
		"totalCountries": 6,
		"totalStates": 57,
		"totalEvents": 317,
		"eventPercent": 0.924381,
		"totalScheduleItems": 4412,
		"schedulePercent": 0.941597,
		"totalGuests": 3603,
		"guestPercent": 0.979076,
		"mostGuestDate": "2023-11-15 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 142
	},
	{
		"id": "9b8b512",
		"email": "michael@emagen.com",
		"name": "Michael",
		"createdDate": "2021-09-27 17:20:48",
		"totalOrganizations": 10,
		"totalTours": 10,
		"totalDays": 402,
		"totalCountries": 15,
		"totalStates": 42,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 808,
		"schedulePercent": 0.422593,
		"totalGuests": 717,
		"guestPercent": 0.757251,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 64
	},
	{
		"id": "059a20b",
		"email": "julian.michael.weller@gmail.com",
		"name": "Julian",
		"createdDate": "2017-09-15 18:28:12",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 870,
		"totalCountries": 3,
		"totalStates": 41,
		"totalEvents": 189,
		"eventPercent": 0.78718,
		"totalScheduleItems": 1683,
		"schedulePercent": 0.660575,
		"totalGuests": 494,
		"guestPercent": 0.68971,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "Royale",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 53
	},
	{
		"id": "0c86cbc",
		"email": "ljungar@gmail.com",
		"name": "Tobias",
		"createdDate": "2013-04-29 14:13:58",
		"totalOrganizations": 31,
		"totalTours": 24,
		"totalDays": 401,
		"totalCountries": 5,
		"totalStates": 12,
		"totalEvents": 358,
		"eventPercent": 0.945305,
		"totalScheduleItems": 2267,
		"schedulePercent": 0.761489,
		"totalGuests": 414,
		"guestPercent": 0.659913,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Royal Arena",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 140
	},
	{
		"id": "464b6dd",
		"email": "nothabs.management@gmail.com",
		"name": "Princess",
		"createdDate": "2022-09-23 14:09:32",
		"totalOrganizations": 9,
		"totalTours": 14,
		"totalDays": 471,
		"totalCountries": 15,
		"totalStates": 25,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 571,
		"schedulePercent": 0.328036,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Grande Halle De La Villette",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 14
	},
	{
		"id": "94d9c8c",
		"email": "jnmatthews@live.co.uk",
		"name": "Jonathan",
		"createdDate": "2023-10-23 16:02:49",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 36,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 205,
		"schedulePercent": 0.153357,
		"totalGuests": 47,
		"guestPercent": 0.417031,
		"mostGuestDate": "2023-12-03 00:00:00",
		"mostGuestName": "Summersalt",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 9
	},
	{
		"id": "8449013",
		"email": "1776mjones@gmail.com",
		"name": "Michael",
		"createdDate": "2022-07-29 05:57:46",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 644,
		"totalCountries": 1,
		"totalStates": 37,
		"totalEvents": 219,
		"eventPercent": 0.836048,
		"totalScheduleItems": 1318,
		"schedulePercent": 0.57052,
		"totalGuests": 174,
		"guestPercent": 0.532115,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "Arrow Rock Lyceum Theatre",
		"mostGuestCity": "Arrow Rock",
		"mostGuestAmount": 22
	},
	{
		"id": "ffe8093",
		"email": "ania@centraleuro.org",
		"name": "Ania",
		"createdDate": "2022-05-23 13:37:17",
		"totalOrganizations": 5,
		"totalTours": 11,
		"totalDays": 197,
		"totalCountries": 11,
		"totalStates": 20,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 1059,
		"schedulePercent": 0.500728,
		"totalGuests": 239,
		"guestPercent": 0.573037,
		"mostGuestDate": "2023-07-01 00:00:00",
		"mostGuestName": "Crystal Palace Park",
		"mostGuestCity": "London",
		"mostGuestAmount": 40
	},
	{
		"id": "3530ee3",
		"email": "francia.a.c@gmail.com",
		"name": "Francia",
		"createdDate": "2023-09-24 21:12:38",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 96,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 713,
		"schedulePercent": 0.385909,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4d3e051",
		"email": "Paula.Eden@wmg.com",
		"name": "Paula",
		"createdDate": "2017-08-10 03:01:47",
		"totalOrganizations": 48,
		"totalTours": 44,
		"totalDays": 15011,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 1913,
		"eventPercent": 0.993908,
		"totalScheduleItems": 742,
		"schedulePercent": 0.397431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a1bff98",
		"email": "tb@kingstar-music.com",
		"name": "Timo",
		"createdDate": "2016-10-18 19:58:39",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 140,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 747,
		"schedulePercent": 0.400609,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6c86883",
		"email": "arielposen@gmail.com",
		"name": "Ariel",
		"createdDate": "2013-06-11 15:42:59",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 233,
		"totalCountries": 9,
		"totalStates": 48,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 872,
		"schedulePercent": 0.443915,
		"totalGuests": 953,
		"guestPercent": 0.813402,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 65
	},
	{
		"id": "b7c06f2",
		"email": "faveyd@me.com",
		"name": "Dave",
		"createdDate": "2018-02-13 21:11:22",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 235,
		"totalCountries": 7,
		"totalStates": 22,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 841,
		"schedulePercent": 0.434777,
		"totalGuests": 200,
		"guestPercent": 0.550258,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Union Chapel",
		"mostGuestCity": "London",
		"mostGuestAmount": 37
	},
	{
		"id": "6a04bf0",
		"email": "f.grimm@leiter-verlag.com",
		"name": "Felix",
		"createdDate": "2022-09-15 08:11:19",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 233,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 576,
		"schedulePercent": 0.330685,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "02f1bf0",
		"email": "attyb@icloud.com",
		"name": "Atalanta",
		"createdDate": "2017-02-20 15:36:05",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 339,
		"totalCountries": 6,
		"totalStates": 41,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 2620,
		"schedulePercent": 0.810886,
		"totalGuests": 302,
		"guestPercent": 0.603496,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "DeVos Performance Hall",
		"mostGuestCity": "Grand Rapids",
		"mostGuestAmount": 30
	},
	{
		"id": "e05717e",
		"email": "jackie@ifyouknowitsingit.com",
		"name": "Jackie",
		"createdDate": "2022-07-29 12:29:23",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 126,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 455,
		"schedulePercent": 0.278639,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-01-20 00:00:00",
		"mostGuestName": "Revolution Live",
		"mostGuestCity": "Fort Lauderdale",
		"mostGuestAmount": 4
	},
	{
		"id": "692d714",
		"email": "marc.engel@mac.com",
		"name": "Marc",
		"createdDate": "2016-02-13 15:08:56",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 35,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 421,
		"schedulePercent": 0.264468,
		"totalGuests": 347,
		"guestPercent": 0.627467,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "Kings Theatre",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 61
	},
	{
		"id": "2262879",
		"email": "jason@pitchside.co",
		"name": "Jason",
		"createdDate": "2017-04-18 12:36:27",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 555,
		"totalCountries": 5,
		"totalStates": 47,
		"totalEvents": 141,
		"eventPercent": 0.678321,
		"totalScheduleItems": 982,
		"schedulePercent": 0.476361,
		"totalGuests": 153,
		"guestPercent": 0.515958,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "Town Ballroom",
		"mostGuestCity": "Buffalo",
		"mostGuestAmount": 32
	},
	{
		"id": "a87cf51",
		"email": "Wedgeb@me.com",
		"name": "Wedge",
		"createdDate": "2015-05-11 04:31:08",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 80,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 291,
		"schedulePercent": 0.203682,
		"totalGuests": 72,
		"guestPercent": 0.446828,
		"mostGuestDate": "2023-04-25 00:00:00",
		"mostGuestName": "The Aztec Theatre",
		"mostGuestCity": "San Antonio",
		"mostGuestAmount": 29
	},
	{
		"id": "4508651",
		"email": "andrewpouya@yahoo.com",
		"name": "Andrew",
		"createdDate": "2021-11-15 21:10:17",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 354,
		"totalCountries": 7,
		"totalStates": 38,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 1402,
		"schedulePercent": 0.59171,
		"totalGuests": 45,
		"guestPercent": 0.412925,
		"mostGuestDate": "2023-02-04 00:00:00",
		"mostGuestName": "Exchange LA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 45
	},
	{
		"id": "e3d7109",
		"email": "info@tiptoebigband.dk",
		"name": "Hans Christian ",
		"createdDate": "2021-06-15 13:00:05",
		"totalOrganizations": 2,
		"totalTours": 11,
		"totalDays": 52,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 176,
		"schedulePercent": 0.136008,
		"totalGuests": 62,
		"guestPercent": 0.437028,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "ODEON",
		"mostGuestCity": "Odense",
		"mostGuestAmount": 37
	},
	{
		"id": "13791a6",
		"email": "piratelight@mac.com",
		"name": "Wilko",
		"createdDate": "2011-10-07 16:59:29",
		"totalOrganizations": 8,
		"totalTours": 2,
		"totalDays": 148,
		"totalCountries": 3,
		"totalStates": 15,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 900,
		"schedulePercent": 0.45292,
		"totalGuests": 1158,
		"guestPercent": 0.851013,
		"mostGuestDate": "2023-01-19 00:00:00",
		"mostGuestName": "Palladium",
		"mostGuestCity": "KÃ¶ln",
		"mostGuestAmount": 184
	},
	{
		"id": "8c01987",
		"email": "carlos.barth@summitwireless.se",
		"name": "Carlos",
		"createdDate": "2010-03-12 08:48:08",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 128,
		"totalCountries": 6,
		"totalStates": 10,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 296,
		"schedulePercent": 0.205933,
		"totalGuests": 169,
		"guestPercent": 0.529466,
		"mostGuestDate": "2023-03-22 00:00:00",
		"mostGuestName": "Pustervik",
		"mostGuestCity": "Gothenburg",
		"mostGuestAmount": 58
	},
	{
		"id": "34821c3",
		"email": "James.MacGranthin@Gmail.com",
		"name": "Jim",
		"createdDate": "2022-05-23 16:04:15",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 132,
		"totalCountries": 12,
		"totalStates": 31,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 751,
		"schedulePercent": 0.402331,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-09-26 00:00:00",
		"mostGuestName": "Electric Ballroom",
		"mostGuestCity": "London",
		"mostGuestAmount": 3
	},
	{
		"id": "43332af",
		"email": "Jan@randomfleet.com",
		"name": "Jan",
		"createdDate": "2022-05-17 12:33:53",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 79,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 263,
		"schedulePercent": 0.187922,
		"totalGuests": 11,
		"guestPercent": 0.345782,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "Schloss Eyrichshof",
		"mostGuestCity": "Ebern",
		"mostGuestAmount": 9
	},
	{
		"id": "9550bdf",
		"email": "ontourwithlauren@gmail.com",
		"name": "Lauren",
		"createdDate": "2023-08-11 17:09:41",
		"totalOrganizations": 1,
		"totalTours": 6,
		"totalDays": 173,
		"totalCountries": 9,
		"totalStates": 42,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1278,
		"schedulePercent": 0.559529,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fcfdb59",
		"email": "jorgerpina@gmail.com",
		"name": "Jorge",
		"createdDate": "2015-04-30 04:36:53",
		"totalOrganizations": 6,
		"totalTours": 15,
		"totalDays": 153,
		"totalCountries": 13,
		"totalStates": 41,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 1723,
		"schedulePercent": 0.669977,
		"totalGuests": 362,
		"guestPercent": 0.636472,
		"mostGuestDate": "2023-03-22 00:00:00",
		"mostGuestName": "The Regent",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 27
	},
	{
		"id": "fdba539",
		"email": "Chris@Schroductions.com",
		"name": "Chris",
		"createdDate": "2017-01-27 21:23:29",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 462,
		"totalCountries": 11,
		"totalStates": 29,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1390,
		"schedulePercent": 0.588531,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "The Metropolitan Nightclub",
		"mostGuestCity": "New Orleans",
		"mostGuestAmount": 5
	},
	{
		"id": "c18c2e6",
		"email": "bj@wastedpotential.net",
		"name": "BJ",
		"createdDate": "2020-01-17 16:03:21",
		"totalOrganizations": 12,
		"totalTours": 10,
		"totalDays": 1955,
		"totalCountries": 18,
		"totalStates": 54,
		"totalEvents": 175,
		"eventPercent": 0.761753,
		"totalScheduleItems": 1483,
		"schedulePercent": 0.610118,
		"totalGuests": 171,
		"guestPercent": 0.530658,
		"mostGuestDate": "2023-08-01 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 57
	},
	{
		"id": "86a7912",
		"email": "seanguest.musicmatters2016@gmail.com",
		"name": "Sean ",
		"createdDate": "2021-09-09 02:15:56",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 260,
		"totalCountries": 1,
		"totalStates": 15,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 1511,
		"schedulePercent": 0.62058,
		"totalGuests": 451,
		"guestPercent": 0.674348,
		"mostGuestDate": "2023-09-06 00:00:00",
		"mostGuestName": "The Aretha Franklin Amphitheatre",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 26
	},
	{
		"id": "9db8831",
		"email": "tassell_dawson@me.com",
		"name": "Matthew",
		"createdDate": "2013-09-09 15:53:17",
		"totalOrganizations": 7,
		"totalTours": 13,
		"totalDays": 461,
		"totalCountries": 11,
		"totalStates": 53,
		"totalEvents": 182,
		"eventPercent": 0.773408,
		"totalScheduleItems": 2699,
		"schedulePercent": 0.8187,
		"totalGuests": 555,
		"guestPercent": 0.711296,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "O2 Shepherd's Bush Empire",
		"mostGuestCity": "London",
		"mostGuestAmount": 49
	},
	{
		"id": "43cf543",
		"email": "diepuig@gmail.com",
		"name": "Diego",
		"createdDate": "2023-06-01 22:18:45",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 161,
		"totalCountries": 6,
		"totalStates": 31,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 660,
		"schedulePercent": 0.365382,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f06b872",
		"email": "jarchermgmt@gmail.com",
		"name": "Jake",
		"createdDate": "2015-03-17 21:32:18",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 341,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 374,
		"schedulePercent": 0.24553,
		"totalGuests": 158,
		"guestPercent": 0.520858,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "MISSION BAYFEST",
		"mostGuestCity": "",
		"mostGuestAmount": 114
	},
	{
		"id": "fd77a41",
		"email": "reyasunshine@icloud.com",
		"name": "Reya",
		"createdDate": "2021-05-13 19:43:42",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 156,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 218,
		"schedulePercent": 0.1617,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f80345d",
		"email": "jakekatzsound@gmail.com",
		"name": "Jake",
		"createdDate": "2014-02-13 08:45:40",
		"totalOrganizations": 9,
		"totalTours": 15,
		"totalDays": 197,
		"totalCountries": 8,
		"totalStates": 55,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 1418,
		"schedulePercent": 0.595418,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-04-30 00:00:00",
		"mostGuestName": "Coors Event Centre",
		"mostGuestCity": "Saskatoon",
		"mostGuestAmount": 6
	},
	{
		"id": "0fff47c",
		"email": "dynastyproductions84@gmail.com",
		"name": "Anthony",
		"createdDate": "2017-06-12 18:01:27",
		"totalOrganizations": 12,
		"totalTours": 56,
		"totalDays": 1671,
		"totalCountries": 25,
		"totalStates": 102,
		"totalEvents": 537,
		"eventPercent": 0.978016,
		"totalScheduleItems": 6538,
		"schedulePercent": 0.985035,
		"totalGuests": 2370,
		"guestPercent": 0.949278,
		"mostGuestDate": "2023-08-14 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 61
	},
	{
		"id": "546017d",
		"email": "vancetm@gmail.com",
		"name": "Danny",
		"createdDate": "2014-01-13 20:19:08",
		"totalOrganizations": 13,
		"totalTours": 13,
		"totalDays": 454,
		"totalCountries": 14,
		"totalStates": 45,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 1795,
		"schedulePercent": 0.684545,
		"totalGuests": 29,
		"guestPercent": 0.387896,
		"mostGuestDate": "2023-07-27 00:00:00",
		"mostGuestName": "Mountain America Center",
		"mostGuestCity": "Idaho Falls",
		"mostGuestAmount": 14
	},
	{
		"id": "fe42cbd",
		"email": "mpontino@cityparksfoundation.org",
		"name": "Michelle",
		"createdDate": "2023-05-17 21:41:14",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 220,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 1475,
		"schedulePercent": 0.607999,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7ea2918",
		"email": "stacey@globalproductionpartners.com",
		"name": "Stacey",
		"createdDate": "2022-11-15 00:36:45",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 188,
		"totalCountries": 11,
		"totalStates": 24,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 1125,
		"schedulePercent": 0.51768,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c9f4229",
		"email": "omarcalling@gmail.com",
		"name": "Omar",
		"createdDate": "2018-01-29 11:35:06",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 108,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 987,
		"schedulePercent": 0.479142,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "109bd57",
		"email": "hanford.music@gmail.com",
		"name": "Email",
		"createdDate": "2015-05-06 21:32:39",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 164,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 344,
		"schedulePercent": 0.230168,
		"totalGuests": 39,
		"guestPercent": 0.404185,
		"mostGuestDate": "2023-03-09 00:00:00",
		"mostGuestName": "Globe Life Field",
		"mostGuestCity": "Arlington",
		"mostGuestAmount": 19
	},
	{
		"id": "4c4a345",
		"email": "LesMisTourCM@NetworksTours.com",
		"name": "Chris",
		"createdDate": "2022-04-30 23:11:16",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 598,
		"totalCountries": 1,
		"totalStates": 23,
		"totalEvents": 509,
		"eventPercent": 0.975367,
		"totalScheduleItems": 4860,
		"schedulePercent": 0.957092,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "89e67c7",
		"email": "racheltheprince@gmail.com",
		"name": "Rachel",
		"createdDate": "2021-11-24 17:55:50",
		"totalOrganizations": 2,
		"totalTours": 9,
		"totalDays": 62,
		"totalCountries": 3,
		"totalStates": 8,
		"totalEvents": 13,
		"eventPercent": 0.0892597,
		"totalScheduleItems": 125,
		"schedulePercent": 0.106079,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7791881",
		"email": "timt@transparentproductions.com",
		"name": "Tim",
		"createdDate": "2021-09-16 18:49:21",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 101,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 347,
		"schedulePercent": 0.231757,
		"totalGuests": 308,
		"guestPercent": 0.608131,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "The Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 130
	},
	{
		"id": "1c4bca8",
		"email": "eli.grenier@gmail.com",
		"name": "Elisabeth ",
		"createdDate": "2022-09-15 13:08:25",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 518,
		"totalCountries": 21,
		"totalStates": 52,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 1326,
		"schedulePercent": 0.573699,
		"totalGuests": 306,
		"guestPercent": 0.606807,
		"mostGuestDate": "2023-09-27 00:00:00",
		"mostGuestName": "Imperial Bell",
		"mostGuestCity": "Quebec",
		"mostGuestAmount": 36
	},
	{
		"id": "fa404f8",
		"email": "belkd88@gmail.com",
		"name": "Drew",
		"createdDate": "2020-03-05 00:29:24",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 460,
		"totalCountries": 9,
		"totalStates": 28,
		"totalEvents": 173,
		"eventPercent": 0.757516,
		"totalScheduleItems": 1633,
		"schedulePercent": 0.649583,
		"totalGuests": 1887,
		"guestPercent": 0.927427,
		"mostGuestDate": "2023-09-03 00:00:00",
		"mostGuestName": "The Cosmopolitan of Las Vegas",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 138
	},
	{
		"id": "5f887ce",
		"email": "jy@indigo-productions.fr",
		"name": "Jean-Yves",
		"createdDate": "2022-09-22 12:05:24",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 51,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 294,
		"schedulePercent": 0.205006,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "20deb7c",
		"email": "alexfresnedamusic@gmail.com",
		"name": "Alexander",
		"createdDate": "2023-09-04 20:22:13",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 34,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 138,
		"schedulePercent": 0.112171,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c48de9f",
		"email": "ohlnick@yahoo.com",
		"name": "nicholas",
		"createdDate": "2014-07-14 21:12:32",
		"totalOrganizations": 13,
		"totalTours": 16,
		"totalDays": 889,
		"totalCountries": 15,
		"totalStates": 50,
		"totalEvents": 186,
		"eventPercent": 0.782016,
		"totalScheduleItems": 3393,
		"schedulePercent": 0.8873,
		"totalGuests": 917,
		"guestPercent": 0.803337,
		"mostGuestDate": "2023-04-06 00:00:00",
		"mostGuestName": "Crypto.com Arena",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 49
	},
	{
		"id": "0894581",
		"email": "neil@twankyrecords.com",
		"name": "Neil",
		"createdDate": "2022-08-08 16:31:14",
		"totalOrganizations": 1,
		"totalTours": 9,
		"totalDays": 337,
		"totalCountries": 5,
		"totalStates": 8,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 323,
		"schedulePercent": 0.220501,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b0c1a4d",
		"email": "finn@brace-productions.com",
		"name": "Finn",
		"createdDate": "2018-04-27 08:15:32",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 376,
		"totalCountries": 10,
		"totalStates": 13,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 547,
		"schedulePercent": 0.31956,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3153457",
		"email": "Oeightyfour@gmail.com",
		"name": "Aaron",
		"createdDate": "2014-10-31 19:30:42",
		"totalOrganizations": 11,
		"totalTours": 3,
		"totalDays": 424,
		"totalCountries": 11,
		"totalStates": 50,
		"totalEvents": 141,
		"eventPercent": 0.678321,
		"totalScheduleItems": 2513,
		"schedulePercent": 0.795259,
		"totalGuests": 1494,
		"guestPercent": 0.893656,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 69
	},
	{
		"id": "f0d79aa",
		"email": "ianl@dircon.co.uk",
		"name": "Ian",
		"createdDate": "2013-12-20 01:02:32",
		"totalOrganizations": 15,
		"totalTours": 32,
		"totalDays": 326,
		"totalCountries": 19,
		"totalStates": 43,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 1671,
		"schedulePercent": 0.657794,
		"totalGuests": 918,
		"guestPercent": 0.803735,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Minnesota State Fair",
		"mostGuestCity": "St Paul",
		"mostGuestAmount": 65
	},
	{
		"id": "bff6daf",
		"email": "aubrey@weekdaymgmt.com",
		"name": "Aubrey",
		"createdDate": "2022-06-10 20:09:07",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 208,
		"totalCountries": 6,
		"totalStates": 35,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1022,
		"schedulePercent": 0.491591,
		"totalGuests": 356,
		"guestPercent": 0.633029,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 46
	},
	{
		"id": "d3f68f3",
		"email": "jotaayerza@gmail.com",
		"name": "Jose Jeronimo",
		"createdDate": "2022-06-15 10:47:09",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 194,
		"totalCountries": 5,
		"totalStates": 22,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 403,
		"schedulePercent": 0.258376,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "efab4a1",
		"email": "drfun1969@gmail.com",
		"name": "Meredith",
		"createdDate": "2011-04-22 01:08:45",
		"totalOrganizations": 10,
		"totalTours": 4,
		"totalDays": 197,
		"totalCountries": 8,
		"totalStates": 24,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 1341,
		"schedulePercent": 0.577937,
		"totalGuests": 955,
		"guestPercent": 0.814064,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "London Palladium",
		"mostGuestCity": "London",
		"mostGuestAmount": 53
	},
	{
		"id": "7941d70",
		"email": "nigelsbrown65@mac.com",
		"name": "Nigel",
		"createdDate": "2015-09-16 13:07:05",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 218,
		"totalCountries": 3,
		"totalStates": 27,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 720,
		"schedulePercent": 0.388028,
		"totalGuests": 493,
		"guestPercent": 0.689313,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 63
	},
	{
		"id": "18a30b4",
		"email": "noum@dreamhaus.com",
		"name": "Noum",
		"createdDate": "2022-12-01 03:37:12",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 168,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 753,
		"schedulePercent": 0.403655,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a09f411",
		"email": "cnnrward@gmail.com",
		"name": "Connor ",
		"createdDate": "2022-06-22 14:43:51",
		"totalOrganizations": 1,
		"totalTours": 6,
		"totalDays": 173,
		"totalCountries": 9,
		"totalStates": 42,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1278,
		"schedulePercent": 0.559529,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8673115",
		"email": "mixsandos@gmail.com",
		"name": "Matt",
		"createdDate": "2009-08-27 17:56:55",
		"totalOrganizations": 11,
		"totalTours": 11,
		"totalDays": 277,
		"totalCountries": 10,
		"totalStates": 50,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 2254,
		"schedulePercent": 0.759237,
		"totalGuests": 966,
		"guestPercent": 0.816845,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 65
	},
	{
		"id": "d3af81a",
		"email": "aidan@legacycontent.com",
		"name": "Aidan",
		"createdDate": "2022-05-27 01:14:20",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 124,
		"totalCountries": 2,
		"totalStates": 39,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 339,
		"schedulePercent": 0.227122,
		"totalGuests": 21,
		"guestPercent": 0.371342,
		"mostGuestDate": "2023-04-09 00:00:00",
		"mostGuestName": "The Moroccan Lounge",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 12
	},
	{
		"id": "d76db49",
		"email": "bgiller@rocnation.com",
		"name": "Ben",
		"createdDate": "2021-07-01 21:14:24",
		"totalOrganizations": 11,
		"totalTours": 13,
		"totalDays": 1011,
		"totalCountries": 8,
		"totalStates": 35,
		"totalEvents": 318,
		"eventPercent": 0.924778,
		"totalScheduleItems": 3103,
		"schedulePercent": 0.861873,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a4ee056",
		"email": "Info@optimysticgroup.com",
		"name": "RA",
		"createdDate": "2021-04-21 11:55:33",
		"totalOrganizations": 8,
		"totalTours": 3,
		"totalDays": 416,
		"totalCountries": 3,
		"totalStates": 25,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 132,
		"schedulePercent": 0.108992,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "The Intersection",
		"mostGuestCity": "Grand Rapids",
		"mostGuestAmount": 9
	},
	{
		"id": "106b444",
		"email": "mrhodge@mac.com",
		"name": "Simon",
		"createdDate": "2023-04-05 12:09:15",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 81,
		"totalCountries": 3,
		"totalStates": 24,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 571,
		"schedulePercent": 0.328036,
		"totalGuests": 104,
		"guestPercent": 0.478877,
		"mostGuestDate": "2023-07-03 00:00:00",
		"mostGuestName": "Thomas Wolfe Auditorium",
		"mostGuestCity": "Asheville",
		"mostGuestAmount": 29
	},
	{
		"id": "651de24",
		"email": "kshomo@levitytalent.com",
		"name": "Kinsey",
		"createdDate": "2022-06-16 10:47:34",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 179,
		"totalCountries": 7,
		"totalStates": 27,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 772,
		"schedulePercent": 0.409482,
		"totalGuests": 516,
		"guestPercent": 0.697656,
		"mostGuestDate": "2023-09-09 00:00:00",
		"mostGuestName": "Radio City Music Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 81
	},
	{
		"id": "fe94318",
		"email": "andrewbeselica@gmail.com",
		"name": "Andrew ",
		"createdDate": "2013-10-06 23:50:20",
		"totalOrganizations": 6,
		"totalTours": 11,
		"totalDays": 1335,
		"totalCountries": 7,
		"totalStates": 54,
		"totalEvents": 271,
		"eventPercent": 0.895113,
		"totalScheduleItems": 4346,
		"schedulePercent": 0.939478,
		"totalGuests": 2293,
		"guestPercent": 0.947292,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Dos Equis Pavilion",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 155
	},
	{
		"id": "c653e5d",
		"email": "wes.kidd@redlightmanagement.com",
		"name": "wes",
		"createdDate": "2013-03-08 13:59:56",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 151,
		"totalCountries": 2,
		"totalStates": 35,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 992,
		"schedulePercent": 0.481658,
		"totalGuests": 223,
		"guestPercent": 0.564958,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 38
	},
	{
		"id": "6ad85ee",
		"email": "camtrewin@gmail.com",
		"name": "Cam",
		"createdDate": "2013-01-25 08:34:23",
		"totalOrganizations": 22,
		"totalTours": 50,
		"totalDays": 851,
		"totalCountries": 13,
		"totalStates": 57,
		"totalEvents": 292,
		"eventPercent": 0.910211,
		"totalScheduleItems": 3361,
		"schedulePercent": 0.883989,
		"totalGuests": 395,
		"guestPercent": 0.651569,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "El Rey Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 40
	},
	{
		"id": "6561872",
		"email": "metallkax@hotmail.com",
		"name": "Karla",
		"createdDate": "2018-08-10 01:34:24",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 42,
		"totalCountries": 3,
		"totalStates": 11,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 241,
		"schedulePercent": 0.1764,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8fef56d",
		"email": "tm@the502s.com",
		"name": "TM",
		"createdDate": "2022-04-01 00:27:51",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 341,
		"totalCountries": 9,
		"totalStates": 51,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 941,
		"schedulePercent": 0.462853,
		"totalGuests": 267,
		"guestPercent": 0.588399,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "Lincoln Hall",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 23
	},
	{
		"id": "de7737f",
		"email": "MITCH.GEE@ICLOUD.COM",
		"name": "Mitch",
		"createdDate": "2022-01-18 20:12:12",
		"totalOrganizations": 7,
		"totalTours": 31,
		"totalDays": 449,
		"totalCountries": 14,
		"totalStates": 50,
		"totalEvents": 202,
		"eventPercent": 0.810091,
		"totalScheduleItems": 2464,
		"schedulePercent": 0.788372,
		"totalGuests": 21,
		"guestPercent": 0.371342,
		"mostGuestDate": "2023-06-22 00:00:00",
		"mostGuestName": "Stade Pierre mauroy",
		"mostGuestCity": "Lille",
		"mostGuestAmount": 12
	},
	{
		"id": "809d861",
		"email": "the.firstborn@gmail.com",
		"name": "Bruno",
		"createdDate": "2014-05-23 01:42:41",
		"totalOrganizations": 13,
		"totalTours": 24,
		"totalDays": 621,
		"totalCountries": 27,
		"totalStates": 93,
		"totalEvents": 463,
		"eventPercent": 0.96954,
		"totalScheduleItems": 5501,
		"schedulePercent": 0.972984,
		"totalGuests": 7210,
		"guestPercent": 0.998676,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Olympiastadion Berlin",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 794
	},
	{
		"id": "28f33c2",
		"email": "adamjoseflevy@gmail.com",
		"name": "Adam",
		"createdDate": "2021-12-01 20:18:02",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 30,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 245,
		"schedulePercent": 0.179049,
		"totalGuests": 83,
		"guestPercent": 0.45835,
		"mostGuestDate": "2023-09-26 00:00:00",
		"mostGuestName": "Kiri Te Kanawa Theatre, Aotea Centre",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 31
	},
	{
		"id": "2115544",
		"email": "tom@tlcmusic.co.uk",
		"name": "Tom",
		"createdDate": "2018-06-26 13:54:16",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 485,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 629,
		"schedulePercent": 0.354125,
		"totalGuests": 893,
		"guestPercent": 0.797908,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "London Palladium",
		"mostGuestCity": "London",
		"mostGuestAmount": 132
	},
	{
		"id": "3d8c350",
		"email": "emal@festco.com.au",
		"name": "Emal",
		"createdDate": "2023-05-06 04:44:48",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 45,
		"totalCountries": 8,
		"totalStates": 15,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 173,
		"schedulePercent": 0.134684,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a3921e2",
		"email": "dpearson@chippendales.com",
		"name": "Danielle",
		"createdDate": "2015-01-22 20:07:20",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 59,
		"totalCountries": 7,
		"totalStates": 19,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 516,
		"schedulePercent": 0.306185,
		"totalGuests": 149,
		"guestPercent": 0.513972,
		"mostGuestDate": "2023-03-21 00:00:00",
		"mostGuestName": "Volkshaus",
		"mostGuestCity": "Zurich",
		"mostGuestAmount": 16
	},
	{
		"id": "620792c",
		"email": "sean@macstarproductions.com",
		"name": "Sean",
		"createdDate": "2010-01-25 20:32:39",
		"totalOrganizations": 23,
		"totalTours": 16,
		"totalDays": 749,
		"totalCountries": 9,
		"totalStates": 46,
		"totalEvents": 209,
		"eventPercent": 0.82201,
		"totalScheduleItems": 2294,
		"schedulePercent": 0.764932,
		"totalGuests": 845,
		"guestPercent": 0.787048,
		"mostGuestDate": "2023-09-11 00:00:00",
		"mostGuestName": "The National Jazz Museum in Harlem",
		"mostGuestCity": "New York",
		"mostGuestAmount": 75
	},
	{
		"id": "dd9564f",
		"email": "opryvincent@gmail.com",
		"name": "Darrin ",
		"createdDate": "2017-10-06 14:02:24",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 161,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 148,
		"eventPercent": 0.696332,
		"totalScheduleItems": 827,
		"schedulePercent": 0.429215,
		"totalGuests": 313,
		"guestPercent": 0.61078,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 38
	},
	{
		"id": "cd76745",
		"email": "milifost9@gmail.com",
		"name": "Mili",
		"createdDate": "2022-05-16 09:36:33",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 153,
		"totalCountries": 7,
		"totalStates": 28,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 1320,
		"schedulePercent": 0.57158,
		"totalGuests": 379,
		"guestPercent": 0.64455,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "South Facing Festival",
		"mostGuestCity": "London",
		"mostGuestAmount": 68
	},
	{
		"id": "5d5e7c9",
		"email": "j_matzinger@hotmail.com",
		"name": "Joel",
		"createdDate": "2012-01-18 10:32:48",
		"totalOrganizations": 8,
		"totalTours": 7,
		"totalDays": 437,
		"totalCountries": 5,
		"totalStates": 40,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 1313,
		"schedulePercent": 0.569726,
		"totalGuests": 169,
		"guestPercent": 0.529466,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Tabernacle",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 57
	},
	{
		"id": "67cd497",
		"email": "trishgorman@hotmail.com",
		"name": "Patricia",
		"createdDate": "2016-08-17 00:16:38",
		"totalOrganizations": 7,
		"totalTours": 55,
		"totalDays": 505,
		"totalCountries": 7,
		"totalStates": 26,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 893,
		"schedulePercent": 0.450934,
		"totalGuests": 244,
		"guestPercent": 0.575288,
		"mostGuestDate": "2023-04-29 00:00:00",
		"mostGuestName": "The Broadside",
		"mostGuestCity": "New Orleans",
		"mostGuestAmount": 43
	},
	{
		"id": "0206964",
		"email": "kws1124@gmail.com",
		"name": "Kyle",
		"createdDate": "2018-09-25 16:34:36",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 561,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 575,
		"schedulePercent": 0.33042,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "42032b4",
		"email": "sevignymatt@gmail.com",
		"name": "Mathieu",
		"createdDate": "2015-06-17 13:36:23",
		"totalOrganizations": 13,
		"totalTours": 11,
		"totalDays": 619,
		"totalCountries": 12,
		"totalStates": 26,
		"totalEvents": 191,
		"eventPercent": 0.790491,
		"totalScheduleItems": 1591,
		"schedulePercent": 0.63965,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "ThÃ©Ã¢tre du Cuivre",
		"mostGuestCity": "Rouyn-Noranda",
		"mostGuestAmount": 7
	},
	{
		"id": "1e11941",
		"email": "Christophernewman4@gmail.com",
		"name": "Christopher",
		"createdDate": "2017-03-15 02:48:14",
		"totalOrganizations": 12,
		"totalTours": 8,
		"totalDays": 1393,
		"totalCountries": 4,
		"totalStates": 45,
		"totalEvents": 248,
		"eventPercent": 0.869554,
		"totalScheduleItems": 3607,
		"schedulePercent": 0.901073,
		"totalGuests": 1899,
		"guestPercent": 0.928619,
		"mostGuestDate": "2023-04-13 00:00:00",
		"mostGuestName": "The Fisher Center for the Performing Arts",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 104
	},
	{
		"id": "4f75f09",
		"email": "soundmantom@gmail.com",
		"name": "Tom",
		"createdDate": "2012-03-21 20:36:38",
		"totalOrganizations": 7,
		"totalTours": 21,
		"totalDays": 734,
		"totalCountries": 19,
		"totalStates": 60,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 3310,
		"schedulePercent": 0.878294,
		"totalGuests": 646,
		"guestPercent": 0.737916,
		"mostGuestDate": "2023-09-05 00:00:00",
		"mostGuestName": "Singapore Indoor Stadium",
		"mostGuestCity": "Singapore",
		"mostGuestAmount": 78
	},
	{
		"id": "a311669",
		"email": "cbroadway@me.com",
		"name": "Curtis",
		"createdDate": "2023-11-17 20:52:20",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 27,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 225,
		"schedulePercent": 0.1666,
		"totalGuests": 73,
		"guestPercent": 0.447755,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "The Factory",
		"mostGuestCity": "Chesterfield",
		"mostGuestAmount": 29
	},
	{
		"id": "836b568",
		"email": "wmtouring@gmail.com",
		"name": "ADMIN",
		"createdDate": "2022-08-22 14:39:43",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 219,
		"totalCountries": 4,
		"totalStates": 36,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 1275,
		"schedulePercent": 0.558337,
		"totalGuests": 564,
		"guestPercent": 0.715005,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 68
	},
	{
		"id": "c914228",
		"email": "emma@luger.dk",
		"name": "Matilde",
		"createdDate": "2019-07-30 07:21:23",
		"totalOrganizations": 72,
		"totalTours": 41,
		"totalDays": 678,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 601,
		"eventPercent": 0.982651,
		"totalScheduleItems": 3103,
		"schedulePercent": 0.861873,
		"totalGuests": 337,
		"guestPercent": 0.621375,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "Fredagsrock i Tivoli",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 50
	},
	{
		"id": "9a7c3bc",
		"email": "wversen@me.com",
		"name": "Walter",
		"createdDate": "2015-03-10 20:22:07",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 890,
		"totalCountries": 4,
		"totalStates": 24,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 946,
		"schedulePercent": 0.464177,
		"totalGuests": 85,
		"guestPercent": 0.459144,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "Westgate Las Vegas Resort & Casino",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 32
	},
	{
		"id": "d92f607",
		"email": "slowriot1@mac.com",
		"name": "Shaun",
		"createdDate": "2014-09-24 20:34:06",
		"totalOrganizations": 22,
		"totalTours": 22,
		"totalDays": 702,
		"totalCountries": 23,
		"totalStates": 66,
		"totalEvents": 166,
		"eventPercent": 0.74308,
		"totalScheduleItems": 2683,
		"schedulePercent": 0.817508,
		"totalGuests": 1452,
		"guestPercent": 0.887697,
		"mostGuestDate": "2023-02-09 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 76
	},
	{
		"id": "8391899",
		"email": "Adrien@voulezvousdanser.com",
		"name": "Adrien",
		"createdDate": "2019-11-05 06:34:18",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 468,
		"totalCountries": 14,
		"totalStates": 32,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 604,
		"schedulePercent": 0.343266,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-04-26 00:00:00",
		"mostGuestName": "Foro Indierocks!",
		"mostGuestCity": "MÃ©xico D.F.",
		"mostGuestAmount": 6
	},
	{
		"id": "b0da024",
		"email": "tb@tobiasberg.dk",
		"name": "Tobias",
		"createdDate": "2016-01-07 10:33:58",
		"totalOrganizations": 17,
		"totalTours": 8,
		"totalDays": 425,
		"totalCountries": 10,
		"totalStates": 21,
		"totalEvents": 325,
		"eventPercent": 0.928751,
		"totalScheduleItems": 3032,
		"schedulePercent": 0.854059,
		"totalGuests": 790,
		"guestPercent": 0.774467,
		"mostGuestDate": "2023-03-12 00:00:00",
		"mostGuestName": "Verti Music Hall",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 63
	},
	{
		"id": "d086199",
		"email": "920colleen@gmail.com",
		"name": "Colleen",
		"createdDate": "2022-09-19 19:08:28",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 275,
		"totalCountries": 8,
		"totalStates": 21,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 734,
		"schedulePercent": 0.39359,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3294c3b",
		"email": "jake@crushworks.co",
		"name": "Jacob",
		"createdDate": "2022-03-30 08:56:41",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 203,
		"totalCountries": 6,
		"totalStates": 12,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 580,
		"schedulePercent": 0.332141,
		"totalGuests": 240,
		"guestPercent": 0.573434,
		"mostGuestDate": "2023-06-18 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 40
	},
	{
		"id": "2bb079a",
		"email": "sebastienp.tm@gmail.com",
		"name": "SebastienP",
		"createdDate": "2022-08-26 00:50:11",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 74,
		"totalCountries": 4,
		"totalStates": 29,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 341,
		"schedulePercent": 0.228314,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6195cad",
		"email": "sam@sjcaudio.com",
		"name": "Sam",
		"createdDate": "2022-03-31 09:02:05",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 297,
		"totalCountries": 6,
		"totalStates": 9,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 861,
		"schedulePercent": 0.440339,
		"totalGuests": 125,
		"guestPercent": 0.494636,
		"mostGuestDate": "2023-04-30 00:00:00",
		"mostGuestName": "Camp and Furnace",
		"mostGuestCity": "Liverpool",
		"mostGuestAmount": 23
	},
	{
		"id": "c960511",
		"email": "mookie@psycho-acoustic.co.uk",
		"name": "John",
		"createdDate": "2012-03-09 01:20:33",
		"totalOrganizations": 11,
		"totalTours": 11,
		"totalDays": 150,
		"totalCountries": 13,
		"totalStates": 26,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 946,
		"schedulePercent": 0.464177,
		"totalGuests": 181,
		"guestPercent": 0.537147,
		"mostGuestDate": "2023-09-29 00:00:00",
		"mostGuestName": "Union Chapel",
		"mostGuestCity": "London",
		"mostGuestAmount": 31
	},
	{
		"id": "18e0a52",
		"email": "martinearley.music@gmail.com",
		"name": "Martin",
		"createdDate": "2015-11-15 01:41:56",
		"totalOrganizations": 9,
		"totalTours": 4,
		"totalDays": 345,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 215,
		"schedulePercent": 0.159846,
		"totalGuests": 100,
		"guestPercent": 0.473977,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 27
	},
	{
		"id": "8a91cef",
		"email": "alliedautonomy@gmail.com",
		"name": "Chris",
		"createdDate": "2018-01-15 02:59:48",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 409,
		"totalCountries": 9,
		"totalStates": 56,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 1761,
		"schedulePercent": 0.676864,
		"totalGuests": 610,
		"guestPercent": 0.72891,
		"mostGuestDate": "2023-03-28 00:00:00",
		"mostGuestName": "Electric Brixton",
		"mostGuestCity": "London",
		"mostGuestAmount": 79
	},
	{
		"id": "1b6966b",
		"email": "jakekelleytouring@gmail.com",
		"name": "Jake",
		"createdDate": "2017-07-08 20:32:08",
		"totalOrganizations": 3,
		"totalTours": 10,
		"totalDays": 565,
		"totalCountries": 9,
		"totalStates": 62,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 2369,
		"schedulePercent": 0.776189,
		"totalGuests": 126,
		"guestPercent": 0.495564,
		"mostGuestDate": "2023-10-24 00:00:00",
		"mostGuestName": "The Fillmore",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 25
	},
	{
		"id": "e55893f",
		"email": "brandon.duncan93@live.com",
		"name": "Brandon",
		"createdDate": "2023-04-30 08:31:22",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 275,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 1124,
		"schedulePercent": 0.517282,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b2ad7fd",
		"email": "lbarbosa@rimasmusic.com",
		"name": "Lucas",
		"createdDate": "2022-06-11 11:16:14",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 916,
		"totalCountries": 12,
		"totalStates": 37,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 1706,
		"schedulePercent": 0.666004,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e5a8925",
		"email": "di@wgman.com",
		"name": "Diana",
		"createdDate": "2016-07-17 07:20:36",
		"totalOrganizations": 29,
		"totalTours": 28,
		"totalDays": 407,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 1153,
		"schedulePercent": 0.527877,
		"totalGuests": 463,
		"guestPercent": 0.678321,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Sydney Opera House",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 83
	},
	{
		"id": "b34905a",
		"email": "davidkleintm@gmail.com",
		"name": "David",
		"createdDate": "2011-08-08 20:31:56",
		"totalOrganizations": 12,
		"totalTours": 7,
		"totalDays": 587,
		"totalCountries": 3,
		"totalStates": 23,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1613,
		"schedulePercent": 0.645213,
		"totalGuests": 78,
		"guestPercent": 0.45398,
		"mostGuestDate": "2023-07-23 00:00:00",
		"mostGuestName": "Soaring Eagle Casino",
		"mostGuestCity": "Mt Pleasant",
		"mostGuestAmount": 28
	},
	{
		"id": "b4ee6b1",
		"email": "dave@dmbmanagement.co",
		"name": "David",
		"createdDate": "2023-02-13 17:21:32",
		"totalOrganizations": 3,
		"totalTours": 13,
		"totalDays": 187,
		"totalCountries": 8,
		"totalStates": 32,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 1401,
		"schedulePercent": 0.591445,
		"totalGuests": 670,
		"guestPercent": 0.744272,
		"mostGuestDate": "2023-03-28 00:00:00",
		"mostGuestName": "Electric Brixton",
		"mostGuestCity": "London",
		"mostGuestAmount": 79
	},
	{
		"id": "93d0434",
		"email": "chris.ulbrich@mac.com",
		"name": "Christian",
		"createdDate": "2022-06-16 04:56:48",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 46,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 242,
		"schedulePercent": 0.177195,
		"totalGuests": 40,
		"guestPercent": 0.405642,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Groton Hill Music Center",
		"mostGuestCity": "Groton",
		"mostGuestAmount": 10
	},
	{
		"id": "5e4e6c5",
		"email": "ebarkho@gmail.com",
		"name": "Enas",
		"createdDate": "2013-07-27 21:49:47",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 239,
		"totalCountries": 6,
		"totalStates": 43,
		"totalEvents": 137,
		"eventPercent": 0.666402,
		"totalScheduleItems": 2076,
		"schedulePercent": 0.731029,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "98aa791",
		"email": "thore_frerichs@gmx.de",
		"name": "Thore",
		"createdDate": "2022-07-11 13:57:52",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 160,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 503,
		"schedulePercent": 0.29996,
		"totalGuests": 480,
		"guestPercent": 0.683353,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "Circus Krone",
		"mostGuestCity": "MÃ¼nchen",
		"mostGuestAmount": 41
	},
	{
		"id": "b2f5707",
		"email": "adamjduncan@mac.com",
		"name": "Adam",
		"createdDate": "2016-09-12 17:56:54",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 754,
		"totalCountries": 3,
		"totalStates": 42,
		"totalEvents": 290,
		"eventPercent": 0.909019,
		"totalScheduleItems": 5728,
		"schedulePercent": 0.976692,
		"totalGuests": 4275,
		"guestPercent": 0.98583,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Paycom Center",
		"mostGuestCity": "Oklahoma City",
		"mostGuestAmount": 167
	},
	{
		"id": "bb1cdea",
		"email": "kit@nordicmetalalliance.com",
		"name": "Kit",
		"createdDate": "2022-04-15 14:42:19",
		"totalOrganizations": 13,
		"totalTours": 14,
		"totalDays": 242,
		"totalCountries": 19,
		"totalStates": 63,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 1998,
		"schedulePercent": 0.718051,
		"totalGuests": 1074,
		"guestPercent": 0.835121,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 54
	},
	{
		"id": "cb070fa",
		"email": "mackbvangorder@gmail.com",
		"name": "Mack",
		"createdDate": "2022-03-23 16:44:51",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 1455,
		"totalCountries": 4,
		"totalStates": 47,
		"totalEvents": 190,
		"eventPercent": 0.788505,
		"totalScheduleItems": 2731,
		"schedulePercent": 0.8236,
		"totalGuests": 432,
		"guestPercent": 0.66574,
		"mostGuestDate": "2023-03-14 00:00:00",
		"mostGuestName": "The Bowery Ballroom",
		"mostGuestCity": "New York",
		"mostGuestAmount": 39
	},
	{
		"id": "c6fa645",
		"email": "mandy@chasefamilymusic.com",
		"name": "Mimi",
		"createdDate": "2019-03-17 19:34:06",
		"totalOrganizations": 15,
		"totalTours": 26,
		"totalDays": 384,
		"totalCountries": 17,
		"totalStates": 65,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 2010,
		"schedulePercent": 0.721097,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d129e6c",
		"email": "andrerecke@mac.com",
		"name": "Andre",
		"createdDate": "2011-05-05 02:31:03",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 308,
		"totalCountries": 8,
		"totalStates": 25,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 937,
		"schedulePercent": 0.461528,
		"totalGuests": 363,
		"guestPercent": 0.637134,
		"mostGuestDate": "2023-06-14 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 138
	},
	{
		"id": "8d8594f",
		"email": "brandon@vossevents.com",
		"name": "Brandon",
		"createdDate": "2018-11-12 19:20:29",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 255,
		"totalCountries": 15,
		"totalStates": 64,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 1633,
		"schedulePercent": 0.649583,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c486645",
		"email": "thomasjaddison@gmail.com",
		"name": "Tom",
		"createdDate": "2011-01-09 20:30:45",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 716,
		"totalCountries": 5,
		"totalStates": 42,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 1574,
		"schedulePercent": 0.635545,
		"totalGuests": 2470,
		"guestPercent": 0.952986,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "Forest Hills Stadium",
		"mostGuestCity": "Forest Hills",
		"mostGuestAmount": 184
	},
	{
		"id": "a658a71",
		"email": "vortalec@gmail.com",
		"name": "Alec",
		"createdDate": "2022-02-16 16:39:48",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 516,
		"totalCountries": 13,
		"totalStates": 31,
		"totalEvents": 184,
		"eventPercent": 0.77791,
		"totalScheduleItems": 1952,
		"schedulePercent": 0.712356,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4ad8825",
		"email": "bryce.jmm@gmail.com",
		"name": "Bryce",
		"createdDate": "2023-03-22 20:25:36",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 286,
		"totalCountries": 1,
		"totalStates": 22,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 162,
		"schedulePercent": 0.128195,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ddf8d36",
		"email": "code10red@gmail.com",
		"name": "Steven",
		"createdDate": "2011-05-27 22:10:41",
		"totalOrganizations": 47,
		"totalTours": 39,
		"totalDays": 1692,
		"totalCountries": 13,
		"totalStates": 61,
		"totalEvents": 577,
		"eventPercent": 0.981327,
		"totalScheduleItems": 7896,
		"schedulePercent": 0.993643,
		"totalGuests": 2115,
		"guestPercent": 0.940273,
		"mostGuestDate": "2023-08-27 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 151
	},
	{
		"id": "16f4a07",
		"email": "team@downboysrecords.com",
		"name": "Warrant",
		"createdDate": "2021-12-02 01:11:55",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 118,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 791,
		"schedulePercent": 0.417296,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "aab74d1",
		"email": "monika.paillard@gmail.com",
		"name": "Monika",
		"createdDate": "2019-02-17 12:35:16",
		"totalOrganizations": 10,
		"totalTours": 20,
		"totalDays": 412,
		"totalCountries": 18,
		"totalStates": 52,
		"totalEvents": 185,
		"eventPercent": 0.780029,
		"totalScheduleItems": 2896,
		"schedulePercent": 0.841213,
		"totalGuests": 534,
		"guestPercent": 0.704278,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Finsbury Park",
		"mostGuestCity": "London",
		"mostGuestAmount": 60
	},
	{
		"id": "f16a7c7",
		"email": "contact@abrahamrecio.com",
		"name": "Abraham",
		"createdDate": "2023-08-03 14:18:00",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 23,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 150,
		"schedulePercent": 0.120117,
		"totalGuests": 90,
		"guestPercent": 0.463912,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Echoplex",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 50
	},
	{
		"id": "c619deb",
		"email": "pedhannan@gmail.com",
		"name": "Patrick",
		"createdDate": "2017-05-31 16:46:37",
		"totalOrganizations": 9,
		"totalTours": 13,
		"totalDays": 661,
		"totalCountries": 22,
		"totalStates": 88,
		"totalEvents": 303,
		"eventPercent": 0.916832,
		"totalScheduleItems": 5523,
		"schedulePercent": 0.973646,
		"totalGuests": 1147,
		"guestPercent": 0.848894,
		"mostGuestDate": "2023-04-26 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 81
	},
	{
		"id": "cc601e4",
		"email": "gabriel@frontxrow.com",
		"name": "Gabriel",
		"createdDate": "2023-04-01 22:52:41",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 45,
		"totalCountries": 6,
		"totalStates": 14,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 601,
		"schedulePercent": 0.341544,
		"totalGuests": 116,
		"guestPercent": 0.488147,
		"mostGuestDate": "2023-04-25 00:00:00",
		"mostGuestName": "Cassiopeia",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 24
	},
	{
		"id": "1c148bb",
		"email": "alisson@nappyboyent.com",
		"name": "Alisson",
		"createdDate": "2021-07-19 22:41:32",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 38,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 116,
		"schedulePercent": 0.100384,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7874dbb",
		"email": "andreas@backstagegroup.se",
		"name": "Andreas",
		"createdDate": "2022-05-13 09:12:27",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 644,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 1192,
		"schedulePercent": 0.538339,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "99822e5",
		"email": "dsoproductions@gmail.com",
		"name": "Daryl",
		"createdDate": "2018-10-05 14:44:36",
		"totalOrganizations": 3,
		"totalTours": 37,
		"totalDays": 76,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 447,
		"schedulePercent": 0.275328,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "876021d",
		"email": "colinlorne252@gmail.com",
		"name": "Colin",
		"createdDate": "2016-01-15 19:17:26",
		"totalOrganizations": 23,
		"totalTours": 16,
		"totalDays": 897,
		"totalCountries": 7,
		"totalStates": 57,
		"totalEvents": 264,
		"eventPercent": 0.889419,
		"totalScheduleItems": 4481,
		"schedulePercent": 0.944511,
		"totalGuests": 2080,
		"guestPercent": 0.938684,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 112
	},
	{
		"id": "d688df2",
		"email": "rich.tinangel@gmail.com",
		"name": "Richard ",
		"createdDate": "2020-03-10 11:54:33",
		"totalOrganizations": 1,
		"totalTours": 11,
		"totalDays": 256,
		"totalCountries": 12,
		"totalStates": 24,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 514,
		"schedulePercent": 0.30592,
		"totalGuests": 106,
		"guestPercent": 0.480466,
		"mostGuestDate": "2023-02-06 00:00:00",
		"mostGuestName": "MOTH Club",
		"mostGuestCity": "London",
		"mostGuestAmount": 29
	},
	{
		"id": "ef76f9e",
		"email": "jayminer97@gmail.com",
		"name": "Jay",
		"createdDate": "2022-06-21 16:00:18",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 143,
		"totalCountries": 9,
		"totalStates": 37,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 772,
		"schedulePercent": 0.409482,
		"totalGuests": 199,
		"guestPercent": 0.549729,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "White Oak Music Hall",
		"mostGuestCity": "Houston",
		"mostGuestAmount": 34
	},
	{
		"id": "b2f4862",
		"email": "zoltan@doomstarbookings.com",
		"name": "Zoltan",
		"createdDate": "2019-02-19 09:53:29",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 59,
		"totalCountries": 5,
		"totalStates": 11,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 533,
		"schedulePercent": 0.314395,
		"totalGuests": 183,
		"guestPercent": 0.538737,
		"mostGuestDate": "2023-09-29 00:00:00",
		"mostGuestName": "BAM",
		"mostGuestCity": "Metz",
		"mostGuestAmount": 18
	},
	{
		"id": "45622ac",
		"email": "keith@thisisboombox.com",
		"name": "Keith",
		"createdDate": "2023-01-13 21:38:32",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 142,
		"totalCountries": 3,
		"totalStates": 36,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 496,
		"schedulePercent": 0.296385,
		"totalGuests": 124,
		"guestPercent": 0.493445,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 87
	},
	{
		"id": "dd53235",
		"email": "MLivingston@sjcfl.us",
		"name": "M",
		"createdDate": "2022-08-03 15:47:25",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 228,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 226,
		"eventPercent": 0.843464,
		"totalScheduleItems": 2311,
		"schedulePercent": 0.766521,
		"totalGuests": 47,
		"guestPercent": 0.417031,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "St. Augustine Amphitheatre",
		"mostGuestCity": "St. Augustine",
		"mostGuestAmount": 18
	},
	{
		"id": "589557b",
		"email": "hamish.a.dickinson@gmail.com",
		"name": "Hamish",
		"createdDate": "2019-09-23 12:20:06",
		"totalOrganizations": 14,
		"totalTours": 17,
		"totalDays": 851,
		"totalCountries": 17,
		"totalStates": 77,
		"totalEvents": 219,
		"eventPercent": 0.836048,
		"totalScheduleItems": 3311,
		"schedulePercent": 0.878824,
		"totalGuests": 1872,
		"guestPercent": 0.926367,
		"mostGuestDate": "2023-08-29 00:00:00",
		"mostGuestName": "Riverside Municipal Auditorium",
		"mostGuestCity": "Riverside",
		"mostGuestAmount": 64
	},
	{
		"id": "6631760",
		"email": "kelly.kiwha@rnzb.org.nz",
		"name": "Kelly",
		"createdDate": "2022-08-07 01:28:11",
		"totalOrganizations": 1,
		"totalTours": 8,
		"totalDays": 226,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 1999,
		"schedulePercent": 0.718315,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "48f745b",
		"email": "pwang@rangemp.com",
		"name": "Phoebe",
		"createdDate": "2022-10-17 02:04:52",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 153,
		"totalCountries": 8,
		"totalStates": 41,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1530,
		"schedulePercent": 0.625083,
		"totalGuests": 254,
		"guestPercent": 0.581248,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Acrisure Arena",
		"mostGuestCity": "Palm Desert",
		"mostGuestAmount": 77
	},
	{
		"id": "ea18df5",
		"email": "oostinelli@riverdance.com",
		"name": "Oran ",
		"createdDate": "2022-01-31 11:46:10",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 332,
		"totalCountries": 4,
		"totalStates": 37,
		"totalEvents": 275,
		"eventPercent": 0.897629,
		"totalScheduleItems": 2835,
		"schedulePercent": 0.836181,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "64f41e0",
		"email": "ryanmsteve@gmail.com",
		"name": "Steve",
		"createdDate": "2022-06-02 20:21:55",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 180,
		"totalCountries": 7,
		"totalStates": 36,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 862,
		"schedulePercent": 0.440604,
		"totalGuests": 347,
		"guestPercent": 0.627467,
		"mostGuestDate": "2023-02-24 00:00:00",
		"mostGuestName": "The Danforth Music Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 70
	},
	{
		"id": "b025757",
		"email": "maria@christopher.dk",
		"name": "Maria",
		"createdDate": "2018-01-10 10:58:04",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 75,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 548,
		"schedulePercent": 0.319958,
		"totalGuests": 201,
		"guestPercent": 0.55198,
		"mostGuestDate": "2023-12-06 00:00:00",
		"mostGuestName": "Musikhuset Aarhus",
		"mostGuestCity": "Aarhus",
		"mostGuestAmount": 17
	},
	{
		"id": "88a626c",
		"email": "413musicent@gmail.com",
		"name": "Melodie",
		"createdDate": "2016-03-10 17:00:00",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 495,
		"totalCountries": 5,
		"totalStates": 28,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1400,
		"schedulePercent": 0.59118,
		"totalGuests": 420,
		"guestPercent": 0.661237,
		"mostGuestDate": "2023-05-04 00:00:00",
		"mostGuestName": "Hope Church Memphis",
		"mostGuestCity": "Cordova",
		"mostGuestAmount": 41
	},
	{
		"id": "8e422a5",
		"email": "gezoeter@gmail.com",
		"name": "Shireen",
		"createdDate": "2019-03-20 20:41:34",
		"totalOrganizations": 3,
		"totalTours": 10,
		"totalDays": 706,
		"totalCountries": 14,
		"totalStates": 61,
		"totalEvents": 210,
		"eventPercent": 0.823732,
		"totalScheduleItems": 1657,
		"schedulePercent": 0.654748,
		"totalGuests": 543,
		"guestPercent": 0.706926,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Union Transfer",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 36
	},
	{
		"id": "6133c97",
		"email": "jscholl@bespoketheatricals.com",
		"name": "Justin",
		"createdDate": "2023-03-19 15:52:36",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 233,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 1572,
		"schedulePercent": 0.635015,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "68004e6",
		"email": "lukegerard_webb@yahoo.com.au",
		"name": "Luke",
		"createdDate": "2015-04-20 15:02:50",
		"totalOrganizations": 31,
		"totalTours": 32,
		"totalDays": 812,
		"totalCountries": 17,
		"totalStates": 63,
		"totalEvents": 238,
		"eventPercent": 0.860019,
		"totalScheduleItems": 2725,
		"schedulePercent": 0.82254,
		"totalGuests": 129,
		"guestPercent": 0.497815,
		"mostGuestDate": "2023-09-27 00:00:00",
		"mostGuestName": "Lafayette",
		"mostGuestCity": "London",
		"mostGuestAmount": 50
	},
	{
		"id": "87f1cdc",
		"email": "iteran@cityparksfoundation.org",
		"name": "Isabella",
		"createdDate": "2019-05-16 16:58:35",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ea62207",
		"email": "Jake@808agency.com.au",
		"name": "Jake",
		"createdDate": "2023-05-06 02:12:44",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 60,
		"totalCountries": 10,
		"totalStates": 18,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 262,
		"schedulePercent": 0.186465,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "46b6d90",
		"email": "joern.gierschke@livenation-production.de",
		"name": "J&#246;rn",
		"createdDate": "2022-05-30 14:54:19",
		"totalOrganizations": 7,
		"totalTours": 4,
		"totalDays": 272,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 122,
		"schedulePercent": 0.104092,
		"totalGuests": 206,
		"guestPercent": 0.555291,
		"mostGuestDate": "2023-01-12 00:00:00",
		"mostGuestName": "Metropol",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 109
	},
	{
		"id": "2f54be4",
		"email": "evanjlow@gmail.com",
		"name": "Evan",
		"createdDate": "2015-09-09 16:41:46",
		"totalOrganizations": 15,
		"totalTours": 16,
		"totalDays": 380,
		"totalCountries": 9,
		"totalStates": 44,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 2171,
		"schedulePercent": 0.745597,
		"totalGuests": 36,
		"guestPercent": 0.397961,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Austin Psych Fest",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 18
	},
	{
		"id": "e9fa1e1",
		"email": "gilmour@mfmgt.com",
		"name": "Darrell",
		"createdDate": "2010-02-05 20:24:31",
		"totalOrganizations": 6,
		"totalTours": 19,
		"totalDays": 184,
		"totalCountries": 18,
		"totalStates": 47,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 1232,
		"schedulePercent": 0.547874,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-04-27 00:00:00",
		"mostGuestName": "Dr. Phillips Center for the Performing Arts",
		"mostGuestCity": "Orlando",
		"mostGuestAmount": 2
	},
	{
		"id": "175637e",
		"email": "contact@restlessroad.com",
		"name": "Restless",
		"createdDate": "2021-05-06 18:13:06",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 1117,
		"totalCountries": 8,
		"totalStates": 52,
		"totalEvents": 213,
		"eventPercent": 0.827837,
		"totalScheduleItems": 3609,
		"schedulePercent": 0.901338,
		"totalGuests": 531,
		"guestPercent": 0.702821,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "Thompson Boling Arena",
		"mostGuestCity": "Knoxville",
		"mostGuestAmount": 47
	},
	{
		"id": "5b104cd",
		"email": "rick@polarisaus.com",
		"name": "Rick",
		"createdDate": "2018-10-17 12:06:30",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 92,
		"totalCountries": 9,
		"totalStates": 37,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 989,
		"schedulePercent": 0.479804,
		"totalGuests": 93,
		"guestPercent": 0.468017,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Hordern Pavilion",
		"mostGuestCity": "Moore Park",
		"mostGuestAmount": 31
	},
	{
		"id": "9dfaacf",
		"email": "autumna972@gmail.com",
		"name": "Autumn",
		"createdDate": "2019-04-06 19:21:30",
		"totalOrganizations": 7,
		"totalTours": 12,
		"totalDays": 208,
		"totalCountries": 15,
		"totalStates": 52,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1545,
		"schedulePercent": 0.628658,
		"totalGuests": 471,
		"guestPercent": 0.681632,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Orpheum Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 55
	},
	{
		"id": "c3328fe",
		"email": "lauraehimmel@gmail.com",
		"name": "Laura",
		"createdDate": "2022-02-03 03:18:34",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 28,
		"totalCountries": 6,
		"totalStates": 8,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 266,
		"schedulePercent": 0.190041,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4e34483",
		"email": "brianwilsonct@gmail.com",
		"name": "Brian",
		"createdDate": "2012-05-25 04:50:57",
		"totalOrganizations": 10,
		"totalTours": 10,
		"totalDays": 1321,
		"totalCountries": 16,
		"totalStates": 71,
		"totalEvents": 268,
		"eventPercent": 0.893127,
		"totalScheduleItems": 2549,
		"schedulePercent": 0.799099,
		"totalGuests": 546,
		"guestPercent": 0.707853,
		"mostGuestDate": "2023-06-07 00:00:00",
		"mostGuestName": "Verti Music Hall",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 61
	},
	{
		"id": "76da9c0",
		"email": "loic@highwayholidays.eu",
		"name": "LoÃ¯c",
		"createdDate": "2023-04-21 16:18:06",
		"totalOrganizations": 5,
		"totalTours": 15,
		"totalDays": 474,
		"totalCountries": 18,
		"totalStates": 71,
		"totalEvents": 209,
		"eventPercent": 0.82201,
		"totalScheduleItems": 2005,
		"schedulePercent": 0.720037,
		"totalGuests": 504,
		"guestPercent": 0.693021,
		"mostGuestDate": "2023-04-27 00:00:00",
		"mostGuestName": "PICA (Port Melbourne Industrial Centre for the Arts)",
		"mostGuestCity": "Port Melbourne",
		"mostGuestAmount": 34
	},
	{
		"id": "7c51451",
		"email": "jennrussell@me.com",
		"name": "Jennifer",
		"createdDate": "2019-04-15 11:03:44",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 165,
		"totalCountries": 9,
		"totalStates": 21,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 1075,
		"schedulePercent": 0.505761,
		"totalGuests": 735,
		"guestPercent": 0.760429,
		"mostGuestDate": "2023-11-20 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 119
	},
	{
		"id": "7e3b7a3",
		"email": "isabelle.lewis@attentionmgmt.com",
		"name": "Isabelle ",
		"createdDate": "2023-05-02 10:45:02",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 242,
		"totalCountries": 10,
		"totalStates": 18,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 692,
		"schedulePercent": 0.377698,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "603961d",
		"email": "Amber@nonstoptix.com",
		"name": "Amber",
		"createdDate": "2022-07-28 23:20:52",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 170,
		"totalCountries": 4,
		"totalStates": 14,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 325,
		"schedulePercent": 0.222222,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a2b5d85",
		"email": "samgrismanproject@gmail.com",
		"name": "Rachel",
		"createdDate": "2023-07-10 01:52:11",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 103,
		"totalCountries": 1,
		"totalStates": 18,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 131,
		"schedulePercent": 0.10833,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "The Caverns",
		"mostGuestCity": "Pelham",
		"mostGuestAmount": 4
	},
	{
		"id": "efef893",
		"email": "damonvonn@mac.com",
		"name": "Damon",
		"createdDate": "2013-01-28 07:05:05",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 1117,
		"totalCountries": 3,
		"totalStates": 21,
		"totalEvents": 154,
		"eventPercent": 0.71368,
		"totalScheduleItems": 2020,
		"schedulePercent": 0.72348,
		"totalGuests": 95,
		"guestPercent": 0.470269,
		"mostGuestDate": "2023-08-09 00:00:00",
		"mostGuestName": "Denver Botanic Gardens Amphitheatre",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 29
	},
	{
		"id": "f92254b",
		"email": "cirquetourb@gmail.com",
		"name": "Stephen",
		"createdDate": "2023-09-26 00:16:09",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 102,
		"totalCountries": 2,
		"totalStates": 36,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 781,
		"schedulePercent": 0.413058,
		"totalGuests": 110,
		"guestPercent": 0.482453,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "Avenir Centre",
		"mostGuestCity": "Moncton",
		"mostGuestAmount": 20
	},
	{
		"id": "04982c6",
		"email": "simone@rockopera.it",
		"name": "Simone",
		"createdDate": "2016-08-02 23:34:27",
		"totalOrganizations": 9,
		"totalTours": 1,
		"totalDays": 60,
		"totalCountries": 1,
		"totalStates": 28,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 299,
		"schedulePercent": 0.207787,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "74c2709",
		"email": "carajanderson@me.com",
		"name": "Cara",
		"createdDate": "2013-07-04 00:16:25",
		"totalOrganizations": 14,
		"totalTours": 11,
		"totalDays": 903,
		"totalCountries": 21,
		"totalStates": 60,
		"totalEvents": 412,
		"eventPercent": 0.960535,
		"totalScheduleItems": 5729,
		"schedulePercent": 0.976824,
		"totalGuests": 101,
		"guestPercent": 0.474904,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 29
	},
	{
		"id": "6defac0",
		"email": "chris@csdproductions.co.uk",
		"name": "Chris",
		"createdDate": "2022-05-15 12:40:41",
		"totalOrganizations": 13,
		"totalTours": 14,
		"totalDays": 528,
		"totalCountries": 20,
		"totalStates": 38,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1343,
		"schedulePercent": 0.578599,
		"totalGuests": 82,
		"guestPercent": 0.457688,
		"mostGuestDate": "2023-08-27 00:00:00",
		"mostGuestName": "Carfest South",
		"mostGuestCity": "Hampshire",
		"mostGuestAmount": 24
	},
	{
		"id": "735c2d1",
		"email": "chris@earstoyou.co.uk",
		"name": "Chris",
		"createdDate": "2018-03-30 13:36:52",
		"totalOrganizations": 7,
		"totalTours": 18,
		"totalDays": 984,
		"totalCountries": 18,
		"totalStates": 67,
		"totalEvents": 291,
		"eventPercent": 0.909548,
		"totalScheduleItems": 4575,
		"schedulePercent": 0.947954,
		"totalGuests": 493,
		"guestPercent": 0.689313,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 81
	},
	{
		"id": "b6edcbb",
		"email": "evpdonohue@gmail.com",
		"name": "Evan ",
		"createdDate": "2014-04-04 20:48:06",
		"totalOrganizations": 11,
		"totalTours": 7,
		"totalDays": 830,
		"totalCountries": 4,
		"totalStates": 55,
		"totalEvents": 190,
		"eventPercent": 0.788505,
		"totalScheduleItems": 2853,
		"schedulePercent": 0.8383,
		"totalGuests": 1257,
		"guestPercent": 0.866243,
		"mostGuestDate": "2023-01-12 00:00:00",
		"mostGuestName": "Riverside Revival Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 149
	},
	{
		"id": "c6d0873",
		"email": "ryan.resch@me.com",
		"name": "Ryan",
		"createdDate": "2016-09-22 02:37:49",
		"totalOrganizations": 11,
		"totalTours": 10,
		"totalDays": 1196,
		"totalCountries": 6,
		"totalStates": 27,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 1534,
		"schedulePercent": 0.626407,
		"totalGuests": 844,
		"guestPercent": 0.786651,
		"mostGuestDate": "2023-11-05 00:00:00",
		"mostGuestName": "SoFi Stadium",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 652
	},
	{
		"id": "be45e10",
		"email": "chris@fussyintl.com",
		"name": "Chris",
		"createdDate": "2012-03-30 18:09:01",
		"totalOrganizations": 2,
		"totalTours": 7,
		"totalDays": 94,
		"totalCountries": 9,
		"totalStates": 25,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 424,
		"schedulePercent": 0.265528,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "af09622",
		"email": "kent.gaudern@gmail.com",
		"name": "Kent",
		"createdDate": "2015-05-20 16:27:42",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 117,
		"totalCountries": 13,
		"totalStates": 44,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 721,
		"schedulePercent": 0.38869,
		"totalGuests": 135,
		"guestPercent": 0.50245,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Whitewater Amphitheater",
		"mostGuestCity": "New Braunfels",
		"mostGuestAmount": 28
	},
	{
		"id": "b6af4d3",
		"email": "mariegallop@yahoo.co.uk",
		"name": "Marie",
		"createdDate": "2022-08-25 08:17:42",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 121,
		"totalCountries": 8,
		"totalStates": 31,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 652,
		"schedulePercent": 0.362998,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "16aa7ac",
		"email": "sebastian@powerlineent.com",
		"name": "Sebastian",
		"createdDate": "2019-05-29 13:52:12",
		"totalOrganizations": 3,
		"totalTours": 13,
		"totalDays": 462,
		"totalCountries": 17,
		"totalStates": 76,
		"totalEvents": 293,
		"eventPercent": 0.911005,
		"totalScheduleItems": 3495,
		"schedulePercent": 0.893656,
		"totalGuests": 1480,
		"guestPercent": 0.89167,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Royal Festival Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 35
	},
	{
		"id": "e00a69b",
		"email": "tomclarktouring@gmail.com",
		"name": "Tom",
		"createdDate": "2022-10-31 17:09:38",
		"totalOrganizations": 9,
		"totalTours": 12,
		"totalDays": 409,
		"totalCountries": 14,
		"totalStates": 27,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 2060,
		"schedulePercent": 0.728645,
		"totalGuests": 553,
		"guestPercent": 0.710899,
		"mostGuestDate": "2023-04-20 00:00:00",
		"mostGuestName": "O2 Academy Bristol",
		"mostGuestCity": "Bristol",
		"mostGuestAmount": 54
	},
	{
		"id": "9d74992",
		"email": "andrea.falcher@comcast.net",
		"name": "Andrea",
		"createdDate": "2022-08-11 14:07:40",
		"totalOrganizations": 2,
		"totalTours": 14,
		"totalDays": 470,
		"totalCountries": 1,
		"totalStates": 15,
		"totalEvents": 1,
		"eventPercent": 0.00662164,
		"totalScheduleItems": 369,
		"schedulePercent": 0.242749,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9368e51",
		"email": "Johnnyp@johnproctor.co",
		"name": "John",
		"createdDate": "2022-05-12 12:32:38",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 34,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 132,
		"schedulePercent": 0.108992,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "46139cc",
		"email": "joshuavsmith@yahoo.com",
		"name": "Joshua",
		"createdDate": "2012-05-13 21:14:08",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 427,
		"totalCountries": 3,
		"totalStates": 36,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1165,
		"schedulePercent": 0.530526,
		"totalGuests": 127,
		"guestPercent": 0.497153,
		"mostGuestDate": "2023-03-09 00:00:00",
		"mostGuestName": "Globe Life Field",
		"mostGuestCity": "Arlington",
		"mostGuestAmount": 19
	},
	{
		"id": "a7881c9",
		"email": "scrow9000@mac.com",
		"name": "Sheryl",
		"createdDate": "2019-09-04 11:46:15",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 146,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 1353,
		"schedulePercent": 0.581248,
		"totalGuests": 111,
		"guestPercent": 0.483115,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "Fantasy Springs Casino",
		"mostGuestCity": "Indio",
		"mostGuestAmount": 36
	},
	{
		"id": "374accd",
		"email": "sierrahulltravel@gmail.com",
		"name": "Sierra",
		"createdDate": "2021-10-05 14:54:04",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 1076,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 1017,
		"schedulePercent": 0.490266,
		"totalGuests": 111,
		"guestPercent": 0.483115,
		"mostGuestDate": "2023-06-22 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 14
	},
	{
		"id": "ac644e8",
		"email": "jeremycaglayan@outlook.com",
		"name": "Jeremy",
		"createdDate": "2023-04-18 14:28:20",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 310,
		"totalCountries": 8,
		"totalStates": 45,
		"totalEvents": 256,
		"eventPercent": 0.882135,
		"totalScheduleItems": 1462,
		"schedulePercent": 0.606277,
		"totalGuests": 361,
		"guestPercent": 0.635413,
		"mostGuestDate": "2023-04-27 00:00:00",
		"mostGuestName": "Brooklyn Made",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 36
	},
	{
		"id": "2b788b7",
		"email": "emma@libraryproductions.com",
		"name": "Emma",
		"createdDate": "2021-07-12 13:59:06",
		"totalOrganizations": 2,
		"totalTours": 17,
		"totalDays": 53,
		"totalCountries": 5,
		"totalStates": 6,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 311,
		"schedulePercent": 0.214144,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "17490c2",
		"email": "jake@triple8mgmt.com",
		"name": "Jake",
		"createdDate": "2021-04-12 14:09:01",
		"totalOrganizations": 7,
		"totalTours": 14,
		"totalDays": 961,
		"totalCountries": 5,
		"totalStates": 45,
		"totalEvents": 306,
		"eventPercent": 0.918951,
		"totalScheduleItems": 3527,
		"schedulePercent": 0.89604,
		"totalGuests": 1678,
		"guestPercent": 0.910475,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Billy Bob's",
		"mostGuestCity": "Ft. Worth",
		"mostGuestAmount": 65
	},
	{
		"id": "93a3b79",
		"email": "vincent@secondsonproductions.com",
		"name": "Vincent",
		"createdDate": "2012-03-02 22:35:58",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 66,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 245,
		"schedulePercent": 0.179049,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6ff4086",
		"email": "Stuartarnold@mac.com",
		"name": "Stuart",
		"createdDate": "2013-03-03 12:06:38",
		"totalOrganizations": 12,
		"totalTours": 13,
		"totalDays": 517,
		"totalCountries": 12,
		"totalStates": 27,
		"totalEvents": 275,
		"eventPercent": 0.897629,
		"totalScheduleItems": 2552,
		"schedulePercent": 0.799497,
		"totalGuests": 163,
		"guestPercent": 0.525493,
		"mostGuestDate": "2023-03-27 00:00:00",
		"mostGuestName": "Lafayette",
		"mostGuestCity": "London",
		"mostGuestAmount": 41
	},
	{
		"id": "47f9964",
		"email": "info@ltrlagency.com",
		"name": "Sophia",
		"createdDate": "2023-03-19 15:14:32",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 70,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 401,
		"schedulePercent": 0.25692,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5272663",
		"email": "whoamiweekly@gmail.com",
		"name": "Clarence",
		"createdDate": "2019-09-10 20:33:45",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 33,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 291,
		"schedulePercent": 0.203682,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b3caa30",
		"email": "geoff.donkin@me.com",
		"name": "Geoff",
		"createdDate": "2010-01-25 05:19:59",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 730,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 220,
		"eventPercent": 0.83671,
		"totalScheduleItems": 2557,
		"schedulePercent": 0.800291,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "99f7fbb",
		"email": "teresa@lovepolice.com.au",
		"name": "Teresa",
		"createdDate": "2022-05-12 03:08:45",
		"totalOrganizations": 20,
		"totalTours": 17,
		"totalDays": 273,
		"totalCountries": 4,
		"totalStates": 10,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 1185,
		"schedulePercent": 0.535955,
		"totalGuests": 297,
		"guestPercent": 0.600848,
		"mostGuestDate": "2023-10-24 00:00:00",
		"mostGuestName": "Northcote Theatre",
		"mostGuestCity": "Northcote",
		"mostGuestAmount": 47
	},
	{
		"id": "9a91661",
		"email": "buffy@morrishigham.com",
		"name": "BUFFY",
		"createdDate": "2015-10-21 22:41:13",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 1093,
		"totalCountries": 3,
		"totalStates": 48,
		"totalEvents": 244,
		"eventPercent": 0.865713,
		"totalScheduleItems": 4826,
		"schedulePercent": 0.956297,
		"totalGuests": 3343,
		"guestPercent": 0.974705,
		"mostGuestDate": "2023-05-28 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 121
	},
	{
		"id": "c2d7b07",
		"email": "julia@makewake.net",
		"name": "Julia",
		"createdDate": "2023-05-20 11:32:04",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 1018,
		"totalCountries": 3,
		"totalStates": 53,
		"totalEvents": 237,
		"eventPercent": 0.858429,
		"totalScheduleItems": 2675,
		"schedulePercent": 0.816978,
		"totalGuests": 157,
		"guestPercent": 0.520196,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "House of Blues Orlando",
		"mostGuestCity": "Lake Buena Vista",
		"mostGuestAmount": 16
	},
	{
		"id": "031f85c",
		"email": "ryanpham@85southshow.com",
		"name": "Ryan",
		"createdDate": "2021-06-30 04:34:37",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 1382,
		"totalCountries": 1,
		"totalStates": 25,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 2319,
		"schedulePercent": 0.768243,
		"totalGuests": 485,
		"guestPercent": 0.685207,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Macon Centreplex- Coliseum",
		"mostGuestCity": "Macon",
		"mostGuestAmount": 51
	},
	{
		"id": "27bcc2d",
		"email": "iandigance@me.com",
		"name": "Ian",
		"createdDate": "2013-11-28 06:02:41",
		"totalOrganizations": 20,
		"totalTours": 15,
		"totalDays": 555,
		"totalCountries": 16,
		"totalStates": 31,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 1124,
		"schedulePercent": 0.517282,
		"totalGuests": 734,
		"guestPercent": 0.760297,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Marvel Stadium",
		"mostGuestCity": "Docklands",
		"mostGuestAmount": 58
	},
	{
		"id": "8f203dd",
		"email": "KIM@MYGHOSTTECH.COM",
		"name": "KIM",
		"createdDate": "2021-07-19 19:49:43",
		"totalOrganizations": 6,
		"totalTours": 17,
		"totalDays": 808,
		"totalCountries": 9,
		"totalStates": 52,
		"totalEvents": 223,
		"eventPercent": 0.840154,
		"totalScheduleItems": 3774,
		"schedulePercent": 0.910608,
		"totalGuests": 1343,
		"guestPercent": 0.876573,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 185
	},
	{
		"id": "413b608",
		"email": "dgaviria@loudlive.com",
		"name": "Damian",
		"createdDate": "2021-03-22 20:18:10",
		"totalOrganizations": 1,
		"totalTours": 24,
		"totalDays": 1021,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 213,
		"eventPercent": 0.827837,
		"totalScheduleItems": 1018,
		"schedulePercent": 0.490531,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9766b5c",
		"email": "theo@unit-production.com",
		"name": "ThÃ©o",
		"createdDate": "2018-10-01 14:37:25",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 115,
		"totalCountries": 14,
		"totalStates": 20,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 688,
		"schedulePercent": 0.375579,
		"totalGuests": 241,
		"guestPercent": 0.574361,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "Philharmonie de Paris",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 30
	},
	{
		"id": "930dc12",
		"email": "Kat.Rallis@livenation.com.au",
		"name": "Kat",
		"createdDate": "2015-06-12 05:27:58",
		"totalOrganizations": 256,
		"totalTours": 157,
		"totalDays": 4100,
		"totalCountries": 19,
		"totalStates": 53,
		"totalEvents": 1156,
		"eventPercent": 0.991657,
		"totalScheduleItems": 10362,
		"schedulePercent": 0.997484,
		"totalGuests": 2035,
		"guestPercent": 0.935373,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "Palais Theatre",
		"mostGuestCity": "St Kilda",
		"mostGuestAmount": 117
	},
	{
		"id": "721b5e7",
		"email": "contact@metatone.com",
		"name": "Metatone",
		"createdDate": "2022-08-30 15:22:34",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 1792,
		"totalCountries": 10,
		"totalStates": 43,
		"totalEvents": 184,
		"eventPercent": 0.77791,
		"totalScheduleItems": 1865,
		"schedulePercent": 0.698186,
		"totalGuests": 1575,
		"guestPercent": 0.900808,
		"mostGuestDate": "2023-06-02 00:00:00",
		"mostGuestName": "Avant Gardner",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 133
	},
	{
		"id": "400686e",
		"email": "fredrik@db4u.se",
		"name": "Fredrik",
		"createdDate": "2020-01-09 09:19:00",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 257,
		"totalCountries": 3,
		"totalStates": 5,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 1528,
		"schedulePercent": 0.624421,
		"totalGuests": 1051,
		"guestPercent": 0.83287,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Gullmarsvallen",
		"mostGuestCity": "Lysekil",
		"mostGuestAmount": 159
	},
	{
		"id": "9fb8198",
		"email": "priscille@teckoff.com",
		"name": "Priscille",
		"createdDate": "2018-05-06 14:37:24",
		"totalOrganizations": 11,
		"totalTours": 9,
		"totalDays": 872,
		"totalCountries": 6,
		"totalStates": 32,
		"totalEvents": 224,
		"eventPercent": 0.841345,
		"totalScheduleItems": 2993,
		"schedulePercent": 0.850219,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "da9cf5e",
		"email": "elliotzactaylor@gmail.com",
		"name": "Elliot",
		"createdDate": "2015-02-28 13:26:16",
		"totalOrganizations": 10,
		"totalTours": 13,
		"totalDays": 282,
		"totalCountries": 14,
		"totalStates": 26,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 1317,
		"schedulePercent": 0.570388,
		"totalGuests": 97,
		"guestPercent": 0.471461,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "Church of Saint Bartholomew, Ann Street",
		"mostGuestCity": "Brighton",
		"mostGuestAmount": 29
	},
	{
		"id": "9dff7ff",
		"email": "collinreaganld@gmail.com",
		"name": "Collin",
		"createdDate": "2022-06-23 19:01:31",
		"totalOrganizations": 9,
		"totalTours": 12,
		"totalDays": 349,
		"totalCountries": 8,
		"totalStates": 55,
		"totalEvents": 235,
		"eventPercent": 0.855516,
		"totalScheduleItems": 2566,
		"schedulePercent": 0.801483,
		"totalGuests": 529,
		"guestPercent": 0.701894,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Petco Park",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 38
	},
	{
		"id": "5ee4e30",
		"email": "julien@jonbatiste.com",
		"name": "Julien",
		"createdDate": "2021-09-16 03:13:46",
		"totalOrganizations": 5,
		"totalTours": 11,
		"totalDays": 517,
		"totalCountries": 9,
		"totalStates": 32,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 1185,
		"schedulePercent": 0.535955,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e808f05",
		"email": "Gayle@qprime.com",
		"name": "Gayle",
		"createdDate": "2010-10-27 16:03:07",
		"totalOrganizations": 2,
		"totalTours": 12,
		"totalDays": 262,
		"totalCountries": 11,
		"totalStates": 49,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1489,
		"schedulePercent": 0.615415,
		"totalGuests": 2682,
		"guestPercent": 0.95974,
		"mostGuestDate": "2023-10-02 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 405
	},
	{
		"id": "615b2ee",
		"email": "juicination@gmail.com",
		"name": "Andrew",
		"createdDate": "2013-03-16 18:22:19",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 111,
		"totalCountries": 5,
		"totalStates": 11,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 972,
		"schedulePercent": 0.473447,
		"totalGuests": 179,
		"guestPercent": 0.535426,
		"mostGuestDate": "2023-03-14 00:00:00",
		"mostGuestName": "Brisbane Entertainment Centre",
		"mostGuestCity": "Boondall",
		"mostGuestAmount": 32
	},
	{
		"id": "d1e62b2",
		"email": "rune@standup.no",
		"name": "Rune",
		"createdDate": "2022-09-05 11:05:10",
		"totalOrganizations": 8,
		"totalTours": 3,
		"totalDays": 72,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 229,
		"schedulePercent": 0.169117,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "76e1d8a",
		"email": "mariahwilkins@me.com",
		"name": "Mariah",
		"createdDate": "2012-12-28 06:41:56",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 218,
		"totalCountries": 5,
		"totalStates": 10,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 248,
		"schedulePercent": 0.180771,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8902dcc",
		"email": "Amy@volaramgmt.com",
		"name": "Evolution Touring",
		"createdDate": "2022-07-28 20:32:32",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 319,
		"totalCountries": 13,
		"totalStates": 46,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 1306,
		"schedulePercent": 0.567872,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ebcd555",
		"email": "morgan@ufomusic.com",
		"name": "Morgan",
		"createdDate": "2022-03-02 16:09:52",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 48,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 428,
		"schedulePercent": 0.267117,
		"totalGuests": 364,
		"guestPercent": 0.638194,
		"mostGuestDate": "2023-06-04 00:00:00",
		"mostGuestName": "Central Park SummerStage",
		"mostGuestCity": "New York",
		"mostGuestAmount": 74
	},
	{
		"id": "e8b8ea7",
		"email": "nikkiboon@live.com",
		"name": "Nikki",
		"createdDate": "2016-01-16 02:25:32",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 783,
		"totalCountries": 6,
		"totalStates": 46,
		"totalEvents": 171,
		"eventPercent": 0.75394,
		"totalScheduleItems": 3341,
		"schedulePercent": 0.882532,
		"totalGuests": 670,
		"guestPercent": 0.744272,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 51
	},
	{
		"id": "d6f1f9c",
		"email": "simon@obliquemgt.com",
		"name": "Simon",
		"createdDate": "2015-10-24 00:37:01",
		"totalOrganizations": 14,
		"totalTours": 26,
		"totalDays": 282,
		"totalCountries": 11,
		"totalStates": 54,
		"totalEvents": 166,
		"eventPercent": 0.74308,
		"totalScheduleItems": 1382,
		"schedulePercent": 0.587339,
		"totalGuests": 385,
		"guestPercent": 0.646934,
		"mostGuestDate": "2023-06-13 00:00:00",
		"mostGuestName": "(Le) Poisson Rouge",
		"mostGuestCity": "New York",
		"mostGuestAmount": 29
	},
	{
		"id": "c796086",
		"email": "tecsworldwideinc@gmail.com",
		"name": "John",
		"createdDate": "2017-03-27 15:45:20",
		"totalOrganizations": 15,
		"totalTours": 16,
		"totalDays": 397,
		"totalCountries": 8,
		"totalStates": 20,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 1542,
		"schedulePercent": 0.628261,
		"totalGuests": 793,
		"guestPercent": 0.774997,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 108
	},
	{
		"id": "d4998fb",
		"email": "stephlynnhanson8@gmail.com",
		"name": "Stephanie",
		"createdDate": "2023-08-08 03:44:24",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 68,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 450,
		"schedulePercent": 0.27652,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "268f957",
		"email": "alison.northway@gmail.com",
		"name": "Alison ",
		"createdDate": "2022-02-27 17:09:38",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 381,
		"totalCountries": 6,
		"totalStates": 36,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1131,
		"schedulePercent": 0.519931,
		"totalGuests": 193,
		"guestPercent": 0.545093,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 29
	},
	{
		"id": "ee7833c",
		"email": "jeremiengandu@hotmail.com",
		"name": "Jeremie",
		"createdDate": "2019-05-28 17:51:32",
		"totalOrganizations": 16,
		"totalTours": 16,
		"totalDays": 658,
		"totalCountries": 9,
		"totalStates": 40,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 2052,
		"schedulePercent": 0.727851,
		"totalGuests": 1349,
		"guestPercent": 0.877367,
		"mostGuestDate": "2023-03-14 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 115
	},
	{
		"id": "5d9c0d1",
		"email": "josh@joshsobeck.com",
		"name": "Josh",
		"createdDate": "2015-03-04 23:40:12",
		"totalOrganizations": 11,
		"totalTours": 16,
		"totalDays": 375,
		"totalCountries": 10,
		"totalStates": 57,
		"totalEvents": 208,
		"eventPercent": 0.819891,
		"totalScheduleItems": 2941,
		"schedulePercent": 0.846378,
		"totalGuests": 2235,
		"guestPercent": 0.945703,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 291
	},
	{
		"id": "d34a068",
		"email": "jimboneal@me.com",
		"name": "James",
		"createdDate": "2017-06-30 02:24:59",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 577,
		"totalCountries": 3,
		"totalStates": 34,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1640,
		"schedulePercent": 0.651569,
		"totalGuests": 595,
		"guestPercent": 0.724805,
		"mostGuestDate": "2023-01-10 00:00:00",
		"mostGuestName": "The Classic Center",
		"mostGuestCity": "Athens",
		"mostGuestAmount": 68
	},
	{
		"id": "449f550",
		"email": "marcussalcido@gmail.com",
		"name": "Marcus ",
		"createdDate": "2023-03-29 23:27:50",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 236,
		"totalCountries": 10,
		"totalStates": 52,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 1694,
		"schedulePercent": 0.663621,
		"totalGuests": 453,
		"guestPercent": 0.67501,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 67
	},
	{
		"id": "53b8ace",
		"email": "rockytinder@gmail.com",
		"name": "Rocky",
		"createdDate": "2018-02-13 21:34:03",
		"totalOrganizations": 4,
		"totalTours": 21,
		"totalDays": 535,
		"totalCountries": 21,
		"totalStates": 83,
		"totalEvents": 268,
		"eventPercent": 0.893127,
		"totalScheduleItems": 3636,
		"schedulePercent": 0.903721,
		"totalGuests": 972,
		"guestPercent": 0.81764,
		"mostGuestDate": "2023-02-06 00:00:00",
		"mostGuestName": "The Vermont Hollywood",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 92
	},
	{
		"id": "c92434d",
		"email": "tywthompson@gmail.com",
		"name": "Ty",
		"createdDate": "2016-01-10 21:27:58",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 30,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 219,
		"schedulePercent": 0.16276,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "37546b0",
		"email": "brandonschneeberger@gmail.com",
		"name": "Brandon ",
		"createdDate": "2015-03-20 16:26:55",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 366,
		"totalCountries": 3,
		"totalStates": 28,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 830,
		"schedulePercent": 0.430539,
		"totalGuests": 2750,
		"guestPercent": 0.962521,
		"mostGuestDate": "2023-09-24 00:00:00",
		"mostGuestName": "PNC Music Pavilion",
		"mostGuestCity": "Charlotte",
		"mostGuestAmount": 141
	},
	{
		"id": "bbe9559",
		"email": "swin@rainmakerartists.com",
		"name": "Swin",
		"createdDate": "2018-02-07 11:32:47",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 549,
		"totalCountries": 7,
		"totalStates": 54,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 1790,
		"schedulePercent": 0.682823,
		"totalGuests": 64,
		"guestPercent": 0.439015,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "The Danforth Music Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 8
	},
	{
		"id": "5bbfdf4",
		"email": "Kemprodasst@gmail.com",
		"name": "Tomika",
		"createdDate": "2022-09-28 21:27:49",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 20,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 135,
		"schedulePercent": 0.110317,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a87495b",
		"email": "zrickun@gmail.com",
		"name": "Zak",
		"createdDate": "2018-03-29 15:27:54",
		"totalOrganizations": 8,
		"totalTours": 13,
		"totalDays": 467,
		"totalCountries": 3,
		"totalStates": 31,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 892,
		"schedulePercent": 0.450536,
		"totalGuests": 160,
		"guestPercent": 0.522845,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Barclays Center",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 81
	},
	{
		"id": "ab90ade",
		"email": "tmsoundtech@gmail.com",
		"name": "Trevor",
		"createdDate": "2019-08-06 23:44:44",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 1525,
		"totalCountries": 4,
		"totalStates": 40,
		"totalEvents": 184,
		"eventPercent": 0.77791,
		"totalScheduleItems": 4260,
		"schedulePercent": 0.935903,
		"totalGuests": 901,
		"guestPercent": 0.800159,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 63
	},
	{
		"id": "efdb923",
		"email": "info@northernmusic.co.uk",
		"name": "les",
		"createdDate": "2018-08-20 14:55:08",
		"totalOrganizations": 5,
		"totalTours": 36,
		"totalDays": 242,
		"totalCountries": 25,
		"totalStates": 72,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 1751,
		"schedulePercent": 0.674745,
		"totalGuests": 126,
		"guestPercent": 0.495564,
		"mostGuestDate": "2023-02-25 00:00:00",
		"mostGuestName": "Fryshuset",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 13
	},
	{
		"id": "f2fadcf",
		"email": "brandon@halemgmt.net",
		"name": "Brandon ",
		"createdDate": "2018-05-31 17:34:37",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 911,
		"totalCountries": 15,
		"totalStates": 61,
		"totalEvents": 276,
		"eventPercent": 0.898292,
		"totalScheduleItems": 3884,
		"schedulePercent": 0.917759,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a72fa3c",
		"email": "Henry@centraleuro.org",
		"name": "Henry",
		"createdDate": "2014-02-22 05:17:44",
		"totalOrganizations": 8,
		"totalTours": 14,
		"totalDays": 255,
		"totalCountries": 14,
		"totalStates": 32,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1491,
		"schedulePercent": 0.616077,
		"totalGuests": 239,
		"guestPercent": 0.573037,
		"mostGuestDate": "2023-07-01 00:00:00",
		"mostGuestName": "Crystal Palace Park",
		"mostGuestCity": "London",
		"mostGuestAmount": 40
	},
	{
		"id": "ce76783",
		"email": "dewey@fortyonefifteen.com",
		"name": "Dewey",
		"createdDate": "2022-10-06 16:27:49",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 55,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 421,
		"schedulePercent": 0.264468,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-03-04 00:00:00",
		"mostGuestName": "Malcom Brown Auditorium",
		"mostGuestCity": "Shelby",
		"mostGuestAmount": 2
	},
	{
		"id": "eebe277",
		"email": "robert@mogul.vision",
		"name": "Robert",
		"createdDate": "2023-06-07 09:11:29",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 305,
		"totalCountries": 6,
		"totalStates": 34,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 1535,
		"schedulePercent": 0.626937,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cc2e0c4",
		"email": "mika.gredegard@livenation.se",
		"name": "Mika",
		"createdDate": "2018-05-09 09:56:52",
		"totalOrganizations": 59,
		"totalTours": 51,
		"totalDays": 561,
		"totalCountries": 12,
		"totalStates": 37,
		"totalEvents": 436,
		"eventPercent": 0.966097,
		"totalScheduleItems": 3030,
		"schedulePercent": 0.853794,
		"totalGuests": 1212,
		"guestPercent": 0.859224,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Slottsskogsvallen",
		"mostGuestCity": "GÃ¶teborg",
		"mostGuestAmount": 72
	},
	{
		"id": "a028acf",
		"email": "patrick@solver-productions.com",
		"name": "Patrick",
		"createdDate": "2017-03-20 17:56:04",
		"totalOrganizations": 20,
		"totalTours": 19,
		"totalDays": 559,
		"totalCountries": 29,
		"totalStates": 87,
		"totalEvents": 226,
		"eventPercent": 0.843464,
		"totalScheduleItems": 3903,
		"schedulePercent": 0.919084,
		"totalGuests": 2014,
		"guestPercent": 0.934578,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 91
	},
	{
		"id": "a517814",
		"email": "tungcalbert@gmail.com",
		"name": "Calbert",
		"createdDate": "2016-07-01 00:15:43",
		"totalOrganizations": 16,
		"totalTours": 8,
		"totalDays": 441,
		"totalCountries": 18,
		"totalStates": 55,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1611,
		"schedulePercent": 0.644683,
		"totalGuests": 765,
		"guestPercent": 0.767845,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "The Dell Music Center",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 61
	},
	{
		"id": "7f6c465",
		"email": "harriettehull@gmail.com",
		"name": "Harriette",
		"createdDate": "2022-10-24 15:18:56",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 307,
		"totalCountries": 6,
		"totalStates": 18,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 207,
		"schedulePercent": 0.154417,
		"totalGuests": 32,
		"guestPercent": 0.392531,
		"mostGuestDate": "2023-04-03 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 20
	},
	{
		"id": "07fa6cf",
		"email": "jeroen.vrijhoef@gmail.com",
		"name": "Jeroen",
		"createdDate": "2018-06-26 15:43:38",
		"totalOrganizations": 47,
		"totalTours": 22,
		"totalDays": 734,
		"totalCountries": 9,
		"totalStates": 39,
		"totalEvents": 239,
		"eventPercent": 0.860681,
		"totalScheduleItems": 2782,
		"schedulePercent": 0.829956,
		"totalGuests": 1131,
		"guestPercent": 0.845583,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "HISTORY",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 58
	},
	{
		"id": "de89f51",
		"email": "neil_anderson@mac.com",
		"name": "Neil",
		"createdDate": "2022-05-28 20:13:26",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 67,
		"totalCountries": 5,
		"totalStates": 9,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 411,
		"schedulePercent": 0.261952,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ee8986d",
		"email": "ctmorrison85@me.com",
		"name": "Chris ",
		"createdDate": "2012-01-11 20:38:43",
		"totalOrganizations": 6,
		"totalTours": 17,
		"totalDays": 301,
		"totalCountries": 8,
		"totalStates": 46,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 1679,
		"schedulePercent": 0.659648,
		"totalGuests": 423,
		"guestPercent": 0.663091,
		"mostGuestDate": "2023-06-04 00:00:00",
		"mostGuestName": "AFAS Live",
		"mostGuestCity": "Amsterdam",
		"mostGuestAmount": 49
	},
	{
		"id": "002713f",
		"email": "chacka@gmail.com",
		"name": "Paolo",
		"createdDate": "2018-03-30 11:19:11",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 50,
		"totalCountries": 1,
		"totalStates": 18,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 498,
		"schedulePercent": 0.297576,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "eaf52a4",
		"email": "kaptein@me.com",
		"name": "Aleksander",
		"createdDate": "2019-06-11 21:18:45",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 35,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 369,
		"schedulePercent": 0.242749,
		"totalGuests": 186,
		"guestPercent": 0.540458,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Idyllfestivalen",
		"mostGuestCity": "KrÃ¥kerÃ¸y",
		"mostGuestAmount": 27
	},
	{
		"id": "45ad4aa",
		"email": "neil.hargreaves@aggressivemanagement.co.uk",
		"name": "Neil",
		"createdDate": "2023-01-18 15:43:15",
		"totalOrganizations": 5,
		"totalTours": 13,
		"totalDays": 238,
		"totalCountries": 9,
		"totalStates": 21,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 970,
		"schedulePercent": 0.472255,
		"totalGuests": 548,
		"guestPercent": 0.709178,
		"mostGuestDate": "2023-11-26 00:00:00",
		"mostGuestName": "EartH (Evolutionary Arts Hackney)",
		"mostGuestCity": "London",
		"mostGuestAmount": 97
	},
	{
		"id": "709d433",
		"email": "alfrun.gis@gmail.com",
		"name": "Alfrun",
		"createdDate": "2023-06-28 08:11:27",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 162,
		"totalCountries": 11,
		"totalStates": 20,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 408,
		"schedulePercent": 0.260363,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "The Croxton Bandroom",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 2
	},
	{
		"id": "1ed5f6f",
		"email": "markjeffreysmithers@gmail.com",
		"name": "Mark",
		"createdDate": "2011-01-28 02:22:12",
		"totalOrganizations": 24,
		"totalTours": 11,
		"totalDays": 247,
		"totalCountries": 5,
		"totalStates": 11,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 1126,
		"schedulePercent": 0.518607,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-01-29 00:00:00",
		"mostGuestName": "Fremantle Park",
		"mostGuestCity": "Fremantle",
		"mostGuestAmount": 9
	},
	{
		"id": "5148350",
		"email": "chase@blindambitionmgt.com",
		"name": "Chase",
		"createdDate": "2017-09-06 23:53:17",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 314,
		"totalCountries": 1,
		"totalStates": 27,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 690,
		"schedulePercent": 0.376771,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "232d0d0",
		"email": "ak@transparentarts.com",
		"name": "Alex",
		"createdDate": "2019-10-16 23:31:29",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 194,
		"totalCountries": 13,
		"totalStates": 34,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 650,
		"schedulePercent": 0.362336,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-07-02 00:00:00",
		"mostGuestName": "Hyde Park",
		"mostGuestCity": "London",
		"mostGuestAmount": 2
	},
	{
		"id": "342a879",
		"email": "momjohnberg@gmail.com",
		"name": "John",
		"createdDate": "2015-06-28 21:13:19",
		"totalOrganizations": 9,
		"totalTours": 4,
		"totalDays": 670,
		"totalCountries": 2,
		"totalStates": 35,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1883,
		"schedulePercent": 0.701629,
		"totalGuests": 653,
		"guestPercent": 0.739902,
		"mostGuestDate": "2023-09-21 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 100
	},
	{
		"id": "e6a73de",
		"email": "rahmy@me.com",
		"name": "bill",
		"createdDate": "2023-02-10 05:44:49",
		"totalOrganizations": 9,
		"totalTours": 13,
		"totalDays": 201,
		"totalCountries": 5,
		"totalStates": 22,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 999,
		"schedulePercent": 0.484836,
		"totalGuests": 41,
		"guestPercent": 0.407231,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Mana Wynwood- Sound Stadium Hall",
		"mostGuestCity": "Miami",
		"mostGuestAmount": 21
	},
	{
		"id": "8258ccd",
		"email": "Kyle.fasel@gmail.com",
		"name": "Kyle",
		"createdDate": "2015-07-23 19:20:24",
		"totalOrganizations": 8,
		"totalTours": 3,
		"totalDays": 199,
		"totalCountries": 6,
		"totalStates": 46,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 1511,
		"schedulePercent": 0.62058,
		"totalGuests": 556,
		"guestPercent": 0.712356,
		"mostGuestDate": "2023-07-23 00:00:00",
		"mostGuestName": "Yuengling Center",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 24
	},
	{
		"id": "9e831dc",
		"email": "sluettke@me.com",
		"name": "Samantha",
		"createdDate": "2013-02-15 02:36:20",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 919,
		"totalCountries": 11,
		"totalStates": 43,
		"totalEvents": 303,
		"eventPercent": 0.916832,
		"totalScheduleItems": 3499,
		"schedulePercent": 0.894054,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1488002",
		"email": "Poppsycle@earthlink.net",
		"name": "Pete",
		"createdDate": "2014-09-11 16:09:49",
		"totalOrganizations": 15,
		"totalTours": 2,
		"totalDays": 101,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 432,
		"schedulePercent": 0.269103,
		"totalGuests": 147,
		"guestPercent": 0.511323,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "The Teragram Ballroom",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 37
	},
	{
		"id": "c737b19",
		"email": "jackie@foundationsmgmt.com",
		"name": "Jackie",
		"createdDate": "2019-11-07 22:09:25",
		"totalOrganizations": 9,
		"totalTours": 22,
		"totalDays": 700,
		"totalCountries": 15,
		"totalStates": 64,
		"totalEvents": 227,
		"eventPercent": 0.844391,
		"totalScheduleItems": 3552,
		"schedulePercent": 0.897497,
		"totalGuests": 1682,
		"guestPercent": 0.910873,
		"mostGuestDate": "2023-09-05 00:00:00",
		"mostGuestName": "Singapore Indoor Stadium",
		"mostGuestCity": "Singapore",
		"mostGuestAmount": 78
	},
	{
		"id": "23fd2fc",
		"email": "jlymhq@gmail.com",
		"name": "Etjen",
		"createdDate": "2022-03-27 00:38:48",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 155,
		"totalCountries": 11,
		"totalStates": 44,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 956,
		"schedulePercent": 0.467753,
		"totalGuests": 320,
		"guestPercent": 0.614091,
		"mostGuestDate": "2023-04-24 00:00:00",
		"mostGuestName": "Paradiso",
		"mostGuestCity": "Amsterdam",
		"mostGuestAmount": 35
	},
	{
		"id": "ac75e91",
		"email": "nick@vossevents.com",
		"name": " Nick",
		"createdDate": "2018-11-12 19:08:16",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 266,
		"totalCountries": 15,
		"totalStates": 68,
		"totalEvents": 148,
		"eventPercent": 0.696332,
		"totalScheduleItems": 1668,
		"schedulePercent": 0.657131,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b22be05",
		"email": "steven@stevenmusic.com",
		"name": "Steven",
		"createdDate": "2022-06-07 12:03:49",
		"totalOrganizations": 2,
		"totalTours": 9,
		"totalDays": 155,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 359,
		"schedulePercent": 0.23732,
		"totalGuests": 41,
		"guestPercent": 0.407231,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Grog Shop",
		"mostGuestCity": "Cleveland Heights",
		"mostGuestAmount": 6
	},
	{
		"id": "6548dcc",
		"email": "nick.gomezgarcia@redlightmanagement.com",
		"name": "Nick",
		"createdDate": "2017-05-15 20:06:35",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 435,
		"totalCountries": 8,
		"totalStates": 23,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 525,
		"schedulePercent": 0.309628,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8e65018",
		"email": "c.nguyenn@gmail.com",
		"name": "Christina",
		"createdDate": "2017-11-29 06:54:06",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 114,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 568,
		"schedulePercent": 0.327374,
		"totalGuests": 217,
		"guestPercent": 0.560853,
		"mostGuestDate": "2023-07-16 00:00:00",
		"mostGuestName": "Canal Club",
		"mostGuestCity": "Richmond",
		"mostGuestAmount": 27
	},
	{
		"id": "f63dfcb",
		"email": "catlbrown@live.com",
		"name": "Cat",
		"createdDate": "2023-03-24 20:33:22",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 90,
		"totalCountries": 10,
		"totalStates": 20,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 772,
		"schedulePercent": 0.409482,
		"totalGuests": 366,
		"guestPercent": 0.639386,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 67
	},
	{
		"id": "b554b1c",
		"email": "callum.yates@advocate-av.com",
		"name": "Callum",
		"createdDate": "2021-10-15 14:04:01",
		"totalOrganizations": 12,
		"totalTours": 36,
		"totalDays": 770,
		"totalCountries": 15,
		"totalStates": 55,
		"totalEvents": 205,
		"eventPercent": 0.814859,
		"totalScheduleItems": 1925,
		"schedulePercent": 0.706926,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Victoria Warehouse",
		"mostGuestCity": "Stretford",
		"mostGuestAmount": 2
	},
	{
		"id": "bc9d0a9",
		"email": "jason@overland-creative.com",
		"name": "Jason",
		"createdDate": "2018-08-21 15:11:11",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 56,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 236,
		"schedulePercent": 0.172295,
		"totalGuests": 129,
		"guestPercent": 0.497815,
		"mostGuestDate": "2023-01-21 00:00:00",
		"mostGuestName": "Cervantes' Masterpiece Ballroom & Cervantes' Other Side",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 50
	},
	{
		"id": "c769d68",
		"email": "7Bridges2.0@gmail.com",
		"name": "Keith",
		"createdDate": "2020-02-27 15:59:35",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 144,
		"totalCountries": 1,
		"totalStates": 22,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 517,
		"schedulePercent": 0.306847,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fc5fef6",
		"email": "britt.antle@gmail.com",
		"name": "Brittany ",
		"createdDate": "2016-10-30 21:14:55",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 55,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 289,
		"schedulePercent": 0.20249,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3d6a76a",
		"email": "artists@theassemblymusic.com",
		"name": "Kate",
		"createdDate": "2022-05-05 16:04:39",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 132,
		"totalCountries": 2,
		"totalStates": 41,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 368,
		"schedulePercent": 0.24222,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Uptown Theater",
		"mostGuestCity": "KCMO",
		"mostGuestAmount": 5
	},
	{
		"id": "f7d59d7",
		"email": "masonfelps5@gmail.com",
		"name": "Mason",
		"createdDate": "2018-09-05 21:36:11",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 60,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 691,
		"schedulePercent": 0.377301,
		"totalGuests": 1656,
		"guestPercent": 0.907694,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Dickies Arena",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 433
	},
	{
		"id": "3c2b6c0",
		"email": "tlashmar@me.com",
		"name": "Tammi",
		"createdDate": "2021-09-21 20:46:46",
		"totalOrganizations": 10,
		"totalTours": 4,
		"totalDays": 104,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 149,
		"schedulePercent": 0.118925,
		"totalGuests": 30,
		"guestPercent": 0.389485,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Museum of the Bible",
		"mostGuestCity": "Washington",
		"mostGuestAmount": 10
	},
	{
		"id": "9c2bcf4",
		"email": "mackenziefoltz@gmail.com",
		"name": "MacKenzie",
		"createdDate": "2017-09-17 19:21:59",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 311,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 583,
		"schedulePercent": 0.333068,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "29c7396",
		"email": "post@sebastianekeberg.no",
		"name": "Sebastian",
		"createdDate": "2012-01-15 11:47:38",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 85,
		"totalCountries": 11,
		"totalStates": 17,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 707,
		"schedulePercent": 0.383525,
		"totalGuests": 869,
		"guestPercent": 0.791816,
		"mostGuestDate": "2023-05-07 00:00:00",
		"mostGuestName": "O2 Academy Bristol",
		"mostGuestCity": "Bristol",
		"mostGuestAmount": 132
	},
	{
		"id": "b32b2eb",
		"email": "jondunleavy007@gmail.com",
		"name": "Jon",
		"createdDate": "2011-10-06 19:10:07",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 132,
		"totalCountries": 9,
		"totalStates": 20,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 751,
		"schedulePercent": 0.402331,
		"totalGuests": 344,
		"guestPercent": 0.624685,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "Metro",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 52
	},
	{
		"id": "26c1428",
		"email": "wanderingroadie@gmail.com",
		"name": "Michael",
		"createdDate": "2022-03-23 23:10:49",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 133,
		"totalCountries": 6,
		"totalStates": 32,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 1095,
		"schedulePercent": 0.510661,
		"totalGuests": 156,
		"guestPercent": 0.518607,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "The Rave / Eagles Club",
		"mostGuestCity": "Milwaukee",
		"mostGuestAmount": 34
	},
	{
		"id": "155b81e",
		"email": "devinblakep@gmail.com",
		"name": "Devin ",
		"createdDate": "2018-01-15 16:53:19",
		"totalOrganizations": 11,
		"totalTours": 14,
		"totalDays": 1086,
		"totalCountries": 11,
		"totalStates": 57,
		"totalEvents": 316,
		"eventPercent": 0.923719,
		"totalScheduleItems": 5763,
		"schedulePercent": 0.977486,
		"totalGuests": 2395,
		"guestPercent": 0.95047,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 299
	},
	{
		"id": "d873b87",
		"email": "jordanlambert5@gmail.com",
		"name": "Jordan",
		"createdDate": "2017-05-26 17:43:06",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 146,
		"totalCountries": 2,
		"totalStates": 35,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1125,
		"schedulePercent": 0.51768,
		"totalGuests": 593,
		"guestPercent": 0.723878,
		"mostGuestDate": "2023-03-06 00:00:00",
		"mostGuestName": "Florida Strawberry Festival",
		"mostGuestCity": "Plant City",
		"mostGuestAmount": 40
	},
	{
		"id": "d1c8b88",
		"email": "danopresto@gmail.com",
		"name": "Dan",
		"createdDate": "2015-06-08 17:18:23",
		"totalOrganizations": 15,
		"totalTours": 14,
		"totalDays": 822,
		"totalCountries": 11,
		"totalStates": 61,
		"totalEvents": 271,
		"eventPercent": 0.895113,
		"totalScheduleItems": 4262,
		"schedulePercent": 0.936167,
		"totalGuests": 1210,
		"guestPercent": 0.858827,
		"mostGuestDate": "2023-10-29 00:00:00",
		"mostGuestName": "Broadmoor World Arena",
		"mostGuestCity": "Colorado Springs",
		"mostGuestAmount": 40
	},
	{
		"id": "7114963",
		"email": "fohwalker@gmail.com",
		"name": "Jeff",
		"createdDate": "2018-09-05 19:55:21",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 262,
		"totalCountries": 8,
		"totalStates": 39,
		"totalEvents": 133,
		"eventPercent": 0.656602,
		"totalScheduleItems": 1770,
		"schedulePercent": 0.67885,
		"totalGuests": 560,
		"guestPercent": 0.714475,
		"mostGuestDate": "2023-09-06 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 51
	},
	{
		"id": "a8ef756",
		"email": "regisd@mac.com",
		"name": "Regis",
		"createdDate": "2022-04-21 02:37:34",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 50,
		"totalCountries": 7,
		"totalStates": 10,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 137,
		"schedulePercent": 0.111244,
		"totalGuests": 89,
		"guestPercent": 0.462323,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "Rex Club",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 36
	},
	{
		"id": "625af8e",
		"email": "patrick.unger.licht@gmail.com",
		"name": "Patrick",
		"createdDate": "2019-03-09 22:45:34",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 170,
		"totalCountries": 19,
		"totalStates": 52,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 1245,
		"schedulePercent": 0.55092,
		"totalGuests": 1033,
		"guestPercent": 0.829824,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "Olympiahalle",
		"mostGuestCity": "MÃ¼nchen",
		"mostGuestAmount": 135
	},
	{
		"id": "a2c257e",
		"email": "runningralph@yahoo.com",
		"name": "Rodger",
		"createdDate": "2019-07-01 14:56:41",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 123,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 737,
		"schedulePercent": 0.394385,
		"totalGuests": 111,
		"guestPercent": 0.483115,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "The Rail Club",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 39
	},
	{
		"id": "e2c621c",
		"email": "jonas@lubis.dk",
		"name": "Jonas",
		"createdDate": "2013-11-02 16:19:10",
		"totalOrganizations": 19,
		"totalTours": 13,
		"totalDays": 637,
		"totalCountries": 11,
		"totalStates": 27,
		"totalEvents": 520,
		"eventPercent": 0.976559,
		"totalScheduleItems": 3727,
		"schedulePercent": 0.908356,
		"totalGuests": 877,
		"guestPercent": 0.79367,
		"mostGuestDate": "2023-03-12 00:00:00",
		"mostGuestName": "Verti Music Hall",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 63
	},
	{
		"id": "1e3f927",
		"email": "derekfudge@mac.com",
		"name": "Derek",
		"createdDate": "2013-09-21 13:18:23",
		"totalOrganizations": 12,
		"totalTours": 10,
		"totalDays": 476,
		"totalCountries": 17,
		"totalStates": 53,
		"totalEvents": 222,
		"eventPercent": 0.839227,
		"totalScheduleItems": 3078,
		"schedulePercent": 0.858959,
		"totalGuests": 1057,
		"guestPercent": 0.833797,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 58
	},
	{
		"id": "1ec863e",
		"email": "brad@redlettermanagementgroup.com",
		"name": "William",
		"createdDate": "2013-07-16 16:48:41",
		"totalOrganizations": 16,
		"totalTours": 5,
		"totalDays": 108,
		"totalCountries": 1,
		"totalStates": 28,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 1019,
		"schedulePercent": 0.490796,
		"totalGuests": 207,
		"guestPercent": 0.555953,
		"mostGuestDate": "2023-02-19 00:00:00",
		"mostGuestName": "ChristWay Community Church",
		"mostGuestCity": "Ooltewah",
		"mostGuestAmount": 26
	},
	{
		"id": "1135f5c",
		"email": "jihad@novemberyellow.com",
		"name": "Jihad",
		"createdDate": "2023-10-13 02:17:14",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 67,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 212,
		"schedulePercent": 0.157595,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "172910f",
		"email": "danielle@hairballonline.com",
		"name": "Danielle",
		"createdDate": "2019-03-18 21:00:15",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 366,
		"totalCountries": 3,
		"totalStates": 26,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 1417,
		"schedulePercent": 0.595153,
		"totalGuests": 81,
		"guestPercent": 0.455966,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Rockin Rogers - Rogers Community Center",
		"mostGuestCity": "Rogers",
		"mostGuestAmount": 26
	},
	{
		"id": "6f9458e",
		"email": "summerdeanmusic@gmail.com",
		"name": "Summer",
		"createdDate": "2021-11-30 17:38:13",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 376,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 494,
		"schedulePercent": 0.295193,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c0ead17",
		"email": "tcsassist@weareadptv.com",
		"name": "TCS",
		"createdDate": "2023-10-18 21:02:22",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 74,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 372,
		"schedulePercent": 0.244868,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "30a3e34",
		"email": "rekmgmt@robertearlkeen.com",
		"name": "Cindy",
		"createdDate": "2020-12-11 17:06:20",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 24,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 120,
		"schedulePercent": 0.102635,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-09-21 00:00:00",
		"mostGuestName": "The Birchmere",
		"mostGuestCity": "Alexandria",
		"mostGuestAmount": 7
	},
	{
		"id": "8ecd6e9",
		"email": "matthewdaly@me.com",
		"name": "Matthew",
		"createdDate": "2015-02-17 17:16:17",
		"totalOrganizations": 19,
		"totalTours": 21,
		"totalDays": 192,
		"totalCountries": 8,
		"totalStates": 39,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 1214,
		"schedulePercent": 0.543504,
		"totalGuests": 598,
		"guestPercent": 0.725599,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "The Studio at Webster Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 61
	},
	{
		"id": "70987f2",
		"email": "ellekingtouring@gmail.com",
		"name": "EK",
		"createdDate": "2017-06-21 17:37:24",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 4,
		"totalStates": 44,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 1825,
		"schedulePercent": 0.69024,
		"totalGuests": 689,
		"guestPercent": 0.750497,
		"mostGuestDate": "2023-03-22 00:00:00",
		"mostGuestName": "The Factory in Deep Ellum",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 39
	},
	{
		"id": "dfd0558",
		"email": "kaatz@infinity-staging.de",
		"name": "Andre",
		"createdDate": "2012-12-15 16:31:07",
		"totalOrganizations": 14,
		"totalTours": 6,
		"totalDays": 136,
		"totalCountries": 10,
		"totalStates": 38,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 1104,
		"schedulePercent": 0.512647,
		"totalGuests": 368,
		"guestPercent": 0.64071,
		"mostGuestDate": "2023-09-09 00:00:00",
		"mostGuestName": "Riverside Municipal Auditorium",
		"mostGuestCity": "Riverside",
		"mostGuestAmount": 33
	},
	{
		"id": "f01f788",
		"email": "juliand_712@hotmail.com",
		"name": "Julian",
		"createdDate": "2023-02-28 15:26:29",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 38,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 184,
		"schedulePercent": 0.141306,
		"totalGuests": 359,
		"guestPercent": 0.634618,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "Stadthalle Graz",
		"mostGuestCity": "Graz",
		"mostGuestAmount": 203
	},
	{
		"id": "8a792f0",
		"email": "jesshagedorn@icloud.com",
		"name": "Jess",
		"createdDate": "2018-03-08 01:25:27",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 398,
		"totalCountries": 21,
		"totalStates": 51,
		"totalEvents": 134,
		"eventPercent": 0.658721,
		"totalScheduleItems": 1613,
		"schedulePercent": 0.645213,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1d13bec",
		"email": "jarrett@ams.events",
		"name": "Jarrett",
		"createdDate": "2023-09-04 19:00:12",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 107,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 392,
		"schedulePercent": 0.254139,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ca5ca60",
		"email": "christy.fitzgerald@lifesurge.com",
		"name": "Christy",
		"createdDate": "2022-03-10 14:10:58",
		"totalOrganizations": 6,
		"totalTours": 96,
		"totalDays": 463,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 405,
		"eventPercent": 0.958284,
		"totalScheduleItems": 2935,
		"schedulePercent": 0.845848,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "PHX: Dream City Church",
		"mostGuestCity": "Phoenix",
		"mostGuestAmount": 0
	},
	{
		"id": "7f755e8",
		"email": "zackknudsenphotography@gmail.com",
		"name": "Zack",
		"createdDate": "2018-08-30 00:12:31",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 122,
		"totalCountries": 5,
		"totalStates": 35,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 950,
		"schedulePercent": 0.465369,
		"totalGuests": 54,
		"guestPercent": 0.427096,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "Billy Bob's",
		"mostGuestCity": "Ft. Worth",
		"mostGuestAmount": 18
	},
	{
		"id": "bec091a",
		"email": "jamie@vevents.org",
		"name": "Jamie",
		"createdDate": "2021-07-12 16:40:04",
		"totalOrganizations": 13,
		"totalTours": 22,
		"totalDays": 931,
		"totalCountries": 8,
		"totalStates": 19,
		"totalEvents": 171,
		"eventPercent": 0.75394,
		"totalScheduleItems": 1195,
		"schedulePercent": 0.538737,
		"totalGuests": 40,
		"guestPercent": 0.405642,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "The Brighton Centre",
		"mostGuestCity": "Brighton",
		"mostGuestAmount": 15
	},
	{
		"id": "1d1f2d1",
		"email": "zachrunquist@gmail.com",
		"name": "Zach",
		"createdDate": "2015-01-05 22:46:20",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 584,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 210,
		"schedulePercent": 0.156006,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c3b8b99",
		"email": "brian.sellei@menosquecero.es",
		"name": "Brian",
		"createdDate": "2009-08-14 21:09:53",
		"totalOrganizations": 26,
		"totalTours": 4,
		"totalDays": 391,
		"totalCountries": 11,
		"totalStates": 51,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1592,
		"schedulePercent": 0.640313,
		"totalGuests": 65,
		"guestPercent": 0.440207,
		"mostGuestDate": "2023-02-09 00:00:00",
		"mostGuestName": "TERRAZA HOTEL TAMANACO CARACAS",
		"mostGuestCity": "Caracas",
		"mostGuestAmount": 31
	},
	{
		"id": "7cf92bb",
		"email": "naterebolledo@gmail.com",
		"name": "Nathan",
		"createdDate": "2018-01-08 18:03:43",
		"totalOrganizations": 10,
		"totalTours": 10,
		"totalDays": 1782,
		"totalCountries": 15,
		"totalStates": 57,
		"totalEvents": 331,
		"eventPercent": 0.932327,
		"totalScheduleItems": 2589,
		"schedulePercent": 0.804529,
		"totalGuests": 515,
		"guestPercent": 0.697126,
		"mostGuestDate": "2023-09-14 00:00:00",
		"mostGuestName": "The Brooklyn Mirage",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 140
	},
	{
		"id": "94ab8c8",
		"email": "tish@latishacotto.com",
		"name": "Tobe",
		"createdDate": "2022-10-07 11:21:35",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 133,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 491,
		"schedulePercent": 0.293206,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3789b6a",
		"email": "gnarlos@gmail.com",
		"name": "Carlos",
		"createdDate": "2013-01-10 10:51:35",
		"totalOrganizations": 12,
		"totalTours": 7,
		"totalDays": 201,
		"totalCountries": 7,
		"totalStates": 32,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1202,
		"schedulePercent": 0.540723,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-09-22 00:00:00",
		"mostGuestName": "O2 Academy Birmingham",
		"mostGuestCity": "Birmingham",
		"mostGuestAmount": 5
	},
	{
		"id": "786e33a",
		"email": "adam_capps@ymail.com",
		"name": "Adam",
		"createdDate": "2015-09-25 21:55:14",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 258,
		"totalCountries": 4,
		"totalStates": 42,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 1695,
		"schedulePercent": 0.664018,
		"totalGuests": 629,
		"guestPercent": 0.733545,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "Dixie National Livestock Show / State Coliseum",
		"mostGuestCity": "Jackson",
		"mostGuestAmount": 31
	},
	{
		"id": "2e7dde4",
		"email": "kser0923@gmail.com",
		"name": "Kellye",
		"createdDate": "2017-09-18 00:55:39",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 185,
		"totalCountries": 4,
		"totalStates": 30,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 1279,
		"schedulePercent": 0.559926,
		"totalGuests": 1946,
		"guestPercent": 0.931665,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Gorge Amphitheatre",
		"mostGuestCity": "George",
		"mostGuestAmount": 205
	},
	{
		"id": "53ee7a7",
		"email": "carissa.stolting@icloud.com",
		"name": "Carissa",
		"createdDate": "2014-04-09 20:34:22",
		"totalOrganizations": 4,
		"totalTours": 12,
		"totalDays": 168,
		"totalCountries": 12,
		"totalStates": 31,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 677,
		"schedulePercent": 0.371342,
		"totalGuests": 42,
		"guestPercent": 0.40882,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Boettcher Concert Hall",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 23
	},
	{
		"id": "166835b",
		"email": "arturo@who-do.it",
		"name": "Arturo",
		"createdDate": "2022-04-11 13:24:30",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 919,
		"totalCountries": 1,
		"totalStates": 35,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 725,
		"schedulePercent": 0.390677,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0acbd38",
		"email": "WOFLtouring@gmail.com",
		"name": "Justin",
		"createdDate": "2022-10-31 18:35:15",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 84,
		"totalCountries": 1,
		"totalStates": 28,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 1196,
		"schedulePercent": 0.539134,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "Columbia County Performing Arts Center",
		"mostGuestCity": "Evans",
		"mostGuestAmount": 4
	},
	{
		"id": "d7c8249",
		"email": "saldana.sandra@icloud.com",
		"name": "Sandra",
		"createdDate": "2023-01-09 15:11:43",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 346,
		"totalCountries": 4,
		"totalStates": 18,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 722,
		"schedulePercent": 0.389485,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4a46cdf",
		"email": "robboproductions@gmail.com",
		"name": "Roberto",
		"createdDate": "2010-03-22 15:26:54",
		"totalOrganizations": 21,
		"totalTours": 7,
		"totalDays": 378,
		"totalCountries": 1,
		"totalStates": 33,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1399,
		"schedulePercent": 0.591048,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "PARCO DI  SAN GIULIANO MESTRE",
		"mostGuestCity": "VENEZIA",
		"mostGuestAmount": 17
	},
	{
		"id": "6da163c",
		"email": "kyle.ronan@mac.com",
		"name": "Kyle",
		"createdDate": "2011-07-24 23:53:42",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 365,
		"totalCountries": 7,
		"totalStates": 42,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 2176,
		"schedulePercent": 0.746391,
		"totalGuests": 2290,
		"guestPercent": 0.947027,
		"mostGuestDate": "2023-10-03 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 104
	},
	{
		"id": "6f4ad60",
		"email": "rbrown7861@gmail.com",
		"name": "Robert",
		"createdDate": "2015-06-25 23:49:35",
		"totalOrganizations": 18,
		"totalTours": 11,
		"totalDays": 399,
		"totalCountries": 11,
		"totalStates": 59,
		"totalEvents": 193,
		"eventPercent": 0.792743,
		"totalScheduleItems": 2742,
		"schedulePercent": 0.824791,
		"totalGuests": 691,
		"guestPercent": 0.751291,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 43
	},
	{
		"id": "028f10e",
		"email": "paul.vallette@gmail.com",
		"name": "Paul",
		"createdDate": "2014-04-22 04:53:46",
		"totalOrganizations": 14,
		"totalTours": 22,
		"totalDays": 920,
		"totalCountries": 10,
		"totalStates": 64,
		"totalEvents": 281,
		"eventPercent": 0.903059,
		"totalScheduleItems": 4069,
		"schedulePercent": 0.926103,
		"totalGuests": 1687,
		"guestPercent": 0.911402,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "The Armory",
		"mostGuestCity": "Minneapolis",
		"mostGuestAmount": 76
	},
	{
		"id": "e26072c",
		"email": "lien.delentdecker@lobbycall.com",
		"name": "Lien",
		"createdDate": "2022-01-11 09:49:15",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 313,
		"totalCountries": 16,
		"totalStates": 54,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 3879,
		"schedulePercent": 0.917097,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f02768f",
		"email": "travis@teconcertservices.com",
		"name": "Travis",
		"createdDate": "2012-02-09 07:09:17",
		"totalOrganizations": 3,
		"totalTours": 14,
		"totalDays": 116,
		"totalCountries": 1,
		"totalStates": 28,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 1036,
		"schedulePercent": 0.493709,
		"totalGuests": 142,
		"guestPercent": 0.506688,
		"mostGuestDate": "2023-07-18 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 21
	},
	{
		"id": "cffa1ae",
		"email": "zadro.eric@gmail.com",
		"name": "Eric",
		"createdDate": "2022-09-23 10:52:13",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 235,
		"totalCountries": 14,
		"totalStates": 48,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 661,
		"schedulePercent": 0.365779,
		"totalGuests": 112,
		"guestPercent": 0.484836,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Le Bikini",
		"mostGuestCity": "Ramonville-Saint-Agne",
		"mostGuestAmount": 47
	},
	{
		"id": "cffbd08",
		"email": "hello@goforerin.com",
		"name": "Erin",
		"createdDate": "2023-09-21 15:31:44",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 29,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 9,
		"eventPercent": 0.0607866,
		"totalScheduleItems": 153,
		"schedulePercent": 0.122765,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8da411f",
		"email": "rsn@ironshorerecords.ky",
		"name": "Rich",
		"createdDate": "2023-02-21 23:32:47",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 82,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 986,
		"schedulePercent": 0.47848,
		"totalGuests": 365,
		"guestPercent": 0.638591,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Abbotsford Centre",
		"mostGuestCity": "Abbotsford",
		"mostGuestAmount": 66
	},
	{
		"id": "3be4c30",
		"email": "thefindo@yahoo.com",
		"name": "James",
		"createdDate": "2013-11-09 11:15:25",
		"totalOrganizations": 13,
		"totalTours": 10,
		"totalDays": 116,
		"totalCountries": 8,
		"totalStates": 13,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 864,
		"schedulePercent": 0.440869,
		"totalGuests": 449,
		"guestPercent": 0.673553,
		"mostGuestDate": "2023-03-04 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 166
	},
	{
		"id": "81bb780",
		"email": "dana@lproof.org",
		"name": "Dana",
		"createdDate": "2021-01-27 18:01:33",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 32,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 301,
		"schedulePercent": 0.208449,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "80e092b",
		"email": "Matthewpetroff@gmail.com",
		"name": "Matt",
		"createdDate": "2014-01-28 16:18:32",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 798,
		"totalCountries": 16,
		"totalStates": 27,
		"totalEvents": 331,
		"eventPercent": 0.932327,
		"totalScheduleItems": 3899,
		"schedulePercent": 0.918289,
		"totalGuests": 6793,
		"guestPercent": 0.996689,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Wembley Stadium",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 625
	},
	{
		"id": "d13f533",
		"email": "mike@thenadas.com",
		"name": "Mike ",
		"createdDate": "2011-01-29 22:55:55",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 400,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 431,
		"schedulePercent": 0.268971,
		"totalGuests": 52,
		"guestPercent": 0.425374,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Hoyt Sherman Place",
		"mostGuestCity": "Des Moines",
		"mostGuestAmount": 43
	},
	{
		"id": "9387985",
		"email": "jameshaywardproduction@gmail.com",
		"name": "James",
		"createdDate": "2017-05-10 09:14:06",
		"totalOrganizations": 17,
		"totalTours": 13,
		"totalDays": 812,
		"totalCountries": 20,
		"totalStates": 79,
		"totalEvents": 343,
		"eventPercent": 0.938551,
		"totalScheduleItems": 5256,
		"schedulePercent": 0.969011,
		"totalGuests": 279,
		"guestPercent": 0.593961,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Don Valley Bowl",
		"mostGuestCity": "Sheffield",
		"mostGuestAmount": 108
	},
	{
		"id": "5af720d",
		"email": "craigtiede@mac.com",
		"name": "Craig",
		"createdDate": "2009-07-27 05:27:10",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 247,
		"totalCountries": 14,
		"totalStates": 40,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1206,
		"schedulePercent": 0.541518,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-05-28 00:00:00",
		"mostGuestName": "Cross The Tracks Festival",
		"mostGuestCity": "London",
		"mostGuestAmount": 9
	},
	{
		"id": "cef81e2",
		"email": "ssheldon@rm64.com",
		"name": "Scott",
		"createdDate": "2011-06-06 16:57:53",
		"totalOrganizations": 13,
		"totalTours": 13,
		"totalDays": 294,
		"totalCountries": 8,
		"totalStates": 40,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 1176,
		"schedulePercent": 0.533439,
		"totalGuests": 220,
		"guestPercent": 0.563237,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The Aztec Theatre",
		"mostGuestCity": "San Antonio",
		"mostGuestAmount": 70
	},
	{
		"id": "d9bb593",
		"email": "svenake.granit@gmail.com",
		"name": "Sven-&#197;ke",
		"createdDate": "2022-05-18 18:50:32",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 84,
		"totalCountries": 3,
		"totalStates": 18,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 190,
		"schedulePercent": 0.144617,
		"totalGuests": 303,
		"guestPercent": 0.604158,
		"mostGuestDate": "2023-12-03 00:00:00",
		"mostGuestName": "Goteborgs Konserthus Big Hall",
		"mostGuestCity": "Goteborg",
		"mostGuestAmount": 52
	},
	{
		"id": "a673409",
		"email": "nikolajlahnworking@gmail.com",
		"name": "Nikolaj",
		"createdDate": "2019-03-08 19:41:06",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 27,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 257,
		"schedulePercent": 0.184479,
		"totalGuests": 290,
		"guestPercent": 0.597934,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "BirkerÃ¸d IdrÃ¦tscenter",
		"mostGuestCity": "BirkerÃ¸d",
		"mostGuestAmount": 35
	},
	{
		"id": "be1d403",
		"email": "esherrod@cityparksfoundation.org",
		"name": "Emily",
		"createdDate": "2017-04-27 19:47:21",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 284,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e85c6a2",
		"email": "jo@bohmpresents.com",
		"name": "Jo",
		"createdDate": "2022-08-17 06:27:31",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 225,
		"totalCountries": 4,
		"totalStates": 20,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 1209,
		"schedulePercent": 0.542445,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-07-05 00:00:00",
		"mostGuestName": "The State Theatre",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 6
	},
	{
		"id": "de62f29",
		"email": "bang@liveniteevents.com",
		"name": "Ryan",
		"createdDate": "2022-12-12 19:06:24",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 1914,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 2390,
		"schedulePercent": 0.779235,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4bee6a6",
		"email": "b.beim4@gmail.com",
		"name": "Brent",
		"createdDate": "2014-06-13 19:57:23",
		"totalOrganizations": 5,
		"totalTours": 13,
		"totalDays": 723,
		"totalCountries": 6,
		"totalStates": 50,
		"totalEvents": 201,
		"eventPercent": 0.808237,
		"totalScheduleItems": 3400,
		"schedulePercent": 0.887697,
		"totalGuests": 1864,
		"guestPercent": 0.925573,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Nissan Stadium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 90
	},
	{
		"id": "f0d4438",
		"email": "salomewiedenhoff@gmail.com",
		"name": "Salome",
		"createdDate": "2017-02-11 18:49:03",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 133,
		"totalCountries": 3,
		"totalStates": 9,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 578,
		"schedulePercent": 0.331082,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "21cd9ca",
		"email": "badlandmusicgroup@gmail.com",
		"name": "Jonathan",
		"createdDate": "2016-02-22 18:09:43",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 999,
		"totalCountries": 9,
		"totalStates": 54,
		"totalEvents": 318,
		"eventPercent": 0.924778,
		"totalScheduleItems": 3899,
		"schedulePercent": 0.918289,
		"totalGuests": 792,
		"guestPercent": 0.774864,
		"mostGuestDate": "2023-03-06 00:00:00",
		"mostGuestName": "Northcote Theatre",
		"mostGuestCity": "Northcote",
		"mostGuestAmount": 71
	},
	{
		"id": "ad2aab4",
		"email": "mark@simplexitytravel.com",
		"name": "Mark",
		"createdDate": "2022-05-09 14:41:17",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 31,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 125,
		"schedulePercent": 0.106079,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d83d589",
		"email": "reedthegreat2.0@gmail.com",
		"name": "Victor ",
		"createdDate": "2021-07-27 19:41:10",
		"totalOrganizations": 4,
		"totalTours": 12,
		"totalDays": 648,
		"totalCountries": 17,
		"totalStates": 76,
		"totalEvents": 262,
		"eventPercent": 0.887962,
		"totalScheduleItems": 3611,
		"schedulePercent": 0.901735,
		"totalGuests": 1221,
		"guestPercent": 0.86121,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Royal Festival Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 35
	},
	{
		"id": "1562755",
		"email": "jprnr79@gmail.com",
		"name": "James ",
		"createdDate": "2023-07-28 21:02:15",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 34,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 181,
		"schedulePercent": 0.139054,
		"totalGuests": 38,
		"guestPercent": 0.402198,
		"mostGuestDate": "2023-09-22 00:00:00",
		"mostGuestName": "Belly Up",
		"mostGuestCity": "Solana Beach",
		"mostGuestAmount": 14
	},
	{
		"id": "0b8b77c",
		"email": "Cody.Foote@gmail.com",
		"name": "Cody",
		"createdDate": "2014-06-09 20:44:56",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 730,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 170,
		"eventPercent": 0.752086,
		"totalScheduleItems": 1559,
		"schedulePercent": 0.631837,
		"totalGuests": 1077,
		"guestPercent": 0.835916,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "St. Josephâ€™s Health Amphitheater at Lakeview",
		"mostGuestCity": "Syracuse",
		"mostGuestAmount": 46
	},
	{
		"id": "b0e805c",
		"email": "jen.kam@gmail.com",
		"name": "Jen",
		"createdDate": "2019-05-21 07:48:46",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 269,
		"totalCountries": 12,
		"totalStates": 23,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 1055,
		"schedulePercent": 0.499139,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7418160",
		"email": "office@mw-eventengineering.com",
		"name": "Markus",
		"createdDate": "2023-02-28 11:37:09",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 84,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 709,
		"schedulePercent": 0.384585,
		"totalGuests": 359,
		"guestPercent": 0.634618,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "Stadthalle Graz",
		"mostGuestCity": "Graz",
		"mostGuestAmount": 203
	},
	{
		"id": "32bdf7d",
		"email": "LindzF06@gmail.com",
		"name": "Lindsay",
		"createdDate": "2018-12-16 17:48:47",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 785,
		"totalCountries": 4,
		"totalStates": 47,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 2360,
		"schedulePercent": 0.774997,
		"totalGuests": 1436,
		"guestPercent": 0.88571,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Julian B Lane Riverfront Park",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 92
	},
	{
		"id": "86ff35f",
		"email": "wearesodown@gmail.com",
		"name": "Ehren",
		"createdDate": "2021-09-17 02:06:49",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 410,
		"totalCountries": 3,
		"totalStates": 17,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 326,
		"schedulePercent": 0.222487,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-02-04 00:00:00",
		"mostGuestName": "Pima County Fairgrounds",
		"mostGuestCity": "Tucson",
		"mostGuestAmount": 2
	},
	{
		"id": "bdcfb5e",
		"email": "nillmensee@gmail.com",
		"name": "noma ",
		"createdDate": "2020-01-21 20:18:16",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 193,
		"totalCountries": 7,
		"totalStates": 41,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 1598,
		"schedulePercent": 0.642564,
		"totalGuests": 1010,
		"guestPercent": 0.826513,
		"mostGuestDate": "2023-09-06 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 51
	},
	{
		"id": "ced432c",
		"email": "fiftyriffs@gmail.com",
		"name": "Stuart",
		"createdDate": "2019-01-04 03:34:38",
		"totalOrganizations": 2,
		"totalTours": 11,
		"totalDays": 279,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 659,
		"schedulePercent": 0.364985,
		"totalGuests": 52,
		"guestPercent": 0.425374,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "Tivoli",
		"mostGuestCity": "Brisbane",
		"mostGuestAmount": 52
	},
	{
		"id": "b59a79c",
		"email": "craniummgt@gmail.com",
		"name": "Peter",
		"createdDate": "2019-01-15 06:18:49",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 98,
		"totalCountries": 4,
		"totalStates": 10,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 450,
		"schedulePercent": 0.27652,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "50342ef",
		"email": "sw@cycleof5ths.com",
		"name": "Summer",
		"createdDate": "2022-01-08 06:59:13",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 122,
		"totalCountries": 2,
		"totalStates": 41,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 501,
		"schedulePercent": 0.298768,
		"totalGuests": 347,
		"guestPercent": 0.627467,
		"mostGuestDate": "2023-03-02 00:00:00",
		"mostGuestName": "Gramps",
		"mostGuestCity": "Miami",
		"mostGuestAmount": 38
	},
	{
		"id": "d0acbfc",
		"email": "nicholascfoerster@gmail.com",
		"name": "Nicholas ",
		"createdDate": "2018-01-26 02:56:47",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 660,
		"totalCountries": 16,
		"totalStates": 66,
		"totalEvents": 188,
		"eventPercent": 0.785856,
		"totalScheduleItems": 2383,
		"schedulePercent": 0.778307,
		"totalGuests": 75,
		"guestPercent": 0.450007,
		"mostGuestDate": "2023-07-24 00:00:00",
		"mostGuestName": "Greensboro Coliseum Complex",
		"mostGuestCity": "Greensboro",
		"mostGuestAmount": 20
	},
	{
		"id": "78a90b6",
		"email": "carla@lauriaweb.com",
		"name": "Carla",
		"createdDate": "2022-05-13 10:24:55",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 102,
		"totalCountries": 5,
		"totalStates": 29,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 889,
		"schedulePercent": 0.449344,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7060696",
		"email": "travis.epsteen@gmail.com",
		"name": "Travis ",
		"createdDate": "2015-11-05 19:44:18",
		"totalOrganizations": 6,
		"totalTours": 39,
		"totalDays": 607,
		"totalCountries": 5,
		"totalStates": 56,
		"totalEvents": 232,
		"eventPercent": 0.852073,
		"totalScheduleItems": 2183,
		"schedulePercent": 0.74798,
		"totalGuests": 173,
		"guestPercent": 0.53185,
		"mostGuestDate": "2023-01-21 00:00:00",
		"mostGuestName": "Cervantes' Masterpiece Ballroom & Cervantes' Other Side",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 50
	},
	{
		"id": "9c78765",
		"email": "Jashxcore@gmail.com",
		"name": "Joshua",
		"createdDate": "2018-01-04 21:45:01",
		"totalOrganizations": 13,
		"totalTours": 14,
		"totalDays": 271,
		"totalCountries": 7,
		"totalStates": 43,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 2930,
		"schedulePercent": 0.845583,
		"totalGuests": 1325,
		"guestPercent": 0.874719,
		"mostGuestDate": "2023-06-28 00:00:00",
		"mostGuestName": "The Complex - Rockwell",
		"mostGuestCity": "Salt Lake City",
		"mostGuestAmount": 91
	},
	{
		"id": "436d21d",
		"email": "harrymatthewford@Gmail.com",
		"name": "Harry",
		"createdDate": "2021-06-16 13:28:27",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 743,
		"totalCountries": 7,
		"totalStates": 33,
		"totalEvents": 352,
		"eventPercent": 0.942127,
		"totalScheduleItems": 4817,
		"schedulePercent": 0.955635,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Lincoln Financial Field",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 4
	},
	{
		"id": "a462e65",
		"email": "Jordan@borderlightentertainment.com",
		"name": "Jordan",
		"createdDate": "2019-01-17 05:14:27",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 37,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 255,
		"schedulePercent": 0.183287,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bc1ba69",
		"email": "dalewatsonemail@gmail.com",
		"name": "Dale",
		"createdDate": "2016-05-18 06:14:18",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 228,
		"totalCountries": 5,
		"totalStates": 34,
		"totalEvents": 202,
		"eventPercent": 0.810091,
		"totalScheduleItems": 369,
		"schedulePercent": 0.242749,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c8b0238",
		"email": "junia@lvrn.com",
		"name": "Junia",
		"createdDate": "2017-04-24 20:57:15",
		"totalOrganizations": 23,
		"totalTours": 19,
		"totalDays": 839,
		"totalCountries": 8,
		"totalStates": 39,
		"totalEvents": 225,
		"eventPercent": 0.842008,
		"totalScheduleItems": 2422,
		"schedulePercent": 0.78281,
		"totalGuests": 2416,
		"guestPercent": 0.95153,
		"mostGuestDate": "2023-11-06 00:00:00",
		"mostGuestName": "Avant Gardner",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 124
	},
	{
		"id": "777e4f9",
		"email": "kenyondixonmusic@gmail.com",
		"name": "Kenyon",
		"createdDate": "2013-09-30 05:06:30",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 491,
		"totalCountries": 3,
		"totalStates": 20,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 668,
		"schedulePercent": 0.368296,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e330d33",
		"email": "bella@mogul.vision",
		"name": "Bella",
		"createdDate": "2023-06-07 22:33:03",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 273,
		"totalCountries": 6,
		"totalStates": 32,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 1288,
		"schedulePercent": 0.563237,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cddc7ae",
		"email": "ek@loyaltmgmt.com",
		"name": "Emily",
		"createdDate": "2017-02-10 21:19:38",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 622,
		"totalCountries": 16,
		"totalStates": 55,
		"totalEvents": 154,
		"eventPercent": 0.71368,
		"totalScheduleItems": 2328,
		"schedulePercent": 0.770362,
		"totalGuests": 837,
		"guestPercent": 0.785194,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 68
	},
	{
		"id": "50e3bf1",
		"email": "Daniel.Bess@wmg.com",
		"name": "Daniel",
		"createdDate": "2018-04-19 17:27:07",
		"totalOrganizations": 48,
		"totalTours": 44,
		"totalDays": 15011,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 1913,
		"eventPercent": 0.993908,
		"totalScheduleItems": 742,
		"schedulePercent": 0.397431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cef5483",
		"email": "Trisha@twisted-talent.com",
		"name": "Twisted",
		"createdDate": "2022-11-11 11:25:28",
		"totalOrganizations": 11,
		"totalTours": 10,
		"totalDays": 324,
		"totalCountries": 21,
		"totalStates": 44,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 2052,
		"schedulePercent": 0.727851,
		"totalGuests": 1136,
		"guestPercent": 0.846246,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "AFAS Live",
		"mostGuestCity": "Amsterdam",
		"mostGuestAmount": 74
	},
	{
		"id": "69a7eea",
		"email": "merle@c22agency.com",
		"name": "Merle",
		"createdDate": "2022-03-24 12:50:44",
		"totalOrganizations": 13,
		"totalTours": 23,
		"totalDays": 364,
		"totalCountries": 26,
		"totalStates": 89,
		"totalEvents": 254,
		"eventPercent": 0.879619,
		"totalScheduleItems": 3060,
		"schedulePercent": 0.8579,
		"totalGuests": 1567,
		"guestPercent": 0.900146,
		"mostGuestDate": "2023-03-14 00:00:00",
		"mostGuestName": "Huxley's",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 64
	},
	{
		"id": "dc49d47",
		"email": "KHAYLA@A22ENTERTAINMENT.COM",
		"name": "Khayla",
		"createdDate": "2021-09-21 00:16:29",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 107,
		"totalCountries": 1,
		"totalStates": 18,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 872,
		"schedulePercent": 0.443915,
		"totalGuests": 357,
		"guestPercent": 0.633558,
		"mostGuestDate": "2023-04-22 00:00:00",
		"mostGuestName": "Grace Church Houston",
		"mostGuestCity": "Houston",
		"mostGuestAmount": 70
	},
	{
		"id": "0297999",
		"email": "alexanderhreeder@gmail.com",
		"name": "Alex",
		"createdDate": "2015-04-05 15:18:41",
		"totalOrganizations": 10,
		"totalTours": 5,
		"totalDays": 599,
		"totalCountries": 9,
		"totalStates": 45,
		"totalEvents": 133,
		"eventPercent": 0.656602,
		"totalScheduleItems": 1933,
		"schedulePercent": 0.707721,
		"totalGuests": 308,
		"guestPercent": 0.608131,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Orpheum Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 41
	},
	{
		"id": "a2f7d82",
		"email": "edgardiazp@me.com",
		"name": "EDGAR",
		"createdDate": "2021-08-12 12:26:42",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 90,
		"totalCountries": 4,
		"totalStates": 10,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 117,
		"schedulePercent": 0.100914,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "Colegio EcoTecnolÃ³gico Sagrado CorazÃ³n de JesÃºs",
		"mostGuestCity": "Cuilapa",
		"mostGuestAmount": 1
	},
	{
		"id": "13efb77",
		"email": "totempro@earthlink.net",
		"name": "Mason",
		"createdDate": "2010-03-05 01:38:12",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 81,
		"totalCountries": 6,
		"totalStates": 18,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 513,
		"schedulePercent": 0.30539,
		"totalGuests": 175,
		"guestPercent": 0.533439,
		"mostGuestDate": "2023-07-03 00:00:00",
		"mostGuestName": "Montreal Symphony Orchestra",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 22
	},
	{
		"id": "b84e28f",
		"email": "Jakob@lightit.se",
		"name": "Jakob",
		"createdDate": "2014-05-05 11:51:38",
		"totalOrganizations": 11,
		"totalTours": 16,
		"totalDays": 418,
		"totalCountries": 3,
		"totalStates": 32,
		"totalEvents": 209,
		"eventPercent": 0.82201,
		"totalScheduleItems": 1864,
		"schedulePercent": 0.698053,
		"totalGuests": 405,
		"guestPercent": 0.655145,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Uppsala Konsert & Kongress",
		"mostGuestCity": "Uppsala",
		"mostGuestAmount": 40
	},
	{
		"id": "fca6399",
		"email": "thetexasound@gmail.com",
		"name": "Justin",
		"createdDate": "2022-07-28 20:35:50",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 138,
		"totalCountries": 8,
		"totalStates": 29,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 889,
		"schedulePercent": 0.449344,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bce096f",
		"email": "kristin@juelconcepts.com",
		"name": "Kristin",
		"createdDate": "2023-08-17 13:52:52",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 33,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 123,
		"schedulePercent": 0.105019,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ad483a9",
		"email": "whitneywangsgaard@gmail.com",
		"name": "Whitney",
		"createdDate": "2015-05-19 16:14:55",
		"totalOrganizations": 9,
		"totalTours": 16,
		"totalDays": 544,
		"totalCountries": 4,
		"totalStates": 39,
		"totalEvents": 210,
		"eventPercent": 0.823732,
		"totalScheduleItems": 3733,
		"schedulePercent": 0.908754,
		"totalGuests": 422,
		"guestPercent": 0.662694,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "The Salt Shed",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 45
	},
	{
		"id": "9169e00",
		"email": "patrickmeadoconnell@hotmail.com",
		"name": "Patrick",
		"createdDate": "2018-01-10 04:57:27",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 364,
		"totalCountries": 12,
		"totalStates": 20,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 402,
		"schedulePercent": 0.257582,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6ad54ec",
		"email": "mrharryacampbell@gmail.com",
		"name": "Harry",
		"createdDate": "2023-08-10 20:08:22",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 38,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 436,
		"schedulePercent": 0.270825,
		"totalGuests": 156,
		"guestPercent": 0.518607,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Meridian Theatres @ Centrepointe",
		"mostGuestCity": "Ottawa",
		"mostGuestAmount": 37
	},
	{
		"id": "c40eff0",
		"email": "rene@artistlogistics.com",
		"name": "Rene",
		"createdDate": "2017-03-21 22:49:13",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 380,
		"totalCountries": 15,
		"totalStates": 37,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 667,
		"schedulePercent": 0.367898,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "50017ef",
		"email": "david@davidcurley.ie",
		"name": "David ",
		"createdDate": "2022-04-19 16:33:22",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 610,
		"totalCountries": 11,
		"totalStates": 29,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 1180,
		"schedulePercent": 0.534896,
		"totalGuests": 44,
		"guestPercent": 0.411469,
		"mostGuestDate": "2023-02-24 00:00:00",
		"mostGuestName": "The Academy, Middle Abbey Street",
		"mostGuestCity": "Dublin",
		"mostGuestAmount": 18
	},
	{
		"id": "b86ef4f",
		"email": "stokes.sound@gmail.com",
		"name": "Gary",
		"createdDate": "2013-01-07 21:27:13",
		"totalOrganizations": 16,
		"totalTours": 12,
		"totalDays": 564,
		"totalCountries": 10,
		"totalStates": 35,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 1502,
		"schedulePercent": 0.618329,
		"totalGuests": 298,
		"guestPercent": 0.60098,
		"mostGuestDate": "2023-05-09 00:00:00",
		"mostGuestName": "Islington Assembly Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 29
	},
	{
		"id": "54b6357",
		"email": "Devinkingtm@gmail.com",
		"name": "Devin",
		"createdDate": "2013-02-24 20:37:34",
		"totalOrganizations": 7,
		"totalTours": 16,
		"totalDays": 414,
		"totalCountries": 14,
		"totalStates": 59,
		"totalEvents": 208,
		"eventPercent": 0.819891,
		"totalScheduleItems": 2946,
		"schedulePercent": 0.846775,
		"totalGuests": 320,
		"guestPercent": 0.614091,
		"mostGuestDate": "2023-09-11 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 36
	},
	{
		"id": "a53d273",
		"email": "corinestelz@gmail.com",
		"name": "Cory",
		"createdDate": "2018-06-21 22:45:23",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 565,
		"totalCountries": 4,
		"totalStates": 31,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 663,
		"schedulePercent": 0.366177,
		"totalGuests": 174,
		"guestPercent": 0.532115,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Wrigley Field",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 33
	},
	{
		"id": "7cf84cd",
		"email": "jason@essentialsequential.com",
		"name": "jason",
		"createdDate": "2023-01-04 01:31:42",
		"totalOrganizations": 1,
		"totalTours": 20,
		"totalDays": 107,
		"totalCountries": 3,
		"totalStates": 16,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 231,
		"schedulePercent": 0.169779,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "aca9a10",
		"email": "jeanpitre@bellaliant.net",
		"name": "Jean",
		"createdDate": "2020-09-03 17:57:03",
		"totalOrganizations": 31,
		"totalTours": 18,
		"totalDays": 1528,
		"totalCountries": 5,
		"totalStates": 50,
		"totalEvents": 1033,
		"eventPercent": 0.990465,
		"totalScheduleItems": 1694,
		"schedulePercent": 0.663621,
		"totalGuests": 171,
		"guestPercent": 0.530658,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Rebecca Cohn Auditorium",
		"mostGuestCity": "Halifax",
		"mostGuestAmount": 18
	},
	{
		"id": "8b36cfe",
		"email": "bjmelman@gmail.com",
		"name": "Ben ",
		"createdDate": "2016-09-13 21:42:10",
		"totalOrganizations": 14,
		"totalTours": 11,
		"totalDays": 823,
		"totalCountries": 14,
		"totalStates": 46,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 1665,
		"schedulePercent": 0.656734,
		"totalGuests": 309,
		"guestPercent": 0.609058,
		"mostGuestDate": "2023-01-04 00:00:00",
		"mostGuestName": "Paramount Theatre",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 53
	},
	{
		"id": "1f96ed7",
		"email": "ben.perrytm@gmail.com",
		"name": "Ben ",
		"createdDate": "2017-01-09 22:40:27",
		"totalOrganizations": 13,
		"totalTours": 13,
		"totalDays": 170,
		"totalCountries": 7,
		"totalStates": 22,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1482,
		"schedulePercent": 0.609985,
		"totalGuests": 721,
		"guestPercent": 0.757913,
		"mostGuestDate": "2023-03-04 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 166
	},
	{
		"id": "db2c203",
		"email": "matt@mktouring.com",
		"name": "Matt",
		"createdDate": "2021-11-30 20:19:55",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 111,
		"totalCountries": 7,
		"totalStates": 31,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 363,
		"schedulePercent": 0.239703,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c064b97",
		"email": "bearthetm@gmail.com",
		"name": "Ryan",
		"createdDate": "2019-05-03 16:48:19",
		"totalOrganizations": 12,
		"totalTours": 10,
		"totalDays": 547,
		"totalCountries": 11,
		"totalStates": 55,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 1586,
		"schedulePercent": 0.638326,
		"totalGuests": 1497,
		"guestPercent": 0.893921,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 128
	},
	{
		"id": "7aab8e1",
		"email": "QUARAN@QUARANBRANKER.COM",
		"name": "QUARAN",
		"createdDate": "2022-05-31 05:09:58",
		"totalOrganizations": 5,
		"totalTours": 14,
		"totalDays": 609,
		"totalCountries": 4,
		"totalStates": 23,
		"totalEvents": 180,
		"eventPercent": 0.770229,
		"totalScheduleItems": 2150,
		"schedulePercent": 0.742286,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3fd5205",
		"email": "chucky@advocatetouring.com",
		"name": "Chucky ",
		"createdDate": "2013-10-15 11:55:01",
		"totalOrganizations": 15,
		"totalTours": 14,
		"totalDays": 208,
		"totalCountries": 4,
		"totalStates": 35,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 1737,
		"schedulePercent": 0.672096,
		"totalGuests": 1075,
		"guestPercent": 0.835254,
		"mostGuestDate": "2023-09-09 00:00:00",
		"mostGuestName": "Forum Melbourne",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 89
	},
	{
		"id": "c7fffe7",
		"email": "CHRISTINE@QPRIME.COM",
		"name": "Christine",
		"createdDate": "2010-11-01 16:04:04",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 177,
		"totalCountries": 4,
		"totalStates": 38,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 721,
		"schedulePercent": 0.38869,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4f3c5f4",
		"email": "rogersmissen@gmail.com",
		"name": "Roger",
		"createdDate": "2020-01-18 11:14:50",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 133,
		"totalCountries": 4,
		"totalStates": 9,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 955,
		"schedulePercent": 0.467355,
		"totalGuests": 571,
		"guestPercent": 0.716991,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Nordichallen",
		"mostGuestCity": "Sundsvall",
		"mostGuestAmount": 103
	},
	{
		"id": "54d6723",
		"email": "anna@velour.nu",
		"name": "Anna",
		"createdDate": "2022-04-04 11:16:00",
		"totalOrganizations": 14,
		"totalTours": 12,
		"totalDays": 167,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 162,
		"eventPercent": 0.732883,
		"totalScheduleItems": 784,
		"schedulePercent": 0.41425,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-01-21 00:00:00",
		"mostGuestName": "Ideal Bar",
		"mostGuestCity": "Copenhagen",
		"mostGuestAmount": 2
	},
	{
		"id": "f2b2322",
		"email": "itsdug@aol.com",
		"name": "Doug",
		"createdDate": "2010-04-07 01:48:10",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 774,
		"totalCountries": 10,
		"totalStates": 53,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 2785,
		"schedulePercent": 0.830221,
		"totalGuests": 318,
		"guestPercent": 0.613031,
		"mostGuestDate": "2023-07-05 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 57
	},
	{
		"id": "04a8137",
		"email": "russell@prathertouring.com",
		"name": "Russell",
		"createdDate": "2023-08-19 13:35:50",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 192,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 617,
		"schedulePercent": 0.348431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3ca4f68",
		"email": "euphoriun@gmail.com",
		"name": "Mark",
		"createdDate": "2022-07-14 21:03:53",
		"totalOrganizations": 2,
		"totalTours": 19,
		"totalDays": 238,
		"totalCountries": 11,
		"totalStates": 22,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 995,
		"schedulePercent": 0.483115,
		"totalGuests": 347,
		"guestPercent": 0.627467,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Alexandra Palace Theatre",
		"mostGuestCity": "London",
		"mostGuestAmount": 123
	},
	{
		"id": "6eac79a",
		"email": "camila@freebornmedia.net",
		"name": "Camila",
		"createdDate": "2023-03-27 22:09:17",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 478,
		"totalCountries": 6,
		"totalStates": 24,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 2144,
		"schedulePercent": 0.741226,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-09-03 00:00:00",
		"mostGuestName": "Lakewood Amphitheatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 8
	},
	{
		"id": "caa9db3",
		"email": "jesse@qprime.com",
		"name": "Jesse",
		"createdDate": "2011-10-17 22:53:27",
		"totalOrganizations": 10,
		"totalTours": 23,
		"totalDays": 570,
		"totalCountries": 14,
		"totalStates": 69,
		"totalEvents": 267,
		"eventPercent": 0.892729,
		"totalScheduleItems": 3022,
		"schedulePercent": 0.853132,
		"totalGuests": 809,
		"guestPercent": 0.778175,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "Toad's Place",
		"mostGuestCity": "New Haven",
		"mostGuestAmount": 63
	},
	{
		"id": "7bb17fe",
		"email": "taylorbingley@gmail.com",
		"name": "Taylor",
		"createdDate": "2013-08-28 18:05:36",
		"totalOrganizations": 11,
		"totalTours": 11,
		"totalDays": 392,
		"totalCountries": 19,
		"totalStates": 71,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 4521,
		"schedulePercent": 0.9461,
		"totalGuests": 4979,
		"guestPercent": 0.991789,
		"mostGuestDate": "2023-04-21 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 156
	},
	{
		"id": "7ec743b",
		"email": "oona@royalstudios.com",
		"name": "Oona",
		"createdDate": "2023-07-27 04:44:51",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 149,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 768,
		"schedulePercent": 0.40829,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ec0bc83",
		"email": "luisribeirojahlive@gmail.com",
		"name": "Luis ",
		"createdDate": "2022-06-19 11:32:32",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 164,
		"totalCountries": 10,
		"totalStates": 25,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 505,
		"schedulePercent": 0.301152,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-03-02 00:00:00",
		"mostGuestName": "Metronum",
		"mostGuestCity": "Toulouse",
		"mostGuestAmount": 2
	},
	{
		"id": "a4def39",
		"email": "booking@christinedclario.com",
		"name": "Carlos",
		"createdDate": "2016-01-29 23:09:21",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 81,
		"totalCountries": 6,
		"totalStates": 18,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 385,
		"schedulePercent": 0.251093,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fb6de33",
		"email": "westwing@kj.de",
		"name": "West",
		"createdDate": "2023-02-21 17:03:08",
		"totalOrganizations": 23,
		"totalTours": 22,
		"totalDays": 480,
		"totalCountries": 7,
		"totalStates": 28,
		"totalEvents": 195,
		"eventPercent": 0.796318,
		"totalScheduleItems": 1419,
		"schedulePercent": 0.595948,
		"totalGuests": 893,
		"guestPercent": 0.797908,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Arena Wien",
		"mostGuestCity": "Vienna",
		"mostGuestAmount": 67
	},
	{
		"id": "efae06e",
		"email": "Anna.Cage@wmg.com",
		"name": "Anna",
		"createdDate": "2017-08-10 02:03:27",
		"totalOrganizations": 52,
		"totalTours": 46,
		"totalDays": 15594,
		"totalCountries": 16,
		"totalStates": 88,
		"totalEvents": 2100,
		"eventPercent": 0.997881,
		"totalScheduleItems": 3223,
		"schedulePercent": 0.871805,
		"totalGuests": 836,
		"guestPercent": 0.784664,
		"mostGuestDate": "2023-02-16 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 60
	},
	{
		"id": "d13a95d",
		"email": "Bucknerkb04@gmail.com",
		"name": "Kate",
		"createdDate": "2015-04-01 00:48:38",
		"totalOrganizations": 10,
		"totalTours": 12,
		"totalDays": 1195,
		"totalCountries": 7,
		"totalStates": 45,
		"totalEvents": 259,
		"eventPercent": 0.884783,
		"totalScheduleItems": 4505,
		"schedulePercent": 0.945305,
		"totalGuests": 3401,
		"guestPercent": 0.975765,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 137
	},
	{
		"id": "f42c724",
		"email": "management@theelovaters.com",
		"name": "The",
		"createdDate": "2022-12-21 16:09:51",
		"totalOrganizations": 4,
		"totalTours": 15,
		"totalDays": 688,
		"totalCountries": 5,
		"totalStates": 40,
		"totalEvents": 239,
		"eventPercent": 0.860681,
		"totalScheduleItems": 2809,
		"schedulePercent": 0.83287,
		"totalGuests": 1899,
		"guestPercent": 0.928619,
		"mostGuestDate": "2023-06-18 00:00:00",
		"mostGuestName": "Red Rocks Park and Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 183
	},
	{
		"id": "8e8973d",
		"email": "csharpe912@yahoo.com",
		"name": "Clay",
		"createdDate": "2018-08-02 19:23:05",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 237,
		"schedulePercent": 0.173355,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "79a2e4e",
		"email": "brandt@hexagrambooking.com",
		"name": "Brandt",
		"createdDate": "2016-06-08 18:59:02",
		"totalOrganizations": 29,
		"totalTours": 39,
		"totalDays": 716,
		"totalCountries": 17,
		"totalStates": 70,
		"totalEvents": 319,
		"eventPercent": 0.925175,
		"totalScheduleItems": 3769,
		"schedulePercent": 0.910343,
		"totalGuests": 1265,
		"guestPercent": 0.867302,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Marathon Music Works",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 57
	},
	{
		"id": "741c3e8",
		"email": "vincent.mahe@orange.fr",
		"name": "Vincent",
		"createdDate": "2019-05-07 13:21:40",
		"totalOrganizations": 13,
		"totalTours": 10,
		"totalDays": 236,
		"totalCountries": 14,
		"totalStates": 32,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 1606,
		"schedulePercent": 0.643888,
		"totalGuests": 434,
		"guestPercent": 0.666534,
		"mostGuestDate": "2023-07-19 00:00:00",
		"mostGuestName": "ArÃ¨nes de Bayonne",
		"mostGuestCity": "Bayonne",
		"mostGuestAmount": 38
	},
	{
		"id": "043f5de",
		"email": "rich@thetouringgroup.com",
		"name": "Richard",
		"createdDate": "2019-10-26 15:10:51",
		"totalOrganizations": 18,
		"totalTours": 11,
		"totalDays": 1646,
		"totalCountries": 18,
		"totalStates": 43,
		"totalEvents": 134,
		"eventPercent": 0.658721,
		"totalScheduleItems": 2459,
		"schedulePercent": 0.787313,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c4dd0cb",
		"email": "umejun@earthlink.net",
		"name": "Jun",
		"createdDate": "2017-08-15 18:00:33",
		"totalOrganizations": 10,
		"totalTours": 12,
		"totalDays": 289,
		"totalCountries": 17,
		"totalStates": 59,
		"totalEvents": 141,
		"eventPercent": 0.678321,
		"totalScheduleItems": 1581,
		"schedulePercent": 0.636869,
		"totalGuests": 223,
		"guestPercent": 0.564958,
		"mostGuestDate": "2023-09-29 00:00:00",
		"mostGuestName": "The Moroccan Lounge",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 43
	},
	{
		"id": "204968f",
		"email": "raj@bootlegbeatles.com",
		"name": "Raj",
		"createdDate": "2011-12-09 21:02:27",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 22,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 154,
		"schedulePercent": 0.123692,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ff0a68e",
		"email": "jononottjono@gmail.com",
		"name": "Jonathan",
		"createdDate": "2015-06-30 00:55:51",
		"totalOrganizations": 13,
		"totalTours": 17,
		"totalDays": 366,
		"totalCountries": 10,
		"totalStates": 23,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 1737,
		"schedulePercent": 0.672096,
		"totalGuests": 1031,
		"guestPercent": 0.829294,
		"mostGuestDate": "2023-06-01 00:00:00",
		"mostGuestName": "The Enmore Theatre",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 107
	},
	{
		"id": "e40daa1",
		"email": "Tim.Foisset@wmg.com",
		"name": "Tim",
		"createdDate": "2017-06-01 20:11:23",
		"totalOrganizations": 52,
		"totalTours": 44,
		"totalDays": 15011,
		"totalCountries": 15,
		"totalStates": 88,
		"totalEvents": 1913,
		"eventPercent": 0.993908,
		"totalScheduleItems": 742,
		"schedulePercent": 0.397431,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "efc415d",
		"email": "ellaoconnorwilliams@gmail.com",
		"name": "Ella",
		"createdDate": "2021-10-18 18:12:05",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 74,
		"totalCountries": 4,
		"totalStates": 25,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 334,
		"schedulePercent": 0.225533,
		"totalGuests": 90,
		"guestPercent": 0.463912,
		"mostGuestDate": "2023-07-18 00:00:00",
		"mostGuestName": "Levon Helm Studios",
		"mostGuestCity": "Woodstock",
		"mostGuestAmount": 21
	},
	{
		"id": "e3852a2",
		"email": "phil@philbrennan.com",
		"name": "Philip",
		"createdDate": "2021-12-09 18:43:14",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 510,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 702,
		"schedulePercent": 0.381274,
		"totalGuests": 250,
		"guestPercent": 0.578599,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "Variety Playhouse",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 20
	},
	{
		"id": "f0bb659",
		"email": "jeffcohran@gmail.com",
		"name": "Jeff",
		"createdDate": "2015-12-07 12:32:47",
		"totalOrganizations": 5,
		"totalTours": 12,
		"totalDays": 88,
		"totalCountries": 6,
		"totalStates": 13,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 515,
		"schedulePercent": 0.306052,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9ab87d6",
		"email": "dbarca11@gmail.com",
		"name": "Daniel",
		"createdDate": "2019-11-01 00:43:43",
		"totalOrganizations": 20,
		"totalTours": 12,
		"totalDays": 303,
		"totalCountries": 4,
		"totalStates": 16,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 916,
		"schedulePercent": 0.456099,
		"totalGuests": 34,
		"guestPercent": 0.395842,
		"mostGuestDate": "2023-11-19 00:00:00",
		"mostGuestName": "Chevalier Theatre",
		"mostGuestCity": "Medford",
		"mostGuestAmount": 10
	},
	{
		"id": "36d1fe3",
		"email": "hollysandeman@me.com",
		"name": "Holly",
		"createdDate": "2015-09-10 15:29:26",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 183,
		"totalCountries": 7,
		"totalStates": 10,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 1133,
		"schedulePercent": 0.520726,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6592174",
		"email": "allancasillas@me.com",
		"name": "Allan",
		"createdDate": "2015-06-17 16:01:01",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 492,
		"totalCountries": 11,
		"totalStates": 46,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 1967,
		"schedulePercent": 0.713813,
		"totalGuests": 1099,
		"guestPercent": 0.839624,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 96
	},
	{
		"id": "6671cff",
		"email": "brett@goodboydaisy.com",
		"name": "Brett",
		"createdDate": "2021-08-12 23:47:44",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 55,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 248,
		"schedulePercent": 0.180771,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-12-03 00:00:00",
		"mostGuestName": "Brooklyn Bowl Las Vegas",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 7
	},
	{
		"id": "466adaf",
		"email": "eric.hegi@gmail.com",
		"name": "Eric",
		"createdDate": "2013-10-09 15:48:15",
		"totalOrganizations": 4,
		"totalTours": 12,
		"totalDays": 221,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 1704,
		"schedulePercent": 0.665607,
		"totalGuests": 445,
		"guestPercent": 0.670905,
		"mostGuestDate": "2023-04-06 00:00:00",
		"mostGuestName": "The Apollo Theater",
		"mostGuestCity": "New York",
		"mostGuestAmount": 35
	},
	{
		"id": "bf8878a",
		"email": "officialwoolimusic@gmail.com",
		"name": "Adam",
		"createdDate": "2017-03-28 22:05:25",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 964,
		"totalCountries": 8,
		"totalStates": 35,
		"totalEvents": 133,
		"eventPercent": 0.656602,
		"totalScheduleItems": 1497,
		"schedulePercent": 0.617402,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5b47a15",
		"email": "mike@arc-flame.com",
		"name": "Michael",
		"createdDate": "2017-09-01 22:14:59",
		"totalOrganizations": 15,
		"totalTours": 14,
		"totalDays": 248,
		"totalCountries": 15,
		"totalStates": 29,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 1816,
		"schedulePercent": 0.688121,
		"totalGuests": 1008,
		"guestPercent": 0.825718,
		"mostGuestDate": "2023-04-15 00:00:00",
		"mostGuestName": "OVO Arena",
		"mostGuestCity": "London",
		"mostGuestAmount": 113
	},
	{
		"id": "d1b6a2a",
		"email": "bing@flysouthmusic.com",
		"name": "Ryan",
		"createdDate": "2014-04-16 19:35:44",
		"totalOrganizations": 10,
		"totalTours": 18,
		"totalDays": 563,
		"totalCountries": 11,
		"totalStates": 62,
		"totalEvents": 240,
		"eventPercent": 0.86174,
		"totalScheduleItems": 3813,
		"schedulePercent": 0.912329,
		"totalGuests": 1328,
		"guestPercent": 0.875116,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Soma",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 77
	},
	{
		"id": "ad60a14",
		"email": "philippecailleaux@me.com",
		"name": "Philippe",
		"createdDate": "2012-04-06 14:12:27",
		"totalOrganizations": 5,
		"totalTours": 15,
		"totalDays": 412,
		"totalCountries": 8,
		"totalStates": 18,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 2016,
		"schedulePercent": 0.722553,
		"totalGuests": 51,
		"guestPercent": 0.423785,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Theatre Antique de Vienne",
		"mostGuestCity": "Vienne",
		"mostGuestAmount": 15
	},
	{
		"id": "1c01847",
		"email": "trunzo@songsbyjoan.com",
		"name": "Jacob",
		"createdDate": "2020-01-22 05:29:49",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 473,
		"totalCountries": 4,
		"totalStates": 41,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 1378,
		"schedulePercent": 0.586677,
		"totalGuests": 252,
		"guestPercent": 0.58032,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 55
	},
	{
		"id": "eb378a0",
		"email": "marcel@marcel-reckler.com",
		"name": "Marcel",
		"createdDate": "2021-11-13 07:31:25",
		"totalOrganizations": 7,
		"totalTours": 4,
		"totalDays": 160,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 1249,
		"schedulePercent": 0.551847,
		"totalGuests": 419,
		"guestPercent": 0.660707,
		"mostGuestDate": "2023-09-29 00:00:00",
		"mostGuestName": "Huxleys Neue Welt",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 70
	},
	{
		"id": "a5dbfbd",
		"email": "JAZMYN@BETHELMUSIC.COM",
		"name": "Jazmyn",
		"createdDate": "2016-03-29 22:56:28",
		"totalOrganizations": 6,
		"totalTours": 21,
		"totalDays": 211,
		"totalCountries": 2,
		"totalStates": 17,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 1371,
		"schedulePercent": 0.585618,
		"totalGuests": 555,
		"guestPercent": 0.711296,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "The Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 130
	},
	{
		"id": "47afec4",
		"email": "jackie@friendsatwork.com",
		"name": "Jackie",
		"createdDate": "2022-05-24 22:07:48",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 333,
		"totalCountries": 12,
		"totalStates": 25,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 1208,
		"schedulePercent": 0.542047,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "65fd38e",
		"email": "luke@imaginedps.com",
		"name": "Luke",
		"createdDate": "2022-09-23 01:53:41",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 103,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 211,
		"schedulePercent": 0.157198,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a114005",
		"email": "sylvester@theartist.eu",
		"name": "Sylvester",
		"createdDate": "2022-08-09 13:45:24",
		"totalOrganizations": 66,
		"totalTours": 42,
		"totalDays": 1491,
		"totalCountries": 3,
		"totalStates": 15,
		"totalEvents": 942,
		"eventPercent": 0.98967,
		"totalScheduleItems": 6070,
		"schedulePercent": 0.981062,
		"totalGuests": 1656,
		"guestPercent": 0.907694,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Royal Arena",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 140
	},
	{
		"id": "313954e",
		"email": "intern@lecrae.com",
		"name": "Team",
		"createdDate": "2018-03-13 19:41:58",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 246,
		"totalCountries": 5,
		"totalStates": 39,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 2584,
		"schedulePercent": 0.803602,
		"totalGuests": 1016,
		"guestPercent": 0.827308,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "The Orion Amphitheater",
		"mostGuestCity": "Huntsville",
		"mostGuestAmount": 80
	},
	{
		"id": "c1a1e42",
		"email": "hello@katmurphy.com.au",
		"name": "Kat",
		"createdDate": "2022-01-07 04:59:18",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 53,
		"totalCountries": 3,
		"totalStates": 8,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 451,
		"schedulePercent": 0.277049,
		"totalGuests": 38,
		"guestPercent": 0.402198,
		"mostGuestDate": "2023-11-29 00:00:00",
		"mostGuestName": "Spark Arena",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 38
	},
	{
		"id": "6b7c9c2",
		"email": "mastertour2@asterios.fr",
		"name": "MasterTour2",
		"createdDate": "2017-10-19 14:21:30",
		"totalOrganizations": 11,
		"totalTours": 4,
		"totalDays": 761,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 1987,
		"schedulePercent": 0.716726,
		"totalGuests": 105,
		"guestPercent": 0.480069,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "Chateau Rouge",
		"mostGuestCity": "Annemasse",
		"mostGuestAmount": 18
	},
	{
		"id": "7aa3def",
		"email": "aj@blackhawktouring.com",
		"name": "Antonio",
		"createdDate": "2023-04-27 05:22:09",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 95,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 868,
		"schedulePercent": 0.442061,
		"totalGuests": 145,
		"guestPercent": 0.509734,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Terminal West",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 37
	},
	{
		"id": "4a4e58a",
		"email": "tenorsmastertour@gmail.com",
		"name": "Tamar",
		"createdDate": "2015-07-10 16:51:28",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 59,
		"totalCountries": 4,
		"totalStates": 19,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 438,
		"schedulePercent": 0.272017,
		"totalGuests": 31,
		"guestPercent": 0.390942,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "Southern Alberta Jubilee Auditorium",
		"mostGuestCity": "Calgary",
		"mostGuestAmount": 12
	},
	{
		"id": "295bfd7",
		"email": "rich.allis@yahoo.com",
		"name": "Rich",
		"createdDate": "2018-05-29 19:42:17",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 156,
		"totalCountries": 4,
		"totalStates": 41,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 708,
		"schedulePercent": 0.383923,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c21eb5d",
		"email": "chandrawatson@gmail.com",
		"name": "Chandra",
		"createdDate": "2012-01-04 17:21:27",
		"totalOrganizations": 2,
		"totalTours": 9,
		"totalDays": 312,
		"totalCountries": 1,
		"totalStates": 34,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 1027,
		"schedulePercent": 0.492253,
		"totalGuests": 98,
		"guestPercent": 0.472917,
		"mostGuestDate": "2023-05-04 00:00:00",
		"mostGuestName": "St. Cecilia Music Center",
		"mostGuestCity": "Grand Rapids",
		"mostGuestAmount": 12
	},
	{
		"id": "6a4366f",
		"email": "lauren.lucas@pop.belmont.edu",
		"name": "Lauren",
		"createdDate": "2022-01-12 00:59:15",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 111,
		"totalCountries": 1,
		"totalStates": 28,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 509,
		"schedulePercent": 0.303536,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a34441a",
		"email": "katiemwilkinson@gmail.com",
		"name": "Katie",
		"createdDate": "2017-08-15 03:05:31",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 4,
		"totalStates": 34,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 1687,
		"schedulePercent": 0.661899,
		"totalGuests": 2645,
		"guestPercent": 0.957224,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "Nissan Stadium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 118
	},
	{
		"id": "ae9d306",
		"email": "colleenchristmanmusic@gmail.com",
		"name": "Colleen",
		"createdDate": "2022-03-28 18:24:12",
		"totalOrganizations": 4,
		"totalTours": 15,
		"totalDays": 390,
		"totalCountries": 13,
		"totalStates": 38,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1590,
		"schedulePercent": 0.639518,
		"totalGuests": 569,
		"guestPercent": 0.715799,
		"mostGuestDate": "2023-06-27 00:00:00",
		"mostGuestName": "Trinity College Dublin",
		"mostGuestCity": "Dublin",
		"mostGuestAmount": 52
	},
	{
		"id": "63624cc",
		"email": "tobz@ismasheditproductions.com",
		"name": "Toby",
		"createdDate": "2017-04-04 15:58:21",
		"totalOrganizations": 7,
		"totalTours": 16,
		"totalDays": 786,
		"totalCountries": 12,
		"totalStates": 25,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 1714,
		"schedulePercent": 0.668521,
		"totalGuests": 190,
		"guestPercent": 0.542445,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "c262f9b",
		"email": "martijn.tmprod@gmail.com",
		"name": "Martijn",
		"createdDate": "2015-06-10 13:57:02",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 85,
		"totalCountries": 12,
		"totalStates": 34,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 917,
		"schedulePercent": 0.456363,
		"totalGuests": 1318,
		"guestPercent": 0.874056,
		"mostGuestDate": "2023-09-25 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 122
	},
	{
		"id": "fbda249",
		"email": "jay@rightangleent.com",
		"name": "Jay",
		"createdDate": "2022-11-25 22:26:26",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 187,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 133,
		"eventPercent": 0.656602,
		"totalScheduleItems": 2161,
		"schedulePercent": 0.74414,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "Columbia County Performing Arts Center",
		"mostGuestCity": "Evans",
		"mostGuestAmount": 4
	},
	{
		"id": "56ae198",
		"email": "tomdubesound@gmail.com",
		"name": "Thomas",
		"createdDate": "2013-10-25 03:49:17",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 100,
		"totalCountries": 10,
		"totalStates": 15,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 411,
		"schedulePercent": 0.261952,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "907be5e",
		"email": "info@prod2.ca",
		"name": "Dany",
		"createdDate": "2021-04-09 13:40:56",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 660,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 467,
		"eventPercent": 0.970203,
		"totalScheduleItems": 977,
		"schedulePercent": 0.474507,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4c82da0",
		"email": "griffina4@hotmail.com",
		"name": "Griffin",
		"createdDate": "2017-08-28 20:30:24",
		"totalOrganizations": 6,
		"totalTours": 15,
		"totalDays": 871,
		"totalCountries": 12,
		"totalStates": 44,
		"totalEvents": 236,
		"eventPercent": 0.857237,
		"totalScheduleItems": 3482,
		"schedulePercent": 0.892729,
		"totalGuests": 1231,
		"guestPercent": 0.862667,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 185
	},
	{
		"id": "3149b21",
		"email": "contact@itsmyfriendana.com",
		"name": "Ana",
		"createdDate": "2023-11-05 07:55:55",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 40,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 179,
		"schedulePercent": 0.137598,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9a3b5ef",
		"email": "gennicolecrow@gmail.com",
		"name": "Genevieve",
		"createdDate": "2023-01-10 20:54:19",
		"totalOrganizations": 3,
		"totalTours": 10,
		"totalDays": 372,
		"totalCountries": 10,
		"totalStates": 52,
		"totalEvents": 201,
		"eventPercent": 0.808237,
		"totalScheduleItems": 3132,
		"schedulePercent": 0.864521,
		"totalGuests": 1672,
		"guestPercent": 0.909681,
		"mostGuestDate": "2023-01-29 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 229
	},
	{
		"id": "17c50a6",
		"email": "ccarter@fullcourtmanagement.com",
		"name": "Courtney",
		"createdDate": "2014-09-04 20:08:32",
		"totalOrganizations": 16,
		"totalTours": 11,
		"totalDays": 879,
		"totalCountries": 15,
		"totalStates": 61,
		"totalEvents": 254,
		"eventPercent": 0.879619,
		"totalScheduleItems": 3518,
		"schedulePercent": 0.895511,
		"totalGuests": 452,
		"guestPercent": 0.674613,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Cadence Bank Amphitheatre at Chastain Park",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 67
	},
	{
		"id": "48a8b1f",
		"email": "jori.greaves@gmail.com",
		"name": "Jori",
		"createdDate": "2018-09-09 18:51:39",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 100,
		"totalCountries": 5,
		"totalStates": 28,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 947,
		"schedulePercent": 0.464707,
		"totalGuests": 370,
		"guestPercent": 0.64124,
		"mostGuestDate": "2023-08-28 00:00:00",
		"mostGuestName": "Ford Idaho Sports Center",
		"mostGuestCity": "Nampa",
		"mostGuestAmount": 36
	},
	{
		"id": "e362677",
		"email": "andrew.anonlabs@gmail.com",
		"name": "Andrew",
		"createdDate": "2023-07-06 19:25:58",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 36,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 131,
		"schedulePercent": 0.10833,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fdfbee8",
		"email": "tim@daislive.com",
		"name": "Tim",
		"createdDate": "2016-02-20 11:55:00",
		"totalOrganizations": 11,
		"totalTours": 8,
		"totalDays": 839,
		"totalCountries": 4,
		"totalStates": 44,
		"totalEvents": 167,
		"eventPercent": 0.745332,
		"totalScheduleItems": 3587,
		"schedulePercent": 0.899086,
		"totalGuests": 3874,
		"guestPercent": 0.982386,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "Moody Center",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 314
	},
	{
		"id": "c2663ea",
		"email": "bodysnatchertm@gmail.com",
		"name": "Daniel",
		"createdDate": "2022-04-27 02:59:35",
		"totalOrganizations": 8,
		"totalTours": 11,
		"totalDays": 231,
		"totalCountries": 15,
		"totalStates": 66,
		"totalEvents": 177,
		"eventPercent": 0.765594,
		"totalScheduleItems": 2637,
		"schedulePercent": 0.813005,
		"totalGuests": 700,
		"guestPercent": 0.753145,
		"mostGuestDate": "2023-11-19 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 57
	},
	{
		"id": "edb3512",
		"email": "adam@10productionmanagement.com.au",
		"name": "Adam",
		"createdDate": "2021-12-05 23:27:39",
		"totalOrganizations": 18,
		"totalTours": 58,
		"totalDays": 1066,
		"totalCountries": 15,
		"totalStates": 54,
		"totalEvents": 382,
		"eventPercent": 0.952457,
		"totalScheduleItems": 4556,
		"schedulePercent": 0.947557,
		"totalGuests": 605,
		"guestPercent": 0.727983,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 77
	},
	{
		"id": "be38116",
		"email": "brian@lovepolice.com.au",
		"name": "Brian",
		"createdDate": "2010-10-19 21:56:44",
		"totalOrganizations": 47,
		"totalTours": 20,
		"totalDays": 310,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 181,
		"eventPercent": 0.771818,
		"totalScheduleItems": 1395,
		"schedulePercent": 0.590385,
		"totalGuests": 584,
		"guestPercent": 0.720302,
		"mostGuestDate": "2023-03-06 00:00:00",
		"mostGuestName": "Northcote Theatre",
		"mostGuestCity": "Northcote",
		"mostGuestAmount": 71
	},
	{
		"id": "abbcd1c",
		"email": "mich.nathaniel22@gmail.com",
		"name": "Michael",
		"createdDate": "2020-01-07 19:19:05",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 26,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 8,
		"eventPercent": 0.0535028,
		"totalScheduleItems": 66,
		"schedulePercent": 0.0662164,
		"totalGuests": 22,
		"guestPercent": 0.373725,
		"mostGuestDate": "2023-04-29 00:00:00",
		"mostGuestName": "Bright Shade Music and Arts Festival",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 22
	},
	{
		"id": "c1959cc",
		"email": "alby@wearenowhere.co.nz",
		"name": "Alistair",
		"createdDate": "2021-11-24 03:22:53",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 42,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 181,
		"schedulePercent": 0.139054,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "40784ca",
		"email": "wheresbryce@gmail.com",
		"name": "Bryce",
		"createdDate": "2017-09-12 20:06:28",
		"totalOrganizations": 17,
		"totalTours": 22,
		"totalDays": 1075,
		"totalCountries": 18,
		"totalStates": 88,
		"totalEvents": 477,
		"eventPercent": 0.971527,
		"totalScheduleItems": 8327,
		"schedulePercent": 0.99457,
		"totalGuests": 3664,
		"guestPercent": 0.979473,
		"mostGuestDate": "2023-04-21 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 156
	},
	{
		"id": "e024737",
		"email": "hannah.green@prabhumusic.net",
		"name": "Hannah",
		"createdDate": "2017-04-13 19:11:10",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 105,
		"totalCountries": 12,
		"totalStates": 16,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 664,
		"schedulePercent": 0.366574,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5ec0f14",
		"email": "tmiller906@me.com",
		"name": "Tim",
		"createdDate": "2015-10-04 07:43:39",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 127,
		"totalCountries": 8,
		"totalStates": 15,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 757,
		"schedulePercent": 0.405509,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fcb4d99",
		"email": "mgmt@triathalon.band",
		"name": "Jake",
		"createdDate": "2022-08-18 18:59:36",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 87,
		"totalCountries": 9,
		"totalStates": 17,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 327,
		"schedulePercent": 0.222752,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Zakk",
		"mostGuestCity": "Dusseldorf",
		"mostGuestAmount": 2
	},
	{
		"id": "27b16d2",
		"email": "davidkotton@yahoo.com",
		"name": "David",
		"createdDate": "2014-10-31 19:45:39",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 504,
		"totalCountries": 9,
		"totalStates": 43,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 1906,
		"schedulePercent": 0.705072,
		"totalGuests": 465,
		"guestPercent": 0.678718,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 207
	},
	{
		"id": "686416f",
		"email": "mitchell.louis.clark@gmail.com",
		"name": "Mitchell",
		"createdDate": "2020-09-15 22:08:49",
		"totalOrganizations": 14,
		"totalTours": 6,
		"totalDays": 486,
		"totalCountries": 4,
		"totalStates": 31,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 2201,
		"schedulePercent": 0.750099,
		"totalGuests": 3587,
		"guestPercent": 0.978943,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Dickies Arena",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 433
	},
	{
		"id": "48e2273",
		"email": "welch.krackamya@gmail.com",
		"name": "Krackamya",
		"createdDate": "2018-06-30 03:57:04",
		"totalOrganizations": 10,
		"totalTours": 16,
		"totalDays": 771,
		"totalCountries": 11,
		"totalStates": 45,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 1885,
		"schedulePercent": 0.702291,
		"totalGuests": 1256,
		"guestPercent": 0.86611,
		"mostGuestDate": "2023-05-19 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 194
	},
	{
		"id": "d121a3d",
		"email": "ashleypoitevin@gmail.com",
		"name": "Ashley",
		"createdDate": "2017-08-30 15:07:30",
		"totalOrganizations": 10,
		"totalTours": 5,
		"totalDays": 239,
		"totalCountries": 6,
		"totalStates": 28,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1305,
		"schedulePercent": 0.567607,
		"totalGuests": 1181,
		"guestPercent": 0.854324,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Buffalo Outer Harbor Concert Site",
		"mostGuestCity": "Buffalo",
		"mostGuestAmount": 131
	},
	{
		"id": "1bcbabf",
		"email": "marktibbitstm@gmail.com",
		"name": "Mark",
		"createdDate": "2023-01-03 21:35:58",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 127,
		"totalCountries": 1,
		"totalStates": 30,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 1461,
		"schedulePercent": 0.605748,
		"totalGuests": 397,
		"guestPercent": 0.652894,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Brownsville Assembly of God",
		"mostGuestCity": "Pensacola",
		"mostGuestAmount": 45
	},
	{
		"id": "eb5ae69",
		"email": "mattywicks@gmail.com",
		"name": "Matt",
		"createdDate": "2013-01-20 07:18:19",
		"totalOrganizations": 24,
		"totalTours": 38,
		"totalDays": 574,
		"totalCountries": 25,
		"totalStates": 88,
		"totalEvents": 264,
		"eventPercent": 0.889419,
		"totalScheduleItems": 3151,
		"schedulePercent": 0.86611,
		"totalGuests": 1009,
		"guestPercent": 0.826381,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "Hindley Street Music Hall",
		"mostGuestCity": "Adelaide",
		"mostGuestAmount": 42
	},
	{
		"id": "2bd4828",
		"email": "brian@sleepwalkmgmt.com",
		"name": "Brian",
		"createdDate": "2021-09-27 17:55:48",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 141,
		"totalCountries": 3,
		"totalStates": 33,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 368,
		"schedulePercent": 0.24222,
		"totalGuests": 55,
		"guestPercent": 0.42842,
		"mostGuestDate": "2023-04-15 00:00:00",
		"mostGuestName": "Zebulon",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 24
	},
	{
		"id": "bf2fa6b",
		"email": "claire@bossymusic.com",
		"name": "Claire",
		"createdDate": "2011-09-06 13:31:54",
		"totalOrganizations": 13,
		"totalTours": 17,
		"totalDays": 790,
		"totalCountries": 7,
		"totalStates": 18,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 979,
		"schedulePercent": 0.475566,
		"totalGuests": 102,
		"guestPercent": 0.477155,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "Lansdowne Hotel",
		"mostGuestCity": "Chippendale",
		"mostGuestAmount": 18
	},
	{
		"id": "3fb68c4",
		"email": "j.short@markow.inc",
		"name": "Jakob",
		"createdDate": "2022-07-13 00:07:49",
		"totalOrganizations": 3,
		"totalTours": 12,
		"totalDays": 412,
		"totalCountries": 7,
		"totalStates": 44,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 2200,
		"schedulePercent": 0.749437,
		"totalGuests": 201,
		"guestPercent": 0.55198,
		"mostGuestDate": "2023-05-18 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 124
	},
	{
		"id": "f402717",
		"email": "Scott@youandmetouring.com",
		"name": "Scott",
		"createdDate": "2013-03-21 21:59:32",
		"totalOrganizations": 34,
		"totalTours": 10,
		"totalDays": 113,
		"totalCountries": 10,
		"totalStates": 12,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 920,
		"schedulePercent": 0.457026,
		"totalGuests": 393,
		"guestPercent": 0.650775,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Brighton Dome",
		"mostGuestCity": "Brighton",
		"mostGuestAmount": 40
	},
	{
		"id": "d4e3906",
		"email": "claytonhunt@me.com",
		"name": "Clay",
		"createdDate": "2011-10-03 23:15:05",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 4,
		"totalStates": 34,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 1687,
		"schedulePercent": 0.661899,
		"totalGuests": 2645,
		"guestPercent": 0.957224,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "Nissan Stadium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 118
	},
	{
		"id": "b1713be",
		"email": "MRosenthalAcct@gmail.com",
		"name": "Michael ",
		"createdDate": "2014-04-14 14:49:55",
		"totalOrganizations": 15,
		"totalTours": 29,
		"totalDays": 709,
		"totalCountries": 22,
		"totalStates": 71,
		"totalEvents": 347,
		"eventPercent": 0.940273,
		"totalScheduleItems": 3204,
		"schedulePercent": 0.870083,
		"totalGuests": 640,
		"guestPercent": 0.735929,
		"mostGuestDate": "2023-06-19 00:00:00",
		"mostGuestName": "Moore Theatre",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 133
	},
	{
		"id": "db55815",
		"email": "stewartblanchard@yahoo.com",
		"name": "Stephen",
		"createdDate": "2010-02-11 21:00:16",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 135,
		"totalCountries": 6,
		"totalStates": 30,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 1780,
		"schedulePercent": 0.680969,
		"totalGuests": 4325,
		"guestPercent": 0.985962,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Fenway Park",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 322
	},
	{
		"id": "5a5bf2b",
		"email": "hagler310@gmail.com",
		"name": "John",
		"createdDate": "2010-10-27 17:03:41",
		"totalOrganizations": 8,
		"totalTours": 20,
		"totalDays": 298,
		"totalCountries": 8,
		"totalStates": 44,
		"totalEvents": 145,
		"eventPercent": 0.688518,
		"totalScheduleItems": 1303,
		"schedulePercent": 0.567077,
		"totalGuests": 448,
		"guestPercent": 0.673156,
		"mostGuestDate": "2023-10-24 00:00:00",
		"mostGuestName": "Northcote Theatre",
		"mostGuestCity": "Northcote",
		"mostGuestAmount": 47
	},
	{
		"id": "1810e99",
		"email": "durandjonesadvance@gmail.com",
		"name": "Durand",
		"createdDate": "2023-08-23 18:32:55",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 74,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 310,
		"schedulePercent": 0.213349,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-09-24 00:00:00",
		"mostGuestName": "Magic Bag",
		"mostGuestCity": "Ferndale",
		"mostGuestAmount": 2
	},
	{
		"id": "228ca39",
		"email": "rogerw@xixentertainment.com",
		"name": "Roger",
		"createdDate": "2018-01-10 19:51:57",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 328,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 549,
		"schedulePercent": 0.320355,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "95fd6b2",
		"email": "henryfarcher@gmail.com",
		"name": "henry",
		"createdDate": "2022-07-25 19:26:04",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 83,
		"totalCountries": 5,
		"totalStates": 29,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 550,
		"schedulePercent": 0.321414,
		"totalGuests": 492,
		"guestPercent": 0.689048,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 88
	},
	{
		"id": "2e4cd54",
		"email": "mitch@hairstontouringproductions.com",
		"name": "Mitchell",
		"createdDate": "2023-07-23 18:20:55",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 718,
		"totalCountries": 6,
		"totalStates": 26,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 754,
		"schedulePercent": 0.404185,
		"totalGuests": 52,
		"guestPercent": 0.425374,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "The Echo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 35
	},
	{
		"id": "eb9c946",
		"email": "geoff.plourd@gmail.com",
		"name": "Geoff",
		"createdDate": "2021-03-29 16:57:36",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 679,
		"totalCountries": 10,
		"totalStates": 51,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 2370,
		"schedulePercent": 0.776321,
		"totalGuests": 1310,
		"guestPercent": 0.873262,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 291
	},
	{
		"id": "1d191e7",
		"email": "Highroadmusic@gmail.com",
		"name": "Sarah",
		"createdDate": "2017-01-09 20:50:19",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 198,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4e4915c",
		"email": "enquiries@hoffmannaudio.com",
		"name": "Jack",
		"createdDate": "2019-10-03 12:57:54",
		"totalOrganizations": 15,
		"totalTours": 26,
		"totalDays": 364,
		"totalCountries": 9,
		"totalStates": 25,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 1972,
		"schedulePercent": 0.714342,
		"totalGuests": 315,
		"guestPercent": 0.611839,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "The Metro Theatre",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 60
	},
	{
		"id": "5f9746c",
		"email": "jesper@philbert.dk",
		"name": "Jesper",
		"createdDate": "2016-09-07 04:06:17",
		"totalOrganizations": 12,
		"totalTours": 9,
		"totalDays": 253,
		"totalCountries": 5,
		"totalStates": 11,
		"totalEvents": 219,
		"eventPercent": 0.836048,
		"totalScheduleItems": 1558,
		"schedulePercent": 0.63144,
		"totalGuests": 279,
		"guestPercent": 0.593961,
		"mostGuestDate": "2023-03-12 00:00:00",
		"mostGuestName": "Verti Music Hall",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 63
	},
	{
		"id": "5fc039d",
		"email": "rachelbaiman@gmail.com",
		"name": "Rachel",
		"createdDate": "2020-03-11 19:11:17",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 1472,
		"totalCountries": 3,
		"totalStates": 41,
		"totalEvents": 207,
		"eventPercent": 0.817905,
		"totalScheduleItems": 1479,
		"schedulePercent": 0.609191,
		"totalGuests": 162,
		"guestPercent": 0.524169,
		"mostGuestDate": "2023-05-02 00:00:00",
		"mostGuestName": "Club Cafe",
		"mostGuestCity": "Pittsburgh",
		"mostGuestAmount": 18
	},
	{
		"id": "cb46a01",
		"email": "tyler@triplebam.com",
		"name": "Chris",
		"createdDate": "2019-01-29 18:26:25",
		"totalOrganizations": 13,
		"totalTours": 19,
		"totalDays": 1249,
		"totalCountries": 20,
		"totalStates": 59,
		"totalEvents": 300,
		"eventPercent": 0.915508,
		"totalScheduleItems": 2239,
		"schedulePercent": 0.755926,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Hatfield Park",
		"mostGuestCity": "",
		"mostGuestAmount": 13
	},
	{
		"id": "6a969e7",
		"email": "courtneyisaboy@gmail.com",
		"name": "Courtney",
		"createdDate": "2014-04-10 15:24:16",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 702,
		"totalCountries": 3,
		"totalStates": 31,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 1444,
		"schedulePercent": 0.602304,
		"totalGuests": 926,
		"guestPercent": 0.805589,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 105
	},
	{
		"id": "e9fb391",
		"email": "emma.goodland@mac.com",
		"name": "Emma",
		"createdDate": "2014-06-17 01:44:02",
		"totalOrganizations": 14,
		"totalTours": 10,
		"totalDays": 202,
		"totalCountries": 4,
		"totalStates": 10,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 598,
		"schedulePercent": 0.339955,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "The Thornbury Theatre",
		"mostGuestCity": "Thornbury",
		"mostGuestAmount": 6
	},
	{
		"id": "8e9ce49",
		"email": "mhall@wildhorsesaloon.com",
		"name": "Michael",
		"createdDate": "2019-04-24 22:55:26",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 4994,
		"schedulePercent": 0.961065,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "493c2dd",
		"email": "w_millerjr@hotmail.com",
		"name": "Walter",
		"createdDate": "2017-11-02 13:10:47",
		"totalOrganizations": 12,
		"totalTours": 8,
		"totalDays": 488,
		"totalCountries": 9,
		"totalStates": 70,
		"totalEvents": 255,
		"eventPercent": 0.880943,
		"totalScheduleItems": 2772,
		"schedulePercent": 0.8285,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d0cef8c",
		"email": "niklaskarlsson@orbitculture.com",
		"name": "Niklas",
		"createdDate": "2022-10-17 12:19:44",
		"totalOrganizations": 2,
		"totalTours": 11,
		"totalDays": 369,
		"totalCountries": 13,
		"totalStates": 53,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 1330,
		"schedulePercent": 0.575023,
		"totalGuests": 265,
		"guestPercent": 0.586942,
		"mostGuestDate": "2023-09-24 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 35
	},
	{
		"id": "4429add",
		"email": "avaajoseph@gmail.com",
		"name": "Ava",
		"createdDate": "2023-05-24 23:07:21",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 336,
		"totalCountries": 10,
		"totalStates": 20,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 389,
		"schedulePercent": 0.252682,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-06-19 00:00:00",
		"mostGuestName": "The Jones Assembly",
		"mostGuestCity": "Oklahoma City",
		"mostGuestAmount": 9
	},
	{
		"id": "ae9806a",
		"email": "ansley@juniorvarsity.club",
		"name": "Ansley",
		"createdDate": "2019-05-01 20:41:25",
		"totalOrganizations": 10,
		"totalTours": 21,
		"totalDays": 315,
		"totalCountries": 9,
		"totalStates": 44,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 2340,
		"schedulePercent": 0.773143,
		"totalGuests": 1985,
		"guestPercent": 0.932989,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "The Armory",
		"mostGuestCity": "Minneapolis",
		"mostGuestAmount": 76
	},
	{
		"id": "765f578",
		"email": "nhbrennian@gmail.com",
		"name": "Nicole",
		"createdDate": "2016-10-26 19:14:50",
		"totalOrganizations": 21,
		"totalTours": 14,
		"totalDays": 404,
		"totalCountries": 10,
		"totalStates": 46,
		"totalEvents": 182,
		"eventPercent": 0.773408,
		"totalScheduleItems": 3380,
		"schedulePercent": 0.885843,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Orpheum Theater",
		"mostGuestCity": "New Orleans",
		"mostGuestAmount": 3
	},
	{
		"id": "93de29e",
		"email": "heloise.auclert@gmail.com",
		"name": "HELOISE",
		"createdDate": "2021-07-07 18:49:12",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 376,
		"totalCountries": 5,
		"totalStates": 26,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 1639,
		"schedulePercent": 0.651172,
		"totalGuests": 409,
		"guestPercent": 0.657264,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Krakatoa",
		"mostGuestCity": "MÃ©rignac",
		"mostGuestAmount": 23
	},
	{
		"id": "29a434b",
		"email": "tm.chrissy@gmail.com",
		"name": "Chrissy",
		"createdDate": "2010-07-13 22:00:24",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 62,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 8,
		"eventPercent": 0.0535028,
		"totalScheduleItems": 182,
		"schedulePercent": 0.139849,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "76efd58",
		"email": "hi.rubenalonso@gmail.com",
		"name": "Ruben",
		"createdDate": "2023-01-12 23:42:03",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 117,
		"totalCountries": 8,
		"totalStates": 38,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 852,
		"schedulePercent": 0.438353,
		"totalGuests": 65,
		"guestPercent": 0.440207,
		"mostGuestDate": "2023-02-09 00:00:00",
		"mostGuestName": "TERRAZA HOTEL TAMANACO CARACAS",
		"mostGuestCity": "Caracas",
		"mostGuestAmount": 31
	},
	{
		"id": "067fe4b",
		"email": "AnnaJeter2011@gmail.com",
		"name": "Anna",
		"createdDate": "2022-04-16 01:14:05",
		"totalOrganizations": 3,
		"totalTours": 14,
		"totalDays": 528,
		"totalCountries": 10,
		"totalStates": 54,
		"totalEvents": 213,
		"eventPercent": 0.827837,
		"totalScheduleItems": 3595,
		"schedulePercent": 0.899616,
		"totalGuests": 843,
		"guestPercent": 0.786253,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 139
	},
	{
		"id": "3817f04",
		"email": "ken@teamzedd.com",
		"name": "Ken",
		"createdDate": "2017-06-08 23:13:47",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 728,
		"totalCountries": 5,
		"totalStates": 19,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 1063,
		"schedulePercent": 0.501788,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7a16e57",
		"email": "BOMTourCM@networkstours.com",
		"name": "Brad",
		"createdDate": "2022-08-06 15:44:30",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 353,
		"totalCountries": 2,
		"totalStates": 33,
		"totalEvents": 253,
		"eventPercent": 0.877765,
		"totalScheduleItems": 3370,
		"schedulePercent": 0.885313,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4b90a1a",
		"email": "Bcross77@aol.com",
		"name": "Bryan",
		"createdDate": "2009-09-25 16:44:48",
		"totalOrganizations": 14,
		"totalTours": 9,
		"totalDays": 273,
		"totalCountries": 4,
		"totalStates": 39,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 1514,
		"schedulePercent": 0.621639,
		"totalGuests": 1538,
		"guestPercent": 0.898292,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "FirstBank Amphitheater",
		"mostGuestCity": "Franklin",
		"mostGuestAmount": 104
	},
	{
		"id": "5aa777c",
		"email": "dean.odxtmgmt@gmail.com",
		"name": "Dean",
		"createdDate": "2016-12-07 06:33:00",
		"totalOrganizations": 14,
		"totalTours": 21,
		"totalDays": 434,
		"totalCountries": 8,
		"totalStates": 50,
		"totalEvents": 176,
		"eventPercent": 0.763475,
		"totalScheduleItems": 2083,
		"schedulePercent": 0.732486,
		"totalGuests": 333,
		"guestPercent": 0.619653,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 54
	},
	{
		"id": "0de1c2b",
		"email": "tmusca@electriccallboy.com",
		"name": "Ian",
		"createdDate": "2023-07-10 18:38:11",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 28,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 286,
		"schedulePercent": 0.201033,
		"totalGuests": 280,
		"guestPercent": 0.594623,
		"mostGuestDate": "2023-08-30 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 40
	},
	{
		"id": "60220b2",
		"email": "joemeekums@gmail.com",
		"name": "Joe",
		"createdDate": "2015-04-24 21:36:23",
		"totalOrganizations": 11,
		"totalTours": 14,
		"totalDays": 588,
		"totalCountries": 15,
		"totalStates": 52,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 1878,
		"schedulePercent": 0.700834,
		"totalGuests": 793,
		"guestPercent": 0.774997,
		"mostGuestDate": "2023-04-26 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 62
	},
	{
		"id": "bdf032a",
		"email": "doug@rischgrp.com",
		"name": "Douglas",
		"createdDate": "2018-04-14 16:28:47",
		"totalOrganizations": 25,
		"totalTours": 25,
		"totalDays": 632,
		"totalCountries": 15,
		"totalStates": 55,
		"totalEvents": 211,
		"eventPercent": 0.825586,
		"totalScheduleItems": 2110,
		"schedulePercent": 0.736724,
		"totalGuests": 663,
		"guestPercent": 0.742815,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "House of Blues Anaheim",
		"mostGuestCity": "Anaheim",
		"mostGuestAmount": 74
	},
	{
		"id": "7978df5",
		"email": "martin@bergenlive.no",
		"name": "Martin",
		"createdDate": "2011-12-13 14:51:18",
		"totalOrganizations": 7,
		"totalTours": 2,
		"totalDays": 71,
		"totalCountries": 11,
		"totalStates": 16,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 652,
		"schedulePercent": 0.362998,
		"totalGuests": 869,
		"guestPercent": 0.791816,
		"mostGuestDate": "2023-05-07 00:00:00",
		"mostGuestName": "O2 Academy Bristol",
		"mostGuestCity": "Bristol",
		"mostGuestAmount": 132
	},
	{
		"id": "a0fd3ee",
		"email": "partizanmgmt@gmail.com",
		"name": "Carl",
		"createdDate": "2022-05-02 18:31:48",
		"totalOrganizations": 1,
		"totalTours": 9,
		"totalDays": 58,
		"totalCountries": 7,
		"totalStates": 13,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 391,
		"schedulePercent": 0.253344,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5bb8a19",
		"email": "brandon.arnold@jurassicquest.com",
		"name": "Brandon",
		"createdDate": "2021-04-17 03:19:13",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 1090,
		"totalCountries": 3,
		"totalStates": 48,
		"totalEvents": 587,
		"eventPercent": 0.982254,
		"totalScheduleItems": 6860,
		"schedulePercent": 0.987816,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "61b46d9",
		"email": "emma.anderson@fullstopmgmt.com",
		"name": "Emma",
		"createdDate": "2022-06-01 23:24:41",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 764,
		"totalCountries": 16,
		"totalStates": 60,
		"totalEvents": 137,
		"eventPercent": 0.666402,
		"totalScheduleItems": 2758,
		"schedulePercent": 0.826778,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Moody Amphitheater",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 5
	},
	{
		"id": "69c5730",
		"email": "isaiah@mindovermgmt.com",
		"name": "Isaiah",
		"createdDate": "2022-09-22 02:44:20",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 31,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 206,
		"schedulePercent": 0.153754,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "161ed37",
		"email": "foxingtheband@gmail.com",
		"name": "Foxing",
		"createdDate": "2016-10-11 20:33:33",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 261,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 243,
		"schedulePercent": 0.17799,
		"totalGuests": 24,
		"guestPercent": 0.375712,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "WaMu Theater",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 20
	},
	{
		"id": "f68b0fb",
		"email": "switzerap@gmail.com",
		"name": "Arthur",
		"createdDate": "2012-02-29 00:54:14",
		"totalOrganizations": 8,
		"totalTours": 3,
		"totalDays": 523,
		"totalCountries": 9,
		"totalStates": 45,
		"totalEvents": 167,
		"eventPercent": 0.745332,
		"totalScheduleItems": 2869,
		"schedulePercent": 0.839359,
		"totalGuests": 2742,
		"guestPercent": 0.962257,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Bank of America Stadium",
		"mostGuestCity": "Charlotte",
		"mostGuestAmount": 243
	},
	{
		"id": "3b1b4c7",
		"email": "magnus@scoproduction.se",
		"name": "Magnus",
		"createdDate": "2013-12-18 23:28:06",
		"totalOrganizations": 25,
		"totalTours": 8,
		"totalDays": 51,
		"totalCountries": 6,
		"totalStates": 7,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 410,
		"schedulePercent": 0.261422,
		"totalGuests": 20,
		"guestPercent": 0.36909,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "Oslo Concert Hall",
		"mostGuestCity": "Oslo",
		"mostGuestAmount": 10
	},
	{
		"id": "9ce582a",
		"email": "jeremystones@me.com",
		"name": "Jeremy",
		"createdDate": "2010-12-26 07:46:45",
		"totalOrganizations": 41,
		"totalTours": 40,
		"totalDays": 521,
		"totalCountries": 6,
		"totalStates": 19,
		"totalEvents": 188,
		"eventPercent": 0.785856,
		"totalScheduleItems": 3003,
		"schedulePercent": 0.851146,
		"totalGuests": 188,
		"guestPercent": 0.541518,
		"mostGuestDate": "2023-11-29 00:00:00",
		"mostGuestName": "Spark Arena",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 38
	},
	{
		"id": "ce236fd",
		"email": "martin.terror@gmail.com",
		"name": "Martin",
		"createdDate": "2014-03-12 23:15:12",
		"totalOrganizations": 6,
		"totalTours": 11,
		"totalDays": 223,
		"totalCountries": 2,
		"totalStates": 44,
		"totalEvents": 154,
		"eventPercent": 0.71368,
		"totalScheduleItems": 1898,
		"schedulePercent": 0.70388,
		"totalGuests": 2471,
		"guestPercent": 0.953119,
		"mostGuestDate": "2023-05-11 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 76
	},
	{
		"id": "2d4b0fb",
		"email": "Ben@nhtours.co.uk",
		"name": "Benjamin",
		"createdDate": "2016-10-23 06:31:48",
		"totalOrganizations": 9,
		"totalTours": 12,
		"totalDays": 245,
		"totalCountries": 14,
		"totalStates": 34,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 1436,
		"schedulePercent": 0.600848,
		"totalGuests": 673,
		"guestPercent": 0.745332,
		"mostGuestDate": "2023-03-09 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 176
	},
	{
		"id": "78af24b",
		"email": "marc@glassvillemusic.com",
		"name": "Marc",
		"createdDate": "2022-09-01 09:41:07",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 77,
		"totalCountries": 15,
		"totalStates": 23,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 528,
		"schedulePercent": 0.312012,
		"totalGuests": 159,
		"guestPercent": 0.521918,
		"mostGuestDate": "2023-05-03 00:00:00",
		"mostGuestName": "Festsaal Kreuzberg",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 15
	},
	{
		"id": "c447df3",
		"email": "adamcutlets@gmail.com",
		"name": "Adam",
		"createdDate": "2014-05-06 01:22:25",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 269,
		"totalCountries": 9,
		"totalStates": 39,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 3147,
		"schedulePercent": 0.865316,
		"totalGuests": 1867,
		"guestPercent": 0.926103,
		"mostGuestDate": "2023-01-13 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 146
	},
	{
		"id": "88fe37f",
		"email": "thaclassickid@gmail.com",
		"name": "Michael",
		"createdDate": "2012-04-23 20:49:01",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 371,
		"totalCountries": 3,
		"totalStates": 20,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 1127,
		"schedulePercent": 0.519269,
		"totalGuests": 430,
		"guestPercent": 0.664548,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 75
	},
	{
		"id": "cd9ba80",
		"email": "viviane@9evague.com",
		"name": "Viviane",
		"createdDate": "2019-05-27 18:54:01",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 129,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 311,
		"schedulePercent": 0.214144,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "ThÃ©Ã¢tre du Cuivre",
		"mostGuestCity": "Rouyn-Noranda",
		"mostGuestAmount": 7
	},
	{
		"id": "b47d14b",
		"email": "Lauren.m.mcshane@gmail.com",
		"name": "Lauren ",
		"createdDate": "2018-11-01 19:23:30",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 59,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 146,
		"schedulePercent": 0.117071,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "Weinberg Center for the Arts",
		"mostGuestCity": "Frederick",
		"mostGuestAmount": 2
	},
	{
		"id": "40dcd95",
		"email": "ben@itdevents.com",
		"name": "Benjamin",
		"createdDate": "2023-05-25 02:43:15",
		"totalOrganizations": 1,
		"totalTours": 12,
		"totalDays": 136,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 607,
		"schedulePercent": 0.34459,
		"totalGuests": 18,
		"guestPercent": 0.364455,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "Universal Sydney",
		"mostGuestCity": "Darlinghurst",
		"mostGuestAmount": 6
	},
	{
		"id": "2ae4afc",
		"email": "daniel.asip@acrophaserecords.com",
		"name": "Daniel",
		"createdDate": "2018-10-13 19:55:43",
		"totalOrganizations": 3,
		"totalTours": 16,
		"totalDays": 150,
		"totalCountries": 14,
		"totalStates": 26,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 416,
		"schedulePercent": 0.263144,
		"totalGuests": 89,
		"guestPercent": 0.462323,
		"mostGuestDate": "2023-09-27 00:00:00",
		"mostGuestName": "Lafayette",
		"mostGuestCity": "London",
		"mostGuestAmount": 50
	},
	{
		"id": "b9c8a2a",
		"email": "Timszymanski@hotmail.com",
		"name": "Tim",
		"createdDate": "2023-07-05 21:15:49",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 180,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 1778,
		"schedulePercent": 0.680705,
		"totalGuests": 1280,
		"guestPercent": 0.868892,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Raising Cane's River Center",
		"mostGuestCity": "Baton Rouge",
		"mostGuestAmount": 122
	},
	{
		"id": "8038422",
		"email": "eric@strictangel.com",
		"name": "Eric",
		"createdDate": "2009-06-03 22:18:14",
		"totalOrganizations": 8,
		"totalTours": 11,
		"totalDays": 724,
		"totalCountries": 18,
		"totalStates": 80,
		"totalEvents": 304,
		"eventPercent": 0.917892,
		"totalScheduleItems": 3485,
		"schedulePercent": 0.892994,
		"totalGuests": 2891,
		"guestPercent": 0.967951,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "The Riviera Theatre",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 95
	},
	{
		"id": "0208d83",
		"email": "dataentry@risingstartravel.biz",
		"name": "Lindsey",
		"createdDate": "2020-02-18 16:21:04",
		"totalOrganizations": 50,
		"totalTours": 54,
		"totalDays": 13351,
		"totalCountries": 26,
		"totalStates": 115,
		"totalEvents": 3342,
		"eventPercent": 0.999735,
		"totalScheduleItems": 47938,
		"schedulePercent": 0.999735,
		"totalGuests": 22506,
		"guestPercent": 0.999735,
		"mostGuestDate": "2023-11-21 00:00:00",
		"mostGuestName": "Wildhorse Saloon",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 283
	},
	{
		"id": "791c9de",
		"email": "tarafritz@me.com",
		"name": "Tara",
		"createdDate": "2012-10-06 06:13:34",
		"totalOrganizations": 11,
		"totalTours": 11,
		"totalDays": 782,
		"totalCountries": 15,
		"totalStates": 49,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 2435,
		"schedulePercent": 0.784664,
		"totalGuests": 840,
		"guestPercent": 0.785724,
		"mostGuestDate": "2023-09-12 00:00:00",
		"mostGuestName": "Concord Pavilion",
		"mostGuestCity": "Concord",
		"mostGuestAmount": 76
	},
	{
		"id": "9441c4e",
		"email": "samuelcrocker@me.com",
		"name": "Samuel",
		"createdDate": "2016-01-07 11:42:31",
		"totalOrganizations": 4,
		"totalTours": 12,
		"totalDays": 174,
		"totalCountries": 10,
		"totalStates": 57,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 1239,
		"schedulePercent": 0.549729,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-04-30 00:00:00",
		"mostGuestName": "Coors Event Centre",
		"mostGuestCity": "Saskatoon",
		"mostGuestAmount": 6
	},
	{
		"id": "032545c",
		"email": "Brush.chris@yahoo.com",
		"name": "Chris",
		"createdDate": "2018-11-29 01:04:13",
		"totalOrganizations": 6,
		"totalTours": 12,
		"totalDays": 512,
		"totalCountries": 6,
		"totalStates": 56,
		"totalEvents": 174,
		"eventPercent": 0.759502,
		"totalScheduleItems": 2109,
		"schedulePercent": 0.736459,
		"totalGuests": 465,
		"guestPercent": 0.678718,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "The Factory",
		"mostGuestCity": "Chesterfield",
		"mostGuestAmount": 29
	},
	{
		"id": "43d2625",
		"email": "marc@liteup.co.uk",
		"name": "Marc",
		"createdDate": "2017-07-04 18:35:36",
		"totalOrganizations": 8,
		"totalTours": 15,
		"totalDays": 744,
		"totalCountries": 21,
		"totalStates": 78,
		"totalEvents": 244,
		"eventPercent": 0.865713,
		"totalScheduleItems": 3995,
		"schedulePercent": 0.923057,
		"totalGuests": 216,
		"guestPercent": 0.560058,
		"mostGuestDate": "2023-03-16 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 52
	},
	{
		"id": "d33238d",
		"email": "vinnie@thebeehive.la",
		"name": "Vinnie",
		"createdDate": "2021-08-11 22:37:51",
		"totalOrganizations": 45,
		"totalTours": 48,
		"totalDays": 2361,
		"totalCountries": 30,
		"totalStates": 89,
		"totalEvents": 610,
		"eventPercent": 0.983049,
		"totalScheduleItems": 9975,
		"schedulePercent": 0.997086,
		"totalGuests": 4497,
		"guestPercent": 0.988081,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 300
	},
	{
		"id": "d8d2397",
		"email": "dgalarraga0789@gmail.com",
		"name": "Diego",
		"createdDate": "2019-09-26 22:11:48",
		"totalOrganizations": 5,
		"totalTours": 11,
		"totalDays": 268,
		"totalCountries": 5,
		"totalStates": 37,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 1070,
		"schedulePercent": 0.505099,
		"totalGuests": 396,
		"guestPercent": 0.652232,
		"mostGuestDate": "2023-11-29 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 33
	},
	{
		"id": "d295c9e",
		"email": "garrett@therelay.group",
		"name": "Garrett",
		"createdDate": "2022-04-15 18:37:10",
		"totalOrganizations": 8,
		"totalTours": 7,
		"totalDays": 2052,
		"totalCountries": 15,
		"totalStates": 57,
		"totalEvents": 262,
		"eventPercent": 0.887962,
		"totalScheduleItems": 3496,
		"schedulePercent": 0.893789,
		"totalGuests": 927,
		"guestPercent": 0.806118,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Saban Theatre",
		"mostGuestCity": "Beverly Hills",
		"mostGuestAmount": 118
	},
	{
		"id": "ab841e4",
		"email": "info@discobiscuits.com",
		"name": "Disco",
		"createdDate": "2014-06-03 21:29:59",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 213,
		"totalCountries": 1,
		"totalStates": 30,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 1481,
		"schedulePercent": 0.609456,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ea8fbed",
		"email": "mathewcb@me.com",
		"name": "Matt",
		"createdDate": "2012-09-04 07:32:14",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 1041,
		"totalCountries": 10,
		"totalStates": 56,
		"totalEvents": 297,
		"eventPercent": 0.914051,
		"totalScheduleItems": 5842,
		"schedulePercent": 0.978149,
		"totalGuests": 6328,
		"guestPercent": 0.996292,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 240
	},
	{
		"id": "a64ac49",
		"email": "lauren.domville@gmail.com",
		"name": "Lauren",
		"createdDate": "2021-03-30 11:42:06",
		"totalOrganizations": 15,
		"totalTours": 56,
		"totalDays": 370,
		"totalCountries": 19,
		"totalStates": 42,
		"totalEvents": 155,
		"eventPercent": 0.716197,
		"totalScheduleItems": 1451,
		"schedulePercent": 0.603894,
		"totalGuests": 339,
		"guestPercent": 0.622964,
		"mostGuestDate": "2023-02-21 00:00:00",
		"mostGuestName": "Millennium Forum Theatre & Conference Centre",
		"mostGuestCity": "Londonderry",
		"mostGuestAmount": 40
	},
	{
		"id": "05f4438",
		"email": "carolyn@newvillagemgmt.com",
		"name": "Carolyn",
		"createdDate": "2023-03-27 16:49:10",
		"totalOrganizations": 8,
		"totalTours": 13,
		"totalDays": 1674,
		"totalCountries": 18,
		"totalStates": 62,
		"totalEvents": 265,
		"eventPercent": 0.890743,
		"totalScheduleItems": 2091,
		"schedulePercent": 0.73381,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-09-07 00:00:00",
		"mostGuestName": "Rockport Music-Shalin Liu Performance Center",
		"mostGuestCity": "Rockport",
		"mostGuestAmount": 4
	},
	{
		"id": "d517e18",
		"email": "jordanyadigg@gmail.com",
		"name": "Jordan",
		"createdDate": "2014-10-26 00:48:42",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 486,
		"totalCountries": 15,
		"totalStates": 53,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 962,
		"schedulePercent": 0.469077,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "12ecae7",
		"email": "davesalt@me.com",
		"name": "Dave",
		"createdDate": "2015-11-27 20:17:43",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 271,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 70,
		"schedulePercent": 0.0686002,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e9fb7ef",
		"email": "rosie@lostmotel.com",
		"name": "Rosie ",
		"createdDate": "2020-02-09 22:09:43",
		"totalOrganizations": 25,
		"totalTours": 42,
		"totalDays": 1319,
		"totalCountries": 21,
		"totalStates": 104,
		"totalEvents": 567,
		"eventPercent": 0.980532,
		"totalScheduleItems": 6863,
		"schedulePercent": 0.988213,
		"totalGuests": 1828,
		"guestPercent": 0.922924,
		"mostGuestDate": "2023-06-01 00:00:00",
		"mostGuestName": "The Enmore Theatre",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 107
	},
	{
		"id": "eb82bb1",
		"email": "pete.abbott@gmail.com",
		"name": "Peter",
		"createdDate": "2012-01-19 08:37:43",
		"totalOrganizations": 34,
		"totalTours": 20,
		"totalDays": 994,
		"totalCountries": 21,
		"totalStates": 71,
		"totalEvents": 298,
		"eventPercent": 0.914448,
		"totalScheduleItems": 5925,
		"schedulePercent": 0.97934,
		"totalGuests": 954,
		"guestPercent": 0.813667,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 178
	},
	{
		"id": "c2acce2",
		"email": "evelina.eliasson@yaay.se",
		"name": "Evelina",
		"createdDate": "2022-12-06 08:01:45",
		"totalOrganizations": 47,
		"totalTours": 25,
		"totalDays": 488,
		"totalCountries": 9,
		"totalStates": 36,
		"totalEvents": 316,
		"eventPercent": 0.923719,
		"totalScheduleItems": 2916,
		"schedulePercent": 0.843994,
		"totalGuests": 354,
		"guestPercent": 0.632102,
		"mostGuestDate": "2023-04-23 00:00:00",
		"mostGuestName": "Uppsala Konsert & Kongress",
		"mostGuestCity": "Uppsala",
		"mostGuestAmount": 31
	},
	{
		"id": "2fd8676",
		"email": "jaron@taptouring.com",
		"name": "Jaron",
		"createdDate": "2019-10-07 20:15:42",
		"totalOrganizations": 15,
		"totalTours": 18,
		"totalDays": 562,
		"totalCountries": 5,
		"totalStates": 35,
		"totalEvents": 159,
		"eventPercent": 0.726394,
		"totalScheduleItems": 1658,
		"schedulePercent": 0.655145,
		"totalGuests": 922,
		"guestPercent": 0.804529,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "Islington Assembly Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 67
	},
	{
		"id": "1c348c2",
		"email": "Freeman@freemanpromotions.com",
		"name": "Jon",
		"createdDate": "2011-07-15 01:05:59",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 408,
		"totalCountries": 10,
		"totalStates": 60,
		"totalEvents": 181,
		"eventPercent": 0.771818,
		"totalScheduleItems": 2063,
		"schedulePercent": 0.729043,
		"totalGuests": 1446,
		"guestPercent": 0.88677,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Palladium Times Square",
		"mostGuestCity": "New York",
		"mostGuestAmount": 97
	},
	{
		"id": "911fe3f",
		"email": "scottbeale@mac.com",
		"name": "Scott",
		"createdDate": "2011-05-31 06:38:29",
		"totalOrganizations": 11,
		"totalTours": 5,
		"totalDays": 224,
		"totalCountries": 1,
		"totalStates": 36,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1807,
		"schedulePercent": 0.687194,
		"totalGuests": 1587,
		"guestPercent": 0.902132,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 137
	},
	{
		"id": "25ab937",
		"email": "takahikokatayama@icloud.com",
		"name": "Takahiko",
		"createdDate": "2023-03-16 00:53:13",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 88,
		"totalCountries": 8,
		"totalStates": 25,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 581,
		"schedulePercent": 0.332406,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-05-15 00:00:00",
		"mostGuestName": "Regent Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 10
	},
	{
		"id": "c394221",
		"email": "pruskijohn@gmail.com",
		"name": "John",
		"createdDate": "2023-06-19 16:33:51",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 53,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 441,
		"schedulePercent": 0.272944,
		"totalGuests": 94,
		"guestPercent": 0.469474,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "The Tap",
		"mostGuestCity": "College Station",
		"mostGuestAmount": 20
	},
	{
		"id": "30bb21d",
		"email": "Kevin@newislandgroup.com",
		"name": "Kevin",
		"createdDate": "2023-09-27 13:45:12",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 42,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 291,
		"schedulePercent": 0.203682,
		"totalGuests": 218,
		"guestPercent": 0.561515,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 24
	},
	{
		"id": "b2c71ac",
		"email": "omar.lrp@gmail.com",
		"name": "Omar",
		"createdDate": "2014-11-08 21:37:46",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 649,
		"totalCountries": 8,
		"totalStates": 46,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1798,
		"schedulePercent": 0.685737,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9862c61",
		"email": "MAMATIFF@GMAIL.COM",
		"name": "TIFFANY",
		"createdDate": "2020-01-22 22:09:22",
		"totalOrganizations": 2,
		"totalTours": 7,
		"totalDays": 118,
		"totalCountries": 17,
		"totalStates": 27,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 704,
		"schedulePercent": 0.382201,
		"totalGuests": 114,
		"guestPercent": 0.486028,
		"mostGuestDate": "2023-06-05 00:00:00",
		"mostGuestName": "Columbia Theater",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 29
	},
	{
		"id": "02a6048",
		"email": "steph@gryffinofficial.com",
		"name": "Stephanie",
		"createdDate": "2016-09-02 00:07:03",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 337,
		"totalCountries": 13,
		"totalStates": 36,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1406,
		"schedulePercent": 0.592902,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e619d58",
		"email": "cooper151@aol.com",
		"name": "Tony",
		"createdDate": "2016-06-28 21:56:19",
		"totalOrganizations": 8,
		"totalTours": 23,
		"totalDays": 604,
		"totalCountries": 10,
		"totalStates": 56,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 2090,
		"schedulePercent": 0.733678,
		"totalGuests": 1376,
		"guestPercent": 0.880678,
		"mostGuestDate": "2023-04-17 00:00:00",
		"mostGuestName": "The SSE Arena, Wembley",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 49
	},
	{
		"id": "47867f7",
		"email": "glenn@valiantprod.com",
		"name": "Glenn",
		"createdDate": "2019-04-07 00:21:56",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 673,
		"totalCountries": 7,
		"totalStates": 29,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 138,
		"schedulePercent": 0.112171,
		"totalGuests": 908,
		"guestPercent": 0.801218,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 58
	},
	{
		"id": "6f0e9ba",
		"email": "johanna@husetunderbron.se",
		"name": "Johanna",
		"createdDate": "2019-03-21 15:06:01",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 194,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 1140,
		"schedulePercent": 0.523507,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "76fe8ce",
		"email": "tourmanager@erniehaase.com",
		"name": "Ernie",
		"createdDate": "2022-08-15 01:14:51",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 316,
		"totalCountries": 4,
		"totalStates": 18,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 492,
		"schedulePercent": 0.293604,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "77751bd",
		"email": "nickjhardy@gmail.com",
		"name": "Nick",
		"createdDate": "2012-02-21 17:38:38",
		"totalOrganizations": 14,
		"totalTours": 16,
		"totalDays": 953,
		"totalCountries": 12,
		"totalStates": 56,
		"totalEvents": 248,
		"eventPercent": 0.869554,
		"totalScheduleItems": 4260,
		"schedulePercent": 0.935903,
		"totalGuests": 3080,
		"guestPercent": 0.970203,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 187
	},
	{
		"id": "da386e2",
		"email": "managejim@gmail.com",
		"name": "Jimmy",
		"createdDate": "2022-06-29 23:11:32",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 95,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 825,
		"schedulePercent": 0.428023,
		"totalGuests": 715,
		"guestPercent": 0.756191,
		"mostGuestDate": "2023-02-28 00:00:00",
		"mostGuestName": "Come and Take It Live",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 32
	},
	{
		"id": "be0183f",
		"email": "epsheppard13@gmail.com",
		"name": "Emily",
		"createdDate": "2019-09-11 15:09:38",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 144,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 484,
		"schedulePercent": 0.290558,
		"totalGuests": 21,
		"guestPercent": 0.371342,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "FirstOntario Centre",
		"mostGuestCity": "Hamilton",
		"mostGuestAmount": 10
	},
	{
		"id": "1955c7c",
		"email": "lornehounsell@gmail.com",
		"name": "Lorne",
		"createdDate": "2018-11-17 02:42:00",
		"totalOrganizations": 4,
		"totalTours": 12,
		"totalDays": 534,
		"totalCountries": 9,
		"totalStates": 26,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 1274,
		"schedulePercent": 0.557674,
		"totalGuests": 46,
		"guestPercent": 0.415044,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "Burton Cummings Theatre",
		"mostGuestCity": "Winnipeg",
		"mostGuestAmount": 15
	},
	{
		"id": "6b30216",
		"email": "seb@pls-designer.com",
		"name": "Seb",
		"createdDate": "2020-01-30 21:52:54",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 87,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 723,
		"schedulePercent": 0.38975,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "297888b",
		"email": "jesse@aldulaimi.co",
		"name": "Jesse",
		"createdDate": "2013-03-15 18:50:21",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 868,
		"totalCountries": 21,
		"totalStates": 66,
		"totalEvents": 192,
		"eventPercent": 0.791683,
		"totalScheduleItems": 2958,
		"schedulePercent": 0.848232,
		"totalGuests": 1102,
		"guestPercent": 0.839756,
		"mostGuestDate": "2023-08-01 00:00:00",
		"mostGuestName": "The Bellweather",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 82
	},
	{
		"id": "f9dc0a5",
		"email": "jaredpettinato@icloud.com",
		"name": "Jared",
		"createdDate": "2021-08-27 17:32:37",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 26,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 139,
		"schedulePercent": 0.113362,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6d4d8cb",
		"email": "dobrawaczocher@gmail.com",
		"name": "Dobrawa",
		"createdDate": "2023-11-22 21:07:46",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 38,
		"totalCountries": 4,
		"totalStates": 8,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 221,
		"schedulePercent": 0.163952,
		"totalGuests": 75,
		"guestPercent": 0.450007,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "Huxleys Neue Welt",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 48
	},
	{
		"id": "60f3a33",
		"email": "chadly48854@yahoo.com",
		"name": "CHAD",
		"createdDate": "2013-04-26 23:51:34",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 908,
		"totalCountries": 8,
		"totalStates": 36,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 345,
		"schedulePercent": 0.23083,
		"totalGuests": 115,
		"guestPercent": 0.48722,
		"mostGuestDate": "2023-07-19 00:00:00",
		"mostGuestName": "Huntington Center",
		"mostGuestCity": "Toledo",
		"mostGuestAmount": 41
	},
	{
		"id": "b30886d",
		"email": "ff.octavio@gmail.com",
		"name": "Octavio",
		"createdDate": "2022-08-09 01:08:42",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 46,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 185,
		"schedulePercent": 0.142233,
		"totalGuests": 111,
		"guestPercent": 0.483115,
		"mostGuestDate": "2023-05-17 00:00:00",
		"mostGuestName": "The Crocodile",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 44
	},
	{
		"id": "8a1dd98",
		"email": "verinabanks@yahoo.com",
		"name": "Verina",
		"createdDate": "2023-09-19 18:11:13",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 59,
		"totalCountries": 1,
		"totalStates": 18,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 637,
		"schedulePercent": 0.357171,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "01677bf",
		"email": "derekbirrell@gmail.com",
		"name": "Derek",
		"createdDate": "2019-05-31 17:21:04",
		"totalOrganizations": 5,
		"totalTours": 33,
		"totalDays": 288,
		"totalCountries": 14,
		"totalStates": 50,
		"totalEvents": 160,
		"eventPercent": 0.72838,
		"totalScheduleItems": 2367,
		"schedulePercent": 0.776056,
		"totalGuests": 680,
		"guestPercent": 0.748378,
		"mostGuestDate": "2023-03-28 00:00:00",
		"mostGuestName": "Electric Brixton",
		"mostGuestCity": "London",
		"mostGuestAmount": 79
	},
	{
		"id": "99a8349",
		"email": "ludolemap@orange.fr",
		"name": "Ludovic",
		"createdDate": "2018-10-05 16:52:32",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 522,
		"totalCountries": 4,
		"totalStates": 15,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 773,
		"schedulePercent": 0.410012,
		"totalGuests": 45,
		"guestPercent": 0.412925,
		"mostGuestDate": "2023-01-29 00:00:00",
		"mostGuestName": "Le Nouveau SiÃ¨cle",
		"mostGuestCity": "Lille",
		"mostGuestAmount": 14
	},
	{
		"id": "a127916",
		"email": "lisa.mullineux@opera.org.au",
		"name": "Lisa",
		"createdDate": "2022-04-13 00:27:53",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 71,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 421,
		"schedulePercent": 0.264468,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "142979d",
		"email": "davebruster@aol.com",
		"name": "Dave",
		"createdDate": "2010-01-18 18:39:38",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 74,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 654,
		"schedulePercent": 0.363528,
		"totalGuests": 264,
		"guestPercent": 0.58628,
		"mostGuestDate": "2023-05-08 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 31
	},
	{
		"id": "a9356cb",
		"email": "david@globaltouringservices.com",
		"name": "david",
		"createdDate": "2018-04-05 11:45:59",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 181,
		"totalCountries": 7,
		"totalStates": 30,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 990,
		"schedulePercent": 0.480201,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "760555a",
		"email": "ASwovelin@blueman.com",
		"name": "Alanna",
		"createdDate": "2023-02-09 21:01:41",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 209,
		"totalCountries": 4,
		"totalStates": 9,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 1052,
		"schedulePercent": 0.498345,
		"totalGuests": 18,
		"guestPercent": 0.364455,
		"mostGuestDate": "2023-04-13 00:00:00",
		"mostGuestName": "QUARTERBACK Immobilien ARENA",
		"mostGuestCity": "Leipzig",
		"mostGuestAmount": 10
	},
	{
		"id": "adb33e9",
		"email": "drew@foundationsmusic.com",
		"name": "Drew",
		"createdDate": "2011-03-22 18:52:40",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 646,
		"totalCountries": 6,
		"totalStates": 48,
		"totalEvents": 186,
		"eventPercent": 0.782016,
		"totalScheduleItems": 3473,
		"schedulePercent": 0.892332,
		"totalGuests": 2934,
		"guestPercent": 0.968746,
		"mostGuestDate": "2023-10-03 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 104
	},
	{
		"id": "481cf7b",
		"email": "rhenley@rocnation.com",
		"name": "Rod",
		"createdDate": "2022-06-12 15:20:06",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 261,
		"totalCountries": 11,
		"totalStates": 25,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 817,
		"schedulePercent": 0.425374,
		"totalGuests": 366,
		"guestPercent": 0.639386,
		"mostGuestDate": "2023-07-25 00:00:00",
		"mostGuestName": "Hordern Pavilion",
		"mostGuestCity": "Moore Park",
		"mostGuestAmount": 90
	},
	{
		"id": "66f91e3",
		"email": "Georgedoherty2@gmail.com",
		"name": "George",
		"createdDate": "2014-10-15 07:33:39",
		"totalOrganizations": 10,
		"totalTours": 25,
		"totalDays": 1169,
		"totalCountries": 21,
		"totalStates": 72,
		"totalEvents": 454,
		"eventPercent": 0.968084,
		"totalScheduleItems": 5265,
		"schedulePercent": 0.969276,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "GUNNERSBURY PARK",
		"mostGuestCity": "LONDON",
		"mostGuestAmount": 1
	},
	{
		"id": "a0a9d8b",
		"email": "jnvlahos@gmail.com",
		"name": "Jon",
		"createdDate": "2019-10-01 14:04:59",
		"totalOrganizations": 10,
		"totalTours": 38,
		"totalDays": 976,
		"totalCountries": 8,
		"totalStates": 50,
		"totalEvents": 606,
		"eventPercent": 0.982916,
		"totalScheduleItems": 5578,
		"schedulePercent": 0.974573,
		"totalGuests": 891,
		"guestPercent": 0.79751,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "Music Hall Center for the Performing Arts",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 63
	},
	{
		"id": "f865ef9",
		"email": "ry@eighteentwentysix.com",
		"name": "Rylan",
		"createdDate": "2018-10-14 23:48:23",
		"totalOrganizations": 10,
		"totalTours": 15,
		"totalDays": 2037,
		"totalCountries": 20,
		"totalStates": 58,
		"totalEvents": 215,
		"eventPercent": 0.831016,
		"totalScheduleItems": 3335,
		"schedulePercent": 0.881605,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "UshuaÃ¯a Beach Club Ibiza",
		"mostGuestCity": "Sant Josep de sa Talaia",
		"mostGuestAmount": 3
	},
	{
		"id": "f4f9a66",
		"email": "flimmindaflaydo@hotmail.co.uk",
		"name": "Andrea",
		"createdDate": "2022-03-06 18:07:33",
		"totalOrganizations": 3,
		"totalTours": 10,
		"totalDays": 195,
		"totalCountries": 12,
		"totalStates": 44,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 989,
		"schedulePercent": 0.479804,
		"totalGuests": 903,
		"guestPercent": 0.800424,
		"mostGuestDate": "2023-11-21 00:00:00",
		"mostGuestName": "Forum Melbourne",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 69
	},
	{
		"id": "009d436",
		"email": "aaron@morrishigham.com",
		"name": "Aaron",
		"createdDate": "2017-08-09 21:44:31",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 3,
		"totalStates": 45,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1593,
		"schedulePercent": 0.641372,
		"totalGuests": 1540,
		"guestPercent": 0.898556,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 57
	},
	{
		"id": "45cd863",
		"email": "gillesledouaron@me.com",
		"name": "Gilles",
		"createdDate": "2019-09-20 12:19:57",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 199,
		"totalCountries": 3,
		"totalStates": 15,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 978,
		"schedulePercent": 0.474772,
		"totalGuests": 365,
		"guestPercent": 0.638591,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "U Palatinu",
		"mostGuestCity": "Ajaccio",
		"mostGuestAmount": 52
	},
	{
		"id": "99d21bf",
		"email": "kirk.harrington@gmail.com",
		"name": "Kirk",
		"createdDate": "2011-11-22 14:28:29",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 182,
		"totalCountries": 10,
		"totalStates": 43,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 1515,
		"schedulePercent": 0.622037,
		"totalGuests": 1266,
		"guestPercent": 0.867435,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 70
	},
	{
		"id": "f22cd36",
		"email": "ghenevamae@gmail.com",
		"name": "Gheneva-Mae",
		"createdDate": "2020-03-11 16:01:31",
		"totalOrganizations": 12,
		"totalTours": 22,
		"totalDays": 1214,
		"totalCountries": 15,
		"totalStates": 49,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 1293,
		"schedulePercent": 0.564429,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-02-05 00:00:00",
		"mostGuestName": "Ancienne Belgique",
		"mostGuestCity": "Brussel",
		"mostGuestAmount": 10
	},
	{
		"id": "ec1422f",
		"email": "simonjosephparkinson@gmail.com",
		"name": "Simon",
		"createdDate": "2018-08-01 06:44:45",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 404,
		"totalCountries": 15,
		"totalStates": 68,
		"totalEvents": 195,
		"eventPercent": 0.796318,
		"totalScheduleItems": 1961,
		"schedulePercent": 0.713415,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0e75791",
		"email": "jaydub@me.com",
		"name": "John",
		"createdDate": "2009-06-04 16:20:54",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 280,
		"totalCountries": 14,
		"totalStates": 55,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 1894,
		"schedulePercent": 0.703218,
		"totalGuests": 607,
		"guestPercent": 0.728248,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "The Smith Center- Reynolds Hall",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 49
	},
	{
		"id": "46ba784",
		"email": "foggy@gcdphotography.com",
		"name": "Grace",
		"createdDate": "2017-04-29 14:32:28",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 120,
		"totalCountries": 7,
		"totalStates": 14,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 293,
		"schedulePercent": 0.204609,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-03-29 00:00:00",
		"mostGuestName": "Logo",
		"mostGuestCity": "Hamburg",
		"mostGuestAmount": 5
	},
	{
		"id": "e261840",
		"email": "m.gough@troopersdrumcorps.org",
		"name": "Michael",
		"createdDate": "2021-05-19 01:39:08",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 82,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 746,
		"schedulePercent": 0.400212,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "899bdd2",
		"email": "erisondan744@gmail.com",
		"name": "Dan",
		"createdDate": "2023-05-17 12:54:31",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 99,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 695,
		"schedulePercent": 0.37889,
		"totalGuests": 772,
		"guestPercent": 0.770097,
		"mostGuestDate": "2023-11-05 00:00:00",
		"mostGuestName": "The Concert Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 112
	},
	{
		"id": "112939f",
		"email": "Majabaeckman@icloud.com",
		"name": "Maja",
		"createdDate": "2022-06-15 14:06:36",
		"totalOrganizations": 7,
		"totalTours": 4,
		"totalDays": 198,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 596,
		"schedulePercent": 0.338763,
		"totalGuests": 168,
		"guestPercent": 0.528804,
		"mostGuestDate": "2023-04-23 00:00:00",
		"mostGuestName": "Uppsala Konsert & Kongress",
		"mostGuestCity": "Uppsala",
		"mostGuestAmount": 31
	},
	{
		"id": "0c986b9",
		"email": "chris@freelantzproductions.com",
		"name": "Chris",
		"createdDate": "2019-02-13 05:07:45",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 363,
		"totalCountries": 8,
		"totalStates": 35,
		"totalEvents": 137,
		"eventPercent": 0.666402,
		"totalScheduleItems": 1250,
		"schedulePercent": 0.552377,
		"totalGuests": 599,
		"guestPercent": 0.725864,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Toyota Center",
		"mostGuestCity": "Houston",
		"mostGuestAmount": 123
	},
	{
		"id": "1a1d8af",
		"email": "Philbroad60@gmail.com",
		"name": "Phil",
		"createdDate": "2017-11-12 17:44:26",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 406,
		"totalCountries": 19,
		"totalStates": 47,
		"totalEvents": 286,
		"eventPercent": 0.906502,
		"totalScheduleItems": 3492,
		"schedulePercent": 0.893392,
		"totalGuests": 6182,
		"guestPercent": 0.995365,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Olympiastadion Berlin",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 794
	},
	{
		"id": "fd2e771",
		"email": "sc@softcult.band",
		"name": "Chris",
		"createdDate": "2022-08-27 03:50:19",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 406,
		"totalCountries": 14,
		"totalStates": 60,
		"totalEvents": 196,
		"eventPercent": 0.798835,
		"totalScheduleItems": 2204,
		"schedulePercent": 0.750762,
		"totalGuests": 161,
		"guestPercent": 0.523374,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "The Drake Hotel",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 34
	},
	{
		"id": "875b572",
		"email": "teoriasavulso.lda@gmail.com",
		"name": "Paulo",
		"createdDate": "2023-04-10 10:34:40",
		"totalOrganizations": 2,
		"totalTours": 19,
		"totalDays": 55,
		"totalCountries": 4,
		"totalStates": 9,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 477,
		"schedulePercent": 0.288041,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-05-12 00:00:00",
		"mostGuestName": "Volkshaus",
		"mostGuestCity": "Zurich",
		"mostGuestAmount": 4
	},
	{
		"id": "d0c2121",
		"email": "thomas@weezer.com",
		"name": "Thomas",
		"createdDate": "2016-05-20 20:58:02",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 699,
		"totalCountries": 5,
		"totalStates": 42,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 1519,
		"schedulePercent": 0.622964,
		"totalGuests": 2310,
		"guestPercent": 0.948086,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "Forest Hills Stadium",
		"mostGuestCity": "Forest Hills",
		"mostGuestAmount": 184
	},
	{
		"id": "6e2f133",
		"email": "tourmanager@hannahdasher.com",
		"name": "Hannah",
		"createdDate": "2023-08-09 18:36:29",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 121,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 176,
		"schedulePercent": 0.136008,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "769477d",
		"email": "dsoshaughnessy@gmail.com",
		"name": "Dean",
		"createdDate": "2017-07-24 15:01:54",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 199,
		"totalCountries": 1,
		"totalStates": 38,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 794,
		"schedulePercent": 0.41809,
		"totalGuests": 39,
		"guestPercent": 0.404185,
		"mostGuestDate": "2023-05-10 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 19
	},
	{
		"id": "a0e2365",
		"email": "juan@ravenhouseltd.com",
		"name": "Juan",
		"createdDate": "2009-12-03 16:56:32",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 100,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 658,
		"schedulePercent": 0.364455,
		"totalGuests": 498,
		"guestPercent": 0.691034,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "Stubb's Bar-B-Q",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 45
	},
	{
		"id": "2bd5fd9",
		"email": "headandtheheartlogins@gmail.com",
		"name": "THATH",
		"createdDate": "2021-07-20 15:19:06",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 376,
		"totalCountries": 2,
		"totalStates": 41,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 1040,
		"schedulePercent": 0.494769,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a5c90f3",
		"email": "johnson.lisa@mac.com",
		"name": "Lisa",
		"createdDate": "2018-03-28 13:22:13",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 39,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 120,
		"schedulePercent": 0.102635,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "dfdaca1",
		"email": "callum@clmoore.co.uk",
		"name": "Callum",
		"createdDate": "2019-10-16 15:18:48",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 298,
		"totalCountries": 11,
		"totalStates": 30,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 1360,
		"schedulePercent": 0.583102,
		"totalGuests": 229,
		"guestPercent": 0.568402,
		"mostGuestDate": "2023-06-15 00:00:00",
		"mostGuestName": "Lafayette",
		"mostGuestCity": "London",
		"mostGuestAmount": 48
	},
	{
		"id": "a8c74d3",
		"email": "roland@safehavenmgt.com",
		"name": "Roland",
		"createdDate": "2017-10-14 18:42:19",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 328,
		"totalCountries": 1,
		"totalStates": 27,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 3106,
		"schedulePercent": 0.862402,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2402e92",
		"email": "shane@hollytucker.com",
		"name": "Shane",
		"createdDate": "2017-02-09 17:38:58",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 366,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 349,
		"schedulePercent": 0.232684,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f0c6246",
		"email": "a.raquel.cooper@gmail.com",
		"name": "Ashley",
		"createdDate": "2018-11-21 18:11:04",
		"totalOrganizations": 17,
		"totalTours": 15,
		"totalDays": 2829,
		"totalCountries": 20,
		"totalStates": 70,
		"totalEvents": 435,
		"eventPercent": 0.9657,
		"totalScheduleItems": 6754,
		"schedulePercent": 0.987154,
		"totalGuests": 1676,
		"guestPercent": 0.910343,
		"mostGuestDate": "2023-05-03 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 125
	},
	{
		"id": "bda5c4c",
		"email": "adammillar99@gmail.com",
		"name": "Adam",
		"createdDate": "2022-10-10 08:56:00",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 442,
		"totalCountries": 8,
		"totalStates": 17,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 1369,
		"schedulePercent": 0.585088,
		"totalGuests": 322,
		"guestPercent": 0.614753,
		"mostGuestDate": "2023-09-07 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 59
	},
	{
		"id": "e085686",
		"email": "sjpriebe@gmail.com",
		"name": "Shawn",
		"createdDate": "2022-07-18 00:13:27",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 364,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 1404,
		"schedulePercent": 0.592372,
		"totalGuests": 978,
		"guestPercent": 0.818832,
		"mostGuestDate": "2023-11-21 00:00:00",
		"mostGuestName": "Wildhorse Saloon",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 283
	},
	{
		"id": "d2ce12d",
		"email": "ella@banishedmusic.com",
		"name": "Ella",
		"createdDate": "2022-04-21 03:10:12",
		"totalOrganizations": 34,
		"totalTours": 17,
		"totalDays": 113,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 442,
		"schedulePercent": 0.273606,
		"totalGuests": 389,
		"guestPercent": 0.648656,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "Powerstation",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 61
	},
	{
		"id": "c320da2",
		"email": "Kelsey.waldon@gmail.com",
		"name": "Kelsey ",
		"createdDate": "2016-06-30 16:16:24",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 465,
		"totalCountries": 5,
		"totalStates": 32,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 360,
		"schedulePercent": 0.237982,
		"totalGuests": 118,
		"guestPercent": 0.489207,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "Southern Alberta Jubilee Auditorium",
		"mostGuestCity": "Calgary",
		"mostGuestAmount": 18
	},
	{
		"id": "6d334f7",
		"email": "BandsVisitCM@NetworksTours.com",
		"name": "Chris",
		"createdDate": "2021-08-08 23:29:12",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 358,
		"eventPercent": 0.945305,
		"totalScheduleItems": 3288,
		"schedulePercent": 0.876837,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c44034e",
		"email": "vanmoran@msn.com",
		"name": "Brian",
		"createdDate": "2012-11-01 01:19:17",
		"totalOrganizations": 13,
		"totalTours": 22,
		"totalDays": 752,
		"totalCountries": 16,
		"totalStates": 71,
		"totalEvents": 275,
		"eventPercent": 0.897629,
		"totalScheduleItems": 3751,
		"schedulePercent": 0.909548,
		"totalGuests": 2634,
		"guestPercent": 0.956827,
		"mostGuestDate": "2023-05-18 00:00:00",
		"mostGuestName": "Mohegan Sun Arena At Casey Plaza",
		"mostGuestCity": "Wilkes-Barre",
		"mostGuestAmount": 98
	},
	{
		"id": "d7f8d10",
		"email": "doghouse.tcp@gmail.com",
		"name": "Dog",
		"createdDate": "2022-01-02 06:46:27",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 71,
		"totalCountries": 5,
		"totalStates": 21,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 71,
		"schedulePercent": 0.0688651,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-10-10 00:00:00",
		"mostGuestName": "Tumbleroot Brewery and Distillery",
		"mostGuestCity": "Santa Fe",
		"mostGuestAmount": 11
	},
	{
		"id": "f679081",
		"email": "pam@travelstars.net",
		"name": "Pam",
		"createdDate": "2016-02-13 15:38:06",
		"totalOrganizations": 2,
		"totalTours": 10,
		"totalDays": 306,
		"totalCountries": 4,
		"totalStates": 48,
		"totalEvents": 182,
		"eventPercent": 0.773408,
		"totalScheduleItems": 2856,
		"schedulePercent": 0.838564,
		"totalGuests": 2371,
		"guestPercent": 0.949543,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Paycom Center",
		"mostGuestCity": "Oklahoma City",
		"mostGuestAmount": 167
	},
	{
		"id": "ce9d19c",
		"email": "Ttuckaho@icloud.com",
		"name": "T",
		"createdDate": "2017-08-08 16:10:18",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 296,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 741,
		"schedulePercent": 0.396636,
		"totalGuests": 179,
		"guestPercent": 0.535426,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Billy Bob's Texas",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 56
	},
	{
		"id": "f364052",
		"email": "bas@blackricebooking.com",
		"name": "Bas ",
		"createdDate": "2017-11-07 15:32:59",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 144,
		"totalCountries": 15,
		"totalStates": 33,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 742,
		"schedulePercent": 0.397431,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-08-29 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 2
	},
	{
		"id": "102f453",
		"email": "rob@hoffstar.com",
		"name": "Robby",
		"createdDate": "2012-03-27 16:49:00",
		"totalOrganizations": 10,
		"totalTours": 13,
		"totalDays": 436,
		"totalCountries": 18,
		"totalStates": 66,
		"totalEvents": 182,
		"eventPercent": 0.773408,
		"totalScheduleItems": 1929,
		"schedulePercent": 0.707588,
		"totalGuests": 1374,
		"guestPercent": 0.880413,
		"mostGuestDate": "2023-08-05 00:00:00",
		"mostGuestName": "Roadrunner",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 71
	},
	{
		"id": "660f24b",
		"email": "danieljlewis002@gmail.com",
		"name": "Daniel",
		"createdDate": "2017-04-24 18:07:23",
		"totalOrganizations": 8,
		"totalTours": 3,
		"totalDays": 725,
		"totalCountries": 6,
		"totalStates": 53,
		"totalEvents": 204,
		"eventPercent": 0.813005,
		"totalScheduleItems": 5664,
		"schedulePercent": 0.975765,
		"totalGuests": 2091,
		"guestPercent": 0.939478,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Mattress Firm Amphitheatre",
		"mostGuestCity": "Chula Vista",
		"mostGuestAmount": 136
	},
	{
		"id": "2563b45",
		"email": "mariodrubio@gmail.com",
		"name": "Mario",
		"createdDate": "2018-08-10 06:28:16",
		"totalOrganizations": 22,
		"totalTours": 22,
		"totalDays": 736,
		"totalCountries": 28,
		"totalStates": 101,
		"totalEvents": 308,
		"eventPercent": 0.919746,
		"totalScheduleItems": 4394,
		"schedulePercent": 0.941067,
		"totalGuests": 1657,
		"guestPercent": 0.908224,
		"mostGuestDate": "2023-01-15 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 63
	},
	{
		"id": "73dbb1d",
		"email": "benn@555productionhouse.com",
		"name": "Benn",
		"createdDate": "2022-11-26 01:09:14",
		"totalOrganizations": 4,
		"totalTours": 12,
		"totalDays": 95,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 269,
		"schedulePercent": 0.191498,
		"totalGuests": 263,
		"guestPercent": 0.585618,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "The Triffid",
		"mostGuestCity": "Newstead",
		"mostGuestAmount": 83
	},
	{
		"id": "0c70d59",
		"email": "DavidWilsonTMFOH@gmail.com",
		"name": "David",
		"createdDate": "2019-04-03 23:05:19",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 89,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 640,
		"schedulePercent": 0.358496,
		"totalGuests": 200,
		"guestPercent": 0.550258,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 38
	},
	{
		"id": "2464e67",
		"email": "Mrryanseaman@gmail.com",
		"name": "Ryan",
		"createdDate": "2016-10-05 03:17:00",
		"totalOrganizations": 13,
		"totalTours": 8,
		"totalDays": 190,
		"totalCountries": 3,
		"totalStates": 43,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 1669,
		"schedulePercent": 0.657396,
		"totalGuests": 1017,
		"guestPercent": 0.82744,
		"mostGuestDate": "2023-04-11 00:00:00",
		"mostGuestName": "Summit Music Hall",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 63
	},
	{
		"id": "77cb8b5",
		"email": "chrisdouridas@gmail.com",
		"name": "Chris",
		"createdDate": "2022-06-06 17:27:25",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 310,
		"totalCountries": 6,
		"totalStates": 40,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 801,
		"schedulePercent": 0.420871,
		"totalGuests": 212,
		"guestPercent": 0.558469,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 50
	},
	{
		"id": "2de1b47",
		"email": "flynnhannon@gmail.com",
		"name": "Flynn",
		"createdDate": "2023-10-04 19:59:42",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 46,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 214,
		"schedulePercent": 0.159184,
		"totalGuests": 174,
		"guestPercent": 0.532115,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 28
	},
	{
		"id": "9fc9d87",
		"email": "joshuarossmusic@gmail.com",
		"name": "Kyle",
		"createdDate": "2023-01-12 17:24:33",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 479,
		"totalCountries": 3,
		"totalStates": 48,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 922,
		"schedulePercent": 0.457555,
		"totalGuests": 63,
		"guestPercent": 0.437558,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Sydney Showgrounds",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 17
	},
	{
		"id": "5ab23fe",
		"email": "markakagus@me.com",
		"name": "Mark",
		"createdDate": "2012-03-15 21:03:49",
		"totalOrganizations": 11,
		"totalTours": 10,
		"totalDays": 342,
		"totalCountries": 32,
		"totalStates": 78,
		"totalEvents": 189,
		"eventPercent": 0.78718,
		"totalScheduleItems": 3140,
		"schedulePercent": 0.865051,
		"totalGuests": 1164,
		"guestPercent": 0.851808,
		"mostGuestDate": "2023-04-15 00:00:00",
		"mostGuestName": "OVO Arena",
		"mostGuestCity": "London",
		"mostGuestAmount": 113
	},
	{
		"id": "6b3d569",
		"email": "jackiezyseah@gmail.com",
		"name": "Jackie",
		"createdDate": "2023-03-25 00:28:18",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 243,
		"totalCountries": 4,
		"totalStates": 37,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 1226,
		"schedulePercent": 0.546815,
		"totalGuests": 65,
		"guestPercent": 0.440207,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Patchogue Theatre for the Performing Arts",
		"mostGuestCity": "Patchogue",
		"mostGuestAmount": 20
	},
	{
		"id": "d5fc764",
		"email": "mail@wedomusic.dk",
		"name": "Ulrik",
		"createdDate": "2019-01-21 12:54:43",
		"totalOrganizations": 42,
		"totalTours": 32,
		"totalDays": 326,
		"totalCountries": 3,
		"totalStates": 12,
		"totalEvents": 318,
		"eventPercent": 0.924778,
		"totalScheduleItems": 1485,
		"schedulePercent": 0.610648,
		"totalGuests": 574,
		"guestPercent": 0.718051,
		"mostGuestDate": "2023-02-23 00:00:00",
		"mostGuestName": "Rust",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 37
	},
	{
		"id": "0b12fcb",
		"email": "eryn@propermgmt.com",
		"name": "Eryn",
		"createdDate": "2023-01-03 21:37:46",
		"totalOrganizations": 20,
		"totalTours": 19,
		"totalDays": 1424,
		"totalCountries": 5,
		"totalStates": 51,
		"totalEvents": 525,
		"eventPercent": 0.976957,
		"totalScheduleItems": 8418,
		"schedulePercent": 0.994968,
		"totalGuests": 2192,
		"guestPercent": 0.943319,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 293
	},
	{
		"id": "fc63959",
		"email": "kimberly@maddjett.com",
		"name": "Kimberly",
		"createdDate": "2019-01-14 22:26:18",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 538,
		"totalCountries": 3,
		"totalStates": 37,
		"totalEvents": 254,
		"eventPercent": 0.879619,
		"totalScheduleItems": 5252,
		"schedulePercent": 0.968746,
		"totalGuests": 2542,
		"guestPercent": 0.95537,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Raising Cane's River Center",
		"mostGuestCity": "Baton Rouge",
		"mostGuestAmount": 122
	},
	{
		"id": "2b12b79",
		"email": "jess@wwt.earth",
		"name": "Jess",
		"createdDate": "2023-01-31 23:45:14",
		"totalOrganizations": 8,
		"totalTours": 16,
		"totalDays": 619,
		"totalCountries": 12,
		"totalStates": 41,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1036,
		"schedulePercent": 0.493709,
		"totalGuests": 400,
		"guestPercent": 0.653291,
		"mostGuestDate": "2023-03-23 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 62
	},
	{
		"id": "4346c78",
		"email": "martybrownsound@icloud.com",
		"name": "Marty",
		"createdDate": "2015-10-24 09:42:45",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 164,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 773,
		"schedulePercent": 0.410012,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Midnattsrocken / Midnight rock festival",
		"mostGuestCity": "Lakselv",
		"mostGuestAmount": 0
	},
	{
		"id": "0773f93",
		"email": "Chasegmeyer@yahoo.com",
		"name": "Chase ",
		"createdDate": "2016-10-31 18:07:59",
		"totalOrganizations": 8,
		"totalTours": 14,
		"totalDays": 1938,
		"totalCountries": 15,
		"totalStates": 61,
		"totalEvents": 430,
		"eventPercent": 0.964111,
		"totalScheduleItems": 9170,
		"schedulePercent": 0.996292,
		"totalGuests": 2006,
		"guestPercent": 0.933784,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "The Brooklyn Mirage",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 179
	},
	{
		"id": "fd9b1e8",
		"email": "oliver@showlab.no",
		"name": "Oliver ",
		"createdDate": "2022-10-05 11:02:55",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 23,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 161,
		"schedulePercent": 0.127135,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e7c59e0",
		"email": "heytaraanne@gmail.com",
		"name": "Tara",
		"createdDate": "2011-09-08 02:50:03",
		"totalOrganizations": 21,
		"totalTours": 14,
		"totalDays": 513,
		"totalCountries": 17,
		"totalStates": 50,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 1579,
		"schedulePercent": 0.636472,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e4687f7",
		"email": "jbtourmanager@gmail.com",
		"name": "John",
		"createdDate": "2012-06-12 18:09:34",
		"totalOrganizations": 7,
		"totalTours": 17,
		"totalDays": 349,
		"totalCountries": 7,
		"totalStates": 53,
		"totalEvents": 179,
		"eventPercent": 0.769037,
		"totalScheduleItems": 1795,
		"schedulePercent": 0.684545,
		"totalGuests": 75,
		"guestPercent": 0.450007,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "Fox Theater",
		"mostGuestCity": "Oakland",
		"mostGuestAmount": 51
	},
	{
		"id": "a63b8ac",
		"email": "andy@thehomeslicegroup.com",
		"name": " ",
		"createdDate": "2019-05-29 16:44:56",
		"totalOrganizations": 8,
		"totalTours": 3,
		"totalDays": 509,
		"totalCountries": 1,
		"totalStates": 23,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 635,
		"schedulePercent": 0.356112,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2492f07",
		"email": "noah.brown2@gmail.com",
		"name": "Noah",
		"createdDate": "2010-06-06 15:10:07",
		"totalOrganizations": 18,
		"totalTours": 9,
		"totalDays": 1925,
		"totalCountries": 11,
		"totalStates": 65,
		"totalEvents": 296,
		"eventPercent": 0.912992,
		"totalScheduleItems": 4302,
		"schedulePercent": 0.937757,
		"totalGuests": 1178,
		"guestPercent": 0.853529,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 92
	},
	{
		"id": "7ba613c",
		"email": "lauren.mccully@mushroomgroup.com",
		"name": "Lauren",
		"createdDate": "2018-04-12 01:57:14",
		"totalOrganizations": 13,
		"totalTours": 25,
		"totalDays": 274,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 2010,
		"schedulePercent": 0.721097,
		"totalGuests": 558,
		"guestPercent": 0.713151,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Sidney Myer Music Bowl",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 58
	},
	{
		"id": "2700c13",
		"email": "evelina@headstomp.com",
		"name": "Evelina",
		"createdDate": "2015-09-14 11:14:33",
		"totalOrganizations": 64,
		"totalTours": 46,
		"totalDays": 552,
		"totalCountries": 10,
		"totalStates": 55,
		"totalEvents": 434,
		"eventPercent": 0.965303,
		"totalScheduleItems": 3596,
		"schedulePercent": 0.900013,
		"totalGuests": 302,
		"guestPercent": 0.603496,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Goteborgs Konserthus Big Hall",
		"mostGuestCity": "Goteborg",
		"mostGuestAmount": 48
	},
	{
		"id": "25def56",
		"email": "tourmanagerriley@gmail.com",
		"name": "Riley",
		"createdDate": "2014-01-21 17:16:49",
		"totalOrganizations": 15,
		"totalTours": 16,
		"totalDays": 702,
		"totalCountries": 2,
		"totalStates": 44,
		"totalEvents": 251,
		"eventPercent": 0.873792,
		"totalScheduleItems": 4514,
		"schedulePercent": 0.945967,
		"totalGuests": 6216,
		"guestPercent": 0.996027,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Dickies Arena",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 433
	},
	{
		"id": "38af2aa",
		"email": "iiris@allday.fi",
		"name": "Iiris",
		"createdDate": "2023-09-06 08:19:48",
		"totalOrganizations": 31,
		"totalTours": 32,
		"totalDays": 205,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 205,
		"eventPercent": 0.814859,
		"totalScheduleItems": 758,
		"schedulePercent": 0.405642,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e9af277",
		"email": "darren@darrenwalters.com",
		"name": "Darren",
		"createdDate": "2011-06-22 16:13:30",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 50,
		"totalCountries": 4,
		"totalStates": 13,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 317,
		"schedulePercent": 0.216395,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5aadae4",
		"email": "courtneymarieandrewsmusic@gmail.com",
		"name": "Courtney",
		"createdDate": "2017-06-30 17:15:32",
		"totalOrganizations": 6,
		"totalTours": 12,
		"totalDays": 307,
		"totalCountries": 12,
		"totalStates": 48,
		"totalEvents": 157,
		"eventPercent": 0.720567,
		"totalScheduleItems": 1107,
		"schedulePercent": 0.513309,
		"totalGuests": 209,
		"guestPercent": 0.557277,
		"mostGuestDate": "2023-09-13 00:00:00",
		"mostGuestName": "Stubbs BBQ",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 28
	},
	{
		"id": "a7aba42",
		"email": "showtoday@aol.com",
		"name": "Andrew",
		"createdDate": "2010-06-08 17:41:51",
		"totalOrganizations": 14,
		"totalTours": 21,
		"totalDays": 949,
		"totalCountries": 19,
		"totalStates": 73,
		"totalEvents": 275,
		"eventPercent": 0.897629,
		"totalScheduleItems": 3229,
		"schedulePercent": 0.87207,
		"totalGuests": 1476,
		"guestPercent": 0.891273,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 243
	},
	{
		"id": "41d2455",
		"email": "justinjonesA1@mac.com",
		"name": "Justin",
		"createdDate": "2011-02-04 23:43:39",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 713,
		"totalCountries": 23,
		"totalStates": 53,
		"totalEvents": 251,
		"eventPercent": 0.873792,
		"totalScheduleItems": 2735,
		"schedulePercent": 0.824394,
		"totalGuests": 45,
		"guestPercent": 0.412925,
		"mostGuestDate": "2023-02-25 00:00:00",
		"mostGuestName": "Altice Arena",
		"mostGuestCity": "Lisboa",
		"mostGuestAmount": 8
	},
	{
		"id": "5591520",
		"email": "danteboweadvancing@gmail.com",
		"name": "Cora",
		"createdDate": "2023-01-20 14:53:20",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 73,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 344,
		"schedulePercent": 0.230168,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cf78f8e",
		"email": "Sparkl@infinitywestinc.com",
		"name": "Sparkl",
		"createdDate": "2020-02-15 15:48:45",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 138,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 368,
		"schedulePercent": 0.24222,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "27ef15b",
		"email": "chris@therocksmanagement.com",
		"name": "Christopher",
		"createdDate": "2016-02-08 20:52:26",
		"totalOrganizations": 10,
		"totalTours": 18,
		"totalDays": 681,
		"totalCountries": 5,
		"totalStates": 51,
		"totalEvents": 183,
		"eventPercent": 0.775659,
		"totalScheduleItems": 1707,
		"schedulePercent": 0.666269,
		"totalGuests": 1146,
		"guestPercent": 0.848629,
		"mostGuestDate": "2023-04-08 00:00:00",
		"mostGuestName": "MGM Music Hall at Fenway",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 86
	},
	{
		"id": "7083f8d",
		"email": "Chilie.Andersen@cphmusic.dk",
		"name": "Chilie",
		"createdDate": "2016-04-20 11:40:30",
		"totalOrganizations": 100,
		"totalTours": 35,
		"totalDays": 1264,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 1197,
		"eventPercent": 0.992319,
		"totalScheduleItems": 5062,
		"schedulePercent": 0.963449,
		"totalGuests": 76,
		"guestPercent": 0.451596,
		"mostGuestDate": "2023-05-26 00:00:00",
		"mostGuestName": "Tivoli Friheden",
		"mostGuestCity": "Aarhus",
		"mostGuestAmount": 25
	},
	{
		"id": "568f27a",
		"email": "tate.sablatura@belmont.edu",
		"name": "Tate",
		"createdDate": "2017-08-30 21:11:19",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 45,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 221,
		"schedulePercent": 0.163952,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "02a0442",
		"email": "lennea@made.no",
		"name": "Lennea",
		"createdDate": "2016-05-13 09:22:25",
		"totalOrganizations": 13,
		"totalTours": 9,
		"totalDays": 712,
		"totalCountries": 20,
		"totalStates": 53,
		"totalEvents": 225,
		"eventPercent": 0.842008,
		"totalScheduleItems": 2539,
		"schedulePercent": 0.797245,
		"totalGuests": 345,
		"guestPercent": 0.62601,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "Studentersamfundet i Trondheim",
		"mostGuestCity": "Trondheim",
		"mostGuestAmount": 70
	},
	{
		"id": "456a954",
		"email": "booking@carsoncoma.hu",
		"name": "Zsolt",
		"createdDate": "2023-02-15 16:37:26",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 77,
		"totalCountries": 3,
		"totalStates": 3,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 528,
		"schedulePercent": 0.312012,
		"totalGuests": 77,
		"guestPercent": 0.452523,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "KrimÃ³",
		"mostGuestCity": "Szentes",
		"mostGuestAmount": 11
	},
	{
		"id": "f4b1117",
		"email": "christielynnward@gmail.com",
		"name": "Christie",
		"createdDate": "2022-03-18 14:03:41",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 27,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 13,
		"eventPercent": 0.0892597,
		"totalScheduleItems": 180,
		"schedulePercent": 0.138392,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9012f39",
		"email": "tomhollerbach@me.com",
		"name": "Tom",
		"createdDate": "2018-05-17 20:56:20",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 350,
		"totalCountries": 15,
		"totalStates": 27,
		"totalEvents": 260,
		"eventPercent": 0.885975,
		"totalScheduleItems": 3442,
		"schedulePercent": 0.889551,
		"totalGuests": 6950,
		"guestPercent": 0.998146,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Olympiastadion Berlin",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 794
	},
	{
		"id": "da121de",
		"email": "janis@shenpc.com",
		"name": "Janis",
		"createdDate": "2015-06-02 17:02:28",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 232,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 1591,
		"schedulePercent": 0.63965,
		"totalGuests": 240,
		"guestPercent": 0.573434,
		"mostGuestDate": "2023-03-15 00:00:00",
		"mostGuestName": "Music Hall of Williamsburg",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 59
	},
	{
		"id": "6055d5f",
		"email": "markjmaher@me.com",
		"name": "Mark",
		"createdDate": "2009-06-04 19:59:07",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 184,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1189,
		"schedulePercent": 0.537015,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7009e93",
		"email": "bryannawells@gmail.com",
		"name": "Bryanna",
		"createdDate": "2023-08-06 19:21:06",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 59,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 386,
		"schedulePercent": 0.25149,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1b1ff9d",
		"email": "Dannash83@gmail.com",
		"name": "Daniel",
		"createdDate": "2015-04-17 05:36:25",
		"totalOrganizations": 28,
		"totalTours": 90,
		"totalDays": 1158,
		"totalCountries": 11,
		"totalStates": 47,
		"totalEvents": 328,
		"eventPercent": 0.929943,
		"totalScheduleItems": 3222,
		"schedulePercent": 0.871673,
		"totalGuests": 1255,
		"guestPercent": 0.865846,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "The Forum ",
		"mostGuestCity": "Melbourne ",
		"mostGuestAmount": 131
	},
	{
		"id": "440081f",
		"email": "jLenzMT@gmail.com",
		"name": "Jeppe",
		"createdDate": "2023-08-31 11:27:48",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 60,
		"totalCountries": 13,
		"totalStates": 19,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 327,
		"schedulePercent": 0.222752,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "31f6b30",
		"email": "martin@runningroadie.com",
		"name": "Martin",
		"createdDate": "2019-06-10 09:33:09",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 417,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 1045,
		"schedulePercent": 0.495828,
		"totalGuests": 203,
		"guestPercent": 0.553172,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Complejo deportivo Fuente de la NiÃ±a",
		"mostGuestCity": "Guadalajara",
		"mostGuestAmount": 27
	},
	{
		"id": "b8ae028",
		"email": "mgmt@imprintedgroup.com",
		"name": "TOUR",
		"createdDate": "2023-06-01 17:27:06",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 560,
		"totalCountries": 12,
		"totalStates": 36,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 262,
		"schedulePercent": 0.186465,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-06-15 00:00:00",
		"mostGuestName": "Ardmore Hall",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 2
	},
	{
		"id": "04e9dd1",
		"email": "nadinercarlson@gmail.com",
		"name": "Nadine",
		"createdDate": "2013-09-21 22:07:37",
		"totalOrganizations": 4,
		"totalTours": 14,
		"totalDays": 487,
		"totalCountries": 8,
		"totalStates": 28,
		"totalEvents": 181,
		"eventPercent": 0.771818,
		"totalScheduleItems": 2158,
		"schedulePercent": 0.743743,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5bfc268",
		"email": "Katethegreatcunningham@gmail.com",
		"name": "Kate",
		"createdDate": "2019-11-06 17:59:16",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 185,
		"totalCountries": 4,
		"totalStates": 30,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 909,
		"schedulePercent": 0.454774,
		"totalGuests": 296,
		"guestPercent": 0.600715,
		"mostGuestDate": "2023-09-12 00:00:00",
		"mostGuestName": "Cain's Ballroom",
		"mostGuestCity": "Tulsa",
		"mostGuestAmount": 23
	},
	{
		"id": "d4979f9",
		"email": "nktourmanager@gmail.com",
		"name": "NK",
		"createdDate": "2017-10-02 17:20:28",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 586,
		"totalCountries": 6,
		"totalStates": 46,
		"totalEvents": 167,
		"eventPercent": 0.745332,
		"totalScheduleItems": 3056,
		"schedulePercent": 0.857105,
		"totalGuests": 2934,
		"guestPercent": 0.968746,
		"mostGuestDate": "2023-10-03 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 104
	},
	{
		"id": "9decf39",
		"email": "katie@watchtowerentertainment.com",
		"name": "Katie",
		"createdDate": "2022-12-29 22:10:58",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 419,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 225,
		"schedulePercent": 0.1666,
		"totalGuests": 49,
		"guestPercent": 0.419944,
		"mostGuestDate": "2023-07-23 00:00:00",
		"mostGuestName": "Stages Music Arts",
		"mostGuestCity": "Cockeysville",
		"mostGuestAmount": 12
	},
	{
		"id": "34d3a12",
		"email": "Mark.Hengge@jurassicquest.com",
		"name": "Mark",
		"createdDate": "2022-12-28 16:15:57",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 415,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 2700,
		"schedulePercent": 0.818964,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "891d8e1",
		"email": "dkpro@tcob.biz",
		"name": "Tobias",
		"createdDate": "2022-08-09 09:39:46",
		"totalOrganizations": 31,
		"totalTours": 35,
		"totalDays": 376,
		"totalCountries": 6,
		"totalStates": 11,
		"totalEvents": 329,
		"eventPercent": 0.930738,
		"totalScheduleItems": 2138,
		"schedulePercent": 0.740034,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d4099df",
		"email": "trei3@aol.com",
		"name": "Raymond",
		"createdDate": "2015-04-24 19:14:16",
		"totalOrganizations": 9,
		"totalTours": 3,
		"totalDays": 403,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 140,
		"schedulePercent": 0.113892,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "27f95c5",
		"email": "lierin@milomgmt.com",
		"name": "Lierin",
		"createdDate": "2022-01-15 20:43:15",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 414,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 833,
		"schedulePercent": 0.432393,
		"totalGuests": 189,
		"guestPercent": 0.54218,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 49
	},
	{
		"id": "df9451f",
		"email": "craigmcq72@gmail.com",
		"name": "Craig",
		"createdDate": "2010-01-22 16:56:24",
		"totalOrganizations": 13,
		"totalTours": 29,
		"totalDays": 573,
		"totalCountries": 19,
		"totalStates": 58,
		"totalEvents": 207,
		"eventPercent": 0.817905,
		"totalScheduleItems": 3058,
		"schedulePercent": 0.857767,
		"totalGuests": 2234,
		"guestPercent": 0.945438,
		"mostGuestDate": "2023-04-09 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 76
	},
	{
		"id": "e53671d",
		"email": "stacytourmanager@gmail.com",
		"name": "Stacy",
		"createdDate": "2023-03-21 17:40:33",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 142,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 1133,
		"schedulePercent": 0.520726,
		"totalGuests": 353,
		"guestPercent": 0.631307,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 71
	},
	{
		"id": "494b8b8",
		"email": "civilianmusic@gmail.com",
		"name": "Christopher",
		"createdDate": "2013-06-28 02:01:39",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 149,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 592,
		"schedulePercent": 0.336909,
		"totalGuests": 218,
		"guestPercent": 0.561515,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Russel and Roots",
		"mostGuestCity": "Surrey",
		"mostGuestAmount": 48
	},
	{
		"id": "53cf760",
		"email": "Malloryhenley@gmail.com",
		"name": "Mallory",
		"createdDate": "2018-02-08 19:59:44",
		"totalOrganizations": 13,
		"totalTours": 23,
		"totalDays": 1057,
		"totalCountries": 16,
		"totalStates": 53,
		"totalEvents": 258,
		"eventPercent": 0.883989,
		"totalScheduleItems": 3411,
		"schedulePercent": 0.888227,
		"totalGuests": 1049,
		"guestPercent": 0.832605,
		"mostGuestDate": "2023-04-11 00:00:00",
		"mostGuestName": "Bimbo's 365 Club",
		"mostGuestCity": "San Francisco",
		"mostGuestAmount": 68
	},
	{
		"id": "4bb08ba",
		"email": "thereklawsmusic@gmail.com",
		"name": "Jenna",
		"createdDate": "2023-01-03 23:33:38",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 101,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 533,
		"schedulePercent": 0.314395,
		"totalGuests": 384,
		"guestPercent": 0.646669,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "HISTORY",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 117
	},
	{
		"id": "ed35c81",
		"email": "dominicord@icloud.com",
		"name": "Dominic",
		"createdDate": "2016-03-02 16:59:08",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 604,
		"totalCountries": 7,
		"totalStates": 18,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 1062,
		"schedulePercent": 0.501258,
		"totalGuests": 77,
		"guestPercent": 0.452523,
		"mostGuestDate": "2023-08-27 00:00:00",
		"mostGuestName": "Carfest South",
		"mostGuestCity": "Hampshire",
		"mostGuestAmount": 24
	},
	{
		"id": "6ef2d05",
		"email": "info@e1fx.com",
		"name": "E1FX",
		"createdDate": "2019-11-15 19:57:42",
		"totalOrganizations": 45,
		"totalTours": 34,
		"totalDays": 1101,
		"totalCountries": 3,
		"totalStates": 44,
		"totalEvents": 721,
		"eventPercent": 0.986757,
		"totalScheduleItems": 3986,
		"schedulePercent": 0.922527,
		"totalGuests": 2699,
		"guestPercent": 0.960932,
		"mostGuestDate": "2023-08-16 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 154
	},
	{
		"id": "4e40a3b",
		"email": "ju@highwayholidays.eu",
		"name": "Justine",
		"createdDate": "2022-06-06 19:37:52",
		"totalOrganizations": 9,
		"totalTours": 12,
		"totalDays": 426,
		"totalCountries": 16,
		"totalStates": 61,
		"totalEvents": 174,
		"eventPercent": 0.759502,
		"totalScheduleItems": 1612,
		"schedulePercent": 0.644948,
		"totalGuests": 448,
		"guestPercent": 0.673156,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Annexet",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 155
	},
	{
		"id": "a69aa26",
		"email": "conorghickey@gmail.com",
		"name": "Conor",
		"createdDate": "2017-06-09 23:10:14",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 708,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 1677,
		"schedulePercent": 0.65925,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9a0f511",
		"email": "andersolivermusikk@gmail.com",
		"name": "Anders",
		"createdDate": "2020-02-13 16:27:40",
		"totalOrganizations": 10,
		"totalTours": 6,
		"totalDays": 101,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 753,
		"schedulePercent": 0.403655,
		"totalGuests": 125,
		"guestPercent": 0.494636,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Hvalstrandfestivalen",
		"mostGuestCity": "Nesbru",
		"mostGuestAmount": 25
	},
	{
		"id": "1e3ed8c",
		"email": "matthewabrewer@icloud.com",
		"name": "Matthew",
		"createdDate": "2016-01-07 18:04:11",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 650,
		"totalCountries": 5,
		"totalStates": 49,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 2320,
		"schedulePercent": 0.768772,
		"totalGuests": 1981,
		"guestPercent": 0.932459,
		"mostGuestDate": "2023-12-09 00:00:00",
		"mostGuestName": "The Orion Amphitheater",
		"mostGuestCity": "Huntsville",
		"mostGuestAmount": 109
	},
	{
		"id": "5376745",
		"email": "anna@thenewgoldmedal.com",
		"name": "Email",
		"createdDate": "2022-01-07 18:20:58",
		"totalOrganizations": 9,
		"totalTours": 3,
		"totalDays": 145,
		"totalCountries": 10,
		"totalStates": 30,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 330,
		"schedulePercent": 0.224209,
		"totalGuests": 264,
		"guestPercent": 0.58628,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "Knockdown Center",
		"mostGuestCity": "Maspeth",
		"mostGuestAmount": 60
	},
	{
		"id": "9e1491b",
		"email": "joecrouch90@gmail.com",
		"name": "Joe",
		"createdDate": "2018-10-15 16:53:34",
		"totalOrganizations": 12,
		"totalTours": 18,
		"totalDays": 766,
		"totalCountries": 14,
		"totalStates": 43,
		"totalEvents": 195,
		"eventPercent": 0.796318,
		"totalScheduleItems": 1743,
		"schedulePercent": 0.673156,
		"totalGuests": 92,
		"guestPercent": 0.466163,
		"mostGuestDate": "2023-03-21 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 30
	},
	{
		"id": "2723774",
		"email": "Conorhesse@gmail.com",
		"name": "Louis",
		"createdDate": "2014-06-11 04:28:45",
		"totalOrganizations": 7,
		"totalTours": 14,
		"totalDays": 420,
		"totalCountries": 16,
		"totalStates": 84,
		"totalEvents": 265,
		"eventPercent": 0.890743,
		"totalScheduleItems": 3157,
		"schedulePercent": 0.86664,
		"totalGuests": 2825,
		"guestPercent": 0.965965,
		"mostGuestDate": "2023-10-08 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 116
	},
	{
		"id": "5866cf2",
		"email": "stenlundadam@gmail.com",
		"name": "Adam",
		"createdDate": "2023-11-15 10:37:52",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 30,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 137,
		"schedulePercent": 0.111244,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "01e3474",
		"email": "asherpeterson@gmail.com",
		"name": "Asher",
		"createdDate": "2017-10-30 16:37:51",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 731,
		"totalCountries": 7,
		"totalStates": 52,
		"totalEvents": 391,
		"eventPercent": 0.95537,
		"totalScheduleItems": 2780,
		"schedulePercent": 0.829294,
		"totalGuests": 856,
		"guestPercent": 0.788902,
		"mostGuestDate": "2023-04-10 00:00:00",
		"mostGuestName": "The Fisher Center for the Performing Arts",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 139
	},
	{
		"id": "cf6cf4e",
		"email": "fredrik@besterman.nu",
		"name": "Fredrik",
		"createdDate": "2019-01-09 15:36:03",
		"totalOrganizations": 16,
		"totalTours": 4,
		"totalDays": 94,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 578,
		"schedulePercent": 0.331082,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f8a8677",
		"email": "doug@liverpoolbandvans.co.uk",
		"name": "Doug",
		"createdDate": "2017-08-27 10:41:21",
		"totalOrganizations": 4,
		"totalTours": 10,
		"totalDays": 80,
		"totalCountries": 8,
		"totalStates": 15,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 776,
		"schedulePercent": 0.411601,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "Bluedot Festival at Jodrell Bank Observatory",
		"mostGuestCity": "",
		"mostGuestAmount": 15
	},
	{
		"id": "50f4f5d",
		"email": "johnjherguth@gmail.com",
		"name": "John",
		"createdDate": "2018-03-27 13:04:38",
		"totalOrganizations": 16,
		"totalTours": 17,
		"totalDays": 753,
		"totalCountries": 16,
		"totalStates": 52,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 1359,
		"schedulePercent": 0.582837,
		"totalGuests": 90,
		"guestPercent": 0.463912,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Queen Elizabeth Theatre",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 33
	},
	{
		"id": "4776a17",
		"email": "debbiejotaylor@gmail.com",
		"name": "Debbie",
		"createdDate": "2019-04-12 04:03:38",
		"totalOrganizations": 9,
		"totalTours": 8,
		"totalDays": 178,
		"totalCountries": 5,
		"totalStates": 20,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 2057,
		"schedulePercent": 0.728513,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6355896",
		"email": "thomas.d.tucker@gmail.com",
		"name": "Thomas",
		"createdDate": "2018-02-11 20:54:37",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 61,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 367,
		"schedulePercent": 0.241557,
		"totalGuests": 472,
		"guestPercent": 0.681764,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "Constellation Room",
		"mostGuestCity": "Santa Ana",
		"mostGuestAmount": 65
	},
	{
		"id": "03d1b05",
		"email": "thedr@jackjohnsonmusic.com",
		"name": "Nik",
		"createdDate": "2010-06-10 21:35:57",
		"totalOrganizations": 265,
		"totalTours": 8,
		"totalDays": 424,
		"totalCountries": 11,
		"totalStates": 50,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 2460,
		"schedulePercent": 0.787445,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7f6c795",
		"email": "skavaliauskaitee@gmail.com",
		"name": "Saule",
		"createdDate": "2021-11-03 13:50:45",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 253,
		"totalCountries": 12,
		"totalStates": 30,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 840,
		"schedulePercent": 0.433982,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7819dca",
		"email": "gigirocks70@gmail.com",
		"name": "Gigi",
		"createdDate": "2015-11-05 14:11:52",
		"totalOrganizations": 13,
		"totalTours": 8,
		"totalDays": 280,
		"totalCountries": 7,
		"totalStates": 49,
		"totalEvents": 176,
		"eventPercent": 0.763475,
		"totalScheduleItems": 2452,
		"schedulePercent": 0.785989,
		"totalGuests": 422,
		"guestPercent": 0.662694,
		"mostGuestDate": "2023-10-22 00:00:00",
		"mostGuestName": "THE PUNK ROCK MUSEUM",
		"mostGuestCity": "LAS VEGAS",
		"mostGuestAmount": 58
	},
	{
		"id": "472d4ea",
		"email": "booking@naomirainemusic.com",
		"name": "Naomi",
		"createdDate": "2021-04-02 00:51:24",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 176,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 2556,
		"schedulePercent": 0.799894,
		"totalGuests": 889,
		"guestPercent": 0.796981,
		"mostGuestDate": "2023-05-02 00:00:00",
		"mostGuestName": "The Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 99
	},
	{
		"id": "f7d83d9",
		"email": "amy.hills@nilerodgers.com",
		"name": "Amy",
		"createdDate": "2021-06-14 13:07:46",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 387,
		"totalCountries": 15,
		"totalStates": 51,
		"totalEvents": 192,
		"eventPercent": 0.791683,
		"totalScheduleItems": 2915,
		"schedulePercent": 0.843862,
		"totalGuests": 485,
		"guestPercent": 0.685207,
		"mostGuestDate": "2023-07-20 00:00:00",
		"mostGuestName": "Warwick Castle",
		"mostGuestCity": "Warwick",
		"mostGuestAmount": 23
	},
	{
		"id": "c36144e",
		"email": "bb@newcommunitymgmt.com",
		"name": "Brad",
		"createdDate": "2021-08-29 17:48:37",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 81,
		"totalCountries": 3,
		"totalStates": 30,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 420,
		"schedulePercent": 0.263939,
		"totalGuests": 162,
		"guestPercent": 0.524169,
		"mostGuestDate": "2023-09-27 00:00:00",
		"mostGuestName": "Hammerstein Ballroom",
		"mostGuestCity": "New York",
		"mostGuestAmount": 19
	},
	{
		"id": "70c2a5e",
		"email": "duchessiredale@gmail.com",
		"name": "Duchess",
		"createdDate": "2017-02-16 10:07:54",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 373,
		"totalCountries": 13,
		"totalStates": 46,
		"totalEvents": 171,
		"eventPercent": 0.75394,
		"totalScheduleItems": 2347,
		"schedulePercent": 0.773672,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "edd7b6e",
		"email": "therealcheyennenicole@gmail.com",
		"name": "Cheyenne",
		"createdDate": "2022-03-10 02:13:11",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 170,
		"totalCountries": 4,
		"totalStates": 23,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 1017,
		"schedulePercent": 0.490266,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6c0dace",
		"email": "chopperld@hotmail.com",
		"name": "Chad",
		"createdDate": "2016-06-14 04:20:25",
		"totalOrganizations": 7,
		"totalTours": 12,
		"totalDays": 520,
		"totalCountries": 14,
		"totalStates": 47,
		"totalEvents": 204,
		"eventPercent": 0.813005,
		"totalScheduleItems": 3687,
		"schedulePercent": 0.906635,
		"totalGuests": 376,
		"guestPercent": 0.643888,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Acrisure Arena",
		"mostGuestCity": "Palm Desert",
		"mostGuestAmount": 77
	},
	{
		"id": "49531db",
		"email": "stagollc@gmail.com",
		"name": "Clinton",
		"createdDate": "2010-06-07 08:02:48",
		"totalOrganizations": 24,
		"totalTours": 33,
		"totalDays": 1043,
		"totalCountries": 14,
		"totalStates": 37,
		"totalEvents": 301,
		"eventPercent": 0.91617,
		"totalScheduleItems": 3109,
		"schedulePercent": 0.862535,
		"totalGuests": 1288,
		"guestPercent": 0.870481,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "The Forum ",
		"mostGuestCity": "Melbourne ",
		"mostGuestAmount": 131
	},
	{
		"id": "1d77387",
		"email": "mjrobertson314@gmail.com",
		"name": "Melanie",
		"createdDate": "2022-08-31 18:41:11",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 91,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 834,
		"schedulePercent": 0.432658,
		"totalGuests": 1300,
		"guestPercent": 0.871673,
		"mostGuestDate": "2023-03-01 00:00:00",
		"mostGuestName": "Houston Livestock Show and Rodeo",
		"mostGuestCity": "Houston",
		"mostGuestAmount": 100
	},
	{
		"id": "23d8d9b",
		"email": "dan@nutcracker.com",
		"name": "Dan",
		"createdDate": "2017-06-28 20:51:01",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 115,
		"totalCountries": 1,
		"totalStates": 38,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 1466,
		"schedulePercent": 0.606542,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "The Music Center @ Strathmore",
		"mostGuestCity": "North Bethesda",
		"mostGuestAmount": 9
	},
	{
		"id": "b7c56f6",
		"email": "bbowman6@gmail.com",
		"name": "Brittany",
		"createdDate": "2012-06-15 16:04:51",
		"totalOrganizations": 9,
		"totalTours": 9,
		"totalDays": 488,
		"totalCountries": 8,
		"totalStates": 44,
		"totalEvents": 189,
		"eventPercent": 0.78718,
		"totalScheduleItems": 3253,
		"schedulePercent": 0.874189,
		"totalGuests": 1847,
		"guestPercent": 0.925043,
		"mostGuestDate": "2023-08-30 00:00:00",
		"mostGuestName": "Hollywood Casino Amphitheatre",
		"mostGuestCity": "Tinley Park",
		"mostGuestAmount": 122
	},
	{
		"id": "1c12d8e",
		"email": "erinroblin@gmail.com",
		"name": "Erin",
		"createdDate": "2019-08-05 23:22:56",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 243,
		"totalCountries": 6,
		"totalStates": 28,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1342,
		"schedulePercent": 0.578334,
		"totalGuests": 1181,
		"guestPercent": 0.854324,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Buffalo Outer Harbor Concert Site",
		"mostGuestCity": "Buffalo",
		"mostGuestAmount": 131
	},
	{
		"id": "fa476fc",
		"email": "adam.conde@me.com",
		"name": "Adam",
		"createdDate": "2012-01-18 18:52:06",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 50,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 478,
		"schedulePercent": 0.288439,
		"totalGuests": 536,
		"guestPercent": 0.70494,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Lake Tahoe Outdoor Arena",
		"mostGuestCity": "Stateline",
		"mostGuestAmount": 75
	},
	{
		"id": "a951191",
		"email": "foisy9@me.com",
		"name": "Josh",
		"createdDate": "2013-10-09 16:51:31",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 217,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 1230,
		"schedulePercent": 0.547477,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "19dd332",
		"email": "justinasoto@gmail.com",
		"name": "Justin ",
		"createdDate": "2013-07-30 00:47:51",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 364,
		"totalCountries": 6,
		"totalStates": 42,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 795,
		"schedulePercent": 0.41862,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "89d8cd4",
		"email": "archertourmgmt@gmail.com",
		"name": "Jacob",
		"createdDate": "2022-09-27 22:39:18",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 271,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 9,
		"eventPercent": 0.0607866,
		"totalScheduleItems": 161,
		"schedulePercent": 0.127135,
		"totalGuests": 37,
		"guestPercent": 0.400212,
		"mostGuestDate": "2023-05-04 00:00:00",
		"mostGuestName": "The Catalyst",
		"mostGuestCity": "Santa Cruz",
		"mostGuestAmount": 19
	},
	{
		"id": "1bef9b9",
		"email": "flavien@fmlightingcompany.be",
		"name": "Flavien",
		"createdDate": "2023-01-28 16:09:33",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 254,
		"totalCountries": 4,
		"totalStates": 17,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 908,
		"schedulePercent": 0.454244,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-12-03 00:00:00",
		"mostGuestName": "Rockhal",
		"mostGuestCity": "Esch-sur-Alzette",
		"mostGuestAmount": 5
	},
	{
		"id": "d238920",
		"email": "michaelcadenmusic@gmail.com",
		"name": "Michael",
		"createdDate": "2022-01-17 02:40:07",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 37,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5a971a2",
		"email": "jennimountford@cmplive.com",
		"name": "Jenni ",
		"createdDate": "2013-12-13 15:14:08",
		"totalOrganizations": 11,
		"totalTours": 5,
		"totalDays": 339,
		"totalCountries": 6,
		"totalStates": 37,
		"totalEvents": 189,
		"eventPercent": 0.78718,
		"totalScheduleItems": 2003,
		"schedulePercent": 0.71964,
		"totalGuests": 1819,
		"guestPercent": 0.922262,
		"mostGuestDate": "2023-06-26 00:00:00",
		"mostGuestName": "Blackpool Opera House",
		"mostGuestCity": "Blackpool",
		"mostGuestAmount": 84
	},
	{
		"id": "fa6513c",
		"email": "joelcoopetha@gmail.com",
		"name": "Joel",
		"createdDate": "2022-04-23 00:03:47",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 407,
		"totalCountries": 3,
		"totalStates": 6,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 277,
		"schedulePercent": 0.19653,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "YARRABAH SPORTS FIELD",
		"mostGuestCity": "YARRABAH",
		"mostGuestAmount": 6
	},
	{
		"id": "e07a76b",
		"email": "Dann@txdxe.com",
		"name": "Dann",
		"createdDate": "2023-03-21 00:02:25",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 105,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 599,
		"schedulePercent": 0.340352,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "14f2915",
		"email": "acp.clement@gmail.com",
		"name": "Cl&#233;ment",
		"createdDate": "2019-10-07 09:55:11",
		"totalOrganizations": 19,
		"totalTours": 17,
		"totalDays": 492,
		"totalCountries": 8,
		"totalStates": 29,
		"totalEvents": 292,
		"eventPercent": 0.910211,
		"totalScheduleItems": 3655,
		"schedulePercent": 0.904516,
		"totalGuests": 401,
		"guestPercent": 0.654218,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "U Palatinu",
		"mostGuestCity": "Ajaccio",
		"mostGuestAmount": 52
	},
	{
		"id": "3ae992c",
		"email": "seattlediy@gmail.com",
		"name": "Melanie",
		"createdDate": "2013-04-24 15:11:32",
		"totalOrganizations": 20,
		"totalTours": 20,
		"totalDays": 863,
		"totalCountries": 22,
		"totalStates": 90,
		"totalEvents": 420,
		"eventPercent": 0.962654,
		"totalScheduleItems": 5333,
		"schedulePercent": 0.9706,
		"totalGuests": 2423,
		"guestPercent": 0.951662,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "The Theatre at Ace Hotel",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 66
	},
	{
		"id": "6984a25",
		"email": "aaron@slushmanagement.com",
		"name": "Aaron",
		"createdDate": "2012-03-03 00:05:07",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 157,
		"totalCountries": 13,
		"totalStates": 27,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 995,
		"schedulePercent": 0.483115,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-07-06 00:00:00",
		"mostGuestName": "IndigO2",
		"mostGuestCity": "London",
		"mostGuestAmount": 12
	},
	{
		"id": "192aa81",
		"email": "tommylefroyband@gmail.com",
		"name": "Wynter ",
		"createdDate": "2023-09-08 13:43:36",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 30,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 13,
		"eventPercent": 0.0892597,
		"totalScheduleItems": 107,
		"schedulePercent": 0.0952192,
		"totalGuests": 149,
		"guestPercent": 0.513972,
		"mostGuestDate": "2023-10-10 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 39
	},
	{
		"id": "5f312d8",
		"email": "tmchico@icloud.com",
		"name": "Mark",
		"createdDate": "2016-06-07 18:13:30",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 816,
		"totalCountries": 7,
		"totalStates": 50,
		"totalEvents": 145,
		"eventPercent": 0.688518,
		"totalScheduleItems": 1866,
		"schedulePercent": 0.698583,
		"totalGuests": 2587,
		"guestPercent": 0.956297,
		"mostGuestDate": "2023-05-14 00:00:00",
		"mostGuestName": "Honda Center",
		"mostGuestCity": "Anaheim",
		"mostGuestAmount": 109
	},
	{
		"id": "48fdccf",
		"email": "jsmith@jses.ltd",
		"name": "Jon",
		"createdDate": "2019-08-10 16:12:25",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 235,
		"totalCountries": 12,
		"totalStates": 22,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 1148,
		"schedulePercent": 0.526553,
		"totalGuests": 200,
		"guestPercent": 0.550258,
		"mostGuestDate": "2023-04-29 00:00:00",
		"mostGuestName": "Essigfabrik",
		"mostGuestCity": "KÃ¶ln",
		"mostGuestAmount": 28
	},
	{
		"id": "6776901",
		"email": "mike@iconconcerts.com",
		"name": "Mike",
		"createdDate": "2021-06-20 22:15:13",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 460,
		"totalCountries": 8,
		"totalStates": 49,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 1866,
		"schedulePercent": 0.698583,
		"totalGuests": 158,
		"guestPercent": 0.520858,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Footprint Center",
		"mostGuestCity": "Phoenix",
		"mostGuestAmount": 38
	},
	{
		"id": "36e8886",
		"email": "tsb@line-contracts.co.uk",
		"name": "Tom",
		"createdDate": "2014-01-18 16:18:57",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 267,
		"totalCountries": 12,
		"totalStates": 38,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 1397,
		"schedulePercent": 0.59065,
		"totalGuests": 591,
		"guestPercent": 0.722818,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Brighton Dome",
		"mostGuestCity": "Brighton",
		"mostGuestAmount": 40
	},
	{
		"id": "d7be270",
		"email": "luciesound@gmail.com",
		"name": "Lucie",
		"createdDate": "2010-07-07 21:16:49",
		"totalOrganizations": 22,
		"totalTours": 9,
		"totalDays": 677,
		"totalCountries": 9,
		"totalStates": 22,
		"totalEvents": 173,
		"eventPercent": 0.757516,
		"totalScheduleItems": 2138,
		"schedulePercent": 0.740034,
		"totalGuests": 438,
		"guestPercent": 0.667461,
		"mostGuestDate": "2023-01-10 00:00:00",
		"mostGuestName": "The Classic Center",
		"mostGuestCity": "Athens",
		"mostGuestAmount": 68
	},
	{
		"id": "61ade2b",
		"email": "chrismark14@hotmail.co.uk",
		"name": "Chris",
		"createdDate": "2015-03-13 17:55:27",
		"totalOrganizations": 17,
		"totalTours": 7,
		"totalDays": 564,
		"totalCountries": 14,
		"totalStates": 31,
		"totalEvents": 178,
		"eventPercent": 0.767051,
		"totalScheduleItems": 2044,
		"schedulePercent": 0.726394,
		"totalGuests": 77,
		"guestPercent": 0.452523,
		"mostGuestDate": "2023-09-09 00:00:00",
		"mostGuestName": "Boogietown Festival",
		"mostGuestCity": "Walton on Thames",
		"mostGuestAmount": 39
	},
	{
		"id": "14d79df",
		"email": "todd@twsolutionsllc.com",
		"name": "Todd ",
		"createdDate": "2017-07-04 02:56:17",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 370,
		"totalCountries": 3,
		"totalStates": 32,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 980,
		"schedulePercent": 0.475699,
		"totalGuests": 393,
		"guestPercent": 0.650775,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "The Centre",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 20
	},
	{
		"id": "c4c5412",
		"email": "sam@kingtouring.com",
		"name": "Sam",
		"createdDate": "2021-06-02 17:23:47",
		"totalOrganizations": 4,
		"totalTours": 22,
		"totalDays": 500,
		"totalCountries": 6,
		"totalStates": 48,
		"totalEvents": 214,
		"eventPercent": 0.829559,
		"totalScheduleItems": 3761,
		"schedulePercent": 0.909946,
		"totalGuests": 73,
		"guestPercent": 0.447755,
		"mostGuestDate": "2023-01-13 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 24
	},
	{
		"id": "918fa47",
		"email": "mixbydb@gmail.com",
		"name": "Drew",
		"createdDate": "2022-02-02 05:48:32",
		"totalOrganizations": 12,
		"totalTours": 36,
		"totalDays": 824,
		"totalCountries": 6,
		"totalStates": 26,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 954,
		"schedulePercent": 0.46709,
		"totalGuests": 91,
		"guestPercent": 0.465236,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "Lansdowne Hotel",
		"mostGuestCity": "Chippendale",
		"mostGuestAmount": 18
	},
	{
		"id": "d787cfe",
		"email": "pyry@fullsteam.fi",
		"name": "Pyry",
		"createdDate": "2023-04-03 08:40:05",
		"totalOrganizations": 94,
		"totalTours": 76,
		"totalDays": 840,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 838,
		"eventPercent": 0.988213,
		"totalScheduleItems": 3266,
		"schedulePercent": 0.875513,
		"totalGuests": 61,
		"guestPercent": 0.435571,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "On The Rocks",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 10
	},
	{
		"id": "ead63d2",
		"email": "pgonzalez@universallatin.com",
		"name": "Pedro",
		"createdDate": "2015-09-05 17:04:01",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 264,
		"totalCountries": 3,
		"totalStates": 37,
		"totalEvents": 198,
		"eventPercent": 0.802543,
		"totalScheduleItems": 197,
		"schedulePercent": 0.148325,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cceb212",
		"email": "lamart1@yahoo.com",
		"name": "Lamar",
		"createdDate": "2022-04-23 22:17:05",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 388,
		"totalCountries": 5,
		"totalStates": 42,
		"totalEvents": 252,
		"eventPercent": 0.876308,
		"totalScheduleItems": 3422,
		"schedulePercent": 0.888624,
		"totalGuests": 357,
		"guestPercent": 0.633558,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "The Orion Amphitheater",
		"mostGuestCity": "Huntsville",
		"mostGuestAmount": 80
	},
	{
		"id": "6b4f610",
		"email": "corey@samhunttouring.com",
		"name": "Corey",
		"createdDate": "2023-02-07 21:23:48",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 570,
		"totalCountries": 2,
		"totalStates": 36,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 2267,
		"schedulePercent": 0.761489,
		"totalGuests": 1193,
		"guestPercent": 0.856443,
		"mostGuestDate": "2023-09-07 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 118
	},
	{
		"id": "f3f49a5",
		"email": "brandon@thebrandonduncan.com",
		"name": "Brandon",
		"createdDate": "2023-07-09 20:58:38",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 21,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 78,
		"schedulePercent": 0.0736326,
		"totalGuests": 24,
		"guestPercent": 0.375712,
		"mostGuestDate": "2023-07-17 00:00:00",
		"mostGuestName": "Soho Restaurant & Music Club",
		"mostGuestCity": "Santa Barbara",
		"mostGuestAmount": 8
	},
	{
		"id": "eb2f7be",
		"email": "Kristen.Williams@wmg.com",
		"name": "Kristen",
		"createdDate": "2013-02-22 23:34:21",
		"totalOrganizations": 55,
		"totalTours": 47,
		"totalDays": 15594,
		"totalCountries": 16,
		"totalStates": 88,
		"totalEvents": 2083,
		"eventPercent": 0.997616,
		"totalScheduleItems": 2663,
		"schedulePercent": 0.815786,
		"totalGuests": 26,
		"guestPercent": 0.381274,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "Music Farm",
		"mostGuestCity": "Charleston",
		"mostGuestAmount": 7
	},
	{
		"id": "8e6064a",
		"email": "kcnapper@gmail.com",
		"name": "K.C.",
		"createdDate": "2015-05-27 01:36:22",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 134,
		"totalCountries": 7,
		"totalStates": 35,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 1412,
		"schedulePercent": 0.593829,
		"totalGuests": 1414,
		"guestPercent": 0.884254,
		"mostGuestDate": "2023-08-30 00:00:00",
		"mostGuestName": "Hollywood Casino Amphitheatre",
		"mostGuestCity": "Tinley Park",
		"mostGuestAmount": 122
	},
	{
		"id": "d56d800",
		"email": "parkwaydrivemastertour@gmail.com",
		"name": "PWD",
		"createdDate": "2016-07-06 21:10:53",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 145,
		"totalCountries": 11,
		"totalStates": 39,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 1214,
		"schedulePercent": 0.543504,
		"totalGuests": 939,
		"guestPercent": 0.809297,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "FivePoint Amphitheatre",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 104
	},
	{
		"id": "1f63afe",
		"email": "kari.hyvarinen@kaakonkulmanaani.fi",
		"name": "Kari",
		"createdDate": "2019-08-21 07:11:25",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 224,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 497,
		"schedulePercent": 0.296914,
		"totalGuests": 140,
		"guestPercent": 0.505628,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Kulttuuritehdas Korjaamo",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 32
	},
	{
		"id": "cfdf6b4",
		"email": "edel@bocproductions.ie",
		"name": "Edel",
		"createdDate": "2023-04-11 16:43:12",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 103,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 589,
		"schedulePercent": 0.335055,
		"totalGuests": 526,
		"guestPercent": 0.700702,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "The SSE Hydro Evening Show ",
		"mostGuestCity": "Glasgow",
		"mostGuestAmount": 38
	},
	{
		"id": "07ee334",
		"email": "scottysveganchocolate@gmail.com",
		"name": "Scott",
		"createdDate": "2022-05-12 09:44:35",
		"totalOrganizations": 16,
		"totalTours": 13,
		"totalDays": 346,
		"totalCountries": 14,
		"totalStates": 57,
		"totalEvents": 134,
		"eventPercent": 0.658721,
		"totalScheduleItems": 1461,
		"schedulePercent": 0.605748,
		"totalGuests": 1497,
		"guestPercent": 0.893921,
		"mostGuestDate": "2023-11-19 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 57
	},
	{
		"id": "9788aed",
		"email": "jrau13@gmail.com",
		"name": "Joshua",
		"createdDate": "2009-10-05 16:48:20",
		"totalOrganizations": 22,
		"totalTours": 5,
		"totalDays": 476,
		"totalCountries": 14,
		"totalStates": 45,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 2325,
		"schedulePercent": 0.769832,
		"totalGuests": 2088,
		"guestPercent": 0.939213,
		"mostGuestDate": "2023-07-03 00:00:00",
		"mostGuestName": "Banc of California Stadium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 219
	},
	{
		"id": "d332314",
		"email": "kremeraudio@gmail.com",
		"name": "Stephen",
		"createdDate": "2022-01-16 23:26:28",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 279,
		"totalCountries": 13,
		"totalStates": 62,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 2767,
		"schedulePercent": 0.828102,
		"totalGuests": 1905,
		"guestPercent": 0.929281,
		"mostGuestDate": "2023-08-29 00:00:00",
		"mostGuestName": "Riverside Municipal Auditorium",
		"mostGuestCity": "Riverside",
		"mostGuestAmount": 64
	},
	{
		"id": "ae59708",
		"email": "nathan@njartistmgmt.com",
		"name": "Nathan",
		"createdDate": "2019-09-03 05:39:51",
		"totalOrganizations": 6,
		"totalTours": 12,
		"totalDays": 473,
		"totalCountries": 19,
		"totalStates": 64,
		"totalEvents": 189,
		"eventPercent": 0.78718,
		"totalScheduleItems": 2008,
		"schedulePercent": 0.720699,
		"totalGuests": 514,
		"guestPercent": 0.696597,
		"mostGuestDate": "2023-01-26 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 80
	},
	{
		"id": "bed8491",
		"email": "bryanhroberts@me.com",
		"name": "Bryan",
		"createdDate": "2010-08-31 20:54:22",
		"totalOrganizations": 6,
		"totalTours": 15,
		"totalDays": 1073,
		"totalCountries": 10,
		"totalStates": 61,
		"totalEvents": 573,
		"eventPercent": 0.981195,
		"totalScheduleItems": 6179,
		"schedulePercent": 0.981989,
		"totalGuests": 6916,
		"guestPercent": 0.997484,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Raymond James Stadium",
		"mostGuestCity": "Tampa ",
		"mostGuestAmount": 248
	},
	{
		"id": "fb38092",
		"email": "tomaswolfe@gmail.com",
		"name": "Tomas",
		"createdDate": "2013-06-19 08:53:20",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 531,
		"totalCountries": 10,
		"totalStates": 44,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 1661,
		"schedulePercent": 0.656204,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-06-25 00:00:00",
		"mostGuestName": "Music Hall at Fair Park",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 3
	},
	{
		"id": "4e13be1",
		"email": "hayescarll@gmail.com",
		"name": "Hayes",
		"createdDate": "2016-03-03 19:11:15",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 408,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 746,
		"schedulePercent": 0.400212,
		"totalGuests": 155,
		"guestPercent": 0.51768,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "The Teragram Ballroom",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 26
	},
	{
		"id": "3c4e8b7",
		"email": "cody@rightangleent.com",
		"name": "Cody ",
		"createdDate": "2022-09-14 02:43:08",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 368,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 229,
		"eventPercent": 0.847305,
		"totalScheduleItems": 2757,
		"schedulePercent": 0.826645,
		"totalGuests": 18,
		"guestPercent": 0.364455,
		"mostGuestDate": "2023-01-13 00:00:00",
		"mostGuestName": "Pantages Theater",
		"mostGuestCity": "Tacoma",
		"mostGuestAmount": 8
	},
	{
		"id": "d973ee5",
		"email": "Sophiejaneloomis@gmail.com",
		"name": "Sophie",
		"createdDate": "2013-11-11 18:21:30",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 246,
		"totalCountries": 3,
		"totalStates": 6,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 883,
		"schedulePercent": 0.447623,
		"totalGuests": 36,
		"guestPercent": 0.397961,
		"mostGuestDate": "2023-06-02 00:00:00",
		"mostGuestName": "Orange Warsaw Festival",
		"mostGuestCity": "Warszawa",
		"mostGuestAmount": 36
	},
	{
		"id": "5ef509e",
		"email": "rose@kontourproductions.com",
		"name": "Rose",
		"createdDate": "2021-12-08 13:02:28",
		"totalOrganizations": 70,
		"totalTours": 65,
		"totalDays": 1023,
		"totalCountries": 6,
		"totalStates": 19,
		"totalEvents": 568,
		"eventPercent": 0.980665,
		"totalScheduleItems": 4640,
		"schedulePercent": 0.949278,
		"totalGuests": 120,
		"guestPercent": 0.490134,
		"mostGuestDate": "2023-05-30 00:00:00",
		"mostGuestName": "Richmond Theatre",
		"mostGuestCity": "Richmond",
		"mostGuestAmount": 21
	},
	{
		"id": "0718253",
		"email": "Rebekah@ujimaproductions.com",
		"name": "Rebekah",
		"createdDate": "2009-09-05 02:11:14",
		"totalOrganizations": 12,
		"totalTours": 20,
		"totalDays": 628,
		"totalCountries": 20,
		"totalStates": 64,
		"totalEvents": 288,
		"eventPercent": 0.907694,
		"totalScheduleItems": 2612,
		"schedulePercent": 0.810356,
		"totalGuests": 13,
		"guestPercent": 0.352536,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Dos Equis Pavilion",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 9
	},
	{
		"id": "c657712",
		"email": "tcombs1@prodigy.net",
		"name": "Tim",
		"createdDate": "2013-09-26 18:24:27",
		"totalOrganizations": 9,
		"totalTours": 4,
		"totalDays": 302,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 2384,
		"schedulePercent": 0.778572,
		"totalGuests": 978,
		"guestPercent": 0.818832,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "Abba's House ",
		"mostGuestCity": "Hixson",
		"mostGuestAmount": 37
	},
	{
		"id": "3724d33",
		"email": "booking@blindowlsd.com",
		"name": "Allan ",
		"createdDate": "2023-07-31 13:18:27",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 58,
		"totalCountries": 3,
		"totalStates": 12,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 56,
		"schedulePercent": 0.0589326,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "05a1b8b",
		"email": "sslovy@gmail.com",
		"name": "Sami",
		"createdDate": "2014-05-17 23:22:15",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 765,
		"totalCountries": 10,
		"totalStates": 58,
		"totalEvents": 180,
		"eventPercent": 0.770229,
		"totalScheduleItems": 1719,
		"schedulePercent": 0.669315,
		"totalGuests": 370,
		"guestPercent": 0.64124,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "BeachLife Festival",
		"mostGuestCity": "Redondo Beach",
		"mostGuestAmount": 22
	},
	{
		"id": "de16aa4",
		"email": "laura.s@mac.com",
		"name": "Laura",
		"createdDate": "2015-07-15 17:43:57",
		"totalOrganizations": 11,
		"totalTours": 14,
		"totalDays": 1407,
		"totalCountries": 12,
		"totalStates": 44,
		"totalEvents": 276,
		"eventPercent": 0.898292,
		"totalScheduleItems": 4714,
		"schedulePercent": 0.952457,
		"totalGuests": 403,
		"guestPercent": 0.654748,
		"mostGuestDate": "2023-01-10 00:00:00",
		"mostGuestName": "The Classic Center",
		"mostGuestCity": "Athens",
		"mostGuestAmount": 68
	},
	{
		"id": "a96acbb",
		"email": "juliaervin@gmail.com",
		"name": "Julia",
		"createdDate": "2009-11-18 06:55:34",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 159,
		"totalCountries": 16,
		"totalStates": 53,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 1996,
		"schedulePercent": 0.717653,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9407983",
		"email": "kiddihjalmur@gmail.com",
		"name": "Gudmundur",
		"createdDate": "2013-10-28 20:21:22",
		"totalOrganizations": 5,
		"totalTours": 12,
		"totalDays": 56,
		"totalCountries": 5,
		"totalStates": 8,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 138,
		"schedulePercent": 0.112171,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d0f0441",
		"email": "jonathan@bbmgt.co",
		"name": "Jonathan",
		"createdDate": "2018-08-14 23:35:44",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 331,
		"totalCountries": 9,
		"totalStates": 24,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 844,
		"schedulePercent": 0.435836,
		"totalGuests": 1887,
		"guestPercent": 0.927427,
		"mostGuestDate": "2023-09-03 00:00:00",
		"mostGuestName": "The Cosmopolitan of Las Vegas",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 138
	},
	{
		"id": "a35709f",
		"email": "gabygentedezona@gmail.com",
		"name": "JOSE",
		"createdDate": "2023-06-05 23:40:50",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 177,
		"totalCountries": 6,
		"totalStates": 13,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 185,
		"schedulePercent": 0.142233,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ceddaa5",
		"email": "everett79@gmail.com",
		"name": "Steve",
		"createdDate": "2014-03-24 16:53:23",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 535,
		"totalCountries": 10,
		"totalStates": 73,
		"totalEvents": 236,
		"eventPercent": 0.857237,
		"totalScheduleItems": 2352,
		"schedulePercent": 0.774202,
		"totalGuests": 928,
		"guestPercent": 0.806251,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 54
	},
	{
		"id": "f845d1b",
		"email": "mark@doublee.co.uk",
		"name": "Mark",
		"createdDate": "2013-11-04 20:30:54",
		"totalOrganizations": 22,
		"totalTours": 17,
		"totalDays": 598,
		"totalCountries": 11,
		"totalStates": 25,
		"totalEvents": 180,
		"eventPercent": 0.770229,
		"totalScheduleItems": 2292,
		"schedulePercent": 0.764402,
		"totalGuests": 61,
		"guestPercent": 0.435571,
		"mostGuestDate": "2023-06-02 00:00:00",
		"mostGuestName": "The Enmore Theatre",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 23
	},
	{
		"id": "98e191a",
		"email": "lucas.leckie7@gmail.com",
		"name": "Lucas",
		"createdDate": "2022-02-04 03:03:52",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 62,
		"totalCountries": 3,
		"totalStates": 20,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 543,
		"schedulePercent": 0.318633,
		"totalGuests": 89,
		"guestPercent": 0.462323,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Chevalier Theatre",
		"mostGuestCity": "Medford",
		"mostGuestAmount": 18
	},
	{
		"id": "84f5de3",
		"email": "rob@tenatoms.com",
		"name": "Rob",
		"createdDate": "2021-05-25 17:14:56",
		"totalOrganizations": 9,
		"totalTours": 23,
		"totalDays": 847,
		"totalCountries": 8,
		"totalStates": 58,
		"totalEvents": 218,
		"eventPercent": 0.834724,
		"totalScheduleItems": 2313,
		"schedulePercent": 0.767183,
		"totalGuests": 942,
		"guestPercent": 0.810224,
		"mostGuestDate": "2023-03-10 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 46
	},
	{
		"id": "f4db56d",
		"email": "hicorook@gmail.com",
		"name": "Corinne",
		"createdDate": "2022-01-11 23:35:42",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 214,
		"totalCountries": 10,
		"totalStates": 41,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 740,
		"schedulePercent": 0.396371,
		"totalGuests": 472,
		"guestPercent": 0.681764,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "Royale",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 53
	},
	{
		"id": "df7d936",
		"email": "fher@beesmail.com",
		"name": "Fabian ",
		"createdDate": "2015-03-21 01:52:37",
		"totalOrganizations": 10,
		"totalTours": 8,
		"totalDays": 944,
		"totalCountries": 11,
		"totalStates": 50,
		"totalEvents": 300,
		"eventPercent": 0.915508,
		"totalScheduleItems": 3263,
		"schedulePercent": 0.874851,
		"totalGuests": 269,
		"guestPercent": 0.589193,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "Auditorio Marina Sur",
		"mostGuestCity": "Valencia",
		"mostGuestAmount": 31
	},
	{
		"id": "ff0409e",
		"email": "kathrynparaskevas@gmail.com",
		"name": "Kathryn",
		"createdDate": "2022-02-02 17:10:15",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 454,
		"totalCountries": 29,
		"totalStates": 66,
		"totalEvents": 154,
		"eventPercent": 0.71368,
		"totalScheduleItems": 3056,
		"schedulePercent": 0.857105,
		"totalGuests": 1175,
		"guestPercent": 0.853132,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 134
	},
	{
		"id": "1ad0851",
		"email": "mindblowingp@gmail.com",
		"name": "Nestor",
		"createdDate": "2019-04-12 16:03:22",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 68,
		"totalCountries": 10,
		"totalStates": 15,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 101,
		"schedulePercent": 0.0908489,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e8fd42e",
		"email": "brad@woodsmusic.com",
		"name": "Brandon",
		"createdDate": "2020-07-01 21:39:20",
		"totalOrganizations": 1,
		"totalTours": 13,
		"totalDays": 212,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1125,
		"schedulePercent": 0.51768,
		"totalGuests": 112,
		"guestPercent": 0.484836,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 55
	},
	{
		"id": "a7a0d23",
		"email": "dshepp5@icloud.com",
		"name": "David",
		"createdDate": "2015-03-23 22:44:28",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 403,
		"totalCountries": 5,
		"totalStates": 10,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 985,
		"schedulePercent": 0.47795,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5073c57",
		"email": "Jirubio1986@aol.com",
		"name": "Juan",
		"createdDate": "2021-05-27 15:43:08",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 936,
		"totalCountries": 5,
		"totalStates": 51,
		"totalEvents": 287,
		"eventPercent": 0.9069,
		"totalScheduleItems": 3336,
		"schedulePercent": 0.88187,
		"totalGuests": 1309,
		"guestPercent": 0.872997,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 105
	},
	{
		"id": "c5dd28a",
		"email": "lee@spicymanagement.com",
		"name": "Lee",
		"createdDate": "2019-05-17 10:01:20",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 44,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 176,
		"schedulePercent": 0.136008,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "40e57bb",
		"email": "fefefelixix@gmail.com",
		"name": "Felix",
		"createdDate": "2022-08-23 22:46:05",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 182,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 815,
		"schedulePercent": 0.424844,
		"totalGuests": 148,
		"guestPercent": 0.51225,
		"mostGuestDate": "2023-04-09 00:00:00",
		"mostGuestName": "(Le) Poisson Rouge",
		"mostGuestCity": "New York",
		"mostGuestAmount": 25
	},
	{
		"id": "5ac9eba",
		"email": "kate@spinningtopmusic.com",
		"name": "Kate",
		"createdDate": "2019-02-15 04:09:33",
		"totalOrganizations": 14,
		"totalTours": 40,
		"totalDays": 782,
		"totalCountries": 4,
		"totalStates": 26,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 1251,
		"schedulePercent": 0.552774,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "Vinyl",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 1
	},
	{
		"id": "541812c",
		"email": "pbushee.pb@gmail.com",
		"name": "Philip",
		"createdDate": "2018-03-18 21:12:49",
		"totalOrganizations": 15,
		"totalTours": 14,
		"totalDays": 585,
		"totalCountries": 8,
		"totalStates": 51,
		"totalEvents": 312,
		"eventPercent": 0.921997,
		"totalScheduleItems": 2894,
		"schedulePercent": 0.840948,
		"totalGuests": 1750,
		"guestPercent": 0.9167,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "NRG Stadium",
		"mostGuestCity": "Houston",
		"mostGuestAmount": 100
	},
	{
		"id": "5a8c6f4",
		"email": "stevecurtis2@yahoo.com",
		"name": "Steve",
		"createdDate": "2017-09-14 17:53:49",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 729,
		"totalCountries": 16,
		"totalStates": 41,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 2150,
		"schedulePercent": 0.742286,
		"totalGuests": 314,
		"guestPercent": 0.61131,
		"mostGuestDate": "2023-07-05 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 57
	},
	{
		"id": "abe0a9c",
		"email": "alexissmithmedia@gmail.com",
		"name": "Alexis",
		"createdDate": "2021-09-18 20:48:47",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 165,
		"totalCountries": 5,
		"totalStates": 22,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 1180,
		"schedulePercent": 0.534896,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "155e488",
		"email": "jrsproductionservicesinc@gmail.com",
		"name": "Josh",
		"createdDate": "2022-02-16 16:09:45",
		"totalOrganizations": 10,
		"totalTours": 8,
		"totalDays": 588,
		"totalCountries": 4,
		"totalStates": 44,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 2308,
		"schedulePercent": 0.766389,
		"totalGuests": 496,
		"guestPercent": 0.690637,
		"mostGuestDate": "2023-07-26 00:00:00",
		"mostGuestName": "Santander Arena",
		"mostGuestCity": "Reading",
		"mostGuestAmount": 52
	},
	{
		"id": "4f8f742",
		"email": "fiona@fionazwieb.com",
		"name": "Fiona",
		"createdDate": "2016-02-23 15:47:13",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 61,
		"totalCountries": 7,
		"totalStates": 23,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 394,
		"schedulePercent": 0.255066,
		"totalGuests": 16,
		"guestPercent": 0.361012,
		"mostGuestDate": "2023-04-20 00:00:00",
		"mostGuestName": "Miami Theater Center",
		"mostGuestCity": "Miami Shores",
		"mostGuestAmount": 16
	},
	{
		"id": "7c8ac56",
		"email": "charlesmiericke@gmail.com",
		"name": "Charles",
		"createdDate": "2022-12-23 17:43:13",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 337,
		"totalCountries": 3,
		"totalStates": 43,
		"totalEvents": 248,
		"eventPercent": 0.869554,
		"totalScheduleItems": 2448,
		"schedulePercent": 0.785591,
		"totalGuests": 58,
		"guestPercent": 0.431863,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "Kauffman Center for the Performing Arts",
		"mostGuestCity": "Kansas City",
		"mostGuestAmount": 18
	},
	{
		"id": "671cf70",
		"email": "Elsa.DeCampos@LiveNation.fi",
		"name": "Elsa",
		"createdDate": "2017-05-16 10:01:18",
		"totalOrganizations": 41,
		"totalTours": 36,
		"totalDays": 687,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 602,
		"eventPercent": 0.982784,
		"totalScheduleItems": 2576,
		"schedulePercent": 0.80241,
		"totalGuests": 73,
		"guestPercent": 0.447755,
		"mostGuestDate": "2023-11-26 00:00:00",
		"mostGuestName": "Tavastia",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 19
	},
	{
		"id": "5d5b9d6",
		"email": "chris@sorrentostrategic.com.au",
		"name": "Chris",
		"createdDate": "2018-05-29 05:17:48",
		"totalOrganizations": 2,
		"totalTours": 15,
		"totalDays": 170,
		"totalCountries": 6,
		"totalStates": 32,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 717,
		"schedulePercent": 0.387233,
		"totalGuests": 83,
		"guestPercent": 0.45835,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "Sidney Myer Music Bowl",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 25
	},
	{
		"id": "bbc882f",
		"email": "toddtouring@gmail.com",
		"name": "Todd",
		"createdDate": "2017-08-22 16:31:19",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 927,
		"totalCountries": 13,
		"totalStates": 66,
		"totalEvents": 319,
		"eventPercent": 0.925175,
		"totalScheduleItems": 5437,
		"schedulePercent": 0.972322,
		"totalGuests": 3580,
		"guestPercent": 0.978678,
		"mostGuestDate": "2023-08-09 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 135
	},
	{
		"id": "577e147",
		"email": "kieper@event-werker.com",
		"name": "Jannik",
		"createdDate": "2023-05-06 10:44:47",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 31,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 268,
		"schedulePercent": 0.190836,
		"totalGuests": 226,
		"guestPercent": 0.566945,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "SAP Arena",
		"mostGuestCity": "Mannheim",
		"mostGuestAmount": 61
	},
	{
		"id": "d0325cb",
		"email": "ronishmgmt@gmail.com",
		"name": "Ronnie",
		"createdDate": "2017-09-02 20:37:37",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 180,
		"totalCountries": 5,
		"totalStates": 44,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 1300,
		"schedulePercent": 0.56668,
		"totalGuests": 644,
		"guestPercent": 0.737253,
		"mostGuestDate": "2023-06-02 00:00:00",
		"mostGuestName": "The Peppermint Club",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 51
	},
	{
		"id": "d8ffea4",
		"email": "jmanwalker@gmail.com",
		"name": "Jay",
		"createdDate": "2022-04-27 18:12:16",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 54,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 160,
		"schedulePercent": 0.126871,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Lincoln Hall",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 6
	},
	{
		"id": "c072042",
		"email": "Steven@sjdcreative.net",
		"name": "Steven",
		"createdDate": "2023-03-22 21:46:16",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 99,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 288,
		"schedulePercent": 0.201828,
		"totalGuests": 175,
		"guestPercent": 0.533439,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "Manchester Academy",
		"mostGuestCity": "Manchester",
		"mostGuestAmount": 42
	},
	{
		"id": "6bfdbd3",
		"email": "hodges.alexandria@gmail.com",
		"name": "Alexandria",
		"createdDate": "2018-12-18 22:58:55",
		"totalOrganizations": 13,
		"totalTours": 9,
		"totalDays": 556,
		"totalCountries": 15,
		"totalStates": 40,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 1785,
		"schedulePercent": 0.682161,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b03480d",
		"email": "djesuddmusic@gmail.com",
		"name": "E",
		"createdDate": "2022-10-19 17:01:43",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 133,
		"totalCountries": 1,
		"totalStates": 26,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 437,
		"schedulePercent": 0.271355,
		"totalGuests": 75,
		"guestPercent": 0.450007,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Nile Theater",
		"mostGuestCity": "Mesa",
		"mostGuestAmount": 22
	},
	{
		"id": "35e6a46",
		"email": "martonbisitsguitar@gmail.com",
		"name": "Marton ",
		"createdDate": "2021-04-23 23:31:14",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 97,
		"totalCountries": 6,
		"totalStates": 15,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 214,
		"schedulePercent": 0.159184,
		"totalGuests": 13,
		"guestPercent": 0.352536,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "Underground Arts",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 11
	},
	{
		"id": "1a5a1cd",
		"email": "avery.j.sullivan@gmail.com",
		"name": "Avery",
		"createdDate": "2022-01-16 23:03:27",
		"totalOrganizations": 5,
		"totalTours": 13,
		"totalDays": 235,
		"totalCountries": 5,
		"totalStates": 42,
		"totalEvents": 133,
		"eventPercent": 0.656602,
		"totalScheduleItems": 1521,
		"schedulePercent": 0.623361,
		"totalGuests": 925,
		"guestPercent": 0.805191,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "The Armory",
		"mostGuestCity": "Minneapolis",
		"mostGuestAmount": 76
	},
	{
		"id": "d2deeae",
		"email": "frostchildrentour@gmail.com",
		"name": "Brian",
		"createdDate": "2023-07-26 23:20:11",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 44,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ce6016f",
		"email": "nycentral13@gmail.com",
		"name": "Eric",
		"createdDate": "2022-03-19 22:43:15",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 43,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 201,
		"schedulePercent": 0.151106,
		"totalGuests": 48,
		"guestPercent": 0.419415,
		"mostGuestDate": "2023-04-11 00:00:00",
		"mostGuestName": "Lunario",
		"mostGuestCity": "MÃ©xico D.F.",
		"mostGuestAmount": 12
	},
	{
		"id": "56742de",
		"email": "chrisjpaules@gmail.com",
		"name": "Chris",
		"createdDate": "2013-08-19 22:42:27",
		"totalOrganizations": 9,
		"totalTours": 6,
		"totalDays": 209,
		"totalCountries": 6,
		"totalStates": 32,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 1103,
		"schedulePercent": 0.512382,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-09-22 00:00:00",
		"mostGuestName": "O2 Academy Birmingham",
		"mostGuestCity": "Birmingham",
		"mostGuestAmount": 5
	},
	{
		"id": "69aa1ac",
		"email": "ian@wpl-music.com",
		"name": "Ian",
		"createdDate": "2016-03-17 15:30:10",
		"totalOrganizations": 9,
		"totalTours": 12,
		"totalDays": 465,
		"totalCountries": 7,
		"totalStates": 16,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1397,
		"schedulePercent": 0.59065,
		"totalGuests": 304,
		"guestPercent": 0.605218,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Wolverhampton Civic Hall",
		"mostGuestCity": "Wolverhampton",
		"mostGuestAmount": 70
	},
	{
		"id": "e27365c",
		"email": "Joseluis@vpsmusic.com",
		"name": "JOSE ",
		"createdDate": "2022-08-10 19:48:32",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 116,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 138,
		"schedulePercent": 0.112171,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "dd71840",
		"email": "grannanethan@yahoo.com",
		"name": "Ethan",
		"createdDate": "2023-04-24 20:43:55",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 231,
		"totalCountries": 1,
		"totalStates": 31,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 219,
		"schedulePercent": 0.16276,
		"totalGuests": 62,
		"guestPercent": 0.437028,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "Toyota Field",
		"mostGuestCity": "Madison",
		"mostGuestAmount": 13
	},
	{
		"id": "c6b268d",
		"email": "martinchandra@hotmail.com",
		"name": "Martin",
		"createdDate": "2013-02-13 18:06:06",
		"totalOrganizations": 22,
		"totalTours": 7,
		"totalDays": 198,
		"totalCountries": 6,
		"totalStates": 26,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 1255,
		"schedulePercent": 0.554099,
		"totalGuests": 337,
		"guestPercent": 0.621375,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Goteborgs Konserthus Big Hall",
		"mostGuestCity": "Goteborg",
		"mostGuestAmount": 48
	},
	{
		"id": "c05d721",
		"email": "Shannon@vaden.biz",
		"name": "Shannon",
		"createdDate": "2012-02-27 15:28:01",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 371,
		"totalCountries": 1,
		"totalStates": 26,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 519,
		"schedulePercent": 0.307641,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "232149d",
		"email": "bobj@jerseysurf.org",
		"name": "Bob",
		"createdDate": "2016-02-07 04:35:30",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 52,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 13,
		"eventPercent": 0.0892597,
		"totalScheduleItems": 560,
		"schedulePercent": 0.325652,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "dc87b2c",
		"email": "bettywhomastertour@gmail.com",
		"name": "Cynthia ",
		"createdDate": "2014-04-16 22:55:59",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 235,
		"totalCountries": 8,
		"totalStates": 31,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 534,
		"schedulePercent": 0.314793,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e7f05da",
		"email": "logigal@me.com",
		"name": "Jennifer",
		"createdDate": "2013-02-07 12:45:34",
		"totalOrganizations": 11,
		"totalTours": 14,
		"totalDays": 441,
		"totalCountries": 13,
		"totalStates": 25,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1327,
		"schedulePercent": 0.573831,
		"totalGuests": 36,
		"guestPercent": 0.397961,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Ridgefield Playhouse",
		"mostGuestCity": "Ridgefield",
		"mostGuestAmount": 16
	},
	{
		"id": "f6ca6fb",
		"email": "shanrouting@gmail.com",
		"name": "Bonnie",
		"createdDate": "2023-09-28 16:10:01",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 100,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 220,
		"schedulePercent": 0.163554,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ef65fa8",
		"email": "kai@rocketentertainment.com",
		"name": "Kai",
		"createdDate": "2022-01-04 19:56:02",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 130,
		"totalCountries": 3,
		"totalStates": 6,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 213,
		"schedulePercent": 0.158125,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0355c1e",
		"email": "annette@tajmer.dk",
		"name": "Annette",
		"createdDate": "2015-10-20 09:22:19",
		"totalOrganizations": 11,
		"totalTours": 5,
		"totalDays": 143,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 831,
		"schedulePercent": 0.431334,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f28910c",
		"email": "dylanwheeler903tm@gmail.com",
		"name": "Dylan",
		"createdDate": "2021-05-18 16:01:49",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 155,
		"totalCountries": 1,
		"totalStates": 24,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 802,
		"schedulePercent": 0.421269,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ad2897d",
		"email": "toni@akaaustralia.com.au",
		"name": "toni",
		"createdDate": "2014-11-22 16:41:21",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 392,
		"totalCountries": 3,
		"totalStates": 18,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 944,
		"schedulePercent": 0.463515,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ac67960",
		"email": "team@charlottesands.com",
		"name": "Charlotte",
		"createdDate": "2021-09-21 17:22:35",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 110,
		"totalCountries": 8,
		"totalStates": 30,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 451,
		"schedulePercent": 0.277049,
		"totalGuests": 30,
		"guestPercent": 0.389485,
		"mostGuestDate": "2023-01-26 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 11
	},
	{
		"id": "0bee789",
		"email": "amy.amanda.waters@gmail.com",
		"name": "Amy",
		"createdDate": "2017-08-09 15:36:12",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 79,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 621,
		"schedulePercent": 0.35002,
		"totalGuests": 120,
		"guestPercent": 0.490134,
		"mostGuestDate": "2023-09-06 00:00:00",
		"mostGuestName": "Ponte Vedra Concert Hall",
		"mostGuestCity": "Ponte Vedra Beach",
		"mostGuestAmount": 26
	},
	{
		"id": "4837791",
		"email": "rick@goodintent.com.au",
		"name": "Rick",
		"createdDate": "2023-03-09 23:26:52",
		"totalOrganizations": 2,
		"totalTours": 8,
		"totalDays": 146,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 422,
		"schedulePercent": 0.26513,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "910f9e6",
		"email": "emily.condra@elevationchurch.org",
		"name": "Emily",
		"createdDate": "2022-08-17 00:41:23",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 52,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 789,
		"schedulePercent": 0.415971,
		"totalGuests": 3514,
		"guestPercent": 0.977751,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "Moody Center",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 314
	},
	{
		"id": "b2e67f8",
		"email": "jcolduvell@eighteentwentysix.com",
		"name": "Joseph",
		"createdDate": "2023-10-11 21:16:43",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 42,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 509,
		"schedulePercent": 0.303536,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c0edc0b",
		"email": "ryan@whiskeyjam.com",
		"name": "Ryan",
		"createdDate": "2022-11-03 16:48:53",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 167,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 992,
		"schedulePercent": 0.481658,
		"totalGuests": 968,
		"guestPercent": 0.816978,
		"mostGuestDate": "2023-05-28 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 121
	},
	{
		"id": "0aa8502",
		"email": "poppy@crsmanagement.co.nz",
		"name": "Poppy",
		"createdDate": "2018-08-14 23:39:57",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 334,
		"totalCountries": 13,
		"totalStates": 28,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 791,
		"schedulePercent": 0.417296,
		"totalGuests": 44,
		"guestPercent": 0.411469,
		"mostGuestDate": "2023-04-17 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 39
	},
	{
		"id": "2b07729",
		"email": "Zimtodd@gmail.com",
		"name": "Todd",
		"createdDate": "2009-12-03 20:15:43",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 98,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 391,
		"schedulePercent": 0.253344,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5c37603",
		"email": "craigalms@gmail.com",
		"name": "Craig",
		"createdDate": "2022-11-01 02:35:37",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 95,
		"totalCountries": 1,
		"totalStates": 23,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 475,
		"schedulePercent": 0.287247,
		"totalGuests": 12,
		"guestPercent": 0.348828,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Marquee Theatre",
		"mostGuestCity": "Tempe",
		"mostGuestAmount": 10
	},
	{
		"id": "9b2f44e",
		"email": "sasha@sixshooterrecords.com",
		"name": "Sasha",
		"createdDate": "2023-03-08 20:36:38",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 1028,
		"totalCountries": 9,
		"totalStates": 48,
		"totalEvents": 213,
		"eventPercent": 0.827837,
		"totalScheduleItems": 1760,
		"schedulePercent": 0.676599,
		"totalGuests": 657,
		"guestPercent": 0.741226,
		"mostGuestDate": "2023-06-14 00:00:00",
		"mostGuestName": "TD Music Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 65
	},
	{
		"id": "fde10a0",
		"email": "mitchellkenne@gmail.com",
		"name": "Mitchell",
		"createdDate": "2016-02-19 23:19:17",
		"totalOrganizations": 11,
		"totalTours": 23,
		"totalDays": 871,
		"totalCountries": 21,
		"totalStates": 89,
		"totalEvents": 349,
		"eventPercent": 0.940803,
		"totalScheduleItems": 4426,
		"schedulePercent": 0.942259,
		"totalGuests": 1610,
		"guestPercent": 0.903854,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 43
	},
	{
		"id": "5c1b51c",
		"email": "phillystringz@gmail.com",
		"name": "Phil",
		"createdDate": "2016-05-13 13:47:34",
		"totalOrganizations": 4,
		"totalTours": 10,
		"totalDays": 714,
		"totalCountries": 23,
		"totalStates": 70,
		"totalEvents": 193,
		"eventPercent": 0.792743,
		"totalScheduleItems": 1926,
		"schedulePercent": 0.707059,
		"totalGuests": 392,
		"guestPercent": 0.650113,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "The Majestic Ventura Theater",
		"mostGuestCity": "Ventura",
		"mostGuestAmount": 43
	},
	{
		"id": "b662f78",
		"email": "will@kontourproductions.com",
		"name": "Will",
		"createdDate": "2021-07-26 15:41:02",
		"totalOrganizations": 71,
		"totalTours": 65,
		"totalDays": 1023,
		"totalCountries": 6,
		"totalStates": 19,
		"totalEvents": 568,
		"eventPercent": 0.980665,
		"totalScheduleItems": 4640,
		"schedulePercent": 0.949278,
		"totalGuests": 120,
		"guestPercent": 0.490134,
		"mostGuestDate": "2023-05-30 00:00:00",
		"mostGuestName": "Richmond Theatre",
		"mostGuestCity": "Richmond",
		"mostGuestAmount": 21
	},
	{
		"id": "447b329",
		"email": "ssouthwell01@gmail.com",
		"name": "Simone",
		"createdDate": "2020-01-16 19:02:34",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 451,
		"totalCountries": 5,
		"totalStates": 32,
		"totalEvents": 183,
		"eventPercent": 0.775659,
		"totalScheduleItems": 2583,
		"schedulePercent": 0.803337,
		"totalGuests": 2027,
		"guestPercent": 0.935108,
		"mostGuestDate": "2023-09-07 00:00:00",
		"mostGuestName": "Irving Plaza",
		"mostGuestCity": "New York",
		"mostGuestAmount": 94
	},
	{
		"id": "9d99eb0",
		"email": "clementperault.tm@gmail.com",
		"name": "Clement",
		"createdDate": "2021-10-29 13:08:06",
		"totalOrganizations": 6,
		"totalTours": 9,
		"totalDays": 548,
		"totalCountries": 20,
		"totalStates": 67,
		"totalEvents": 231,
		"eventPercent": 0.850616,
		"totalScheduleItems": 3102,
		"schedulePercent": 0.861608,
		"totalGuests": 228,
		"guestPercent": 0.568137,
		"mostGuestDate": "2023-05-23 00:00:00",
		"mostGuestName": "Le Nouveau SiÃ¨cle",
		"mostGuestCity": "Lille",
		"mostGuestAmount": 14
	},
	{
		"id": "42ee07c",
		"email": "harmonm88@gmail.com",
		"name": "Matt",
		"createdDate": "2018-01-30 22:28:22",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 96,
		"totalCountries": 4,
		"totalStates": 34,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 532,
		"schedulePercent": 0.313733,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-07-16 00:00:00",
		"mostGuestName": "House of Independents",
		"mostGuestCity": "Asbury Park",
		"mostGuestAmount": 1
	},
	{
		"id": "fb47ac0",
		"email": "Livetdv@gmail.com",
		"name": "Francesco",
		"createdDate": "2013-08-13 14:30:34",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 117,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 1414,
		"schedulePercent": 0.594623,
		"totalGuests": 964,
		"guestPercent": 0.816448,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Mizner Park Amphitheatre",
		"mostGuestCity": "Boca Raton",
		"mostGuestAmount": 80
	},
	{
		"id": "5adbc22",
		"email": "ryanmclifford@gmail.com",
		"name": "Ryan",
		"createdDate": "2022-12-14 03:05:58",
		"totalOrganizations": 1,
		"totalTours": 23,
		"totalDays": 117,
		"totalCountries": 1,
		"totalStates": 24,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 479,
		"schedulePercent": 0.288703,
		"totalGuests": 55,
		"guestPercent": 0.42842,
		"mostGuestDate": "2023-09-03 00:00:00",
		"mostGuestName": "BoatYard Lake Norman",
		"mostGuestCity": "Cornelius",
		"mostGuestAmount": 9
	},
	{
		"id": "b84e5df",
		"email": "lukep.reynolds@gmail.com",
		"name": "Luke",
		"createdDate": "2015-10-13 02:06:54",
		"totalOrganizations": 27,
		"totalTours": 23,
		"totalDays": 3748,
		"totalCountries": 9,
		"totalStates": 70,
		"totalEvents": 939,
		"eventPercent": 0.989405,
		"totalScheduleItems": 8939,
		"schedulePercent": 0.996027,
		"totalGuests": 1132,
		"guestPercent": 0.845848,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 64
	},
	{
		"id": "ecfa1a1",
		"email": "tourbusdriver55@gmail.com",
		"name": "Randy",
		"createdDate": "2021-09-06 02:14:41",
		"totalOrganizations": 10,
		"totalTours": 11,
		"totalDays": 451,
		"totalCountries": 9,
		"totalStates": 50,
		"totalEvents": 148,
		"eventPercent": 0.696332,
		"totalScheduleItems": 3089,
		"schedulePercent": 0.860283,
		"totalGuests": 3426,
		"guestPercent": 0.975897,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 299
	},
	{
		"id": "1ceaca7",
		"email": "carolynnmayy@gmail.com",
		"name": "Email",
		"createdDate": "2023-03-27 17:16:28",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 531,
		"totalCountries": 14,
		"totalStates": 43,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 826,
		"schedulePercent": 0.42842,
		"totalGuests": 45,
		"guestPercent": 0.412925,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Jazz At Lincoln Center",
		"mostGuestCity": "New York",
		"mostGuestAmount": 18
	},
	{
		"id": "954cd3b",
		"email": "Mark@haydenhaddock.com",
		"name": "Mark",
		"createdDate": "2019-09-10 03:01:46",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 362,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 4,
		"schedulePercent": 0.0139054,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e65d35e",
		"email": "shawnjamessoul@gmail.com",
		"name": "Shawn ",
		"createdDate": "2017-06-29 22:25:03",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 53,
		"totalCountries": 3,
		"totalStates": 33,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 420,
		"schedulePercent": 0.263939,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-09-24 00:00:00",
		"mostGuestName": "Crescent Ballroom",
		"mostGuestCity": "Phoenix",
		"mostGuestAmount": 4
	},
	{
		"id": "68435d0",
		"email": "lewis.lovely@gmail.com",
		"name": "Lewis",
		"createdDate": "2012-01-04 04:07:28",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 531,
		"totalCountries": 12,
		"totalStates": 60,
		"totalEvents": 213,
		"eventPercent": 0.827837,
		"totalScheduleItems": 2002,
		"schedulePercent": 0.719375,
		"totalGuests": 1728,
		"guestPercent": 0.915243,
		"mostGuestDate": "2023-01-12 00:00:00",
		"mostGuestName": "Riverside Revival Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 149
	},
	{
		"id": "0f10700",
		"email": "ryanwestbrook@me.com",
		"name": "Ryan",
		"createdDate": "2010-01-29 22:50:35",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 767,
		"totalCountries": 11,
		"totalStates": 46,
		"totalEvents": 151,
		"eventPercent": 0.705469,
		"totalScheduleItems": 2599,
		"schedulePercent": 0.807443,
		"totalGuests": 1533,
		"guestPercent": 0.898027,
		"mostGuestDate": "2023-06-04 00:00:00",
		"mostGuestName": "Central Park SummerStage",
		"mostGuestCity": "New York",
		"mostGuestAmount": 74
	},
	{
		"id": "360db67",
		"email": "toliusmail@gmail.com",
		"name": "Grant",
		"createdDate": "2012-11-02 20:01:04",
		"totalOrganizations": 20,
		"totalTours": 25,
		"totalDays": 1065,
		"totalCountries": 23,
		"totalStates": 82,
		"totalEvents": 349,
		"eventPercent": 0.940803,
		"totalScheduleItems": 4762,
		"schedulePercent": 0.954046,
		"totalGuests": 1052,
		"guestPercent": 0.833135,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 134
	},
	{
		"id": "5bdd17e",
		"email": "kennedy@goodforshowproductions.com",
		"name": "Kennedy",
		"createdDate": "2022-10-24 15:43:57",
		"totalOrganizations": 9,
		"totalTours": 9,
		"totalDays": 2002,
		"totalCountries": 8,
		"totalStates": 61,
		"totalEvents": 397,
		"eventPercent": 0.956562,
		"totalScheduleItems": 4038,
		"schedulePercent": 0.924646,
		"totalGuests": 373,
		"guestPercent": 0.642564,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 64
	},
	{
		"id": "691cf24",
		"email": "kaswaag@gmail.com",
		"name": "Kasper",
		"createdDate": "2017-01-05 11:50:42",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 482,
		"totalCountries": 11,
		"totalStates": 31,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 1135,
		"schedulePercent": 0.52205,
		"totalGuests": 72,
		"guestPercent": 0.446828,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "Studentersamfundet i Trondheim",
		"mostGuestCity": "Trondheim",
		"mostGuestAmount": 70
	},
	{
		"id": "737282c",
		"email": "brendan.goers@gmail.com",
		"name": "Brendan",
		"createdDate": "2017-01-30 06:31:00",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 146,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 874,
		"schedulePercent": 0.444974,
		"totalGuests": 1198,
		"guestPercent": 0.857105,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Nashville Municipal Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 111
	},
	{
		"id": "4a3e660",
		"email": "Jack.brewislawes@gmail.com",
		"name": "Jack",
		"createdDate": "2017-11-30 22:13:49",
		"totalOrganizations": 29,
		"totalTours": 30,
		"totalDays": 799,
		"totalCountries": 15,
		"totalStates": 80,
		"totalEvents": 358,
		"eventPercent": 0.945305,
		"totalScheduleItems": 5507,
		"schedulePercent": 0.973249,
		"totalGuests": 983,
		"guestPercent": 0.820156,
		"mostGuestDate": "2023-11-19 00:00:00",
		"mostGuestName": "Max-Schmeling-Halle",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 64
	},
	{
		"id": "713d299",
		"email": "patrick.a.bond@gmail.com",
		"name": "Patrick",
		"createdDate": "2012-10-17 22:51:03",
		"totalOrganizations": 7,
		"totalTours": 15,
		"totalDays": 280,
		"totalCountries": 5,
		"totalStates": 19,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 215,
		"schedulePercent": 0.159846,
		"totalGuests": 737,
		"guestPercent": 0.761224,
		"mostGuestDate": "2023-10-04 00:00:00",
		"mostGuestName": "Death Mountain",
		"mostGuestCity": "Lakes O'Fire",
		"mostGuestAmount": 666
	},
	{
		"id": "295c1d0",
		"email": "storey.jimi@gmail.com",
		"name": "Jimi",
		"createdDate": "2013-01-05 03:18:32",
		"totalOrganizations": 12,
		"totalTours": 17,
		"totalDays": 1425,
		"totalCountries": 21,
		"totalStates": 75,
		"totalEvents": 354,
		"eventPercent": 0.943584,
		"totalScheduleItems": 3934,
		"schedulePercent": 0.920275,
		"totalGuests": 1699,
		"guestPercent": 0.913124,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 243
	},
	{
		"id": "484a128",
		"email": "tee@kuntreegirl.com",
		"name": "Tamika",
		"createdDate": "2023-02-08 18:22:08",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 290,
		"totalCountries": 4,
		"totalStates": 30,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 1795,
		"schedulePercent": 0.684545,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "348a435",
		"email": "shenitsky@gmail.com",
		"name": "Alexander",
		"createdDate": "2019-06-21 19:51:44",
		"totalOrganizations": 8,
		"totalTours": 25,
		"totalDays": 383,
		"totalCountries": 10,
		"totalStates": 53,
		"totalEvents": 176,
		"eventPercent": 0.763475,
		"totalScheduleItems": 2246,
		"schedulePercent": 0.757118,
		"totalGuests": 1127,
		"guestPercent": 0.843994,
		"mostGuestDate": "2023-05-14 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 77
	},
	{
		"id": "1cbf67b",
		"email": "lowecharlie@icloud.com",
		"name": "Charlie",
		"createdDate": "2021-09-15 23:18:03",
		"totalOrganizations": 6,
		"totalTours": 14,
		"totalDays": 896,
		"totalCountries": 16,
		"totalStates": 62,
		"totalEvents": 182,
		"eventPercent": 0.773408,
		"totalScheduleItems": 1997,
		"schedulePercent": 0.717786,
		"totalGuests": 793,
		"guestPercent": 0.774997,
		"mostGuestDate": "2023-08-14 00:00:00",
		"mostGuestName": "Grand Ole Opry",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 35
	},
	{
		"id": "fb08da1",
		"email": "jkgrss@gmail.com",
		"name": "Jake",
		"createdDate": "2011-07-05 20:43:25",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 212,
		"totalCountries": 3,
		"totalStates": 30,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 658,
		"schedulePercent": 0.364455,
		"totalGuests": 781,
		"guestPercent": 0.771951,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "The Theatre at Ace Hotel",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 153
	},
	{
		"id": "324e232",
		"email": "beatrizera@hotmail.com",
		"name": "Beatriz",
		"createdDate": "2022-07-27 21:00:25",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 387,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 369,
		"schedulePercent": 0.242749,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "abb8445",
		"email": "tommymcdonald1@gmail.com",
		"name": "Tommy ",
		"createdDate": "2012-04-03 05:39:01",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 465,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 987,
		"schedulePercent": 0.479142,
		"totalGuests": 701,
		"guestPercent": 0.75341,
		"mostGuestDate": "2023-02-09 00:00:00",
		"mostGuestName": "Phoenix Open Golf Tournament",
		"mostGuestCity": "Scottsdale",
		"mostGuestAmount": 94
	},
	{
		"id": "3814f36",
		"email": "travisfnstephens@yahoo.com",
		"name": "Travis",
		"createdDate": "2018-07-31 17:20:10",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 490,
		"totalCountries": 13,
		"totalStates": 44,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 1601,
		"schedulePercent": 0.643358,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4ebe2cf",
		"email": "ally@allyvenableband.com",
		"name": "Ally",
		"createdDate": "2022-09-28 02:14:44",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 130,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 272,
		"schedulePercent": 0.193749,
		"totalGuests": 73,
		"guestPercent": 0.447755,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "The Factory",
		"mostGuestCity": "Chesterfield",
		"mostGuestAmount": 29
	},
	{
		"id": "34c558c",
		"email": "robertocontreras@hotmail.com",
		"name": "Roberto",
		"createdDate": "2010-12-09 23:45:15",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 242,
		"totalCountries": 7,
		"totalStates": 39,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 2041,
		"schedulePercent": 0.725599,
		"totalGuests": 1935,
		"guestPercent": 0.930738,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Glen Helen Amphitheater",
		"mostGuestCity": "San Bernardino",
		"mostGuestAmount": 201
	},
	{
		"id": "48bdb96",
		"email": "suzannemeloche@yahoo.com",
		"name": "Suzanne",
		"createdDate": "2011-05-06 16:40:17",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 122,
		"totalCountries": 12,
		"totalStates": 17,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 104,
		"schedulePercent": 0.0933651,
		"totalGuests": 199,
		"guestPercent": 0.549729,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 75
	},
	{
		"id": "a146670",
		"email": "cody@urlabrand.com",
		"name": "Cody",
		"createdDate": "2022-09-05 22:52:47",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 135,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 484,
		"schedulePercent": 0.290558,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c9db139",
		"email": "cfkibler@gmail.com",
		"name": "Carson",
		"createdDate": "2023-04-18 20:47:00",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 703,
		"totalCountries": 19,
		"totalStates": 48,
		"totalEvents": 156,
		"eventPercent": 0.718051,
		"totalScheduleItems": 2462,
		"schedulePercent": 0.788108,
		"totalGuests": 465,
		"guestPercent": 0.678718,
		"mostGuestDate": "2023-09-05 00:00:00",
		"mostGuestName": "Singapore Indoor Stadium",
		"mostGuestCity": "Singapore",
		"mostGuestAmount": 78
	},
	{
		"id": "c25e7e1",
		"email": "thomas@zamoraprod.com",
		"name": "Thomas",
		"createdDate": "2019-04-05 09:51:10",
		"totalOrganizations": 10,
		"totalTours": 16,
		"totalDays": 658,
		"totalCountries": 10,
		"totalStates": 48,
		"totalEvents": 185,
		"eventPercent": 0.780029,
		"totalScheduleItems": 1772,
		"schedulePercent": 0.678983,
		"totalGuests": 36,
		"guestPercent": 0.397961,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "Parc AndrÃ©-CitroÃ«n",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 21
	},
	{
		"id": "7d2dd07",
		"email": "twothirteen.productions@gmail.com",
		"name": "Petri",
		"createdDate": "2022-07-07 19:42:17",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 155,
		"totalCountries": 14,
		"totalStates": 26,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 1043,
		"schedulePercent": 0.495431,
		"totalGuests": 767,
		"guestPercent": 0.768772,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 91
	},
	{
		"id": "c35894d",
		"email": "joshkluge@gmail.com",
		"name": "Josh",
		"createdDate": "2019-02-21 16:59:44",
		"totalOrganizations": 7,
		"totalTours": 23,
		"totalDays": 708,
		"totalCountries": 15,
		"totalStates": 70,
		"totalEvents": 363,
		"eventPercent": 0.947424,
		"totalScheduleItems": 4804,
		"schedulePercent": 0.955105,
		"totalGuests": 2015,
		"guestPercent": 0.934711,
		"mostGuestDate": "2023-05-19 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 59
	},
	{
		"id": "35d9632",
		"email": "awsprague@gmail.com",
		"name": "Andrew",
		"createdDate": "2017-01-16 23:18:05",
		"totalOrganizations": 15,
		"totalTours": 17,
		"totalDays": 705,
		"totalCountries": 12,
		"totalStates": 57,
		"totalEvents": 228,
		"eventPercent": 0.845848,
		"totalScheduleItems": 2592,
		"schedulePercent": 0.805986,
		"totalGuests": 517,
		"guestPercent": 0.697921,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Regent Theater DTLA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 46
	},
	{
		"id": "53c880a",
		"email": "cameron.breen@yahoo.com",
		"name": "Cameron",
		"createdDate": "2022-01-06 18:22:04",
		"totalOrganizations": 8,
		"totalTours": 15,
		"totalDays": 297,
		"totalCountries": 18,
		"totalStates": 63,
		"totalEvents": 183,
		"eventPercent": 0.775659,
		"totalScheduleItems": 2257,
		"schedulePercent": 0.760297,
		"totalGuests": 2777,
		"guestPercent": 0.963846,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Saint Andrewâ€™s Hall",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 80
	},
	{
		"id": "7d942a9",
		"email": "HENRIQUE@ACOMICSOUL.COM",
		"name": "Henrique",
		"createdDate": "2023-01-16 09:43:58",
		"totalOrganizations": 68,
		"totalTours": 62,
		"totalDays": 756,
		"totalCountries": 26,
		"totalStates": 53,
		"totalEvents": 329,
		"eventPercent": 0.930738,
		"totalScheduleItems": 2452,
		"schedulePercent": 0.785989,
		"totalGuests": 109,
		"guestPercent": 0.481393,
		"mostGuestDate": "2023-08-16 00:00:00",
		"mostGuestName": "Boom Chicago",
		"mostGuestCity": "Amsterdam",
		"mostGuestAmount": 18
	},
	{
		"id": "3455940",
		"email": "mgmt@plzmakeitruins.com",
		"name": "Joseph",
		"createdDate": "2022-07-30 09:27:59",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 122,
		"totalCountries": 8,
		"totalStates": 15,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 269,
		"schedulePercent": 0.191498,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "Printworks London",
		"mostGuestCity": "London",
		"mostGuestAmount": 5
	},
	{
		"id": "3ef5897",
		"email": "management@ambianceartists.com",
		"name": "Eric",
		"createdDate": "2012-07-13 17:31:30",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 308,
		"totalCountries": 1,
		"totalStates": 34,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 813,
		"schedulePercent": 0.424182,
		"totalGuests": 26,
		"guestPercent": 0.381274,
		"mostGuestDate": "2023-06-01 00:00:00",
		"mostGuestName": "Devon Lakeshore Amphitheater",
		"mostGuestCity": "Decatur",
		"mostGuestAmount": 26
	},
	{
		"id": "ea1ef5e",
		"email": "miriam.vasquez@quebecormedia.com",
		"name": "Miriam",
		"createdDate": "2021-09-17 12:22:14",
		"totalOrganizations": 23,
		"totalTours": 12,
		"totalDays": 309,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 280,
		"eventPercent": 0.901867,
		"totalScheduleItems": 1198,
		"schedulePercent": 0.540061,
		"totalGuests": 3259,
		"guestPercent": 0.974043,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "ThÃ©Ã¢tre St-Denis",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 204
	},
	{
		"id": "f7e12c1",
		"email": "itsbrenda@me.com",
		"name": "Brenda",
		"createdDate": "2022-06-18 20:58:33",
		"totalOrganizations": 3,
		"totalTours": 11,
		"totalDays": 436,
		"totalCountries": 3,
		"totalStates": 30,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 1576,
		"schedulePercent": 0.635942,
		"totalGuests": 456,
		"guestPercent": 0.676334,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "The Brooklyn Mirage",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 179
	},
	{
		"id": "ec0ed4c",
		"email": "leerogers12@mac.com",
		"name": "Lee",
		"createdDate": "2021-06-30 00:10:04",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 362,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 8,
		"eventPercent": 0.0535028,
		"totalScheduleItems": 258,
		"schedulePercent": 0.184744,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "dfab944",
		"email": "Claudio@ruidomusic.com",
		"name": "Claudio",
		"createdDate": "2014-03-19 15:18:23",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 93,
		"totalCountries": 6,
		"totalStates": 11,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 274,
		"schedulePercent": 0.195074,
		"totalGuests": 584,
		"guestPercent": 0.720302,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "Concha AcÃºstica de Colinas de Bello Monte",
		"mostGuestCity": "Caracas",
		"mostGuestAmount": 92
	},
	{
		"id": "08daf2b",
		"email": "todd.moody5@gmail.com",
		"name": "Todd",
		"createdDate": "2015-06-15 16:11:08",
		"totalOrganizations": 10,
		"totalTours": 4,
		"totalDays": 407,
		"totalCountries": 14,
		"totalStates": 60,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 2797,
		"schedulePercent": 0.832075,
		"totalGuests": 1768,
		"guestPercent": 0.917759,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "Angel Of The Winds Arena",
		"mostGuestCity": "Everett",
		"mostGuestAmount": 59
	},
	{
		"id": "ed2057f",
		"email": "kocomontaner@me.com",
		"name": "Koco",
		"createdDate": "2015-01-30 01:10:46",
		"totalOrganizations": 14,
		"totalTours": 4,
		"totalDays": 270,
		"totalCountries": 12,
		"totalStates": 48,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 1365,
		"schedulePercent": 0.584029,
		"totalGuests": 1095,
		"guestPercent": 0.838564,
		"mostGuestDate": "2023-05-07 00:00:00",
		"mostGuestName": "Palacio de los Deportes",
		"mostGuestCity": "Ciudad de Mexico",
		"mostGuestAmount": 70
	},
	{
		"id": "53d1110",
		"email": "rktourmgmt@gmail.com",
		"name": "Coby",
		"createdDate": "2022-03-18 20:06:05",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 107,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 616,
		"schedulePercent": 0.347901,
		"totalGuests": 101,
		"guestPercent": 0.474904,
		"mostGuestDate": "2023-03-04 00:00:00",
		"mostGuestName": "Gruene Hall",
		"mostGuestCity": "New Braunfels",
		"mostGuestAmount": 29
	},
	{
		"id": "02b45bf",
		"email": "ella@year0001.com",
		"name": "Ella",
		"createdDate": "2021-11-02 14:10:59",
		"totalOrganizations": 14,
		"totalTours": 15,
		"totalDays": 214,
		"totalCountries": 19,
		"totalStates": 47,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 1285,
		"schedulePercent": 0.56125,
		"totalGuests": 752,
		"guestPercent": 0.765064,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Annexet",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 155
	},
	{
		"id": "efea275",
		"email": "ctikly@me.com",
		"name": "Callum",
		"createdDate": "2016-02-26 20:43:51",
		"totalOrganizations": 9,
		"totalTours": 8,
		"totalDays": 719,
		"totalCountries": 15,
		"totalStates": 65,
		"totalEvents": 133,
		"eventPercent": 0.656602,
		"totalScheduleItems": 1849,
		"schedulePercent": 0.695007,
		"totalGuests": 197,
		"guestPercent": 0.548669,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Oxford Art Factory",
		"mostGuestCity": "Darlinghurst",
		"mostGuestAmount": 46
	},
	{
		"id": "9ff2716",
		"email": "adamlabov@hotmail.com",
		"name": "Adam",
		"createdDate": "2011-05-26 03:21:47",
		"totalOrganizations": 8,
		"totalTours": 11,
		"totalDays": 697,
		"totalCountries": 8,
		"totalStates": 45,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 2089,
		"schedulePercent": 0.733413,
		"totalGuests": 400,
		"guestPercent": 0.653291,
		"mostGuestDate": "2023-06-14 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 138
	},
	{
		"id": "4aea6ee",
		"email": "dalex549@gmail.com",
		"name": "Alex",
		"createdDate": "2018-09-20 15:52:52",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 665,
		"totalCountries": 10,
		"totalStates": 57,
		"totalEvents": 196,
		"eventPercent": 0.798835,
		"totalScheduleItems": 3617,
		"schedulePercent": 0.902132,
		"totalGuests": 3167,
		"guestPercent": 0.972586,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 298
	},
	{
		"id": "28f3b86",
		"email": "marcogigbros@gmail.com",
		"name": "Marko ",
		"createdDate": "2015-11-29 16:36:14",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 369,
		"totalCountries": 6,
		"totalStates": 12,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 572,
		"schedulePercent": 0.328963,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-03-07 00:00:00",
		"mostGuestName": "Das Bett",
		"mostGuestCity": "Frankfurt am Main",
		"mostGuestAmount": 3
	},
	{
		"id": "d282fee",
		"email": "matt7533@gmail.com",
		"name": "Matt",
		"createdDate": "2018-01-30 17:37:27",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 2070,
		"totalCountries": 3,
		"totalStates": 57,
		"totalEvents": 394,
		"eventPercent": 0.955767,
		"totalScheduleItems": 6130,
		"schedulePercent": 0.981195,
		"totalGuests": 5489,
		"guestPercent": 0.994041,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 240
	},
	{
		"id": "a1fbc72",
		"email": "Rubes.harman@gmail.com",
		"name": "Rubes",
		"createdDate": "2013-09-23 01:17:41",
		"totalOrganizations": 17,
		"totalTours": 12,
		"totalDays": 775,
		"totalCountries": 20,
		"totalStates": 56,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 1433,
		"schedulePercent": 0.600053,
		"totalGuests": 81,
		"guestPercent": 0.455966,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Lido",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 16
	},
	{
		"id": "341265f",
		"email": "andreamsmith@mac.com",
		"name": "Andrea",
		"createdDate": "2016-01-28 16:13:31",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 302,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 991,
		"schedulePercent": 0.480996,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3358748",
		"email": "justwalk94@gmail.com",
		"name": "Justin",
		"createdDate": "2016-06-26 04:38:35",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 959,
		"totalCountries": 10,
		"totalStates": 56,
		"totalEvents": 250,
		"eventPercent": 0.872335,
		"totalScheduleItems": 3202,
		"schedulePercent": 0.869951,
		"totalGuests": 801,
		"guestPercent": 0.776453,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 160
	},
	{
		"id": "4660b8b",
		"email": "mreynolds591@gmail.com",
		"name": "Mike",
		"createdDate": "2014-04-05 03:58:54",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 1587,
		"totalCountries": 13,
		"totalStates": 63,
		"totalEvents": 331,
		"eventPercent": 0.932327,
		"totalScheduleItems": 2988,
		"schedulePercent": 0.849821,
		"totalGuests": 1604,
		"guestPercent": 0.903324,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 96
	},
	{
		"id": "1315dc5",
		"email": "ericlouxprodas@gmail.com",
		"name": "Eric",
		"createdDate": "2013-05-05 20:04:35",
		"totalOrganizations": 17,
		"totalTours": 5,
		"totalDays": 176,
		"totalCountries": 15,
		"totalStates": 38,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 939,
		"schedulePercent": 0.462323,
		"totalGuests": 155,
		"guestPercent": 0.51768,
		"mostGuestDate": "2023-05-17 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 30
	},
	{
		"id": "c30b502",
		"email": "ines.bartl.berlin@gmail.com",
		"name": "Ines",
		"createdDate": "2020-01-14 11:10:30",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 29,
		"totalCountries": 6,
		"totalStates": 13,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 261,
		"schedulePercent": 0.185936,
		"totalGuests": 39,
		"guestPercent": 0.404185,
		"mostGuestDate": "2023-05-21 00:00:00",
		"mostGuestName": "Underworld",
		"mostGuestCity": "London",
		"mostGuestAmount": 18
	},
	{
		"id": "91c325f",
		"email": "hammondmds@aol.com",
		"name": "Melvin",
		"createdDate": "2016-12-14 00:24:18",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 174,
		"totalCountries": 1,
		"totalStates": 22,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 684,
		"schedulePercent": 0.374388,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3ca6c21",
		"email": "Kat.nystrom@gmail.com",
		"name": "Katie",
		"createdDate": "2012-06-16 17:49:09",
		"totalOrganizations": 11,
		"totalTours": 5,
		"totalDays": 1326,
		"totalCountries": 13,
		"totalStates": 58,
		"totalEvents": 321,
		"eventPercent": 0.9265,
		"totalScheduleItems": 4523,
		"schedulePercent": 0.946232,
		"totalGuests": 1167,
		"guestPercent": 0.85247,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Broncos Stadium at Mile High Station",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 255
	},
	{
		"id": "663d367",
		"email": "anisia.turunen@warnermusic.com",
		"name": "anisia",
		"createdDate": "2023-08-15 18:51:48",
		"totalOrganizations": 20,
		"totalTours": 20,
		"totalDays": 146,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 595,
		"schedulePercent": 0.337968,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "26ce3bd",
		"email": "dindas@g4dproductions.com",
		"name": "Jon",
		"createdDate": "2018-08-15 21:55:05",
		"totalOrganizations": 5,
		"totalTours": 15,
		"totalDays": 300,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 952,
		"schedulePercent": 0.466163,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fccd1f3",
		"email": "adriane@cegentertainment.com",
		"name": "Adriane",
		"createdDate": "2012-09-07 20:17:34",
		"totalOrganizations": 11,
		"totalTours": 10,
		"totalDays": 379,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 1710,
		"schedulePercent": 0.667461,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e3ce74f",
		"email": "kc@dreamville.com",
		"name": "KC",
		"createdDate": "2016-09-04 19:13:42",
		"totalOrganizations": 12,
		"totalTours": 17,
		"totalDays": 1449,
		"totalCountries": 18,
		"totalStates": 59,
		"totalEvents": 303,
		"eventPercent": 0.916832,
		"totalScheduleItems": 3209,
		"schedulePercent": 0.870348,
		"totalGuests": 1665,
		"guestPercent": 0.908886,
		"mostGuestDate": "2023-02-26 00:00:00",
		"mostGuestName": "Coca-Cola Roxy",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 81
	},
	{
		"id": "50d0984",
		"email": "jmierly@gmail.com",
		"name": "Joshua",
		"createdDate": "2017-06-10 05:57:20",
		"totalOrganizations": 11,
		"totalTours": 14,
		"totalDays": 1240,
		"totalCountries": 20,
		"totalStates": 67,
		"totalEvents": 379,
		"eventPercent": 0.951,
		"totalScheduleItems": 4656,
		"schedulePercent": 0.94994,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "04ac14f",
		"email": "stuartberk@gmail.com",
		"name": "Stuart",
		"createdDate": "2011-07-26 23:29:31",
		"totalOrganizations": 26,
		"totalTours": 16,
		"totalDays": 1247,
		"totalCountries": 19,
		"totalStates": 55,
		"totalEvents": 225,
		"eventPercent": 0.842008,
		"totalScheduleItems": 3369,
		"schedulePercent": 0.885181,
		"totalGuests": 710,
		"guestPercent": 0.755132,
		"mostGuestDate": "2023-09-05 00:00:00",
		"mostGuestName": "Singapore Indoor Stadium",
		"mostGuestCity": "Singapore",
		"mostGuestAmount": 78
	},
	{
		"id": "0603190",
		"email": "simon@stltouring.com",
		"name": "Simon",
		"createdDate": "2017-07-20 17:09:40",
		"totalOrganizations": 22,
		"totalTours": 13,
		"totalDays": 342,
		"totalCountries": 20,
		"totalStates": 67,
		"totalEvents": 159,
		"eventPercent": 0.726394,
		"totalScheduleItems": 2481,
		"schedulePercent": 0.790624,
		"totalGuests": 251,
		"guestPercent": 0.579393,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "Kings Theatre",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 34
	},
	{
		"id": "52c4c17",
		"email": "jacco@events4christ.nl",
		"name": "Jacco",
		"createdDate": "2014-03-16 21:52:57",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 82,
		"totalCountries": 3,
		"totalStates": 4,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 532,
		"schedulePercent": 0.313733,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8093ead",
		"email": "dave@davebarnes.com",
		"name": "Dave",
		"createdDate": "2010-12-02 06:13:00",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 281,
		"totalCountries": 1,
		"totalStates": 26,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 1447,
		"schedulePercent": 0.602834,
		"totalGuests": 938,
		"guestPercent": 0.808767,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 78
	},
	{
		"id": "0dd05c4",
		"email": "darrencrisp62@gmail.com",
		"name": "Darren",
		"createdDate": "2015-05-22 02:18:22",
		"totalOrganizations": 1,
		"totalTours": 9,
		"totalDays": 101,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 616,
		"schedulePercent": 0.347901,
		"totalGuests": 323,
		"guestPercent": 0.61568,
		"mostGuestDate": "2023-08-14 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 61
	},
	{
		"id": "9ddf0e8",
		"email": "eugenerecords@gmail.com",
		"name": "Mark",
		"createdDate": "2016-06-01 20:13:15",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 119,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 763,
		"schedulePercent": 0.406569,
		"totalGuests": 151,
		"guestPercent": 0.515296,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "Zony Mash Beer Project",
		"mostGuestCity": "New Orleans",
		"mostGuestAmount": 40
	},
	{
		"id": "e7b2263",
		"email": "scottwesterwellerbc@gmail.com",
		"name": "Scott",
		"createdDate": "2016-01-03 10:57:30",
		"totalOrganizations": 86,
		"totalTours": 22,
		"totalDays": 679,
		"totalCountries": 11,
		"totalStates": 30,
		"totalEvents": 142,
		"eventPercent": 0.680705,
		"totalScheduleItems": 1736,
		"schedulePercent": 0.671964,
		"totalGuests": 737,
		"guestPercent": 0.761224,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 121
	},
	{
		"id": "65a7dbf",
		"email": "tom@icreatestuff.com",
		"name": "Tom",
		"createdDate": "2018-07-07 06:42:16",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 129,
		"totalCountries": 11,
		"totalStates": 34,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 467,
		"schedulePercent": 0.283406,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "315b938",
		"email": "lonnieferguson1@aol.com",
		"name": "Alonzo",
		"createdDate": "2010-09-24 06:02:16",
		"totalOrganizations": 8,
		"totalTours": 3,
		"totalDays": 302,
		"totalCountries": 1,
		"totalStates": 22,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 1456,
		"schedulePercent": 0.604556,
		"totalGuests": 559,
		"guestPercent": 0.713415,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Cadence Bank Amphitheatre at Chastain Park",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 67
	},
	{
		"id": "fdfbb91",
		"email": "mikewoodhouse89@gmail.com",
		"name": "Mike",
		"createdDate": "2014-09-09 23:40:05",
		"totalOrganizations": 11,
		"totalTours": 13,
		"totalDays": 990,
		"totalCountries": 15,
		"totalStates": 27,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 1676,
		"schedulePercent": 0.658986,
		"totalGuests": 445,
		"guestPercent": 0.670905,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "Church of Saint Bartholomew, Ann Street",
		"mostGuestCity": "Brighton",
		"mostGuestAmount": 29
	},
	{
		"id": "f9bc1fa",
		"email": "garethfromlewis@gmail.com",
		"name": "Gareth",
		"createdDate": "2013-12-10 04:14:07",
		"totalOrganizations": 7,
		"totalTours": 26,
		"totalDays": 598,
		"totalCountries": 17,
		"totalStates": 65,
		"totalEvents": 298,
		"eventPercent": 0.914448,
		"totalScheduleItems": 4170,
		"schedulePercent": 0.932327,
		"totalGuests": 1189,
		"guestPercent": 0.855781,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 90
	},
	{
		"id": "a16e12c",
		"email": "dominic@solver-productions.com",
		"name": "Dominic",
		"createdDate": "2021-04-21 16:20:12",
		"totalOrganizations": 11,
		"totalTours": 12,
		"totalDays": 339,
		"totalCountries": 25,
		"totalStates": 80,
		"totalEvents": 174,
		"eventPercent": 0.759502,
		"totalScheduleItems": 3114,
		"schedulePercent": 0.862932,
		"totalGuests": 1430,
		"guestPercent": 0.885313,
		"mostGuestDate": "2023-10-31 00:00:00",
		"mostGuestName": "Mitsubishi Electric Halle - DÃ¼sseldorf Congress Sport & Event GmbH",
		"mostGuestCity": "DÃ¼sseldorf",
		"mostGuestAmount": 84
	},
	{
		"id": "50c9638",
		"email": "marticar197@gmail.com",
		"name": "Carlos ",
		"createdDate": "2023-10-17 16:05:36",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 67,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 268,
		"schedulePercent": 0.190836,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "11d8e38",
		"email": "shaunmartin@mac.com",
		"name": "Shaun",
		"createdDate": "2015-09-18 12:11:12",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 132,
		"totalCountries": 12,
		"totalStates": 36,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 1016,
		"schedulePercent": 0.490001,
		"totalGuests": 668,
		"guestPercent": 0.74361,
		"mostGuestDate": "2023-02-04 00:00:00",
		"mostGuestName": "OVO Arena, Wembley",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 110
	},
	{
		"id": "0c7612e",
		"email": "john@redcreativegroup.com",
		"name": "John",
		"createdDate": "2023-02-06 01:59:32",
		"totalOrganizations": 2,
		"totalTours": 9,
		"totalDays": 120,
		"totalCountries": 1,
		"totalStates": 31,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 214,
		"schedulePercent": 0.159184,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Starlight Theatre",
		"mostGuestCity": "Kansas City",
		"mostGuestAmount": 6
	},
	{
		"id": "faee3a2",
		"email": "nanoisemusic@gmail.com",
		"name": "Hariet",
		"createdDate": "2023-04-03 23:28:36",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 43,
		"totalCountries": 5,
		"totalStates": 12,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 173,
		"schedulePercent": 0.134684,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4ce6b3a",
		"email": "emily.atkins@weybourne.co.uk",
		"name": "Emily",
		"createdDate": "2022-05-06 15:21:16",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 158,
		"totalCountries": 8,
		"totalStates": 34,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1090,
		"schedulePercent": 0.510131,
		"totalGuests": 200,
		"guestPercent": 0.550258,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Irving Plaza",
		"mostGuestCity": "New York",
		"mostGuestAmount": 53
	},
	{
		"id": "775ae82",
		"email": "cait@iconconcerts.com",
		"name": "Cait ",
		"createdDate": "2021-06-21 17:58:21",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 240,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 78,
		"schedulePercent": 0.0736326,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-12-05 00:00:00",
		"mostGuestName": "The Paramount",
		"mostGuestCity": "Huntington",
		"mostGuestAmount": 4
	},
	{
		"id": "11b9696",
		"email": "wilfarr@wilfarr.com",
		"name": "William",
		"createdDate": "2018-06-05 18:02:12",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 923,
		"totalCountries": 14,
		"totalStates": 48,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 2424,
		"schedulePercent": 0.783075,
		"totalGuests": 643,
		"guestPercent": 0.736988,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "O2 Shepherd's Bush Empire",
		"mostGuestCity": "London",
		"mostGuestAmount": 49
	},
	{
		"id": "b979f67",
		"email": "craig@onesparkent.com",
		"name": "Craig",
		"createdDate": "2014-06-27 18:02:00",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 483,
		"totalCountries": 1,
		"totalStates": 37,
		"totalEvents": 211,
		"eventPercent": 0.825586,
		"totalScheduleItems": 1315,
		"schedulePercent": 0.569991,
		"totalGuests": 185,
		"guestPercent": 0.539664,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "Arrow Rock Lyceum Theatre",
		"mostGuestCity": "Arrow Rock",
		"mostGuestAmount": 22
	},
	{
		"id": "7343427",
		"email": "Jonnystiegler@gmail.com",
		"name": "Jonny",
		"createdDate": "2015-11-30 20:10:30",
		"totalOrganizations": 61,
		"totalTours": 59,
		"totalDays": 2933,
		"totalCountries": 29,
		"totalStates": 100,
		"totalEvents": 869,
		"eventPercent": 0.988611,
		"totalScheduleItems": 11189,
		"schedulePercent": 0.998013,
		"totalGuests": 4528,
		"guestPercent": 0.988478,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 300
	},
	{
		"id": "2d3521c",
		"email": "florian.derschau@litemare.com",
		"name": "Florian von",
		"createdDate": "2017-05-06 15:43:57",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 361,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 183,
		"eventPercent": 0.775659,
		"totalScheduleItems": 657,
		"schedulePercent": 0.364058,
		"totalGuests": 18,
		"guestPercent": 0.364455,
		"mostGuestDate": "2023-04-13 00:00:00",
		"mostGuestName": "QUARTERBACK Immobilien ARENA",
		"mostGuestCity": "Leipzig",
		"mostGuestAmount": 10
	},
	{
		"id": "cff3201",
		"email": "info@dearrouge.com",
		"name": "Drew",
		"createdDate": "2014-11-19 17:50:04",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 113,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 154,
		"schedulePercent": 0.123692,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "50ac828",
		"email": "adrian@rcam.com",
		"name": "Adrian",
		"createdDate": "2021-05-21 04:06:32",
		"totalOrganizations": 2,
		"totalTours": 11,
		"totalDays": 180,
		"totalCountries": 4,
		"totalStates": 30,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1410,
		"schedulePercent": 0.593431,
		"totalGuests": 957,
		"guestPercent": 0.815256,
		"mostGuestDate": "2023-06-04 00:00:00",
		"mostGuestName": "Central Park SummerStage",
		"mostGuestCity": "New York",
		"mostGuestAmount": 74
	},
	{
		"id": "baedf46",
		"email": "gwpro@me.com",
		"name": "George",
		"createdDate": "2022-01-29 16:31:54",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 216,
		"totalCountries": 12,
		"totalStates": 40,
		"totalEvents": 110,
		"eventPercent": 0.578731,
		"totalScheduleItems": 1856,
		"schedulePercent": 0.696332,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5f584af",
		"email": "markbrightman@me.com",
		"name": "Mark",
		"createdDate": "2013-10-10 21:53:22",
		"totalOrganizations": 14,
		"totalTours": 7,
		"totalDays": 182,
		"totalCountries": 19,
		"totalStates": 55,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 1183,
		"schedulePercent": 0.535558,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9431025",
		"email": "tamir@wrcmgmt.net",
		"name": "Tamir",
		"createdDate": "2016-05-29 05:23:55",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 816,
		"totalCountries": 18,
		"totalStates": 36,
		"totalEvents": 338,
		"eventPercent": 0.936432,
		"totalScheduleItems": 3775,
		"schedulePercent": 0.91074,
		"totalGuests": 7079,
		"guestPercent": 0.998543,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Wembley Stadium",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 625
	},
	{
		"id": "74da849",
		"email": "willstockstm@gmail.com",
		"name": "William",
		"createdDate": "2022-04-10 11:29:42",
		"totalOrganizations": 12,
		"totalTours": 13,
		"totalDays": 876,
		"totalCountries": 8,
		"totalStates": 14,
		"totalEvents": 132,
		"eventPercent": 0.653688,
		"totalScheduleItems": 1417,
		"schedulePercent": 0.595153,
		"totalGuests": 498,
		"guestPercent": 0.691034,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "The SSE Arena, Wembley",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 226
	},
	{
		"id": "e368ff8",
		"email": "jeffc113@me.com",
		"name": "Jeff",
		"createdDate": "2016-03-18 21:31:25",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 366,
		"totalCountries": 1,
		"totalStates": 25,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 997,
		"schedulePercent": 0.484042,
		"totalGuests": 29,
		"guestPercent": 0.387896,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "del Lago Resort & Casino",
		"mostGuestCity": "Waterloo",
		"mostGuestAmount": 10
	},
	{
		"id": "bc0f585",
		"email": "cappydee64@gmail.com",
		"name": "Daniel",
		"createdDate": "2011-07-07 16:55:07",
		"totalOrganizations": 10,
		"totalTours": 13,
		"totalDays": 833,
		"totalCountries": 13,
		"totalStates": 53,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 2218,
		"schedulePercent": 0.753145,
		"totalGuests": 626,
		"guestPercent": 0.733015,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 75
	},
	{
		"id": "8c7dd72",
		"email": "Petebrightuk@gmail.com",
		"name": "Pete",
		"createdDate": "2016-12-01 02:14:58",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 380,
		"totalCountries": 14,
		"totalStates": 35,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1161,
		"schedulePercent": 0.529466,
		"totalGuests": 46,
		"guestPercent": 0.415044,
		"mostGuestDate": "2023-10-04 00:00:00",
		"mostGuestName": "Powerstation",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 11
	},
	{
		"id": "e4941be",
		"email": "calle@grimaldievent.se",
		"name": "Gian-Carlo ",
		"createdDate": "2013-10-06 16:35:20",
		"totalOrganizations": 12,
		"totalTours": 4,
		"totalDays": 182,
		"totalCountries": 4,
		"totalStates": 12,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 994,
		"schedulePercent": 0.482585,
		"totalGuests": 698,
		"guestPercent": 0.752218,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Gullmarsvallen",
		"mostGuestCity": "Lysekil",
		"mostGuestAmount": 159
	},
	{
		"id": "03c93b3",
		"email": "timmyabercrombie@gmail.com",
		"name": "Timothy",
		"createdDate": "2011-09-22 20:20:50",
		"totalOrganizations": 10,
		"totalTours": 10,
		"totalDays": 586,
		"totalCountries": 13,
		"totalStates": 65,
		"totalEvents": 303,
		"eventPercent": 0.916832,
		"totalScheduleItems": 3883,
		"schedulePercent": 0.917494,
		"totalGuests": 1709,
		"guestPercent": 0.913654,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 86
	},
	{
		"id": "a9308ac",
		"email": "joseph@transparentproductions.com",
		"name": "Joseph",
		"createdDate": "2020-11-25 05:48:53",
		"totalOrganizations": 11,
		"totalTours": 13,
		"totalDays": 913,
		"totalCountries": 3,
		"totalStates": 34,
		"totalEvents": 203,
		"eventPercent": 0.811681,
		"totalScheduleItems": 3014,
		"schedulePercent": 0.852205,
		"totalGuests": 607,
		"guestPercent": 0.728248,
		"mostGuestDate": "2023-05-21 00:00:00",
		"mostGuestName": "Calvary Community Church",
		"mostGuestCity": "Westlake Village",
		"mostGuestAmount": 47
	},
	{
		"id": "8a91156",
		"email": "steve@musicandart.net",
		"name": "Steve",
		"createdDate": "2014-10-14 15:42:49",
		"totalOrganizations": 6,
		"totalTours": 40,
		"totalDays": 316,
		"totalCountries": 12,
		"totalStates": 44,
		"totalEvents": 155,
		"eventPercent": 0.716197,
		"totalScheduleItems": 1120,
		"schedulePercent": 0.516223,
		"totalGuests": 51,
		"guestPercent": 0.423785,
		"mostGuestDate": "2023-06-26 00:00:00",
		"mostGuestName": "Vancouver Playhouse",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 20
	},
	{
		"id": "3e91f9f",
		"email": "westonblaha@gmail.com",
		"name": "Weston",
		"createdDate": "2014-10-26 15:59:12",
		"totalOrganizations": 5,
		"totalTours": 13,
		"totalDays": 333,
		"totalCountries": 20,
		"totalStates": 63,
		"totalEvents": 171,
		"eventPercent": 0.75394,
		"totalScheduleItems": 2847,
		"schedulePercent": 0.837902,
		"totalGuests": 1221,
		"guestPercent": 0.86121,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 57
	},
	{
		"id": "181685c",
		"email": "taylor@bigloud.com",
		"name": "Taylor",
		"createdDate": "2023-01-17 23:03:44",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 329,
		"totalCountries": 2,
		"totalStates": 37,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 1499,
		"schedulePercent": 0.617931,
		"totalGuests": 165,
		"guestPercent": 0.526818,
		"mostGuestDate": "2023-09-25 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 58
	},
	{
		"id": "c094c73",
		"email": "lg@helterskelter.dk",
		"name": "Line ",
		"createdDate": "2023-06-15 09:11:59",
		"totalOrganizations": 16,
		"totalTours": 16,
		"totalDays": 225,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 216,
		"eventPercent": 0.831943,
		"totalScheduleItems": 1163,
		"schedulePercent": 0.529996,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "sÃ¸nderborg",
		"mostGuestCity": "SÃ¸nderborg",
		"mostGuestAmount": 4
	},
	{
		"id": "8de9027",
		"email": "brentwashburnav@gmail.com",
		"name": "Brent",
		"createdDate": "2018-09-10 15:14:06",
		"totalOrganizations": 16,
		"totalTours": 17,
		"totalDays": 1026,
		"totalCountries": 18,
		"totalStates": 58,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 2841,
		"schedulePercent": 0.837373,
		"totalGuests": 788,
		"guestPercent": 0.774202,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 130
	},
	{
		"id": "51e627b",
		"email": "chauncey@audiowurx.com",
		"name": "Chauncey",
		"createdDate": "2016-11-27 04:22:09",
		"totalOrganizations": 15,
		"totalTours": 15,
		"totalDays": 1396,
		"totalCountries": 11,
		"totalStates": 52,
		"totalEvents": 237,
		"eventPercent": 0.858429,
		"totalScheduleItems": 4696,
		"schedulePercent": 0.95153,
		"totalGuests": 1378,
		"guestPercent": 0.88081,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 207
	},
	{
		"id": "92cda78",
		"email": "lott@me.com",
		"name": "Lott",
		"createdDate": "2012-06-01 19:51:54",
		"totalOrganizations": 7,
		"totalTours": 4,
		"totalDays": 301,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 907,
		"schedulePercent": 0.45398,
		"totalGuests": 10,
		"guestPercent": 0.343531,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "Turning Point Community Church",
		"mostGuestCity": "Macon",
		"mostGuestAmount": 6
	},
	{
		"id": "97291f8",
		"email": "sbmgnt@gmail.com",
		"name": "Richard",
		"createdDate": "2018-09-27 21:06:16",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 213,
		"totalCountries": 9,
		"totalStates": 25,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 1209,
		"schedulePercent": 0.542445,
		"totalGuests": 283,
		"guestPercent": 0.595153,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "Brilla Torrevieja",
		"mostGuestCity": "Torrevieja",
		"mostGuestAmount": 37
	},
	{
		"id": "918f4e2",
		"email": "ejwildyouth@gmail.com",
		"name": "evan",
		"createdDate": "2023-03-15 11:51:18",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 639,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 362,
		"schedulePercent": 0.238776,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2965da0",
		"email": "rachaelrogers101@gmail.com",
		"name": "Rachael",
		"createdDate": "2023-10-09 02:22:38",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 27,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 73,
		"schedulePercent": 0.0701894,
		"totalGuests": 110,
		"guestPercent": 0.482453,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Le Capitole",
		"mostGuestCity": "Ville de QuÃ©bec",
		"mostGuestAmount": 27
	},
	{
		"id": "a356396",
		"email": "justonefrye@gmail.com",
		"name": "Mike",
		"createdDate": "2016-05-26 16:27:19",
		"totalOrganizations": 16,
		"totalTours": 27,
		"totalDays": 701,
		"totalCountries": 15,
		"totalStates": 56,
		"totalEvents": 196,
		"eventPercent": 0.798835,
		"totalScheduleItems": 2167,
		"schedulePercent": 0.745332,
		"totalGuests": 2076,
		"guestPercent": 0.938419,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 121
	},
	{
		"id": "88ee7d2",
		"email": "aimee@hrd8wrk.com",
		"name": "Aimee",
		"createdDate": "2021-09-11 02:01:07",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 525,
		"totalCountries": 4,
		"totalStates": 47,
		"totalEvents": 168,
		"eventPercent": 0.747451,
		"totalScheduleItems": 2725,
		"schedulePercent": 0.82254,
		"totalGuests": 787,
		"guestPercent": 0.773805,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "del Lago Resort & Casino",
		"mostGuestCity": "Waterloo",
		"mostGuestAmount": 39
	},
	{
		"id": "835e69e",
		"email": "rhacken@rocnation.com",
		"name": "Rachel",
		"createdDate": "2022-07-28 21:02:55",
		"totalOrganizations": 12,
		"totalTours": 33,
		"totalDays": 1258,
		"totalCountries": 21,
		"totalStates": 93,
		"totalEvents": 533,
		"eventPercent": 0.977486,
		"totalScheduleItems": 7363,
		"schedulePercent": 0.990862,
		"totalGuests": 3346,
		"guestPercent": 0.974838,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Saint Andrewâ€™s Hall",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 80
	},
	{
		"id": "d243125",
		"email": "tyler@zacbrown.com",
		"name": "Tyler",
		"createdDate": "2017-11-09 19:22:55",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 135,
		"totalCountries": 6,
		"totalStates": 30,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 1780,
		"schedulePercent": 0.680969,
		"totalGuests": 4325,
		"guestPercent": 0.985962,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Fenway Park",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 322
	},
	{
		"id": "eac949c",
		"email": "houseinthewoods@bigpond.com",
		"name": "Rob ",
		"createdDate": "2013-02-25 12:19:42",
		"totalOrganizations": 13,
		"totalTours": 30,
		"totalDays": 974,
		"totalCountries": 15,
		"totalStates": 44,
		"totalEvents": 194,
		"eventPercent": 0.794597,
		"totalScheduleItems": 2939,
		"schedulePercent": 0.846246,
		"totalGuests": 808,
		"guestPercent": 0.77791,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "The Enmore Theatre",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 111
	},
	{
		"id": "97abbbf",
		"email": "tammy@skaggsfamilyrecords.com",
		"name": "Tammy",
		"createdDate": "2017-02-17 18:04:38",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 124,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 995,
		"schedulePercent": 0.483115,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "26fd1cf",
		"email": "oliver@unitedstage.dk",
		"name": "Oliver",
		"createdDate": "2023-06-09 08:36:06",
		"totalOrganizations": 59,
		"totalTours": 50,
		"totalDays": 884,
		"totalCountries": 3,
		"totalStates": 9,
		"totalEvents": 808,
		"eventPercent": 0.988081,
		"totalScheduleItems": 3679,
		"schedulePercent": 0.90637,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c08ba8d",
		"email": "maciek@act.as",
		"name": "Maciek",
		"createdDate": "2023-05-05 11:07:42",
		"totalOrganizations": 14,
		"totalTours": 13,
		"totalDays": 283,
		"totalCountries": 4,
		"totalStates": 15,
		"totalEvents": 194,
		"eventPercent": 0.794597,
		"totalScheduleItems": 1443,
		"schedulePercent": 0.602039,
		"totalGuests": 165,
		"guestPercent": 0.526818,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Sentrum Scene",
		"mostGuestCity": "Oslo",
		"mostGuestAmount": 69
	},
	{
		"id": "550aca6",
		"email": "kenthomas73@gmail.com",
		"name": "Ken",
		"createdDate": "2013-10-12 20:15:50",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 157,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1572,
		"schedulePercent": 0.635015,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4baaa88",
		"email": "Bobert@electroganic.org",
		"name": "Robert",
		"createdDate": "2018-04-24 15:15:55",
		"totalOrganizations": 9,
		"totalTours": 8,
		"totalDays": 336,
		"totalCountries": 5,
		"totalStates": 54,
		"totalEvents": 224,
		"eventPercent": 0.841345,
		"totalScheduleItems": 2593,
		"schedulePercent": 0.806648,
		"totalGuests": 1206,
		"guestPercent": 0.858032,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Palladium Times Square",
		"mostGuestCity": "New York",
		"mostGuestAmount": 97
	},
	{
		"id": "4a88968",
		"email": "cassie@tkoartistmanagement.com",
		"name": "Cassie",
		"createdDate": "2014-09-30 15:21:29",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 696,
		"totalCountries": 1,
		"totalStates": 41,
		"totalEvents": 170,
		"eventPercent": 0.752086,
		"totalScheduleItems": 2138,
		"schedulePercent": 0.740034,
		"totalGuests": 366,
		"guestPercent": 0.639386,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Billy Bob's",
		"mostGuestCity": "Ft. Worth",
		"mostGuestAmount": 48
	},
	{
		"id": "43a4e07",
		"email": "cat@purpletourmanagement.com",
		"name": "Cat",
		"createdDate": "2016-11-09 10:16:34",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 59,
		"totalCountries": 6,
		"totalStates": 8,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 488,
		"schedulePercent": 0.292014,
		"totalGuests": 328,
		"guestPercent": 0.617667,
		"mostGuestDate": "2023-10-31 00:00:00",
		"mostGuestName": "Mitsubishi Electric Halle - DÃ¼sseldorf Congress Sport & Event GmbH",
		"mostGuestCity": "DÃ¼sseldorf",
		"mostGuestAmount": 84
	},
	{
		"id": "a20bf10",
		"email": "Sarin@wemarchatdawn.com",
		"name": "Sarin",
		"createdDate": "2020-02-08 03:13:00",
		"totalOrganizations": 21,
		"totalTours": 15,
		"totalDays": 644,
		"totalCountries": 19,
		"totalStates": 66,
		"totalEvents": 339,
		"eventPercent": 0.937094,
		"totalScheduleItems": 5985,
		"schedulePercent": 0.980268,
		"totalGuests": 91,
		"guestPercent": 0.465236,
		"mostGuestDate": "2023-05-04 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 24
	},
	{
		"id": "f777adf",
		"email": "jayvan90@gmail.com",
		"name": "Jay",
		"createdDate": "2023-07-07 19:19:58",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 88,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 432,
		"schedulePercent": 0.269103,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "John Cain Arena",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 5
	},
	{
		"id": "5b9f5d6",
		"email": "t_2tech@yahoo.co.uk",
		"name": "Chris (T) ",
		"createdDate": "2013-03-06 20:59:05",
		"totalOrganizations": 13,
		"totalTours": 5,
		"totalDays": 107,
		"totalCountries": 7,
		"totalStates": 19,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 551,
		"schedulePercent": 0.322077,
		"totalGuests": 300,
		"guestPercent": 0.602569,
		"mostGuestDate": "2023-05-04 00:00:00",
		"mostGuestName": "Electric Ballroom",
		"mostGuestCity": "London",
		"mostGuestAmount": 43
	},
	{
		"id": "b2641c8",
		"email": "alan@hbprnm.com",
		"name": "Alan",
		"createdDate": "2023-04-18 16:11:56",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 401,
		"totalCountries": 1,
		"totalStates": 30,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 406,
		"schedulePercent": 0.259436,
		"totalGuests": 180,
		"guestPercent": 0.536485,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "Brooklyn Bowl Las Vegas",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 16
	},
	{
		"id": "a6456a6",
		"email": "twadebooking@gmail.com",
		"name": "Travis",
		"createdDate": "2015-11-05 19:08:43",
		"totalOrganizations": 25,
		"totalTours": 16,
		"totalDays": 542,
		"totalCountries": 14,
		"totalStates": 70,
		"totalEvents": 238,
		"eventPercent": 0.860019,
		"totalScheduleItems": 3406,
		"schedulePercent": 0.887962,
		"totalGuests": 952,
		"guestPercent": 0.81327,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Brooklyn Bowl Las Vegas",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 67
	},
	{
		"id": "647958d",
		"email": "jp.durant@redlightmanagement.com",
		"name": "JP",
		"createdDate": "2013-11-24 19:34:08",
		"totalOrganizations": 10,
		"totalTours": 6,
		"totalDays": 1351,
		"totalCountries": 4,
		"totalStates": 52,
		"totalEvents": 390,
		"eventPercent": 0.954576,
		"totalScheduleItems": 4659,
		"schedulePercent": 0.950205,
		"totalGuests": 2020,
		"guestPercent": 0.934976,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 89
	},
	{
		"id": "7f21422",
		"email": "vuori.janne@gmail.com",
		"name": "Janne",
		"createdDate": "2018-06-05 18:09:23",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 144,
		"totalCountries": 6,
		"totalStates": 7,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 415,
		"schedulePercent": 0.262747,
		"totalGuests": 13,
		"guestPercent": 0.352536,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "Ravintola Leikari",
		"mostGuestCity": "Kotka",
		"mostGuestAmount": 6
	},
	{
		"id": "487c65f",
		"email": "charles@sostouring.com",
		"name": "Charles",
		"createdDate": "2017-07-13 21:19:28",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 696,
		"totalCountries": 23,
		"totalStates": 64,
		"totalEvents": 232,
		"eventPercent": 0.852073,
		"totalScheduleItems": 1963,
		"schedulePercent": 0.71368,
		"totalGuests": 124,
		"guestPercent": 0.493445,
		"mostGuestDate": "2023-05-04 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 24
	},
	{
		"id": "7160454",
		"email": "karenhappy525@icloud.com",
		"name": "Karen",
		"createdDate": "2023-11-26 05:45:41",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 66,
		"totalCountries": 1,
		"totalStates": 29,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 885,
		"schedulePercent": 0.44802,
		"totalGuests": 13,
		"guestPercent": 0.352536,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "The Music Center @ Strathmore",
		"mostGuestCity": "North Bethesda",
		"mostGuestAmount": 9
	},
	{
		"id": "1a35953",
		"email": "office@elloramanagement.com",
		"name": "Andrew",
		"createdDate": "2022-07-27 05:17:45",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 53,
		"totalCountries": 3,
		"totalStates": 15,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 341,
		"schedulePercent": 0.228314,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e83fbdd",
		"email": "kelseyLmaynard@gmail.com",
		"name": "Kelsey",
		"createdDate": "2017-02-25 17:13:29",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 501,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 148,
		"eventPercent": 0.696332,
		"totalScheduleItems": 2794,
		"schedulePercent": 0.831678,
		"totalGuests": 1774,
		"guestPercent": 0.918554,
		"mostGuestDate": "2023-05-28 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 121
	},
	{
		"id": "b9987ec",
		"email": "gaylealquizar@gmail.com",
		"name": "Gayle",
		"createdDate": "2022-03-30 21:52:39",
		"totalOrganizations": 11,
		"totalTours": 16,
		"totalDays": 409,
		"totalCountries": 11,
		"totalStates": 69,
		"totalEvents": 184,
		"eventPercent": 0.77791,
		"totalScheduleItems": 3008,
		"schedulePercent": 0.851543,
		"totalGuests": 301,
		"guestPercent": 0.603099,
		"mostGuestDate": "2023-06-22 00:00:00",
		"mostGuestName": "Regent Theater DTLA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 37
	},
	{
		"id": "4e3bcc1",
		"email": "AlwaysAaronA@gmail.com",
		"name": "Aaron",
		"createdDate": "2017-10-02 13:57:04",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 241,
		"totalCountries": 5,
		"totalStates": 39,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 2524,
		"schedulePercent": 0.795789,
		"totalGuests": 1075,
		"guestPercent": 0.835254,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "The Orion Amphitheater",
		"mostGuestCity": "Huntsville",
		"mostGuestAmount": 80
	},
	{
		"id": "18ff793",
		"email": "tore@nbs.audio",
		"name": "Tore",
		"createdDate": "2017-09-03 01:09:00",
		"totalOrganizations": 6,
		"totalTours": 9,
		"totalDays": 207,
		"totalCountries": 23,
		"totalStates": 59,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 2017,
		"schedulePercent": 0.722686,
		"totalGuests": 1065,
		"guestPercent": 0.834327,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 54
	},
	{
		"id": "a0f5ec7",
		"email": "kay@silent-giant.com",
		"name": "Kay",
		"createdDate": "2016-01-22 01:07:13",
		"totalOrganizations": 19,
		"totalTours": 16,
		"totalDays": 298,
		"totalCountries": 11,
		"totalStates": 41,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 1529,
		"schedulePercent": 0.624685,
		"totalGuests": 640,
		"guestPercent": 0.735929,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 50
	},
	{
		"id": "3d30f51",
		"email": "baileyzimmermanmusic@yahoo.com",
		"name": "Bailey",
		"createdDate": "2022-10-05 22:26:45",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 242,
		"totalCountries": 4,
		"totalStates": 31,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1791,
		"schedulePercent": 0.683088,
		"totalGuests": 526,
		"guestPercent": 0.700702,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "South Fork Dirt Riders",
		"mostGuestCity": "Taylorville",
		"mostGuestAmount": 29
	},
	{
		"id": "bd3b0a8",
		"email": "Chris@TourPros.net",
		"name": "Chris",
		"createdDate": "2010-05-13 15:44:48",
		"totalOrganizations": 11,
		"totalTours": 6,
		"totalDays": 47,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 512,
		"schedulePercent": 0.305125,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-02-20 00:00:00",
		"mostGuestName": "Hotel Ziggy",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 4
	},
	{
		"id": "eaaaa8b",
		"email": "evan.driskill@gmail.com",
		"name": "Evan",
		"createdDate": "2015-07-06 08:12:37",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 1240,
		"totalCountries": 13,
		"totalStates": 46,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 1138,
		"schedulePercent": 0.522845,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-05-21 00:00:00",
		"mostGuestName": "Olympic Stadium",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 5
	},
	{
		"id": "34ef8cb",
		"email": "treed32@me.com",
		"name": "T.",
		"createdDate": "2022-03-01 20:47:35",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 199,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 511,
		"schedulePercent": 0.304595,
		"totalGuests": 180,
		"guestPercent": 0.536485,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Chateau Ste. Michelle Winery",
		"mostGuestCity": "Woodinville",
		"mostGuestAmount": 26
	},
	{
		"id": "0b15306",
		"email": "more@moretocome.com.au",
		"name": "Hugh",
		"createdDate": "2022-10-12 00:11:29",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 886,
		"totalCountries": 4,
		"totalStates": 11,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 406,
		"schedulePercent": 0.259436,
		"totalGuests": 36,
		"guestPercent": 0.397961,
		"mostGuestDate": "2023-07-26 00:00:00",
		"mostGuestName": "The Station",
		"mostGuestCity": "Jindabyne",
		"mostGuestAmount": 22
	},
	{
		"id": "4b054aa",
		"email": "justine@awpservices.co",
		"name": "Justine",
		"createdDate": "2022-07-14 20:08:31",
		"totalOrganizations": 3,
		"totalTours": 10,
		"totalDays": 402,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 2411,
		"schedulePercent": 0.781618,
		"totalGuests": 190,
		"guestPercent": 0.542445,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "Orpheum Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 34
	},
	{
		"id": "f412ff2",
		"email": "savannah.tag13@gmail.com",
		"name": "Savannah",
		"createdDate": "2021-11-18 17:46:01",
		"totalOrganizations": 15,
		"totalTours": 27,
		"totalDays": 1564,
		"totalCountries": 5,
		"totalStates": 53,
		"totalEvents": 308,
		"eventPercent": 0.919746,
		"totalScheduleItems": 3316,
		"schedulePercent": 0.879354,
		"totalGuests": 343,
		"guestPercent": 0.624421,
		"mostGuestDate": "2023-08-14 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 61
	},
	{
		"id": "c0dacad",
		"email": "touringboz@icloud.com",
		"name": "Will",
		"createdDate": "2023-02-13 12:22:31",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 70,
		"totalCountries": 5,
		"totalStates": 11,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 539,
		"schedulePercent": 0.317309,
		"totalGuests": 318,
		"guestPercent": 0.613031,
		"mostGuestDate": "2023-03-28 00:00:00",
		"mostGuestName": "Electric Brixton",
		"mostGuestCity": "London",
		"mostGuestAmount": 79
	},
	{
		"id": "6fe8270",
		"email": "maxwellheck21@gmail.com",
		"name": "Maxwell",
		"createdDate": "2021-08-10 13:52:23",
		"totalOrganizations": 4,
		"totalTours": 10,
		"totalDays": 471,
		"totalCountries": 17,
		"totalStates": 51,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 2107,
		"schedulePercent": 0.736326,
		"totalGuests": 1212,
		"guestPercent": 0.859224,
		"mostGuestDate": "2023-11-15 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 142
	},
	{
		"id": "3b55fe5",
		"email": "jasonhecht78@gmail.com",
		"name": "Jason",
		"createdDate": "2011-08-02 20:50:56",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 494,
		"totalCountries": 4,
		"totalStates": 39,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 2549,
		"schedulePercent": 0.799099,
		"totalGuests": 2645,
		"guestPercent": 0.957224,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "Nissan Stadium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 118
	},
	{
		"id": "1006d07",
		"email": "PaulMayTM@gmail.com",
		"name": "Paul",
		"createdDate": "2014-07-21 20:47:07",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 780,
		"totalCountries": 4,
		"totalStates": 31,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 1419,
		"schedulePercent": 0.595948,
		"totalGuests": 240,
		"guestPercent": 0.573434,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "Westgate Las Vegas Resort & Casino",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 32
	},
	{
		"id": "2bdaf40",
		"email": "davidlandreth@gmail.com",
		"name": "David",
		"createdDate": "2014-01-03 01:02:29",
		"totalOrganizations": 6,
		"totalTours": 31,
		"totalDays": 546,
		"totalCountries": 11,
		"totalStates": 54,
		"totalEvents": 281,
		"eventPercent": 0.903059,
		"totalScheduleItems": 2163,
		"schedulePercent": 0.74467,
		"totalGuests": 856,
		"guestPercent": 0.788902,
		"mostGuestDate": "2023-02-24 00:00:00",
		"mostGuestName": "The Great Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 50
	},
	{
		"id": "d55ea5a",
		"email": "brian@sosshouse.com",
		"name": "Brian",
		"createdDate": "2019-10-24 04:24:11",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 160,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 690,
		"schedulePercent": 0.376771,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "73ff749",
		"email": "clara.millan@menosquecero.es",
		"name": "Millan",
		"createdDate": "2011-09-09 12:53:03",
		"totalOrganizations": 12,
		"totalTours": 3,
		"totalDays": 246,
		"totalCountries": 6,
		"totalStates": 16,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 1759,
		"schedulePercent": 0.676202,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "644bd32",
		"email": "ellenbenjamin3@gmail.com",
		"name": "Ellen",
		"createdDate": "2021-08-03 20:56:25",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 158,
		"totalCountries": 10,
		"totalStates": 48,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1147,
		"schedulePercent": 0.526288,
		"totalGuests": 822,
		"guestPercent": 0.781618,
		"mostGuestDate": "2023-06-27 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 43
	},
	{
		"id": "300244f",
		"email": "keargow@gmail.com",
		"name": "Karina",
		"createdDate": "2018-06-14 16:24:16",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 514,
		"totalCountries": 12,
		"totalStates": 43,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 3156,
		"schedulePercent": 0.866508,
		"totalGuests": 1494,
		"guestPercent": 0.893656,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 98
	},
	{
		"id": "231480c",
		"email": "DIVINE@WUMUSICGROUP.COM",
		"name": "Mitchell",
		"createdDate": "2015-05-07 22:21:18",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 417,
		"totalCountries": 11,
		"totalStates": 30,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 509,
		"schedulePercent": 0.303536,
		"totalGuests": 1684,
		"guestPercent": 0.911138,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Grand Garden Arena",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 161
	},
	{
		"id": "9726305",
		"email": "lbargatze@gmail.com",
		"name": "Laura",
		"createdDate": "2019-10-17 20:25:33",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 287,
		"totalCountries": 6,
		"totalStates": 43,
		"totalEvents": 181,
		"eventPercent": 0.771818,
		"totalScheduleItems": 1250,
		"schedulePercent": 0.552377,
		"totalGuests": 1444,
		"guestPercent": 0.88624,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 44
	},
	{
		"id": "62925a3",
		"email": "rach@weareunified.com",
		"name": "Rachael",
		"createdDate": "2014-02-13 00:06:59",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 143,
		"totalCountries": 7,
		"totalStates": 30,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 1195,
		"schedulePercent": 0.538737,
		"totalGuests": 751,
		"guestPercent": 0.764667,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 75
	},
	{
		"id": "91d0d01",
		"email": "chase@brickent.com",
		"name": "Chase",
		"createdDate": "2017-10-03 04:38:24",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 251,
		"totalCountries": 1,
		"totalStates": 32,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 1686,
		"schedulePercent": 0.661237,
		"totalGuests": 440,
		"guestPercent": 0.668653,
		"mostGuestDate": "2023-04-22 00:00:00",
		"mostGuestName": "Great Southern Bank Arena",
		"mostGuestCity": "Springfield",
		"mostGuestAmount": 68
	},
	{
		"id": "1ec6991",
		"email": "che@chearthur.com",
		"name": "Che",
		"createdDate": "2011-03-08 05:00:28",
		"totalOrganizations": 10,
		"totalTours": 12,
		"totalDays": 312,
		"totalCountries": 4,
		"totalStates": 50,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 1689,
		"schedulePercent": 0.662561,
		"totalGuests": 1937,
		"guestPercent": 0.93087,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "The Tulsa Theater",
		"mostGuestCity": "Tulsa",
		"mostGuestAmount": 147
	},
	{
		"id": "fe061ab",
		"email": "deafclubtour@gmail.com",
		"name": "Lou",
		"createdDate": "2022-11-08 02:18:43",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 64,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 283,
		"schedulePercent": 0.199841,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9429647",
		"email": "laurie@wartiste.com",
		"name": "Association",
		"createdDate": "2019-09-23 09:21:52",
		"totalOrganizations": 10,
		"totalTours": 6,
		"totalDays": 578,
		"totalCountries": 7,
		"totalStates": 25,
		"totalEvents": 167,
		"eventPercent": 0.745332,
		"totalScheduleItems": 2080,
		"schedulePercent": 0.731691,
		"totalGuests": 759,
		"guestPercent": 0.766653,
		"mostGuestDate": "2023-04-02 00:00:00",
		"mostGuestName": "La Seine Musicale",
		"mostGuestCity": "Boulogne-Billancourt",
		"mostGuestAmount": 31
	},
	{
		"id": "4109aeb",
		"email": "lillistoeberl@gmail.com",
		"name": "Lilli",
		"createdDate": "2022-10-30 07:11:17",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 51,
		"totalCountries": 10,
		"totalStates": 15,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 551,
		"schedulePercent": 0.322077,
		"totalGuests": 79,
		"guestPercent": 0.454907,
		"mostGuestDate": "2023-11-02 00:00:00",
		"mostGuestName": "Batschkapp",
		"mostGuestCity": "Frankfurt am Main",
		"mostGuestAmount": 16
	},
	{
		"id": "c82262b",
		"email": "johnstone.ben@gmail.com",
		"name": "Ben",
		"createdDate": "2015-07-23 11:24:38",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 198,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 1639,
		"schedulePercent": 0.651172,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f0f1634",
		"email": "mariatorres.biz@gmail.com",
		"name": "Maria",
		"createdDate": "2017-11-20 14:29:35",
		"totalOrganizations": 6,
		"totalTours": 59,
		"totalDays": 277,
		"totalCountries": 14,
		"totalStates": 34,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 1508,
		"schedulePercent": 0.62005,
		"totalGuests": 591,
		"guestPercent": 0.722818,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Finsbury Park",
		"mostGuestCity": "London",
		"mostGuestAmount": 60
	},
	{
		"id": "0839b9c",
		"email": "rodolphe.padel@hotmail.fr",
		"name": "Rodolphe",
		"createdDate": "2023-01-13 22:31:45",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 198,
		"totalCountries": 5,
		"totalStates": 26,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 1179,
		"schedulePercent": 0.534631,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a6a3a74",
		"email": "jkasten09@gmail.com",
		"name": "Jason",
		"createdDate": "2016-10-18 07:09:47",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 106,
		"totalCountries": 3,
		"totalStates": 22,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 529,
		"schedulePercent": 0.312409,
		"totalGuests": 143,
		"guestPercent": 0.508277,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Rose Bowl Stadium",
		"mostGuestCity": "Pasadena",
		"mostGuestAmount": 40
	},
	{
		"id": "5d2d081",
		"email": "49winchester@gmail.com",
		"name": "Chase",
		"createdDate": "2022-02-28 19:00:44",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 646,
		"totalCountries": 9,
		"totalStates": 47,
		"totalEvents": 178,
		"eventPercent": 0.767051,
		"totalScheduleItems": 1566,
		"schedulePercent": 0.634088,
		"totalGuests": 921,
		"guestPercent": 0.804397,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 96
	},
	{
		"id": "70258f9",
		"email": "billyhempel@gmail.com",
		"name": "Billy",
		"createdDate": "2012-08-14 13:27:45",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 260,
		"totalCountries": 12,
		"totalStates": 30,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 1327,
		"schedulePercent": 0.573831,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4c3aab8",
		"email": "jesse_voss@hotmail.com",
		"name": "Jesse ",
		"createdDate": "2023-06-29 11:25:56",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 76,
		"totalCountries": 4,
		"totalStates": 5,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 364,
		"schedulePercent": 0.240498,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "65621d3",
		"email": "andrestabares7@gmail.com",
		"name": "Andres",
		"createdDate": "2016-08-07 21:53:58",
		"totalOrganizations": 11,
		"totalTours": 13,
		"totalDays": 967,
		"totalCountries": 11,
		"totalStates": 58,
		"totalEvents": 230,
		"eventPercent": 0.849159,
		"totalScheduleItems": 4043,
		"schedulePercent": 0.925043,
		"totalGuests": 3104,
		"guestPercent": 0.97113,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Raymond James Stadium",
		"mostGuestCity": "Tampa ",
		"mostGuestAmount": 248
	},
	{
		"id": "3e43083",
		"email": "mathias.mantzke@blacksheep-management.de",
		"name": "Mathias",
		"createdDate": "2023-03-24 15:23:24",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 72,
		"totalCountries": 8,
		"totalStates": 14,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 606,
		"schedulePercent": 0.343796,
		"totalGuests": 768,
		"guestPercent": 0.769037,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "Arena Leipzig",
		"mostGuestCity": "Leipzig",
		"mostGuestAmount": 143
	},
	{
		"id": "8a40650",
		"email": "darren@castingcrowns.com",
		"name": "Darren",
		"createdDate": "2013-11-12 14:44:48",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 214,
		"totalCountries": 3,
		"totalStates": 41,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 3090,
		"schedulePercent": 0.860813,
		"totalGuests": 1119,
		"guestPercent": 0.842802,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "Macon Centreplex - City Auditorium",
		"mostGuestCity": "Macon",
		"mostGuestAmount": 60
	},
	{
		"id": "df7945c",
		"email": "adam@freesolo.us",
		"name": "Adam",
		"createdDate": "2016-06-03 06:32:20",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 492,
		"totalCountries": 10,
		"totalStates": 49,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 1855,
		"schedulePercent": 0.695934,
		"totalGuests": 493,
		"guestPercent": 0.689313,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 66
	},
	{
		"id": "f05aee7",
		"email": "amelia@refugemediagroup.com",
		"name": "Amelia",
		"createdDate": "2023-08-08 16:02:51",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 30,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 378,
		"schedulePercent": 0.247252,
		"totalGuests": 114,
		"guestPercent": 0.486028,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "Music Hall Center for the Performing Arts",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 63
	},
	{
		"id": "9f3faa8",
		"email": "RyanG@Show-Logistics.com",
		"name": "Charles",
		"createdDate": "2013-02-19 04:25:33",
		"totalOrganizations": 30,
		"totalTours": 40,
		"totalDays": 603,
		"totalCountries": 24,
		"totalStates": 107,
		"totalEvents": 310,
		"eventPercent": 0.920938,
		"totalScheduleItems": 4212,
		"schedulePercent": 0.934181,
		"totalGuests": 1107,
		"guestPercent": 0.840683,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "Backstage Kulturzentrum",
		"mostGuestCity": "MÃ¼nchen",
		"mostGuestAmount": 64
	},
	{
		"id": "9289481",
		"email": "renee.ugar@gmail.com",
		"name": "Renee",
		"createdDate": "2023-03-21 06:51:33",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 136,
		"totalCountries": 7,
		"totalStates": 18,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 836,
		"schedulePercent": 0.433188,
		"totalGuests": 31,
		"guestPercent": 0.390942,
		"mostGuestDate": "2023-09-26 00:00:00",
		"mostGuestName": "Colours Hoxton",
		"mostGuestCity": "London",
		"mostGuestAmount": 27
	},
	{
		"id": "ebd33b1",
		"email": "charboneau.lauren@gmail.com",
		"name": "Lauren",
		"createdDate": "2018-04-10 01:09:07",
		"totalOrganizations": 7,
		"totalTours": 2,
		"totalDays": 195,
		"totalCountries": 3,
		"totalStates": 39,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 1744,
		"schedulePercent": 0.673553,
		"totalGuests": 2213,
		"guestPercent": 0.944511,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Dickies Arena",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 433
	},
	{
		"id": "269aed2",
		"email": "reotourmgr@icloud.com",
		"name": "Ken",
		"createdDate": "2016-01-23 20:41:24",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 231,
		"totalCountries": 3,
		"totalStates": 34,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 1178,
		"schedulePercent": 0.534234,
		"totalGuests": 62,
		"guestPercent": 0.437028,
		"mostGuestDate": "2023-10-10 00:00:00",
		"mostGuestName": "Hoyt Sherman Place",
		"mostGuestCity": "Des Moines",
		"mostGuestAmount": 24
	},
	{
		"id": "c6b354d",
		"email": "sethglier@yahoo.com",
		"name": "Seth ",
		"createdDate": "2022-06-08 21:09:23",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 122,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 136,
		"schedulePercent": 0.110581,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "05e19de",
		"email": "rian@skwareeyes.com",
		"name": "Rian",
		"createdDate": "2020-08-22 05:59:57",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 374,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 1147,
		"schedulePercent": 0.526288,
		"totalGuests": 502,
		"guestPercent": 0.692623,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "London Music Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 34
	},
	{
		"id": "6b05336",
		"email": "belmontofficial@gmail.com",
		"name": "Taz",
		"createdDate": "2022-11-29 03:58:54",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 62,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 456,
		"schedulePercent": 0.279168,
		"totalGuests": 50,
		"guestPercent": 0.421798,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "Park West",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 25
	},
	{
		"id": "705cd0a",
		"email": "Hanahaolegirl79@gmail.com",
		"name": "Patricia",
		"createdDate": "2015-05-19 02:23:12",
		"totalOrganizations": 1,
		"totalTours": 9,
		"totalDays": 141,
		"totalCountries": 4,
		"totalStates": 29,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 1012,
		"schedulePercent": 0.488412,
		"totalGuests": 945,
		"guestPercent": 0.810886,
		"mostGuestDate": "2023-04-13 00:00:00",
		"mostGuestName": "The Paramount",
		"mostGuestCity": "Huntington",
		"mostGuestAmount": 60
	},
	{
		"id": "bf6f41f",
		"email": "peeweesound@hotmail.com",
		"name": "Luis ",
		"createdDate": "2021-12-29 13:45:21",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 364,
		"totalCountries": 7,
		"totalStates": 23,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 1783,
		"schedulePercent": 0.681764,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a9f79c4",
		"email": "austindaugherty90@gmail.com",
		"name": "Austin",
		"createdDate": "2019-07-01 22:45:17",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 141,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 724,
		"schedulePercent": 0.390147,
		"totalGuests": 255,
		"guestPercent": 0.58191,
		"mostGuestDate": "2023-03-10 00:00:00",
		"mostGuestName": "The Ritz Ybor",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 26
	},
	{
		"id": "d162c0b",
		"email": "Yunahonentertainment@gmail.com",
		"name": "Jade",
		"createdDate": "2022-09-20 21:10:41",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 35,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 227,
		"schedulePercent": 0.168057,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "62624b8",
		"email": "burninghouserecords@gmail.com",
		"name": "Casey",
		"createdDate": "2014-07-08 22:11:26",
		"totalOrganizations": 2,
		"totalTours": 10,
		"totalDays": 59,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 263,
		"schedulePercent": 0.187922,
		"totalGuests": 69,
		"guestPercent": 0.445107,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "El Rey Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 29
	},
	{
		"id": "f917f64",
		"email": "bill@gr6y.com",
		"name": "Bill",
		"createdDate": "2018-12-05 17:59:23",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 209,
		"totalCountries": 9,
		"totalStates": 45,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 1707,
		"schedulePercent": 0.666269,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e37e9db",
		"email": "tomstorms@sheltermusic.com",
		"name": "Tom",
		"createdDate": "2021-02-22 21:09:36",
		"totalOrganizations": 19,
		"totalTours": 22,
		"totalDays": 1350,
		"totalCountries": 13,
		"totalStates": 68,
		"totalEvents": 485,
		"eventPercent": 0.972189,
		"totalScheduleItems": 6147,
		"schedulePercent": 0.981459,
		"totalGuests": 847,
		"guestPercent": 0.787313,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Pine Knob Music Theatre",
		"mostGuestCity": "City of the Village of Clarkston",
		"mostGuestAmount": 62
	},
	{
		"id": "de36f19",
		"email": "mattburgessee@gmail.com",
		"name": "Matthew",
		"createdDate": "2023-02-06 13:26:26",
		"totalOrganizations": 2,
		"totalTours": 9,
		"totalDays": 705,
		"totalCountries": 23,
		"totalStates": 70,
		"totalEvents": 187,
		"eventPercent": 0.784135,
		"totalScheduleItems": 1888,
		"schedulePercent": 0.702953,
		"totalGuests": 387,
		"guestPercent": 0.647596,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "The Majestic Ventura Theater",
		"mostGuestCity": "Ventura",
		"mostGuestAmount": 43
	},
	{
		"id": "aca301d",
		"email": "francesgolsen@gmail.com",
		"name": "Frances",
		"createdDate": "2023-10-12 01:36:38",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 83,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 403,
		"schedulePercent": 0.258376,
		"totalGuests": 119,
		"guestPercent": 0.489604,
		"mostGuestDate": "2023-11-21 00:00:00",
		"mostGuestName": "Western Springs Stadium and Park",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 115
	},
	{
		"id": "c5fa2f3",
		"email": "kitblanchard@comcast.net",
		"name": "Kit",
		"createdDate": "2011-09-19 17:07:08",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 661,
		"totalCountries": 12,
		"totalStates": 41,
		"totalEvents": 204,
		"eventPercent": 0.813005,
		"totalScheduleItems": 2582,
		"schedulePercent": 0.803072,
		"totalGuests": 109,
		"guestPercent": 0.481393,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Hard Rock Live",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 48
	},
	{
		"id": "e0b2b48",
		"email": "gyi@bellsouth.net",
		"name": "Stella",
		"createdDate": "2019-08-16 04:00:27",
		"totalOrganizations": 2,
		"totalTours": 8,
		"totalDays": 255,
		"totalCountries": 5,
		"totalStates": 41,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1188,
		"schedulePercent": 0.53675,
		"totalGuests": 977,
		"guestPercent": 0.818567,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 70
	},
	{
		"id": "6bbc6db",
		"email": "tai@goldenhourproductions.co",
		"name": "Tai",
		"createdDate": "2023-06-16 13:26:20",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 152,
		"totalCountries": 10,
		"totalStates": 16,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 304,
		"schedulePercent": 0.209906,
		"totalGuests": 78,
		"guestPercent": 0.45398,
		"mostGuestDate": "2023-08-04 00:00:00",
		"mostGuestName": "Parc Downsview Park",
		"mostGuestCity": "North York",
		"mostGuestAmount": 32
	},
	{
		"id": "fafdeca",
		"email": "debbiewilson@wilspro.com",
		"name": "Debbie",
		"createdDate": "2013-09-18 21:38:03",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 1558,
		"totalCountries": 12,
		"totalStates": 54,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 1531,
		"schedulePercent": 0.625348,
		"totalGuests": 1213,
		"guestPercent": 0.859621,
		"mostGuestDate": "2023-06-15 00:00:00",
		"mostGuestName": "Central Park SummerStage",
		"mostGuestCity": "New York",
		"mostGuestAmount": 81
	},
	{
		"id": "6190ee6",
		"email": "stephenrvarga@gmail.com",
		"name": "Stephen",
		"createdDate": "2012-06-25 06:25:26",
		"totalOrganizations": 24,
		"totalTours": 32,
		"totalDays": 535,
		"totalCountries": 15,
		"totalStates": 24,
		"totalEvents": 195,
		"eventPercent": 0.796318,
		"totalScheduleItems": 2184,
		"schedulePercent": 0.748113,
		"totalGuests": 262,
		"guestPercent": 0.585221,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Palais Theatre",
		"mostGuestCity": "St Kilda",
		"mostGuestAmount": 51
	},
	{
		"id": "5450b83",
		"email": "chris.lott01@gmail.com",
		"name": "Chris",
		"createdDate": "2015-05-02 15:29:41",
		"totalOrganizations": 20,
		"totalTours": 16,
		"totalDays": 963,
		"totalCountries": 7,
		"totalStates": 51,
		"totalEvents": 201,
		"eventPercent": 0.808237,
		"totalScheduleItems": 2807,
		"schedulePercent": 0.832737,
		"totalGuests": 486,
		"guestPercent": 0.686664,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 37
	},
	{
		"id": "ab1efd0",
		"email": "rupert@lizhobbsgroup.com",
		"name": "Rupert",
		"createdDate": "2016-08-24 16:19:14",
		"totalOrganizations": 52,
		"totalTours": 47,
		"totalDays": 119,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 763,
		"schedulePercent": 0.406569,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Cartmel Racecourse",
		"mostGuestCity": "Grange-over-Sands",
		"mostGuestAmount": 0
	},
	{
		"id": "bcb0450",
		"email": "nikki@alist.com.au",
		"name": "Nikki",
		"createdDate": "2022-05-17 23:28:45",
		"totalOrganizations": 29,
		"totalTours": 39,
		"totalDays": 1387,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 850,
		"eventPercent": 0.988346,
		"totalScheduleItems": 3632,
		"schedulePercent": 0.902794,
		"totalGuests": 28,
		"guestPercent": 0.386306,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Nautilus Arts Centre",
		"mostGuestCity": "Port Lincoln",
		"mostGuestAmount": 15
	},
	{
		"id": "45e953f",
		"email": "gestion@productionsgrandv.com",
		"name": "Genevi&#232;ve",
		"createdDate": "2023-04-18 22:28:44",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 83,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 273,
		"schedulePercent": 0.194676,
		"totalGuests": 137,
		"guestPercent": 0.504039,
		"mostGuestDate": "2023-08-10 00:00:00",
		"mostGuestName": "Carrefour de la mer",
		"mostGuestCity": "Caraquet",
		"mostGuestAmount": 15
	},
	{
		"id": "2e48945",
		"email": "lucas.peterson@wwecorp.com",
		"name": "Lucas",
		"createdDate": "2019-08-14 01:10:20",
		"totalOrganizations": 3,
		"totalTours": 78,
		"totalDays": 349,
		"totalCountries": 8,
		"totalStates": 55,
		"totalEvents": 204,
		"eventPercent": 0.813005,
		"totalScheduleItems": 3055,
		"schedulePercent": 0.856708,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6b39e74",
		"email": "dexb4.1995@gmail.com",
		"name": "Dexter",
		"createdDate": "2022-02-22 23:52:54",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 215,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 2,
		"eventPercent": 0.0140379,
		"totalScheduleItems": 12,
		"schedulePercent": 0.0252947,
		"totalGuests": 26,
		"guestPercent": 0.381274,
		"mostGuestDate": "2023-07-24 00:00:00",
		"mostGuestName": "Elsewhere",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 26
	},
	{
		"id": "0219e53",
		"email": "zefnoise@icloud.com",
		"name": "Emilio",
		"createdDate": "2022-03-09 12:10:01",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 78,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 460,
		"schedulePercent": 0.280625,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "86dd3b5",
		"email": "wmburke@vybechyle.com",
		"name": "William",
		"createdDate": "2013-10-04 04:35:48",
		"totalOrganizations": 45,
		"totalTours": 31,
		"totalDays": 2413,
		"totalCountries": 21,
		"totalStates": 74,
		"totalEvents": 661,
		"eventPercent": 0.98477,
		"totalScheduleItems": 11156,
		"schedulePercent": 0.997881,
		"totalGuests": 9765,
		"guestPercent": 0.999338,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Glen Helen Amphitheater",
		"mostGuestCity": "San Bernardino",
		"mostGuestAmount": 250
	},
	{
		"id": "a8db4b2",
		"email": "kyosuke@bushwicksounds.com",
		"name": "Kyosuke",
		"createdDate": "2022-02-15 18:08:22",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 73,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 285,
		"schedulePercent": 0.200901,
		"totalGuests": 186,
		"guestPercent": 0.540458,
		"mostGuestDate": "2023-05-12 00:00:00",
		"mostGuestName": "Elsewhere",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 30
	},
	{
		"id": "865b03f",
		"email": "johnbruceaffleck@gmail.com",
		"name": "John Bruce",
		"createdDate": "2014-01-04 12:58:27",
		"totalOrganizations": 13,
		"totalTours": 30,
		"totalDays": 562,
		"totalCountries": 16,
		"totalStates": 72,
		"totalEvents": 258,
		"eventPercent": 0.883989,
		"totalScheduleItems": 3880,
		"schedulePercent": 0.917362,
		"totalGuests": 1008,
		"guestPercent": 0.825718,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Qudos Bank Arena",
		"mostGuestCity": "Sydney Olympic Park",
		"mostGuestAmount": 64
	},
	{
		"id": "c00584f",
		"email": "andreas@balslev-olesen.com",
		"name": "Andreas",
		"createdDate": "2014-12-02 12:32:13",
		"totalOrganizations": 7,
		"totalTours": 1,
		"totalDays": 49,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 328,
		"schedulePercent": 0.223547,
		"totalGuests": 337,
		"guestPercent": 0.621375,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "Fredagsrock i Tivoli",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 50
	},
	{
		"id": "b7fd871",
		"email": "todd@parkerandmrfrench.com",
		"name": "Wagstaff",
		"createdDate": "2011-08-12 12:24:01",
		"totalOrganizations": 11,
		"totalTours": 18,
		"totalDays": 743,
		"totalCountries": 13,
		"totalStates": 52,
		"totalEvents": 218,
		"eventPercent": 0.834724,
		"totalScheduleItems": 2213,
		"schedulePercent": 0.751953,
		"totalGuests": 503,
		"guestPercent": 0.692756,
		"mostGuestDate": "2023-09-19 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 76
	},
	{
		"id": "a457609",
		"email": "michigandermusic@gmail.com",
		"name": "Jason",
		"createdDate": "2019-08-14 20:26:06",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 311,
		"totalCountries": 5,
		"totalStates": 40,
		"totalEvents": 155,
		"eventPercent": 0.716197,
		"totalScheduleItems": 1287,
		"schedulePercent": 0.562839,
		"totalGuests": 846,
		"guestPercent": 0.78718,
		"mostGuestDate": "2023-11-05 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 82
	},
	{
		"id": "e65a609",
		"email": "danielle@modsun.com",
		"name": "Danielle ",
		"createdDate": "2020-03-05 04:51:55",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 689,
		"totalCountries": 7,
		"totalStates": 33,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 540,
		"schedulePercent": 0.317706,
		"totalGuests": 263,
		"guestPercent": 0.585618,
		"mostGuestDate": "2023-03-14 00:00:00",
		"mostGuestName": "Paradise Rock Club",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 20
	},
	{
		"id": "d364d4a",
		"email": "tom.ball3992@gmail.com",
		"name": "Tom",
		"createdDate": "2017-01-23 09:25:37",
		"totalOrganizations": 14,
		"totalTours": 19,
		"totalDays": 405,
		"totalCountries": 11,
		"totalStates": 23,
		"totalEvents": 133,
		"eventPercent": 0.656602,
		"totalScheduleItems": 1087,
		"schedulePercent": 0.509072,
		"totalGuests": 49,
		"guestPercent": 0.419944,
		"mostGuestDate": "2023-05-03 00:00:00",
		"mostGuestName": "Islington Assembly Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 26
	},
	{
		"id": "6b2d315",
		"email": "fredcoxmerch@aol.com",
		"name": "Fred",
		"createdDate": "2009-11-11 19:45:04",
		"totalOrganizations": 18,
		"totalTours": 17,
		"totalDays": 398,
		"totalCountries": 10,
		"totalStates": 48,
		"totalEvents": 176,
		"eventPercent": 0.763475,
		"totalScheduleItems": 3010,
		"schedulePercent": 0.85194,
		"totalGuests": 20,
		"guestPercent": 0.36909,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "The Capitol Theatre",
		"mostGuestCity": "Port Chester",
		"mostGuestAmount": 9
	},
	{
		"id": "4feb5e2",
		"email": "grace@gracekittykat.co.uk",
		"name": "Grace",
		"createdDate": "2022-05-26 13:43:39",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 296,
		"totalCountries": 7,
		"totalStates": 36,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 1148,
		"schedulePercent": 0.526553,
		"totalGuests": 443,
		"guestPercent": 0.669315,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 86
	},
	{
		"id": "0dac015",
		"email": "natalie.ferwerda@gmail.com",
		"name": "Natalie",
		"createdDate": "2015-02-03 04:14:16",
		"totalOrganizations": 12,
		"totalTours": 11,
		"totalDays": 470,
		"totalCountries": 3,
		"totalStates": 41,
		"totalEvents": 290,
		"eventPercent": 0.909019,
		"totalScheduleItems": 3579,
		"schedulePercent": 0.898424,
		"totalGuests": 1828,
		"guestPercent": 0.922924,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "State Farm Arena",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 133
	},
	{
		"id": "a063998",
		"email": "mitch253@earthlink.net",
		"name": "Mitch",
		"createdDate": "2011-06-16 20:04:11",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 525,
		"totalCountries": 3,
		"totalStates": 43,
		"totalEvents": 193,
		"eventPercent": 0.792743,
		"totalScheduleItems": 2758,
		"schedulePercent": 0.826778,
		"totalGuests": 504,
		"guestPercent": 0.693021,
		"mostGuestDate": "2023-07-17 00:00:00",
		"mostGuestName": "Telluride- Club Red",
		"mostGuestCity": "Mountain Village",
		"mostGuestAmount": 36
	},
	{
		"id": "9c81f92",
		"email": "lyndie.liwanpo@gmail.com",
		"name": "Lyndie",
		"createdDate": "2017-12-12 00:20:47",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 85,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 283,
		"schedulePercent": 0.199841,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c282128",
		"email": "hmindahl@me.com",
		"name": "Hans Marius",
		"createdDate": "2020-10-12 23:13:14",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 126,
		"totalCountries": 8,
		"totalStates": 26,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 1185,
		"schedulePercent": 0.535955,
		"totalGuests": 356,
		"guestPercent": 0.633029,
		"mostGuestDate": "2023-03-29 00:00:00",
		"mostGuestName": "El Rey Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 58
	},
	{
		"id": "c479388",
		"email": "stelucianis@gmail.com",
		"name": "Stefano",
		"createdDate": "2015-04-27 09:55:43",
		"totalOrganizations": 14,
		"totalTours": 11,
		"totalDays": 2242,
		"totalCountries": 1,
		"totalStates": 57,
		"totalEvents": 168,
		"eventPercent": 0.747451,
		"totalScheduleItems": 1815,
		"schedulePercent": 0.687856,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-02-08 00:00:00",
		"mostGuestName": "Teatro Verdi",
		"mostGuestCity": "Firenze",
		"mostGuestAmount": 1
	},
	{
		"id": "20c7585",
		"email": "tm@sabapivot.com",
		"name": "Rosalinda",
		"createdDate": "2022-02-28 21:20:35",
		"totalOrganizations": 1,
		"totalTours": 9,
		"totalDays": 45,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 177,
		"schedulePercent": 0.136936,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ac48d8f",
		"email": "geoffreysoundman@gmail.com",
		"name": "Geoff",
		"createdDate": "2013-05-27 18:18:27",
		"totalOrganizations": 11,
		"totalTours": 6,
		"totalDays": 121,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 726,
		"schedulePercent": 0.391074,
		"totalGuests": 379,
		"guestPercent": 0.64455,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Manning Bar",
		"mostGuestCity": "Camperdown",
		"mostGuestAmount": 63
	},
	{
		"id": "ea91ed1",
		"email": "ryleekellogg@icloud.com",
		"name": "Rylee",
		"createdDate": "2014-09-02 23:26:31",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 207,
		"totalCountries": 7,
		"totalStates": 16,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 894,
		"schedulePercent": 0.451331,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "00668c2",
		"email": "starsetron@gmail.com",
		"name": "Ron",
		"createdDate": "2014-10-30 02:45:42",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 36,
		"totalCountries": 9,
		"totalStates": 12,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 360,
		"schedulePercent": 0.237982,
		"totalGuests": 45,
		"guestPercent": 0.412925,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 13
	},
	{
		"id": "6cde20b",
		"email": "louise.ahlander@allthingslive.se",
		"name": "Louise",
		"createdDate": "2023-03-01 13:59:15",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 97,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 251,
		"schedulePercent": 0.18183,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "31d4245",
		"email": "cooperdavisaudio@gmail.com",
		"name": "Cooper",
		"createdDate": "2020-09-08 18:45:29",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 683,
		"totalCountries": 11,
		"totalStates": 55,
		"totalEvents": 189,
		"eventPercent": 0.78718,
		"totalScheduleItems": 2548,
		"schedulePercent": 0.798967,
		"totalGuests": 821,
		"guestPercent": 0.781221,
		"mostGuestDate": "2023-10-03 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 76
	},
	{
		"id": "216052d",
		"email": "bellasmithtouring@gmail.com",
		"name": "Bella",
		"createdDate": "2022-07-28 01:03:44",
		"totalOrganizations": 12,
		"totalTours": 22,
		"totalDays": 999,
		"totalCountries": 7,
		"totalStates": 54,
		"totalEvents": 352,
		"eventPercent": 0.942127,
		"totalScheduleItems": 4075,
		"schedulePercent": 0.9265,
		"totalGuests": 990,
		"guestPercent": 0.821481,
		"mostGuestDate": "2023-06-29 00:00:00",
		"mostGuestName": "Music Hall of Williamsburg",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 85
	},
	{
		"id": "b9703c2",
		"email": "julienbahuau@gmail.com",
		"name": "Julien",
		"createdDate": "2013-09-18 14:45:54",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 132,
		"totalCountries": 8,
		"totalStates": 21,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1292,
		"schedulePercent": 0.564296,
		"totalGuests": 81,
		"guestPercent": 0.455966,
		"mostGuestDate": "2023-03-12 00:00:00",
		"mostGuestName": "Ancienne Belgique",
		"mostGuestCity": "Brussel",
		"mostGuestAmount": 37
	},
	{
		"id": "5f6abc6",
		"email": "Jenn@jllmgmt.com",
		"name": "Jenn",
		"createdDate": "2022-02-09 00:06:31",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 563,
		"totalCountries": 15,
		"totalStates": 62,
		"totalEvents": 176,
		"eventPercent": 0.763475,
		"totalScheduleItems": 3074,
		"schedulePercent": 0.858694,
		"totalGuests": 472,
		"guestPercent": 0.681764,
		"mostGuestDate": "2023-09-05 00:00:00",
		"mostGuestName": "Singapore Indoor Stadium",
		"mostGuestCity": "Singapore",
		"mostGuestAmount": 78
	},
	{
		"id": "faef86e",
		"email": "johncrawfordtm@gmail.com",
		"name": "John",
		"createdDate": "2022-05-10 09:26:33",
		"totalOrganizations": 18,
		"totalTours": 22,
		"totalDays": 523,
		"totalCountries": 12,
		"totalStates": 56,
		"totalEvents": 211,
		"eventPercent": 0.825586,
		"totalScheduleItems": 2925,
		"schedulePercent": 0.845054,
		"totalGuests": 781,
		"guestPercent": 0.771951,
		"mostGuestDate": "2023-11-02 00:00:00",
		"mostGuestName": "O2 Arena",
		"mostGuestCity": "London",
		"mostGuestAmount": 99
	},
	{
		"id": "e0aa08f",
		"email": "rhcpfnf@gmail.com",
		"name": "RHCP",
		"createdDate": "2023-01-08 19:38:07",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 134,
		"totalCountries": 15,
		"totalStates": 28,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 3149,
		"guestPercent": 0.971792,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "Tottenham Hotspur Stadium",
		"mostGuestCity": "London",
		"mostGuestAmount": 338
	},
	{
		"id": "2269de3",
		"email": "kgerhart2@gmail.com",
		"name": "Kyle",
		"createdDate": "2015-10-20 02:41:05",
		"totalOrganizations": 19,
		"totalTours": 8,
		"totalDays": 590,
		"totalCountries": 16,
		"totalStates": 62,
		"totalEvents": 218,
		"eventPercent": 0.834724,
		"totalScheduleItems": 2586,
		"schedulePercent": 0.804132,
		"totalGuests": 337,
		"guestPercent": 0.621375,
		"mostGuestDate": "2023-07-18 00:00:00",
		"mostGuestName": "El Rey Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 44
	},
	{
		"id": "9332263",
		"email": "elisadronow@gmail.com",
		"name": "Elisa",
		"createdDate": "2019-10-11 17:43:48",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 191,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 2247,
		"schedulePercent": 0.757251,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "745c488",
		"email": "david@david51.com",
		"name": "David",
		"createdDate": "2010-10-20 17:45:52",
		"totalOrganizations": 13,
		"totalTours": 15,
		"totalDays": 1064,
		"totalCountries": 19,
		"totalStates": 77,
		"totalEvents": 361,
		"eventPercent": 0.946894,
		"totalScheduleItems": 5191,
		"schedulePercent": 0.967289,
		"totalGuests": 1482,
		"guestPercent": 0.891802,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "Qudos Bank Arena",
		"mostGuestCity": "Sydney Olympic Park",
		"mostGuestAmount": 64
	},
	{
		"id": "1616d4c",
		"email": "jacob@monstermanagement.com.au",
		"name": "Jacob",
		"createdDate": "2013-10-08 13:14:26",
		"totalOrganizations": 19,
		"totalTours": 38,
		"totalDays": 779,
		"totalCountries": 13,
		"totalStates": 71,
		"totalEvents": 359,
		"eventPercent": 0.946232,
		"totalScheduleItems": 3700,
		"schedulePercent": 0.907297,
		"totalGuests": 751,
		"guestPercent": 0.764667,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "Franklin Music Hall",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 56
	},
	{
		"id": "70b1052",
		"email": "meera@riverjuke.co.uk",
		"name": "Meera",
		"createdDate": "2023-06-01 17:29:24",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 555,
		"totalCountries": 13,
		"totalStates": 45,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 3045,
		"schedulePercent": 0.855648,
		"totalGuests": 529,
		"guestPercent": 0.701894,
		"mostGuestDate": "2023-10-31 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 91
	},
	{
		"id": "caa8a52",
		"email": "renehallags@gmail.com",
		"name": "Rene ",
		"createdDate": "2021-08-17 23:04:17",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 781,
		"totalCountries": 1,
		"totalStates": 30,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 203,
		"schedulePercent": 0.152165,
		"totalGuests": 693,
		"guestPercent": 0.751556,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Glass Cactus",
		"mostGuestCity": "Grapevine",
		"mostGuestAmount": 68
	},
	{
		"id": "3ce8943",
		"email": "Bridget.Herrmann@wmg.com",
		"name": "Bridget",
		"createdDate": "2018-07-25 16:32:33",
		"totalOrganizations": 52,
		"totalTours": 48,
		"totalDays": 15166,
		"totalCountries": 15,
		"totalStates": 89,
		"totalEvents": 1989,
		"eventPercent": 0.996159,
		"totalScheduleItems": 1236,
		"schedulePercent": 0.549066,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b359466",
		"email": "steveiredale@zoho.eu",
		"name": "Steve ",
		"createdDate": "2023-08-23 06:33:30",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 141,
		"totalCountries": 4,
		"totalStates": 11,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 332,
		"schedulePercent": 0.225136,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cf70e84",
		"email": "xmastreemike@icloud.com",
		"name": "Michael",
		"createdDate": "2017-09-22 13:12:48",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 650,
		"totalCountries": 15,
		"totalStates": 26,
		"totalEvents": 264,
		"eventPercent": 0.889419,
		"totalScheduleItems": 2759,
		"schedulePercent": 0.827043,
		"totalGuests": 5715,
		"guestPercent": 0.99457,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Wembley Stadium",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 625
	},
	{
		"id": "6476625",
		"email": "temi.kinnunen@gmail.com",
		"name": "Temi",
		"createdDate": "2017-05-10 11:57:24",
		"totalOrganizations": 23,
		"totalTours": 30,
		"totalDays": 863,
		"totalCountries": 4,
		"totalStates": 28,
		"totalEvents": 787,
		"eventPercent": 0.987684,
		"totalScheduleItems": 3749,
		"schedulePercent": 0.909416,
		"totalGuests": 235,
		"guestPercent": 0.57052,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "Tavastia",
		"mostGuestCity": "Helsingfors",
		"mostGuestAmount": 40
	},
	{
		"id": "3c53efd",
		"email": "kmiller@crushmalibu.com",
		"name": "Kevin",
		"createdDate": "2020-02-17 04:53:59",
		"totalOrganizations": 7,
		"totalTours": 15,
		"totalDays": 1106,
		"totalCountries": 12,
		"totalStates": 51,
		"totalEvents": 183,
		"eventPercent": 0.775659,
		"totalScheduleItems": 3469,
		"schedulePercent": 0.891935,
		"totalGuests": 407,
		"guestPercent": 0.656337,
		"mostGuestDate": "2023-01-10 00:00:00",
		"mostGuestName": "The Classic Center",
		"mostGuestCity": "Athens",
		"mostGuestAmount": 68
	},
	{
		"id": "3d32138",
		"email": "rockandrollambulance@gmail.com",
		"name": "Sean",
		"createdDate": "2009-07-24 00:04:55",
		"totalOrganizations": 8,
		"totalTours": 14,
		"totalDays": 523,
		"totalCountries": 13,
		"totalStates": 52,
		"totalEvents": 195,
		"eventPercent": 0.796318,
		"totalScheduleItems": 2948,
		"schedulePercent": 0.846908,
		"totalGuests": 365,
		"guestPercent": 0.638591,
		"mostGuestDate": "2023-07-01 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 41
	},
	{
		"id": "deb6c00",
		"email": "tuckerfredock@gmail.com",
		"name": "Tucker",
		"createdDate": "2017-07-08 22:08:01",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 848,
		"totalCountries": 2,
		"totalStates": 39,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 2075,
		"schedulePercent": 0.730764,
		"totalGuests": 1257,
		"guestPercent": 0.866243,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "AT&T Stadium / SHOW",
		"mostGuestCity": "Arlington",
		"mostGuestAmount": 155
	},
	{
		"id": "e099fbe",
		"email": "Niki.prekop@gmail.com",
		"name": "Niki",
		"createdDate": "2019-08-31 02:51:51",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 843,
		"totalCountries": 7,
		"totalStates": 42,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 1079,
		"schedulePercent": 0.507218,
		"totalGuests": 250,
		"guestPercent": 0.578599,
		"mostGuestDate": "2023-04-21 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 85
	},
	{
		"id": "3e71f7c",
		"email": "topherpatt@gmail.com",
		"name": "Christopher",
		"createdDate": "2015-09-14 23:12:41",
		"totalOrganizations": 49,
		"totalTours": 35,
		"totalDays": 1276,
		"totalCountries": 13,
		"totalStates": 59,
		"totalEvents": 677,
		"eventPercent": 0.9853,
		"totalScheduleItems": 6448,
		"schedulePercent": 0.984638,
		"totalGuests": 4851,
		"guestPercent": 0.990995,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 221
	},
	{
		"id": "0211eb5",
		"email": "tim@oldlion.eu",
		"name": "Tim",
		"createdDate": "2011-11-25 13:50:35",
		"totalOrganizations": 33,
		"totalTours": 11,
		"totalDays": 458,
		"totalCountries": 11,
		"totalStates": 33,
		"totalEvents": 155,
		"eventPercent": 0.716197,
		"totalScheduleItems": 1846,
		"schedulePercent": 0.694478,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Olympic Stadium",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 4
	},
	{
		"id": "637bb5f",
		"email": "lexi@lexishannon.com",
		"name": "Lexi",
		"createdDate": "2023-06-12 20:24:13",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 93,
		"totalCountries": 4,
		"totalStates": 16,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 319,
		"schedulePercent": 0.218117,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fecda7b",
		"email": "gui.fraisse@gmail.com",
		"name": "Guilhem",
		"createdDate": "2023-02-03 05:23:21",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 170,
		"totalCountries": 13,
		"totalStates": 33,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 199,
		"schedulePercent": 0.149781,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-03-21 00:00:00",
		"mostGuestName": "THE REGENCY BALLROOM",
		"mostGuestCity": "San Francisco",
		"mostGuestAmount": 6
	},
	{
		"id": "6442f26",
		"email": "tony@gpmgmt.co.uk",
		"name": "Anthony",
		"createdDate": "2023-07-04 11:35:05",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 226,
		"totalCountries": 3,
		"totalStates": 4,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 182,
		"schedulePercent": 0.139849,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "164edff",
		"email": "louise@freighttrainmusic.com.au",
		"name": "Louise",
		"createdDate": "2016-10-16 12:15:27",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 114,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 404,
		"schedulePercent": 0.259039,
		"totalGuests": 162,
		"guestPercent": 0.524169,
		"mostGuestDate": "2023-06-18 00:00:00",
		"mostGuestName": "Joan Sutherland Performing Arts Centre",
		"mostGuestCity": "Penrith",
		"mostGuestAmount": 58
	},
	{
		"id": "5d6e0ea",
		"email": "christophermwillatt@gmail.com",
		"name": "Chris",
		"createdDate": "2023-05-15 10:10:19",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 51,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 163,
		"schedulePercent": 0.128857,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-09-29 00:00:00",
		"mostGuestName": "The Roxy Theatre",
		"mostGuestCity": "Hollywood",
		"mostGuestAmount": 2
	},
	{
		"id": "04ba022",
		"email": "info@transparentproductions.com",
		"name": "Haley",
		"createdDate": "2022-08-22 19:35:08",
		"totalOrganizations": 13,
		"totalTours": 17,
		"totalDays": 576,
		"totalCountries": 3,
		"totalStates": 40,
		"totalEvents": 221,
		"eventPercent": 0.83777,
		"totalScheduleItems": 4290,
		"schedulePercent": 0.937359,
		"totalGuests": 2310,
		"guestPercent": 0.948086,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 293
	},
	{
		"id": "3a9dbbf",
		"email": "jamnationinfo@jamtour.com",
		"name": "Deanna",
		"createdDate": "2014-12-12 16:55:25",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 217,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 133,
		"eventPercent": 0.656602,
		"totalScheduleItems": 1276,
		"schedulePercent": 0.558734,
		"totalGuests": 2824,
		"guestPercent": 0.964905,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 298
	},
	{
		"id": "4202259",
		"email": "BillKuykendall@gmail.com",
		"name": "Billy",
		"createdDate": "2022-02-01 21:43:57",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 246,
		"totalCountries": 6,
		"totalStates": 41,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 827,
		"schedulePercent": 0.429215,
		"totalGuests": 35,
		"guestPercent": 0.396901,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Iron City Bham",
		"mostGuestCity": "Birmingham",
		"mostGuestAmount": 17
	},
	{
		"id": "a539077",
		"email": "andytylerclarke@gmail.com",
		"name": "Andy",
		"createdDate": "2016-06-28 04:17:04",
		"totalOrganizations": 13,
		"totalTours": 35,
		"totalDays": 805,
		"totalCountries": 15,
		"totalStates": 69,
		"totalEvents": 401,
		"eventPercent": 0.957489,
		"totalScheduleItems": 6144,
		"schedulePercent": 0.981327,
		"totalGuests": 5687,
		"guestPercent": 0.994438,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The ICON Festival Stage",
		"mostGuestCity": "Cincinnati",
		"mostGuestAmount": 238
	},
	{
		"id": "e69e270",
		"email": "casey.cress@gmail.com",
		"name": "Casey",
		"createdDate": "2012-07-28 17:52:26",
		"totalOrganizations": 8,
		"totalTours": 15,
		"totalDays": 271,
		"totalCountries": 5,
		"totalStates": 48,
		"totalEvents": 166,
		"eventPercent": 0.74308,
		"totalScheduleItems": 1525,
		"schedulePercent": 0.623758,
		"totalGuests": 2226,
		"guestPercent": 0.94504,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 183
	},
	{
		"id": "bafc4b4",
		"email": "caiken@mac.com",
		"name": "Clay",
		"createdDate": "2023-01-20 00:12:24",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 341,
		"totalCountries": 2,
		"totalStates": 33,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 969,
		"schedulePercent": 0.471063,
		"totalGuests": 405,
		"guestPercent": 0.655145,
		"mostGuestDate": "2023-04-29 00:00:00",
		"mostGuestName": "Koka Booth Amphitheatre",
		"mostGuestCity": "Cary",
		"mostGuestAmount": 93
	},
	{
		"id": "6b27f70",
		"email": "production@star-entertainment.org",
		"name": "Production",
		"createdDate": "2021-05-03 14:27:18",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 41,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 237,
		"schedulePercent": 0.173355,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d5ed2ba",
		"email": "jordanlovis@gmail.com",
		"name": "Jordan",
		"createdDate": "2011-09-09 23:28:10",
		"totalOrganizations": 12,
		"totalTours": 12,
		"totalDays": 972,
		"totalCountries": 23,
		"totalStates": 80,
		"totalEvents": 235,
		"eventPercent": 0.855516,
		"totalScheduleItems": 4742,
		"schedulePercent": 0.953384,
		"totalGuests": 1512,
		"guestPercent": 0.895908,
		"mostGuestDate": "2023-03-29 00:00:00",
		"mostGuestName": "OVO Arena",
		"mostGuestCity": "London",
		"mostGuestAmount": 104
	},
	{
		"id": "d8b3a2b",
		"email": "niksheasby@gmail.com",
		"name": "Nicholas",
		"createdDate": "2011-10-25 18:48:23",
		"totalOrganizations": 8,
		"totalTours": 20,
		"totalDays": 800,
		"totalCountries": 11,
		"totalStates": 55,
		"totalEvents": 230,
		"eventPercent": 0.849159,
		"totalScheduleItems": 3827,
		"schedulePercent": 0.913786,
		"totalGuests": 623,
		"guestPercent": 0.732353,
		"mostGuestDate": "2023-06-19 00:00:00",
		"mostGuestName": "Moore Theatre",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 133
	},
	{
		"id": "f01b5d2",
		"email": "mathieu.burton@hotmail.com",
		"name": "Mathieu",
		"createdDate": "2018-02-19 17:42:35",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 58,
		"totalCountries": 5,
		"totalStates": 7,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 517,
		"schedulePercent": 0.306847,
		"totalGuests": 64,
		"guestPercent": 0.439015,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "MOTH Club",
		"mostGuestCity": "London",
		"mostGuestAmount": 12
	},
	{
		"id": "9dea5a6",
		"email": "jim@losingthethread.co.uk",
		"name": "Jim",
		"createdDate": "2017-07-10 12:14:00",
		"totalOrganizations": 12,
		"totalTours": 21,
		"totalDays": 269,
		"totalCountries": 6,
		"totalStates": 18,
		"totalEvents": 166,
		"eventPercent": 0.74308,
		"totalScheduleItems": 1678,
		"schedulePercent": 0.659515,
		"totalGuests": 1092,
		"guestPercent": 0.838035,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 159
	},
	{
		"id": "af2aeff",
		"email": "nicky@goodharbormusic.com",
		"name": "Nicky",
		"createdDate": "2010-09-17 14:59:15",
		"totalOrganizations": 21,
		"totalTours": 25,
		"totalDays": 1161,
		"totalCountries": 15,
		"totalStates": 67,
		"totalEvents": 312,
		"eventPercent": 0.921997,
		"totalScheduleItems": 4748,
		"schedulePercent": 0.953781,
		"totalGuests": 1272,
		"guestPercent": 0.868229,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "Ben And Jerrys Concerts On The Green",
		"mostGuestCity": "Shelburne",
		"mostGuestAmount": 71
	},
	{
		"id": "7e5018b",
		"email": "em@gomcircus.com.au",
		"name": "Em ",
		"createdDate": "2023-02-08 05:37:48",
		"totalOrganizations": 1,
		"totalTours": 8,
		"totalDays": 426,
		"totalCountries": 11,
		"totalStates": 38,
		"totalEvents": 175,
		"eventPercent": 0.761753,
		"totalScheduleItems": 1440,
		"schedulePercent": 0.601642,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cd7fc8b",
		"email": "rightcoastrecords@gmail.com",
		"name": "Scott",
		"createdDate": "2015-06-06 23:33:26",
		"totalOrganizations": 2,
		"totalTours": 18,
		"totalDays": 285,
		"totalCountries": 2,
		"totalStates": 37,
		"totalEvents": 162,
		"eventPercent": 0.732883,
		"totalScheduleItems": 1166,
		"schedulePercent": 0.531188,
		"totalGuests": 114,
		"guestPercent": 0.486028,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "GOLDFIELD",
		"mostGuestCity": "ROSEVILLE",
		"mostGuestAmount": 20
	},
	{
		"id": "fc1001f",
		"email": "audioshorty@me.com",
		"name": "Steve",
		"createdDate": "2022-04-27 16:49:02",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 104,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 730,
		"schedulePercent": 0.392266,
		"totalGuests": 227,
		"guestPercent": 0.567474,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Saban Theatre",
		"mostGuestCity": "Beverly Hills",
		"mostGuestAmount": 43
	},
	{
		"id": "0a2b63d",
		"email": "billy@howlaroundeg.com",
		"name": "William",
		"createdDate": "2023-07-07 23:00:13",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 516,
		"totalCountries": 11,
		"totalStates": 23,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 769,
		"schedulePercent": 0.408555,
		"totalGuests": 263,
		"guestPercent": 0.585618,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Tivoli",
		"mostGuestCity": "Brisbane",
		"mostGuestAmount": 63
	},
	{
		"id": "d2d61fd",
		"email": "hopecatherinesutton@gmail.com",
		"name": "Hope",
		"createdDate": "2017-03-22 13:58:51",
		"totalOrganizations": 9,
		"totalTours": 8,
		"totalDays": 1711,
		"totalCountries": 16,
		"totalStates": 59,
		"totalEvents": 424,
		"eventPercent": 0.963316,
		"totalScheduleItems": 5755,
		"schedulePercent": 0.977222,
		"totalGuests": 1344,
		"guestPercent": 0.876837,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 75
	},
	{
		"id": "2f39c43",
		"email": "gabriela.guadalupe@live.com",
		"name": "Gabriela ",
		"createdDate": "2022-08-03 16:07:11",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 574,
		"totalCountries": 4,
		"totalStates": 26,
		"totalEvents": 206,
		"eventPercent": 0.816183,
		"totalScheduleItems": 3367,
		"schedulePercent": 0.884519,
		"totalGuests": 20,
		"guestPercent": 0.36909,
		"mostGuestDate": "2023-08-10 00:00:00",
		"mostGuestName": "Allegiant Stadium",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 20
	},
	{
		"id": "375329d",
		"email": "Edd@eddsedgwicklive.com",
		"name": "Edd",
		"createdDate": "2018-02-04 08:59:13",
		"totalOrganizations": 17,
		"totalTours": 18,
		"totalDays": 422,
		"totalCountries": 12,
		"totalStates": 34,
		"totalEvents": 216,
		"eventPercent": 0.831943,
		"totalScheduleItems": 2792,
		"schedulePercent": 0.831413,
		"totalGuests": 1217,
		"guestPercent": 0.860416,
		"mostGuestDate": "2023-11-29 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 222
	},
	{
		"id": "4477558",
		"email": "sameerjcash@gmail.com",
		"name": "Sameer",
		"createdDate": "2023-06-26 14:53:10",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 64,
		"totalCountries": 2,
		"totalStates": 20,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 278,
		"schedulePercent": 0.196795,
		"totalGuests": 260,
		"guestPercent": 0.584293,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 52
	},
	{
		"id": "eb8b4a3",
		"email": "mccombover1@gmail.com",
		"name": "Jeremy",
		"createdDate": "2015-08-21 03:52:19",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 313,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 387,
		"schedulePercent": 0.251622,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "726a428",
		"email": "saintleviticus@gmail.com",
		"name": "Aarron",
		"createdDate": "2014-07-27 00:53:55",
		"totalOrganizations": 23,
		"totalTours": 18,
		"totalDays": 525,
		"totalCountries": 13,
		"totalStates": 53,
		"totalEvents": 160,
		"eventPercent": 0.72838,
		"totalScheduleItems": 2260,
		"schedulePercent": 0.760694,
		"totalGuests": 375,
		"guestPercent": 0.643358,
		"mostGuestDate": "2023-09-14 00:00:00",
		"mostGuestName": "Emo's",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 41
	},
	{
		"id": "976d745",
		"email": "ikevtpb@mac.com",
		"name": "Ike",
		"createdDate": "2009-07-16 01:12:11",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 228,
		"totalCountries": 4,
		"totalStates": 36,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1596,
		"schedulePercent": 0.641769,
		"totalGuests": 2052,
		"guestPercent": 0.937359,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Gorge Amphitheatre",
		"mostGuestCity": "George",
		"mostGuestAmount": 205
	},
	{
		"id": "44fcc7b",
		"email": "misha@Mbkentertainment.com",
		"name": "Misha",
		"createdDate": "2017-08-02 18:00:48",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 72,
		"totalCountries": 3,
		"totalStates": 26,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 550,
		"schedulePercent": 0.321414,
		"totalGuests": 191,
		"guestPercent": 0.543769,
		"mostGuestDate": "2023-05-26 00:00:00",
		"mostGuestName": "Coca-Cola Roxy",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 76
	},
	{
		"id": "1f1b94e",
		"email": "leo.raddatz@blacksheep-management.de",
		"name": "Leo",
		"createdDate": "2023-03-24 15:21:46",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 72,
		"totalCountries": 8,
		"totalStates": 14,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 606,
		"schedulePercent": 0.343796,
		"totalGuests": 768,
		"guestPercent": 0.769037,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "Arena Leipzig",
		"mostGuestCity": "Leipzig",
		"mostGuestAmount": 143
	},
	{
		"id": "0fba23a",
		"email": "vesasytela@mac.com",
		"name": "Vesa",
		"createdDate": "2010-01-21 20:17:04",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 33,
		"totalCountries": 8,
		"totalStates": 8,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 164,
		"schedulePercent": 0.129652,
		"totalGuests": 55,
		"guestPercent": 0.42842,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "Electric Ballroom",
		"mostGuestCity": "London",
		"mostGuestAmount": 12
	},
	{
		"id": "2f58960",
		"email": "cjhudson@mac.com",
		"name": "Chris ",
		"createdDate": "2012-05-11 16:33:58",
		"totalOrganizations": 21,
		"totalTours": 1,
		"totalDays": 146,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 1353,
		"schedulePercent": 0.581248,
		"totalGuests": 111,
		"guestPercent": 0.483115,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "Fantasy Springs Casino",
		"mostGuestCity": "Indio",
		"mostGuestAmount": 36
	},
	{
		"id": "bece346",
		"email": "omar@83entertainment.com",
		"name": "Omar",
		"createdDate": "2018-02-28 18:09:31",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 569,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 1285,
		"schedulePercent": 0.56125,
		"totalGuests": 556,
		"guestPercent": 0.712356,
		"mostGuestDate": "2023-03-10 00:00:00",
		"mostGuestName": "Texas Live!",
		"mostGuestCity": "Arlington",
		"mostGuestAmount": 50
	},
	{
		"id": "bdd9e89",
		"email": "normanticmusic@gmail.com",
		"name": "Norman",
		"createdDate": "2016-03-25 01:51:09",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 377,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 163,
		"schedulePercent": 0.128857,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-06-25 00:00:00",
		"mostGuestName": "Music Hall at Fair Park",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 3
	},
	{
		"id": "e1dcfc4",
		"email": "alice.kop@hotmail.com",
		"name": "Louise",
		"createdDate": "2019-11-29 13:39:50",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 168,
		"totalCountries": 6,
		"totalStates": 14,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 306,
		"schedulePercent": 0.211098,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-01-17 00:00:00",
		"mostGuestName": "ThÃ©Ã¢tre Edwige FeuillÃ¨re",
		"mostGuestCity": "Vesoul",
		"mostGuestAmount": 4
	},
	{
		"id": "74aaf64",
		"email": "josh@premierproductions.com",
		"name": "Joshua",
		"createdDate": "2014-10-09 20:56:47",
		"totalOrganizations": 24,
		"totalTours": 12,
		"totalDays": 693,
		"totalCountries": 5,
		"totalStates": 32,
		"totalEvents": 141,
		"eventPercent": 0.678321,
		"totalScheduleItems": 3052,
		"schedulePercent": 0.856046,
		"totalGuests": 5183,
		"guestPercent": 0.993114,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Dickies Arena",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 433
	},
	{
		"id": "28ea0b3",
		"email": "jaumepipa@gmail.com",
		"name": "Jaume",
		"createdDate": "2018-06-21 13:31:00",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 71,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 266,
		"schedulePercent": 0.190041,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Auditorio de la Granja",
		"mostGuestCity": "Ciudad Real",
		"mostGuestAmount": 15
	},
	{
		"id": "316a14b",
		"email": "tonelopez@gmail.com",
		"name": "Tone",
		"createdDate": "2015-11-02 15:04:57",
		"totalOrganizations": 76,
		"totalTours": 63,
		"totalDays": 1612,
		"totalCountries": 26,
		"totalStates": 89,
		"totalEvents": 713,
		"eventPercent": 0.986624,
		"totalScheduleItems": 7084,
		"schedulePercent": 0.989803,
		"totalGuests": 4368,
		"guestPercent": 0.986889,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 221
	},
	{
		"id": "55b5b25",
		"email": "luc.vanweelden@gmail.com",
		"name": "Luc",
		"createdDate": "2018-05-23 13:39:06",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 126,
		"totalCountries": 12,
		"totalStates": 36,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 536,
		"schedulePercent": 0.315455,
		"totalGuests": 138,
		"guestPercent": 0.504966,
		"mostGuestDate": "2023-05-24 00:00:00",
		"mostGuestName": "The Bowery Ballroom",
		"mostGuestCity": "New York",
		"mostGuestAmount": 31
	},
	{
		"id": "c26894e",
		"email": "cohunepalm@gmail.com",
		"name": "Garrett",
		"createdDate": "2019-01-10 14:03:05",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 366,
		"totalCountries": 3,
		"totalStates": 28,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 830,
		"schedulePercent": 0.430539,
		"totalGuests": 2750,
		"guestPercent": 0.962521,
		"mostGuestDate": "2023-09-24 00:00:00",
		"mostGuestName": "PNC Music Pavilion",
		"mostGuestCity": "Charlotte",
		"mostGuestAmount": 141
	},
	{
		"id": "7d94e5e",
		"email": "Custersound@gmail.com",
		"name": "Chris",
		"createdDate": "2018-09-26 20:05:55",
		"totalOrganizations": 16,
		"totalTours": 9,
		"totalDays": 807,
		"totalCountries": 5,
		"totalStates": 48,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1170,
		"schedulePercent": 0.531983,
		"totalGuests": 46,
		"guestPercent": 0.415044,
		"mostGuestDate": "2023-04-07 00:00:00",
		"mostGuestName": "University Of Hawaii",
		"mostGuestCity": "Honolulu",
		"mostGuestAmount": 7
	},
	{
		"id": "ddd2122",
		"email": "anthony.aitken@icloud.com",
		"name": "Ant",
		"createdDate": "2014-12-08 04:41:27",
		"totalOrganizations": 39,
		"totalTours": 4,
		"totalDays": 115,
		"totalCountries": 9,
		"totalStates": 15,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 442,
		"schedulePercent": 0.273606,
		"totalGuests": 66,
		"guestPercent": 0.441001,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Powerstation",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 27
	},
	{
		"id": "c9f9e6d",
		"email": "joneluv@aol.com",
		"name": "John",
		"createdDate": "2017-01-04 18:01:27",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 737,
		"totalCountries": 4,
		"totalStates": 40,
		"totalEvents": 184,
		"eventPercent": 0.77791,
		"totalScheduleItems": 3087,
		"schedulePercent": 0.859886,
		"totalGuests": 2645,
		"guestPercent": 0.957224,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "Nissan Stadium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 118
	},
	{
		"id": "bd4e605",
		"email": "aurelie@auguriproductions.com",
		"name": "Auguri",
		"createdDate": "2014-02-01 15:34:31",
		"totalOrganizations": 13,
		"totalTours": 10,
		"totalDays": 1670,
		"totalCountries": 7,
		"totalStates": 33,
		"totalEvents": 329,
		"eventPercent": 0.930738,
		"totalScheduleItems": 3870,
		"schedulePercent": 0.916303,
		"totalGuests": 1066,
		"guestPercent": 0.834459,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "ArkÃ©a Arena",
		"mostGuestCity": "Floirac",
		"mostGuestAmount": 60
	},
	{
		"id": "533a2f5",
		"email": "green.kylieliz@gmail.com",
		"name": "Kylie",
		"createdDate": "2022-11-15 13:26:19",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 287,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 658,
		"schedulePercent": 0.364455,
		"totalGuests": 287,
		"guestPercent": 0.597007,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Madison Theater",
		"mostGuestCity": "Covington",
		"mostGuestAmount": 47
	},
	{
		"id": "92a7f25",
		"email": "alf@totallive.no",
		"name": "Alf",
		"createdDate": "2019-02-19 11:12:35",
		"totalOrganizations": 15,
		"totalTours": 8,
		"totalDays": 293,
		"totalCountries": 14,
		"totalStates": 28,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 1818,
		"schedulePercent": 0.68865,
		"totalGuests": 364,
		"guestPercent": 0.638194,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "Pepsi center",
		"mostGuestCity": "Ciudad de MÃ©xico",
		"mostGuestAmount": 57
	},
	{
		"id": "6428f0e",
		"email": "production@gagreflex.co.uk",
		"name": "Ollie",
		"createdDate": "2019-10-11 16:44:32",
		"totalOrganizations": 31,
		"totalTours": 30,
		"totalDays": 431,
		"totalCountries": 4,
		"totalStates": 22,
		"totalEvents": 272,
		"eventPercent": 0.895643,
		"totalScheduleItems": 1546,
		"schedulePercent": 0.629056,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b44dd73",
		"email": "aj.faber@gmail.com",
		"name": "AJ",
		"createdDate": "2014-10-21 18:53:42",
		"totalOrganizations": 19,
		"totalTours": 28,
		"totalDays": 596,
		"totalCountries": 12,
		"totalStates": 48,
		"totalEvents": 250,
		"eventPercent": 0.872335,
		"totalScheduleItems": 3457,
		"schedulePercent": 0.890346,
		"totalGuests": 1621,
		"guestPercent": 0.905311,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 185
	},
	{
		"id": "344ede9",
		"email": "Sarah@nachtmusik.co.uk",
		"name": "Sarah",
		"createdDate": "2015-03-16 12:04:11",
		"totalOrganizations": 11,
		"totalTours": 9,
		"totalDays": 436,
		"totalCountries": 13,
		"totalStates": 26,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 961,
		"schedulePercent": 0.468812,
		"totalGuests": 38,
		"guestPercent": 0.402198,
		"mostGuestDate": "2023-07-31 00:00:00",
		"mostGuestName": "Olavfestdagene i Trondheim",
		"mostGuestCity": "Trondheim",
		"mostGuestAmount": 18
	},
	{
		"id": "9c8d827",
		"email": "aoife.a@gmail.com",
		"name": "Aoife",
		"createdDate": "2016-08-02 09:18:35",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 188,
		"totalCountries": 7,
		"totalStates": 8,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 6,
		"schedulePercent": 0.0164217,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "96ed2bb",
		"email": "omar@culturaprofetica.com",
		"name": "Omar",
		"createdDate": "2017-05-30 01:38:58",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 98,
		"totalCountries": 6,
		"totalStates": 11,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 278,
		"schedulePercent": 0.196795,
		"totalGuests": 584,
		"guestPercent": 0.720302,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "Concha AcÃºstica de Colinas de Bello Monte",
		"mostGuestCity": "Caracas",
		"mostGuestAmount": 92
	},
	{
		"id": "52cc157",
		"email": "lizzie_graham@icloud.com",
		"name": "Lizzie",
		"createdDate": "2018-07-31 12:27:26",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 646,
		"totalCountries": 19,
		"totalStates": 48,
		"totalEvents": 320,
		"eventPercent": 0.925705,
		"totalScheduleItems": 4691,
		"schedulePercent": 0.951397,
		"totalGuests": 1136,
		"guestPercent": 0.846246,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Qudos Bank Arena",
		"mostGuestCity": "Sydney Olympic Park",
		"mostGuestAmount": 67
	},
	{
		"id": "5469fad",
		"email": "geraghtymic@gmail.com",
		"name": "Micheal",
		"createdDate": "2015-10-30 16:47:59",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 163,
		"totalCountries": 5,
		"totalStates": 34,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 1034,
		"schedulePercent": 0.49318,
		"totalGuests": 586,
		"guestPercent": 0.720832,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 50
	},
	{
		"id": "03294a3",
		"email": "jtobias03@gmail.com",
		"name": "Jason",
		"createdDate": "2009-09-16 15:18:10",
		"totalOrganizations": 7,
		"totalTours": 19,
		"totalDays": 567,
		"totalCountries": 18,
		"totalStates": 66,
		"totalEvents": 244,
		"eventPercent": 0.865713,
		"totalScheduleItems": 5414,
		"schedulePercent": 0.971659,
		"totalGuests": 2658,
		"guestPercent": 0.958284,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "Pine Knob Music Theatre",
		"mostGuestCity": "Clarkston",
		"mostGuestAmount": 104
	},
	{
		"id": "98e7f21",
		"email": "psantana@cityparksfoundation.org",
		"name": "Priscila",
		"createdDate": "2023-03-30 16:49:10",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 271,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d57a527",
		"email": "tbutashmusic@gmail.com",
		"name": "Victoria",
		"createdDate": "2021-07-25 23:22:10",
		"totalOrganizations": 3,
		"totalTours": 11,
		"totalDays": 436,
		"totalCountries": 9,
		"totalStates": 51,
		"totalEvents": 192,
		"eventPercent": 0.791683,
		"totalScheduleItems": 3167,
		"schedulePercent": 0.867567,
		"totalGuests": 1221,
		"guestPercent": 0.86121,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "Rabbit Rabbit",
		"mostGuestCity": "Asheville",
		"mostGuestAmount": 89
	},
	{
		"id": "6d7e303",
		"email": "phildrivesthings@gmail.com",
		"name": "Phil",
		"createdDate": "2021-08-07 07:57:35",
		"totalOrganizations": 16,
		"totalTours": 47,
		"totalDays": 757,
		"totalCountries": 22,
		"totalStates": 101,
		"totalEvents": 466,
		"eventPercent": 0.97007,
		"totalScheduleItems": 5156,
		"schedulePercent": 0.966627,
		"totalGuests": 1557,
		"guestPercent": 0.899616,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "Albert Hall",
		"mostGuestCity": "City Centre",
		"mostGuestAmount": 68
	},
	{
		"id": "e4a8ec6",
		"email": "beth@electroganic.org",
		"name": "Beth",
		"createdDate": "2022-02-10 15:17:27",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 330,
		"totalCountries": 5,
		"totalStates": 54,
		"totalEvents": 226,
		"eventPercent": 0.843464,
		"totalScheduleItems": 2563,
		"schedulePercent": 0.800954,
		"totalGuests": 1216,
		"guestPercent": 0.860283,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Palladium Times Square",
		"mostGuestCity": "New York",
		"mostGuestAmount": 97
	},
	{
		"id": "e3efbce",
		"email": "kiwisound@me.com",
		"name": "Bjarne",
		"createdDate": "2016-05-16 23:33:27",
		"totalOrganizations": 12,
		"totalTours": 15,
		"totalDays": 370,
		"totalCountries": 17,
		"totalStates": 35,
		"totalEvents": 137,
		"eventPercent": 0.666402,
		"totalScheduleItems": 1998,
		"schedulePercent": 0.718051,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ab0880a",
		"email": "kelsey@birthdaycakemedia.com",
		"name": "Kelsey",
		"createdDate": "2022-08-08 18:01:46",
		"totalOrganizations": 7,
		"totalTours": 31,
		"totalDays": 634,
		"totalCountries": 11,
		"totalStates": 58,
		"totalEvents": 320,
		"eventPercent": 0.925705,
		"totalScheduleItems": 2248,
		"schedulePercent": 0.757648,
		"totalGuests": 980,
		"guestPercent": 0.819362,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 54
	},
	{
		"id": "292aeda",
		"email": "robertcadams63@gmail.com",
		"name": "BigDog",
		"createdDate": "2023-05-05 15:08:13",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 239,
		"totalCountries": 1,
		"totalStates": 31,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 826,
		"schedulePercent": 0.42842,
		"totalGuests": 60,
		"guestPercent": 0.434644,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "City Winery Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 12
	},
	{
		"id": "8e2b11c",
		"email": "jmarquezaudio@gmail.com",
		"name": "Jeremy",
		"createdDate": "2018-11-25 21:50:57",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 461,
		"totalCountries": 5,
		"totalStates": 32,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 880,
		"schedulePercent": 0.446961,
		"totalGuests": 237,
		"guestPercent": 0.571712,
		"mostGuestDate": "2023-06-07 00:00:00",
		"mostGuestName": "GrÃ¶na Lund",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 26
	},
	{
		"id": "5616738",
		"email": "Dannyharveysound@gmail.com",
		"name": "Danny",
		"createdDate": "2015-08-19 15:31:45",
		"totalOrganizations": 10,
		"totalTours": 13,
		"totalDays": 354,
		"totalCountries": 13,
		"totalStates": 64,
		"totalEvents": 186,
		"eventPercent": 0.782016,
		"totalScheduleItems": 2679,
		"schedulePercent": 0.817243,
		"totalGuests": 661,
		"guestPercent": 0.742286,
		"mostGuestDate": "2023-06-07 00:00:00",
		"mostGuestName": "The Factory in Deep Ellum",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 35
	},
	{
		"id": "b371f8c",
		"email": "misty.paige@268generation.com",
		"name": "Misty",
		"createdDate": "2015-09-17 19:01:04",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 134,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 263,
		"schedulePercent": 0.187922,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "32d51ca",
		"email": "rusthook1@gmail.com",
		"name": "Rusty",
		"createdDate": "2016-08-05 20:07:05",
		"totalOrganizations": 9,
		"totalTours": 2,
		"totalDays": 39,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 231,
		"schedulePercent": 0.169779,
		"totalGuests": 66,
		"guestPercent": 0.441001,
		"mostGuestDate": "2023-10-10 00:00:00",
		"mostGuestName": "Hoyt Sherman Place",
		"mostGuestCity": "Des Moines",
		"mostGuestAmount": 24
	},
	{
		"id": "5fcda77",
		"email": "bcmanagement@gmail.com",
		"name": "Belinda",
		"createdDate": "2022-04-01 13:10:59",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 72,
		"totalCountries": 3,
		"totalStates": 17,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 45,
		"schedulePercent": 0.0507218,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5a48caa",
		"email": "nsharra@roundroomlive.com",
		"name": "Nicole",
		"createdDate": "2023-08-18 14:52:07",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 458,
		"totalCountries": 3,
		"totalStates": 45,
		"totalEvents": 215,
		"eventPercent": 0.831016,
		"totalScheduleItems": 2894,
		"schedulePercent": 0.840948,
		"totalGuests": 513,
		"guestPercent": 0.696332,
		"mostGuestDate": "2023-12-06 00:00:00",
		"mostGuestName": "Terrace Theatre",
		"mostGuestCity": "Long Beach",
		"mostGuestAmount": 73
	},
	{
		"id": "7d9f3fd",
		"email": "andrea.russo@misterxservice.it",
		"name": "ANDREA",
		"createdDate": "2022-10-02 15:27:03",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 32,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 144,
		"schedulePercent": 0.115614,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cf0b032",
		"email": "kane@mcgeemanagement.com",
		"name": "Admin",
		"createdDate": "2018-01-22 01:20:45",
		"totalOrganizations": 7,
		"totalTours": 12,
		"totalDays": 235,
		"totalCountries": 10,
		"totalStates": 47,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 1698,
		"schedulePercent": 0.664415,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5fb7d39",
		"email": "Fullofhellhc@gmail.com",
		"name": "Dylan",
		"createdDate": "2016-06-14 02:49:47",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 82,
		"totalCountries": 9,
		"totalStates": 35,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 526,
		"schedulePercent": 0.310025,
		"totalGuests": 820,
		"guestPercent": 0.780691,
		"mostGuestDate": "2023-03-21 00:00:00",
		"mostGuestName": "1720",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 67
	},
	{
		"id": "4cb87de",
		"email": "sonsoftheeastmusic@gmail.com",
		"name": "Nic",
		"createdDate": "2018-08-01 12:33:20",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 139,
		"totalCountries": 8,
		"totalStates": 33,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 534,
		"schedulePercent": 0.314793,
		"totalGuests": 226,
		"guestPercent": 0.566945,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "The Metro Theatre",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 82
	},
	{
		"id": "4635289",
		"email": "henrik@lydvaesenet.dk",
		"name": "Henrik",
		"createdDate": "2013-01-19 21:35:27",
		"totalOrganizations": 8,
		"totalTours": 3,
		"totalDays": 45,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 345,
		"schedulePercent": 0.23083,
		"totalGuests": 16,
		"guestPercent": 0.361012,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Amager Bio / BETA",
		"mostGuestCity": "KÃ¸benhavn",
		"mostGuestAmount": 6
	},
	{
		"id": "2422a22",
		"email": "tarby.ryan@gmail.com",
		"name": "Ryan",
		"createdDate": "2015-09-10 19:36:04",
		"totalOrganizations": 11,
		"totalTours": 8,
		"totalDays": 266,
		"totalCountries": 15,
		"totalStates": 62,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 2901,
		"schedulePercent": 0.84214,
		"totalGuests": 3494,
		"guestPercent": 0.977354,
		"mostGuestDate": "2023-04-21 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 156
	},
	{
		"id": "b0736b1",
		"email": "ibeugenius@gmail.com",
		"name": "Brian",
		"createdDate": "2020-02-17 07:10:20",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 47,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 213,
		"schedulePercent": 0.158125,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b71db15",
		"email": "kallie.otto@gmail.com",
		"name": "Kallie ",
		"createdDate": "2021-08-24 01:59:34",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 598,
		"totalCountries": 6,
		"totalStates": 51,
		"totalEvents": 207,
		"eventPercent": 0.817905,
		"totalScheduleItems": 3034,
		"schedulePercent": 0.854191,
		"totalGuests": 3122,
		"guestPercent": 0.971527,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "State Farm Arena",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 133
	},
	{
		"id": "01a4d57",
		"email": "info@artikalsoundsystem.com",
		"name": "Chris",
		"createdDate": "2020-01-11 00:36:16",
		"totalOrganizations": 6,
		"totalTours": 23,
		"totalDays": 1135,
		"totalCountries": 3,
		"totalStates": 41,
		"totalEvents": 291,
		"eventPercent": 0.909548,
		"totalScheduleItems": 4140,
		"schedulePercent": 0.930473,
		"totalGuests": 2047,
		"guestPercent": 0.93683,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 137
	},
	{
		"id": "9fbe8c3",
		"email": "miketomasulo716@gmail.com",
		"name": "Mike",
		"createdDate": "2019-09-18 03:09:27",
		"totalOrganizations": 14,
		"totalTours": 7,
		"totalDays": 251,
		"totalCountries": 2,
		"totalStates": 43,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 2481,
		"schedulePercent": 0.790624,
		"totalGuests": 229,
		"guestPercent": 0.568402,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "The Studio at Webster Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 54
	},
	{
		"id": "eaabdee",
		"email": "james@bullocks.co.uk",
		"name": "James",
		"createdDate": "2023-04-05 10:04:32",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 81,
		"totalCountries": 3,
		"totalStates": 24,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 571,
		"schedulePercent": 0.328036,
		"totalGuests": 104,
		"guestPercent": 0.478877,
		"mostGuestDate": "2023-07-03 00:00:00",
		"mostGuestName": "Thomas Wolfe Auditorium",
		"mostGuestCity": "Asheville",
		"mostGuestAmount": 29
	},
	{
		"id": "6cffff9",
		"email": "tyler888@gmail.com",
		"name": "Tyler ",
		"createdDate": "2012-09-25 15:43:03",
		"totalOrganizations": 16,
		"totalTours": 4,
		"totalDays": 574,
		"totalCountries": 15,
		"totalStates": 45,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 1712,
		"schedulePercent": 0.667991,
		"totalGuests": 601,
		"guestPercent": 0.726526,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 77
	},
	{
		"id": "eb5dd65",
		"email": "started.in.mono@gmail.com",
		"name": "Hector",
		"createdDate": "2018-12-27 03:29:07",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 55,
		"totalCountries": 3,
		"totalStates": 20,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 241,
		"schedulePercent": 0.1764,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9275c14",
		"email": "Broncogym@yahoo.com",
		"name": "Rene",
		"createdDate": "2021-05-01 06:34:22",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 634,
		"totalCountries": 4,
		"totalStates": 49,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 628,
		"schedulePercent": 0.353331,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "161c7aa",
		"email": "geronrains@gmail.com",
		"name": "Geron",
		"createdDate": "2014-05-26 21:10:24",
		"totalOrganizations": 10,
		"totalTours": 13,
		"totalDays": 624,
		"totalCountries": 11,
		"totalStates": 66,
		"totalEvents": 251,
		"eventPercent": 0.873792,
		"totalScheduleItems": 4995,
		"schedulePercent": 0.961462,
		"totalGuests": 3789,
		"guestPercent": 0.981327,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 171
	},
	{
		"id": "5fe6c2b",
		"email": "tourmanfad@me.com",
		"name": "Ian",
		"createdDate": "2013-09-26 10:35:04",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 102,
		"totalCountries": 4,
		"totalStates": 29,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 480,
		"schedulePercent": 0.288968,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "85b6f8e",
		"email": "df@5by5.ca",
		"name": "Dean",
		"createdDate": "2010-05-02 01:49:50",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 250,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 137,
		"schedulePercent": 0.111244,
		"totalGuests": 16,
		"guestPercent": 0.361012,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Paterson Park",
		"mostGuestCity": "Delta",
		"mostGuestAmount": 16
	},
	{
		"id": "f8861bd",
		"email": "iamtammichang@gmail.com",
		"name": "Tammi",
		"createdDate": "2022-07-12 10:01:02",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 298,
		"totalCountries": 6,
		"totalStates": 16,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 405,
		"schedulePercent": 0.259303,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3ebd9bb",
		"email": "jessicam@vstarentertainment.com",
		"name": "Jess",
		"createdDate": "2023-08-21 14:42:40",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 127,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 471,
		"schedulePercent": 0.28473,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b63fc8b",
		"email": "chelsea.boisen@gmail.com",
		"name": "Chelsea",
		"createdDate": "2014-03-13 21:46:44",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 499,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 2435,
		"schedulePercent": 0.784664,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Stubbs BBQ",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 1
	},
	{
		"id": "1939c8b",
		"email": "stefan@unitedvision.se",
		"name": "Stefan",
		"createdDate": "2022-04-13 11:16:06",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 225,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 342,
		"schedulePercent": 0.228976,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5fa2d6e",
		"email": "kellan.audio@gmail.com",
		"name": "Kellan",
		"createdDate": "2019-07-08 06:04:13",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 220,
		"totalCountries": 6,
		"totalStates": 17,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 1088,
		"schedulePercent": 0.509469,
		"totalGuests": 897,
		"guestPercent": 0.799232,
		"mostGuestDate": "2023-11-05 00:00:00",
		"mostGuestName": "The Concert Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 112
	},
	{
		"id": "2eb7cf9",
		"email": "kaitlyn.chen47@gmail.com",
		"name": "Kaitlyn",
		"createdDate": "2022-04-04 21:12:59",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 262,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 927,
		"schedulePercent": 0.45835,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1848792",
		"email": "sean@baselinemusic.ca",
		"name": "Sean ",
		"createdDate": "2016-08-17 16:48:38",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 181,
		"totalCountries": 5,
		"totalStates": 18,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 428,
		"schedulePercent": 0.267117,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e1cfe7b",
		"email": "timothyawaite@gmail.com",
		"name": "Timothy",
		"createdDate": "2014-08-21 04:28:11",
		"totalOrganizations": 10,
		"totalTours": 5,
		"totalDays": 886,
		"totalCountries": 4,
		"totalStates": 37,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 1372,
		"schedulePercent": 0.585883,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "053002c",
		"email": "erinkinseymusic@gmail.com",
		"name": "Erin ",
		"createdDate": "2022-06-14 21:33:21",
		"totalOrganizations": 2,
		"totalTours": 8,
		"totalDays": 737,
		"totalCountries": 6,
		"totalStates": 46,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1686,
		"schedulePercent": 0.661237,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Concerthouse",
		"mostGuestCity": "GÃ¶teborg",
		"mostGuestAmount": 2
	},
	{
		"id": "f00b1d3",
		"email": "alfiepalmer12@icloud.com",
		"name": "Alfie ",
		"createdDate": "2019-02-21 13:08:37",
		"totalOrganizations": 20,
		"totalTours": 17,
		"totalDays": 518,
		"totalCountries": 8,
		"totalStates": 25,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 1871,
		"schedulePercent": 0.69951,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "484a43a",
		"email": "urban@urbanproduction.se",
		"name": "Urban",
		"createdDate": "2013-10-06 16:55:55",
		"totalOrganizations": 13,
		"totalTours": 3,
		"totalDays": 140,
		"totalCountries": 2,
		"totalStates": 12,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 1177,
		"schedulePercent": 0.533704,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "10455e1",
		"email": "josh@luckymanmgmt.com",
		"name": "Josh",
		"createdDate": "2015-10-05 01:53:16",
		"totalOrganizations": 13,
		"totalTours": 29,
		"totalDays": 620,
		"totalCountries": 13,
		"totalStates": 57,
		"totalEvents": 246,
		"eventPercent": 0.868759,
		"totalScheduleItems": 2631,
		"schedulePercent": 0.811945,
		"totalGuests": 47,
		"guestPercent": 0.417031,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Cervantes' Masterpiece Ballroom & Cervantes' Other Side",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 21
	},
	{
		"id": "87cf00f",
		"email": "wattaudio@hotmail.com",
		"name": "Marcos",
		"createdDate": "2014-09-27 23:41:15",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 134,
		"totalCountries": 8,
		"totalStates": 27,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 406,
		"schedulePercent": 0.259436,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2b80d47",
		"email": "ianborak@gmail.com",
		"name": "Ian",
		"createdDate": "2017-07-24 13:07:58",
		"totalOrganizations": 10,
		"totalTours": 9,
		"totalDays": 550,
		"totalCountries": 18,
		"totalStates": 68,
		"totalEvents": 251,
		"eventPercent": 0.873792,
		"totalScheduleItems": 3675,
		"schedulePercent": 0.905708,
		"totalGuests": 1628,
		"guestPercent": 0.90584,
		"mostGuestDate": "2023-08-13 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 125
	},
	{
		"id": "fe928f4",
		"email": "tim@giantexpression.com",
		"name": "Tim",
		"createdDate": "2019-07-17 16:11:13",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 161,
		"totalCountries": 5,
		"totalStates": 31,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 950,
		"schedulePercent": 0.465369,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "682afc7",
		"email": "info@major-movez.de",
		"name": "Farhang",
		"createdDate": "2023-08-21 15:17:29",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 119,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 231,
		"schedulePercent": 0.169779,
		"totalGuests": 346,
		"guestPercent": 0.626672,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Mercedes-Benz Arena Berlin",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 335
	},
	{
		"id": "4c130dc",
		"email": "carine.abraham7@gmail.com",
		"name": "Carine",
		"createdDate": "2023-02-24 22:15:38",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 294,
		"totalCountries": 3,
		"totalStates": 21,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 556,
		"schedulePercent": 0.32446,
		"totalGuests": 404,
		"guestPercent": 0.655013,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Central Park SummerStage",
		"mostGuestCity": "New York",
		"mostGuestAmount": 74
	},
	{
		"id": "f27aac8",
		"email": "mpricewtm@gmail.com",
		"name": "Matt",
		"createdDate": "2014-05-06 22:12:56",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 469,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 446,
		"schedulePercent": 0.27493,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "New Lenox Commons",
		"mostGuestCity": "New Lenox",
		"mostGuestAmount": 4
	},
	{
		"id": "fa70b8f",
		"email": "taurean@migomgmt.com",
		"name": "Taurean",
		"createdDate": "2018-07-11 18:36:45",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 373,
		"totalCountries": 6,
		"totalStates": 15,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 37,
		"schedulePercent": 0.0446298,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9a9e1de",
		"email": "hi@sadiejean.com",
		"name": "James",
		"createdDate": "2023-02-06 19:18:20",
		"totalOrganizations": 2,
		"totalTours": 7,
		"totalDays": 181,
		"totalCountries": 9,
		"totalStates": 24,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 798,
		"schedulePercent": 0.419547,
		"totalGuests": 251,
		"guestPercent": 0.579393,
		"mostGuestDate": "2023-03-20 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 69
	},
	{
		"id": "b8a1343",
		"email": "thedirtynil@gmail.com",
		"name": "The Dirty Nil",
		"createdDate": "2021-10-07 03:36:19",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 77,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 463,
		"schedulePercent": 0.282082,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "23bc196",
		"email": "roadmanager@sidewalkprophets.com",
		"name": "Ben",
		"createdDate": "2011-10-21 15:03:21",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 658,
		"totalCountries": 1,
		"totalStates": 15,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 1832,
		"schedulePercent": 0.692094,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f5e8d94",
		"email": "samueldemey@hotmail.be",
		"name": "Samuel",
		"createdDate": "2018-07-06 15:11:30",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 211,
		"totalCountries": 6,
		"totalStates": 31,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1400,
		"schedulePercent": 0.59118,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ec5967b",
		"email": "EJbernas@me.com",
		"name": "EJ",
		"createdDate": "2022-01-12 17:52:59",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 649,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 417,
		"schedulePercent": 0.263674,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-03-23 00:00:00",
		"mostGuestName": "EMP REHEARSAL",
		"mostGuestCity": "Arlington",
		"mostGuestAmount": 5
	},
	{
		"id": "a70497c",
		"email": "maxbaldwin26@gmail.com",
		"name": "Max",
		"createdDate": "2019-05-22 06:16:22",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 543,
		"totalCountries": 14,
		"totalStates": 63,
		"totalEvents": 187,
		"eventPercent": 0.784135,
		"totalScheduleItems": 1854,
		"schedulePercent": 0.695669,
		"totalGuests": 766,
		"guestPercent": 0.768243,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Humphreys Concerts By The Bay",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 42
	},
	{
		"id": "a79601f",
		"email": "sgholm@gmail.com",
		"name": "Samuel",
		"createdDate": "2018-05-20 17:45:46",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 625,
		"totalCountries": 11,
		"totalStates": 39,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 3987,
		"schedulePercent": 0.922659,
		"totalGuests": 4785,
		"guestPercent": 0.99073,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 240
	},
	{
		"id": "6dad616",
		"email": "luchoclivio@gmail.com",
		"name": "Luciano",
		"createdDate": "2022-07-27 09:44:09",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 347,
		"totalCountries": 5,
		"totalStates": 25,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 673,
		"schedulePercent": 0.369885,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3813121",
		"email": "jerrywongmusic@gmail.com",
		"name": "Jerry",
		"createdDate": "2019-07-13 16:02:16",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 60,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "67ac7b0",
		"email": "taylortouring@gmail.com",
		"name": "Taylor",
		"createdDate": "2015-11-10 05:20:22",
		"totalOrganizations": 10,
		"totalTours": 1,
		"totalDays": 214,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 385,
		"schedulePercent": 0.251093,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "db391e0",
		"email": "justin@justinwysong.com",
		"name": "Justin",
		"createdDate": "2013-08-02 17:09:36",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 256,
		"totalCountries": 10,
		"totalStates": 40,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 3091,
		"schedulePercent": 0.860946,
		"totalGuests": 1505,
		"guestPercent": 0.894716,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 98
	},
	{
		"id": "1feea10",
		"email": "justinehoutx@gmail.com",
		"name": "Justine",
		"createdDate": "2019-06-08 15:34:58",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 156,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 959,
		"schedulePercent": 0.46868,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9e06146",
		"email": "cade@piasongs.com",
		"name": "Cade",
		"createdDate": "2021-04-19 21:07:24",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 176,
		"totalCountries": 1,
		"totalStates": 42,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 826,
		"schedulePercent": 0.42842,
		"totalGuests": 29,
		"guestPercent": 0.387896,
		"mostGuestDate": "2023-06-02 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 14
	},
	{
		"id": "5971715",
		"email": "hello@skentertainment.co",
		"name": "SK",
		"createdDate": "2022-09-07 12:13:17",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 128,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 1124,
		"schedulePercent": 0.517282,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "045167c",
		"email": "Suzigreen@me.com",
		"name": "Suzi",
		"createdDate": "2016-03-28 11:35:22",
		"totalOrganizations": 10,
		"totalTours": 12,
		"totalDays": 414,
		"totalCountries": 15,
		"totalStates": 24,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 2327,
		"schedulePercent": 0.770097,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-07-31 00:00:00",
		"mostGuestName": "Rough Trade East",
		"mostGuestCity": "London",
		"mostGuestAmount": 2
	},
	{
		"id": "dd88ebe",
		"email": "Ski@ski.productions",
		"name": "Eric",
		"createdDate": "2021-06-21 22:40:44",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 260,
		"totalCountries": 9,
		"totalStates": 36,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 1652,
		"schedulePercent": 0.653821,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "db40f66",
		"email": "olliemsilva@gmail.com",
		"name": "Olivia",
		"createdDate": "2023-02-22 14:33:23",
		"totalOrganizations": 6,
		"totalTours": 14,
		"totalDays": 477,
		"totalCountries": 9,
		"totalStates": 43,
		"totalEvents": 191,
		"eventPercent": 0.790491,
		"totalScheduleItems": 2242,
		"schedulePercent": 0.756721,
		"totalGuests": 1180,
		"guestPercent": 0.854059,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "Rabbit Rabbit",
		"mostGuestCity": "Asheville",
		"mostGuestAmount": 89
	},
	{
		"id": "595c09c",
		"email": "simon.jackson777@gmail.com",
		"name": "Simon",
		"createdDate": "2021-10-01 10:58:33",
		"totalOrganizations": 5,
		"totalTours": 22,
		"totalDays": 394,
		"totalCountries": 24,
		"totalStates": 69,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 2216,
		"schedulePercent": 0.753013,
		"totalGuests": 647,
		"guestPercent": 0.738445,
		"mostGuestDate": "2023-06-09 00:00:00",
		"mostGuestName": "Irving Plaza",
		"mostGuestCity": "New York",
		"mostGuestAmount": 50
	},
	{
		"id": "037f5ff",
		"email": "trevor@tourmusiclive.com",
		"name": "Trevor",
		"createdDate": "2016-06-17 01:31:30",
		"totalOrganizations": 26,
		"totalTours": 43,
		"totalDays": 421,
		"totalCountries": 12,
		"totalStates": 50,
		"totalEvents": 182,
		"eventPercent": 0.773408,
		"totalScheduleItems": 2330,
		"schedulePercent": 0.770891,
		"totalGuests": 1468,
		"guestPercent": 0.890213,
		"mostGuestDate": "2023-05-07 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 75
	},
	{
		"id": "01d5c17",
		"email": "Toricachia@gmail.com",
		"name": "Tori ",
		"createdDate": "2022-02-06 21:22:59",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 1116,
		"totalCountries": 14,
		"totalStates": 44,
		"totalEvents": 186,
		"eventPercent": 0.782016,
		"totalScheduleItems": 2335,
		"schedulePercent": 0.771686,
		"totalGuests": 1583,
		"guestPercent": 0.901735,
		"mostGuestDate": "2023-06-02 00:00:00",
		"mostGuestName": "Avant Gardner",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 133
	},
	{
		"id": "40ba53a",
		"email": "showmix@mac.com",
		"name": "Scott",
		"createdDate": "2009-08-20 14:28:33",
		"totalOrganizations": 11,
		"totalTours": 6,
		"totalDays": 207,
		"totalCountries": 5,
		"totalStates": 22,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 769,
		"schedulePercent": 0.408555,
		"totalGuests": 57,
		"guestPercent": 0.430804,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "Whisky a Go Go",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 12
	},
	{
		"id": "451775d",
		"email": "pmcgill3@gmail.com",
		"name": "Philip",
		"createdDate": "2019-01-15 07:15:41",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 463,
		"totalCountries": 5,
		"totalStates": 30,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 796,
		"schedulePercent": 0.41915,
		"totalGuests": 361,
		"guestPercent": 0.635413,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 64
	},
	{
		"id": "6730b8e",
		"email": "marcrebillet@gmail.com",
		"name": "Marc",
		"createdDate": "2019-07-11 22:07:02",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 337,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 432,
		"schedulePercent": 0.269103,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "971c47c",
		"email": "melissa.tmfoh@gmail.com",
		"name": "Melissa",
		"createdDate": "2021-11-10 20:28:54",
		"totalOrganizations": 9,
		"totalTours": 17,
		"totalDays": 475,
		"totalCountries": 12,
		"totalStates": 62,
		"totalEvents": 194,
		"eventPercent": 0.794597,
		"totalScheduleItems": 1859,
		"schedulePercent": 0.697259,
		"totalGuests": 298,
		"guestPercent": 0.60098,
		"mostGuestDate": "2023-09-29 00:00:00",
		"mostGuestName": "The Moroccan Lounge",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 43
	},
	{
		"id": "bab23c4",
		"email": "tylerirhodes13@gmail.com",
		"name": "Tyler",
		"createdDate": "2019-04-30 23:01:04",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 1212,
		"totalCountries": 7,
		"totalStates": 56,
		"totalEvents": 278,
		"eventPercent": 0.900278,
		"totalScheduleItems": 5985,
		"schedulePercent": 0.980268,
		"totalGuests": 451,
		"guestPercent": 0.674348,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "Thompson Boling Arena",
		"mostGuestCity": "Knoxville",
		"mostGuestAmount": 47
	},
	{
		"id": "333be65",
		"email": "elodie@riverjuke.co.uk",
		"name": "Elodie",
		"createdDate": "2022-06-03 08:32:16",
		"totalOrganizations": 20,
		"totalTours": 29,
		"totalDays": 2001,
		"totalCountries": 25,
		"totalStates": 88,
		"totalEvents": 570,
		"eventPercent": 0.98093,
		"totalScheduleItems": 9801,
		"schedulePercent": 0.996822,
		"totalGuests": 3637,
		"guestPercent": 0.979208,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "The SSE Arena, Wembley",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 226
	},
	{
		"id": "ca8439f",
		"email": "jack.summers@philabs.xyz",
		"name": "Email",
		"createdDate": "2022-12-12 16:21:25",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 34,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 3,
		"eventPercent": 0.0202622,
		"totalScheduleItems": 176,
		"schedulePercent": 0.136008,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-03-01 00:00:00",
		"mostGuestName": "Layer 2 Day",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 15
	},
	{
		"id": "08627e6",
		"email": "danifriedman@icloud.com",
		"name": "Dani",
		"createdDate": "2023-08-01 15:09:10",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 53,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 270,
		"schedulePercent": 0.19216,
		"totalGuests": 59,
		"guestPercent": 0.433982,
		"mostGuestDate": "2023-08-23 00:00:00",
		"mostGuestName": "House of Blues Anaheim",
		"mostGuestCity": "Anaheim",
		"mostGuestAmount": 47
	},
	{
		"id": "a6cb9d5",
		"email": "mayajdottin@gmail.com",
		"name": "Maya",
		"createdDate": "2023-03-30 19:05:16",
		"totalOrganizations": 6,
		"totalTours": 12,
		"totalDays": 448,
		"totalCountries": 12,
		"totalStates": 41,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 2164,
		"schedulePercent": 0.744802,
		"totalGuests": 611,
		"guestPercent": 0.729043,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "House of Blues Anaheim",
		"mostGuestCity": "Anaheim",
		"mostGuestAmount": 74
	},
	{
		"id": "218d9cc",
		"email": "Sean@qprime.com",
		"name": "Sean",
		"createdDate": "2017-08-14 22:05:07",
		"totalOrganizations": 24,
		"totalTours": 53,
		"totalDays": 1504,
		"totalCountries": 18,
		"totalStates": 97,
		"totalEvents": 612,
		"eventPercent": 0.983181,
		"totalScheduleItems": 7720,
		"schedulePercent": 0.992319,
		"totalGuests": 4749,
		"guestPercent": 0.989935,
		"mostGuestDate": "2023-10-02 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 405
	},
	{
		"id": "950ff87",
		"email": "marchjody@gmail.com",
		"name": "Jody",
		"createdDate": "2021-08-09 19:49:58",
		"totalOrganizations": 10,
		"totalTours": 35,
		"totalDays": 740,
		"totalCountries": 14,
		"totalStates": 62,
		"totalEvents": 358,
		"eventPercent": 0.945305,
		"totalScheduleItems": 4798,
		"schedulePercent": 0.954973,
		"totalGuests": 2636,
		"guestPercent": 0.956959,
		"mostGuestDate": "2023-09-06 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 66
	},
	{
		"id": "93149b4",
		"email": "guillaume@highwayholidays.eu",
		"name": "Guillaume",
		"createdDate": "2022-02-21 08:59:32",
		"totalOrganizations": 10,
		"totalTours": 21,
		"totalDays": 691,
		"totalCountries": 18,
		"totalStates": 64,
		"totalEvents": 221,
		"eventPercent": 0.83777,
		"totalScheduleItems": 2587,
		"schedulePercent": 0.804264,
		"totalGuests": 612,
		"guestPercent": 0.72944,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 50
	},
	{
		"id": "0f4a738",
		"email": "chezstock@gmail.com",
		"name": "Chez",
		"createdDate": "2011-06-06 23:37:33",
		"totalOrganizations": 20,
		"totalTours": 10,
		"totalDays": 229,
		"totalCountries": 7,
		"totalStates": 36,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 1403,
		"schedulePercent": 0.592107,
		"totalGuests": 527,
		"guestPercent": 0.701232,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 207
	},
	{
		"id": "5d02a29",
		"email": "Doug@GunRoomMedia.com",
		"name": "Doug",
		"createdDate": "2009-12-10 14:26:45",
		"totalOrganizations": 16,
		"totalTours": 12,
		"totalDays": 425,
		"totalCountries": 7,
		"totalStates": 14,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 1493,
		"schedulePercent": 0.616342,
		"totalGuests": 715,
		"guestPercent": 0.756191,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "Motorpoint Arena Nottingham",
		"mostGuestCity": "Nottingham",
		"mostGuestAmount": 58
	},
	{
		"id": "907c547",
		"email": "tanner@tannerkinney.com",
		"name": "Tanner",
		"createdDate": "2021-06-14 17:12:59",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 183,
		"totalCountries": 4,
		"totalStates": 37,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 507,
		"schedulePercent": 0.302741,
		"totalGuests": 93,
		"guestPercent": 0.468017,
		"mostGuestDate": "2023-03-21 00:00:00",
		"mostGuestName": "The Metro Theatre",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 20
	},
	{
		"id": "1b49d0e",
		"email": "hartmannkristof@gmail.com",
		"name": "Kristof",
		"createdDate": "2015-01-02 04:01:21",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 288,
		"totalCountries": 21,
		"totalStates": 60,
		"totalEvents": 195,
		"eventPercent": 0.796318,
		"totalScheduleItems": 2783,
		"schedulePercent": 0.830089,
		"totalGuests": 558,
		"guestPercent": 0.713151,
		"mostGuestDate": "2023-04-02 00:00:00",
		"mostGuestName": "Slaktkyrkan",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 42
	},
	{
		"id": "96c2a76",
		"email": "alohadanhennessy@gmail.com",
		"name": "Daniel",
		"createdDate": "2022-03-31 05:20:08",
		"totalOrganizations": 35,
		"totalTours": 10,
		"totalDays": 174,
		"totalCountries": 5,
		"totalStates": 12,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 1102,
		"schedulePercent": 0.511853,
		"totalGuests": 253,
		"guestPercent": 0.580718,
		"mostGuestDate": "2023-11-21 00:00:00",
		"mostGuestName": "Forum Melbourne",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 69
	},
	{
		"id": "0072f71",
		"email": "bahackett@gmail.com",
		"name": "Ben ",
		"createdDate": "2018-09-11 21:03:04",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 47,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 237,
		"schedulePercent": 0.173355,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Marquis Theater",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 4
	},
	{
		"id": "46613f8",
		"email": "Jessicacrans@gmail.com",
		"name": "Jessica",
		"createdDate": "2016-01-06 23:46:59",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 140,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 558,
		"schedulePercent": 0.32499,
		"totalGuests": 146,
		"guestPercent": 0.510528,
		"mostGuestDate": "2023-09-29 00:00:00",
		"mostGuestName": "Ruoff Home Mortgage Music Center",
		"mostGuestCity": "Noblesville",
		"mostGuestAmount": 16
	},
	{
		"id": "6c339ca",
		"email": "scott@portugaltheman.com",
		"name": "Ian",
		"createdDate": "2014-04-23 13:30:23",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 96,
		"totalCountries": 3,
		"totalStates": 18,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 628,
		"schedulePercent": 0.353331,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "00d8ada",
		"email": "soliddtodd@gmail.com",
		"name": "Todd",
		"createdDate": "2014-04-16 21:49:42",
		"totalOrganizations": 10,
		"totalTours": 8,
		"totalDays": 125,
		"totalCountries": 1,
		"totalStates": 23,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 509,
		"schedulePercent": 0.303536,
		"totalGuests": 473,
		"guestPercent": 0.682294,
		"mostGuestDate": "2023-09-27 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 80
	},
	{
		"id": "b2ddc38",
		"email": "tom.roelofs@gmail.com",
		"name": "Thomas",
		"createdDate": "2018-09-10 22:19:31",
		"totalOrganizations": 8,
		"totalTours": 12,
		"totalDays": 208,
		"totalCountries": 20,
		"totalStates": 48,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 1146,
		"schedulePercent": 0.525493,
		"totalGuests": 314,
		"guestPercent": 0.61131,
		"mostGuestDate": "2023-06-09 00:00:00",
		"mostGuestName": "Lafayette",
		"mostGuestCity": "London",
		"mostGuestAmount": 43
	},
	{
		"id": "4a0fca0",
		"email": "Matt@mattgillmusic.com",
		"name": "Matthew",
		"createdDate": "2013-07-08 19:49:08",
		"totalOrganizations": 18,
		"totalTours": 5,
		"totalDays": 194,
		"totalCountries": 4,
		"totalStates": 12,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 263,
		"schedulePercent": 0.187922,
		"totalGuests": 39,
		"guestPercent": 0.404185,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Under the â€˜Kâ€™ Bridge Park",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 19
	},
	{
		"id": "a191bfa",
		"email": "sweeney@yoursandowls.com.au",
		"name": "Anthony",
		"createdDate": "2018-02-27 00:46:46",
		"totalOrganizations": 12,
		"totalTours": 58,
		"totalDays": 553,
		"totalCountries": 14,
		"totalStates": 51,
		"totalEvents": 206,
		"eventPercent": 0.816183,
		"totalScheduleItems": 1836,
		"schedulePercent": 0.693021,
		"totalGuests": 95,
		"guestPercent": 0.470269,
		"mostGuestDate": "2023-02-04 00:00:00",
		"mostGuestName": "The Echo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 25
	},
	{
		"id": "99338ff",
		"email": "selena@selenarox.com",
		"name": "Selena",
		"createdDate": "2015-03-21 22:21:47",
		"totalOrganizations": 6,
		"totalTours": 26,
		"totalDays": 743,
		"totalCountries": 16,
		"totalStates": 65,
		"totalEvents": 207,
		"eventPercent": 0.817905,
		"totalScheduleItems": 2957,
		"schedulePercent": 0.8481,
		"totalGuests": 1090,
		"guestPercent": 0.837373,
		"mostGuestDate": "2023-07-10 00:00:00",
		"mostGuestName": "Somerset House",
		"mostGuestCity": "London",
		"mostGuestAmount": 121
	},
	{
		"id": "3eff89f",
		"email": "kristofferhinrichsen@me.com",
		"name": "Kristoffer",
		"createdDate": "2016-10-06 12:46:27",
		"totalOrganizations": 8,
		"totalTours": 3,
		"totalDays": 345,
		"totalCountries": 6,
		"totalStates": 14,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 1592,
		"schedulePercent": 0.640313,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d3fa67a",
		"email": "mariesha6@gmail.com",
		"name": "Mariesha ",
		"createdDate": "2022-06-15 21:51:53",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 376,
		"totalCountries": 5,
		"totalStates": 26,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 2354,
		"schedulePercent": 0.774467,
		"totalGuests": 735,
		"guestPercent": 0.760429,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "Palacio de los Deportes",
		"mostGuestCity": "Ciudad de MÃ©xico",
		"mostGuestAmount": 79
	},
	{
		"id": "8558d75",
		"email": "miguel@charco.biz",
		"name": "Miguel ",
		"createdDate": "2018-11-03 14:29:09",
		"totalOrganizations": 37,
		"totalTours": 23,
		"totalDays": 805,
		"totalCountries": 9,
		"totalStates": 42,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 1345,
		"schedulePercent": 0.579129,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-04-22 00:00:00",
		"mostGuestName": "PARIS 15",
		"mostGuestCity": "MÃ¡laga",
		"mostGuestAmount": 4
	},
	{
		"id": "f87cfc5",
		"email": "clincoln1@gmail.com",
		"name": "Christian",
		"createdDate": "2014-01-13 21:16:24",
		"totalOrganizations": 8,
		"totalTours": 14,
		"totalDays": 366,
		"totalCountries": 21,
		"totalStates": 67,
		"totalEvents": 154,
		"eventPercent": 0.71368,
		"totalScheduleItems": 2320,
		"schedulePercent": 0.768772,
		"totalGuests": 1037,
		"guestPercent": 0.830486,
		"mostGuestDate": "2023-07-10 00:00:00",
		"mostGuestName": "Somerset House",
		"mostGuestCity": "London",
		"mostGuestAmount": 121
	},
	{
		"id": "7379600",
		"email": "joelmartinaudio@gmail.com",
		"name": "Joel",
		"createdDate": "2015-10-01 20:38:28",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 980,
		"totalCountries": 3,
		"totalStates": 37,
		"totalEvents": 279,
		"eventPercent": 0.901205,
		"totalScheduleItems": 3685,
		"schedulePercent": 0.906502,
		"totalGuests": 1408,
		"guestPercent": 0.883592,
		"mostGuestDate": "2023-11-21 00:00:00",
		"mostGuestName": "Wildhorse Saloon",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 283
	},
	{
		"id": "aaa1b0a",
		"email": "juanlove.jlp@gmail.com",
		"name": "Juan",
		"createdDate": "2018-06-15 20:03:20",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 285,
		"totalCountries": 1,
		"totalStates": 31,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 1434,
		"schedulePercent": 0.60045,
		"totalGuests": 1500,
		"guestPercent": 0.894319,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 137
	},
	{
		"id": "87ea0bb",
		"email": "mattfloydtouring@gmail.com",
		"name": "Matt",
		"createdDate": "2016-07-13 20:37:25",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 416,
		"totalCountries": 10,
		"totalStates": 49,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 2126,
		"schedulePercent": 0.73871,
		"totalGuests": 360,
		"guestPercent": 0.635015,
		"mostGuestDate": "2023-08-13 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 43
	},
	{
		"id": "856fd4b",
		"email": "cristela@sabapivot.com",
		"name": "Cristela",
		"createdDate": "2022-03-31 05:44:53",
		"totalOrganizations": 3,
		"totalTours": 9,
		"totalDays": 45,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 177,
		"schedulePercent": 0.136936,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c94e94b",
		"email": "jendrik@travtours.com",
		"name": "Jendrik",
		"createdDate": "2022-11-29 16:48:57",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 72,
		"totalCountries": 6,
		"totalStates": 7,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 685,
		"schedulePercent": 0.37505,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f9205fd",
		"email": "hannamehmet@gmail.com",
		"name": "Hanna",
		"createdDate": "2023-05-25 13:13:59",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 55,
		"totalCountries": 6,
		"totalStates": 9,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 267,
		"schedulePercent": 0.190571,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "Blue Shell",
		"mostGuestCity": "KÃ¶ln",
		"mostGuestAmount": 3
	},
	{
		"id": "deb33ad",
		"email": "jbenton78@gmail.com",
		"name": "Josh",
		"createdDate": "2015-03-23 20:08:09",
		"totalOrganizations": 15,
		"totalTours": 16,
		"totalDays": 1118,
		"totalCountries": 14,
		"totalStates": 66,
		"totalEvents": 273,
		"eventPercent": 0.896305,
		"totalScheduleItems": 3613,
		"schedulePercent": 0.901867,
		"totalGuests": 1600,
		"guestPercent": 0.902927,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 300
	},
	{
		"id": "57ebcf0",
		"email": "rwelchman@outlook.com",
		"name": "Rhys",
		"createdDate": "2021-06-07 15:18:41",
		"totalOrganizations": 7,
		"totalTours": 20,
		"totalDays": 668,
		"totalCountries": 12,
		"totalStates": 61,
		"totalEvents": 255,
		"eventPercent": 0.880943,
		"totalScheduleItems": 3851,
		"schedulePercent": 0.915375,
		"totalGuests": 32,
		"guestPercent": 0.392531,
		"mostGuestDate": "2023-07-01 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 20
	},
	{
		"id": "3e47f31",
		"email": "jlipschutz@me.com",
		"name": "Jay",
		"createdDate": "2013-06-13 18:22:18",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 527,
		"totalCountries": 2,
		"totalStates": 45,
		"totalEvents": 221,
		"eventPercent": 0.83777,
		"totalScheduleItems": 1527,
		"schedulePercent": 0.624288,
		"totalGuests": 153,
		"guestPercent": 0.515958,
		"mostGuestDate": "2023-02-24 00:00:00",
		"mostGuestName": "Gila River Resorts & Casinos - Wild Horse Pass",
		"mostGuestCity": "Chandler",
		"mostGuestAmount": 36
	},
	{
		"id": "7281b07",
		"email": "connorhoustonlive@gmail.com",
		"name": "Connor",
		"createdDate": "2018-07-09 10:16:30",
		"totalOrganizations": 17,
		"totalTours": 19,
		"totalDays": 1246,
		"totalCountries": 22,
		"totalStates": 68,
		"totalEvents": 259,
		"eventPercent": 0.884783,
		"totalScheduleItems": 4683,
		"schedulePercent": 0.951265,
		"totalGuests": 1282,
		"guestPercent": 0.869156,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "Somerset House",
		"mostGuestCity": "London",
		"mostGuestAmount": 97
	},
	{
		"id": "b774627",
		"email": "malin.virta@lifeline.se",
		"name": "Malin",
		"createdDate": "2021-08-02 14:06:30",
		"totalOrganizations": 11,
		"totalTours": 2,
		"totalDays": 73,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 330,
		"schedulePercent": 0.224209,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6679b77",
		"email": "joe@riverjuke.co.uk",
		"name": "Joseph",
		"createdDate": "2019-03-02 11:12:30",
		"totalOrganizations": 49,
		"totalTours": 48,
		"totalDays": 2997,
		"totalCountries": 28,
		"totalStates": 109,
		"totalEvents": 794,
		"eventPercent": 0.987816,
		"totalScheduleItems": 13532,
		"schedulePercent": 0.999073,
		"totalGuests": 4219,
		"guestPercent": 0.985432,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "The SSE Arena, Wembley",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 226
	},
	{
		"id": "d3af37d",
		"email": "rcpmtm@gmail.com",
		"name": "Steve",
		"createdDate": "2023-05-31 20:43:18",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 46,
		"totalCountries": 3,
		"totalStates": 7,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 193,
		"schedulePercent": 0.145941,
		"totalGuests": 38,
		"guestPercent": 0.402198,
		"mostGuestDate": "2023-09-22 00:00:00",
		"mostGuestName": "Belly Up",
		"mostGuestCity": "Solana Beach",
		"mostGuestAmount": 14
	},
	{
		"id": "99b2fb8",
		"email": "post@nttas.com",
		"name": "Michael",
		"createdDate": "2019-04-02 11:15:27",
		"totalOrganizations": 6,
		"totalTours": 11,
		"totalDays": 755,
		"totalCountries": 11,
		"totalStates": 30,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 927,
		"schedulePercent": 0.45835,
		"totalGuests": 1030,
		"guestPercent": 0.829162,
		"mostGuestDate": "2023-05-07 00:00:00",
		"mostGuestName": "O2 Academy Bristol",
		"mostGuestCity": "Bristol",
		"mostGuestAmount": 132
	},
	{
		"id": "ef852a7",
		"email": "gary@thesnuts.co.uk",
		"name": "Gary",
		"createdDate": "2019-06-20 22:04:02",
		"totalOrganizations": 3,
		"totalTours": 10,
		"totalDays": 93,
		"totalCountries": 3,
		"totalStates": 23,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 721,
		"schedulePercent": 0.38869,
		"totalGuests": 126,
		"guestPercent": 0.495564,
		"mostGuestDate": "2023-02-05 00:00:00",
		"mostGuestName": "Stereo",
		"mostGuestCity": "Glasgow",
		"mostGuestAmount": 28
	},
	{
		"id": "4c93ca2",
		"email": "h.schmidbauer@msk-live.de",
		"name": "Helen",
		"createdDate": "2023-05-26 07:01:22",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 332,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 1540,
		"schedulePercent": 0.627599,
		"totalGuests": 146,
		"guestPercent": 0.510528,
		"mostGuestDate": "2023-11-27 00:00:00",
		"mostGuestName": "Friedrichstadt-Palast",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 30
	},
	{
		"id": "615e3f4",
		"email": "nickbeii@icloud.com",
		"name": "Nick",
		"createdDate": "2023-07-18 16:12:56",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 69,
		"totalCountries": 13,
		"totalStates": 25,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 749,
		"schedulePercent": 0.401404,
		"totalGuests": 325,
		"guestPercent": 0.61674,
		"mostGuestDate": "2023-07-31 00:00:00",
		"mostGuestName": "Annexet",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 51
	},
	{
		"id": "945afd8",
		"email": "jsierraalta@gmail.com",
		"name": "Jesus",
		"createdDate": "2012-10-24 07:39:47",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 300,
		"totalCountries": 11,
		"totalStates": 48,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 1159,
		"schedulePercent": 0.529201,
		"totalGuests": 73,
		"guestPercent": 0.447755,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "Auditorio Marina Sur",
		"mostGuestCity": "Valencia",
		"mostGuestAmount": 31
	},
	{
		"id": "a2f9eb7",
		"email": "office@dancecode.fr",
		"name": "William",
		"createdDate": "2023-09-21 13:41:16",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 32,
		"totalCountries": 3,
		"totalStates": 9,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 225,
		"schedulePercent": 0.1666,
		"totalGuests": 11,
		"guestPercent": 0.345782,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Parc des Expositions de Bordeaux",
		"mostGuestCity": "Bordeaux",
		"mostGuestAmount": 11
	},
	{
		"id": "ad7e6fd",
		"email": "david@djongroup.com",
		"name": "David",
		"createdDate": "2022-11-27 02:39:15",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 99,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 119,
		"schedulePercent": 0.102106,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "911fbce",
		"email": "info@rubywatersmusic.com",
		"name": "Ruby",
		"createdDate": "2023-04-05 17:56:20",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 202,
		"totalCountries": 8,
		"totalStates": 27,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 418,
		"schedulePercent": 0.263806,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "O2 Shepherd's Bush Empire",
		"mostGuestCity": "London",
		"mostGuestAmount": 2
	},
	{
		"id": "96fcfdc",
		"email": "mastertour1@asterios.fr",
		"name": "MasterTour1",
		"createdDate": "2017-10-19 13:15:21",
		"totalOrganizations": 30,
		"totalTours": 9,
		"totalDays": 707,
		"totalCountries": 6,
		"totalStates": 29,
		"totalEvents": 298,
		"eventPercent": 0.914448,
		"totalScheduleItems": 3323,
		"schedulePercent": 0.880281,
		"totalGuests": 627,
		"guestPercent": 0.733413,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "FNAC Live Paris",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 100
	},
	{
		"id": "95417d5",
		"email": "dougmurph@me.com",
		"name": "Dougie",
		"createdDate": "2012-02-02 17:02:50",
		"totalOrganizations": 23,
		"totalTours": 25,
		"totalDays": 492,
		"totalCountries": 21,
		"totalStates": 78,
		"totalEvents": 231,
		"eventPercent": 0.850616,
		"totalScheduleItems": 3069,
		"schedulePercent": 0.858164,
		"totalGuests": 287,
		"guestPercent": 0.597007,
		"mostGuestDate": "2023-06-02 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 29
	},
	{
		"id": "8a97aa9",
		"email": "candunn.jennette@harlemglobetrotters.com",
		"name": "Candunn",
		"createdDate": "2022-12-07 15:43:31",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 341,
		"totalCountries": 8,
		"totalStates": 68,
		"totalEvents": 251,
		"eventPercent": 0.873792,
		"totalScheduleItems": 2606,
		"schedulePercent": 0.808502,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f489775",
		"email": "peytonbighorse@gmail.com",
		"name": "Peyton",
		"createdDate": "2022-02-21 13:36:54",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 91,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 527,
		"schedulePercent": 0.311349,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "The Crocodile - Belltown Bloom",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 2
	},
	{
		"id": "2271bf0",
		"email": "Taintedridley@gmail.com",
		"name": "Jayden ",
		"createdDate": "2022-08-16 20:58:41",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 27,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 225,
		"schedulePercent": 0.1666,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bca31d2",
		"email": "productionmgr@pilobolus.org",
		"name": "Rya",
		"createdDate": "2022-01-14 16:10:36",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 388,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1177,
		"schedulePercent": 0.533704,
		"totalGuests": 233,
		"guestPercent": 0.569726,
		"mostGuestDate": "2023-03-12 00:00:00",
		"mostGuestName": "Shubert Theatre",
		"mostGuestCity": "New Haven",
		"mostGuestAmount": 18
	},
	{
		"id": "6d1cc3a",
		"email": "alex@url2irl.club",
		"name": "Alex",
		"createdDate": "2022-03-12 23:52:29",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 256,
		"totalCountries": 8,
		"totalStates": 31,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1165,
		"schedulePercent": 0.530526,
		"totalGuests": 465,
		"guestPercent": 0.678718,
		"mostGuestDate": "2023-01-25 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 67
	},
	{
		"id": "6660ed3",
		"email": "greg@preservationhall.com",
		"name": "Greg",
		"createdDate": "2013-09-11 16:57:59",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 91,
		"totalCountries": 3,
		"totalStates": 30,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 324,
		"schedulePercent": 0.221428,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "78a4d82",
		"email": "andrewkoontz@sbcglobal.net",
		"name": "Andrew",
		"createdDate": "2018-11-16 16:49:07",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 405,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 1350,
		"schedulePercent": 0.580718,
		"totalGuests": 31,
		"guestPercent": 0.390942,
		"mostGuestDate": "2023-12-01 00:00:00",
		"mostGuestName": "Aronoff Center",
		"mostGuestCity": "Cincinnati",
		"mostGuestAmount": 31
	},
	{
		"id": "2c0dbbb",
		"email": "nicoleannerosiak@gmail.com",
		"name": "Nicole",
		"createdDate": "2022-12-03 16:43:25",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 98,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 1411,
		"schedulePercent": 0.593564,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "42f4244",
		"email": "tannerbrandell@gmail.com",
		"name": "Tanner",
		"createdDate": "2015-08-18 22:47:10",
		"totalOrganizations": 10,
		"totalTours": 9,
		"totalDays": 264,
		"totalCountries": 4,
		"totalStates": 38,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 1642,
		"schedulePercent": 0.651834,
		"totalGuests": 148,
		"guestPercent": 0.51225,
		"mostGuestDate": "2023-02-24 00:00:00",
		"mostGuestName": "Amalie Arena",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 25
	},
	{
		"id": "2f64c4c",
		"email": "lsamuelsshragg@cityparksfoundation.org",
		"name": "Lily",
		"createdDate": "2023-06-09 14:48:00",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 187,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 1419,
		"schedulePercent": 0.595948,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6570f09",
		"email": "tylerbcrawford@gmail.com",
		"name": "Tyler",
		"createdDate": "2018-05-30 02:23:12",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 130,
		"totalCountries": 6,
		"totalStates": 30,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 610,
		"schedulePercent": 0.345914,
		"totalGuests": 269,
		"guestPercent": 0.589193,
		"mostGuestDate": "2023-05-15 00:00:00",
		"mostGuestName": "Rickshaw Theatre",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 41
	},
	{
		"id": "9803a48",
		"email": "robertjq@earthlink.net",
		"name": "Bob",
		"createdDate": "2009-12-01 22:51:33",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 222,
		"totalCountries": 10,
		"totalStates": 28,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 1741,
		"schedulePercent": 0.672759,
		"totalGuests": 1636,
		"guestPercent": 0.90637,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Billy Bob's",
		"mostGuestCity": "Ft. Worth",
		"mostGuestAmount": 65
	},
	{
		"id": "3617e5d",
		"email": "nextel@mthdmgmt.com",
		"name": "justin",
		"createdDate": "2023-06-26 01:56:25",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 52,
		"totalCountries": 1,
		"totalStates": 16,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 149,
		"schedulePercent": 0.118925,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bd4ce54",
		"email": "nick@movinmusictouring.net",
		"name": "Nick",
		"createdDate": "2011-07-06 08:04:09",
		"totalOrganizations": 8,
		"totalTours": 25,
		"totalDays": 251,
		"totalCountries": 18,
		"totalStates": 55,
		"totalEvents": 162,
		"eventPercent": 0.732883,
		"totalScheduleItems": 2820,
		"schedulePercent": 0.834724,
		"totalGuests": 219,
		"guestPercent": 0.562575,
		"mostGuestDate": "2023-02-21 00:00:00",
		"mostGuestName": "Millennium Forum Theatre & Conference Centre",
		"mostGuestCity": "Londonderry",
		"mostGuestAmount": 40
	},
	{
		"id": "13997a2",
		"email": "landobandothhl@gmail.com",
		"name": "Felando",
		"createdDate": "2022-12-14 03:35:32",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 112,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2aa039f",
		"email": "ollie@atlas-touring.com",
		"name": "Ollie",
		"createdDate": "2023-07-10 16:55:39",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 146,
		"totalCountries": 1,
		"totalStates": 26,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 304,
		"schedulePercent": 0.209906,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "22cb51f",
		"email": "aamaru.tm@gmail.com",
		"name": "Amanda",
		"createdDate": "2023-03-10 14:07:40",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 103,
		"totalCountries": 1,
		"totalStates": 29,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 800,
		"guestPercent": 0.776056,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Glass Cactus",
		"mostGuestCity": "Grapevine",
		"mostGuestAmount": 68
	},
	{
		"id": "7d5bb10",
		"email": "info@elevenmusic.com",
		"name": "Karen",
		"createdDate": "2021-11-25 00:37:31",
		"totalOrganizations": 9,
		"totalTours": 17,
		"totalDays": 295,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 870,
		"schedulePercent": 0.442988,
		"totalGuests": 322,
		"guestPercent": 0.614753,
		"mostGuestDate": "2023-09-01 00:00:00",
		"mostGuestName": "Dee Why RSL Club",
		"mostGuestCity": "Dee Why",
		"mostGuestAmount": 25
	},
	{
		"id": "4c3896e",
		"email": "noadurban@gmail.com",
		"name": "Noa",
		"createdDate": "2023-04-14 22:01:38",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 29,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 103,
		"schedulePercent": 0.0929678,
		"totalGuests": 17,
		"guestPercent": 0.362469,
		"mostGuestDate": "2023-06-18 00:00:00",
		"mostGuestName": "Zebulon",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 8
	},
	{
		"id": "0b00198",
		"email": "contact@awpservices.co",
		"name": "Adam",
		"createdDate": "2022-01-13 21:27:15",
		"totalOrganizations": 12,
		"totalTours": 24,
		"totalDays": 717,
		"totalCountries": 7,
		"totalStates": 40,
		"totalEvents": 217,
		"eventPercent": 0.833267,
		"totalScheduleItems": 5496,
		"schedulePercent": 0.972851,
		"totalGuests": 3496,
		"guestPercent": 0.977486,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The ICON Festival Stage",
		"mostGuestCity": "Cincinnati",
		"mostGuestAmount": 238
	},
	{
		"id": "973080a",
		"email": "chloemai@me.com",
		"name": "Chloe",
		"createdDate": "2023-07-10 18:22:33",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 235,
		"totalCountries": 5,
		"totalStates": 5,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 476,
		"schedulePercent": 0.287909,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "Montreux Jazz Festival",
		"mostGuestCity": "Montreux",
		"mostGuestAmount": 7
	},
	{
		"id": "d808e90",
		"email": "bryce@lukewarm.net",
		"name": "Bryce",
		"createdDate": "2021-10-26 19:08:15",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 45,
		"totalCountries": 6,
		"totalStates": 17,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 137,
		"schedulePercent": 0.111244,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d81cf2c",
		"email": "nouhfence@gmail.com",
		"name": "Noa",
		"createdDate": "2022-02-04 19:20:14",
		"totalOrganizations": 8,
		"totalTours": 18,
		"totalDays": 571,
		"totalCountries": 15,
		"totalStates": 58,
		"totalEvents": 199,
		"eventPercent": 0.804264,
		"totalScheduleItems": 2006,
		"schedulePercent": 0.720434,
		"totalGuests": 1888,
		"guestPercent": 0.927692,
		"mostGuestDate": "2023-05-19 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 194
	},
	{
		"id": "023019e",
		"email": "begjees@gmail.com",
		"name": "BEGUE",
		"createdDate": "2023-07-22 09:32:32",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 96,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 271,
		"schedulePercent": 0.192822,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a9551d2",
		"email": "mswifty@mac.com",
		"name": "michael",
		"createdDate": "2012-03-20 14:50:53",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 290,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 173,
		"eventPercent": 0.757516,
		"totalScheduleItems": 1855,
		"schedulePercent": 0.695934,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-08-04 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 2
	},
	{
		"id": "1646b15",
		"email": "sjh1483@gmail.com",
		"name": "Scott",
		"createdDate": "2017-09-07 17:33:47",
		"totalOrganizations": 6,
		"totalTours": 14,
		"totalDays": 1087,
		"totalCountries": 8,
		"totalStates": 41,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 1299,
		"schedulePercent": 0.566018,
		"totalGuests": 666,
		"guestPercent": 0.742948,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 75
	},
	{
		"id": "d4e2644",
		"email": "emma.kuylenstjerna@hotmail.com",
		"name": "Emma",
		"createdDate": "2022-11-29 10:13:40",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 234,
		"totalCountries": 3,
		"totalStates": 24,
		"totalEvents": 187,
		"eventPercent": 0.784135,
		"totalScheduleItems": 1603,
		"schedulePercent": 0.643756,
		"totalGuests": 735,
		"guestPercent": 0.760429,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Slottsskogsvallen",
		"mostGuestCity": "GÃ¶teborg",
		"mostGuestAmount": 72
	},
	{
		"id": "78768d0",
		"email": "taylorcochrane15@gmail.com",
		"name": "Taylor",
		"createdDate": "2021-09-26 15:58:24",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 673,
		"totalCountries": 10,
		"totalStates": 51,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 2598,
		"schedulePercent": 0.80731,
		"totalGuests": 946,
		"guestPercent": 0.811416,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 58
	},
	{
		"id": "f24e0d6",
		"email": "jwc@birdhousegroup.com",
		"name": "JW",
		"createdDate": "2014-03-05 21:21:07",
		"totalOrganizations": 8,
		"totalTours": 33,
		"totalDays": 265,
		"totalCountries": 9,
		"totalStates": 33,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 1857,
		"schedulePercent": 0.696729,
		"totalGuests": 159,
		"guestPercent": 0.521918,
		"mostGuestDate": "2023-12-23 00:00:00",
		"mostGuestName": "Fisher Center for the Performing Arts",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 59
	},
	{
		"id": "b045d96",
		"email": "l.leclerc@asterios.fr",
		"name": "Loic",
		"createdDate": "2018-09-05 13:31:50",
		"totalOrganizations": 30,
		"totalTours": 7,
		"totalDays": 530,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 1730,
		"schedulePercent": 0.671037,
		"totalGuests": 147,
		"guestPercent": 0.511323,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "Chateau Rouge",
		"mostGuestCity": "Annemasse",
		"mostGuestAmount": 18
	},
	{
		"id": "ad40c18",
		"email": "lisa@bastillemusic.co.uk",
		"name": "Lisa-Marie",
		"createdDate": "2018-06-21 10:48:17",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 114,
		"totalCountries": 4,
		"totalStates": 19,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 816,
		"schedulePercent": 0.425109,
		"totalGuests": 738,
		"guestPercent": 0.761753,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 187
	},
	{
		"id": "9fcd03b",
		"email": "rylan@7smgmt.com",
		"name": "Rylan",
		"createdDate": "2022-08-12 21:55:58",
		"totalOrganizations": 8,
		"totalTours": 22,
		"totalDays": 1325,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 139,
		"eventPercent": 0.671434,
		"totalScheduleItems": 603,
		"schedulePercent": 0.343001,
		"totalGuests": 254,
		"guestPercent": 0.581248,
		"mostGuestDate": "2023-05-12 00:00:00",
		"mostGuestName": "Bluebird Theater",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 25
	},
	{
		"id": "8bd71d6",
		"email": "markscurrsound@googlemail.com",
		"name": "Mark",
		"createdDate": "2012-05-28 13:51:16",
		"totalOrganizations": 31,
		"totalTours": 67,
		"totalDays": 1391,
		"totalCountries": 25,
		"totalStates": 105,
		"totalEvents": 483,
		"eventPercent": 0.972057,
		"totalScheduleItems": 5842,
		"schedulePercent": 0.978149,
		"totalGuests": 1507,
		"guestPercent": 0.894981,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 109
	},
	{
		"id": "9518bc8",
		"email": "audiobyalexluna@gmail.com",
		"name": "Alejandro",
		"createdDate": "2021-08-14 15:29:50",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 235,
		"totalCountries": 1,
		"totalStates": 40,
		"totalEvents": 167,
		"eventPercent": 0.745332,
		"totalScheduleItems": 2034,
		"schedulePercent": 0.724672,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "The Music Center @ Strathmore",
		"mostGuestCity": "North Bethesda",
		"mostGuestAmount": 9
	},
	{
		"id": "51e7249",
		"email": "Deeheskett@gmail.com",
		"name": "Dee ",
		"createdDate": "2022-05-06 10:37:01",
		"totalOrganizations": 7,
		"totalTours": 23,
		"totalDays": 118,
		"totalCountries": 5,
		"totalStates": 8,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 504,
		"schedulePercent": 0.30049,
		"totalGuests": 94,
		"guestPercent": 0.469474,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "Privatclub",
		"mostGuestCity": "",
		"mostGuestAmount": 16
	},
	{
		"id": "79fa2b7",
		"email": "Jeri.gennaro@gmail.com",
		"name": "Jeri",
		"createdDate": "2018-04-03 18:29:50",
		"totalOrganizations": 10,
		"totalTours": 7,
		"totalDays": 403,
		"totalCountries": 10,
		"totalStates": 50,
		"totalEvents": 166,
		"eventPercent": 0.74308,
		"totalScheduleItems": 2465,
		"schedulePercent": 0.788505,
		"totalGuests": 1153,
		"guestPercent": 0.849556,
		"mostGuestDate": "2023-06-10 00:00:00",
		"mostGuestName": "Los Angeles State Historic Park",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 61
	},
	{
		"id": "00f31b9",
		"email": "jhsfoh@gmail.com",
		"name": "Jeffrey",
		"createdDate": "2016-01-24 14:26:03",
		"totalOrganizations": 21,
		"totalTours": 15,
		"totalDays": 471,
		"totalCountries": 14,
		"totalStates": 52,
		"totalEvents": 174,
		"eventPercent": 0.759502,
		"totalScheduleItems": 2287,
		"schedulePercent": 0.763475,
		"totalGuests": 515,
		"guestPercent": 0.697126,
		"mostGuestDate": "2023-05-25 00:00:00",
		"mostGuestName": "The Regent",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 68
	},
	{
		"id": "49c6abc",
		"email": "Alexkendricktours@gmail.com",
		"name": "Alex",
		"createdDate": "2021-11-23 17:14:31",
		"totalOrganizations": 5,
		"totalTours": 12,
		"totalDays": 291,
		"totalCountries": 12,
		"totalStates": 58,
		"totalEvents": 184,
		"eventPercent": 0.77791,
		"totalScheduleItems": 2030,
		"schedulePercent": 0.724142,
		"totalGuests": 1871,
		"guestPercent": 0.926235,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Saint Andrewâ€™s Hall",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 80
	},
	{
		"id": "650553f",
		"email": "duda@hrd8wrk.com",
		"name": "Michael ",
		"createdDate": "2023-03-27 18:15:59",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 64,
		"totalCountries": 4,
		"totalStates": 12,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 307,
		"schedulePercent": 0.21176,
		"totalGuests": 93,
		"guestPercent": 0.468017,
		"mostGuestDate": "2023-09-19 00:00:00",
		"mostGuestName": "Baby's All Right",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 27
	},
	{
		"id": "d68ec89",
		"email": "cahlers@c2concerts.de",
		"name": "Chris",
		"createdDate": "2016-11-02 10:05:55",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 41,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 238,
		"schedulePercent": 0.174679,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-05-23 00:00:00",
		"mostGuestName": "Olympiahalle",
		"mostGuestCity": "Munchen",
		"mostGuestAmount": 2
	},
	{
		"id": "3da5620",
		"email": "jake.mazzuca@advocate-av.com",
		"name": "Jake",
		"createdDate": "2021-07-12 15:07:21",
		"totalOrganizations": 28,
		"totalTours": 72,
		"totalDays": 1435,
		"totalCountries": 18,
		"totalStates": 76,
		"totalEvents": 435,
		"eventPercent": 0.9657,
		"totalScheduleItems": 4366,
		"schedulePercent": 0.940405,
		"totalGuests": 362,
		"guestPercent": 0.636472,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "O2 Shepherd's Bush Empire",
		"mostGuestCity": "London",
		"mostGuestAmount": 58
	},
	{
		"id": "8e51a38",
		"email": "matthewschillawski@gmail.com",
		"name": "Matthew",
		"createdDate": "2022-11-28 14:54:26",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 321,
		"totalCountries": 5,
		"totalStates": 53,
		"totalEvents": 193,
		"eventPercent": 0.792743,
		"totalScheduleItems": 3580,
		"schedulePercent": 0.898689,
		"totalGuests": 1309,
		"guestPercent": 0.872997,
		"mostGuestDate": "2023-02-21 00:00:00",
		"mostGuestName": "Eagles Ballroom",
		"mostGuestCity": "Milwaukee",
		"mostGuestAmount": 63
	},
	{
		"id": "d124e5a",
		"email": "daryl@djwmgmt.com",
		"name": "Daryl",
		"createdDate": "2023-07-22 21:16:12",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 73,
		"totalCountries": 1,
		"totalStates": 14,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 94,
		"schedulePercent": 0.0852867,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "faa58f7",
		"email": "mikesound1@gmail.com",
		"name": "Mike",
		"createdDate": "2011-10-20 01:59:35",
		"totalOrganizations": 11,
		"totalTours": 13,
		"totalDays": 687,
		"totalCountries": 9,
		"totalStates": 48,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 591,
		"schedulePercent": 0.336512,
		"totalGuests": 68,
		"guestPercent": 0.444312,
		"mostGuestDate": "2023-10-29 00:00:00",
		"mostGuestName": "EartH (Evolutionary Arts Hackney)",
		"mostGuestCity": "London",
		"mostGuestAmount": 14
	},
	{
		"id": "3544f6b",
		"email": "mrctouring@gmail.com",
		"name": "Mark",
		"createdDate": "2018-05-20 17:50:12",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 251,
		"totalCountries": 2,
		"totalStates": 37,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 1774,
		"schedulePercent": 0.679513,
		"totalGuests": 456,
		"guestPercent": 0.676334,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "del Lago Resort & Casino",
		"mostGuestCity": "Waterloo",
		"mostGuestAmount": 39
	},
	{
		"id": "a032a7f",
		"email": "ja@joeangelsanchez.com",
		"name": "JoeAngel",
		"createdDate": "2014-02-28 16:22:45",
		"totalOrganizations": 11,
		"totalTours": 12,
		"totalDays": 1418,
		"totalCountries": 4,
		"totalStates": 43,
		"totalEvents": 387,
		"eventPercent": 0.954046,
		"totalScheduleItems": 7249,
		"schedulePercent": 0.99073,
		"totalGuests": 3445,
		"guestPercent": 0.97603,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Gas South Arena",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 157
	},
	{
		"id": "617dd5a",
		"email": "spear.alec@gmail.com",
		"name": "Alec",
		"createdDate": "2016-03-09 08:06:36",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 315,
		"totalCountries": 12,
		"totalStates": 51,
		"totalEvents": 182,
		"eventPercent": 0.773408,
		"totalScheduleItems": 2800,
		"schedulePercent": 0.83234,
		"totalGuests": 611,
		"guestPercent": 0.729043,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "The Smith Center- Reynolds Hall",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 49
	},
	{
		"id": "7b860c8",
		"email": "theaquadolls@gmail.com",
		"name": "Keilah",
		"createdDate": "2023-03-15 19:37:07",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 47,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 2,
		"schedulePercent": 0.00966759,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4f1e30d",
		"email": "skid.tourservice@gmail.com",
		"name": "SkiD",
		"createdDate": "2022-03-27 01:40:54",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 62,
		"totalCountries": 4,
		"totalStates": 26,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 709,
		"schedulePercent": 0.384585,
		"totalGuests": 243,
		"guestPercent": 0.574758,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Zappa",
		"mostGuestCity": "Antwerpen",
		"mostGuestAmount": 30
	},
	{
		"id": "8510373",
		"email": "tanner@selective.group",
		"name": "Tanner",
		"createdDate": "2023-04-21 21:16:45",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 196,
		"totalCountries": 7,
		"totalStates": 37,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 1684,
		"schedulePercent": 0.66084,
		"totalGuests": 1886,
		"guestPercent": 0.927162,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Glen Helen Amphitheater",
		"mostGuestCity": "San Bernardino",
		"mostGuestAmount": 201
	},
	{
		"id": "7c5f656",
		"email": "mikaela@unitedvision.se",
		"name": "Mikaela",
		"createdDate": "2023-03-15 15:40:31",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 67,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 426,
		"schedulePercent": 0.26619,
		"totalGuests": 179,
		"guestPercent": 0.535426,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Filadelfiakyrkan",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 36
	},
	{
		"id": "2c1c815",
		"email": "daileytourmgmt@earthlink.net",
		"name": "Sean",
		"createdDate": "2019-01-18 00:55:45",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 338,
		"totalCountries": 12,
		"totalStates": 67,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 1895,
		"schedulePercent": 0.703483,
		"totalGuests": 981,
		"guestPercent": 0.819891,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "O2 Forum",
		"mostGuestCity": "London",
		"mostGuestAmount": 49
	},
	{
		"id": "27452bb",
		"email": "gardner.samuel28@yahoo.com",
		"name": "Sam",
		"createdDate": "2015-10-08 16:02:22",
		"totalOrganizations": 14,
		"totalTours": 10,
		"totalDays": 218,
		"totalCountries": 17,
		"totalStates": 30,
		"totalEvents": 120,
		"eventPercent": 0.614885,
		"totalScheduleItems": 1577,
		"schedulePercent": 0.636207,
		"totalGuests": 383,
		"guestPercent": 0.646404,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 102
	},
	{
		"id": "9c5b034",
		"email": "Gabriel2simon@gmail.com",
		"name": "Gabriel",
		"createdDate": "2019-10-27 17:44:13",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 241,
		"totalCountries": 2,
		"totalStates": 19,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 522,
		"schedulePercent": 0.308304,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b697c82",
		"email": "berit.roppen@livenation.se",
		"name": "Berit",
		"createdDate": "2013-05-24 14:03:17",
		"totalOrganizations": 70,
		"totalTours": 50,
		"totalDays": 766,
		"totalCountries": 11,
		"totalStates": 45,
		"totalEvents": 552,
		"eventPercent": 0.979738,
		"totalScheduleItems": 3619,
		"schedulePercent": 0.902529,
		"totalGuests": 11,
		"guestPercent": 0.345782,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Lokverkstan",
		"mostGuestCity": "Motala",
		"mostGuestAmount": 4
	},
	{
		"id": "f970026",
		"email": "pm@kaika.dk",
		"name": "Peter MÃ¸ller",
		"createdDate": "2021-09-21 07:25:45",
		"totalOrganizations": 21,
		"totalTours": 18,
		"totalDays": 321,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 302,
		"eventPercent": 0.916567,
		"totalScheduleItems": 2396,
		"schedulePercent": 0.780162,
		"totalGuests": 203,
		"guestPercent": 0.553172,
		"mostGuestDate": "2023-04-21 00:00:00",
		"mostGuestName": "Det Kongelige Teater, Gamle Scene",
		"mostGuestCity": "K&#248;benhavn K",
		"mostGuestAmount": 61
	},
	{
		"id": "52d12e2",
		"email": "pcyoung93@gmail.com",
		"name": "Philip",
		"createdDate": "2023-04-26 21:33:57",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 86,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 633,
		"schedulePercent": 0.35545,
		"totalGuests": 170,
		"guestPercent": 0.530261,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 147
	},
	{
		"id": "f1ab5b9",
		"email": "faina123@mac.com",
		"name": "Faina",
		"createdDate": "2015-06-13 13:21:43",
		"totalOrganizations": 4,
		"totalTours": 2,
		"totalDays": 45,
		"totalCountries": 6,
		"totalStates": 6,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 231,
		"schedulePercent": 0.169779,
		"totalGuests": 63,
		"guestPercent": 0.437558,
		"mostGuestDate": "2023-05-19 00:00:00",
		"mostGuestName": "Ronnie Scott's",
		"mostGuestCity": "London",
		"mostGuestAmount": 16
	},
	{
		"id": "595aecd",
		"email": "Wes.vause@pressonpublicity.com",
		"name": "Wes",
		"createdDate": "2022-09-06 20:43:53",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 305,
		"totalCountries": 3,
		"totalStates": 43,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1472,
		"schedulePercent": 0.607734,
		"totalGuests": 528,
		"guestPercent": 0.701629,
		"mostGuestDate": "2023-03-22 00:00:00",
		"mostGuestName": "The Factory in Deep Ellum",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 39
	},
	{
		"id": "e325122",
		"email": "vemshoff@satx.rr.com",
		"name": "Vernon",
		"createdDate": "2017-03-10 02:13:41",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 121,
		"totalCountries": 1,
		"totalStates": 29,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 693,
		"schedulePercent": 0.377831,
		"totalGuests": 77,
		"guestPercent": 0.452523,
		"mostGuestDate": "2023-02-13 00:00:00",
		"mostGuestName": "San Antonio Stock Show & Rodeo",
		"mostGuestCity": "San Antonio",
		"mostGuestAmount": 77
	},
	{
		"id": "d2f0fbb",
		"email": "bmoore@grndwrkent.com",
		"name": "brandon",
		"createdDate": "2019-12-06 07:03:46",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 46,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 9,
		"schedulePercent": 0.0210568,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9f249e0",
		"email": "Armatrak@mac.com",
		"name": "Richard",
		"createdDate": "2013-08-20 14:14:09",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 124,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 721,
		"schedulePercent": 0.38869,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5ccd72a",
		"email": "megan@futureclassic.com",
		"name": "Megan",
		"createdDate": "2022-05-18 02:58:05",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 223,
		"totalCountries": 9,
		"totalStates": 30,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 951,
		"schedulePercent": 0.465634,
		"totalGuests": 395,
		"guestPercent": 0.651569,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 207
	},
	{
		"id": "574c805",
		"email": "julialovechoi@gmail.com",
		"name": "Julia Love",
		"createdDate": "2019-07-08 08:54:48",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 213,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 788,
		"schedulePercent": 0.415574,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0669fb5",
		"email": "jonas@tajmer.dk",
		"name": "Jonas",
		"createdDate": "2016-01-26 14:34:47",
		"totalOrganizations": 8,
		"totalTours": 3,
		"totalDays": 26,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "925e657",
		"email": "brandontcagle@gmail.com",
		"name": "Brandon",
		"createdDate": "2012-04-10 20:17:00",
		"totalOrganizations": 9,
		"totalTours": 18,
		"totalDays": 472,
		"totalCountries": 22,
		"totalStates": 81,
		"totalEvents": 283,
		"eventPercent": 0.904516,
		"totalScheduleItems": 3136,
		"schedulePercent": 0.864919,
		"totalGuests": 2409,
		"guestPercent": 0.951265,
		"mostGuestDate": "2023-01-14 00:00:00",
		"mostGuestName": "Forum Melbourne",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 70
	},
	{
		"id": "1b908ac",
		"email": "magnus@flobecker.com",
		"name": "Magnus",
		"createdDate": "2016-09-11 13:35:38",
		"totalOrganizations": 7,
		"totalTours": 2,
		"totalDays": 96,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 182,
		"schedulePercent": 0.139849,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8d85e6b",
		"email": "ellen.lefebvre@riksteatern.se",
		"name": "Ellen",
		"createdDate": "2023-02-02 11:28:10",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 176,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 391,
		"schedulePercent": 0.253344,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "87d9420",
		"email": "lefthandsoundsorg@gmail.com",
		"name": "Maciej",
		"createdDate": "2019-02-05 18:26:42",
		"totalOrganizations": 6,
		"totalTours": 9,
		"totalDays": 145,
		"totalCountries": 13,
		"totalStates": 30,
		"totalEvents": 79,
		"eventPercent": 0.456099,
		"totalScheduleItems": 967,
		"schedulePercent": 0.470269,
		"totalGuests": 468,
		"guestPercent": 0.680572,
		"mostGuestDate": "2023-11-12 00:00:00",
		"mostGuestName": "Im Wizemann",
		"mostGuestCity": "Stuttgart",
		"mostGuestAmount": 44
	},
	{
		"id": "3beda79",
		"email": "wfelixaudio@gmail.com",
		"name": "William",
		"createdDate": "2021-06-02 10:17:16",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 611,
		"totalCountries": 3,
		"totalStates": 41,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 1411,
		"schedulePercent": 0.593564,
		"totalGuests": 95,
		"guestPercent": 0.470269,
		"mostGuestDate": "2023-05-01 00:00:00",
		"mostGuestName": "CMA Theater at the Country Music Hall of Fame and Museum",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 18
	},
	{
		"id": "f4bc626",
		"email": "TLBFOH@Gmail.com",
		"name": "Sean ",
		"createdDate": "2023-07-31 05:24:06",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 144,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 439,
		"schedulePercent": 0.272547,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "eddc3c9",
		"email": "joe.nemer@illusivepresents.com",
		"name": "Joe",
		"createdDate": "2017-10-06 01:13:06",
		"totalOrganizations": 50,
		"totalTours": 12,
		"totalDays": 151,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 1205,
		"schedulePercent": 0.540988,
		"totalGuests": 488,
		"guestPercent": 0.687591,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "Forum Melbourne",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 79
	},
	{
		"id": "51ffaac",
		"email": "searcyrj@gmail.com",
		"name": "Robert",
		"createdDate": "2015-01-05 22:50:42",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 367,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 890,
		"schedulePercent": 0.449609,
		"totalGuests": 31,
		"guestPercent": 0.390942,
		"mostGuestDate": "2023-04-13 00:00:00",
		"mostGuestName": "Hillvue Heights Baptist Church",
		"mostGuestCity": "Bowling Green",
		"mostGuestAmount": 11
	},
	{
		"id": "ce76cab",
		"email": "dom@cluenoclue.com",
		"name": "Clue",
		"createdDate": "2022-09-11 23:31:45",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 67,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 122,
		"schedulePercent": 0.104092,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "74c7a8d",
		"email": "james@theboswellcompany.com",
		"name": "James",
		"createdDate": "2019-12-27 19:00:07",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 227,
		"totalCountries": 8,
		"totalStates": 44,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 1302,
		"schedulePercent": 0.566812,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c742808",
		"email": "uda@semaphoremgmt.com",
		"name": "Udaravi",
		"createdDate": "2023-03-14 16:49:25",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 154,
		"totalCountries": 6,
		"totalStates": 31,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 753,
		"schedulePercent": 0.403655,
		"totalGuests": 976,
		"guestPercent": 0.818435,
		"mostGuestDate": "2023-05-19 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 194
	},
	{
		"id": "3598a6f",
		"email": "miakersten@gmail.com",
		"name": "Mia",
		"createdDate": "2023-01-23 20:15:09",
		"totalOrganizations": 2,
		"totalTours": 11,
		"totalDays": 204,
		"totalCountries": 8,
		"totalStates": 27,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 590,
		"schedulePercent": 0.33585,
		"totalGuests": 39,
		"guestPercent": 0.404185,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Dos Equis Pavilion",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 13
	},
	{
		"id": "3cc2f16",
		"email": "poindextertj@gmail.com",
		"name": "Thomas",
		"createdDate": "2018-10-02 13:03:40",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 862,
		"totalCountries": 12,
		"totalStates": 57,
		"totalEvents": 277,
		"eventPercent": 0.899616,
		"totalScheduleItems": 4459,
		"schedulePercent": 0.943981,
		"totalGuests": 2395,
		"guestPercent": 0.95047,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Gas South Arena",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 157
	},
	{
		"id": "0865bd4",
		"email": "romain@thelinkprod.fr",
		"name": "Romain",
		"createdDate": "2019-07-22 19:32:00",
		"totalOrganizations": 6,
		"totalTours": 15,
		"totalDays": 334,
		"totalCountries": 20,
		"totalStates": 73,
		"totalEvents": 205,
		"eventPercent": 0.814859,
		"totalScheduleItems": 2745,
		"schedulePercent": 0.825189,
		"totalGuests": 1506,
		"guestPercent": 0.894848,
		"mostGuestDate": "2023-03-14 00:00:00",
		"mostGuestName": "Huxley's",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 64
	},
	{
		"id": "105c36e",
		"email": "nickmarson@mac.com",
		"name": "Nick",
		"createdDate": "2012-10-10 06:46:29",
		"totalOrganizations": 26,
		"totalTours": 25,
		"totalDays": 1067,
		"totalCountries": 26,
		"totalStates": 88,
		"totalEvents": 270,
		"eventPercent": 0.894451,
		"totalScheduleItems": 4164,
		"schedulePercent": 0.932062,
		"totalGuests": 1631,
		"guestPercent": 0.905973,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 207
	},
	{
		"id": "e34e818",
		"email": "ec@martingarrix.com",
		"name": "Eric",
		"createdDate": "2023-06-19 19:07:38",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 585,
		"totalCountries": 17,
		"totalStates": 27,
		"totalEvents": 159,
		"eventPercent": 0.726394,
		"totalScheduleItems": 2164,
		"schedulePercent": 0.744802,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4302ea9",
		"email": "yokelore@gmail.com",
		"name": "Yoke",
		"createdDate": "2023-07-27 19:55:35",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 105,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 532,
		"schedulePercent": 0.313733,
		"totalGuests": 63,
		"guestPercent": 0.437558,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Music Hall of Williamsburg",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 23
	},
	{
		"id": "bf4a337",
		"email": "shelbymcmillen92@gmail.com",
		"name": "Shelby",
		"createdDate": "2023-05-26 15:55:59",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 220,
		"totalCountries": 1,
		"totalStates": 33,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 1205,
		"schedulePercent": 0.540988,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "541a0c5",
		"email": "gina.coates@me.com",
		"name": "Gina",
		"createdDate": "2013-06-04 17:42:15",
		"totalOrganizations": 10,
		"totalTours": 2,
		"totalDays": 249,
		"totalCountries": 16,
		"totalStates": 32,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1057,
		"schedulePercent": 0.500066,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4dfe6a4",
		"email": "dcase87@gmail.com",
		"name": "Dan",
		"createdDate": "2013-05-06 21:56:07",
		"totalOrganizations": 15,
		"totalTours": 27,
		"totalDays": 527,
		"totalCountries": 19,
		"totalStates": 74,
		"totalEvents": 281,
		"eventPercent": 0.903059,
		"totalScheduleItems": 3875,
		"schedulePercent": 0.9167,
		"totalGuests": 2744,
		"guestPercent": 0.962389,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 171
	},
	{
		"id": "402d071",
		"email": "ephaudio@gmail.com",
		"name": "Erin",
		"createdDate": "2015-09-14 21:44:16",
		"totalOrganizations": 15,
		"totalTours": 4,
		"totalDays": 168,
		"totalCountries": 4,
		"totalStates": 13,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 636,
		"schedulePercent": 0.356774,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cd605bd",
		"email": "jarod.m.grimm@gmail.com",
		"name": "Jarod",
		"createdDate": "2022-05-01 17:05:02",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 511,
		"totalCountries": 2,
		"totalStates": 37,
		"totalEvents": 192,
		"eventPercent": 0.791683,
		"totalScheduleItems": 1498,
		"schedulePercent": 0.617799,
		"totalGuests": 18,
		"guestPercent": 0.364455,
		"mostGuestDate": "2023-08-04 00:00:00",
		"mostGuestName": "Musikfest",
		"mostGuestCity": "Bethlehem",
		"mostGuestAmount": 12
	},
	{
		"id": "a04ad5e",
		"email": "sannydamet@gmail.com",
		"name": "Danny",
		"createdDate": "2013-08-22 02:33:49",
		"totalOrganizations": 16,
		"totalTours": 9,
		"totalDays": 827,
		"totalCountries": 3,
		"totalStates": 49,
		"totalEvents": 283,
		"eventPercent": 0.904516,
		"totalScheduleItems": 3635,
		"schedulePercent": 0.903589,
		"totalGuests": 3453,
		"guestPercent": 0.976162,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Nashville Municipal Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 111
	},
	{
		"id": "3288b0f",
		"email": "alexlgrant@gmail.com",
		"name": "Alexandra",
		"createdDate": "2014-09-22 18:39:18",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 342,
		"totalCountries": 3,
		"totalStates": 31,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 2004,
		"schedulePercent": 0.719772,
		"totalGuests": 87,
		"guestPercent": 0.460734,
		"mostGuestDate": "2023-10-08 00:00:00",
		"mostGuestName": "Power Trip Music Festival",
		"mostGuestCity": "Indio",
		"mostGuestAmount": 87
	},
	{
		"id": "3270df5",
		"email": "christine@ubuntuworldmusic.com",
		"name": "Christine",
		"createdDate": "2022-11-05 03:51:07",
		"totalOrganizations": 7,
		"totalTours": 93,
		"totalDays": 547,
		"totalCountries": 16,
		"totalStates": 65,
		"totalEvents": 283,
		"eventPercent": 0.904516,
		"totalScheduleItems": 2775,
		"schedulePercent": 0.829029,
		"totalGuests": 200,
		"guestPercent": 0.550258,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "Jazz At Lincoln Center",
		"mostGuestCity": "New York",
		"mostGuestAmount": 25
	},
	{
		"id": "7b743e1",
		"email": "ceducate@osacorp.com",
		"name": "Carmen",
		"createdDate": "2012-10-17 17:38:03",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 521,
		"totalCountries": 5,
		"totalStates": 36,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 3313,
		"schedulePercent": 0.879089,
		"totalGuests": 35,
		"guestPercent": 0.396901,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "Southern Alberta Jubilee Auditorium",
		"mostGuestCity": "Calgary",
		"mostGuestAmount": 12
	},
	{
		"id": "c20b175",
		"email": "blake.byrd@ymail.com",
		"name": "blake",
		"createdDate": "2021-05-05 01:27:09",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 937,
		"totalCountries": 2,
		"totalStates": 39,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 2388,
		"schedulePercent": 0.779102,
		"totalGuests": 1483,
		"guestPercent": 0.892067,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Moody Center",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 140
	},
	{
		"id": "71dde98",
		"email": "akraney4@gmail.com",
		"name": "Aaron",
		"createdDate": "2018-06-05 05:07:54",
		"totalOrganizations": 8,
		"totalTours": 4,
		"totalDays": 480,
		"totalCountries": 2,
		"totalStates": 36,
		"totalEvents": 179,
		"eventPercent": 0.769037,
		"totalScheduleItems": 2540,
		"schedulePercent": 0.79804,
		"totalGuests": 2781,
		"guestPercent": 0.963978,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Gas South Arena",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 157
	},
	{
		"id": "73b4670",
		"email": "will@24-7productions.com",
		"name": "Will",
		"createdDate": "2023-06-28 15:52:09",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 184,
		"totalCountries": 12,
		"totalStates": 30,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 1172,
		"schedulePercent": 0.532645,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1628ac7",
		"email": "chrismfriday@gmail.com",
		"name": "Chris ",
		"createdDate": "2013-12-17 23:23:11",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 58,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 146,
		"schedulePercent": 0.117071,
		"totalGuests": 101,
		"guestPercent": 0.474904,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Grand Ballroom at Webster Hall ",
		"mostGuestCity": "New York",
		"mostGuestAmount": 22
	},
	{
		"id": "a432b80",
		"email": "timbassett.production@gmail.com",
		"name": "Tim ",
		"createdDate": "2017-05-02 21:28:47",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 117,
		"totalCountries": 1,
		"totalStates": 32,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 1448,
		"schedulePercent": 0.603099,
		"totalGuests": 174,
		"guestPercent": 0.532115,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "Jannus Live",
		"mostGuestCity": "St. Petersburg",
		"mostGuestAmount": 24
	},
	{
		"id": "a1bad95",
		"email": "benshockley@me.com",
		"name": "Ben",
		"createdDate": "2017-01-29 16:58:38",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 477,
		"totalCountries": 9,
		"totalStates": 44,
		"totalEvents": 141,
		"eventPercent": 0.678321,
		"totalScheduleItems": 2213,
		"schedulePercent": 0.751953,
		"totalGuests": 3218,
		"guestPercent": 0.973381,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Raymond James Stadium",
		"mostGuestCity": "Tampa ",
		"mostGuestAmount": 248
	},
	{
		"id": "91d57b6",
		"email": "cbrad@mac.com",
		"name": "Charlie",
		"createdDate": "2010-01-16 06:14:26",
		"totalOrganizations": 11,
		"totalTours": 11,
		"totalDays": 154,
		"totalCountries": 10,
		"totalStates": 28,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 1173,
		"schedulePercent": 0.53291,
		"totalGuests": 19,
		"guestPercent": 0.366971,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Grand Garden Arena",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 19
	},
	{
		"id": "be2de61",
		"email": "mikeg.tour@gmail.com",
		"name": "Michael",
		"createdDate": "2017-07-17 22:38:24",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 1044,
		"schedulePercent": 0.495564,
		"totalGuests": 292,
		"guestPercent": 0.598596,
		"mostGuestDate": "2023-06-08 00:00:00",
		"mostGuestName": "Nissan Stadium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 37
	},
	{
		"id": "31ed159",
		"email": "st@stylus-productions.com",
		"name": "Sam",
		"createdDate": "2017-11-26 07:46:57",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 484,
		"totalCountries": 15,
		"totalStates": 53,
		"totalEvents": 194,
		"eventPercent": 0.794597,
		"totalScheduleItems": 3265,
		"schedulePercent": 0.875381,
		"totalGuests": 500,
		"guestPercent": 0.691829,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 125
	},
	{
		"id": "5cb732a",
		"email": "tacmac7@mac.com",
		"name": "Tori",
		"createdDate": "2017-06-24 01:37:25",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 149,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 5,
		"eventPercent": 0.0348298,
		"totalScheduleItems": 984,
		"schedulePercent": 0.477553,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "17e19fb",
		"email": "ScottCad@Earthlink.net",
		"name": "Scott",
		"createdDate": "2009-07-17 03:16:15",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 170,
		"totalCountries": 9,
		"totalStates": 20,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1151,
		"schedulePercent": 0.527347,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "51cd00c",
		"email": "cktouring@gmail.com",
		"name": "Beau",
		"createdDate": "2022-05-11 18:12:06",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 587,
		"totalCountries": 2,
		"totalStates": 42,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 2766,
		"schedulePercent": 0.82797,
		"totalGuests": 3113,
		"guestPercent": 0.971262,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 104
	},
	{
		"id": "681cc1f",
		"email": "ben1157@mac.com",
		"name": "Benjamin",
		"createdDate": "2013-03-21 16:59:03",
		"totalOrganizations": 2,
		"totalTours": 18,
		"totalDays": 457,
		"totalCountries": 6,
		"totalStates": 41,
		"totalEvents": 145,
		"eventPercent": 0.688518,
		"totalScheduleItems": 2443,
		"schedulePercent": 0.785326,
		"totalGuests": 813,
		"guestPercent": 0.779235,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Firstbank Amphitheater",
		"mostGuestCity": "Franklin",
		"mostGuestAmount": 47
	},
	{
		"id": "871fdce",
		"email": "nservant@studiosextan.fr",
		"name": "Studio",
		"createdDate": "2021-11-04 10:54:57",
		"totalOrganizations": 2,
		"totalTours": 12,
		"totalDays": 96,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 986,
		"schedulePercent": 0.47848,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "dc88d39",
		"email": "zach@epicenterproductions.net",
		"name": "Zach",
		"createdDate": "2022-08-21 02:59:54",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 88,
		"totalCountries": 7,
		"totalStates": 20,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 211,
		"schedulePercent": 0.157198,
		"totalGuests": 41,
		"guestPercent": 0.407231,
		"mostGuestDate": "2023-07-19 00:00:00",
		"mostGuestName": "Huntington Center",
		"mostGuestCity": "Toledo",
		"mostGuestAmount": 41
	},
	{
		"id": "a5d1aba",
		"email": "brookestevenson4312@gmail.com",
		"name": "Brooke",
		"createdDate": "2023-10-23 15:49:06",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 62,
		"totalCountries": 1,
		"totalStates": 24,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 293,
		"schedulePercent": 0.204609,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c7559f6",
		"email": "seth.watrous@gmail.com",
		"name": "Seth",
		"createdDate": "2019-11-13 01:44:51",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 104,
		"totalCountries": 2,
		"totalStates": 33,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 89,
		"schedulePercent": 0.081711,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "dc59ae0",
		"email": "bruna@vitorkley.com.br",
		"name": "Bruna",
		"createdDate": "2022-06-22 01:56:29",
		"totalOrganizations": 3,
		"totalTours": 12,
		"totalDays": 179,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 239,
		"eventPercent": 0.860681,
		"totalScheduleItems": 1285,
		"schedulePercent": 0.56125,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "17aa6a1",
		"email": "dylan@dylanmaudio.com",
		"name": "Dylan",
		"createdDate": "2022-02-02 12:24:11",
		"totalOrganizations": 33,
		"totalTours": 34,
		"totalDays": 833,
		"totalCountries": 15,
		"totalStates": 77,
		"totalEvents": 347,
		"eventPercent": 0.940273,
		"totalScheduleItems": 4915,
		"schedulePercent": 0.959343,
		"totalGuests": 1263,
		"guestPercent": 0.867037,
		"mostGuestDate": "2023-01-22 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 133
	},
	{
		"id": "a220a70",
		"email": "pete@whyandhow.com",
		"name": "Pete",
		"createdDate": "2021-02-22 21:48:03",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 1108,
		"totalCountries": 5,
		"totalStates": 44,
		"totalEvents": 152,
		"eventPercent": 0.708118,
		"totalScheduleItems": 1564,
		"schedulePercent": 0.633558,
		"totalGuests": 181,
		"guestPercent": 0.537147,
		"mostGuestDate": "2023-05-21 00:00:00",
		"mostGuestName": "George E. Potter Center",
		"mostGuestCity": "Jackson",
		"mostGuestAmount": 31
	},
	{
		"id": "a130f6f",
		"email": "Scooterswrld@gmail.com",
		"name": "Scooter",
		"createdDate": "2013-10-02 20:10:32",
		"totalOrganizations": 9,
		"totalTours": 17,
		"totalDays": 1017,
		"totalCountries": 10,
		"totalStates": 48,
		"totalEvents": 248,
		"eventPercent": 0.869554,
		"totalScheduleItems": 2576,
		"schedulePercent": 0.80241,
		"totalGuests": 1327,
		"guestPercent": 0.874983,
		"mostGuestDate": "2023-01-30 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 74
	},
	{
		"id": "d66e2e5",
		"email": "ramonesanchez89@gmail.com",
		"name": "Ramon",
		"createdDate": "2022-07-13 15:57:41",
		"totalOrganizations": 9,
		"totalTours": 9,
		"totalDays": 363,
		"totalCountries": 5,
		"totalStates": 50,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 2471,
		"schedulePercent": 0.789035,
		"totalGuests": 3199,
		"guestPercent": 0.972984,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 185
	},
	{
		"id": "6c1e8cf",
		"email": "bermudezmaria@me.com",
		"name": "Maria",
		"createdDate": "2014-05-06 03:38:28",
		"totalOrganizations": 6,
		"totalTours": 16,
		"totalDays": 431,
		"totalCountries": 18,
		"totalStates": 52,
		"totalEvents": 157,
		"eventPercent": 0.720567,
		"totalScheduleItems": 2746,
		"schedulePercent": 0.825454,
		"totalGuests": 4522,
		"guestPercent": 0.988346,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Glen Helen Amphitheater",
		"mostGuestCity": "San Bernardino",
		"mostGuestAmount": 250
	},
	{
		"id": "f60a224",
		"email": "theresa.aboudaoud@gmail.com",
		"name": "Theresa",
		"createdDate": "2021-08-16 21:41:07",
		"totalOrganizations": 8,
		"totalTours": 10,
		"totalDays": 850,
		"totalCountries": 4,
		"totalStates": 35,
		"totalEvents": 145,
		"eventPercent": 0.688518,
		"totalScheduleItems": 2356,
		"schedulePercent": 0.774864,
		"totalGuests": 678,
		"guestPercent": 0.747451,
		"mostGuestDate": "2023-03-14 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 115
	},
	{
		"id": "82a8a03",
		"email": "dscheid@gmail.com",
		"name": "David",
		"createdDate": "2011-01-09 00:33:32",
		"totalOrganizations": 11,
		"totalTours": 15,
		"totalDays": 647,
		"totalCountries": 7,
		"totalStates": 41,
		"totalEvents": 77,
		"eventPercent": 0.446431,
		"totalScheduleItems": 1339,
		"schedulePercent": 0.577539,
		"totalGuests": 488,
		"guestPercent": 0.687591,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Berkeley",
		"mostGuestAmount": 42
	},
	{
		"id": "587cc71",
		"email": "victor.coisy@umusic.com",
		"name": "Victor",
		"createdDate": "2022-09-23 12:09:49",
		"totalOrganizations": 23,
		"totalTours": 20,
		"totalDays": 364,
		"totalCountries": 4,
		"totalStates": 24,
		"totalEvents": 198,
		"eventPercent": 0.802543,
		"totalScheduleItems": 2119,
		"schedulePercent": 0.738048,
		"totalGuests": 192,
		"guestPercent": 0.544564,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Carre Des Docks",
		"mostGuestCity": "Le Havre",
		"mostGuestAmount": 25
	},
	{
		"id": "c672764",
		"email": "mdqxtourcm@gmail.com",
		"name": "Million Dollar",
		"createdDate": "2023-10-30 17:36:24",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 56,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 289,
		"schedulePercent": 0.20249,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b55c868",
		"email": "KLAllen@sjcfl.us",
		"name": "Kayla",
		"createdDate": "2022-08-03 15:45:45",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 231,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 229,
		"eventPercent": 0.847305,
		"totalScheduleItems": 2336,
		"schedulePercent": 0.771818,
		"totalGuests": 47,
		"guestPercent": 0.417031,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "St. Augustine Amphitheatre",
		"mostGuestCity": "St. Augustine",
		"mostGuestAmount": 18
	},
	{
		"id": "1058e10",
		"email": "jordanv.tm@gmail.com",
		"name": "Jordan",
		"createdDate": "2019-09-17 01:52:15",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 1577,
		"totalCountries": 10,
		"totalStates": 44,
		"totalEvents": 204,
		"eventPercent": 0.813005,
		"totalScheduleItems": 2212,
		"schedulePercent": 0.751689,
		"totalGuests": 1579,
		"guestPercent": 0.901338,
		"mostGuestDate": "2023-06-02 00:00:00",
		"mostGuestName": "Avant Gardner",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 133
	},
	{
		"id": "5fa9a40",
		"email": "smulva@gmail.com",
		"name": "SCOTT",
		"createdDate": "2012-11-07 17:11:04",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 142,
		"totalCountries": 2,
		"totalStates": 21,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 527,
		"schedulePercent": 0.311349,
		"totalGuests": 300,
		"guestPercent": 0.602569,
		"mostGuestDate": "2023-04-10 00:00:00",
		"mostGuestName": "The Fisher Center for the Performing Arts",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 139
	},
	{
		"id": "ece7640",
		"email": "sound.guy@mac.com",
		"name": "Christopher",
		"createdDate": "2021-12-29 01:52:32",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 99,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 318,
		"schedulePercent": 0.217322,
		"totalGuests": 322,
		"guestPercent": 0.614753,
		"mostGuestDate": "2023-09-01 00:00:00",
		"mostGuestName": "Dee Why RSL Club",
		"mostGuestCity": "Dee Why",
		"mostGuestAmount": 25
	},
	{
		"id": "0d58c1c",
		"email": "dave@magicspace.net",
		"name": "Dave",
		"createdDate": "2011-12-06 21:26:19",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 57,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 246,
		"schedulePercent": 0.179976,
		"totalGuests": 76,
		"guestPercent": 0.451596,
		"mostGuestDate": "2023-12-15 00:00:00",
		"mostGuestName": "Dr. Phillips Center for the Performing Arts",
		"mostGuestCity": "Orlando",
		"mostGuestAmount": 14
	},
	{
		"id": "b04929e",
		"email": "sunderlandaudio@gmail.com",
		"name": "Mark",
		"createdDate": "2013-06-29 12:12:35",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 437,
		"totalCountries": 19,
		"totalStates": 39,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1555,
		"schedulePercent": 0.631042,
		"totalGuests": 259,
		"guestPercent": 0.583764,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "Kirstenbosch National Botanical Garden",
		"mostGuestCity": "Cape Town",
		"mostGuestAmount": 48
	},
	{
		"id": "abef6ee",
		"email": "sdw@SidewinderMgmt.com",
		"name": "Simon",
		"createdDate": "2014-06-11 08:10:25",
		"totalOrganizations": 3,
		"totalTours": 8,
		"totalDays": 137,
		"totalCountries": 5,
		"totalStates": 18,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 698,
		"schedulePercent": 0.37995,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "dfa703b",
		"email": "bobbyburg@gmail.com",
		"name": "Bobby",
		"createdDate": "2014-10-20 20:13:56",
		"totalOrganizations": 8,
		"totalTours": 3,
		"totalDays": 27,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 138,
		"schedulePercent": 0.112171,
		"totalGuests": 64,
		"guestPercent": 0.439015,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "Space",
		"mostGuestCity": "Evanston",
		"mostGuestAmount": 20
	},
	{
		"id": "51c624b",
		"email": "boxer@biggerhammer.com",
		"name": "Jahn",
		"createdDate": "2014-12-17 15:30:42",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 27,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 7,
		"eventPercent": 0.0458217,
		"totalScheduleItems": 115,
		"schedulePercent": 0.0993246,
		"totalGuests": 92,
		"guestPercent": 0.466163,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "The Observatory",
		"mostGuestCity": "Santa Ana",
		"mostGuestAmount": 31
	},
	{
		"id": "a77a657",
		"email": "nat@arcadiamusicco.com",
		"name": "Nat",
		"createdDate": "2021-11-03 05:31:41",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 177,
		"totalCountries": 14,
		"totalStates": 30,
		"totalEvents": 49,
		"eventPercent": 0.307906,
		"totalScheduleItems": 874,
		"schedulePercent": 0.444974,
		"totalGuests": 446,
		"guestPercent": 0.671434,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 207
	},
	{
		"id": "4135ca4",
		"email": "irene.pina@rlm.es",
		"name": "Irene",
		"createdDate": "2021-11-23 12:59:54",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 81,
		"totalCountries": 7,
		"totalStates": 29,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 372,
		"schedulePercent": 0.244868,
		"totalGuests": 103,
		"guestPercent": 0.478082,
		"mostGuestDate": "2023-06-29 00:00:00",
		"mostGuestName": "Plaza de EspaÃ±a, Sevilla",
		"mostGuestCity": "",
		"mostGuestAmount": 44
	},
	{
		"id": "4a704af",
		"email": "daniel@krall.se",
		"name": "Danne",
		"createdDate": "2015-02-07 11:31:58",
		"totalOrganizations": 13,
		"totalTours": 6,
		"totalDays": 413,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 126,
		"eventPercent": 0.633691,
		"totalScheduleItems": 1646,
		"schedulePercent": 0.652761,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6997982",
		"email": "mrk.strand@gmail.com",
		"name": "Mark",
		"createdDate": "2015-05-02 19:59:16",
		"totalOrganizations": 6,
		"totalTours": 2,
		"totalDays": 366,
		"totalCountries": 4,
		"totalStates": 35,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 1517,
		"schedulePercent": 0.622434,
		"totalGuests": 1115,
		"guestPercent": 0.841875,
		"mostGuestDate": "2023-04-29 00:00:00",
		"mostGuestName": "Saenger Theatre",
		"mostGuestCity": "New Orleans",
		"mostGuestAmount": 130
	},
	{
		"id": "850e23f",
		"email": "shebz420@gmail.com",
		"name": "Sheba",
		"createdDate": "2019-05-26 22:06:46",
		"totalOrganizations": 1,
		"totalTours": 6,
		"totalDays": 71,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 408,
		"schedulePercent": 0.260363,
		"totalGuests": 293,
		"guestPercent": 0.599258,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Le Poisson Rouge",
		"mostGuestCity": "New York",
		"mostGuestAmount": 29
	},
	{
		"id": "dc4fe76",
		"email": "info@duncecapmanagement.com",
		"name": "Jeremy ",
		"createdDate": "2012-11-11 20:08:26",
		"totalOrganizations": 30,
		"totalTours": 21,
		"totalDays": 868,
		"totalCountries": 19,
		"totalStates": 72,
		"totalEvents": 357,
		"eventPercent": 0.94504,
		"totalScheduleItems": 5001,
		"schedulePercent": 0.961992,
		"totalGuests": 1895,
		"guestPercent": 0.928221,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Meridian Centre",
		"mostGuestCity": "St. Catharines",
		"mostGuestAmount": 110
	},
	{
		"id": "cae5cfa",
		"email": "Melonie.harrison2@gmail.com",
		"name": "Melonie",
		"createdDate": "2013-09-15 22:53:41",
		"totalOrganizations": 8,
		"totalTours": 2,
		"totalDays": 129,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 1197,
		"schedulePercent": 0.539531,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-03-21 00:00:00",
		"mostGuestName": "Peoria Civic Center",
		"mostGuestCity": "Peoria",
		"mostGuestAmount": 3
	},
	{
		"id": "e92bfa1",
		"email": "kycabot@mac.com",
		"name": "Philip",
		"createdDate": "2009-05-05 17:17:18",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 714,
		"totalCountries": 8,
		"totalStates": 31,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 3316,
		"schedulePercent": 0.879354,
		"totalGuests": 955,
		"guestPercent": 0.814064,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "London Palladium",
		"mostGuestCity": "London",
		"mostGuestAmount": 53
	},
	{
		"id": "1fa9916",
		"email": "abs@thetourmanagers.com",
		"name": "Abigail",
		"createdDate": "2016-10-02 12:10:33",
		"totalOrganizations": 20,
		"totalTours": 16,
		"totalDays": 2228,
		"totalCountries": 26,
		"totalStates": 76,
		"totalEvents": 299,
		"eventPercent": 0.914978,
		"totalScheduleItems": 3261,
		"schedulePercent": 0.874719,
		"totalGuests": 228,
		"guestPercent": 0.568137,
		"mostGuestDate": "2023-11-26 00:00:00",
		"mostGuestName": "Truist Arena",
		"mostGuestCity": "Highland Heights",
		"mostGuestAmount": 92
	},
	{
		"id": "fc581bb",
		"email": "bray@maddjett.com",
		"name": "Bray",
		"createdDate": "2022-05-17 20:23:15",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 364,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 2710,
		"schedulePercent": 0.820686,
		"totalGuests": 1653,
		"guestPercent": 0.907032,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Raising Cane's River Center",
		"mostGuestCity": "Baton Rouge",
		"mostGuestAmount": 122
	},
	{
		"id": "63fa23a",
		"email": "j.brangis@gmail.com",
		"name": "Jay",
		"createdDate": "2019-05-21 15:55:30",
		"totalOrganizations": 9,
		"totalTours": 24,
		"totalDays": 424,
		"totalCountries": 4,
		"totalStates": 20,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 1048,
		"schedulePercent": 0.496358,
		"totalGuests": 83,
		"guestPercent": 0.45835,
		"mostGuestDate": "2023-02-11 00:00:00",
		"mostGuestName": "Sidney Myer Music Bowl",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 25
	},
	{
		"id": "6c07bae",
		"email": "myak@wildkratts.com",
		"name": "Mya",
		"createdDate": "2018-01-15 20:03:39",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 38,
		"totalCountries": 2,
		"totalStates": 16,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 436,
		"schedulePercent": 0.270825,
		"totalGuests": 156,
		"guestPercent": 0.518607,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Meridian Theatres @ Centrepointe",
		"mostGuestCity": "Ottawa",
		"mostGuestAmount": 37
	},
	{
		"id": "b308312",
		"email": "jagnertours@gmail.com",
		"name": "Jesse",
		"createdDate": "2022-01-01 00:50:39",
		"totalOrganizations": 12,
		"totalTours": 10,
		"totalDays": 622,
		"totalCountries": 2,
		"totalStates": 36,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 1495,
		"schedulePercent": 0.616872,
		"totalGuests": 1147,
		"guestPercent": 0.848894,
		"mostGuestDate": "2023-09-29 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 68
	},
	{
		"id": "ba813a0",
		"email": "johanna@yesmamsound.com",
		"name": "Johanna",
		"createdDate": "2017-06-14 08:06:15",
		"totalOrganizations": 24,
		"totalTours": 9,
		"totalDays": 153,
		"totalCountries": 11,
		"totalStates": 16,
		"totalEvents": 73,
		"eventPercent": 0.424315,
		"totalScheduleItems": 696,
		"schedulePercent": 0.379023,
		"totalGuests": 35,
		"guestPercent": 0.396901,
		"mostGuestDate": "2023-01-09 00:00:00",
		"mostGuestName": "The Enmore Theatre",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 15
	},
	{
		"id": "8d3b139",
		"email": "billings.justinm@gmail.com",
		"name": "Justin",
		"createdDate": "2017-03-23 17:52:06",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 219,
		"totalCountries": 7,
		"totalStates": 34,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 934,
		"schedulePercent": 0.461131,
		"totalGuests": 1355,
		"guestPercent": 0.878029,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Kings Theatre",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 88
	},
	{
		"id": "5c6f6c4",
		"email": "shanemarsh.prod@gmail.com",
		"name": "SHANE",
		"createdDate": "2022-09-20 07:15:06",
		"totalOrganizations": 24,
		"totalTours": 9,
		"totalDays": 162,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 1207,
		"schedulePercent": 0.541915,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fc4216e",
		"email": "prod@thetalentboutique.fr",
		"name": "Loic",
		"createdDate": "2023-03-03 09:41:13",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 105,
		"totalCountries": 6,
		"totalStates": 20,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 840,
		"schedulePercent": 0.433982,
		"totalGuests": 156,
		"guestPercent": 0.518607,
		"mostGuestDate": "2023-11-17 00:00:00",
		"mostGuestName": "Rockstore",
		"mostGuestCity": "Montpellier",
		"mostGuestAmount": 20
	},
	{
		"id": "b6cebf4",
		"email": "liamrippon@me.com",
		"name": "LIAM",
		"createdDate": "2011-02-19 16:14:34",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 131,
		"totalCountries": 7,
		"totalStates": 12,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 358,
		"schedulePercent": 0.237055,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bf3a081",
		"email": "thomasmayorgas@gmail.com",
		"name": "Thomas",
		"createdDate": "2020-01-10 08:07:31",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 939,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 790,
		"schedulePercent": 0.416501,
		"totalGuests": 32,
		"guestPercent": 0.392531,
		"mostGuestDate": "2023-12-06 00:00:00",
		"mostGuestName": "Paloma",
		"mostGuestCity": "Nimes",
		"mostGuestAmount": 7
	},
	{
		"id": "6726b89",
		"email": "jay@riseupartists.com",
		"name": "Jay",
		"createdDate": "2022-11-15 17:40:04",
		"totalOrganizations": 4,
		"totalTours": 15,
		"totalDays": 129,
		"totalCountries": 2,
		"totalStates": 36,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 805,
		"schedulePercent": 0.422461,
		"totalGuests": 329,
		"guestPercent": 0.618064,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 37
	},
	{
		"id": "8dd9bd8",
		"email": "matt@team-love.com",
		"name": "Matt",
		"createdDate": "2011-12-13 22:45:16",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 82,
		"totalCountries": 6,
		"totalStates": 19,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 317,
		"schedulePercent": 0.216395,
		"totalGuests": 270,
		"guestPercent": 0.589591,
		"mostGuestDate": "2023-10-24 00:00:00",
		"mostGuestName": "Northcote Theatre",
		"mostGuestCity": "Northcote",
		"mostGuestAmount": 47
	},
	{
		"id": "e407eb8",
		"email": "interstatela46@gmail.com",
		"name": "Luchia",
		"createdDate": "2022-09-21 22:01:43",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 125,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 957,
		"schedulePercent": 0.468017,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ed7de04",
		"email": "maryashleymorgan@gmail.com",
		"name": "Mary",
		"createdDate": "2013-12-24 03:40:21",
		"totalOrganizations": 14,
		"totalTours": 13,
		"totalDays": 1502,
		"totalCountries": 4,
		"totalStates": 34,
		"totalEvents": 172,
		"eventPercent": 0.755661,
		"totalScheduleItems": 3103,
		"schedulePercent": 0.861873,
		"totalGuests": 837,
		"guestPercent": 0.785194,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Texas Trust CU Theatre",
		"mostGuestCity": "Grand Prairie",
		"mostGuestAmount": 69
	},
	{
		"id": "345b5d0",
		"email": "Greyzen10@yahoo.com",
		"name": "Greyson",
		"createdDate": "2016-11-04 05:04:21",
		"totalOrganizations": 8,
		"totalTours": 15,
		"totalDays": 1169,
		"totalCountries": 8,
		"totalStates": 57,
		"totalEvents": 300,
		"eventPercent": 0.915508,
		"totalScheduleItems": 5338,
		"schedulePercent": 0.970732,
		"totalGuests": 1499,
		"guestPercent": 0.894186,
		"mostGuestDate": "2023-05-18 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 60
	},
	{
		"id": "86b483b",
		"email": "pbinder@calmusicexpress.com",
		"name": "Paul",
		"createdDate": "2010-06-15 17:46:14",
		"totalOrganizations": 13,
		"totalTours": 13,
		"totalDays": 331,
		"totalCountries": 10,
		"totalStates": 40,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 3164,
		"schedulePercent": 0.866905,
		"totalGuests": 2062,
		"guestPercent": 0.937492,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Petco Park",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 372
	},
	{
		"id": "537f4c0",
		"email": "teisenhood@gmail.com",
		"name": "Thomas",
		"createdDate": "2019-08-12 01:24:59",
		"totalOrganizations": 9,
		"totalTours": 8,
		"totalDays": 799,
		"totalCountries": 6,
		"totalStates": 56,
		"totalEvents": 306,
		"eventPercent": 0.918951,
		"totalScheduleItems": 3814,
		"schedulePercent": 0.912462,
		"totalGuests": 788,
		"guestPercent": 0.774202,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "FirstBank Amphitheater",
		"mostGuestCity": "Franklin",
		"mostGuestAmount": 92
	},
	{
		"id": "ebc9400",
		"email": "declan@manchestercollective.co.uk",
		"name": "Declan",
		"createdDate": "2022-09-05 17:13:44",
		"totalOrganizations": 1,
		"totalTours": 8,
		"totalDays": 89,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 437,
		"schedulePercent": 0.271355,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "Howard Assembly Room",
		"mostGuestCity": "Leeds",
		"mostGuestAmount": 2
	},
	{
		"id": "8a38c97",
		"email": "info@tizi.ca",
		"name": "Olivia",
		"createdDate": "2023-01-18 15:54:38",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 60,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 282,
		"schedulePercent": 0.199311,
		"totalGuests": 145,
		"guestPercent": 0.509734,
		"mostGuestDate": "2023-02-09 00:00:00",
		"mostGuestName": "Imperial Bell",
		"mostGuestCity": "Quebec",
		"mostGuestAmount": 17
	},
	{
		"id": "734f019",
		"email": "mikywoodztouring@gmail.com",
		"name": "Miky",
		"createdDate": "2023-06-07 00:40:25",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 76,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 173,
		"schedulePercent": 0.134684,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1ce687d",
		"email": "joerg@live-legend.de",
		"name": "Joerg",
		"createdDate": "2014-03-11 11:52:00",
		"totalOrganizations": 9,
		"totalTours": 7,
		"totalDays": 379,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 116,
		"eventPercent": 0.598464,
		"totalScheduleItems": 1139,
		"schedulePercent": 0.523242,
		"totalGuests": 644,
		"guestPercent": 0.737253,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Schloss Oranienburg",
		"mostGuestCity": "Oranienburg",
		"mostGuestAmount": 95
	},
	{
		"id": "8ecb852",
		"email": "info@egtouring.com",
		"name": "EG",
		"createdDate": "2014-09-23 17:50:19",
		"totalOrganizations": 21,
		"totalTours": 17,
		"totalDays": 977,
		"totalCountries": 20,
		"totalStates": 66,
		"totalEvents": 371,
		"eventPercent": 0.949146,
		"totalScheduleItems": 5564,
		"schedulePercent": 0.974308,
		"totalGuests": 2066,
		"guestPercent": 0.937889,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Don Valley Bowl",
		"mostGuestCity": "Sheffield",
		"mostGuestAmount": 108
	},
	{
		"id": "f045afe",
		"email": "miklosbartovics@gmail.com",
		"name": "Miklos",
		"createdDate": "2017-12-27 18:54:45",
		"totalOrganizations": 12,
		"totalTours": 22,
		"totalDays": 597,
		"totalCountries": 28,
		"totalStates": 103,
		"totalEvents": 304,
		"eventPercent": 0.917892,
		"totalScheduleItems": 4831,
		"schedulePercent": 0.95643,
		"totalGuests": 2260,
		"guestPercent": 0.946232,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Petco Park",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 186
	},
	{
		"id": "1a89335",
		"email": "danieleposey@gmail.com",
		"name": "Daniel",
		"createdDate": "2020-03-04 21:07:26",
		"totalOrganizations": 11,
		"totalTours": 22,
		"totalDays": 1116,
		"totalCountries": 13,
		"totalStates": 66,
		"totalEvents": 407,
		"eventPercent": 0.958681,
		"totalScheduleItems": 4556,
		"schedulePercent": 0.947557,
		"totalGuests": 1153,
		"guestPercent": 0.849556,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 59
	},
	{
		"id": "c8077da",
		"email": "jen@thersegroup.com",
		"name": "Jen",
		"createdDate": "2022-05-24 18:33:22",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 275,
		"totalCountries": 14,
		"totalStates": 57,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 3379,
		"schedulePercent": 0.88571,
		"totalGuests": 4126,
		"guestPercent": 0.985035,
		"mostGuestDate": "2023-04-21 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 156
	},
	{
		"id": "af7e1c5",
		"email": "jessica@jubel.se",
		"name": "Jessica",
		"createdDate": "2018-12-03 12:08:56",
		"totalOrganizations": 9,
		"totalTours": 12,
		"totalDays": 420,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 437,
		"schedulePercent": 0.271355,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "64f3162",
		"email": "anna@spinningtopmusic.com",
		"name": "Anna",
		"createdDate": "2021-02-03 04:07:36",
		"totalOrganizations": 15,
		"totalTours": 39,
		"totalDays": 781,
		"totalCountries": 4,
		"totalStates": 26,
		"totalEvents": 122,
		"eventPercent": 0.620977,
		"totalScheduleItems": 1248,
		"schedulePercent": 0.551715,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-05-06 00:00:00",
		"mostGuestName": "Vinyl",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 1
	},
	{
		"id": "ec0d717",
		"email": "oristol@gmail.com",
		"name": "Oscar",
		"createdDate": "2022-12-12 20:42:20",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 130,
		"totalCountries": 12,
		"totalStates": 35,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 1299,
		"schedulePercent": 0.566018,
		"totalGuests": 350,
		"guestPercent": 0.629718,
		"mostGuestDate": "2023-03-10 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 82
	},
	{
		"id": "4dc6b0b",
		"email": "louis99091@gmail.com",
		"name": "Louis's Test Account",
		"createdDate": "2018-12-06 21:57:57",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 69,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 477,
		"schedulePercent": 0.288041,
		"totalGuests": 276,
		"guestPercent": 0.592902,
		"mostGuestDate": "2023-08-05 00:00:00",
		"mostGuestName": "Ullevi",
		"mostGuestCity": "GÃ¶teborg",
		"mostGuestAmount": 41
	},
	{
		"id": "6510ad8",
		"email": "Sarah@pivotal-agency.com",
		"name": "sarah ",
		"createdDate": "2022-10-19 23:48:14",
		"totalOrganizations": 10,
		"totalTours": 10,
		"totalDays": 310,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 181,
		"eventPercent": 0.771818,
		"totalScheduleItems": 1143,
		"schedulePercent": 0.524699,
		"totalGuests": 562,
		"guestPercent": 0.714607,
		"mostGuestDate": "2023-06-24 00:00:00",
		"mostGuestName": "Salvage Station",
		"mostGuestCity": "Asheville",
		"mostGuestAmount": 48
	},
	{
		"id": "b86e463",
		"email": "strangenews@gmail.com",
		"name": "Matthew",
		"createdDate": "2022-04-07 02:58:13",
		"totalOrganizations": 44,
		"totalTours": 21,
		"totalDays": 457,
		"totalCountries": 12,
		"totalStates": 43,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 2081,
		"schedulePercent": 0.731956,
		"totalGuests": 1554,
		"guestPercent": 0.899484,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Powerstation",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 88
	},
	{
		"id": "3467d86",
		"email": "alex.hayley@windmill.org.au",
		"name": "Alex",
		"createdDate": "2020-11-01 23:09:22",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 110,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 475,
		"schedulePercent": 0.287247,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1f4847a",
		"email": "talktochrisp@mac.com",
		"name": "Chris",
		"createdDate": "2011-08-15 05:37:55",
		"totalOrganizations": 14,
		"totalTours": 2,
		"totalDays": 117,
		"totalCountries": 1,
		"totalStates": 25,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 246,
		"schedulePercent": 0.179976,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": "2023-09-05 00:00:00",
		"mostGuestName": "9:30 Club",
		"mostGuestCity": "Washington",
		"mostGuestAmount": 0
	},
	{
		"id": "80f7bf7",
		"email": "beau@redlightmanagement.com",
		"name": "Beau",
		"createdDate": "2017-09-06 15:50:10",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 161,
		"totalCountries": 6,
		"totalStates": 34,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1380,
		"schedulePercent": 0.587207,
		"totalGuests": 960,
		"guestPercent": 0.815786,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 187
	},
	{
		"id": "e5e61ff",
		"email": "Joshua@jcubeentertainment.com",
		"name": "Joshua",
		"createdDate": "2016-04-27 14:42:11",
		"totalOrganizations": 2,
		"totalTours": 8,
		"totalDays": 421,
		"totalCountries": 5,
		"totalStates": 25,
		"totalEvents": 196,
		"eventPercent": 0.798835,
		"totalScheduleItems": 1769,
		"schedulePercent": 0.678453,
		"totalGuests": 279,
		"guestPercent": 0.593961,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "Chickie Wah Wah",
		"mostGuestCity": "New Orleans",
		"mostGuestAmount": 32
	},
	{
		"id": "db29e84",
		"email": "poolkidsband@gmail.com",
		"name": "Pool",
		"createdDate": "2023-04-10 16:40:34",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 60,
		"totalCountries": 1,
		"totalStates": 21,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 333,
		"schedulePercent": 0.225268,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-10-20 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 3
	},
	{
		"id": "32505b0",
		"email": "ethanchambers16@gmail.com",
		"name": "Ethan",
		"createdDate": "2017-07-07 22:51:24",
		"totalOrganizations": 10,
		"totalTours": 16,
		"totalDays": 367,
		"totalCountries": 7,
		"totalStates": 36,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 1726,
		"schedulePercent": 0.67064,
		"totalGuests": 29,
		"guestPercent": 0.387896,
		"mostGuestDate": "2023-04-19 00:00:00",
		"mostGuestName": "The Paramount Theater",
		"mostGuestCity": "Charlottesville",
		"mostGuestAmount": 13
	},
	{
		"id": "dab22e8",
		"email": "tobias.witting@allthingslive.se",
		"name": "Tobias",
		"createdDate": "2022-04-19 10:41:01",
		"totalOrganizations": 11,
		"totalTours": 10,
		"totalDays": 322,
		"totalCountries": 3,
		"totalStates": 27,
		"totalEvents": 266,
		"eventPercent": 0.891935,
		"totalScheduleItems": 2039,
		"schedulePercent": 0.725202,
		"totalGuests": 765,
		"guestPercent": 0.767845,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Slottsskogsvallen",
		"mostGuestCity": "GÃ¶teborg",
		"mostGuestAmount": 72
	},
	{
		"id": "297fe6e",
		"email": "Denniemiller@me.com",
		"name": "Dennie ",
		"createdDate": "2014-10-26 06:57:05",
		"totalOrganizations": 10,
		"totalTours": 5,
		"totalDays": 313,
		"totalCountries": 8,
		"totalStates": 39,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 2316,
		"schedulePercent": 0.767713,
		"totalGuests": 355,
		"guestPercent": 0.632631,
		"mostGuestDate": "2023-08-28 00:00:00",
		"mostGuestName": "Ford Idaho Sports Center",
		"mostGuestCity": "Nampa",
		"mostGuestAmount": 36
	},
	{
		"id": "3032a06",
		"email": "Josephthebandtouring@gmail.com",
		"name": "Joseph",
		"createdDate": "2023-03-25 20:36:50",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 97,
		"totalCountries": 4,
		"totalStates": 32,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 527,
		"schedulePercent": 0.311349,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-05-21 00:00:00",
		"mostGuestName": "Revolution Hall",
		"mostGuestCity": "Portland",
		"mostGuestAmount": 2
	},
	{
		"id": "afe4a81",
		"email": "griffin@sixshooterrecords.com",
		"name": "Griffin",
		"createdDate": "2023-09-27 14:47:24",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 128,
		"totalCountries": 4,
		"totalStates": 6,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 147,
		"schedulePercent": 0.118262,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "134287d",
		"email": "maja@queerstothefront.com",
		"name": "Maja",
		"createdDate": "2022-12-15 01:17:22",
		"totalOrganizations": 2,
		"totalTours": 18,
		"totalDays": 412,
		"totalCountries": 10,
		"totalStates": 31,
		"totalEvents": 202,
		"eventPercent": 0.810091,
		"totalScheduleItems": 1841,
		"schedulePercent": 0.693815,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-02-24 00:00:00",
		"mostGuestName": "Albert Hall",
		"mostGuestCity": "Manchester",
		"mostGuestAmount": 7
	},
	{
		"id": "dab45d7",
		"email": "heinemann@ceres-online.com",
		"name": "Martin",
		"createdDate": "2014-02-07 23:12:20",
		"totalOrganizations": 9,
		"totalTours": 3,
		"totalDays": 437,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 142,
		"eventPercent": 0.680705,
		"totalScheduleItems": 1304,
		"schedulePercent": 0.56721,
		"totalGuests": 1356,
		"guestPercent": 0.878162,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "Mercedes-Benz Arena Berlin",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 154
	},
	{
		"id": "f5fb75a",
		"email": "hjorth@cphmusic.dk",
		"name": "Lasse",
		"createdDate": "2013-12-11 14:40:46",
		"totalOrganizations": 169,
		"totalTours": 71,
		"totalDays": 2137,
		"totalCountries": 6,
		"totalStates": 24,
		"totalEvents": 1982,
		"eventPercent": 0.996027,
		"totalScheduleItems": 8982,
		"schedulePercent": 0.996159,
		"totalGuests": 309,
		"guestPercent": 0.609058,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Tivoli Friheden",
		"mostGuestCity": "Aarhus",
		"mostGuestAmount": 36
	},
	{
		"id": "6ff88f1",
		"email": "wlukepierce@gmail.com",
		"name": "Luke",
		"createdDate": "2012-10-22 19:58:00",
		"totalOrganizations": 13,
		"totalTours": 16,
		"totalDays": 950,
		"totalCountries": 13,
		"totalStates": 64,
		"totalEvents": 232,
		"eventPercent": 0.852073,
		"totalScheduleItems": 2720,
		"schedulePercent": 0.822275,
		"totalGuests": 88,
		"guestPercent": 0.461661,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "Razzmatazz",
		"mostGuestCity": "Barcelona",
		"mostGuestAmount": 16
	},
	{
		"id": "02ab7ea",
		"email": "sandy.bod@hypertension-music.de",
		"name": "Sandy",
		"createdDate": "2023-01-17 10:05:36",
		"totalOrganizations": 2,
		"totalTours": 21,
		"totalDays": 308,
		"totalCountries": 5,
		"totalStates": 30,
		"totalEvents": 212,
		"eventPercent": 0.826778,
		"totalScheduleItems": 1468,
		"schedulePercent": 0.606939,
		"totalGuests": 245,
		"guestPercent": 0.57595,
		"mostGuestDate": "2023-02-14 00:00:00",
		"mostGuestName": "Freiheitshalle",
		"mostGuestCity": "Hof",
		"mostGuestAmount": 41
	},
	{
		"id": "0eb004b",
		"email": "donmuzquiz@gmail.com",
		"name": "Don",
		"createdDate": "2013-05-21 17:42:44",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 547,
		"totalCountries": 7,
		"totalStates": 37,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 1566,
		"schedulePercent": 0.634088,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c073d2a",
		"email": "ajarrin@rocnation.com",
		"name": "Andrew",
		"createdDate": "2020-02-13 16:03:25",
		"totalOrganizations": 10,
		"totalTours": 19,
		"totalDays": 614,
		"totalCountries": 17,
		"totalStates": 82,
		"totalEvents": 321,
		"eventPercent": 0.9265,
		"totalScheduleItems": 4588,
		"schedulePercent": 0.948219,
		"totalGuests": 2878,
		"guestPercent": 0.967686,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Saint Andrewâ€™s Hall",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 80
	},
	{
		"id": "5b6d90c",
		"email": "shawn.brent@me.com",
		"name": "Shawn",
		"createdDate": "2016-02-11 22:41:39",
		"totalOrganizations": 11,
		"totalTours": 3,
		"totalDays": 186,
		"totalCountries": 4,
		"totalStates": 19,
		"totalEvents": 64,
		"eventPercent": 0.381274,
		"totalScheduleItems": 1066,
		"schedulePercent": 0.503509,
		"totalGuests": 76,
		"guestPercent": 0.451596,
		"mostGuestDate": "2023-05-26 00:00:00",
		"mostGuestName": "Daytona Beach Bandshell",
		"mostGuestCity": "Daytona Beach",
		"mostGuestAmount": 17
	},
	{
		"id": "3da9d7a",
		"email": "ilya@full-time.ninja",
		"name": "Ilia",
		"createdDate": "2023-04-25 22:29:10",
		"totalOrganizations": 4,
		"totalTours": 8,
		"totalDays": 216,
		"totalCountries": 4,
		"totalStates": 44,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1132,
		"schedulePercent": 0.520461,
		"totalGuests": 428,
		"guestPercent": 0.664018,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "The Theater at Virgin Hotels",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 40
	},
	{
		"id": "0bfb2f7",
		"email": "matthews@activist.co",
		"name": "Bryce",
		"createdDate": "2023-03-28 18:40:50",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 674,
		"totalCountries": 11,
		"totalStates": 53,
		"totalEvents": 210,
		"eventPercent": 0.823732,
		"totalScheduleItems": 3241,
		"schedulePercent": 0.873262,
		"totalGuests": 1760,
		"guestPercent": 0.917627,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 142
	},
	{
		"id": "a360c39",
		"email": "tobyjohnson01@gmail.com",
		"name": "Toby",
		"createdDate": "2021-12-28 14:58:48",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 682,
		"totalCountries": 16,
		"totalStates": 41,
		"totalEvents": 163,
		"eventPercent": 0.735134,
		"totalScheduleItems": 1969,
		"schedulePercent": 0.71421,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "20d6523",
		"email": "nolando@me.com",
		"name": "Nolan ",
		"createdDate": "2011-05-14 14:02:10",
		"totalOrganizations": 16,
		"totalTours": 17,
		"totalDays": 1965,
		"totalCountries": 11,
		"totalStates": 86,
		"totalEvents": 330,
		"eventPercent": 0.931665,
		"totalScheduleItems": 4426,
		"schedulePercent": 0.942259,
		"totalGuests": 1714,
		"guestPercent": 0.914184,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 137
	},
	{
		"id": "e3c2637",
		"email": "spchambless@me.com",
		"name": "Stephen",
		"createdDate": "2013-07-10 13:26:59",
		"totalOrganizations": 12,
		"totalTours": 15,
		"totalDays": 914,
		"totalCountries": 6,
		"totalStates": 54,
		"totalEvents": 379,
		"eventPercent": 0.951,
		"totalScheduleItems": 4933,
		"schedulePercent": 0.960403,
		"totalGuests": 3864,
		"guestPercent": 0.982122,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Gas South Arena",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 157
	},
	{
		"id": "ff5a843",
		"email": "n.miltiadou@gmail.com",
		"name": "Nikita",
		"createdDate": "2018-04-16 09:38:15",
		"totalOrganizations": 16,
		"totalTours": 29,
		"totalDays": 635,
		"totalCountries": 6,
		"totalStates": 16,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 1050,
		"schedulePercent": 0.497153,
		"totalGuests": 142,
		"guestPercent": 0.506688,
		"mostGuestDate": "2023-04-20 00:00:00",
		"mostGuestName": "The Corner Hotel",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 87
	},
	{
		"id": "752196d",
		"email": "teeroyharrison@gmail.com",
		"name": "Troy",
		"createdDate": "2011-12-29 02:31:12",
		"totalOrganizations": 17,
		"totalTours": 27,
		"totalDays": 619,
		"totalCountries": 16,
		"totalStates": 68,
		"totalEvents": 280,
		"eventPercent": 0.901867,
		"totalScheduleItems": 2971,
		"schedulePercent": 0.848497,
		"totalGuests": 1144,
		"guestPercent": 0.848232,
		"mostGuestDate": "2023-04-26 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 81
	},
	{
		"id": "27175cb",
		"email": "alex@aferrall.co.uk",
		"name": "Alexandra",
		"createdDate": "2012-04-14 23:04:16",
		"totalOrganizations": 17,
		"totalTours": 7,
		"totalDays": 155,
		"totalCountries": 8,
		"totalStates": 26,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 1310,
		"schedulePercent": 0.568799,
		"totalGuests": 391,
		"guestPercent": 0.649715,
		"mostGuestDate": "2023-10-03 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 49
	},
	{
		"id": "e757d97",
		"email": "siripathaneadam@gmail.com",
		"name": "Adam ",
		"createdDate": "2015-10-21 22:49:18",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 209,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 842,
		"schedulePercent": 0.434909,
		"totalGuests": 74,
		"guestPercent": 0.449212,
		"mostGuestDate": "2023-07-23 00:00:00",
		"mostGuestName": "Soaring Eagle Casino",
		"mostGuestCity": "Mt Pleasant",
		"mostGuestAmount": 28
	},
	{
		"id": "98539f7",
		"email": "smcguire27@me.com",
		"name": "Stephen",
		"createdDate": "2012-10-06 18:40:40",
		"totalOrganizations": 7,
		"totalTours": 2,
		"totalDays": 129,
		"totalCountries": 7,
		"totalStates": 19,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 714,
		"schedulePercent": 0.386174,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4c16afa",
		"email": "kristen@mickmgmt.com",
		"name": "Kristen",
		"createdDate": "2019-05-06 22:18:50",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 802,
		"totalCountries": 12,
		"totalStates": 59,
		"totalEvents": 184,
		"eventPercent": 0.77791,
		"totalScheduleItems": 2809,
		"schedulePercent": 0.83287,
		"totalGuests": 2674,
		"guestPercent": 0.959343,
		"mostGuestDate": "2023-08-20 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 99
	},
	{
		"id": "27c837c",
		"email": "a.schroeder@msk-live.de",
		"name": "Alexej",
		"createdDate": "2022-11-07 16:06:44",
		"totalOrganizations": 11,
		"totalTours": 11,
		"totalDays": 222,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 1137,
		"schedulePercent": 0.522712,
		"totalGuests": 624,
		"guestPercent": 0.732751,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "E-Werk",
		"mostGuestCity": "Koln",
		"mostGuestAmount": 82
	},
	{
		"id": "9b16900",
		"email": "mmmeyer7@gmail.com",
		"name": "Michele",
		"createdDate": "2022-03-30 17:59:35",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 282,
		"totalCountries": 8,
		"totalStates": 40,
		"totalEvents": 157,
		"eventPercent": 0.720567,
		"totalScheduleItems": 2634,
		"schedulePercent": 0.812343,
		"totalGuests": 39,
		"guestPercent": 0.404185,
		"mostGuestDate": "2023-01-08 00:00:00",
		"mostGuestName": "Belly Up",
		"mostGuestCity": "Aspen",
		"mostGuestAmount": 20
	},
	{
		"id": "6ec7b20",
		"email": "willywag@me.com",
		"name": "Will",
		"createdDate": "2013-09-09 21:34:55",
		"totalOrganizations": 18,
		"totalTours": 14,
		"totalDays": 193,
		"totalCountries": 8,
		"totalStates": 22,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 1470,
		"schedulePercent": 0.607469,
		"totalGuests": 548,
		"guestPercent": 0.709178,
		"mostGuestDate": "2023-12-06 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 53
	},
	{
		"id": "78ec78c",
		"email": "nancyghosh@aol.com",
		"name": "Nancy",
		"createdDate": "2012-02-29 19:27:27",
		"totalOrganizations": 9,
		"totalTours": 3,
		"totalDays": 94,
		"totalCountries": 5,
		"totalStates": 23,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 525,
		"schedulePercent": 0.309628,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "93fb2ca",
		"email": "lvazquez@l-productions.fr",
		"name": "Laura",
		"createdDate": "2022-03-21 14:26:48",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 916,
		"totalCountries": 4,
		"totalStates": 25,
		"totalEvents": 202,
		"eventPercent": 0.810091,
		"totalScheduleItems": 2472,
		"schedulePercent": 0.789167,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f6ad9bb",
		"email": "elliotlidard@gmail.com",
		"name": "Elliot",
		"createdDate": "2012-09-25 16:36:18",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 794,
		"totalCountries": 11,
		"totalStates": 42,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 2416,
		"schedulePercent": 0.782016,
		"totalGuests": 1021,
		"guestPercent": 0.827837,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 93
	},
	{
		"id": "f214ba5",
		"email": "Oakes.sound@yahoo.com",
		"name": "Lauren",
		"createdDate": "2016-09-16 20:25:38",
		"totalOrganizations": 12,
		"totalTours": 33,
		"totalDays": 881,
		"totalCountries": 6,
		"totalStates": 53,
		"totalEvents": 286,
		"eventPercent": 0.906502,
		"totalScheduleItems": 3017,
		"schedulePercent": 0.852602,
		"totalGuests": 776,
		"guestPercent": 0.771156,
		"mostGuestDate": "2023-11-08 00:00:00",
		"mostGuestName": "CMA Theater at the Country Music Hall of Fame and Museum",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 41
	},
	{
		"id": "738a204",
		"email": "debi@rocktheworldtravel.com",
		"name": "Debi",
		"createdDate": "2016-03-11 00:16:59",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 968,
		"totalCountries": 27,
		"totalStates": 78,
		"totalEvents": 227,
		"eventPercent": 0.844391,
		"totalScheduleItems": 2739,
		"schedulePercent": 0.824527,
		"totalGuests": 618,
		"guestPercent": 0.730897,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "The Majestic Ventura Theater",
		"mostGuestCity": "Ventura",
		"mostGuestAmount": 43
	},
	{
		"id": "d182154",
		"email": "te@godmodemusic.com",
		"name": "Talya",
		"createdDate": "2018-09-27 01:09:13",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 1450,
		"totalCountries": 16,
		"totalStates": 56,
		"totalEvents": 240,
		"eventPercent": 0.86174,
		"totalScheduleItems": 2020,
		"schedulePercent": 0.72348,
		"totalGuests": 515,
		"guestPercent": 0.697126,
		"mostGuestDate": "2023-05-11 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 50
	},
	{
		"id": "859daa7",
		"email": "kim.gray@mac.com",
		"name": "Kim",
		"createdDate": "2011-05-09 06:20:52",
		"totalOrganizations": 10,
		"totalTours": 14,
		"totalDays": 1047,
		"totalCountries": 7,
		"totalStates": 33,
		"totalEvents": 491,
		"eventPercent": 0.972851,
		"totalScheduleItems": 6969,
		"schedulePercent": 0.989008,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "Lincoln Financial Field",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 4
	},
	{
		"id": "634191c",
		"email": "gregmlevitt@gmail.com",
		"name": "Greg",
		"createdDate": "2013-06-12 14:24:02",
		"totalOrganizations": 15,
		"totalTours": 6,
		"totalDays": 329,
		"totalCountries": 4,
		"totalStates": 36,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 978,
		"schedulePercent": 0.474772,
		"totalGuests": 194,
		"guestPercent": 0.54602,
		"mostGuestDate": "2023-09-14 00:00:00",
		"mostGuestName": "The Mission Ballroom",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 52
	},
	{
		"id": "fc0d397",
		"email": "shelby.marvel@redlightmanagement.com",
		"name": "Change",
		"createdDate": "2019-11-20 17:27:01",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 415,
		"totalCountries": 16,
		"totalStates": 44,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 1120,
		"schedulePercent": 0.516223,
		"totalGuests": 151,
		"guestPercent": 0.515296,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "Travis County Exposition Center- Leudecke Arena",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 28
	},
	{
		"id": "9211c39",
		"email": "admin@rockmafia.com",
		"name": "Alex",
		"createdDate": "2023-05-01 20:16:43",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 152,
		"totalCountries": 10,
		"totalStates": 42,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 1223,
		"schedulePercent": 0.546418,
		"totalGuests": 96,
		"guestPercent": 0.471063,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "El Rey Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 54
	},
	{
		"id": "07a50b9",
		"email": "didirri1@gmail.com",
		"name": "Didirri",
		"createdDate": "2018-05-24 19:45:24",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 45,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 119,
		"schedulePercent": 0.102106,
		"totalGuests": 11,
		"guestPercent": 0.345782,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "Mary's Underground",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 6
	},
	{
		"id": "5e06260",
		"email": "warren@qprime.com",
		"name": "Warren ",
		"createdDate": "2010-09-29 21:55:54",
		"totalOrganizations": 16,
		"totalTours": 34,
		"totalDays": 806,
		"totalCountries": 14,
		"totalStates": 74,
		"totalEvents": 376,
		"eventPercent": 0.949676,
		"totalScheduleItems": 4145,
		"schedulePercent": 0.930738,
		"totalGuests": 3722,
		"guestPercent": 0.9804,
		"mostGuestDate": "2023-10-02 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 405
	},
	{
		"id": "14ef319",
		"email": "jack@open-mgmt.com",
		"name": "Jack",
		"createdDate": "2018-04-02 22:06:35",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 983,
		"totalCountries": 10,
		"totalStates": 54,
		"totalEvents": 249,
		"eventPercent": 0.870746,
		"totalScheduleItems": 3129,
		"schedulePercent": 0.864256,
		"totalGuests": 1876,
		"guestPercent": 0.926632,
		"mostGuestDate": "2023-03-10 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 180
	},
	{
		"id": "a8a8302",
		"email": "joona@allday.fi",
		"name": "Joona",
		"createdDate": "2023-03-06 09:21:25",
		"totalOrganizations": 31,
		"totalTours": 41,
		"totalDays": 437,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 437,
		"eventPercent": 0.96623,
		"totalScheduleItems": 1466,
		"schedulePercent": 0.606542,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "348317e",
		"email": "Lukenashville@gmail.com",
		"name": "Luke",
		"createdDate": "2011-03-16 01:14:18",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 714,
		"totalCountries": 3,
		"totalStates": 35,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 1832,
		"schedulePercent": 0.692094,
		"totalGuests": 1161,
		"guestPercent": 0.85141,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 105
	},
	{
		"id": "dc5e3eb",
		"email": "sergio@ginmusica.com",
		"name": "Sergio",
		"createdDate": "2019-03-14 14:03:54",
		"totalOrganizations": 10,
		"totalTours": 8,
		"totalDays": 174,
		"totalCountries": 5,
		"totalStates": 32,
		"totalEvents": 171,
		"eventPercent": 0.75394,
		"totalScheduleItems": 735,
		"schedulePercent": 0.39412,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a8ae3e1",
		"email": "karenne.81@gmail.com",
		"name": "Karen",
		"createdDate": "2019-11-03 15:23:31",
		"totalOrganizations": 3,
		"totalTours": 27,
		"totalDays": 738,
		"totalCountries": 16,
		"totalStates": 73,
		"totalEvents": 304,
		"eventPercent": 0.917892,
		"totalScheduleItems": 3835,
		"schedulePercent": 0.914581,
		"totalGuests": 1896,
		"guestPercent": 0.928486,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "T-Mobile Arena",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 118
	},
	{
		"id": "f8f4e65",
		"email": "andrew.mcclymont@lobbycall.com",
		"name": "Andrew",
		"createdDate": "2020-01-28 19:06:28",
		"totalOrganizations": 8,
		"totalTours": 13,
		"totalDays": 268,
		"totalCountries": 11,
		"totalStates": 21,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 1596,
		"schedulePercent": 0.641769,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-06-29 00:00:00",
		"mostGuestName": "Rock Werchter Festival",
		"mostGuestCity": "Werchter",
		"mostGuestAmount": 3
	},
	{
		"id": "979b58e",
		"email": "sensesfailmgmt@gmail.com",
		"name": "Buddy",
		"createdDate": "2014-09-03 20:06:23",
		"totalOrganizations": 9,
		"totalTours": 24,
		"totalDays": 381,
		"totalCountries": 15,
		"totalStates": 69,
		"totalEvents": 227,
		"eventPercent": 0.844391,
		"totalScheduleItems": 2921,
		"schedulePercent": 0.844524,
		"totalGuests": 1254,
		"guestPercent": 0.865713,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Soma",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 77
	},
	{
		"id": "f1eca51",
		"email": "daniel@babyrecords.net",
		"name": "DANIEL",
		"createdDate": "2023-05-04 16:37:05",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 391,
		"totalCountries": 6,
		"totalStates": 16,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 723,
		"schedulePercent": 0.38975,
		"totalGuests": 8,
		"guestPercent": 0.336644,
		"mostGuestDate": "2023-09-03 00:00:00",
		"mostGuestName": "Lakewood Amphitheatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 8
	},
	{
		"id": "d61a123",
		"email": "richardtm.haleyreinhart@gmail.com",
		"name": "Richard",
		"createdDate": "2019-02-05 02:19:21",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 75,
		"totalCountries": 1,
		"totalStates": 24,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 447,
		"schedulePercent": 0.275328,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d808b03",
		"email": "megan.boardman@redlightmanagement.com",
		"name": "Megan",
		"createdDate": "2018-02-22 18:30:14",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 983,
		"totalCountries": 5,
		"totalStates": 56,
		"totalEvents": 314,
		"eventPercent": 0.922527,
		"totalScheduleItems": 4929,
		"schedulePercent": 0.96027,
		"totalGuests": 2831,
		"guestPercent": 0.966097,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Mattress Firm Amphitheatre",
		"mostGuestCity": "Chula Vista",
		"mostGuestAmount": 136
	},
	{
		"id": "1147ad5",
		"email": "levvalivet1@gmail.com",
		"name": "Ã…ge",
		"createdDate": "2023-03-02 19:33:39",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 251,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 236,
		"schedulePercent": 0.172295,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "199beb7",
		"email": "ianjmcarthur@mac.com",
		"name": "Ian",
		"createdDate": "2012-01-23 17:35:35",
		"totalOrganizations": 14,
		"totalTours": 17,
		"totalDays": 171,
		"totalCountries": 8,
		"totalStates": 41,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 908,
		"schedulePercent": 0.454244,
		"totalGuests": 61,
		"guestPercent": 0.435571,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "The Masonic Lodge at Hollywood Forever",
		"mostGuestCity": "",
		"mostGuestAmount": 14
	},
	{
		"id": "93b6142",
		"email": "maxtaylorgrant@icloud.com",
		"name": "Max",
		"createdDate": "2017-03-27 12:03:17",
		"totalOrganizations": 10,
		"totalTours": 13,
		"totalDays": 254,
		"totalCountries": 16,
		"totalStates": 49,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1630,
		"schedulePercent": 0.649186,
		"totalGuests": 687,
		"guestPercent": 0.750232,
		"mostGuestDate": "2023-12-16 00:00:00",
		"mostGuestName": "OVO Arena Wembley",
		"mostGuestCity": "London",
		"mostGuestAmount": 119
	},
	{
		"id": "5285246",
		"email": "msmainh@gmail.com",
		"name": "Marina",
		"createdDate": "2018-01-23 22:23:42",
		"totalOrganizations": 2,
		"totalTours": 18,
		"totalDays": 244,
		"totalCountries": 8,
		"totalStates": 24,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 684,
		"schedulePercent": 0.374388,
		"totalGuests": 100,
		"guestPercent": 0.473977,
		"mostGuestDate": "2023-03-14 00:00:00",
		"mostGuestName": "The Carolina Theatre",
		"mostGuestCity": "Durham",
		"mostGuestAmount": 13
	},
	{
		"id": "bd56a36",
		"email": "audreysiataga@gmail.com",
		"name": "Audrey",
		"createdDate": "2019-03-11 23:58:37",
		"totalOrganizations": 7,
		"totalTours": 15,
		"totalDays": 373,
		"totalCountries": 11,
		"totalStates": 46,
		"totalEvents": 101,
		"eventPercent": 0.547874,
		"totalScheduleItems": 929,
		"schedulePercent": 0.459409,
		"totalGuests": 1140,
		"guestPercent": 0.84757,
		"mostGuestDate": "2023-05-19 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 194
	},
	{
		"id": "d0b32e6",
		"email": "deeandtee2@gmail.com",
		"name": "Denise",
		"createdDate": "2019-07-15 19:01:51",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 491,
		"totalCountries": 5,
		"totalStates": 38,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 496,
		"schedulePercent": 0.296385,
		"totalGuests": 702,
		"guestPercent": 0.753543,
		"mostGuestDate": "2023-04-20 00:00:00",
		"mostGuestName": "Coca-Cola Roxy",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 128
	},
	{
		"id": "6c9f82d",
		"email": "bernie@artizanx.com",
		"name": "Bernie",
		"createdDate": "2023-06-08 03:48:34",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 33,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 270,
		"schedulePercent": 0.19216,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "YARRABAH SPORTS FIELD",
		"mostGuestCity": "YARRABAH",
		"mostGuestAmount": 6
	},
	{
		"id": "276b342",
		"email": "jmangrum32@gmail.com",
		"name": "Joshua",
		"createdDate": "2018-10-25 19:09:31",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 1350,
		"totalCountries": 4,
		"totalStates": 50,
		"totalEvents": 294,
		"eventPercent": 0.9118,
		"totalScheduleItems": 3768,
		"schedulePercent": 0.910211,
		"totalGuests": 535,
		"guestPercent": 0.704675,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "20 Monroe Live",
		"mostGuestCity": "Grand Rapids",
		"mostGuestAmount": 28
	},
	{
		"id": "61b2ef0",
		"email": "danmoyse@gmail.com",
		"name": "Dan ",
		"createdDate": "2011-05-12 05:09:49",
		"totalOrganizations": 9,
		"totalTours": 17,
		"totalDays": 374,
		"totalCountries": 12,
		"totalStates": 56,
		"totalEvents": 235,
		"eventPercent": 0.855516,
		"totalScheduleItems": 2741,
		"schedulePercent": 0.824659,
		"totalGuests": 1088,
		"guestPercent": 0.83724,
		"mostGuestDate": "2023-06-27 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 43
	},
	{
		"id": "36646f5",
		"email": "lisa.petrie2@gmail.com",
		"name": "Petrie",
		"createdDate": "2019-03-27 16:59:11",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 215,
		"totalCountries": 7,
		"totalStates": 30,
		"totalEvents": 129,
		"eventPercent": 0.644683,
		"totalScheduleItems": 1222,
		"schedulePercent": 0.54602,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c036885",
		"email": "aaron.dimatulac@icloud.com",
		"name": "Aaron",
		"createdDate": "2018-03-05 20:12:18",
		"totalOrganizations": 10,
		"totalTours": 5,
		"totalDays": 957,
		"totalCountries": 4,
		"totalStates": 54,
		"totalEvents": 316,
		"eventPercent": 0.923719,
		"totalScheduleItems": 5688,
		"schedulePercent": 0.976162,
		"totalGuests": 3954,
		"guestPercent": 0.983313,
		"mostGuestDate": "2023-09-07 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 118
	},
	{
		"id": "a5af050",
		"email": "jantore.solberg@ncb.no",
		"name": "Jan Tore",
		"createdDate": "2021-11-04 11:14:08",
		"totalOrganizations": 1,
		"totalTours": 15,
		"totalDays": 91,
		"totalCountries": 2,
		"totalStates": 4,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 184,
		"schedulePercent": 0.141306,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "57e4597",
		"email": "settneiland@me.com",
		"name": "Sett",
		"createdDate": "2013-08-23 11:48:44",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 40,
		"totalCountries": 2,
		"totalStates": 15,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 203,
		"schedulePercent": 0.152165,
		"totalGuests": 50,
		"guestPercent": 0.421798,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "The John F. Kennedy Center for the Performing Arts",
		"mostGuestCity": "Washington",
		"mostGuestAmount": 20
	},
	{
		"id": "a241fd0",
		"email": "kwiddoes23@gmail.com",
		"name": "Kaylene",
		"createdDate": "2021-09-08 15:20:16",
		"totalOrganizations": 10,
		"totalTours": 13,
		"totalDays": 383,
		"totalCountries": 12,
		"totalStates": 58,
		"totalEvents": 207,
		"eventPercent": 0.817905,
		"totalScheduleItems": 3049,
		"schedulePercent": 0.855781,
		"totalGuests": 2084,
		"guestPercent": 0.938949,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The ICON Festival Stage",
		"mostGuestCity": "Cincinnati",
		"mostGuestAmount": 238
	},
	{
		"id": "a7b69aa",
		"email": "jlhernandez@alegriacorp.com",
		"name": "Jose ",
		"createdDate": "2010-08-05 15:55:23",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 20,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 8,
		"eventPercent": 0.0535028,
		"totalScheduleItems": 121,
		"schedulePercent": 0.10396,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "aa682a5",
		"email": "maxym.lytvynenko@gmail.com",
		"name": "Maksym",
		"createdDate": "2022-11-30 10:40:55",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 388,
		"totalCountries": 15,
		"totalStates": 41,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 1638,
		"schedulePercent": 0.650642,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0992302",
		"email": "ptrofin72@hotmail.com",
		"name": "Pat",
		"createdDate": "2019-04-23 22:51:46",
		"totalOrganizations": 4,
		"totalTours": 18,
		"totalDays": 209,
		"totalCountries": 3,
		"totalStates": 11,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 798,
		"schedulePercent": 0.419547,
		"totalGuests": 314,
		"guestPercent": 0.61131,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Commodore Ballroom",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 58
	},
	{
		"id": "260ba37",
		"email": "jamie@thepenthouse.life",
		"name": "Jamie",
		"createdDate": "2022-09-21 18:39:43",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 54,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 28,
		"eventPercent": 0.188055,
		"totalScheduleItems": 146,
		"schedulePercent": 0.117071,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "576a749",
		"email": "ryanmglatt@gmail.com",
		"name": "Ryan",
		"createdDate": "2021-09-30 16:08:39",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 107,
		"totalCountries": 3,
		"totalStates": 14,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 343,
		"schedulePercent": 0.229638,
		"totalGuests": 5,
		"guestPercent": 0.321414,
		"mostGuestDate": "2023-06-29 00:00:00",
		"mostGuestName": "Mayo Performing Arts Center",
		"mostGuestCity": "Morristown",
		"mostGuestAmount": 5
	},
	{
		"id": "84076d2",
		"email": "taylorbrown1@gmail.com",
		"name": "Taylor",
		"createdDate": "2012-06-01 16:34:54",
		"totalOrganizations": 3,
		"totalTours": 10,
		"totalDays": 115,
		"totalCountries": 3,
		"totalStates": 36,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 1026,
		"schedulePercent": 0.49212,
		"totalGuests": 310,
		"guestPercent": 0.609853,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Hordern Pavilion",
		"mostGuestCity": "Moore Park",
		"mostGuestAmount": 67
	},
	{
		"id": "5989686",
		"email": "sbplewes@gmail.com",
		"name": "Benjamin",
		"createdDate": "2017-06-05 22:29:41",
		"totalOrganizations": 12,
		"totalTours": 21,
		"totalDays": 750,
		"totalCountries": 4,
		"totalStates": 41,
		"totalEvents": 452,
		"eventPercent": 0.967819,
		"totalScheduleItems": 3458,
		"schedulePercent": 0.890743,
		"totalGuests": 1940,
		"guestPercent": 0.931267,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Coney Island Amphitheater",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 110
	},
	{
		"id": "50915a3",
		"email": "chrisjamesliggett@gmail.com",
		"name": "Chris",
		"createdDate": "2023-03-03 16:26:13",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 24,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 73,
		"schedulePercent": 0.0701894,
		"totalGuests": 24,
		"guestPercent": 0.375712,
		"mostGuestDate": "2023-05-25 00:00:00",
		"mostGuestName": "Manchester Club Academy",
		"mostGuestCity": "Manchester",
		"mostGuestAmount": 20
	},
	{
		"id": "5b31062",
		"email": "lous.tournee@asterios.fr",
		"name": "NICO",
		"createdDate": "2022-04-28 09:57:08",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 356,
		"totalCountries": 5,
		"totalStates": 11,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 497,
		"schedulePercent": 0.296914,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "964a657",
		"email": "Jh.sakaguchi@gmail.com",
		"name": "Jaclyn",
		"createdDate": "2017-01-13 18:47:55",
		"totalOrganizations": 9,
		"totalTours": 5,
		"totalDays": 229,
		"totalCountries": 4,
		"totalStates": 36,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 790,
		"schedulePercent": 0.416501,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ad0d53d",
		"email": "nick_ingram@me.com",
		"name": "Nick",
		"createdDate": "2012-02-21 16:46:20",
		"totalOrganizations": 9,
		"totalTours": 36,
		"totalDays": 487,
		"totalCountries": 18,
		"totalStates": 63,
		"totalEvents": 201,
		"eventPercent": 0.808237,
		"totalScheduleItems": 2410,
		"schedulePercent": 0.781353,
		"totalGuests": 396,
		"guestPercent": 0.652232,
		"mostGuestDate": "2023-03-16 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 52
	},
	{
		"id": "1f3d2d0",
		"email": "schippeaux4@gmail.com",
		"name": "Stefan",
		"createdDate": "2022-07-01 19:36:33",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 367,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 829,
		"schedulePercent": 0.430009,
		"totalGuests": 237,
		"guestPercent": 0.571712,
		"mostGuestDate": "2023-05-22 00:00:00",
		"mostGuestName": "Troubadour",
		"mostGuestCity": "West Hollywood",
		"mostGuestAmount": 42
	},
	{
		"id": "951e592",
		"email": "info@kennylattimore.com",
		"name": "Q",
		"createdDate": "2023-01-10 16:44:39",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 355,
		"totalCountries": 1,
		"totalStates": 15,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 406,
		"schedulePercent": 0.259436,
		"totalGuests": 63,
		"guestPercent": 0.437558,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "Mable House Barnes Amphitheatre",
		"mostGuestCity": "Mableton",
		"mostGuestAmount": 20
	},
	{
		"id": "f87cd1a",
		"email": "wrmoesta@gmail.com",
		"name": "William",
		"createdDate": "2015-04-16 20:16:00",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 153,
		"totalCountries": 2,
		"totalStates": 37,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 2105,
		"schedulePercent": 0.736194,
		"totalGuests": 3099,
		"guestPercent": 0.970997,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "Dickies Arena",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 433
	},
	{
		"id": "19b3c5e",
		"email": "donaldadurr@gmail.com",
		"name": "Don",
		"createdDate": "2022-10-22 21:05:19",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 31,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 290,
		"schedulePercent": 0.203152,
		"totalGuests": 427,
		"guestPercent": 0.663621,
		"mostGuestDate": "2023-11-26 00:00:00",
		"mostGuestName": "1720",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 67
	},
	{
		"id": "3b2e548",
		"email": "alpaga_93@hotmail.com",
		"name": "Aldo",
		"createdDate": "2016-08-03 17:32:20",
		"totalOrganizations": 6,
		"totalTours": 4,
		"totalDays": 583,
		"totalCountries": 4,
		"totalStates": 44,
		"totalEvents": 201,
		"eventPercent": 0.808237,
		"totalScheduleItems": 1203,
		"schedulePercent": 0.540856,
		"totalGuests": 258,
		"guestPercent": 0.583234,
		"mostGuestDate": "2023-02-25 00:00:00",
		"mostGuestName": "Agua Caliente Casino",
		"mostGuestCity": "Rancho Mirage",
		"mostGuestAmount": 32
	},
	{
		"id": "7e37465",
		"email": "carlydf9@icloud.com",
		"name": "Carly",
		"createdDate": "2015-09-10 15:25:58",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 231,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 601,
		"schedulePercent": 0.341544,
		"totalGuests": 412,
		"guestPercent": 0.658588,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 63
	},
	{
		"id": "c8b784d",
		"email": "muddymusic@bigpond.com",
		"name": "Rupert",
		"createdDate": "2009-11-09 12:51:58",
		"totalOrganizations": 13,
		"totalTours": 10,
		"totalDays": 335,
		"totalCountries": 8,
		"totalStates": 23,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 1667,
		"schedulePercent": 0.656999,
		"totalGuests": 66,
		"guestPercent": 0.441001,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Boettcher Concert Hall",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 23
	},
	{
		"id": "477ea91",
		"email": "info@theichogroup.com",
		"name": "Icho",
		"createdDate": "2021-08-03 14:08:34",
		"totalOrganizations": 2,
		"totalTours": 5,
		"totalDays": 174,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 2542,
		"schedulePercent": 0.798437,
		"totalGuests": 889,
		"guestPercent": 0.796981,
		"mostGuestDate": "2023-05-02 00:00:00",
		"mostGuestName": "The Fox Theatre",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 99
	},
	{
		"id": "73da002",
		"email": "emporellis@gmail.com",
		"name": "Ted",
		"createdDate": "2012-03-28 21:40:13",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 141,
		"totalCountries": 4,
		"totalStates": 29,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 1012,
		"schedulePercent": 0.488412,
		"totalGuests": 945,
		"guestPercent": 0.810886,
		"mostGuestDate": "2023-04-13 00:00:00",
		"mostGuestName": "The Paramount",
		"mostGuestCity": "Huntington",
		"mostGuestAmount": 60
	},
	{
		"id": "dddb95c",
		"email": "sensitrails.booking@gmail.com",
		"name": "Tim",
		"createdDate": "2022-02-11 20:00:22",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 358,
		"totalCountries": 3,
		"totalStates": 29,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1013,
		"schedulePercent": 0.488942,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "9b06df1",
		"email": "pm@chaserice.com",
		"name": "Jonathan",
		"createdDate": "2023-05-09 13:48:08",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 424,
		"totalCountries": 4,
		"totalStates": 46,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1367,
		"schedulePercent": 0.584691,
		"totalGuests": 307,
		"guestPercent": 0.607072,
		"mostGuestDate": "2023-12-02 00:00:00",
		"mostGuestName": "CFG Bank Arena",
		"mostGuestCity": "Baltimore",
		"mostGuestAmount": 42
	},
	{
		"id": "caf781e",
		"email": "tom@rawtec.fr",
		"name": "Thomas",
		"createdDate": "2022-11-16 17:37:19",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 110,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 661,
		"schedulePercent": 0.365779,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d588dbc",
		"email": "tournate27@gmail.com",
		"name": "Nate ",
		"createdDate": "2019-09-25 19:31:52",
		"totalOrganizations": 5,
		"totalTours": 10,
		"totalDays": 780,
		"totalCountries": 6,
		"totalStates": 45,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 2568,
		"schedulePercent": 0.801748,
		"totalGuests": 759,
		"guestPercent": 0.766653,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 75
	},
	{
		"id": "fdcaaf1",
		"email": "tom@rewiredsound.co.uk",
		"name": "Tom",
		"createdDate": "2013-03-13 09:43:33",
		"totalOrganizations": 10,
		"totalTours": 13,
		"totalDays": 196,
		"totalCountries": 9,
		"totalStates": 34,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 1679,
		"schedulePercent": 0.659648,
		"totalGuests": 997,
		"guestPercent": 0.82307,
		"mostGuestDate": "2023-08-17 00:00:00",
		"mostGuestName": "Rebellion",
		"mostGuestCity": "Manchester",
		"mostGuestAmount": 52
	},
	{
		"id": "10206e9",
		"email": "georgia_morello@hotmail.com",
		"name": "Georgia",
		"createdDate": "2022-03-26 21:36:19",
		"totalOrganizations": 23,
		"totalTours": 26,
		"totalDays": 435,
		"totalCountries": 10,
		"totalStates": 39,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 1849,
		"schedulePercent": 0.695007,
		"totalGuests": 810,
		"guestPercent": 0.778837,
		"mostGuestDate": "2023-04-20 00:00:00",
		"mostGuestName": "The Corner Hotel",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 87
	},
	{
		"id": "79b0a25",
		"email": "g.leibot@gmail.com",
		"name": "Geraldine ",
		"createdDate": "2019-02-15 05:49:52",
		"totalOrganizations": 6,
		"totalTours": 11,
		"totalDays": 537,
		"totalCountries": 14,
		"totalStates": 52,
		"totalEvents": 251,
		"eventPercent": 0.873792,
		"totalScheduleItems": 2609,
		"schedulePercent": 0.809959,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "136211e",
		"email": "hpe2@telus.net",
		"name": "Bill",
		"createdDate": "2014-06-12 15:28:00",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 226,
		"totalCountries": 5,
		"totalStates": 21,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 1304,
		"schedulePercent": 0.56721,
		"totalGuests": 141,
		"guestPercent": 0.506026,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "The Axis Club Theatre",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 41
	},
	{
		"id": "c9464a0",
		"email": "robin@highwayholidays.eu",
		"name": "Robin",
		"createdDate": "2019-11-11 13:17:02",
		"totalOrganizations": 10,
		"totalTours": 23,
		"totalDays": 692,
		"totalCountries": 21,
		"totalStates": 71,
		"totalEvents": 295,
		"eventPercent": 0.912594,
		"totalScheduleItems": 3198,
		"schedulePercent": 0.869686,
		"totalGuests": 783,
		"guestPercent": 0.772613,
		"mostGuestDate": "2023-02-10 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 50
	},
	{
		"id": "43a9975",
		"email": "kaiusami1@gmail.com",
		"name": "Kai",
		"createdDate": "2022-05-12 13:47:15",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 71,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 145,
		"schedulePercent": 0.116144,
		"totalGuests": 102,
		"guestPercent": 0.477155,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "The Echo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 40
	},
	{
		"id": "0e02b0e",
		"email": "dean@totalwave.com.au",
		"name": "Dean",
		"createdDate": "2023-02-16 11:38:09",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 43,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 209,
		"schedulePercent": 0.155609,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6202e30",
		"email": "jackbotts@hotmail.com",
		"name": "Jack",
		"createdDate": "2023-01-26 21:13:47",
		"totalOrganizations": 2,
		"totalTours": 8,
		"totalDays": 411,
		"totalCountries": 15,
		"totalStates": 50,
		"totalEvents": 156,
		"eventPercent": 0.718051,
		"totalScheduleItems": 1828,
		"schedulePercent": 0.691034,
		"totalGuests": 791,
		"guestPercent": 0.774599,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 75
	},
	{
		"id": "9c5a542",
		"email": "darryl@parkhillstudio.net",
		"name": "Darryl",
		"createdDate": "2017-12-12 18:09:15",
		"totalOrganizations": 8,
		"totalTours": 13,
		"totalDays": 364,
		"totalCountries": 2,
		"totalStates": 46,
		"totalEvents": 199,
		"eventPercent": 0.804264,
		"totalScheduleItems": 1867,
		"schedulePercent": 0.69898,
		"totalGuests": 125,
		"guestPercent": 0.494636,
		"mostGuestDate": "2023-08-10 00:00:00",
		"mostGuestName": "Budweiser Stage",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 22
	},
	{
		"id": "7c5b9d6",
		"email": "daniel@onthebusmgmt.com",
		"name": "Daniel",
		"createdDate": "2021-10-12 00:31:31",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 254,
		"totalCountries": 2,
		"totalStates": 27,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 572,
		"schedulePercent": 0.328963,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "The Cutting Room",
		"mostGuestCity": "New York",
		"mostGuestAmount": 2
	},
	{
		"id": "ce90b6e",
		"email": "joanne@sixboroughs.com.au",
		"name": "Joanne",
		"createdDate": "2021-12-01 06:36:06",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 255,
		"totalCountries": 3,
		"totalStates": 9,
		"totalEvents": 155,
		"eventPercent": 0.716197,
		"totalScheduleItems": 978,
		"schedulePercent": 0.474772,
		"totalGuests": 20,
		"guestPercent": 0.36909,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Hindley Street Music Hall",
		"mostGuestCity": "Adelaide",
		"mostGuestAmount": 20
	},
	{
		"id": "fcdd793",
		"email": "info@thevanns.com.au",
		"name": "The VANNS",
		"createdDate": "2023-04-14 01:09:23",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 60,
		"totalCountries": 2,
		"totalStates": 7,
		"totalEvents": 15,
		"eventPercent": 0.100384,
		"totalScheduleItems": 87,
		"schedulePercent": 0.0797245,
		"totalGuests": 37,
		"guestPercent": 0.400212,
		"mostGuestDate": "2023-05-19 00:00:00",
		"mostGuestName": "Hotel Esplanade",
		"mostGuestCity": "St Kilda",
		"mostGuestAmount": 25
	},
	{
		"id": "a6178d0",
		"email": "tgaber@aol.com",
		"name": "Terrence",
		"createdDate": "2015-03-16 16:49:04",
		"totalOrganizations": 14,
		"totalTours": 32,
		"totalDays": 886,
		"totalCountries": 16,
		"totalStates": 61,
		"totalEvents": 204,
		"eventPercent": 0.813005,
		"totalScheduleItems": 2684,
		"schedulePercent": 0.817773,
		"totalGuests": 928,
		"guestPercent": 0.806251,
		"mostGuestDate": "2023-06-21 00:00:00",
		"mostGuestName": "Greek Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 56
	},
	{
		"id": "44f1eef",
		"email": "coriwein@gmail.com",
		"name": "Cori",
		"createdDate": "2016-09-19 04:17:05",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 1128,
		"totalCountries": 8,
		"totalStates": 18,
		"totalEvents": 251,
		"eventPercent": 0.873792,
		"totalScheduleItems": 4262,
		"schedulePercent": 0.936167,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "69cd6cc",
		"email": "danisaccophoto@gmail.com",
		"name": "Danielle",
		"createdDate": "2022-09-05 16:38:11",
		"totalOrganizations": 4,
		"totalTours": 31,
		"totalDays": 575,
		"totalCountries": 7,
		"totalStates": 52,
		"totalEvents": 235,
		"eventPercent": 0.855516,
		"totalScheduleItems": 3899,
		"schedulePercent": 0.918289,
		"totalGuests": 1253,
		"guestPercent": 0.865316,
		"mostGuestDate": "2023-06-19 00:00:00",
		"mostGuestName": "Moore Theatre",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 133
	},
	{
		"id": "65217ad",
		"email": "opera.punch.0s@icloud.com",
		"name": "gilles",
		"createdDate": "2023-07-20 08:45:02",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 92,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 194,
		"schedulePercent": 0.146736,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3563dfb",
		"email": "librahouse.studio@gmail.com",
		"name": "Mitchell",
		"createdDate": "2022-04-04 23:00:51",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 938,
		"totalCountries": 6,
		"totalStates": 36,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 1338,
		"schedulePercent": 0.57701,
		"totalGuests": 110,
		"guestPercent": 0.482453,
		"mostGuestDate": "2023-10-18 00:00:00",
		"mostGuestName": "The Echo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 35
	},
	{
		"id": "252098c",
		"email": "theedannybland@gmail.com",
		"name": "Danny",
		"createdDate": "2017-08-31 19:51:36",
		"totalOrganizations": 6,
		"totalTours": 9,
		"totalDays": 434,
		"totalCountries": 8,
		"totalStates": 53,
		"totalEvents": 201,
		"eventPercent": 0.808237,
		"totalScheduleItems": 1625,
		"schedulePercent": 0.647596,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "38390a1",
		"email": "Biffen@inflames.com",
		"name": "Biffen",
		"createdDate": "2014-11-30 23:26:25",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 49,
		"totalCountries": 4,
		"totalStates": 6,
		"totalEvents": 27,
		"eventPercent": 0.180903,
		"totalScheduleItems": 288,
		"schedulePercent": 0.201828,
		"totalGuests": 192,
		"guestPercent": 0.544564,
		"mostGuestDate": "2023-08-31 00:00:00",
		"mostGuestName": "Mosebacketerrassen",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 66
	},
	{
		"id": "4248038",
		"email": "maude.lemarrec@gmail.com",
		"name": "Maude",
		"createdDate": "2022-09-19 17:03:16",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 624,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 4,
		"eventPercent": 0.0288703,
		"totalScheduleItems": 156,
		"schedulePercent": 0.124752,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c54452e",
		"email": "iainpthomson@gmail.com",
		"name": "Iain",
		"createdDate": "2017-03-10 04:53:58",
		"totalOrganizations": 19,
		"totalTours": 11,
		"totalDays": 1396,
		"totalCountries": 15,
		"totalStates": 62,
		"totalEvents": 279,
		"eventPercent": 0.901205,
		"totalScheduleItems": 4185,
		"schedulePercent": 0.933386,
		"totalGuests": 987,
		"guestPercent": 0.820554,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Cheekwood",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 106
	},
	{
		"id": "48f3c9e",
		"email": "ben@emissah.co.uk",
		"name": "Ben",
		"createdDate": "2018-09-04 09:34:34",
		"totalOrganizations": 8,
		"totalTours": 21,
		"totalDays": 359,
		"totalCountries": 11,
		"totalStates": 21,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 1743,
		"schedulePercent": 0.673156,
		"totalGuests": 470,
		"guestPercent": 0.680969,
		"mostGuestDate": "2023-04-06 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 106
	},
	{
		"id": "62048cd",
		"email": "forange6@gmail.com",
		"name": "Frankie",
		"createdDate": "2023-08-04 00:03:53",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 104,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 493,
		"schedulePercent": 0.294663,
		"totalGuests": 809,
		"guestPercent": 0.778175,
		"mostGuestDate": "2023-09-15 00:00:00",
		"mostGuestName": "Hammerstein Ballroom",
		"mostGuestCity": "New York",
		"mostGuestAmount": 54
	},
	{
		"id": "7c77435",
		"email": "Gemma@live-mgmt.com",
		"name": "Gemma",
		"createdDate": "2017-04-14 14:00:10",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 628,
		"totalCountries": 18,
		"totalStates": 47,
		"totalEvents": 311,
		"eventPercent": 0.921335,
		"totalScheduleItems": 4505,
		"schedulePercent": 0.945305,
		"totalGuests": 1136,
		"guestPercent": 0.846246,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Qudos Bank Arena",
		"mostGuestCity": "Sydney Olympic Park",
		"mostGuestAmount": 67
	},
	{
		"id": "49af6e8",
		"email": "jillmb@eighteentwentysix.com",
		"name": "Jill Erin",
		"createdDate": "2018-08-17 19:06:42",
		"totalOrganizations": 8,
		"totalTours": 11,
		"totalDays": 1257,
		"totalCountries": 14,
		"totalStates": 54,
		"totalEvents": 263,
		"eventPercent": 0.888889,
		"totalScheduleItems": 3615,
		"schedulePercent": 0.902,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "UshuaÃ¯a Beach Club Ibiza",
		"mostGuestCity": "Sant Josep de sa Talaia",
		"mostGuestAmount": 3
	},
	{
		"id": "d0b081c",
		"email": "keirbailey@gmail.com",
		"name": "Keir",
		"createdDate": "2017-10-11 00:13:20",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 928,
		"totalCountries": 10,
		"totalStates": 53,
		"totalEvents": 177,
		"eventPercent": 0.765594,
		"totalScheduleItems": 2417,
		"schedulePercent": 0.782281,
		"totalGuests": 393,
		"guestPercent": 0.650775,
		"mostGuestDate": "2023-07-05 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 57
	},
	{
		"id": "410c9bd",
		"email": "dgraham@rudeproduction.com",
		"name": "Dan",
		"createdDate": "2021-07-17 15:50:06",
		"totalOrganizations": 12,
		"totalTours": 12,
		"totalDays": 356,
		"totalCountries": 10,
		"totalStates": 63,
		"totalEvents": 206,
		"eventPercent": 0.816183,
		"totalScheduleItems": 2467,
		"schedulePercent": 0.78877,
		"totalGuests": 549,
		"guestPercent": 0.709707,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 147
	},
	{
		"id": "410d427",
		"email": "ColinJBennet@gmail.com",
		"name": "Colin",
		"createdDate": "2015-06-26 00:04:22",
		"totalOrganizations": 9,
		"totalTours": 4,
		"totalDays": 111,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 615,
		"schedulePercent": 0.347504,
		"totalGuests": 818,
		"guestPercent": 0.779764,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 88
	},
	{
		"id": "977d35f",
		"email": "toler.emily95@gmail.com",
		"name": "Emily",
		"createdDate": "2019-04-25 22:17:39",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 0,
		"totalStates": 0,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 4994,
		"schedulePercent": 0.961065,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ed1144a",
		"email": "brashap@gmail.com",
		"name": "Brian",
		"createdDate": "2018-06-01 17:49:10",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 44,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 309,
		"schedulePercent": 0.212422,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-03-18 00:00:00",
		"mostGuestName": "The Capitol Theatre",
		"mostGuestCity": "Port Chester",
		"mostGuestAmount": 9
	},
	{
		"id": "66654c4",
		"email": "Jewebsteriii@gmail.com",
		"name": "Jack",
		"createdDate": "2022-01-24 19:04:57",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 191,
		"totalCountries": 7,
		"totalStates": 38,
		"totalEvents": 98,
		"eventPercent": 0.533969,
		"totalScheduleItems": 1012,
		"schedulePercent": 0.488412,
		"totalGuests": 797,
		"guestPercent": 0.775526,
		"mostGuestDate": "2023-10-25 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 56
	},
	{
		"id": "b10641b",
		"email": "janjanson66@yahoo.com",
		"name": "Jan Eric",
		"createdDate": "2012-08-30 05:39:45",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 428,
		"totalCountries": 8,
		"totalStates": 36,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1337,
		"schedulePercent": 0.576877,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ee6ea69",
		"email": "zito@zitoproductionservices.com",
		"name": "Zito",
		"createdDate": "2014-08-09 03:19:42",
		"totalOrganizations": 21,
		"totalTours": 13,
		"totalDays": 809,
		"totalCountries": 11,
		"totalStates": 45,
		"totalEvents": 180,
		"eventPercent": 0.770229,
		"totalScheduleItems": 3302,
		"schedulePercent": 0.877765,
		"totalGuests": 356,
		"guestPercent": 0.633029,
		"mostGuestDate": "2023-05-05 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 207
	},
	{
		"id": "209dd78",
		"email": "arnotmiller@gmail.com",
		"name": "Arno",
		"createdDate": "2016-09-28 19:21:38",
		"totalOrganizations": 23,
		"totalTours": 25,
		"totalDays": 2683,
		"totalCountries": 18,
		"totalStates": 84,
		"totalEvents": 399,
		"eventPercent": 0.957092,
		"totalScheduleItems": 6280,
		"schedulePercent": 0.983313,
		"totalGuests": 1139,
		"guestPercent": 0.847437,
		"mostGuestDate": "2023-09-28 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 125
	},
	{
		"id": "b3e451a",
		"email": "ross@themotionlabs.co.uk",
		"name": "Ross",
		"createdDate": "2016-03-12 20:47:34",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 347,
		"totalCountries": 11,
		"totalStates": 22,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 1369,
		"schedulePercent": 0.585088,
		"totalGuests": 120,
		"guestPercent": 0.490134,
		"mostGuestDate": "2023-12-03 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 56
	},
	{
		"id": "d25edeb",
		"email": "adam@oninfiveproduction.com",
		"name": "Adam",
		"createdDate": "2022-04-08 11:54:39",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 453,
		"totalCountries": 4,
		"totalStates": 11,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 1004,
		"schedulePercent": 0.486161,
		"totalGuests": 593,
		"guestPercent": 0.723878,
		"mostGuestDate": "2023-10-16 00:00:00",
		"mostGuestName": "O2 Forum Kentish Town",
		"mostGuestCity": "London",
		"mostGuestAmount": 95
	},
	{
		"id": "faf5e26",
		"email": "daisy@blackpearlproduction.co.uk",
		"name": "Daisy",
		"createdDate": "2022-09-06 15:40:37",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 298,
		"totalCountries": 11,
		"totalStates": 44,
		"totalEvents": 150,
		"eventPercent": 0.702556,
		"totalScheduleItems": 1510,
		"schedulePercent": 0.620315,
		"totalGuests": 808,
		"guestPercent": 0.77791,
		"mostGuestDate": "2023-11-23 00:00:00",
		"mostGuestName": "HISTORY",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 58
	},
	{
		"id": "21faca3",
		"email": "cmerlaud@icloud.com",
		"name": "christophe",
		"createdDate": "2021-06-07 11:52:20",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 620,
		"totalCountries": 3,
		"totalStates": 15,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 1709,
		"schedulePercent": 0.667196,
		"totalGuests": 1697,
		"guestPercent": 0.912992,
		"mostGuestDate": "2023-03-29 00:00:00",
		"mostGuestName": "Zenith Paris - La Villette",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 203
	},
	{
		"id": "b36f8d6",
		"email": "happierman@gmail.com",
		"name": "Jeffrey",
		"createdDate": "2017-07-03 20:20:46",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 63,
		"totalCountries": 1,
		"totalStates": 28,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 506,
		"schedulePercent": 0.301814,
		"totalGuests": 113,
		"guestPercent": 0.485366,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "The Bell House",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 10
	},
	{
		"id": "161480d",
		"email": "emma@bullocks.co.uk",
		"name": "Emma",
		"createdDate": "2023-04-05 15:32:29",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 81,
		"totalCountries": 3,
		"totalStates": 24,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 571,
		"schedulePercent": 0.328036,
		"totalGuests": 104,
		"guestPercent": 0.478877,
		"mostGuestDate": "2023-07-03 00:00:00",
		"mostGuestName": "Thomas Wolfe Auditorium",
		"mostGuestCity": "Asheville",
		"mostGuestAmount": 29
	},
	{
		"id": "af94ec6",
		"email": "thewonderyearsband@gmail.com",
		"name": "Wonder",
		"createdDate": "2019-01-08 18:35:26",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 120,
		"totalCountries": 3,
		"totalStates": 30,
		"totalEvents": 82,
		"eventPercent": 0.466296,
		"totalScheduleItems": 1167,
		"schedulePercent": 0.531453,
		"totalGuests": 1266,
		"guestPercent": 0.867435,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 70
	},
	{
		"id": "85e146e",
		"email": "marc.crain@gmail.com",
		"name": "Marc",
		"createdDate": "2013-11-07 18:35:47",
		"totalOrganizations": 2,
		"totalTours": 12,
		"totalDays": 323,
		"totalCountries": 15,
		"totalStates": 71,
		"totalEvents": 168,
		"eventPercent": 0.747451,
		"totalScheduleItems": 2457,
		"schedulePercent": 0.787048,
		"totalGuests": 857,
		"guestPercent": 0.789432,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "AB Banan-Kompaniet",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 42
	},
	{
		"id": "077fc3a",
		"email": "moss@oldmanmoss.com",
		"name": "Tim",
		"createdDate": "2012-08-27 07:38:27",
		"totalOrganizations": 10,
		"totalTours": 5,
		"totalDays": 123,
		"totalCountries": 7,
		"totalStates": 39,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 1881,
		"schedulePercent": 0.701364,
		"totalGuests": 2668,
		"guestPercent": 0.959078,
		"mostGuestDate": "2023-04-21 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 156
	},
	{
		"id": "1c166ca",
		"email": "pribisco70@gmail.com",
		"name": "Matt",
		"createdDate": "2014-06-14 20:29:10",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 522,
		"totalCountries": 13,
		"totalStates": 57,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 3332,
		"schedulePercent": 0.88134,
		"totalGuests": 1125,
		"guestPercent": 0.843597,
		"mostGuestDate": "2023-09-05 00:00:00",
		"mostGuestName": "Singapore Indoor Stadium",
		"mostGuestCity": "Singapore",
		"mostGuestAmount": 78
	},
	{
		"id": "616f01d",
		"email": "matt@techproduction.co.uk",
		"name": "Matt",
		"createdDate": "2022-05-23 13:26:53",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 194,
		"totalCountries": 7,
		"totalStates": 26,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 2033,
		"schedulePercent": 0.72454,
		"totalGuests": 486,
		"guestPercent": 0.686664,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 53
	},
	{
		"id": "5402617",
		"email": "DANEHONEYCUTT@HOTMAIL.COM",
		"name": "Dane",
		"createdDate": "2019-08-01 20:03:56",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 193,
		"totalCountries": 1,
		"totalStates": 35,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1422,
		"schedulePercent": 0.597272,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c5471c2",
		"email": "jude@artistmanagementplus.com",
		"name": "Jude",
		"createdDate": "2022-09-28 18:58:39",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 802,
		"totalCountries": 11,
		"totalStates": 59,
		"totalEvents": 301,
		"eventPercent": 0.91617,
		"totalScheduleItems": 2035,
		"schedulePercent": 0.724805,
		"totalGuests": 70,
		"guestPercent": 0.445636,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "John Dee Live Club & Pub",
		"mostGuestCity": "Oslo",
		"mostGuestAmount": 15
	},
	{
		"id": "853e68d",
		"email": "finlay@whitecord.net",
		"name": "Finlay",
		"createdDate": "2022-09-28 19:34:26",
		"totalOrganizations": 10,
		"totalTours": 16,
		"totalDays": 394,
		"totalCountries": 9,
		"totalStates": 34,
		"totalEvents": 196,
		"eventPercent": 0.798835,
		"totalScheduleItems": 1540,
		"schedulePercent": 0.627599,
		"totalGuests": 557,
		"guestPercent": 0.712753,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "O2 Academy Glasgow",
		"mostGuestCity": "Glasgow",
		"mostGuestAmount": 75
	},
	{
		"id": "242578d",
		"email": "josh.poramblinboys@gmail.com",
		"name": "Josh ",
		"createdDate": "2022-02-07 17:32:22",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 355,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 70,
		"eventPercent": 0.407363,
		"totalScheduleItems": 502,
		"schedulePercent": 0.299563,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e5fa020",
		"email": "zsdrum@gmail.com",
		"name": "Zack ",
		"createdDate": "2018-04-05 18:39:39",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 7,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 463,
		"schedulePercent": 0.282082,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "615c8ef",
		"email": "jordan@rcitheatricals.com",
		"name": "Jordan",
		"createdDate": "2022-11-18 17:48:21",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 358,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 289,
		"eventPercent": 0.908621,
		"totalScheduleItems": 2398,
		"schedulePercent": 0.780426,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e4ea33d",
		"email": "mattspicher@mac.com",
		"name": "Matthew",
		"createdDate": "2023-04-25 02:32:43",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 488,
		"totalCountries": 1,
		"totalStates": 31,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 826,
		"schedulePercent": 0.42842,
		"totalGuests": 60,
		"guestPercent": 0.434644,
		"mostGuestDate": "2023-07-13 00:00:00",
		"mostGuestName": "City Winery Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 12
	},
	{
		"id": "ad05a2c",
		"email": "olav@standup.no",
		"name": "Olav AndrÃ©",
		"createdDate": "2021-09-14 08:03:18",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 124,
		"totalCountries": 1,
		"totalStates": 17,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 401,
		"schedulePercent": 0.25692,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "de559c4",
		"email": "lisedngr@gmail.com",
		"name": "Lise",
		"createdDate": "2018-04-08 14:50:35",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 554,
		"totalCountries": 11,
		"totalStates": 26,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 2316,
		"schedulePercent": 0.767713,
		"totalGuests": 19,
		"guestPercent": 0.366971,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "La Laierie",
		"mostGuestCity": "Strasbourg",
		"mostGuestAmount": 10
	},
	{
		"id": "e9db701",
		"email": "driften@husetunderbron.se",
		"name": "Drift",
		"createdDate": "2022-04-22 14:41:19",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 194,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 1140,
		"schedulePercent": 0.523507,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0a18f15",
		"email": "tssfmastertour@gmail.com",
		"name": "TSSF",
		"createdDate": "2018-10-30 22:42:55",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 58,
		"totalCountries": 10,
		"totalStates": 16,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 295,
		"schedulePercent": 0.205668,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Fiddler's Green Amphitheatre",
		"mostGuestCity": "Greenwood Village",
		"mostGuestAmount": 13
	},
	{
		"id": "8c0c083",
		"email": "debbiehutchins1@gmail.com",
		"name": "Debbie",
		"createdDate": "2010-06-07 19:02:06",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 716,
		"totalCountries": 13,
		"totalStates": 57,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 2306,
		"schedulePercent": 0.766256,
		"totalGuests": 1861,
		"guestPercent": 0.92544,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 142
	},
	{
		"id": "0a2d88b",
		"email": "bethgsmith@gmail.com",
		"name": "Beth",
		"createdDate": "2022-10-13 17:04:55",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 246,
		"totalCountries": 7,
		"totalStates": 24,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 1051,
		"schedulePercent": 0.497682,
		"totalGuests": 800,
		"guestPercent": 0.776056,
		"mostGuestDate": "2023-04-06 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 106
	},
	{
		"id": "15d634c",
		"email": "jade.harbord@frontiertouring.com",
		"name": "Jade",
		"createdDate": "2021-06-10 03:55:05",
		"totalOrganizations": 29,
		"totalTours": 45,
		"totalDays": 1143,
		"totalCountries": 26,
		"totalStates": 83,
		"totalEvents": 414,
		"eventPercent": 0.961197,
		"totalScheduleItems": 7220,
		"schedulePercent": 0.990597,
		"totalGuests": 3945,
		"guestPercent": 0.983181,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Raymond James Stadium",
		"mostGuestCity": "Tampa ",
		"mostGuestAmount": 248
	},
	{
		"id": "a66f9da",
		"email": "bruiserbroussard@gmail.com",
		"name": "Brian",
		"createdDate": "2014-06-03 01:23:59",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 94,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 471,
		"schedulePercent": 0.28473,
		"totalGuests": 287,
		"guestPercent": 0.597007,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "The Windjammer",
		"mostGuestCity": "Isle of Palms",
		"mostGuestAmount": 24
	},
	{
		"id": "cb65643",
		"email": "dpg331@me.com",
		"name": "Dan",
		"createdDate": "2011-09-20 17:59:07",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 342,
		"totalCountries": 4,
		"totalStates": 16,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 177,
		"schedulePercent": 0.136936,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f31084f",
		"email": "reuben@banishedmusic.com",
		"name": "Reuben",
		"createdDate": "2020-12-04 02:41:58",
		"totalOrganizations": 57,
		"totalTours": 64,
		"totalDays": 667,
		"totalCountries": 17,
		"totalStates": 56,
		"totalEvents": 283,
		"eventPercent": 0.904516,
		"totalScheduleItems": 3256,
		"schedulePercent": 0.874321,
		"totalGuests": 2674,
		"guestPercent": 0.959343,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Powerstation",
		"mostGuestCity": "Auckland",
		"mostGuestAmount": 88
	},
	{
		"id": "246ec2d",
		"email": "matthew.sojka@gmail.com",
		"name": "Matthew",
		"createdDate": "2017-06-29 16:17:08",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 1160,
		"totalCountries": 5,
		"totalStates": 55,
		"totalEvents": 354,
		"eventPercent": 0.943584,
		"totalScheduleItems": 3798,
		"schedulePercent": 0.911402,
		"totalGuests": 2042,
		"guestPercent": 0.9363,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 102
	},
	{
		"id": "3fba1fe",
		"email": "ryan@publicemily.net",
		"name": "Ryan",
		"createdDate": "2023-01-19 15:43:02",
		"totalOrganizations": 10,
		"totalTours": 7,
		"totalDays": 202,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 37,
		"eventPercent": 0.240498,
		"totalScheduleItems": 443,
		"schedulePercent": 0.274136,
		"totalGuests": 331,
		"guestPercent": 0.619256,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "The Riverside Theater",
		"mostGuestCity": "Milwaukee",
		"mostGuestAmount": 40
	},
	{
		"id": "2ff40ed",
		"email": "Billwheel581@gmail.com",
		"name": "Bill",
		"createdDate": "2016-11-23 10:07:20",
		"totalOrganizations": 4,
		"totalTours": 24,
		"totalDays": 273,
		"totalCountries": 13,
		"totalStates": 36,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 1767,
		"schedulePercent": 0.678056,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "62e4d88",
		"email": "aaronsings@me.com",
		"name": "Aaron",
		"createdDate": "2018-11-17 00:29:40",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 1265,
		"totalCountries": 2,
		"totalStates": 18,
		"totalEvents": 91,
		"eventPercent": 0.505761,
		"totalScheduleItems": 1008,
		"schedulePercent": 0.487618,
		"totalGuests": 61,
		"guestPercent": 0.435571,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Ranchmanâ€™s Cookhouse & Dancehall",
		"mostGuestCity": "Calgary",
		"mostGuestAmount": 26
	},
	{
		"id": "58e161a",
		"email": "cherish@shotgunmgmt.com",
		"name": "Cherish",
		"createdDate": "2018-10-07 19:19:30",
		"totalOrganizations": 8,
		"totalTours": 8,
		"totalDays": 385,
		"totalCountries": 5,
		"totalStates": 40,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1379,
		"schedulePercent": 0.58681,
		"totalGuests": 156,
		"guestPercent": 0.518607,
		"mostGuestDate": "2023-05-02 00:00:00",
		"mostGuestName": "Regent Theater DTLA",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 47
	},
	{
		"id": "1d7c8c6",
		"email": "mark_a_wilson@mac.com",
		"name": "Mark ",
		"createdDate": "2016-05-30 05:40:14",
		"totalOrganizations": 22,
		"totalTours": 23,
		"totalDays": 939,
		"totalCountries": 11,
		"totalStates": 41,
		"totalEvents": 220,
		"eventPercent": 0.83671,
		"totalScheduleItems": 3381,
		"schedulePercent": 0.885975,
		"totalGuests": 1742,
		"guestPercent": 0.915773,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "2a69d52",
		"email": "esteban.varela@diegotorres.com",
		"name": "ESTEBAN",
		"createdDate": "2019-08-05 19:30:04",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 30,
		"totalCountries": 2,
		"totalStates": 9,
		"totalEvents": 14,
		"eventPercent": 0.0946894,
		"totalScheduleItems": 150,
		"schedulePercent": 0.120117,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2e8c1ea",
		"email": "jroy@building429.com",
		"name": "Jason",
		"createdDate": "2013-03-22 15:11:54",
		"totalOrganizations": 15,
		"totalTours": 11,
		"totalDays": 537,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 252,
		"eventPercent": 0.876308,
		"totalScheduleItems": 4045,
		"schedulePercent": 0.925308,
		"totalGuests": 6083,
		"guestPercent": 0.995232,
		"mostGuestDate": "2023-07-21 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 299
	},
	{
		"id": "a84a284",
		"email": "santi@vitalmanagement.com",
		"name": "Kendall",
		"createdDate": "2013-08-17 05:15:23",
		"totalOrganizations": 9,
		"totalTours": 9,
		"totalDays": 500,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 771,
		"schedulePercent": 0.409085,
		"totalGuests": 7,
		"guestPercent": 0.332009,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "The Lincoln",
		"mostGuestCity": "Cheyenne",
		"mostGuestAmount": 3
	},
	{
		"id": "6fecc04",
		"email": "office@bonnieraitt.com",
		"name": "Vanessa",
		"createdDate": "2016-01-28 20:41:22",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 197,
		"totalCountries": 8,
		"totalStates": 24,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 1341,
		"schedulePercent": 0.577937,
		"totalGuests": 955,
		"guestPercent": 0.814064,
		"mostGuestDate": "2023-06-03 00:00:00",
		"mostGuestName": "London Palladium",
		"mostGuestCity": "London",
		"mostGuestAmount": 53
	},
	{
		"id": "ea42406",
		"email": "jojo.pneuma@gmail.com",
		"name": "Przemek",
		"createdDate": "2018-12-12 05:15:02",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 154,
		"totalCountries": 17,
		"totalStates": 39,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 877,
		"schedulePercent": 0.446431,
		"totalGuests": 191,
		"guestPercent": 0.543769,
		"mostGuestDate": "2023-11-27 00:00:00",
		"mostGuestName": "The Triffid",
		"mostGuestCity": "Newstead",
		"mostGuestAmount": 25
	},
	{
		"id": "17e5ee3",
		"email": "dougietours@gmail.com",
		"name": "Doug",
		"createdDate": "2023-08-08 23:12:31",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 28,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 13,
		"eventPercent": 0.0892597,
		"totalScheduleItems": 161,
		"schedulePercent": 0.127135,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "aaf6805",
		"email": "James.Marsh@wmg.com",
		"name": "James",
		"createdDate": "2018-10-26 15:04:25",
		"totalOrganizations": 50,
		"totalTours": 45,
		"totalDays": 15229,
		"totalCountries": 16,
		"totalStates": 88,
		"totalEvents": 1990,
		"eventPercent": 0.996292,
		"totalScheduleItems": 1533,
		"schedulePercent": 0.625612,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4904592",
		"email": "jessicajb.pro@gmail.com",
		"name": "Jessica",
		"createdDate": "2021-08-25 19:14:28",
		"totalOrganizations": 11,
		"totalTours": 16,
		"totalDays": 494,
		"totalCountries": 10,
		"totalStates": 54,
		"totalEvents": 240,
		"eventPercent": 0.86174,
		"totalScheduleItems": 2918,
		"schedulePercent": 0.844259,
		"totalGuests": 633,
		"guestPercent": 0.73487,
		"mostGuestDate": "2023-03-10 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 46
	},
	{
		"id": "58ce35b",
		"email": "fenia.galtieri@gmail.com",
		"name": "Fenia",
		"createdDate": "2022-09-11 14:44:42",
		"totalOrganizations": 8,
		"totalTours": 13,
		"totalDays": 611,
		"totalCountries": 23,
		"totalStates": 76,
		"totalEvents": 197,
		"eventPercent": 0.800821,
		"totalScheduleItems": 585,
		"schedulePercent": 0.334128,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a995432",
		"email": "lcornell88@gmail.com",
		"name": "Louis",
		"createdDate": "2016-12-22 00:09:49",
		"totalOrganizations": 16,
		"totalTours": 13,
		"totalDays": 648,
		"totalCountries": 13,
		"totalStates": 35,
		"totalEvents": 183,
		"eventPercent": 0.775659,
		"totalScheduleItems": 2197,
		"schedulePercent": 0.748907,
		"totalGuests": 996,
		"guestPercent": 0.822805,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "The Forum ",
		"mostGuestCity": "Melbourne ",
		"mostGuestAmount": 131
	},
	{
		"id": "f880e4a",
		"email": "emixn63@gmail.com",
		"name": "Kevin",
		"createdDate": "2016-08-08 19:06:08",
		"totalOrganizations": 10,
		"totalTours": 3,
		"totalDays": 38,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 17,
		"eventPercent": 0.113627,
		"totalScheduleItems": 196,
		"schedulePercent": 0.147663,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-08-01 00:00:00",
		"mostGuestName": "Space",
		"mostGuestCity": "Evanston",
		"mostGuestAmount": 3
	},
	{
		"id": "08ad9ef",
		"email": "derek.williams@mac.com",
		"name": "Derek",
		"createdDate": "2009-05-05 20:59:27",
		"totalOrganizations": 16,
		"totalTours": 32,
		"totalDays": 1906,
		"totalCountries": 16,
		"totalStates": 65,
		"totalEvents": 250,
		"eventPercent": 0.872335,
		"totalScheduleItems": 4059,
		"schedulePercent": 0.925705,
		"totalGuests": 1926,
		"guestPercent": 0.930208,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 128
	},
	{
		"id": "5a94197",
		"email": "tompambrun@gmail.com",
		"name": "Thomas",
		"createdDate": "2009-11-24 18:08:31",
		"totalOrganizations": 20,
		"totalTours": 17,
		"totalDays": 3061,
		"totalCountries": 21,
		"totalStates": 71,
		"totalEvents": 354,
		"eventPercent": 0.943584,
		"totalScheduleItems": 3625,
		"schedulePercent": 0.902662,
		"totalGuests": 1406,
		"guestPercent": 0.883327,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 128
	},
	{
		"id": "35dca86",
		"email": "info@overcoatmgmt.com",
		"name": "Overcoat",
		"createdDate": "2023-05-24 19:43:29",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 40,
		"totalCountries": 4,
		"totalStates": 20,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 284,
		"schedulePercent": 0.200503,
		"totalGuests": 490,
		"guestPercent": 0.688518,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Radio City Music Hall",
		"mostGuestCity": "New York",
		"mostGuestAmount": 107
	},
	{
		"id": "bfaf9c8",
		"email": "brian.hillman@ymail.com",
		"name": "Brian",
		"createdDate": "2014-07-25 15:21:20",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 1096,
		"totalCountries": 6,
		"totalStates": 50,
		"totalEvents": 200,
		"eventPercent": 0.805456,
		"totalScheduleItems": 3601,
		"schedulePercent": 0.900543,
		"totalGuests": 1597,
		"guestPercent": 0.902662,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 102
	},
	{
		"id": "eebb7df",
		"email": "lolokinser@gmail.com",
		"name": "LoLo ",
		"createdDate": "2015-06-25 14:35:47",
		"totalOrganizations": 4,
		"totalTours": 6,
		"totalDays": 1380,
		"totalCountries": 3,
		"totalStates": 44,
		"totalEvents": 262,
		"eventPercent": 0.887962,
		"totalScheduleItems": 5191,
		"schedulePercent": 0.967289,
		"totalGuests": 1340,
		"guestPercent": 0.876308,
		"mostGuestDate": "2023-02-09 00:00:00",
		"mostGuestName": "Phoenix Open Golf Tournament",
		"mostGuestCity": "Scottsdale",
		"mostGuestAmount": 94
	},
	{
		"id": "cb3ec91",
		"email": "dickmassey@me.com",
		"name": "Dick",
		"createdDate": "2014-05-19 04:59:27",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 366,
		"totalCountries": 12,
		"totalStates": 20,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 402,
		"schedulePercent": 0.257582,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "4d4326b",
		"email": "dillonb3nnett@gmail.com",
		"name": "Dillon",
		"createdDate": "2016-11-25 20:05:41",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 487,
		"totalCountries": 4,
		"totalStates": 38,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 2014,
		"schedulePercent": 0.722024,
		"totalGuests": 2370,
		"guestPercent": 0.949278,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 298
	},
	{
		"id": "b8fc6cf",
		"email": "ar2rohrndz@yahoo.com",
		"name": "Arturo",
		"createdDate": "2015-08-19 17:22:29",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 390,
		"totalCountries": 18,
		"totalStates": 57,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 1150,
		"schedulePercent": 0.527083,
		"totalGuests": 1029,
		"guestPercent": 0.828764,
		"mostGuestDate": "2023-10-08 00:00:00",
		"mostGuestName": "Power Trip Music Festival",
		"mostGuestCity": "Indio",
		"mostGuestAmount": 87
	},
	{
		"id": "3928b07",
		"email": "Efbystereo74@gmail.com",
		"name": "Efrem",
		"createdDate": "2019-10-15 19:00:12",
		"totalOrganizations": 8,
		"totalTours": 14,
		"totalDays": 145,
		"totalCountries": 9,
		"totalStates": 35,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 563,
		"schedulePercent": 0.326314,
		"totalGuests": 123,
		"guestPercent": 0.492782,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "Alex Theatre",
		"mostGuestCity": "Glendale",
		"mostGuestAmount": 42
	},
	{
		"id": "fcd43e0",
		"email": "cillianv@hotmail.com",
		"name": "Cillian",
		"createdDate": "2019-02-05 03:55:37",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 194,
		"totalCountries": 5,
		"totalStates": 31,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 352,
		"schedulePercent": 0.234538,
		"totalGuests": 34,
		"guestPercent": 0.395842,
		"mostGuestDate": "2023-03-15 00:00:00",
		"mostGuestName": "The Sheen Center for Thought & Culture",
		"mostGuestCity": "New York",
		"mostGuestAmount": 10
	},
	{
		"id": "9eefa64",
		"email": "DREALLDAY@GMAIL.COM",
		"name": "DrÃ¨",
		"createdDate": "2010-05-27 21:10:19",
		"totalOrganizations": 36,
		"totalTours": 13,
		"totalDays": 838,
		"totalCountries": 8,
		"totalStates": 44,
		"totalEvents": 321,
		"eventPercent": 0.9265,
		"totalScheduleItems": 3217,
		"schedulePercent": 0.871143,
		"totalGuests": 145,
		"guestPercent": 0.509734,
		"mostGuestDate": "2023-03-12 00:00:00",
		"mostGuestName": "American Airlines Center",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 33
	},
	{
		"id": "df70006",
		"email": "genekim1222@gmail.com",
		"name": "Gene",
		"createdDate": "2016-03-10 13:27:56",
		"totalOrganizations": 13,
		"totalTours": 12,
		"totalDays": 968,
		"totalCountries": 4,
		"totalStates": 44,
		"totalEvents": 292,
		"eventPercent": 0.910211,
		"totalScheduleItems": 4582,
		"schedulePercent": 0.948086,
		"totalGuests": 900,
		"guestPercent": 0.799629,
		"mostGuestDate": "2023-05-21 00:00:00",
		"mostGuestName": "Calvary Community Church",
		"mostGuestCity": "Westlake Village",
		"mostGuestAmount": 47
	},
	{
		"id": "4d47d40",
		"email": "turner@reynoldsmgmt.com",
		"name": "Turner",
		"createdDate": "2017-02-10 19:11:25",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 271,
		"totalCountries": 17,
		"totalStates": 32,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 460,
		"schedulePercent": 0.280625,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e6b9dd3",
		"email": "mattmiller@txdxe.com",
		"name": "Matt",
		"createdDate": "2014-04-24 07:06:19",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 34,
		"totalCountries": 10,
		"totalStates": 15,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 267,
		"schedulePercent": 0.190571,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7c2800a",
		"email": "Talon.fausnaught@gmail.com",
		"name": "Derek",
		"createdDate": "2011-08-02 04:15:19",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 346,
		"totalCountries": 3,
		"totalStates": 34,
		"totalEvents": 97,
		"eventPercent": 0.528672,
		"totalScheduleItems": 1062,
		"schedulePercent": 0.501258,
		"totalGuests": 452,
		"guestPercent": 0.674613,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 37
	},
	{
		"id": "77cdc4a",
		"email": "maryam.drame@blixten.se",
		"name": "Maryam ",
		"createdDate": "2017-03-15 10:41:31",
		"totalOrganizations": 32,
		"totalTours": 10,
		"totalDays": 212,
		"totalCountries": 1,
		"totalStates": 15,
		"totalEvents": 154,
		"eventPercent": 0.71368,
		"totalScheduleItems": 995,
		"schedulePercent": 0.483115,
		"totalGuests": 350,
		"guestPercent": 0.629718,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Borgholms Slott",
		"mostGuestCity": "Borgholm",
		"mostGuestAmount": 45
	},
	{
		"id": "a2bdd6a",
		"email": "Nathalie.Potvin@quebecor.com",
		"name": "Nathalie",
		"createdDate": "2018-12-11 01:25:45",
		"totalOrganizations": 28,
		"totalTours": 9,
		"totalDays": 172,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 147,
		"eventPercent": 0.693153,
		"totalScheduleItems": 752,
		"schedulePercent": 0.402861,
		"totalGuests": 1157,
		"guestPercent": 0.850616,
		"mostGuestDate": "2023-05-04 00:00:00",
		"mostGuestName": "Cabaret du Casino",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 111
	},
	{
		"id": "3f093d4",
		"email": "meg@welcometonightvale.com",
		"name": "Meg",
		"createdDate": "2017-04-11 19:36:26",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 63,
		"totalCountries": 1,
		"totalStates": 28,
		"totalEvents": 42,
		"eventPercent": 0.270957,
		"totalScheduleItems": 506,
		"schedulePercent": 0.301814,
		"totalGuests": 113,
		"guestPercent": 0.485366,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "The Bell House",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 10
	},
	{
		"id": "37da31c",
		"email": "hannah@lytlemgmt.com",
		"name": "Hannah",
		"createdDate": "2013-12-20 00:06:24",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 349,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 167,
		"eventPercent": 0.745332,
		"totalScheduleItems": 1336,
		"schedulePercent": 0.57648,
		"totalGuests": 1095,
		"guestPercent": 0.838564,
		"mostGuestDate": "2023-12-08 00:00:00",
		"mostGuestName": "The Chelsea",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 53
	},
	{
		"id": "5bab651",
		"email": "alecjamesrussell@gmail.com",
		"name": "Alec",
		"createdDate": "2015-04-01 22:02:27",
		"totalOrganizations": 9,
		"totalTours": 18,
		"totalDays": 273,
		"totalCountries": 10,
		"totalStates": 53,
		"totalEvents": 157,
		"eventPercent": 0.720567,
		"totalScheduleItems": 2271,
		"schedulePercent": 0.762151,
		"totalGuests": 29,
		"guestPercent": 0.387896,
		"mostGuestDate": "2023-07-01 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 20
	},
	{
		"id": "a89c97a",
		"email": "janne@piikkikasvi.fi",
		"name": "Janne",
		"createdDate": "2016-11-11 12:15:53",
		"totalOrganizations": 3,
		"totalTours": 39,
		"totalDays": 155,
		"totalCountries": 9,
		"totalStates": 34,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 748,
		"schedulePercent": 0.401006,
		"totalGuests": 21,
		"guestPercent": 0.371342,
		"mostGuestDate": "2023-09-14 00:00:00",
		"mostGuestName": "Horseshoe Tavern",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 6
	},
	{
		"id": "7e122dd",
		"email": "iraytouring@gmail.com",
		"name": "Immy",
		"createdDate": "2019-06-17 15:44:23",
		"totalOrganizations": 16,
		"totalTours": 34,
		"totalDays": 1554,
		"totalCountries": 22,
		"totalStates": 103,
		"totalEvents": 570,
		"eventPercent": 0.98093,
		"totalScheduleItems": 7566,
		"schedulePercent": 0.991657,
		"totalGuests": 2509,
		"guestPercent": 0.954311,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 57
	},
	{
		"id": "f3739f4",
		"email": "jessical@alist.com.au",
		"name": "Jessica ",
		"createdDate": "2023-01-03 04:51:25",
		"totalOrganizations": 22,
		"totalTours": 49,
		"totalDays": 1047,
		"totalCountries": 3,
		"totalStates": 16,
		"totalEvents": 707,
		"eventPercent": 0.986227,
		"totalScheduleItems": 3186,
		"schedulePercent": 0.868494,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2c7b61f",
		"email": "mlsound@wowway.com",
		"name": "Mike",
		"createdDate": "2010-06-22 21:46:39",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 59,
		"totalCountries": 4,
		"totalStates": 9,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 323,
		"schedulePercent": 0.220501,
		"totalGuests": 90,
		"guestPercent": 0.463912,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "ACL Live @ Moody Theater",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 28
	},
	{
		"id": "ac47c78",
		"email": "upsincethursday@gmail.com",
		"name": "Jermaine",
		"createdDate": "2013-07-31 21:50:10",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 241,
		"totalCountries": 4,
		"totalStates": 19,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 297,
		"schedulePercent": 0.206595,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "125d4c2",
		"email": "chris@thisisgoodnoise.com",
		"name": "Chris",
		"createdDate": "2023-02-03 05:00:32",
		"totalOrganizations": 1,
		"totalTours": 9,
		"totalDays": 52,
		"totalCountries": 4,
		"totalStates": 7,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 432,
		"schedulePercent": 0.269103,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "dd4f533",
		"email": "matt@sheltur.co",
		"name": "matt",
		"createdDate": "2023-07-02 18:08:30",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 164,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 12,
		"eventPercent": 0.0814462,
		"totalScheduleItems": 148,
		"schedulePercent": 0.118395,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c316d31",
		"email": "jellyrollhelper@gmail.com",
		"name": "Seth",
		"createdDate": "2023-11-24 17:56:38",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 40,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 202,
		"schedulePercent": 0.1519,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f06d2c3",
		"email": "neil@punkdafunk.com",
		"name": "Neil",
		"createdDate": "2011-01-28 13:06:55",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 1285,
		"totalCountries": 12,
		"totalStates": 34,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 740,
		"schedulePercent": 0.396371,
		"totalGuests": 367,
		"guestPercent": 0.64018,
		"mostGuestDate": "2023-01-04 00:00:00",
		"mostGuestName": "Paramount Theatre",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 53
	},
	{
		"id": "c4a18ef",
		"email": "Josh.Werblun@me.com",
		"name": "Josh",
		"createdDate": "2016-07-19 20:42:35",
		"totalOrganizations": 18,
		"totalTours": 38,
		"totalDays": 1217,
		"totalCountries": 3,
		"totalStates": 44,
		"totalEvents": 280,
		"eventPercent": 0.901867,
		"totalScheduleItems": 5240,
		"schedulePercent": 0.968481,
		"totalGuests": 999,
		"guestPercent": 0.823732,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "Firstbank Amphitheater",
		"mostGuestCity": "Franklin",
		"mostGuestAmount": 47
	},
	{
		"id": "b24f334",
		"email": "patrickcole239@gmail.com",
		"name": "Patrick",
		"createdDate": "2018-10-30 01:50:50",
		"totalOrganizations": 1,
		"totalTours": 10,
		"totalDays": 308,
		"totalCountries": 17,
		"totalStates": 74,
		"totalEvents": 220,
		"eventPercent": 0.83671,
		"totalScheduleItems": 2955,
		"schedulePercent": 0.84757,
		"totalGuests": 1221,
		"guestPercent": 0.86121,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Royal Festival Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 35
	},
	{
		"id": "0c96ce3",
		"email": "Tensetenor@earthlink.net",
		"name": "K",
		"createdDate": "2016-07-10 23:39:10",
		"totalOrganizations": 4,
		"totalTours": 7,
		"totalDays": 468,
		"totalCountries": 3,
		"totalStates": 40,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 2890,
		"schedulePercent": 0.840418,
		"totalGuests": 2685,
		"guestPercent": 0.959873,
		"mostGuestDate": "2023-08-02 00:00:00",
		"mostGuestName": "Northwell Health at Jones Beach Theater",
		"mostGuestCity": "Wantagh",
		"mostGuestAmount": 109
	},
	{
		"id": "7d44d2a",
		"email": "andyhayward@mac.com",
		"name": "Andy",
		"createdDate": "2009-09-30 21:52:33",
		"totalOrganizations": 4,
		"totalTours": 11,
		"totalDays": 248,
		"totalCountries": 11,
		"totalStates": 53,
		"totalEvents": 134,
		"eventPercent": 0.658721,
		"totalScheduleItems": 2582,
		"schedulePercent": 0.803072,
		"totalGuests": 2440,
		"guestPercent": 0.951927,
		"mostGuestDate": "2023-10-17 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 171
	},
	{
		"id": "945d9fc",
		"email": "jgtouring@saylesegan.com",
		"name": "Jonathan",
		"createdDate": "2022-03-28 14:57:18",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 257,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 1274,
		"schedulePercent": 0.557674,
		"totalGuests": 232,
		"guestPercent": 0.569196,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 35
	},
	{
		"id": "a019b06",
		"email": "summer@topicartists.com",
		"name": "Summer",
		"createdDate": "2021-11-08 21:24:10",
		"totalOrganizations": 5,
		"totalTours": 11,
		"totalDays": 999,
		"totalCountries": 6,
		"totalStates": 15,
		"totalEvents": 81,
		"eventPercent": 0.463117,
		"totalScheduleItems": 628,
		"schedulePercent": 0.353331,
		"totalGuests": 38,
		"guestPercent": 0.402198,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "Lansdowne Hotel",
		"mostGuestCity": "Chippendale",
		"mostGuestAmount": 18
	},
	{
		"id": "6809ec1",
		"email": "info@hotsardines.com",
		"name": "Gwen",
		"createdDate": "2016-07-28 15:24:24",
		"totalOrganizations": 2,
		"totalTours": 18,
		"totalDays": 38,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 29,
		"eventPercent": 0.194411,
		"totalScheduleItems": 0,
		"schedulePercent": 0,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "712db64",
		"email": "caroline.rudolph@notapublicfiguremgmt.com",
		"name": "Caroline",
		"createdDate": "2019-01-11 00:09:24",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 37,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 1829,
		"schedulePercent": 0.691299,
		"totalGuests": 196,
		"guestPercent": 0.546947,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 64
	},
	{
		"id": "c1d447c",
		"email": "joe@zakabelmanagement.com",
		"name": "ZAK",
		"createdDate": "2023-09-27 14:13:52",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 36,
		"totalCountries": 9,
		"totalStates": 11,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 264,
		"schedulePercent": 0.189114,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7ceecde",
		"email": "ari@friendlyannouncer.com",
		"name": "Ari",
		"createdDate": "2021-10-11 22:35:01",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 203,
		"totalCountries": 15,
		"totalStates": 52,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 1618,
		"schedulePercent": 0.646272,
		"totalGuests": 1209,
		"guestPercent": 0.858429,
		"mostGuestDate": "2023-08-09 00:00:00",
		"mostGuestName": "Hollywood Forever",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 88
	},
	{
		"id": "6e157f6",
		"email": "donivandsc@aol.com",
		"name": "Donivan",
		"createdDate": "2015-11-02 19:53:11",
		"totalOrganizations": 3,
		"totalTours": 10,
		"totalDays": 105,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 415,
		"schedulePercent": 0.262747,
		"totalGuests": 102,
		"guestPercent": 0.477155,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "Alex Theatre",
		"mostGuestCity": "Glendale",
		"mostGuestAmount": 20
	},
	{
		"id": "6e93435",
		"email": "williamlevy38@gmail.com",
		"name": "Will",
		"createdDate": "2017-04-27 17:40:26",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 98,
		"totalCountries": 10,
		"totalStates": 37,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 622,
		"schedulePercent": 0.350417,
		"totalGuests": 591,
		"guestPercent": 0.722818,
		"mostGuestDate": "2023-02-04 00:00:00",
		"mostGuestName": "Garden Amphitheatre",
		"mostGuestCity": "Garden Grove",
		"mostGuestAmount": 75
	},
	{
		"id": "405ac90",
		"email": "ksanchez@balletx.org",
		"name": "Kereni",
		"createdDate": "2023-02-26 14:39:15",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 44,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 291,
		"schedulePercent": 0.203682,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-03-25 00:00:00",
		"mostGuestName": "Vilar Performing Arts Center",
		"mostGuestCity": "Beaver Creek",
		"mostGuestAmount": 6
	},
	{
		"id": "7feefd4",
		"email": "Adam@AdamJStuart.com",
		"name": "Adam",
		"createdDate": "2016-01-26 01:49:01",
		"totalOrganizations": 7,
		"totalTours": 4,
		"totalDays": 277,
		"totalCountries": 16,
		"totalStates": 43,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 670,
		"schedulePercent": 0.368958,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2396cec",
		"email": "Somedude50@hotmail.com",
		"name": "Kelly",
		"createdDate": "2013-01-11 22:24:37",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 118,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 791,
		"schedulePercent": 0.417296,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8b1f204",
		"email": "Michaelthomasnoonan@gmail.com",
		"name": "Michael",
		"createdDate": "2016-10-17 19:13:01",
		"totalOrganizations": 8,
		"totalTours": 28,
		"totalDays": 814,
		"totalCountries": 6,
		"totalStates": 15,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 896,
		"schedulePercent": 0.452126,
		"totalGuests": 232,
		"guestPercent": 0.569196,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "b3bd010",
		"email": "dave@domovoi-event-services.co.uk",
		"name": "Dave ",
		"createdDate": "2021-11-16 00:56:24",
		"totalOrganizations": 10,
		"totalTours": 11,
		"totalDays": 191,
		"totalCountries": 9,
		"totalStates": 17,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 1173,
		"schedulePercent": 0.53291,
		"totalGuests": 166,
		"guestPercent": 0.52748,
		"mostGuestDate": "2023-02-25 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 30
	},
	{
		"id": "fb6e624",
		"email": "cjtour@mfmgt.com",
		"name": "C",
		"createdDate": "2022-04-14 23:50:52",
		"totalOrganizations": 3,
		"totalTours": 16,
		"totalDays": 253,
		"totalCountries": 2,
		"totalStates": 22,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 944,
		"schedulePercent": 0.463515,
		"totalGuests": 568,
		"guestPercent": 0.715534,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "London Music Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 34
	},
	{
		"id": "8d3f01a",
		"email": "sidneygracewagner@me.com",
		"name": "Sidney",
		"createdDate": "2018-12-12 19:25:06",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 478,
		"totalCountries": 2,
		"totalStates": 32,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 1867,
		"schedulePercent": 0.69898,
		"totalGuests": 389,
		"guestPercent": 0.648656,
		"mostGuestDate": "2023-04-13 00:00:00",
		"mostGuestName": "The Fisher Center for the Performing Arts",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 104
	},
	{
		"id": "cd5a082",
		"email": "Nicoleerinmassey@icloud.com",
		"name": "Nicole",
		"createdDate": "2019-03-21 21:52:09",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 364,
		"totalCountries": 12,
		"totalStates": 20,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 402,
		"schedulePercent": 0.257582,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "68771cc",
		"email": "stephanie@rocketentertainment.com",
		"name": "Stephanie",
		"createdDate": "2019-08-09 09:30:34",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 319,
		"totalCountries": 9,
		"totalStates": 18,
		"totalEvents": 145,
		"eventPercent": 0.688518,
		"totalScheduleItems": 1155,
		"schedulePercent": 0.528142,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d1d8591",
		"email": "Steve@pullthepinout.co.uk",
		"name": "Stephen",
		"createdDate": "2019-06-04 16:51:15",
		"totalOrganizations": 5,
		"totalTours": 2,
		"totalDays": 252,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 281,
		"schedulePercent": 0.198384,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e16bcbe",
		"email": "adam@blainartistmanagement.com",
		"name": "Adam",
		"createdDate": "2017-01-10 19:25:40",
		"totalOrganizations": 12,
		"totalTours": 12,
		"totalDays": 115,
		"totalCountries": 3,
		"totalStates": 23,
		"totalEvents": 47,
		"eventPercent": 0.296385,
		"totalScheduleItems": 460,
		"schedulePercent": 0.280625,
		"totalGuests": 249,
		"guestPercent": 0.578334,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Los Angeles Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 44
	},
	{
		"id": "9986666",
		"email": "eddiekercher@mac.com",
		"name": "Eddie",
		"createdDate": "2010-05-11 21:49:44",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 568,
		"totalCountries": 11,
		"totalStates": 56,
		"totalEvents": 212,
		"eventPercent": 0.826778,
		"totalScheduleItems": 3348,
		"schedulePercent": 0.882929,
		"totalGuests": 3491,
		"guestPercent": 0.976824,
		"mostGuestDate": "2023-10-14 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 185
	},
	{
		"id": "3f9f94f",
		"email": "elise@friendlyannouncer.com",
		"name": "Elise",
		"createdDate": "2023-08-01 16:29:59",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 34,
		"totalCountries": 6,
		"totalStates": 10,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 199,
		"schedulePercent": 0.149781,
		"totalGuests": 29,
		"guestPercent": 0.387896,
		"mostGuestDate": "2023-08-22 00:00:00",
		"mostGuestName": "Kantine am Berghain",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 17
	},
	{
		"id": "07178eb",
		"email": "jonathantrier@mac.com",
		"name": "Jon",
		"createdDate": "2012-05-07 20:33:17",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 122,
		"totalCountries": 4,
		"totalStates": 8,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 231,
		"schedulePercent": 0.169779,
		"totalGuests": 125,
		"guestPercent": 0.494636,
		"mostGuestDate": "2023-09-24 00:00:00",
		"mostGuestName": "Barbican Centre",
		"mostGuestCity": "London",
		"mostGuestAmount": 48
	},
	{
		"id": "a18a019",
		"email": "ben@hotmgmt.com",
		"name": "Ben",
		"createdDate": "2022-04-19 22:23:56",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 202,
		"totalCountries": 4,
		"totalStates": 35,
		"totalEvents": 109,
		"eventPercent": 0.57542,
		"totalScheduleItems": 955,
		"schedulePercent": 0.467355,
		"totalGuests": 38,
		"guestPercent": 0.402198,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "The Grace",
		"mostGuestCity": "London",
		"mostGuestAmount": 10
	},
	{
		"id": "1fc24ce",
		"email": "williamramirez@me.com",
		"name": "William",
		"createdDate": "2016-01-15 00:40:04",
		"totalOrganizations": 10,
		"totalTours": 5,
		"totalDays": 941,
		"totalCountries": 9,
		"totalStates": 38,
		"totalEvents": 59,
		"eventPercent": 0.356377,
		"totalScheduleItems": 892,
		"schedulePercent": 0.450536,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "0c34afd",
		"email": "TamaraLSimmons22@gmail.com",
		"name": "Tamara L",
		"createdDate": "2023-10-18 02:18:40",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 136,
		"totalCountries": 2,
		"totalStates": 28,
		"totalEvents": 68,
		"eventPercent": 0.397828,
		"totalScheduleItems": 1319,
		"schedulePercent": 0.570785,
		"totalGuests": 305,
		"guestPercent": 0.606277,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Altria Theater",
		"mostGuestCity": "Richmond",
		"mostGuestAmount": 36
	},
	{
		"id": "513a220",
		"email": "makaela.glancy@gmail.com",
		"name": "Makaela",
		"createdDate": "2023-01-06 00:37:46",
		"totalOrganizations": 10,
		"totalTours": 15,
		"totalDays": 399,
		"totalCountries": 10,
		"totalStates": 59,
		"totalEvents": 197,
		"eventPercent": 0.800821,
		"totalScheduleItems": 2625,
		"schedulePercent": 0.811416,
		"totalGuests": 1666,
		"guestPercent": 0.909151,
		"mostGuestDate": "2023-03-10 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 180
	},
	{
		"id": "56322c4",
		"email": "booking@monkeymusic.dk",
		"name": "Mek",
		"createdDate": "2023-03-25 08:14:49",
		"totalOrganizations": 13,
		"totalTours": 11,
		"totalDays": 66,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 62,
		"eventPercent": 0.372798,
		"totalScheduleItems": 152,
		"schedulePercent": 0.121971,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2f0918d",
		"email": "crystal@shopkeepermgmt.com",
		"name": "Crystal",
		"createdDate": "2012-03-15 19:43:05",
		"totalOrganizations": 7,
		"totalTours": 2,
		"totalDays": 386,
		"totalCountries": 4,
		"totalStates": 32,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 660,
		"schedulePercent": 0.365382,
		"totalGuests": 27,
		"guestPercent": 0.38379,
		"mostGuestDate": "2023-01-25 00:00:00",
		"mostGuestName": "Arts Commons",
		"mostGuestCity": "Calgary",
		"mostGuestAmount": 8
	},
	{
		"id": "6cd9383",
		"email": "ortet.jh@gmail.com",
		"name": "JH",
		"createdDate": "2020-01-27 11:05:30",
		"totalOrganizations": 8,
		"totalTours": 2,
		"totalDays": 228,
		"totalCountries": 3,
		"totalStates": 17,
		"totalEvents": 167,
		"eventPercent": 0.745332,
		"totalScheduleItems": 1540,
		"schedulePercent": 0.627599,
		"totalGuests": 449,
		"guestPercent": 0.673553,
		"mostGuestDate": "2023-04-12 00:00:00",
		"mostGuestName": "Bataclan",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 36
	},
	{
		"id": "6dd82f6",
		"email": "peteparenteau@gmail.com",
		"name": "Pete",
		"createdDate": "2013-07-15 20:38:02",
		"totalOrganizations": 8,
		"totalTours": 13,
		"totalDays": 766,
		"totalCountries": 10,
		"totalStates": 56,
		"totalEvents": 199,
		"eventPercent": 0.804264,
		"totalScheduleItems": 4196,
		"schedulePercent": 0.933784,
		"totalGuests": 4076,
		"guestPercent": 0.984638,
		"mostGuestDate": "2023-07-08 00:00:00",
		"mostGuestName": "Raymond James Stadium",
		"mostGuestCity": "Tampa ",
		"mostGuestAmount": 248
	},
	{
		"id": "1d75602",
		"email": "gregorypruvot@gmail.com",
		"name": "Gregory",
		"createdDate": "2022-12-17 09:53:59",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 238,
		"totalCountries": 13,
		"totalStates": 27,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 925,
		"schedulePercent": 0.458217,
		"totalGuests": 569,
		"guestPercent": 0.715799,
		"mostGuestDate": "2023-08-09 00:00:00",
		"mostGuestName": "Plaza de Toros Alicante",
		"mostGuestCity": "Alacant",
		"mostGuestAmount": 28
	},
	{
		"id": "867a97f",
		"email": "bethbrown81@outlook.com",
		"name": "Beth",
		"createdDate": "2019-11-22 16:47:51",
		"totalOrganizations": 11,
		"totalTours": 5,
		"totalDays": 333,
		"totalCountries": 4,
		"totalStates": 37,
		"totalEvents": 208,
		"eventPercent": 0.819891,
		"totalScheduleItems": 1443,
		"schedulePercent": 0.602039,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "dc25e68",
		"email": "kyle.peters@sslproductions.com",
		"name": "Kyle",
		"createdDate": "2023-01-27 02:50:43",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 91,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 311,
		"schedulePercent": 0.214144,
		"totalGuests": 19,
		"guestPercent": 0.366971,
		"mostGuestDate": "2023-05-19 00:00:00",
		"mostGuestName": "The Liacouras Center",
		"mostGuestCity": "Philadelphia",
		"mostGuestAmount": 9
	},
	{
		"id": "180a188",
		"email": "thunderbird@viscom.net",
		"name": "Rueben",
		"createdDate": "2012-12-18 16:53:02",
		"totalOrganizations": 13,
		"totalTours": 13,
		"totalDays": 892,
		"totalCountries": 13,
		"totalStates": 71,
		"totalEvents": 409,
		"eventPercent": 0.959873,
		"totalScheduleItems": 2999,
		"schedulePercent": 0.850616,
		"totalGuests": 424,
		"guestPercent": 0.663223,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Greenfield Lake Amphitheatre",
		"mostGuestCity": "Wilmington",
		"mostGuestAmount": 20
	},
	{
		"id": "3f7b35b",
		"email": "angelo@radarfestival.co.uk",
		"name": "Angelo",
		"createdDate": "2019-09-18 13:16:48",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 101,
		"totalCountries": 15,
		"totalStates": 46,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 1195,
		"schedulePercent": 0.538737,
		"totalGuests": 611,
		"guestPercent": 0.729043,
		"mostGuestDate": "2023-04-01 00:00:00",
		"mostGuestName": "Electric Ballroom",
		"mostGuestCity": "London",
		"mostGuestAmount": 76
	},
	{
		"id": "d3ed0df",
		"email": "Russopro@hotmail.com",
		"name": "Jimmy",
		"createdDate": "2018-05-29 22:33:11",
		"totalOrganizations": 15,
		"totalTours": 18,
		"totalDays": 1034,
		"totalCountries": 13,
		"totalStates": 66,
		"totalEvents": 167,
		"eventPercent": 0.745332,
		"totalScheduleItems": 3830,
		"schedulePercent": 0.914051,
		"totalGuests": 896,
		"guestPercent": 0.799099,
		"mostGuestDate": "2023-06-27 00:00:00",
		"mostGuestName": "Trinity College Dublin",
		"mostGuestCity": "Dublin",
		"mostGuestAmount": 52
	},
	{
		"id": "4aac31b",
		"email": "Hoffismail@aol.com",
		"name": "David",
		"createdDate": "2012-10-19 04:47:41",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 209,
		"totalCountries": 4,
		"totalStates": 31,
		"totalEvents": 67,
		"eventPercent": 0.393193,
		"totalScheduleItems": 804,
		"schedulePercent": 0.421931,
		"totalGuests": 406,
		"guestPercent": 0.65594,
		"mostGuestDate": "2023-03-04 00:00:00",
		"mostGuestName": "INTERNATIONAL THEATER",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 41
	},
	{
		"id": "19afcc5",
		"email": "gwilson@hadproductions.net",
		"name": "Gary",
		"createdDate": "2020-01-15 20:34:30",
		"totalOrganizations": 4,
		"totalTours": 9,
		"totalDays": 494,
		"totalCountries": 9,
		"totalStates": 34,
		"totalEvents": 157,
		"eventPercent": 0.720567,
		"totalScheduleItems": 1730,
		"schedulePercent": 0.671037,
		"totalGuests": 1306,
		"guestPercent": 0.872467,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 66
	},
	{
		"id": "eb5ad69",
		"email": "tarekhammad80@gmail.com",
		"name": "Tarek",
		"createdDate": "2014-06-11 17:17:41",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 247,
		"totalCountries": 4,
		"totalStates": 29,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 1325,
		"schedulePercent": 0.573037,
		"totalGuests": 555,
		"guestPercent": 0.711296,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "del Lago Resort & Casino",
		"mostGuestCity": "Waterloo",
		"mostGuestAmount": 39
	},
	{
		"id": "5e1d560",
		"email": "615KVZ@gmail.com",
		"name": "Kristen",
		"createdDate": "2017-06-28 14:52:57",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 1095,
		"totalCountries": 3,
		"totalStates": 43,
		"totalEvents": 239,
		"eventPercent": 0.860681,
		"totalScheduleItems": 3085,
		"schedulePercent": 0.859489,
		"totalGuests": 2867,
		"guestPercent": 0.966759,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 137
	},
	{
		"id": "a093cd4",
		"email": "jacobsawicki@thenativehowl.com",
		"name": "Jacob",
		"createdDate": "2019-02-23 22:09:30",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 62,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 239,
		"schedulePercent": 0.175209,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "42b3f95",
		"email": "thefrontbottomsmusic@gmail.com",
		"name": "Matt",
		"createdDate": "2014-06-07 00:46:06",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 93,
		"totalCountries": 3,
		"totalStates": 37,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 537,
		"schedulePercent": 0.316514,
		"totalGuests": 818,
		"guestPercent": 0.779764,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 88
	},
	{
		"id": "350ff66",
		"email": "alexzandratours@gmail.com",
		"name": "Alexzandra ",
		"createdDate": "2022-05-26 01:45:41",
		"totalOrganizations": 7,
		"totalTours": 14,
		"totalDays": 1062,
		"totalCountries": 11,
		"totalStates": 42,
		"totalEvents": 176,
		"eventPercent": 0.763475,
		"totalScheduleItems": 2530,
		"schedulePercent": 0.796451,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8c1f46a",
		"email": "christopher.ryan.reynolds@gmail.com",
		"name": "Christopher",
		"createdDate": "2023-02-17 23:51:52",
		"totalOrganizations": 7,
		"totalTours": 13,
		"totalDays": 292,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 1111,
		"schedulePercent": 0.513972,
		"totalGuests": 229,
		"guestPercent": 0.568402,
		"mostGuestDate": "2023-09-14 00:00:00",
		"mostGuestName": "Emo's",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 41
	},
	{
		"id": "d6f775f",
		"email": "laura.haarala@piikkikasvi.fi",
		"name": "Laura",
		"createdDate": "2020-08-26 09:32:12",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 915,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 265,
		"schedulePercent": 0.189511,
		"totalGuests": 20,
		"guestPercent": 0.36909,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Krapin Paja",
		"mostGuestCity": "Tuusula",
		"mostGuestAmount": 11
	},
	{
		"id": "d0130ea",
		"email": "helloitslozo@gmail.com",
		"name": "Lois",
		"createdDate": "2022-07-18 20:55:56",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 160,
		"totalCountries": 5,
		"totalStates": 19,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 505,
		"schedulePercent": 0.301152,
		"totalGuests": 74,
		"guestPercent": 0.449212,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "South Side Ballroom",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 17
	},
	{
		"id": "cde4627",
		"email": "jesse@rosamortem.com",
		"name": "Jesse",
		"createdDate": "2022-02-02 03:52:12",
		"totalOrganizations": 10,
		"totalTours": 13,
		"totalDays": 680,
		"totalCountries": 13,
		"totalStates": 57,
		"totalEvents": 215,
		"eventPercent": 0.831016,
		"totalScheduleItems": 3678,
		"schedulePercent": 0.906105,
		"totalGuests": 2518,
		"guestPercent": 0.954708,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 300
	},
	{
		"id": "7b886c5",
		"email": "bcbrinton@gmail.com",
		"name": "Blaine",
		"createdDate": "2010-06-25 20:34:39",
		"totalOrganizations": 11,
		"totalTours": 17,
		"totalDays": 464,
		"totalCountries": 13,
		"totalStates": 53,
		"totalEvents": 186,
		"eventPercent": 0.782016,
		"totalScheduleItems": 3937,
		"schedulePercent": 0.92054,
		"totalGuests": 2995,
		"guestPercent": 0.969276,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Little Caesars Arena",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 205
	},
	{
		"id": "b61dfcf",
		"email": "katsu@nakstudios.com",
		"name": "Email ",
		"createdDate": "2015-11-01 17:30:46",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 47,
		"totalCountries": 3,
		"totalStates": 6,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 402,
		"schedulePercent": 0.257582,
		"totalGuests": 431,
		"guestPercent": 0.66521,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Brighton Dome",
		"mostGuestCity": "Brighton",
		"mostGuestAmount": 40
	},
	{
		"id": "0620cba",
		"email": "p.symington@gmail.com",
		"name": "Paul",
		"createdDate": "2011-10-10 22:29:11",
		"totalOrganizations": 10,
		"totalTours": 6,
		"totalDays": 585,
		"totalCountries": 11,
		"totalStates": 18,
		"totalEvents": 118,
		"eventPercent": 0.606939,
		"totalScheduleItems": 1879,
		"schedulePercent": 0.701099,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "bd049e8",
		"email": "mattdrastic@gmail.com",
		"name": "Matt",
		"createdDate": "2013-10-20 22:47:37",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 96,
		"totalCountries": 4,
		"totalStates": 34,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 532,
		"schedulePercent": 0.313733,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-07-16 00:00:00",
		"mostGuestName": "House of Independents",
		"mostGuestCity": "Asbury Park",
		"mostGuestAmount": 1
	},
	{
		"id": "caec8d0",
		"email": "justin@jxrlimited.com",
		"name": "Justin",
		"createdDate": "2016-06-19 09:50:19",
		"totalOrganizations": 36,
		"totalTours": 42,
		"totalDays": 1289,
		"totalCountries": 17,
		"totalStates": 85,
		"totalEvents": 472,
		"eventPercent": 0.970997,
		"totalScheduleItems": 6560,
		"schedulePercent": 0.985432,
		"totalGuests": 2754,
		"guestPercent": 0.962919,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Cow Palace",
		"mostGuestCity": "Daly City",
		"mostGuestAmount": 226
	},
	{
		"id": "dce95a4",
		"email": "Sambeaumusic@gmail.com",
		"name": "Sam",
		"createdDate": "2018-03-08 17:58:40",
		"totalOrganizations": 13,
		"totalTours": 33,
		"totalDays": 961,
		"totalCountries": 18,
		"totalStates": 80,
		"totalEvents": 352,
		"eventPercent": 0.942127,
		"totalScheduleItems": 4536,
		"schedulePercent": 0.946762,
		"totalGuests": 1758,
		"guestPercent": 0.917494,
		"mostGuestDate": "2023-03-01 00:00:00",
		"mostGuestName": "Shrine Auditorium and Expo Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 135
	},
	{
		"id": "c83f864",
		"email": "mberdan80@gmail.com",
		"name": "Michael",
		"createdDate": "2018-07-13 14:04:46",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 63,
		"totalCountries": 5,
		"totalStates": 19,
		"totalEvents": 38,
		"eventPercent": 0.246855,
		"totalScheduleItems": 182,
		"schedulePercent": 0.139849,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "721d6d9",
		"email": "jessicasheehan35@gmail.com",
		"name": "Jessica",
		"createdDate": "2017-11-14 21:20:15",
		"totalOrganizations": 7,
		"totalTours": 4,
		"totalDays": 578,
		"totalCountries": 8,
		"totalStates": 47,
		"totalEvents": 153,
		"eventPercent": 0.711032,
		"totalScheduleItems": 2935,
		"schedulePercent": 0.845848,
		"totalGuests": 861,
		"guestPercent": 0.790359,
		"mostGuestDate": "2023-04-20 00:00:00",
		"mostGuestName": "KFC Yum! Center",
		"mostGuestCity": "Louisville",
		"mostGuestAmount": 154
	},
	{
		"id": "f1d2132",
		"email": "danbartell@me.com",
		"name": "Dan",
		"createdDate": "2012-05-10 02:17:32",
		"totalOrganizations": 7,
		"totalTours": 7,
		"totalDays": 244,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 340,
		"schedulePercent": 0.228049,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-06-20 00:00:00",
		"mostGuestName": "Pechanga Arena",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 2
	},
	{
		"id": "89039a2",
		"email": "enginelightsmusic@gmail.com",
		"name": "John",
		"createdDate": "2023-03-22 11:36:16",
		"totalOrganizations": 1,
		"totalTours": 5,
		"totalDays": 116,
		"totalCountries": 1,
		"totalStates": 28,
		"totalEvents": 76,
		"eventPercent": 0.441001,
		"totalScheduleItems": 427,
		"schedulePercent": 0.266587,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-06-11 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 1
	},
	{
		"id": "8e4f5b6",
		"email": "torferrell@mac.com",
		"name": "Victoria",
		"createdDate": "2015-02-20 12:37:43",
		"totalOrganizations": 24,
		"totalTours": 14,
		"totalDays": 138,
		"totalCountries": 10,
		"totalStates": 31,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 969,
		"schedulePercent": 0.471063,
		"totalGuests": 37,
		"guestPercent": 0.400212,
		"mostGuestDate": "2023-02-28 00:00:00",
		"mostGuestName": "La Cigale",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 16
	},
	{
		"id": "82e200c",
		"email": "hoppie@sundaymorning.la",
		"name": "Hoppie",
		"createdDate": "2019-03-22 22:24:01",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 403,
		"totalCountries": 5,
		"totalStates": 9,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 162,
		"schedulePercent": 0.128195,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c30c602",
		"email": "peter.gomez@lifeline.se",
		"name": "Peter",
		"createdDate": "2022-03-08 12:33:27",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 362,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 144,
		"eventPercent": 0.685605,
		"totalScheduleItems": 640,
		"schedulePercent": 0.358496,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "2c58f6e",
		"email": "info@stularsen.com",
		"name": "Stu-Nora",
		"createdDate": "2012-12-12 11:59:46",
		"totalOrganizations": 7,
		"totalTours": 11,
		"totalDays": 209,
		"totalCountries": 13,
		"totalStates": 23,
		"totalEvents": 78,
		"eventPercent": 0.451066,
		"totalScheduleItems": 817,
		"schedulePercent": 0.425374,
		"totalGuests": 244,
		"guestPercent": 0.575288,
		"mostGuestDate": "2023-11-15 00:00:00",
		"mostGuestName": "Union Chapel",
		"mostGuestCity": "London",
		"mostGuestAmount": 87
	},
	{
		"id": "7a7e5cc",
		"email": "gavin.hansen@me.com",
		"name": "Gavin",
		"createdDate": "2016-10-17 00:59:06",
		"totalOrganizations": 11,
		"totalTours": 8,
		"totalDays": 326,
		"totalCountries": 4,
		"totalStates": 14,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 628,
		"schedulePercent": 0.353331,
		"totalGuests": 97,
		"guestPercent": 0.471461,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "John Cain Arena",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 35
	},
	{
		"id": "ac48043",
		"email": "gina.orr@gmail.com",
		"name": "Gina",
		"createdDate": "2013-02-07 16:53:29",
		"totalOrganizations": 9,
		"totalTours": 13,
		"totalDays": 373,
		"totalCountries": 18,
		"totalStates": 80,
		"totalEvents": 254,
		"eventPercent": 0.879619,
		"totalScheduleItems": 3310,
		"schedulePercent": 0.878294,
		"totalGuests": 1221,
		"guestPercent": 0.86121,
		"mostGuestDate": "2023-04-28 00:00:00",
		"mostGuestName": "Royal Festival Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 35
	},
	{
		"id": "278fda5",
		"email": "isa@cobraside.com",
		"name": "Isa",
		"createdDate": "2017-06-09 20:56:48",
		"totalOrganizations": 19,
		"totalTours": 14,
		"totalDays": 226,
		"totalCountries": 9,
		"totalStates": 52,
		"totalEvents": 149,
		"eventPercent": 0.698715,
		"totalScheduleItems": 709,
		"schedulePercent": 0.384585,
		"totalGuests": 148,
		"guestPercent": 0.51225,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "Music Hall of Williamsburg",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 16
	},
	{
		"id": "0809535",
		"email": "Ryanweaver1414@gmail.com",
		"name": "Ryan",
		"createdDate": "2019-03-01 20:51:51",
		"totalOrganizations": 8,
		"totalTours": 11,
		"totalDays": 796,
		"totalCountries": 7,
		"totalStates": 45,
		"totalEvents": 207,
		"eventPercent": 0.817905,
		"totalScheduleItems": 2395,
		"schedulePercent": 0.780029,
		"totalGuests": 608,
		"guestPercent": 0.728778,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 67
	},
	{
		"id": "617707a",
		"email": "dayofshow@aol.com",
		"name": "Matt ",
		"createdDate": "2011-09-03 23:29:18",
		"totalOrganizations": 10,
		"totalTours": 9,
		"totalDays": 468,
		"totalCountries": 9,
		"totalStates": 35,
		"totalEvents": 117,
		"eventPercent": 0.602172,
		"totalScheduleItems": 908,
		"schedulePercent": 0.454244,
		"totalGuests": 212,
		"guestPercent": 0.558469,
		"mostGuestDate": "2023-10-07 00:00:00",
		"mostGuestName": "North Island Credit Union Amphitheatre",
		"mostGuestCity": "Chula Vista",
		"mostGuestAmount": 24
	},
	{
		"id": "900e346",
		"email": "mtaylor@ctsaudio.com",
		"name": "Michael",
		"createdDate": "2011-01-28 13:28:14",
		"totalOrganizations": 5,
		"totalTours": 14,
		"totalDays": 878,
		"totalCountries": 5,
		"totalStates": 40,
		"totalEvents": 495,
		"eventPercent": 0.973778,
		"totalScheduleItems": 3471,
		"schedulePercent": 0.892067,
		"totalGuests": 823,
		"guestPercent": 0.781883,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "Macon Centreplex - City Auditorium",
		"mostGuestCity": "Macon",
		"mostGuestAmount": 60
	},
	{
		"id": "7a81bc5",
		"email": "jordan@wastedpotentialpresents.com",
		"name": "Jordan",
		"createdDate": "2017-08-08 12:59:54",
		"totalOrganizations": 22,
		"totalTours": 22,
		"totalDays": 1581,
		"totalCountries": 17,
		"totalStates": 58,
		"totalEvents": 278,
		"eventPercent": 0.900278,
		"totalScheduleItems": 3368,
		"schedulePercent": 0.884783,
		"totalGuests": 1380,
		"guestPercent": 0.881075,
		"mostGuestDate": "2023-05-10 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 170
	},
	{
		"id": "52dd2ec",
		"email": "craig@meyerentgroup.com",
		"name": "Craig A",
		"createdDate": "2019-07-17 22:08:19",
		"totalOrganizations": 1,
		"totalTours": 14,
		"totalDays": 62,
		"totalCountries": 1,
		"totalStates": 9,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 281,
		"schedulePercent": 0.198384,
		"totalGuests": 34,
		"guestPercent": 0.395842,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "The Carolina Opry",
		"mostGuestCity": "Myrtle Beach",
		"mostGuestAmount": 12
	},
	{
		"id": "82b474f",
		"email": "nbj25@me.com",
		"name": "Nick",
		"createdDate": "2013-06-28 07:50:14",
		"totalOrganizations": 2,
		"totalTours": 8,
		"totalDays": 531,
		"totalCountries": 2,
		"totalStates": 37,
		"totalEvents": 128,
		"eventPercent": 0.640975,
		"totalScheduleItems": 2254,
		"schedulePercent": 0.759237,
		"totalGuests": 4758,
		"guestPercent": 0.990068,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "The Pacific Amphitheatre",
		"mostGuestCity": "Costa Mesa",
		"mostGuestAmount": 238
	},
	{
		"id": "01bdc9c",
		"email": "iamchasebutler@gmail.com",
		"name": "Kevin",
		"createdDate": "2018-05-15 00:31:14",
		"totalOrganizations": 8,
		"totalTours": 7,
		"totalDays": 1512,
		"totalCountries": 9,
		"totalStates": 57,
		"totalEvents": 312,
		"eventPercent": 0.921997,
		"totalScheduleItems": 4224,
		"schedulePercent": 0.934976,
		"totalGuests": 2255,
		"guestPercent": 0.945967,
		"mostGuestDate": "2023-11-18 00:00:00",
		"mostGuestName": "Giant Center",
		"mostGuestCity": "Hershey",
		"mostGuestAmount": 72
	},
	{
		"id": "5414a24",
		"email": "craigwalker@devilhillsent.com",
		"name": "Craig",
		"createdDate": "2021-05-26 15:30:32",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 108,
		"totalCountries": 2,
		"totalStates": 30,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 378,
		"schedulePercent": 0.247252,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-04-25 00:00:00",
		"mostGuestName": "Fine Line Music Cafe",
		"mostGuestCity": "Minneapolis",
		"mostGuestAmount": 2
	},
	{
		"id": "bef75b7",
		"email": "dave@dmcprod.com",
		"name": "David",
		"createdDate": "2014-01-10 16:56:29",
		"totalOrganizations": 7,
		"totalTours": 8,
		"totalDays": 1428,
		"totalCountries": 18,
		"totalStates": 70,
		"totalEvents": 272,
		"eventPercent": 0.895643,
		"totalScheduleItems": 3416,
		"schedulePercent": 0.888492,
		"totalGuests": 274,
		"guestPercent": 0.590783,
		"mostGuestDate": "2023-06-30 00:00:00",
		"mostGuestName": "Billy Bob's Texas",
		"mostGuestCity": "Fort Worth",
		"mostGuestAmount": 56
	},
	{
		"id": "91f4f63",
		"email": "sandersonsound@yahoo.com",
		"name": "Charlie",
		"createdDate": "2012-03-12 03:36:02",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 35,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 20,
		"eventPercent": 0.133095,
		"totalScheduleItems": 346,
		"schedulePercent": 0.23136,
		"totalGuests": 480,
		"guestPercent": 0.683353,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "The Wharf Amphitheater",
		"mostGuestCity": "Orange Beach",
		"mostGuestAmount": 78
	},
	{
		"id": "8d9d18c",
		"email": "Kris5647@hotmail.com",
		"name": "Kristopher",
		"createdDate": "2018-03-31 05:42:46",
		"totalOrganizations": 11,
		"totalTours": 7,
		"totalDays": 140,
		"totalCountries": 11,
		"totalStates": 22,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 818,
		"schedulePercent": 0.425904,
		"totalGuests": 215,
		"guestPercent": 0.559529,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "170 Russell",
		"mostGuestCity": "Melbourne",
		"mostGuestAmount": 56
	},
	{
		"id": "362801e",
		"email": "jody@arcadia-us.net",
		"name": "Jody",
		"createdDate": "2011-09-14 20:38:03",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 40,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 495,
		"schedulePercent": 0.295722,
		"totalGuests": 363,
		"guestPercent": 0.637134,
		"mostGuestDate": "2023-05-09 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 55
	},
	{
		"id": "a10b96d",
		"email": "jessharrisonboond@gmail.com",
		"name": "Jess",
		"createdDate": "2022-12-20 12:59:01",
		"totalOrganizations": 23,
		"totalTours": 59,
		"totalDays": 1378,
		"totalCountries": 20,
		"totalStates": 85,
		"totalEvents": 555,
		"eventPercent": 0.980003,
		"totalScheduleItems": 6250,
		"schedulePercent": 0.982651,
		"totalGuests": 930,
		"guestPercent": 0.806913,
		"mostGuestDate": "2023-03-31 00:00:00",
		"mostGuestName": "Brooklyn Bowl Nashville",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 69
	},
	{
		"id": "03f832d",
		"email": "AndyShawPM@gmail.com",
		"name": "Andy",
		"createdDate": "2023-01-10 16:22:18",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 157,
		"totalCountries": 5,
		"totalStates": 31,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 1485,
		"schedulePercent": 0.610648,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "34bde72",
		"email": "shawn@trueartist.org",
		"name": "Shawn",
		"createdDate": "2011-11-05 02:15:11",
		"totalOrganizations": 12,
		"totalTours": 9,
		"totalDays": 292,
		"totalCountries": 3,
		"totalStates": 31,
		"totalEvents": 202,
		"eventPercent": 0.810091,
		"totalScheduleItems": 2141,
		"schedulePercent": 0.740564,
		"totalGuests": 2790,
		"guestPercent": 0.964243,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "Gas South Arena",
		"mostGuestCity": "Duluth",
		"mostGuestAmount": 157
	},
	{
		"id": "023df98",
		"email": "paulrossilee@gmail.com",
		"name": "Paul ",
		"createdDate": "2023-02-22 14:08:07",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 200,
		"totalCountries": 4,
		"totalStates": 32,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1135,
		"schedulePercent": 0.52205,
		"totalGuests": 746,
		"guestPercent": 0.763475,
		"mostGuestDate": "2023-06-13 00:00:00",
		"mostGuestName": "Northwell Health at Jones Beach Theater",
		"mostGuestCity": "Wantagh",
		"mostGuestAmount": 75
	},
	{
		"id": "190eb77",
		"email": "gabrielgw@gmail.com",
		"name": "Gabriel ",
		"createdDate": "2015-08-11 14:20:08",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 317,
		"totalCountries": 11,
		"totalStates": 57,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 1366,
		"schedulePercent": 0.584426,
		"totalGuests": 13,
		"guestPercent": 0.352536,
		"mostGuestDate": "2023-03-07 00:00:00",
		"mostGuestName": "Great American Music Hall",
		"mostGuestCity": "San Francisco",
		"mostGuestAmount": 10
	},
	{
		"id": "8121f80",
		"email": "jordan@rtatouring.com",
		"name": "Jordan",
		"createdDate": "2016-06-14 11:47:32",
		"totalOrganizations": 18,
		"totalTours": 49,
		"totalDays": 1453,
		"totalCountries": 20,
		"totalStates": 74,
		"totalEvents": 358,
		"eventPercent": 0.945305,
		"totalScheduleItems": 5321,
		"schedulePercent": 0.970335,
		"totalGuests": 752,
		"guestPercent": 0.765064,
		"mostGuestDate": "2023-03-17 00:00:00",
		"mostGuestName": "The Enmore Theatre",
		"mostGuestCity": "Newtown",
		"mostGuestAmount": 111
	},
	{
		"id": "dd48ac5",
		"email": "stylesmusic@me.com",
		"name": "Steven",
		"createdDate": "2012-06-18 12:42:19",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 104,
		"totalCountries": 7,
		"totalStates": 29,
		"totalEvents": 60,
		"eventPercent": 0.361144,
		"totalScheduleItems": 553,
		"schedulePercent": 0.322871,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e16d730",
		"email": "rob@zerodblive.com",
		"name": "Rob",
		"createdDate": "2016-05-20 12:31:06",
		"totalOrganizations": 6,
		"totalTours": 11,
		"totalDays": 466,
		"totalCountries": 7,
		"totalStates": 14,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 1126,
		"schedulePercent": 0.518607,
		"totalGuests": 353,
		"guestPercent": 0.631307,
		"mostGuestDate": "2023-08-11 00:00:00",
		"mostGuestName": "South Facing Festival",
		"mostGuestCity": "London",
		"mostGuestAmount": 68
	},
	{
		"id": "0ab9bbc",
		"email": "Carl.Ryan@umusic.com",
		"name": "Carl",
		"createdDate": "2013-06-23 19:06:15",
		"totalOrganizations": 28,
		"totalTours": 53,
		"totalDays": 2990,
		"totalCountries": 36,
		"totalStates": 121,
		"totalEvents": 692,
		"eventPercent": 0.985962,
		"totalScheduleItems": 12600,
		"schedulePercent": 0.998543,
		"totalGuests": 3239,
		"guestPercent": 0.973778,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 178
	},
	{
		"id": "bdff574",
		"email": "motionrob@me.com",
		"name": "Rob",
		"createdDate": "2015-10-16 00:53:27",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 798,
		"totalCountries": 16,
		"totalStates": 27,
		"totalEvents": 331,
		"eventPercent": 0.932327,
		"totalScheduleItems": 3899,
		"schedulePercent": 0.918289,
		"totalGuests": 6793,
		"guestPercent": 0.996689,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Wembley Stadium",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 625
	},
	{
		"id": "b7b4223",
		"email": "keaton@m99studios.com",
		"name": "Keaton ",
		"createdDate": "2019-03-12 22:05:26",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 280,
		"totalCountries": 9,
		"totalStates": 46,
		"totalEvents": 102,
		"eventPercent": 0.551847,
		"totalScheduleItems": 1841,
		"schedulePercent": 0.693815,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-03-09 00:00:00",
		"mostGuestName": "Smoothie King Center",
		"mostGuestCity": "New Orleans",
		"mostGuestAmount": 2
	},
	{
		"id": "8503fe8",
		"email": "josea@vstarentertainment.com",
		"name": "Jose",
		"createdDate": "2023-01-24 20:51:49",
		"totalOrganizations": 1,
		"totalTours": 8,
		"totalDays": 317,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 175,
		"eventPercent": 0.761753,
		"totalScheduleItems": 1246,
		"schedulePercent": 0.551185,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ce1625c",
		"email": "richard@phish.com",
		"name": "Richard",
		"createdDate": "2011-08-15 22:42:54",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 99,
		"totalCountries": 2,
		"totalStates": 11,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 864,
		"schedulePercent": 0.440869,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "44d08a2",
		"email": "brandon@prodigyartists.com",
		"name": "Brandon",
		"createdDate": "2022-05-26 00:47:02",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 354,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 31,
		"schedulePercent": 0.0405244,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "426809b",
		"email": "Cho.management1@gmail.com",
		"name": "Chigo",
		"createdDate": "2019-05-22 16:05:57",
		"totalOrganizations": 17,
		"totalTours": 19,
		"totalDays": 1507,
		"totalCountries": 18,
		"totalStates": 54,
		"totalEvents": 231,
		"eventPercent": 0.850616,
		"totalScheduleItems": 1857,
		"schedulePercent": 0.696729,
		"totalGuests": 13,
		"guestPercent": 0.352536,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Hatfield Park",
		"mostGuestCity": "",
		"mostGuestAmount": 13
	},
	{
		"id": "3167b30",
		"email": "amanda.fotes@gmail.com",
		"name": "Amanda",
		"createdDate": "2019-04-24 02:17:16",
		"totalOrganizations": 5,
		"totalTours": 14,
		"totalDays": 388,
		"totalCountries": 5,
		"totalStates": 50,
		"totalEvents": 188,
		"eventPercent": 0.785856,
		"totalScheduleItems": 2084,
		"schedulePercent": 0.732618,
		"totalGuests": 852,
		"guestPercent": 0.787975,
		"mostGuestDate": "2023-03-10 00:00:00",
		"mostGuestName": "Massey Hall",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 46
	},
	{
		"id": "1635bb0",
		"email": "kitblanchard@gmail.com",
		"name": "Kit",
		"createdDate": "2019-08-23 08:24:41",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 439,
		"totalCountries": 7,
		"totalStates": 28,
		"totalEvents": 88,
		"eventPercent": 0.491723,
		"totalScheduleItems": 1648,
		"schedulePercent": 0.653159,
		"totalGuests": 60,
		"guestPercent": 0.434644,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "WinStar World Casino- Paladium Sports Lounge",
		"mostGuestCity": "Thackerville",
		"mostGuestAmount": 58
	},
	{
		"id": "2348e86",
		"email": "kr.hofmann@icloud.com",
		"name": "Janine",
		"createdDate": "2021-06-06 18:43:52",
		"totalOrganizations": 1,
		"totalTours": 7,
		"totalDays": 219,
		"totalCountries": 6,
		"totalStates": 22,
		"totalEvents": 86,
		"eventPercent": 0.483115,
		"totalScheduleItems": 473,
		"schedulePercent": 0.286187,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c5ae9c4",
		"email": "laura.sanchez.j@gmail.com",
		"name": "Laura",
		"createdDate": "2015-03-17 20:39:04",
		"totalOrganizations": 8,
		"totalTours": 14,
		"totalDays": 1260,
		"totalCountries": 9,
		"totalStates": 53,
		"totalEvents": 382,
		"eventPercent": 0.952457,
		"totalScheduleItems": 4741,
		"schedulePercent": 0.953251,
		"totalGuests": 1583,
		"guestPercent": 0.901735,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 281
	},
	{
		"id": "5f8928e",
		"email": "ben@kirschner-vs.de",
		"name": "Benjamin",
		"createdDate": "2020-02-11 20:06:12",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 143,
		"totalCountries": 6,
		"totalStates": 30,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 2155,
		"schedulePercent": 0.743213,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "6c2550d",
		"email": "ben@beyondgt.com",
		"name": "Benjamin",
		"createdDate": "2018-07-15 14:57:36",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 143,
		"totalCountries": 11,
		"totalStates": 17,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 793,
		"schedulePercent": 0.417958,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "O2 Apollo Manchester",
		"mostGuestCity": "Manchester",
		"mostGuestAmount": 2
	},
	{
		"id": "05c965e",
		"email": "marly.messina@gmail.com",
		"name": "Marly",
		"createdDate": "2022-08-08 17:51:47",
		"totalOrganizations": 2,
		"totalTours": 6,
		"totalDays": 357,
		"totalCountries": 15,
		"totalStates": 43,
		"totalEvents": 106,
		"eventPercent": 0.565488,
		"totalScheduleItems": 2595,
		"schedulePercent": 0.807045,
		"totalGuests": 5153,
		"guestPercent": 0.992584,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Glen Helen Amphitheater",
		"mostGuestCity": "San Bernardino",
		"mostGuestAmount": 250
	},
	{
		"id": "1c26537",
		"email": "shana@seriousfanmusic.es",
		"name": "Shana",
		"createdDate": "2019-12-02 16:47:28",
		"totalOrganizations": 4,
		"totalTours": 138,
		"totalDays": 573,
		"totalCountries": 22,
		"totalStates": 94,
		"totalEvents": 340,
		"eventPercent": 0.937492,
		"totalScheduleItems": 2146,
		"schedulePercent": 0.741624,
		"totalGuests": 1247,
		"guestPercent": 0.864654,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Teatro PavÃ³n",
		"mostGuestCity": "Madrid",
		"mostGuestAmount": 55
	},
	{
		"id": "cd2ae8a",
		"email": "suzanne@theglowmgmt.com",
		"name": "Suzanne ",
		"createdDate": "2022-02-16 18:34:11",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 75,
		"totalCountries": 5,
		"totalStates": 22,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 425,
		"schedulePercent": 0.265793,
		"totalGuests": 58,
		"guestPercent": 0.431863,
		"mostGuestDate": "2023-11-13 00:00:00",
		"mostGuestName": "100 Club",
		"mostGuestCity": "London",
		"mostGuestAmount": 29
	},
	{
		"id": "db08463",
		"email": "Sarah@qprime.com",
		"name": "Sarah",
		"createdDate": "2019-05-03 17:01:34",
		"totalOrganizations": 10,
		"totalTours": 9,
		"totalDays": 1842,
		"totalCountries": 14,
		"totalStates": 61,
		"totalEvents": 512,
		"eventPercent": 0.975632,
		"totalScheduleItems": 7219,
		"schedulePercent": 0.990465,
		"totalGuests": 5366,
		"guestPercent": 0.993511,
		"mostGuestDate": "2023-09-24 00:00:00",
		"mostGuestName": "PNC Music Pavilion",
		"mostGuestCity": "Charlotte",
		"mostGuestAmount": 141
	},
	{
		"id": "b612f34",
		"email": "mitch@madstoneproductions.com",
		"name": "Mitchell",
		"createdDate": "2011-01-25 22:30:57",
		"totalOrganizations": 6,
		"totalTours": 11,
		"totalDays": 481,
		"totalCountries": 16,
		"totalStates": 61,
		"totalEvents": 190,
		"eventPercent": 0.788505,
		"totalScheduleItems": 2676,
		"schedulePercent": 0.81711,
		"totalGuests": 892,
		"guestPercent": 0.797643,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "The Midland Theatre",
		"mostGuestCity": "Kansas City",
		"mostGuestAmount": 106
	},
	{
		"id": "8fe5187",
		"email": "papayanson@gmail.com",
		"name": "Yann",
		"createdDate": "2017-10-15 15:44:11",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 192,
		"totalCountries": 8,
		"totalStates": 14,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 342,
		"schedulePercent": 0.228976,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-05-25 00:00:00",
		"mostGuestName": "La Cigale",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 1
	},
	{
		"id": "1ab8126",
		"email": "sophie@nocturaproduction.com",
		"name": "Sophie",
		"createdDate": "2021-12-01 22:37:06",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 197,
		"totalCountries": 6,
		"totalStates": 19,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 544,
		"schedulePercent": 0.319031,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "825e81a",
		"email": "christopherjayphill@gmail.com",
		"name": "Chris",
		"createdDate": "2015-11-03 21:02:44",
		"totalOrganizations": 23,
		"totalTours": 27,
		"totalDays": 863,
		"totalCountries": 25,
		"totalStates": 84,
		"totalEvents": 385,
		"eventPercent": 0.953649,
		"totalScheduleItems": 6237,
		"schedulePercent": 0.982519,
		"totalGuests": 2898,
		"guestPercent": 0.968084,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "The Salt Shed",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 95
	},
	{
		"id": "5cafbef",
		"email": "will@willseefar.com",
		"name": "Will",
		"createdDate": "2015-07-23 14:11:13",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 753,
		"totalCountries": 5,
		"totalStates": 54,
		"totalEvents": 412,
		"eventPercent": 0.960535,
		"totalScheduleItems": 5151,
		"schedulePercent": 0.966362,
		"totalGuests": 1128,
		"guestPercent": 0.844259,
		"mostGuestDate": "2023-12-06 00:00:00",
		"mostGuestName": "Terrace Theatre",
		"mostGuestCity": "Long Beach",
		"mostGuestAmount": 73
	},
	{
		"id": "f07400c",
		"email": "aisling.charlesworth@frontiertouring.com",
		"name": "Aisling",
		"createdDate": "2016-07-11 06:51:01",
		"totalOrganizations": 15,
		"totalTours": 20,
		"totalDays": 406,
		"totalCountries": 7,
		"totalStates": 28,
		"totalEvents": 165,
		"eventPercent": 0.740034,
		"totalScheduleItems": 2843,
		"schedulePercent": 0.837505,
		"totalGuests": 1294,
		"guestPercent": 0.871143,
		"mostGuestDate": "2023-04-20 00:00:00",
		"mostGuestName": "KFC Yum! Center",
		"mostGuestCity": "Louisville",
		"mostGuestAmount": 154
	},
	{
		"id": "84e9244",
		"email": "apreswho@gmail.com",
		"name": "Rob",
		"createdDate": "2017-03-16 20:38:28",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 45,
		"totalCountries": 5,
		"totalStates": 23,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 261,
		"schedulePercent": 0.185936,
		"totalGuests": 234,
		"guestPercent": 0.570256,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Irving Plaza",
		"mostGuestCity": "New York",
		"mostGuestAmount": 49
	},
	{
		"id": "94b9e20",
		"email": "cera.martina@gmail.com",
		"name": "MARTINA",
		"createdDate": "2022-09-15 13:11:13",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 240,
		"totalCountries": 1,
		"totalStates": 39,
		"totalEvents": 111,
		"eventPercent": 0.581777,
		"totalScheduleItems": 729,
		"schedulePercent": 0.391869,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-07-07 00:00:00",
		"mostGuestName": "PARCO DI  SAN GIULIANO MESTRE",
		"mostGuestCity": "VENEZIA",
		"mostGuestAmount": 17
	},
	{
		"id": "8738171",
		"email": "maxine.drury@icloud.com",
		"name": "Maxine",
		"createdDate": "2015-08-27 18:24:24",
		"totalOrganizations": 7,
		"totalTours": 10,
		"totalDays": 212,
		"totalCountries": 10,
		"totalStates": 18,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 859,
		"schedulePercent": 0.439942,
		"totalGuests": 158,
		"guestPercent": 0.520858,
		"mostGuestDate": "2023-04-25 00:00:00",
		"mostGuestName": "La Cigale",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 30
	},
	{
		"id": "373415e",
		"email": "Eric.Silver@redlightmanagement.com",
		"name": "ERIC",
		"createdDate": "2021-07-09 19:16:07",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 521,
		"totalCountries": 3,
		"totalStates": 25,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 809,
		"schedulePercent": 0.422858,
		"totalGuests": 3,
		"guestPercent": 0.308436,
		"mostGuestDate": "2023-06-09 00:00:00",
		"mostGuestName": "Red Hat Amphitheater",
		"mostGuestCity": "Raleigh",
		"mostGuestAmount": 3
	},
	{
		"id": "7649bb9",
		"email": "marta@centraleuro.org",
		"name": "Marta",
		"createdDate": "2023-03-28 16:03:08",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 21,
		"totalCountries": 1,
		"totalStates": 2,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 171,
		"schedulePercent": 0.13336,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "787281e",
		"email": "hughesman@ozemail.com.au",
		"name": "David",
		"createdDate": "2014-10-09 20:41:55",
		"totalOrganizations": 26,
		"totalTours": 4,
		"totalDays": 143,
		"totalCountries": 4,
		"totalStates": 13,
		"totalEvents": 56,
		"eventPercent": 0.342471,
		"totalScheduleItems": 1058,
		"schedulePercent": 0.500463,
		"totalGuests": 200,
		"guestPercent": 0.550258,
		"mostGuestDate": "2023-04-05 00:00:00",
		"mostGuestName": "Forsyth Barr Stadium",
		"mostGuestCity": "Dunedin",
		"mostGuestAmount": 32
	},
	{
		"id": "ba44830",
		"email": "bgimenez808@gmail.com",
		"name": "Brandon",
		"createdDate": "2013-08-01 02:49:31",
		"totalOrganizations": 4,
		"totalTours": 10,
		"totalDays": 240,
		"totalCountries": 5,
		"totalStates": 30,
		"totalEvents": 100,
		"eventPercent": 0.545226,
		"totalScheduleItems": 1208,
		"schedulePercent": 0.542047,
		"totalGuests": 856,
		"guestPercent": 0.788902,
		"mostGuestDate": "2023-02-25 00:00:00",
		"mostGuestName": "Showbox SoDo",
		"mostGuestCity": "Seattle",
		"mostGuestAmount": 51
	},
	{
		"id": "273d08f",
		"email": "Thiswildlifeband@gmail.com",
		"name": "Anthony",
		"createdDate": "2014-11-06 20:15:47",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 95,
		"totalCountries": 1,
		"totalStates": 27,
		"totalEvents": 65,
		"eventPercent": 0.384452,
		"totalScheduleItems": 1107,
		"schedulePercent": 0.513309,
		"totalGuests": 819,
		"guestPercent": 0.780559,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 91
	},
	{
		"id": "574016d",
		"email": "world2er@aol.com",
		"name": "Jimmy",
		"createdDate": "2010-11-01 17:05:04",
		"totalOrganizations": 7,
		"totalTours": 1,
		"totalDays": 97,
		"totalCountries": 2,
		"totalStates": 3,
		"totalEvents": 19,
		"eventPercent": 0.128327,
		"totalScheduleItems": 272,
		"schedulePercent": 0.193749,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5db4262",
		"email": "kemp.sophie@ymail.com",
		"name": "Sophie",
		"createdDate": "2017-10-06 21:10:01",
		"totalOrganizations": 19,
		"totalTours": 4,
		"totalDays": 103,
		"totalCountries": 4,
		"totalStates": 10,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 390,
		"schedulePercent": 0.252947,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "309abda",
		"email": "tourmanagernate@gmail.com",
		"name": "Nate",
		"createdDate": "2018-08-29 05:19:40",
		"totalOrganizations": 7,
		"totalTours": 9,
		"totalDays": 1372,
		"totalCountries": 6,
		"totalStates": 51,
		"totalEvents": 295,
		"eventPercent": 0.912594,
		"totalScheduleItems": 4745,
		"schedulePercent": 0.953649,
		"totalGuests": 905,
		"guestPercent": 0.800821,
		"mostGuestDate": "2023-02-24 00:00:00",
		"mostGuestName": "Iron City Bham",
		"mostGuestCity": "Birmingham",
		"mostGuestAmount": 49
	},
	{
		"id": "327979b",
		"email": "kurt@remotecontroltouring.com",
		"name": "Kurt",
		"createdDate": "2014-03-18 00:33:43",
		"totalOrganizations": 51,
		"totalTours": 14,
		"totalDays": 623,
		"totalCountries": 17,
		"totalStates": 57,
		"totalEvents": 160,
		"eventPercent": 0.72838,
		"totalScheduleItems": 2042,
		"schedulePercent": 0.726129,
		"totalGuests": 240,
		"guestPercent": 0.573434,
		"mostGuestDate": "2023-06-18 00:00:00",
		"mostGuestName": "Koko",
		"mostGuestCity": "London",
		"mostGuestAmount": 40
	},
	{
		"id": "14cec2f",
		"email": "RebeccaS@3cgrecords.com",
		"name": "Rebecca",
		"createdDate": "2012-07-16 17:39:02",
		"totalOrganizations": 6,
		"totalTours": 20,
		"totalDays": 774,
		"totalCountries": 5,
		"totalStates": 48,
		"totalEvents": 310,
		"eventPercent": 0.920938,
		"totalScheduleItems": 2661,
		"schedulePercent": 0.815124,
		"totalGuests": 245,
		"guestPercent": 0.57595,
		"mostGuestDate": "2023-11-19 00:00:00",
		"mostGuestName": "Racket NYC",
		"mostGuestCity": "New York",
		"mostGuestAmount": 88
	},
	{
		"id": "46611b0",
		"email": "jjweekstm@gmail.com",
		"name": "JJ",
		"createdDate": "2019-04-22 16:28:15",
		"totalOrganizations": 6,
		"totalTours": 6,
		"totalDays": 417,
		"totalCountries": 4,
		"totalStates": 44,
		"totalEvents": 193,
		"eventPercent": 0.792743,
		"totalScheduleItems": 3599,
		"schedulePercent": 0.900411,
		"totalGuests": 930,
		"guestPercent": 0.806913,
		"mostGuestDate": "2023-03-03 00:00:00",
		"mostGuestName": "Macon Centreplex - City Auditorium",
		"mostGuestCity": "Macon",
		"mostGuestAmount": 60
	},
	{
		"id": "624deb6",
		"email": "mattchequer@me.com",
		"name": "Matthew",
		"createdDate": "2012-02-10 17:05:25",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 358,
		"totalCountries": 10,
		"totalStates": 27,
		"totalEvents": 53,
		"eventPercent": 0.327904,
		"totalScheduleItems": 1054,
		"schedulePercent": 0.498609,
		"totalGuests": 205,
		"guestPercent": 0.554231,
		"mostGuestDate": "2023-09-10 00:00:00",
		"mostGuestName": "Pier 17",
		"mostGuestCity": "New York",
		"mostGuestAmount": 77
	},
	{
		"id": "e12fe69",
		"email": "aggih@xnet.is",
		"name": "Agnar",
		"createdDate": "2013-06-16 17:44:28",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 66,
		"totalCountries": 8,
		"totalStates": 11,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 490,
		"schedulePercent": 0.292676,
		"totalGuests": 94,
		"guestPercent": 0.469474,
		"mostGuestDate": "2023-03-06 00:00:00",
		"mostGuestName": "Pumpehuset",
		"mostGuestCity": "Copenhagen",
		"mostGuestAmount": 24
	},
	{
		"id": "40254bd",
		"email": "brndgrlck97@gmail.com",
		"name": "Brandon",
		"createdDate": "2019-09-27 21:37:36",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 110,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 711,
		"schedulePercent": 0.384982,
		"totalGuests": 715,
		"guestPercent": 0.756191,
		"mostGuestDate": "2023-06-07 00:00:00",
		"mostGuestName": "Hammerstein Ballroom",
		"mostGuestCity": "New York",
		"mostGuestAmount": 59
	},
	{
		"id": "a708bcb",
		"email": "cheneybrannon73@gmail.com",
		"name": "CHENEY",
		"createdDate": "2020-12-12 18:11:05",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 395,
		"totalCountries": 4,
		"totalStates": 40,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 826,
		"schedulePercent": 0.42842,
		"totalGuests": 766,
		"guestPercent": 0.768243,
		"mostGuestDate": "2023-05-13 00:00:00",
		"mostGuestName": "Humphreys Concerts By The Bay",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 42
	},
	{
		"id": "be8de3c",
		"email": "nshefts@me.com",
		"name": "Nancy",
		"createdDate": "2017-10-06 15:34:50",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 449,
		"totalCountries": 8,
		"totalStates": 41,
		"totalEvents": 196,
		"eventPercent": 0.798835,
		"totalScheduleItems": 3037,
		"schedulePercent": 0.854589,
		"totalGuests": 385,
		"guestPercent": 0.646934,
		"mostGuestDate": "2023-10-05 00:00:00",
		"mostGuestName": "SoFi Stadium",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 40
	},
	{
		"id": "95cfa46",
		"email": "toursupport2@aol.com",
		"name": "Martin",
		"createdDate": "2010-01-07 21:38:43",
		"totalOrganizations": 23,
		"totalTours": 13,
		"totalDays": 448,
		"totalCountries": 15,
		"totalStates": 62,
		"totalEvents": 194,
		"eventPercent": 0.794597,
		"totalScheduleItems": 3039,
		"schedulePercent": 0.854721,
		"totalGuests": 483,
		"guestPercent": 0.684545,
		"mostGuestDate": "2023-03-27 00:00:00",
		"mostGuestName": "Royal Albert Hall",
		"mostGuestCity": "London",
		"mostGuestAmount": 27
	},
	{
		"id": "b11cfd6",
		"email": "mariamargaritachon@gmail.com",
		"name": "Maria",
		"createdDate": "2017-06-19 18:39:26",
		"totalOrganizations": 2,
		"totalTours": 4,
		"totalDays": 338,
		"totalCountries": 5,
		"totalStates": 10,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 967,
		"schedulePercent": 0.470269,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b04028b",
		"email": "guido@travtours.com",
		"name": "Guido",
		"createdDate": "2022-11-11 00:01:47",
		"totalOrganizations": 13,
		"totalTours": 11,
		"totalDays": 197,
		"totalCountries": 9,
		"totalStates": 23,
		"totalEvents": 90,
		"eventPercent": 0.500331,
		"totalScheduleItems": 1775,
		"schedulePercent": 0.679778,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ade30d7",
		"email": "piet.maesschalck@lobbycall.com",
		"name": "Piet",
		"createdDate": "2020-03-06 14:00:42",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 260,
		"totalCountries": 14,
		"totalStates": 36,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 871,
		"schedulePercent": 0.443517,
		"totalGuests": 217,
		"guestPercent": 0.560853,
		"mostGuestDate": "2023-03-05 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 26
	},
	{
		"id": "695c527",
		"email": "nadia.bobaly@gmail.com",
		"name": "Nadia",
		"createdDate": "2021-07-15 03:04:11",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 257,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 85,
		"eventPercent": 0.478612,
		"totalScheduleItems": 511,
		"schedulePercent": 0.304595,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ec9144b",
		"email": "helga@made.no",
		"name": "Helga",
		"createdDate": "2016-03-29 13:03:54",
		"totalOrganizations": 12,
		"totalTours": 6,
		"totalDays": 381,
		"totalCountries": 15,
		"totalStates": 30,
		"totalEvents": 94,
		"eventPercent": 0.516223,
		"totalScheduleItems": 1327,
		"schedulePercent": 0.573831,
		"totalGuests": 226,
		"guestPercent": 0.566945,
		"mostGuestDate": "2023-04-18 00:00:00",
		"mostGuestName": "Pepsi center",
		"mostGuestCity": "Ciudad de MÃ©xico",
		"mostGuestAmount": 57
	},
	{
		"id": "c2a7db1",
		"email": "jason@ozarkmusicgroup.com",
		"name": "Jason",
		"createdDate": "2023-04-13 09:47:11",
		"totalOrganizations": 6,
		"totalTours": 12,
		"totalDays": 661,
		"totalCountries": 7,
		"totalStates": 50,
		"totalEvents": 293,
		"eventPercent": 0.911005,
		"totalScheduleItems": 4644,
		"schedulePercent": 0.949676,
		"totalGuests": 949,
		"guestPercent": 0.812475,
		"mostGuestDate": "2023-07-30 00:00:00",
		"mostGuestName": "TCU Amphitheater at White River State Park",
		"mostGuestCity": "Indianapolis",
		"mostGuestAmount": 48
	},
	{
		"id": "71be9d9",
		"email": "brittanyjmoll@gmail.com",
		"name": "Brittany",
		"createdDate": "2022-05-31 23:27:08",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 340,
		"totalCountries": 16,
		"totalStates": 55,
		"totalEvents": 238,
		"eventPercent": 0.860019,
		"totalScheduleItems": 2606,
		"schedulePercent": 0.808502,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "03a6bb3",
		"email": "info@lomaxaudio.co.uk",
		"name": "Finn",
		"createdDate": "2022-01-07 13:16:13",
		"totalOrganizations": 23,
		"totalTours": 44,
		"totalDays": 1697,
		"totalCountries": 20,
		"totalStates": 54,
		"totalEvents": 328,
		"eventPercent": 0.929943,
		"totalScheduleItems": 3570,
		"schedulePercent": 0.897894,
		"totalGuests": 367,
		"guestPercent": 0.64018,
		"mostGuestDate": "2023-09-17 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 58
	},
	{
		"id": "8f192c5",
		"email": "heather@transparentproductions.com",
		"name": "Heather ",
		"createdDate": "2023-01-20 22:45:01",
		"totalOrganizations": 11,
		"totalTours": 15,
		"totalDays": 597,
		"totalCountries": 2,
		"totalStates": 38,
		"totalEvents": 188,
		"eventPercent": 0.785856,
		"totalScheduleItems": 3259,
		"schedulePercent": 0.874454,
		"totalGuests": 1491,
		"guestPercent": 0.893127,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 293
	},
	{
		"id": "c15ed82",
		"email": "PTPTourCM@networkstours.com",
		"name": "Luke",
		"createdDate": "2023-09-20 00:30:09",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 64,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 0,
		"eventPercent": 0,
		"totalScheduleItems": 345,
		"schedulePercent": 0.23083,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "b21fab2",
		"email": "kb@scooterbraun.com",
		"name": "Karly",
		"createdDate": "2022-06-18 23:07:44",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 116,
		"totalCountries": 2,
		"totalStates": 6,
		"totalEvents": 8,
		"eventPercent": 0.0535028,
		"totalScheduleItems": 142,
		"schedulePercent": 0.114422,
		"totalGuests": 36,
		"guestPercent": 0.397961,
		"mostGuestDate": "2023-06-16 00:00:00",
		"mostGuestName": "North to Shore Festival",
		"mostGuestCity": "Asbury Park",
		"mostGuestAmount": 36
	},
	{
		"id": "db82965",
		"email": "hello@laurenfarrah.com",
		"name": "Lauren",
		"createdDate": "2019-01-02 02:42:31",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 509,
		"totalCountries": 13,
		"totalStates": 44,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 1329,
		"schedulePercent": 0.574758,
		"totalGuests": 126,
		"guestPercent": 0.495564,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Luther F. Carson Four Rivers Center",
		"mostGuestCity": "Paducah",
		"mostGuestAmount": 19
	},
	{
		"id": "7d665ea",
		"email": "max@goldtheoryartists.com",
		"name": "Max",
		"createdDate": "2023-05-22 10:13:56",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 101,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 433,
		"schedulePercent": 0.269766,
		"totalGuests": 10,
		"guestPercent": 0.343531,
		"mostGuestDate": "2023-12-03 00:00:00",
		"mostGuestName": "Knockdown Center",
		"mostGuestCity": "Maspeth",
		"mostGuestAmount": 6
	},
	{
		"id": "72e30b1",
		"email": "iz@u-l.studio",
		"name": "Isaac",
		"createdDate": "2023-08-01 15:55:01",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 59,
		"totalCountries": 4,
		"totalStates": 8,
		"totalEvents": 13,
		"eventPercent": 0.0892597,
		"totalScheduleItems": 139,
		"schedulePercent": 0.113362,
		"totalGuests": 87,
		"guestPercent": 0.460734,
		"mostGuestDate": "2023-09-19 00:00:00",
		"mostGuestName": "Baby's All Right",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 27
	},
	{
		"id": "866ed15",
		"email": "alejandra@aultouring.com",
		"name": "Alejandra",
		"createdDate": "2023-09-06 17:08:10",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 59,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 592,
		"schedulePercent": 0.336909,
		"totalGuests": 402,
		"guestPercent": 0.654615,
		"mostGuestDate": "2023-09-30 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 185
	},
	{
		"id": "42d0003",
		"email": "brettdoig@me.com",
		"name": "Brett",
		"createdDate": "2012-10-02 06:46:31",
		"totalOrganizations": 16,
		"totalTours": 14,
		"totalDays": 525,
		"totalCountries": 12,
		"totalStates": 44,
		"totalEvents": 295,
		"eventPercent": 0.912594,
		"totalScheduleItems": 2354,
		"schedulePercent": 0.774467,
		"totalGuests": 736,
		"guestPercent": 0.760959,
		"mostGuestDate": "2023-10-13 00:00:00",
		"mostGuestName": "Historic Scoot Inn",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 44
	},
	{
		"id": "58fa295",
		"email": "grantdraper@gmail.com",
		"name": "Grant",
		"createdDate": "2015-09-23 00:21:41",
		"totalOrganizations": 6,
		"totalTours": 9,
		"totalDays": 285,
		"totalCountries": 7,
		"totalStates": 16,
		"totalEvents": 93,
		"eventPercent": 0.51172,
		"totalScheduleItems": 1561,
		"schedulePercent": 0.632764,
		"totalGuests": 161,
		"guestPercent": 0.523374,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "SoFi Stadium",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 29
	},
	{
		"id": "384b0c5",
		"email": "steve@patchworklondon.co.uk",
		"name": "Steve",
		"createdDate": "2014-09-11 10:23:24",
		"totalOrganizations": 59,
		"totalTours": 7,
		"totalDays": 537,
		"totalCountries": 8,
		"totalStates": 16,
		"totalEvents": 83,
		"eventPercent": 0.469739,
		"totalScheduleItems": 1356,
		"schedulePercent": 0.582175,
		"totalGuests": 174,
		"guestPercent": 0.532115,
		"mostGuestDate": "2023-02-03 00:00:00",
		"mostGuestName": "PRYZM Kingston",
		"mostGuestCity": "Kingston upon Thames",
		"mostGuestAmount": 26
	},
	{
		"id": "d457b83",
		"email": "rjohara7@gmail.com",
		"name": "Ryan",
		"createdDate": "2021-07-19 19:43:54",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 78,
		"totalCountries": 2,
		"totalStates": 29,
		"totalEvents": 44,
		"eventPercent": 0.281949,
		"totalScheduleItems": 551,
		"schedulePercent": 0.322077,
		"totalGuests": 23,
		"guestPercent": 0.37452,
		"mostGuestDate": "2023-05-19 00:00:00",
		"mostGuestName": "Meritage Resort And Spa",
		"mostGuestCity": "Napa",
		"mostGuestAmount": 22
	},
	{
		"id": "370ec8c",
		"email": "nicolai@ffp-fx.net",
		"name": "Nicolai",
		"createdDate": "2018-07-27 18:11:49",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 285,
		"totalCountries": 11,
		"totalStates": 16,
		"totalEvents": 232,
		"eventPercent": 0.852073,
		"totalScheduleItems": 2840,
		"schedulePercent": 0.836975,
		"totalGuests": 6182,
		"guestPercent": 0.995365,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Olympiastadion Berlin",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 794
	},
	{
		"id": "4f19361",
		"email": "nottet.justine@gmail.com",
		"name": "Justine",
		"createdDate": "2022-09-26 17:55:11",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 111,
		"totalCountries": 7,
		"totalStates": 10,
		"totalEvents": 24,
		"eventPercent": 0.160773,
		"totalScheduleItems": 333,
		"schedulePercent": 0.225268,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-11-28 00:00:00",
		"mostGuestName": "O2 Institute",
		"mostGuestCity": "Birmingham",
		"mostGuestAmount": 4
	},
	{
		"id": "8087a6f",
		"email": "ryantilke@hotmail.com",
		"name": "Ryan",
		"createdDate": "2018-08-01 04:01:17",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 617,
		"totalCountries": 5,
		"totalStates": 38,
		"totalEvents": 146,
		"eventPercent": 0.690372,
		"totalScheduleItems": 1615,
		"schedulePercent": 0.645742,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-11-01 00:00:00",
		"mostGuestName": "Venetian Hotel",
		"mostGuestCity": "Las Vegas",
		"mostGuestAmount": 4
	},
	{
		"id": "26e4f64",
		"email": "tony@tonywigens.com",
		"name": "Tony",
		"createdDate": "2018-06-29 00:57:47",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 132,
		"totalCountries": 2,
		"totalStates": 24,
		"totalEvents": 32,
		"eventPercent": 0.21229,
		"totalScheduleItems": 298,
		"schedulePercent": 0.207257,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "770703d",
		"email": "morganfox04@gmail.com",
		"name": "Morgan",
		"createdDate": "2021-04-26 13:09:11",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 283,
		"totalCountries": 4,
		"totalStates": 41,
		"totalEvents": 159,
		"eventPercent": 0.726394,
		"totalScheduleItems": 2330,
		"schedulePercent": 0.770891,
		"totalGuests": 735,
		"guestPercent": 0.760429,
		"mostGuestDate": "2023-12-06 00:00:00",
		"mostGuestName": "Terrace Theatre",
		"mostGuestCity": "Long Beach",
		"mostGuestAmount": 73
	},
	{
		"id": "51306f5",
		"email": "troycwiggins@gmail.com",
		"name": "Troy",
		"createdDate": "2023-09-08 15:35:17",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 224,
		"totalCountries": 1,
		"totalStates": 18,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 630,
		"schedulePercent": 0.354523,
		"totalGuests": 61,
		"guestPercent": 0.435571,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "Dos Equis Pavilion",
		"mostGuestCity": "Dallas",
		"mostGuestAmount": 13
	},
	{
		"id": "82223c6",
		"email": "davidcgleeson@gmail.com",
		"name": "David",
		"createdDate": "2013-10-02 11:54:17",
		"totalOrganizations": 3,
		"totalTours": 12,
		"totalDays": 357,
		"totalCountries": 11,
		"totalStates": 43,
		"totalEvents": 162,
		"eventPercent": 0.732883,
		"totalScheduleItems": 2606,
		"schedulePercent": 0.808502,
		"totalGuests": 2164,
		"guestPercent": 0.94173,
		"mostGuestDate": "2023-08-23 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 310
	},
	{
		"id": "cc6732e",
		"email": "Karenringland@mac.com",
		"name": "Karen",
		"createdDate": "2017-02-23 20:30:06",
		"totalOrganizations": 8,
		"totalTours": 5,
		"totalDays": 670,
		"totalCountries": 15,
		"totalStates": 43,
		"totalEvents": 121,
		"eventPercent": 0.617667,
		"totalScheduleItems": 2131,
		"schedulePercent": 0.739372,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "46e9210",
		"email": "dustin.reynolds@me.com",
		"name": "Dustin",
		"createdDate": "2012-03-21 03:06:27",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 513,
		"totalCountries": 10,
		"totalStates": 45,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 2870,
		"schedulePercent": 0.839624,
		"totalGuests": 1569,
		"guestPercent": 0.900411,
		"mostGuestDate": "2023-11-15 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 142
	},
	{
		"id": "111945d",
		"email": "braxton@enhaus.io",
		"name": "Braxton",
		"createdDate": "2022-09-16 20:35:24",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 482,
		"totalCountries": 4,
		"totalStates": 35,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 1371,
		"schedulePercent": 0.585618,
		"totalGuests": 617,
		"guestPercent": 0.730764,
		"mostGuestDate": "2023-11-07 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 80
	},
	{
		"id": "9186eee",
		"email": "didier@foureverything.com",
		"name": "Didier",
		"createdDate": "2022-02-26 17:53:55",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 224,
		"totalCountries": 3,
		"totalStates": 17,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 991,
		"schedulePercent": 0.480996,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7c70671",
		"email": "khemmisdoom@gmail.com",
		"name": "Khemmis",
		"createdDate": "2018-01-30 00:21:10",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 37,
		"totalCountries": 3,
		"totalStates": 19,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 307,
		"schedulePercent": 0.21176,
		"totalGuests": 221,
		"guestPercent": 0.563634,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "Mississippi Studios and Bar Bar",
		"mostGuestCity": "Portland",
		"mostGuestAmount": 24
	},
	{
		"id": "898471e",
		"email": "mchi@cityparksfoundation.org",
		"name": "Max",
		"createdDate": "2023-05-24 17:48:55",
		"totalOrganizations": 1,
		"totalTours": 3,
		"totalDays": 208,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 89,
		"eventPercent": 0.495166,
		"totalScheduleItems": 1468,
		"schedulePercent": 0.606939,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7c4d427",
		"email": "meko@10summersrecords.com",
		"name": "Meko",
		"createdDate": "2016-06-23 00:15:58",
		"totalOrganizations": 7,
		"totalTours": 3,
		"totalDays": 144,
		"totalCountries": 3,
		"totalStates": 31,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 1009,
		"schedulePercent": 0.487882,
		"totalGuests": 1162,
		"guestPercent": 0.851543,
		"mostGuestDate": "2023-05-07 00:00:00",
		"mostGuestName": "The Novo",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 75
	},
	{
		"id": "6d54a2b",
		"email": "madison.troiano@gmail.com",
		"name": "Madison",
		"createdDate": "2020-03-07 20:42:22",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 56,
		"totalCountries": 1,
		"totalStates": 4,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 464,
		"schedulePercent": 0.282612,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "3897d8f",
		"email": "allan.amtouring@gmail.com",
		"name": "Allan",
		"createdDate": "2014-06-25 13:55:31",
		"totalOrganizations": 12,
		"totalTours": 2,
		"totalDays": 136,
		"totalCountries": 10,
		"totalStates": 35,
		"totalEvents": 63,
		"eventPercent": 0.377169,
		"totalScheduleItems": 920,
		"schedulePercent": 0.457026,
		"totalGuests": 843,
		"guestPercent": 0.786253,
		"mostGuestDate": "2023-06-09 00:00:00",
		"mostGuestName": "Dreamland Margate - Scenic Stage",
		"mostGuestCity": "Margate",
		"mostGuestAmount": 77
	},
	{
		"id": "33ad630",
		"email": "earl@inearsystems.com",
		"name": "Earl",
		"createdDate": "2011-12-24 21:36:06",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 144,
		"totalCountries": 2,
		"totalStates": 34,
		"totalEvents": 75,
		"eventPercent": 0.434909,
		"totalScheduleItems": 1600,
		"schedulePercent": 0.642829,
		"totalGuests": 2760,
		"guestPercent": 0.963184,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "MIDFLORIDA Credit Union Amphitheatre",
		"mostGuestCity": "Tampa",
		"mostGuestAmount": 104
	},
	{
		"id": "0081723",
		"email": "dalal.rajhi@lobbycall.com",
		"name": "Dalal",
		"createdDate": "2022-02-22 09:12:05",
		"totalOrganizations": 29,
		"totalTours": 47,
		"totalDays": 1003,
		"totalCountries": 30,
		"totalStates": 110,
		"totalEvents": 456,
		"eventPercent": 0.968613,
		"totalScheduleItems": 6183,
		"schedulePercent": 0.982122,
		"totalGuests": 621,
		"guestPercent": 0.731426,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "Brooklyn Steel",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 51
	},
	{
		"id": "a61498d",
		"email": "calum@patchworklondon.co.uk",
		"name": "Calum",
		"createdDate": "2018-08-02 14:37:24",
		"totalOrganizations": 36,
		"totalTours": 15,
		"totalDays": 1757,
		"totalCountries": 12,
		"totalStates": 31,
		"totalEvents": 211,
		"eventPercent": 0.825586,
		"totalScheduleItems": 3151,
		"schedulePercent": 0.86611,
		"totalGuests": 671,
		"guestPercent": 0.745067,
		"mostGuestDate": "2023-11-29 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 222
	},
	{
		"id": "8e9931e",
		"email": "tourtalkhotline@gmail.com",
		"name": "Tuyet",
		"createdDate": "2014-04-04 20:39:09",
		"totalOrganizations": 11,
		"totalTours": 15,
		"totalDays": 554,
		"totalCountries": 4,
		"totalStates": 45,
		"totalEvents": 158,
		"eventPercent": 0.723083,
		"totalScheduleItems": 2824,
		"schedulePercent": 0.835254,
		"totalGuests": 2512,
		"guestPercent": 0.954576,
		"mostGuestDate": "2023-08-23 00:00:00",
		"mostGuestName": "Red Rocks Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 310
	},
	{
		"id": "d1beca0",
		"email": "c_wiest@hotmail.com",
		"name": "Christopher",
		"createdDate": "2010-08-09 23:22:06",
		"totalOrganizations": 8,
		"totalTours": 11,
		"totalDays": 317,
		"totalCountries": 10,
		"totalStates": 54,
		"totalEvents": 185,
		"eventPercent": 0.780029,
		"totalScheduleItems": 2206,
		"schedulePercent": 0.751026,
		"totalGuests": 1374,
		"guestPercent": 0.880413,
		"mostGuestDate": "2023-03-21 00:00:00",
		"mostGuestName": "1720",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 67
	},
	{
		"id": "0083226",
		"email": "eva@fishamble.com",
		"name": "Eva",
		"createdDate": "2022-08-29 13:06:17",
		"totalOrganizations": 1,
		"totalTours": 6,
		"totalDays": 518,
		"totalCountries": 6,
		"totalStates": 33,
		"totalEvents": 134,
		"eventPercent": 0.658721,
		"totalScheduleItems": 271,
		"schedulePercent": 0.192822,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "cd070b6",
		"email": "mikeymgmt@gmail.com",
		"name": "Tenille Arts",
		"createdDate": "2015-05-07 15:21:28",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 186,
		"totalCountries": 4,
		"totalStates": 32,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 983,
		"schedulePercent": 0.476758,
		"totalGuests": 119,
		"guestPercent": 0.489604,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "The O2",
		"mostGuestCity": "London",
		"mostGuestAmount": 22
	},
	{
		"id": "dac3f7c",
		"email": "admin@tinykingdommusic.com",
		"name": "Meagan",
		"createdDate": "2022-02-03 16:12:52",
		"totalOrganizations": 3,
		"totalTours": 5,
		"totalDays": 150,
		"totalCountries": 8,
		"totalStates": 34,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 631,
		"schedulePercent": 0.355052,
		"totalGuests": 444,
		"guestPercent": 0.669845,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "Vic Theatre",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 48
	},
	{
		"id": "1b88861",
		"email": "JR@abvgrnd.com",
		"name": "J.R.",
		"createdDate": "2017-01-03 01:18:42",
		"totalOrganizations": 9,
		"totalTours": 9,
		"totalDays": 505,
		"totalCountries": 6,
		"totalStates": 33,
		"totalEvents": 95,
		"eventPercent": 0.519004,
		"totalScheduleItems": 1181,
		"schedulePercent": 0.535161,
		"totalGuests": 21,
		"guestPercent": 0.371342,
		"mostGuestDate": "2023-03-16 00:00:00",
		"mostGuestName": "Music Hall of Williamsburg",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 6
	},
	{
		"id": "4c3907b",
		"email": "chrisner1@aol.com",
		"name": "Chris",
		"createdDate": "2012-03-28 20:10:07",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 46,
		"totalCountries": 4,
		"totalStates": 9,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 54,
		"schedulePercent": 0.0573434,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-11-10 00:00:00",
		"mostGuestName": "Ford Field",
		"mostGuestCity": "Detroit",
		"mostGuestAmount": 2
	},
	{
		"id": "102437b",
		"email": "bradblackwelltm@gmail.com",
		"name": "Bradley",
		"createdDate": "2022-09-22 13:59:22",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 33,
		"totalCountries": 6,
		"totalStates": 8,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 186,
		"schedulePercent": 0.143027,
		"totalGuests": 24,
		"guestPercent": 0.375712,
		"mostGuestDate": "2023-11-02 00:00:00",
		"mostGuestName": "The Academy, Middle Abbey Street",
		"mostGuestCity": "Dublin 1",
		"mostGuestAmount": 5
	},
	{
		"id": "fcd34f2",
		"email": "julie.galetar@gmail.com",
		"name": "Julie",
		"createdDate": "2018-11-14 20:09:28",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 266,
		"totalCountries": 17,
		"totalStates": 52,
		"totalEvents": 136,
		"eventPercent": 0.663488,
		"totalScheduleItems": 882,
		"schedulePercent": 0.447358,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "269bf5d",
		"email": "simon@aisamusic.com",
		"name": "Simon",
		"createdDate": "2018-06-06 19:17:47",
		"totalOrganizations": 12,
		"totalTours": 28,
		"totalDays": 231,
		"totalCountries": 18,
		"totalStates": 49,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 1789,
		"schedulePercent": 0.682691,
		"totalGuests": 420,
		"guestPercent": 0.661237,
		"mostGuestDate": "2023-07-02 00:00:00",
		"mostGuestName": "Festival de Nimes",
		"mostGuestCity": "NÃ®mes",
		"mostGuestAmount": 66
	},
	{
		"id": "10f5a9d",
		"email": "benjaminxrichards@gmail.com",
		"name": "Ben",
		"createdDate": "2017-03-15 20:47:11",
		"totalOrganizations": 9,
		"totalTours": 20,
		"totalDays": 428,
		"totalCountries": 8,
		"totalStates": 42,
		"totalEvents": 244,
		"eventPercent": 0.865713,
		"totalScheduleItems": 3877,
		"schedulePercent": 0.916832,
		"totalGuests": 1162,
		"guestPercent": 0.851543,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Alexandra Palace",
		"mostGuestCity": "London",
		"mostGuestAmount": 187
	},
	{
		"id": "822a00c",
		"email": "info@operatorsounds.com",
		"name": "Jon",
		"createdDate": "2017-04-13 20:51:17",
		"totalOrganizations": 6,
		"totalTours": 17,
		"totalDays": 293,
		"totalCountries": 13,
		"totalStates": 43,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 985,
		"schedulePercent": 0.47795,
		"totalGuests": 145,
		"guestPercent": 0.509734,
		"mostGuestDate": "2023-10-25 00:00:00",
		"mostGuestName": "The Roundhouse",
		"mostGuestCity": "London",
		"mostGuestAmount": 122
	},
	{
		"id": "f7d5f6d",
		"email": "sands4jazz@yahoo.com",
		"name": "Stephanie",
		"createdDate": "2019-02-07 15:50:43",
		"totalOrganizations": 6,
		"totalTours": 23,
		"totalDays": 489,
		"totalCountries": 10,
		"totalStates": 39,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 1267,
		"schedulePercent": 0.556483,
		"totalGuests": 166,
		"guestPercent": 0.52748,
		"mostGuestDate": "2023-01-20 00:00:00",
		"mostGuestName": "Walt Disney Concert Hall",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 23
	},
	{
		"id": "8084cd3",
		"email": "pwflinton@gmail.com",
		"name": "PETE",
		"createdDate": "2023-05-26 15:17:44",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 137,
		"totalCountries": 12,
		"totalStates": 14,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 459,
		"schedulePercent": 0.280228,
		"totalGuests": 80,
		"guestPercent": 0.455304,
		"mostGuestDate": "2023-09-01 00:00:00",
		"mostGuestName": "Don Valley Stadium",
		"mostGuestCity": "Sheffield",
		"mostGuestAmount": 34
	},
	{
		"id": "6b3a48c",
		"email": "tmacdonald@maverick.com",
		"name": "Tori",
		"createdDate": "2022-05-02 17:43:57",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 84,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 48,
		"eventPercent": 0.301682,
		"totalScheduleItems": 30,
		"schedulePercent": 0.0401271,
		"totalGuests": 14,
		"guestPercent": 0.355052,
		"mostGuestDate": "2023-07-29 00:00:00",
		"mostGuestName": "Country Fan Fest",
		"mostGuestCity": "Grantsville",
		"mostGuestAmount": 6
	},
	{
		"id": "7ee732a",
		"email": "kosi@twohandsmedia.com",
		"name": "Sven",
		"createdDate": "2022-11-03 11:14:29",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 413,
		"totalCountries": 18,
		"totalStates": 42,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 1359,
		"schedulePercent": 0.582837,
		"totalGuests": 768,
		"guestPercent": 0.769037,
		"mostGuestDate": "2023-03-30 00:00:00",
		"mostGuestName": "Olympiahalle",
		"mostGuestCity": "MÃ¼nchen",
		"mostGuestAmount": 135
	},
	{
		"id": "4dc409f",
		"email": "tim.harnois@gmail.com",
		"name": "Tim",
		"createdDate": "2019-03-16 02:27:27",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 249,
		"totalCountries": 8,
		"totalStates": 34,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 1618,
		"schedulePercent": 0.646272,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7801045",
		"email": "angela.bowles1@gmail.com",
		"name": "Email",
		"createdDate": "2021-06-04 00:22:09",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 121,
		"totalCountries": 6,
		"totalStates": 22,
		"totalEvents": 52,
		"eventPercent": 0.322077,
		"totalScheduleItems": 376,
		"schedulePercent": 0.246457,
		"totalGuests": 80,
		"guestPercent": 0.455304,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "Jazz Cafe",
		"mostGuestCity": "London",
		"mostGuestAmount": 25
	},
	{
		"id": "2e0aaa3",
		"email": "konsta@fullsteam.fi",
		"name": "Konsta",
		"createdDate": "2016-06-24 10:46:52",
		"totalOrganizations": 102,
		"totalTours": 78,
		"totalDays": 1164,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 1160,
		"eventPercent": 0.991789,
		"totalScheduleItems": 3975,
		"schedulePercent": 0.921732,
		"totalGuests": 61,
		"guestPercent": 0.435571,
		"mostGuestDate": "2023-08-25 00:00:00",
		"mostGuestName": "On The Rocks",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 10
	},
	{
		"id": "0949dac",
		"email": "jlage@mac.com",
		"name": "Julian",
		"createdDate": "2013-01-08 17:54:00",
		"totalOrganizations": 3,
		"totalTours": 13,
		"totalDays": 111,
		"totalCountries": 8,
		"totalStates": 31,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 646,
		"schedulePercent": 0.361542,
		"totalGuests": 153,
		"guestPercent": 0.515958,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Lodge Room",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 42
	},
	{
		"id": "ac89a96",
		"email": "gothlampy@mac.com",
		"name": "Alastair",
		"createdDate": "2012-03-31 02:17:02",
		"totalOrganizations": 17,
		"totalTours": 18,
		"totalDays": 236,
		"totalCountries": 11,
		"totalStates": 44,
		"totalEvents": 114,
		"eventPercent": 0.592239,
		"totalScheduleItems": 2124,
		"schedulePercent": 0.738578,
		"totalGuests": 1013,
		"guestPercent": 0.827043,
		"mostGuestDate": "2023-04-08 00:00:00",
		"mostGuestName": "Saban Theatre",
		"mostGuestCity": "Beverly Hills",
		"mostGuestAmount": 84
	},
	{
		"id": "9c90916",
		"email": "glyn@allsorted.eu",
		"name": "Glyn ",
		"createdDate": "2011-08-30 22:29:07",
		"totalOrganizations": 13,
		"totalTours": 29,
		"totalDays": 351,
		"totalCountries": 19,
		"totalStates": 52,
		"totalEvents": 197,
		"eventPercent": 0.800821,
		"totalScheduleItems": 1940,
		"schedulePercent": 0.709707,
		"totalGuests": 33,
		"guestPercent": 0.394252,
		"mostGuestDate": "2023-10-09 00:00:00",
		"mostGuestName": "Big Night Live",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 6
	},
	{
		"id": "2b33d05",
		"email": "aaron.rochotte@gmail.com",
		"name": "Aaron",
		"createdDate": "2021-06-15 01:33:53",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 365,
		"totalCountries": 1,
		"totalStates": 37,
		"totalEvents": 140,
		"eventPercent": 0.674878,
		"totalScheduleItems": 1829,
		"schedulePercent": 0.691299,
		"totalGuests": 196,
		"guestPercent": 0.546947,
		"mostGuestDate": "2023-05-16 00:00:00",
		"mostGuestName": "Ryman Auditorium",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 64
	},
	{
		"id": "278e493",
		"email": "quintonkelley@gmail.com",
		"name": "Quinton",
		"createdDate": "2015-10-28 02:29:32",
		"totalOrganizations": 11,
		"totalTours": 11,
		"totalDays": 1825,
		"totalCountries": 7,
		"totalStates": 51,
		"totalEvents": 421,
		"eventPercent": 0.962919,
		"totalScheduleItems": 6845,
		"schedulePercent": 0.987684,
		"totalGuests": 1983,
		"guestPercent": 0.932592,
		"mostGuestDate": "2023-06-23 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 102
	},
	{
		"id": "680e68f",
		"email": "aun@thinkcommon.com",
		"name": "Anshunee ",
		"createdDate": "2016-05-03 14:46:16",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 60,
		"totalCountries": 1,
		"totalStates": 10,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 245,
		"schedulePercent": 0.179049,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fd5ce33",
		"email": "rosemkean@gmail.com",
		"name": "Rose",
		"createdDate": "2016-07-26 01:55:40",
		"totalOrganizations": 5,
		"totalTours": 9,
		"totalDays": 177,
		"totalCountries": 3,
		"totalStates": 8,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 324,
		"schedulePercent": 0.221428,
		"totalGuests": 365,
		"guestPercent": 0.638591,
		"mostGuestDate": "2023-01-27 00:00:00",
		"mostGuestName": "Christchurch Town Hall of the Performing Arts",
		"mostGuestCity": "Christchurch",
		"mostGuestAmount": 93
	},
	{
		"id": "93030ae",
		"email": "tessphilipson@gmail.com",
		"name": "Tess",
		"createdDate": "2022-02-16 20:34:18",
		"totalOrganizations": 8,
		"totalTours": 14,
		"totalDays": 674,
		"totalCountries": 4,
		"totalStates": 43,
		"totalEvents": 203,
		"eventPercent": 0.811681,
		"totalScheduleItems": 2897,
		"schedulePercent": 0.84161,
		"totalGuests": 2203,
		"guestPercent": 0.943981,
		"mostGuestDate": "2023-07-23 00:00:00",
		"mostGuestName": "The Belasco Theater",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 67
	},
	{
		"id": "9b5d59a",
		"email": "m.tholen99@gmail.com",
		"name": "Mike",
		"createdDate": "2014-06-19 18:35:22",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 119,
		"totalCountries": 1,
		"totalStates": 18,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 790,
		"schedulePercent": 0.416501,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "83a6f57",
		"email": "tatianapeavey@gmail.com",
		"name": "Tatiana",
		"createdDate": "2018-08-10 06:21:45",
		"totalOrganizations": 13,
		"totalTours": 13,
		"totalDays": 266,
		"totalCountries": 19,
		"totalStates": 37,
		"totalEvents": 72,
		"eventPercent": 0.417825,
		"totalScheduleItems": 1134,
		"schedulePercent": 0.521255,
		"totalGuests": 207,
		"guestPercent": 0.555953,
		"mostGuestDate": "2023-06-05 00:00:00",
		"mostGuestName": "Columbia Theater",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 29
	},
	{
		"id": "6f7afe6",
		"email": "corey.roze@gmail.com",
		"name": "Corinna",
		"createdDate": "2022-01-24 03:32:01",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 175,
		"totalCountries": 2,
		"totalStates": 17,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 328,
		"schedulePercent": 0.223547,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "8d71042",
		"email": "niclas@dotmusic.se",
		"name": "Niclas",
		"createdDate": "2013-01-20 13:02:35",
		"totalOrganizations": 38,
		"totalTours": 8,
		"totalDays": 179,
		"totalCountries": 6,
		"totalStates": 32,
		"totalEvents": 108,
		"eventPercent": 0.57211,
		"totalScheduleItems": 1164,
		"schedulePercent": 0.530261,
		"totalGuests": 345,
		"guestPercent": 0.62601,
		"mostGuestDate": "2023-10-01 00:00:00",
		"mostGuestName": "Goteborgs Konserthus Big Hall",
		"mostGuestCity": "Goteborg",
		"mostGuestAmount": 48
	},
	{
		"id": "d8d6eda",
		"email": "moshethaengineer@gmail.com",
		"name": "Amery",
		"createdDate": "2009-08-19 22:16:25",
		"totalOrganizations": 24,
		"totalTours": 16,
		"totalDays": 870,
		"totalCountries": 13,
		"totalStates": 50,
		"totalEvents": 164,
		"eventPercent": 0.736591,
		"totalScheduleItems": 2488,
		"schedulePercent": 0.791948,
		"totalGuests": 300,
		"guestPercent": 0.602569,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "Kansas City Music Hall",
		"mostGuestCity": "Kansas City",
		"mostGuestAmount": 30
	},
	{
		"id": "d318ef5",
		"email": "Adrien@highwayholidays.eu",
		"name": "Adrien",
		"createdDate": "2018-01-20 13:29:42",
		"totalOrganizations": 7,
		"totalTours": 19,
		"totalDays": 410,
		"totalCountries": 17,
		"totalStates": 65,
		"totalEvents": 233,
		"eventPercent": 0.853662,
		"totalScheduleItems": 2826,
		"schedulePercent": 0.835386,
		"totalGuests": 1746,
		"guestPercent": 0.916435,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Annexet",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 155
	},
	{
		"id": "623659b",
		"email": "joe@pyrprod.fr",
		"name": "Joe",
		"createdDate": "2022-08-22 14:47:13",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 166,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 61,
		"eventPercent": 0.365912,
		"totalScheduleItems": 924,
		"schedulePercent": 0.457953,
		"totalGuests": 938,
		"guestPercent": 0.808767,
		"mostGuestDate": "2023-07-06 00:00:00",
		"mostGuestName": "Cabaret Sauvage",
		"mostGuestCity": "Paris",
		"mostGuestAmount": 47
	},
	{
		"id": "d51e911",
		"email": "seanmur615@gmail.com",
		"name": "Sean",
		"createdDate": "2016-07-25 21:05:41",
		"totalOrganizations": 27,
		"totalTours": 17,
		"totalDays": 2852,
		"totalCountries": 16,
		"totalStates": 71,
		"totalEvents": 463,
		"eventPercent": 0.96954,
		"totalScheduleItems": 6169,
		"schedulePercent": 0.981724,
		"totalGuests": 1693,
		"guestPercent": 0.912329,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Saban Theatre",
		"mostGuestCity": "Beverly Hills",
		"mostGuestAmount": 118
	},
	{
		"id": "0198433",
		"email": "aubkazzmusic@gmail.com",
		"name": "Aubrey",
		"createdDate": "2019-02-10 20:18:38",
		"totalOrganizations": 2,
		"totalTours": 1,
		"totalDays": 55,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 242,
		"schedulePercent": 0.177195,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "c95ea6b",
		"email": "luke@7agency.com.au",
		"name": "Luke",
		"createdDate": "2021-03-15 06:28:52",
		"totalOrganizations": 22,
		"totalTours": 27,
		"totalDays": 346,
		"totalCountries": 11,
		"totalStates": 24,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 2574,
		"schedulePercent": 0.802145,
		"totalGuests": 438,
		"guestPercent": 0.667461,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "Hordern Pavilion",
		"mostGuestCity": "Moore Park",
		"mostGuestAmount": 31
	},
	{
		"id": "3405c01",
		"email": "ianmccarthylx@gmail.com",
		"name": "Ian",
		"createdDate": "2015-11-04 00:15:23",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 420,
		"totalCountries": 18,
		"totalStates": 62,
		"totalEvents": 177,
		"eventPercent": 0.765594,
		"totalScheduleItems": 4636,
		"schedulePercent": 0.949146,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "21b1766",
		"email": "nate@giftedmgmt.com",
		"name": "Nathaniel",
		"createdDate": "2014-10-16 23:30:48",
		"totalOrganizations": 12,
		"totalTours": 7,
		"totalDays": 1126,
		"totalCountries": 8,
		"totalStates": 29,
		"totalEvents": 74,
		"eventPercent": 0.429082,
		"totalScheduleItems": 749,
		"schedulePercent": 0.401404,
		"totalGuests": 75,
		"guestPercent": 0.450007,
		"mostGuestDate": "2023-07-23 00:00:00",
		"mostGuestName": "National Botanical Gardens",
		"mostGuestCity": "",
		"mostGuestAmount": 23
	},
	{
		"id": "4bf34e5",
		"email": "shanecrowl@mac.com",
		"name": "Shane",
		"createdDate": "2011-02-19 18:10:49",
		"totalOrganizations": 8,
		"totalTours": 12,
		"totalDays": 1384,
		"totalCountries": 13,
		"totalStates": 52,
		"totalEvents": 265,
		"eventPercent": 0.890743,
		"totalScheduleItems": 5639,
		"schedulePercent": 0.975235,
		"totalGuests": 1164,
		"guestPercent": 0.851808,
		"mostGuestDate": "2023-08-26 00:00:00",
		"mostGuestName": "The Brooklyn Mirage",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 179
	},
	{
		"id": "fcc9e93",
		"email": "cyril.safeway@gmail.com",
		"name": "Cyril",
		"createdDate": "2019-10-27 11:31:10",
		"totalOrganizations": 5,
		"totalTours": 8,
		"totalDays": 781,
		"totalCountries": 9,
		"totalStates": 49,
		"totalEvents": 107,
		"eventPercent": 0.568137,
		"totalScheduleItems": 2263,
		"schedulePercent": 0.761091,
		"totalGuests": 264,
		"guestPercent": 0.58628,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "Ãrea 12 Alicante",
		"mostGuestCity": "Alacant",
		"mostGuestAmount": 35
	},
	{
		"id": "d56ede1",
		"email": "mattmoermond@mac.com",
		"name": "Matthew",
		"createdDate": "2016-05-27 06:46:19",
		"totalOrganizations": 12,
		"totalTours": 6,
		"totalDays": 456,
		"totalCountries": 15,
		"totalStates": 38,
		"totalEvents": 66,
		"eventPercent": 0.38816,
		"totalScheduleItems": 1286,
		"schedulePercent": 0.562177,
		"totalGuests": 372,
		"guestPercent": 0.641902,
		"mostGuestDate": "2023-08-01 00:00:00",
		"mostGuestName": "The Bellweather",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 82
	},
	{
		"id": "dd48a38",
		"email": "karenlatu@gmail.com",
		"name": "Karen",
		"createdDate": "2013-09-06 07:48:02",
		"totalOrganizations": 4,
		"totalTours": 19,
		"totalDays": 265,
		"totalCountries": 12,
		"totalStates": 21,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 637,
		"schedulePercent": 0.357171,
		"totalGuests": 162,
		"guestPercent": 0.524169,
		"mostGuestDate": "2023-06-18 00:00:00",
		"mostGuestName": "Joan Sutherland Performing Arts Centre",
		"mostGuestCity": "Penrith",
		"mostGuestAmount": 58
	},
	{
		"id": "46ecd4e",
		"email": "ptrhlavacek@gmail.com",
		"name": "Petr",
		"createdDate": "2015-05-11 08:49:10",
		"totalOrganizations": 5,
		"totalTours": 16,
		"totalDays": 200,
		"totalCountries": 17,
		"totalStates": 42,
		"totalEvents": 103,
		"eventPercent": 0.55635,
		"totalScheduleItems": 823,
		"schedulePercent": 0.427493,
		"totalGuests": 13,
		"guestPercent": 0.352536,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "The 13th floor",
		"mostGuestCity": "Austin",
		"mostGuestAmount": 11
	},
	{
		"id": "f4c01f9",
		"email": "thifault.sam@gmail.com",
		"name": "Samuel",
		"createdDate": "2013-03-03 19:31:51",
		"totalOrganizations": 9,
		"totalTours": 13,
		"totalDays": 633,
		"totalCountries": 11,
		"totalStates": 60,
		"totalEvents": 143,
		"eventPercent": 0.681896,
		"totalScheduleItems": 1592,
		"schedulePercent": 0.640313,
		"totalGuests": 712,
		"guestPercent": 0.755529,
		"mostGuestDate": "2023-11-25 00:00:00",
		"mostGuestName": "MTELUS",
		"mostGuestCity": "MontrÃ©al",
		"mostGuestAmount": 211
	},
	{
		"id": "6405d9c",
		"email": "toddkth@gmail.com",
		"name": "Todd",
		"createdDate": "2016-01-23 22:05:31",
		"totalOrganizations": 13,
		"totalTours": 9,
		"totalDays": 347,
		"totalCountries": 15,
		"totalStates": 59,
		"totalEvents": 185,
		"eventPercent": 0.780029,
		"totalScheduleItems": 2764,
		"schedulePercent": 0.827573,
		"totalGuests": 1789,
		"guestPercent": 0.919348,
		"mostGuestDate": "2023-05-21 00:00:00",
		"mostGuestName": "Levitt Pavilion",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 99
	},
	{
		"id": "9125956",
		"email": "anne.kirsikka@warnermusic.com",
		"name": "Anne",
		"createdDate": "2016-05-24 07:07:45",
		"totalOrganizations": 54,
		"totalTours": 77,
		"totalDays": 1317,
		"totalCountries": 4,
		"totalStates": 37,
		"totalEvents": 993,
		"eventPercent": 0.990068,
		"totalScheduleItems": 4615,
		"schedulePercent": 0.948749,
		"totalGuests": 919,
		"guestPercent": 0.803999,
		"mostGuestDate": "2023-10-08 00:00:00",
		"mostGuestName": "Tavastia",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 58
	},
	{
		"id": "33a0b38",
		"email": "lot.verheyden@gmail.com",
		"name": "Lotte",
		"createdDate": "2016-10-07 08:54:40",
		"totalOrganizations": 9,
		"totalTours": 3,
		"totalDays": 110,
		"totalCountries": 2,
		"totalStates": 2,
		"totalEvents": 71,
		"eventPercent": 0.411734,
		"totalScheduleItems": 613,
		"schedulePercent": 0.346974,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1095c77",
		"email": "JACLYN.BEAUDRY@gmail.com",
		"name": "Jaclyn",
		"createdDate": "2022-08-03 15:07:13",
		"totalOrganizations": 6,
		"totalTours": 7,
		"totalDays": 647,
		"totalCountries": 6,
		"totalStates": 42,
		"totalEvents": 123,
		"eventPercent": 0.624156,
		"totalScheduleItems": 1580,
		"schedulePercent": 0.636737,
		"totalGuests": 170,
		"guestPercent": 0.530261,
		"mostGuestDate": "2023-10-21 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 42
	},
	{
		"id": "c2d8308",
		"email": "admin@lostmotel.com",
		"name": "Lost",
		"createdDate": "2023-11-03 02:15:58",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 123,
		"totalCountries": 8,
		"totalStates": 19,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 387,
		"schedulePercent": 0.251622,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-12-10 00:00:00",
		"mostGuestName": "Fremantle Prison",
		"mostGuestCity": "Fremantle",
		"mostGuestAmount": 2
	},
	{
		"id": "014c891",
		"email": "jim@rightangleent.com",
		"name": "James",
		"createdDate": "2019-05-23 16:37:00",
		"totalOrganizations": 6,
		"totalTours": 3,
		"totalDays": 208,
		"totalCountries": 2,
		"totalStates": 36,
		"totalEvents": 157,
		"eventPercent": 0.720567,
		"totalScheduleItems": 2140,
		"schedulePercent": 0.740432,
		"totalGuests": 16,
		"guestPercent": 0.361012,
		"mostGuestDate": "2023-01-13 00:00:00",
		"mostGuestName": "Pantages Theater",
		"mostGuestCity": "Tacoma",
		"mostGuestAmount": 8
	},
	{
		"id": "8e0c636",
		"email": "phil@mammothmusicgroup.com",
		"name": "Phil",
		"createdDate": "2019-09-16 18:16:24",
		"totalOrganizations": 24,
		"totalTours": 27,
		"totalDays": 5059,
		"totalCountries": 6,
		"totalStates": 51,
		"totalEvents": 315,
		"eventPercent": 0.923057,
		"totalScheduleItems": 3534,
		"schedulePercent": 0.896438,
		"totalGuests": 90,
		"guestPercent": 0.463912,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "The Caverns",
		"mostGuestCity": "Pelham",
		"mostGuestAmount": 26
	},
	{
		"id": "48bfb4a",
		"email": "nikki.crogan@gmail.com",
		"name": "Nikki ",
		"createdDate": "2019-08-01 06:20:15",
		"totalOrganizations": 7,
		"totalTours": 14,
		"totalDays": 768,
		"totalCountries": 12,
		"totalStates": 31,
		"totalEvents": 135,
		"eventPercent": 0.66084,
		"totalScheduleItems": 1294,
		"schedulePercent": 0.564826,
		"totalGuests": 15,
		"guestPercent": 0.358098,
		"mostGuestDate": "2023-05-03 00:00:00",
		"mostGuestName": "Riverstage",
		"mostGuestCity": "Brisbane City",
		"mostGuestAmount": 6
	},
	{
		"id": "d8b6305",
		"email": "rlawiii@me.com",
		"name": "Russell",
		"createdDate": "2014-02-24 22:54:43",
		"totalOrganizations": 3,
		"totalTours": 6,
		"totalDays": 441,
		"totalCountries": 3,
		"totalStates": 49,
		"totalEvents": 185,
		"eventPercent": 0.780029,
		"totalScheduleItems": 2442,
		"schedulePercent": 0.785194,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "a06b798",
		"email": "chandlerbaldwin8@gmail.com",
		"name": "Chandler",
		"createdDate": "2016-10-02 19:41:38",
		"totalOrganizations": 5,
		"totalTours": 1,
		"totalDays": 294,
		"totalCountries": 1,
		"totalStates": 23,
		"totalEvents": 45,
		"eventPercent": 0.286717,
		"totalScheduleItems": 481,
		"schedulePercent": 0.289101,
		"totalGuests": 135,
		"guestPercent": 0.50245,
		"mostGuestDate": "2023-10-02 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 73
	},
	{
		"id": "09b15f3",
		"email": "vpmattjr@gmail.com",
		"name": "Matt",
		"createdDate": "2011-09-26 19:20:39",
		"totalOrganizations": 14,
		"totalTours": 10,
		"totalDays": 278,
		"totalCountries": 4,
		"totalStates": 45,
		"totalEvents": 131,
		"eventPercent": 0.650377,
		"totalScheduleItems": 2429,
		"schedulePercent": 0.783737,
		"totalGuests": 1219,
		"guestPercent": 0.860548,
		"mostGuestDate": "2023-10-11 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 112
	},
	{
		"id": "f87bf8e",
		"email": "chevellestage@gmail.com",
		"name": "Steve",
		"createdDate": "2022-02-07 22:18:29",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 110,
		"totalCountries": 1,
		"totalStates": 22,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 985,
		"schedulePercent": 0.47795,
		"totalGuests": 513,
		"guestPercent": 0.696332,
		"mostGuestDate": "2023-09-13 00:00:00",
		"mostGuestName": "Byline Bank Aragon Ballroom",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 65
	},
	{
		"id": "b36f1e3",
		"email": "maik@eventsforbrands.de",
		"name": "Maik",
		"createdDate": "2014-09-09 15:27:30",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 333,
		"totalCountries": 3,
		"totalStates": 13,
		"totalEvents": 193,
		"eventPercent": 0.792743,
		"totalScheduleItems": 995,
		"schedulePercent": 0.483115,
		"totalGuests": 1178,
		"guestPercent": 0.853529,
		"mostGuestDate": "2023-09-02 00:00:00",
		"mostGuestName": "Mercedes-Benz Arena Berlin",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 335
	},
	{
		"id": "c851e19",
		"email": "colethepa@gmail.com",
		"name": "Cole",
		"createdDate": "2022-08-11 21:49:30",
		"totalOrganizations": 3,
		"totalTours": 1,
		"totalDays": 54,
		"totalCountries": 1,
		"totalStates": 19,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 504,
		"schedulePercent": 0.30049,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d175302",
		"email": "Keenanbevans@hotmail.com",
		"name": "Keenan",
		"createdDate": "2016-07-11 04:00:28",
		"totalOrganizations": 10,
		"totalTours": 11,
		"totalDays": 440,
		"totalCountries": 19,
		"totalStates": 60,
		"totalEvents": 208,
		"eventPercent": 0.819891,
		"totalScheduleItems": 3352,
		"schedulePercent": 0.883327,
		"totalGuests": 317,
		"guestPercent": 0.612502,
		"mostGuestDate": "2023-11-04 00:00:00",
		"mostGuestName": "Petco Park",
		"mostGuestCity": "San Diego",
		"mostGuestAmount": 186
	},
	{
		"id": "e9f11ee",
		"email": "sydneymanagement@unifiedmusicgroup.com",
		"name": "UNIFIED",
		"createdDate": "2017-11-13 04:46:13",
		"totalOrganizations": 8,
		"totalTours": 34,
		"totalDays": 446,
		"totalCountries": 7,
		"totalStates": 38,
		"totalEvents": 127,
		"eventPercent": 0.636869,
		"totalScheduleItems": 2297,
		"schedulePercent": 0.765197,
		"totalGuests": 1547,
		"guestPercent": 0.898954,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "University of New South Wales Roundhouse",
		"mostGuestCity": "Sydney",
		"mostGuestAmount": 95
	},
	{
		"id": "d74967d",
		"email": "justin@bigloud.com",
		"name": "Justin",
		"createdDate": "2021-10-28 14:55:07",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 540,
		"totalCountries": 3,
		"totalStates": 25,
		"totalEvents": 84,
		"eventPercent": 0.474507,
		"totalScheduleItems": 1784,
		"schedulePercent": 0.681896,
		"totalGuests": 1427,
		"guestPercent": 0.885181,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Truist Park",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 168
	},
	{
		"id": "2c8e409",
		"email": "anna.tidmore@268generation.com",
		"name": "Anna",
		"createdDate": "2023-09-08 14:10:07",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 128,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 23,
		"eventPercent": 0.15296,
		"totalScheduleItems": 196,
		"schedulePercent": 0.147663,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "11b2bc9",
		"email": "pchiovini@gmail.com",
		"name": "Paolo",
		"createdDate": "2012-07-13 08:55:59",
		"totalOrganizations": 5,
		"totalTours": 15,
		"totalDays": 478,
		"totalCountries": 4,
		"totalStates": 14,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 2132,
		"schedulePercent": 0.739505,
		"totalGuests": 868,
		"guestPercent": 0.791683,
		"mostGuestDate": "2023-11-24 00:00:00",
		"mostGuestName": "YouTube Theater",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 105
	},
	{
		"id": "c804bc2",
		"email": "ymojica@cityparksfoundation.org",
		"name": "Yunie",
		"createdDate": "2023-03-30 16:48:06",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 271,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 92,
		"eventPercent": 0.508674,
		"totalScheduleItems": 1488,
		"schedulePercent": 0.611177,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d957688",
		"email": "caleb@calebcook.net",
		"name": "Caleb",
		"createdDate": "2017-09-14 01:02:16",
		"totalOrganizations": 14,
		"totalTours": 20,
		"totalDays": 865,
		"totalCountries": 4,
		"totalStates": 43,
		"totalEvents": 345,
		"eventPercent": 0.939478,
		"totalScheduleItems": 6656,
		"schedulePercent": 0.986359,
		"totalGuests": 2915,
		"guestPercent": 0.968481,
		"mostGuestDate": "2023-08-24 00:00:00",
		"mostGuestName": "Bridgestone Arena",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 293
	},
	{
		"id": "4940f95",
		"email": "michael@allsorted.eu",
		"name": "Michael ",
		"createdDate": "2016-02-06 09:29:06",
		"totalOrganizations": 12,
		"totalTours": 26,
		"totalDays": 299,
		"totalCountries": 18,
		"totalStates": 42,
		"totalEvents": 162,
		"eventPercent": 0.732883,
		"totalScheduleItems": 1644,
		"schedulePercent": 0.651967,
		"totalGuests": 33,
		"guestPercent": 0.394252,
		"mostGuestDate": "2023-10-09 00:00:00",
		"mostGuestName": "Big Night Live",
		"mostGuestCity": "Boston",
		"mostGuestAmount": 6
	},
	{
		"id": "61eb6f3",
		"email": "info@soulhasnotempo.com",
		"name": "Ali",
		"createdDate": "2023-03-06 22:25:04",
		"totalOrganizations": 6,
		"totalTours": 10,
		"totalDays": 40,
		"totalCountries": 3,
		"totalStates": 10,
		"totalEvents": 16,
		"eventPercent": 0.107006,
		"totalScheduleItems": 136,
		"schedulePercent": 0.110581,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d3f4e65",
		"email": "cathymasontm@gmail.com",
		"name": "Cathy",
		"createdDate": "2014-05-14 17:16:31",
		"totalOrganizations": 9,
		"totalTours": 9,
		"totalDays": 69,
		"totalCountries": 2,
		"totalStates": 13,
		"totalEvents": 50,
		"eventPercent": 0.311747,
		"totalScheduleItems": 369,
		"schedulePercent": 0.242749,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "7ea8848",
		"email": "yo.srem@gmail.com",
		"name": "Nicholas",
		"createdDate": "2019-08-02 07:43:48",
		"totalOrganizations": 10,
		"totalTours": 17,
		"totalDays": 385,
		"totalCountries": 11,
		"totalStates": 57,
		"totalEvents": 211,
		"eventPercent": 0.825586,
		"totalScheduleItems": 2614,
		"schedulePercent": 0.810621,
		"totalGuests": 1454,
		"guestPercent": 0.888359,
		"mostGuestDate": "2023-01-22 00:00:00",
		"mostGuestName": "Eventim Apollo",
		"mostGuestCity": "London",
		"mostGuestAmount": 133
	},
	{
		"id": "374b9c2",
		"email": "jenn@sooksproductions.com",
		"name": "Jenn",
		"createdDate": "2013-12-05 19:28:52",
		"totalOrganizations": 7,
		"totalTours": 6,
		"totalDays": 305,
		"totalCountries": 4,
		"totalStates": 15,
		"totalEvents": 154,
		"eventPercent": 0.71368,
		"totalScheduleItems": 1395,
		"schedulePercent": 0.590385,
		"totalGuests": 446,
		"guestPercent": 0.671434,
		"mostGuestDate": "2023-10-28 00:00:00",
		"mostGuestName": "Queen Elizabeth Theatre",
		"mostGuestCity": "Toronto",
		"mostGuestAmount": 49
	},
	{
		"id": "ad1e960",
		"email": "tmcraigwellington@gmail.com",
		"name": "Craig",
		"createdDate": "2022-07-03 14:33:23",
		"totalOrganizations": 5,
		"totalTours": 5,
		"totalDays": 262,
		"totalCountries": 3,
		"totalStates": 6,
		"totalEvents": 138,
		"eventPercent": 0.668388,
		"totalScheduleItems": 1469,
		"schedulePercent": 0.607337,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-05-11 00:00:00",
		"mostGuestName": "De Montfort Hall",
		"mostGuestCity": "Leicester",
		"mostGuestAmount": 2
	},
	{
		"id": "7b8583c",
		"email": "darrickkinslow@gmail.com",
		"name": "Darrick",
		"createdDate": "2015-11-04 16:52:19",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 366,
		"totalCountries": 3,
		"totalStates": 27,
		"totalEvents": 125,
		"eventPercent": 0.630513,
		"totalScheduleItems": 454,
		"schedulePercent": 0.278109,
		"totalGuests": 552,
		"guestPercent": 0.710369,
		"mostGuestDate": "2023-02-17 00:00:00",
		"mostGuestName": "Stride Bank Center",
		"mostGuestCity": "Enid",
		"mostGuestAmount": 25
	},
	{
		"id": "ad1e907",
		"email": "john@mid8media.com",
		"name": "John",
		"createdDate": "2022-03-20 02:16:33",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 611,
		"totalCountries": 7,
		"totalStates": 37,
		"totalEvents": 40,
		"eventPercent": 0.257449,
		"totalScheduleItems": 521,
		"schedulePercent": 0.308039,
		"totalGuests": 50,
		"guestPercent": 0.421798,
		"mostGuestDate": "2023-04-14 00:00:00",
		"mostGuestName": "Sunbar Tempe",
		"mostGuestCity": "Tempe",
		"mostGuestAmount": 23
	},
	{
		"id": "6f5121b",
		"email": "sam@breitgroup.com",
		"name": "Sam",
		"createdDate": "2023-11-07 23:30:18",
		"totalOrganizations": 11,
		"totalTours": 3,
		"totalDays": 27,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 10,
		"eventPercent": 0.067011,
		"totalScheduleItems": 146,
		"schedulePercent": 0.117071,
		"totalGuests": 39,
		"guestPercent": 0.404185,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "Liberation Church",
		"mostGuestCity": "Richmond",
		"mostGuestAmount": 25
	},
	{
		"id": "a0258d9",
		"email": "chad@chadguyproductions.com",
		"name": "Chad",
		"createdDate": "2012-02-15 19:48:24",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 366,
		"totalCountries": 6,
		"totalStates": 30,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 3331,
		"schedulePercent": 0.88081,
		"totalGuests": 4773,
		"guestPercent": 0.990332,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Ohio Stadium",
		"mostGuestCity": "Columbus",
		"mostGuestAmount": 240
	},
	{
		"id": "2cc42b1",
		"email": "Andrew302w@gmail.com",
		"name": "Andrew",
		"createdDate": "2015-11-25 14:40:01",
		"totalOrganizations": 3,
		"totalTours": 2,
		"totalDays": 52,
		"totalCountries": 2,
		"totalStates": 26,
		"totalEvents": 35,
		"eventPercent": 0.230301,
		"totalScheduleItems": 492,
		"schedulePercent": 0.293604,
		"totalGuests": 615,
		"guestPercent": 0.730234,
		"mostGuestDate": "2023-08-03 00:00:00",
		"mostGuestName": "The Observatory",
		"mostGuestCity": "Santa Ana",
		"mostGuestAmount": 75
	},
	{
		"id": "c17f251",
		"email": "jade114@gmail.com",
		"name": "L",
		"createdDate": "2020-04-19 22:32:00",
		"totalOrganizations": 1,
		"totalTours": 4,
		"totalDays": 30,
		"totalCountries": 1,
		"totalStates": 11,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 81,
		"schedulePercent": 0.075884,
		"totalGuests": 1,
		"guestPercent": 0.289763,
		"mostGuestDate": "2023-08-01 00:00:00",
		"mostGuestName": "Madison Square Garden",
		"mostGuestCity": "New York",
		"mostGuestAmount": 1
	},
	{
		"id": "f629b11",
		"email": "laurel@constantartists.com",
		"name": "Laurel",
		"createdDate": "2023-07-11 18:24:00",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 162,
		"totalCountries": 4,
		"totalStates": 11,
		"totalEvents": 22,
		"eventPercent": 0.145809,
		"totalScheduleItems": 363,
		"schedulePercent": 0.239703,
		"totalGuests": 6,
		"guestPercent": 0.327109,
		"mostGuestDate": "2023-08-19 00:00:00",
		"mostGuestName": "field day festival",
		"mostGuestCity": "London",
		"mostGuestAmount": 6
	},
	{
		"id": "e2c0841",
		"email": "sajevoices@gmail.com",
		"name": "s&#228;je",
		"createdDate": "2023-10-22 00:24:22",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 26,
		"totalCountries": 1,
		"totalStates": 8,
		"totalEvents": 8,
		"eventPercent": 0.0535028,
		"totalScheduleItems": 141,
		"schedulePercent": 0.114157,
		"totalGuests": 4,
		"guestPercent": 0.312806,
		"mostGuestDate": "2023-11-16 00:00:00",
		"mostGuestName": "The Cutting Room",
		"mostGuestCity": "New York",
		"mostGuestAmount": 2
	},
	{
		"id": "aff1350",
		"email": "jon@spacebarsociety.com",
		"name": "Jonathan",
		"createdDate": "2019-10-15 21:19:53",
		"totalOrganizations": 11,
		"totalTours": 20,
		"totalDays": 1186,
		"totalCountries": 16,
		"totalStates": 60,
		"totalEvents": 278,
		"eventPercent": 0.900278,
		"totalScheduleItems": 3820,
		"schedulePercent": 0.912859,
		"totalGuests": 904,
		"guestPercent": 0.800556,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "Palacio de los Deportes",
		"mostGuestCity": "Ciudad de MÃ©xico",
		"mostGuestAmount": 108
	},
	{
		"id": "700b274",
		"email": "amberjinae@gmail.com",
		"name": "Amber",
		"createdDate": "2022-06-23 00:29:55",
		"totalOrganizations": 3,
		"totalTours": 7,
		"totalDays": 817,
		"totalCountries": 18,
		"totalStates": 31,
		"totalEvents": 336,
		"eventPercent": 0.935108,
		"totalScheduleItems": 3973,
		"schedulePercent": 0.9216,
		"totalGuests": 6793,
		"guestPercent": 0.996689,
		"mostGuestDate": "2023-08-18 00:00:00",
		"mostGuestName": "Wembley Stadium",
		"mostGuestCity": "Wembley",
		"mostGuestAmount": 625
	},
	{
		"id": "d60f0ab",
		"email": "dmbynum@gmail.com",
		"name": "Daniel",
		"createdDate": "2013-03-08 00:05:09",
		"totalOrganizations": 6,
		"totalTours": 5,
		"totalDays": 153,
		"totalCountries": 11,
		"totalStates": 30,
		"totalEvents": 55,
		"eventPercent": 0.337836,
		"totalScheduleItems": 739,
		"schedulePercent": 0.396106,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5356b0c",
		"email": "hollistrickland@aol.com",
		"name": "Holli",
		"createdDate": "2017-02-01 00:39:20",
		"totalOrganizations": 7,
		"totalTours": 16,
		"totalDays": 374,
		"totalCountries": 19,
		"totalStates": 60,
		"totalEvents": 209,
		"eventPercent": 0.82201,
		"totalScheduleItems": 3044,
		"schedulePercent": 0.855383,
		"totalGuests": 9,
		"guestPercent": 0.340087,
		"mostGuestDate": "2023-12-10 00:00:00",
		"mostGuestName": "Raue Center For The Arts",
		"mostGuestCity": "Crystal Lake",
		"mostGuestAmount": 7
	},
	{
		"id": "7792ef3",
		"email": "onemarkonly@mac.com",
		"name": "Mark",
		"createdDate": "2009-09-02 21:43:51",
		"totalOrganizations": 9,
		"totalTours": 10,
		"totalDays": 1261,
		"totalCountries": 7,
		"totalStates": 36,
		"totalEvents": 173,
		"eventPercent": 0.757516,
		"totalScheduleItems": 3057,
		"schedulePercent": 0.857635,
		"totalGuests": 1253,
		"guestPercent": 0.865316,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Beacon Theatre",
		"mostGuestCity": "New York",
		"mostGuestAmount": 78
	},
	{
		"id": "65b8197",
		"email": "nickrucker@gmail.com",
		"name": "Nick",
		"createdDate": "2017-09-12 18:04:20",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 289,
		"totalCountries": 9,
		"totalStates": 61,
		"totalEvents": 119,
		"eventPercent": 0.610383,
		"totalScheduleItems": 1584,
		"schedulePercent": 0.637929,
		"totalGuests": 777,
		"guestPercent": 0.771289,
		"mostGuestDate": "2023-05-20 00:00:00",
		"mostGuestName": "O2 Forum",
		"mostGuestCity": "London",
		"mostGuestAmount": 49
	},
	{
		"id": "7f1b986",
		"email": "landyngerleman2@icloud.com",
		"name": "Landyn",
		"createdDate": "2020-09-19 06:22:07",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 70,
		"totalCountries": 1,
		"totalStates": 12,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 513,
		"schedulePercent": 0.30539,
		"totalGuests": 2,
		"guestPercent": 0.295722,
		"mostGuestDate": "2023-01-28 00:00:00",
		"mostGuestName": "Bottom Lounge",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 1
	},
	{
		"id": "2ee23fd",
		"email": "altopalomgmt@gmail.com",
		"name": "Dillon",
		"createdDate": "2023-03-14 02:05:28",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 43,
		"totalCountries": 2,
		"totalStates": 25,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 247,
		"schedulePercent": 0.180373,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fa32624",
		"email": "nadsono@hotmail.com",
		"name": "Steven",
		"createdDate": "2015-07-26 19:00:12",
		"totalOrganizations": 4,
		"totalTours": 1,
		"totalDays": 36,
		"totalCountries": 1,
		"totalStates": 5,
		"totalEvents": 26,
		"eventPercent": 0.174944,
		"totalScheduleItems": 193,
		"schedulePercent": 0.145941,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "e7cabe4",
		"email": "info@eventcity.de",
		"name": "Karina",
		"createdDate": "2023-10-19 08:56:28",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 26,
		"totalCountries": 3,
		"totalStates": 5,
		"totalEvents": 11,
		"eventPercent": 0.0756191,
		"totalScheduleItems": 224,
		"schedulePercent": 0.166071,
		"totalGuests": 257,
		"guestPercent": 0.582969,
		"mostGuestDate": "2023-10-27 00:00:00",
		"mostGuestName": "SAP Arena",
		"mostGuestCity": "Mannheim",
		"mostGuestAmount": 61
	},
	{
		"id": "05210c8",
		"email": "edieebooth@gmail.com",
		"name": "Edie",
		"createdDate": "2021-10-15 19:01:00",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 131,
		"totalCountries": 7,
		"totalStates": 12,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 358,
		"schedulePercent": 0.237055,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5b53260",
		"email": "tricia.engelbert@gmail.com",
		"name": "Tricia",
		"createdDate": "2017-08-28 18:00:26",
		"totalOrganizations": 9,
		"totalTours": 14,
		"totalDays": 1586,
		"totalCountries": 22,
		"totalStates": 74,
		"totalEvents": 331,
		"eventPercent": 0.932327,
		"totalScheduleItems": 5943,
		"schedulePercent": 0.979605,
		"totalGuests": 570,
		"guestPercent": 0.716726,
		"mostGuestDate": "2023-11-14 00:00:00",
		"mostGuestName": "Acrisure Arena",
		"mostGuestCity": "Palm Desert",
		"mostGuestAmount": 77
	},
	{
		"id": "e889732",
		"email": "ronnie@meshuggah.net",
		"name": "Ronnie",
		"createdDate": "2017-03-05 09:47:42",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 103,
		"totalCountries": 10,
		"totalStates": 34,
		"totalEvents": 51,
		"eventPercent": 0.316249,
		"totalScheduleItems": 606,
		"schedulePercent": 0.343796,
		"totalGuests": 504,
		"guestPercent": 0.693021,
		"mostGuestDate": "2023-04-08 00:00:00",
		"mostGuestName": "Annexet",
		"mostGuestCity": "Stockholm",
		"mostGuestAmount": 78
	},
	{
		"id": "bb0f3d1",
		"email": "sebastian@musikwirtschaft.de",
		"name": "Sebastian",
		"createdDate": "2017-08-09 15:11:00",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 68,
		"totalCountries": 2,
		"totalStates": 5,
		"totalEvents": 33,
		"eventPercent": 0.21719,
		"totalScheduleItems": 804,
		"schedulePercent": 0.421931,
		"totalGuests": 1102,
		"guestPercent": 0.839756,
		"mostGuestDate": "2023-07-19 00:00:00",
		"mostGuestName": "Schlossplatz Stuttgart",
		"mostGuestCity": "Stuttgart",
		"mostGuestAmount": 214
	},
	{
		"id": "daac05d",
		"email": "patriciaelenavillegas@gmail.com",
		"name": "Pati",
		"createdDate": "2017-06-29 12:06:21",
		"totalOrganizations": 2,
		"totalTours": 7,
		"totalDays": 440,
		"totalCountries": 7,
		"totalStates": 42,
		"totalEvents": 246,
		"eventPercent": 0.868759,
		"totalScheduleItems": 4021,
		"schedulePercent": 0.924116,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f848a06",
		"email": "celiagranegarcia@hotmail.com",
		"name": "Celia",
		"createdDate": "2014-02-20 12:20:15",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 310,
		"totalCountries": 5,
		"totalStates": 51,
		"totalEvents": 113,
		"eventPercent": 0.588929,
		"totalScheduleItems": 1118,
		"schedulePercent": 0.515693,
		"totalGuests": 744,
		"guestPercent": 0.762945,
		"mostGuestDate": "2023-11-11 00:00:00",
		"mostGuestName": "RECINTO GOLF COSTA ADEJE",
		"mostGuestCity": "ARONA",
		"mostGuestAmount": 99
	},
	{
		"id": "74256cf",
		"email": "carmenchoney@gmail.com",
		"name": "Carmen",
		"createdDate": "2012-10-03 01:13:46",
		"totalOrganizations": 8,
		"totalTours": 6,
		"totalDays": 295,
		"totalCountries": 4,
		"totalStates": 12,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 763,
		"schedulePercent": 0.406569,
		"totalGuests": 51,
		"guestPercent": 0.423785,
		"mostGuestDate": "2023-10-26 00:00:00",
		"mostGuestName": "Winspear Centre",
		"mostGuestCity": "Edmonton",
		"mostGuestAmount": 14
	},
	{
		"id": "03b5c42",
		"email": "ccbroussard@gmail.com",
		"name": "Chris",
		"createdDate": "2013-01-14 20:22:27",
		"totalOrganizations": 10,
		"totalTours": 4,
		"totalDays": 801,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 216,
		"eventPercent": 0.831943,
		"totalScheduleItems": 4269,
		"schedulePercent": 0.93683,
		"totalGuests": 1696,
		"guestPercent": 0.912594,
		"mostGuestDate": "2023-09-08 00:00:00",
		"mostGuestName": "Raising Cane's River Center",
		"mostGuestCity": "Baton Rouge",
		"mostGuestAmount": 122
	},
	{
		"id": "ec50236",
		"email": "chris.gradystl@gmail.com",
		"name": "Christopher",
		"createdDate": "2013-10-06 02:17:24",
		"totalOrganizations": 10,
		"totalTours": 8,
		"totalDays": 500,
		"totalCountries": 6,
		"totalStates": 36,
		"totalEvents": 99,
		"eventPercent": 0.537015,
		"totalScheduleItems": 2347,
		"schedulePercent": 0.773672,
		"totalGuests": 1003,
		"guestPercent": 0.824527,
		"mostGuestDate": "2023-03-11 00:00:00",
		"mostGuestName": "The Pageant",
		"mostGuestCity": "St. Louis",
		"mostGuestAmount": 94
	},
	{
		"id": "21e79e6",
		"email": "j.luther@nwgroup.com.au",
		"name": "Jake",
		"createdDate": "2023-09-06 05:44:11",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 50,
		"totalCountries": 1,
		"totalStates": 6,
		"totalEvents": 18,
		"eventPercent": 0.119984,
		"totalScheduleItems": 161,
		"schedulePercent": 0.127135,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "d677b6c",
		"email": "carimei@variousartistsmanagement.com",
		"name": "Carimei",
		"createdDate": "2022-01-19 23:26:31",
		"totalOrganizations": 8,
		"totalTours": 9,
		"totalDays": 827,
		"totalCountries": 22,
		"totalStates": 62,
		"totalEvents": 176,
		"eventPercent": 0.763475,
		"totalScheduleItems": 3728,
		"schedulePercent": 0.908621,
		"totalGuests": 775,
		"guestPercent": 0.771024,
		"mostGuestDate": "2023-10-31 00:00:00",
		"mostGuestName": "Hollywood Palladium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 91
	},
	{
		"id": "eba0133",
		"email": "derekdbh@yahoo.com",
		"name": "Derek",
		"createdDate": "2010-08-04 01:03:41",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 180,
		"totalCountries": 1,
		"totalStates": 30,
		"totalEvents": 69,
		"eventPercent": 0.403788,
		"totalScheduleItems": 1218,
		"schedulePercent": 0.544828,
		"totalGuests": 1363,
		"guestPercent": 0.878956,
		"mostGuestDate": "2023-09-23 00:00:00",
		"mostGuestName": "Five Point Amphitheater",
		"mostGuestCity": "Irvine",
		"mostGuestAmount": 137
	},
	{
		"id": "1d6c684",
		"email": "ce.smith@live.co.uk",
		"name": "Caroline",
		"createdDate": "2013-11-11 03:04:18",
		"totalOrganizations": 15,
		"totalTours": 7,
		"totalDays": 241,
		"totalCountries": 5,
		"totalStates": 26,
		"totalEvents": 96,
		"eventPercent": 0.524566,
		"totalScheduleItems": 2045,
		"schedulePercent": 0.726659,
		"totalGuests": 1197,
		"guestPercent": 0.856973,
		"mostGuestDate": "2023-07-03 00:00:00",
		"mostGuestName": "Banc of California Stadium",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 219
	},
	{
		"id": "2815ba1",
		"email": "cco@gentrytouring.com",
		"name": "Gabriella Gale",
		"createdDate": "2022-02-14 21:39:51",
		"totalOrganizations": 9,
		"totalTours": 8,
		"totalDays": 1385,
		"totalCountries": 11,
		"totalStates": 43,
		"totalEvents": 197,
		"eventPercent": 0.800821,
		"totalScheduleItems": 2773,
		"schedulePercent": 0.828632,
		"totalGuests": 941,
		"guestPercent": 0.810091,
		"mostGuestDate": "2023-11-03 00:00:00",
		"mostGuestName": "Terminal 5",
		"mostGuestCity": "New York",
		"mostGuestAmount": 81
	},
	{
		"id": "b7afb2d",
		"email": "nfutral@gmail.com",
		"name": "Nate",
		"createdDate": "2010-11-05 20:28:41",
		"totalOrganizations": 10,
		"totalTours": 13,
		"totalDays": 1091,
		"totalCountries": 6,
		"totalStates": 54,
		"totalEvents": 258,
		"eventPercent": 0.883989,
		"totalScheduleItems": 4401,
		"schedulePercent": 0.9412,
		"totalGuests": 3177,
		"guestPercent": 0.972851,
		"mostGuestDate": "2023-10-03 00:00:00",
		"mostGuestName": "Ascend Amphitheater",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 104
	},
	{
		"id": "6258621",
		"email": "anthia.nibitegeka@cullberg.com",
		"name": "Anthia",
		"createdDate": "2021-10-18 09:55:16",
		"totalOrganizations": 1,
		"totalTours": 6,
		"totalDays": 78,
		"totalCountries": 3,
		"totalStates": 6,
		"totalEvents": 54,
		"eventPercent": 0.332936,
		"totalScheduleItems": 668,
		"schedulePercent": 0.368296,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "fd43662",
		"email": "mikeahern24@gmail.com",
		"name": "Mike",
		"createdDate": "2014-09-11 20:57:05",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 91,
		"totalCountries": 2,
		"totalStates": 17,
		"totalEvents": 43,
		"eventPercent": 0.275593,
		"totalScheduleItems": 539,
		"schedulePercent": 0.317309,
		"totalGuests": 515,
		"guestPercent": 0.697126,
		"mostGuestDate": "2023-09-21 00:00:00",
		"mostGuestName": "The Forum",
		"mostGuestCity": "Inglewood",
		"mostGuestAmount": 100
	},
	{
		"id": "881eca8",
		"email": "stuart@cctours.co.uk",
		"name": "Stuart",
		"createdDate": "2022-07-14 19:40:29",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 561,
		"totalCountries": 18,
		"totalStates": 54,
		"totalEvents": 112,
		"eventPercent": 0.585883,
		"totalScheduleItems": 1895,
		"schedulePercent": 0.703483,
		"totalGuests": 602,
		"guestPercent": 0.726791,
		"mostGuestDate": "2023-10-19 00:00:00",
		"mostGuestName": "The Fonda Theatre",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 130
	},
	{
		"id": "113ad61",
		"email": "alexanderjgehring@gmail.com",
		"name": "Alexander",
		"createdDate": "2019-08-29 20:50:11",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 38,
		"totalCountries": 5,
		"totalStates": 6,
		"totalEvents": 21,
		"eventPercent": 0.139452,
		"totalScheduleItems": 207,
		"schedulePercent": 0.154417,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ef19526",
		"email": "alex@riserhouse.com",
		"name": "Alex",
		"createdDate": "2021-12-01 18:03:05",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 733,
		"totalCountries": 1,
		"totalStates": 42,
		"totalEvents": 169,
		"eventPercent": 0.749437,
		"totalScheduleItems": 1570,
		"schedulePercent": 0.634618,
		"totalGuests": 308,
		"guestPercent": 0.608131,
		"mostGuestDate": "2023-10-02 00:00:00",
		"mostGuestName": "The Basement East",
		"mostGuestCity": "Nashville",
		"mostGuestAmount": 73
	},
	{
		"id": "ed952ae",
		"email": "rhwt814@gmail.com",
		"name": "Bill",
		"createdDate": "2010-06-15 22:41:36",
		"totalOrganizations": 8,
		"totalTours": 2,
		"totalDays": 85,
		"totalCountries": 1,
		"totalStates": 3,
		"totalEvents": 57,
		"eventPercent": 0.346312,
		"totalScheduleItems": 809,
		"schedulePercent": 0.422858,
		"totalGuests": 1300,
		"guestPercent": 0.871673,
		"mostGuestDate": "2023-03-01 00:00:00",
		"mostGuestName": "Houston Livestock Show and Rodeo",
		"mostGuestCity": "Houston",
		"mostGuestAmount": 100
	},
	{
		"id": "bfbd386",
		"email": "katievan7@gmail.com",
		"name": "Katie",
		"createdDate": "2011-10-15 01:27:15",
		"totalOrganizations": 4,
		"totalTours": 4,
		"totalDays": 273,
		"totalCountries": 3,
		"totalStates": 38,
		"totalEvents": 166,
		"eventPercent": 0.74308,
		"totalScheduleItems": 2730,
		"schedulePercent": 0.823335,
		"totalGuests": 726,
		"guestPercent": 0.75884,
		"mostGuestDate": "2023-11-09 00:00:00",
		"mostGuestName": "The Bellwether",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 40
	},
	{
		"id": "87c0e19",
		"email": "jgelfond1@me.com",
		"name": "Joshua",
		"createdDate": "2019-07-13 16:33:49",
		"totalOrganizations": 5,
		"totalTours": 4,
		"totalDays": 571,
		"totalCountries": 11,
		"totalStates": 39,
		"totalEvents": 115,
		"eventPercent": 0.595021,
		"totalScheduleItems": 1064,
		"schedulePercent": 0.502318,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "caa572b",
		"email": "alexkeyser@me.com",
		"name": "Alex",
		"createdDate": "2019-06-28 08:44:01",
		"totalOrganizations": 7,
		"totalTours": 5,
		"totalDays": 576,
		"totalCountries": 12,
		"totalStates": 34,
		"totalEvents": 105,
		"eventPercent": 0.56231,
		"totalScheduleItems": 1353,
		"schedulePercent": 0.581248,
		"totalGuests": 1052,
		"guestPercent": 0.833135,
		"mostGuestDate": "2023-06-02 00:00:00",
		"mostGuestName": "Avant Gardner",
		"mostGuestCity": "Brooklyn",
		"mostGuestAmount": 133
	},
	{
		"id": "39be362",
		"email": "ricardo.productionmanager@gmail.com",
		"name": "Ricardo",
		"createdDate": "2023-07-29 16:47:36",
		"totalOrganizations": 2,
		"totalTours": 2,
		"totalDays": 145,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 68,
		"schedulePercent": 0.0678056,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f23168d",
		"email": "parismastertour@gmail.com",
		"name": "Tour",
		"createdDate": "2022-10-30 22:05:23",
		"totalOrganizations": 2,
		"totalTours": 7,
		"totalDays": 137,
		"totalCountries": 1,
		"totalStates": 27,
		"totalEvents": 46,
		"eventPercent": 0.290955,
		"totalScheduleItems": 427,
		"schedulePercent": 0.266587,
		"totalGuests": 49,
		"guestPercent": 0.419944,
		"mostGuestDate": "2023-10-06 00:00:00",
		"mostGuestName": "Hollywood Bowl",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 12
	},
	{
		"id": "2d5b8a1",
		"email": "joel.shackleford@gmail.com",
		"name": "Joel",
		"createdDate": "2017-02-26 02:00:55",
		"totalOrganizations": 10,
		"totalTours": 8,
		"totalDays": 885,
		"totalCountries": 11,
		"totalStates": 47,
		"totalEvents": 124,
		"eventPercent": 0.627467,
		"totalScheduleItems": 1369,
		"schedulePercent": 0.585088,
		"totalGuests": 302,
		"guestPercent": 0.603496,
		"mostGuestDate": "2023-06-02 00:00:00",
		"mostGuestName": "Riverside Municipal Auditorium",
		"mostGuestCity": "Riverside",
		"mostGuestAmount": 59
	},
	{
		"id": "6bca98c",
		"email": "lisa.arzt@gmail.com",
		"name": "Lisa",
		"createdDate": "2022-02-02 12:35:32",
		"totalOrganizations": 7,
		"totalTours": 20,
		"totalDays": 581,
		"totalCountries": 9,
		"totalStates": 44,
		"totalEvents": 137,
		"eventPercent": 0.666402,
		"totalScheduleItems": 2434,
		"schedulePercent": 0.784399,
		"totalGuests": 322,
		"guestPercent": 0.614753,
		"mostGuestDate": "2023-10-15 00:00:00",
		"mostGuestName": "The Eastern",
		"mostGuestCity": "Atlanta",
		"mostGuestAmount": 35
	},
	{
		"id": "88d44f2",
		"email": "jw@juddwhite.net",
		"name": "Judd",
		"createdDate": "2010-04-27 21:36:00",
		"totalOrganizations": 19,
		"totalTours": 10,
		"totalDays": 1161,
		"totalCountries": 14,
		"totalStates": 58,
		"totalEvents": 305,
		"eventPercent": 0.918554,
		"totalScheduleItems": 4412,
		"schedulePercent": 0.941597,
		"totalGuests": 1365,
		"guestPercent": 0.879354,
		"mostGuestDate": "2023-06-28 00:00:00",
		"mostGuestName": "Rogers Arena",
		"mostGuestCity": "Vancouver",
		"mostGuestAmount": 178
	},
	{
		"id": "a5ae171",
		"email": "dustin@transparentproductions.com",
		"name": "Dustin",
		"createdDate": "2022-02-08 19:55:56",
		"totalOrganizations": 5,
		"totalTours": 7,
		"totalDays": 570,
		"totalCountries": 2,
		"totalStates": 23,
		"totalEvents": 80,
		"eventPercent": 0.460204,
		"totalScheduleItems": 1265,
		"schedulePercent": 0.555953,
		"totalGuests": 245,
		"guestPercent": 0.57595,
		"mostGuestDate": "2023-07-22 00:00:00",
		"mostGuestName": "Palmetto Pointe Church of God (Myrtle Beach)",
		"mostGuestCity": "Myrtle Beach",
		"mostGuestAmount": 45
	},
	{
		"id": "5041273",
		"email": "management@yngfrnco.com",
		"name": "Joseph",
		"createdDate": "2019-10-24 02:02:12",
		"totalOrganizations": 2,
		"totalTours": 3,
		"totalDays": 366,
		"totalCountries": 5,
		"totalStates": 15,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 188,
		"schedulePercent": 0.144087,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f121afe",
		"email": "stickfigurepaypal@gmail.com",
		"name": "Stick",
		"createdDate": "2019-10-03 00:14:25",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 151,
		"totalCountries": 2,
		"totalStates": 14,
		"totalEvents": 34,
		"eventPercent": 0.223149,
		"totalScheduleItems": 752,
		"schedulePercent": 0.402861,
		"totalGuests": 1390,
		"guestPercent": 0.88187,
		"mostGuestDate": "2023-06-18 00:00:00",
		"mostGuestName": "Red Rocks Park and Amphitheatre",
		"mostGuestCity": "Morrison",
		"mostGuestAmount": 183
	},
	{
		"id": "2acb044",
		"email": "ashleyalexander75@gmail.com",
		"name": "Ashley ",
		"createdDate": "2018-05-18 22:02:13",
		"totalOrganizations": 10,
		"totalTours": 11,
		"totalDays": 813,
		"totalCountries": 9,
		"totalStates": 38,
		"totalEvents": 270,
		"eventPercent": 0.894451,
		"totalScheduleItems": 2461,
		"schedulePercent": 0.78771,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "f70dbbd",
		"email": "david.darlington@mac.com",
		"name": "David",
		"createdDate": "2013-10-02 01:37:09",
		"totalOrganizations": 19,
		"totalTours": 17,
		"totalDays": 506,
		"totalCountries": 11,
		"totalStates": 20,
		"totalEvents": 130,
		"eventPercent": 0.647596,
		"totalScheduleItems": 1743,
		"schedulePercent": 0.673156,
		"totalGuests": 1148,
		"guestPercent": 0.849159,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "2a8e7f3",
		"email": "dolphinproduction@icloud.com",
		"name": "Nils",
		"createdDate": "2018-01-11 20:55:08",
		"totalOrganizations": 15,
		"totalTours": 8,
		"totalDays": 311,
		"totalCountries": 6,
		"totalStates": 24,
		"totalEvents": 329,
		"eventPercent": 0.930738,
		"totalScheduleItems": 2253,
		"schedulePercent": 0.759105,
		"totalGuests": 669,
		"guestPercent": 0.743875,
		"mostGuestDate": "2023-04-21 00:00:00",
		"mostGuestName": "Det Kongelige Teater, Gamle Scene",
		"mostGuestCity": "K&#248;benhavn K",
		"mostGuestAmount": 61
	},
	{
		"id": "3d4556b",
		"email": "southerlandofficial@gmail.com",
		"name": "Email",
		"createdDate": "2023-06-08 20:25:13",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 76,
		"totalCountries": 1,
		"totalStates": 20,
		"totalEvents": 25,
		"eventPercent": 0.167792,
		"totalScheduleItems": 79,
		"schedulePercent": 0.0745597,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "1156041",
		"email": "john@bookmcr.com",
		"name": "John ",
		"createdDate": "2015-03-19 22:39:55",
		"totalOrganizations": 20,
		"totalTours": 38,
		"totalDays": 1369,
		"totalCountries": 20,
		"totalStates": 81,
		"totalEvents": 435,
		"eventPercent": 0.9657,
		"totalScheduleItems": 5631,
		"schedulePercent": 0.975103,
		"totalGuests": 2223,
		"guestPercent": 0.944908,
		"mostGuestDate": "2023-09-20 00:00:00",
		"mostGuestName": "The Night Cat",
		"mostGuestCity": "Fitzroy",
		"mostGuestAmount": 153
	},
	{
		"id": "25f5b51",
		"email": "gabriel@ontourmgmt.com",
		"name": "Gabriel",
		"createdDate": "2013-05-13 22:49:40",
		"totalOrganizations": 3,
		"totalTours": 3,
		"totalDays": 503,
		"totalCountries": 9,
		"totalStates": 12,
		"totalEvents": 30,
		"eventPercent": 0.200503,
		"totalScheduleItems": 601,
		"schedulePercent": 0.341544,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "5a38fde",
		"email": "Jessicaweigand1762@gmail.com",
		"name": "Jessica",
		"createdDate": "2014-10-01 17:32:11",
		"totalOrganizations": 11,
		"totalTours": 24,
		"totalDays": 897,
		"totalCountries": 7,
		"totalStates": 52,
		"totalEvents": 294,
		"eventPercent": 0.9118,
		"totalScheduleItems": 6682,
		"schedulePercent": 0.986624,
		"totalGuests": 1127,
		"guestPercent": 0.843994,
		"mostGuestDate": "2023-02-18 00:00:00",
		"mostGuestName": "The Salt Shed",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 45
	},
	{
		"id": "b0b9af5",
		"email": "emma@lookoutkid.com",
		"name": "Emma",
		"createdDate": "2023-01-18 23:51:46",
		"totalOrganizations": 20,
		"totalTours": 72,
		"totalDays": 1368,
		"totalCountries": 18,
		"totalStates": 80,
		"totalEvents": 627,
		"eventPercent": 0.983843,
		"totalScheduleItems": 7911,
		"schedulePercent": 0.993908,
		"totalGuests": 5265,
		"guestPercent": 0.993246,
		"mostGuestDate": "2023-09-16 00:00:00",
		"mostGuestName": "The ICON Festival Stage",
		"mostGuestCity": "Cincinnati",
		"mostGuestAmount": 238
	},
	{
		"id": "c63f893",
		"email": "kimmo.lempila@lakewave.fi",
		"name": "Kimmo",
		"createdDate": "2022-01-21 09:11:00",
		"totalOrganizations": 3,
		"totalTours": 4,
		"totalDays": 52,
		"totalCountries": 1,
		"totalStates": 1,
		"totalEvents": 41,
		"eventPercent": 0.265263,
		"totalScheduleItems": 224,
		"schedulePercent": 0.166071,
		"totalGuests": 25,
		"guestPercent": 0.377036,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Suvilahti",
		"mostGuestCity": "Helsinki",
		"mostGuestAmount": 11
	},
	{
		"id": "2f7f694",
		"email": "mikaela@invisiblemgmt.com",
		"name": "Mikaela",
		"createdDate": "2021-07-26 04:00:25",
		"totalOrganizations": 5,
		"totalTours": 6,
		"totalDays": 628,
		"totalCountries": 8,
		"totalStates": 37,
		"totalEvents": 104,
		"eventPercent": 0.559131,
		"totalScheduleItems": 1485,
		"schedulePercent": 0.610648,
		"totalGuests": 488,
		"guestPercent": 0.687591,
		"mostGuestDate": "2023-03-24 00:00:00",
		"mostGuestName": "The Salt Shed",
		"mostGuestCity": "Chicago",
		"mostGuestAmount": 62
	},
	{
		"id": "a0269ef",
		"email": "curtis_king16@hotmail.com",
		"name": "Curtis",
		"createdDate": "2019-11-06 20:08:31",
		"totalOrganizations": 7,
		"totalTours": 12,
		"totalDays": 538,
		"totalCountries": 17,
		"totalStates": 52,
		"totalEvents": 186,
		"eventPercent": 0.782016,
		"totalScheduleItems": 1944,
		"schedulePercent": 0.710502,
		"totalGuests": 460,
		"guestPercent": 0.677659,
		"mostGuestDate": "2023-10-31 00:00:00",
		"mostGuestName": "The Wiltern",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 85
	},
	{
		"id": "7b986ca",
		"email": "aayers80@gmail.com",
		"name": "Adam",
		"createdDate": "2010-08-30 17:29:49",
		"totalOrganizations": 9,
		"totalTours": 11,
		"totalDays": 783,
		"totalCountries": 8,
		"totalStates": 55,
		"totalEvents": 285,
		"eventPercent": 0.905973,
		"totalScheduleItems": 4135,
		"schedulePercent": 0.929811,
		"totalGuests": 1134,
		"guestPercent": 0.845981,
		"mostGuestDate": "2023-04-29 00:00:00",
		"mostGuestName": "Saenger Theatre",
		"mostGuestCity": "New Orleans",
		"mostGuestAmount": 130
	},
	{
		"id": "5b66ee8",
		"email": "chad@wastedpotential.net",
		"name": "Chadrick",
		"createdDate": "2013-11-29 23:56:05",
		"totalOrganizations": 33,
		"totalTours": 28,
		"totalDays": 1944,
		"totalCountries": 15,
		"totalStates": 72,
		"totalEvents": 416,
		"eventPercent": 0.96133,
		"totalScheduleItems": 5683,
		"schedulePercent": 0.97603,
		"totalGuests": 3551,
		"guestPercent": 0.978281,
		"mostGuestDate": "2023-07-14 00:00:00",
		"mostGuestName": "Microsoft Theather",
		"mostGuestCity": "Los Angeles",
		"mostGuestAmount": 315
	},
	{
		"id": "c70a309",
		"email": "ali.martin@me.com",
		"name": "Alice",
		"createdDate": "2012-10-23 12:46:50",
		"totalOrganizations": 4,
		"totalTours": 5,
		"totalDays": 395,
		"totalCountries": 6,
		"totalStates": 9,
		"totalEvents": 58,
		"eventPercent": 0.35055,
		"totalScheduleItems": 1230,
		"schedulePercent": 0.547477,
		"totalGuests": 37,
		"guestPercent": 0.400212,
		"mostGuestDate": "2023-07-12 00:00:00",
		"mostGuestName": "Montreux Jazz Festival",
		"mostGuestCity": "Montreux",
		"mostGuestAmount": 7
	},
	{
		"id": "1f5f779",
		"email": "laineywilsonmedia@gmail.com",
		"name": "Lainey",
		"createdDate": "2019-08-15 18:38:28",
		"totalOrganizations": 1,
		"totalTours": 2,
		"totalDays": 365,
		"totalCountries": 5,
		"totalStates": 49,
		"totalEvents": 161,
		"eventPercent": 0.72997,
		"totalScheduleItems": 2248,
		"schedulePercent": 0.757648,
		"totalGuests": 586,
		"guestPercent": 0.720832,
		"mostGuestDate": "2023-07-28 00:00:00",
		"mostGuestName": "Mohegan Sun Arena",
		"mostGuestCity": "Uncasville",
		"mostGuestAmount": 42
	},
	{
		"id": "d59b0b2",
		"email": "keh@pap.no",
		"name": "Kaj-Even",
		"createdDate": "2019-04-05 11:19:43",
		"totalOrganizations": 4,
		"totalTours": 17,
		"totalDays": 120,
		"totalCountries": 4,
		"totalStates": 7,
		"totalEvents": 36,
		"eventPercent": 0.235201,
		"totalScheduleItems": 369,
		"schedulePercent": 0.242749,
		"totalGuests": 27,
		"guestPercent": 0.38379,
		"mostGuestDate": "2023-09-27 00:00:00",
		"mostGuestName": "Gasometer",
		"mostGuestCity": "Vienna",
		"mostGuestAmount": 7
	},
	{
		"id": "48864cf",
		"email": "klasbeyer@gmail.com",
		"name": "x",
		"createdDate": "2021-09-08 10:27:30",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 69,
		"totalCountries": 2,
		"totalStates": 8,
		"totalEvents": 39,
		"eventPercent": 0.252284,
		"totalScheduleItems": 296,
		"schedulePercent": 0.205933,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "517c7c6",
		"email": "eric@410mgmt.com",
		"name": "Eric",
		"createdDate": "2014-05-27 20:35:22",
		"totalOrganizations": 28,
		"totalTours": 26,
		"totalDays": 747,
		"totalCountries": 10,
		"totalStates": 54,
		"totalEvents": 352,
		"eventPercent": 0.942127,
		"totalScheduleItems": 4526,
		"schedulePercent": 0.946365,
		"totalGuests": 2529,
		"guestPercent": 0.955238,
		"mostGuestDate": "2023-08-12 00:00:00",
		"mostGuestName": "Buffalo Outer Harbor Concert Site",
		"mostGuestCity": "Buffalo",
		"mostGuestAmount": 131
	},
	{
		"id": "0a157d0",
		"email": "skaarsm@gmail.com",
		"name": "Alex",
		"createdDate": "2019-12-27 21:43:54",
		"totalOrganizations": 1,
		"totalTours": 1,
		"totalDays": 97,
		"totalCountries": 1,
		"totalStates": 13,
		"totalEvents": 31,
		"eventPercent": 0.206992,
		"totalScheduleItems": 382,
		"schedulePercent": 0.250166,
		"totalGuests": 131,
		"guestPercent": 0.499272,
		"mostGuestDate": "2023-10-29 00:00:00",
		"mostGuestName": "Mayo Performing Arts Center",
		"mostGuestCity": "Morristown",
		"mostGuestAmount": 24
	},
	{
		"id": "990dbd0",
		"email": "chance@cindermgmt.com",
		"name": "Chance",
		"createdDate": "2022-02-22 01:15:15",
		"totalOrganizations": 4,
		"totalTours": 3,
		"totalDays": 511,
		"totalCountries": 12,
		"totalStates": 43,
		"totalEvents": 87,
		"eventPercent": 0.487088,
		"totalScheduleItems": 1164,
		"schedulePercent": 0.530261,
		"totalGuests": 1245,
		"guestPercent": 0.863991,
		"mostGuestDate": "2023-06-17 00:00:00",
		"mostGuestName": "Broncos Stadium at Mile High Station",
		"mostGuestCity": "Denver",
		"mostGuestAmount": 255
	},
	{
		"id": "69b2109",
		"email": "ccoffie@earthlink.net",
		"name": "Chris",
		"createdDate": "2009-09-10 22:51:51",
		"totalOrganizations": 6,
		"totalTours": 8,
		"totalDays": 367,
		"totalCountries": 13,
		"totalStates": 47,
		"totalEvents": 183,
		"eventPercent": 0.775659,
		"totalScheduleItems": 1978,
		"schedulePercent": 0.715799,
		"totalGuests": 0,
		"guestPercent": 0,
		"mostGuestDate": null,
		"mostGuestName": null,
		"mostGuestCity": null,
		"mostGuestAmount": null
	},
	{
		"id": "ee5ac6e",
		"email": "nadine@mct-agentur.com",
		"name": "Nadine",
		"createdDate": "2014-09-08 10:38:09",
		"totalOrganizations": 5,
		"totalTours": 3,
		"totalDays": 289,
		"totalCountries": 12,
		"totalStates": 21,
		"totalEvents": 214,
		"eventPercent": 0.829559,
		"totalScheduleItems": 2910,
		"schedulePercent": 0.843464,
		"totalGuests": 6207,
		"guestPercent": 0.995895,
		"mostGuestDate": "2023-07-15 00:00:00",
		"mostGuestName": "Olympiastadion Berlin",
		"mostGuestCity": "Berlin",
		"mostGuestAmount": 794
	}
]
}
