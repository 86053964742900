import React from "react"
import { Transition } from "react-spring/renderprops"

import Layout from "../components/Layout"

const Events = (props) => {
	props.addSection("Events")
	let loaded = false
	const sectionIndex = props.sections.findIndex(section => section === "Events")
	if (props.destinationIndex === sectionIndex) {
		loaded = true
	}
	const totalEvents = props.totalEvents

	const percentage = Math.floor(props.eventPercent * 100)
	const offsetPercentage = 158 - (props.eventPercent * 158)
	// set css variable to animate donut offset
	let donutClasses = "donutchart-indicator"
	if (typeof document === "object" && loaded){
		document.documentElement.style
	.setProperty('--donut-offset', offsetPercentage)
	donutClasses = "donutchart-indicator donut-loaded"
	}

	return (
		<>
			<Layout>

				<div style={styles.donutContainer}>
					<div style={styles.donut}>
						<svg className="donutchart" viewBox="0 0 100 100">
							<circle className="fullCircle" r="25" cx="50" cy="50" />
							<circle className={donutClasses} r="25" cx="50" cy="50" />
						</svg>
					</div>
				</div>
				{loaded &&
					<>
						<Transition
							from={{ opacity: 0, transform: 'translate3d(0,-40px,0)' }}
							enter={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}
						>
							{item => props=>
								<p style={{...props, ...styles.textOne}}>There were <b>{totalEvents}</b> events...</p>
							}
						</Transition>

						<Transition
							from={{ opacity: 0, transform: 'translate3d(0,-40px,0)' }}
							enter={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}
							delay={50}
						>
							{item => props=>
								<div style={{...props, ...styles.textTwo}}>
									<p>That's more than</p>
									<p><b>{percentage}%</b></p>
									<p>of Master Tour users!</p>
								</div>
							}
						</Transition>
					</>
				}

			</Layout>
		</>
	)
}

const styles = {
	donutContainer: {
		position: "absolute",
		height: "100vh",
		width: "100vw",
		left: 0,
		top:0
	},
	donut: {
		left: "2%",
		position: "absolute",
		top: "22vh",
		maxWidth: "95vw",
		width: "92vh",
	},
	textOne: {
		fontSize: "1.2em",
		marginBottom: 20,
		textShadow: "0 2px 4px rgba(0,0,0,0.12), 0 2px 3px rgba(0,0,0,0.24)",
	},
	textTwo: {
		fontSize: "1.5em",
		textShadow: "0 2px 4px rgba(0,0,0,0.12), 0 2px 3px rgba(0,0,0,0.24)",
	},
}

export default Events
