import React from "react"
import EventricSocialLinks from "./EventricSocialLinks"

const Layout = (props) => {
	return (
		<div style={styles.container}>
			<div style={styles.MTLogoContainer}>
				<div style={styles.mtLogo}>
					<img
						src="https://cdn-eoy.eventric.com/img/logo-mt.png"
						srcSet={`https://cdn-eoy.eventric.com/img/logo-mt@2x.png 2x, https://cdn-eoy.eventric.com/img/logo-mt@3x.png 3x`}
						alt="Master Tour logo"
						style={styles.imageWidth}
					/>
				</div>
				<p style={styles.masterTourText}>MASTER TOUR 2023 Summary</p>
			</div>

			<div className={`${props.className}`} style={styles.children}>
				{props.children}
			</div>

			<div style={styles.evContainer}>
				<>
					<a href="https://www.eventric.com" target="_blank" rel="noopener noreferrer">
						<div style={styles.evLogo}>
							<img
								src="https://cdn-eoy.eventric.com/img/logo-ev.png"
								alt="Eventric logo"
								style={styles.imageWidth}
							/>
						</div>
					</a>
				</>
				<EventricSocialLinks style={{ marginRight: 7 }} />
			</div>
		</div>
	)
}

const styles = {
	children: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		paddingRight: 5,
		minHeight: "70%",
		maxHeight: "70%",
	},
	container: {
		display: "flex",
		flex: 1,
		flexDirection: "column",
		justifyContent: "space-between",
		height: "100%",
		maxHeight: "100vh",
		width: "100%",
	},
	evContainer: {
		alignItems: "flex-end",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		minHeight: "20%",
		maxHeight: "20%",
		zIndex: 5000,
	},
	evLogo: {
		width: "6em",
	},
	imageWidth: {
		width: "95%",
	},
	masterTourText: {
		fontSize: "0.7em",
		fontWeight: 600,
		marginLeft: 5,
	},
	MTLogoContainer: {
		alignItems: "center",
		display: "flex",
		fontSize: "1.4em",
		minHeight: "10%",
		maxHeight: "10%",
		margin: "15px 0 0 -40px",
		zIndex: 5000,
	},
	mtLogo: {
		width: "2em",
	},
}

export default Layout
