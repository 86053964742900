import React from "react"

const TwitterIcon = (props) => (
	<a
		className="twitterSocial"
		href="https://x.com/eventric"
		target="_blank"
		rel="noopener noreferrer"
	>
		<svg
			className="socialLink"
			width={props.size}
			height={props.size}
			fill={props.color}
			viewBox="0 0 32 32"
		>
			<path d="M 5.3339844,0 C 2.4009902,0 0,2.3999902 0,5.3339844 V 26.666016 C 0,29.60201 2.3999902,32 5.3339844,32 H 26.667969 C 29.601963,32 32,29.60201 32,26.666016 V 5.3339844 C 32,2.3999902 29.601963,0 26.667969,0 Z M 4.5878906,3.8632812 h 7.0859374 l 6.144531,8.6972658 7.691407,-8.6972658 h 2.099609 L 18.751953,13.880859 H 18.75 l 9.634766,13.638672 H 21.298828 L 14.810547,18.335938 6.6875,27.519531 H 4.5878906 L 13.878906,17.015625 Z M 7.4433594,5.4003906 14.933594,15.822266 15.875,17.130859 v 0.002 l 6.412109,8.919922 h 3.22461 L 17.654297,15.121094 16.712891,13.810547 10.667969,5.4003906 Z" />{" "}
		</svg>
	</a>
)

export default TwitterIcon
